import { FC, ReactElement, useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  DefaultButton,
  MessageBarType,
  Separator,
  TextField,
  IIconProps,
} from '@fluentui/react';

import useNotifications from '../../../hooks/useNotifications';
import apiService from '../../../api';
import {
  customerSelector,
  setLocation,
  setCustomerInformation,
  availableLocationsSelector,
  setAvailableLocations,
} from '../../../redux/recordKeepingSlice';

import SeparatorGy from '../../SeparatorGy/SeparatorGy';

import { ISearchState } from './ISearchState';
import { ISearchProps } from './ISearchProps';
import SearchCustomer from './SearchCustomer';
import { useCustomersFetch } from '../../../hooks/useCustomersFetch';
import api from '../../../api';
import styles from './Search.module.scss';
import SearchLocation from './SearchLocation';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';

const SearchCustomerLocation: FC<ISearchProps> = ({ callbackLoading }): ReactElement => {

  const [searchState, setSearchState] = useState<ISearchState>({
    loading: false,
    customersList: null,
  });
  const [isSearchCollapsed, setIsSearchCollapsed] = useState<boolean>(false);

  const selectedCustomer = useSelector(customerSelector);

  const locationsList = useSelector(availableLocationsSelector);

  const { loadingCustomers, customersList, fetchCustomers } = useCustomersFetch(api.getCustomerList);

  const [isFirstLoading, { setFalse: wasFirstLoading }] = useBoolean(true);

  const dispatch = useDispatch();
  const { addNotification } = useNotifications();
  const upIcon : IIconProps = {iconName: 'ChevronUp'};
  const downIcon : IIconProps = {iconName: 'ChevronDown'};

  const fetchCustomerData = async () => {
    try {
      setSearchState(prev => ({ ...prev, loading: true }));
      const { data }: any = await apiService.getCustomerInformation(selectedCustomer.id);
      dispatch(setCustomerInformation(data.data));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Customer data fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setSearchState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchLocations = async () => {
    try {
      setSearchState(prev => ({ ...prev, loading: true }));
      const { data }: any = await apiService.getLocationsByCustomerId(
        selectedCustomer.id,
        { pageSize: 5000, pageNumber: 1 },
        {},
      );
      dispatch(setAvailableLocations(data.data));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Locations fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setSearchState(prev => ({ ...prev, loading: false }));
    }
  };

  const onCustomerChange = async () => {
    if (!isNil(selectedCustomer)) {
      dispatch(setLocation({ id: null, locationCode: '', locationName: '', shipToNumber: '', customerId: '', addrLine1: '', addrLine2: '', city: '' }));
      await fetchCustomerData();
      await fetchLocations();
    }
  };

  useEffect(() => {
    setSearchState(prev => ({ ...prev, loading: loadingCustomers, customersList }));
  }, [loadingCustomers, customersList]);

  useEffect(() => {
    if (!isFirstLoading) {
      onCustomerChange();
    }
    wasFirstLoading();
  }, [selectedCustomer]);

  useEffect(() => {
    if (callbackLoading) {
      callbackLoading(searchState.loading);
    }
  }, [searchState.loading]);

  return (
    <>
      <div>
        { !isSearchCollapsed && <SeparatorGy/> }
        <div
          className={classNames('ms-Grid-row', 'record-keeping-body', {
            'collapsed-block': isSearchCollapsed,
          })}
        >
          <div className={classNames('ms-Grid-col ms-lg8')}>
            <SearchCustomer
              customersList={searchState.customersList}
              fetchCustomers={fetchCustomers}
            />
            <SearchLocation
              locationsList={locationsList}
            />
          </div>
          <div className={classNames('ms-Grid-col ms-lg1')}>
            <Separator className="separator" vertical />
          </div>

          <div className={classNames('ms-Grid-col ms-lg3')}>
            <div className="info-container">
              <label>Region</label>
              <TextField
                value={isNil(selectedCustomer) ? '' : selectedCustomer.regionCode}
                disabled
              />
            </div>
          </div>

        </div>

        <SeparatorGy alignContent="end"/>
        <div className={styles.collapseActionContainer}>
          <DefaultButton
            onClick={() => setIsSearchCollapsed(!isSearchCollapsed)}
            iconProps= {isSearchCollapsed ? downIcon: upIcon}
          >
            {isSearchCollapsed ? 'Expand' : 'Collapse'}
          </DefaultButton>
        </div>
      </div>
      {searchState.loading && <LoadingScreen />}
    </>
  );
};

export default SearchCustomerLocation;