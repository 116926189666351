import { tireOrderingTestInstance } from '../axios';

const getTypeCodeList = (pagination: any, sortOrder: any, filters: any) => 
  tireOrderingTestInstance.post('/type-code', {pagination, sortOrder, filters});

const getTypeCodeAuthList = (pagination: any, sortOrder: any, filters: any, customerId: any) => 
  tireOrderingTestInstance.post('/type-code-auth', {pagination, sortOrder, filters, customerId});
  
const deleteTypeCode = (ids: Array<any>) =>
  tireOrderingTestInstance.delete('/type-code', { data: { ids } });

export default {
  getTypeCodeList,
  getTypeCodeAuthList,
  deleteTypeCode,
};