import { HeadCell, ISorting } from '../DataGridComponent/DataGridModels';

export const pageSizes = [100, 250, 500];

export interface BrandSelectionModalComponentProps {
    isOpen: boolean;
    onSubmit: (value?:any) => void;
    onDismiss: () => void;
    brandPrefix: string;
}

export interface BrandSelectionModalComponentState {
  loading: boolean;
  brandNumbers: Array<any>;
  foundCount: number;
}

export const brandNumbersSelectionHeadCell: HeadCell[] = [
  {
    key: 'column1',
    name: 'Start',
    fieldName: 'startNumber',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'End',
    fieldName: 'endNumber',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Count',
    fieldName: 'count',
    typeField: 'text',
  }, 
];

export const emptySelectedBrand = {
  startNumber: null,
  endNumber: null,
  locateCount: null,
};

export const defaultSorting: ISorting = {
  column: brandNumbersSelectionHeadCell[0].fieldName,
  order: 'desc',
};