export const viewConsignedTiresColumns = [
  {
    key: 'column0',
    name: 'Prefix',
    fieldName: 'pfx',
  },
  {
    key: 'column1',
    name: 'Brand Number',
    fieldName: 'bno',
  },
  {
    key: 'column2',
    name: 'Suffix',
    fieldName: 'sfx',
  },
];

export const columns = [
  {
    key: 'column1',
    name: 'Vehicle Number',
    fieldName: 'vNo',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Position',
    fieldName: 'pos',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'From',
    fieldName: 'from',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'To',
    fieldName: 'to',
    isSorted: false,
    isSortedDescending: false,
  },
];

export const columnsByVehicle = [
  {
    key: 'column0',
    name: 'Prefix',
    fieldName: 'pfx',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'Brand Number',
    fieldName: 'bno',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Suffix',
    fieldName: 'sfx',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'Total Miles',
    fieldName: 'totalMiles',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'Applied Units',
    fieldName: 'appliedUnits',
    isSorted: false,
    isSortedDescending: false,
  },
];

export const emptyApplyingForm = {
  applyUnits: '',
};
