import { FormEvent, useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IColumn,
  IconButton,
  IDropdownOption,
  MessageBarType,
  PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';
import { useSelector } from 'react-redux';
import apiService from '../../../../../../api';
import useNotifications from '../../../../../../hooks/useNotifications';
import { customerSelector, locationSelector } from '../../../../../../redux/recordKeepingSlice';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../../Pagination/Pagination';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';
import { IUnflagConsignedTiresTabState } from './IUnflagConsignedTiresTabState';
import { IUnflagConsignedTiresTabProps } from './IUnflagConsignedTiresTabProps';
import { unflageConsignedTiresColumns } from './consts';
import { pageSizes } from '../../../../../../consts/recordKeeping';
import styles from './UnflagConsignedTiresTab.module.scss';
import { sortOrder } from '../../../../../../consts/sortOrder';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_tireDisposition_unflagConsignedTires } from '../../../../../../consts/programKeys';

const UnflagConsignedTiresTab = (props: IUnflagConsignedTiresTabProps) => {
  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);

  const [state, setState] = useState<IUnflagConsignedTiresTabState>({
    items: [],
    selectedItems: [],
    loading: false,
    foundCount: 0,
  });

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });

  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(unflageConsignedTiresColumns);
  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [filters, setFilters] = useState<any>({ searchString: null });

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_tireDisposition_unflagConsignedTires);

  const fetchUnregConsignedTires = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.unflagConsignedTiresAPI.get(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
        filters,
      );
      const foundCount = data.total.found;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      addNotification({
        text: `Post OOS Tires fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.unflagConsignedTiresAPI.delete(state.selectedItems);
      addNotification({
        text: 'Selected tire(s) were successfully unflaged.',
        type: MessageBarType.success,
      });
      if (paginationProps.current === 1) {
        await fetchUnregConsignedTires();
      } else {
        await paginationProps.onChangePage(1);
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Unflag consigned tire deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const handleSelect = (e: any, itemId: any) => {
    if (e.target.checked) {
      setState((prev: any) => ({ ...prev, selectedItems: [...state.selectedItems, itemId] }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: state.selectedItems.filter(row => row !== itemId) }));
    }
  };

  const handleSelectAll = (e: any, items: Array<any>) => {
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedItems: items })) : setState((prev: any) => ({ ...prev, selectedItems: [] }));
  };

  const handleSearch = () => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    fetchUnregConsignedTires();
  };

  useEffect(() => {
    fetchUnregConsignedTires();
  }, [
    paginationProps.current,
    countOnPage,
    columnsState,
    customerId,
    locationId,
  ]);

  return (
    <>
      <div className="ms-Grid">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <div className={styles.tableHeading}>
              <div>
                <Text variant="xLarge" className={styles.highlight}>Unflag Consigned Tires</Text>
                <SeparatorGy vertical />
                <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
              </div>
            </div>
            <div className={styles.tableHeading}>
              <div className={styles.filtersBlock}>
                <TextField
                  id="searchString"
                  placeholder="Enter search string"
                  onChange={(event, searchString) => {
                    setFilters((prev: any) => ({ ...prev, searchString }));
                  }}
                  value={filters.searchString}
                />
                <PrimaryButton id="searchButton" className={styles.gyBackground} onClick={handleSearch} text="Search" />
              </div>
              <div>
                <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                <Dropdown
                  options={pageSizes.map(pageSize => ({
                    key: pageSize,
                    text: pageSize.toString(),
                  }))}
                  defaultSelectedKey={pageSizes[0]}
                  selectedKey={countOnPage?.key}
                  onChange={onChangeCountOnPage}
                />
                <SeparatorGy vertical />
                <Text variant="large" className={styles.highlight}>{state.selectedItems.length} items selected</Text>
                <SeparatorGy vertical />
                <IconButton
                  id="toggleDeletingConfirmationButton"
                  disabled={!state.selectedItems.length || !userPermissions.isWrite}
                  iconProps={{ iconName: 'Delete' }}
                  onClick={toggleDeletingConfirmation}
                />
              </div>
            </div>
            <div className={styles['table-wrapper']}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    {
                      columnsState.map(item => (
                        <th
                          key={item.name}
                          className={
                            item.isSorted && item.isSortedDescending ?
                              styles.descending :
                              item.isSorted && !item.isSortedDescending ? styles.ascending : undefined
                          }
                          onClick={() => onColumnClick(item)}
                        >
                          {item.name}
                        </th>
                      ))
                    }
                    <th>
                      <div className={styles.round}>
                        <input
                          type="checkbox"
                          id="all"
                          checked={state.items.length !== 0 && (state.selectedItems.length === +countOnPage.key || state.selectedItems.length === state.items.length)}
                          onChange={(e) => handleSelectAll(e, state.items.map(({ id }) => id))}
                        />
                        <label htmlFor="all"></label>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    state.items.map(item => (
                      <tr key={item.id}
                        className={
                          classNames(
                            styles.trBasic,
                            {
                              [styles.trSelected]: state.selectedItems.includes(item.id),
                            },
                          )
                        }
                      >
                        <td>{item.pfx}</td>
                        <td>{item.bno}</td>
                        <td>{item.sfx}</td>
                        <td>{item.oosSt}</td>
                        <td>
                          <div className={styles.round}>
                            <input type="checkbox" id={item.id} checked={state.selectedItems.includes(item.id)} onChange={(e) => handleSelect(e, item.id)} />
                            <label htmlFor={item.id}></label>
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <SeparatorGy />
            <Pagination {...paginationProps} />
          </div>
        </div>
      </div>

      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to unflag consigned tires ${state.selectedItems.length} items?`,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default UnflagConsignedTiresTab;
