import { FC, FormEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { get, isEmpty, isEqual, isNil } from 'lodash';
import {
  DefaultButton,
  PrimaryButton,
  MessageBarType,
  Modal,
  Text,
  Icon,
  TextField,
  Dialog,
  DialogType,
  DialogFooter,
  Dropdown,
  IDropdownOption,
  Label,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';

import useNotifications from '../../../../../../../hooks/useNotifications';
import apiService from '../../../../../../../api';

import LoadingScreen from '../../../../../../LoadingScreen/LoadingScreen';
import SeparatorGy from '../../../../../../SeparatorGy/SeparatorGy';

import { IShipToEditingModalProps } from './IShipToEditingModalProps';
import { IShipToEditingModalState } from './IShipToEditingModalState';
import { emptyForm } from './consts';

import styles from './ShipToEditingModal.module.scss';

const ShipToEditingModal: FC<IShipToEditingModalProps> = ({ isModalOpen, hideModal, itemInfo, customerId }): ReactElement => {

  const { addNotification } = useNotifications();
  const [state, setState] = useState<IShipToEditingModalState>({
    loading: false,
    countries: [],
    states: [],
    servicedByList: [],
    ...emptyForm,
  });
  const [showCancelConfirmation, { toggle: toggleShowCancelConfirmation }] = useBoolean(false);
  const [showSubmitConfirmation, { toggle: toggleShowSubmitConfirmation }] = useBoolean(false);
  const [parsedErrors, setParsedErrors] = useState<any>([]);

  const creatingNewItem = useMemo(() => isNil(itemInfo), [itemInfo]);

  const submitButtonDisabled = useMemo(() => {
    const { loading, countries, states, servicedByList, locationId, fClosedOut, ...fieldsData } = state;
    const requiredFields = [
      'shipToNumber',
      'locationCode',
      'geographicCode',
      'addressLine1',
      'city',
      'state',
      'country',
      'zip',
      'servicedBy',
    ];

    return isNil(customerId) ||
      requiredFields.some(fieldName => isEmpty(get(state, fieldName, null))) ||
      (creatingNewItem ?
        false :
        isEqual({ locationId, fClosedOut, ...fieldsData }, itemInfo));
  }, [
    itemInfo,
    state,
  ]);

  const handleSubmit = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { loading, countries, states, servicedByList, ...submittingData } = state;

      if (creatingNewItem) {
        await apiService.shipToAPI.addRecord({ mcbCustomerId: customerId, ...submittingData });
      } else {
        await apiService.shipToAPI.updateRecord({ id: submittingData.locationId, ...submittingData });
      }

      addNotification({
        text: `Role was successfully ${creatingNewItem ? 'created' : 'updated'}.`,
        type: MessageBarType.success,
      });
      setParsedErrors([]);
      hideModal();
    } catch (e: any) {

      const { response: { data } } = e;
      const { message, state, statusCode } = data;
      addNotification({
        text: `Ship To item ${creatingNewItem ? 'creating' : 'updating'} error: ${message}`,
        type: MessageBarType.error,
      });
      if (statusCode === 400)
        setParsedErrors(state);
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleCancelClick = () => {
    if (creatingNewItem) {
      toggleShowCancelConfirmation();
    } else {
      hideModal();
    }
  };

  const handleClearClick = () => {
    setState((prev: IShipToEditingModalState) => ({...prev, ...emptyForm}));
  };

  const fetchInitialDropDownOptions = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data: servicedByList } = await apiService.shipToAPI.getServicedByList();
      console.log(servicedByList);
      const { data: { countries } } = await apiService.customerAPI.getCountries();
      setState((prev: any) => ({ ...prev, countries, servicedByList }));
    } catch (e: any) {
      addNotification({
        text: `Fetching countries error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchStates = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data: { states } }: any = await apiService.customerBillTo.getStates(state.country);
      setState((prev: any) => ({ ...prev, states }));
    } catch (e: any) {
      addNotification({
        text: `Fetching states error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const onChangeTextInput = (ev: any, value: any) => {
    setState((prev: any) => ({ ...prev, [ev.target.name]: value }));
  };

  const onChangeCountry = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    item && setState((prev: any) => ({ ...prev, country: item.key, state: null }));
  };

  const onChangeState = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    item && setState((prev: any) => ({ ...prev, state: item.key }));
  };

  const onChangeServicedBy = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    item && setState((prev: any) => ({ ...prev, servicedBy: item.key }));
  };

  const parseErrors = (field: string) => {
    const customError = parsedErrors?.filter((error: any) => error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      if (isNil(itemInfo)) {
        setState((prev: IShipToEditingModalState) => ({
          ...prev,
          ...emptyForm,
        }));
      } else {
        setState((prev: IShipToEditingModalState) => ({
          ...prev,
          ...itemInfo,
        }));
      }
    }
  }, [
    isModalOpen,
    itemInfo,
  ]);

  useEffect(() => {
    if (!isNil(state.country))
      fetchStates();
  }, [state.country]);

  useEffect(() => {
    fetchInitialDropDownOptions();
  }, []);

  return state.loading ?
    (<LoadingScreen />) : (
      <>
        <Modal
          isOpen={isModalOpen}
          onDismiss={handleCancelClick}
          containerClassName={styles.modalContainer}
        >
          <Text block variant="xLarge" id="title">
            {creatingNewItem ? 'Create New Ship To' : 'Edit Ship To'}
          </Text>
          <SeparatorGy />
          <div className="ms-Grid">
            <div className={classNames('ms-Grid-row', styles.row)}>
              <div className="ms-Grid-col ms-sm4">
                <TextField
                  name="shipToNumber"
                  value={state.shipToNumber}
                  placeholder="Ship To #"
                  label="Ship To #:"
                  onChange={onChangeTextInput}
                  errorMessage={parseErrors('shipToNumber')}
                />
              </div>
              <div className="ms-Grid-col ms-sm8">
                <TextField
                  name="addressLine1"
                  value={state.addressLine1}
                  placeholder="Address line 1"
                  label="Address line 1:"
                  onChange={onChangeTextInput}
                  errorMessage={parseErrors('addressLine1')}
                />
              </div>
            </div>

            <div className={classNames('ms-Grid-row', styles.row)}>
              <div className="ms-Grid-col ms-sm4">
                <TextField
                  name="locationCode"
                  value={state.locationCode}
                  placeholder="Location Code"
                  label="Location Code:"
                  onChange={onChangeTextInput}
                  errorMessage={parseErrors('locationCode')}
                />
              </div>
              <div className="ms-Grid-col ms-sm8">
                <TextField
                  name="addressLine2"
                  value={state.addressLine2}
                  placeholder="Address line 2"
                  label="Address line 2:"
                  onChange={onChangeTextInput}
                  errorMessage={parseErrors('addressLine2')}
                />
              </div>
            </div>

            <div className={classNames('ms-Grid-row', styles.row)}>
              <div className="ms-Grid-col ms-sm4">
                <TextField
                  name="dssRation"
                  value={state.dssRation}
                  placeholder="DSS Ratio"
                  label="Default Spare Stock Ratio:"
                  onChange={onChangeTextInput}
                  errorMessage={parseErrors('dssRation')}
                />
              </div>
              <div className="ms-Grid-col ms-sm4">
                <Dropdown
                  id="countryDropdown"
                  options={state.countries.map((item: any) => ({
                    key: item,
                    text: item,
                  }))}
                  label="Country:"
                  selectedKey={state.country}
                  onChange={onChangeCountry}
                  errorMessage={parseErrors('country')}
                />
              </div>
              <div className="ms-Grid-col ms-sm4">
                <Dropdown
                  id="stateDropdown"
                  options={state.states.map((item: any) => ({
                    key: item,
                    text: item,
                  }))}
                  label="State:"
                  selectedKey={state.state}
                  onChange={onChangeState}
                  errorMessage={parseErrors('state')}
                />
              </div>
            </div>

            <div className={classNames('ms-Grid-row', styles.row)}>
              <div className="ms-Grid-col ms-sm4">
                <TextField
                  name="geographicCode"
                  value={state.geographicCode}
                  placeholder="Geographic Code"
                  label="Geographic Code:"
                  onChange={onChangeTextInput}
                  errorMessage={parseErrors('geographicCode')}
                />
              </div>
              <div className="ms-Grid-col ms-sm4">
                <TextField
                  name="city"
                  value={state.city}
                  placeholder="City"
                  label="City:"
                  onChange={onChangeTextInput}
                  onRenderPrefix={() => <Icon iconName="CityNext" />}
                  errorMessage={parseErrors('city')}
                />
              </div>
              <div className="ms-Grid-col ms-sm4">
                <TextField
                  name="zip"
                  value={state.zip}
                  placeholder="ZIP"
                  label="ZIP:"
                  onChange={onChangeTextInput}
                  onRenderPrefix={() => <Icon iconName="Location" />}
                  errorMessage={parseErrors('zip')}
                />
              </div>
            </div>
            <SeparatorGy />
            <div className={classNames('ms-Grid-row', styles.row)}>
              <div className="ms-Grid-col ms-sm6">
                <Dropdown
                  id="servicedByDropdown"
                  options={state.servicedByList.map((item: any) => ({
                    key: item,
                    text: item,
                  }))}
                  label="Serviced By:"
                  selectedKey={state.servicedBy}
                  onChange={onChangeServicedBy}
                  errorMessage={parseErrors('servicedBy')}
                />
                <TextField
                  name="shipInstructions"
                  value={state.shipInstructions}
                  placeholder="Shipping Instructions"
                  label="Shipping Instructions:"
                  onChange={onChangeTextInput}
                  errorMessage={parseErrors('shipInstructions')}
                  multiline
                  resizable={false}
                  rows={6}
                />
              </div>
              <div className="ms-Grid-col ms-sm6">
                <Label>Contact:</Label>
                <TextField
                  name="contactName"
                  value={state.contactName}
                  placeholder="Full Name"
                  label="Full Name:"
                  onChange={onChangeTextInput}
                  errorMessage={parseErrors('contactName')}
                />
                <TextField
                  name="contactPhone1"
                  value={state.contactPhone1}
                  placeholder="Phone 1"
                  label="Phone 1:"
                  onChange={onChangeTextInput}
                  errorMessage={parseErrors('contactPhone1')}
                />
                <TextField
                  name="contactPhone2"
                  value={state.contactPhone2}
                  placeholder="Phone 2"
                  label="Phone 2:"
                  onChange={onChangeTextInput}
                  errorMessage={parseErrors('contactPhone2')}
                />
              </div>
            </div>
          </div>
          <SeparatorGy />
          <div className={styles.modalFooter}>
            <DefaultButton
              id="cancelCopyingRoleButton"
              text="Cancel"
              onClick={handleCancelClick}
            />
            <PrimaryButton
              id="submitButton"
              text={creatingNewItem ? 'Create' : 'Save Updates'}
              onClick={creatingNewItem ? toggleShowSubmitConfirmation : handleSubmit}
              disabled={submitButtonDisabled}
            />
            <PrimaryButton
              id="clearButton"
              text={'Clear'}
              onClick={handleClearClick}
            />
          </div>
        </Modal>
        <Dialog
          hidden={!showCancelConfirmation}
          onDismiss={toggleShowCancelConfirmation}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Confirmation',
            subText: creatingNewItem ?
              'Are you sure you would like to cancel? This item will not be created.' :
              'Are you sure you would like to cancel discard all changes made?',
          }}
          modalProps={{ isBlocking: true }}
        >
          <DialogFooter>
            <DefaultButton onClick={toggleShowCancelConfirmation} text="No - Return" />
            <PrimaryButton onClick={() => { toggleShowCancelConfirmation(); hideModal(); }} text="Yes - Cancel" />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!showSubmitConfirmation}
          onDismiss={toggleShowSubmitConfirmation}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Confirmation',
            subText: 'Are you sure you would like to proceed and add this new item?',
          }}
          modalProps={{ isBlocking: true }}
        >
          <DialogFooter>
            <DefaultButton onClick={toggleShowSubmitConfirmation} text="No - Return" />
            <PrimaryButton
              onClick={() => { toggleShowSubmitConfirmation(); handleSubmit(); }}
              text={`Yes - ${creatingNewItem ? 'Create' : 'Update'} item`}
            />
          </DialogFooter>
        </Dialog>
      </>
    );
};

export default ShipToEditingModal;
