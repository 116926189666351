import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoCompleteField from './AutoCompleteField';
import { Text } from '@fluentui/react';
import { ILocation } from './ILocation';
import { isNil } from 'lodash';
import { customerSelector, locationSelector, setLocation } from '../../../redux/recordKeepingSlice';
import { setOrderCode } from '../../../redux/tireOrderingSlice';
import styles from './Search.module.scss';

interface SearchLocationProps {
    locationsList: ILocation[] | null;
  }

const SearchLocation: FC<SearchLocationProps> = ({
  locationsList,
}) => {
    
  const dispatch = useDispatch();
  const selectedCustomer = useSelector(customerSelector);
  const disabled = isNil(selectedCustomer);
  const selectedLocation = useSelector(locationSelector);

  const getLocationById = (idToFind: number) => {
    const locationToFind = locationsList?.find(({ id }) => id === idToFind);
    return locationToFind ? locationToFind : null;
  };

  const onChangeSearchLocation = (newLocation: any) => {
    dispatch(setLocation(getLocationById(newLocation)));
    dispatch(setOrderCode(null));
  };

  const onLocationClear = (text: string) => {
    if (!text) {
      dispatch(setLocation({ id: null, locationCode: '', locationName: '', shipToNumber: '', customerId: '', addrLine1: '', addrLine2: '', city: '' }));
    }
  };

  return (
    <div className="search-form-container">
      <div>
        <Text variant='xLarge' className={styles.highlight}>Search a Location</Text>
      </div>
      <div className="search-form-body">
        <AutoCompleteField
          onChangeAction={onChangeSearchLocation}
          initialValue={selectedLocation?.locationCode}
          list={locationsList ?
            locationsList.map(({ id, locationCode }) => ({
              key: id,
              text: locationCode,
            })) : []
          }
          label="Location Code"
          data-testid='customer-loc-code'
          icon="POISolid"
          disabled={disabled}
          textValue={onLocationClear}
        />
        <AutoCompleteField
          onChangeAction={onChangeSearchLocation}
          initialValue={selectedLocation?.locationName}
          list={
            locationsList ?
              locationsList.map(({ id, locationName }) => ({
                key: id,
                text: locationName,
              })) : []
          }
          label="Location Name"
          data-testid='customer-loc-name'
          icon="POISolid"
          disabled={disabled}
          textValue={onLocationClear}
        />
        <AutoCompleteField
          onChangeAction={onChangeSearchLocation}
          initialValue={selectedLocation?.shipToNumber}
          list={
            locationsList ?
              locationsList.map(({ id, shipToNumber }) => ({
                key: id,
                text: shipToNumber,
              })) : []
          }
          label="Ship to #"
          icon="NumberSymbol"
          data-testid='customer-ship-to'
          disabled={disabled}
          textValue={onLocationClear}
        />
      </div>
    </div>
  );
};

export default SearchLocation;
