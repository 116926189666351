import React, { useState, useEffect } from 'react';
import DataGridComponent from '../../../../../shared/DataGridComponent';
import { BillInspCodesHeadCell } from './const';
import styles from './BillInspCodes.module.scss';
import apiService from '../../../../../api';
import { IBillingInspCode } from './IBillingInspCodes';
import { useUserPermissions } from '../../../../../hooks/useUserPermissions';
import { auth_terms_billableInspCodes } from '../../../../../consts/programKeys';
import useNotifications from '../../../../../hooks/useNotifications';
import {
  MessageBarType,
  PrimaryButton,
  Dropdown,
  IDropdownOption,
  Text,
  IColumn,
} from '@fluentui/react';
import LoadingScreen from '../../../../LoadingScreen/LoadingScreen';
import { useParams } from 'react-router-dom';
import { debounce, isEmpty } from 'lodash';


function BillInspCodes() {

  const { contractId } = useParams<{ contractId: string }>();
  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_terms_billableInspCodes);
  const [isLoading, setIsLoading] = useState(false);
  //const [selectedBillable, setSelectedBillable] = useState<any>();
  const [billingInspCodes, setBillingInspCodes] = useState<IBillingInspCode[]>([]);
  const [selectedInspCodes, setSelectedInspCode] = useState<any>(null);
  const [displayed, setDisplayed] = useState<IBillingInspCode[]>([]);
  const [updatedBillingInspCodes, setUpdatedBillingInspCodes] = useState<IBillingInspCode[]>([]);
  const { addNotification } = useNotifications();
  const [searchedText, setSearchedText] = useState('');

  const [sortOrder, setSortOrder] = useState<{ column: string, order: string }>({
    column: 'billable',
    order: 'asc',
  });

  //const isBillableInvalid = (selectedBillable != 'Y' && selectedBillable != 'N' && selectedBillable != 'y' && selectedBillable != 'n');
  const isBillableInvalid = billingInspCodes.findIndex(b => b.billable !== 'Y' && b.billable !== 'N') > - 1;
  const isRowSelected = !isEmpty(selectedInspCodes);

  const fetchBillingInspCodes = async (contractId?: string, pagination?: any, sortOrder?: any[], filterText?: string) => {
    setIsLoading(true);
    try {
      const response = await apiService.terms.getBillingInspCodes(`${contractId}`, pagination, sortOrder, filterText);
      setBillingInspCodes(response?.data?.data);
      setUpdatedBillingInspCodes(response?.data?.data);
      setDisplayed(response?.data?.data);
    } catch (error: any) {
      console.error(error?.state?.message);
      const { response } = error;
      addNotification({
        text: `Customers fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateBillingInspCodes = async (contractId?: string, pagination?: any, sortOrder?: any[], filterText?: string) => {
    setIsLoading(true);
    try {
      const response = await apiService.terms.updateBillingInspCodes({
        items: billingInspCodes.map(b => ({ ...b, foosavg: '1' })),
      });
      setUpdatedBillingInspCodes(response?.data);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Billable fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(false);
      await fetchBillingInspCodes(contractId, pagination, sortOrder, filterText);
    }
  };

  const handleSelectedRows = (data: IBillingInspCode[]) => {
    if (!data.length) {
      setSelectedInspCode([]);
      return;
    }
    setSelectedInspCode(data);
  };

  useEffect(() => {
    fetchBillingInspCodes(contractId);
  }, []);

  const getDisplayedData = () => {
    const results = billingInspCodes.filter((bic) => {
      return (
        bic.billable?.toUpperCase().includes(searchedText.toUpperCase()) ||
        bic.comments?.toUpperCase().includes(searchedText.toUpperCase()) ||
        bic.description?.toUpperCase().includes(searchedText.toUpperCase()) ||
        bic.inspCode?.toString().includes(searchedText)
      );
    }).sort((a, b) => {
      switch (sortOrder?.column) {
        case 'billable':
          if (sortOrder?.order === 'asc') {
            return a?.billable?.localeCompare(b?.billable ?? '') ?? 0;
          }
          return b?.billable?.localeCompare(a.billable ?? '') ?? 0;
        case 'description':
          if (sortOrder?.order === 'asc') {
            return a?.description?.localeCompare(b?.description ?? '') ?? 0;
          }
          return b?.description?.localeCompare(a.description ?? '') ?? 0;
        case 'comments':
          if (sortOrder?.order === 'asc') {
            return a?.comments?.localeCompare(b?.comments ?? '') ?? 0;
          }
          return b?.comments?.localeCompare(a.comments ?? '') ?? 0;
        case 'inspCode':
          if (sortOrder?.order === 'asc') {
            return a?.inspCode?.toString()?.localeCompare(b?.inspCode?.toString() ?? '') ?? 0;
          }
          return b?.inspCode?.toString()?.localeCompare(a.inspCode?.toString() ?? '') ?? 0;

        default:
          return 0;
      }
      return 0;
    });
    setDisplayed(results);
  };

  const handleUpdate = (rows: IBillingInspCode[]) => {
    setBillingInspCodes(rows);
    setUpdatedBillingInspCodes(rows);
  };

  const handleSubmit = async () => {
    await updateBillingInspCodes(contractId);
  };

  const handleDataChanged = debounce((data: any) => {
    const { sortOrder, searchedText } = data;
    setSortOrder(sortOrder);
    setSearchedText(searchedText);
  }, 500);

  useEffect(() => {
    getDisplayedData();
  }, [sortOrder, searchedText]);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }, [displayed]);

  return (

    <div className="ms-Grid">
      <div id='billing-insp-codes' className={styles.billInspCodesContainer}>
        <DataGridComponent
          idTable={'bill-inps-codes'}
          title='Bill Insp Codes'
          headCells={BillInspCodesHeadCell}
          rowsTable={displayed}
          totalDataFound={displayed.length}
          enableSearching
          enableRowsPerPage
          enableResizeColumns
          handleSelectRow={handleSelectedRows}
          handleUpdate={handleUpdate}
          handleChangeDataGridState={handleDataChanged}
          isLoading={isLoading} />
      </div>
      <PrimaryButton
        id="submitButton"
        onClick={handleSubmit}
        text="Save"
        disabled={isBillableInvalid || !userPermissions.isWrite}
      />
      {
        isLoading && <LoadingScreen />
      }
    </div >
  );
};

export default BillInspCodes;
