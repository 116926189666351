import { FC, ReactElement, useState, useEffect } from 'react';
import { DefaultButton, PrimaryButton, MessageBarType, Modal, Text, Icon, TextField, IconButton } from '@fluentui/react';

import useNotifications from '../../../../../../hooks/useNotifications';
import apiService from '../../../../../../api';

import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';

import { IPermissionCopyingModalProps } from './IPermissionCopyingModalProps';
import { IPermissionCopyingModalState } from './IPermissionCopyingModalState';

import styles from './PermissionCopyingModal.module.scss';

const PermissionCopyingModal: FC<IPermissionCopyingModalProps> = ({ isModalOpen, hideModal, roleData }): ReactElement => {

  const { addNotification } = useNotifications();
  const [state, setState] = useState<IPermissionCopyingModalState>({
    loading: false,
    roleName: '',
  });

  useEffect(() => {
    if (isModalOpen)
      setState({
        loading: false,
        roleName: '',
      });
  }, [isModalOpen]);

  const handleCopy = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.management.cloneRole(roleData.id, state.roleName);
      addNotification({
        text: 'Role was successfully copied.',
        type: MessageBarType.success,
      });
      setState(prev => ({ ...prev, loading: false }));
      hideModal();
    } catch (e: any) {
      setState(prev => ({ ...prev, loading: false }));
      const { response } = e;
      addNotification({
        text: `Role copying error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  return state.loading ? <LoadingScreen /> : (
    <Modal
      isOpen={isModalOpen}
      onDismiss={hideModal}
      containerClassName={styles.modalContainer}
    >
      <div className='modalHeader'>
        <IconButton
          iconProps={{ iconName: 'Cancel' }}
          ariaLabel="Close popup modal"
          onClick={hideModal}
        />
      </div>
      <Text block variant="xLarge" className={styles.modalHeader} id="title">
        <Icon iconName="NewFolder" className={styles.mainIcon} />
        Create a copy of the {roleData?.name} role
      </Text>
      <SeparatorGy />
      <div className={styles.modalBody}>
        <div>
          <TextField
            value={state.roleName}
            onChange={(ev: any, roleName: any) => setState((prev) => ({ ...prev, roleName }))}
            label="New Role Name"
          />
          <div className={styles.copyingInstruction}>
            <Icon iconName="Error" className={styles.instructionIcon} />
            <Text>The role name cannot be modified upon creation. Be sure to confirm role name before creating the group.</Text>
          </div>
        </div>
      </div>
      <div className={styles.modalFooter}>
        <DefaultButton
          id="cancelCopyingRoleButton"
          text="Cancel - Return"
          onClick={hideModal}
        />
        <PrimaryButton
          id="copyRoleButton"
          text="Yes - Create New Role"
          onClick={handleCopy}
          disabled={state.roleName.length === 0}
        />
      </div>
    </Modal>
  );
};

export default PermissionCopyingModal;
