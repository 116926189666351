export const mockLocations = [
  {
    addrLine1: '333 W 34TH ST 9TH FLOOR',
    addrLine2: '333 W. 34TH STREET  9TH FLOOR',
    available: true,
    city: 'NEW YORK',
    country: 'USA',
    customerId: 'C000568012',
    id: '0000001164',
    locationCode: 'LC1',
    locationName: '333 W 34TH ST 9TH FLOOR333 W. 34TH STREET  9TH FLOOR',
    shipToNumber: '0000568012',
    state: 'NY',
  },
  {
    addrLine1: '97-11 - 222ND STREET',
    addrLine2: 'QUEENS VILLAGE DEPOT  #8',
    available: true,
    city: 'QUEENS VILLAGE',
    country: 'USA',
    customerId: 'C000568012',
    id: '0000001165',
    locationCode: '008',
    locationName: '97-11 - 222ND STREETQUEENS VILLAGE DEPOT  #8',
    shipToNumber: '0000624174',
    state: 'NY',
  },
];

export const mockTireSizes = [
  'B315/80R22.5',
  'B305/85R22.5',
];

export const emptyFilterModalWindowState = {
  loading: false,
  locations: [],
  tireSizes: [],
  tireClasses: [],
};

export const initialFilterState = {
  locationCode: '',
  tireSize: null,
  tireClass: null,
  typeCode: null,
  productCode: null,
  dotNo: null,
  totalMilesFrom: 0,
  totalMilesTo: 500000,
  addedDateFrom: null,
  addedDateTo: null,
  changeDateFrom: null,
  changeDateTo: null,
  allOos: false,
  billed: false,
  flgasDmg: false,
  dmg: false,
  sold: false,
  earlyRemoval: false,
  retreadAdjust: false,
  lost: false,
  transferTo: false,
  closeOut: false,
};

