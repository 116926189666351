import { isNil } from 'lodash';

export const printingTypes = {
  excel: 'Excel',
  pdf: 'PDF', 
};


export const downloadFile = (dataToBePrinted: any, printingType: any, reportName?: any) => {
  const typeFilePrinted = printingTypes.excel?'data:application/vnd.ms-excel;base64':'application/pdf';
  const url = window.URL.createObjectURL(new Blob([dataToBePrinted], { type: typeFilePrinted }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download', 
    `${isNil(reportName) ? 'report' : reportName}.${
      printingType === printingTypes.excel ? 'xlsx' : 
        printingType === printingTypes.pdf ? 'pdf' :
          printingType === 'Document' ? 'doc' :
            'docx'
    }`,
  );
  document.body.appendChild(link);
  link.click();
};
