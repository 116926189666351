import { MessageBarType, PrimaryButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { debounce, isEmpty } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import apiService from '../../../../../api';
import { ICustomer } from '../../../../../components/Common/Search/ICustomer';
import useNotifications from '../../../../../hooks/useNotifications';
import { INotification } from '../../../../../models/INotification';
import { customerSelector } from '../../../../../redux/customerSlice';
import DataGridComponent from '../../../../../shared/DataGridComponent';
import AutoCompleteField from '../../../../Common/Search/AutoCompleteField';
import styles from './CommonOwner.module.scss';
import { commonOwnerHeadCell } from './consts';
import { useUserPermissions } from '../../../../../hooks/useUserPermissions';
import { auth_customer_owner } from '../../../../../consts/programKeys';

interface CommonOwnerComponentProps {
  value?: any
}

const CommonOwnerComponent: FunctionComponent<CommonOwnerComponentProps> = () => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_customer_owner);

  const { addNotification } = useNotifications();
  const customer = useSelector(customerSelector);

  const [customersList, setCustomerList] = useState<ICustomer[]>([]);
  const [customersSelected, setCustomersSelected] = useState<Array<ICustomer> | null>(null);
  const [subsidiariesList, setSubsidiariesList] = useState<ICustomer[]>([]);
  const [totalFound, setTotalFound] = useState(0);
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });
  const [customerToAdd, setCustomerToAdd] = useState<any>({});
  const [iscommonOwner] = useState(customer?.fComOwnr == 'Y');

  const [isLoadingFetchCustomers, { toggle: toggleIsLoadingFetchCustomers }] = useBoolean(false);
  const [isLoadingAvailableTable, { toggle: toggleShowLoadingAvailableTable }] = useBoolean(false);

  const fetchCustomers = async (filters: any) => {
    try {
      toggleIsLoadingFetchCustomers();
      const { data }: any = await apiService.customerAPI.getAvailableSubsidiaries(filters);
      setCustomerList(data.data);
      toggleIsLoadingFetchCustomers();
    } catch (e: any) {
      setNotification({ text: 'Customers fetching error.', type: MessageBarType.error });
    }
  };

  const fetchSubsidiaries = async (
    pagination: any = { pageSize: 5, pageNumber: 1 },
    filters?: any,
    sortOrder: any = { column: commonOwnerHeadCell[0].fieldName, order: 'asc' },
  ) => {
    try {
      toggleIsLoadingFetchCustomers();
      const { data }: any = await apiService.customerAPI.getCustomerSubsidiaries(pagination, filters, sortOrder, customer?.id);
      setSubsidiariesList(data.data);
      setTotalFound(data?.total?.found);
      toggleIsLoadingFetchCustomers();
    } catch (e: any) {
      setNotification({ text: 'Subsidiaries fetching error.', type: MessageBarType.error });
    }
  };

  const fetchAddNewSubsidiary = async () => {
    try {
      const bodyRequest = {
        customerId: customer?.id,
        customerOwnerId: customerToAdd?.id,
      };
      await apiService.customerAPI.addNewSubsidiary(bodyRequest);
      setNotification({ text: 'New subsidiary adding successfully.', type: MessageBarType.success });
    } catch (error) {
      setNotification({ text: 'Adding subsidiary error.', type: MessageBarType.error });
    }
  };

  const fetchDeleteSubsidiary = async () => {
    try {
      const bodyRequest = {
        customerOwnerId: getIdsFromCustomers(customersSelected),
      };
      await apiService.customerAPI.deleteSubsidiary(bodyRequest);
      setNotification({ text: 'Subsidiaries deleted successfully.', type: MessageBarType.success });
    } catch (error) {
      setNotification({ text: 'Deleting subsidiaries error.', type: MessageBarType.error });
    }
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    toggleShowLoadingAvailableTable();
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString: searchedText,
    };

    await fetchSubsidiaries(pagination, filters, sortOrder);
    toggleShowLoadingAvailableTable();
  };

  const handleAddSubsidiary = async () => {
    toggleShowLoadingAvailableTable();
    await fetchAddNewSubsidiary();
    await fetchSubsidiaries();
    setCustomerToAdd({});
    toggleShowLoadingAvailableTable();
  };

  const handleSelectRow = (rowsSelected: any) => {
    setCustomersSelected(rowsSelected);
  };

  const handleDelete = async () => {
    toggleShowLoadingAvailableTable();
    await fetchDeleteSubsidiary();
    await fetchSubsidiaries();
    toggleShowLoadingAvailableTable();
  };

  const onCustomerChange = (newCustomer: any) => {
    setCustomerToAdd(getCustomerById(newCustomer));
  };

  const removeEmpty = ({ text }: any) => text !== null;

  const getCustomerNameList = React.useMemo(() => customersList ?
    customersList.map(({ id, customerName }) => ({
      key: id,
      text: customerName,
    })).filter(removeEmpty) : [], [customersList]);

  const customerNameInputText = (custName: string) => {
    if (custName.replace(/ /g, '').length) {
      getCustomersDebounce({ custName });
    }
  };

  const getCustomersDebounce = debounce(async (customerData) => {
    fetchCustomers(customerData);
  }, 500);

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  const getIdsFromCustomers = (customerArray: any) => customerArray?.map((customer: any) => customer?.id);
  const getCustomerById = (idToFind: number) => customersList?.find(({ id }) => id === idToFind) || null;
  const disableSusidiaryBtn = isEmpty(customerToAdd);

  return (
    <>
      <div className="ms-Grid margin-left-rigth-2 marginTop18" dir='ltr'>
        {iscommonOwner ? (
          <div className="ms-Grid-row">
            <div className={classNames('ms-Grid-col', 'ms-md12')}>
              <DataGridComponent
                idTable='common-owner-table'
                title='Owner'
                headCells={commonOwnerHeadCell}
                rowsTable={subsidiariesList}
                totalDataFound={totalFound}
                enablePagination
                enableSearching
                enableRowsPerPage
                enableCheckBox
                enableDeleteOption
                handleChangeDataGridState={handleChangeDataGridState}
                handleSelectRow={handleSelectRow}
                handleDelete={handleDelete}
                isLoading={isLoadingAvailableTable}
              />
            </div>
            <div className={classNames('ms-Grid-col', 'ms-md6', styles.subsidiaryContainer)}>
              <div className={classNames('ms-Grid')}>
                <div className={classNames('ms-Grid-col', 'ms-md8')}>
                  <AutoCompleteField
                    onChangeAction={onCustomerChange}
                    value={customerToAdd?.id}
                    list={getCustomerNameList}
                    label="Add New Subsidiary"
                    icon="Contact"
                    data-testid='customer-contact'
                    textValue={customerNameInputText}
                    isLoading={isLoadingFetchCustomers}
                  />
                </div>
                <div className={classNames('ms-Grid-col', 'ms-md4', styles.addSubsidiaryBtn)}>
                  <PrimaryButton
                    id='addSubsidiaryBtn'
                    disabled={disableSusidiaryBtn || !userPermissions.isWrite}
                    onClick={handleAddSubsidiary}
                    text="+ Add"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.alertWarning}>
            This customer is not a Common Owner, if you need manage Subsidiaries, please change that on General tab.
          </div>
        )}
      </div>
    </>
  );
};

export default CommonOwnerComponent;