import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';
import { Pivot, PivotItem } from '@fluentui/react';

import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import {
  auth_vehicleMiles_applyMilesToTires,
  auth_vehicleMiles_postMonthlyMiles,
  auth_vehicleMiles_postPriorVehicleMiles,
  auth_vehicleMiles_MileageAudit,
} from '../../../../consts/programKeys';

import TabHeader from '../TabHeader/TabHeader';

import { IVehicleMilesTabProps } from './IVehicleMilesTabProps';
import { vehicleMilesTabs } from './consts';

import ApplyMilesToTires from './tabComponents/ApplyMilesToTires/ApplyMilesToTires';
import PostMonthlyMilesTab from './tabComponents/postMonthlyMiles/PostMonthlyMilesTab';
import PostPriorVehicleMiles from './tabComponents/postPriorVehicleMiles/PostPriorVehicleMiles';

import styles from './VehicleMilesTab.module.scss';
import MileageAuditTab from './tabComponents/MileageAudit/MileageAuditTab';

const VehicleMilesTab: FC<IVehicleMilesTabProps> = (): ReactElement => {
  const [selectedKey, setSelectedKey] = React.useState(vehicleMilesTabs.postMonthlyMiles);

  const { hasPermission } = useUserPermissions();
  const userPermissions_applyMilesToTires = hasPermission(auth_vehicleMiles_applyMilesToTires);
  const userPermissions_postMonthlyMiles = hasPermission(auth_vehicleMiles_postMonthlyMiles);
  const userPermissions_postPriorVehicleMiles = hasPermission(auth_vehicleMiles_postPriorVehicleMiles);
  const userPermissions_MileageAudit = hasPermission(auth_vehicleMiles_MileageAudit);

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  return (
    <div>
      <TabHeader
        details
      />
      <Pivot
        className={classNames('pivot-container')}
        selectedKey={selectedKey}
        onLinkClick={handleLinkClick}
        getTabId={getTabId}
        linkFormat="tabs"
      >
        {userPermissions_postMonthlyMiles.isAccess && (
          <PivotItem headerText={vehicleMilesTabs.postMonthlyMiles} itemKey={vehicleMilesTabs.postMonthlyMiles}>
            <div className="vertical-pivot-container"><PostMonthlyMilesTab /></div>
          </PivotItem>
        )}
        {userPermissions_postPriorVehicleMiles.isAccess && (
          <PivotItem headerText={vehicleMilesTabs.postPriorVehicleMiles} itemKey={vehicleMilesTabs.postPriorVehicleMiles}>
            <div className="vertical-pivot-container"><PostPriorVehicleMiles /></div>
          </PivotItem>
        )}
        {userPermissions_applyMilesToTires.isAccess && (
          <PivotItem headerText={vehicleMilesTabs.applyMilesToTires} itemKey={vehicleMilesTabs.applyMilesToTires}>
            <div className="vertical-pivot-container"><ApplyMilesToTires /></div>
          </PivotItem>
        )}
        {userPermissions_MileageAudit.isAccess && (
          <PivotItem headerText={vehicleMilesTabs.mileageAudit} itemKey={vehicleMilesTabs.mileageAudit}>
            <div className="vertical-pivot-container"><MileageAuditTab isWrite={ userPermissions_MileageAudit.isWrite } /></div>
          </PivotItem>
        )}
      </Pivot>
    </div>
  );
};

export default VehicleMilesTab;
