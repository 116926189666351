import {HeadCell} from '../../../../../shared/DataGridComponent/DataGridModels';
import {IFormDataField} from '../../../../../shared/FormBuilderComponent';

export const FLEET_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'numberOfWheelsField',
    properties: {
      id: 'numberOfWheels',
      label: 'Number of Wheels',
      name: 'numberOfWheels',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'numberOfVehicleUnitsField',
    properties: {
      id: 'numberOfVehicleUnits',
      label: 'Number of Vehicle Units',
      name: 'numberOfVehicleUnits',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'monthlyVehicleUnitsField',
    properties: {
      id: 'monthlyVehicleUnits',
      label: 'Monthly Vehicle Units',
      name: 'monthlyVehicleUnits',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'quoteIdField',
    properties: {
      id: 'quoteId',
      label: 'Quote ID',
      name: 'quoteId',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'contractIdField',
    properties: {
      id: 'contractId',
      label: 'Contract Id',
      name: 'contractId',
      type: 'text',
      disabled: false,
    },
  },
];

export const FILTERS_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'statusField',
    properties: {
      id: 'status',
      label: 'Filter by:',
      name: 'status',
      type: 'text',
    },
  },
  {
    formFieldName: 'quoteIdField',
    properties: {
      id: 'quoteId',
      label: 'Quote Id:',
      name: 'quoteId',
      type: 'text',
    },
  },
  {
    formFieldName: 'versionField',
    properties: {
      id: 'version',
      label: 'Version:',
      name: 'version',
      type: 'text',
    },
  },
  {
    formFieldName: 'contractIdField',
    properties: {
      id: 'contractId',
      label: 'Contract Id:',
      name: 'contractId',
      type: 'text',
    },
  },
];

export const INVOICE_STATUSES = [
  'Show All',
  'Baseline',
  'Quote Id',
  'Contract Id',
];

export interface IQuoteId {
  quoteId: string,
  versions: number[],
};

export interface IVehicle {
  make?: IMake | undefined,
}

export interface IMake {
  make?: string | undefined,
  description?: string | undefined,
  models?: IModel[] | undefined
};

export interface IModel {
  description?: string | undefined,
  model?: string | undefined,
}

export interface IFleetData{
  fleetId?: string | undefined,
  mCbFleetid?: string,
  vehicleMake?: string;
  vehicleModel?: string;
  numberOfWheels?: number;
  numberOfVehicleUnits?: number;
  monthlyVehicleUnits?: number;
  quoteId?: string | null;
  contractId?: string | null;
  products?: IProductData[];
}

export interface IProductData{
  productId: number | null;
  authprodId?: number;
  productCode?: string;
  productDescription?: string;
  tireSize?: string;
  bogey?: number | undefined;
  usage?: string;
}

export interface ProductModalComponentProps {
  fleetId: string | undefined;
  products?: IProductData[] | undefined,
  id: string;
  title?: string | undefined;
  isOpen: boolean;
  onSubmit: (value?:any) => void;
  onUpdateProduct: (products: IProductData[]) => void;
  onDismiss: () => void;
  successLabel: string;
  cancelLabel: string;
}

export const initialFleet: IFleetData[] = [
  {
    fleetId: '',
    mCbFleetid: '',
    vehicleMake: '',
    vehicleModel: '',
    numberOfVehicleUnits: 0,
    numberOfWheels: 0,
    quoteId: '',
    contractId: '',
    products: [],
  },
];

export const fleetsTableHeadCells: HeadCell[] = [
  {
    key: 'column0',
    typeField: 'text',
    isEditable: false,
    name: 'Vehicle Make',
    fieldName: 'vehicleMake',
  },
  {
    key: 'column1',
    typeField: 'text',
    isEditable: false,
    name: 'Vehicle Model',
    fieldName: 'vehicleModel',
  },
  {
    key: 'column2',
    typeField: 'text',
    isEditable: false,
    name: 'Number of Wheels',
    fieldName: 'numberOfWheels',
  },
  {
    key: 'column3',
    typeField: 'text',
    isEditable: false,
    name: 'Number of Vehicle Units',
    fieldName: 'numberOfVehicleUnits',
  },
  {
    key: 'column4',
    typeField: 'text',
    isEditable: false,
    name: 'Monthly Vehicle Units',
    fieldName: 'monthlyVehicleUnits',
  },
  {
    key: 'column5',
    typeField: 'text',
    isEditable: false,
    name: 'Quote ID',
    fieldName: 'quoteId',
  },
  {
    key: 'column6',
    typeField: 'text',
    isEditable: false,
    name: 'Contract ID',
    fieldName: 'contractId',
  },
];

export const filterOptions = [
  {
    key: 'baseline',
    text: 'Baseline',
  },
  {
    key: 'contractId',
    text: 'Contract Id',
  },
  {
    key: 'quoteId',
    text: 'Quote Id',
  },
];

export const PRODUCT_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'productIdField',
    properties: {
      id: 'productId',
      label: 'Product Id',
      name: 'productId',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'productCodeField',
    properties: {
      id: 'productCode',
      label: 'Product Code',
      name: 'productCode',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'productDescriptionField',
    properties: {
      id: 'productDescription',
      label: 'Product Description',
      name: 'productDescription',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'tireSizeField',
    properties: {
      id: 'tireSize',
      label: 'Tire Size',
      name: 'tireSize',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'bogeyField',
    properties: {
      id: 'bogey',
      label: 'Bogey',
      name: 'bogey',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'usageField',
    properties: {
      id: 'usage',
      label: 'Usage',
      name: 'usage',
      type: 'text',
      disabled: false,
    },
  },
];

export const productTableHeadCells: HeadCell[] = [
  {
    key: 'column0',
    typeField: 'text',
    isEditable: false,
    name: 'Product Code',
    fieldName: 'productCode',
  },
  {
    key: 'column1',
    typeField: 'text',
    isEditable: false,
    name: 'Product Description',
    fieldName: 'description',
  },
  {
    key: 'column2',
    typeField: 'text',
    isEditable: false,
    name: 'Tire Size',
    fieldName: 'tireSize',
  },
  {
    key: 'column3',
    typeField: 'text',
    isEditable: false,
    name: 'Bogey',
    fieldName: 'bogey',
  },
  {
    key: 'column4',
    typeField: 'text',
    isEditable: false,
    name: 'Usage',
    fieldName: 'percUsage',
  },
];