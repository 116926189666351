import { HeadCell } from '../DataGridComponent/DataGridModels';
import { IFormDataField } from '../FormBuilderComponent';
import apiService from '../../api';

export const MAKE_MODEL_GROUPS_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'idField',
    properties: {
      id: 'id',
      label: 'Id',
      name: 'id',
      type: 'text',
      disabled: false,
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'rateField',
    properties: {
      id: 'rate',
      label: 'Rate',
      name: 'rate',
      type: 'text',
      disabled: false,
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'vehicleMakeField',
    properties: {
      id: 'vehicleMake',
      label: 'Vehicle Make',
      name: 'vehicleMake',
      type: 'text',
      disabled: false,
      required: true,
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'vehicleModelField',
    properties: {
      id: 'vehicleModel',
      label: 'Vehicle Model',
      name: 'vehicleModel',
      type: 'text',
      disabled: false,
      required: true,
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'numberOfWheelsField',
    properties: {
      id: 'numberOfWheels',
      label: 'Number of Wheels',
      name: 'numberOfWheels',
      type: 'text',
      disabled: false,
      required: true,
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'numberOfVehicleUnitsField',
    properties: {
      id: 'numberOfVehicleUnits',
      label: 'Number of Vehicle Units',
      name: 'numberOfVehicleUnits',
      type: 'text',
      disabled: false,
      required: true,
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'monthlyVehicleUnitsField',
    properties: {
      id: 'monthlyVehicleUnits',
      label: 'Monthly Vehicle Units',
      name: 'monthlyVehicleUnits',
      type: 'text',
      disabled: false,
      required: true,
      textTransform: 'uppercase',
    },
  },
];

export const makeModelHeadCells: HeadCell[] = [
  {
    key: 'column0',
    name: 'Rate',
    fieldName: 'rate',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Vehicle Make',
    fieldName: 'vehicleMake',
    typeField: 'text',
    isEditable: false,
    isMandatory: true,
  },
  {
    key: 'column2',
    name: 'Vehicle Model',
    fieldName: 'vehicleModel',
    typeField: 'text',
    isEditable: false,
    isMandatory: true,
  },
  {
    key: 'column3',
    name: 'Number of Wheels',
    fieldName: 'numberOfWheels',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'Number of Vehicle Units',
    fieldName: 'numberOfVehicleUnits',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column5',
    name: 'Monthly Vehicle Units',
    fieldName: 'monthlyVehicleUnits',
    typeField: 'text',
    isEditable: false,
  },
];

export const productsApiModules: any = {
  terms: {
    getProductsAPI: apiService.terms.getProducts,
    getProductCodesAPI: apiService.terms.getProductCodes,
    deleteProductsAPI: apiService.terms.deleteProducts,
    deleteAllProductsAPI: apiService.terms.deleteAllProducts,
    addProductAPI: apiService.terms.addProduct,
    updateProductAPI: apiService.terms.updateProduct,
  },
  quote: {
    getProductsAPI: apiService.quotesAPI.getProducts,
    getProductCodesAPI: apiService.quotesAPI.getProductCodes,
    deleteProductsAPI: apiService.quotesAPI.deleteProducts,
    deleteAllProductsAPI: apiService.quotesAPI.deleteAllProducts,
    addProductAPI: apiService.quotesAPI.addProduct,
    updateProductAPI: apiService.quotesAPI.updateProduct,
  },
  fleet: {
    getProductsAPI: apiService.customerAPI.getProducts,
    getProductCodesAPI: apiService.customerAPI.listProducts,
    deleteProductsAPI: apiService.customerAPI.deleteProducts,
    deleteAllProductsAPI: apiService.customerAPI.deleteAllProducts,
    addProductAPI: apiService.customerAPI.addProduct,
    updateProductAPI: apiService.customerAPI.updateProduct,
  },
};


export const fleetsTableHeadCells: HeadCell[] = [
  {
    key: 'column0',
    typeField: 'text',
    isEditable: false,
    name: 'Vehicle Make',
    fieldName: 'vehicleMake',
  },
  {
    key: 'column1',
    typeField: 'text',
    isEditable: false,
    name: 'Vehicle Model',
    fieldName: 'vehicleModel',
  },
  {
    key: 'column2',
    typeField: 'text',
    isEditable: false,
    name: 'Number of Wheels',
    fieldName: 'numberOfWheels',
  },
  {
    key: 'column3',
    typeField: 'text',
    isEditable: false,
    name: 'Number of Vehicle Units',
    fieldName: 'numberOfVehicleUnits',
  },
  {
    key: 'column4',
    typeField: 'text',
    isEditable: false,
    name: 'Monthly Vehicle Units',
    fieldName: 'monthlyVehicleUnits',
  },
  {
    key: 'column5',
    typeField: 'text',
    isEditable: false,
    name: 'Quote ID',
    fieldName: 'quoteId',
  },
  {
    key: 'column6',
    typeField: 'text',
    isEditable: false,
    name: 'Contract ID',
    fieldName: 'contractId',
  },
];