import { FunctionComponent, useEffect, useState } from 'react';
import { GBSErrorManagementProps, GBS_ORDER_DETAIL_FIELDS, IGBSError, gbsErrorTableHeadCells, gbsPageSizes, defaultSorting } from './consts';
import { TextField, Text, DefaultButton, PrimaryButton, DatePicker, MessageBarType, Dialog, DialogFooter, DialogType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import styles from './GBSErrorManagement.module.scss';
import { useField } from '../../../../hooks/useField';
import DataGridComponent from '../../../../shared/DataGridComponent';
import apiService from '../../../../api';
import { INotification } from '../../../../models/INotification';
import useNotifications from '../../../../hooks/useNotifications';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import { initalDataGridState } from '../../../../shared/DataGridComponent/utils';
import PrintingModal from '../../../PrintingModal/PrintingModal';
import classNames from 'classnames';
import { downloadFile, printingTypes } from '../../../PrintingModal/consts';
import moment from 'moment';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { auth_tireOrder_gbsErrorManagement } from '../../../../consts/programKeys';
import { shipToEMHandler } from '../../../../shared/TextFieldValidation';

const GBSErrorManagementTab: FunctionComponent<GBSErrorManagementProps> = () => {

  const [isCleared, setClearStatus] = useState<boolean>(false);
  const [isFirstLoading, setFirstLoading] = useState<boolean>(true);
  const [selectedError, setSelectedError] = useState<IGBSError | null>(null);
  const [gbsErrors, setGbsErrors] = useState<IGBSError[]>([]);
  const [billToInfo, setBillToInfo] = useState<string>();
  const [isLoading, { toggle: toggleLoading }] = useBoolean(false);
  const [pageLoading, { toggle: togglePageLoading }] = useBoolean(false);
  const [hasSelectedAnError, setHasSelectedAnError] = useState(false);
  const [dataGridState, setDataGridState] = useState({
    ...initalDataGridState,
    countOnPage: {
      key: gbsPageSizes[0],
      text: gbsPageSizes[0].toString(),
    },
    sortOrder: defaultSorting,
  });
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });
  const [totalErrors, setTotalErrors] = useState(0);
  const [chosenDate, setChosenDate] = useState<string>(new Date().toString());
  const { addNotification } = useNotifications();
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [isDialogVisible, { toggle: toggleConfirmation }] = useBoolean(false);
  const [isDisabled, setDisabled] = useState(false);
  const [origDelNoteNum, setOrigDelNoteNum] = useState<string>();
  const [origSAPNum, setOrigSAPNum] = useState<string>();

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_tireOrder_gbsErrorManagement);

  const orderDataFieldsGroup: any = {
    midasOrderNumberDataField: useField(GBS_ORDER_DETAIL_FIELDS.midasOrderNumberDataField),
    customerNumberDataField: useField(GBS_ORDER_DETAIL_FIELDS.customerNumberDataField),
    typeCodeDataField: useField(GBS_ORDER_DETAIL_FIELDS.typeCodeDataField),
    sapOrderNoDataField: useField(GBS_ORDER_DETAIL_FIELDS.sapOrderNoDataField),
    shipToNumberDataField: useField(GBS_ORDER_DETAIL_FIELDS.shipToNumberDataField),
    productCodeDataField: useField(GBS_ORDER_DETAIL_FIELDS.productCodeDataField),
    goodyearDeliveryNoteNumberDataField: useField(GBS_ORDER_DETAIL_FIELDS.goodyearDeliveryNoteNumberDataField),
    quantityDataField: useField(GBS_ORDER_DETAIL_FIELDS.quantityDataField),
    costDataField: useField(GBS_ORDER_DETAIL_FIELDS.costDataField),
    shipDateDataField: useField(GBS_ORDER_DETAIL_FIELDS.shipDateDataField),
    statusDataField: useField(GBS_ORDER_DETAIL_FIELDS.statusDataField),
    GBSErrorDateDataField: useField(GBS_ORDER_DETAIL_FIELDS.GBSErrorDateDataField),
  };

  const getFieldForm: any = (field: string) => orderDataFieldsGroup[field] || {};
  const getFieldFormValue = (field: string) => orderDataFieldsGroup[field].getValue() || null;
  const setFormValue = (fieldForm: string, value: any) => orderDataFieldsGroup[fieldForm]?.setValue(value);

  const onClearClicked = async () => {
    togglePageLoading();
    toggleLoading();
    setClearStatus(true);
    setFormValue('midasOrderNumberDataField', '');
    setFormValue('GBSErrorDateDataField', '');
    setFormValue('customerNumberDataField', '');
    setFormValue('typeCodeDataField', '');
    setFormValue('shipToNumberDataField', '');
    setFormValue('quantityDataField', '');
    setFormValue('sapOrderNoDataField', '');
    setFormValue('costDataField', '');
    setFormValue('shipDateDataField', '');
    setFormValue('productCodeDataField', '');
    setFormValue('goodyearDeliveryNoteNumberDataField', '');
    setFormValue('statusDataField', '');
    const payload = {
      datetime: null,
      pagination: {
        pageSize: gbsPageSizes[0].toString(),
        pageNumber: 1,
      },
      sortOrder: {
        column: 'sapOrderNo',
        order: 'asc',
      },
    };
    try {
      setSelectedError(null);
      setHasSelectedAnError(false);
      const { data }: any = await apiService.tireOrders.getGBSErrorList(payload);
      setGbsErrors(data.data);
      await getErrorList(payload);
      setNotification({ text: 'Successfully cleared error table', type: MessageBarType.success });
    } catch (e: any) {
      setNotification({ text: 'Error clearing GBS Error table', type: MessageBarType.error });
    }
    finally {
      setFormValue('GBSErrorDateDataField', null);
      toggleLoading();
      togglePageLoading();
    }
  };

  const onClearClickedUncheck = async () => {
    togglePageLoading();
    toggleLoading();
    setFormValue('midasOrderNumberDataField', '');
    setFormValue('customerNumberDataField', '');
    setFormValue('typeCodeDataField', '');
    setFormValue('shipToNumberDataField', '');
    setFormValue('quantityDataField', '');
    setFormValue('sapOrderNoDataField', '');
    setFormValue('costDataField', '');
    setFormValue('shipDateDataField', '');
    setFormValue('productCodeDataField', '');
    setFormValue('goodyearDeliveryNoteNumberDataField', '');
    setFormValue('statusDataField', '');
    toggleLoading();
    togglePageLoading();
  };

  const onAddClicked = async () => {
    toggleConfirmation();
    togglePageLoading();
    const payload = {
      typeCode: getFieldFormValue('typeCodeDataField'),
      qty: getFieldFormValue('quantityDataField'),
      productCode: getFieldFormValue('productCodeDataField'),
      gyDeliveryNoteNo: getFieldFormValue('goodyearDeliveryNoteNumberDataField'),
      shipToNo: getFieldFormValue('shipToNumberDataField'),
      billToNo: getFieldFormValue('customerNumberDataField'),
      shipDate: getFieldFormValue('shipDateDataField'),
      cost: getFieldFormValue('costDataField'),
      sapOrderNo: getFieldFormValue('sapOrderNoDataField'),
    };
    try {
      await apiService.tireOrders.addGBSError(payload);
      await getErrorList();
      setNotification({ text: 'Successfully added Error order information', type: MessageBarType.success });
      onClearClicked();
    } catch (e: any) {
      setNotification({ text: 'Error adding GBS Error', type: MessageBarType.error });
    }
    finally {
      togglePageLoading();
    }
  };

  const onUpdateClicked = async () => {
    togglePageLoading();
    const payload = {
      typeCode: getFieldFormValue('typeCodeDataField'),
      qty: getFieldFormValue('quantityDataField'),
      shipToNo: getFieldFormValue('shipToNumberDataField'),
      billToNo: getFieldFormValue('customerNumberDataField'),
      gyDeliveryNoteNo: getFieldFormValue('goodyearDeliveryNoteNumberDataField'),
      sapOrderNo: getFieldFormValue('sapOrderNoDataField'),
      srcGyDeliveryNoteNo: origDelNoteNum,
      srcSapOrderNo: origSAPNum,
      productCode: getFieldFormValue('productCodeDataField'),
    };
    try {
      await apiService.tireOrders.updateGBSError(payload);
      await getErrorList();
      setNotification({ text: 'Successfully updated Error order information', type: MessageBarType.success });
    } catch (e: any) {
      setNotification({ text: 'Error updating GBS Error', type: MessageBarType.error });
    }
    finally {
      togglePageLoading();
    }
  };

  const onDeleteClicked = async () => {
    togglePageLoading();
    const payload = {
      sapOrderNo: getFieldFormValue('sapOrderNoDataField'),
      gyDeliveryNoteNo: getFieldFormValue('goodyearDeliveryNoteNumberDataField'),
    };
    try {
      await apiService.tireOrders.deleteGBSError(payload);
      setFormValue('statusDataField', 'D');
      setNotification({ text: 'Successfully deleted Error order information', type: MessageBarType.success });
      await getErrorList();
    } catch (e: any) {
      setNotification({ text: 'Error deleting GBS Error', type: MessageBarType.error });
    }
    finally {
      togglePageLoading();
    }
  };

  const handleStateChange = async (dataGridState: any) => {
    setDataGridState(dataGridState);
  };

  const handleSelectRow = async (rowSelected: any[]) => {
    if (rowSelected.length == 0) {
      setSelectedError(null);
      setHasSelectedAnError(false);
      onClearClickedUncheck();
      setDisabled(false);
    }
    else {
      setHasSelectedAnError(true);
      const row = rowSelected[0];
      setFormValue('sapOrderNoDataField', row.sapOrderNo);
      setSelectedError(row);
      setDisabled(true);
      await getOrder(row.sapOrderNo);
    }
  };

  const getOrder = async (sapOrderNo: string) => {
    togglePageLoading();
    try {
      const { data } = await apiService.tireOrders.getGBSOrder({ sapOrderNo: sapOrderNo });;
      setFormValue('midasOrderNumberDataField', data.orderId);
      setFormValue('customerNumberDataField', data.customerId);
      setFormValue('typeCodeDataField', data.typeCode);
      setFormValue('shipToNumberDataField', data.shipToNo);
      setFormValue('productCodeDataField', data.productCode);
      setFormValue('goodyearDeliveryNoteNumberDataField', data.gyDeliveryNoteNo);
      setFormValue('quantityDataField', data.qty);
      setFormValue('costDataField', data.cost);
      const shipDate = new Date(data.shipDate).toISOString().slice(0, 10);
      setFormValue('shipDateDataField', shipDate);
      setFormValue('statusDataField', data.fstatus);
      setOrigDelNoteNum(data.gyDeliveryNoteNo);
      setOrigSAPNum(sapOrderNo);
      //setBillToInfo(data.billToNo);
    } catch (e: any) {
      const { response } = e;
      setNotification({ text: `Error fetching GBS Order: ${response?.data?.message}`, type: MessageBarType.error });
    }

    togglePageLoading();
  };

  const getErrorList = async (emptyPayload?: any) => {
    togglePageLoading();
    toggleLoading();
    try {
      let payload: {
        datetime?: any;
        pagination: {
            pageSize: string;
            pageNumber: number;
        };
        sortOrder: {
            column: string;
            order: any;
        };
    };

      if (!isCleared && !getFieldFormValue('GBSErrorDateDataField')) setFormValue('GBSErrorDateDataField', new Date());

      if (typeof emptyPayload !== 'undefined') {
        payload = emptyPayload;
      } else { 
        const GBSErrorDateDataField = getFieldFormValue('GBSErrorDateDataField') ? moment(getFieldFormValue('GBSErrorDateDataField')).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        
        payload = {
          datetime: GBSErrorDateDataField,
          pagination: {
            pageSize: dataGridState.countOnPage.text,
            pageNumber: dataGridState.paginationProps.current,
          },
          sortOrder: dataGridState.sortOrder,
        };
        if (isCleared) {
          payload.datetime = null;
        } 
      }
      const { data }: any = await apiService.tireOrders.getGBSErrorList(payload);
      setTotalErrors(data.total.all);
      setGbsErrors(data.data);
      setFirstLoading(false);
    } catch (e: any) {
      setNotification({ text: 'Error fetching GBS Error list', type: MessageBarType.error });
    }
    finally {
      toggleLoading();
      togglePageLoading();
    }
  };

  const handlePrint = async (printingType: any) => {
    toggleShowPrintExport();
    try {
      togglePageLoading();
      const input = {
        datetime: getFieldFormValue('GBSErrorDateDataField') || null,
        sortOrder: {
          column: dataGridState.sortOrder?.column,
          order: dataGridState.sortOrder?.order,
        },
      };

      const requestData = {
        input,
        headerFields: [],
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.tireOrders.printExcelGBS(requestData) :
        await apiService.tireOrders.printPdfGBS(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      addNotification({
        text: 'Printing Error',
        type: MessageBarType.error,
      });
    }
    finally {
      togglePageLoading();
    }
  };

  const onDateChanged = (newDate: any | null | undefined) => {
    setClearStatus(false);
    setFormValue('shipDateDataField', new Date(1900, 1, 1));
    setFormValue('GBSErrorDateDataField', new Date(1900, 1, 1));
    setFormValue('shipDateDataField', newDate);
    setFormValue('GBSErrorDateDataField', newDate); 
    setChosenDate(newDate);
  };

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  useEffect(() => {
    if (!isFirstLoading)
      getErrorList();
  }, [
    dataGridState,
  ]);

  useEffect(() => {
    getErrorList();
  }, [
    chosenDate,
  ]);

  // form field validation
  const customerEMHandler = (field: string, value: string) => {
    if (value && !value.match('^\\d{10}$')) {
      return `${field} number must be 10 numbers`;
    }
  };

  const orderEMHandler = (field: string, value: string) => {
    if (value && !value.match('^[a-zA-Z0-9]{10}$')) {
      return `${field} Number must be 10 alphanumeric numbers`;
    }
  };
  
  const midasOrderEMHandler = (field: string, value: string) => {
    if (value && !value.match('^[a-zA-Z0-9]$') && value.length > 10) {
      return `${field} Number is limited to 10 alphanumeric characters`;
    }
  };

  const typeEMHandler = (value: string) => {
    if (value && !value.match('[a-zA-Z0-9 ]{4}$')) {
      return 'Type code must be 4-5 alphanumeric numbers';
    }
  };

  const productEMHandler = (value: string) => {
    if (value && !value.match('^[a-zA-Z0-9]{14}$')) {
      return 'Product number must be 14 alphanumeric numbers';
    }
  };

  const costEMHandler = (value: string) => {
    if (value && Number(value) < 0) {
      return 'Cost should be a positive number';
    }
  };

  const quantityEMHandler = (value: string) => {
    const num = Number(value);

    if (value && !Number.isInteger(num)) {
      if (!Number.isInteger(num)) {
        return 'Quantity should be a positive whole number';
      }
      if (num < 0 || String(num) !== value) {
        return 'Quantity should be positive';
      }
    }

  };

  const isFieldEmpty: boolean = !getFieldFormValue('typeCodeDataField')
    || !getFieldFormValue('shipToNumberDataField')
    || !getFieldFormValue('customerNumberDataField')
    || !getFieldFormValue('productCodeDataField')
    || !getFieldFormValue('goodyearDeliveryNoteNumberDataField')
    || !getFieldFormValue('sapOrderNoDataField')
    || !getFieldFormValue('midasOrderNumberDataField')
    || !getFieldFormValue('costDataField')
    || !getFieldFormValue('quantityDataField')
    || !getFieldFormValue('shipDateDataField');

  // if one of the handlers return true or a field is still empty, we have an invalid input
  const invalidInput: boolean = !!midasOrderEMHandler('Midas', getFieldFormValue('midasOrderNumberDataField'))
    || !!orderEMHandler('SAP', getFieldFormValue('sapOrderNoDataField'))
    || !!orderEMHandler('GY', getFieldFormValue('goodyearDeliveryNoteNumberDataField'))
    || !!productEMHandler(getFieldFormValue('productCodeDataField'))
    || !!customerEMHandler('customer', getFieldFormValue('customerNumberDataField'))
    || !!shipToEMHandler(getFieldFormValue('shipToNumberDataField'))
    || !!typeEMHandler(getFieldFormValue('typeCodeDataField'))
    || !!quantityEMHandler(getFieldFormValue('quantityDataField'))
    || !!costEMHandler(getFieldFormValue('costDataField'))
    || isFieldEmpty;

  const isUpdDisabled = !(userPermissions.isWrite && hasSelectedAnError);

  return (
    <>
      <SeparatorGy />
      <Text variant='xLarge' className={styles.highlight}>GBS Order Error Management</Text>
      <SeparatorGy />
      <div className='ms-Grid'>
        <div className='ms-Grid-row'>
          <div className={styles.orderDetailsWrapper}>
            <div className='ms-Grid-col ms-sm10'>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm10">
                  <Text variant='xLarge' className={styles.highlight}>Order Details ({moment(chosenDate).format('MM/DD/YYYY')} GBS Errors)</Text>
                  <SeparatorGy />
                </div>
                <div className="ms-Grid-col ms-sm2">
                  <DatePicker
                    {...getFieldForm('GBSErrorDateDataField')}
                    showMonthPickerAsOverlay={true}
                    placeholder=""
                    ariaLabel="shipDate"
                    onSelectDate={(addedDateFrom: Date | null | undefined) => onDateChanged(addedDateFrom)}
                  />
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm10">
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm3">
                      {/* invalid and errorMessgae props onGetErrorMessage */}
                      <TextField {...getFieldForm('midasOrderNumberDataField')} disabled={!userPermissions.isWrite || isDisabled} onGetErrorMessage={(value) => midasOrderEMHandler('Midas Order', value)} />
                    </div>
                    <div className="ms-Grid-col ms-sm3">
                      <TextField {...getFieldForm('customerNumberDataField')} disabled={!userPermissions.isWrite} onGetErrorMessage={(value) => customerEMHandler('Customer', value)} />
                    </div>
                    <div className="ms-Grid-col ms-sm3">
                      <TextField {...getFieldForm('typeCodeDataField')} disabled={!userPermissions.isWrite} onGetErrorMessage={typeEMHandler} />
                    </div>
                    <div className="ms-Grid-col ms-sm3">
                      <TextField {...getFieldForm('sapOrderNoDataField')} disabled={!userPermissions.isWrite} onGetErrorMessage={(value) => orderEMHandler('SAP Order', value)} />
                    </div>
                  </div>
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm4">
                      <TextField {...getFieldForm('shipToNumberDataField')} disabled={!userPermissions.isWrite} onGetErrorMessage={shipToEMHandler} />
                    </div>
                    <div className="ms-Grid-col ms-sm4">
                      <TextField {...getFieldForm('productCodeDataField')} disabled={!userPermissions.isWrite} onGetErrorMessage={productEMHandler} />
                    </div>
                    <div className="ms-Grid-col ms-sm4">
                      <TextField {...getFieldForm('goodyearDeliveryNoteNumberDataField')} disabled={!userPermissions.isWrite} onGetErrorMessage={(value) => orderEMHandler('Goodyear Delivery Note', value)} />
                    </div>
                  </div>
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm3">
                      <TextField {...getFieldForm('quantityDataField')} disabled={!userPermissions.isWrite} onGetErrorMessage={quantityEMHandler} />
                    </div>
                    <div className="ms-Grid-col ms-sm3">
                      <TextField {...getFieldForm('costDataField')} disabled={!userPermissions.isWrite || isDisabled} onGetErrorMessage={costEMHandler} />
                    </div>
                    <div className="ms-Grid-col ms-sm3">
                      <TextField {...getFieldForm('shipDateDataField')} disabled={!userPermissions.isWrite || isDisabled} />
                    </div>
                    <div className="ms-Grid-col ms-sm3">
                      <TextField {...getFieldForm('statusDataField')} disabled={true} />
                    </div>
                  </div>
                </div>
                <div className="ms-Grid-col ms-sm2">
                  <div className={styles.buttonWrapper}>
                    <PrimaryButton text='Clear' id='clearBtn' onClick={onClearClicked} disabled={!userPermissions.isWrite} />
                    <PrimaryButton text='Add' id='addBtn' onClick={() => { toggleConfirmation(); }} disabled={invalidInput || !userPermissions.isWrite} />
                    <PrimaryButton text='Update' id='updateBtn' onClick={onUpdateClicked} disabled={isUpdDisabled} />
                    <PrimaryButton text='Delete' id='deleteBtn' onClick={onDeleteClicked} disabled={!(selectedError && userPermissions.isWrite)} />
                  </div>
                </div>
                <Dialog
                  hidden={!isDialogVisible}
                  onDismiss={toggleConfirmation}
                  dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Confirmation',
                    subText: 'Are you sure you want to add the error order information?',
                  }}
                  modalProps={{ isBlocking: true }}
                  maxWidth={500}
                >
                  <DialogFooter>
                    <PrimaryButton onClick={onAddClicked} text="Add" />
                    <DefaultButton onClick={() => { toggleConfirmation(); }} text="Close" />
                  </DialogFooter>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <DataGridComponent
              idTable={'gbs-table'}
              title='GBS Errors'
              totalDataFound={totalErrors}
              headCells={gbsErrorTableHeadCells}
              rowsTable={gbsErrors}
              defaultRowsPerPage={gbsPageSizes}
              enableSearching={false}
              enableCheckBox={true}
              enableAutoControls={false}
              enableMultiSelectRow={false}
              enableDeleteOption={false}
              enablePagination={true}
              enableRowsPerPage={true}
              enableEditableRows={false}
              handleChangeDataGridState={handleStateChange}
              handleSelectRow={handleSelectRow}
              isLoading={isLoading}
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.printWrapper)}>
            <DefaultButton onClick={toggleShowPrintExport} text="Print/Export" />
          </div>
        </div>
        <PrintingModal
          isOpened={showPrintExport}
          onClose={toggleShowPrintExport}
          onPrint={handlePrint}
        />
      </div>
      {pageLoading && <LoadingScreen />}
    </>
  );
};

export default GBSErrorManagementTab;
