import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { differenceWith, get, isEmpty, isEqual, isNil } from 'lodash';
import {
  PrimaryButton,
  Checkbox,
  ChoiceGroup,
  IChoiceGroupOption,
  TextField,
  DatePicker,
  DayOfWeek,
  Dropdown,
  IconButton,
  Text,
  IDropdownOption,
  IColumn,
  Dialog,
  DialogType,
  DialogFooter,
  DefaultButton,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { MessageBarType } from '@fluentui/react';

import apiService from '../../../../../api';
import useNotifications from '../../../../../hooks/useNotifications';
import { HeadCell } from '../../../../../shared/DataGridComponent/DataGridModels';
import { useUserPermissions } from '../../../../../hooks/useUserPermissions';
import { auth_terms_rateAdjustment } from '../../../../../consts/programKeys';
import { sortOrder } from '../../../../../consts/sortOrder';
import { numberEMHandler } from '../../../../../shared/TextFieldValidation';

import LoadingScreen from '../../../../LoadingScreen/LoadingScreen';
import PrintingModal from '../../../../PrintingModal/PrintingModal';
import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../Pagination/Pagination';
import { options, RateAdjustmentsHeadCells, pageSizes } from './const';
import { printingTypes, downloadFile } from '../../../../PrintingModal/consts';
import { IPaginationProps } from '../../../../Pagination/IPaginationProps';

import { IRateAdjustmentsState } from './IRateAdjustmentsState';

import styles from './RateAdjustment.module.scss';
import { transformDate } from '../../../../../shared/transformDate';


const RateAdjustments: React.FC = () => {

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };


  const [isLoading, setIsLoading] = useState(false);
  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_terms_rateAdjustment);
  const [option, setOption] = useState<string | undefined>('fixedRate');
  const [percentage, setPercentage] = useState('');
  const [initialRateFor, setInitialRateFor] = useState('');
  const [adjustEvery, setAdjustEvery] = useState('');
  const [overrideUpdatingTreadDepthPriceTable, setOverrideUpdatingTreadDepthPriceTable] = useState(false);
  const [rateLetterDue, setRateLetterDue] = useState<string | undefined | null>();
  const [parsedErrors, setParsedErrors] = useState<any>([]);

  const { contractId, id } = useParams<{ contractId: string, id: string }>();
  const { addNotification } = useNotifications();
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [state, setState] = useState<IRateAdjustmentsState>({
    origItems: [],
    modItems: [],
    selectedItem: null,
    searchString: '',
    foundCount: 0,
    loading: false,
  });
  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: IPaginationProps) => ({ ...prev, current: newPage })),
  });
  const [columnsState, setColumnsState] = useState<Array<any>>([]);
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [showDeletingConfirmation, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [isFirstLoading, { setFalse: wasFirstLoading }] = useBoolean(true);


  const handleChangeSearchString = (event: any, searchString = '') => {
    setState((prev: IRateAdjustmentsState) => ({ ...prev, searchString }));
  };

  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: IPaginationProps) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const handleSelect = (e: any, itemId: any) => {
    setState((prev: IRateAdjustmentsState) => ({ ...prev, selectedItem: e.target.checked ? itemId : null }));
  };

  const handleSearch = () => {
    setPaginationProps((prev: IPaginationProps) => ({ ...prev, current: 1 }));
    fetchRateAdjustments();
  };

  const fetchRateAdjustmentsConfiguration = async () => {
    setIsLoading(true);
    try {
      const { data }: any = await apiService.terms.getRateAdjustmentsDetails(contractId);
      const { percentage, initialRateApplicableFor, adjustRateEvery, rateLetterDue, variableOrFixedRate, overrideUpdatingTreadDepthPriceTable } = data;
      
      setPercentage(percentage ?? '');
      setInitialRateFor(initialRateApplicableFor ?? '');
      setAdjustEvery(adjustRateEvery ?? '');
      setRateLetterDue(isNil(rateLetterDue) ? undefined : moment(rateLetterDue).format('MM/DD/YYYY'));
      setOption(variableOrFixedRate ? 'fixedRate' : 'variableRate');
      setOverrideUpdatingTreadDepthPriceTable(overrideUpdatingTreadDepthPriceTable);
      
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Rate adjustments details fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRateGroups = async () => {
    setIsLoading(true);
    try {
      const { data: { data } }: any = await apiService.terms.getRateGroups(null, null, '', id, contractId, '', '');

      const columns: HeadCell[] = data.map((rateGroup: any) => ({
        key: `column${rateGroup.id}`,
        name: rateGroup.rategroupName,
        fieldName: rateGroup.rategroupName,
        typeField: 'text',
        isEditable: false,
      }));

      setColumnsState([...RateAdjustmentsHeadCells, ...columns]);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Rate Groups fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(true);
    }
  };

  const fetchUpdateDefaultRateConfig = async () => {
    setIsLoading(true);
    try {

      const payload = {
        contractId,
        variableOrFixedRate: option == 'fixedRate',
        percentage: +percentage,
        initialRateApplicableFor: +initialRateFor,
        adjustRateEvery: +adjustEvery,
        rateLetterDue,
        overrideUpdatingTreadDepthPriceTable,
      };
      const { data: { message } }: any = await apiService.terms.updateDefaultRatesConfig(payload);
      addNotification({
        text: message,
        type: MessageBarType.success,
      });

    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Rate Groups updating error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isRateAvailable = (item: any, rateName: string) => {
    const itemRates = get(item, 'rates', []);
    const rate = itemRates.find(({ name }: any) => name === rateName);
    return !isNil(rate);
  };

  const getRateValue = (item: any, rateName: string) => {
    const itemRates = get(item, 'rates', []);
    const rate = itemRates.find(({ name }: any) => name === rateName);
    return get(rate, 'value', '');
  };

  const setRateValue = (itemId: any, rateName: string, newRateValue: any) => {
    const modifiedItemIndex = state.modItems.findIndex(({ id }: any) => id === itemId);
    const rates = state.modItems[modifiedItemIndex].rates.map(({ name, value, ...rest }: any) => ({
      ...rest,
      name,
      value: name === rateName ? newRateValue : value,
    }));
    setState((prev: IRateAdjustmentsState) => ({
      ...prev,
      modItems: [
        ...prev.modItems.slice(0, modifiedItemIndex),
        {
          ...prev.modItems[modifiedItemIndex],
          rates,
        },
        ...prev.modItems.slice(modifiedItemIndex + 1),
      ],
    }));
  };

  const setnewInterval = (itemId: any, newIntervalValue: any) => {
    setState((prev: IRateAdjustmentsState) => ({
      ...prev,
      modItems: prev.modItems.map(({ id, interval, ...rest }: any) => ({
        ...rest,
        id,
        interval: id === itemId ? newIntervalValue : interval,
      })),
    }));
  };

  const getSortOrder = () => {
    const column = columnsState.find(({ isSorted }) => isSorted);
    if (isNil(column))
      return {
        column: RateAdjustmentsHeadCells[1].fieldName,
        order: sortOrder.DESC,
      };
    const { fieldName, isSortedDescending } = column;
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const fetchRateAdjustments = async () => {
    setIsLoading(true);
    try {
      const sortOrder = getSortOrder();
      const response = await apiService.terms.getRateAdjustment(null, sortOrder, state.searchString, contractId, id);
      const tmpArray = get(response, 'data.data', []).map((item: any, index: number) => ({ ...item, id: index }));
      setState((prev: IRateAdjustmentsState) => ({
        ...prev,
        origItems: tmpArray,
        modItems: tmpArray,
        foundCount: response?.data?.total?.all,
        selectedItem: null,
      }));
      setParsedErrors([]);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Rate Adjustment fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const { id, ...itemToBeRemoved } = state.origItems[state.selectedItem];

      await apiService.terms.deleteRateAdjustment(contractId, itemToBeRemoved);
      addNotification({
        text: 'Rate Adjustment(s) was(were) deleted successfully',
        type: MessageBarType.success,
      });
      setParsedErrors([]);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Rate Adjustment(s) deleting error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      toggleDeletingConfirmation();
      await fetchRateAdjustments();
    }
  };

  const handleAddInterval = async () => {
    setIsLoading(true);
    try {
      await apiService.terms.addRateAdjustment({
        contractId,
        variableOrFixedRate: option === 'fixedRate',
        percentage,
        initialRateApplicableFor: initialRateFor,
        adjustRateEvery: adjustEvery,
        rateLetterDue: rateLetterDue,
        overrideUpdatingTreadDepthPriceTable,
      });
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Rate Adjustment Add error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      await fetchRateAdjustments();
      await fetchRateAdjustmentsConfiguration();
    }
  };

  const handleUpdateDefaultConfig = async () => {
    await fetchUpdateDefaultRateConfig();
    await fetchRateAdjustmentsConfiguration();
  };

  const handleRateChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, opt?: IChoiceGroupOption): void => {
    setOption(opt?.key);
  };

  const handlePrint = async (printingType: any) => {
    setIsLoading(true);
    toggleShowPrintExport();
    try {
      const requestData = {
        contractId,
        customerId: id,
        pagination: { pageNumber: paginationProps.current, pageSize: paginationProps.total },
        filterText: '',
      };
      const headerFields = [
        { title: 'customerId', value: id },
        { title: 'contractId', value: contractId },
      ];
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.terms.rateAdjustmentPrintExcel(requestData, headerFields) :
        await apiService.terms.rateAdjustmentPrintPdf(requestData, headerFields);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePercentageChange = (event: any) => {
    const value = event.target.value;
    setPercentage(value);
  };

  const handleInitialRateChange = (event: any) => {
    const value = event.target.value;
    setInitialRateFor(value);
  };

  const handleAdjustEveryChange = (event: any) => {
    const value = event.target.value;
    setAdjustEvery(value);
  };

  const handleUpdateRateAdjustment = async () => {
    setIsLoading(true);
    try {
      await apiService.terms.updateRates(
        contractId,
        state.modItems.map(({ id, ...rest }) => rest),
      );
      addNotification({
        text: 'Rate(s) was(were) updated successfully',
        type: MessageBarType.success,
      });
    } catch (error: any) {
      const message = get(error, 'response.data.message');
      const state = get(error, 'response.data.state');

      console.log('state');
      console.log(state);

      addNotification({
        text: `Rates updating error: ${message}`,
        type: MessageBarType.error,
      });
      setParsedErrors(state);
      setIsLoading(false);
    } finally {
      await fetchRateAdjustments();
      await fetchRateAdjustmentsConfiguration();
    }
  };

  const parseErrors = (index: number, field: string, subIndex: any, subField: any) => {
    const customError = parsedErrors?.find(({ field: errorField }: any) => {
      return errorField == `rateAdjustments[${index}].${field}${subIndex ? `[${subIndex}].${subField}` : ''}`;
    });
    if (customError) {
      return customError.message;
    }
  };

  const mountingMethod = async () => {
    await fetchRateAdjustmentsConfiguration();
    await fetchRateGroups();
    await fetchRateAdjustments();
    wasFirstLoading();
  };

  useEffect(() => {
    if (!isFirstLoading)
      fetchRateAdjustments();
  }, [
    countOnPage,
    paginationProps.current,
    columnsState,
  ]);

  useEffect(() => {
    if (isFirstLoading)
      mountingMethod();
  }, []);

  const shouldDisable = option === 'variableRate';

  return (
    <div className={styles.rateAdjustmentContainer}>
      <div className={styles.flexReverse}>
        <PrimaryButton
          id='updateBtn'
          onClick={toggleShowPrintExport}
          text="Print/Export"
        />
      </div>
      <div className={classNames(styles.flexSpaceBetween, styles.marginButtonSmall)}>
        <ChoiceGroup
          selectedKey={option}
          options={options}
          onChange={handleRateChange}
          label="Pick one"
        />
        <TextField
          disabled={shouldDisable}
          value={percentage}
          onChange={handlePercentageChange}
          onGetErrorMessage={numberEMHandler}
          label="Percentage"
          suffix="%"
        />
        <TextField
          disabled={shouldDisable}
          value={initialRateFor}
          onChange={handleInitialRateChange}
          onGetErrorMessage={numberEMHandler}
          label="Initial Rate Applicable for"
          suffix="months"
        />
        <TextField
          disabled={shouldDisable}
          value={adjustEvery}
          onChange={handleAdjustEveryChange}
          onGetErrorMessage={numberEMHandler}
          label="Adjust Rate Every"
          suffix="months"
        />
        <DatePicker
          value={moment(rateLetterDue).toDate()}
          label="Rate Letter Due"
          firstDayOfWeek={DayOfWeek.Sunday}
          placeholder="Rate Letter Due"
          onSelectDate={(data) => {
            setRateLetterDue(moment(data).format('MM/DD/YYYY') || '');
          }}
          formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
        />
        <PrimaryButton
          className={styles.updButton}
          text='Update default config'
          onClick={handleUpdateDefaultConfig}
        />
      </div>
      <div className={classNames(styles.marginRow)}>
        <Text variant='xLarge'>Rate Adjustments&nbsp;</Text>
        <SeparatorGy vertical />
        <Text variant="xLarge">&nbsp;{state?.foundCount} found</Text>
      </div>
      <div>
        <div className={classNames(styles.tableHeading)}>
          <div>
            <TextField
              id="searchString"
              value={state?.searchString}
              placeholder="Enter search string"
              onChange={handleChangeSearchString}
            />
            <IconButton
              id="searchShipTo"
              iconProps={{ iconName: 'Search' }}
              onClick={handleSearch}
            />
            <SeparatorGy vertical />
            <Text variant="large">Show # of rows:&nbsp;</Text>
            <Dropdown
              options={pageSizes.map(pageSize => ({
                key: pageSize,
                text: pageSize.toString(),
              }))}
              selectedKey={countOnPage?.key}
              onChange={onChangeCountOnPage}
            />
          </div>
          <div>
            <IconButton
              disabled={isNil(state.selectedItem)}
              iconProps={{ iconName: 'Delete' }}
              onClick={toggleDeletingConfirmation}
            />
          </div>
        </div>
      </div>
      <div className={styles.tableWrapper}>
        <table>
          <thead>
            <tr>
              {columnsState.map((column, columnIndex) => (
                <th
                  key={column.name}
                  className={classNames(
                    column.isSorted && column.isSortedDescending ?
                      styles.descending : column.isSorted && !column.isSortedDescending ? styles.ascending : undefined,
                    styles[column.key],
                  )}
                  onClick={() => columnIndex < 3 && onColumnClick(column)}
                >
                  {column.name}
                </th>
              ))}
              <th />
            </tr>
          </thead>
          <tbody>
            {state.modItems.map((item: any, index: number) => (
              <tr
                key={item.id}
                className={state.selectedItem === item.id ? styles.trSelected : styles.trBasic}
              >
                <td>
                  <TextField
                    value={get(item, 'interval', '')}
                    onChange={(ev, newValue) => setnewInterval(item.id, newValue)}
                    errorMessage={parseErrors(index, 'interval', null, null)}
                    type="number"
                  />
                </td>
                <td>{moment(item.effFrom).format('MM/DD/YYYY')}</td>
                <td>{moment(item.effTo).format('MM/DD/YYYY')}</td>
                {columnsState.slice(3).map(({ name }, subindex) =>
                  <td>
                    {isRateAvailable(item, name) &&
                      <TextField
                        value={getRateValue(item, name)}
                        onChange={(ev, newValue) => setRateValue(item.id, name, newValue)}
                        errorMessage={parseErrors(index, 'rates', subindex, 'value')}
                      />}

                  </td>)}
                <td>
                  <div className={styles.round}>
                    <input
                      type="checkbox"
                      id={`rateAdjustment-listing-${item.id}`}
                      checked={state.selectedItem === item.id}
                      onChange={(e) => handleSelect(e, item.id)} />
                    <label htmlFor={`rateAdjustment-listing-${item.id}`} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {state.foundCount > countOnPage?.key && (
        <>
          <SeparatorGy />
          <Pagination {...paginationProps} />
        </>
      )}
      <SeparatorGy />
      <div className={classNames(styles.flexReverse, styles.topMargin)}>
        <Checkbox
          label="Update Tread Depth Price Table Also"
          checked={overrideUpdatingTreadDepthPriceTable}
          onChange={() => setOverrideUpdatingTreadDepthPriceTable(!overrideUpdatingTreadDepthPriceTable)}
        />
      </div>
      <div className={classNames(styles.actionButtons)}>
        <PrimaryButton
          id='addBtn'
          onClick={handleAddInterval}
          text="Add Interval"
          disabled={!userPermissions.isWrite}
        />
        <PrimaryButton
          id='updateBtn'
          onClick={handleUpdateRateAdjustment}
          text="Update Interval(s)"
          disabled={isEmpty(differenceWith(state.origItems, state.modItems, isEqual))}
        />
      </div>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      {isLoading && <LoadingScreen />}
      <Dialog
        hidden={!showDeletingConfirmation}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: 'Are you sure you want to delete selected item?',
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </div >
  );
};

export default RateAdjustments;
