import { HeadCell } from '../DataGridComponent/DataGridModels';

export const vehicleTiressHeadCells: Array<HeadCell> = [
  {
    key: 'column1',
    name: 'VNo',
    fieldName: 'vNo',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'WPos',
    fieldName: 'wPos',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Prefix',
    fieldName: 'pfx',
    typeField: 'text',
  },
  {
    key: 'column4',
    name: 'Brand',
    fieldName: 'brand',
    typeField: 'text',
  },
  {
    key: 'column5',
    name: 'Suffix',
    fieldName: 'sfx',
    typeField: 'text',
  },
  {
    key: 'column6',
    name: 'Change Date',
    fieldName: 'chgDate',
    typeField: 'text',
  },
  {
    key: 'column7',
    name: 'Total Miles',
    fieldName: 'totalMiles',
    typeField: 'text',
  },
];

export const stockTiresHeadCells: Array<HeadCell> = [
  {
    key: 'column1',
    name: 'Prefix',
    fieldName: 'prefix',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Brand',
    fieldName: 'brandNo',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Suffix',
    fieldName: 'suffix',
    typeField: 'text',
  },
  {
    key: 'column4',
    name: 'status',
    fieldName: 'status',
    typeField: 'text',
  },
  {
    key: 'column5',
    name: 'Change Date',
    fieldName: 'chgDate',
    typeField: 'text',
  },
  {
    key: 'column6',
    name: 'Total Miles',
    fieldName: 'totalMiles',
    typeField: 'text',
  },
];