import { HeadCell } from './../../../../../../shared/DataGridComponent/DataGridModels';
export const pageSizes = [100, 250, 500];

export const columns: HeadCell[] = [
  {
    key: 'column1',
    name: 'Prefix',
    fieldName: 'intireBrndPfx',
    typeField: 'text',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Brand #',
    fieldName: 'intireBrndNum',
    typeField: 'text',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'Suffix',
    fieldName: 'intireBrndSfx',
    typeField: 'text',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'DOT #',
    fieldName: 'dotNum',
    typeField: 'text',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'Type Code',
    fieldName: 'typeCode',
    typeField: 'text',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column6',
    name: 'Del Note #',
    fieldName: 'delNoteNo',
    typeField: 'text',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column7',
    name: 'Invoice',
    fieldName: 'invoice',
    typeField: 'text',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column8',
    name: 'Tire Class',
    fieldName: 'tc',
    typeField: 'text',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column9',
    name: 'Lost Date',
    fieldName: 'lostDate',
    typeField: 'text',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column10',
    name: 'Short',
    fieldName: 'short',
    typeField: 'text',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column11',
    name: 'Order Status',
    fieldName: 'orderStatus',
    typeField: 'text',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column12',
    name: 'Short Doc',
    fieldName: 'shortDoc',
    typeField: 'text',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column13',
    name: 'Recv Date',
    fieldName: 'recvDate',
    typeField: 'text',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column14',
    name: 'Xfer Qty',
    fieldName: 'xferQty',
    typeField: 'text',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column15',
    name: 'Recv Qty',
    fieldName: 'recvQty',
    typeField: 'text',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column16',
    name: 'Short Qty',
    fieldName: 'shortQty',
    typeField: 'text',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column17',
    name: 'Whse Qty',
    fieldName: 'whseQty',
    typeField: 'text',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column18',
    name: 'Last Modified By',
    fieldName: 'lastModifiedBy',
    typeField: 'text',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column19',
    name: 'Last Modified On',
    fieldName: 'lastModifiedOn',
    typeField: 'text',
    isSorted: false,
    isSortedDescending: false,
  },
];