export const mockPostByFootprint = {
  data: [
    {
      brand: 3333,
      changeDate: '08/24/2021',
      cot: false,
      dateOn: '08/24/2021',
      id: '123',
      onBrand: 3333,
      onCot: true,
      onPrefix: 'A',
      onSuffix: null,
      position: 'RF',
      prefix: 'A',
      submitted: true,
      suffix: null,
    },
    {
      brand: 2222,
      changeDate: '08/24/2021',
      cot: true,
      dateOn: '08/24/2021',
      id: '124',
      onBrand: 2222,
      onCot: false,
      onPrefix: 'A',
      onSuffix: null,
      position: 'RF',
      prefix: 'A',
      submitted: false,
      suffix: null,
    },
  ],
  total: {
    found: 2,
    total: 3,
  },
  vehicle: {model: 'MINIBS', make: 'ELDORADO'},
};

export const columns = [
  {
    key: 'column0',
    name: 'Position',
    fieldName: 'position',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'Prefix',
    fieldName: 'prefix',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Brand',
    fieldName: 'brand',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'Suffix',
    fieldName: 'suffix',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'OnCot',
    fieldName: 'onCot',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'DateOn',
    fieldName: 'dateOn',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column6',
    name: 'OnPrefix',
    fieldName: 'onPrefix',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column7',
    name: 'OnBrand',
    fieldName: 'onBrand',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column8',
    name: 'OnSuffix',
    fieldName: 'onSuffix',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column9',
    name: 'COT',
    fieldName: 'cot',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column10',
    name: 'Change Date',
    fieldName: 'changeDate',
    isSorted: false,
    isSortedDescending: false,
  },
];

export const emptyMakeModel = {make: '???', model: '???'};