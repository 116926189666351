import {
  DefaultButton, IconButton, MessageBarType,
  Modal,
  PrimaryButton,
  Text,
  Toggle,
} from '@fluentui/react';
import classNames from 'classnames';
import { get, isEmpty, isNil } from 'lodash';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import apiService from '../../../../api';
import useNotifications from '../../../../hooks/useNotifications';
import DialogComponent from '../../../../shared/DialogComponent';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import { statuseClassNameMapping } from '../../consts';
import { frequencyMapping } from './consts';
import FrequencyComponent from './FrequencyComponent';
import { ISchedulingDetailsModalProps } from './ISchedulingDetailsModalProps';
import { ISchedulingDetailsModalState } from './ISchedulingDetailsModalState';
import styles from './SchedulingDetailsModal.module.scss';

const SchedulingDetailsModal: FC<ISchedulingDetailsModalProps> = ({ isModalOpen, hideModal, taskInfo }) => {

  const { addNotification } = useNotifications();

  const [state, setState] = useState<ISchedulingDetailsModalState>({
    loading: false,
    taskData: null,
    dropdownOptions: [],
    overrideData: null,
    recipientsData: null,
  });

  const fetch = async () => {
    setState((prev: ISchedulingDetailsModalState) => ({ ...prev, loading: true }));
    try {
      const { data: { jobName, startTime, excludeDates, onceParameters, overrideProcessStart, processStart, ...other } }: any = await apiService.taskScheduler.getSchedulingConfiguration(taskInfo.mCdSchedid);

      const taskData = {
        jobName,
        excludeDates,
        startTime: moment(startTime).format('hh:mm a'),
        startDate: new Date(startTime),
        onceParameters: onceParameters ? { ...onceParameters, runOn: new Date(get(onceParameters, 'runOn')) } : onceParameters,
        ...other,
      };

      const overrideData = {
        overrideProcessStart: overrideProcessStart, // Fetch Status from API
        processStart: { // Fetch Data from API
          processDate: processStart ? moment(processStart).toDate() : new Date(),
          processTime: processStart ? moment(processStart).format('hh:mm a') : moment().format('hh:mm a'),
          processDateTime: processStart, // Build using date and time
        },
      };

      setState((prev: ISchedulingDetailsModalState) => ({
        ...prev,
        taskData,
        overrideData,
      }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Scheduler configuration fetching  error: ${get(response, 'data.message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: ISchedulingDetailsModalState) => ({ ...prev, loading: false }));
    }
  };

  const fetchRecipients = async () => {
    setState((prev: ISchedulingDetailsModalState) => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.taskScheduler.getRecipients(null, null, taskInfo.mCdSchedid);
      setState((prev: any) => ({ ...prev, recipientsData: data.data }));

    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Scheduler recipient fetching  error: ${get(response, 'data.message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: ISchedulingDetailsModalState) => ({ ...prev, loading: false }));
    }
  };


  const fetchSelectOptions = async () => {
    setState((prev: ISchedulingDetailsModalState) => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.taskScheduler.getSelections();
      setState((prev: any) => ({ ...prev, dropdownOptions: data }));
    } catch (e: any) {
      addNotification({
        text: `Options for selecting fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: ISchedulingDetailsModalState) => ({ ...prev, loading: false }));
    }
  };

  const handleUpdateConfiguration = async () => {
    setState((prev: ISchedulingDetailsModalState) => ({ ...prev, loading: true }));
    try {
      const {
        enabled,
        startDate,
        startTime,
        scheduleId,
        frequency,
        onceParameters,
        dailyParameters,
        weeklyParameters,
        monthlyParameters,
        excludeDates,
        sendNotification,
        failedEmailTemplate,
        successEmailTemplate,
      } = state.taskData;


      const hr = startTime.substring(0, 2);
      const min = startTime.substring(3, 5);
      const meridian = startTime.substring(6);
      const formattedDate = moment(`${moment(startDate).format('YYYY-MM-DD')} ${hr}:${min}:${meridian}`).format('YYYY-MM-DD h:mm A');

      const requestBody: any = {
        enabled,
        startTime: formattedDate,
        scheduleId,
        frequency,
        excludeDates,
        sendNotification,
        failedEmailTemplate,
        successEmailTemplate,
        onceParameters: frequency === frequencyMapping.once ? onceParameters : {},
        dailyParameters: frequency === frequencyMapping.daily ? dailyParameters : {},
        weeklyParameters: frequency === frequencyMapping.weekly ? weeklyParameters : {},
        monthlyParameters: frequency === frequencyMapping.monthly ? monthlyParameters : {},
      };


      const {
        overrideProcessStart,
        processStart,
      } = state.overrideData;
      if (overrideProcessStart) {
        const overrideHr = processStart?.processTime?.substring(0, 2);
        const overrideMin = processStart?.processTime?.substring(3, 5);
        const overrideMeridian = processStart?.processTime?.substring(6);
        const overrideFormattedDate =
          moment(`${moment(processStart?.processDate).format('YYYY-MM-DD')} ${overrideHr}:${overrideMin}:${overrideMeridian}`).format('YYYY-MM-DD h:mm A');
        handleChangeOverrideData(
          {
            ...state.overrideData,
            processStart: {
              ...state.overrideData?.processStart,
              processDateTime: overrideFormattedDate,
            },
          },
        );

        requestBody.overrideProcessStart = overrideProcessStart;
        requestBody.processStart = overrideFormattedDate;
      }
      await apiService.taskScheduler.updateSchedulingConfiguration(requestBody);
      addNotification({
        text: 'Scheduler configuration was successfully updated',
        type: MessageBarType.success,
      });
      fetch();
    } catch (e: any) {
      const { response: { data } } = e;
      const { message, state, statusCode } = data;

      addNotification({
        text: `Scheduler configuration updating error: ${message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: ISchedulingDetailsModalState) => ({ ...prev, loading: false }));
    }
  };

  const handleChangeEnabled = (event: any, enabled: boolean | undefined): void => {
    setState((prev: any) => ({
      ...prev,
      taskData: {
        ...prev.taskData,
        enabled,
      },
    }));
  };

  const handleChangeTaskData = (newTaksData: any) => setState({ ...state, taskData: newTaksData });

  const handleChangeOverrideData = (overrideData: any) => {
    setState({ ...state, overrideData });
  };

  const handleSendTestNotif = async () => {
    try {
      await apiService.taskScheduler.sendTestNotification(taskInfo.mCdSchedid);
      addNotification({
        text: 'TEST Email sent successfully.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Tasks running error: ${get(response, 'data.message', '')}`,
        type: MessageBarType.error,
      });
    } 
  };

  const handleAddRecipient = async (recipientsData: any) => {
    try {
      const { data } = await apiService.taskScheduler.AddRecipient(recipientsData);

      fetchRecipients();
      addNotification({ text: data.message, type: MessageBarType.success });

    } catch (e: any) {
      const { response } = e;
      addNotification({ text: `Create Recipient error: ${response.data?.message}`, type: MessageBarType.error });
    } 
  };

  const handleUpdateRecipient = async (recipientsData: any) => {
    try {
      const { data } = await apiService.taskScheduler.UpdateRecipient(recipientsData);
      fetchRecipients();
      addNotification({ text: data.message, type: MessageBarType.success });

    } catch (e: any) {
      const { response } = e;
      addNotification({ text: `Update Recipient error: ${response.data?.message}`, type: MessageBarType.error });
    } 
  };

  const handleDeleteRecipients = async (rowsToDelete: any) => {
    try {
      const Idvalues: any  = []; 
      for (const key in rowsToDelete){
        const value = rowsToDelete[key];        
        Idvalues.push(value.mCdEmailRecipientid);
      }
      const { data } = await apiService.taskScheduler.DeleteRecipients(Idvalues);
      fetchRecipients();
      addNotification({ text: data.message, type: MessageBarType.success });
    } catch (e: any) {
      const { response } = e;
      addNotification({ text: `Delete Recipient error: ${response.data?.message}`, type: MessageBarType.error });
    } 
  };



  useEffect(() => {
    if (isModalOpen) {
      fetch();
      fetchRecipients();
      fetchSelectOptions();
    }
  }, [
    isModalOpen,
  ]);

  const disableSaveConfig =
    !state.taskData?.frequency || !state.taskData?.startDate ||
    state.taskData?.frequency === frequencyMapping.once && isNil(state.taskData?.onceParameters?.runOn) ||
    state.taskData?.frequency === frequencyMapping.weekly && isEmpty(state.taskData?.weeklyParameters?.days) ||
    state.taskData?.frequency === frequencyMapping.monthly && (
      isEmpty(state.taskData?.monthlyParameters?.months) ||
      !state.taskData?.monthlyParameters?.isByWeek && !state.taskData?.monthlyParameters?.numberDayOfMonth ||
      state.taskData?.monthlyParameters?.isByWeek && (!state.taskData?.monthlyParameters?.weekOfTheMonth ||
        !state.taskData?.monthlyParameters?.numberDayOfWeek)
    );


  return (
    <Modal
      isOpen={isModalOpen}
      containerClassName={styles.modalContainer}
      onDismiss={hideModal}
    >
      <div className="ms-Grid">
        <div className={styles.modalHeader}>
          <div>
            <Text variant="xxLarge" id="title">{taskInfo?.jobName}</Text>
            <Toggle
              checked={state.taskData?.enabled === true}
              onChange={handleChangeEnabled}
            />
            <Text variant="xLarge">{state.taskData?.enabled ? 'Enabled' : 'Disabled'}</Text>
          </div>

          <div>
            <Text className={styles.boldText}>Started: </Text>
            <Text variant='mediumPlus'>
              {isNil(get(state, 'taskData.lastRunTime', null)) ?
                'N/A' :
                moment(state.taskData?.lastRunTime).format('MM/DD/YYYY h:mm:ss a')}
            </Text>
            <Text
              variant='mediumPlus'
              className={styles[get(statuseClassNameMapping, `${taskInfo?.status}.className`)]}
            >
              {taskInfo?.status?.toLowerCase()}
              <img src={get(statuseClassNameMapping, `${taskInfo?.status}.iconSrc`)} width="16px" />
            </Text>
          </div>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel="Close Modal"
            onClick={hideModal}
            id="closeButton"
          />
        </div>
        <SeparatorGy />
        <div className={classNames('ms-Grid-row', styles.row)}>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <Text variant='large' className={styles.boldText}>Last Run History</Text>
          </div>
        </div>
        <br />
        <div className={classNames('ms-Grid-row', styles.row)}>
          <div className={classNames('ms-Grid-col', 'ms-sm4')}>
            <Text className={styles.boldText}>Triggers</Text>
            <br />
            <Text variant='mediumPlus'>{taskInfo?.trigger}</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm4')}>
            <Text className={styles.boldText}>Next Run Time:</Text>
            <br />
            <Text variant='mediumPlus'>
              {
                state.taskData?.nextRunTime ?
                  moment(state.taskData?.nextRunTime).format('MM/DD/YYYY h:mm:ss a') :
                  'N/A'
              }
            </Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm2')}>
            <Text className={styles.boldText}>Last Run Time</Text>
            <br />
            <Text variant='mediumPlus'>
              {
                state.taskData?.lastRunTime ?
                  moment(state.taskData?.lastRunTime).format('MM/DD/YYYY h:mm:ss a') :
                  'N/A'
              }
            </Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm2')}>
            <Text className={styles.boldText}>Last Run Result</Text>
            <br />
            <Text variant='mediumPlus'>
              {
                state.taskData?.lastRunResult || 'N/A'
              }
            </Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm3')} />
        </div>
        <SeparatorGy />

        <div className={classNames('ms-Grid-row', styles.row)}>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            {state.taskData &&
              <FrequencyComponent
                overrideData={state.overrideData}
                handleChangeOverrideData={handleChangeOverrideData}
                taskData={state.taskData}
                dropdownOptions={state.dropdownOptions}
                handleUpdateConfiguration={handleChangeTaskData}
                recipientsData={state.recipientsData}
                handleSendTestNotification={handleSendTestNotif}
                handleAddRecipients={handleAddRecipient}
                handleUpdateRecipient={handleUpdateRecipient}
                handleDeleteRecipients={handleDeleteRecipients}
              />}
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.buttonsWrapper)}>
            <DefaultButton
              text="Cancel"
              onClick={hideModal}
            />
            <PrimaryButton
              text="Save Updates"
              onClick={handleUpdateConfiguration}
              disabled={
                disableSaveConfig
              }
            />
          </div>
        </div>
      </div>
      {state.loading && <LoadingScreen />}
    </Modal >
  );
};

export default SchedulingDetailsModal;
