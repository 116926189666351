import classNames from 'classnames';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBoolean } from '@fluentui/react-hooks';
import { getMenuOptionById } from '../../components/Nav/consts';
import { useCustomersFetch } from '../../hooks/useCustomersFetch';
import useNotifications from '../../hooks/useNotifications';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import { MIDAS_MODULES } from '../../models/EnumMidasModules';
import { customerFilterSelector, customerShouldFilterSelector, setFilter, setShouldFilterRun } from '../../redux/customerSlice';
import DataGridComponent from '../DataGridComponent';
import ModuleTitleComponent from '../ModuleTitleComponent';
import SearchCustomerForm from '../SearchCustomerForm';
import apiService from '../../api';
import { useHistory, useLocation } from 'react-router-dom';
import { IFormData } from '../SearchCustomerForm/ISearchCustomerForm';
import { ICustomer } from '../../components/Common/Search/ICustomer';
import { DefaultButton, MessageBarType } from '@fluentui/react';
import { anyPrefixRegex } from '../SearchCustomerForm/const';
import { defaultSorting, searchCustomerHeadCell } from './consts';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import moment from 'moment';
import styles from './SearchCustomerComponent.module.scss';
import PrintingModal from '../../components/PrintingModal/PrintingModal';
import { downloadFile, printingTypes } from '../../components/PrintingModal/consts';


interface SearchCustomerComponentProps {
  parentComponent: MIDAS_MODULES.CUSTOMER | MIDAS_MODULES.TERMS | MIDAS_MODULES.QUOTE;
  printExcelAPI?: any;
  printPdfAPI?: any
};

const SearchCustomerComponent: FunctionComponent<SearchCustomerComponentProps> = ({ parentComponent, printExcelAPI, printPdfAPI }) => {
  const { loadingCustomers, fetchCustomers, customersList } = useCustomersFetch(apiService.getCustomerSearch, { fdelete: false });
  const { loadingPermissions } = useUserPermissions();
  const customerFilter = useSelector(customerFilterSelector);
  const shouldFilterRun = useSelector(customerShouldFilterSelector);
  const { addNotification } = useNotifications();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isFormFilter, setIsFormFilter] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [formData, setFormData] = useState<IFormData>();
  const [dataTable, setDataTable] = useState<ICustomer[]>([]);

  const history = useHistory();
  const location = useLocation();

  const [isPrintLoading, setIsPrintLoading] = useState(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);

  const [dataGridState, setDataGridState] = useState<any>(null);

  const fetchLocalCustomers = async (
    customSearch: any = formData,
    pagination: any = dataGridState?.pagination,
    sortOrder: any = dataGridState?.sortOrder,
    searchedText: string = dataGridState?.searchedText,
  ) => {
    try {
      const { data } = await apiService.getCustomerSearch(
        { ...customSearch, fdelete: false, searchString: searchedText },
        pagination,
        sortOrder,
      );
      const customerData: ICustomer[] = data.data.map((cust: ICustomer): ICustomer => {
        return {
          ...cust,
          effectiveStartDate: cust?.effectiveStartDate && moment(cust.effectiveStartDate).format('MM/DD/YYYY'),
          effectiveEndDate: cust?.effectiveEndDate && moment(cust.effectiveEndDate).format('MM/DD/YYYY'),
        };
      });
      setDataTable(customerData);
      setTotalRows(data.total.found);
    } catch (e: any) {
      addNotification({
        text: `Jobs fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const handleSelectRow = (rowsSelected: any) => handleRedirect(rowsSelected?.id);

  const handleChangeDataGridState = async (dataGridState: any) => {
    setIsLoading(true);
    const { countOnPage, paginationProps, sortOrder, searchedText } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    setDataGridState({ pagination, sortOrder, searchedText });
    if (formData) await fetchLocalCustomers(formData, pagination, sortOrder, searchedText);
    setIsLoading(false);
  };

  const onSearchFormClick = async (filter: any) => {
    setIsFormFilter(true);
    setFormData(filter);
    setIsLoading(true);
    await fetchLocalCustomers(filter);
    setIsLoading(false);
  };

  const handleRedirect = (id: any) => {
    dispatch(setFilter({ ...formData }));
    const path = `${location.pathname}/${id}${parentComponent == MIDAS_MODULES.TERMS ? '/list' : ''}`;
    history.push(path);
  };

  const handlePrint = async (printingType: any) => {
    toggleShowPrintExport();
    setIsPrintLoading(true);

    const input = {
      filters: {
        ...customerFilter,
        status: anyPrefixRegex.test(customerFilter.status) ? '' : customerFilter.status,
        country: anyPrefixRegex.test(customerFilter.country) ? '' : customerFilter.country,
        state: anyPrefixRegex.test(customerFilter.state) ? '' : customerFilter.state,
        city: anyPrefixRegex.test(customerFilter.city) ? '' : customerFilter.city,
        regionCode: anyPrefixRegex.test(customerFilter.regionCode) ? '' : customerFilter.regionCode,
        zipcode: anyPrefixRegex.test(customerFilter.zipcode) ? '' : customerFilter.zipcode,
        fdelete: false,
        searchString: '',
      },
    };

    try {
      const { data }: any = printingType === printingTypes.excel ?
        await printExcelAPI({ input }) :
        await printPdfAPI({ input });

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      addNotification({
        text: 'Printing Error',
        type: MessageBarType.error,
      });
    }
    finally {
      setIsPrintLoading(false);
    };
  };

  useEffect(() => {
    const fetchFilteredData = async () => {
      dispatch(setShouldFilterRun(false));
      setIsLoading(true);
      const { result, total } = await fetchCustomers({
        ...customerFilter,
        status: anyPrefixRegex.test(customerFilter.status) ? '' : customerFilter.status,
        country: anyPrefixRegex.test(customerFilter.country) ? '' : customerFilter.country,
        state: anyPrefixRegex.test(customerFilter.state) ? '' : customerFilter.state,
        city: anyPrefixRegex.test(customerFilter.city) ? '' : customerFilter.city,
        regionCode: anyPrefixRegex.test(customerFilter.regionCode) ? '' : customerFilter.regionCode,
        zipcode: anyPrefixRegex.test(customerFilter.zipcode) ? '' : customerFilter.zipcode,
        fdelete: false,
        searchString: '',
      }, dataGridState?.pagination);
      setFormData({ ...customerFilter });
      const customerData: ICustomer[] = result.map((cust: ICustomer): ICustomer => {
        return {
          ...cust,
          effectiveStartDate: cust?.effectiveStartDate && moment(cust.effectiveStartDate).format('MM/DD/YYYY'),
          effectiveEndDate: cust?.effectiveEndDate && moment(cust.effectiveEndDate).format('MM/DD/YYYY'),
        };
      });
      setDataTable(customerData);
      setDataTable(result);
      setTotalRows(total);
      setIsLoading(false);
      dispatch(setFilter({}));
    };
    if (shouldFilterRun && Object.entries(customerFilter).length) {
      fetchFilteredData();
    }
  }, [customerFilter, shouldFilterRun]);

  useEffect(() => {
    if (dataTable?.length === 1 && isFormFilter) {
      handleRedirect(dataTable[0]?.id || dataTable[0]?.m_CB_CUSTOMERID);
    }
  }, [dataTable]);

  const moduleInfo = getMenuOptionById(parentComponent);

  return (
    <>
      <div className="ms-Grid record-keeping-container mainContainer" dir="ltr">
        <ModuleTitleComponent moduleInfo={moduleInfo} />
        <div className={classNames('ms-Grid-row', 'padding30')}>
          <SearchCustomerForm
            isLoading={loadingCustomers}
            customersList={customersList}
            fetchData={fetchCustomers}
            onSearchFormClick={onSearchFormClick}
            formName='Customer'
          />
          <div>
            <DataGridComponent
              idTable={'add-modal-table'}
              title='Customers'
              headCells={searchCustomerHeadCell}
              defaultSorting={defaultSorting}
              rowsTable={dataTable}
              totalDataFound={totalRows || 0}
              enablePagination
              enableRowClick
              enableRowsPerPage
              enableSearching
              handleRowClick={handleSelectRow}
              handleChangeDataGridState={handleChangeDataGridState}
              isLoading={isLoading}
            />
          </div>
          <div className={styles.bottomActionButtons}>
            {printExcelAPI && printPdfAPI && <DefaultButton
              id="printExportBtn"
              text="Print/Export"
              onClick={toggleShowPrintExport}
            /> }
          </div>
          {(loadingCustomers || loadingPermissions || isPrintLoading) && <LoadingScreen />}
        </div>
        <PrintingModal
          isOpened={showPrintExport}
          onClose={toggleShowPrintExport}
          onPrint={handlePrint}
        />
      </div>
    </>
  );
};

export default SearchCustomerComponent;