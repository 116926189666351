import { HeadCell } from '../../../../../../shared/DataGridComponent/DataGridModels';

export const billingCodesHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Billing Type',
    fieldName: 'billingType',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Department',
    fieldName: 'departmentCode',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Type',
    fieldName: 'billingTypeCode',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Other',
    fieldName: 'otherCode',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'Radial',
    fieldName: 'radialCode',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column9',
    name: 'Bias',
    fieldName: 'biasCode',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column5',
    name: 'Msg Text1',
    fieldName: 'msgText1',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column6',
    name: 'Msg Text2',
    fieldName: 'msgText2',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column7',
    name: 'Credit Msg Text1',
    fieldName: 'creditMsgText1',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column8',
    name: 'Credit Msg Text2',
    fieldName: 'creditMsgText2',
    typeField: 'text',
    isEditable: false,
  },
];

export const locationsHeadCells: HeadCell[] = [
  {
    key: 'column0',
    name: 'Ship to Number',
    fieldName: 'shipToNumber',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Location Code',
    fieldName: 'locationCode',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Address Line 1',
    fieldName: 'addrLine1',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'City',
    fieldName: 'city',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'State',
    fieldName: 'state',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column5',
    name: 'Country',
    fieldName: 'country',
    typeField: 'text',
    isEditable: false,
  },
];