import { exportTestInstance, recordTestInstance } from '../axios';
import { IReconciliationDetail } from '../components/RecordKeeping/tabComponents/SpareStockTab/tabComponents/ViewPostedSpareStock/ReconciliationReport/ReconciliationDetails/IReconciliationDetail';

const getSpareStockList = (pagination: any, sortOrder: any, customerId: number, locationId: number, postedSSkId: any) =>
  recordTestInstance.post('/spare-stock/post-batch', { pagination, sortOrder, customerId, locationId, postedSSkId });

const deleteSpareStockList = (ids: Array<any>) =>
  recordTestInstance.delete('/spare-stock/post-batch/delete', { data: { ids } });

const postSpareStockBatchAdd = (spareStockList: Array<any>, customerId: number, locationId: number, postedSSkId: any) =>
  recordTestInstance.post('/spare-stock/post-batch/add', { data: spareStockList, customerId, locationId, postedSSkId });

const postSpareStockBatchSave = (spareStockList: Array<any>, customerId: number, locationId: number) =>
  recordTestInstance.post('/spare-stock/post-batch/save', { data: spareStockList, customerId, locationId });

const updateSpareStockBatch = (postBatchUpdates: Array<any>) =>
  recordTestInstance.put('/spare-stock/post-batch', { postBatchUpdates });

const submitSpareStockBatch = (spareStockList: Array<any>) =>
  recordTestInstance.post('/spare-stock/post-batch/submit', { ids: spareStockList });

const getPostedSpareStockList = (pagination: any, filters: any, sortOrder: any, customerId: string, locationId: string | null) =>
  recordTestInstance.post('/spare-stock/view-posted', { pagination, filters, sortOrder, customerId, locationId });

const deletePostedSpareStocks = (ids: Array<any>) =>
  recordTestInstance.delete('/spare-stock/view-posted', { data: { ids } });

const postPostedSpareStocks = (item: any) =>
  recordTestInstance.post('/spare-stock/view-posted/post', item);

const getReconciliationDetails = (pagination: any, filters: any, sortOrder: any) =>
  recordTestInstance.post('/spare-stock/view-posted/reconciliation/details', { pagination, filters, sortOrder });

const updateReconciliationDetails = ({ reconciliationDetailsList, isRecordAsLost }: { reconciliationDetailsList: Array<IReconciliationDetail>, isRecordAsLost: boolean }) =>
  recordTestInstance.put('/spare-stock/view-posted/reconciliation/update', { data: reconciliationDetailsList, isRecordAsLost });

const getReconciliationSummary = (customerId: number, locationIds: Array<string>, period: string) =>
  recordTestInstance.post('/reconciliation/summary', { customerId, locationIds, period });

const updateReconciliationReportStatus = (payload: any) =>
  recordTestInstance.put('/spare-stock/view-posted/reconciliation/update-status', payload);

const notify = (customerId: any, locationId: any, period: any, typeOfLetter: number) =>
  recordTestInstance.post('/reconciliation/notify', { customerId, locationId, period, typeOfLetter }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.docx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    },
    responseType: 'arraybuffer',
  });

const printExcelSpareStock = ({ data, headerFields, locationId, customerId }: { data: any, headerFields?: Array<any>, locationId?: string, customerId: string }) =>
  exportTestInstance.post('/spare-stock/post-batch/from-list/export-excel', { data, headerFields, locationId, customerId }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfSpareStock = ({ data, headerFields, locationId, customerId }: { data: any, headerFields?: Array<any>, locationId?: string, customerId: string }) =>
  exportTestInstance.post('/spare-stock/post-batch/from-list/export-pdf', { data, headerFields, locationId, customerId }, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const printExcelViewPostedSpareStock = (data: any, headerFields?: Array<any>) =>
  exportTestInstance.post('/spare-stock/view-posted/export-excel', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfViewPostedSpareStock = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/spare-stock/view-posted/export-pdf', { data, headerFields }, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const printExcelReconciliationSummary = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/reconciliation/summary/export-excel', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfReconciliationSummary = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/reconciliation/summary/export-pdf', { data, headerFields }, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const printExcelReconciliationDetails = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/spare-stock/view-posted/reconciliation/details/export-excel', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfReconciliationDetails = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/spare-stock/view-posted/reconciliation/details/export-pdf', { data, headerFields }, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const printExcelRecordAsLost = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/spare-stock/view-posted/reconciliation/record-as-lost/export-excel', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfRecordAsLost = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/spare-stock/view-posted/reconciliation/record-as-lost/export-pdf', { data, headerFields }, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const releaseRecordAsLost = (payload: any) =>
  recordTestInstance.post('/spare-stock/view-posted/reconciliation/release', payload);

const getPostStatus = (ids: any) =>
  recordTestInstance.post('/spare-stock/post-batch/current-status', { ids });

const getCostReportDetails = (customerId: string, locationId: string, period: string | null) =>
  recordTestInstance.post('/get-cost-report/details', { customerId, locationId, period });

const getCostReportList = (pagination: any, sortOrder: any, customerId: string, locationId: string, period: string | null) =>
  recordTestInstance.post('/get-cost-report/list', { pagination, sortOrder, customerId, locationId, period });

const getCostSummaryReportDetails = (pagination: any, sortOrder: any, customerId: string, locationId: string, period: string | null) =>
  recordTestInstance.post('/generate-summary-report/details', { pagination, sortOrder, customerId, locationId, period });

const generateNewCostReport = (customerId: string, locationId: string, period: string | null) =>
  recordTestInstance.post('/generate-new-cost-report/list', { customerId, locationId, period });

const generateNewCostReportStatus = (customerId: string, locationId: string, period: string | null) =>
  recordTestInstance.post('/generate-new-cost-report/status', { customerId, locationId, period });

const downloadAvailableCostReport = (input: { reportId: string; reportName?: string; userId?: string; }) =>
  exportTestInstance.post('/cost-report/download', input, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const getMassDetails = (customerId: string, locationId: string) =>
  recordTestInstance.post('/get-mass-details/details', { customerId, locationId });

const getMassDetailsStatus = (customerId: string, locationId: string) =>
  recordTestInstance.post('/get-mass-details/status', { customerId, locationId });

const getMassDetailsList = (pagination: any, sortOrder: any, customerId: string, locationId: string, prefix: string, brand: string, suffix: string) =>
  recordTestInstance.post('/mass-details/list', { pagination, sortOrder, customerId, locationId, prefix, brand, suffix });

const addMassDetails = (customerId: string, locationId: string, prefix: string, brand: string, suffix: string) =>
  recordTestInstance.post('/mass-details/add', { customerId, locationId, prefix, brand, suffix });

const clearAllMassDetails = (customerId: string, locationId: string) =>
  recordTestInstance.post('/clear-all-mass-details/delete', { customerId, locationId });

const deleteMassDetails = (ids: Array<any>) =>
  recordTestInstance.delete('/mass-details/delete', { data: { ids } });

const printMassDetailsExcel = (input: any) =>
  exportTestInstance.post('/mass-details/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printMassDetailsPdf = (input: any) =>
  exportTestInstance.post('/mass-details/export-pdf', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  getSpareStockList,
  deleteSpareStockList,
  postSpareStockBatchAdd,
  postSpareStockBatchSave,
  updateSpareStockBatch,
  submitSpareStockBatch,
  getPostedSpareStockList,
  deletePostedSpareStocks,
  postPostedSpareStocks,
  getReconciliationDetails,
  updateReconciliationDetails,
  getReconciliationSummary,
  updateReconciliationReportStatus,
  notify,
  printExcelSpareStock,
  printPdfSpareStock,
  printExcelViewPostedSpareStock,
  printPdfViewPostedSpareStock,
  printExcelReconciliationSummary,
  printPdfReconciliationSummary,
  printExcelReconciliationDetails,
  printPdfReconciliationDetails,
  printExcelRecordAsLost,
  printPdfRecordAsLost,
  releaseRecordAsLost,
  getPostStatus,
  getCostReportDetails,
  getCostReportList,
  getCostSummaryReportDetails,
  generateNewCostReport,
  generateNewCostReportStatus,
  downloadAvailableCostReport,
  getMassDetails,
  getMassDetailsStatus,
  addMassDetails,
  deleteMassDetails,
  clearAllMassDetails,
  getMassDetailsList,
  printMassDetailsExcel,
  printMassDetailsPdf,
};
