import React, { useCallback, useState } from 'react';
import {
  ChoiceGroup,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IChoiceGroupOption,
  PrimaryButton,
} from '@fluentui/react';

interface FinalizeModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  onOptionChange: (renewal: boolean) => void;
  title?: string;
  subText?: string;
  onSubmitLabel?: string;
  onCancelLabel?: string;
}

const options: IChoiceGroupOption[] = [
  { key: 'renewal', text: 'Renewal Contract' },
  { key: 'new', text: 'New Contract' },
];

function FinalizeModal({
  isOpen,
  onCancel,
  onSubmit,
  onOptionChange,
  title = 'Select one of the options to finalize the term:',
  subText,
  onSubmitLabel = 'Ok',
  onCancelLabel = 'Close',
}: FinalizeModalProps) {
  const [selectedKey, setSelectedKey] = useState<string | undefined>('renewal');
  const onChange = useCallback((ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
    setSelectedKey(option?.key);
    onOptionChange(option?.key === 'renewal' ? true : false);
  }, []);

  return (
    <Dialog
      hidden={!isOpen}
      onDismiss={onCancel}
      dialogContentProps={{
        type: DialogType.normal,
        title,
        subText,
      }}
      modalProps={{ isBlocking: true }}
    >
      <DialogFooter>
        <ChoiceGroup
          style={{
            marginBottom: '16px',
          }}
          selectedKey={selectedKey}
          options={options}
          onChange={onChange}
        />
        <PrimaryButton id='submitDialogBtn' onClick={onSubmit} text={onSubmitLabel} />
        <DefaultButton id='cancelDialogBtn' onClick={onCancel} text={onCancelLabel} />
      </DialogFooter>
    </Dialog>
  );
}

export default FinalizeModal;
