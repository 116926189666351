import { IconButton, TooltipHost } from '@fluentui/react';
import { FC } from 'react';

interface ITooltipProps {
    content: string
}

const Tooltip: FC<ITooltipProps> = ({content}) => {
  return (
    <>
      <TooltipHost
        content={content}
        calloutProps={{ gapSpace: 0 }}
      >
        <IconButton
          iconProps={{ iconName: 'Unknown' }}
        />
      </TooltipHost>
    </>
  );
};

export default Tooltip;