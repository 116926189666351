import { FunctionComponent } from 'react';
import Warehouse from './tabComponents/Warehouse/Warehouse';
import Retread from './tabComponents/Retread/Retread';
import Oems from './tabComponents/Oems/Oems';

/** Each tab into left menu must have a component */
export const maintainPlantsTabs: IMaintainPlantsTabs = {
  oems: { name: 'OEMs', component: Oems },
  warehouse: { name: 'Warehouse', component: Warehouse },
  retread: { name: 'Retread', component: Retread },
};

export interface IMaintainPlantsTabs {
  warehouse: TabComponent;
  retread: TabComponent;
  oems: TabComponent;
}

export interface TabComponent {
  name: string,
  component: FunctionComponent<any> | null
}
