import { DefaultButton, MessageBarType, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import apiService from '../../../../../api';
import useNotifications from '../../../../../hooks/useNotifications';
import { customerSelector } from '../../../../../redux/customerSlice';
import { selectedQuoteSelector } from '../../../../../redux/quoteSlice';
import DataGridComponent from '../../../../../shared/DataGridComponent';
import LoadingScreen from '../../../../LoadingScreen/LoadingScreen';
import { downloadFile, printingTypes } from '../../../../PrintingModal/consts';
import PrintingModal from '../../../../PrintingModal/PrintingModal';
import { tireHeadCells } from './consts';
import styles from './Forecast.module.scss';
import { IForecastProps } from './IForecastProps';
import { IForecastState } from './IForecastState';


const Forecast: FunctionComponent<IForecastProps> = () => {

  const { addNotification } = useNotifications();

  const selectedQuote = useSelector(selectedQuoteSelector);
  const { id: customerId } = useSelector(customerSelector);

  const [state, setState] = useState<IForecastState>({
    forecastData: null,
    loading: false,
  });

  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
      
  const fetchForecastData = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.quotesAPI.getForecastData(selectedQuote.id, customerId, selectedQuote.version);
      setState((prev: any) => ({ ...prev, forecastData: data.data }));
    } catch (e: any) {
      addNotification({
        text: `Fetching forecast error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const headerFields = [
        { title: 'title', value: customerId },
      ];

      const requestData = {
        input: { customerId, quoteId: selectedQuote.id, quoteVersion: selectedQuote.version },
        headerFields: headerFields,
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.quotesAPI.printExcelForecast(requestData) :
        await apiService.quotesAPI.printPdfForecast(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Printing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (selectedQuote) {
      fetchForecastData();
    }
  }, []);

  return (
    <>
      { state.forecastData && <div className={styles.forecastData}>
        <div className={styles.actionButtons}>
          <DefaultButton onClick={toggleShowPrintExport} text="Print/Export" />
        </div>
        { state.forecastData.rategroups.map(rateGroup => 
          <div className={styles.rateGroup}>
            <div className={styles.rateGroupInfo}>
              <Text variant="xLarge" className={styles.highlight}>Rate Group: {rateGroup.name}</Text>
              <Text variant="large" className={styles.highlight}>Tire Rate: {rateGroup.quoteTireRate}</Text>
              <Text variant="large" className={styles.highlight}>EBIT % (Before Bonus): {rateGroup.ebit}</Text>
              <Text variant="large" className={styles.highlight}>EBIT % (After Bonus): {rateGroup.ebitAfterBonus}</Text>
            </div>
            <div className={styles.tiresTables}>
              <div className={styles.tiresTable}>
                <DataGridComponent
                  idTable='original-tread-table'
                  title='ORIGINAL TREAD'
                  headCells={tireHeadCells}
                  totalDataFound={rateGroup.originalTires.length}
                  rowsTable={rateGroup.originalTires.map((el, index) => ({ 
                    prodCode: el.prodCode,
                    tireSize: el.tireSize,
                    bogey: el.bogey.toLocaleString('en-US'), 
                    approxTireMiles: el.approxTireMiles.toLocaleString('en-US'),
                    tiresEqualized: el.tiresEqualized.toLocaleString('en-US'),
                    factoryCost: el.factoryCost.toLocaleString('en-US'),
                    index: index + 1}))}
                />
                <div className={styles.totalsInfo}>
                  <div className={styles.totalsRow}>
                    <Text variant="xLarge" className={styles.highlight}>Totals:</Text>
                    <Text variant="large" className={styles.highlight}>{rateGroup.origTotals.origApproxTireMiles.toLocaleString('en-US')}</Text>
                  </div>
                  <div className={styles.totalsRow}>
                    <Text variant="xLarge" className={styles.highlight}>Bonus Miles:</Text>
                    <Text variant="large" className={styles.highlight}>{rateGroup.origTotals.bonusOrig.toLocaleString('en-US',{minimumFractionDigits:2})}</Text>
                  </div>
                  <div className={styles.totalsRow}>
                    <Text variant="xLarge" className={styles.highlight}>Avg. Miles For.:</Text>
                    <Text variant="large" className={styles.highlight}>{rateGroup.origTotals.origAvgMiles.toLocaleString('en-US',{minimumFractionDigits:2})}</Text>
                  </div>
                  <div className={styles.totalsRow}>
                    <Text variant="xLarge" className={styles.highlight}>Tires Equalized:</Text>
                    <Text variant="large" className={styles.highlight}>{rateGroup.origTotals.origTiresEqualized.toLocaleString('en-US')}</Text>
                  </div>
                  <div className={styles.totalsRow}>
                    <Text variant="xLarge" className={styles.highlight}>Total Cost:</Text>
                    <Text variant="large" className={styles.highlight}>{rateGroup.origTotals.totCostSales.toLocaleString('en-US')}</Text>
                  </div>
                  <div className={styles.totalsRow}>
                    <Text variant="xLarge" className={styles.highlight}>Cost Rate Per Mile:</Text>
                    <Text variant="large" className={styles.highlight}>{rateGroup.origTotals.tireRate}</Text>
                  </div>
                </div>
              </div>
              <div className={styles.tiresTable}>
                <DataGridComponent
                  idTable='retread-table'
                  title='RETREAD'
                  headCells={tireHeadCells}
                  totalDataFound={rateGroup.retreadTires.length}
                  rowsTable={rateGroup.retreadTires.map((el, index) => ({ 
                    prodCode: el.prodCode,
                    tireSize: el.tireSize,
                    bogey: el.bogey.toLocaleString('en-US'), 
                    approxTireMiles: el.approxTireMiles.toLocaleString('en-US'),
                    tiresEqualized: el.tiresEqualized.toLocaleString('en-US'),
                    factoryCost: el.factoryCost.toLocaleString('en-US'),
                    index: index + 1}))}
                />
                <div className={styles.totalsInfo}>
                  <div className={styles.totalsRow}>
                    <Text variant="xLarge" className={styles.highlight}>Totals:</Text>
                    <Text variant="large" className={styles.highlight}>{rateGroup.rtrdTotals.rtrdApproxMiles.toLocaleString('en-US')}</Text>
                  </div>
                  <div className={styles.totalsRow}>
                    <Text variant="xLarge" className={styles.highlight}>Bonus Miles:</Text>
                    <Text variant="large" className={styles.highlight}>{rateGroup.rtrdTotals.bonusRtrd.toLocaleString('en-US',{minimumFractionDigits:2})}</Text>
                  </div>
                  <div className={styles.totalsRow}>
                    <Text variant="xLarge" className={styles.highlight}>Avg. Miles For.:</Text>
                    <Text variant="large" className={styles.highlight}>{rateGroup.rtrdTotals.rtrdAvgMiles.toLocaleString('en-US',{minimumFractionDigits:2})}</Text>
                  </div>
                  <div className={styles.totalsRow}>
                    <Text variant="xLarge" className={styles.highlight}>Tires Equalized:</Text>
                    <Text variant="large" className={styles.highlight}>{rateGroup.rtrdTotals.rtrdTiresEqualized.toLocaleString('en-US')}</Text>
                  </div>
                  <div className={styles.totalsRow}>
                    <Text variant="xLarge" className={styles.highlight}>Total Cost:</Text>
                    <Text variant="large" className={styles.highlight}>{rateGroup.rtrdTotals.rtrdTireCost.toLocaleString('en-US')}</Text>
                  </div>
                  <div className={styles.totalsRow}>
                    <Text variant="xLarge" className={styles.highlight}>Cost Rate Per Mile:</Text>
                    <Text variant="large" className={styles.highlight}>{rateGroup.rtrdTotals.rtrdTireRate}</Text>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.figures}>
              <table className={styles.figuresTable}>
                <thead>
                  <tr>
                    <th className={classNames(styles.figuresHeadCell, styles.figuresLabelCell, styles.figuresCell)}>
                      <Text variant="large" className={styles.highlight}>Rate Figures</Text>
                    </th>
                    <th className={classNames(styles.figuresHeadCell, styles.figuresCell)}>
                      <Text variant="large" className={styles.highlight}>Cost</Text>
                    </th>
                    <th className={classNames(styles.figuresHeadCell, styles.figuresCell)}>
                      <Text variant="large" className={styles.highlight}>Revenue</Text>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={classNames(styles.figuresLabelCell, styles.figuresCell)}>
                      <Text variant="large" className={styles.highlight}>Tire Rate:</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.rateFigures.tireMileRate}</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.rateFigures.revenue.tireMileRate}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td className={classNames(styles.figuresLabelCell, styles.figuresCell)}>
                      <Text variant="large" className={styles.highlight}>Tire MCE Rate:</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.rateFigures.mceRate}</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.rateFigures.revenue.mceRate}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td className={classNames(styles.figuresLabelCell, styles.figuresCell)}>
                      <Text variant="large" className={styles.highlight}>Tire Equipment Rate:</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.rateFigures.eqpRate}</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.rateFigures.revenue.eqpRate}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td className={classNames(styles.figuresLabelCell, styles.figuresCell)}>
                      <Text variant="large" className={styles.highlight}>Tire RM Rate:</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.rateFigures.rmIncentiveRate}</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.rateFigures.revenue.rmIncentiveRate}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td className={classNames(styles.figuresLabelCell, styles.figuresCell)}>
                      <Text variant="large" className={styles.highlight}>Tire AS Rate:</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.rateFigures.asIncentiveRate}</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.rateFigures.revenue.asIncentiveRate}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td className={classNames(styles.figuresLabelCell, styles.figuresCell)}>
                      <Text variant="large" className={styles.highlight}>Tire Scrap Rate:</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.rateFigures.scrapRate}</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.rateFigures.revenue.scrapRate}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td className={classNames(styles.figuresLabelCell, styles.figuresCell)}>
                      <Text variant="large" className={styles.highlight}>Tire Other Rate:</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.rateFigures.otherRate}</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.rateFigures.revenue.otherRate}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td className={classNames(styles.figuresLabelCell, styles.figuresCell)}>
                      <Text variant="large" className={styles.highlight}>Tire Other2 Rate:</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.rateFigures.other2Rate}</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.rateFigures.revenue.other2Rate}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td className={classNames(styles.figuresLabelCell, styles.figuresCell)}>
                      <Text variant="large" className={styles.highlight}>Total Rate:</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.rateFigures.totTireCostRate}</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.rateFigures.revenue.totTireCostRate}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td className={classNames(styles.figuresLabelCell, styles.figuresCell)}>
                      <Text variant="large" className={styles.highlight}>Total Tire Miles:</Text>
                    </td>
                    <td className={classNames(styles.figuresCell, styles.centeredCell)} colSpan={2}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.rateFigures.totTireMiles.toLocaleString('en-US')}</Text>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className={styles.figuresTable}>
                <thead>
                  <tr>
                    <th className={classNames(styles.figuresHeadCell, styles.figuresLabelCell, styles.figuresCell)}>
                      <Text variant="large" className={styles.highlight}>Sale Figures</Text>
                    </th>
                    <th className={classNames(styles.figuresHeadCell, styles.figuresCell)}>
                      <Text variant="large" className={styles.highlight}>Cost</Text>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={classNames(styles.figuresLabelCell, styles.figuresCell)}>
                      <Text variant="large" className={styles.highlight}>Total Gross Sales:</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.salesFigures.totGrossSales.toLocaleString('en-US')}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td className={classNames(styles.figuresLabelCell, styles.figuresCell)}>
                      <Text variant="large" className={styles.highlight}>Total Cost:</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.salesFigures.totCostOfSales.toLocaleString('en-US')}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td className={classNames(styles.figuresLabelCell, styles.figuresCell)}>
                      <Text variant="large" className={styles.highlight}>Total Volume Bonus:</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.salesFigures.totVolBonus}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td className={classNames(styles.figuresLabelCell, styles.figuresCell)}>
                      <Text variant="large" className={styles.highlight}>Total Net Sales:</Text>
                    </td>
                    <td className={styles.figuresCell}>
                      <Text variant="large" className={styles.highlight}>{rateGroup.salesFigures.totNetSales.toLocaleString('en-US')}</Text>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>,
        )}
        <div className={styles.rateGroupInfo}>
          <Text variant="xLarge" className={styles.highlight}>Rate Group: COMPOSITE RATE GROUP</Text>
          <Text variant="large" className={styles.highlight}>Tire Rate: {state.forecastData?.summary.tireRate}</Text>
          <Text variant="large" className={styles.highlight}>EBIT % (Before Bonus): {state.forecastData?.summary.ebitPct}</Text>
          <Text variant="large" className={styles.highlight}>EBIT % (After Bonus): {state.forecastData?.summary.ebitAfterBonusPct}</Text>
        </div>
        <div className={styles.summaryFigures}>
          <Text variant="xLarge" className={styles.highlight}>RATE FIGURES</Text>
          <table className={styles.figuresTable}>
            <tbody>
              <tr>
                <td className={styles.figuresCell}>
                  <div className={styles.summaryCell}>
                    <Text variant="large" className={styles.highlight}>Original Tire Miles:</Text>
                    <Text variant="large" className={styles.highlight}>{state.forecastData?.summary.origTireMiles.toLocaleString('en-US')}</Text>
                  </div>
                </td>
                <td className={styles.figuresCell}>
                  <div className={styles.summaryCell}>
                    <Text variant="large" className={styles.highlight}>Retread Tire Miles:</Text>
                    <Text variant="large" className={styles.highlight}>{state.forecastData?.summary.retreadTireMiles}</Text>
                  </div>
                </td>
                <td className={styles.figuresCell}>
                  <div className={styles.summaryCell}>
                    <Text variant="large" className={styles.highlight}>Total Tire Miles:</Text>
                    <Text variant="large" className={styles.highlight}>{state.forecastData?.summary.totalTireMiles.toLocaleString('en-US')}</Text>
                  </div>
                </td>
              </tr>
              <tr>
                <td className={styles.figuresCell}>
                  <div className={styles.summaryCell}>
                    <Text variant="large" className={styles.highlight}>Original Equalized Tire:</Text>
                    <Text variant="large" className={styles.highlight}>{state.forecastData?.summary.originalEqualizedTire}</Text>
                  </div>
                </td>
                <td className={styles.figuresCell}>
                  <div className={styles.summaryCell}>
                    <Text variant="large" className={styles.highlight}>Retread Equalized Tire:</Text>
                    <Text variant="large" className={styles.highlight}>{state.forecastData?.summary.retreadEqualizedTire}</Text>
                  </div>
                </td>
                <td className={styles.figuresCell}>
                  <div className={styles.summaryCell}>
                    <Text variant="large" className={styles.highlight}>Total Equalized Tire:</Text>
                    <Text variant="large" className={styles.highlight}>{state.forecastData?.summary.totalEqualizedTire}</Text>
                  </div>
                </td>
              </tr>
              <tr>
                <td className={styles.figuresCell}></td>
                <td className={styles.figuresCell}></td>
                <td className={styles.figuresCell}>
                  <div className={styles.summaryCell}>
                    <Text variant="large" className={styles.highlight}>Vehicle Miles:</Text>
                    <Text variant="large" className={styles.highlight}>{state.forecastData?.summary.vehicleMiles.toLocaleString('en-US')}</Text>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.summaryFigures}>
          <Text variant="xLarge" className={styles.highlight}>SALES FIGURES</Text>
          <table className={styles.figuresTable}>
            <tbody>
              <tr>
                <td className={styles.figuresCell}>
                  <div className={styles.summaryCell}>
                    <Text variant="large" className={styles.highlight}>Total Cost Of Sales:</Text>
                    <Text variant="large" className={styles.highlight}>{state.forecastData?.summary.totalCostOfSales.toLocaleString('en-US')}</Text>
                  </div>
                </td>
                <td className={styles.figuresCell}>
                  <div className={styles.summaryCell}>
                    <Text variant="large" className={styles.highlight}>Total Gross Sales:</Text>
                    <Text variant="large" className={styles.highlight}>{state.forecastData?.summary.totalGrossSales.toLocaleString('en-US')}</Text>
                  </div>
                </td>
                <td className={styles.figuresCell}>
                  <div className={styles.summaryCell}>
                    <Text variant="large" className={styles.highlight}>Total Net Sales:</Text>
                    <Text variant="large" className={styles.highlight}>{state.forecastData?.summary.totalNetSales.toLocaleString('en-US')}</Text>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.actionButtons}>
          <DefaultButton id='printButton' onClick={toggleShowPrintExport} text="Print/Export" />
        </div>
      </div> }
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default Forecast;
