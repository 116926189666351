import { DefaultButton, MessageBarType, Pivot, PivotItem, PrimaryButton, Text, TextField } from '@fluentui/react';
import { FC, FormEvent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import apiService from '../../../../../../../../api';
import useNotifications from '../../../../../../../../hooks/useNotifications';
import { customerSelector } from '../../../../../../../../redux/recordKeepingSlice';
import SeparatorGy from '../../../../../../../SeparatorGy/SeparatorGy';
import { renumberRegisterVehicleTabs } from './consts';
import { IRenumberRegisterVehicleProps } from './IRenumberRegisterVehicleProps';
import styles from './RenumberRegisterVehicle.module.scss';
import { useUserPermissions } from '../../../../../../../../hooks/useUserPermissions';
import { auth_actionOnVehicles_registerVehicles} from '../../../../../../../../consts/programKeys';

const RenumberRegisterVehicle: FC<IRenumberRegisterVehicleProps> = ({ selectedVehicle, setLoading, fetchRegisterVehicles, openAddingMode }): ReactElement => {

  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);

  const [selectedKey, setSelectedKey] = useState(renumberRegisterVehicleTabs.series);
  const [oldVehicleNumber, setOldVehicleNumber] = useState<any>({ prefix: '', fromVehicleNumber: '', toVehicleNumber: '', suffix: '' });
  const [newVehicleNumber, setNewVehicleNumber] = useState<any>({ prefix: '', fromVehicleNumber: '', toVehicleNumber: '', suffix: '' });

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_actionOnVehicles_registerVehicles);

  const onChangeOldFieldPrefix = (_ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, prefix: string | undefined) =>
    setOldVehicleNumber((prev: any) => ({ ...prev, prefix: prefix?.toUpperCase() }));

  const onChangeOldFieldSuffix = (_ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, suffix: string | undefined) =>
    setOldVehicleNumber((prev: any) => ({ ...prev, suffix: suffix?.toUpperCase() }));

  const onChangeOldFieldFromVehicleNumber = (_ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, fromVehicleNumber: string | undefined) =>
    setOldVehicleNumber((prev: any) => ({ ...prev, fromVehicleNumber }));

  const onChangeOldFieldToVehicleNumber = (_ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, toVehicleNumber: string | undefined) =>
    setOldVehicleNumber((prev: any) => ({ ...prev, toVehicleNumber }));

  const onChangeNewFieldPrefix = (_ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, prefix: string | undefined) =>
    setNewVehicleNumber((prev: any) => ({ ...prev, prefix: prefix?.toUpperCase() }));
    
  const onChangeNewFieldSuffix = (_ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, suffix: string | undefined) =>
    setNewVehicleNumber((prev: any) => ({ ...prev, suffix: suffix?.toUpperCase() }));

  const onChangeNewFieldFromVehicleNumber = (_ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, fromVehicleNumber: string | undefined) =>
    setNewVehicleNumber((prev: any) => ({ ...prev, fromVehicleNumber }));

  const onChangeNewFieldToVehicleNumber = (_ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, toVehicleNumber: string | undefined) =>
    setNewVehicleNumber((prev: any) => ({ ...prev, toVehicleNumber }));

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
      handleClearFields();
    }
  };

  const handleClearFields = () => {
    setOldVehicleNumber({ prefix: '', fromVehicleNumber: '', toVehicleNumber: '', suffix: '' });
    setNewVehicleNumber({ prefix: '', fromVehicleNumber: '', toVehicleNumber: '', suffix: '' });
  };

  const handleClearButton = () => {
    handleClearFields();
    openAddingMode();
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  const shouldShowUpdate = (selectedKey === renumberRegisterVehicleTabs.series && oldVehicleNumber.fromVehicleNumber && oldVehicleNumber.toVehicleNumber && newVehicleNumber.fromVehicleNumber && newVehicleNumber.toVehicleNumber)
  || (selectedKey === renumberRegisterVehicleTabs.singleVehicle && oldVehicleNumber.fromVehicleNumber && newVehicleNumber.fromVehicleNumber);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const { data }: any = await apiService.registerVehicles.renumberTires(
        oldVehicleNumber.prefix, 
        oldVehicleNumber.suffix, 
        oldVehicleNumber.fromVehicleNumber, 
        oldVehicleNumber.toVehicleNumber, 
        newVehicleNumber.prefix, 
        newVehicleNumber.suffix,
        newVehicleNumber.fromVehicleNumber,
        newVehicleNumber.toVehicleNumber,
        oldVehicleNumber.toVehicleNumber ? 1 : 0,
        customerId,
      );
      fetchRegisterVehicles();
      addNotification({
        text: `Vehicles were successfully renumbered: ${data.message}`,
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Renumber vehicles error: ${response.data.message}`,
        type: MessageBarType.error,
      });
      setLoading(false);
    }
  };


  useEffect(() => {
    handleClearFields();
  }, [ selectedVehicle ]);

  return (
    <div>
      <Pivot
        aria-label="Separately Rendered Content Pivot Example"
        selectedKey={selectedKey}
        onLinkClick={handleLinkClick}
        getTabId={getTabId}
        linkFormat="tabs"
        linkSize="large"
      >
        <PivotItem headerText={renumberRegisterVehicleTabs.series} itemKey={renumberRegisterVehicleTabs.series} >
          <SeparatorGy />
          <div className={styles.vehicleNumberSections}>
            <div>
              <Text variant="large" className={styles.highlight}>Old Vehicle Number</Text>
              <div className={styles.fields}>
                <TextField
                  className={styles.field}
                  label="Prefix"
                  value={oldVehicleNumber.prefix}
                  onChange={onChangeOldFieldPrefix}
                  disabled={selectedVehicle.status}
                />
                <TextField 
                  className={styles.field} 
                  label="Suffix"
                  value={oldVehicleNumber.suffix}
                  onChange={onChangeOldFieldSuffix}
                  disabled={selectedVehicle.status}
                />
              </div>
              <div className={styles.fields}>
                <TextField 
                  className={styles.field} 
                  label="From Vehicle Number"
                  value={oldVehicleNumber.fromVehicleNumber} 
                  onChange={onChangeOldFieldFromVehicleNumber}
                />
                <TextField 
                  className={styles.field} 
                  label="To Vehicle Number"
                  value={oldVehicleNumber.toVehicleNumber}  
                  onChange={onChangeOldFieldToVehicleNumber}
                />
              </div>
            </div>
            <div>
              <Text variant="large" className={styles.highlight}>New Vehicle Number</Text>
              <div className={styles.fields}>
                <TextField
                  className={styles.field}
                  label="Prefix"
                  value={newVehicleNumber.prefix}
                  onChange={onChangeNewFieldPrefix}
                  disabled={selectedVehicle.status}
                />
                <TextField 
                  className={styles.field} 
                  label="Suffix"
                  value={newVehicleNumber.suffix}
                  onChange={onChangeNewFieldSuffix}
                  disabled={selectedVehicle.status}
                />
              </div>
              <div className={styles.fields}>
                <TextField 
                  className={styles.field} 
                  label="From Vehicle Number"
                  value={newVehicleNumber.fromVehicleNumber} 
                  onChange={onChangeNewFieldFromVehicleNumber}
                />
                <TextField 
                  className={styles.field} 
                  label="To Vehicle Number"
                  value={newVehicleNumber.toVehicleNumber}  
                  onChange={onChangeNewFieldToVehicleNumber}
                />
              </div>
            </div>
          </div>
        </PivotItem>
        <PivotItem headerText={renumberRegisterVehicleTabs.singleVehicle} itemKey={renumberRegisterVehicleTabs.singleVehicle}>
          <SeparatorGy />
          <div className={styles.vehicleNumberSections}>
            <div>
              <Text variant="large" className={styles.highlight}>Old Vehicle Number</Text>
              <div className={styles.fields}>
                <TextField
                  className={styles.field}
                  label="Prefix"
                  value={oldVehicleNumber.prefix}
                  onChange={onChangeOldFieldPrefix}
                  disabled={selectedVehicle.status}
                />
                <TextField 
                  className={styles.field} 
                  label="Suffix"
                  value={oldVehicleNumber.suffix}
                  onChange={onChangeOldFieldSuffix}
                  disabled={selectedVehicle.status}
                />
              </div>
              <div className={styles.fields}>
                <TextField 
                  className={styles.field} 
                  label="Vehicle Number"
                  value={oldVehicleNumber.fromVehicleNumber} 
                  onChange={onChangeOldFieldFromVehicleNumber} 
                />
              </div>
            </div>
            <div>
              <Text variant="large" className={styles.highlight}>New Vehicle Number</Text>
              <div className={styles.fields}>
                <TextField
                  className={styles.field}
                  label="Prefix"
                  value={newVehicleNumber.prefix}
                  onChange={onChangeNewFieldPrefix}
                  disabled={selectedVehicle.status}
                />
                <TextField 
                  className={styles.field} 
                  label="Suffix"
                  value={newVehicleNumber.suffix}
                  onChange={onChangeNewFieldSuffix}
                  disabled={selectedVehicle.status}
                />
              </div>
              <div className={styles.fields}>
                <TextField 
                  className={styles.field} 
                  label="Vehicle Number"
                  value={newVehicleNumber.fromVehicleNumber} 
                  onChange={onChangeNewFieldFromVehicleNumber}
                />
              </div>
            </div>
          </div>
        </PivotItem>
      </Pivot>
      <div className={styles.actionButtons}>
        <DefaultButton
          text="Clear"
          id="clearFieldsButton"
          className={styles.actionButton}
          onClick={handleClearButton}
          disabled={!userPermissions.isWrite}
        />
        <PrimaryButton
          text="Update"
          id="updateRenumberButton"
          className={styles.actionButton}
          disabled={!shouldShowUpdate || !userPermissions.isWrite}
          onClick={handleUpdate}
        />
      </div>
    </div>
  );
};

export default RenumberRegisterVehicle;