import { DefaultButton, IColumn, IconButton, MessageBarType, PrimaryButton, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { get } from 'lodash';
import moment from 'moment';
import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import apiService from '../../../../../../../api';
import useNotifications from '../../../../../../../hooks/useNotifications';
import { customerSelector } from '../../../../../../../redux/recordKeepingSlice';
import LoadingScreen from '../../../../../../LoadingScreen/LoadingScreen';
import { downloadFile, printingTypes } from '../../../../../../PrintingModal/consts';
import PrintingModal from '../../../../../../PrintingModal/PrintingModal';
import SeparatorGy from '../../../../../../SeparatorGy/SeparatorGy';
import { columns, modalViews } from './consts';
import { IReconciliationReportProps } from './IReconciliationReportProps';
import { IReconciliationReportState } from './IReconciliationReportState';
import Notify from './Notify/Notify';
import { IReconciliationDetailsModalProps } from './ReconciliationDetails/IReconciliationDetailsModalProps';
import ReconciliationDetailsModal from './ReconciliationDetails/ReconciliationDetailsModal';
import styles from './ReconciliationReport.module.scss';
import RecordAsLostModal from './RecordAsLost/RecordAsLostModal';
import CostReportModal from './CostReport';


const ReconciliationReport: FC<IReconciliationReportProps> = ({onDismiss, selectedLocations, period, isAnnual, postedElement}): ReactElement => {
  const [state, setState] = useState<IReconciliationReportState>({
    items: [],
    loading: false,
    foundCount: 0,
  });
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [showCostReport, { toggle: toggleShowCostReport }] = useBoolean(false);
  const [showNotify, { toggle: toggleShowNotify }] = useBoolean(false);
  const [isSearchCollapsed, { toggle: toggleSearchCollapsed }] = useBoolean(false);
  const [currentView, setCurrentView] = useState<string>(modalViews.RECONCILIATION_REPORT);
  const [groupingMode, setGroupingMode] = useState(false);
  const [reconciliationDetailsData, setReconciliationDetailsData] = useState<IReconciliationDetailsModalProps>({
    title: '',
    period: period,
    onDismiss: () => setCurrentView(modalViews.RECONCILIATION_REPORT),
    reconcileType: '',
    locationIds: [],
    postedElement: null,
  });
  const { addNotification } = useNotifications();
  const { id: customerId, customerName } = useSelector(customerSelector);
  const containerRef = useRef<HTMLDivElement>(null);


  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    
    setColumnsState(newColumns);
  };

  const handleOpenReconciliationDetails = (title: string, reconcileType: string, locationIds: Array<string>) => {
    setCurrentView(modalViews.RECONCILIATION_REPORT_DETAILS);
    setReconciliationDetailsData((prev) => ({...prev, title, reconcileType, locationIds, postedElement }));
  };

  const handleOpenRecordAsLost = () => {
    setCurrentView(modalViews.RECORD_AS_LOST);
    setReconciliationDetailsData((prev) => ({...prev, postedElement, locationIds: selectedLocations }));
  };

  const fetchReconciliationSummary = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data }: any = await apiService.spareStock.getReconciliationSummary(
        customerId,
        groupingMode ? [] : selectedLocations,
        period,
      );
      setState((prev: any) => ({ ...prev, items: [data] }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Reconciliation Summary fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const requestData = {
        customerId,
        locationIds: groupingMode ? [] : selectedLocations,
        period,
      };
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.spareStock.printExcelReconciliationSummary(requestData, []) :
        await apiService.spareStock.printPdfReconciliationSummary(requestData, []);
      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Printing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    fetchReconciliationSummary();
  }, [groupingMode]);

  return (
    <>
      {currentView === modalViews.RECONCILIATION_REPORT ? (
        <div className={classNames(styles.detailsWrapper)}>
          <div className={classNames(styles.detailsBackground)}>
            <div className={classNames(styles.detailsContainer)} ref={containerRef}>
              <div className={classNames(styles.detailsHeader)}>
                <Text variant="xxLarge" className={styles.highlight}>{customerName} | Reconciliation Report</Text>
                <IconButton
                  iconProps={{ iconName: 'Cancel' }}
                  title="close"
                  onClick={() => onDismiss()}
                />
              </div>
              <div className={classNames(styles.scroll)}>
                {
                  state.items.map((element, index) => (
                    <div className={classNames(styles.detailsBody)}>
                      <div className={classNames(styles.infoBlock, isSearchCollapsed ? styles.collapsedBlock : null)}>
                        <div>
                          <div>
                            <Text variant="large" className={styles.highlight}>{element.statistics.asOf ? moment(element.statistics.asOf).format('MM/DD/YYYY') : 'N/A'}</Text>
                            <Text variant="medium" className={styles.highlight}>System Statistics as Of</Text>
                          </div>
                          <div>
                            <Text variant="large" className={styles.highlight}>{get(element, 'statistics.tiresOnVehicle', 'N/A').toLocaleString('en-US')}</Text>
                            <Text variant="medium" className={styles.highlight}>Tires On Vehicle</Text>
                          </div>
                          <div>
                            <Text variant="large" className={styles.highlight}>{get(element, 'statistics.tiresInSpareStock', 'N/A').toLocaleString('en-US')}</Text>
                            <Text variant="medium" className={styles.highlight}>Tires In Spare Stock</Text>
                          </div>
                          <div>
                            <Text variant="large" className={styles.highlight}>{get(element, 'statistics.oosTires', 'N/A').toLocaleString('en-US')}</Text>
                            <Text variant="medium" className={styles.highlight}>OOS Tires</Text>
                          </div>
                        </div>
                        <SeparatorGy vertical />
                        <div>
                          <div>
                            <Text variant="large" className={styles.highlight}>{element.consignedTires !== null ? element.consignedTires.toLocaleString('en-US') : 'N/A'}</Text>
                            <Text variant="medium" className={styles.highlight}>Total Consigned Tires for this account</Text>
                          </div>
                          <div>
                            <Text variant="large" className={styles.highlight}>{element.matched !== null ? element.matched.toLocaleString('en-US') : 'N/A'}</Text>
                            <Text variant="medium" className={styles.highlight}>Matched</Text>
                          </div>
                          <div>
                            <Text variant="large" className={styles.highlight}>{element.tiresInSystemSpareStock !== null ? element.tiresInSystemSpareStock.toLocaleString('en-US') : 'N/A'}</Text>
                            <Text variant="medium" className={styles.highlight}>Tires In System Spare Stock</Text>
                          </div>
                          <div>
                            <Text variant="large" className={styles.highlight}>{element.tiresInCustomerSpareStock !== null ? element.tiresInCustomerSpareStock.toLocaleString('en-US') : 'N/A'}</Text>
                            <Text variant="medium" className={styles.highlight}>Tires In Customer Spare Stock</Text>
                          </div>
                        </div>
                      </div>
                      <SeparatorGy />
                      <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
                        <DefaultButton id="searchCollapsedBtn" onClick={toggleSearchCollapsed}>
                          {isSearchCollapsed ? 'Expand' : 'Collapse'}
                        </DefaultButton>
                      </div>
                      <SeparatorGy />
                      <div className={styles.refreshButton}>
                        <IconButton
                          id="refreshButton"
                          iconProps={{ iconName: 'Refresh' }} 
                          onClick={fetchReconciliationSummary} 
                        />
                      </div>
                      <div className={styles['table-wrapper']}>
                        <table>
                          <thead>
                            <tr>
                              <th></th>
                              {
                                columnsState.map(item => (
                                  <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                                ))
                              }
                            </tr>
                          </thead>
                          <tbody>
                            {
                              element.areas.map((item: any) => (
                                <tr>
                                  <td>
                                    <IconButton
                                      id="viewButton"
                                      iconProps={{ iconName: 'View' }}
                                      onClick={() => handleOpenReconciliationDetails(item.name, item.type, groupingMode ? [] : selectedLocations)}
                                    />
                                  </td>
                                  {
                                    columnsState.map((column) => (
                                      <td>
                                        {item[column.fieldName]}
                                      </td>
                                    ))
                                  }
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                      <SeparatorGy />
                    </div>
                  ))
                }
              </div>
              <div className={classNames(styles.detailsFooter)}>
                <DefaultButton id="group" onClick={() => setGroupingMode(true)} text="Group" />
                <DefaultButton id="printButton" onClick={toggleShowPrintExport} text="Print/Export" />
                <PrimaryButton id="costReportButton" onClick={toggleShowCostReport} text="Cost Report" disabled={!isAnnual}/>
                <PrimaryButton id="notifyButton" onClick={toggleShowNotify} text="Notify" disabled={!isAnnual}/>
                <PrimaryButton id="recordAsLostBtn" onClick={handleOpenRecordAsLost} text="Record as Lost" disabled={!isAnnual}/>
              </div>
            </div>
          </div>
        </div>
      ) : currentView === modalViews.RECONCILIATION_REPORT_DETAILS ? (
        <ReconciliationDetailsModal {...reconciliationDetailsData} />
      ) : currentView === modalViews.RECORD_AS_LOST ? (
        <RecordAsLostModal {...reconciliationDetailsData} />
      ) : null
      }
      <CostReportModal
        isOpened={showCostReport}
        onClose={toggleShowCostReport}
        period={period}
        customerId={customerId}
        locationId={selectedLocations[0]}
      />
      <Notify
        isOpened={showNotify}
        period={period}
        onClose={toggleShowNotify}
      />
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint} 
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default ReconciliationReport;