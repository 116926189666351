import { fieldType } from '../../../../../../consts/fieldType';

export const emptyRebrandTire = {
  pfx: '',
  bno: '',
  sfx: '',
  newPfx: '',
  newBrand: '',
  newSfx: '',
};

export const columns = [
  {
    key: 'column1',
    name: '*Pfx',
    fieldName: 'pfx',
    isEditable: true,
    isSorted: true,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column2',
    name: '*Brand',
    fieldName: 'bno',
    isEditable: true,
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column3',
    name: 'Sfx',
    fieldName: 'sfx',
    isEditable: true,
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column4',
    name: '*New Pfx',
    fieldName: 'newPfx',
    isEditable: true,
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column5',
    name: '*New Brand',
    fieldName: 'newBrand',
    isEditable: true,
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column6',
    name: 'New Sfx',
    fieldName: 'newSfx',
    isEditable: true,
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
];