import React, {
  FC,
  ReactElement,
} from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';

import styles from './LoadingScreen.module.scss';

const LoadingScreen: FC = (): ReactElement => (
  <Spinner size={SpinnerSize.large} className={styles.spinner} />
);

export default LoadingScreen;
