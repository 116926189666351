import React, { FC, FunctionComponent, ReactElement, useEffect } from 'react';
import classNames from 'classnames';
import { Pivot, PivotItem } from '@fluentui/react';

import { IInTransitTireTabProps } from './IInTransitTireTabProps';
import { inTransitTiresTabs } from './consts';
import { useSelector } from 'react-redux';

import TabHeader from '../../../RecordKeeping/tabComponents/TabHeader/TabHeader';
import { activeSubTabSelector } from '../../../../redux/tireOrderingSlice';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { getSubTabsById, TIREORDER_TABS } from '../../consts';
import SeparatorGy from '../../../SeparatorGy/SeparatorGy';

const InTransitTiresTab: FC<IInTransitTireTabProps> = (): ReactElement => {

  const [selectedKey, setSelectedKey] = React.useState(inTransitTiresTabs.transfer.name);
  const activeSubTab = useSelector(activeSubTabSelector);

  const { getPermissionsByAccessKey } = useUserPermissions();

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  const renderTab = (TabComponent: FunctionComponent) => {
    if (TabComponent) return <TabComponent />;
  };


  useEffect(() => {
    setSelectedKey(activeSubTab);
  }, [activeSubTab]);

  return (
    <div>
      <SeparatorGy />
      <Pivot
        className={classNames('pivot-container')}
        selectedKey={selectedKey}
        onLinkClick={handleLinkClick}
        getTabId={getTabId}
        linkFormat="tabs"
      >
        {Object.values(getSubTabsById(TIREORDER_TABS.INTRANSIT_TIRES)).map(
          ({ accessKey, name, component }: any, index) =>
            getPermissionsByAccessKey(accessKey)?.isAccess && (
              <PivotItem headerText={name} key={index} itemKey={name}>
                <div className="">{renderTab(component)}</div>
              </PivotItem>
            ))}
      </Pivot>
    </div>
  );
};

export default InTransitTiresTab;