import React, { useRef, useState } from 'react';
import { IconButton, MessageBarType, TextField } from '@fluentui/react';
import apiService from '../../../../api';
import useNotifications from '../../../../hooks/useNotifications';
import { useDispatch } from 'react-redux';
import { toggleReloadSetBillingDataTab } from '../../../../redux/billingSlice';


function EditManageBillingCodes({ row, index }: any) {
  const [showEditButton, setShowEditButton] = useState(true);
  const [showConfirmActions, setShowConfirmActions] = useState(false);
  const [showRow, setShowRow] = useState(false);
  const [departmentCode, setDepartmentCode] = useState(row.departmentCode);
  const [billingTypeCode, setBillingTypeCode] = useState(row.billingTypeCode);
  const [otherCode, setOtherCode] = useState(row.otherCode);
  const [radialCode, setRadialCode] = useState(row.radialCode);
  const [biasCode, setBiasCode] = useState(row.biasCode);
  const [msgText1, setMsgText1] = useState(row.msgText1);
  const [msgText2, setMsgText2] = useState(row.msgText2);
  const [creditMsgText1, setCreditMsgText1] = useState(row.creditMsgText1);
  const [creditMsgText2, setCreditMsgText2] = useState(row.creditMsgText2);
  const iconsRef = useRef<any>(null);
  const { addNotification } = useNotifications();
  const dispatch = useDispatch();
  const table = document.getElementById('billingCodes');
  const tdWidth = table?.getElementsByTagName('thead')[0]?.getElementsByTagName('tr')[0]?.getElementsByTagName('th')[0].offsetWidth || 124;
  const billingWidth = table?.getElementsByTagName('thead')[0]?.getElementsByTagName('tr')[0]?.getElementsByTagName('th')[1].offsetWidth || 124;
  const departmentWidth = table?.getElementsByTagName('thead')[0]?.getElementsByTagName('tr')[0]?.getElementsByTagName('th')[2].offsetWidth || 124;
  const typeWidth = table?.getElementsByTagName('thead')[0]?.getElementsByTagName('tr')[0]?.getElementsByTagName('th')[3].offsetWidth || 124;
  const otherWidth = table?.getElementsByTagName('thead')[0]?.getElementsByTagName('tr')[0]?.getElementsByTagName('th')[4].offsetWidth || 124;
  const radialWidth = table?.getElementsByTagName('thead')[0]?.getElementsByTagName('tr')[0]?.getElementsByTagName('th')[5].offsetWidth || 124;
  const biasWidth = table?.getElementsByTagName('thead')[0]?.getElementsByTagName('tr')[0]?.getElementsByTagName('th')[6].offsetWidth || 124;
  const text1Width = table?.getElementsByTagName('thead')[0]?.getElementsByTagName('tr')[0]?.getElementsByTagName('th')[7].offsetWidth || 124;
  const text2Width = table?.getElementsByTagName('thead')[0]?.getElementsByTagName('tr')[0]?.getElementsByTagName('th')[8].offsetWidth || 124;
  const credit1Width = table?.getElementsByTagName('thead')[0]?.getElementsByTagName('tr')[0]?.getElementsByTagName('th')[9].offsetWidth || 124;
  const credit2Width = table?.getElementsByTagName('thead')[0]?.getElementsByTagName('tr')[0]?.getElementsByTagName('th')[10].offsetWidth || 124;
  const tdHeight = table?.getElementsByTagName('tbody')[0]?.getElementsByTagName('tr')[index]?.getElementsByTagName('td')[0].offsetHeight || 110;

  const updateBillingCodes = async () => {
    try {
      await apiService.billingAPI.updateBillingCodesList({
        billingCodeId: row.billingCodeId,
        departmentCode,
        billingTypeCode,
        otherCode,
        radialCode,
        biasCode,
        msgText1,
        msgText2,
        creditMsgText1,
        creditMsgText2,
      });
      dispatch(toggleReloadSetBillingDataTab());
      addNotification({
        text: 'Billing codes updated successfully',
        type: MessageBarType.success,
      });
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Billing codes data updating error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  return (
    <div
      ref={iconsRef}
      style={{
        position: 'relative',
        display: 'flex',
      }}>
      {
        showEditButton && <IconButton
          id='viewButton'
          iconProps={{ iconName: 'Edit' }}
          onClick={() => {
            setShowEditButton(false);
            setShowConfirmActions(true);
            setShowRow(true);
          }}
        />
      }
      {
        showConfirmActions && (
          <div>
            <IconButton
              iconProps={{ iconName: 'CheckMark' }}
              onClick={() => {
                setShowEditButton(true);
                setShowConfirmActions(false);
                setShowRow(false);
                updateBillingCodes();
              }}
            />
            <IconButton
              iconProps={{ iconName: 'StatusCircleBlock' }}
              onClick={() => {
                setShowEditButton(true);
                setShowConfirmActions(false);
                setShowRow(false);
              }}
            />
          </div>
        )
      }
      {

        showRow && (
          <div style={{
            position: 'absolute',
            left: (tdWidth - 12) + 'px',
            background: 'white',
            top: (((tdHeight) / 3) * -1) + 'px',//((((iconsRef?.current?.clientHeight) - (tdHeight + 12)) / 4)) + 'px',
            height: (tdHeight - 12) + 'px',
            width: ((table?.clientWidth || 1413)) - ((tdWidth || (124 + 12) + 12)) + 'px',
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <div style={{
              width: billingWidth + 'px',
              paddingLeft: '5px',
            }}>
              {row.billingType}
            </div>
            <TextField
              onChange={(event) => {
                setDepartmentCode(event.currentTarget.value);
              }}
              value={departmentCode} style={{
                width: (departmentWidth - 5) + 'px',
                padding: '0.5em 0.75em 0.5em 0.75em',
              }} />
            <TextField
              onChange={(event) => {
                setBillingTypeCode(event.currentTarget.value);
              }}
              value={billingTypeCode} style={{
                width: (typeWidth - 5) + 'px',
                padding: '0.5em 0.75em 0.5em 0.75em',
              }} />
            <TextField
              onChange={(event) => {
                setOtherCode(event.currentTarget.value);
              }}
              value={otherCode} style={{
                width: (otherWidth - 5) + 'px',
                padding: '0.5em 0.75em 0.5em 0.75em',
              }} />
            <TextField
              onChange={(event) => {
                setRadialCode(event.currentTarget.value);
              }}
              value={radialCode} style={{
                width: (radialWidth - 5) + 'px',
                padding: '0.5em 0.75em 0.5em 0.75em',
              }} />
            <TextField
              onChange={(event) => {
                setBiasCode(event.currentTarget.value);
              }}
              value={biasCode} style={{
                width: (biasWidth - 5) + 'px',
                padding: '0.5em 0.75em 0.5em 0.75em',
              }} />
            <TextField
              onChange={(event) => {
                setMsgText1(event.currentTarget.value);
              }}
              value={msgText1} style={{
                width: (text1Width - 5) + 'px',
                padding: '0.5em 0.75em 0.5em 0.75em',
              }} />
            <TextField
              onChange={(event) => {
                setMsgText2(event.currentTarget.value);
              }}
              value={msgText2} style={{
                width: (text2Width - 5) + 'px',
                padding: '0.5em 0.75em 0.5em 0.75em',
              }} />
            <TextField
              onChange={(event) => {
                setCreditMsgText1(event.currentTarget.value);
              }}
              value={creditMsgText1} style={{
                width: (credit1Width - 5) + 'px',
                padding: '0.5em 0.75em 0.5em 0.75em',
              }} />
            <TextField
              onChange={(event) => {
                setCreditMsgText2(event.currentTarget.value);
              }}
              value={creditMsgText2} style={{
                width: (credit2Width - 5) + 'px',
                padding: '0.5em 0.75em 0.5em 0.75em',
              }} />
          </div>
        )
      }
    </div>
  );
};

export default EditManageBillingCodes;
