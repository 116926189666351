import { tireOrderingTestInstance } from '../axios';

const getAvailableBrands = (customerId: string, brandPrefix: string, pagination: any, sortOrder: any) => 
  tireOrderingTestInstance.post('/order/available-brand', {customerId, brandPrefix, pagination, sortOrder});

const selectBrandNumber = (brand: any) =>
  tireOrderingTestInstance.post('/order/brand-numbers', brand);

export default {
  getAvailableBrands,
  selectBrandNumber,
};
