import { ChoiceGroup, DatePicker, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IChoiceGroupOption, IColumn, IconButton, IDropdownOption, MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { get } from 'lodash';
import moment from 'moment';
import { FC, FormEvent, KeyboardEvent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import apiService from '../../../../../../api';
import { sortOrder } from '../../../../../../consts/sortOrder';
import useNotifications from '../../../../../../hooks/useNotifications';
import { TemporaryTireStatusesEnum } from '../../../../../../models/TemporaryTireStatusesEnum';
import { customerSelector, locationSelector } from '../../../../../../redux/recordKeepingSlice';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';
import Pagination from '../../../../../Pagination/Pagination';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import { pageSizes } from '../../../../../../consts/recordKeeping';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import { availableInTransitTiresColumns, defaultShortageDetails, shortageDetailsReasons, shortInTransitTiresColumns } from './consts';
import { IRecordShortageInShipmentProps } from './IRecordShortageInShipmentProps';
import { IRecordShortageInShipmentState } from './IRecordShortageInShipmentState';
import styles from './RecordShortageInShipment.module.scss';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_inTransitTires_recordShortageInShipment } from '../../../../../../consts/programKeys';
import { invoiceDelivNoteEMHandler } from '../../../../../../shared/TextFieldValidation';
import { transformDate } from '../../../../../../shared/transformDate';
import { inTransitOrderCodeSelector } from '../../../../../../redux/tireOrderingSlice';

const RecordShortageInShipment: FC<IRecordShortageInShipmentProps> = (): ReactElement => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_inTransitTires_recordShortageInShipment);

  const onColumnClick = (column: IColumn, columnsState: any[], setColumnsState: (value: any) => any): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    setColumnsState(newColumns);
  };

  const { addNotification } = useNotifications();

  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);
  const orderCode = useSelector(inTransitOrderCodeSelector);

  const [state, setState] = useState<IRecordShortageInShipmentState>({
    loading: false,
    orderDetails: null,
    selectedAvailableITTItems: [],
    selectedShortITTItems: [],
    foundAvailableITTCount: 0,
    foundShortITTCount: 0,
    availableITTItems: [],
    shortITTItems: [],
  });

  const [orderId, setOrderId] = useState<any>(null);
  const [selectionFields, setSelectionFields] = useState<any>({});
  const [shortageDetails, setShortageDetails] = useState<any>(defaultShortageDetails);
  const [availableITTcountOnPage, setAvailableITTCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [shortITTcountOnPage, setShortITTCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [availableITTPaginationProps, setAvailableITTPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setAvailableITTPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [shortITTPaginationProps, setShortITTPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setShortITTPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [availableITTColumnsState, setAvailableITTColumnsState] = useState<Array<any>>(availableInTransitTiresColumns);
  const [shortITTColumnsState, setShortITTColumnsState] = useState<Array<any>>(shortInTransitTiresColumns);

  const [isDeletingShortITTDialogVisible, { toggle: toggleDeletingShortITTConfirmation }] = useBoolean(false);
  const [isSubmittingShortITTDialogVisible, { toggle: toggleSubmittingShortITTConfirmation }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);

  const onChangeOrderId = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, orderId: string | undefined) => {
    setOrderId(orderId?.toUpperCase());
  };

  const onSelectSeriesFieldChange = (selectionType: string, selectionField: string, value: any) =>
    setSelectionFields((prev: any) => ({ ...prev, [selectionType]: { ...prev[selectionType], [selectionField]: value } }));

  const handleSelectAvailableITT = (e: any, item: any) => {
    const selectedRows = [...state.selectedAvailableITTItems];
    if (e.target.checked) {
      selectedRows.push(item);
      setState((prev: any) => ({ ...prev, selectedAvailableITTItems: selectedRows }));
    } else {
      setState((prev: any) => ({ ...prev, selectedAvailableITTItems: selectedRows.filter(row => row.inTransitTireId !== item.inTransitTireId) }));
    }
  };

  const handleSelectShortITT = (e: any, item: any) => {
    const selectedRows = [...state.selectedShortITTItems];
    if (e.target.checked) {
      selectedRows.push(item);
      setState((prev: any) => ({ ...prev, selectedShortITTItems: selectedRows }));
    } else {
      setState((prev: any) => ({ ...prev, selectedShortITTItems: selectedRows.filter(row => row.inTransitTireAltId !== item.inTransitTireAltId) }));
    }
  };

  const handleSelectAllAvailableITT = (e: any, items: Array<any>) => {
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedAvailableITTItems: items })) : setState((prev: any) => ({ ...prev, selectedAvailableITTItems: [] }));
  };

  const handleSelectAllShortITT = (e: any, items: Array<any>) => {
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedShortITTItems: items })) : setState((prev: any) => ({ ...prev, selectedShortITTItems: [] }));
  };

  const onChangeAvailableITTCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setAvailableITTPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setAvailableITTCountOnPage(item);
  };

  const onChangeShortITTCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setShortITTPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setShortITTCountOnPage(item);
  };

  const getAvailableITTSortOrder = () => {
    const { fieldName, isSortedDescending } = availableITTColumnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const getShortITTSortOrder = () => {
    const { fieldName, isSortedDescending } = shortITTColumnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const fetchDelNumList = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data }: any = await apiService.inTransitTiresAPI.getDelNumList(orderCode?.id || orderId);
      const orderDetails = get(data, 'data[0]');
      setState((prev) => ({ ...prev, orderDetails }));
      if (orderDetails) {
        fetchAvailableITT();
        fetchShortITT();
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Order Details fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchAvailableITT = async (selectionFields?: any) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getAvailableITTSortOrder();
      const { data }: any = await apiService.inTransitTiresAPI.getAvailableInTransitTires(
        selectionFields ? undefined : { pageNumber: availableITTPaginationProps.current, pageSize: +availableITTcountOnPage.key },
        {
          orderId: orderCode?.id || orderId,
          customerId,
          locationId,
          inTransitTiresSelectRange: selectionFields,
        },
        sortOrder,
      );
      const foundAvailableITTCount = data.total.found;
      const availableITTItems = data.data;
      if (selectionFields) {
        setState((prev: any) => ({ ...prev, selectedAvailableITTItems: availableITTItems }));
      }
      else {
        setState((prev: any) => ({ ...prev, availableITTItems, foundAvailableITTCount }));
        setAvailableITTPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundAvailableITTCount / +availableITTcountOnPage.key) }));
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Available In Transit Tires fetching error: ${response?.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchShortITT = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getShortITTSortOrder();
      const { data }: any = await apiService.inTransitTiresAPI.getTemporaryTires(
        { pageNumber: shortITTPaginationProps.current, pageSize: +shortITTcountOnPage.key },
        {
          customerId: customerId,
          status: TemporaryTireStatusesEnum.PENDING_RECORDSHORTAGEINSHIPMENT,
        },
        sortOrder,
      );
      const foundShortITTCount = data.total.found;
      const shortITTItems = data.data;
      setState((prev: any) => ({ ...prev, shortITTItems, foundShortITTCount }));
      setShortITTPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundShortITTCount / +shortITTcountOnPage.key) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Short In Transit Tires fetching error: ${response?.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleSubmit = async () => {
    setState(prev => ({ ...prev, loading: true }));
    if (isSubmittingShortITTDialogVisible) {
      toggleSubmittingShortITTConfirmation();
    }
    try {
      await apiService.inTransitTiresAPI.submitShortInTransitTires(state.selectedShortITTItems.map(el => el.inTransitTireAltId));
      setState(prev => ({ ...prev, selectedShortITTItems: [] }));
      fetchShortITT();
      addNotification({
        text: 'Short In-Transit tires were successfully submitted.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Short In-Transit tires submitting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
    finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    try {

      const inTransitTiresGetInput = {
        pagination: {
          pageSize: availableITTcountOnPage?.text,
          pageNumber: availableITTPaginationProps?.current,
        },
        filters: {
          orderId: orderId,
          customerId: customerId,
          locationId: locationId,
        },
        sortOrder: getAvailableITTSortOrder(),
      };

      const inTransitTiresAltGetInput = {
        pagination: {
          pageSize: shortITTcountOnPage?.text,
          pageNumber: shortITTPaginationProps?.current,
        },
        filters: {
          customerId: customerId,
          status: TemporaryTireStatusesEnum.PENDING_RECORDSHORTAGEINSHIPMENT,
        },
        sortOrder: getShortITTSortOrder(),
      };

      const requestData = {
        inTransitTiresGetInput: inTransitTiresGetInput,
        inTransitTiresAltGetInput: inTransitTiresAltGetInput,
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.inTransitTiresAPI.printExcel(requestData) :
        await apiService.inTransitTiresAPI.printPdf(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
      toggleShowPrintExport();
    } catch (e: any) {
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleMoveSelected = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      await apiService.inTransitTiresAPI.moveTiresToTemporary(
        {
          ids: state.selectedAvailableITTItems.map(el => el.inTransitTireId),
          status: TemporaryTireStatusesEnum.PENDING_RECORDSHORTAGEINSHIPMENT,
          fShort: shortageDetails.reason.key,
          shortDoc: shortageDetails.shortDoc,
          shortDate: transformDate(moment(shortageDetails.shortDate).format('MM/DD/YYYY')),
          comments: shortageDetails.comments,
        },
      );
      setState((prev: any) => ({ ...prev, selectedAvailableITTItems: [] }));
      setShortageDetails(defaultShortageDetails);
      fetchAvailableITT();
      fetchShortITT();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Available In Transit Tires moving error: ${response?.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleMoveSelectedBack = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      await apiService.inTransitTiresAPI.moveTiresBackToMainTable(state.selectedShortITTItems.map(el => el.inTransitTireAltId));
      setState((prev: any) => ({ ...prev, selectedShortITTItems: [] }));
      fetchAvailableITT();
      fetchShortITT();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Available In Transit Tires moving error: ${response?.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleDeleteShortITT = async () => {
    toggleDeletingShortITTConfirmation();
    setState(prev => ({ ...prev, loading: true }));
    try {
      await apiService.inTransitTiresAPI.deleteTemporaryTires(state.selectedShortITTItems.map(el => el.inTransitTireAltId));
      setState((prev: any) => ({ ...prev, selectedShortITTItems: [] }));
      fetchShortITT();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Short In Transit Tires deletion error: ${response?.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (orderCode) {
      fetchDelNumList();
    }
  }, [orderCode]);

  useEffect(() => {
    if (state.orderDetails) {
      fetchAvailableITT();
    }
  }, [
    availableITTPaginationProps.current,
    availableITTcountOnPage,
    availableITTColumnsState,
    customerId,
  ]);

  useEffect(() => {
    if (state.orderDetails) {
      fetchShortITT();
    }
  }, [
    shortITTPaginationProps.current,
    shortITTcountOnPage,
    shortITTColumnsState,
    customerId,
  ]);

  return (
    <div id="container">
      <div className={styles.actionsBlock}>
        <div>
          <Text variant="xLarge" className={styles.highlight}>Order Details</Text>
          <SeparatorGy />
          <div className={styles.orderDetails}>
            <TextField
              id="orderIdInput"
              className={styles.orderDetailsField}
              label="Order #"
              value={orderCode?.id || orderId}
              onChange={onChangeOrderId}
              onKeyPress={(ev: KeyboardEvent) => orderId && ev.key === 'Enter' && fetchDelNumList()}
              disabled={!!state.orderDetails?.orderId}
            />
            {
              !state.orderDetails?.orderId && 
                <IconButton
                  className={styles.iconButton}
                  id="searchOrderDetailsButton"
                  iconProps={{ iconName: 'Search' }}
                  onClick={fetchDelNumList}
                  disabled={!orderId}
                />
            }
            {
              state.orderDetails && 
                <>
                  <DatePicker
                    id="shipDate"
                    className={styles.orderDetailsField}
                    label="Date Shipped"
                    value={moment(state.orderDetails.dataShipped).toDate()}
                    formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                    disabled={true}
                  />
                  <TextField
                    className={styles.orderDetailsField}
                    label="Invoice #"
                    value={state.orderDetails.invcNo}
                    disabled={true}
                  />
                  <TextField
                    className={styles.orderDetailsField}
                    label="DL #"
                    value={state.orderDetails.gdyrDelNo}
                    disabled={true}
                  />
                </>
            }
          </div>
          {
            state.orderDetails && 
              <div>
                <Text variant="xLarge" className={styles.highlight}>Select Series</Text>
                <SeparatorGy />
                <div className={styles.selectSeries}>
                  <div>
                    <Text variant="xLarge" className={styles.highlight}>Begin</Text>
                    <div id="beginSeriesFields" className={styles.selectFields}>
                      <TextField
                        className={styles.selectField}
                        label="Prefix"
                        value={get(selectionFields, 'beginBrand.prefix')}
                        onChange={(_ev, value) => onSelectSeriesFieldChange('beginBrand', 'prefix', value?.toUpperCase())}
                      />
                      <TextField
                        className={styles.selectField}
                        label="Brand"
                        type="number"
                        value={get(selectionFields, 'beginBrand.brandNo')}
                        onChange={(_ev, value) => onSelectSeriesFieldChange('beginBrand', 'brandNo', parseInt(value as any))}
                      />
                      <TextField
                        className={styles.selectField}
                        label="Suffix"
                        value={get(selectionFields, 'beginBrand.suffix')}
                        onChange={(_ev, value) => onSelectSeriesFieldChange('beginBrand', 'suffix', value?.toUpperCase())}
                      />
                    </div>
                  </div>
                  <div>
                    <Text variant="xLarge" className={styles.highlight}>End</Text>
                    <div id="endSeriesFields" className={styles.selectFields}>
                      <TextField
                        className={styles.selectField}
                        label="Prefix"
                        value={get(selectionFields, 'endBrand.prefix')}
                        onChange={(_ev, value) => onSelectSeriesFieldChange('endBrand', 'prefix', value?.toUpperCase())}
                      />
                      <TextField
                        className={styles.selectField}
                        label="Brand"
                        type="number"
                        value={get(selectionFields, 'endBrand.brandNo')}
                        onChange={(_ev, value) => onSelectSeriesFieldChange('endBrand', 'brandNo', parseInt(value as any))}
                      />
                      <TextField
                        className={styles.selectField}
                        label="Suffix"
                        value={get(selectionFields, 'endBrand.suffix')}
                        onChange={(_ev, value) => onSelectSeriesFieldChange('endBrand', 'suffix', value?.toUpperCase())}
                      />
                    </div>
                  </div>
                </div>
                <div className={classNames(styles.buttonsWrapper)}>
                  <PrimaryButton
                    id="selectButton"
                    onClick={() => fetchAvailableITT(selectionFields)}
                    text="Select Series"
                  />
                </div>
              </div>
          }
        </div>
        {
          state.orderDetails && 
            <div className={styles.shortageDetails}>
              <Text variant="xLarge" className={styles.highlight}>Shortage Details</Text>
              <SeparatorGy />
              <div className={styles.choiceField}>
                <Text variant="xLarge" block className={styles.highlight}>Reason:</Text>
                <ChoiceGroup
                  id="reason"
                  selectedKey={shortageDetails.reason.key}
                  options={Object.keys(shortageDetailsReasons).map(key => ({ key, text: get(shortageDetailsReasons, key) }))}
                  onChange={(ev: any, option: IChoiceGroupOption | undefined) => setShortageDetails((prev: any) => ({ ...prev, reason: option }))}
                />
              </div>
              <div className={styles.textFields}>
                <div className={styles.lineFields}>
                  <TextField
                    id="shortDocInput"
                    className={styles.textField}
                    label="Short Doc#"
                    value={shortageDetails.shortDoc}
                    onChange={(_ev, value) => setShortageDetails((prev: any) => ({ ...prev, shortDoc: value }))}
                    onGetErrorMessage={invoiceDelivNoteEMHandler}
                  />
                  <DatePicker
                    id="shortDate"
                    className={styles.textField}
                    label="Incident Date"
                    value={shortageDetails.shortDate}
                    formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                    onSelectDate={(date) => setShortageDetails((prev: any) => ({ ...prev, shortDate: date }))}
                  />
                </div>
                <TextField
                  id="comments"
                  className={styles.textField}
                  label="Comments"
                  multiline
                  rows={10}
                  cols={60}
                  value={shortageDetails.comments}
                  onChange={(_ev, value) => setShortageDetails((prev: any) => ({ ...prev, comments: value }))}
                />
              </div>
            </div>
        }
      </div>
      {
        state.orderDetails && 
          <div className={styles.inTransitTires}>
            <div className={styles.availableInTransitTires}>
              <div className={styles.tableHeading}>
                <PrimaryButton
                  id="moveSelectedButton"
                  onClick={handleMoveSelected}
                  text="Move selected ->"
                  disabled={(!(state.selectedAvailableITTItems.length && shortageDetails.shortDoc && shortageDetails.shortDate)) || !userPermissions.isWrite}
                />
              </div>
              <div className={styles.tableHeading}>
                <div>
                  <Text variant="xLarge" className={styles.highlight}>Available In-Transit Tires</Text>
                  <SeparatorGy vertical />
                  <Text variant="xLarge" className={styles.highlight}>{state.foundAvailableITTCount} found</Text>
                </div>
                <div>
                  <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                  <Dropdown
                    options={pageSizes.map(pageSize => ({
                      key: pageSize,
                      text: pageSize.toString(),
                    }))}
                    defaultSelectedKey={pageSizes[0]}
                    selectedKey={availableITTcountOnPage?.key}
                    onChange={onChangeAvailableITTCountOnPage}
                  />
                  <SeparatorGy vertical />
                  <Text variant="large" className={styles.highlight}>{state.selectedAvailableITTItems.length} items selected</Text>
                </div>
              </div>
              <div className={styles['table-wrapper']}>
                <table id="availableTable">
                  <thead>
                    <tr>
                      {
                        availableITTColumnsState.map(item => (
                          <th
                            key={item.name}
                            className={classNames(
                              item.isSorted && item.isSortedDescending ?
                                styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined,
                              styles[item.key],
                            )}
                            onClick={() => onColumnClick(item, availableITTColumnsState, setAvailableITTColumnsState)}>{item.name}
                          </th>
                        ))
                      }
                      <th className={styles.selectColumn}>
                        <div className={styles.round}>
                          <input
                            type="checkbox"
                            id="availableITTAll"
                            checked={state.availableITTItems.length !== 0 &&
                            (state.selectedAvailableITTItems.length === +availableITTcountOnPage.key
                              || state.selectedAvailableITTItems.length === state.availableITTItems.length
                              || state.selectedAvailableITTItems.length === state.foundAvailableITTCount)}
                            onChange={(e) => handleSelectAllAvailableITT(e, state.availableITTItems.map(item => item))}
                          />
                          <label htmlFor="availableITTAll"></label>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.availableITTItems.map(item => (
                      <tr key={item.inTransitTireId} className={classNames(state.selectedAvailableITTItems.find(el => el.inTransitTireId === item.inTransitTireId) ? styles.trSelected : styles.trBasic)} >
                        <td>{item.intireBrndPfx}</td>
                        <td>{item.intireBrndNum}</td>
                        <td>{item.intireBrndSfx}</td>
                        <td>{item.typeCodeFK}</td>
                        <td>{item.tc}</td>
                        <td>{item.dotNum}</td>
                        <td>
                          <div className={styles.round}>
                            <input
                              type="checkbox"
                              id={`availableITT-${item.inTransitTireId}`}
                              checked={!!state.selectedAvailableITTItems.find(el => el.inTransitTireId === item.inTransitTireId)}
                              onChange={(e) => handleSelectAvailableITT(e, item)} />
                            <label htmlFor={`availableITT-${item.inTransitTireId}`}></label>
                          </div>
                        </td>
                      </tr>
                    ))}
                    {!state.availableITTItems.length && <tr><td colSpan={7} className={styles.centeredColumn}>No records found</td></tr>}
                  </tbody>
                </table>
              </div>
              <SeparatorGy />
              <Pagination {...availableITTPaginationProps} />
            </div>
            <div className={styles.shortInTransitTires}>
              <div className={styles.tableHeading}>
                <PrimaryButton
                  id="moveSelectedBackButton"
                  onClick={handleMoveSelectedBack}
                  text="Move selected back to Available"
                  disabled={!state.selectedShortITTItems.length || !userPermissions.isWrite}
                />
              </div>
              <div className={styles.tableHeading}>
                <div>
                  <Text variant="xLarge" className={styles.highlight}>Short In-Transit Tires</Text>
                  <SeparatorGy vertical />
                  <Text variant="xLarge" className={styles.highlight}>{state.foundShortITTCount} found</Text>
                </div>
                <div>
                  <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                  <Dropdown
                    options={pageSizes.map(pageSize => ({
                      key: pageSize,
                      text: pageSize.toString(),
                    }))}
                    defaultSelectedKey={pageSizes[0]}
                    selectedKey={shortITTcountOnPage?.key}
                    onChange={onChangeShortITTCountOnPage}
                  />
                  <SeparatorGy vertical />
                  <Text variant="large" className={styles.highlight}>{state.selectedShortITTItems.length} items selected</Text>
                  <SeparatorGy vertical />
                  <IconButton
                    id="toggleDeletingConfirmationButton"
                    disabled={!state.selectedShortITTItems.length || !userPermissions.isWrite}
                    iconProps={{ iconName: 'Delete' }}
                    onClick={toggleDeletingShortITTConfirmation}
                  />
                </div>
              </div>
              <div className={styles['table-wrapper']}>
                <table id="shortageTable">
                  <thead>
                    <tr>
                      {
                        shortITTColumnsState.map(item => (
                          <th
                            key={item.name}
                            className={classNames(
                              item.isSorted && item.isSortedDescending ?
                                styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined,
                              styles[item.key],
                            )}
                            onClick={() => onColumnClick(item, shortITTColumnsState, setShortITTColumnsState)}>{item.name}
                          </th>
                        ))
                      }
                      <th className={styles.selectColumn}>
                        <div className={styles.round}>
                          <input
                            type="checkbox"
                            id="shortITTAll"
                            checked={state.shortITTItems.length !== 0 &&
                            (state.selectedShortITTItems.length === +shortITTcountOnPage.key || state.selectedShortITTItems.length === state.shortITTItems.length)}
                            onChange={(e) => handleSelectAllShortITT(e, state.shortITTItems)}
                          />
                          <label htmlFor="shortITTAll"></label>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.shortITTItems.map(item => (
                      <tr key={item.inTransitTireAltId} className={classNames(state.selectedShortITTItems.find(el => el.inTransitTireAltId === item.inTransitTireAltId) ? styles.trSelected : styles.trBasic)} >
                        <td>{item.intireBrndPfx}</td>
                        <td>{item.intireBrndNum}</td>
                        <td>{item.intireBrndSfx}</td>
                        <td>{item.typeCodeFK}</td>
                        <td>{item.tc}</td>
                        <td>{item.dotNum}</td>
                        <td>{item.shortDoc}</td>
                        <td>{item.shortDate}</td>
                        <td>
                          <div className={styles.round}>
                            <input
                              type="checkbox"
                              id={`shortITT-${item.inTransitTireAltId}`}
                              checked={!!state.selectedShortITTItems.find(el => el.inTransitTireAltId === item.inTransitTireAltId)}
                              onChange={(e) => handleSelectShortITT(e, item)} />
                            <label htmlFor={`shortITT-${item.inTransitTireAltId}`}></label>
                          </div>
                        </td>
                      </tr>
                    ))}
                    {!state.shortITTItems.length && <tr><td colSpan={9} className={styles.centeredColumn}>No records found</td></tr>}
                  </tbody>
                </table>
              </div>
              <SeparatorGy />
              <Pagination {...shortITTPaginationProps} />
            </div>
          </div>
      }
      {
        state.orderDetails && 
          <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
            <DefaultButton id="printButton" onClick={toggleShowPrintExport} text="Print/Export" />
            <PrimaryButton
              id="submitButton"
              onClick={toggleSubmittingShortITTConfirmation}
              text="Submit"
              disabled={!state.selectedShortITTItems.length || !userPermissions.isWrite}
            />
          </div>
      }
      <Dialog
        hidden={!isDeletingShortITTDialogVisible}
        onDismiss={toggleDeletingShortITTConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to delete (${state.selectedShortITTItems.length}) items?`,
        }}
        modalProps={{ isBlocking: true }}>
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDeleteShortITT} text="Delete" />
          <DefaultButton onClick={toggleDeletingShortITTConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={!isSubmittingShortITTDialogVisible}
        onDismiss={toggleSubmittingShortITTConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to submit Short In-Transit(${state.selectedShortITTItems.length}) tires?`,
        }}
        modalProps={{ isBlocking: true }}>
        <DialogFooter>
          <PrimaryButton id="submitButton" onClick={handleSubmit} text="Submit" />
          <DefaultButton onClick={toggleSubmittingShortITTConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint} />
      {state.loading && <LoadingScreen />}
    </div>
  );
};

export default RecordShortageInShipment;