import { IDropdownOption } from '@fluentui/react';

export const pageSizes = [100, 250, 500];

export const mockProductCodes = {
  data: [
    {
      productCode: 'string',
      tireSize: 'string',
      description: 'string',
      origOrRtrdC: 'string',
      radialOrBiasC: 'string',
      treadDepth: 0,
      factoryCost: 0,
      casingCost: 0,
      ftube: 'string',
      tubeProductCode: 'string',
      tubeCost: 0,
      materialNumber: 'string',
      specNumber: 'string',
      typeRubberCmpd: 'string',
      plyRating: 'string',
      natRubberCost: 0,
      synRubberCost: 0,
      wireCost: 0,
      laborCost: 0,
      dateEffective: 'string',
      country: 'string',
      createdOn: 'string',
      createdBy: 'string',
      lastModifiedOn: 'string',
      lastModifiedBy: 'string',
      casingSaleValue: 0,
    },
  ],
  total: {
    all: 1,
    found: 1,
  },
};

export const defaultCountOnPage: IDropdownOption = { key: pageSizes[0], text: pageSizes[0].toString() };
