import { FC, useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import LoadingScreen from '../../../../../../../LoadingScreen/LoadingScreen';
import SeparatorGy from '../../../../../../../SeparatorGy/SeparatorGy';
import OrderHeader from './Components/OrderHeader/OrderHeader';
import { emptyPlaceOriginalOrder, emptyPlaceOriginalOrderItem, orderDetailsColumns } from './consts';
import { IEditPlaceOriginalProps } from './IEditPlaceOriginalProps';
import { IEditPlaceOriginalState } from './IEditPlaceOriginalState';
import styles from './EditPlaceOriginal.module.scss';
import classNames from 'classnames';
import moment from 'moment';
import useNotifications from '../../../../../../../../hooks/useNotifications';
import apiService from '../../../../../../../../api';
import { MessageBarType, PrimaryButton } from '@fluentui/react';
import Stepper from '../../../../../../../../shared/Stepper';
import HistoryAndMessages from '../../../../Components/HistoryAndMessages/HistoryAndMessages';
import ShippingInstructions from '../../../../Components/ShippingInstructions/ShippingInstructions';
import OrderDetails from './Components/OrderDetails/OrderDetails';
import { orderSteps } from '../../../../consts';
import { transformDate } from '../../../../../../../../shared/transformDate';

const EditPlaceOriginal: FC<IEditPlaceOriginalProps> = ({order, mode}) => {
  const [state, setState] = useState<IEditPlaceOriginalState>({
    loading: false,
  });

  const [currentStep, setCurrentStep] = useState<number>(0);

  const [typeCodes, setTypeCodes] = useState<any>([]);
  const [plants, setPlants] = useState<any>([]);
  const [parsedErrors, setParsedErrors] = useState<any>([]);

  const [orderToBeAdded, setOrderToBeAdded] = useState<any>(emptyPlaceOriginalOrder);
  const [orderItemToBeAdded, setOrderItemToBeAdded] = useState<any>(emptyPlaceOriginalOrderItem);

  const [orderLoaded, { setTrue: orderWasLoaded }] = useBoolean(false);

  const { addNotification } = useNotifications();

  const fetchOrder = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await apiService.tireOrders.getOrderById(order.id);
      setOrderItemToBeAdded((prev: any) => ({
        ...data.items[0], 
        shipDate: data.items[0].shipDate ? moment(data.items[0].shipDate).format('MM/DD/YYYY') : null,
        recvDate: data.items[0].recvDate ? moment(data.items[0].recvDate).format('MM/DD/YYYY') : null,
        requestedDeliveryDate: moment(data.items[0].requestedDeliveryDate).format('YYYY-MM-DD'),
      }));
      setOrderToBeAdded((prev: any) => ({
        ...data.order,
        recvDate: data.order.recvDate ? moment(data.order.recvDate).format('MM/DD/YYYY') : null, 
        orderDate: data.order.orderDate ? moment(data.order.orderDate).format('MM/DD/YYYY') : null,
      }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching retread order error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
      orderWasLoaded();
    }
  };

  const handleSaveOrder = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await apiService.tireOrders.saveOriginalOrder(
        orderToBeAdded,
        [
          {
            ...orderItemToBeAdded,
            requestedDeliveryDate: transformDate(moment(orderItemToBeAdded.requestedDeliveryDate).format('MM/DD/YYYY')),
          },
        ],
        true,
      );
      setState(prev => ({...prev, orderId: data.order.id}));
      setOrderToBeAdded((prev: any) => ({...prev, id: data.order.id}));  
      addNotification({
        text: 'Order tires were successfully saved.',
        type: MessageBarType.success,
      });
      setParsedErrors([]);
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          setParsedErrors(response.data.state);
          return addNotification({
            text: 'Unable to save changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Order saving error: ${response?.data?.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const renderStepAccordingKey = (stepKey: number) => {
    switch (stepKey) {
      case 0:
        return <OrderDetails
          typeCodes={typeCodes}
          setTypeCodes={setTypeCodes}
          plants={plants}
          setPlants={setPlants}
          orderItemToBeAdded={orderItemToBeAdded}
          setOrderItemToBeAdded={setOrderItemToBeAdded}
          parsedErrors={parsedErrors}
          mode={mode}
        />;
      case 1:
        return <ShippingInstructions orderToBeAdded={orderToBeAdded} mode={mode} />;
      case 2:
        return <HistoryAndMessages orderToBeAdded={orderToBeAdded} />;
      default:
        return stepKey;
    }
  };

  const renderTable = () => {
    return (
      <table>
        <thead>
          <tr>
            {
              orderDetailsColumns.map(item => (
                <th key={item.name}>{item.name}</th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          <tr>
            {
              orderDetailsColumns.map((column) => (
                <td>
                  {orderItemToBeAdded[column.fieldName]}
                </td>
              ))
            }
          </tr>
        </tbody>
      </table>
    );
  };

  useEffect(() => {
    order.id !== null && fetchOrder();
  }, [order.id]);

  return (
    <>
      { orderLoaded &&
        <div className={classNames(styles.mainBlock)}>
          <div>
            <div>
              <Stepper 
                currentStep={currentStep} 
                steps={orderSteps} 
                setCurrentStep={setCurrentStep}
              />  
              <div>{renderStepAccordingKey(currentStep)}</div>
            </div>
            <SeparatorGy vertical />
            <div>
              <OrderHeader 
                orderToBeAdded={orderToBeAdded} 
                setOrderToBeAdded={setOrderToBeAdded} 
                mode={mode} 
              />
            </div>
          </div>
          {renderTable()} 
        </div> 
      }
      { 
        mode !== 'view' && currentStep === 0 ?
          <div className={styles.buttonsWrapper}>
            <div>
              <PrimaryButton text="Save Order" onClick={handleSaveOrder} />
            </div>
          </div> :
          null
      } 
      {state.loading && <LoadingScreen />}      
    </>
  );
};

export default EditPlaceOriginal;