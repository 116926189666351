export const columns = [
  {
    key: 'column0',
    name: 'Reconciliation Type',
    fieldName: 'name',
  },
  {
    key: 'column1',
    name: 'Count',
    fieldName: 'count',
  },
  {
    key: 'column2',
    name: 'Reconciled',
    fieldName: 'reconciledCount',
  },
];

export const mockReconciliationSummary = {
  statistics: {
    asOf: '2021-11-19T13:45:28.961Z',
    tiresOnVehicle: 0,
    tiresInSpareStock: 0,
    oosTires: 0,
  },
  consignedTires: 0,
  tiresInSystemSpareStock: 0,
  tiresInCustomerSpareStock: 0,
  matched: 0,
  areas: [
    {
      type: 'string',
      name: 'string',
      count: 0,
    },
  ],
};

export const modalViews = {
  RECONCILIATION_REPORT: 'Reconciliation Report',
  RECONCILIATION_REPORT_DETAILS: 'Reconciliation Report Details',
  RECORD_AS_LOST: 'Record As Lost',
};