import { HeadCell, ISorting } from '../../../shared/DataGridComponent/DataGridModels';

export const searchTermHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Contract ID',
    fieldName: 'contractId',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Status',
    fieldName: 'contractStatus',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Active Status',
    fieldName: 'activeStatus',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Created On',
    fieldName: 'createdOn',
    typeField: 'date',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'Created By',
    fieldName: 'createdBy',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column5',
    name: 'Updated On',
    fieldName: 'updatedOn',
    typeField: 'date',
    isEditable: false,
  },
  {
    key: 'column6',
    name: 'Updated By',
    fieldName: 'updatedBy',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column7',
    name: 'Effective Start Date',
    fieldName: 'effectiveStartDate',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column8',
    name: 'Current End Date',
    fieldName: 'currentEndDate',
    typeField: 'text',
    isEditable: false,
  },
];

export const pageSizes = [5, 10, 20];

export const defaultPaginationData = {
  searchedText: '',
  countOnPage: { key: pageSizes[0], text: pageSizes[0].toString() },
  paginationProps: {
    total: 1,
    current: 1,
  },
  sortOrder: {
    column: '',
    order: 'asc',
  },
};

export const termsActionsList = [
  {
    label: 'Sent to next status',
    action: 'nextStatus',
  },
  {
    label: 'Sent to previous status',
    action: 'previousStatus',
  },
  {
    label: 'Revert status',
    action: 'revertStatus',
  },
];

export const availableStatuses = [
  'UNDER PREPARATION',
  'GENERATED',
  'AUTHORIZED',
  'ACTIVE',
  'CLOSED OUT',
];

export const defaultSorting: ISorting = {
  column: searchTermHeadCell[7].fieldName,
  order: 'asc',
};