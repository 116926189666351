import { tireOrderingTestInstance, exportTestInstance } from '../axios';

const get = (filters: any, pagination: any, sortOrder: any, customerId?:string) =>
  tireOrderingTestInstance.post('/type-code', { filters, pagination, sortOrder, customerId });

const add = (addingData: any) =>
  tireOrderingTestInstance.post('/type-code/add', { ...addingData });

const update = (updatingData: any) =>
  tireOrderingTestInstance.put('/type-code', { ...updatingData });

const printExcel = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/type-code/export-excel', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/type-code/export-pdf', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  get,
  add,
  update,
  printExcel,
  printPdf,
};
