import { HeadCell } from '../DataGridComponent/DataGridModels';
import { IFormDataField } from '../FormBuilderComponent';

export const controlValuePartHeadCells: Array<HeadCell> = [
  {
    key: 'column0',
    name: 'Key',
    fieldName: 'key',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Value',
    fieldName: 'value',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Description',
    fieldName: 'description',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Sub Keys',
    fieldName: 'subKeys',
    typeField: 'text',
  },
];

export const VALUE_PART_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'keyField',
    properties: {
      id: 'key',
      label: 'Key',
      name: 'key',
      type: 'text',
      disabled: false,
      required: true,
    },
  },
  {
    formFieldName: 'valueField',
    properties: {
      id: 'value',
      label: 'Value',
      name: 'value',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'subKeysField',
    properties: {
      id: 'subKeys',
      label: 'Sub Keys',
      name: 'subKeys',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'descriptionField',
    properties: {
      id: 'description',
      label: 'Description',
      name: 'description',
      type: 'text',
      disabled: false,
    },
  },
];

export const controlValuePartHeadCellsForInspCode: Array<HeadCell> = [
  {
    key: 'column0',
    name: 'Inspection Code',
    fieldName: 'key',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Description',
    fieldName: 'description',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Disposition Code',
    fieldName: 'value',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'TD Required?',
    fieldName: 'subKeys',
    typeField: 'text',
  },
  {
    key: 'column4',
    name: 'Status',
    fieldName: 'status',
    typeField: 'text',
  },
];

export const VALUE_PART_FIELDS_INSPCODE: Array<IFormDataField> = [
  {
    formFieldName: 'keyField',
    properties: {
      id: 'key',
      label: 'Inspection Code',
      name: 'key',
      type: 'text',
      disabled: false,
      required: true,
    },
  },
  {
    formFieldName: 'valueField',
    properties: {
      id: 'value',
      label: 'Disposition Code',
      name: 'value',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'subKeysField',
    properties: {
      id: 'subKeys',
      label: 'TD Required?',
      name: 'subKeys',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'descriptionField',
    properties: {
      id: 'description',
      label: 'Description',
      name: 'description',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'statusField',
    properties: {
      id: 'status',
      label: 'Status',
      name: 'status',
      type: 'text',
      disabled: false,
    },
  },
];

export const controlValuePartHeadCellsForDispCode: Array<HeadCell> = [
  {
    key: 'column0',
    name: 'Disposition Code',
    fieldName: 'key',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Description',
    fieldName: 'description',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Status',
    fieldName: 'status',
    typeField: 'text',
  },
];

export const VALUE_PART_FIELDS_DISPCODE: Array<IFormDataField> = [
  {
    formFieldName: 'keyField',
    properties: {
      id: 'key',
      label: 'Disposition Code',
      name: 'key',
      type: 'text',
      disabled: false,
      required: true,
    },
  },
  {
    formFieldName: 'descriptionField',
    properties: {
      id: 'description',
      label: 'Description',
      name: 'description',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'statusField',
    properties: {
      id: 'status',
      label: 'Status',
      name: 'status',
      type: 'text',
      disabled: false,
    },
  },
];


export const statusOptions = ['ACTIVE','INACTIVE'];
export const tdRequiredOptions = ['N','Y'];

