import { TextField, Text, MessageBarType } from '@fluentui/react';
import { FC, useEffect, useState } from 'react';
import { IOrderHeaderProps } from './IOrderHeaderProps';
import apiService from '../../../../../../../../../../api';
import styles from './OrderHeader.module.scss';
import useNotifications from '../../../../../../../../../../hooks/useNotifications';
import { IOrderHeaderState } from './IOrderHeaderState';
import LoadingScreen from '../../../../../../../../../LoadingScreen/LoadingScreen';

const OrderHeader: FC<IOrderHeaderProps> = ({ orderToBeAdded, setOrderToBeAdded, mode }) => {
  const [state, setState] = useState<IOrderHeaderState>({
    loading: false,
    locationCode: '',
  });

  const { addNotification } = useNotifications();

  const fetchLocations = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data }: any = await apiService.getLocationsByCustomerId(
        orderToBeAdded.customerId,
        { pageSize: 5000, pageNumber: 1 },
        {},
      );
      const orderLocation = data.data.find((location: any) => location.id === orderToBeAdded.locationId).locationCode;
      setState((prev) => ({ ...prev, locationCode: orderLocation }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Locations fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const onChangeOrderField = (field: any, value: any, regExp?: RegExp) => {
    setOrderToBeAdded((prev: any) => ({ ...prev, [field]: regExp ? (regExp.test(value) ? value : prev[field]) : value }));
  };

  useEffect(() => {
    if (!orderToBeAdded.shipToOemName && orderToBeAdded.customerId) fetchLocations();
  }, [orderToBeAdded]);

  return (
    <>
      <div className={styles.container}>
        <Text variant="xLarge" className={styles.highlight}>Order Header</Text>
        <div>
          <div>
            <TextField
              label="Order #"
              value={orderToBeAdded.id}
              disabled
            />
          </div>
          <div>
            <TextField
              label="GDYR order #"
              value={orderToBeAdded.gdyrId}
              onChange={(e, gdyrId) => onChangeOrderField('gdyrId', gdyrId)}
              disabled={mode === 'view' ? true : false}
            />
          </div>
          <div>
            <TextField
              label="Status"
              value={orderToBeAdded.status}
              disabled
            />
          </div>
        </div>
        <div>
          {
            orderToBeAdded.shipToOemName ? 
              <div>
                <TextField
                  label="Ship To OEM"
                  value={orderToBeAdded.shipToOemName}
                  disabled
                />
              </div> :
              <div>
                <TextField
                  label="Location"
                  value={state.locationCode}
                  disabled
                />
              </div>
          }
          <div>
            <TextField
              label="Date ordered"
              value={orderToBeAdded.orderDate || 'N/A'}
              disabled  
            />
          </div>
          <div>
            <TextField
              label="Date recived"
              value={orderToBeAdded.recvDate || 'N/A'}
              disabled  
            />
          </div>
        </div>
      </div>
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default OrderHeader;