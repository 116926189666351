import { DefaultButton, IconButton, MessageBarType, Modal, PivotItem, Pivot, PrimaryButton, Text, TextField } from '@fluentui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import DataGridComponent from '../DataGridComponent';
import { PlantSelectionModalComponentProps, PlantSelectionHeadCell, IPlant, defaultPlantInfo, plantFilterValues, plantsPageSizes } from './consts';
import styles from './PlantModalComponent.module.scss';
import apiService from '../../api';
import useNotifications from '../../hooks/useNotifications';
import { debounce } from 'lodash';

const PlantModalComponent: FunctionComponent<PlantSelectionModalComponentProps> = ({
  id,
  title,
  type,
  isOpen,
  onSubmit,
  onDismiss,
}) => {
  const { addNotification } = useNotifications();
  const [plantList, setPlantList] = useState([]);
  const [gridState, setGridState] = useState<any>({});
  const [totalFound, setTotalFound] = useState(0);
  const [plantSelected, setPlantSelected] = useState({});
  const [plantInfo, setPlantInfo] = useState<IPlant>(defaultPlantInfo);
  const [plantFilters, setPlantFilters] = useState(plantFilterValues);
  const [notification, setNotification] = useState({ text: '', type: 0 });
  const [isLoading, { toggle: toggleChangeLoading }] = useBoolean(false);

  const fetchData = async (pagination: any, filters?: any, sortOrder?: any) => {
    try {
      const { data }: any = await apiService.plantWareHouseAPI.getAvailablePlants(pagination, filters, sortOrder);
      setPlantList(data?.data);
      setTotalFound(data?.total?.found);
    } catch (error) {
      setNotification({ text: 'Fetching Plants error', type: MessageBarType.error });
    }
  };

  const handleChangeDataGridState = debounce(async (dataGridState: any) => {
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    toggleChangeLoading();
    setGridState(dataGridState);
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString: searchedText,
      type: type,
      ...(plantFilters.city !== '' && { city: plantFilters.city }),
      ...(plantFilters.name !== '' && { name: plantFilters.name }),
      ...(plantFilters.closeTo !== '' && { city: plantFilters.closeTo }),
      active: true,
    };
    await fetchData(pagination, filters, sortOrder);
    toggleChangeLoading();
  }, 500);

  const handleFilterChanges = (e: any, field: string) => {
    setPlantFilters((prev: any) => ({ ...prev, [field]: e.target.value }));
  };

  const triggerAPISearch = debounce(async (reset = false) => {
    toggleChangeLoading();
    const { countOnPage, searchedText, sortOrder } = gridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: 1,
    };
    const filters = {
      searchString: searchedText,
      type: type,
      ...(plantFilters.city !== '' && !reset && { city: plantFilters.city }),
      ...(plantFilters.name !== '' && !reset && { name: plantFilters.name }),
      ...(plantFilters.closeTo !== '' && !reset && { city: plantFilters.closeTo }),
      active: true,
    };
    await fetchData(pagination, filters, sortOrder);
    toggleChangeLoading();
  }, 500);

  const handleSelectRow = (rowSelected: IPlant[]) => {
    if (rowSelected.length) {
      const selectedPlant: IPlant = rowSelected[0];
      setPlantSelected(selectedPlant);
      setPlantInfo(selectedPlant);
    }
    else {
      setPlantSelected({});
      setPlantInfo(defaultPlantInfo);
    }
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  return (
    <>
      <Modal
        key={id}
        isOpen={isOpen}
        onDismiss={() => {
          setPlantFilters(plantFilterValues);
          onDismiss();
        }}
        containerClassName='modalContainer'
      >
        <div className={styles.modalBody}>
          <div className='modalHeader'>
            <Text variant="xLarge" className={styles.highlight}>{title}</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel="Close popup modal"
              onClick={() => {
                setPlantFilters(plantFilterValues);
                onDismiss();
              }}
            />
          </div>
          <div>
            <Text variant='large' className={styles.highlight}>Active Filters</Text>
            <div className={styles.plantInfoRow}>
              <div className={styles.plantInfoField}>
                <TextField
                  label='Close To'
                  value={plantFilters.closeTo}
                  onChange={(e) => { handleFilterChanges(e, 'closeTo'); }}
                />
              </div>
              <div className={styles.plantInfoField}>
                <TextField
                  label='Plant Name'
                  value={plantFilters.name}
                  onChange={(e) => { handleFilterChanges(e, 'name'); }}
                />
              </div>
              <div className={styles.plantInfoField}>
                <TextField
                  label='City'
                  value={plantFilters.city}
                  onChange={(e) => { handleFilterChanges(e, 'city'); }}
                />
              </div>
              <div className={styles.plantFilterColumn}>
                <div className={styles.plantFilterField}>
                  <PrimaryButton
                    id="applyFilters"
                    text='Apply Filters'
                    className={styles.fullWidth}
                    onClick={() => { triggerAPISearch(false); }}
                  />
                </div>
                <div className={styles.plantFilterField}>
                  <DefaultButton
                    id="removeFilters"
                    text='Remove Filters'
                    onClick={() => {
                      setPlantFilters(plantFilterValues);
                      triggerAPISearch(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='tableContainer'>
            <DataGridComponent
              idTable={'retread-plant-table'}
              title=''
              headCells={PlantSelectionHeadCell}
              rowsTable={plantList}
              defaultRowsPerPage={plantsPageSizes}
              totalDataFound={totalFound}
              isLoading={isLoading}
              enableCheckBox={true}
              enablePagination={true}
              enableRowsPerPage={true}
              handleChangeDataGridState={handleChangeDataGridState}
              handleSelectRow={handleSelectRow}
            />
          </div>
          <Pivot
            className="pivot-gy-colors"
            getTabId={getTabId}
            linkFormat='tabs'
            linkSize='normal'
          >
            <PivotItem headerText='General' itemKey='1'>
              <div>
                <div className={styles.plantInfoRow}>
                  <div className={styles.plantInfoField}>
                    <TextField
                      label="Plant #"
                      value={plantInfo.plantNo}
                      disabled
                    />
                  </div>
                  <div className={styles.plantInfoField}>
                    <TextField
                      label="Plant Name"
                      value={plantInfo.name}
                      disabled
                    />
                  </div>
                  <div className={styles.plantInfoField}>
                    <TextField
                      label="Type"
                      value={plantInfo.type}
                      disabled
                    />
                  </div>
                </div>
                <div className={styles.plantInfoRow}>
                  <div className={styles.plantInfoField}>
                    <TextField
                      label="Address Line 1"
                      value={plantInfo.addrLine1}
                      disabled
                    />
                  </div>
                  <div className={styles.plantInfoField}>
                    <TextField
                      label="Address Line 2"
                      value={plantInfo.addrLine2}
                      disabled
                    />
                  </div>
                </div>
                <div className={styles.plantInfoRow}>
                  <div className={styles.plantInfoField}>
                    <TextField
                      label="Country"
                      value={plantInfo.country}
                      disabled
                    />
                  </div>
                  <div className={styles.plantInfoField}>
                    <TextField
                      label="State"
                      value={plantInfo.state}
                      disabled
                    />
                  </div>
                  <div className={styles.plantInfoField}>
                    <TextField
                      label="City"
                      value={plantInfo.city}
                      disabled
                    />
                  </div>
                  <div className={styles.plantInfoField}>
                    <TextField
                      label="Zip/Postal Code"
                      value={plantInfo.zip}
                      disabled
                    />
                  </div>
                </div>
                <div className={styles.plantInfoRow}>
                  <div className={styles.plantInfoField}>
                    <TextField
                      label="Contact"
                      value={plantInfo.contactName}
                      disabled
                    />
                  </div>
                  <div className={styles.plantInfoField}>
                    <TextField
                      label="Phone 1"
                      value={plantInfo.phone1}
                      disabled
                    />
                  </div>
                  <div className={styles.plantInfoField}>
                    <TextField
                      label="Phone 2"
                      value={plantInfo.phone2}
                      disabled
                    />
                  </div>
                  <div className={styles.plantInfoField}>
                    <TextField
                      label="Fax"
                      value={plantInfo.fax}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </PivotItem>
            <PivotItem headerText='Comments' itemKey='2'>
              <div className={styles.plantInfoRow}>
                <TextField
                  className={styles.plantInfoField}
                  label="Close To"
                  multiline
                  rows={8}
                  cols={66}
                  value={plantInfo.closeTo}
                  disabled
                />
                <TextField
                  className={styles.plantInfoField}
                  label="Comments"
                  multiline
                  rows={8}
                  cols={66}
                  value={plantInfo.comments}
                  disabled
                />
              </div>
            </PivotItem>
          </Pivot>
          <div className={styles.modalFooter}>
            <DefaultButton
              id="cancelButton"
              text='Cancel'
              onClick={() => {
                setPlantFilters(plantFilterValues);
                onDismiss();
              }}
            />
            <PrimaryButton
              id="selectButton"
              text='Select'
              onClick={(e) => {
                setPlantFilters(plantFilterValues);
                onSubmit(plantSelected);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PlantModalComponent;