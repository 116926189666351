import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import { FunctionComponent } from 'react';

interface DialogComponentProps {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  title: string;
  subText: string;
  onSubmitLabel?: string;
  onCancelLabel?: string;
}

const DialogComponent: FunctionComponent<DialogComponentProps> = ({
  isOpen,
  onCancel,
  onSubmit,
  title,
  subText,
  onSubmitLabel = 'Submit',
  onCancelLabel = 'Cancel',
}) => {
  return (
    <>
      <Dialog
        hidden={!isOpen}
        onDismiss={onCancel}
        dialogContentProps={{
          type: DialogType.normal,
          title,
          subText,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <DefaultButton id='cancelDialogBtn' onClick={onCancel} text={onCancelLabel} />
          <PrimaryButton id='submitDialogBtn' onClick={onSubmit} text={onSubmitLabel} />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default DialogComponent;