import { termTestInstance } from '../axios';

const getTermGeneral = (contractId: any, customerId: any) =>
  termTestInstance.post('/terms/contract/general', { contractId, customerId });

const addTermGeneral = (data: any) => 
  termTestInstance.post('/terms/general/add', data);

const updateTermGeneral = (data: any) => 
  termTestInstance.put('/terms/general/update', data);



export default {
  getTermGeneral,
  addTermGeneral,
  updateTermGeneral,
};