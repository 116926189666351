import { customerTestInstance } from '../axios';

const get = (customerId: any) =>
  customerTestInstance.post('/customer/accounting', { customerId });

const save = (customerId: any, customerGroup: any, expenseCode: any) =>
  customerTestInstance.post('/customer/accounting/save', { customerId, customerGroup, expenseCode });

const getExpenseCodes = () => customerTestInstance.get('/maintain-customer/expense-code');

const getCustomerGroups = () => customerTestInstance.get('/maintain-customer/customer-groups');

export default {
  get,
  save,
  getExpenseCodes,
  getCustomerGroups,
};
