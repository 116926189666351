import { FC } from 'react';
import classNames from 'classnames';
import {
  DefaultButton,
  Icon,
  IconButton,
  Link,
  Text,
} from '@fluentui/react';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { isEmpty, isNil } from 'lodash';

import { IUserDetailsModalProps } from './IUserDetailsModalProps';

import styles from './UserDetailsModal.module.scss';
import moment from 'moment';

const UserDetailsModal: FC<IUserDetailsModalProps> = ({ isOpened, userData, onClose }) => {

  return isOpened && !isNil(userData) ? (
    <div className={styles.root}>
      <div className={classNames('ms-Grid', styles.window)}>
        <div className={styles.modalHeading}>
          <Persona
            text={userData.fullName}
            secondaryText={userData.email}
            size={PersonaSize.size100}
            imageUrl={userData.avatar}
          />
          <div>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              onClick={onClose}
            />
          </div>
        </div>
        <div className={classNames('ms-Grid-row', styles.row)}>
          <div className={classNames('ms-Grid-col', 'ms-sm6')}>
            <Text block className={styles.boldText}>Title</Text>
            <Text block>{userData.jobTitle}</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm3')}>
            <Text block className={styles.boldText}>UserId</Text>
            <Text block>{userData.userId}</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm3')}>
            <Text block className={styles.boldText}>User Status</Text>
            <Text block>{userData.status}</Text>
          </div>
        </div>
        <div className={classNames('ms-Grid-row', styles.row)}>
          <div className={classNames('ms-Grid-col', 'ms-sm6')}>
            <Text block className={styles.boldText}>Date & Time Created</Text>
            <Text block>{moment(userData.createdon).format('MM/DD/YYYY, h:mm:ss a')}</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm6')}>
            <Text block className={styles.boldText}>Date & Time Modified</Text>
            <Text block>{moment(userData.createdon).format('MM/DD/YYYY, h:mm:ss a')}</Text>
          </div>
        </div>
        <div className={classNames('ms-Grid-row', styles.row)}>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <Text block className={styles.boldText}>User Access</Text>
          </div>
        </div>
        <div className={classNames('ms-Grid-row')}>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.accessTypes)}>
            {isEmpty(userData.accessTypes) || isNil(userData.accessTypes) ?
              <Text variant='large'>User has no access.</Text> :
              userData?.accessTypes?.split(',').map((p: any) => (
                <DefaultButton
                  toggle
                  className={styles.moduleButton}
                  text={p}
                />
              ))}
          </div>
        </div>
        <div className={classNames('ms-Grid-row', styles.row)}>
          <div className={classNames('ms-Grid-col', 'ms-sm1', styles.tooltipIconWrapper)}>
            <Icon className={styles.errorIcon} iconName='Error' />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm10')}>
            <Text block>
              Access management is handled in GAIMS. If you want to add or remove access to the users, please visit:
            </Text>
            <Link target='_blank' href='http://go.goodyear.com/cfmx/internal/gaims'>http://go.goodyear.com/cfmx/internal/gaims</Link>
          </div>
        </div>
      </div>
    </div>
  ) : <></>;
};

export default UserDetailsModal;
