import { MessageBarType } from '@fluentui/react';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import apiService from '../../../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { useField } from '../../../../../hooks/useField';
import { customerSelector, setCustomer } from '../../../../../redux/customerSlice';
import { INotification } from '../../../../../models/INotification';
import useNotifications from '../../../../../hooks/useNotifications';
import { useBoolean } from '@fluentui/react-hooks';
import HistoryForm from '../../../../../shared/HistoryForm';
import { HISTORY_FIELDS } from '../../../../../shared/HistoryForm/consts';

interface HistoryComponentProps {
  actionType: string
}

const HistoryComponent: FunctionComponent<HistoryComponentProps> = ({ actionType }) => {

  //auth
  const parentComponentName = 'CUSTOMER';

  const { addNotification } = useNotifications();
  const dispatch = useDispatch();
  const customer = useSelector(customerSelector);
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });

  // form validations
  const commentsField = useField(HISTORY_FIELDS.commentsDataField, undefined);
  const createdByField = useField(HISTORY_FIELDS.createdByDataField);
  const createdOnField = useField(HISTORY_FIELDS.createdOnDataField);
  const lastUpdatedByField = useField(HISTORY_FIELDS.lastUpdatedNameDataField);
  const lastUpdatedOnField = useField(HISTORY_FIELDS.lastUpdatedDateDataField);
  const [isLoading, { toggle: toggleShowLoading }] = useBoolean(false);

  const handleUpdateComments = async () => {
    try {
      toggleShowLoading();
      const bodyRequest = { id: customer?.id, comments: commentsField.getValue() };
      await apiService.customerAPI.updateCustomerHistory(bodyRequest);
      updateLocalCustomer();
      setNotification({ text: 'Comments updated successfully', type: MessageBarType.success });
    } catch (error) {
      setNotification({ text: 'Updating comments error', type: MessageBarType.error });
    } finally {
      toggleShowLoading();
    }
  };

  const updateLocalCustomer = () => {
    const newCustomer = { ...customer, comments: commentsField.getValue() };
    dispatch(setCustomer(newCustomer));
  };

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  useEffect(() => {
    if (!customer) return;
    const { comments, createdBy, createdOn, lastModBy, lastModOn } = customer;
    commentsField.setValue(comments);
    createdByField.setValue(createdBy);
    createdOnField.setValue(moment(createdOn).format('MM/DD/YYYY'));
    lastUpdatedByField.setValue(lastModBy);
    lastUpdatedOnField.setValue(moment(lastModOn).format('MM/DD/YYYY'));
  }, [customer]);

  const parentComments: string = customer?.comments;

  return (
    <div id="container">
      <HistoryForm
        actionType={actionType}
        comments={parentComments}
        handleUpdateComments={handleUpdateComments}
        isLoading={isLoading}
        commentsField={commentsField}
        createdByField={createdByField}
        createdOnField={createdOnField}
        lastUpdatedByField={lastUpdatedByField}
        lastUpdatedOnField={lastUpdatedOnField}
        parentComponentName={parentComponentName}
      />
    </div>
  );
};

export default HistoryComponent;
