import { exportTestInstance, managementTestInstance } from '../axios';

const printSchedulerFunctionHistoryExcel = (input: any) =>
  exportTestInstance.post('/scheduler-function-history/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printSchedulerFunctionHistoryPdf = (input: any) =>
  exportTestInstance.post('/scheduler-function-history/export-pdf', input, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const getTasks = (pagination: any, sortOrder: any, scheduleId: any) =>
  managementTestInstance.post('/scheduler/list', { pagination, sortOrder, scheduleId });

const getTasksForJob = (pagination: any, sortOrder: any, scheduleId: any) =>
  managementTestInstance.post('/scheduler-function/list', { pagination, sortOrder, scheduleId });

const getSchedulingInformation = (taskId: any) =>
  managementTestInstance.get(`/scheduler/${taskId}`);

const getHistory = (requestData: any) =>
  managementTestInstance.post('/scheduler/history/list', requestData);

const getSelections = () =>
  managementTestInstance.get('/scheduler/sections/list');

const runTasksForJob = (scheduleId: any, scheduleFunctionIds: Array<any>) =>
  managementTestInstance.post('/scheduler/run-manually', { scheduleId, scheduleFunctionIds });

const getSchedulingConfiguration = (scheduleId: any) =>
  managementTestInstance.post('/scheduler-configuration/get', { scheduleId });

const updateSchedulingConfiguration = (requestData: any) =>
  managementTestInstance.put('/scheduler-configuration/update', requestData);

const getRecipients = (pagination: any, sortOrder: any, scheduleId: any) =>
  managementTestInstance.post('/scheduler/recipients/list', { pagination, sortOrder, scheduleId });

const sendTestNotification = (scheduleId: any) =>
  managementTestInstance.post('/scheduler/notification/send-test', { scheduleId });

const AddRecipient = (recipientData: any) =>
  managementTestInstance.post('/scheduler/recipients/add', recipientData);

const UpdateRecipient = (recipientData: any) =>
  managementTestInstance.put('/scheduler/recipients/update', recipientData);

const DeleteRecipients = (ids: Array<any>) =>
  managementTestInstance.delete('/scheduler/recipients/delete',  { data: { ids } });


export default {
  printSchedulerFunctionHistoryExcel,
  printSchedulerFunctionHistoryPdf,
  getTasks,
  getSchedulingInformation,
  getHistory,
  getSelections,
  getTasksForJob,
  runTasksForJob,
  getSchedulingConfiguration,
  updateSchedulingConfiguration,
  getRecipients,
  sendTestNotification,
  AddRecipient,
  UpdateRecipient,
  DeleteRecipients,
};
