import { exportTestInstance, tireOrderingTestInstance } from '../axios';

const getDelNumList = (orderId: string) =>
  tireOrderingTestInstance.post('/in-transit/del-num', { orderId });

const getAvailableInTransitTires = (pagination: any, filters: any, sortOrder: any) =>
  tireOrderingTestInstance.post('/in-transit/available-tires', { pagination, filters, sortOrder });

const submitShortInTransitTires = (ids: any[]) =>
  tireOrderingTestInstance.post('/in-transit/submit/record-shortage-in-shipment', { ids });

const transferInTransitTires = (body: any) =>
  tireOrderingTestInstance.post('/in-transit/transfer', { ...body });

const addTires = (body: any) =>
  tireOrderingTestInstance.post('/in-transit/add', { body });

const addTemporaryTires = (body: any) =>
  tireOrderingTestInstance.post('/in-transit/add', body);

const submitTires = (body: any) =>
  tireOrderingTestInstance.post('/in-transit/submit', body);

const moveTiresToTemporary = (body: any) =>
  tireOrderingTestInstance.post('/in-transit/move-to-temporary', body);

const moveTiresBackToMainTable = (ids: Array<string>) =>
  tireOrderingTestInstance.post('/in-transit/move-back-to-main-table', { ids });

const getTemporaryTires = (pagination: any, filters: any, sortOrder: any, deleteTemporaryRecords?: boolean) =>
  tireOrderingTestInstance.post('/in-transit/temporary-tires', { pagination, filters, sortOrder, deleteTemporaryRecords });

const getShortTires = (orderId: any, pagination: any, sortOrder: any) =>
  tireOrderingTestInstance.post('/in-transit/short-tires', { orderId, pagination, sortOrder });

const updateTemporaryTires = (inTransitTiresAlt: Array<any>) =>
  tireOrderingTestInstance.put('/in-transit/temporary-tires', { inTransitTiresAlt });

const deleteTemporaryTires = (ids: Array<any>) =>
  tireOrderingTestInstance.delete('/in-transit/temporary-tires', { data: { ids } });

const updateTiresDotNum = (body: any) =>
  tireOrderingTestInstance.put('/in-transit/update/dot-num', body);

const updateTRR = (body: any) =>
  tireOrderingTestInstance.post('/in-transit/submit/trr', body);

const updateTRRQueue = (body: any) =>
  tireOrderingTestInstance.post('/in-transit/submit/trr/queue', body);

const updateWithTRRStatus = (body: any) =>
  tireOrderingTestInstance.post('/in-transit/submit/trr/status', body);

const getMoveToInIntransitTires = (pagination: any, filters: any, sortOrder: any) =>
  tireOrderingTestInstance.post('/move-to-intransit', { pagination, filters, sortOrder });

const deleteMoveToInIntransitTires = (ids: Array<any>) =>
  tireOrderingTestInstance.delete('/move-to-intransit', { data: { ids } });

const addMoveToInIntransitTire = (data: any) =>
  tireOrderingTestInstance.post('/move-to-intransit/add', data);

const submitMoveToInIntransitTire = (confirmed: any, ids: any) =>
  tireOrderingTestInstance.post('/move-to-intransit/submit', { confirmed, ids });

const printExcel = (input: any) =>
  exportTestInstance.post('/in-transit/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (input: any) =>
  exportTestInstance.post('/in-transit/export-pdf', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const printExcelB36InTransitTires = (data: any) =>
  exportTestInstance.post('/in-transit/print-b36/export-excel', data, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPDFB36InTransitTires = (data: any) =>
  exportTestInstance.post('/in-transit/print-b36/export-pdf', data, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });
const printExcelShortInTransitTires = (data: any) =>
  exportTestInstance.post('/in-transit/print-short/export-excel', data, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPDFShortInTransitTires = (data: any) =>
  exportTestInstance.post('/in-transit/print-short/export-pdf', data, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  getDelNumList,
  getAvailableInTransitTires,
  submitShortInTransitTires,
  transferInTransitTires,
  addTires,
  moveTiresToTemporary,
  moveTiresBackToMainTable,
  getTemporaryTires,
  getShortTires,
  updateTemporaryTires,
  addTemporaryTires,
  deleteTemporaryTires,
  submitTires,
  updateTiresDotNum,
  updateTRR,
  updateTRRQueue,
  updateWithTRRStatus,
  getMoveToInIntransitTires,
  deleteMoveToInIntransitTires,
  addMoveToInIntransitTire,
  submitMoveToInIntransitTire,
  printPdf,
  printExcel,
  printExcelB36InTransitTires,
  printPDFB36InTransitTires,
  printExcelShortInTransitTires,
  printPDFShortInTransitTires,
};
