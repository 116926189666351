import { ChoiceGroup, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IColumn, IDropdownOption, MessageBarType, PrimaryButton, Text } from '@fluentui/react';
import classNames from 'classnames';
import moment from 'moment';
import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBoolean } from '@fluentui/react-hooks';

import { IStartMileageState } from './IStartMileageState';
import { IStartMileageProps } from './IStartMileageProps';
import { columns, selectMode, STATUSES_NOT_ALLOWED } from './consts';
import { pageSizes } from '../../../../../../consts/recordKeeping';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../../Pagination/Pagination';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import ErrorsModal from '../../../../../ErrorsModal/ErrorsModal';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';

import apiService from '../../../../../../api';
import useNotifications from '../../../../../../hooks/useNotifications';
import { customerSelector, locationSelector } from '../../../../../../redux/recordKeepingSlice';
import { sortOrder } from '../../../../../../consts/sortOrder';
import { auth_mileageRun_start } from '../../../../../../consts/programKeys';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';

import styles from './StartMileage.module.scss';

const StartMileage: FC<IStartMileageProps> = (): ReactElement => {

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);

  const [state, setState] = useState<IStartMileageState>({
    items: [],
    selectedItem: null,
    startedItem: null,
    loading: false,
    foundCount: 0,
  });

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const [billingYearList, setBillingYearList] = useState<Array<any>>([]);
  const [filters, setFilters] = useState<any>({ year: 0 });
  const [isClosingDialogVisible, { toggle: toggleClosingConfirmation }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [showErrorsModal, { toggle: toggleShowErrorsModal }] = useBoolean(false);
  const [selectedMode, setSelectedMode] = useState<string | undefined>(selectMode[0].key);
  const [isInProgress, { setTrue: start, setFalse: finish }] = useBoolean(false);
  const [mileageAuditNotDone, { setTrue: setMaNotDone, setFalse: setMaDone }] = useBoolean(false);

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_mileageRun_start);

  const fetchMileageRunListIntervalId = useRef<any>();

  const handleSelect = async (e: any, itemId: any) => {
    setState((prev: any) => ({ ...prev, selectedItem: e.target.checked ? itemId : null, loading: true }));
    try {
      setMaNotDone();
      const { data }: any = await apiService.mileageRun.getMileageAuditStatus(itemId);
      const result = data;
      if (result.data.isCompleted === true) {
        setMaDone();
        addNotification({
          text: `${result.desc.message} Start button enabled.`,
          type: MessageBarType.success,
        });
      } else {
        setMaNotDone();
      }
    } catch (e: any) {
      setMaNotDone();
      const { response } = e;
      switch (response.status) {
        default:
          setMaNotDone();
          addNotification({
            text: `${response.data.desc.message} Cannot be started.`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState((prev: any) => ({ ...prev, selectedItem: e.target.checked ? itemId : null, loading: false }));
    }
  };

  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const handleStart = async () => {
    try {
      start();
      setState(prev => ({ ...prev, loading: true }));
      await apiService.mileageRun.startMileage(state.selectedItem, customerId, selectedMode!, '0');
      addNotification({
        text: 'Selected item(s) were successfully started.',
        type: MessageBarType.success,
      });
      setState((prev: any) => ({ ...prev, startedItem: state.items.find((item: any) => item.id === state.selectedItem).id }));
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 491:
          try {
            await apiService.mileageRun.startMileage(state.selectedItem, customerId, selectedMode!, '2');
          }
          catch (e: any) {
            addNotification({
              text: `Mileage run starting error: ${response.data.message}`,
              type: MessageBarType.error,
            });
          }
          break;
        case 492:
          try {
            await apiService.mileageRun.startMileage(state.selectedItem, customerId, selectedMode!, '4');
          }
          catch (e: any) {
            addNotification({
              text: `Mileage run starting error: ${response.data.message}`,
              type: MessageBarType.error,
            });
          }
          break;
        case 499:
          addNotification({
            text: `Mileage run starting error: ${response.data.message}`,
            type: MessageBarType.error,
          });
          break;
        default:
          addNotification({
            text: `Mileage run starting error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    }
    finally {
      setState((prev: any) => ({ ...prev, selectedItem: null, loading: false }));
      await fetchMileageRunList();
    }
  };

  const fetchStatus = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.mileageRun.getStartStatus(customerId);
      addNotification({
        text: `Customer ${data.customerId}, run status ${data.runStatus}`,
        type: MessageBarType.success,
      });
    } catch (e: any) {
      addNotification({
        text: `Mileage Run status error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchBillingYearList = async () => {
    await fetchStatus();
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.mileageRun.getBillingYearList();
      setBillingYearList(data.data);
      setFilters((prev: any) => ({ ...prev, year: '' + new Date().getFullYear() }));
    } catch (e: any) {
      addNotification({
        text: `Mileage Run fetching billing period error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchMileageRunList = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.mileageRun.getStartDetails(
        filters,
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
      );
      const foundCount = data.total.found;
      const items = data.data;
      const inProgressItem = items.find((item: any) => item.completedStatus === 'I' || item.completedStatus === 'B');
      inProgressItem ? start() : finish();
      inProgressItem?.id === state.startedItem && setState((prev: any) => ({ ...prev, startedItem: null }));
      setState((prev: any) => ({ ...prev, items, foundCount }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      addNotification({
        text: `Mileage Run fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        filters,
        sortOrder,
        customerId,
        locationId,
      };
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.mileageRun.printExcelStart(requestData, []) :
        await apiService.mileageRun.printPdfStart(requestData, []);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Printing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const setFetchMileageRunListInterval = (interval: any) => {
    // Clear old interval
    if (fetchMileageRunListIntervalId.current) {
      clearInterval(fetchMileageRunListIntervalId.current);
      fetchMileageRunListIntervalId.current = undefined;
    }

    // Set new interval
    if (interval > 0) {
      fetchMileageRunListIntervalId.current = setInterval(fetchMileageRunList, interval);
    }
  };

  useEffect(() => {
    fetchBillingYearList();
  }, []);

  useEffect(() => {
    if (filters.year) {
      fetchMileageRunList();
      setFetchMileageRunListInterval(10000);
    }
  }, [paginationProps.current, countOnPage, columnsState, filters, customerId, locationId]);

  useEffect(() => {
    return () => clearInterval(fetchMileageRunListIntervalId.current);
  }, []);

  return (
    <>
      <div>
        <div className={styles.tableHeading}>
          <div>
            <Text variant="xLarge" className={styles.highlight}>Mileage Run Log</Text>
            <SeparatorGy vertical />
            <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
          </div>
          <div>
            <Text variant="large" className={styles.highlight}>Year</Text>
            <SeparatorGy vertical />
            <Dropdown
              id="year"
              options={billingYearList.map(item => ({
                key: item.year,
                text: item.year,
              }))}
              defaultSelectedKey={filters.year}
              selectedKey={filters.year}
              onChange={(e, selectedOption: any) => setFilters((prev: any) => ({ ...prev, year: selectedOption.key }))}
              styles={{ dropdown: { width: 70 } }}
            />
          </div>
          <div>
            <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
            <Dropdown
              options={pageSizes.map(pageSize => ({
                key: pageSize,
                text: pageSize.toString(),
              }))}
              defaultSelectedKey={pageSizes[0]}
              selectedKey={countOnPage?.key}
              onChange={onChangeCountOnPage}
            />
          </div>
        </div>
        <div className={styles['table-wrapper']}>
          <table className={styles.table}>
            <thead>
              <tr>
                {
                  columnsState.map(item => (
                    <th
                      key={item.name}
                      className={classNames(
                        item.isSorted && item.isSortedDescending ?
                          styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined,
                        styles[item.key],
                      )}
                      onClick={() => onColumnClick(item)}>{item.name}
                    </th>
                  ))
                }
                <th></th>
              </tr>
            </thead>
            <tbody>
              {state.items.map(item => (
                <tr key={item.id} className={classNames(state.selectedItem === item.id ? styles.trSelected : styles.trBasic)} >
                  <td>{item.fromBillPeriod && moment(item.fromBillPeriod).format('MM/DD/YYYY')}</td>
                  <td>{item.toBillPeriod && moment(item.toBillPeriod).format('MM/DD/YYYY')}</td>
                  <td>{item.startMode}</td>
                  <td>{item.startedOn && moment(item.startedOn).format('MM/DD/YYYY h:mm:ss A')}</td>
                  <td>{item.status}</td>
                  <td>{item.completedStatus}</td>
                  <td>{item.completedOn && moment(item.completedOn).format('MM/DD/YYYY h:mm:ss A')}</td>
                  <td>{item.billType}</td>
                  <td>
                    {
                      !STATUSES_NOT_ALLOWED.includes(item.completedStatus) && <div className={styles.round}>
                        <input
                          type="checkbox"
                          id={`mileage-${item.id}`}
                          checked={state.selectedItem === item.id}
                          onChange={(e) => handleSelect(e, item.id)} />
                        <label htmlFor={`mileage-${item.id}`}></label>
                      </div>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <SeparatorGy />
        <Pagination {...paginationProps} />
        <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
          <DefaultButton id='printExportBtn' onClick={toggleShowPrintExport} text="Print/Export" />
          <DefaultButton
            id="viewErrorsButton"
            onClick={toggleShowErrorsModal}
            text="View Errors"
          />
          <PrimaryButton
            id="startButton"
            onClick={toggleClosingConfirmation}
            text="Start"
            disabled={!state.selectedItem || isInProgress || !userPermissions.isWrite || mileageAuditNotDone}
          />
        </div>
        <Dialog
          hidden={!isClosingDialogVisible}
          onDismiss={toggleClosingConfirmation}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Select run mode',
          }}
          modalProps={{ isBlocking: true }}
        >
          <ChoiceGroup defaultSelectedKey={selectedMode} options={selectMode} onChange={(e, option) => setSelectedMode(option?.key)} label="Mode" />
          <DialogFooter>
            <PrimaryButton id='startBtn' onClick={() => { handleStart(); toggleClosingConfirmation(); }} text="Start" />
            <DefaultButton onClick={toggleClosingConfirmation} text="Cancel" />
          </DialogFooter>
        </Dialog>
        <ErrorsModal
          isModalOpen={showErrorsModal}
          hideModal={toggleShowErrorsModal}
          fetchErrorsService={apiService.mileageRun.getErrors}
        />
        <PrintingModal
          isOpened={showPrintExport}
          onClose={toggleShowPrintExport}
          onPrint={handlePrint} />
        {state.loading && <LoadingScreen />}
      </div>
    </>
  );
};

export default StartMileage;