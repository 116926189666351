import { createSlice } from '@reduxjs/toolkit';
import { IMassUploadInfo } from '../components/Common/Search/IMassUploadInfo';

const initialState: {
    massUploadInfo: IMassUploadInfo | null
  } = {
    massUploadInfo: {
      customerId: null,
      locationId: null,
      formType: null,
    },
  };

const massUploadSlice = createSlice({
  name: 'massUpload',
  initialState,
  reducers: {
    setMassUploadInfo: (state = initialState, action) => {
      state.massUploadInfo = action.payload;
    },
  },
});

export const {
  setMassUploadInfo,
} = massUploadSlice.actions;

export const massUploadSelector = (state: any) => state.massUpload.massUploadInfo;
  
export default massUploadSlice.reducer;