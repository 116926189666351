import { customerTestInstance } from '../axios';

const getShipTo = (mCbCustomerId: string, pagination: any, sortOrder: any, filters: any) =>
  customerTestInstance.post('/customer/shipto', { mCbCustomerId, pagination, sortOrder, filters });

const closeRecords = (ids: Array<any>, fclosedout: string) =>
  customerTestInstance.post('/customer/shipto/close', { ids, fclosedout });

const addRecord = (addingInfo: any) =>
  customerTestInstance.post('/customer/shipto/add', { ...addingInfo });

const updateRecord = (updatingInfo: any) =>
  customerTestInstance.post('/customer/shipto/update', { ...updatingInfo });

const getServicedByList = () =>
  customerTestInstance.get('/customer/shipto/serviced-by');

const deleteRecord = (mCbCustomerId: string, locationIds: Array<any>) =>
  customerTestInstance.delete('/customer/shipto/delete', { data: { mCbCustomerId, locationIds } });

export default {
  getShipTo,
  closeRecords,
  addRecord,
  updateRecord,
  getServicedByList,
  deleteRecord,
};