import { FormEvent, useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IColumn,
  IconButton,
  IDropdownOption,
  MessageBarType,
  PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import apiService from '../../../../api';
import useNotifications from '../../../../hooks/useNotifications';
import { sortOrder } from '../../../../consts/sortOrder';
import { customerSelector, locationSelector } from '../../../../redux/recordKeepingSlice';
import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../Pagination/Pagination';
import PrintingModal from '../../../PrintingModal/PrintingModal';
import { downloadFile, printingTypes } from '../../../PrintingModal/consts';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import { IPaginationProps } from '../../../Pagination/IPaginationProps';
import { IChangeTypeCodeTabState } from './IChangeTypeCodeTabState';

import AddingModal from './AddingModal/AddingModal';

import { IChangeTypeCodeTabProps } from './IChangeTypeCodeTabProps';
import styles from './ChangeTypeCodeTab.module.scss';
import { pageSizes, columns } from './consts';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { auth_mtc_changeTypeCode} from '../../../../consts/programKeys';
import TabHeader from '../../../RecordKeeping/tabComponents/TabHeader/TabHeader';


const ChangeTypeCodeTab = (props: IChangeTypeCodeTabProps) => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_mtc_changeTypeCode); 

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);

  const [state, setState] = useState<IChangeTypeCodeTabState>({
    items: [],
    selectedItems: [],
    loading: false,
    foundCount: 0,
  });

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [showAddingModal, { toggle: toggleShowAddingModal }] = useBoolean(false);
  const [parsedErrors, setParsedErrors] = useState<any>([]);
  const [editedRowId, setEditedRowId] = useState<number>(-1);

  const updateRowsData = (currentState: any, field: string, value: string | undefined) => {
    return currentState.map((item: { [x: string]: string | undefined; id: any; }) => {
      if (editedRowId === item.id) {
        item[field] = value;
      }
      return item;
    });
  };

  const setPfxTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, bpfx: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'bpfx', bpfx) }));
  };
  const setBnoTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, bno: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'bno', bno) }));
  };
  const setSfxTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, bsfx: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'bsfx', bsfx) }));
  };
  const setNewBnoTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newTypeCode: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'newTypeCode', newTypeCode) }));
  };

  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const handleSave = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const changedCodes = state.items.map(
        ({ id, bno, bpfx, bsfx, currentTypeCode, newTypeCode, customerId }: any) =>
          ({ id, bno, bpfx, bsfx, currentTypeCode, newTypeCode, customerId }),
      );
      await apiService.changeTypeCode.updateTemporaryTable(changedCodes);
      addNotification({
        text: 'Type Code changing was successfully updated.',
        type: MessageBarType.success,
      });
      setParsedErrors([]);
      await fetch();
    } catch (e: any) {
      setState(prev => ({ ...prev, loading: false }));
      const { response } = e;
      switch (response.status) {
        case 400:
          setParsedErrors(response.data.state);
          return addNotification({
            text: 'Unable to save changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `TypeCode changes saving error: ${get(response, 'data.state[0].message', '')}`,
            type: MessageBarType.error,
          });
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.changeTypeCode.submit(state.selectedItems);
      addNotification({
        text: 'TypeCode changes were successfully submitted.',
        type: MessageBarType.success,
      });
      await paginationProps.onChangePage(1);
      await fetch();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `TypeCode changes submitting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetch = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.changeTypeCode.getTemporaryTable(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
      );
      const foundCount = data.total.found;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      addNotification({
        text: `TypeCode changes fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.changeTypeCode.delete(state.selectedItems);
      addNotification({
        text: 'Selected TypeCode change(s) were successfully deleted.',
        type: MessageBarType.success,
      });
      await paginationProps.onChangePage(1);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `TypeCode change(s) deleting error: ${get(response, 'data.state[0].message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      await fetch();
    }
  };

  const handleSelect = (e: any, itemId: any) => {
    const selectedRows = [...state.selectedItems];
    if (e.target.checked) {
      selectedRows.push(itemId);
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows.filter(row => row !== itemId) }));
    }
  };

  const handleSelectAll = (e: any, items: Array<any>) => {
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedItems: items })) : setState((prev: any) => ({ ...prev, selectedItems: [] }));
  };

  const parseErrors = (id: string, field: string) => {
    const customError = parsedErrors?.filter((error: { id: string; field: string; }) => error.id == id && error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  const highlightRowIfError = (item: any) => {
    if (parsedErrors.length > 0) {
      return parsedErrors.filter((error: { id: any; }) => error.id == item.id).length > 0;
    }
  };


  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
      };
      const headerFields = [
        { title: 'customerId', value: customerId },
        { title: 'locationId', value: locationId },
      ];
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.changeTypeCode.printExcel(requestData, headerFields) :
        await apiService.changeTypeCode.printPdf(requestData, headerFields);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (!showAddingModal)
      fetch();
  }, [
    paginationProps.current,
    showAddingModal,
    countOnPage,
    columnsState,
    customerId,
    locationId,
  ]);

  return (
    <>
      <SeparatorGy />
      <div className="ms-Grid">
        <div className={classNames('ms-Grid-row', styles.headingBlock)}>
          <div className="ms-Grid-col ms-sm12">
            <PrimaryButton onClick={toggleShowAddingModal} text="+ Add" disabled={!userPermissions.isWrite}/>
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <div className={styles.tableHeading}>
              <div>
                <Text variant="xLarge" className={styles.highlight}>Change TypeCode</Text>
                <SeparatorGy vertical />
                <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
              </div>
              <div>
                <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                <Dropdown
                  options={pageSizes.map(pageSize => ({
                    key: pageSize,
                    text: pageSize.toString(),
                  }))}
                  defaultSelectedKey={pageSizes[0]}
                  selectedKey={countOnPage?.key}
                  onChange={onChangeCountOnPage}
                />
                <SeparatorGy vertical />
                <Text variant="large" className={styles.highlight}>{state.selectedItems.length} items selected</Text>
                <SeparatorGy vertical />
                <IconButton
                  id="toggleDeletingConfirmationButton"
                  disabled={!state.selectedItems.length || !userPermissions.isWrite}
                  iconProps={{ iconName: 'Delete' }}
                  onClick={toggleDeletingConfirmation}
                />
              </div>
            </div>
            <div className={styles['table-wrapper']}>
              <table>
                <thead>
                  <tr>
                    {
                      columnsState.map(item => (
                        <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                      ))
                    }
                    <th>
                      <div className={styles.round}>
                        <input type="checkbox" id="all" checked={state.items.length !== 0 &&
                        (state.selectedItems.length === +countOnPage.key || state.selectedItems.length === state.items.length)} onChange={(e) => handleSelectAll(e, state.items.map(item => item.id))} />
                        <label htmlFor="all"></label>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    state.items.map(item => (
                      <tr
                        id={item.id}
                        key={item.id}
                        className={classNames(state.selectedItems.includes(item.id) ? styles.trSelected : styles.trBasic,
                          highlightRowIfError(item) ? styles.trError : styles.trBasic)}
                        onFocus={() => { setEditedRowId(item.id); }}>
                        <td>
                          <TextField
                            id="bpfx"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.bpfx}
                            onChange={setPfxTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'bpfx')}
                          />
                        </td>
                        <td>
                          <TextField
                            id="bno"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.bno}
                            onChange={setBnoTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'bno')}
                          />
                        </td>
                        <td>
                          <TextField
                            id="bsfx"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.bsfx}
                            onChange={setSfxTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'bsfx')}
                          />
                        </td>
                        <td><Text>{item.currentTypeCode}</Text></td>
                        <td>
                          <TextField
                            id="newTypeCode"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.newTypeCode}
                            onChange={setNewBnoTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'newTypeCode')}
                          />
                        </td>
                        <td>
                          <div className={styles.round}>
                            <input type="checkbox" id={`checkbox-${item.id}`} checked={state.selectedItems.includes(item.id)} onChange={(e) => handleSelect(e, item.id)} />
                            <label htmlFor={`checkbox-${item.id}`}></label>
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <SeparatorGy />
            <Pagination {...paginationProps} />
          </div>
        </div>
        <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
          <DefaultButton onClick={toggleShowPrintExport} text="Print/Export" />
          <PrimaryButton
            onClick={handleSave}
            text="Save"
            disabled={state.items.length === 0 || !userPermissions.isWrite}
          />
          <PrimaryButton
            id="submitButton"
            onClick={handleSubmit}
            text="Submit"
            disabled={state.selectedItems.length === 0 || !userPermissions.isWrite}
          />
        </div>
      </div>
      <AddingModal
        isOpen={showAddingModal}
        onDismiss={toggleShowAddingModal}
      />
      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to delete ${state.selectedItems.length} items?`,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default ChangeTypeCodeTab;
