import abortedIcon from '../../assets/icons/aborted-icon.svg';
import doneIcon from '../../assets/icons/done-icon.svg';
import errorIcon from '../../assets/icons/error-icon.svg';
import queuedIcon from '../../assets/icons/queued-icon.svg';
import runningIcon from '../../assets/icons/running-icon.svg';
import { HeadCell } from '../../shared/DataGridComponent/DataGridModels';

export const pageSizes = [20, 50, 100];

export const taskSchedulerHeadCell: Array<HeadCell> = [
  {
    key: 'column1',
    name: 'Job Name',
    fieldName: 'jobName',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Status',
    fieldName: 'status',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Triggers',
    fieldName: 'trigger',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'Next Run Time',
    fieldName: 'nextRunTime',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column5',
    name: 'Last Run Time',
    fieldName: 'lastTimeExecuted',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column6',
    name: 'Last Run Result',
    fieldName: 'lastRunResult',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column7',
    name: 'Job Enabled?',
    fieldName: 'enabled',
    typeField: 'boolean',
    isEditable: false,
  },
];

export const statuseClassNameMapping = {
  QUEUED: {
    iconSrc: queuedIcon,
    className: 'queuedStatus',
  },
  RUNNING: {
    iconSrc: runningIcon,
    className: 'runningStatus',
  },
  ERROR: {
    iconSrc: errorIcon,
    className: 'errorStatus',
  },
  ABORTED: {
    iconSrc: abortedIcon,
    className: 'abortedStatus',
  },
  DONE: {
    iconSrc: doneIcon,
    className: 'doneStatus',
  },
};
