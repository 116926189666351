import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { FC } from 'react';
import SeparatorGy from '../components/SeparatorGy/SeparatorGy';

interface IStepperProps {
    steps: Array<string>,
    currentStep: number,
    setCurrentStep: any,
}

const Stepper: FC<IStepperProps> = ({steps, currentStep, setCurrentStep}) => {

  return (
    <div className="stepper">
      <ul>
        {
          steps.map((step, index) => (
            <li className={index === currentStep ? 'active' : ''} onClick={() => setCurrentStep(index)}>
              <div>{step}</div>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default Stepper;