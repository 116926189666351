import { FunctionComponent } from 'react';
import Contacts from './tabComponents/Contacts';
import General from './tabComponents/General';
import Accounting from './tabComponents/Accounting/Accounting';
import BillTo from './tabComponents/BillTo/BillTo';
import Fleet from './tabComponents/Fleet';
import HistoryComponent from './tabComponents/History';
import CommonOwnerComponent from './tabComponents/CommonOwner';
import KeyCharacteristics from './tabComponents/KeyCharacteristics/KeyCharacteristics';
import ShipTo from './tabComponents/ShipTo/ShipTo';
import EquipmentComponent from './tabComponents/Equipment/Equipment';
import {
  auth_customer_accounting,
  auth_customer_billTo,
  auth_customer_contacts,
  auth_customer_equipment,
  auth_customer_fleetData,
  auth_customer_general,
  auth_customer_history,
  auth_customer_keyCharacteristics,
  auth_customer_owner,
  auth_customer_shipTo,
} from '../../../consts/programKeys';

export enum CUSTOMER_TABS {
  GENERAL = 'general',
  CONTACTS = 'contacts',
  HISTORY = 'history',
  FLEET_DATA = 'fleetData',
  BILL_TO = 'billTo',
  SHIP_TO = 'shipTo',
  ACCOUNTING = 'accounting',
  OWNER = 'owner',
  EQUIPMENT = 'equipment',
  KEY_CHARACTERISTICS = 'keyCharacteristics',
};

/** Each tab into left menu must have a component */
export const customerTabs: CustomerTabs = {
  general: {
    id: CUSTOMER_TABS.GENERAL,
    accessKey: auth_customer_general,
    name: 'General',
    component: General,
    actionType: ['add', 'edit', 'active'],
    subTabs: null,
  },
  contacts: {
    id: CUSTOMER_TABS.CONTACTS,
    accessKey: auth_customer_contacts,
    name: 'Contacts',
    component: Contacts,
    actionType: ['edit', 'active'],
    subTabs: null,
  },
  history: {
    id: CUSTOMER_TABS.HISTORY,
    accessKey: auth_customer_history,
    name: 'History',
    component: HistoryComponent,
    actionType: ['edit', 'active'],
    subTabs: null,
  },
  fleetData: {
    id: CUSTOMER_TABS.FLEET_DATA,
    name: 'Fleet Data',
    accessKey: auth_customer_fleetData,
    component: Fleet,
    actionType: ['edit', 'active'],
    subTabs: null,
  },
  bill: {
    id: CUSTOMER_TABS.BILL_TO,
    accessKey: auth_customer_billTo,
    name: 'Bill To',
    component: BillTo,
    actionType: ['edit'],
    subTabs: null,
  },
  ship: {
    id: CUSTOMER_TABS.SHIP_TO,
    accessKey: auth_customer_shipTo,
    name: 'Ship To',
    component: ShipTo,
    actionType: ['edit'],
    subTabs: null,
  },
  accounting: {
    id: CUSTOMER_TABS.ACCOUNTING,
    accessKey: auth_customer_accounting,
    name: 'Accounting',
    component: Accounting,
    actionType: ['edit'],
    subTabs: null,
  },
  owner: {
    id: CUSTOMER_TABS.OWNER,
    accessKey: auth_customer_owner,
    name: 'Owner',
    component: CommonOwnerComponent,
    actionType: ['edit'],
    subTabs: null,
  },
  equipment: {
    id: CUSTOMER_TABS.EQUIPMENT,
    accessKey: auth_customer_equipment,
    name: 'Equipment',
    component: EquipmentComponent,
    actionType: ['edit'],
    subTabs: null,
  },
  keyCharacteristics: {
    id: CUSTOMER_TABS.KEY_CHARACTERISTICS,
    accessKey: auth_customer_keyCharacteristics,
    name: 'Key Characteristics',
    component: KeyCharacteristics,
    actionType: ['edit'],
    subTabs: null,
  },
};

export const getTabById: any = (tabId: string) => Object.values(customerTabs).filter((tab) => tab.id === tabId)[0];
export interface CustomerTabs {
  general?: TabComponent;
  contacts?: TabComponent;
  history?: TabComponent;
  fleetData?: TabComponent;
  bill?: TabComponent;
  ship?: TabComponent;
  accounting?: TabComponent;
  owner?: TabComponent;
  equipment?: TabComponent;
  keyCharacteristics?: TabComponent;
}

export interface TabComponent {
  id: string,
  name: string,
  accessKey?: string,
  component: FunctionComponent<any> | null
  actionType: string[];
  subTabs: any;
}
