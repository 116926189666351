import React, { useState, FunctionComponent } from 'react';
import { get } from 'lodash';
import { setBillingDatatabs } from './const';
import { Pivot, PivotItem, Text } from '@fluentui/react';
import classNames from 'classnames';
import styles from './SetBillingData.module.scss';

function SetBillingData() {
  const [currentTab, setCurrentTab] = useState(setBillingDatatabs.manageBillingOptions.name);

  const handleLinkClick = (item?: PivotItem) => {
    const newTab = get(item, 'props.itemKey', '');
    setCurrentTab(newTab);
  };

  const getTabId = (itemKey: string) => `ShapeColorPivot_${itemKey}`;

  const renderTab = (TabComponent: FunctionComponent) => {
    if (TabComponent) return <TabComponent />;
  };
  return (
    <div className={classNames('ms-Grid', 'record-keeping-container', styles.mainContainer)} dir='ltr'>
      <div className='ms-Grid-row record-keeping-header'>
        <div className={classNames('ms-Grid-col', 'ms-lg12', styles.titleContainer)}>
          <Text variant='xxLargePlus' className={styles.highlight}>Set Billing Data</Text>
        </div>
      </div>
      <div className='ms-Grid-row'>
        <div className={classNames(
          'ms-Grid-col',
          'ms-lg12',
          'tabulatorWrapper',
          styles.headerTabsContainer,
        )}>
          <Pivot
            aria-label='Separately Rendered Content Pivot Example'
            className='pivot-gy-colors'
            selectedKey={currentTab}
            onLinkClick={handleLinkClick}
            getTabId={getTabId}
            linkFormat='tabs'
            linkSize='large'
          >
            {Object.values(setBillingDatatabs).map((tab, index) => (
              <PivotItem headerText={tab.name} key={index} itemKey={tab.name}>
                <div className={classNames('vertical-pivot-container', styles.tabsContainer)}>{renderTab(tab.component)}</div>
              </PivotItem>
            ))}
          </Pivot>
        </div>
      </div>
    </div>
  );
}

export default SetBillingData;
