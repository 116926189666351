export const pageSizes = [5, 10, 20];

export const columns: Array<any> = [
  {
    key: 'column0',
    name: 'Ship To #',
    fieldName: 'shipToNumber',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'Location Code',
    fieldName: 'locationCode',
  },
  {
    key: 'column2',
    name: 'DSS Ration',
    fieldName: 'dssRation',
  },
  {
    key: 'column3',
    name: 'Address Line 1',
    fieldName: 'addressLine1',
  },
  {
    key: 'column4',
    name: 'City',
    fieldName: 'city',
  },
  {
    key: 'column5',
    name: 'State',
    fieldName: 'state',
  },
  {
    key: 'column6',
    name: 'Country',
    fieldName: 'country',
  },
  {
    key: 'column7',
    name: 'Serviced By',
    fieldName: 'servicedBy',
  },
  {
    key: 'column8',
    name: 'Closed',
    fieldName: 'fClosedOut',
  },
];

export const mockShipTo = {
  data: [
    {
      locationId: 'string',
      shipToNumber: 'string',
      locationCode: 'string',
      dssRation: 0,
      addressLine1: 'string',
      city: 'string',
      state: 'string',
      country: 'string',
      servicedBy: 'string',
      fClosedOut: 'string',
    },
  ],
  total: {
    all: 1,
    found: 1,
  },
};