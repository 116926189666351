import { DefaultButton, IconButton, PrimaryButton, TextField, Text, MessageBarType } from '@fluentui/react';
import { FC, useEffect, useState } from 'react';
import { auth_tireOrder_placeOriginal } from '../../../../../../consts/programKeys';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import { IShippingInstructionsProps } from './IShippingInstructionsProps';
import styles from './ShippingInstructions.module.scss';
import apiService from '../../../../../../api';
import useNotifications from '../../../../../../hooks/useNotifications';


const ShippingInstructions: FC<IShippingInstructionsProps> = ({ orderToBeAdded, mode }) => {
  const [state, setState] = useState({
    loading: false,
  });

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_tireOrder_placeOriginal);

  const { addNotification } = useNotifications();

  const [shipInstruction, setShipInstruction] = useState<any>('');
  const [shipInstructions, setShipInstructions] = useState<any>([]);

  const defaultShipInstruction = shipInstructions.length ? 
    (shipInstructions[0].shipInstructions ? shipInstructions[0].shipInstructions : '') + '\n' + (shipInstructions[1].shipInstructions ? shipInstructions[1].shipInstructions : '') :
    '';

  const fetchText = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await apiService.tireOrders.getOrderText(
        orderToBeAdded.id,
      );
      setShipInstructions(data.data);
    } catch (e: any) {
      setState(prev => ({ ...prev, loading: false }));
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSave = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.tireOrders.saveShipInstructions(
        orderToBeAdded.id,
        [
          { ...shipInstructions[0], shipInstructions: shipInstruction },
          { ...shipInstructions[1], shipInstructions: null },
        ],
      );
      addNotification({
        text: 'Ship instructions was successfully saved',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({ 
        text: `Ship instructions saving error: ${response.data.message}`, 
        type: MessageBarType.error ,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
      fetchText();
    }
  };

  const handleClear = async () => {
    setShipInstruction('');
  };

  useEffect(() => {
    if (orderToBeAdded.id) fetchText();
  }, []);

  useEffect(() => {
    setShipInstruction(defaultShipInstruction);
  }, [shipInstructions]);

  return (
    <>
      <div className={styles.container}>
        <div>
          <div className={styles.headingBlock}>
            <div>
              <Text variant="large" className={styles.highlight}>Instructions</Text>
            </div>
            <div>
              <IconButton
                onClick={() => {navigator.clipboard.writeText(shipInstruction);}}
                iconProps={{ iconName: 'Copy' }}
              />
            </div>
          </div>
          <div>
            <TextField 
              multiline 
              rows={5} 
              value={shipInstruction}
              onChange={(e, value: any) => setShipInstruction(value)}
              disabled={mode === 'view' ? true : false}
            />
          </div>
        </div>
        <div className={styles.buttonsWrapper}>
          <DefaultButton onClick={handleClear} text="Clear" disabled={!userPermissions.isWrite || mode === 'view' ? true : false}/>
          <PrimaryButton 
            onClick={handleSave} 
            text="Save" 
            disabled={
              !orderToBeAdded.id || 
              shipInstruction === defaultShipInstruction || 
              !userPermissions.isWrite ||
              mode === 'view' ? true : false
            }
          />
        </div>
      </div>   
      {state.loading && <LoadingScreen />}
    </>
  );
};
  
export default ShippingInstructions;