import { IDropdownOption } from '@fluentui/react';
import { IFiltersState } from './IFiltersState';

export const pageSizes = [5, 10, 20];

export const tableColumns = [
  {
    key: 'column0',
    name: 'Message',
    fieldName: 'message',
  },
  {
    key: 'column1',
    name: 'Severity',
    fieldName: 'finalStatus',
  },
  {
    key: 'column2',
    name: 'Description',
    fieldName: 'description',
  },
  {
    key: 'column3',
    name: 'Start date',
    fieldName: 'startDate',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'End date',
    fieldName: 'endDate',
  },
];

export const allOption: IDropdownOption = {
  key: 'All',
  text: 'All',
};

export const emptyFilters: IFiltersState = {
  searchString: '',
  severities: [allOption.key],
};

export const severityClassNameMapping = {
  Critical: 'criticalSeverity',
  Major: 'majorSeverity',
  Moderate: 'moderateSeverity',
  Low: 'lowSeverity',
};
