import React, { useCallback } from 'react';
import { MessageBarType } from '@fluentui/react';
import { uniqueId } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { pushToast, closeToast, setToasts, notificationsSelector } from '../redux/notificationsSlice';

const useNotifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(notificationsSelector);

  const addNotification = useCallback((newNotification: any) => {
    const id = uniqueId();
    if (newNotification.type === MessageBarType.success) {
      dispatch(
        setToasts([
          ...notifications.filter(({ type }: any) => type !== MessageBarType.error),
          { id, ...newNotification },
        ]),
      );
      setTimeout(() => dispatch(closeToast(id)), 5000);
    } else {
      dispatch(pushToast({ id, ...newNotification }));
    }
  }, [dispatch, notifications]);

  const closeNotification = useCallback(
    (id: any) => {
      dispatch(closeToast(id));
    },
    [dispatch],
  );

  return {
    addNotification,
    closeNotification,
  };
};

export default useNotifications;