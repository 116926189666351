import { FormEvent, useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { get, isEmpty, isNil, uniqueId } from 'lodash';
import moment from 'moment';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IColumn,
  IconButton,
  IDropdownOption,
  MessageBarType,
  PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';

import apiService from '../../../../../../api';
import useNotifications from '../../../../../../hooks/useNotifications';
import AutocompleteInput from '../../../../../../shared/AutocompleteInput';
import { sortOrder } from '../../../../../../consts/sortOrder';
import { customerSelector, locationSelector, setTireForReinstate } from '../../../../../../redux/recordKeepingSlice';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_vehicleMiles_applyMilesToTires } from '../../../../../../consts/programKeys';

import SelectingModal from '../../../../../SelectingModal/SelectingModal';
import ErrorsModal from '../../../../../ErrorsModal/ErrorsModal';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../../Pagination/Pagination';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';

import ViewTireDetailsModal from '../../../../ViewTireDetailsModal/ViewTireDetailsModal';

import { IApplyMilesToTiresProps } from './IApplyMilesToTiresProps';
import { IApplyMilesToTiresState } from './IApplyMilesToTiresState';
import { columns, columnsByVehicle, emptyApplyingForm, viewConsignedTiresColumns } from './consts';
import { pageSizes } from '../../../../../../consts/recordKeeping';

import styles from './ApplyMilesToTires.module.scss';

const ApplyMilesToTires = (props: IApplyMilesToTiresProps) => {

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const onColumnByVehicleClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsByVehicleState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsByVehicleState(newColumns);
  };

  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);

  const dispatch = useDispatch();

  const [state, setState] = useState<IApplyMilesToTiresState>({
    items: [],
    selectedItem: null,
    loading: false,
    foundCount: 0,
    itemsByVehicle: [],
    selectedItemsByVehicle: [],
    foundCountByVehicle: 0,
  });

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [paginationPropsByVehicle, setPaginationPropsByVehicle] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationPropsByVehicle((prev: any) => ({ ...prev, current: newPage })),
  });

  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [countOnPageByVehicle, setCountOnPageByVehicle] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const [columnsByVehicleState, setColumnsByVehicleState] = useState<Array<any>>(columnsByVehicle);
  const [brands, setBrands] = useState<Array<any>>([]);
  const [detailsVisible, { toggle: toggleDetailsVisible }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [isApplyingOpened, { toggle: toggleIsApplyingOpened, setTrue: makeApplyingOpened }] = useBoolean(false);
  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [isFirstLoading, { setFalse: wasFirstLoading }] = useBoolean(true);
  const [showErrorsModal, { toggle: toggleShowErrorsModal }] = useBoolean(false);
  const [showBrandsModal, { toggle: toggleShowBrandsModal }] = useBoolean(false);
  const [applyingForm, setApplyingForm] = useState<any>(emptyApplyingForm);
  const [searchBrand, setSearchBrand] = useState<any>({});
  const [parsedApplyingErors, setParsedAddingErrors] = useState<any>([]);
  const [consignedInfo, setConsignedInfo] = useState<any>({});

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_vehicleMiles_applyMilesToTires);

  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const onChangeCountOnPageByVehicle = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationPropsByVehicle((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPageByVehicle(item);
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const getSortOrderByVehicle = () => {
    const { fieldName, isSortedDescending } = columnsByVehicleState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const openViewTireDetailsWindow = (item: any) => {
    const { consignedTireId, pfx, bno, sfx } = item;
    dispatch(setTireForReinstate({ tireId: consignedTireId, pfx, bno, sfx }));
    toggleDetailsVisible();
  };

  const handleSubmit = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.applyMilesToTires.submit(customerId);
      addNotification({
        text: 'Miles on tires were successfully submitted.',
        type: MessageBarType.success,
      });
      await paginationPropsByVehicle.onChangePage(1);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Miles on tires submitting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      await fetchByVehicle();
    }
  };

  const mountingProcess = async () => {
    await fetchApplyMilesToTires();
    await fetchBrands();
    wasFirstLoading();
  };

  const fetchApplyMilesToTires = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.applyMilesToTires.get(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
      );
      const foundCount = data.total.found;
      const items = data.data.map(({ from, to, ...other }: any) => ({ from: !isNil(from) && moment(from).format('MM/DD/YYYY'), to: !isNil(to) && moment(to).format('MM/DD/YYYY'), ...other }));
      setState((prev: any) => ({ ...prev, items, selectedItem: null, foundCount }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching vehicles error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchByVehicle = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrderByVehicle();
      const { data }: any = await apiService.applyMilesToTires.getByVehicle(
        get(getVehicleById(state.selectedItem), 'vNo', ''),
        { pageNumber: paginationPropsByVehicle.current, pageSize: +countOnPageByVehicle.key },
        (sortOrder && { ...sortOrder }),
        customerId,
        locationId,
      );
      const foundCountByVehicle = data.total.found;
      const itemsByVehicle = data.data;
      setState((prev: any) => ({ ...prev, itemsByVehicle, foundCountByVehicle, selectedItemsByVehicle: [] }));
      setPaginationPropsByVehicle((prev: any) => ({ ...prev, total: Math.ceil(foundCountByVehicle / +countOnPageByVehicle.key) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching by vehicle error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchConsignedInfo = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.applyMilesToTires.getConsignedInfo(
        searchBrand,
        customerId,
      );
      setConsignedInfo(data);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching by vehicle error: ${response.data.message}`,
        type: MessageBarType.error,
      });
      setConsignedInfo({});
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchBrands = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.applyMilesToTires.getBrands(
        customerId,
        locationId,
      );
      setBrands(data.map((brand: any) => ({ id: uniqueId(), ...brand })));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Brands fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const onApplyChanges = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.applyMilesToTires.applyMiles(state.selectedItem, consignedInfo.consignedId, { pfx: searchBrand.pfx, bno: searchBrand.bno, sfx: searchBrand.sfx }, applyingForm.applyUnits, customerId, locationId);
      setParsedAddingErrors([]);
      addNotification({
        text: 'Miles were succeccfully applied to tires.',
        type: MessageBarType.success,
      });
      setSearchBrand({});
      setConsignedInfo({});
      setApplyingForm(emptyApplyingForm);
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          setParsedAddingErrors(response.data.state);
          return addNotification({
            text: 'Applying miles to tires error.',
            type: MessageBarType.error,
          });
        default:
          return addNotification({
            text: response.data.message,
            type: MessageBarType.error,
          });
      }
    } finally {
      fetchByVehicle();
    }
  };

  const getVehicleById = (idToFind: any) => state.items.find(({ id }: any) => id === idToFind);

  const handleSelect = (itemId: any) => {
    setState((prev: any) => ({ ...prev, selectedItem: getVehicleById(itemId).id }));
  };

  const handleSelectByVehicle = (e: any, itemId: any) => {
    if (e.target.checked) {
      setState((prev: any) => ({ ...prev, selectedItemsByVehicle: [...state.selectedItemsByVehicle, itemId] }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItemsByVehicle: state.selectedItemsByVehicle.filter(row => row !== itemId) }));
    }
  };

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.applyMilesToTires.delete(state.selectedItemsByVehicle);
      addNotification({
        text: 'Selected tire(s) were successfully deleted.',
        type: MessageBarType.success,
      });
      await paginationPropsByVehicle.onChangePage(1);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Applying miles to tires deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      await fetchByVehicle();
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        pagination: { pageNumber: paginationPropsByVehicle.current, pageSize: +countOnPageByVehicle.key },
        sortOrder,
        vehicleNo: get(getVehicleById(state.selectedItem), 'vNo', ''),
        customerId,
        locationId,
      };
      const headerFields = [
        { title: 'customerId', value: customerId },
        { title: 'locationId', value: locationId },
      ];
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.applyMilesToTires.printExcel(requestData, headerFields) :
        await apiService.applyMilesToTires.printPdf(requestData, headerFields);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const getBrandById = (idToFind: any) => brands.find(({ id }: any) => idToFind === id);

  const onChangeSearchBrand = (newBrand: any) => setSearchBrand(getBrandById(newBrand));

  const onSelectBrand = (newSelectedBrandId: any) => {
    setSearchBrand(getBrandById(newSelectedBrandId));
    toggleShowBrandsModal();
  };

  const parseApplyingErrors = (field: string) => {
    const customError = parsedApplyingErors?.filter((error: { field: string; }) => error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  useEffect(() => {
    if (!isFirstLoading)
      fetchApplyMilesToTires();
  }, [
    paginationProps.current,
    countOnPage,
    columnsState,
    customerId, locationId,
  ]);

  useEffect(() => {
    mountingProcess();
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
  }, [
    customerId, locationId,
  ]);

  useEffect(() => {
    if (state.selectedItem) {
      fetchByVehicle();
      setSearchBrand({});
      setConsignedInfo({});
      makeApplyingOpened();
    }
  }, [
    paginationPropsByVehicle.current,
    countOnPageByVehicle,
    columnsByVehicleState,
    state.selectedItem,
  ]);

  useEffect(() => {
    if (!isEmpty(searchBrand))
      fetchConsignedInfo();
  }, [
    searchBrand, customerId, locationId,
  ]);

  return (
    <>
      <div className="ms-Grid">
        <div className={classNames('ms-Grid-row', styles.mainRow)}>
          <div className={classNames('ms-Grid-col', isApplyingOpened ? 'ms-sm6' : 'ms-sm11')}>
            <div className={styles.tableHeading}>
              <div>
                <Text variant="xLarge" className={styles.highlight}>Available Open Wheel Positions</Text>
                <SeparatorGy vertical />
                <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
              </div>
              <div>
                <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                <Dropdown
                  options={pageSizes.map(pageSize => ({
                    key: pageSize,
                    text: pageSize.toString(),
                  }))}
                  defaultSelectedKey={pageSizes[0]}
                  selectedKey={countOnPage?.key}
                  onChange={onChangeCountOnPage}
                />
              </div>
            </div>
            <div className={styles['table-wrapper']}>
              <table>
                <thead>
                  <tr>
                    {
                      columnsState.map(item => (
                        <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                      ))
                    }
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    state.items.map(item => (
                      <tr key={item.id}
                        className={classNames(state.selectedItem === item.id ? styles.trSelected : styles.trBasic)}
                      >
                        <td>{item.vNo}</td>
                        <td>{item.position}</td>
                        <td>{item.from}</td>
                        <td>{item.to}</td>
                        <td>
                          <div className={styles.round}>
                            <input
                              type="checkbox"
                              id={item.id}
                              checked={state.selectedItem === item.id}
                              onChange={() => handleSelect(item.id)}
                            />
                            <label htmlFor={item.id}></label>
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <SeparatorGy />
            <Pagination {...paginationProps} />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm1', styles.tablesSeparator)}>
            <IconButton id="togglingButton" onClick={toggleIsApplyingOpened} iconProps={{ iconName: isApplyingOpened ? 'ChevronRight' : 'ChevronLeft' }} />
            <SeparatorGy vertical />
          </div>

          {isApplyingOpened &&
            <div className="ms-Grid-col ms-sm5">
              <div className={styles.tableHeading}>
                <Text variant="xLarge" className={styles.highlight}>Apply Miles to Tires</Text>
              </div>
              <Text block variant="large" className={styles.highlight}>Brand Number</Text>
              <div className={styles.formBlock}>
                <AutocompleteInput
                  chooseCurrentItem={onChangeSearchBrand}
                  value={get(searchBrand, 'id', null)}
                  label="Prefix"
                  list={brands.map(({ id, pfx }) => ({
                    key: id,
                    text: pfx,
                  }))}
                  errorMessage={parseApplyingErrors('pfx')}
                  emptyExpanded
                  required
                />
                <AutocompleteInput
                  chooseCurrentItem={onChangeSearchBrand}
                  value={get(searchBrand, 'id', null)}
                  label="Brand Number"
                  list={brands.map(({ id, bno }) => ({
                    key: id,
                    text: bno,
                  }))}
                  errorMessage={parseApplyingErrors('bno')}
                  emptyExpanded
                  required
                />
                <AutocompleteInput
                  chooseCurrentItem={onChangeSearchBrand}
                  value={get(searchBrand, 'id', null)}
                  label="Suffix"
                  list={brands.filter(({ sfx }: any) => !isNil(sfx)).map(({ id, sfx }) => ({
                    key: id,
                    text: sfx,
                  }))}
                  errorMessage={parseApplyingErrors('sfx')}
                  emptyExpanded
                />
                <IconButton
                  id="searchBrands"
                  iconProps={{ iconName: 'Search' }}
                  onClick={toggleShowBrandsModal}
                />
              </div>
              <div className={styles.formBlock}>
                <TextField
                  id="typeCode"
                  label="Type Code"
                  value={get(consignedInfo, 'typeCode')}
                  disabled
                />
                <TextField
                  id="tireSize"
                  label="Tire Size"
                  value={get(consignedInfo, 'tireSize')}
                  disabled
                />
                <TextField
                  id="totalUnits"
                  label="Total Units"
                  value={get(consignedInfo, 'totalUnits')}
                  disabled
                />
              </div>
              <SeparatorGy />
              <div className={styles.formBlock}>
                <TextField
                  label="Miles"
                  value={get(getVehicleById(state.selectedItem), 'accMiles', '')}
                  disabled
                />
                <TextField
                  id="applyUnits"
                  label="Apply Units"
                  value={applyingForm.applyUnits}
                  onChange={(e: any, applyUnits: any) => setApplyingForm((prev: any) => ({ ...prev, applyUnits }))}
                  errorMessage={parseApplyingErrors('applyUnits')}
                  required
                />
                <SeparatorGy vertical />
                <div>
                  <div>
                    <Text variant="xLarge" block className={styles.highlight}>Vehicle Number</Text>
                    <Text variant="large" className={styles.highlight}>{get(getVehicleById(state.selectedItem), 'vNo', '')}</Text>
                  </div>
                  <div>
                    <Text variant="xLarge" block className={styles.highlight}>Position</Text>
                    <Text variant="large" className={styles.highlight}>{get(getVehicleById(state.selectedItem), 'pos', '')}</Text>
                  </div>
                </div>
                <PrimaryButton
                  text="Apply"
                  disabled={
                    isNil(state.selectedItem) ||
                    isEmpty(searchBrand) ||
                    isEmpty(consignedInfo) ||
                    isEmpty(applyingForm.applyUnits) ||
                    !userPermissions.isWrite
                  }
                  onClick={onApplyChanges}
                />
              </div>
              <SeparatorGy />
              <div className={styles.tableHeading}>
                <div>
                  <Text variant="xLarge" className={styles.highlight}>Processed Tires</Text>
                  <SeparatorGy vertical />
                  <Text variant="xLarge" className={styles.highlight}>{state.foundCountByVehicle} found</Text>
                </div>
                <div>
                  <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                  <Dropdown
                    options={pageSizes.map(pageSize => ({
                      key: pageSize,
                      text: pageSize.toString(),
                    }))}
                    defaultSelectedKey={pageSizes[0]}
                    selectedKey={countOnPageByVehicle?.key}
                    onChange={onChangeCountOnPageByVehicle}
                  />
                  <SeparatorGy vertical />
                  <Text variant="large" className={styles.highlight}>{state.selectedItemsByVehicle.length} items selected</Text>
                  <SeparatorGy vertical />
                  <IconButton
                    id="toggleDeletingConfirmationButton"
                    disabled={!state.selectedItemsByVehicle.length || !userPermissions.isWrite}
                    iconProps={{ iconName: 'Delete' }}
                    onClick={toggleDeletingConfirmation}
                  />
                </div>
              </div>  
              <div className={styles['table-wrapper']}>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      {
                        columnsByVehicleState.map(item => (
                          <th
                            key={item.name}
                            className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined}
                            onClick={() => onColumnByVehicleClick(item)}
                          >
                            {item.name}
                          </th>
                        ))
                      }
                      <th>

                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      state.itemsByVehicle.map(item => (
                        <tr key={item.id}
                          className={classNames(state.selectedItemsByVehicle.includes(item.id) ? styles.trSelected : styles.trBasic)}
                        >
                          <td>
                            <IconButton
                              id="viewDetails"
                              iconProps={{ iconName: 'View' }}
                              onClick={() => openViewTireDetailsWindow(item)}
                            />
                          </td>
                          <td>{item.pfx}</td>
                          <td>{item.bno}</td>
                          <td>{item.sfx}</td>
                          <td>{item.totalMiles}</td>
                          <td>{item.appliedUnits}</td>
                          <td>
                            <div className={styles.round}>
                              <input
                                type="checkbox"
                                id={`applying-${item.id}`}
                                checked={state.selectedItemsByVehicle.includes(item.id)}
                                onChange={(e) => handleSelectByVehicle(e, item.id)}
                              />
                              <label htmlFor={`applying-${item.id}`}></label>
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
              <SeparatorGy />
              <Pagination {...paginationPropsByVehicle} />
              <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
                <DefaultButton
                  id="printExportButton"
                  onClick={toggleShowPrintExport}
                  text="Print/Export"
                />
                <DefaultButton
                  id="viewErrorsButton"
                  onClick={toggleShowErrorsModal}
                  text="View Errors"
                />
                <PrimaryButton
                  id="submitButton"
                  disabled={state.selectedItemsByVehicle.length === 0 || !userPermissions.isWrite}
                  onClick={handleSubmit}
                  text="Submit"
                />
              </div>
            </div>
          }
        </div>
      </div>
      {detailsVisible && <ViewTireDetailsModal isOpened={detailsVisible} onDismiss={toggleDetailsVisible} />}
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      <SelectingModal
        isOpen={showBrandsModal}
        title="View Consigned Tires"
        selectingList={brands}
        onDismiss={toggleShowBrandsModal}
        onSubmit={onSelectBrand}
        columns={viewConsignedTiresColumns}
        preselectedKey={get(searchBrand, 'id', null)}
      />
      <ErrorsModal
        isModalOpen={showErrorsModal}
        hideModal={toggleShowErrorsModal}
        fetchErrorsService={apiService.applyMilesToTires.getErrors}
      />
      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to delete ${state.selectedItemsByVehicle.length} items?`,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default ApplyMilesToTires;
