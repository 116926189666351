import {
  Checkbox, DatePicker,
  DayOfWeek,
  defaultDatePickerStrings, MessageBarType, Text, TextField,
} from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import classNames from 'classnames';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import apiService from '../../../../../api';
import { auth_terms_billingBasis } from '../../../../../consts/programKeys';
import useNotifications from '../../../../../hooks/useNotifications';
import { useUserPermissions } from '../../../../../hooks/useUserPermissions';
import DataGridComponent from '../../../../../shared/DataGridComponent';
import { defaultDataGridPageSizes } from '../../../../../shared/DataGridComponent/utils';
import { transformDate } from '../../../../../shared/transformDate';
import LoadingScreen from '../../../../LoadingScreen/LoadingScreen';
import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import styles from './BillingBasis.module.scss';
import { BillinBasisHeadCell, defaultSorting } from './const';
import { IBillingBasis } from './IBillingBasis';

const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };

function BillingBasis() {
  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_terms_billingBasis);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [billingBasisList, setBillingBasisList] = useState<IBillingBasis[]>([]);
  const [billingBasisToUpd, setBillingBasisToUpd] = useState<Array<IBillingBasis>>([]);
  const { contractId } = useParams<any>();
  const { addNotification } = useNotifications();
  const [selectedBilling, setSelectedBilling] = useState<any>(null);
  const [periodMonth, setPeriodMonth] = useState('');
  const [nextBonusDue, setNextBonusDue] = useState<any>();
  const [isBonus, setIsBonus] = useState(false);

  const fetchBillingBasisList = async (
    pagination: any = { pageSize: defaultDataGridPageSizes[0], pageNumber: 1 },
    filterText: any = '',
    sortOrder?: any,
  ) => {
    setIsLoading(true);
    try {
      const response = await apiService.terms.getBillingBasis(
        pagination,
        sortOrder,
        filterText,
        contractId,
      );
      setBillingBasisList(response?.data?.data);
      setTotalRows(response?.data?.total?.all);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Billing Basis fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBillingBasisDetails = async () => {
    try {
      const { data: {
        bonusBilling,
        bonusBillingPeriod,
        nextBonusDue,
      } }: any = await apiService.terms.getBillingBasisDetails(contractId);

      setIsBonus(bonusBilling);
      setPeriodMonth(bonusBillingPeriod);
      setNextBonusDue(nextBonusDue);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Billing Basis fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const fetchApplyToAll = async () => {
    try {
      const ids = getIdsFromArray(billingBasisList).filter(
        (id: any) => id != selectedBilling.billingBasisId);

      const { data }: any = await apiService.terms.copyToAllBillingBasis(
        selectedBilling.billingBasisId,
        ids);

      addNotification({
        text: 'Data updated',
        type: MessageBarType.success,
      });
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Billing Basis fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const fetchReset = async () => {
    try {
      await apiService.terms.resetBillingBasis(selectedBilling.billingBasisId);
      addNotification({
        text: 'Data updated',
        type: MessageBarType.success,
      });
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Billing Basis fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const fetchUpdateBillingBasis = async (payload: any) => {
    setIsLoading(true);
    try {
      await apiService.terms.updateBillingBasis(payload);
      addNotification({
        text: 'Data updated',
        type: MessageBarType.success,
      });
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Billing Basis Update error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateBillingBonus = async () => {
    setIsLoading(true);
    try {
      await apiService.terms.updateBonusBilling({
        contractId,
        bonusBilling: isBonus,
        bonusBillingPeriod: periodMonth,
        nextBonusDue: nextBonusDue ? transformDate(moment(nextBonusDue).format('MM/DD/YYYY')) : '',
      });
      addNotification({
        text: 'Billing Bonus Updated',
        type: MessageBarType.success,
      });
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Billing Bonus Update error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyToAll = async () => {
    setIsLoading(true);
    await fetchApplyToAll();
    await fetchBillingBasisList();
    setIsLoading(false);
  };

  const handleReset = async () => {
    setIsLoading(true);
    await fetchReset();
    await fetchBillingBasisList();
    setIsLoading(false);
  };

  const handleUpdateBillingBasisTable = (rowsUpdated: any) => {
    setBillingBasisToUpd(rowsUpdated);
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    setIsLoading(true);
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    await fetchBillingBasisList(pagination, searchedText, null);
    setIsLoading(false);
  };

  const handleSelectedRows = (rowsSelected: IBillingBasis[]) => {
    if (!rowsSelected.length) return;
    setSelectedBilling(rowsSelected[0]);
  };

  const handleUpdateBillingBasis = async () => {
    await fetchUpdateBillingBasis({
      items:
        billingBasisToUpd.map(billingBasis => { return { ...billingBasis, contractId }; }),
    });
    await fetchBillingBasisList();
    setBillingBasisToUpd([]);
  };

  useEffect(() => {
    if (!contractId) return;
    fetchBillingBasisDetails();
  }, [contractId]);

  const getIdsFromArray = (array: any) => array?.map((obj: any) => obj.billingBasisId);

  return (
    <div data-testid='billing-basis' id='billing-basis' className={styles.billingBasis}>
      <DataGridComponent
        idTable={'bill-basis'}
        title='Billing Basis'
        headCells={BillinBasisHeadCell}
        rowsTable={billingBasisList}
        totalDataFound={totalRows}
        enableSearching
        enableCheckBox
        enableRowsPerPage
        enableSorting={false}
        handleSelectRow={handleSelectedRows}
        handleChangeDataGridState={handleChangeDataGridState}
        handleUpdate={handleUpdateBillingBasisTable}
        isLoading={isLoading}
      />
      <div className={classNames(styles.actionBtns)}>
        <PrimaryButton
          text="Set Billing Data"
          disabled={!userPermissions.isWrite || !billingBasisToUpd.length}
          onClick={handleUpdateBillingBasis}
        />
        <PrimaryButton
          text="Copy to All"
          disabled={!userPermissions.isWrite || selectedBilling == null}
          onClick={handleCopyToAll}
        />
        <PrimaryButton
          text="Reset Selected"
          disabled={!userPermissions.isWrite || selectedBilling == null}
          onClick={handleReset}
        />
      </div>
      <div>
        <Text variant='xLarge' className={styles.highlight}>Bonus Billing</Text>
        <SeparatorGy />
        <Checkbox
          label="Bonus Billing"
          checked={isBonus}
          onChange={() => { setIsBonus(!isBonus); }}
        />
        <div className={classNames('ms-Grid', styles.datePickers)} dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm3">
              <TextField
                placeholder="Period (Month)"
                ariaLabel="Select a date"
                value={periodMonth}
                onChange={(e) => { setPeriodMonth(e.currentTarget.value); }}
              />
            </div>
            <div className="ms-Grid-col ms-sm3">
              <DatePicker
                firstDayOfWeek={DayOfWeek.Sunday}
                placeholder="Next Bonus Due"
                ariaLabel="Select a date"
                value={nextBonusDue}
                onSelectDate={(date: Date | null | undefined) => setNextBonusDue(date)}
                strings={defaultDatePickerStrings}
                formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
              />
            </div>
          </div>
        </div>
        <div className={classNames(styles.actionBtns)}>
          <div className="ms-Grid-row">
            <PrimaryButton
              text="Save Term"
              disabled={!userPermissions.isWrite}
              onClick={updateBillingBonus}
            />
          </div>
        </div>
      </div>
      {
        isLoading && <LoadingScreen />
      }
    </div>
  );
};

export default BillingBasis;
