import { FunctionComponent, useState, useEffect, FormEvent } from 'react';
import classNames from 'classnames';
import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import { Text, TextField, Dropdown, DatePicker, PrimaryButton, IDropdownOption, MessageBarType } from '@fluentui/react';
import { InitialGeneralViewData, ISectionData, defaultSectionData, GENERAL_DATA_FIELDS, GeneralViewData } from './consts';
import LoadingScreen from '../../../../LoadingScreen/LoadingScreen';
import { useHistory } from 'react-router-dom';
import apiService from '../../../../../api';
import useNotifications from '../../../../../hooks/useNotifications';
import { get, isEmpty, isNil } from 'lodash';
import { useBoolean } from '@fluentui/react-hooks';
import { DefaultButton, Dialog, DialogFooter, DialogType } from '@fluentui/react';

import styles from './General.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { customerSelector, newCustomerSelector, setCustomer } from '../../../../../redux/customerSlice';
import { INotification } from '../../../../../models/INotification';
import { useField } from '../../../../../hooks/useField';

import { useUserPermissions } from '../../../../../hooks/useUserPermissions';
import { auth_customer_general } from '../../../../../consts/programKeys';
import moment from 'moment';
import { transformDate } from '../../../../../shared/transformDate';

interface GeneralProps {
  actionType: string;
};

const General: FunctionComponent<GeneralProps> = ({ actionType }) => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_customer_general);

  const history = useHistory();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(InitialGeneralViewData);
  const [loading, setLoading] = useState(false);
  const [sectionData, setSectionData] = useState<ISectionData>(defaultSectionData);
  const { addNotification } = useNotifications();
  const customer = useSelector(customerSelector);
  const newCustomer = useSelector(newCustomerSelector);

  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });
  const [isDialogVisible, { toggle: toggleDialog }] = useBoolean(false);
  const [showButtonsInModal, setShowButtonsInModal] = useState<boolean>(false);
  const [primaryButtonTitle, setPrimaryButtonTitle] = useState<any>(null);
  const [modalTitle, setModalTitle] = useState<any>(null);
  const [modalSubText, setModalSubText] = useState<any>(null);
  const [isProspect, setIsProspect] = useState(false);
  const [activateCustomer, setActivateCustomer] = useState(false);
  const isUpdate = actionType !== 'add';

  const [activateButtons, setActivateButtons] = useState<boolean>(false);

  const fetchAddUpdateCustomer = async (generalObject: any) => {
    if (isUpdate) {
      return await apiService.customerAPI.updateCustomer(generalObject);
    }
    else {
      return await apiService.customerAPI.addCustomer(generalObject);
    };
  };

  const onCountryChange = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setFormValue('countryDataField', item?.text);
    refetchCountryInfo(item?.text);
  };
  const onStateChange = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setFormValue('stateDataField', item?.text);
  };
  const onRegionCodeChange = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setFormValue('regionCodeDataField', item?.text);
  };
  const onCurrencyChange = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setFormValue('currencyDataField', item?.text);
  };
  const onUnitOfMeasureChange = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setFormValue('unitOfMeasureDataField', item?.text);
  };
  const onOperationTypeChange = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setFormValue('operationTypeDataField', item?.key);
  };
  const onCommonOwnerChanged = (checked: boolean) => {
    const value = checked ? 'Y' : 'N';
    setFormValue('commonOwnerDataField', value);
  };

  const validateCustNameField = () => {
    return ((!isNil(getFieldFormValue('nameDataField')) && getFieldFormValue('nameDataField').length > 56));
  };

  const validateCustNumField = () => {
    return ((!isNil(getFieldFormValue('customerNumberDataField')) && getFieldFormValue('customerNumberDataField').length !== 9) ||
      (!(/^(|\d)+$/.test(getFieldFormValue('customerNumberDataField'))) && !isNil(getFieldFormValue('customerNumberDataField'))));
  };

  const validatePrefixField = () => {
    return (!isNil(getFieldFormValue('prefixDataField')) && getFieldFormValue('prefixDataField').length > 4);
  };

  const onSubmit = async () => {
    setLoading(true);
    const strIsProspect = (activateCustomer === true || !isProspect) ? 'N' : 'Y';
    const generalObject: GeneralViewData = {
      id: customer?.m_CB_CUSTOMERID,
      customerId: customer?.m_CB_CUSTOMERID,
      brandPrefix: getFieldFormValue('prefixDataField'),
      name: getFieldFormValue('nameDataField'),
      oprType: getFieldFormValue('operationTypeDataField'),
      custNumber: getFieldFormValue('customerNumberDataField'),
      abbrvatdName: getFieldFormValue('abbrvatdNameDataField'),
      businessName: getFieldFormValue('businessNameDataField'),
      customerNumber: getFieldFormValue('customerNumberDataField'),
      corrAddrLine1: getFieldFormValue('corrAddrLine1DataField'),
      corrAddrLine2: getFieldFormValue('corrAddrLine2DataField'),
      corrAddrCountry: getFieldFormValue('countryDataField'),
      fcomOwnr: isNil(getFieldFormValue('commonOwnerDataField')) ? 'N' : getFieldFormValue('commonOwnerDataField'),
      corrAddrState: getFieldFormValue('stateDataField'),
      corrAddrCity: getFieldFormValue('corrAddrCityDataField'),
      corrAddrZip: getFieldFormValue('corrAddrZipDataField'),
      corrAddrPhone: getFieldFormValue('corrAddrPhoneDataField'),
      corrAddrFax: getFieldFormValue('corrAddrFaxDataField'),
      regionCode: getFieldFormValue('regionCodeDataField'),
      unitOfMeasure: getFieldFormValue('unitOfMeasureDataField'),
      currency: getFieldFormValue('currencyDataField'),
      originatedBy: getFieldFormValue('originatedByDataField'),
      lastContactDate: getFieldFormValue('lastContactDateDataField'),
      fprospect: isUpdate ? strIsProspect : 'Y',
    };
    try {
      const { data: newCustomer } = await fetchAddUpdateCustomer(generalObject);
      redirectToUpdate(newCustomer);
      if (isUpdate) {
        if (!activateCustomer) {
          setNotification({ text: 'Customer updated successfully', type: MessageBarType.success });
        }
      }
      else {
        setNotification({ text: 'Customer added successfully', type: MessageBarType.success });
      }
    }
    catch (e: any) {
      setNotification({ text: `Error submitting request: ${get(e, 'response.data.message')}`, type: MessageBarType.error });
    }
    finally {
      setLoading(false);
    }
  };

  const redirectToUpdate = (newCustomer: any) => {
    dispatch(setCustomer(newCustomer));
    history.push(newCustomer.id);
  };

  const onActivate = () => {
    setLoading(true);
    toggleDialog();

    if (isEmpty(getFieldFormValue('customerNumberDataField')) || isEmpty(getFieldFormValue('prefixDataField'))) {
      setModalTitle('Not enough data to activate a customer');
      setPrimaryButtonTitle('OK');
      setShowButtonsInModal(false);

      if (isEmpty(getFieldFormValue('customerNumberDataField')) && !isEmpty(getFieldFormValue('prefixDataField'))) {
        setModalSubText('Customer Number is required!');
      }
      else if (isEmpty(getFieldFormValue('prefixDataField')) && !isEmpty(getFieldFormValue('customerNumberDataField'))) {
        setModalSubText('Prefix is required!');
      }
      else {
        setModalSubText('Customer Number and Prefix is required!');
      }
    }
    else {
      setModalTitle('Activate Prospect');
      setModalSubText('Do you really want to activate customer?');
      setPrimaryButtonTitle('Activate');
      setActivateCustomer(true);
      setShowButtonsInModal(true);
    }
  };

  const onDelete = () => {
    setLoading(true);
    toggleDialog();
    setPrimaryButtonTitle('Delete');
    setModalTitle('Delete Prospect');
    setModalSubText('Do you really want to delete the customer?');
    setShowButtonsInModal(true);
  };

  const onClickDialogPrimaryButton = async () => {
    setLoading(true);
    toggleDialog();

    try {
      if (primaryButtonTitle === 'Activate') {
        await apiService.activateCustomer(customer?.m_CB_CUSTOMERID);
        setNotification({ text: 'Customer was successfully activated.', type: MessageBarType.success });
        onSubmit();
        redirectToUpdate(customer?.m_CB_CUSTOMERID);
        window.location.reload();
      }
      else if (primaryButtonTitle === 'Delete') {
        await apiService.customerAPI.deleteCustomer([customer.id]);
        setNotification({ text: 'Successfully deleted customer', type: MessageBarType.success });
      }
    }
    catch (e: any) {
      if (primaryButtonTitle === 'Activate') {
        setNotification({ text: 'Error activating customer', type: MessageBarType.error });
      }
      else {
        setNotification({ text: 'Error deleting customer', type: MessageBarType.error });
      }
    }
    finally {
      setLoading(false);
    }
  };

  const togglePrimaryDialog = () => {
    setLoading(false);
    toggleDialog();
  };

  const disableSubmitButton = () => {
    return (((customer?.prefix === getFieldFormValue('prefixDataField')) && (customer?.customerName === getFieldFormValue('nameDataField')) && (customer?.operType === getFieldFormValue('operationTypeDataField')) &&
      (customer?.customerNumber === getFieldFormValue('customerNumberDataField')) && (customer?.abbrName === getFieldFormValue('abbrvatdNameDataField')) && (customer?.businessName === getFieldFormValue('businessNameDataField')) &&
      (customer?.fComOwnr === getFieldFormValue('commonOwnerDataField')) && (customer?.corrAddressLine1 === getFieldFormValue('corrAddrLine1DataField')) && (customer?.unitOfMeasure === getFieldFormValue('unitOfMeasureDataField')) &&
      (customer?.currency === getFieldFormValue('currencyDataField')) && (customer?.corrAddressLine2 === getFieldFormValue('corrAddrLine2DataField')) && (customer?.originatedBy === getFieldFormValue('originatedByDataField')) &&
      (customer?.lastContactDate === getFieldFormValue('lastContactDateDataField')) && (customer?.corrAddressCountry === getFieldFormValue('countryDataField')) && (customer?.corrAddressState === getFieldFormValue('stateDataField')) &&
      (customer?.regionCode === getFieldFormValue('regionCodeDataField')) && (customer?.corrAddressCity === getFieldFormValue('corrAddrCityDataField')) && (customer?.corrAddressZip === getFieldFormValue('corrAddrZipDataField')) &&
      (customer?.corrAddressPhone === getFieldFormValue('corrAddrPhoneDataField')) && (customer?.corrAddressFax === getFieldFormValue('corrAddrFaxDataField')))
      || (isNil(getFieldFormValue('nameDataField')) || isNil(getFieldFormValue('operationTypeDataField')) || isNil(getFieldFormValue('corrAddrLine1DataField')) || isNil(getFieldFormValue('unitOfMeasureDataField')) ||
        isNil(getFieldFormValue('currencyDataField')) || isNil(getFieldFormValue('countryDataField')) || isNil(getFieldFormValue('stateDataField')) || isNil(getFieldFormValue('regionCodeDataField')) ||
        isNil(getFieldFormValue('corrAddrCityDataField')) || isNil(getFieldFormValue('corrAddrZipDataField')))
      || validateCustNumField() || validatePrefixField() || validateCustNameField()) || loading;
  };


  const refetchCountryInfo = async (country: string | undefined) => {
    setLoading(true);
    const states = await getStates(country);
    const regionCodes = await getRegionCodes(country);
    setSectionData({ ...sectionData, states: states, regionCodes: regionCodes });
    setFormValue('stateDataField', states[0]);
    setFormValue('regionCodeDataField', regionCodes[0]);
    setLoading(false);
  };

  const getCountries = async () => {
    try {
      const { data: { countries } } = await apiService.customerAPI.getCountries();
      return countries;
    }
    catch (e: any) {
      setNotification({ text: 'Error fetching countries', type: MessageBarType.error });
    }
  };

  const getStates = async (country: string | undefined) => {
    try {
      const { data: { states } } = await apiService.customerAPI.getStates(country);
      return states;
    }
    catch (e: any) {
      setNotification({ text: 'Error fetching states', type: MessageBarType.error });
    }
  };

  const getRegionCodes = async (country: string | undefined) => {
    try {
      const { data: { regionCodes } } = await apiService.customerAPI.getRegionCodes(country);
      return regionCodes;
    } catch (e: any) {
      setNotification({ text: 'Error fetching region codes', type: MessageBarType.error });
    }
  };

  const getCurrencies = async () => {
    try {
      const { data: { currencies } } = await apiService.customerAPI.getCurrencies();
      return currencies;
    } catch (e: any) {
      setNotification({ text: 'Error fetching currencies', type: MessageBarType.error });
    }
  };

  const getUnitsOfMeasure = async () => {
    try {
      const { data: { unitOfMeasures } } = await apiService.customerAPI.getUnitsOfMeasure();
      return unitOfMeasures;
    } catch (e: any) {
      setNotification({ text: 'Error fetching units of measure', type: MessageBarType.error });
    }
  };

  const getOperationTypes = async () => {
    try {
      const { data } = await apiService.customerAPI.getOperationTypes();
      return data;
    } catch (e: any) {
      setNotification({ text: 'Error fetching units of measure', type: MessageBarType.error });
    }
  };

  const setInitialData = async () => {

    setLoading(true);

    if (isUpdate) {
      await setCustomerData();
    } else {
      await setNewCustomerData();
    }

    const countries = await getCountries();
    const states = await getStates(isNil(customer?.corrAddressCountry) || isEmpty(customer?.corrAddressCountry)  ? formData?.corrAddrCountry : customer?.corrAddressCountry);
    const regionCodes = await getRegionCodes(isNil(customer?.corrAddressCountry) || isEmpty(customer?.corrAddressCountry)  ? formData?.corrAddrCountry : customer?.corrAddressCountry);
    const currencies = await getCurrencies();
    const unitOfMeasures = await getUnitsOfMeasure();
    const operationTypes = await getOperationTypes();

    await setSectionData({
      ...sectionData,
      countries: countries,
      states: states,
      regionCodes: regionCodes,
      currencies: currencies,
      unitsOfMeasures: unitOfMeasures,
      operationTypes: operationTypes.operationTypes.map((item: any, index: number) => ({
        operationTypes: item,
        operationTypeNames: operationTypes.operationTypeNames[index],
      })),
    });
    setLoading(false);
  };

  const setCustomerData = () => {
    const userData = customer;
    setFormValue('prefixDataField', userData?.prefix);
    setFormValue('nameDataField', userData?.customerName);
    setFormValue('customerNumberDataField', userData?.customerNumber);
    setFormValue('abbrvatdNameDataField', userData?.abbrName);
    setFormValue('businessNameDataField', userData?.businessName);
    setFormValue('corrAddrLine1DataField', userData?.corrAddressLine1);
    setFormValue('corrAddrLine2DataField', userData?.corrAddressLine2);
    setFormValue('corrAddrCityDataField', userData?.corrAddressCity);
    setFormValue('corrAddrZipDataField', userData?.corrAddressZip);
    setFormValue('corrAddrPhoneDataField', userData?.corrAddressPhone);
    setFormValue('corrAddrFaxDataField', userData?.corrAddressFax);
    setFormValue('originatedByDataField', userData?.originatedBy);
    setFormValue('operationTypeDataField', userData?.operType);
    setFormValue('countryDataField', userData?.corrAddressCountry);
    setFormValue('stateDataField', userData?.corrAddressState);
    setFormValue('regionCodeDataField', userData?.regionCode);
    setFormValue('unitOfMeasureDataField', userData?.unitOfMeasure);
    setFormValue('currencyDataField', userData?.currency);
    setFormValue('lastContactDateDataField', userData?.lastContactDate);
    setFormValue('commonOwnerDataField', userData?.fComOwnr);
    setIsProspect(userData?.fProspect === 'Y' ? true : false);

  };

  const setNewCustomerData = () => {
    const userData = newCustomer;
    setFormValue('prefixDataField', userData?.prefix);
    setFormValue('nameDataField', userData?.customerName);
    setFormValue('customerNumberDataField', userData?.customerNumber);
    setFormValue('abbrvatdNameDataField', userData?.abbrName);
    setFormValue('businessNameDataField', userData?.businessName);
    setFormValue('corrAddrCityDataField', userData?.billToCity);
    setFormValue('corrAddrZipDataField', userData?.billToZip);
    setFormValue('countryDataField', userData?.billToCountry);
    setFormValue('stateDataField', userData?.billToState);
    setFormValue('regionCodeDataField', userData?.regionCode);
  };

  const formFieldsGroup: any = {
    prefixDataField: useField(GENERAL_DATA_FIELDS.prefixDataField, undefined),
    nameDataField: useField(GENERAL_DATA_FIELDS.nameDataField, undefined),
    customerNumberDataField: useField(GENERAL_DATA_FIELDS.customerNumberDataField, undefined),
    abbrvatdNameDataField: useField(GENERAL_DATA_FIELDS.abbrvatdNameDataField, undefined),
    businessNameDataField: useField(GENERAL_DATA_FIELDS.businessNameDataField, undefined),
    corrAddrLine1DataField: useField(GENERAL_DATA_FIELDS.corrAddrLine1DataField, undefined),
    corrAddrLine2DataField: useField(GENERAL_DATA_FIELDS.corrAddrLine2DataField, undefined),
    corrAddrCityDataField: useField(GENERAL_DATA_FIELDS.corrAddrCityDataField, undefined),
    corrAddrZipDataField: useField(GENERAL_DATA_FIELDS.corrAddrZipDataField, undefined),
    corrAddrPhoneDataField: useField(GENERAL_DATA_FIELDS.corrAddrPhoneDataField, undefined),
    corrAddrFaxDataField: useField(GENERAL_DATA_FIELDS.corrAddrFaxDataField, undefined),
    originatedByDataField: useField(GENERAL_DATA_FIELDS.originatedByDataField, undefined),
    operationTypeDataField: useField(GENERAL_DATA_FIELDS.operationTypeDataField),
    countryDataField: useField(GENERAL_DATA_FIELDS.countryDataField),
    stateDataField: useField(GENERAL_DATA_FIELDS.stateDataField),
    regionCodeDataField: useField(GENERAL_DATA_FIELDS.regionCodeDataField),
    unitOfMeasureDataField: useField(GENERAL_DATA_FIELDS.unitOfMeasureDataField),
    currencyDataField: useField(GENERAL_DATA_FIELDS.currencyDataField),
    lastContactDateDataField: useField(GENERAL_DATA_FIELDS.lastContactDateDataField, undefined),
    commonOwnerDataField: useField(GENERAL_DATA_FIELDS.commonOwnerDataField),
  };

  const getFieldForm: any = (field: string) => formFieldsGroup[field] || {};
  const getFieldFormValue = (field: string) => formFieldsGroup[field].getValue() || null;
  const getFieldFormClassName = (field: string) => formFieldsGroup[field]?.getRequiredClassName() || '';
  const setFormValue = (fieldForm: string, value: any) => formFieldsGroup[fieldForm]?.setValue(value);

  useEffect(() => {
    setInitialData();
  }, [customer]);

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  useEffect(() => {
    if (isProspect && isUpdate)
      setActivateButtons(true);
  }, [isProspect]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={'ms-Grid'} dir='ltr'>
          <div className={classNames('ms-Grid-row', styles.upperRow)}>
            <div className='ms-Grid-col ms-sm8'>
              <div className='ms-Grid-row'>
                <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                  <TextField {...getFieldForm('prefixDataField')} errorMessage={
                    validatePrefixField() ? 'Prefix should be atleast 4 alpha-numeric characters' : ''} />
                </div>
                <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                  <TextField {...getFieldForm('nameDataField')} errorMessage={
                    validateCustNameField() ? 'Customer name should not be more than 56 alpha-numeric characters' : ''} />
                </div>
                <div className="ms-Grid-col ms-sm4">
                  <Dropdown
                    {...getFieldForm('operationTypeDataField')}
                    options={sectionData?.operationTypes.map(ot => ({
                      key: ot.operationTypes,
                      text: ot.operationTypeNames,
                    }))}
                    selectedKey={getFieldFormValue('operationTypeDataField')}
                    onChange={onOperationTypeChange}
                  />
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                  <TextField {...getFieldForm('customerNumberDataField')} errorMessage={
                    validateCustNumField() ? 'Customer number must be 9 numeric characters' : ''} />
                </div>
                <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                  <TextField {...getFieldForm('abbrvatdNameDataField')} />
                </div>
                <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                  <TextField {...getFieldForm('businessNameDataField')} />
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  <div className={styles.commonOwnerInput}>
                    <label>
                      <input type="checkbox" checked={getFieldFormValue('commonOwnerDataField') === 'Y' ? true : false} onChange={(evt) => { onCommonOwnerChanged(evt.target.checked); }} />
                      Common Owner
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='ms-Grid-col ms-sm4'></div>
          </div>

          <div className={classNames('ms-Grid-row')}>
            <div className="ms-Grid-col ms-sm12">
              <Text variant='xLarge' className={styles.highlight}>Address for correspondence</Text>
              <SeparatorGy />
            </div>
          </div>

          <div className={classNames('ms-Grid-row')}>
            <div className="ms-Grid-col ms-sm9">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm8">
                  <div className="ms-Grid-row">
                    <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                      <TextField {...getFieldForm('corrAddrLine1DataField')} />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                      <TextField {...getFieldForm('corrAddrLine2DataField')} />
                    </div>
                    <div className="ms-Grid-col ms-sm4">
                      <Dropdown
                        {...getFieldForm('countryDataField')}
                        options={sectionData?.countries?.map(country => ({
                          key: country,
                          text: country,
                        }))}
                        selectedKey={getFieldFormValue('countryDataField')}
                        onChange={onCountryChange}
                      />
                    </div>
                    <div className="ms-Grid-col ms-sm4">
                      <Dropdown
                        {...getFieldForm('stateDataField')}
                        options={sectionData?.states?.map(state => ({
                          key: state,
                          text: state,
                        }))}
                        selectedKey={getFieldFormValue('stateDataField')}
                        onChange={onStateChange}
                      />
                    </div>
                    <div className="ms-Grid-col ms-sm4">
                      <Dropdown
                        {...getFieldForm('regionCodeDataField')}
                        options={sectionData?.regionCodes.map(regionCode => ({
                          key: regionCode,
                          text: regionCode.toString(),
                        }))}
                        selectedKey={getFieldFormValue('regionCodeDataField')}
                        onChange={onRegionCodeChange}
                      />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                      <TextField {...getFieldForm('corrAddrCityDataField')} />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                      <TextField {...getFieldForm('corrAddrZipDataField')} />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                      <TextField {...getFieldForm('corrAddrPhoneDataField')} />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                      <TextField {...getFieldForm('corrAddrFaxDataField')} />
                    </div>
                  </div>
                </div>

                <div className="ms-Grid-col ms-sm4">
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6">
                      <Dropdown
                        {...getFieldForm('unitOfMeasureDataField')}
                        options={sectionData?.unitsOfMeasures.map(uom => ({
                          key: uom,
                          text: uom.toString(),
                        }))}
                        selectedKey={getFieldFormValue('unitOfMeasureDataField')}
                        onChange={onUnitOfMeasureChange}
                      />
                    </div>
                    <div className="ms-Grid-col ms-sm6">
                      <Dropdown
                        {...getFieldForm('currencyDataField')}
                        options={sectionData?.currencies.map(currency => ({
                          key: currency,
                          text: currency.toString(),
                        }))}
                        selectedKey={getFieldFormValue('currencyDataField')}
                        onChange={onCurrencyChange}
                      />
                    </div>
                  </div>
                  <div className="ms-Grid-row">
                    <div className={classNames('ms-Grid-col', 'ms-sm6', getFieldFormClassName('originatedByDataField'))}>
                      <TextField {...getFieldForm('originatedByDataField')} />
                    </div>
                    <div className="ms-Grid-col ms-sm6">
                      <DatePicker
                        {...getFieldForm('lastContactDataField')}
                        showMonthPickerAsOverlay={true}
                        placeholder="Select a date..."
                        ariaLabel="Select a date"
                        label='Last Contact Date'
                        onSelectDate={(addedDateFrom: Date | null | undefined) => setFormValue('lastContactDateDataField', transformDate(moment(addedDateFrom).format('MM/DD/YYYY')))}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-Grid-col ms-sm3">
              <div className="ms-Grid-row"></div>
            </div>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.colSubmit)}>
            {activateButtons && <PrimaryButton className={styles.btnContainer} text='Save Prospect' onClick={onSubmit} disabled={disableSubmitButton() || !userPermissions.isWrite} />}
            {activateButtons && <PrimaryButton className={styles.btnContainer} text='Activate' onClick={onActivate} disabled={!userPermissions.isWrite} />}
            {activateButtons && <PrimaryButton className={styles.btnContainer} text='Delete' onClick={onDelete} disabled={!userPermissions.isWrite} />}
            {!activateButtons && <PrimaryButton className={styles.btnContainer} text='Submit' onClick={onSubmit} disabled={disableSubmitButton() || !userPermissions.isWrite} />}
          </div>
        </div>

        <div className="dialogBoxTitle">
          <Dialog
            hidden={!isDialogVisible}
            onDismiss={togglePrimaryDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: modalTitle,
              subText: modalSubText,
            }}
            modalProps={{ isBlocking: true }}
            maxWidth={500}
          >
            <DialogFooter>
              <PrimaryButton onClick={onClickDialogPrimaryButton} text={primaryButtonTitle} />
              {showButtonsInModal && <DefaultButton onClick={togglePrimaryDialog} text="Close" />}
            </DialogFooter>
          </Dialog>
        </div>
      </div>
      {loading && <LoadingScreen />}
    </>
  );
};

export default General;
