import { recordTestInstance, exportTestInstance } from '../axios';

const getErrors = (pagination: any, sortOrder: any, customerId: string, locationId: string) =>
  recordTestInstance.post('/tire-change/openwheel/error', { pagination, sortOrder, customerId, locationId });

const get = (pagination: any, sortOrder: any, customerId: string, locationId: string) =>
  recordTestInstance.post('/tire-change/openwheel', { pagination, sortOrder, customerId, locationId });

const printExcel = (tireChangeInput: any, headerFields: Array<any>) =>
  exportTestInstance.post('/tire-change/export-excel', { tireChangeInput, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (tireChangeInput: any, headerFields: Array<any>) =>
  exportTestInstance.post('/tire-change/export-pdf', { tireChangeInput, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  getErrors,
  get,
  printExcel,
  printPdf,
};
