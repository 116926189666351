import AutocompleteInput from '../../../shared/AutocompleteInput';
import {
  ITextFieldProps,
} from '@fluentui/react';
import { FC, FocusEventHandler } from 'react';

interface AutoCompleteFieldProps {
  onChangeAction?: any;
  value?: string;
  initialValue?: string;
  list: any[];
  icon?: string;
  label: string;
  onRenderPrefix?: (props: ITextFieldProps | undefined) => JSX.Element;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  disabled?: boolean | false;
  textValue?: any
  className?: string
  isLoading?: boolean
  required?: boolean
  upperCase?: boolean
}

const AutoCompleteField: FC<AutoCompleteFieldProps> = ({
  onChangeAction,
  value,
  list,
  icon,
  label,
  onRenderPrefix,
  disabled,
  textValue,
  className,
  isLoading = false,
  initialValue,
  required = false,
  onBlur,
  ...rest
}) => {
  return (
    <div className={className}>
      <div className="ms-TextField">
        <AutocompleteInput
          label={label}
          chooseCurrentItem={onChangeAction}
          value={value}
          initialValue={initialValue}
          list={list}
          prefix=""
          prefixIcon={icon}
          emptyExpanded
          onRenderPrefix={onRenderPrefix}
          disabled={disabled}
          textValue={textValue}
          isLoading={isLoading}
          required={required}
          onBlur={onBlur}
          {...rest}
        />
      </div>
    </div>
  );
};


export default AutoCompleteField;
