import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICustomer } from '../components/Common/Search/ICustomer';

interface ICustomerFilter {
  custPrefix?: string,
  custNumber?: string,
  custName?: string,
  country?: string,
  city?: string,
  state?: string,
  zipcode?: string,
  bName?: string,
  abbrname?: string,
  regionCode?: string,
  status?: string,
}
interface ICustomerState {
  customer: ICustomer | null;
  newCustomer: ICustomer | null;
  filter: ICustomerFilter;
  shouldFilterRun: boolean;
}

const initialState: ICustomerState = {
  customer: null,
  newCustomer: null,
  filter: {},
  shouldFilterRun: false,
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomer: (state, action: PayloadAction<ICustomer>) => {
      state.customer = action.payload;
    },
    setFilter: (state, action: PayloadAction<ICustomerFilter>) => {
      state.filter = action.payload;
    },
    setShouldFilterRun: (state, action: PayloadAction<boolean>) => {
      state.shouldFilterRun = action.payload;
    },
    updateContacts: (state, action: PayloadAction<any>) => {
      state.customer = {
        ...state.customer,
        contacts: action.payload,
      };
    },
    removeCustomer: (state) => {
      state.customer = initialState.customer;
    },
    setNewCustomer: (state, action: PayloadAction<ICustomer>) => {
      state.newCustomer = action.payload;
    },
    removeNewCustomer: (state) => {
      state.newCustomer = initialState.customer;
    },
  },
});

export const {
  setCustomer,
  removeCustomer,
  setNewCustomer,
  removeNewCustomer,
  updateContacts,
  setFilter,
  setShouldFilterRun,
} = customerSlice.actions;

export const customerSelector = ({ customer }: any) => customer.customer;
export const customerFilterSelector = ({ customer }: any) => customer.filter;
export const customerShouldFilterSelector = ({ customer }: any) => customer.shouldFilterRun;
export const newCustomerSelector = ({ customer }: any) => customer.newCustomer;

export default customerSlice.reducer;