import { DefaultButton, Dialog, DialogFooter, DialogType, IconButton, Modal, PrimaryButton, Text } from '@fluentui/react';
import classNames from 'classnames';
import { FunctionComponent } from 'react';
import DataGridComponent from '../DataGridComponent';
import styles from './DeleteModal.module.scss';
interface DeleteModalComponentProps {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  title: string;
  subText: string;
  onSubmitLabel?: string;
  onCancelLabel?: string;
  headcells?: Array<any>;
  rowsToDelete?: Array<any>
}

const DeleteModalComponent: FunctionComponent<DeleteModalComponentProps> = ({
  isOpen,
  onCancel,
  onSubmit,
  title,
  subText,
  onSubmitLabel = 'Submit',
  onCancelLabel = 'Cancel',
  headcells = [],
  rowsToDelete = [],
}) => {
  return (
    <>
      <Modal
        key={'123'}
        isOpen={isOpen}
        onDismiss={onCancel}
        containerClassName={styles.modalContainer}
      >
        <div className={styles.modalBody}>
          <div className='modalHeader'>
            <Text variant='xLarge'>{title}</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel='Close popup modal'
              onClick={onCancel}
            />
          </div>
          <div className={classNames(styles.subTextContainer)}>
            <Text variant='large'>{subText}</Text>
          </div>
          <div className='tableContainer'>
            {headcells.length > 0 &&
              <DataGridComponent
                idTable={'vehicle-table'}
                title=''
                headCells={headcells}
                rowsTable={rowsToDelete}
                enableShowFound={false}
                totalDataFound={rowsToDelete.length}
                enableAutoControls
              />}
          </div>
          <div className={styles.modalFooter}>
            <PrimaryButton id='submitDialogBtn' onClick={onSubmit} text={onSubmitLabel} />
            <DefaultButton id='cancelDialogBtn' onClick={onCancel} text={onCancelLabel} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteModalComponent;