import { exportTestInstance, recordTestInstance } from '../axios';

const getMonthlyMiles = (filters: any, pagination: any, sortOrder: any, locationId: number, customerId: number) =>
  recordTestInstance.post('/post-monthly-miles', {filters, pagination, sortOrder, locationId, customerId});

const getPostMonthlyMilesByVehicle = (billingPeriodId: string, locationId: string, customerId: string) =>
  recordTestInstance.post('/post-monthly-miles/by-vehicle', { billingPeriodId, locationId, customerId });

const save = (data: Array<any>, customerId: string, locationId: string) =>
  recordTestInstance.post('/post-monthly-miles/save', { data, customerId, locationId });
  
const submit = (ids: Array<any>, billingPeriodId: string, customerId: string, locationId: string) =>
  recordTestInstance.post('/post-monthly-miles/start', { ids, billingPeriodId, customerId, locationId });

const deleteMonthlyMiles = (ids: Array<any>, customerId: string, locationId: string ) =>
  recordTestInstance.delete('/post-monthly-miles', { data: { ids, customerId, locationId } });

const getPostMonthlyMilesTotal = (billingPeriodId: string, locationId: string, customerId: string) =>
  recordTestInstance.post('/post-monthly-miles/total', { billingPeriodId, locationId, customerId });

const getErrors = (pagination: any, sortOrder: any, customerId: number, locationId: number) =>
  recordTestInstance.post('/post-monthly-miles/error', {pagination, sortOrder, customerId, locationId});

const printExcel = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/post-monthly-miles/export-excel', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/post-monthly-miles/export-pdf', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  getMonthlyMiles,
  getPostMonthlyMilesByVehicle,
  save,
  submit,
  delete: deleteMonthlyMiles,
  getPostMonthlyMilesTotal,
  getErrors,
  printExcel,
  printPdf,
};