

import { DatePicker, DefaultButton, Dropdown, IconButton, MessageBarType, PrimaryButton, Spinner, Text, TextField, Toggle } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { debounce, get, isNil } from 'lodash';
import moment from 'moment';
import { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import apiService from '../../../../api';
import { auth_billing_createInvoices } from '../../../../consts/programKeys';
import { sortOrder } from '../../../../consts/sortOrder';
import useNotifications from '../../../../hooks/useNotifications';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import DataGridComponent from '../../../../shared/DataGridComponent';
import DialogComponent from '../../../../shared/DialogComponent';
import { FormBuilderGroup } from '../../../../shared/FormBuilderComponent';
import InvoiceModalComponent from '../../../../shared/InvoicesModalComponent';
import { LINEITEMS_FIELDS } from '../../../../shared/ViewMaintainInvoicesModalComponent/consts';
import AutoCompleteField from '../../../Common/Search/AutoCompleteField';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import { downloadFile, printingTypes } from '../../../PrintingModal/consts';
import SelectingModal from '../../../SelectingModal/SelectingModal';
import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import { availableLineItemsHeadCells, defaultInvoiceDetails, defaultLineItem, invoiceTypes, locationsColumns } from './consts';
import styles from './CreateInvoices.module.scss';
import { ICreateInvoicesProps } from './ICreateInvoicesProps';
import { ICreateInvoicesState, ILineItem } from './ICreateInvoicesState';
import PrintingModalInvoice from './PrintingModalInvoice/PrintingModalInvoice';

const CreateInvoices: FC<ICreateInvoicesProps> = (): ReactElement => {

  const { addNotification } = useNotifications();

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_billing_createInvoices);

  const [isLoadingFetchCustomers, { toggle: toggleIsLoadingFetchCustomers }] = useBoolean(false);
  const [customerFilters, setCustomerFilters] = useState<any>(null);
  const [customers, setCustomers] = useState<Array<any>>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [creditInvoiceSelected, setCreditInvoiceSelected] = useState<any>(null);

  const [isLoadingFetchLocations, { toggle: toggleIsLoadingFetchLocations }] = useBoolean(false);
  const [locations, setLocations] = useState<Array<any>>([]);
  const [selectedLocation, setSelectedLocation] = useState<any>({});
  const [showLocationsModal, { toggle: toggleShowLocationsModal }] = useBoolean(false);

  const [billingTypes, setBillingTypes] = useState<Array<string>>([]);

  const [selectedLineItem, setSelectedLineItem] = useState<ILineItem | null>(null);
  const [errorMessages, setErrorMessages] = useState<ILineItem>(defaultLineItem);
  const [invoiceItemsSortOrder, setInvoiceItemsSortOrder] = useState({});
  const [showDeletingInvoiceConfirmation, { toggle: toggleDeletingInvoiceConfirmation }] = useBoolean(false);
  const [showSavingInvoiceConfirmation, { toggle: toggleSavingInvoiceConfirmation }] = useBoolean(false);
  const [showFindInvoices, { toggle: toggleShowFindInvoices }] = useBoolean(false);
  const [showLoadingLineItems, { toggle: toggleShowLoadingLineItems }] = useBoolean(false);
  const [billingCodeSelected, setBillingCodeSelected] = useState<any>();
  const [typeAllowed, setTypeAllowed] = useState(false);
  const [requiredUnitPrice, setRequiredUnitPrice] = useState(false);
  const [showCopyInvoiceConfirmation, { toggle: toggleCopyInvoiceConfirmation }] = useBoolean(false);
  const [isAutoCalculate, { toggle: toggleAutoCalculate }] = useBoolean(true);

  const [state, setState] = useState<ICreateInvoicesState>({
    loading: false,
    contractDetails: null,
    invoiceDetails: defaultInvoiceDetails,
    creditAgainst: null,
    lineItems: [],
    foundLineItems: 0,
    lineItemsLoading: false,
    lineItemsTotalAmount: '',
  });

  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);

  const lineItemsFormFieldsGroup = new FormBuilderGroup(LINEITEMS_FIELDS);

  const getBillingCodesList = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data: response } = await apiService.billingAPI.getBillingCodesList(null, null, { filterText: state.invoiceDetails?.type }, selectedCustomer?.id, selectedLocation?.id);
      const bCodeSelected = response?.data?.find((billingCode: any) => {
        return billingCode?.billingCodeId.length === 32 && state.invoiceDetails?.type === billingCode.billingType;
      });
      setBillingCodeSelected(bCodeSelected);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Billing codes data fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const updateBillingCodes = async () => {
    try {
      await apiService.billingAPI.updateBillingCodesList({
        ...billingCodeSelected,
        msgText1: state.invoiceDetails?.additionalDetails1,
        msgText2: state.invoiceDetails?.additionalDetails2,
      });
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Billing codes data updating error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const fetchApplicableInvoiceTypes = async () => {
    try {
      if (!state.contractDetails?.contractId) return;
      const { data }: any = await apiService.billingAPI.getApplicableInvoiceTypes(state.contractDetails?.contractId);
      setBillingTypes(data);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Invoice types fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    };
  };

  const fetchAditionalDetails = async () => {
    try {
      if (!state?.invoiceDetails) return;
      const { data: { addlLine1, addlLine2, addlLine3 } } = await apiService.billingAPI.getAditionalDetails(
        selectedCustomer.id,
        selectedLocation.id,
        state?.invoiceDetails?.type || '',
        state?.invoiceDetails?.debitOrCredit || '',
      );
      setState({
        ...state,
        invoiceDetails: {
          ...state.invoiceDetails,
          additionalDetails1: addlLine1,
          additionalDetails2: addlLine2,
          additionalDetails3: addlLine3,
        },
      });

    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetch additional details error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const fetchDefaultInvoiceValues = async (
    invoiceDetails?: string,
    radialOrBias: string = lineItemsFormFieldsGroup.getFieldFormValue('radialOrBiasField'),
    qty: string = lineItemsFormFieldsGroup.getFieldFormValue('qtyField'),
    unitPrice: string = lineItemsFormFieldsGroup.getFieldFormValue('unitPriceField'),
  ) => {
    try {
      const { data: { mbCode, description, lineTotal, unitPrice: unitPrinceResponse } }: any = await apiService.billingAPI.getDefaultInvoiceValues(
        invoiceDetails || state.invoiceDetails?.invoiceId || '',
        radialOrBias,
        +qty,
        +unitPrice,
      );

      lineItemsFormFieldsGroup.setFormValue('mbCodeField', shouldFillMbCode(state.invoiceDetails?.type) ? mbCode : '');
      lineItemsFormFieldsGroup.setFormValue('descriptionField', description);
      lineItemsFormFieldsGroup.setFormValue('unitPriceField', unitPrinceResponse ?? '0');
      lineItemsFormFieldsGroup.setFormValue('totalField', `${lineTotal}`);
      lineItemsFormFieldsGroup.setFormValue('costField', '0');

      setTypeAllowed(true);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetch defaults error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const fetchTotalAndDescription = async (qty: any, unitPrice: any) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data: { lineTotal } } = await apiService.billingAPI.getTotalAndDescription(
        state.invoiceDetails?.invoiceId || '',
        qty,
        unitPrice,
      );

      lineItemsFormFieldsGroup.setFormValue(
        'totalField',
        `${lineTotal}` || lineItemsFormFieldsGroup.getFieldFormValue('totalField'),
      );

    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetch total error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchMBCode = async () => {
    try {
      const { data: { mbCode } } = await apiService.billingAPI.getMBCode(
        state.invoiceDetails?.invoiceId || '',
        lineItemsFormFieldsGroup.getFieldFormValue('radialOrBiasField'),
      );
      lineItemsFormFieldsGroup.setFormValue('mbCodeField', shouldFillMbCode(state.invoiceDetails?.type) ? mbCode : '');
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetch MBCode error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const fetchLocations = async () => {
    toggleIsLoadingFetchLocations();
    try {
      const { data }: any = await apiService.getLocationsByCustomerId(
        selectedCustomer.id,
        { pageSize: 5000, pageNumber: 1 },
        {},
      );
      setLocations(data.data);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Locations fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      toggleIsLoadingFetchLocations();
    }
  };

  const fetchContracts = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data: { data } }: any = await apiService.billingAPI.getContractList(
        { pageSize: 1, pageNumber: 1 },
        { column: 'effectiveStart', order: sortOrder.DESC },
        { status: null },
        selectedCustomer.id,
        selectedCustomer.regionCode,
      );
      if (data.length) {
        setState(prev => ({ ...prev, contractDetails: data[0] }));
        fetchContractDetails();
      }
      else
        setState(prev => ({ ...prev, contractDetails: null }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Contracts fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchContractDetails = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.billingAPI.getMaintainInvoiceContractDetails(undefined, selectedCustomer.id);
      setState(prev => ({ ...prev, contractDetails: { ...data } }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Contract Details fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchInvoiceDetails = async (invoiceId: any) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.billingAPI.getInvoiceDetails(invoiceId);
      setState((prev: any) => ({ ...prev, invoiceDetails: { ...prev.invoiceDetails, ...data } }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Invoice Details fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchCreditAgainst = async (invoiceId: any) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.billingAPI.getCreditAgainst(invoiceId);
      setState(prev => ({ ...prev, creditAgainst: data }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Credit Against fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchLineItems = async (
    sortOrder: any = { column: 'createon', order: 'asc' }, //the default sort should be based on the last created date
    invoiceId?: any,
    filterText = null,
  ) => {
    setInvoiceItemsSortOrder(sortOrder);
    setState(prev => ({ ...prev, lineItemsLoading: true }));
    try {
      const { data: { data } }: any = await apiService.billingAPI.getInvoiceItems(
        invoiceId,
        null,
        sortOrder,
        filterText,
      );
      setState(prev => ({ ...prev, lineItems: data.data, foundLineItems: data.total.found, lineItemsTotalAmount: data.totalAmount }));
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          setState(prev => ({ ...prev, lineItems: [], foundLineItems: 0, lineItemsTotalAmount: '' }));
          return addNotification({
            text: response.data.desc.message,
            type: MessageBarType.error,
          });

        default:
          addNotification({
            text: `Line Items fetching error: ${response?.data?.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState(prev => ({ ...prev, lineItemsLoading: false }));
    }
  };

  const onCustomerTextChange = debounce(async (field: string, value: string, sortOrder?: any) => {
    if (value && get(customerFilters, `${field}`) !== value) {
      fetchCustomers({ [field]: value }, sortOrder);
    }
    setCustomerFilters(value ? { [field]: value } : null);
  }, 1000);

  const onCustomerChange = async (newCustomerId: any) => {
    setSelectedCustomer(getListItemById(newCustomerId, customers));
    setSelectedLocation({});
  };

  const onLocationChange = (newLocationId: any) => setSelectedLocation(getListItemById(newLocationId, locations));

  const getListItemById = (idToFind: number, list: any[]) => {
    const itemToFind = list?.find(({ id }) => id === idToFind);
    return itemToFind ? itemToFind : null;
  };

  const removeEmpty = ({ text }: any) => text !== null;

  const customerPrefixList = useMemo(() => customers ?
    customers.map(({ id, prefix }) => ({
      key: id,
      text: prefix,
    })).filter(removeEmpty) : [], [customers]);


  const customerNameList = useMemo(() => customers ?
    customers.map(({ id, customerName }) => ({
      key: id,
      text: customerName,
    })).filter(removeEmpty) : [], [customers]);

  const locationCodeList = useMemo(() => locations ?
    locations.map(({ id, locationCode }) => ({
      key: id,
      text: locationCode,
    })).filter(removeEmpty) : [], [locations]);

  const locationNameList = useMemo(() => locations ?
    locations.map(({ id, locationName }) => ({
      key: id,
      text: locationName,
    })).filter(removeEmpty) : [], [locations]);


  const onLocationModalSubmit = (newLocationId: any) => {
    onLocationChange(newLocationId);
    toggleShowLocationsModal();
  };

  const onInvoiceTypeChange = (_ev: any, option: any) => {
    setState((prev: any) => ({ ...prev, invoiceDetails: { ...prev.invoiceDetails, type: option?.key } }));
  };

  const onDebitOrCreditChange = (_ev: any, option: any) =>
    setState((prev: any) => ({ ...prev, invoiceDetails: { ...prev.invoiceDetails, debitOrCredit: option?.key } }));

  const handleChangeItemsDataGridState = async (dataGridState: any) => {
    if (state.invoiceDetails?.invoiceId) {
      const { sortOrder, searchedText } = dataGridState;
      fetchLineItems(sortOrder, state.invoiceDetails.invoiceId, searchedText);
    }
  };

  const handleSelectLineItem = (rowsSelected: any[]) => {
    if (rowsSelected.length === 1) {
      setSelectedLineItem(rowsSelected[0]);
    }
    else {
      if (selectedLineItem)
        handleClearLineItems();
      setSelectedLineItem(null);
    }
  };

  const setLineItemInfo = (lineItem: any) => {
    const {
      qty,
      wheelPosition,
      radialOrBias,
      mbCode,
      unitPrice,
      total,
      cost,
      description,
    } = lineItem;
    lineItemsFormFieldsGroup.setFormValue('qtyField', qty);
    lineItemsFormFieldsGroup.setFormValue('wheelPositionField', `${wheelPosition}`);
    lineItemsFormFieldsGroup.setFormValue('radialOrBiasField', radialOrBias);
    lineItemsFormFieldsGroup.setFormValue('mbCodeField', mbCode);
    lineItemsFormFieldsGroup.setFormValue('unitPriceField', `${unitPrice}`);
    lineItemsFormFieldsGroup.setFormValue('totalField', `${total}`);
    lineItemsFormFieldsGroup.setFormValue('costField', `${cost}`);
    lineItemsFormFieldsGroup.setFormValue('descriptionField', description);
  };

  const parseFieldsErrors = (errors: any[]) => {
    let parsedFieldsErrors = {};
    errors.forEach(el => {
      parsedFieldsErrors = {
        ...parsedFieldsErrors,
        [el.field]: el.message,
      };
    });

    setErrorMessages(prev => ({ ...prev, ...parsedFieldsErrors }));
  };

  const fetchCustomers = async (filters: any, sortOrder: any = { column: 'custPrefix', order: 'asc' }) => {
    toggleIsLoadingFetchCustomers();
    try {
      const { data: { data } }: any = await apiService.getCustomerList(
        filters,
        undefined,
        sortOrder,
      );
      setCustomers(data);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Customers fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      toggleIsLoadingFetchCustomers();
    }
  };

  const handleApplyInvoice = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      await fetchAditionalDetails();
      const { data }: any = await apiService.billingAPI.createInvoice({
        customerId: selectedCustomer.id,
        contractId: state.contractDetails?.contractId,
        locationId: selectedLocation.id,
        date: state.invoiceDetails?.date,
        type: state.invoiceDetails?.type,
        taxExtempt: state.invoiceDetails?.taxExempt,
        debitOrCredit: state.invoiceDetails?.debitOrCredit,
        additionalDetails1: state.invoiceDetails?.additionalDetails1,
        additionalDetails2: state.invoiceDetails?.additionalDetails2,
        additionalDetails3: state.invoiceDetails?.additionalDetails3,
      });
      setState((prev: any) => ({ ...prev, invoiceDetails: { ...prev.invoiceDetails, invoiceId: data.invoiceId } }));
      await fetchInvoiceDetails(data.invoiceId);
      await fetchDefaultInvoiceValues(data.invoiceId);
      if (typeAllowed) {
        addNotification({
          text: 'Check was successful',
          type: MessageBarType.success,
        });
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Invoice applying error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleCreateLineItem = async () => {
    setErrorMessages(defaultLineItem);
    setState(prev => ({ ...prev, loading: true }));
    try {
      await apiService.billingAPI.addInvoiceItem({
        invoiceId: state.invoiceDetails?.invoiceId,
        customerId: selectedCustomer.id,
        contractId: state.contractDetails?.contractId,
        locationId: selectedLocation.id,
        ...buildLineItemObj,
      });
      handleClearLineItems();
      fetchLineItems(undefined, state.invoiceDetails?.invoiceId);
      addNotification({
        text: 'Line Item was successfully added.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          if (response.data.state)
            parseFieldsErrors(response.data.state);
          return addNotification({
            text: 'Unable to add & validate changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Adding line item error: ${response?.data?.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleUpdateLineItem = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      console.log(lineItemsFormFieldsGroup.getFieldFormValue('totalField'));

      await apiService.billingAPI.updateInvoiceItem({
        invoiceId: state.invoiceDetails?.invoiceId,
        contractId: state.contractDetails?.contractId,
        id: selectedLineItem?.id,
        ...buildLineItemObj,
      });
      handleClearLineItems();
      setSelectedLineItem(null);
      await fetchLineItems(invoiceItemsSortOrder, state.invoiceDetails?.invoiceId);
      await fetchDefaultInvoiceValues();
      addNotification({
        text: 'Line Item was successfully updated.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          if (response.data.state)
            parseFieldsErrors(response.data.state);
          return addNotification({
            text: 'Unable to update & validate changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Adding line item error: ${response?.data?.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleDeleteLineItems = async (rowsToDelete: Array<any> = []) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const idsToDelete = rowsToDelete.map((el: any) => el.id);
      await apiService.billingAPI.deleteInvoiceItem(idsToDelete);
      if (idsToDelete.includes(selectedLineItem?.id)) {
        handleClearLineItems();
        setSelectedLineItem(null);
      }
      await fetchLineItems(invoiceItemsSortOrder, state.invoiceDetails?.invoiceId);
      await fetchDefaultInvoiceValues();
      addNotification({
        text: 'Line Item(s) is successfully deleted.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Deletion line item error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any, printOriginal: boolean) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const requestData = {
        invoiceId: state.invoiceDetails?.invoiceId,
        printOriginal,
        headerFields: [],
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.billingAPI.printExcelInvoiceItems(requestData) :
        await apiService.billingAPI.printPdfInvoiceItems(requestData);

      downloadFile(data, printingType);
      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Printing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSaveInvoice = async () => {
    toggleSavingInvoiceConfirmation();
    setState(prev => ({ ...prev, loading: true }));
    try {
      await apiService.billingAPI.saveInvoice({
        invoiceId: state.invoiceDetails?.invoiceId,
        customerId: selectedCustomer.id,
        contractId: state.contractDetails?.contractId,
        locationId: selectedLocation.id,
        date: state.invoiceDetails?.date,
        type: state.invoiceDetails?.type,
        taxExtempt: state.invoiceDetails?.taxExempt,
        debitOrCredit: state.invoiceDetails?.debitOrCredit,
        additionalDetails1: state.invoiceDetails?.additionalDetails1,
        additionalDetails2: state.invoiceDetails?.additionalDetails2,
        additionalDetails3: state.invoiceDetails?.additionalDetails3,
        invoiceIdForCredit: creditInvoiceSelected?.invoiceId,
      });
      if (billingCodeSelected) {
        await updateBillingCodes();
      }
      setState((prev: any) => ({
        ...prev,
        invoiceDetails: defaultInvoiceDetails,
        creditAgainst: null,
        lineItems: [],
        foundLineItems: 0,
        lineItemsTotalAmount: '',
      }));
      addNotification({
        text: 'Invoice was successfully saved.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Invoice saving error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleDeleteInvoice = async () => {
    toggleDeletingInvoiceConfirmation();
    setState(prev => ({ ...prev, loading: true }));
    try {
      await apiService.billingAPI.deleteAvailableInvoices([state.invoiceDetails?.invoiceId]);
      setState((prev: any) => ({
        ...prev,
        invoiceDetails: defaultInvoiceDetails,
        creditAgainst: null,
        lineItems: [],
        foundLineItems: 0,
        lineItemsTotalAmount: '',
      }));
      addNotification({
        text: 'Invoice was successfully deleted.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Invoice deleting error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleCopyLineItems = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      await apiService.billingAPI.copyInvoices({
        invoiceId: state.invoiceDetails?.invoiceId,
        goodyearNumber: state.creditAgainst?.goodyearNumber,
      });
      handleClearLineItems();
      fetchLineItems(null, state.invoiceDetails?.invoiceId);
      addNotification({
        text: 'Line Items was successfully copied.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Copy line items error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSelectCreditInvoice = (invoiceSelected: any) => {
    setCreditInvoiceSelected(invoiceSelected);
    toggleShowFindInvoices();
  };

  const handleAutoCalculateChange = (event: any, autoCalculate: boolean | undefined): void => {
    toggleAutoCalculate();
  };

  const handleClearLineItems = async () => {
    setState(prev => ({ ...prev, loading: true }));
    lineItemsFormFieldsGroup.cleanFormData();
    setErrorMessages(defaultLineItem);
    await fetchDefaultInvoiceValues(state.invoiceDetails?.invoiceId, '', '', '');
    setState(prev => ({ ...prev, loading: false }));
  };

  const transformDecimalValue = (property: string, value1?: string) => {
    const value = lineItemsFormFieldsGroup.getFieldFormValue(property);
    if (/^\d+.?$/.test(value))
      lineItemsFormFieldsGroup.setFormValue(property, Number.parseFloat(value).toFixed(1));
  };

  const delayedFetchTotalAndDescription = useCallback(
    debounce((qty: any, unitPrice: any) => fetchTotalAndDescription(qty, unitPrice), 500), [state.invoiceDetails?.invoiceId],
  );

  useEffect(() => {
    if (!customerFilters) {
      fetchCustomers({});
    }
  }, [customerFilters]);

  useEffect(() => {
    if (selectedCustomer?.id) {
      fetchLocations();
      fetchContracts();
      fetchApplicableInvoiceTypes();
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (creditInvoiceSelected)
      fetchCreditAgainst(creditInvoiceSelected?.invoiceId);
  }, [creditInvoiceSelected]);

  useEffect(() => {
    if (!state.contractDetails) return;
    fetchApplicableInvoiceTypes();
  }, [state.contractDetails]);

  useEffect(() => {
    if (!state.invoiceDetails?.type || !state.invoiceDetails?.debitOrCredit) return;
    getBillingCodesList();
  }, [state.invoiceDetails?.type, state.invoiceDetails?.debitOrCredit]);

  useEffect(() => {
    if (selectedLineItem) setLineItemInfo(selectedLineItem);
  }, [selectedLineItem]);

  useEffect(() => {
    if (!lineItemsFormFieldsGroup.getFieldFormValue('qtyField') ||
      !lineItemsFormFieldsGroup.getFieldFormValue('unitPriceField') || !isAutoCalculate) return;
    delayedFetchTotalAndDescription(+lineItemsFormFieldsGroup.getFieldFormValue('qtyField'), +lineItemsFormFieldsGroup.getFieldFormValue('unitPriceField'));
  }, [
    lineItemsFormFieldsGroup.getFieldFormValue('radialOrBiasField'),
    lineItemsFormFieldsGroup.getFieldFormValue('qtyField'),
    lineItemsFormFieldsGroup.getFieldFormValue('unitPriceField'),
  ]);

  useEffect(() => {
    if (
      !lineItemsFormFieldsGroup.getFieldFormValue('radialOrBiasField')
      || selectedLineItem
    ) return;
    fetchMBCode();
  }, [lineItemsFormFieldsGroup.getFieldFormValue('radialOrBiasField')]);

  useEffect(() => {
    if (!lineItemsFormFieldsGroup.getFieldFormValue('mbCodeField')) return;
    if (lineItemsFormFieldsGroup.getFieldFormValue('mbCodeField').length != 9)
      setErrorMessages({ ...errorMessages, mbCode: 'The MbCode must be 9 digits' });
    else setErrorMessages(defaultLineItem);
  }, [lineItemsFormFieldsGroup.getFieldFormValue('mbCodeField')]);

  useEffect(() => {
    setErrorMessages({
      ...errorMessages,
      qty: parseFloat(lineItemsFormFieldsGroup.getFieldFormValue('qtyField')) === 0 ? 'QTY should not admit 0 value' : '',
    });
  }, [lineItemsFormFieldsGroup.getFieldFormValue('qtyField')]);


  const buildLineItemObj = {
    qty: lineItemsFormFieldsGroup.getFieldFormValue('qtyField'),
    whlPos: lineItemsFormFieldsGroup.getFieldFormValue('wheelPositionField'),
    rorB: lineItemsFormFieldsGroup.getFieldFormValue('radialOrBiasField'),
    mbCode: lineItemsFormFieldsGroup.getFieldFormValue('mbCodeField'),
    unitPrice: lineItemsFormFieldsGroup.getFieldFormValue('unitPriceField'),
    total: lineItemsFormFieldsGroup.getFieldFormValue('totalField'),
    cost: lineItemsFormFieldsGroup.getFieldFormValue('costField'),
    description: lineItemsFormFieldsGroup.getFieldFormValue('descriptionField'),
  };

  const disableApplyButton =
    !(state.contractDetails?.contractId
      && state.invoiceDetails?.type
      && state.invoiceDetails?.debitOrCredit
      && (state.invoiceDetails?.debitOrCredit == 'CR' ?
        creditInvoiceSelected != null : true
      ))
    || !isNil(state.invoiceDetails?.invoiceId)
    || !userPermissions.isWrite;

  const disableFindBtn =
    state.invoiceDetails?.debitOrCredit != 'CR' ||
    selectedCustomer == null ||
    !isNil(state.invoiceDetails.invoiceId) ||
    !userPermissions.isWrite;
    
  const disableCopyBtn =
    state.invoiceDetails?.debitOrCredit != 'CR' ||
    selectedCustomer == null ||
    state.creditAgainst == null ||
    isNil(state.creditAgainst?.goodyearNumber) ||    
    isNil(state.invoiceDetails.invoiceId)  ||
    !userPermissions.isWrite;

  const billingTypeOptions = billingTypes?.map((item: any) => ({
    key: item,
    text: item,
  }));

  const isInvoiceMileageOrEstimated = state.invoiceDetails?.type === 'MILEAGE' || state.invoiceDetails?.type === 'ESTIMATED';

  const disableLineItems = isNil(state.invoiceDetails?.invoiceId) || !typeAllowed;
  const isMBCodeCorrect = lineItemsFormFieldsGroup.getFieldFormValue('mbCodeField')?.length == 9;

  const disableAddLineBtn = !state.invoiceDetails?.invoiceId
    || (!isMBCodeCorrect) || !userPermissions.isWrite
    || (isInvoiceMileageOrEstimated
      && (!lineItemsFormFieldsGroup.getFieldFormValue('qtyField')
        || !lineItemsFormFieldsGroup.getFieldFormValue('wheelPositionField')))
    || parseFloat(lineItemsFormFieldsGroup.getFieldFormValue('qtyField')) === 0
    || !lineItemsFormFieldsGroup.getFieldFormValue('descriptionField')
    || !lineItemsFormFieldsGroup.getFieldFormValue('unitPriceField')
    || !lineItemsFormFieldsGroup.getFieldFormValue('costField');

  const isTotalFieldDisabled = state.invoiceDetails?.type === 'ALUMINIUM WHEELS'
    || state.invoiceDetails?.type === 'EQUIPMENT'
    || state.invoiceDetails?.type === 'ESTIMATED'
    || state.invoiceDetails?.type === 'MILEAGE'
    || state.invoiceDetails?.type === 'STEEL WHEELS';

  const disableCreditAgainstFields = !isNil(state.invoiceDetails?.invoiceId);
  const shouldFillMbCode = (type = '') => type && type != 'TAX' && type != 'MISC';


  return (
    <div id="createInvoiceContainer">
      <Text variant='xxLarge' className={styles.highlight}>Create Invoice</Text>
      <div className={styles.detailsRow}>
        <div>
          <Text variant="xLarge" className={styles.highlight}>Customer Details</Text>
          <SeparatorGy />
          <div className={styles.fields}>
            <div className={styles.field}>
              <AutoCompleteField
                label="*Prefix"
                data-testid='prefix'
                icon="FavoriteStar"
                value={selectedCustomer?.id}
                list={customerPrefixList}
                textValue={(value: any) => onCustomerTextChange('custPrefix', value)}
                onChangeAction={onCustomerChange}
                isLoading={isLoadingFetchCustomers}
              />
              <IconButton
                id='searchPrefix'
                className={styles.searchButton}
                iconProps={{ iconName: 'Search' }}
                onClick={() => onCustomerChange(customerPrefixList[0].key)}
              />
            </div>
            <div className={styles.field}>
              <AutoCompleteField
                label="*Customer Name"
                data-testid='customer-name'
                icon="Contact"
                value={selectedCustomer?.id}
                list={customerNameList}
                textValue={(value: any) => onCustomerTextChange('custName', value, { column: 'customerName', order: 'asc' })}
                onChangeAction={onCustomerChange}
                isLoading={isLoadingFetchCustomers}
              />
              <IconButton
                id='searchCustName'
                className={styles.searchButton}
                iconProps={{ iconName: 'Search' }}
                onClick={() => onCustomerChange(customerNameList[0].key)}
              />
            </div>
            <div className={styles.field}>
              <SeparatorGy vertical />
            </div>
            <div className={styles.field}>
              <TextField
                label="Region"
                value={selectedCustomer?.regionCode}
                disabled
              />
            </div>
          </div>
          <div className={styles.fields}>
            <div className={styles.field}>
              <AutoCompleteField
                label="Loc Code"
                data-testid='loc-code'
                icon="POISolid"
                value={selectedLocation?.id}
                list={locationCodeList}
                onChangeAction={onLocationChange}
                isLoading={isLoadingFetchLocations}
                disabled={!locations.length}
              />
              <IconButton
                id='searchLocCode'
                className={styles.searchButton}
                iconProps={{ iconName: 'Search' }}
                onClick={() => onLocationChange(locationCodeList[0].key)}
                disabled={!locations.length}
              />
            </div>
            <div className={styles.field}>
              <AutoCompleteField
                label="Location"
                data-testid='location'
                icon="POISolid"
                value={selectedLocation?.id}
                list={locationNameList}
                onChangeAction={onLocationChange}
                isLoading={isLoadingFetchLocations}
                disabled={!locations.length}
              />
            </div>
            <div className={styles.field}>
              <PrimaryButton
                onClick={toggleShowLocationsModal}
                text="Available Locations"
                disabled={!locations.length} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div>
          <Text variant="xLarge" className={styles.highlight}>Contract Details</Text>
          <SeparatorGy />
          <div className={styles.fields}>
            <div className={styles.field}>
              <TextField
                label="Contract ID"
                value={state.contractDetails?.contractId}
                disabled
              />
            </div>
            <div className={styles.field}>
              <TextField
                label="Status"
                value={state.contractDetails?.status}
                disabled
              />
            </div>
            <div className={styles.field}>
              <TextField
                className={styles.mediumField}
                label="Contract Period"
                value={state.contractDetails?.contractPeriod}
                disabled
              />
            </div>
          </div>
        </div>
        <div className={styles.detailsSeparator}>
          <SeparatorGy vertical />
        </div>
        <div>
          <Text variant="xLarge" className={styles.highlight}>Invoice Details</Text>
          <SeparatorGy />
          <div className={styles.fields}>
            <div className={styles.field}>
              <TextField
                label="Invoice #"
                value={state.invoiceDetails?.invoiceNumber}
                disabled
              />
            </div>
            <div className={styles.field}>
              <TextField
                label="Status"
                value={state.invoiceDetails?.status}
                disabled
              />
            </div>
            <div className={classNames(styles.field, styles.dateField)}>
              <DatePicker
                className={styles.orderDetailsField}
                label="Date"
                value={moment(state.invoiceDetails?.date).toDate()}
                onSelectDate={(date: Date | null | undefined) => setState((prev: any) => ({ ...prev, invoiceDetails: { ...prev.invoiceDetails, date: moment(date).format('YYYY/MM/DD') } }))}
                formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                disabled={disableCreditAgainstFields}
              />
            </div>
            <div className={styles.field}>
              <Dropdown
                className={styles.mediumField}
                label="Type"
                options={billingTypeOptions}
                defaultSelectedKey={state.invoiceDetails?.type}
                onChange={onInvoiceTypeChange}
                disabled={disableCreditAgainstFields}
              />
            </div>
            <div className={styles.field}>
              <Dropdown
                label="DB/CR"
                options={invoiceTypes.map((item: any) => ({
                  key: item,
                  text: item,
                }))}
                defaultSelectedKey={state.invoiceDetails?.debitOrCredit}
                onChange={onDebitOrCreditChange}
                disabled={disableCreditAgainstFields}
              />
            </div>
            <div className={styles.field}>
              <PrimaryButton
                id="applyButton"
                text="Apply"
                onClick={handleApplyInvoice}
                disabled={disableApplyButton}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsColumn}>
          <SeparatorGy />
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.availableLineItemsTable}>
          <DataGridComponent
            idTable={'available-line-items-table'}
            title='Available Line Items'
            headCells={availableLineItemsHeadCells}
            rowsTable={state.lineItems}
            totalDataFound={state.foundLineItems}
            handleChangeDataGridState={handleChangeItemsDataGridState}
            handleSelectRow={handleSelectLineItem}
            handleDelete={handleDeleteLineItems}
            isLoading={state.lineItemsLoading}
            enableMultiSelectRow
            enableDeleteOption
            enableCheckBox
          />
          <div className={styles.bottomInfo}>
            <Text variant="xLarge" className={styles.highlight}>Total Amount: ${parseFloat(state?.lineItemsTotalAmount || '0').toFixed(2)}</Text>
          </div>
        </div>
        {/*Credit Against section*/}
        <div>
          <Text variant="xLarge" className={styles.highlight}>Credit Against</Text>
          <SeparatorGy />
          <div className={styles.fields}>
            <div className={styles.field}>
              <TextField
                label="Type"
                value={state.creditAgainst?.type}
                disabled
              />
            </div>
            <div className={styles.field}>
              <TextField
                label="GDYR #"
                value={state.creditAgainst?.goodyearNumber}
                disabled
              />
            </div>
            <div className={classNames(styles.field, styles.dateField)}>
              <DatePicker
                className={styles.orderDetailsField}
                label="Date"
                value={state.creditAgainst ? moment(state.creditAgainst?.date).toDate() : undefined}
                formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                disabled
              />
            </div>
            <div className={styles.field}>
              <TextField
                label="Total"
                value={state.creditAgainst?.total}
                disabled
              />
            </div>
          </div>
          <div className={styles.fields}>
            <PrimaryButton
              text="Find"
              id="findInvoiceBtn"
              disabled={disableFindBtn}
              onClick={toggleShowFindInvoices}
            />
            <PrimaryButton
              text="Copy Line Items"
              id="CopyInvoicesBtn"
              disabled={disableCopyBtn}
              onClick={handleCopyLineItems}
            />
          </div>
        </div>
      </div>
      {/*Line Items section*/}
      <div className={styles.detailsRow}>
        {showLoadingLineItems ? <Spinner /> :
          <div className={styles.detailsColumn}>
            <div className={styles.lineItemsContainer}>
              <Text variant="xLarge" className={styles.highlight}>Line Items</Text>
              <SeparatorGy vertical />
              <Toggle id="autocalculate" label="Recalculate?" checked={isAutoCalculate} onChange={handleAutoCalculateChange} />
            </div>
            <form className={styles.fields}>
              <div className={styles.field}>
                <TextField
                  {...lineItemsFormFieldsGroup.getFieldForm('qtyField')}
                  errorMessage={errorMessages.qty}
                  disabled={disableLineItems}
                  required={isInvoiceMileageOrEstimated}
                />
              </div>
              <div className={styles.field}>
                <TextField
                  {...lineItemsFormFieldsGroup.getFieldForm('wheelPositionField')}
                  errorMessage={errorMessages.wheelPosition}
                  disabled={disableLineItems}
                  required={isInvoiceMileageOrEstimated}
                />
              </div>
              <div className={styles.field}>
                <TextField
                  {...lineItemsFormFieldsGroup.getFieldForm('radialOrBiasField')}
                  errorMessage={errorMessages.radialOrBias}
                  disabled={disableLineItems}
                />
              </div>
              <div className={styles.field}>
                <TextField
                  {...lineItemsFormFieldsGroup.getFieldForm('mbCodeField')}
                  errorMessage={errorMessages.mbCode}
                  maxLength={9}
                  disabled={disableLineItems}
                  description=''
                  required
                />
              </div>
              <div className={styles.field}>
                <TextField
                  {...lineItemsFormFieldsGroup.getFieldForm('unitPriceField')}
                  errorMessage={errorMessages.unitPrice}
                  disabled={disableLineItems}
                  required
                />
              </div>
              <div className={styles.field}>
                <TextField
                  {...lineItemsFormFieldsGroup.getFieldForm('totalField')}
                  onBlur={() => transformDecimalValue('totalField')}
                  errorMessage={errorMessages.total}
                  disabled={isTotalFieldDisabled}
                />
              </div>
              <div className={styles.field}>
                <TextField
                  {...lineItemsFormFieldsGroup.getFieldForm('costField')}
                  onBlur={() => transformDecimalValue('costField')}
                  errorMessage={errorMessages.cost}
                  disabled={disableLineItems}
                  required
                />
              </div>
              <div className={styles.field}>
                <TextField
                  {...lineItemsFormFieldsGroup.getFieldForm('descriptionField')}
                  errorMessage={errorMessages.description}
                  disabled={disableLineItems}
                  required
                />
              </div>
            </form>
            <div className={styles.fieldsActions}>
              <PrimaryButton id="clearItemButton" text="Clear" onClick={handleClearLineItems} />
              <PrimaryButton
                id="addItemButton"
                text="Add New"
                onClick={handleCreateLineItem}
                disabled={!!selectedLineItem || disableAddLineBtn}
              />
              <PrimaryButton
                id="updateItemButton"
                text="Update"
                onClick={handleUpdateLineItem}
                disabled={!selectedLineItem || disableAddLineBtn || !userPermissions.isWrite}
              />
            </div>
          </div>}
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsColumn}>
          <Text variant="xLarge" className={styles.highlight}>Additional Details</Text>
          <div>
            <Text variant="mediumPlus" className={styles.highlight}>(to be shown at the bottom of the invoice)</Text>
          </div>

          <SeparatorGy />
          <div className={styles.aditionalDetailsFieldsContainer}>
            <TextField
              value={state.invoiceDetails?.additionalDetails1}
              onChange={(ev, value) => setState((prev: any) => ({ ...prev, invoiceDetails: { ...prev.invoiceDetails, additionalDetails1: value } }))}
              multiline
              rows={2}
              cols={100}
              required
            />
            <TextField
              value={state.invoiceDetails?.additionalDetails2}
              onChange={(ev, value) => setState((prev: any) => ({ ...prev, invoiceDetails: { ...prev.invoiceDetails, additionalDetails2: value } }))}
              multiline
              rows={2}
              cols={100}
            />
            <TextField
              value={state.invoiceDetails?.additionalDetails3}
              onChange={(ev, value) => setState((prev: any) => ({ ...prev, invoiceDetails: { ...prev.invoiceDetails, additionalDetails3: value } }))}
              multiline
              rows={2}
              cols={100}
              required={state.invoiceDetails?.type == 'DB'}
            />
          </div>
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsColumn}>
          <SeparatorGy />
          <div className={styles.fieldsActions}>
            <DefaultButton
              id="printButton"
              text="Print/Export"
              disabled={!state.invoiceDetails?.invoiceId}
              onClick={toggleShowPrintExport}
            />
            <PrimaryButton
              id="cancelInvoiceButton"
              text="Cancel & Delete Invoice"
              disabled={!state.invoiceDetails?.invoiceId || !userPermissions.isWrite}
              onClick={toggleDeletingInvoiceConfirmation}
            />
            <PrimaryButton
              text="Ok - Save"
              id="saveInvoiceButton"
              disabled={!state.invoiceDetails?.invoiceId || !userPermissions.isWrite || !typeAllowed}
              onClick={toggleSavingInvoiceConfirmation}
            />
          </div>
        </div>
      </div>
      <SelectingModal
        isOpen={showLocationsModal}
        title="Customer Name | Available Locations"
        selectingList={locations}
        onDismiss={toggleShowLocationsModal}
        onSubmit={onLocationModalSubmit}
        columns={locationsColumns}
        preselectedKey={selectedLocation?.id}
      />
      <DialogComponent
        key='deleteInvoiceDialog'
        isOpen={showDeletingInvoiceConfirmation}
        onCancel={toggleDeletingInvoiceConfirmation}
        onSubmit={handleDeleteInvoice}
        title='Confirmation'
        subText='Are you sure you would like to cancel and delete this invoice? No invoice will be created.'
        onCancelLabel='Return'
        onSubmitLabel='Cancel & Delete'
      />
      <DialogComponent
        key='saveInvoiceDialog'
        isOpen={showSavingInvoiceConfirmation}
        onCancel={toggleSavingInvoiceConfirmation}
        onSubmit={handleSaveInvoice}
        title='Confirmation'
        subText='Are you sure you have completed all information and would like to save & create this invoice?'
        onCancelLabel='Return'
        onSubmitLabel='Save & Close'
      />
      <DialogComponent
        key='copyInvoiceDialog'
        isOpen={showCopyInvoiceConfirmation}
        onCancel={toggleCopyInvoiceConfirmation}
        onSubmit={handleCopyLineItems}
        title='Confirmation'
        subText='Are you sure you would like to copy all Line Items from the selected Credit Against invoice?'
        onCancelLabel='Return'
        onSubmitLabel='Copy'
      />
      <PrintingModalInvoice
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      <InvoiceModalComponent
        id='invoiceModal'
        customerId={selectedCustomer?.id}
        invoiceType={state.invoiceDetails?.type || ''}
        isOpen={showFindInvoices}
        onDismiss={toggleShowFindInvoices}
        onSubmit={handleSelectCreditInvoice}
      />
      {(state.loading) && <LoadingScreen />}
    </div>
  );
};

export default CreateInvoices;
