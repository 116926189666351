import {Dropdown, IColumn, IconButton, IDropdownOption, MessageBarType, Text } from '@fluentui/react';
import { FC, ReactElement, useEffect, useState } from 'react';
import { columns } from './consts';
import { pageSizes } from '../../../../../../consts/recordKeeping';
import { IPostPriorVehicleMilesProps } from './IPostPriorVehicleMilesProps';
import { IPostPriorVehicleMilesState } from './IPostPriorVehicleMilesState';
import useNotifications from '../../../../../../hooks/useNotifications';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import classNames from 'classnames';
import { useBoolean } from '@fluentui/react-hooks';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import { sortOrder } from '../../../../../../consts/sortOrder';
import Pagination from '../../../../../Pagination/Pagination';
import apiService from '../../../../../../api';
import styles from './PostPriorVehicleMiles.module.scss';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';
import { customerSelector, locationSelector } from '../../../../../../redux/recordKeepingSlice';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PostedVehicleMiles from '../../postedVehicleMiles/PostedVehicleMiles';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_vehicleMiles_postPriorVehicleMiles} from '../../../../../../consts/programKeys';

const PostPriorVehicleMiles: FC<IPostPriorVehicleMilesProps> = (): ReactElement => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_vehicleMiles_postPriorVehicleMiles);

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);

  const [state, setState] = useState<IPostPriorVehicleMilesState>({
    items: [],
    loading: false,
    foundCount: 0,
  });

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const [billingYearList, setBillingYearList] = useState<Array<any>>([]);
  const [filters, setFilters] = useState<any>({ year: 0 });
  const [currentFilters, setCurrentFilters] = useState<any>({
    from: '',
    to: '',
  });
  const [isEditing, setIsEdit] = useState<any>(null);
  const [isPostedVehicleMilesShow, { setTrue: showPostedVehicleMiles, setFalse: hidePostedVehicleMiles }] = useBoolean(false);

  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const openPostedVehicleMiles = async (item: any) => {
    hidePostedVehicleMiles();
    setIsEdit(item.id);
    setCurrentFilters((prev: any) => ({ 
      ...prev, 
      from: moment(item.fromBillPeriod).format('MM/DD/YYYY'), 
      to: moment(item.toBillPeriod).format('MM/DD/YYYY'), 
    }));
    showPostedVehicleMiles();
  };
    
  const fetchMileageRunList = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.mileageRun.getStartDetails(
        filters,
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
      );
      const foundCount = data.total.found;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      addNotification({
        text: `Mileage Run fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchBillingYearList = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.mileageRun.getBillingYearList();
      setBillingYearList(data.data);
      setFilters((prev: any) => ({...prev, year: ''+new Date().getFullYear()}));
    } catch (e: any) {
      addNotification({
        text: `Mileage Run fetching billing period error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleCancel = () => {
    setIsEdit(null);
    hidePostedVehicleMiles();
  };

  useEffect(() => {
    fetchBillingYearList();
  }, []);

  useEffect(() => {
    handleCancel();
    if (filters.year) {
      fetchMileageRunList();
    }
  }, [paginationProps.current, countOnPage, columnsState, filters, customerId]);

  return (
    <div>
      <div>
        <div className={styles.tableHeading}>
          <div>
            <Text variant="xLarge" className={styles.highlight}>Mileage Run Log</Text>
            <SeparatorGy vertical />
            <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
          </div>
          <div>
            <Text variant="large" className={styles.highlight}>Year</Text>
            <SeparatorGy vertical />
            <Dropdown
              options={billingYearList.map(item => ({
                key: item.year,
                text: item.year,
              }))}
              defaultSelectedKey={filters.year}
              selectedKey={filters.year}
              onChange={(e, selectedOption: any) => setFilters((prev: any) => ({...prev, year: selectedOption.key}))}
              styles={{dropdown: { width: 80 }}}
            />
          </div>
          <div>
            <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
            <Dropdown
              options={pageSizes.map(pageSize => ({
                key: pageSize,
                text: pageSize.toString(),
              }))}
              defaultSelectedKey={pageSizes[0]}
              selectedKey={countOnPage?.key}
              onChange={onChangeCountOnPage}
            />
          </div>
        </div>
        <div className={styles['table-wrapper']}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.viewColumn}></th>
                {
                  columnsState.map(item => (
                    <th
                      key={item.name}
                      className={classNames(
                        item.isSorted && item.isSortedDescending ?
                          styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined,
                        styles[item.key],
                      )}
                      onClick={() => onColumnClick(item)}>{item.name}
                    </th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {state.items.map(item => (
                <tr key={item.id} className={isEditing == item.id ? styles.trSelected : styles.trBasic}>
                  <td>
                    {item.completedStatus == 'S' && <IconButton
                      id="editButton"
                      iconProps={{ iconName: 'Edit' }}
                      className={classNames(styles.viewButton)}
                      onClick={() => openPostedVehicleMiles(item)}
                      disabled={!userPermissions.isWrite}
                    />
                    }
                  </td>
                  <td>{item.fromBillPeriod && moment(item.fromBillPeriod).format('MM/DD/YYYY')}</td>
                  <td>{item.toBillPeriod && moment(item.toBillPeriod).format('MM/DD/YYYY')}</td>
                  <td>{item.startMode}</td>
                  <td>{item.startedOn ? moment(item.startedOn).format('MM/DD/YYYY h:mm:ss A') : item.startedOn}</td>
                  <td>{item.status}</td>
                  <td>{item.completedStatus}</td>
                  <td>{item.completedOn ? moment(item.completedOn).format('MM/DD/YYYY h:mm:ss A') : item.completedOn}</td>
                  <td>{item.billType}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <SeparatorGy />
        <Pagination {...paginationProps} />
        {isPostedVehicleMilesShow && <PostedVehicleMiles billingPeriodId={isEditing} filters={currentFilters} />}
      </div>
      {state.loading && <LoadingScreen />}
    </div>
  );
};

export default PostPriorVehicleMiles;