import { DefaultButton, Dropdown, IDropdownOption, IconButton, MessageBarType, Modal, PrimaryButton, Text, TextField } from '@fluentui/react';
import { debounce, set } from 'lodash';
import moment from 'moment';
import { useBoolean } from '@fluentui/react-hooks';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import apiService from '../../api';
import AutoCompleteField from '../../components/Common/Search/AutoCompleteField';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import SeparatorGy from '../../components/SeparatorGy/SeparatorGy';
import useNotifications from '../../hooks/useNotifications';
import { IControlValue, IDispositionCodesValue } from '../../models/IControlValue';
import { IControlValuePart } from '../../models/IControlValuePart';
import DataGridComponent from '../DataGridComponent';
import { FormBuilderGroup } from '../FormBuilderComponent';
import { controlValueHeadCells } from '../MaintainControlValuesModalComponent/consts';
import { controlValuePartHeadCells, controlValuePartHeadCellsForInspCode, controlValuePartHeadCellsForDispCode, VALUE_PART_FIELDS, VALUE_PART_FIELDS_INSPCODE, VALUE_PART_FIELDS_DISPCODE, statusOptions, tdRequiredOptions } from './consts';
import styles from './EditControlValues.module.scss';
import DialogComponent from '../DialogComponent';
import { HeadCell } from '../DataGridComponent/DataGridModels';


interface EditControlValuesModalComponentProps {
  id: string;
  isOpen: boolean;
  onDismiss: () => void;
  cancelLabel: string;
  controlValue: IControlValue | null;
}
 
const EditControlValuesModalComponent: FunctionComponent<EditControlValuesModalComponentProps> = ({
  id,
  isOpen, 
  onDismiss, 
  cancelLabel,
  controlValue,
}) => {

  const { addNotification } = useNotifications();
  const [valuePartList, setValuePartList] = useState<IControlValuePart[]>([]);
  const [totalFound, setTotalFound] = useState(0);
  const [valuePartSelected, setValuePartSelected] = useState<IControlValuePart[]>([]);

  const [valuePartListPagination, setValuePartListPagination] = useState<any>({ pageSize: 10, pageNumber: 1 });
  const [valuePartListSortOrder, setValuePartListSortOrder] = useState<any>({ column: controlValuePartHeadCells[0].fieldName, order: 'asc' });

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isLoadingTable, setLoadingTable] = useState<boolean>(false);
  const [headCells, setHeadCells] = useState<HeadCell[]>(controlValuePartHeadCells);

  const [valueControlList, setValueControlList] = useState<IControlValue[]>([]);
  const [valueControlSelected, setValueControlSelected] = useState<IControlValue | null>(null);
  const [dispCodeSelected, setDispCodeSelected] = useState<string>('');
  const [statusSelected, setStatusSelected] = useState<string>('');
  const [tdSelected, setTdSelected] = useState<string>('');
  const [valueControlAutocompleteText, setValueControlAutocompleteText] = useState<string>('');
  const [valueDispCodelAutocompleteText, setDispCodeControlAutocompleteText] = useState<string>('');
  const [valueControlAutocompleteLoading, setValueControlAutocompleteLoading] = useState<boolean>(false);

  const [disposition, setDisposition] = useState<IDispositionCodesValue[]>([]);

  const valuePartFieldsGroup = new FormBuilderGroup(VALUE_PART_FIELDS);
  const valuePartFieldsGroupForInspCode = new FormBuilderGroup(VALUE_PART_FIELDS_INSPCODE);
  const valuePartFieldsGroupForDispCode = new FormBuilderGroup(VALUE_PART_FIELDS_DISPCODE);
  const INSPCODE = valueControlSelected?.name == 'INSPCODE';
  const DISPCODE = valueControlSelected?.name == 'DISPCODE';

  const [shouldPreventFirstDataGridLoading, setShouldPreventFirstDataGridLoading] = useState<boolean>(true);

  const [isSavingDialogVisible, { toggle: toggleSavingConfirmation }] = useBoolean(false);

  const fetchData = async(
    pagination: any = valuePartListPagination, 
    filters: any = { sectionName: valueControlSelected?.name != null ? valueControlSelected?.name : controlValue?.name }, 
    sortOrder: any = valuePartListSortOrder,
  ) => {
    setLoadingTable(true);
    try {
      const {data}: any = await apiService.tireOrders.getLookupValueParts(
        pagination,
        sortOrder, 
        filters, 
      );
      setValuePartList(data?.data?.data);
      setValuePartSelected([]);
      setTotalFound(data?.data?.total?.found);
      setValuePartListPagination(pagination);
      setValuePartListSortOrder(sortOrder);
      if(shouldPreventFirstDataGridLoading)
        setShouldPreventFirstDataGridLoading(false);
    } catch (error: any) {
      const { response } = error;
      addNotification({text:`Fetching Control Values error: ${response.data.message}`, type: MessageBarType.error});
    } finally {
      setLoadingTable(false);
    }    
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    const {countOnPage, paginationProps, searchedText, sortOrder} = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString : searchedText,
      sectionName: valueControlSelected?.name,
    };
    if(!shouldPreventFirstDataGridLoading)
      await fetchData(pagination, filters, sortOrder);
  };

  const handleSelectRow = (rowsSelected: any[]) => {
    setValuePartSelected(rowsSelected);
    if (rowsSelected.length === 1) {
      if (INSPCODE) {
        const disp = disposition?.filter(el => el.code === rowsSelected[0]?.value);
        setDispCodeSelected(`${disp[0].code} - ${disp[0].description}`);
        setStatusSelected(rowsSelected[0]?.status);
        setTdSelected(rowsSelected[0]?.subKeys);
        valuePartFieldsGroupForInspCode.setFormValue('valueField', dispCodeSelected);
      }
      else if (DISPCODE) {
        setStatusSelected(rowsSelected[0]?.status);
        valuePartFieldsGroupForDispCode.setFormValue('statusField', rowsSelected[0]?.status);
      } 
    }
  };

  const fetchMaintainControlValues = async(searchString?: string) => {
    setValueControlAutocompleteLoading(true);
    try {
      const {data}: any = await apiService.sections.getMaintainLookupValues(undefined, { column: controlValueHeadCells[0].fieldName, order: 'asc' }, { searchString });
      setValueControlList(data?.data);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text:`Fetching Maintain Control Values error: ${response?.data?.message}`, 
        type: MessageBarType.error,
      });
    } finally {
      setValueControlAutocompleteLoading(false);
    }    
  };

  const getDisposition = async () => {
    try {
      const { data } = await apiService.postOosTires.getDisposition();
      setDisposition(data);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching disposition error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const delayedFetchMaintainControlValue = useCallback(debounce((searchString: string) => fetchMaintainControlValues(searchString), 1000), []);

  const onAddControlValuePart = async () => {
    toggleSavingConfirmation();
    setLoading(true);
    try {
      if (INSPCODE) {
        await apiService.tireOrders.addLookupValueParts({
          part: {
            sectionId: valueControlSelected?.id,
            key: valuePartFieldsGroupForInspCode.getFieldFormValue('keyField'),
            value: valuePartFieldsGroupForInspCode.getFieldFormValue('valueField'),
            subKeys: valuePartFieldsGroupForInspCode.getFieldFormValue('subKeysField'),
            description: valuePartFieldsGroupForInspCode.getFieldFormValue('descriptionField'),
            status: valuePartFieldsGroupForInspCode.getFieldFormValue('statusField'),
          },
        });
      }
      else if (DISPCODE) {
        await apiService.tireOrders.addLookupValueParts({
          part: {
            sectionId: valueControlSelected?.id,
            key: valuePartFieldsGroupForDispCode.getFieldFormValue('keyField'),
            description: valuePartFieldsGroupForDispCode.getFieldFormValue('descriptionField'),
            status: valuePartFieldsGroupForDispCode.getFieldFormValue('statusField'),
          },
        });
      }
      else {
        await apiService.tireOrders.addLookupValueParts({
          part: {
            sectionId: valueControlSelected?.id,
            key: valuePartFieldsGroup.getFieldFormValue('keyField'),
            value: valuePartFieldsGroup.getFieldFormValue('valueField'),
            subKeys: valuePartFieldsGroup.getFieldFormValue('subKeysField'),
            description: valuePartFieldsGroup.getFieldFormValue('descriptionField'),
          },
        });
      }
      fetchData();
      addNotification({
        text: 'Control Value was successfully added.',
        type: MessageBarType.success,
      });
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Control Value adding error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const onUpdateControlValuePart = async () => {
    toggleSavingConfirmation();
    setLoading(true);
    try {
      if (INSPCODE) {
        await apiService.tireOrders.updateLookupValueParts({
          part: [{
            ...valuePartSelected[0],
            key: valuePartFieldsGroupForInspCode.getFieldFormValue('keyField'),
            value: valuePartFieldsGroupForInspCode.getFieldFormValue('valueField'),
            subKeys: valuePartFieldsGroupForInspCode.getFieldFormValue('subKeysField'),
            description: valuePartFieldsGroupForInspCode.getFieldFormValue('descriptionField'),
            status: valuePartFieldsGroupForInspCode.getFieldFormValue('statusField'),
          }],
        });
      }
      else if (DISPCODE) {
        await apiService.tireOrders.updateLookupValueParts({
          part: [{
            ...valuePartSelected[0],
            key: valuePartFieldsGroupForDispCode.getFieldFormValue('keyField'),
            description: valuePartFieldsGroupForDispCode.getFieldFormValue('descriptionField'),
            status: valuePartFieldsGroupForDispCode.getFieldFormValue('statusField'),
          }],
        });
      }
      else {
        await apiService.tireOrders.updateLookupValueParts({
          part: [{
            ...valuePartSelected[0],
            key: valuePartFieldsGroup.getFieldFormValue('keyField'),
            value: valuePartFieldsGroup.getFieldFormValue('valueField'),
            subKeys: valuePartFieldsGroup.getFieldFormValue('subKeysField'),
            description: valuePartFieldsGroup.getFieldFormValue('descriptionField'),
          }],
        });
      }
      fetchData();
      addNotification({
        text: 'Control Value was successfully updated.',
        type: MessageBarType.success,
      });
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Control Value updating error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteValue = async (rowsToDelete: Array<IControlValuePart> = []) => {
    setLoading(true); 
    try {
      await apiService.tireOrders.deleteLookupValueParts(rowsToDelete.map(el => el.id));
      addNotification({
        text: 'Control Value(s) were successfully deleted.',
        type: MessageBarType.success,
      });
      fetchData();
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Control Values(s) deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const onChangeSearchControlValue = (newControlValue: any) => {
    setValueControlSelected(valueControlList.find(el => el.id === newControlValue) || null);
  };

  const onChangeDispCodeValue = (newDispCodeValue: any) => {
    const disp = disposition.filter(el => el.code === newDispCodeValue);
    setDispCodeSelected(`${disp[0].code} - ${disp[0].description}`);
    valuePartFieldsGroupForInspCode.setFormValue('valueField', newDispCodeValue);
  };

  const onChangeStatusValue = (newStatusValue: any) => {
    setStatusSelected(newStatusValue);
    if (INSPCODE) {
      valuePartFieldsGroupForInspCode.setFormValue('statusField', newStatusValue);
    }
    else if (DISPCODE) {
      valuePartFieldsGroupForDispCode.setFormValue('statusField', newStatusValue);
    }
  };

  const onChangeTDValue = (newTDValue: any) => {
    setTdSelected(newTDValue);
    valuePartFieldsGroupForInspCode.setFormValue('subKeysField', newTDValue);
  };

  useEffect(() => {
    if(isOpen)
    {
      fetchMaintainControlValues();
      getDisposition();
    }
    else setShouldPreventFirstDataGridLoading(true);
  }, [isOpen]);

  useEffect(() => {
    if(controlValue && isOpen)
    {
      setValueControlSelected(controlValue);
    }
  }, [controlValue, isOpen]);

  useEffect(() => {
    if(valueControlSelected && isOpen){
      if (INSPCODE) {
        setHeadCells(controlValuePartHeadCellsForInspCode);
      }
      else if (DISPCODE) {
        setHeadCells(controlValuePartHeadCellsForDispCode);
      }
      else {
        setHeadCells(controlValuePartHeadCells);
      }
      fetchData(valuePartListPagination, { sectionName: valueControlSelected?.name }, valuePartListSortOrder);
    }
  }, [valueControlSelected]);

  useEffect(() => {
    delayedFetchMaintainControlValue(valueControlAutocompleteText);
  }, [valueControlAutocompleteText]);

  useEffect(() => {
    if (valuePartSelected.length == 1) {
      if (INSPCODE) {
        valuePartFieldsGroupForInspCode.setFormValue('keyField', valuePartSelected[0]?.key);
        valuePartFieldsGroupForInspCode.setFormValue('valueField', valuePartSelected[0]?.value);
        valuePartFieldsGroupForInspCode.setFormValue('subKeysField', valuePartSelected[0]?.subKeys);
        valuePartFieldsGroupForInspCode.setFormValue('descriptionField', valuePartSelected[0]?.description);
        valuePartFieldsGroupForInspCode.setFormValue('statusField', valuePartSelected[0]?.status);
      }
      else if (DISPCODE) {
        valuePartFieldsGroupForDispCode.setFormValue('keyField', valuePartSelected[0]?.key);
        valuePartFieldsGroupForDispCode.setFormValue('descriptionField', valuePartSelected[0]?.description);
        valuePartFieldsGroupForDispCode.setFormValue('statusField', valuePartSelected[0]?.status);
      }
      else {
        valuePartFieldsGroup.setFormValue('keyField', valuePartSelected[0]?.key);
        valuePartFieldsGroup.setFormValue('valueField', valuePartSelected[0]?.value);
        valuePartFieldsGroup.setFormValue('subKeysField', valuePartSelected[0]?.subKeys);
        valuePartFieldsGroup.setFormValue('descriptionField', valuePartSelected[0]?.description);
      }
    }
    else {
      if (INSPCODE) { 
        setDispCodeSelected('');
        setStatusSelected('');
        setTdSelected('');
        valuePartFieldsGroupForInspCode.cleanFormData(); 
      }
      else if (DISPCODE) {
        setStatusSelected(''); 
        valuePartFieldsGroupForDispCode.cleanFormData(); 
      }
      else { valuePartFieldsGroup.cleanFormData(); } 
    }
  }, [valuePartSelected]);

  const isValuePartFieldsGroupValid = INSPCODE ? valuePartFieldsGroupForInspCode.getFieldFormValue('keyField') : 
    DISPCODE ? valuePartFieldsGroupForDispCode.getFieldFormValue('keyField') : valuePartFieldsGroup.getFieldFormValue('keyField');

  return (  
    <>
      <Modal
        key={id}
        isOpen={isOpen}
        onDismiss={onDismiss}
        containerClassName={styles.modalContainer}
      >
        <div className='modalHeader'>
          <Text variant='xLarge'>Edit Control Values</Text>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel='Close popup modal'
            onClick={onDismiss}
          />
        </div>
        <div className={styles.controlValueDetails}>
          <AutoCompleteField
            label="Select:"
            className={styles.controlValuesList}
            onChangeAction={onChangeSearchControlValue}
            initialValue={valueControlSelected?.name}
            list={
              valueControlList ?
                valueControlList.map(({ id, name }) => ({
                  key: id,
                  text: name,
                })) : []
            }
            textValue={setValueControlAutocompleteText}
            isLoading={valueControlAutocompleteLoading}
          />
          <SeparatorGy vertical/>
          <div>
            <div className={styles.controlValueDetails}>
              <TextField 
                label="Created By"
                className={styles.controlValueField}
                value={valueControlSelected?.createdBy}
                disabled 
              />
              <TextField 
                label='On'
                className={styles.controlValueField}
                value={moment(valueControlSelected?.createdOn).format('MM/DD/YYYY')}
                disabled 
              />
            </div>
            <div className={styles.controlValueDetails}>
              <TextField 
                label="Last Modified By"
                className={styles.controlValueField}
                value={valueControlSelected?.lastModifiedBy}
                disabled 
              />
              <TextField 
                label='On'
                className={styles.controlValueField}
                value={moment(valueControlSelected?.lastModifiedOn).format('MM/DD/YYYY')}
                disabled 
              />
            </div>
          </div>
        </div>
        <div className='tableContainer'>
          <DataGridComponent
            idTable={'lookup-table'}
            title='Lookup Values'
            headCells={headCells}
            rowsTable={valuePartList}
            totalDataFound={totalFound}
            isLoading={isLoadingTable}
            enableCheckBox
            enableSearching
            enablePagination
            enableRowsPerPage
            enableDeleteOption
            enableMultiSelectRow
            handleChangeDataGridState={handleChangeDataGridState}
            handleSelectRow={handleSelectRow}
            handleDelete={handleDeleteValue}
          />
        </div>
        {
          (!INSPCODE && !DISPCODE) &&
          <div className={styles.formContainer}>
            <TextField {...valuePartFieldsGroup.getFieldForm('keyField')} />
            <TextField {...valuePartFieldsGroup.getFieldForm('valueField')} />
          </div>
        }
        
        {
          INSPCODE &&
          <div className={styles.formContainer}>
            <TextField {...valuePartFieldsGroupForInspCode.getFieldForm('keyField')} />
            <TextField className={styles.largeField} {...valuePartFieldsGroupForInspCode.getFieldForm('descriptionField')} />
          </div>
        }

        {
          DISPCODE &&
          <div className={styles.formContainer}>
            <TextField {...valuePartFieldsGroupForDispCode.getFieldForm('keyField')} />
            <TextField className={styles.largeField} {...valuePartFieldsGroupForDispCode.getFieldForm('descriptionField')} />
          </div>
        }

        <div className={styles.formContainer}>
          {(!INSPCODE && !DISPCODE) && <TextField className={styles.largeField} {...valuePartFieldsGroup.getFieldForm('descriptionField')} />}
          {(!INSPCODE && !DISPCODE) &&  <TextField className={styles.largeField} {...valuePartFieldsGroup.getFieldForm('subKeysField')} />}

          {INSPCODE && 
          <AutoCompleteField
            label="Disposition Code:"
            className={styles.controlValuesList}
            onChangeAction={onChangeDispCodeValue}
            initialValue={dispCodeSelected}
            list={
              disposition ?
                disposition.map(({ code, description }) => ({
                  key: code,
                  text: `${code} - ${description}`,
                })) : []
            }
            textValue={setDispCodeControlAutocompleteText}
            isLoading={valueControlAutocompleteLoading}
          />
          }

          {INSPCODE &&           
          <Dropdown
            options={tdRequiredOptions.map((item: any) => ({
              key: item,
              text: item,
            }))}
            id="subKeysField"
            label='TD Required?'
            selectedKey={tdSelected}
            onChange={(e, { key: td }: any) => onChangeTDValue(td)}
          />
          
          }

          {INSPCODE &&                 
          <Dropdown
            options={statusOptions.map((item: any) => ({
              key: item,
              text: item,
            }))}
            id="statusField"
            label='Status'
            selectedKey={statusSelected}
            onChange={(e, { key: status }: any) => onChangeStatusValue(status)}
          />
          }
          
          {DISPCODE &&           
          <Dropdown
            options={statusOptions.map((item: any) => ({
              key: item,
              text: item,
            }))}
            id="statusField"
            label='Status'
            selectedKey={statusSelected}
            onChange={(e, { key: status }: any) => onChangeStatusValue(status)}
          />}

          <PrimaryButton
            id='addBtn'
            onClick={toggleSavingConfirmation}
            disabled={!(valuePartSelected.length !== 1 && isValuePartFieldsGroupValid)}
            text='Add'
          />
          <PrimaryButton
            id='updateBtn'
            onClick={toggleSavingConfirmation}
            disabled={!(valuePartSelected.length === 1 && isValuePartFieldsGroupValid)}
            text='Update'
          />
        </div>     
        <div className={styles.modalFooter}>
          <DefaultButton
            id='cancelButton'
            text={cancelLabel}
            onClick={onDismiss}
          />
        </div>
        <div>
          {isLoading && <LoadingScreen />}
          <DialogComponent
            isOpen={isSavingDialogVisible}
            onCancel={toggleSavingConfirmation}
            onSubmit={valuePartSelected.length === 1 ? onUpdateControlValuePart : onAddControlValuePart}
            title={'Confirmation'}
            subText={`Are you sure of this ${valuePartSelected.length === 1 ? 'update' : 'adding'}? Your change will be saved immediately.`}
            onSubmitLabel={'Yes'}
            onCancelLabel={'No'}
          />
        </div>
      </Modal>
    </>
  );
};
 
export default EditControlValuesModalComponent;