import { HeadCell, ISorting } from '../../../../../../shared/DataGridComponent/DataGridModels';

export const pageSizes = [100, 250, 500];

export const orderErrorsHeadCells: HeadCell[] = [
  {
    key: 'column0',
    name: 'Error ID',
    fieldName: 'postTableId',
    typeField: 'text',
    isEditable: false,
    disableSorting: true,
  },
  {
    key: 'column1',
    name: 'Error Type',
    fieldName: 'type',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Error Description',
    fieldName: 'description',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Additional Info',
    fieldName: 'additionalInfo',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'Date',
    fieldName: 'date',
    typeField: 'text',
    isEditable: false,
  },
];

export const defaultSorting: ISorting = {
  column: orderErrorsHeadCells[1].fieldName,
  order: 'asc',
};