import { FC, FormEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useBoolean } from '@fluentui/react-hooks';
import { differenceWith, get, isEmpty, isEqual, isNil } from 'lodash';
import {
  Checkbox,
  DefaultButton,
  Dropdown,
  IColumn,
  IconButton,
  IDropdownOption,
  MessageBarType,
  PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';

import useNotifications from '../../../../../../../../hooks/useNotifications';
import apiService from '../../../../../../../../api';
import { accessTypes } from '../../../../../../../../consts/accessTypes';
import { sortOrder } from '../../../../../../../../consts/sortOrder';

import SeparatorGy from '../../../../../../../SeparatorGy/SeparatorGy';
import PrintingModal from '../../../../../../../PrintingModal/PrintingModal';
import LoadingScreen from '../../../../../../../LoadingScreen/LoadingScreen';
import Pagination from '../../../../../../../Pagination/Pagination';
import { IPaginationProps } from '../../../../../../../Pagination/IPaginationProps';
import { downloadFile, printingTypes } from '../../../../../../../PrintingModal/consts';

import { IPermissionsSubtabProps } from './IPermissionsSubtabProps';
import { IPermissionsSubtabState } from './IPermissionsSubtabState';
import { tableColumns, emptyFilters, allOption, pageSizes } from './consts';
import { IFiltersState } from './IFiltersState';

import styles from './PermissionsSubtab.module.scss';

const PermissionsSubtab: FC<IPermissionsSubtabProps> = ({ roleId, roleName }) => {

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const { addNotification } = useNotifications();

  const isRoleNameAdmininistrator = (roleName === 'Administrator');

  const [state, setState] = useState<IPermissionsSubtabState>({
    items: [],
    originItems: [],
    modulesList: [],
    loading: false,
    foundCount: 0,
  });
  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(tableColumns);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [isFirstLoading, { setFalse: wasFirstLoading }] = useBoolean(true);
  const [filtersState, setFiltersState] = useState<IFiltersState>(emptyFilters);

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const fetch = async () => {
    setState((prev: IPermissionsSubtabState) => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.management.getRolePermissions(
        roleId,
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        {
          searchString: filtersState.searchString,
          modules: filtersState.module[0] === allOption.key ? [] : filtersState.module,
          ...([allOption.key, accessTypes.WRITE_ACCESS].includes(filtersState.access[0]) ? {} : { isRead: true }),
          ...([allOption.key, accessTypes.READ_ACCESS].includes(filtersState.access[0]) ? {} : { isWrite: true }),
        },
      );
      const foundCount = data.total.found;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, originItems: items, selectedItem: null, foundCount }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching permissions error: ${get(response, 'data.message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleSearch = () => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    fetch();
  };

  const handleSave = async () => {
    setState((prev: IPermissionsSubtabState) => ({ ...prev, loading: true }));
    try {
      const updatedPermissions = state.items
        .filter((item, index) => !isEqual(item, state.originItems[index]))
        .map(({ application, moduleName, ...other }: any) => ({ ...other, isAccess: other.isRead }));

      await apiService.management.updateRolePermissions(
        roleId,
        updatedPermissions,
      );
      addNotification({
        text: 'Permissions were successfully updated',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Updating permissions error: ${get(response, 'data.message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      fetch();
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        roleId,
        pagination: null,
        sortOrder,
        filters: {
          searchString: filtersState.searchString,
          modules: filtersState.module[0] === allOption.key ? [] : filtersState.module,
          ...([allOption.key, accessTypes.WRITE_ACCESS].includes(filtersState.access[0]) ? {} : { isRead: true }),
          ...([allOption.key, accessTypes.READ_ACCESS].includes(filtersState.access[0]) ? {} : { isWrite: true }),
        },
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.management.printRolePermissionsExcel(requestData) :
        await apiService.management.printRolePermissionsPdf(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Printing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleChangeSearchString = (event: any, searchString = '') => {
    setFiltersState((prev: IFiltersState) => ({ ...prev, searchString }));
  };

  const onChangePermission = (field: any, itemToBeUpdated: any, value: any) => {
    setState((prev: any) => ({
      ...prev,
      items: prev.items.map(({ applicationId, applicationMatrixId, moduleId, ...other }: any) => ({
        applicationId,
        applicationMatrixId,
        moduleId,
        ...other,
        [field]: applicationId === itemToBeUpdated.applicationId &&
          applicationMatrixId === itemToBeUpdated.applicationMatrixId &&
          moduleId === itemToBeUpdated.moduleId ? value : other[field],
      }),
      ),
    }));
  };

  const onChangeFilters = (item: any, filter: string, totalCount: number): void => {
    if (item && item.key === allOption.key) {
      setFiltersState((prev: IFiltersState) => ({ ...prev, [filter]: [item.key] }));
    } else if (item) {
      const allPos = get(filtersState, filter, []).indexOf(allOption.key);
      const prevArray = get(filtersState, filter, []);
      const prevArrayWithoutAll = prevArray.filter((m: any, i: number) => i !== allPos);
      setFiltersState((prev: IFiltersState) => ({
        ...prev,
        [filter]: item.selected ?
          [...(prevArrayWithoutAll.length === totalCount - 1 ? [allOption.key] : [item.key, ...prevArrayWithoutAll])] :
          [...prevArray.filter((key: any) => key !== item.key), ...(prevArray.length === 1 ? [allOption.key] : [])],
      }));
    }
  };

  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const handleRollback = (itemToBeRolledBack?: any) => {
    setState((prev: IPermissionsSubtabState) => ({
      ...prev,
      items: !isNil(itemToBeRolledBack) ?
        prev.items.map((item: any) => (
          itemToBeRolledBack?.applicationId === item.applicationId &&
          itemToBeRolledBack?.applicationMatrixId === item.applicationMatrixId &&
          itemToBeRolledBack?.moduleId === item.moduleId
        ) ?
          prev.originItems.find(({ applicationId, applicationMatrixId, moduleId }: any) =>
            applicationId === item.applicationId &&
            applicationMatrixId === item.applicationMatrixId &&
            moduleId === item.moduleId,
          ) :
          item,
        ) :
        prev.originItems,
    }));
  };

  const fetchModules = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await apiService.management.getModules();
      setState((prev) => ({ ...prev, modulesList: data.data }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching modules error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchAll = async () => {
    await fetchModules();
    await fetch();
    wasFirstLoading();
  };

  useEffect(() => {
    if (!isNil(roleId)) {
      if (isFirstLoading) {
        fetchAll();
      } else {
        fetch();
      }
    }
  }, [
    roleId,
    columnsState,
    countOnPage.key,
    paginationProps.current,
  ]);

  return (
    <>
      <div className="ms-Grid">
        <div className={classNames('ms-Grid-row', styles.mainRow)}>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <div className={styles.tabFiltersBlock}>
              <div>
                <TextField
                  id="searchString"
                  value={filtersState.searchString}
                  label="Search"
                  placeholder="Enter search string"
                  onChange={handleChangeSearchString}
                />
                <Dropdown
                  label="Filter By Module"
                  selectedKeys={filtersState.module}
                  onChange={(ev: any, item: any) => onChangeFilters(item, 'module', state.modulesList.length)}
                  options={[allOption, ...state.modulesList.map((v: any) => ({ key: v.id, text: v.name }))]}
                  className={styles.dropdown}
                  multiSelect
                />
                <Dropdown
                  label="Filter By Access"
                  selectedKeys={filtersState.access}
                  onChange={(ev: any, item: any) => setFiltersState((prev: IFiltersState) => ({ ...prev, access: [item.key] }))}
                  options={[allOption, ...Object.values(accessTypes).map((v: any) => ({ key: v, text: v }))]}
                  className={styles.dropdown}
                  multiSelect
                />
                <IconButton
                  iconProps={{ iconName: 'Search' }}
                  onClick={handleSearch}
                />
              </div>
              <div>
                <DefaultButton
                  text="Discard Changes"
                  disabled={isEmpty(differenceWith(state.items, state.originItems, isEqual)) || isRoleNameAdmininistrator}
                  onClick={() => handleRollback()}
                />
                <PrimaryButton
                  text="Save Changes"
                  disabled={isEmpty(differenceWith(state.items, state.originItems, isEqual)) || isRoleNameAdmininistrator}
                  onClick={handleSave}
                />
              </div>
            </div>
            <div className={styles.tableHeading}>
              <div>
                <Text variant="xLarge">Permission Groups</Text>
              </div>
              <div>
                <Text variant="large">Show # of rows:&nbsp;</Text>
                <Dropdown
                  options={pageSizes.map(pageSize => ({
                    key: pageSize,
                    text: pageSize.toString(),
                  }))}
                  defaultSelectedKey={pageSizes[0]}
                  selectedKey={countOnPage?.key}
                  onChange={onChangeCountOnPage}
                />
                <SeparatorGy vertical />
                <Text variant="xLarge">{state.foundCount} found</Text>
              </div>
            </div>
            <div className={styles.tableWrapper}>
              <table>
                <thead>
                  <tr>
                    {
                      columnsState.map(item => (
                        <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                      ))
                    }
                    <th />
                  </tr>
                </thead>
                <tbody className={styles.tableWrapper}>
                  {
                    state.items.map((item: any) => {
                      return (
                        <tr key={item.id}
                          className={styles.trBasic}
                        >
                          <td>{item.application.split('.')[0]}</td>
                          <td>{item.application.split('.')[1]}</td>
                          <td>{(isNil(item.application.split('.')[2]) ? '' : item.application.split('.')[2])}</td>
                          <td>
                            <Checkbox
                              onChange={(ev: any, value) => onChangePermission('isRead', item, value)}
                              checked={item.isRead}
                              disabled={isRoleNameAdmininistrator}
                            />
                          </td>
                          <td>
                            <Checkbox
                              onChange={(ev: any, value) => onChangePermission('isWrite', item, value)}
                              checked={item.isWrite}
                              disabled={isRoleNameAdmininistrator}
                            />
                          </td>
                          <td className={styles.cancelCell}>
                            <IconButton
                              iconProps={{ iconName: 'Cancel' }}
                              onClick={() => handleRollback(item)}
                              disabled={isEqual(
                                item,
                                state.originItems.find(({ applicationId, applicationMatrixId, moduleId }: any) =>
                                  applicationId === item.applicationId &&
                                  applicationMatrixId === item.applicationMatrixId &&
                                  moduleId === item.moduleId,
                                ),
                              )}
                            />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
            {state.foundCount > countOnPage?.key && (
              <>
                <SeparatorGy />
                <Pagination {...paginationProps} />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="ms-Grid-row">
        <div className={classNames('ms-Grid-col', 'ms-sm12', styles.buttonsWrapper)}>
          <DefaultButton onClick={toggleShowPrintExport} text="Print/Export" />
        </div>
      </div>

      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default PermissionsSubtab;
