import { FC, useState } from 'react';
import { IHistoryAndMessagesProps } from './IHistoryAndMessagesProps';
import { IHistoryAndMessagesState } from './IHistoryAndMessagesState';
import apiService from '../../../../../../api';
import useNotifications from '../../../../../../hooks/useNotifications';
import { MessageBarType, Text } from '@fluentui/react';
import DataGridComponent from '../../../../../../shared/DataGridComponent';
import { defaultSorting, orderErrorsHeadCells, pageSizes } from './consts';
import styles from './HistoryAndMessages.module.scss';
import { get } from 'lodash';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';


const HistoryAndMessages: FC<IHistoryAndMessagesProps> = ({ orderToBeAdded }) => {
  const [state, setState] = useState<IHistoryAndMessagesState>({
    items: [],
    loading: false,
    foundCount: 0,
  });

  const { addNotification } = useNotifications();

  const fetchErrors = async (pagination: any, sortOrder: any) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.tireOrders.getErrors(
        pagination,
        sortOrder,
        { orderId: orderToBeAdded.id },
      );
      const foundCount = data.total.found;
      setState(prev => ({ ...prev, items: data.data, foundCount }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Errors fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleChangeItemsDataGridState = async (dataGridState: any) => {
    const { countOnPage, paginationProps, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    if (orderToBeAdded.id) fetchErrors(pagination, sortOrder);
  };

  return (
    <>
      <div className={styles.infoBlock}>
        <div>
          <div>
            <Text variant="large" className={styles.highlight}>{get(state, 'order.createdBy', 'N/A')}</Text>
            <Text variant="medium" className={styles.highlight}>Created By</Text>
          </div>
          <div>
            <Text variant="large" className={styles.highlight}>{get(state, 'order.createdOn', 'N/A')}</Text>
            <Text variant="medium" className={styles.highlight}>Created On</Text>
          </div>
          <div>
            <Text variant="large" className={styles.highlight}>{get(state, 'order.lastModifiedBy', 'N/A')}</Text>
            <Text variant="medium" className={styles.highlight}>Last Modified By</Text>
          </div>
          <div>
            <Text variant="large" className={styles.highlight}>{get(state, 'order.lastModifiedOn', 'N/A')}</Text>
            <Text variant="medium" className={styles.highlight}>Last Modified On</Text>
          </div>
        </div>
      </div>
      <DataGridComponent
        idTable={'available-line-items-table'}
        title='Order Errors'
        defaultSorting={defaultSorting}
        headCells={orderErrorsHeadCells}
        rowsTable={state.items}
        totalDataFound={state.foundCount}
        handleChangeDataGridState={handleChangeItemsDataGridState}
        isLoading={state.loading}
        defaultRowsPerPage={pageSizes}
        enablePagination
        enableRowsPerPage
      />   
      {state.loading && <LoadingScreen />}
    </>
  );
};
  
export default HistoryAndMessages;