import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  selectedQuote: any,
} = {
  selectedQuote: null,
};

const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    setSelectedQuote: (state, action) => {
      state.selectedQuote = action.payload;
    },
  },
});

export const {
  setSelectedQuote,
} = quoteSlice.actions;

export const selectedQuoteSelector = (state: any) => state.quote.selectedQuote;

export default quoteSlice.reducer;
