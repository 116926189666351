import { DefaultButton, Icon, MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import classNames from 'classnames';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import DataGridComponent from '../../../../shared/DataGridComponent';
import { ACTION_GROUPS_ENUM } from '../../../../shared/DataGridComponent/ActionsComponent/ActionsGroupEnum';
import { FormBuilderGroup } from '../../../../shared/FormBuilderComponent';
import { availableQuotesHeadCell, CUSTOMER_FIELDS } from './consts';
import styles from './ViewTireSales.module.scss';
import { useBoolean } from '@fluentui/react-hooks';
import AutoCompleteField from '../../../Common/Search/AutoCompleteField';
import apiService from '../../../../api';
import { ICustomer } from '../../../Common/Search/ICustomer';
import { debounce } from 'lodash';
import { INotification } from '../../../../models/INotification';
import { IQuotes } from '../../../../models/IQuotes';
import useNotifications from '../../../../hooks/useNotifications';
import ViewMaintainTireSalesModalComponent from '../../../../shared/ViewMaintainTireSalesModalComponent';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { auth_billing_soldLostTireQuotesBilling } from '../../../../consts/programKeys';
import PrintingModal from '../../../PrintingModal/PrintingModal';
import { downloadFile, printingTypes } from '../../../PrintingModal/consts';
import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import { defaultDataGridPageSizes } from '../../../../shared/DataGridComponent/utils';


interface ViewTireSalesComponentProps {
  value?: any;
}

const ViewTireSalesComponent: FunctionComponent<ViewTireSalesComponentProps> = () => {

  //hooks
  const { addNotification } = useNotifications();

  const [availableQuotes, setavailableQuotes] = useState<Array<IQuotes>>([]);
  const [totalFound, setTotalFound] = useState(0);
  const [availableQuotesSelected, setAvailableQuotesSelected] = useState<Array<any>>([]);
  const [customersList, setCustomerList] = useState<ICustomer[]>([]);
  const [customerSelected, setCustomerSelected] = useState<any>();
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });
  const [dataGridState, setdataGridState] = useState<any>({});

  //toggles
  const [isLoadingAvailableTable, { toggle: toggleShowLoadingAvailableTable }] = useBoolean(false);
  const [isLoadingFetchCustomers, { toggle: toggleIsLoadingFetchCustomers }] = useBoolean(false);
  const [reloadControlsTable, { toggle: toggleReloadControlsTable }] = useBoolean(false);
  const [isModalCreateQuoteOpen, { toggle: toggleIsModalCreateQuoteOpen }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);


  //permission
  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_billing_soldLostTireQuotesBilling);

  const customerFormFieldsGroup = new FormBuilderGroup(CUSTOMER_FIELDS);

  const fetchAvailableQuotes = async (
    pagination: any = { pageSize: defaultDataGridPageSizes[0], pageNumber: 1 },
    sortOrder: any = { column: availableQuotesHeadCell[0].fieldName, order: 'asc' },
    filters?: any,
  ) => {
    try {
      setavailableQuotes([]);
      setdataGridState({ pagination, sortOrder, filters });
      const { data }: any = await apiService.billingAPI.getAvailableQuotesByCustomer(
        pagination,
        sortOrder,
        filters,
        customerSelected?.id,
      );
      setavailableQuotes(data?.data);
      setTotalFound(data?.total?.found);
    } catch (error) {
      setNotification({ text: 'Fetching tires error', type: MessageBarType.error });
    }
  };

  const fetchDeleteQuotes = async (ids: Array<string>) => {
    try {
      await apiService.billingAPI.deleteQuotes(ids);
      setNotification({ text: `${ids.length} quotes deleted successfully`, type: MessageBarType.success });
    } catch (error) {
      setNotification({ text: 'Deleting quotes error', type: MessageBarType.error });
    }
  };

  const fetchCustomers = async (
    filter?: any, 
    pagination?: any, 
    sort: any = { column: 'custPrefix', order: 'asc' }) => {
    try {
      toggleIsLoadingFetchCustomers();
      const { data }: any = await apiService.getCustomerList(filter, pagination, sort);
      setCustomerList(data.data);
      toggleIsLoadingFetchCustomers();
    } catch (e: any) {
      setNotification({ text: 'Customers fetching error.', type: MessageBarType.error });
    }
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    toggleShowLoadingAvailableTable();
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      filterText: searchedText,
    };
    if (customerSelected) await fetchAvailableQuotes(pagination, sortOrder, filters);
    toggleShowLoadingAvailableTable();
  };

  const handleSelectRow = (invoicesSelected: Array<any>) => setAvailableQuotesSelected(invoicesSelected);

  const handleDeleteQuotes = async (rows: any[] = []) => {
    toggleShowLoadingAvailableTable();
    const quotesIds = getIdsFromQuotes(availableQuotesSelected);
    await fetchDeleteQuotes(quotesIds);
    toggleShowLoadingAvailableTable();
  };

  const handleChangeCustomerSelected = async () => {
    toggleShowLoadingAvailableTable();
    const { customerNumber, regionCode } = customerSelected;
    customerFormFieldsGroup.setFormValue('customerNumberField', customerNumber);
    customerFormFieldsGroup.setFormValue('regionField', regionCode);
    await fetchAvailableQuotes();
    toggleShowLoadingAvailableTable();
  };

  const handleCloseCreateQuote = async () => {
    toggleIsModalCreateQuoteOpen();
    await fetchAvailableQuotes();
  };

  const handlePrint = async (printingType: any) => {
    toggleShowPrintExport();
    toggleShowLoadingAvailableTable();
    try {
      const { pagination, sortOrder, filters } = dataGridState;
      const requestData = {
        input: {
          pagination,
          sortOrder,
          filters,
          customerId: customerSelected?.id,
        },
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.billingAPI.printExcelViewTireSales(requestData) :
        await apiService.billingAPI.printPdfViewTireSales(requestData);

      downloadFile(data, printingType);
      setNotification({ text: 'File was successfully received.', type: MessageBarType.success });
    } catch (e: any) {
      const { response } = e;
      setNotification({ text: `Printing error: ${response.data.message}`, type: MessageBarType.error });
    } finally {
      toggleShowLoadingAvailableTable();
    };
  };

  const removeEmpty = ({ text }: any) => text !== null;

  const getCustomerPrefixList = useMemo(() => customersList ?
    customersList.map(({ id, prefix }) => ({
      key: id,
      text: prefix,
    })).filter(removeEmpty) : [], [customersList]);


  const getCustomerNameList = useMemo(() => customersList ?
    customersList.map(({ id, customerName }) => ({
      key: id,
      text: customerName,
    })).filter(removeEmpty) : [], [customersList]);

  const customerPrefixInputText = (custPrefix: string) => {
    if (custPrefix.replace(/ /g, '').length) {
      getCustomersDebounce({ custPrefix });
    }
  };

  const customerNameInputText = (custName: string) => {
    if (custName.replace(/ /g, '').length) {
      getCustomersDebounce({ custName }, { column: 'customerName', order: 'asc' });
    }
  };

  const getCustomersDebounce = debounce(async (customerData, sortOrder?) => {
    fetchCustomers(customerData, undefined,  sortOrder);
  }, 500);

  const onCustomerChange = async (newCustomerId: any) => {
    setCustomerSelected(getCustomerById(newCustomerId));
  };


  //handleErrorSelection

  useEffect(() => {
    if (customerSelected) handleChangeCustomerSelected();
  }, [customerSelected]);

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);


  const getIdsFromQuotes = (invoicesArray: Array<any>) => invoicesArray?.map((invoice: any) => invoice.quoteId);
  const getCustomerById = (idToFind: number) => customersList?.find(({ id }) => id === idToFind) || null;

  return (
    <>
      <div className={classNames('ms-grid', styles.marginTop20)} dir='ltr'>
        <div className={classNames('ms-Grid-row')}>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
            <Text variant='xxLarge' className={styles.highlight}>Sold & Lost Tire Sales Quote/Billing</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
            <Text variant='xLarge' className={styles.highlight}>Customer Details</Text>
            <SeparatorGy />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm10', styles.marginTop20)}>
            <div className={classNames('ms-Grid-col', styles.customerSearchContainer)}>
              <AutoCompleteField
                onChangeAction={onCustomerChange}
                value={customerSelected?.id}
                list={getCustomerPrefixList}
                label="Customer Prefix"
                data-testid='customer-prefix'
                icon="FavoriteStar"
                textValue={customerPrefixInputText}
                isLoading={isLoadingFetchCustomers}
              />
              <Icon iconName='Search' className={styles.searchIcon} />
            </div>
            <div className={classNames('ms-Grid-col', styles.customerSearchContainer)}>
              <AutoCompleteField
                onChangeAction={onCustomerChange}
                value={customerSelected?.id}
                list={getCustomerNameList}
                label="Customer Name"
                data-testid='customer-name'
                icon="Contact"
                textValue={customerNameInputText}
                isLoading={isLoadingFetchCustomers}
              />
              <Icon iconName='Search' className={styles.searchIcon} />
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm3')}>
              <TextField {...customerFormFieldsGroup.getFieldForm('customerNumberField')} />
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm3')}>
              <TextField {...customerFormFieldsGroup.getFieldForm('regionField')} />
            </div>
          </div>
        </div>
      </div>

      <div className={classNames('ms-grid', 'table-container')} dir='ltr'>
        <div className={classNames('ms-Grid-row')}>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
            <DataGridComponent
              idTable={'available-quotes'}
              title='Available Quotes'
              headCells={availableQuotesHeadCell}
              rowsTable={availableQuotes}
              totalDataFound={totalFound}
              isLoading={isLoadingAvailableTable}
              actionsGroupName={ACTION_GROUPS_ENUM.VIEW_TIRE_SALES}
              enableCheckBox={true}
              enableMultiSelectRow={true}
              enablePagination={true}
              enableRowsPerPage={true}
              enableDeleteOption={userPermissions.isWrite}
              enableResizeColumns={true}
              handleChangeDataGridState={handleChangeDataGridState}
              handleDelete={handleDeleteQuotes}
              handleSelectRow={handleSelectRow}
            />
          </div>
        </div>
      </div>

      <div className="ms-Grid-row">
        <div className={classNames('ms-Grid-col', 'ms-sm12')}>
          <div className={styles.buttonContainer}>
            <DefaultButton
              id='printExportBtn'
              text='Print/Export'
              onClick={toggleShowPrintExport}
            />
            <PrimaryButton
              id='maintainQuoteBtn'
              text='Create Quote'
              onClick={toggleIsModalCreateQuoteOpen}
              disabled={!userPermissions.isWrite}
            />
          </div>
        </div>
      </div>

      <ViewMaintainTireSalesModalComponent
        id='tireSalesModal'
        parentCustomerId={customerSelected?.id}
        isOpen={isModalCreateQuoteOpen}
        title='View Tire Sales Quote'
        onCancel={handleCloseCreateQuote}
        onSubmit={handleCloseCreateQuote}
        actionType='create'
      />

      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
    </>
  );
};

export default ViewTireSalesComponent;