import { HeadCell } from '../../../../../shared/DataGridComponent/DataGridModels';

const contactsHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Name',
    fieldName: 'name',
    typeField: 'text',
    isEditable: true,
    isMandatory: true,
  },
  {
    key: 'column1',
    name: 'Title',
    fieldName: 'title',
    typeField: 'text',
    isEditable: true,
  },
  {
    key: 'column2',
    name: 'Email',
    fieldName: 'email',
    typeField: 'text',
    isEditable: true,
  },
  {
    key: 'column3',
    name: 'Phone Office',
    fieldName: 'phone1',
    typeField: 'text',
    isEditable: true,
  },
  {
    key: 'column4',
    name: 'Phone Mobile',
    fieldName: 'phone2',
    typeField: 'text',
    isEditable: true,
  },
  {
    key: 'column5',
    name: 'Fax',
    fieldName: 'fax',
    typeField: 'text',
    isEditable: true,
  },
];

export {
  contactsHeadCell,
};