import { Dropdown, IconButton, TextField, Text, IDropdownStyles, MessageBarType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { FC, useEffect, useState } from 'react';
import AutocompleteInput from '../../../../../../../../shared/AutocompleteInput';
import PlantModalComponent from '../../../../../../../../shared/PlantSelectionModalComponent';
import { IOrderHeaderProps } from './IOrderHeaderProps';
import styles from './OrderHeader.module.scss';
import apiService from '../../../../../../../../api';
import useNotifications from '../../../../../../../../hooks/useNotifications';
import { IOrderHeaderState } from './IOrderHeaderState';
import { debounce } from 'lodash';
import LoadingScreen from '../../../../../../../LoadingScreen/LoadingScreen';

const OrderHeader: FC<IOrderHeaderProps> = ({
  plant,
  setPlant,
  plants,
  setPlants,
  orderToBeAdded, 
  setOrderToBeAdded,
  orderItemToBeAdded,
  setOrderItemToBeAdded,

}) => {
  const [state, setState] = useState<IOrderHeaderState>({
    billToSundres: [],
    plants: [],
    loading: false,
  });

  const { addNotification } = useNotifications();

  const [showPlantModal, { toggle: toggleShowPlantModal }] = useBoolean(false);

  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 150 } };

  const fetchBillToSundry = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.tireOrders.getBillToSundry();
      setState((prev: any) => ({ ...prev, billToSundres: data }));
      setOrderToBeAdded((prev: any) => ({ ...prev, billToSundry: data[0] }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching bill to sundry error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchPlant = async (plant?: string) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.plantWareHouseAPI.getAvailablePlants(
        null,
        { searchString: plant, type: 'RETREAD', active: true },
        {},
      );
      const items = data.data;
      setState((prev: any) => ({ ...prev, plants: items }));
      setPlants(items);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching plants error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const plantInputText = (plant: string) => {
    if (plant.length > 0) {
      getPlant(plant);
    }
  };
    
  const getPlant = debounce(async (plant) => {
    fetchPlant(plant);
  }, 1000);

  const handleSelectPlant = (rowSelected: any) => {
    setPlant(rowSelected.plantNo);
    setOrderItemToBeAdded((prev: any) => ({ ...prev, warehouse: rowSelected.id }));
    fetchPlant(rowSelected.plantNo);
    toggleShowPlantModal();
  };

  const onChangeOrderField = (field: any, value: any, regExp?: RegExp) => {
    setOrderToBeAdded((prev: any) => ({ ...prev, [field]: regExp ? (regExp.test(value) ? value : prev[field]) : value }));
  };

  const onChangeOrderItemField = (field: any, value: any, regExp?: RegExp) => {
    setOrderItemToBeAdded((prev: any) => ({ ...prev, [field]: regExp ? (regExp.test(value) ? value : prev[field]) : value }));
  };

  const onChangeOrderItemAutocompleteField = (field: any, value: any) =>
    setOrderItemToBeAdded((prev: any) => ({ ...prev, [field]: value }));

  useEffect(() => {
    setState((prev: any) => ({ ...prev, plants: plants }));
    fetchPlant();
    fetchBillToSundry();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <Text variant="xLarge" className={styles.highlight}>Order Header</Text>
        <div>
          <div>
            <TextField
              label="Order #"
              value={orderToBeAdded.id}
              disabled
            />
          </div>
        </div>
        <div>
          <div>
            <Dropdown
              label="Bill to Sundry"
              options={state.billToSundres.map((billToSundry, index) => ({
                key: index,
                text: billToSundry,
              }))}
              defaultSelectedKey={state.billToSundres.findIndex((billToSundre) => billToSundre === orderToBeAdded.billToSundry)}
              onChange={(e, { text: billToSundry }: any) => onChangeOrderField('billToSundry', billToSundry)}
              styles={dropdownStyles}
              required
            />
          </div>
          <div>
            <AutocompleteInput
              label="Retread plant"
              initialValue={plant}
              list={state.plants.map((plant: any) => ({
                key: plant.id,
                text: plant.plantNo,
              }))}
              emptyExpanded
              required
              chooseCurrentItem={(warehouse: any) => onChangeOrderItemAutocompleteField('warehouse', warehouse)}
              textValue={plantInputText}
              upperCase
            /> 
            <IconButton
              id='searchPrefix'
              onClick={toggleShowPlantModal}
              iconProps={{ iconName: 'Search' }}
            />
          </div>
          <div>
            <TextField
              label="Invoice"
              value={orderItemToBeAdded.invoiceId}
              onChange={(e, invoiceId: any) => onChangeOrderItemField('invoiceId', invoiceId)}
            />
          </div>
        </div>
      </div>
      <PlantModalComponent
        id='wareHouseModal'
        title='View Retread Details'
        type='RETREAD'
        isOpen={showPlantModal}
        onSubmit={handleSelectPlant}
        onDismiss={toggleShowPlantModal}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default OrderHeader;