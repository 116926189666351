

import { DatePicker, DateRangeType, Dropdown, IconButton, MessageBarType, Spinner } from '@fluentui/react';
import classNames from 'classnames';
import moment from 'moment';
import { FC, ReactElement, useEffect, useState } from 'react';
import apiService from '../../../../api';
import useNotifications from '../../../../hooks/useNotifications';
import DataGridComponent from '../../../../shared/DataGridComponent';
import { ACTION_GROUPS_ENUM } from '../../../../shared/DataGridComponent/ActionsComponent/ActionsGroupEnum';
import { reportFrequencies, reportTypes, REPORT_FREQUENCY, storedReportsHeadCell, defaultDataGridPageSizes } from './consts';
import styles from './StoredReports.module.scss';
import { useBoolean } from '@fluentui/react-hooks';


const StoredReports: FC = (): ReactElement => {

  const { addNotification } = useNotifications();

  const [reports, setReports] = useState([]);
  const [reportsFoundCount, setReportsFoundCount] = useState<number>(0);
  const [isFirstLoading, setFirstLoading] = useState<boolean>(true);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [reloadControlsTable, { toggle: toggleReloadControlsTable }] = useBoolean(false);
  const [defaultSortOrder, setDefaultSortOrder] = useState<any>({ column: storedReportsHeadCell[3].fieldName, order: 'desc' });

  const [filters, setFilters] = useState({ reportFrequency: null, date: undefined, reportType: null, period: null });

  const handleChangeDataGridState = async (dataGridState: any) => {
    const { countOnPage, paginationProps, sortOrder, searchedText } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    console.log(searchedText);
    await fetchReports(pagination, sortOrder, searchedText);
    setFirstLoading(false);
  };

  const onReportFrequencyChange = (_ev: any, option: any) => {
    setFilters((prev: any) => ({ ...prev, reportFrequency: option?.key, date: undefined, reportType: null, period: null }));
  };

  const onReportTypeChange = (_ev: any, option: any) => {
    setFilters((prev: any) => ({ ...prev, reportType: option?.key }));
  };

  const fetchReports = async (
    pagination: any = { pageSize: defaultDataGridPageSizes[0], pageNumber: 1 },
    sortOrder: any = { column: storedReportsHeadCell[3].fieldName, order: 'desc' },
    search?: string,
  ) => {
    try {
      setLoading(true);
      const { data }: any = await apiService.reporting.getStoredReports(
        pagination,
        {
          ...filters,
          period: filters.period ? moment(filters.period).format('MM/YY') : undefined,
          search,
        },
        sortOrder,
      );
      data.data.forEach((element:any) => {
        if (element.reportGroup === 'MonthEnd Report') {
          element.reportGroup = 'Month End Reports';
        }
      });
      setReports(data.data);
      setReportsFoundCount(data?.total?.found);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Reports fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const reportFrequenciesOptions = reportFrequencies?.map((item: any) => ({
    key: item,
    text: item,
  }));

  const reportTypeOptions = reportTypes?.map((item: any) => ({
    key: item,
    text: item,
  }));

  useEffect(() => {
    if (isFirstLoading) return;
    toggleReloadControlsTable();
  }, [
    filters.reportFrequency,
    filters.date,
    filters.reportType,
  ]);
  useEffect(() => {
    if (isFirstLoading) return;
    fetchReports();
  }, [
    filters.period,
  ]);

  return (
    <>
      <div className='ms-Grid-row'>
        <div className={classNames('ms-Grid-col', 'ms-sm12')}>
          <div className={styles.filters}>
            <div className={styles.field}>
              <Dropdown
                className={styles.mediumField}
                label="Report Frequency"
                options={reportFrequenciesOptions}
                defaultSelectedKey={filters.reportFrequency}
                onChange={onReportFrequencyChange}
              />
              {filters.reportFrequency && <IconButton
                id='clearReportFrequency'
                className={styles.iconButton}
                iconProps={{ iconName: 'Clear' }}
                onClick={() => setFilters((prev) => ({ ...prev, reportFrequency: null, date: undefined, reportType: null, period: null }))}
              />}
            </div>
            {filters.reportFrequency === REPORT_FREQUENCY.DAILY_REPORTS && <div className={styles.field}>
              <DatePicker
                label="Date"
                value={filters.date ? moment(filters.date).toDate() : undefined}
                onSelectDate={(date: Date | null | undefined) => setFilters((prev: any) => ({ ...prev, date: moment(date).format('MM/DD/YYYY') }))}
                formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
              />
              {filters.date && <IconButton
                id='clearDate'
                className={classNames(styles.iconButton, styles.clearCalendarBtn)}
                iconProps={{ iconName: 'Clear' }}
                onClick={() => setFilters((prev) => ({ ...prev, date: undefined }))}
              />}
            </div>}
            {filters.reportFrequency === REPORT_FREQUENCY.MONTH_END_REPORTS && <>
              <div className={styles.field}>
                <Dropdown
                  className={styles.mediumField}
                  label="Report Type"
                  options={reportTypeOptions}
                  defaultSelectedKey={filters.reportType}
                  onChange={onReportTypeChange}
                />
                {filters.reportType && <IconButton
                  id='clearReportType'
                  className={styles.iconButton}
                  iconProps={{ iconName: 'Clear' }}
                  onClick={() => setFilters((prev) => ({ ...prev, reportType: null }))}
                />}
              </div>
              <div className={styles.field}>
                <DatePicker
                  label="Period"
                  calendarProps={{
                    highlightSelectedMonth: true,
                    isDayPickerVisible: false,
                    dateRangeType: DateRangeType.Month,
                  }}
                  value={filters.period ? moment(filters.period).toDate() : undefined}
                  onSelectDate={(date: Date | null | undefined) => setFilters((prev: any) => ({ ...prev, period: moment(date).format('MM/DD/YYYY') }))}
                  formatDate={(date: any) => moment(date).format('MM/YY')}
                />
                {filters.period && <IconButton
                  id='clearPeriod'
                  className={classNames(styles.iconButton, styles.clearCalendarBtn)}
                  iconProps={{ iconName: 'Clear' }}
                  onClick={() => setFilters((prev) => ({ ...prev, period: null }))}
                />}
              </div> </>}
          </div>
          <DataGridComponent
            idTable={'reports-table'}
            title='Reports'
            headCells={storedReportsHeadCell}
            defaultRowsPerPage={defaultDataGridPageSizes}
            defaultSorting={defaultSortOrder}
            rowsTable={reports}
            totalDataFound={reportsFoundCount}
            actionsGroupName={ACTION_GROUPS_ENUM.VIEW_PDF_STOREDREPORTS}
            enablePagination
            enableRowsPerPage
            enableSearching
            isLoading={isLoading}
            reloadControlsTable={reloadControlsTable}
            handleChangeDataGridState={handleChangeDataGridState}
          />

        </div>
      </div>
    </>
  );
};

export default StoredReports;
