import { recordTestInstance, exportTestInstance  } from '../axios';

const get = (pagination: any, sortOrder: any, customerId: number, locationId: number, filters: any = { searchString: null } ) =>
  recordTestInstance.post('/flagged-as-damaged', { pagination, filters, sortOrder, customerId, locationId });

const deletePostFlaggedAsDamaged = (ids: Array<any>) =>
  recordTestInstance.delete('/flagged-as-damaged/delete', { data: { ids } });

const add = (data: any, customerId: string, locationId: string) =>
  recordTestInstance.post('/flagged-as-damaged/add', { ...data, customerId, locationId });

const update = (data: Array<any>, customerId: string, locationId: string) =>
  recordTestInstance.put('/flagged-as-damaged/save', { data, customerId, locationId });

const submit = (data: Array<any>, customerId: string, locationId: string) =>
  recordTestInstance.post('/flagged-as-damaged/submit', { data, customerId, locationId });

const getErrors = (pagination: any, sortOrder: any, customerId: string, locationId: string) =>
  recordTestInstance.post('/flagged-as-damaged/error', { pagination, sortOrder, customerId, locationId });

const printExcel = (flagAsDmgInput: any) =>
  exportTestInstance.post('/flagged-as-damaged/export-excel', { flagAsDmgInput }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (flagAsDmgInput: any) =>
  exportTestInstance.post('/flagged-as-damaged/export-pdf', { flagAsDmgInput }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  get,
  delete: deletePostFlaggedAsDamaged,
  add,
  update,
  submit,
  getErrors,
  printExcel,
  printPdf,
};