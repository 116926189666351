import { recordTestInstance, exportTestInstance } from '../axios';

const get = (pagination: any, filters: any, sortOrder: any, customerId: any, locationId: any) =>
  recordTestInstance.post('/registered-tire', { pagination, filters, sortOrder, customerId, locationId });

const getFootprints = () =>
  recordTestInstance.post('/registered-tire/footprints');

const getWheelPositionsByFootprintId = (footprintId: any, pagination: any, sortOrder: any) =>
  recordTestInstance.post('/registered-tire/whlpos-by-footprint-id', { footprintId, pagination, sortOrder });

const getModel = (customerId: any, make: any) =>
  recordTestInstance.post('/model', { customerId, make });

const getMake = (customerId: any) =>
  recordTestInstance.post('/make', { customerId });

const getWheels = (customerId: any, locationId: any) =>
  recordTestInstance.post('/wheels', { customerId, locationId });

const getStatus = (customerId: any, locationId: any) =>
  recordTestInstance.post('/status', { customerId, locationId });

const saveComments = (id: any, comments: string) =>
  recordTestInstance.post('/registered-tire/save-comments', { id, comments });

const reinstate = (vehicleId: any, regDate: any, customerId: any) =>
  recordTestInstance.post('/registered-tire/reinstate', { customerId, vehicleId, regDate });

const deleteRegisterVehicle = (id: any, vehNo: any, customerId: any) =>
  recordTestInstance.delete('/registered-tire', { data: { deleteModels: [{ id, vehNo, customerId }] } });

const unreg = (vehicleId: any, regDate: any, customerId: any) =>
  recordTestInstance.post('/registered-tire/unreg', { customerId, vehicleId, regDate });

const update = (data: any, customerId: any) =>
  recordTestInstance.put('/registered-tire', { ...data, customerId });

const add = (data: any, customerId: any, locationId: any) =>
  recordTestInstance.post('/registered-tire/add', { ...data, customerId, locationId });

const renumberTires = (
  oldPrefix: string,
  oldSuffix: string,
  oldVehNoFrom: number,
  oldVehNoTo: number,
  newPrefix: string,
  newSuffix: string,
  newVehNoFrom: number,
  newVehNoTo: number,
  renumberRange: number,
  customerId: string,
) =>
  recordTestInstance.post('/registered-tire/renumber', { oldPrefix, oldSuffix, oldVehNoFrom, oldVehNoTo, newPrefix, newSuffix, newVehNoFrom, newVehNoTo, renumberRange, customerId });

const relocateVehicle = (vehicleId: string, locCode: number, customerId: string) =>
  recordTestInstance.post('/registered-tire/relocate', { vehicleId, locCode, customerId });

const printExcel = (registeredVehicleInput: any, headerFields: Array<any>) =>
  exportTestInstance.post('/registered-tire/export-excel', { registeredVehicleInput, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (registeredVehicleInput: any, headerFields: Array<any>) =>
  exportTestInstance.post('/registered-tire/export-pdf', { registeredVehicleInput, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  get,
  getModel,
  getFootprints,
  getWheelPositionsByFootprintId,
  getMake,
  getWheels,
  getStatus,
  saveComments,
  delete: deleteRegisterVehicle,
  reinstate,
  unreg,
  update,
  add,
  renumberTires,
  relocateVehicle,
  printExcel,
  printPdf,
};
