export const mockViewOpenWheels = [
  {
    accMiles: 1000,
    billed: true,
    from: '2021-07-12T17:58:48.836Z',
    tireChanged: false,
    to: '2021-08-12T17:58:48.836Z',
    vehicleNumber: 12345432,
  },
  {
    accMiles: 1000,
    billed: true,
    from: '2021-07-12T17:58:48.836Z',
    tireChanged: false,
    to: '2021-08-12T17:58:48.836Z',
    vehicleNumber: 12345432,
  },
  {
    accMiles: 1000,
    billed: true,
    from: '2021-07-12T17:58:48.836Z',
    tireChanged: false,
    to: '2021-08-12T17:58:48.836Z',
    vehicleNumber: 12345432,
  },
];

export const columns = [
  {
    key: 'column0',
    name: 'Vehicle Number',
    fieldName: 'vehicleNumber',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'Position',
    fieldName: 'position',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'From',
    fieldName: 'from',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'To',
    fieldName: 'to',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'Acc Miles',
    fieldName: 'accMiles',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'Loc Code',
    fieldName: 'tireChange',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column6',
    name: 'Billed',
    fieldName: 'billed',
    isSorted: false,
    isSortedDescending: false,
  },
];