import { FunctionComponent, useState, useEffect } from 'react';
import { DefaultButton, IconButton, MessageBarType, Modal, PrimaryButton, Text, TextField } from '@fluentui/react';
import styles from './MakeModelMaintain.module.scss';
import { useBoolean } from '@fluentui/react-hooks';
import DataGridComponent from '../../../../shared/DataGridComponent';
import { IMake, IModel, makeTableHeadCells, modelTableHeadCells, MakeModelMaintainProps, MAKE_FIELDS, MODEL_FIELDS } from './consts';
import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import { FormBuilderGroup } from '../../../../shared/FormBuilderComponent';
import apiService from '../../../../api';
import { INotification } from '../../../../models/INotification';
import useNotifications from '../../../../hooks/useNotifications';
import classNames from 'classnames';
import { filter, isEmpty } from 'lodash';

const MakeModelMaintain: FunctionComponent<MakeModelMaintainProps> = () => {

  //States
  const [makeList, setMakeList] = useState<IMake[]>([]);
  const [totalMakes, setTotalMakes] = useState(0);
  const [modelList, setModelList] = useState<IModel[]>([]);
  const [filteredModelList, setFilteredModelList] = useState<IModel[]>([]);
  const [totalModels, setTotalModels] = useState(0);
  const [selectedMakes, setSelectedMakes] = useState<IMake[]>([]);
  const [selectedModels, setSelectedModels] = useState<IModel[]>([]);
  const [dataGridStateMakes, setDataGridStateMakes] = useState<any>();
  const [dataGridStateModels, setDataGridStateModels] = useState<any>();

  //Notifications
  const { addNotification } = useNotifications();
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });

  //Toggles 
  const [isLoadingMake, { toggle: toggleLoadingMake }] = useBoolean(false);
  const [isLoadingModel, { toggle: toggleLoadingModel }] = useBoolean(false);
  const [isModalOpen, { toggle: toggleModalOpen }] = useBoolean(false);

  //Forms
  const makeFieldsGroup = new FormBuilderGroup(MAKE_FIELDS);
  const modelFieldsGroup = new FormBuilderGroup(MODEL_FIELDS);



  //Make Functions
  const handleSelectRowMake = async (rowsSelected: any) => {
    setSelectedMakes(rowsSelected);
    if (rowsSelected.length == 1) {
      modelFieldsGroup.cleanFormData();
      makeFieldsGroup.setFormValue('makeIdField', rowsSelected[0].id);
      makeFieldsGroup.setFormValue('makeField', rowsSelected[0].make);
      makeFieldsGroup.setFormValue('descriptionField', rowsSelected[0].description);
    }
    else {
      makeFieldsGroup.cleanFormData();
      modelFieldsGroup.cleanFormData();
      setTotalModels(0);
    }
  };

  const filterModels = (id: string) => {
    toggleLoadingModel();
    try {
      const result = modelList.filter(model => model.makeId == id);
      setFilteredModelList(result);
      setTotalModels(result.length);
    } catch (e: any) {
      setNotification({ text: 'Error filtering models', type: MessageBarType.error });
    }
    finally {
      toggleLoadingModel();
    }
  };

  const onAddMakeClicked = async () => {
    toggleLoadingMake();
    const payload = {
      make: makeFieldsGroup.getFieldFormValue('makeField'),
      description: makeFieldsGroup.getFieldFormValue('descriptionField'),
    };
    try {
      await apiService.management.addMake(payload);
      fetchMakes();
      setNotification({ text: 'Succesfully added Make', type: MessageBarType.success });
    } catch (e: any) {
      setNotification({ text: 'Error adding make', type: MessageBarType.error });
    } finally {
      toggleLoadingMake();
    }
  };

  const onUpdateMakeClicked = async () => {
    toggleLoadingMake();
    const payload = {
      id: makeFieldsGroup.getFieldFormValue('makeIdField'),
      make: makeFieldsGroup.getFieldFormValue('makeField'),
      description: makeFieldsGroup.getFieldFormValue('descriptionField'),
    };
    try {
      await apiService.management.updateMake(payload);
      fetchMakes();
      setNotification({ text: 'Succesfully updated Make', type: MessageBarType.success });
    } catch (e: any) {
      setNotification({ text: 'Error updating make', type: MessageBarType.error });
    }
    finally {
      toggleLoadingMake();
    }
  };

  const onDeleteMakeClicked = async () => {
    toggleLoadingMake();
    const ids: string[] = [];
    selectedMakes.forEach(make => {
      ids.push(make.id);
    });
    try {
      await apiService.management.deleteMakes(ids);
      toggleModalOpen();
      fetchMakes();
      setNotification({ text: 'Succesfully deleted makes', type: MessageBarType.success });
    } catch (e: any) {
      setNotification({ text: 'Error deleting make', type: MessageBarType.error });
    }
    finally {
      toggleLoadingMake();
    }
  };


  //Model Functions
  const handleSelectRowModel = async (rowsSelected: any) => {
    setSelectedModels(rowsSelected);
    if (rowsSelected.length == 1) {
      const row = rowsSelected[0];
      modelFieldsGroup.cleanFormData();
      modelFieldsGroup.setFormValue('makeIdField', row.makeId);
      modelFieldsGroup.setFormValue('idField', row.id);
      modelFieldsGroup.setFormValue('modelField', row.model);
      modelFieldsGroup.setFormValue('descriptionField', row.description);
    }
    else {
      modelFieldsGroup.cleanFormData();
    }
  };

  const onAddModelClicked = async () => {
    toggleLoadingModel();
    const payload = {
      model: modelFieldsGroup.getFieldFormValue('modelField'),
      description: modelFieldsGroup.getFieldFormValue('descriptionField'),
      makeId: makeFieldsGroup.getFieldFormValue('makeIdField'),
    };
    try {
      await apiService.management.addModel(payload);
      fetchModels();
      setNotification({ text: 'Succesfully added model', type: MessageBarType.success });
    } catch (e: any) {
      setNotification({ text: 'Error adding model', type: MessageBarType.error });
    } finally {
      toggleLoadingModel();
    }
  };

  const onUpdateModelClicked = async () => {
    toggleLoadingModel();
    const payload = {
      id: modelFieldsGroup.getFieldFormValue('idField'),
      model: modelFieldsGroup.getFieldFormValue('modelField'),
      description: modelFieldsGroup.getFieldFormValue('descriptionField'),
      makeId: makeFieldsGroup.getFieldFormValue('makeIdField'),
    };
    try {
      await apiService.management.updateModel(payload);
      fetchModels();
      setNotification({ text: 'Succesfully updated model', type: MessageBarType.success });
    } catch (e: any) {
      setNotification({ text: 'Error updating model', type: MessageBarType.error });
    }
    finally {
      toggleLoadingModel();
    }
  };

  const onDeleteModelClicked = async () => {
    toggleLoadingModel();
    const ids: string[] = [];
    selectedModels.forEach(model => {
      ids.push(model.id);
    });
    try {
      await apiService.management.deleteModels(ids);
      fetchModels();
      toggleModalOpen();
      setSelectedModels([]);
      setNotification({ text: 'Succesfully deleted models', type: MessageBarType.success });
    } catch (e: any) {
      setNotification({ text: 'Error deleting models', type: MessageBarType.error });
    }
    finally {
      toggleLoadingModel();
    }
  };

  const fetchMakes = async () => {
    toggleLoadingMake();
    try {
      const payload = {
        filters: {
          searchString: '',
        },
        sortOrder: {
          column: dataGridStateMakes?.sortOrder?.column,
          order: dataGridStateMakes?.sortOrder?.order,
        },
      };
      const { data } = await apiService.management.listMakes(payload);
      setMakeList(data.data);
      setTotalMakes(data.total.found);
    }
    finally {
      toggleLoadingMake();
    }
  };

  const handleStateChangedMakes = async (state: any) => {
    setDataGridStateMakes(state);
  };

  const handleStateChangedModels = async (state: any) => {
    setDataGridStateModels(state);
  };

  const fetchModels = async () => {
    toggleLoadingModel();
    try {
      const payload = {
        filters: {
          searchString: '',
        },
        sortOrder: {
          column: dataGridStateModels.sortOrder?.column,
          order: dataGridStateModels.sortOrder?.order,
        },
      };
      const { data } = await apiService.management.listModels(payload);
      setModelList(data.data);
    } catch (e: any) {
      setNotification({ text: 'Error getting models', type: MessageBarType.error });
    }
    finally {
      toggleLoadingModel();
    }
  };

  //consts
  const disableMakeAdd = isEmpty(makeFieldsGroup.getFieldFormValue('makeField')) || selectedMakes.length > 0;
  const enableMakeDelete = filteredModelList.length === 0 && selectedMakes.length === 1;
  const enableMakeUpdate = selectedMakes.length == 1;

  const disableModelAdd = isEmpty(modelFieldsGroup.getFieldFormValue('modelField'))
    || (selectedModels.length > 0);
  const enableModelUpdate = selectedModels.length == 1;


  //Effects

  useEffect(() => {
    setFilteredModelList([]);
    selectedMakes.length == 1 ? filterModels(selectedMakes[0].id) : setFilteredModelList([]);
  }, [selectedMakes, modelList]);

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  useEffect(() => {
    fetchMakes();
  }, [dataGridStateMakes]);

  useEffect(() => {
    if (selectedMakes.length == 1)
      fetchModels();
  }, [dataGridStateModels, selectedMakes]);

  return (
    <>
      <div className='ms-Grid' dir='ltr'>
        {/* Grids */}
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm6">
            <div className={styles.gridContainer}>
              <DataGridComponent
                idTable={'makeTable'}
                title={'Available Makes'}
                headCells={makeTableHeadCells}
                rowsTable={makeList}
                totalDataFound={totalMakes}
                isLoading={isLoadingMake}
                enableDeleteOption={false}
                enableMultiSelectRow={false}
                enableEditableRows={false}
                enableCheckBox={true}
                enableAutoControls={false}
                enableSearching={false}
                enablePagination={false}
                enableRowsPerPage={false}
                handleChangeDataGridState={handleStateChangedMakes}
                handleSelectRow={handleSelectRowMake}
              />
            </div>
          </div>
          <div className="ms-Grid-col ms-sm6">
            <div className={styles.gridContainer}>
              <DataGridComponent
                idTable={'modelTable'}
                title={'Available Models'}
                headCells={modelTableHeadCells}
                rowsTable={filteredModelList}
                totalDataFound={totalModels}
                isLoading={isLoadingModel}
                enableDeleteOption={false}
                enableMultiSelectRow={false}
                enableEditableRows={false}
                enableCheckBox={true}
                enableAutoControls={false}
                enableSearching={false}
                enablePagination={false}
                enableRowsPerPage={false}
                handleChangeDataGridState={handleStateChangedModels}
                handleSelectRow={handleSelectRowModel}
              />
            </div>
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className='ms-Grid-col ms-sm6'>
            <SeparatorGy />
            <Text variant='large'>Make Details</Text>
            <div className={styles.detailsContainer}>
              <div className='ms-Grid-row'>
                <div className="ms-Grid-col ms-sm8">
                  <div className={styles.textFieldContainer}>
                    <TextField className={styles.descriptionTextField}
                      {...makeFieldsGroup.getFieldForm('makeField')}
                    />
                    <TextField className={styles.descriptionTextField}
                      {...makeFieldsGroup.getFieldForm('descriptionField')}
                    />
                  </div>
                </div>
                <div className="ms-Grid-col ms-sm4">
                  <div className={styles.buttonContainer}>
                    <PrimaryButton text='Add' onClick={onAddMakeClicked} disabled={disableMakeAdd} />
                    <PrimaryButton text='Update' onClick={onUpdateMakeClicked} disabled={!enableMakeUpdate} />
                    <PrimaryButton text='Delete' onClick={toggleModalOpen} disabled={!enableMakeDelete} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='ms-Grid-col ms-sm6'>
            <SeparatorGy />
            <Text variant='large'>Model Details</Text>
            <div className={styles.detailsContainer}>
              <div className='ms-Grid-row'>
                <div className="ms-Grid-col ms-sm8">
                  <div className={styles.textFieldContainer}>
                    <TextField className={styles.descriptionTextField}
                      {...modelFieldsGroup.getFieldForm('modelField')}
                    />
                    <TextField className={styles.descriptionTextField}
                      {...modelFieldsGroup.getFieldForm('descriptionField')}
                    />
                  </div>
                </div>
                <div className="ms-Grid-col ms-sm4">
                  <div className={styles.buttonContainer}>
                    <PrimaryButton text='Add' onClick={onAddModelClicked} disabled={disableModelAdd} />
                    <PrimaryButton text='Update' onClick={onUpdateModelClicked} disabled={!enableModelUpdate} />
                    <PrimaryButton text='Delete' onClick={toggleModalOpen} disabled={!enableModelUpdate} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        key={'modalID'}
        isOpen={isModalOpen}
        onDismiss={toggleModalOpen}
        containerClassName={styles.modalContainer}
      >
        <div className={styles.modalBody}>
          <div className='modalHeader'>
            <Text variant="xLarge">Confirmation</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel='Close popup modal'
              onClick={toggleModalOpen}
            />
          </div>

          <div className={classNames('ms-Grid-col', styles.modalTextContainer)}>
            <Text variant="medium">Are you sure you want to delete the selected {selectedModels.length > 0 ? 'models' : 'makes'}?</Text>
          </div>

          <div className={classNames('ms-Grid-col', styles.modalButtonsContainer)}>
            <PrimaryButton
              id='printExportModalBtn'
              text="Delete"
              onClick={selectedModels.length > 0 ? onDeleteModelClicked : onDeleteMakeClicked}
              className={styles.button}
            />
            <DefaultButton
              text="Cancel"
              onClick={toggleModalOpen}
              className={styles.button}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MakeModelMaintain;