import moment from 'moment';

export const emptyPostFlaggedTire = {
  prefix: '',
  brandNumber: '',
  suffix: null,
  a: '',
  treadDepth: '',
  docNumber: '',
  oosDate: moment().format('MM/DD/YYYY'),
  // runout: false,
};

export const inspectionCodesColumns = [
  {
    key: 'column0',
    name: 'Billable',
    fieldName: 'billable',
  },
  {
    key: 'column1',
    name: 'Inspection code',
    fieldName: 'inspCode',
  },
  {
    key: 'column2',
    name: 'Description',
    fieldName: 'description',
  },
  {
    key: 'column3',
    name: 'Comments',
    fieldName: 'comments',
  },
];

export const columns = [
  {
    key: 'column0',
    name: 'Prefix',
    fieldName: 'prefix',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'Brand Number',
    fieldName: 'brandNumber',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Suffix',
    fieldName: 'suffix',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'A',
    fieldName: 'a',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'Tread Depth',
    fieldName: 'treadDepth',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column6',
    name: 'OOS Doc',
    fieldName: 'docNumber',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column7',
    name: 'OOS Date',
    fieldName: 'oosDate',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column8',
    name: 'Plant',
    fieldName: 'plant',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column9',
    name: 'Tire Class',
    fieldName: 'tireClass',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column10',
    name: 'Type Code',
    fieldName: 'typeCode',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column11',
    name: 'Current Vehicle',
    fieldName: 'currentVehicle',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column12',
    name: 'Current Position',
    fieldName: 'currentPosition',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column13',
    name: 'Current On',
    fieldName: 'currentOn',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column14',
    name: 'Previous Vehicle',
    fieldName: 'previousVehicle',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column15',
    name: 'Previous Position',
    fieldName: 'previousPosition',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column16',
    name: 'Previous Off',
    fieldName: 'previousOff',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column18',
    name: 'Miles',
    fieldName: 'miles',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
];