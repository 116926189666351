import homeIcon from '../../assets/icons/sidebarIcons/homeIcon.svg';
import customerIcon from '../../assets/icons/sidebarIcons/customerIcon.svg';
import quoteIcon from '../../assets/icons/sidebarIcons/quoteIcon.svg';
import termsIcon from '../../assets/icons/sidebarIcons/termsIcon.svg';
import tireOrderingIcon from '../../assets/icons/sidebarIcons/tireOrderingIcon.svg';
import recordKeepingIcon from '../../assets/icons/sidebarIcons/recordKeepingIcon.svg';
import billingIcon from '../../assets/icons/sidebarIcons/billingIcon.svg';
import reportingIcon from '../../assets/icons/sidebarIcons/reportingIcon.svg';
import masterDataIcon from '../../assets/icons/sidebarIcons/masterDataIcon.svg';

import titleHomeIcon from '../../assets/icons/titleIcons/title-homeIcon.svg';
import titleCustomerIcon from '../../assets/icons/titleIcons/title-customerIcon.svg';
import titleQuoteIcon from '../../assets/icons/titleIcons/title-quoteIcon.svg';
import titleTermsIcon from '../../assets/icons/titleIcons/title-termsIcon.svg';
import titleTireOrderingIcon from '../../assets/icons/titleIcons/title-tireOrderingIcon.svg';
import titleRecordKeepingIcon from '../../assets/icons/titleIcons/title-recordKeepingIcon.svg';
import titleBillingIcon from '../../assets/icons/titleIcons/title-billingIcon.svg';
import titleReportingIcon from '../../assets/icons/titleIcons/title-reportingIcon.svg';
import titleMasterDataIcon from '../../assets/icons/titleIcons/title-masterDataIcon.svg';

import { MIDAS_MODULES } from '../../models/EnumMidasModules';

export interface IMenuOption {
  id: string,
  name: string,
  routePath: string,
  iconSideBarSrc: string,
  iconTitleSrc: string,
}

export const MENU_LISTING: Array<IMenuOption> = [
  {
    id: MIDAS_MODULES.HOME,
    name: 'Home',
    routePath: '/',
    iconSideBarSrc: homeIcon,
    iconTitleSrc: titleHomeIcon,
  },
  {
    id: MIDAS_MODULES.CUSTOMER,
    name: 'Customer',
    routePath: '/customer',
    iconSideBarSrc: customerIcon,
    iconTitleSrc: titleCustomerIcon,
  },
  {
    id: MIDAS_MODULES.QUOTE,
    name: 'Quote',
    routePath: '/quote',
    iconSideBarSrc: quoteIcon,
    iconTitleSrc: titleQuoteIcon,
  },
  {
    id: MIDAS_MODULES.TERMS,
    name: 'Terms',
    routePath: '/terms',
    iconSideBarSrc: termsIcon,
    iconTitleSrc: titleTermsIcon,
  },
  {
    id: MIDAS_MODULES.TIRE_ORDERING,
    name: 'Tire Ordering',
    routePath: '/tireorders',
    iconSideBarSrc: tireOrderingIcon,
    iconTitleSrc: titleTireOrderingIcon,
  },
  {
    id: MIDAS_MODULES.RECORD_KEEPING,
    name: 'Record Keeping',
    routePath: '/recordkeeping',
    iconSideBarSrc: recordKeepingIcon,
    iconTitleSrc: titleRecordKeepingIcon,
  },
  {
    id: MIDAS_MODULES.BILLING,
    name: 'Billing',
    routePath: '/billing',
    iconSideBarSrc: billingIcon,
    iconTitleSrc: titleBillingIcon,
  },
  {
    id: MIDAS_MODULES.REPORTING,
    name: 'Reporting',
    routePath: '/reports',
    iconSideBarSrc: reportingIcon,
    iconTitleSrc: titleReportingIcon,
  },
  {
    id: MIDAS_MODULES.MY_REPORTS,
    name: 'My Reports',
    routePath: '/myreports',
    iconSideBarSrc: termsIcon,
    iconTitleSrc: titleTermsIcon,
  },
  {
    id: MIDAS_MODULES.MAINTAIN,
    name: 'Master Data',
    routePath: '/maintain',
    iconSideBarSrc: masterDataIcon,
    iconTitleSrc: titleMasterDataIcon,
  },
  // {
  //   id: MIDAS_MODULES.ADD_INS,
  //   name: 'Add-Ins',
  //   routePath: '/add-ins',
  //   iconSideBarSrc: billingIcon,
  //   iconTitleSrc: titleBillingIcon,
  // },
];

export const getMenuOptionById: any = (idToFind: string) => MENU_LISTING.find(({ id }) => id === idToFind);
export const geticonSideBarSrcById = (idToFind: string) => getMenuOptionById(idToFind)?.iconSideBarSrc;