import { FC, useState } from 'react';
import { DefaultButton, Dialog, DialogFooter, DialogType, IconButton, MessageBarType, Modal, PrimaryButton, Text, TextField } from '@fluentui/react';
import { IThreadMassUploadProps } from './IMassUploadProps';
import { useBoolean } from '@fluentui/react-hooks';
import apiService from '../../api';

import styles from './MassUpload.module.scss';
import useNotifications from '../../hooks/useNotifications';
import { IThreadMassUploadState } from './IMassUploadState';
import classNames from 'classnames';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { downloadFile } from '../PrintingModal/consts';

const ThreadMassUpload: FC<IThreadMassUploadProps> = ({isModalOpen, hideModal, quoteId, sold, manual, fetchTiresQuote, getUploadRunningStatus}) => {
  const [state, setState] = useState<IThreadMassUploadState>({ loading: false });
  const [selectedFile, setSelectedFile] = useState<any | Blob>(null);
  const [showUploadConfirmation, { toggle: toggleUploadConfirmation }] = useBoolean(false);

  const { addNotification } = useNotifications();

  const hanldeUpload = () => {
    getUploadRunningStatus('IDLE');
    toggleUploadConfirmation();
    uploadTreadDepth();
  };

  const parseErrors = (arrayOfErrors: Array<any>) => {
    let line = '';
    if (arrayOfErrors.length) {
      arrayOfErrors.forEach((element, index) => {
        line += `${++index}) ${element.message} \n`;
      });
    }
    return line;
  };

  const uploadTreadDepth = async () => {
    setState(prev => ({ ...prev, loading: true }));
    addNotification({
      text: 'Adding tires in progress. Another pop up will be seen once completed.',
      type: MessageBarType.success,
    });
    getUploadRunningStatus('RUNNING');
    try {
      const response: any = await apiService.billingAPI.uploadTreadDepth(
        quoteId,
        sold,
        manual,
        selectedFile,
      );
      addNotification({
        text: 'Upload completed. View file to see result.',
        type: MessageBarType.success,
      });
      downloadFile(response.data, 'Excel');
      fetchTiresQuote();
      if (response.data) {
        getUploadRunningStatus('COMPLETED');
      }
    } catch (e: any) {
      const { response } = e;
      const arrayBuffer = response.request.response;
      const textDecoder = new TextDecoder();
      const responseText = textDecoder.decode(arrayBuffer);
      getUploadRunningStatus('FAILED');
      switch (response.status) {
        case 499:
          return addNotification({
            text: `Unable to upload: ${responseText}`,
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Saving error: ${responseText}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
      hideModal();
    }
  };

  const downloadTemplate = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.commonAPI.downloadTemplate(
        'TreadDepth',
      );
      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, 'Excel');
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Download Template error: ${parseErrors(response?.data?.state)}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    toggleUploadConfirmation();
  };

  const handleCancel = () => {
    setSelectedFile(null);
    toggleUploadConfirmation();
  };

  return (
    <>
      {!state.loading ? 
        <Modal
          isOpen={isModalOpen}
          onDismiss={hideModal}
          isBlocking={false}
          containerClassName={styles.modalContainer}
        >
          <div className={styles.modalBody}>
            <div className={styles.modalHeader}>
              <Text variant="xLarge">Upload Thread Depth</Text>
              <IconButton
                iconProps={{ iconName: 'Cancel' }}
                ariaLabel="Close popup modal"
                onClick={hideModal}
              />
            </div>
            <div className={styles.massUpload}>
              <PrimaryButton text="Template Download" onClick={downloadTemplate}/>
              <div className="ms-TextField">
                Download template, input data <b>and leave the Result blank.</b>
                <br/>After filling up the file, click Upload button below. <br /><br />
                <b>Please Note that only a maximum of 500 rows per file will be processed.</b>
              </div>
            </div>
            <div className={styles.modalFooter}>
              <label 
                className={
                  classNames(
                    styles.customButton,
                    styles.disabled,
                  )
                }
              >
                <TextField type="file" onChange={changeHandler} className={styles.upload}/>
                <span>
                  <span>Upload</span>
                </span>
              </label>
            </div> 
          </div>
        
        </Modal> : <LoadingScreen />
      }
      <Dialog
        hidden={!showUploadConfirmation}
        onDismiss={toggleUploadConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: 'Are you sure you want to upload this file?',
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={hanldeUpload} text="Upload" />
          <DefaultButton onClick={handleCancel} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ThreadMassUpload;