import { HeadCell } from '../DataGridComponent/DataGridModels';

export const locationsHeadCells: Array<HeadCell> = [
  {
    key: 'column1',
    name: 'Ship to Number',
    fieldName: 'shipToNumber',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Location Code',
    fieldName: 'locationCode',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Address Line 1',
    fieldName: 'addrLine1',
    typeField: 'text',
  },
  {
    key: 'column5',
    name: 'City',
    fieldName: 'city',
    typeField: 'text',
  },
];