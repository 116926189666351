import { DefaultButton, IconButton, MessageBarType, Modal, PrimaryButton, Text } from '@fluentui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import DataGridComponent from '../DataGridComponent';
import styles from './LocationsModal.module.scss';
import { useBoolean } from '@fluentui/react-hooks';
import { locationsHeadCells } from './consts';
import apiService from '../../api';
import useNotifications from '../../hooks/useNotifications';


interface LocationsModalComponentProps {
  id: string;
  customerId: string;
  title: string;
  isOpen: boolean;
  onSubmit: (value?: any) => void;
  onDismiss: () => void;
  successLabel: string;
  cancelLabel: string;
}

const LocationsModalComponent: FunctionComponent<LocationsModalComponentProps> = ({
  id,
  customerId,
  title,
  isOpen,
  onSubmit,
  onDismiss,
  successLabel,
  cancelLabel,
}) => {

  const { addNotification } = useNotifications();
  const [locationsList, setlocationsList] = useState([]);
  const [totalFound, setTotalFound] = useState(0);
  const [locationSelected, setLocationSelected] = useState(null);
  const [notification, setNotification] = useState({ text: '', type: 0 });
  const [isLoading, { toggle: toggleChangeLoading }] = useBoolean(false);

  const fetchData = async (pagination: any, filters?: any, sortOrder?: any) => {
    try {
      const { data }: any = await apiService.getLocationsByCustomerId(
        customerId,
        pagination,
        sortOrder);
      setlocationsList(data?.data);
      setTotalFound(data?.total?.found);
    } catch (error) {
      setNotification({ text: 'Fetching Locations error', type: MessageBarType.error });
    }
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    toggleChangeLoading();
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString: searchedText,
    };
    await fetchData(pagination, filters, sortOrder);
    toggleChangeLoading();
  };

  const handleSelectRow = (rowsSelected: any) => {
    if (rowsSelected.length) setLocationSelected(rowsSelected[0]);
  };

  const handleSubmit = () => onSubmit(locationSelected);

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  const disableSelecBtn = locationSelected == null;

  return (
    <>
      <Modal
        key={id}
        isOpen={isOpen}
        onDismiss={onDismiss}
        containerClassName='modalContainer'
      >
        <div className={styles.modalBody}>
          <div className='modalHeader'>
            <Text variant='xLarge'>{title}</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel='Close popup modal'
              onClick={onDismiss}
            />
          </div>
          <div className='tableContainer'>
            <DataGridComponent
              idTable={'vehicle-table'}
              title=''
              headCells={locationsHeadCells}
              rowsTable={locationsList}
              totalDataFound={totalFound}
              isLoading={isLoading}
              enableCheckBox={true}
              enableSearching={true}
              enablePagination={true}
              enableRowsPerPage={true}
              handleChangeDataGridState={handleChangeDataGridState}
              handleSelectRow={handleSelectRow}
            />
          </div>
          <div className={styles.modalFooter}>
            <DefaultButton
              id='cancelButton'
              text={cancelLabel}
              onClick={onDismiss}
            />
            <PrimaryButton
              id='selectButton'
              text={successLabel}
              onClick={handleSubmit}
              disabled={disableSelecBtn}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LocationsModalComponent;