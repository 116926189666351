import { PrimaryButton, Spinner, SpinnerSize, Text, TextField } from '@fluentui/react';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { UseFieldReturnDefinition } from '../../hooks/useField';

import SeparatorGy from '../../components/SeparatorGy/SeparatorGy';

import styles from './History.module.scss';

import { useUserPermissions } from '../../hooks/useUserPermissions';
import { auth_customer_history, auth_quote_history, auth_terms_history } from '../../consts/programKeys';

interface HistoryFormProps {
  actionType: string;
  handleUpdateComments: () => void;
  comments: string;
  isLoading: boolean;
  commentsField: UseFieldReturnDefinition,
  createdByField: UseFieldReturnDefinition,
  createdOnField: UseFieldReturnDefinition,
  lastUpdatedByField: UseFieldReturnDefinition,
  lastUpdatedOnField: UseFieldReturnDefinition,
  disableEditing?: boolean,
  parentComponentName: string;
}

const HistoryForm: React.FC<HistoryFormProps> = ({
  actionType,
  handleUpdateComments,
  comments = '',
  isLoading,
  commentsField,
  createdByField,
  createdOnField,
  lastUpdatedByField,
  lastUpdatedOnField,
  disableEditing,
  parentComponentName,
}) => {
  const maxCommentsCharacters = 250;
  // form validations
  const isUpdate = actionType === 'edit';

  useEffect(() => {
    if (commentsField.getValue()?.length >= maxCommentsCharacters) {
      commentsField.setValue(commentsField.getValue().substring(0, maxCommentsCharacters));
    }
  }, [commentsField?.value]);


  const { hasPermission } = useUserPermissions();
  const userPermissions_cust = hasPermission(auth_customer_history);
  const userPermissions_quote = hasPermission(auth_quote_history);
  const userPermissions_terms = hasPermission(auth_terms_history);

  const disableUpdBtn = (comments === commentsField.getValue() || isLoading || (parentComponentName === 'CUSTOMER' && !userPermissions_cust.isWrite) ||
    (parentComponentName === 'QUOTE' && !userPermissions_quote.isWrite) || (parentComponentName === 'TERMS' && !userPermissions_terms.isWrite)
  );
  return (
    <>
      <div className="ms-Grid margin-left-rigth-2 marginTop18" dir='ltr'>
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm6')}>
            <Text variant="xLarge" className={styles.headerLabel}>Comments</Text>
            <SeparatorGy />
            <div className={classNames('ms-Grid-col', 'ms-sm12')}>
              <TextField disabled={disableEditing} {...commentsField} multiline rows={8} />
              <p className={styles.charCounter}>
                {
                  `Characters: 
                  ${commentsField.getValue()?.length || 0}/
                  ${maxCommentsCharacters}`
                }
              </p>
            </div>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm6', styles.boxContainer)}>
            {isUpdate && (
              <div>
                <Text variant="xLarge" className={styles.headerLabel}>Created By</Text>
                <SeparatorGy />
                <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                  <TextField disabled={disableEditing} {...createdByField} />
                </div>
                <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                  <TextField disabled={disableEditing} {...createdOnField} />
                </div>
              </div>
            )}
            <div>
              <Text variant="xLarge" className={styles.headerLabel}>Last Updated By</Text>
              <SeparatorGy />
              <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                <TextField disabled={disableEditing} {...lastUpdatedByField} />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                <TextField disabled={disableEditing} {...lastUpdatedOnField} />
              </div>
            </div>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.submitContainer)}>
            {isLoading && (
              <Spinner size={SpinnerSize.large} />
            )}
            {!isLoading && (
              <PrimaryButton
                id='updateBtn'
                onClick={handleUpdateComments}
                text="Update comments"
                disabled={disableUpdBtn}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryForm;
