import { HeadCell } from '../DataGridComponent/DataGridModels';

export const plantsPageSizes = [100, 250, 500];
export interface PlantSelectionModalComponentProps {
  id: string;
  title: string;
  type: string;
  isOpen: boolean;
  onSubmit: (value?: any) => void;
  onDismiss: () => void;
}

export const PlantSelectionHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Plant Number',
    fieldName: 'plantNo',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Name',
    fieldName: 'name',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Address 1',
    fieldName: 'addrLine1',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Address 2',
    fieldName: 'addrLine2',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'City',
    fieldName: 'city',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'State',
    fieldName: 'state',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Country',
    fieldName: 'country',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Radial Bias',
    fieldName: 'radialOrBias',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Zip',
    fieldName: 'zip',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Phone',
    fieldName: 'phone1',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Fax',
    fieldName: 'fax',
    typeField: 'text',
  },
];

export interface IPlant {
  id?: string;
  plantNo: string;
  type: string;
  name: string;
  addrLine1: string;
  addrLine2: string;
  city: string;
  state?: string;
  country?: string;
  zip: string;
  contactName: string;
  email: string;
  phone1: string;
  phone2: string;
  fax: string;
  closeTo?: string;
  comments?: string;
  createdby?: string;
  createdon?: Date;
  lastmodifiedby?: string;
  lastmodifiedon?: Date;
  mCbPlantid?: string;
};

export const defaultPlantInfo: IPlant = {
  plantNo: '',
  name: '',
  type: '',
  addrLine1: '',
  addrLine2: '',
  city: '',
  zip: '',
  contactName: '',
  phone1: '',
  phone2: '',
  email: '',
  fax: '',
  state: '',
  country: '',
};

export const plantFilterValues = {
  closeTo: '',
  name: '',
  city: '',
};