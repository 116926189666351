export const mockVehicleConsignedTires = [{
  billed: false,
  brand: 17664,
  chgDate: '2022-02-18T00:00:00',
  closeOut: null,
  dmg: null,
  dotNo: 'MC 72 TRM 3321',
  earlyRemoval: null,
  flgasDmg: null,
  id: '085285892E204E08003DF9003586C300',
  locationCode: 'GGB',
  lost: false,
  oosAv: 'Y',
  pfx: 'EM',
  prodCode: '15804256900000',
  retredAdjust: null,
  sfx: null,
  sold: null,
  tc: '1',
  tireSize: 'B315/80R22.5',
  totalMiles: 11304,
  transferTo: null,
  typeCode: 'A9999',
  vNo: '980',
  wPos: 'LRO'},
];

export const mockStockConsignedTires = [
  { brandNo: 9638,
    chgDate: '2022-05-25T00:00:00',
    dotNo: 'MC 72 TRM 0220',
    id: 'F4951E1475434FDEB7851DD48085CD37',
    locationCode: 'GGB',
    oosAv: 'Y',
    prefix: 'NM',
    prodCode: '15804256900000',
    status: null,
    suffix: null,
    tc: '1',
    tireSize: 'B315/80R22.5',
    totalMiles: 69923,
    typeCode: 'A9999',
  },
];

export const pageSizesVehicles = [500, 750, 1000];
export const pageSizesStock = [500, 750, 1000];

export const showHideColumns = [
  {
    key: 'column1',
    name: 'VNo',
    isVisible: true,
  },
  {
    key: 'column2',
    name: 'WPos',
    isVisible: true,
  },
  {
    key: 'column3',
    name: 'Prefix',
    isVisible: true,
  },
  {
    key: 'column4',
    name: 'Brand',
    isVisible: true,
  },
  {
    key: 'column5',
    name: 'Suffix',
    isVisible: true,
  },
  {
    key: 'column6',
    name: 'Change Date',
    isVisible: true,
  },
  {
    key: 'column7',
    name: 'Total Miles',
    isVisible: true,
  },
  {
    key: 'column8',
    name: 'Tire Size',
    isVisible: true,
  },
  {
    key: 'column9',
    name: 'Type Code',
    isVisible: true,
  },
  {
    key: 'column10',
    name: 'Prod Code',
    isVisible: true,
  },
  {
    key: 'column11',
    name: 'TC',
    isVisible: true,
  },
  {
    key: 'column12',
    name: 'Loc',
    isVisible: true,
  },
  {
    key: 'column13',
    name: 'OOS Av',
    isVisible: true,
  },
  {
    key: 'column14',
    name: 'Dot #',
    isVisible: true,
  },
];

export const vehicleTiresColumns = [
  {
    key: 'column1',
    name: 'VNo',
    fieldName: 'vNo',
    isSorted: true,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'Vno',
  },
  {
    key: 'column2',
    name: 'WPos',
    fieldName: 'wPos',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'WheelPos',
  },
  {
    key: 'column3',
    name: 'Prefix',
    fieldName: 'pfx',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'Pfx',
  },
  {
    key: 'column4',
    name: 'Brand',
    fieldName: 'brand',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'BrandNo',
  },
  {
    key: 'column5',
    name: 'Suffix',
    fieldName: 'sfx',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'Sfx',
  },
  {
    key: 'column6',
    name: 'Change Date',
    fieldName: 'chgDate',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'ChgDate',
  },
  {
    key: 'column7',
    name: 'Total Miles',
    fieldName: 'totalMiles',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'TotalMiles',
  },
  {
    key: 'column8',
    name: 'Tire Size',
    fieldName: 'tireSize',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'TireSize',
  },
  {
    key: 'column9',
    name: 'Type Code',
    fieldName: 'typeCode',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'TypeCode',
  },
  {
    key: 'column10',
    name: 'Prod Code',
    fieldName: 'prodCode',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'ProdCode',
  },
  {
    key: 'column11',
    name: 'TC',
    fieldName: 'tc',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'TC',
  },
  {
    key: 'column12',
    name: 'Loc',
    fieldName: 'locationCode',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'Loc',
  },
  {
    key: 'column13',
    name: 'OOS Av',
    fieldName: 'oosAv',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'OosAvg',
  },
  {
    key: 'column14',
    name: 'Dot #',
    fieldName: 'dotNo',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'DotNo',
  },
];

export const stockTiresColumns = [
  {
    key: 'column1',
    name: 'Prefix',
    fieldName: 'prefix',
    isSorted: true,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'Prefix',
  },
  {
    key: 'column2',
    name: 'Brand',
    fieldName: 'brandNo',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'BrandNo',
  },
  {
    key: 'column3',
    name: 'Suffix',
    fieldName: 'suffix',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'Suffix',
  },
  {
    key: 'column4',
    name: 'Status',
    fieldName: 'status',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'Status',
  },
  {
    key: 'column5',
    name: 'Change Date',
    fieldName: 'chgDate',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'ChgDate',
  },
  {
    key: 'column6',
    name: 'Total Miles',
    fieldName: 'totalMiles',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'TotMiles',
  },
  {
    key: 'column7',
    name: 'Tire Size',
    fieldName: 'tireSize',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'TireSize',
  },
  {
    key: 'column8',
    name: 'Type Code',
    fieldName: 'typeCode',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'TypeCode',
  },
  {
    key: 'column9',
    name: 'Prod Code',
    fieldName: 'prodCode',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'ProdCode',
  },
  {
    key: 'column10',
    name: 'TC',
    fieldName: 'tc',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'TC',
  },
  {
    key: 'column11',
    name: 'Loc',
    fieldName: 'locationCode',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'Loc',
  },
  {
    key: 'column12',
    name: 'OOS Av',
    fieldName: 'oosAv',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'OosAvg',
  },
  {
    key: 'column13',
    name: 'Dot #',
    fieldName: 'dotNo',
    isSorted: false,
    isSortedDescending: false,
    isVisible: true,
    exportName: 'DotNo',
  },
];
