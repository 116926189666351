import React, { useEffect, useMemo, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IColumn,
  Icon,
  IconButton,
  IDropdownOption,
  MaskedTextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Text,
  TextField,
  Toggle,
} from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { get, debounce, isEqual, isNil } from 'lodash';
import AutocompleteInput from '../../../../../../shared/AutocompleteInput';
import apiService from '../../../../../../api';
import useNotifications from '../../../../../../hooks/useNotifications';
import { customerSelector, locationSelector, setTireForReinstate } from '../../../../../../redux/recordKeepingSlice';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../../Pagination/Pagination';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';
import { IPostOosTire } from './IPostOosTire';
import { IPostOosTiresTabState } from './IPostOosTiresTabState';
import { IPostOosTiresTabProps } from './IPostOosTiresTabProps';
import { emptyPostOosTire, inspectionCodesColumns, plantsColumns, postOOsTiresColumns, TIRE_FIELDS } from './consts';
import { pageSizes } from '../../../../../../consts/recordKeeping';
import styles from './PostOosTiresTab.module.scss';
import SelectingModal from '../../../../../SelectingModal/SelectingModal';
import ErrorsModal from '../../../../../ErrorsModal/ErrorsModal';
import moment from 'moment';
import ViewTireDetailsModal from '../../../../ViewTireDetailsModal/ViewTireDetailsModal';
import { sortOrder } from '../../../../../../consts/sortOrder';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_tireDisposition_postOosTires } from '../../../../../../consts/programKeys';
import useKeyPress from '../../../../../../hooks/useKeyPress/useKeyPress';
import { FormBuilderGroup } from '../../../../../../shared/FormBuilderComponent';

const PostOosTiresTab = (props: IPostOosTiresTabProps) => {

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = columnsState.map(el => ({ ...el }));
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        if (!currColumn.isSorted) currColumn.isSortedDescending = true;
        if (currColumn.isSortedDescending && currColumn.isSorted) {
          currColumn.isSorted = false;
          currColumn.isSortedDescending = true;
        }
        else {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        }
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const dispatch = useDispatch();
  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);

  const [state, setState] = React.useState<IPostOosTiresTabState>({
    items: [],
    selectedItems: [],
    loading: false,
    foundCount: 0,
  });

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(postOOsTiresColumns);
  const [filters, setFilters] = useState<any>({ milesTo: null });
  const [untouchedItems, setUntouchedItems] = useState<Array<any>>([]);
  const [detailsVisible, { toggle: toggleDetailsVisible }] = useBoolean(false);
  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [isErrorDialogVisible, { toggle: toggleErrorDialog }] = useBoolean(false);
  const [isTDErrorDialogVisible, { toggle: toggleTDErrorDialog }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [showErrorsModal, { toggle: toggleShowErrorsModal }] = useBoolean(false);
  const [showPlantsModal, { toggle: toggleShowPlantsModal }] = useBoolean(false);
  const [showAModal, { toggle: toggleShowAModal }] = useBoolean(false);
  const [showBModal, { toggle: toggleShowBModal }] = useBoolean(false);
  const [tireToBeAdded, setTireToBeAdded] = useState<any>(emptyPostOosTire);
  const [parsedErrors, setParsedErrors] = useState<any>([]);
  const [parsedAddingErrors, setParsedAddingErrors] = useState<any>([]);
  const [disposition, setDisposition] = useState<Array<IDropdownOption>>();
  const [dispositionCodes, setDispositionCodes] = useState<Array<IDropdownOption>>();
  const [plants, setPlants] = useState<Array<any>>([]);
  const [inspectionCodesDMG, setInspectionCodesDMG] = useState<Array<any>>([]);
  const [postTableId, setPostTableId] = useState<any>(null);
  const [docClaimNo, setDocClaimNo] = useState<any>({});
  const [isTDRequired, setIsTDRequired] = useState<boolean>(false);
  const [aFieldVal, setAFieldVal] = useState<string>('');
  const [bFieldVal, setBFieldVal] = useState<string>('');
  const [isAFieldValidated, setIsAFieldValidated] = useState<boolean>(false);

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_tireDisposition_postOosTires);

  const tiresFormFieldsGroup = new FormBuilderGroup(TIRE_FIELDS);

  const plantList = useMemo(() => plants ?
    plants.map(({ mCbPlantid, plantNo }) => ({
      key: mCbPlantid,
      text: plantNo,
    })) : [], [plants]);

  const mountingProcess = async () => {
    await getDisposition();
    await fetchPlants('');
    await fetchOosTires();
  };

  const getSortOrder = () => {
    const column = columnsState.find(({ isSorted }) => isSorted);
    if (isNil(column))
      return {
        column: 'createdOn',
        order: sortOrder.ASC,
      };
    const { fieldName, isSortedDescending } = column;
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const openViewTireDetailsWindow = (item: any) => {
    const { consignedTireId, prefix, brandNumber, suffix } = item;
    dispatch(setTireForReinstate({ tireId: consignedTireId, pfx: prefix, bno: brandNumber, sfx: suffix }));
    toggleDetailsVisible();
  };

  const fetchOosTires = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.postOosTires.get(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
        filters,
      );
      const foundCount = data.total.found;
      const items = data.data.map(
        ({ oosDate, ...others }: any) => ({ oosDate: moment(oosDate).format('MM/DD/YYYY'), ...others }),
      );
      setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      addNotification({
        text: `Post OOS Tires fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        filters,
        customerId,
        locationId,
      };
 
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.postOosTires.printExcel(requestData) :
        await apiService.postOosTires.printPdf(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const setTireToBeEdited = (id: string, field: string, value: any, regExp?: RegExp) => {
    if (!regExp || (regExp && regExp.test(value))) {
      setState((prev: any) => ({
        ...prev,
        items: prev.items.map((item: IPostOosTire) => item.id === id ? { ...item, [field]: value } : item),
      }));
    }
  };

  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const getDisposition = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await apiService.postOosTires.getDisposition();
      setDisposition(data.map(({ code, description }: any) => ({ key: code, text: `${code} - ${description}` })));
      setDispositionCodes(data.map(({ code }: any) => ({ key: code, text: code })));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching disposition error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchPlants = async (filter: any) => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await apiService.postOosTires.getPlants({ ...filter, type: 'RETREAD' });
      setPlants(data.data);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching plants error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const getInspectionCodesDMG = async (dispCodeSelected: string) => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await apiService.getInspectionCodesByCustomer(customerId, dispCodeSelected);
      setInspectionCodesDMG(data);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching inspection codes error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSave = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.postOosTires.update(state.items, customerId, locationId);
      setUntouchedItems(state.items);
      setParsedErrors([]);
      addNotification({
        text: 'Post OOS tires were successfully saved.',
        type: MessageBarType.success,
      });
      if (paginationProps.current === 1) {
        await fetchOosTires();
      } else {
        await paginationProps.onChangePage(1);
      }
    } catch (e: any) {
      const { response } = e;
      setState(prev => ({ ...prev, loading: false }));
      switch (response.status) {
        case 400:
          setParsedErrors(response.data.state);
          return addNotification({
            text: 'Saving tires error.',
            type: MessageBarType.error,
          });
        default:
          return addNotification({
            text: response.data.message,
            type: MessageBarType.error,
          });
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.postOosTires.submit(state.items, customerId, locationId);
      setUntouchedItems(state.items);
      setParsedErrors([]);
      addNotification({
        text: 'Post OOS tires were successfully submitted.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      setState(prev => ({ ...prev, loading: false }));
      switch (response.status) {
        case 400:
          return addNotification({
            text: 'Submitting tires error.',
            type: MessageBarType.error,
          });
        case 499:
          return addNotification({
            text: response.data.desc.message,
            type: MessageBarType.error,
          });
        default:
          return addNotification({
            text: response.data.message,
            type: MessageBarType.error,
          });
      }
    } finally {
      if (paginationProps.current === 1) {
        await fetchOosTires();
      } else {
        await paginationProps.onChangePage(1);
      }
    }
  };

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.postOosTires.delete(state.selectedItems);
      addNotification({
        text: 'Selected tire(s) were successfully deleted.',
        type: MessageBarType.success,
      });
      if (paginationProps.current === 1) {
        await fetchOosTires();
      } else {
        await paginationProps.onChangePage(1);
      }
    } catch (e: any) {
      const { response } = e;
      setState(prev => ({ ...prev, loading: false }));
      addNotification({
        text: `Post OOS Tires deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const handleOk = () => {
    isAFieldValidated ? toggleShowAModal() : toggleShowBModal();
    toggleErrorDialog();
  };

  const handleAddTire: any = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      if (inspectionCodesDMG.find(item => (item.inspCode == tireToBeAdded.a)).td == 'Y' && !tireToBeAdded.treadDepth) {
        toggleTDErrorDialog();
      }
      else {
        await apiService.postOosTires.add(
          {
            ...tireToBeAdded,
            a: inspectionCodesDMG.find(item => item.inspCode == tireToBeAdded.a).inspCode,
            b: inspectionCodesDMG.find(item => item.inspCode == tireToBeAdded.b)?.inspCode,
            disposition: tireToBeAdded.disposition.key,
            bill: tireToBeAdded.override ? tireToBeAdded.bill: false,
            includeOosAverage: tireToBeAdded.override ? tireToBeAdded.includeOosAverage: false,
          },
          customerId,
          locationId,
        );
        setParsedAddingErrors([]);
        setTireToBeAdded((tireToBeAdded: any) => ({ ...tireToBeAdded, brandNumber: '', suffix: '' , treadDepth: ''}));
        tiresFormFieldsGroup.setFormValue('brandNumberField', '');
        tiresFormFieldsGroup.setFormValue('suffixField', '');
        tiresFormFieldsGroup.setFormValue('treadDepthField', '');
        await fetchOosTires();
        document.getElementById('brandNumber')?.focus();
      }
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          setParsedAddingErrors(response.data.state);
          if (response.data.state) {
            return addNotification({
              text: `Adding form error. ${response.data.state[0].message}`,
              type: MessageBarType.error,
            });
          } else {
            return addNotification({
              text: `Adding form error. ${response.data.desc.message}`,
              type: MessageBarType.error,
            });
          }
        case 499:
          if (response.data.state) {
            return addNotification({
              text: `Adding form error. ${response.data.state[0].message}`,
              type: MessageBarType.error,
            });
          }
          setTireToBeAdded((tireToBeAdded: any) => ({ ...tireToBeAdded }));
          return addNotification({
            text: response.data.message,
            type: MessageBarType.error,
          });
        default:
          return addNotification({
            text: response.data.message,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSelect = (e: any, itemId: any) => {
    if (e.target.checked) {
      setState((prev: any) => ({ ...prev, selectedItems: [...state.selectedItems, itemId] }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: state.selectedItems.filter(row => row !== itemId) }));
    }
  };

  const handleSelectAll = (e: any, items: Array<any>) => {
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedItems: items })) : setState((prev: any) => ({ ...prev, selectedItems: [] }));
  };

  const parseAddingErrors = (field: string) => {
    const customError = parsedAddingErrors?.filter((error: { field: string; }) => error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  const validateABField = (value: string) => {
    return inspectionCodesDMG.find(item => (item.inspCode.includes(value)));
  };

  const parseErrors = (id: string, field: string) => {
    const customError = parsedErrors?.filter((error: { id: string; field: string; }) => error.id === id && error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  const highlightRowIfError = (item: any) => {
    if (parsedErrors.length > 0) {
      return parsedErrors.filter((error: { id: any; }) => error.id === item.id).length > 0;
    }
  };

  const onChangeField = async (field: any, value: any, regExp?: RegExp) => {
    if (field == 'disposition') {
      await getInspectionCodesDMG(value.key);
      setIsTDRequired(false);
      setAFieldVal('');
      setBFieldVal('');
      setTireToBeAdded((prev: any) => ({ ...prev, ['a']: '' }));
      setTireToBeAdded((prev: any) => ({ ...prev, ['b']: '' }));
      setTireToBeAdded((prev: any) => ({ ...prev, ['treadDepth']: '' }));
    }
    else if (field == 'a')
    {
      setAFieldVal(value);
      setIsAFieldValidated(true);

      if (!validateABField(value)) {
        toggleErrorDialog();
        setAFieldVal('');
      }
      else {
        (value.length == 2 && inspectionCodesDMG.find(item => (item.inspCode == value)).td == 'Y' && !tireToBeAdded.treadDepth) ? setIsTDRequired(true) : setIsTDRequired(false);
      }
    }
    else if (field == 'b')
    {
      setBFieldVal(value);
      setIsAFieldValidated(false);
        
      if (!validateABField(value)) {
        toggleErrorDialog();
        setBFieldVal('');
      }
    }
    setTireToBeAdded((prev: any) => ({ ...prev, [field]: regExp ? (regExp.test(value) ? value : prev[field]) : value }));
  };

  const setNewSelectedPlant = (plant: any) => {
    setTireToBeAdded((prev: any) => ({ ...prev, plant }));
    toggleShowPlantsModal();
  };

  const setA = (a: any) => {
    setTireToBeAdded((prev: any) => ({ ...prev, a }));
    setAFieldVal(a);
    inspectionCodesDMG.find(item => (item.inspCode == a)).td == 'Y' && !tireToBeAdded.treadDepth ? setIsTDRequired(true) : setIsTDRequired(false);
    toggleShowAModal();
  };

  const setB = (b: any) => {
    setTireToBeAdded((prev: any) => ({ ...prev, b }));
    setBFieldVal(b);
    toggleShowBModal();
  };

  const isItemUntouched = (itemToCheck: any) => {
    const untouchedItem = untouchedItems.find(({ id }: any) => id === itemToCheck.id);
    if (isNil(untouchedItem))
      return false;
    return isEqual(itemToCheck, untouchedItem);
  };

  const plantNumberInputText = (plantNo: string) => {
    if (plantNo.replace(/ /g, '').length) {
      getPlants({ plantNo });
    } else {
      getPlants({ plantNo: '' });
      onChangeField('plant', null);
    }
  };

  const getPlants = debounce(async (plantNumber) => {
    fetchPlants(plantNumber);
  }, 1000);

  const searchRecordWithError = async (postTableId: any, resp?: boolean) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      setPostTableId(postTableId);
      const response = resp ? 'Y' : 'N';
      setState((prev: any) => ({ ...prev, selectedItems: [state.items.find((item: any) => item.id == postTableId)?.id] }));
      setState((prev) => ({ ...prev, items: prev.items.map(el => el.id === postTableId ? { ...el, response } : el) }));
      setPostTableId(null);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `OOS fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  useKeyPress({
    handleAdd: handleAddTire,
    handleSubmit: handleSubmit,
  });

  useEffect(() => {
    fetchOosTires();
  }, [
    paginationProps.current,
    countOnPage,
    columnsState,
    customerId,
    locationId,
    filters.milesTo,
  ]);

  useEffect(() => {
    if (['20'].indexOf(tireToBeAdded.disposition.key) === -1) {
      setTireToBeAdded((prev: any) => ({ ...prev, plant: null }));
    }
  }, [tireToBeAdded.disposition]);


  useEffect(() => {
    mountingProcess();
  }, []);

  useEffect(() => {

    setTireToBeAdded({
      ...tireToBeAdded,
      prefix: tiresFormFieldsGroup.getFieldFormValue('prefixField'),
      brandNumber: tiresFormFieldsGroup.getFieldFormValue('brandNumberField'),
      suffix: tiresFormFieldsGroup.getFieldFormValue('suffixField'),
    });

    if (!tiresFormFieldsGroup.getFieldFormValue('prefixField') ||
      !tiresFormFieldsGroup.getFieldFormValue('brandNumberField')) return;

  }, [
    tiresFormFieldsGroup.getFieldFormValue('prefixField'),
    tiresFormFieldsGroup.getFieldFormValue('brandNumberField'),
    tiresFormFieldsGroup.getFieldFormValue('suffixField'),
  ]);

  const disableAddBtn = !tireToBeAdded.oosDate ||
    !tireToBeAdded.docNumber ||
    !tireToBeAdded.disposition ||
    (tireToBeAdded.disposition && ['20'].indexOf(tireToBeAdded.disposition.key) !== -1 && !tireToBeAdded.plant) ||
    !tiresFormFieldsGroup.getFieldFormValue('prefixField') ||
    !tiresFormFieldsGroup.getFieldFormValue('brandNumberField') ||
    (!tireToBeAdded.a || !inspectionCodesDMG.find(item => (item.inspCode == tireToBeAdded.a))) || 
    (tireToBeAdded.b && !inspectionCodesDMG.find(item => (item.inspCode == tireToBeAdded.b))) ||
    (isTDRequired && !tireToBeAdded.treadDepth) ||
    docClaimNo.length > 10 || !userPermissions.isWrite;

  return (
    <>
      <div className="ms-Grid">
        <div className={classNames('ms-Grid-row', styles.headingBlock)}>
          <div className={classNames(styles.tiresAddingBlock, 'ms-Grid-col', 'ms-sm12')}>
            <div>
              <div>
                <MaskedTextField
                  id="oosDate"
                  mask="99/99/9999"
                  label="Enter OOS Date"
                  value={tireToBeAdded.oosDate}
                  onChange={(e, oosDate) => onChangeField('oosDate', oosDate)}
                  onRenderPrefix={() => <Icon iconName="Calendar" />}
                  errorMessage={parseAddingErrors('oosDate')}
                  required
                />
              </div>
              <div>
                <TextField
                  id="docNumber"
                  label="Doc/Claim #"
                  prefix="#"
                  value={tireToBeAdded.docNumber}
                  onChange={(e, docNumber) => { setDocClaimNo(docNumber); onChangeField('docNumber', docNumber, /^[a-zA-Z0-9]*$/);}}
                  errorMessage={parseAddingErrors('docNumber') || docClaimNo.length > 10 ? 'Doc/Claim # must be at least 10 characters.' : ''}
                  required
                />
              </div>
              <div>
                <Dropdown
                  id="disposition"
                  label="Disposition of Tires"
                  selectedKey={tireToBeAdded.disposition.key || null}
                  onChange={(e, disposition) => onChangeField('disposition', disposition)}
                  options={disposition || []}
                  errorMessage={parseAddingErrors('disposition')}
                  required
                />
              </div>
              <div className={styles.autocompleteWithTableBlock}>
                <AutocompleteInput
                  label="Plant #"
                  value={get(tireToBeAdded, 'plant', null)}
                  list={plantList}
                  chooseCurrentItem={(plant: any) => onChangeField('plant', plant)}
                  disabled={['20'].indexOf(tireToBeAdded.disposition.key) === -1}
                  required={['20'].indexOf(tireToBeAdded.disposition.key) !== -1}
                  errorMessage={parseAddingErrors('plant')}
                  emptyExpanded
                  textValue={plantNumberInputText}
                />
                <IconButton
                  id="searchPlants"
                  iconProps={{ iconName: 'Search' }}
                  onClick={toggleShowPlantsModal}
                  disabled={['20'].indexOf(tireToBeAdded.disposition.key) === -1}
                />
              </div>
            </div>
            <div>
              <div>
                <div>
                  <TextField
                    {...tiresFormFieldsGroup.getFieldForm('prefixField')}
                    maxLength={4}
                    errorMessage={parseAddingErrors('prefix')}
                    required
                  />
                </div>
                <div>
                  <TextField
                    {...tiresFormFieldsGroup.getFieldForm('brandNumberField')}
                    maxLength={6}
                    errorMessage={parseAddingErrors('brandNumber')}
                    required
                  />
                </div>
                <div>
                  <TextField
                    {...tiresFormFieldsGroup.getFieldForm('suffixField')}
                    maxLength={5}
                    errorMessage={parseAddingErrors('suffix')}
                  />
                </div>
              </div>
              <div>
                <div>
                  <TextField
                    label="A"
                    value={aFieldVal}
                    onChange={(e, a) => onChangeField('a', a)}
                    errorMessage={parseAddingErrors('a')}
                    disabled = {inspectionCodesDMG.length === 0}
                    required
                  />
                  <IconButton
                    id="searchA"
                    iconProps={{ iconName: 'Search' }}
                    onClick={toggleShowAModal}
                  />
                </div>
                <div>
                  <TextField
                    label="B"
                    value={bFieldVal}
                    onChange={(e, b) => onChangeField('b', b)}
                    disabled = {inspectionCodesDMG.length === 0}
                    errorMessage={parseAddingErrors('b')}
                  />
                  <IconButton
                    id="searchB"
                    iconProps={{ iconName: 'Search' }}
                    onClick={toggleShowBModal}
                  />
                </div>
              </div>
              <div>
                <div>
                  <TextField
                    label="Disp."
                    value={tireToBeAdded.disposition.key}
                    disabled
                  />
                </div>
                <div>
                  <TextField
                    label="TD"
                    suffix="/32"
                    value={tireToBeAdded.treadDepth}
                    onChange={(e, treadDepth) => onChangeField('treadDepth', treadDepth)}
                    errorMessage={parseAddingErrors('treadDepth')}
                    max={32}
                    min={0}
                    required={isTDRequired}
                  />
                </div>
              </div>
              <div>
                <div>
                  <Toggle
                    label="Override?"
                    checked={tireToBeAdded.override}
                    onChange={(e, override) => onChangeField('override', override)}
                  />
                </div>
                <div>
                  <Toggle
                    label="Bill?"
                    checked={tireToBeAdded.bill}
                    onChange={(e, bill) => onChangeField('bill', bill)}
                    disabled={!tireToBeAdded.override}
                  />
                </div>
                <div>
                  <Toggle
                    label="Include OOS Avg?"
                    checked={tireToBeAdded.includeOosAverage}
                    onChange={(e, includeOosAverage) => onChangeField('includeOosAverage', includeOosAverage)}
                    disabled={!tireToBeAdded.override}
                  />
                </div>
              </div>
              <div>
                <div>
                  <PrimaryButton
                    id="addButton"
                    disabled={disableAddBtn}
                    onClick={handleAddTire}
                    text="Add"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.verticalScrollable)}>
            <div className={styles.tableHeading}>
              <div>
                <Text variant="xLarge" className={styles.highlight}>Disposed Tires</Text>
                <SeparatorGy vertical />
                <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
              </div>
            </div>
            <div className={styles.tableHeading}>
              <div className={styles.tiresFilteringBlock}>
                View Tires with {'<'}&nbsp;
                <TextField
                  id="searchString"
                  placeholder="Enter search string"
                  value={filters.milesTo}
                  onChange={(e, miles) => setFilters((prev: any) => ({ ...prev, milesTo: isNaN(Number(miles)) || Number(miles) == 0 ? null : Number(miles) }))}
                />
                &nbsp;Miles
              </div>
              <div>
                <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                <Dropdown
                  options={pageSizes.map(pageSize => ({
                    key: pageSize,
                    text: pageSize.toString(),
                  }))}
                  defaultSelectedKey={pageSizes[0]}
                  selectedKey={countOnPage?.key}
                  onChange={onChangeCountOnPage}
                />
                <SeparatorGy vertical />
                <Text variant="large" className={styles.highlight}>{state.selectedItems.length} items selected</Text>
                <SeparatorGy vertical />
                <IconButton
                  id="toggleDeletingConfirmationButton"
                  disabled={!state.selectedItems.length || !userPermissions.isWrite}
                  iconProps={{ iconName: 'Delete' }}
                  onClick={toggleDeletingConfirmation}
                />
              </div>
            </div>
            <div className={styles['table-wrapper']}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th></th>
                    {
                      columnsState.map(item => (
                        <th
                          key={item.name}
                          className={
                            item.isSorted && item.isSortedDescending ?
                              styles.descending :
                              item.isSorted && !item.isSortedDescending ? styles.ascending : undefined
                          }
                          onClick={() => onColumnClick(item)}
                        >
                          {item.name}
                        </th>
                      ))
                    }
                    <th></th>
                    <th>
                      <div className={styles.round}>
                        <input
                          type="checkbox"
                          id="all"
                          checked={state.items.length !== 0 && (state.selectedItems.length === +countOnPage.key || state.selectedItems.length === state.items.length)}
                          onChange={(e) => handleSelectAll(e, state.items.map(({ id }) => id))}
                        />
                        <label htmlFor="all"></label>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    state.items.map(item => (
                      <tr key={item.id}
                        className={
                          classNames(
                            styles.trBasic,
                            {
                              [styles.trSelected]: state.selectedItems.includes(item.id),
                              [styles.trError]: highlightRowIfError(item),
                            },
                          )
                        }
                      >
                        <td>
                          <IconButton
                            id="viewButton"
                            iconProps={{ iconName: 'View' }}
                            className={classNames(styles.viewButton)}
                            onClick={() => openViewTireDetailsWindow(item)}
                          />
                        </td>
                        <td>{item.prefix}</td>
                        <td>{item.brandNumber}</td>
                        <td>{item.suffix}</td>
                        <td>
                          <TextField
                            className={styles.fixedInput}
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.a}
                            onChange={(e, a) => setTireToBeEdited(item.id, 'a', a)}
                            errorMessage={parseErrors(item.id, 'a')}
                          />
                        </td>
                        <td>
                          <TextField
                            className={styles.fixedInput}
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.b}
                            onChange={(e, b) => setTireToBeEdited(item.id, 'b', b)}
                            errorMessage={parseErrors(item.id, 'b')}
                          />
                        </td>
                        <td>
                          <Dropdown
                            defaultSelectedKey={item.dispositionCode}
                            options={dispositionCodes || []}
                            onChange={(e, dispositionCode) => setTireToBeEdited(item.id, 'dispositionCode', dispositionCode?.key)}
                          />
                        </td>
                        <td>
                          <TextField
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.treadDepth}
                            onChange={(e, treadDepth) => setTireToBeEdited(item.id, 'treadDepth', treadDepth)}
                            errorMessage={parseErrors(item.id, 'treadDepth')}
                            max={32}
                            min={0}
                          />
                        </td>
                        <td>
                          <TextField
                            className={styles.fixedInput}
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.oosDoc}
                            onChange={(e, oosDoc) => setTireToBeEdited(item.id, 'oosDoc', oosDoc, /^[a-zA-Z0-9]*$/)}
                            errorMessage={parseErrors(item.id, 'oosDoc')}
                          />
                        </td>
                        <td>
                          <MaskedTextField
                            className={styles.fixedInput}
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.oosDate}
                            mask="99/99/9999"
                            onChange={(e, oosDate) => setTireToBeEdited(item.id, 'oosDate', oosDate)}
                            errorMessage={parseErrors(item.id, 'oosDate')}
                          />
                        </td>
                        <td>{item.addDocNo}</td>
                        <td>{item.addDocDate && moment(item.addDocDate).format('MM/DD/YYYY')}</td>
                        <td>{item.plant}</td>
                        <td>{item.tireClass}</td>
                        <td>{item.typeCode}</td>
                        <td>{item.currentVehicle}</td>
                        <td>{item.currentPosition}</td>
                        <td>{item.currentOn && moment(item.currentOn).format('MM/DD/YYYY')}</td>
                        <td>{item.previousVehicle}</td>
                        <td>{item.previousPosition}</td>
                        <td>{item.previousOff && moment(item.previousOff).format('MM/DD/YYYY')}</td>
                        <td>
                          <Toggle
                            checked={item.billable}
                            disabled
                          />
                        </td>
                        <td>
                          <Toggle
                            id="includeOosAvg"
                            checked={item.includeOosAvg}
                            disabled
                          />
                        </td>
                        <td>{item.miles}</td>
                        <td>
                          {
                            isItemUntouched(item) ?
                              <MessageBar
                                messageBarType={MessageBarType.success}
                                isMultiline={false}
                              >
                                Submitted
                              </MessageBar> :
                              <MessageBar
                                messageBarType={MessageBarType.warning}
                                isMultiline={false}
                              >
                                Unsubmitted
                              </MessageBar>
                          }
                        </td>
                        <td>
                          <div className={styles.round}>
                            <input type="checkbox" id={item.id} checked={state.selectedItems.includes(item.id)} onChange={(e) => handleSelect(e, item.id)} />
                            <label htmlFor={item.id}></label>
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <SeparatorGy />
            <Pagination {...paginationProps} />
          </div>
        </div>
        <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
          <DefaultButton
            id='printExportBtn'
            onClick={toggleShowPrintExport}
            text="Print/Export"
          />
          <DefaultButton
            onClick={toggleShowErrorsModal}
            text="View Errors"
          />
          <PrimaryButton
            id="saveButton"
            onClick={handleSave}
            disabled={!userPermissions.isWrite}
            text="Save"
          />
          <PrimaryButton
            id="submitButton"
            onClick={handleSubmit}
            disabled={!userPermissions.isWrite}
            text="Submit"
          />
        </div>
      </div>
      {detailsVisible && <ViewTireDetailsModal isOpened={detailsVisible} onDismiss={toggleDetailsVisible} />}
      <SelectingModal
        isOpen={showPlantsModal}
        title="Available Plants"
        selectingList={plants.map(plant => ({ ...plant, id: plant.mCbPlantid }))}
        onDismiss={toggleShowPlantsModal}
        onSubmit={setNewSelectedPlant}
        columns={plantsColumns}
        preselectedKey={get(tireToBeAdded, 'plant', null)}
      />
      <SelectingModal
        isOpen={showAModal}
        title="Available Inspection Codes"
        selectingList={inspectionCodesDMG.map(code => ({ id: code.inspCode, billable: code.billable, inspCode: code.inspCode, description: code.description, comments: code.comments }))}
        onDismiss={toggleShowAModal}
        onSubmit={setA}
        columns={inspectionCodesColumns}
        preselectedKey={get(tireToBeAdded, 'a', null)}
      />
      <SelectingModal
        isOpen={showBModal}
        title="Available Inspection Codes"
        selectingList={inspectionCodesDMG.map(code => ({ id: code.inspCode, billable: code.billable, inspCode: code.inspCode, description: code.description, comments: code.comments }))}
        onDismiss={toggleShowBModal}
        onSubmit={setB}
        columns={inspectionCodesColumns}
        preselectedKey={get(tireToBeAdded, 'b', null)}
      />
      <ErrorsModal
        searchError={searchRecordWithError}
        isModalOpen={showErrorsModal}
        hideModal={toggleShowErrorsModal}
        fetchErrorsService={apiService.postOosTires.getErrors}
        shouldConfirm
      />
      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to delete ${state.selectedItems.length} items?`,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={!isErrorDialogVisible}
        onDismiss={toggleErrorDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Error',
          subText: 'Please select a correct inspection code in the modal window.',
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="okButton" onClick={handleOk} text="OK" />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={!isTDErrorDialogVisible}
        onDismiss={toggleTDErrorDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Error',
          subText: 'This Disposition and Inspection code combination requires a tread depth in order to proceed.',
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="okButton" onClick={toggleTDErrorDialog} text="OK" />
        </DialogFooter>
      </Dialog>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default PostOosTiresTab;
