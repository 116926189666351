
import { Icon, IRenderFunction, ITextFieldProps, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import { get, upperCase } from 'lodash';
import { FC, FocusEventHandler, ReactElement, useEffect, useRef, useState } from 'react';

interface IAutocompleteInputProps {
  list: Array<any>;
  value?: string;
  initialValue?: string;
  chooseCurrentItem?: (item: any) => void;
  prefix?: string;
  onRenderPrefix?: IRenderFunction<ITextFieldProps>;
  prefixIcon?: string;
  disabled?: boolean;
  emptyExpanded?: boolean;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  required?: boolean;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  upperCase?: boolean;
  textValue?: (textValue: any) => void; // if need text value in input
  isLoading?: boolean
}

const AutocompleteInput: FC<IAutocompleteInputProps> = ({
  list,
  chooseCurrentItem,
  prefixIcon,
  value,
  upperCase,
  emptyExpanded,
  textValue,
  initialValue,
  isLoading = false,
  ...inputProps
}): ReactElement => {
  const [expanded, setExpanded] = useState<any>(false);
  const [currentFocus, setCurrentFocus] = useState<any>(0);
  const [currentElement, setCurrentElement] = useState<any>('');
  const [searchResult, setSearchResult] = useState<any>([]);

  const autocompleteEl = document.getElementById('#autocomplete');

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setExpanded(false);
        setCurrentFocus(0);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);

  useEffect(() => {
    if (autocompleteEl) {
      autocompleteEl.addEventListener('mouseover', () => setCurrentFocus(-1));
    }
  }, [autocompleteEl]);

  useEffect(() => {
    setSearchResult(list);
  }, [list]);

  useEffect(() => {
    if (initialValue) {
      setCurrentElement(initialValue);
    } else {
      setCurrentElement(
        get(list.find(({ key }) => value === key), 'text', ''),
      );
      setExpanded(false);

      if (textValue) {
        textValue(
          get(list.find(({ key }) => value === key), 'text', ''),
        );
      }
    }


    const result = value ? list.filter(
      ({ key }) => key === value.toString())
      : list;
    setSearchResult(result);
  }, [value, initialValue]);

  const openDropDown = () => {
    setExpanded(true);
  };

  const closeDropDown = () => {
    setExpanded(false);
  };

  const chooseActive = (currentItem: any) => {
    if (currentItem) {
      setCurrentElement(currentItem.text);
      if (textValue) {
        textValue(currentItem.text);
      }
      chooseCurrentItem && chooseCurrentItem(currentItem.key);
      closeDropDown();
    }
  };

  const onRenderPrefix = (
    props: ITextFieldProps | undefined,
  ): JSX.Element => (
    <div>
      {prefixIcon && <Icon iconName={prefixIcon} aria-hidden="true" />}
      <span>{props?.prefix}</span>
    </div>
  );

  const scrollAutocompleteList = (futureFocus: any) => {
    const autocompleteList = !isLoading && expanded ? document.getElementById('autocomplete-list') : null;
    if (autocompleteList !== null) {
      autocompleteList.scrollTop = 32 * (futureFocus - 2);
    }
  };

  const customerPrefixHandleKeyPress = (e: any) => {
    if (e.nativeEvent.keyCode === 38) {
      if (currentFocus > 0) {
        scrollAutocompleteList(currentFocus - 1);
        setCurrentFocus(currentFocus - 1);
      }
    } else if (e.nativeEvent.keyCode === 40) {
      if (currentFocus < searchResult.length - 1) {
        scrollAutocompleteList(currentFocus + 1);
        setCurrentFocus(currentFocus + 1);
      }
    } else if (e.nativeEvent.keyCode === 9) {
      closeDropDown();
    } else if (e.nativeEvent.keyCode === 13) {
      chooseActive(searchResult[currentFocus]);
      closeDropDown();
    }
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    openDropDown();
    const result = value.length ? list.filter(
      (el) => el?.text?.toLowerCase()?.includes(value.toLowerCase()),
    ) : list;
    setCurrentElement(value);
    if (textValue) {
      textValue(value);
    }
    setSearchResult(result);
    setCurrentFocus(0);
    scrollAutocompleteList(0);
  };

  if (inputProps.prefix || prefixIcon) inputProps.onRenderPrefix = onRenderPrefix;
  if (emptyExpanded) {
    inputProps.onFocus = () => {
      setExpanded(true);
    };
  }

  return (
    <div className="autocomplete" ref={containerRef} >
      <TextField
        type="text"
        value={upperCase ? currentElement.toUpperCase() : currentElement}
        onChange={(e) => handleChange(e)}
        onKeyDown={customerPrefixHandleKeyPress}
        autoComplete="off"
        {...inputProps}
      />

      {
        expanded && (
          <>
            {
              (isLoading) && (
                <div className="autocomplete-items">
                  <Spinner size={SpinnerSize.large} />
                </div>
              )
            }
            {!isLoading && <div
              className={
                inputProps.errorMessage ?
                  'autocomplete-items-error' :
                  'autocomplete-items'
              }
            >
              {
                searchResult.map((el: any, i: number) => (
                  <div
                    data-testid={el.key}
                    key={el.key}
                    className={currentFocus === i ? 'active' : ''}
                    onClick={() => chooseActive(el)}
                  >
                    {el.text}
                  </div>
                ))
              }
            </div>
            }
          </>
        )}
    </div>
  );
};

AutocompleteInput.defaultProps = {
  emptyExpanded: false,
};

export default AutocompleteInput;