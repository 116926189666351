export const tableColumns = [
  {
    key: 'column0',
    name: 'Pos',
    fieldName: 'pos',
  },
  {
    key: 'column9',
    name: 'FUNDO',
    fieldName: 'fundo',
  },
  {
    key: 'column1',
    name: 'Prefix',
    fieldName: 'pfx',
  },
  {
    key: 'column2',
    name: 'Brand Number',
    fieldName: 'brandNo',
  },
  {
    key: 'column3',
    name: 'Suffix',
    fieldName: 'sfx',
  },
  {
    key: 'column4',
    name: 'FCot',
    fieldName: 'fcot',
  },
  {
    key: 'column5',
    name: 'From',
    fieldName: 'from',
  },
  {
    key: 'column6',
    name: 'To',
    fieldName: 'to',
  },
  {
    key: 'column7',
    name: 'Units',
    fieldName: 'units',
  },
  {
    key: 'column8',
    name: 'Location',
    fieldName: 'locCode',
  },
];

export const mockVehicleNumbers = {
  data: [
    '11111',
    '22222',
    '33333',
  ],
  total: {
    all: 3,
    found: 3,
  },
};

export const mockVehicles = {
  desc: {
    message: 'string',
  },
  data: {
    make: 'string',
    model: 'string',
    status: 'string',
    asOf: '2021-10-22T02:33:02.362Z',
    asOfLastMileageRun: '2021-10-22T02:33:02.362Z',
    comments: 'string',
    total: {
      all: 1,
      found: 1,
    },
    data: [
      {
        id: 'string',
        locationId: 'string',
        vehId: 'string',
        pos: 'string',
        pfx: 'string',
        brandNo: 0,
        sfx: 'string',
        fcot: 'string',
        from: '2021-10-22T02:33:02.362Z',
        to: '2021-10-22T02:33:02.362Z',
        units: 0,
        locCode: 'string',
      },
    ],
  },
};