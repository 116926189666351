import { ICustomer } from '../../components/Common/Search/ICustomer';

export interface IResult {
  prefix?: string,
  businessName?: string,
  abbrName?: string,
  billToCity?: string,
  billToState?: string,
  billToCountry?: string,
  billToZip?: string,
  id?: string,
  customerNumber?: string,
  customerName?: string,
  regionCode?: string
}

export interface IFormData {
  custPrefix: string,
  custNumber: string,
  custName: string,
  country: string,
  city: string,
  state: string,
  zipcode: string,
  bName: string,
  abbrname: string,
  regionCode: string,
}

export interface IAutoComplete {
  key?: string | number | undefined | null,
  text?: string | null | undefined
}

export interface ISearchCustomerForm {
  customersList: ICustomer[];
  isLoading: boolean;
  fetchData: (filter: any, pagination?: any, sort?: any) => any;
  onSearchFormClick: (filter: any) => void;
  formName: string;
}

export const typeOfStatuses = [  
  { key: '0', text: 'ANY STATUS' },
  { key: '1', text: 'UNDER PREPARATION' },
  { key: '2', text: 'GENERATED' },
  { key: '3', text: 'AUTHORIZED' },
  { key: '4', text: 'ACTIVE' },
  { key: '5', text: 'CLOSED OUT' },
];

