import React from 'react';
import CustomerTabsComponent from './Components';

export const CustomerView: React.FC = () => {
  return (
    <div>
      <CustomerTabsComponent />
    </div>
  );
};
