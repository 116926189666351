import { recordTestInstance } from '../axios';

const downloadTemplate = (serviceName: string) =>
  recordTestInstance.post('/download-template', { serviceName }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

export default {
  downloadTemplate,
};