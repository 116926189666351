import { recordTestInstance, exportTestInstance } from '../axios';

const deletePostByFootprint = (ids: Array<any>) =>
  recordTestInstance.delete('/tire-change/footprint', { data: { ids } });

const getErrors = (pagination: any, sortOrder: any, customerId: string, locationId: string, vno: string) =>
  recordTestInstance.post('/tire-change/footprint/error', { pagination, sortOrder, customerId, locationId, vno });

const get = (pagination: any, sortOrder: any, customerId: string, locationId: string, vehicleNo: string) =>
  recordTestInstance.post('/tire-change/footprint', { pagination, sortOrder, customerId, locationId, filters: { vehicleNo } });

const save = (data: Array<any>, customerId: string, locationId: string) =>
  recordTestInstance.post('/tire-change/footprint/save', { data, customerId, locationId });

const submit = (data: Array<any>, customerId: string, locationId: string) =>
  recordTestInstance.post('/tire-change/footprint/submit', { data, customerId, locationId });

const printExcel = (input: any, headerFields: Array<any>) =>
  exportTestInstance.post('/tire-change/footprint/export-excel', { input, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (input: any, headerFields: Array<any>) =>
  exportTestInstance.post('/tire-change/footprint/export-pdf', { input, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });


export default {
  delete: deletePostByFootprint,
  getErrors,
  get,
  save,
  submit,
  printExcel,
  printPdf,
};
