import { IconButton, PrimaryButton, TextField, Text, MessageBarType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useNotifications from '../../../../../../../../hooks/useNotifications';
import AutocompleteInput from '../../../../../../../../shared/AutocompleteInput';
import BrandModalComponent from '../../../../../../../../shared/BrandSelectionModalComponent';
import TypeCodeModalComponent from '../../../../../../../../shared/TypeCodeModalComponent';
import LoadingScreen from '../../../../../../../LoadingScreen/LoadingScreen';
import SeparatorGy from '../../../../../../../SeparatorGy/SeparatorGy';
import { emptyTypeCode } from './consts';
import { INewOrderDetailsProps } from './INewOrderDetailsProps';
import { INewOrderDetailsState } from './INewOrderDetailsState';
import apiService from '../../../../../../../../api';
import styles from './NewOrderDetails.module.scss';
import { customerSelector } from '../../../../../../../../redux/recordKeepingSlice';
import { debounce } from 'lodash';
import { contractInformationSelector } from '../../../../../../../../redux/tireOrderingSlice';

const NewOrderDetails: FC<INewOrderDetailsProps> = ({
  typeCodes,
  setTypeCodes,
  orderToBeAdded,
  setOrderToBeAdded,
  orderItemToBeAdded,
  setOrderItemToBeAdded,
}) => {
  const [state, setState] = useState<INewOrderDetailsState>({
    typeCodes: [],
    loading: false,
  });

  const { addNotification } = useNotifications();
  const { id: customerId, prefix: brandPrefix, corrAddressCountry: country } = useSelector(customerSelector);
  
  const contractInformation = useSelector(contractInformationSelector);

  const [currentTypeCode, setCurrentTypeCode] = useState<any>(emptyTypeCode);
  const [contractBrandPrefix, setContractBrandPrefix] = useState('');

  const [showTypeCodeModal, { toggle: toggleShowTypeCodeModal }] = useBoolean(false);
  const [showBrandModal, { toggle: toggleShowBrandModal }] = useBoolean(false);
  const [isSaveDisabled, { setTrue: disableSaving, setFalse: enableSaving }] = useBoolean(true);

  const fetchTypeCode = async (typeCode?: string) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.typeCodeAPI.getTypeCodeAuthList(
        null,
        {},
        { searchString: typeCode, originalOrRetread: 'R', country: country },
        customerId,
      );
      const items = data.data;
      setState((prev: any) => ({ ...prev, typeCodes: items }));
      setContractBrandPrefix(data.contractBrandPrefix);
      setTypeCodes(items);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching type codes error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSaveOrder = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await apiService.tireOrders.saveRetreadOrder(
        orderToBeAdded,
        orderItemToBeAdded,
        true,
      );
      setState(prev => ({...prev, orderId: data.order.id}));
      setOrderToBeAdded((prev: any) => ({...prev, id: data.order.id}));
      addNotification({
        text: 'Order tire was successfully saved.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Order saving error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const setTypeCode = (typeCodeIndex: any) => {
    setOrderToBeAdded((prev: any) => ({ ...prev, typeCode: state.typeCodes[typeCodeIndex].typeCode }));
    setCurrentTypeCode(state.typeCodes[typeCodeIndex]);
  };

  const typeCodeInputText = (typeCode: string) => {
    if (typeCode.length > 0) {
      getTypeCode(typeCode);
    }
  };

  const getTypeCode = debounce(async (typeCode) => {
    fetchTypeCode(typeCode);
  }, 1000);

  const handleSelectTypeCode = (rowSelected: any) => {
    setOrderToBeAdded((prev: any) => ({ ...prev, typeCode: rowSelected[0].typeCode }));
    fetchTypeCode(rowSelected[0].typeCode);
    setCurrentTypeCode(rowSelected[0]);
    toggleShowTypeCodeModal();
  };

  const selectBrandNumbers = async (brand: any) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.brandAPI.selectBrandNumber(
        brand,
      );
      setOrderItemToBeAdded((prev: any) => ({ 
        ...prev, 
        beginBrandPrefix: contractBrandPrefix, 
        beginBrandNumber: data.brandBegin,
        endBrandPrefix: contractBrandPrefix,
        endBrandNumber: data.brandEnd,
        quantity: data.quantity,
      }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: ` Brand Numbers selecting error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSelectBrand = (rowSelected: any) => {
    selectBrandNumbers(rowSelected);
    toggleShowBrandModal();
  };

  useEffect(() => {
    if (
      orderToBeAdded.typeCode &&
      orderToBeAdded.billToSundry &&
      orderItemToBeAdded.beginBrandPrefix &&
      orderItemToBeAdded.beginBrandNumber &&
      orderItemToBeAdded.endBrandPrefix &&
      orderItemToBeAdded.endBrandNumber &&
      orderItemToBeAdded.warehouse &&
      orderItemToBeAdded.quantity
    ) {
      enableSaving();
    } else {
      disableSaving();
    }
  }, [orderToBeAdded, orderItemToBeAdded]);

  useEffect(() => {
    const typeCodeIndex = typeCodes.findIndex((value: any) => value.typeCode === orderToBeAdded.typeCode);
    typeCodeIndex >= 0 && setCurrentTypeCode(typeCodes[typeCodeIndex]);
    fetchTypeCode();
  }, []);

  return (
    <>
      <div className={styles.headingBlock}>
        <Text variant="large" className={styles.highlight}>Type code details</Text>
        <SeparatorGy />
        <div>
          <div>
            <Text variant="large" className={styles.highlight}>{currentTypeCode.productCode ? currentTypeCode.productCode : 'N/A'}</Text>
            <Text variant="medium" className={styles.highlight}>Product Code</Text>
          </div>
          <div>
            <Text variant="large" className={styles.highlight}>{currentTypeCode.tireSize ? currentTypeCode.tireSize : 'N/A'}</Text>
            <Text variant="medium" className={styles.highlight}>Tire Size</Text>
          </div>
          <div>
            <Text variant="large" className={styles.highlight}>{currentTypeCode.description ? currentTypeCode.description : 'N/A'}</Text>
            <Text variant="medium" className={styles.highlight}>Description</Text>
          </div>
          <div>
            <Text variant="large" className={styles.highlight}>{currentTypeCode.rubberType ? currentTypeCode.rubberType : 'N/A'}</Text>
            <Text variant="medium" className={styles.highlight}>Type of rubber</Text>
          </div>
        </div>
        <SeparatorGy />
      </div>
      <div className={styles.container}>
        <div>
          <div>
            <AutocompleteInput
              label="Type Code"
              initialValue={orderToBeAdded.typeCode}
              list={state.typeCodes.map((typeCode: any, index: number) => ({
                key: index,
                text: typeCode.typeCode,
              }))}
              emptyExpanded
              required
              chooseCurrentItem={(typeCode: any) => setTypeCode(typeCode)}
              textValue={typeCodeInputText}
              upperCase
            />
            <IconButton
              id='searchPrefix'
              onClick={toggleShowTypeCodeModal}
              iconProps={{ iconName: 'Search' }}
            />
          </div>
          <div>
            <TextField
              label="QTY"
              value={orderItemToBeAdded.quantity}
              disabled
            />
          </div>
        </div>
        <div>
          <div>
            <TextField
              label="Prefix begin"
              maxLength={4}
              value={orderItemToBeAdded.beginBrandPrefix}
              disabled
            />
          </div>
          <div>
            <TextField
              label="Brand begin"
              maxLength={6}
              value={orderItemToBeAdded.beginBrandNumber}
              disabled
            />
          </div>
          <div>
            <TextField
              label="Suffix begin"
              maxLength={5}
              value={orderItemToBeAdded.beginBrandSuffix}
              disabled
            />
          </div>
          <div className={styles.searchButton}>
            <IconButton
              id='searchPrefix'
              onClick={toggleShowBrandModal}
              iconProps={{ iconName: 'Search' }}
            />
          </div>
        </div>
        <div>
          <div>
            <TextField
              label="Prefix end"
              maxLength={4}
              value={orderItemToBeAdded.endBrandPrefix}
              disabled
            />
          </div>
          <div>
            <TextField
              label="Brand end"
              maxLength={6}
              value={orderItemToBeAdded.endBrandNumber}
              disabled
            />
          </div>
          <div>
            <TextField
              label="Suffix end"
              maxLength={5}
              value={orderItemToBeAdded.endBrandSuffix}
              disabled
            />
          </div>
        </div>
        <div className={styles.buttonsWrapper}>
          <div>
            <PrimaryButton 
              text="Add"
              onClick={handleSaveOrder} 
              disabled={contractInformation?.activeStatus === 'NORMAL RUNOUT' || isSaveDisabled}
            />
          </div>
          <div></div>
        </div>
      </div>
      <TypeCodeModalComponent
        id='wareHouseModal'
        title='Type Codes'
        isOpen={showTypeCodeModal}
        onSubmit={handleSelectTypeCode}
        onDismiss={toggleShowTypeCodeModal}
        successLabel='Select Type Code'
        cancelLabel='Cancel'
        originalOrRetread='R'
        isUserDependent
      />
      <BrandModalComponent
        isOpen={showBrandModal}
        onSubmit={handleSelectBrand}
        onDismiss={toggleShowBrandModal}
        brandPrefix={contractBrandPrefix}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default NewOrderDetails;