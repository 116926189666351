import { HeadCell } from '../../../../shared/DataGridComponent/DataGridModels';

export const defaultDataGridPageSizes = [25, 50, 100];

export const storedReportsHeadCell: Array<HeadCell> = [
  {
    key: 'column1',
    name: 'Report Name',
    fieldName: 'reportName',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Report Frequency',
    fieldName: 'reportGroup',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Report Type',
    fieldName: 'reportType',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'Created On',
    fieldName: 'generatedOn',
    typeField: 'date',
    isEditable: false,
  },
];

export const REPORT_FREQUENCY = {
  DAILY_REPORTS: 'Daily Reports',
  MONTH_END_REPORTS: 'Month End Reports',
};

export const REPORT_TYPE = {
  RECONCILIATION_REPORTS: 'Reconciliation Reports',
  ANALYTICS: 'Analytics',
};

export const reportFrequencies = [
  REPORT_FREQUENCY.DAILY_REPORTS, 
  REPORT_FREQUENCY.MONTH_END_REPORTS,
];

export const reportTypes = [
  REPORT_TYPE.RECONCILIATION_REPORTS, 
  REPORT_TYPE.ANALYTICS,
];