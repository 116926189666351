import { customerTestInstance } from '../axios';

const getCustomerSearch = (
  filters: any,
  pagination: any,
  sortOrder: any,
  customerId?: string,
) =>
  customerTestInstance.post('/customer/search', {
    filters,
    pagination,
    sortOrder,
    customerId,
  });

export default getCustomerSearch;
