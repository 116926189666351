export const listBox = [
  { name: 'LRK - Multiple Missing', value: 0 },
  { name: 'LRK - One Missing', value: 1 },
  { name: 'LRK - Zero Missing', value: 2 },
  { name: 'Mult Missing - Inv Req & Prev Annual', value: 3 }, 
  { name: 'Mult Missing - Inv Req', value: 4 }, 
  { name: 'Mult Missing - Missing Paperwork', value: 5 }, 
  { name: 'Mult Missing - Prev Annual', value: 6 }, 
  { name: 'SS - Multiple Missing', value: 7 }, 
  { name: 'SS - One Missing', value: 8 }, 
  { name: 'SS - Zero Missing', value: 9 },
];