import { HeadCell } from '../../../../../../../shared/DataGridComponent/DataGridModels';

export const locationsHeadCells: HeadCell[] = [
  {
    key: 'column0',
    name: 'Ship to Number',
    fieldName: 'shipToNumber',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Location Code',
    fieldName: 'locationCode',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Address Line 1',
    fieldName: 'addrLine1',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'City',
    fieldName: 'city',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'State',
    fieldName: 'state',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column5',
    name: 'Country',
    fieldName: 'country',
    typeField: 'text',
    isEditable: false,
  },
];