export const emptyForm: any = {
  addressLine1: '',
  addressLine2: '',
  country: null,
  state: null,
  servicedBy: null,
  city: '',
  zip: '',
  shipToNumber: '',
  locationCode: '',
  dssRation: '',
  geographicCode: '',
  locationId: '',
  fClosedOut: '',
  shipInstructions: '',
  contactName: '',
  contactPhone1: '',
  contactPhone2: '',
};