import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { debounce, get, isEmpty, isNil } from 'lodash';
import moment from 'moment';
import { Checkbox, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IColumn, IDropdownOption, MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useBoolean } from '@fluentui/react-hooks';

import apiService from '../../../../api';
import useNotifications from '../../../../hooks/useNotifications';
import { removeCustomer, setCustomer, customerSelector, setShouldFilterRun } from '../../../../redux/customerSlice';

import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../Pagination/Pagination';
import { IPaginationProps } from '../../../Pagination/IPaginationProps';
import { sortOrder } from '../../../../consts/sortOrder';
import { selectedQuoteSelector, setSelectedQuote } from '../../../../redux/quoteSlice';

import { IListingState } from './IListingState';
import { listingHeadCell, pageSizes } from './consts';

import styles from './Listing.module.scss';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { auth_quote_viewListing } from '../../../../consts/programKeys';
import { quoteStatuses } from '../../const';
import DataGridComponent from '../../../../shared/DataGridComponent';
import DialogComponent from '../../../../shared/DialogComponent';
import { defaultDataGridPageSizes } from '../../../../shared/DataGridComponent/utils';

interface QuoteTabsComponentProps {
  value?: string;
}

const Listing: FunctionComponent<QuoteTabsComponentProps> = () => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_quote_viewListing);

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const setSortingToCreatedOn = () => {
    setColumnsState(columnsState.map((col: IColumn) => ({
      ...col,
      isSorted: col.fieldName === listingHeadCell[4].fieldName,
      isSortedDescending: true,
    })));
  };

  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const selectedQuote = useSelector(selectedQuoteSelector);
  const customer = useSelector(customerSelector);
  const { addNotification } = useNotifications();

  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [state, setState] = React.useState<IListingState>({
    items: [],
    searchString: '',
    loading: false,
    foundCount: 0,
  });
  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [columnsState, setColumnsState] = useState<Array<any>>(listingHeadCell);
  const [showOlderQuotes, { toggle: toggleShowOlderQuotes }] = useBoolean(false);
  const [showDeletingConfirmation, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [countCreatedQuotes, setCountCreatedQuotes] = useState<number>(0);
  const [showAddingModal, { toggle: toggleShowAddingModal }] = useBoolean(false);
  const [userAgree, { setTrue: userAgreed }] = useBoolean(false);

  const disableButtons = useMemo(
    () => isNil(selectedQuote) ||
      ((selectedQuote.status === quoteStatuses.AUTHORIZED || selectedQuote.status === quoteStatuses.ACTIVATED) && moment().isBefore(moment(selectedQuote.expirationDate)) || !userPermissions.isWrite),
    [selectedQuote],
  );

  const fetchCustomers = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data: { data } }: any = await apiService.getCustomerSearch({}, null, null, id);
      dispatch(setCustomer(data[0]));
    } catch (e: any) {
      addNotification({ text: 'Customers fetching error.', type: MessageBarType.error });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchListing = async (
    pagination: any = { pageSize: defaultDataGridPageSizes[0], pageNumber: 1 },
    sortOrder: any = {},
    filters?: any,
  ) => {
    try {
      setState((prev: any) => ({ ...prev, loading: true }));
      const requestBody = {
        pagination,
        sortOrder,
        filters,
        customerId: customer.id,
        locationId: customer.regionCode,
        ...(showOlderQuotes && { yearsOlder: 6 }),
      };
      const { data } = await apiService.quoteListing.getList(requestBody);
      const foundCount = data?.total?.found;
      dispatch(setSelectedQuote(null));
      setState((prev: any) => ({ ...prev, items: data.data, foundCount }));
    } catch (e: any) {
      addNotification({ text: 'Listing fetching error.', type: MessageBarType.error });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleConfirmAddNew = async () => {
    userAgreed();
    toggleShowAddingModal();
    await addNewQuote();
  };

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    try {
      setState(prev => ({ ...prev, loading: true }));
      const requestBody = {
        customerId: customer.id,
        sourceQuoteId: selectedQuote.id,
        quoteVersion: selectedQuote.version,
      };
      await apiService.quoteListing.delete(requestBody);
      dispatch(setSelectedQuote(null));
      addNotification({
        text: 'Selected quote was successfully deleted.',
        type: MessageBarType.success,
      });
      await paginationProps.onChangePage(1);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Quote Listing deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      await fetchListing();
    }
  };

  const handleCopyQuote: any = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const requestBody = {
        customerId: customer.id,
        sourceQuoteId: selectedQuote.id,
        quoteVersion: selectedQuote.version,
      };
      await apiService.quoteListing.copy(requestBody);
      dispatch(setSelectedQuote(null));
      addNotification({
        text: 'Selected quote was successfully copied.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Quote Listing copying error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      await fetchListing();
    }
  };

  const handleAddNewQuote: any = async () => {
    if (countCreatedQuotes < 3 || userAgree) {
      addNewQuote();
    } else {
      toggleShowAddingModal();
    }
  };

  const handleVersionQuote: any = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const requestBody = {
        customerId: customer.id,
        sourceQuoteId: selectedQuote.id,
        quoteVersion: selectedQuote.version,
      };
      await apiService.quoteListing.version(requestBody);
      dispatch(setSelectedQuote(null));
      addNotification({
        text: 'New version of quote was successfully created.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Creating new version error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      await fetchListing();
    }
  };

  const addNewQuote: any = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const requestBody = {
        customerId: customer.id,
      };
      await apiService.quoteListing.add(requestBody);
      setCountCreatedQuotes(countCreatedQuotes + 1);
      dispatch(setSelectedQuote(null));
      addNotification({
        text: 'Quote was successfully added.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Quote adding error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      await fetchListing();
    }
  };

  const handleSelectRow = (rowsSelected: any) =>
    dispatch(setSelectedQuote(rowsSelected[0] || null));

  const handleChangeDataGridState = async (dataGridState: any) => {
    setState(prev => ({ ...prev, loading: true }));
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString: searchedText,
    };
    if (customer)
      await fetchListing(pagination, sortOrder, filters);
    setState(prev => ({ ...prev, loading: false }));
  };

  const handleBackToSearch = () => {
    dispatch(setShouldFilterRun(true));
    dispatch(removeCustomer());
    dispatch(setSelectedQuote(null));
    history.push('/quote');
  };

  const handleBackToTerm = () => {
    history.push(`/terms/${id}/list/${selectedQuote.contractId}`);
    window.location.reload();
  };

  const handleShowDetails = () => {
    dispatch(setSelectedQuote(selectedQuote));
    history.push(`${location.pathname}/${selectedQuote.id}`);
  };

  useEffect(() => {
    if (isNil(customer) && userPermissions)
      fetchCustomers();
  }, [id]);

  useEffect(() => {
    if (!customer) return;
    fetchListing();
  }, [customer, showOlderQuotes]);


  return (
    <div className={styles.availableTermsContainer}>
      <div className={styles.topActionButtons}>
        <DefaultButton
          onClick={() => handleBackToSearch()}
          text="Back to Search"
        />
      </div>
      <div className={styles.titleContainer}>
        {customer &&
          <>
            {isEmpty(customer?.contractStatus) ? customer && <h1 className={styles.customerHeader}>{customer?.customerName} </h1> :
              customer && <h1 className={styles.customerHeader}>{customer?.customerName} - {customer?.contractStatus} </h1>}
            <h2>Quote Listing</h2>
          </>}
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <Checkbox
            checked={showOlderQuotes}
            onChange={toggleShowOlderQuotes}
            label="Show quotes older than 6 years"
          />
        </div>
      </div>
      <div className={classNames('ms-Grid-row', styles.marginRow)}>

        <DataGridComponent
          idTable={'available-quotes-table'}
          title=''
          headCells={columnsState}
          rowsTable={state.items}
          totalDataFound={state.foundCount}
          enablePagination
          enableRowClick
          enableRowsPerPage
          enableSearching
          enableCheckBox
          handleSelectRow={handleSelectRow}
          handleChangeDataGridState={handleChangeDataGridState}
          isLoading={state.loading}
        />
      </div>
      <div className={classNames('ms-Grid-row', styles.marginRow)}>
        <div className={classNames(styles.buttonsGroup, 'ms-Grid-col', 'ms-sm12')}>
          <PrimaryButton
            id="deleteBtn"
            text='Delete'
            disabled={disableButtons}
            onClick={toggleDeletingConfirmation}
          />
          <PrimaryButton
            id="copyButton"
            text="Copy"
            disabled={disableButtons}
            onClick={handleCopyQuote}
          />
          <PrimaryButton
            id="versionButton"
            iconProps={{ iconName: 'Add' }}
            text="Version"
            disabled={disableButtons}
            onClick={handleVersionQuote}
          />
          <PrimaryButton
            id="showDetailsButton"
            text="Show Details"
            disabled={isNil(selectedQuote) || !userPermissions.isWrite}
            onClick={handleShowDetails}
          />
          <PrimaryButton
            id="addNewButton"
            text="Add New"
            iconProps={{ iconName: 'Add' }}
            onClick={handleAddNewQuote}
            disabled={!userPermissions.isWrite}
          />
          {(!isNil(selectedQuote) && (selectedQuote?.status === quoteStatuses.CREATED)) &&
            <PrimaryButton
              text="Go to Term"
              onClick={handleBackToTerm} />}
        </div>
      </div>

      <DialogComponent
        isOpen={showDeletingConfirmation}
        onCancel={toggleDeletingConfirmation}
        onSubmit={handleDelete}
        title='Confirmation'
        subText='Are you sure you want to delete selected item?'
        onSubmitLabel='Delete'
        onCancelLabel='Cancel'
      />

      <DialogComponent
        isOpen={showAddingModal}
        onCancel={toggleShowAddingModal}
        onSubmit={handleConfirmAddNew}
        title='Confirmation'
        subText='Are you sure you want to add more quotes?'
        onSubmitLabel='Add'
        onCancelLabel='Cancel'
      />
    </div>
  );
};

export default Listing;