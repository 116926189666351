import { DefaultButton, IconButton, MessageBarType, Modal, PrimaryButton, Text, TextField } from '@fluentui/react';
import { debounce } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoCompleteField from '../../../../components/Common/Search/AutoCompleteField';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';
import { quoteStatuses } from '../../../../components/Quote/const';
import useNotifications from '../../../../hooks/useNotifications';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { IProduct } from '../../../../models/IProduct';
import { selectedQuoteSelector, setSelectedQuote } from '../../../../redux/quoteSlice';
import DataGridComponent from '../../../DataGridComponent';
import { FormBuilderGroup } from '../../../FormBuilderComponent';
import { productsApiModules } from '../../consts';
import { ProductModalComponentProps, PRODUCTS_GROUPS_FIELDS, productTableHeadCells } from './consts';
import styles from '../../MakeModel.module.scss';

const ProductDetailComponent: FunctionComponent<any> = ({
  id,
  title,
  fleetId,
  isOpen,
  onDismiss,
  cancelLabel,
  permissionKey,
  parentComponentName,
}) => {

  const { addNotification } = useNotifications();

  const selectedQuote = parentComponentName === 'quote' ? useSelector(selectedQuoteSelector) : undefined;

  const [isLoading, setLoading] = useState(false);
  const [isLoadingTable, setLoadingTable] = useState<boolean>(false);
  const [isLoadingCodes, setLoadingCodes] = useState<boolean>(false);
  const [totalFound, setTotalFound] = useState(0);
  const [productList, setProductList] = useState<Array<IProduct>>([]);
  const [productsSelected, setProductsSelected] = useState<Array<IProduct>>([]);
  const [productListPagination, setProductListPagination] = useState<any>({ pageSize: 100, pageNumber: 1 });
  const [productListSortOrder, setProductListSortOrder] = useState<any>({ column: productTableHeadCells[0].fieldName, order: 'asc' });

  const [allProductCodes, setAllProductCodes] = useState<any>([]);

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(permissionKey);
  const dispatch = useDispatch();

  const productsFieldsGroup = new FormBuilderGroup(PRODUCTS_GROUPS_FIELDS);

  const removeEmpty = ({ text }: any) => text !== null;

  const productCodeList = allProductCodes?.map((el: any) => ({
    key: el.code,
    text: el.code,
  })).filter(removeEmpty);

  const fetchProductCodesList = async (filters: any) => {
    setLoadingCodes(true);
    const { data }: any = await productsApiModules[parentComponentName].getProductCodesAPI(filters);
    setAllProductCodes(data.data);
    setLoadingCodes(false);
  };

  const getProductsDebounce = debounce(async (productCode: any) => {
    fetchProductCodesList({ code: productCode });
  }, 1000);

  const onProductCodeChange = (product: any) => {
    setProductFieldsByCode(product);
    productsFieldsGroup.setFormValue('productCodeField', product);
  };

  const setProductFieldsByCode = (code: string) => {
    const found = allProductCodes.find((el: any) => el.code === code);
    productsFieldsGroup.setFormValue('descriptionField', found?.description);
    productsFieldsGroup.setFormValue('tireSizeField', found?.tireSize);
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    const { countOnPage, paginationProps, sortOrder, searchedText } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    fetchProducts(pagination, sortOrder, searchedText);
  };

  const fetchProducts = async (
    pagination: any = productListPagination,
    sortOrder: any = productListSortOrder,
    searchedText = '',
  ) => {
    setLoadingTable(true);

    if (sortOrder?.column === 'bogey') {
      sortOrder = { ...sortOrder, column: 'bogeyUnits' };
    } else if (sortOrder?.column === 'percUsage') {
      sortOrder = { ...sortOrder, column: 'pctUsage' };
    }
    try {
      const { data: { data, total: { found } } }: any = await productsApiModules[parentComponentName].getProductsAPI(pagination, sortOrder, fleetId, searchedText);
      setProductList(data);
      setTotalFound(found);
      setProductListPagination(pagination);
      setProductListSortOrder(sortOrder);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Products fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoadingTable(false);
    }
  };

  const addProduct = async () => {
    setLoading(true);
    try {
      await productsApiModules[parentComponentName].addProductAPI({
        fleetId,
        quoteId: selectedQuote?.id,
        quoteVersion: selectedQuote?.version,
        productCode: productsFieldsGroup.getFieldFormValue('productCodeField'),
        description: productsFieldsGroup.getFieldFormValue('descriptionField'),
        tireSize: productsFieldsGroup.getFieldFormValue('tireSizeField'),
        bogey: productsFieldsGroup.getFieldFormValue('bogeyField'),
        percUsage: productsFieldsGroup.getFieldFormValue('usageField'),
      });
      if (selectedQuote?.status === quoteStatuses.GENERATED)
        dispatch(setSelectedQuote({ ...selectedQuote, status: quoteStatuses.REGENERATED }));
      addNotification({
        text: 'Product was successfully added.',
        type: MessageBarType.success,
      });
      fetchProducts();
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Product adding error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const updateProduct = async () => {
    setLoading(true);
    try {
      await productsApiModules[parentComponentName].updateProductAPI({
        ...productsSelected[0],
        fleetId: fleetId,
        quoteId: selectedQuote?.id,
        quoteVersion: selectedQuote?.version,
        productId: productsSelected[0].id,
        productCode: productsFieldsGroup.getFieldFormValue('productCodeField'),
        description: productsFieldsGroup.getFieldFormValue('descriptionField'),
        tireSize: productsFieldsGroup.getFieldFormValue('tireSizeField'),
        bogey: productsFieldsGroup.getFieldFormValue('bogeyField'),
        percUsage: productsFieldsGroup.getFieldFormValue('usageField'),
      });
      if (selectedQuote?.status === quoteStatuses.GENERATED)
        dispatch(setSelectedQuote({ ...selectedQuote, status: quoteStatuses.REGENERATED }));
      addNotification({
        text: 'Product was successfully updated.',
        type: MessageBarType.success,
      });
      fetchProducts();
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Product updating error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (rows: IProduct[] = []) => {
    setLoading(true);
    try {
      await productsApiModules[parentComponentName].deleteProductsAPI(rows.map(el => el.id), selectedQuote?.id, selectedQuote?.version);
      if (selectedQuote?.status === quoteStatuses.GENERATED)
        dispatch(setSelectedQuote({ ...selectedQuote, status: quoteStatuses.REGENERATED }));
      addNotification({
        text: 'Product(s) was successfully deleted.',
        type: MessageBarType.success,
      });
      fetchProducts();
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Product(s) deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteAll = async () => {
    setLoading(true);
    try {
      await productsApiModules[parentComponentName].deleteAllProductsAPI(fleetId, selectedQuote?.id, selectedQuote?.version);
      if (selectedQuote?.status === quoteStatuses.GENERATED)
        dispatch(setSelectedQuote({ ...selectedQuote, status: quoteStatuses.REGENERATED }));
      addNotification({
        text: 'Product(s) was successfully deleted.',
        type: MessageBarType.success,
      });
      setProductsSelected([]);
      fetchProducts();
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Product(s) deletion error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (productsSelected.length == 1) {
      productsFieldsGroup.setFormValue('productCodeField', productsSelected[0]?.productCode);
      productsFieldsGroup.setFormValue('descriptionField', productsSelected[0]?.description);
      productsFieldsGroup.setFormValue('tireSizeField', productsSelected[0]?.tireSize);
      productsFieldsGroup.setFormValue('bogeyField', productsSelected[0]?.bogey);
      productsFieldsGroup.setFormValue('usageField', `${productsSelected[0]?.percUsage}`);
    }
    else {
      productsFieldsGroup.cleanFormData();
    }
  }, [productsSelected]);

  useEffect(() => {
    if (isOpen)
      fetchProductCodesList({});
  }, [isOpen]);

  return (
    <>
      <div >
        <div className={styles.formContainer}>
          <AutoCompleteField
            initialValue={productsFieldsGroup.getFieldFormValue('productCodeField')}
            onChangeAction={onProductCodeChange}
            list={productCodeList}
            label="Product Code"
            data-testid='product-code'
            icon="car"
            isLoading={isLoadingCodes}
            textValue={getProductsDebounce}
          />
          <TextField  {...productsFieldsGroup.getFieldForm('descriptionField')} />
          <TextField  {...productsFieldsGroup.getFieldForm('tireSizeField')} />
          <TextField  {...productsFieldsGroup.getFieldForm('bogeyField')} />
          <TextField  {...productsFieldsGroup.getFieldForm('usageField')} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DefaultButton
            id='deleteAll'
            text='Clear all Products'
            onClick={deleteAll}
            disabled={!(userPermissions.isWrite && productList.length !== 0)}
            style={{ marginRight: '8px' }}
          />
          <PrimaryButton
            id='updateButton'
            text='Update Product'
            onClick={updateProduct}
            disabled={!(userPermissions.isWrite && productsSelected.length === 1 && productsSelected && productsFieldsGroup.getFieldFormValue('productCodeField'))}
            style={{ marginRight: '8px' }}
          />
          <PrimaryButton
            id='addButton'
            text='Add Product'
            onClick={addProduct}
            disabled={!(userPermissions.isWrite && productsSelected.length !== 1 && productsSelected && productsFieldsGroup.getFieldFormValue('productCodeField'))}
            style={{ marginRight: '8px' }}
          />
        </div>
        <div className='tableContainer'>
          <DataGridComponent
            idTable={'productTable'}
            title={'Products'}
            headCells={productTableHeadCells}
            rowsTable={productList}
            totalDataFound={totalFound}
            isLoading={isLoadingTable}
            enableDeleteOption={userPermissions.isWrite}
            enableCheckBox={userPermissions.isWrite}
            enableMultiSelectRow
            enablePagination
            enableRowsPerPage
            enableSearching={true}
            handleDelete={handleDelete}
            handleSelectRow={setProductsSelected}
            handleChangeDataGridState={handleChangeDataGridState}
          />
        </div>

        <div>
          <DefaultButton
            id="cancelButton"
            text={cancelLabel}
            onClick={onDismiss}
            style={{ float: 'right' }}
          />
        </div>
        {isLoading && <LoadingScreen />}
      </div>
    </>);
};

export default ProductDetailComponent;
