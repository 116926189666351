import { IDropdownOption } from '@fluentui/react';
import { IFiltersState } from './IFiltersState';

export const pageSizes = [100, 200, 300];

export const tableColumns = [
  {
    key: 'column0',
    name: 'Module',
    fieldName: 'application',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'Main Tab',
    fieldName: 'application',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Subtab',
    fieldName: 'application',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'Read Access',
    fieldName: 'isRead',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'Write Access',
    fieldName: 'isWrite',
    isSorted: false,
    isSortedDescending: false,
  },
];

export const allOption: IDropdownOption = {
  key: 'All',
  text: 'All',
};

export const emptyFilters: IFiltersState = {
  searchString: '',
  module: [allOption.key],
  access: [allOption.key],
};
