import { FC, FormEvent, ReactElement, useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { useSelector } from 'react-redux';
import { useBoolean } from '@fluentui/react-hooks';
import {
  Checkbox,
  DatePicker,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IColumn,
  IconButton,
  IDropdownOption,
  MaskedTextField,
  MessageBarType,
  PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';

import useNotifications from '../../../../../../hooks/useNotifications';
import apiService from '../../../../../../api';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { sortOrder } from '../../../../../../consts/sortOrder';
import { auth_tireChange_deleteButton, auth_tireChange_postBackdatedChanges } from '../../../../../../consts/programKeys';
import { customerSelector, locationSelector } from '../../../../../../redux/recordKeepingSlice';

import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import ErrorsModal from '../../../../../ErrorsModal/ErrorsModal';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../../Pagination/Pagination';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';

import { IPostBackdatedChangesState } from './IPostBackdatedChangesState';
import { columns } from './consts';
import { pageSizes } from '../../../../../../consts/recordKeeping';
import { IPostBackdatedChangesProps } from './IPostBackdatedChangesProps';

import styles from './PostBackdatedChanges.module.scss';
import { IPostByB73Format } from '../PostByB73Format/IPostByB73Format';
import { emptyB73Format } from '../PostByB73Format/consts';
import { transformDate } from '../../../../../../shared/transformDate';

const PostBackdatedChanges: FC<IPostBackdatedChangesProps> = (): ReactElement => {
  const [state, setState] = useState<IPostBackdatedChangesState>({
    loading: false,
    items: [],
    selectedItems: [],
    foundCount: 0,
  });
  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) =>
      setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });

  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({
    key: pageSizes[0],
    text: pageSizes[0].toString(),
  });
  const { addNotification } = useNotifications();
  const { id: customerId, customerName } = useSelector(customerSelector);
  const { id: locationId, locationName } = useSelector(locationSelector);

  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [showErrorsModal, { toggle: toggleShowErrorsModal }] = useBoolean(false);
  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [editedRowId, setEditedRowId] = useState<number>(-1);
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const [defaults, setDefaults] = useState<any>({ pfx: '', date: null });
  const [useDefaults, { toggle: toggleUseDefaults }] = useBoolean(false);
  const [parsedErrors, setParsedErrors] = useState<any>([]);
  const [tireToBeAdded, setTireToBeAdded] = useState<IPostByB73Format>(emptyB73Format);
  const [postTableId, setPostTableId] = useState<any>(null);

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_tireChange_postBackdatedChanges);
  const deletePermission = hasPermission(auth_tireChange_deleteButton);

  const onChangeDefaultsPrefix = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, pfx: string | undefined) => {
    setDefaults((prev: any) => ({ ...prev, pfx: pfx?.toUpperCase() }));
  };

  const updateAddValues = (defaultValues: any, field: string, value: string | boolean | undefined) => {
    defaultValues[field] = value;
    return defaultValues;
  };

  const updateRowsData = (currentState: any, field: string, value: string | undefined) => {
    return currentState.map((item: { [x: string]: string | undefined; id: any; }) => {
      if (editedRowId === item.id) {
        item[field] = value;
      }
      return item;
    });
  };

  const setOnPrefixTireToBeEdited = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, onPrefix: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'onPrefix', onPrefix?.toUpperCase()) }));
  };
  const setOnBrandNumberTireToBeEdited = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, onBrandNumber: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'onBrandNumber', onBrandNumber?.toUpperCase()) }));
  };
  const setOnSuffixTireToBeEdited = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, onSuffix: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'onSuffix', onSuffix?.toUpperCase()) }));
  };
  const setOnCotTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, onCot: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'onCot', onCot?.toUpperCase()) }));
  };

  const handleAddTire: any = async (ev: any) => {
    ev.preventDefault();
    const tireForAdding = { ...tireToBeAdded };
    tireForAdding.chgDate = /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/gm.test(tireToBeAdded.chgDate) ? transformDate(tireToBeAdded.chgDate) : tireToBeAdded.chgDate;
    if (tireForAdding.offCot === 'Y') {
      tireForAdding.offCot = true;
    }
    if (tireForAdding.offCot === 'N') {
      tireForAdding.offCot = false;
    }
    if (tireForAdding.onCot === 'Y') {
      tireForAdding.onCot = true;
    }
    if (tireForAdding.onCot === 'N') {
      tireForAdding.onCot = false;
    }
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.postBackdatedChanges.save([{ ...tireForAdding }], customerId, locationId);
      addNotification({
        text: 'Tire was successfully added.',
        type: MessageBarType.success,
      });
      if (useDefaults) {
        setTireToBeAdded({...emptyB73Format, chgDate: moment(defaults.date).format('MM/DD/YYYY'), offPrefix: defaults.pfx, onPrefix: defaults.pfx});
      } else {
        setTireToBeAdded(emptyB73Format);
      }
      setParsedErrors([]);
      await fetchPostBackdatedChanges();
    } catch (e: any) {
      const { response } = e;
      const locationId = response.data.state.filter((error: any) => error.field == 'locationId');
      switch (response.status) {
        case 400:
          setParsedErrors(response.data.state);
          if (locationId.length) {
            return addNotification({
              text: 'Location must not be empty',
              type: MessageBarType.error,
            });
          }
          return addNotification({
            text: 'Unable to save changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Saving error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
      document.getElementById('chgDate')?.focus();
    }
  };

  const handleRelease = async () => {
    const itemsForSave: Array<any> = [];
    state.selectedItems.map(selectedItem => {
      itemsForSave.push(state.items.find(item => item.id == selectedItem));
    });
    itemsForSave.map((item: { onCot: string | boolean; offCot: string | boolean; }) => {
      if (item.offCot === 'Y') {
        item.offCot = true;
      }
      if (item.offCot === 'N') {
        item.offCot = false;
      }
      if (item.onCot === 'Y') {
        item.onCot = true;
      }
      if (item.onCot === 'N') {
        item.onCot = false;
      }
      return item;
    });
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.postBackdatedChanges.release(itemsForSave, customerId, locationId);
      addNotification({
        text: 'Backdated tires were successfully released.',
        type: MessageBarType.success,
      });
      if (paginationProps.current === 1) {
        await fetchPostBackdatedChanges();
      } else {
        await paginationProps.onChangePage(1);
      }
    } catch (e: any) {
      setState((prev: any) => ({ ...prev, loading: false }));
      const { response } = e;
      const locationId = response.data?.state?.filter((error: any) => error.field == 'locationId');
      switch (response.status) {
        case 400:
          setParsedErrors(response.data.state);
          if (locationId.length) {
            return addNotification({
              text: 'Location must not be empty',
              type: MessageBarType.error,
            });
          }
          return addNotification({
            text: `Backdated releasing error: ${response.data.state[0].message}`,
            type: MessageBarType.error,
          });
        case 499:
          return addNotification({
            text: `Backdated releasing error: ${response.data.state[0].message}`,
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Backdated releasing error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    }
  };

  const onChangeCountOnPage = (
    event: FormEvent<HTMLDivElement>,
    item: IDropdownOption<any> | undefined,
  ): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.postBackdatedChanges.delete(state.selectedItems);
      addNotification({
        text: 'Selected tire(s) were successfully deleted.',
        type: MessageBarType.success,
      });
      if (paginationProps.current === 1) {
        await fetchPostBackdatedChanges();
      } else {
        await paginationProps.onChangePage(1);
      }
    } catch (e: any) {
      const { response } = e;
      setState(prev => ({ ...prev, loading: false }));
      addNotification({
        text: `Backdated deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(
      ({ isSorted }) => isSorted,
    );
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };
  
  const handleSave = async () => {
    const itemsForSave: Array<any> = [];
    state.selectedItems.map(selectedItem => {
      itemsForSave.push(state.items.find(item => item.id == selectedItem));
    });
    itemsForSave.map((item: { onCot: string | boolean; offCot: string | boolean; }) => {
      if (item?.offCot === 'Y') {
        item.offCot = true;
      }
      if (item?.offCot === 'N') {
        item.offCot = false;
      }
      if (item?.onCot === 'Y') {
        item.onCot = true;
      }
      if (item?.onCot === 'N') {
        item.onCot = false;
      }
      return item;
    });
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.postBackdatedChanges.save(itemsForSave, customerId, locationId);
      addNotification({
        text: 'Post Backstaged tires were successfully saved.',
        type: MessageBarType.success,
      });
      await fetchPostBackdatedChanges();
      setParsedErrors([]);
    } catch (e: any) {
      const { response } = e;
      const locationId = response.data.state.filter((error: any) => error.field == 'locationId');
      switch (response.status) {
        case 400:
          setParsedErrors(response.data.state);
          if (locationId.length) {
            return addNotification({
              text: 'Location must not be empty',
              type: MessageBarType.error,
            });
          }
          return addNotification({
            text: 'Unable to save changes.',
            type: MessageBarType.error,
          });
        default: {
          let notificationMessage = `Saving error: ${response.data.message}`;
          if (response.data.state.length > 0) {
            notificationMessage = `Saving error: ${response.data.state[0].message}`;
          }
          addNotification({
            text: notificationMessage,
            type: MessageBarType.error,
          });
        }
      }
      await fetchPostBackdatedChanges();
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchPostBackdatedChanges = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.postBackdatedChanges.get(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
      );
      const foundCount = data.total.found;
      const items = data.data.map((item: { chgDate: string; onCot: string; offCot: string; }) => {
        item.chgDate = moment(item.chgDate).format('MM/DD/YYYY');
        item.onCot = item.onCot ? 'Y' : 'N';
        item.offCot = item.offCot ? 'Y' : 'N';
        return item;
      });
      if (postTableId) {
        setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [items.find((item: any) => item.id == postTableId)?.id] }));
        setPostTableId(null);
      } else {
        setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      }
      setPaginationProps((prev: any) => ({
        ...prev,
        total: Math.ceil(foundCount / +countOnPage.key),
      }));
    } catch (e: any) {
      addNotification({
        text: `Backdated fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const renderLargeLabel = (props: any) => (
    <Text variant="large" className={styles.highlight}>{props.label}</Text>
  );

  const handleSelect = (e: any, itemId: any) => {
    if (e.target.checked) {
      setState((prev: any) => ({ ...prev, selectedItems: [...state.selectedItems, itemId] }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: state.selectedItems.filter(row => row !== itemId) }));
    }
  };

  const handleSelectAll = (e: any, items: Array<any>) => {
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedItems: items })) : setState((prev: any) => ({ ...prev, selectedItems: [] }));
  };

  const onEdit = (itemId: any) => {
    setEditedRowId(itemId);
    if (useDefaults) {
      const newItems = [...state.items].map(item => {
        if (item.id == itemId) {
          item.onPrefix = defaults.pfx;
          item.offPrefix = defaults.pfx;
          if (!isNil(defaults.date)) {
            item.changeDate = moment(defaults.date).format('MM/DD/YYYY');
          }
        }
        return item;
      });
      setState(prev => ({ ...prev, items: newItems }));
    }
  };

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const parseErrors = (id: string | number | null, field: string) => {
    const customError = parsedErrors?.filter((error: { id: string; field: string; }) => error.id == id && error.field == field)[0];
    if (customError) {
      return customError.message;
    }
  };

  const highlightRowIfError = (id: any) => {
    if (parsedErrors.length > 0) {
      return parsedErrors.filter((error: { id: any; }) => error.id == id).length > 0;
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
      };
      const headerFields = [
        { title: 'Customer Name', value: customerName },
        { title: 'Location Name', value: locationName },
      ];
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.postBackdatedChanges.printExcel(requestData, headerFields) :
        await apiService.postBackdatedChanges.printPdf(requestData, headerFields);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const searchRecordWithError = async (postTableId: any, response?: boolean) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.postBackdatedChanges.getPage(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
        postTableId,
      );
      setPostTableId(postTableId);
      if (data.pageNumber != paginationProps.current) {
        await paginationProps.onChangePage(data.pageNumber);
      } else {
        setState((prev: any) => ({ ...prev, selectedItems: [state.items.find((item: any) => item.id == postTableId)?.id] }));
        setState((prev) => ({ ...prev, items: prev.items.map(el => el.id === postTableId ? ({ ...el, response}) : el) }));
        setPostTableId(null);
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `B73 fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    fetchPostBackdatedChanges();
  }, [paginationProps.current, countOnPage, columnsState, customerId, locationId]);

  useEffect(() => {
    if(useDefaults) {
      setTireToBeAdded((prev: any) => ({ ...prev, chgDate: moment(defaults.date).format('MM/DD/YYYY'), offPrefix: defaults.pfx, onPrefix: defaults.pfx }));
    } else {
      setTireToBeAdded((prev: any) => ({ ...prev, chgDate: '', offPrefix: '', onPrefix: '' }));
    }
  }, [useDefaults]);

  return (
    <>
      <div>
        <div className={styles.tableHeading}>
          <div>
            <Text variant="xLarge" className={styles.highlight}>Posted Tire Changes</Text>
            <SeparatorGy vertical />
            <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
          </div>
        </div>
        <div className={styles.tableHeading}>
          <form className={styles.tiresAddingBlock} onSubmit={handleAddTire}>
            <div className="ms-TextField">
              <label>ChgDate</label>
              <MaskedTextField
                id="chgDate"
                value={tireToBeAdded.chgDate}
                mask="99/99/9999"
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'chgDate', value?.toUpperCase()))}
                errorMessage={parseErrors(null, 'chgDate')}
                autoFocus
              />
            </div>
            <div className="ms-TextField">
              <label>VNo</label>
              <TextField
                value={'' + tireToBeAdded.vNo}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'vNo', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'vno')}
              />
            </div>
            <div className="ms-TextField">
              <label>WPos</label>
              <TextField
                value={tireToBeAdded.wPos}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'wPos', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'wPos')}
              />
            </div>
            <div className="ms-TextField">
              <label>OffPrefix</label>
              <TextField
                value={tireToBeAdded.offPrefix}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'offPrefix', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'offPrefix')}
              />
            </div>
            <div className="ms-TextField">
              <label>OffBrandNumber</label>
              <TextField
                value={'' + tireToBeAdded.offBrandNumber}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'offBrandNumber', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'offBrandNumber')}
              />
            </div>
            <div className="ms-TextField">
              <label>OffSuffix</label>
              <TextField
                value={tireToBeAdded.offSuffix}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'offSuffix', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'offSuffix')}
              />
            </div>
            <div className="ms-TextField">
              <label>OffCOT</label>
              <TextField
                value={'' + tireToBeAdded.offCot}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'offCot', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'offCot')}
              />
            </div>
            <div className="ms-TextField">
              <label>OnPrefix</label>
              <TextField
                value={tireToBeAdded.onPrefix}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'onPrefix', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'onPrefix')}
              />
            </div>
            <div className="ms-TextField">
              <label>OnBrandNumber</label>
              <TextField
                value={'' + tireToBeAdded.onBrandNumber}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'onBrandNumber', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'onBrandNumber')}
              />
            </div>
            <div className="ms-TextField">
              <label>OnSuffix</label>
              <TextField
                value={tireToBeAdded.onSuffix}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'onSuffix', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'onSuffix')}
              />
            </div>
            <div className="ms-TextField">
              <label>OnCOT</label>
              <TextField
                value={'' + tireToBeAdded.onCot}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'onCot', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'onCot')}
              />
            </div>
            <PrimaryButton
              // disabled={!tireToBeAdded.pfx || !tireToBeAdded.bno || !tireToBeAdded.newPfx || !tireToBeAdded.newBrand}
              disabled={!userPermissions.isWrite}
              id="addButton"
              text="Add"
              type='submit'
            />
          </form>
        </div>
        <div className={styles.tableHeading}>
          <div className={styles.useDefaults}>
            <Checkbox id="useDefaults" label="Use Defaults:" onRenderLabel={renderLargeLabel} checked={useDefaults} onChange={toggleUseDefaults} />
            <div>
              <TextField id="prefixDefault" label="Ctrl Pfx" value={defaults.pfx} onChange={onChangeDefaultsPrefix} />
              <DatePicker
                id="dateDefault"
                showMonthPickerAsOverlay={true}
                label="MM/DD/YY"
                value={defaults.date}
                onSelectDate={(date: Date | null | undefined) => setDefaults((prev: any) => ({ ...prev, date }))}
                formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
              />
            </div>
          </div>
          <div>
            <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
            <Dropdown
              options={pageSizes.map((pageSize) => ({
                key: pageSize,
                text: pageSize.toString(),
              }))}
              defaultSelectedKey={pageSizes[0]}
              selectedKey={countOnPage?.key}
              onChange={onChangeCountOnPage}
            />
            <SeparatorGy vertical />
            <Text variant="large" className={styles.highlight}>
              {state.selectedItems.length} items selected
            </Text>
            <SeparatorGy vertical />
            <IconButton
              id="toggleDeletingConfirmationButton"
              disabled={!(state.selectedItems.length && userPermissions.isWrite && deletePermission.isWrite)}
              iconProps={{ iconName: 'Delete' }}
              onClick={toggleDeletingConfirmation}
            />
          </div>
        </div>
        <div className={styles['table-wrapper']}>
          <table>
            <thead>
              <tr>
                {
                  columnsState.map(item => (
                    <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                  ))
                }
                <th>
                  <div className={styles.round}>
                    <input type="checkbox" id="all" checked={state.items.length !== 0 &&
                      (state.selectedItems.length === +countOnPage.key || state.selectedItems.length === state.items.length)} onChange={(e) => handleSelectAll(e, state.items.map(item => item.id))} />
                    <label htmlFor="all"></label>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                state.items.map((item, index) => (
                  item.submit ?
                    <tr>
                      <td>{item.chgDate && moment(item.chgDate).format('MM/DD/YYYY')}</td>
                      <td>{item.vno}</td>
                      <td>{item.wPos}</td>
                      <td>{item.offPrefix}</td>
                      <td>{item.offBrandNumber}</td>
                      <td>{item.offSuffix}</td>
                      <td>{item.offCot}</td>
                      <td>{item.onPrefix}</td>
                      <td>{item.onBrandNumber}</td>
                      <td>{item.onSuffix}</td>
                      <td>{item.onCot}</td>
                      <td>{item.submit ? 'Y' : 'N'}</td>
                      <td>
                        <div className={styles.round}>
                          <input type="checkbox" id={item.id} checked={state.selectedItems.includes(item.id)} onChange={(e) => handleSelect(e, item.id)} />
                          <label htmlFor={item.id}></label>
                        </div>
                      </td>
                    </tr> :
                    <tr
                      key={item.id}
                      className={classNames(state.selectedItems.includes(item.id) ? styles.trSelected : styles.trBasic,
                        highlightRowIfError(item.id) ? styles.trError : styles.trBasic)}
                      onFocus={() => onEdit(item.id)}
                    >
                      <td>{item.chgDate && moment(item.chgDate).format('MM/DD/YYYY')}</td>
                      <td>{item.vno}</td>
                      <td>{item.wPos}</td>
                      <td>{item.offPrefix}</td>
                      <td>{item.offBrandNumber}</td>
                      <td>{item.offSuffix}</td>
                      <td>{item.offCot}</td>
                      <td>
                        <TextField
                          id="onPrefix"
                          styles={{ fieldGroup: { border: '1px solid transparent' } }}
                          value={item.onPrefix}
                          onChange={setOnPrefixTireToBeEdited}
                          errorMessage={parseErrors(item.id, 'onPrefix')}
                        />
                      </td>
                      <td>
                        <TextField
                          id="onBrandNumber"
                          styles={{ fieldGroup: { border: '1px solid transparent' } }}
                          value={item.onBrandNumber}
                          onChange={setOnBrandNumberTireToBeEdited}
                          errorMessage={parseErrors(item.id, 'onBrandNumber')}
                        />
                      </td>
                      <td>
                        <TextField
                          id="onSuffix"
                          styles={{ fieldGroup: { border: '1px solid transparent' } }}
                          value={item.onSuffix}
                          onChange={setOnSuffixTireToBeEdited}
                          errorMessage={parseErrors(item.id, 'onSuffix')}
                        />
                      </td>
                      <td>
                        <TextField
                          id="onCot"
                          styles={{ fieldGroup: { border: '1px solid transparent' } }}
                          value={item.onCot}
                          onChange={setOnCotTireToBeEdited}
                          errorMessage={parseErrors(item.id, 'onCot')}
                        />
                      </td>
                      <td>{item.submit ? 'Y' : 'N'}</td>
                      <td>
                        <div className={styles.round}>
                          <input type="checkbox" id={item.id} checked={state.selectedItems.includes(item.id)} onChange={(e) => handleSelect(e, item.id)} />
                          <label htmlFor={item.id}></label>
                        </div>
                      </td>
                    </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <SeparatorGy />
        <Pagination {...paginationProps} />
      </div>
      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to delete (${state.selectedItems.length}) items?`,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <div className={classNames(styles.buttonsWrapper)}>
        <DefaultButton
          id="printButton"
          onClick={toggleShowPrintExport}
          text="Print/Export"
        />
        <DefaultButton
          id="viewErrorsButton"
          onClick={toggleShowErrorsModal}
          text="View Errors"
        />
        <PrimaryButton
          id="saveButton"
          text="Save"
          onClick={handleSave}
          disabled={!userPermissions.isWrite || !state.selectedItems.length}
        />
        <PrimaryButton
          id="releaseButton"
          text="Release"
          onClick={handleRelease}
          disabled={!userPermissions.isWrite || !state.selectedItems.length}
        />
      </div>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      <ErrorsModal
        isModalOpen={showErrorsModal}
        hideModal={toggleShowErrorsModal}
        fetchErrorsService={apiService.postBackdatedChanges.getErrors}
        searchError={searchRecordWithError}
        shouldConfirm={true}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default PostBackdatedChanges;