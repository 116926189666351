import { DefaultButton, IconButton, MessageBarType, Modal, PrimaryButton, Text } from '@fluentui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import DataGridComponent from '../DataGridComponent';
import apiService from '../../api';
import { useBoolean } from '@fluentui/react-hooks';
import useNotifications from '../../hooks/useNotifications';
import styles from './ViewErrors.module.scss';
import { errorsHeadCell } from './consts';
import DialogComponent from '../DialogComponent';
import billingAPI from '../../api/billingAPI';


interface ViewErrorsModalComponentProps {
  id: string;
  quoteId: string;
  title: string;
  isOpen: boolean;
  onSubmit: (value?: any) => void;
  onDismiss: () => void;
  successLabel: string;
  setSelectedTireError: any;
  tireList?: any;
}

const ViewErrorsModalComponent: FunctionComponent<ViewErrorsModalComponentProps> = ({
  id,
  quoteId,
  title,
  isOpen,
  onSubmit,
  onDismiss,
  successLabel,
  setSelectedTireError,
  tireList,
}) => {

  //hooks
  const { addNotification } = useNotifications();

  const [selectedError, setError] = useState<any>({});
  const [errorsList, seErrorsList] = useState([]);
  const [totalFound, setTotalFound] = useState(0);
  const [notification, setNotification] = useState({ text: '', type: 0 });
  const [isLoading, { toggle: toggleChangeLoading }] = useBoolean(false);

  const [showSubmitConfirmation, { toggle: toggleSubmitConfirmation }] = useBoolean(false);

  const fetchData = async (pagination: any, filters?: any, sortOrder?: any) => {
    try {
      const { data }: any = await apiService.billingAPI.viewErrors(
        pagination,
        sortOrder,
        quoteId,
      );
      seErrorsList(data?.data);
      setTotalFound(data?.total?.found);
    } catch (error) {
      setNotification({ text: 'Fetching Locations error', type: MessageBarType.error });
    }
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    toggleChangeLoading();
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString: searchedText,
    };
    await fetchData(pagination, filters, sortOrder);
    toggleChangeLoading();
  };

  const handleSubmit = () => onSubmit();

  const handleSelectedError = (error?: any) => {
    setError(error);
    if (error[0]?.type === 'PRMT' && error?.length) toggleSubmitConfirmation();
  };

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  return (
    <>
      <Modal
        key={id}
        isOpen={isOpen}
        onDismiss={onDismiss}
        containerClassName='modalContainer'
      >
        <div className={styles.modalBody}>
          <div className='modalHeader'>
            <Text variant='xLarge'>{title}</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel='Close popup modal'
              onClick={onDismiss}
            />
          </div>
          <div className='tableContainer'>
            <DataGridComponent
              idTable={'vehicle-table'}
              title=''
              headCells={errorsHeadCell}
              rowsTable={errorsList}
              totalDataFound={totalFound}
              isLoading={isLoading}
              enableCheckBox={true}
              enableSearching={true}
              enablePagination={true}
              enableRowsPerPage={true}
              handleChangeDataGridState={handleChangeDataGridState}
              handleSelectRow={handleSelectedError}
            />
          </div>
          <div className={styles.modalFooter}>
            <DefaultButton
              id='printExportButton'
              text='Print/Export'
              onClick={onDismiss}
            />
            <PrimaryButton
              id='selectButton'
              text={successLabel}
              onClick={handleSubmit}
            />
          </div>
        </div>

        <DialogComponent
          key='submitConfirmationDialog'
          isOpen={showSubmitConfirmation}
          onCancel={() => toggleSubmitConfirmation()}
          onSubmit={async () => {
            const selectedTireError = tireList.filter((tire: any) =>  tire?.tireId === selectedError[0]?.postTableId);
            const consignedTireId = selectedTireError[0].consignedTireId;
            const result: any = await billingAPI.updateSoldAndLostTire(consignedTireId, 'Y');
            if (result?.status === 200) {
              setSelectedTireError(selectedTireError);
              toggleSubmitConfirmation();
            }
          }}
          title='Confirmation'
          subText={selectedError[0]?.description}
          onCancelLabel='No'
          onSubmitLabel='Yes'
        />
      </Modal>
    </>
  );
};

export default ViewErrorsModalComponent;