import { FunctionComponent } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { IconButton, List, MessageBarType } from '@fluentui/react';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';
import useNotifications from '../../../../hooks/useNotifications';
import { downloadFile, printingTypes } from '../../../../components/PrintingModal/consts';
import { ACTION_GROUPS_ENUM } from '../ActionsGroupEnum';
import { downloadReport, reportFormat } from './hooks';

interface DownloadAvailableReportActionProps {
  row: any;
  actionsGroupName: string;
}

const DownloadAvailableReportAction: FunctionComponent<DownloadAvailableReportActionProps> = ({
  row,
  actionsGroupName,
}) => {
  const { addNotification } = useNotifications();
  const [showLoading, { toggle: toggleShowLoading }] = useBoolean(false);


  const fetchDownloadReport = async () => {
    toggleShowLoading();
    try {

      const { data }: any = await downloadReport({ row, actionsGroupName });
      const format = reportFormat({ row, actionsGroupName });

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });

      const fileTypeRegex = /(?:\.([^.]+))?$/;
      const fileType: any = fileTypeRegex?.exec(format);

      if (fileType.input === 'pdf' || fileType[1] === 'pdf') {
        downloadFile(data, printingTypes.pdf, row?.reportName);
      } else if (fileType.input === 'excel') {
        downloadFile(data, printingTypes.excel, row?.reportName);
      } else if (fileType[1] === 'xlsx') {
        downloadFile(data, printingTypes.excel);
      }

    } catch (e: any) {
      addNotification({
        text: 'Printing Error',
        type: MessageBarType.error,
      });
    } finally {
      toggleShowLoading();
    }
  };

  const handleClick = () => fetchDownloadReport();
  const disableAction = row.status ? row.status !== 'DONE' : false;
  return (
    <>
      <IconButton
        id='downloadReport'
        iconProps={{ iconName: 'DownloadDocument' }}
        onClick={handleClick}
        disabled={disableAction}
      />
      {showLoading && <LoadingScreen></LoadingScreen>}
    </>
  );
};

export default DownloadAvailableReportAction;