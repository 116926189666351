import { FC, FormEvent, ReactElement } from 'react';
import { IFinancialDetailsTabProps } from './FinancialDetailsTabProps';
import styles from './FinancialDetailsTab.module.scss';

import { Dropdown, IDropdownOption, Text, TextField, Toggle } from '@fluentui/react';
import classNames from 'classnames';
import { get } from 'lodash';
import moment from 'moment';

const FinancialDetailsTab: FC<IFinancialDetailsTabProps> = ({ 
  details,
  billedByOptions,
  billedBy,
  setBilledBy,
  remainingTreadDepth,
  setRemainingTreadDepth,
}): ReactElement => {

  const onChangeRemainingTreadDepthField = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: any) => {
    setRemainingTreadDepth(/^[0-9]*$/.test(value!) && value);
  };

  const onChangeBilledByField = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void =>
    setBilledBy(item?.text);

  return (
    <>
      <div className={classNames(styles.settings)}>
        <div>
          <div className={classNames(styles.filters)}>
            <div>
              <label>Accounted?</label>
              <Toggle
                checked={details?.accounted}
                defaultChecked
                onText="Yes"
                offText="No"
                disabled
              />
            </div>
            <div>
              <label>Billed?</label>
              <Toggle
                checked={details?.billed}
                defaultChecked
                onText="Yes"
                offText="No"
                disabled
              />
            </div>
            <div>
              <label>Billed by</label>
              <Dropdown
                id="billedBy"
                placeholder="Select an option"
                options={billedByOptions.map((item: any) => ({
                  key: item,
                  text: item,
                }))}
                onChange={onChangeBilledByField}
                defaultSelectedKey={billedBy}
                disabled={!details}
              />
            </div>
            <div>
              <label>Remaining Tread Depth</label>
              <TextField
                id="remainingTreadDepth"
                value={remainingTreadDepth}
                onChange={onChangeRemainingTreadDepthField}
                disabled={!details || !details.treadDepth}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.body)}>
          <div className={classNames(styles.infoBlocks)}>
            <div className="infoBlock">
              <Text variant="xLarge" className={styles.highlight}>Financial Details:</Text>
              <div className={classNames(styles.infoBlockData)}>
                <div className={classNames(styles.row)}>
                  <Text variant="medium" className={styles.highlight}>Last Mileage Run:</Text>
                  <Text variant="medium" className={styles.highlight}>
                    {details?.details.lastMileageRun ? moment(details.details.lastMileageRun).format('MM/DD/YYYY') : 'N/A'}
                  </Text>
                </div>
                <div className={classNames(styles.row)}>
                  <Text variant="medium" className={styles.highlight}>Total Mileage:</Text>
                  <Text variant="medium" className={styles.highlight}>
                    {get(details, 'details.totalMileage', 'N/A')}
                  </Text>
                </div>
                <div className={classNames(styles.row)}>
                  <Text variant="medium" className={styles.highlight}>Tire Cost:</Text>
                  <Text variant="medium" className={styles.highlight}>
                    {get(details, 'details.tireCost', 'N/A')}
                  </Text>
                </div>
                <div className={classNames(styles.row)}>
                  <Text variant="medium" className={styles.highlight}>Cost Recovered:</Text>
                  <Text variant="medium" className={styles.highlight}>
                    {get(details, 'details.costRecovered', 'N/A')}
                  </Text>
                </div>
                <div className={classNames(styles.row)}>
                  <Text variant="medium" className={styles.highlight}>Billed Amount:</Text>
                  <Text variant="medium" className={styles.highlight}>
                    {get(details, 'details.billedAmount', 'N/A')}
                  </Text>
                </div>
                <div className={classNames(styles.row)}>
                  <Text variant="medium" className={styles.highlight}>Casing Value:</Text>
                  <Text variant="medium" className={styles.highlight}>
                    {get(details, 'details.casingValue', 'N/A')}
                  </Text>
                </div>
              </div>
            </div>
            <div className="infoBlock">
              <Text variant="xLarge" className={styles.highlight}>Takeover Details</Text>
              <div className={classNames(styles.infoBlockData)}>
                <div className={classNames(styles.row)}>
                  <Text variant="medium" className={styles.highlight}>Residual Value:</Text>
                  <Text variant="medium" className={styles.highlight}>
                    {get(details, 'takeover.residualValue', 'N/A')}
                  </Text>
                </div>
                <div className={classNames(styles.row)}>
                  <Text variant="medium" className={styles.highlight}>Residual Miles:</Text>
                  <Text variant="medium" className={styles.highlight}>
                    {get(details, 'takeover.residualMiles', 'N/A')}
                  </Text>
                </div>
                <div className={classNames(styles.row)}>
                  <Text variant="medium" className={styles.highlight}>Takeover Date:</Text>
                  <Text variant="medium" className={styles.highlight}>
                    {get(details, 'takeover.takeoverDate', 'N/A')}
                  </Text>
                </div>
                <div className={classNames(styles.row)}>
                  <Text variant="medium" className={styles.highlight}>Tire Mfgr:</Text>
                  <Text variant="medium" className={styles.highlight}>
                    {get(details, 'takeover.tireMfgr', 'N/A')}
                  </Text>
                </div>
              </div>
            </div>
            <div className="infoBlock">
              <Text variant="xLarge" className={styles.highlight}>Billing Price</Text>
              <div className={classNames(styles.infoBlockData)}>
                <div className={classNames(styles.row)}>
                  <Text variant="medium" className={styles.highlight}>Basis Unit:</Text>
                  <Text variant="medium" className={styles.highlight}>
                    {get(details, 'billing.basisUnit', 'N/A')}
                  </Text>
                </div>
                <div className={classNames(styles.row)}>
                  <Text variant="medium" className={styles.highlight}>Billing Price:</Text>
                  <Text variant="medium" className={styles.highlight}>
                    {get(details, 'billing.billingPrice', 'N/A')}
                  </Text>
                </div>
                <div className={classNames(styles.row)}>
                  <Text variant="medium" className={styles.highlight}>Casing Value:</Text>
                  <Text variant="medium" className={styles.highlight}>
                    {get(details, 'billing.casingValue', 'N/A')}
                  </Text>
                </div>
                <div className={classNames(styles.row)}>
                  <Text variant="medium" className={styles.highlight}>Current Rate Group:</Text>
                  <Text variant="medium" className={styles.highlight}>
                    {get(details, 'billing.currentRateGroup', 'N/A')}
                  </Text>
                </div>
                <div className={classNames(styles.row)}>
                  <Text variant="medium" className={styles.highlight}>Current Tire Rate:</Text>
                  <Text variant="medium" className={styles.highlight}>
                    {get(details, 'billing.currentTireRate', 'N/A')}
                  </Text>
                </div>
                <div className={classNames(styles.row)}>
                  <Text variant="medium" className={styles.highlight}>Price Tread Depth:</Text>
                  <Text variant="medium" className={styles.highlight}>
                    {get(details, 'billing.priceTreadDepth', 'N/A')}
                  </Text>
                </div>
                <div className={classNames(styles.row)}>
                  <Text variant="medium" className={styles.highlight}>Total Billing Price:</Text>
                  <Text variant="medium" className={styles.highlight}>
                    {get(details, 'billing.totalBillingPrice', 'N/A')}
                  </Text>
                </div>
                <div className={classNames(styles.row)}>
                  <Text variant="medium" className={styles.highlight}>Total Unit Run:</Text>
                  <Text variant="medium" className={styles.highlight}>
                    {get(details, 'billing.totalUnitRun', 'N/A')}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FinancialDetailsTab;
