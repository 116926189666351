import { createSlice } from '@reduxjs/toolkit';
import { IPostSpareStock } from '../components/RecordKeeping/tabComponents/SpareStockTab/tabComponents/PostSpareStock/IPostSpareStock';

const initialState: {
    items: Array<IPostSpareStock>,
} = {
  items: [],
};

const postSpareStockSlice = createSlice({
  name: 'postSpareStock',
  initialState,
  reducers: {
    addPostSpareStockItems: (state, action) => {
      state.items = [...state.items, ...action.payload];
    },
    replacePostSpareStockItems: (state, action) => {
      state.items = action.payload;
    },
    removePostSpareStockItems: (state, action) => {
      state.items = state.items.filter(({ id }) => action.payload.indexOf(id) === -1);
    },
    clearPostSpareStockItems: (state) => {
      state.items = [];
    },
    editPostSpareStockItem: (state, action) => {
      state.items = state.items.map((item: IPostSpareStock) => item.id === action.payload.id ? { ...item, [action.payload.field]: action.payload.value } : item);
    },
  },
});

export const {
  addPostSpareStockItems,
  replacePostSpareStockItems,
  removePostSpareStockItems,
  clearPostSpareStockItems,
  editPostSpareStockItem,
} = postSpareStockSlice.actions;

export const postSpareStockSelector = (state: any) => state.postSpareStock.items;

export default postSpareStockSlice.reducer;