export enum MIDAS_MODULES {
  HOME = 'home',
  CUSTOMER = 'Customer',
  QUOTE = 'Quote',
  TERMS = 'Terms',
  TIRE_ORDERING = 'Tire Orders',
  RECORD_KEEPING = 'RecordKeeping',
  BILLING = 'Billing',
  REPORTING = 'Reporting',
  MY_REPORTS = 'My Reports',
  MAINTAIN = 'MasterData',
};