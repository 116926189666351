import { DefaultButton, IconButton, MessageBarType, Modal, PrimaryButton, Text } from '@fluentui/react';
import { FunctionComponent, useState } from 'react';
import { pageSizes, shipToOemHeadCell, ShipToOemModalComponentProps } from './consts';
import styles from './ShipToOemModalComponent.module.scss';
import apiService from '../../api';
import useNotifications from '../../hooks/useNotifications';
import { useBoolean } from '@fluentui/react-hooks';
import DataGridComponent from '../DataGridComponent';
import { useSelector } from 'react-redux';
import { customerSelector } from '../../redux/recordKeepingSlice';

const ShipToOemModalComponent: FunctionComponent<ShipToOemModalComponentProps> = ({
  id,
  title,
  isOpen,
  onSubmit,
  onDismiss,
  successLabel,
  cancelLabel,
}) => {
  const { addNotification } = useNotifications();
  const [shipToOemList, setShipToOemList] = useState([]);
  const [totalFound, setTotalFound] = useState(0);
  const [shipToOemSelected, setShipToOemSelected] = useState({});
  const [notification, setNotification] = useState({ text: '', type: 0 });
  const [isLoading, { toggle: toggleShowLoadingScreen }] = useBoolean(false);

  const { id: customerId } = useSelector(customerSelector);

  const fetchData = async (pagination: any, filters?: any, sortOrder?: any) => {
    try {
      toggleShowLoadingScreen();
      const { data }: any = await apiService.maintainOEMsAPI.get(pagination, filters, sortOrder);
      setShipToOemList(data?.data);
      setTotalFound(data?.total?.found);
      toggleShowLoadingScreen();
    } catch (error) {
      setNotification({ text: 'Fetching typeCodes error', type: MessageBarType.error });
    }
  };

  const handleChangeDataGridState = (dataGridState: any) => {
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString: searchedText,
    };
    fetchData(pagination, filters, sortOrder);
  };

  const handleSelectRow = (rowsSelected: any) => {
    setShipToOemSelected(rowsSelected);
  };

  return (
    <>
      <Modal
        key={id}
        isOpen={isOpen}
        onDismiss={onDismiss}
        containerClassName='modalContainer'
      >
        <div className={styles.modalBody}>
          <div className='modalHeader'>
            <Text id="title" variant="xLarge" className={styles.highlight}>{title}</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel="Close popup modal"
              onClick={onDismiss}
            />
          </div>
          <div className='tableContainer'>
            <DataGridComponent
              idTable={'shipTo-table'}
              title=''
              headCells={shipToOemHeadCell}
              rowsTable={shipToOemList}
              totalDataFound={totalFound}
              isLoading={isLoading}
              enableCheckBox={true}
              enableSearching={true}
              enablePagination={true}
              enableRowsPerPage={true}
              defaultRowsPerPage={pageSizes}
              handleChangeDataGridState={handleChangeDataGridState}
              handleSelectRow={handleSelectRow}
            />
          </div>
          <div className={styles.modalFooter}>
            <DefaultButton
              id="cancelButton"
              text={cancelLabel}
              onClick={onDismiss}
            />
            <PrimaryButton
              id="selectButton"
              text={successLabel}
              onClick={(e) => onSubmit(shipToOemSelected)}
            />
          </div>
        </div>
      </Modal>
    </>);
};

export default ShipToOemModalComponent;