

import { Checkbox, DatePicker, DefaultButton, Dialog, DialogFooter, Dropdown, Icon, IDropdownOption, MessageBarType, PrimaryButton, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { debounce } from 'lodash';
import moment from 'moment';
import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import apiService from '../../../../api';
import useNotifications from '../../../../hooks/useNotifications';
import { INotification } from '../../../../models/INotification';
import DataGridComponent from '../../../../shared/DataGridComponent';
import { FormBuilderGroup } from '../../../../shared/FormBuilderComponent';
import SwitchComponent from '../../../../shared/SwitchComponent';
import AutoCompleteField from '../../../Common/Search/AutoCompleteField';
import { ICustomer } from '../../../Common/Search/ICustomer';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import { downloadFile, printingTypes } from '../../../PrintingModal/consts';
import PrintingModal from '../../../PrintingModal/PrintingModal';
import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import { pendingMileageRunsHeadCell, SearchOptions, SEARCH_FIELDS } from './consts';
import { IViewPendingMileageRunState } from './IViewPendingMileageRunState';
import styles from './ViewPendingMileageRun.module.scss';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { auth_billing_viewPendingMileageRun } from '../../../../consts/programKeys';
import { transformDate } from '../../../../shared/transformDate';

const ViewPendingMileageRun: FC<any> = (): ReactElement => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_billing_viewPendingMileageRun);

  const { addNotification } = useNotifications();

  const [initialState] = useState<IViewPendingMileageRunState>({
    items: [],
    selectedItems: [],
    foundItems: 0,
    loadingItems: false,
    loading: false,
  });

  const [state, setState] = useState<IViewPendingMileageRunState>({
    items: [],
    selectedItems: [],
    foundItems: 0,
    loadingItems: false,
    loading: false,
  });

  const [countries, setCountries] = useState([]);
  const [regionCodes, setRegionCodes] = useState([]);
  const [customersList, setCustomerList] = useState<ICustomer[]>([]);
  const [customerSelected, setCustomerSelected] = useState<any>();
  const [countrySelected, setCountrySelected] = useState<string>('');
  const [customSearchOption, setCustomSearchOption] = useState<SearchOptions>('regionSearch');
  const [isLoadingFetchCustomers, { toggle: toggleIsLoadingFetchCustomers }] = useBoolean(false);
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });
  const [billingMonth, setBillingMonth] = useState<any>(transformDate(moment().format('MM/DD/YYYY')));
  const [itemsPagination, setItemsPagination] = useState({ pageSize: 10, pageNumber: 1 });
  const [itemsSortOrder, setItemsSortOrder] = useState({ column: pendingMileageRunsHeadCell[0].fieldName, order: 'asc' });
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [reloadControlsTable, { toggle: toggleReloadControlsTable }] = useBoolean(false);
  const [isConfirmEstimateDialogVisible, { toggle: toggleConfirmEstimateConfirmation }] = useBoolean(false);

  const searchFormFieldsGroup = new FormBuilderGroup(SEARCH_FIELDS);

  const removeEmpty = ({ text }: any) => text !== null;

  const getCustomerPrefixList = useMemo(() => customersList ?
    customersList.map(({ id, prefix }) => ({
      key: id,
      text: prefix,
    })).filter(removeEmpty) : [], [customersList]);


  const getCustomerNameList = useMemo(() => customersList ?
    customersList.map(({ id, customerName }) => ({
      key: id,
      text: customerName,
    })).filter(removeEmpty) : [], [customersList]);

  const customerPrefixInputText = (custPrefix: string) => {
    if (custPrefix.replace(/ /g, '').length) {
      getCustomersDebounce({ custPrefix });
    }
  };

  const customerNameInputText = (custName: string) => {
    if (custName.replace(/ /g, '').length) {
      getCustomersDebounce({ custName }, { column: 'customerName', order: 'asc' });
    }
  };

  const getCustomersDebounce = debounce(async (customerData, sortOrder?) => {
    fetchCustomers(customerData, undefined, sortOrder);
  }, 500);


  const onCustomerChange = async (newCustomerId: any) => {
    setCustomerSelected(getCustomerById(newCustomerId));
  };

  const handleCountryChange = (event: any, item: IDropdownOption<any> | undefined): void => {
    if (item?.text) setCountrySelected(item?.text);
  };

  const getCustomerById = (idToFind: number) => customersList?.find(({ id }) => id === idToFind) || null;

  const handleChangeCustomSearch = (property: any) => {
    setCustomSearchOption(property || '');
  };

  const handleCleanSearch = () => {
    setCustomerSelected(null);
    searchFormFieldsGroup.cleanFormData();
    try {
      if (customerSelected.prefix !== null) {
        customerSelected.prefix = '';
        setState(initialState);
        handleSearch();
      }
    } catch {
      console.log(state);
    }
  };

  const onSearchChange = ({ target: { id } }: any, item: IDropdownOption<any> | undefined): void => {
    searchFormFieldsGroup.setFormValue(`${id}Field`, item?.text);
  };

  const handleSearch = async () => {
    await fetchItems();
    toggleReloadControlsTable();
  };

  const handleSelectItems = (rowsSelected: any[]) => {
    setState(prev => ({ ...prev, selectedItems: rowsSelected }));
  };

  const fetchCustomers = async (
    filter: any,
    pagination?: any,
    sort: any = { column: 'custPrefix', order: 'asc' }) => {
    try {
      toggleIsLoadingFetchCustomers();
      const { data }: any = await apiService.getCustomerList(filter, pagination, sort);
      setCustomerList(data.data);
      toggleIsLoadingFetchCustomers();
    } catch (e: any) {
      setNotification({ text: 'Customers fetching error.', type: MessageBarType.error });
    }
  };

  const fetchCountries = async () => {
    try {
      const { data: { countries } } = await apiService.customerAPI.getCountries();
      setCountries(countries);
      setCountrySelected(countries[1]);
    }
    catch (e: any) {
      setNotification({ text: 'Error fetching countries', type: MessageBarType.error });
    }
  };

  const fetchRegionCodes = async () => {
    try {
      const { data: { regionCodes } } = await apiService.customerAPI.getRegionCodes(countrySelected);
      setRegionCodes(regionCodes);
    } catch (e: any) {
      setNotification({ text: 'Error fetching region codes', type: MessageBarType.error });
    }
  };

  const handleChangeItemsDataGridState = async (dataGridState: any) => {
    const { countOnPage, paginationProps, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    if (enableSearchButton) {
      fetchItems(pagination, sortOrder);
    }
  };

  const fetchItems = async (pagination: any = itemsPagination, sortOrder: any = itemsSortOrder) => {
    setItemsPagination(pagination);
    setItemsSortOrder(sortOrder);
    setState(prev => ({ ...prev, loadingItems: true }));
    try {
      const { data }: any = await apiService.billingAPI.getPendingMileageRuns(
        {
          country: countrySelected,
          region: customSearchOption == 'regionSearch' ? searchFormFieldsGroup.getFieldFormValue('regionField') || undefined : undefined,
          brandPrefix: customSearchOption == 'customerSearch' ? customerSelected?.prefix : undefined,
          date: billingMonth,
        },
        pagination,
        sortOrder,
      );
      if (customerSelected?.prefix === '') {
        setState(initialState);
      } else {
        setState(prev => ({ ...prev, items: data.data, foundItems: data.total.found }));
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Pending Mileage Runs fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loadingItems: false }));
    }
  };

  const handleEstimateItems = async () => {
    toggleConfirmEstimateConfirmation();
    setState(prev => ({ ...prev, loading: true }));
    try {
      await apiService.billingAPI.estimatePendingMileageRuns(state.selectedItems.map(el => ({
        customerId: el.customerId,
        billingPeriodId: el.customerBillingPeriodId,
        estimateBillStart: el.estimateBillStart,
        estimateBillEnd: el.estimateBillEnd,
        billableItemId: el.billableItemId,
      })));
      setState(prev => ({ ...prev, selectedItems: [] }));
      fetchItems();
      addNotification({
        text: 'Item(s) was successfully estimated.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Estimation of item(s) error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const requestData = {
        pendingMileageRunSearch: {
          filters: {
            country: countrySelected,
            region: customSearchOption == 'regionSearch' ? searchFormFieldsGroup.getFieldFormValue('regionField') || undefined : undefined,
            brandPrefix: customSearchOption == 'customerSearch' ? customerSelected?.prefix : undefined,
            date: billingMonth,
          },
          pagination: itemsPagination,
          sortOrder: itemsSortOrder,
        },
        headerFields: [],
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.billingAPI.printExcelPendingMileageRuns(requestData) :
        await apiService.billingAPI.printPdfPendingMileageRuns(requestData);

      downloadFile(data, printingType);
      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Printing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (customSearchOption == 'customerSearch') fetchCustomers({});
  }, [customSearchOption]);

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  useEffect(() => {
    if (countrySelected) fetchRegionCodes();
  }, [countrySelected]);

  useEffect(() => {
    if (!countries?.length) fetchCountries();
  }, []);

  const enableSearchButton =
    (customSearchOption == 'regionSearch' && searchFormFieldsGroup.getFieldFormValue('regionField')) ||
    (customSearchOption == 'customerSearch' && customerSelected) ||
    (customSearchOption == 'SAPSearch' && searchFormFieldsGroup.getFieldFormValue('sapInvoiceNoField'));

  return (
    <div>
      <div className='ms-grid'>
        <div className='ms-Grid-row'>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
            <Text variant='xxLarge' className={styles.highlight}>
              View Pending Mileage Run</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
            <Text variant='xLarge' className={styles.highlight}>
              <Icon iconName='Search' />&nbsp;&nbsp;Search Available Pending Mileage Runs
            </Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <div className={classNames('ms-Grid-col', 'ms-sm3', styles.marginTop20)}>
              <Dropdown
                label='Country'
                options={countries.map(country => ({
                  key: country,
                  text: country,
                }))}
                selectedKey={countrySelected}
                onChange={handleCountryChange}
              />
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm9', styles.customerDetailsContainer)}>
              <SeparatorGy />
            </div>
          </div>

          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
            <div className={classNames('ms-Grid-col', 'ms-sm3', styles.searchOptionContainer)}>
              <div className={classNames('ms-Grid-col', 'ms-sm12', styles.customerSearchOption_title)}>
                <Checkbox
                  id={'regionSearch'}
                  onChange={() => handleChangeCustomSearch('regionSearch')}
                  checked={customSearchOption == 'regionSearch'}
                />
                <Text variant='mediumPlus' className={styles.highlight}>Search by Region</Text>
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                <Dropdown
                  {...searchFormFieldsGroup.getFieldForm('regionField')}
                  placeholder='Select a region'
                  options={regionCodes.map(region => ({
                    key: region,
                    text: region,
                  }))}
                  onChange={onSearchChange}
                  selectedKey={searchFormFieldsGroup.getFieldFormValue('regionField')}
                  disabled={customSearchOption != 'regionSearch'}
                />
              </div>
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm4', styles.searchOptionContainer)}>
              <div className={classNames('ms-Grid-col', 'ms-sm12', styles.customerSearchOption_title)}>
                <Checkbox
                  onChange={() => handleChangeCustomSearch('customerSearch')}
                  checked={customSearchOption == 'customerSearch'}
                />
                <Text variant='mediumPlus' className={styles.highlight}>Search by Customer</Text>
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                <AutoCompleteField
                  onChangeAction={onCustomerChange}
                  value={customerSelected?.id}
                  list={getCustomerPrefixList}
                  label="Customer Prefix"
                  data-testid='customer-prefix'
                  icon="FavoriteStar"
                  textValue={customerPrefixInputText}
                  isLoading={isLoadingFetchCustomers}
                  disabled={customSearchOption != 'customerSearch'}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                <AutoCompleteField
                  onChangeAction={onCustomerChange}
                  value={customerSelected?.id}
                  list={getCustomerNameList}
                  label="Customer Name"
                  data-testid='customer-name'
                  icon="Contact"
                  textValue={customerNameInputText}
                  isLoading={isLoadingFetchCustomers}
                  disabled={customSearchOption != 'customerSearch'}
                />
              </div>
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm2', styles.searchBtnContainer)}>
              <PrimaryButton
                id="searchBtn"
                text="Search"
                onClick={handleSearch}
                disabled={!enableSearchButton}
              />
              <DefaultButton id='clearSearchBtn' onClick={handleCleanSearch} text="Clear Search" />
            </div>
          </div>
        </div>
        <div className='ms-Grid-row'>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <SeparatorGy />
          </div>
        </div>
        <div className='ms-Grid-row'>
          <div className={classNames('ms-Grid-col', 'ms-sm3')}>
            <DatePicker
              className={styles.orderDetailsField}
              label="For the billing month"
              value={moment(billingMonth).toDate()}
              onSelectDate={setBillingMonth}
              formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
            />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm2', styles.filterBtnContainer)}>
            <PrimaryButton
              id="retrieveButton"
              onClick={handleSearch}
              disabled={!enableSearchButton}
              text="Retrieve"
            />
          </div>
        </div>
        <div className='ms-Grid-row'>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
            <DataGridComponent
              idTable={'mileage-run-pending-table'}
              title='Available Customers - Mileage Run Pending'
              headCells={pendingMileageRunsHeadCell}
              rowsTable={state.items}
              totalDataFound={state.foundItems}
              handleChangeDataGridState={handleChangeItemsDataGridState}
              handleSelectRow={handleSelectItems}
              isLoading={state.loadingItems}
              reloadControlsTable={reloadControlsTable}
              enablePagination
              enableCheckBox
              enableResizeColumns
              enableRowsPerPage
            />
          </div>
        </div>
        <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
          <DefaultButton
            id="printButton"
            onClick={toggleShowPrintExport}
            disabled={!enableSearchButton}
            text="Print/Export"
          />
          <PrimaryButton
            id="estimateButton"
            onClick={toggleConfirmEstimateConfirmation}
            disabled={!state.selectedItems.length || !userPermissions.isWrite}
            text="Estimate"
          />
        </div>
      </div>
      <Dialog
        hidden={!isConfirmEstimateDialogVisible}
        onDismiss={toggleConfirmEstimateConfirmation}
        maxWidth="initial"
        title={'Are you sure you would like to estimate this mileage?'}
        modalProps={{ isBlocking: true }}>
        <table>
          <thead>
            <tr>
              {pendingMileageRunsHeadCell.map(item => (
                <th key={item.key}>{item.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {state.selectedItems.map(item => (
              <tr key={item.billableItemId} className={styles.trBasic} >
                <td>{item.brandPrefix}</td>
                <td>{item.customerName}</td>
                <td>{item.lastMileageRun && moment(item.lastMileageRun).format('MM/DD/YYYY')}</td>
                <td>{item.lastBillDoneOn && moment(item.lastBillDoneOn).format('MM/DD/YYYY')}</td>
                <td>{item.lastBillType}</td>
                <td>{item.estimateBillStart && moment(item.estimateBillStart).format('MM/DD/YYYY')}</td>
                <td>{item.estimateBillEnd && moment(item.estimateBillEnd).format('MM/DD/YYYY')}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <DialogFooter>
          <DefaultButton
            onClick={toggleConfirmEstimateConfirmation}
            text="No - Return" />
          <PrimaryButton id="yesButton"
            onClick={handleEstimateItems}
            text="Yes - Estimate" />
        </DialogFooter>
      </Dialog>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      {state.loading && <LoadingScreen />}
    </div>
  );
};

export default ViewPendingMileageRun;
