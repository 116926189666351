import { FC, FormEvent, useEffect, useMemo, useState } from 'react';
import { get, isNil } from 'lodash';

import { DefaultButton, Dropdown, IColumn, IconButton, IDropdownOption, Modal, PrimaryButton, Text } from '@fluentui/react';

import { IPaginationProps } from '../Pagination/IPaginationProps';

import { pageSizes } from './consts';
import { ISelectingModalProps } from './ISelectingModalProps';

import styles from './SelectingModal.module.scss';
import Pagination from '../Pagination/Pagination';
import SeparatorGy from '../SeparatorGy/SeparatorGy';
import moment from 'moment';

const SelectingModal: FC<ISelectingModalProps> = ({ isOpen, selectingList, onDismiss, onSubmit, title, preselectedKey, columns }) => {
  const [selectedKey, setSelectedKey] = useState<string | null | undefined>(preselectedKey);
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [columnsState, setColumnsState] = useState<Array<any>>([...columns]);

  const pre: string = useMemo(() => title.replace(/\s/g, ''), []);

  useEffect(() => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1, total: Math.ceil(selectingList.length / +countOnPage.key) }));
  }, [countOnPage, isOpen, selectingList.length, columnsState]);

  useEffect(() => {
    if (isOpen) {
      setSelectedKey(preselectedKey);
    }
  }, [isOpen]);


  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    item && setCountOnPage(item);
  };

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const getSortedList = () => {
    const sortedColumn = columnsState.find(({ isSorted }: IColumn) => isSorted);
    if (!sortedColumn) return selectingList;
    return [...selectingList].sort((a, b) => (sortedColumn.isSortedDescending ? -1 : 1) * (get(a, sortedColumn.fieldName, 0) > get(b, sortedColumn.fieldName, 0) ? 1 : -1));
  };

  const boolToString = (item: any, fieldName: any) => {
    const value = get(item, fieldName, '');
    if (value === true) {
      return 'Yes';
    } else if (value === false) {
      return 'No';
    } else if (/[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z/gm.test(value)) {
      return moment(value).format('MM/DD/YYYY');
    } else {
      return value;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      isBlocking={true}
      containerClassName={styles.modalContainer}
    >
      <div>
        <Text variant="xLarge">{title}</Text>
        <IconButton
          iconProps={{ iconName: 'Cancel' }}
          ariaLabel="Close popup modal"
          onClick={onDismiss}
        />
      </div>
      <div>
        <Text variant="large">Show # of rows:&nbsp;</Text>
        <Dropdown
          options={pageSizes.map(pageSize => ({
            key: pageSize,
            text: pageSize.toString(),
          }))}
          selectedKey={countOnPage?.key}
          onChange={onChangeCountOnPage}
        />
        <SeparatorGy vertical />
        <Text variant="large">{selectingList.length} Found</Text>
      </div>
      <div className={styles['table-wrapper']}>
        <table>
          <thead>
            <tr>
              {
                columnsState.map(item => (
                  <th
                    key={item.name}
                    className={
                      item.isSorted && item.isSortedDescending ?
                        styles.descending :
                        item.isSorted && !item.isSortedDescending ? styles.ascending : undefined
                    }
                    onClick={() => onColumnClick(item)}
                  >
                    {item.name}
                  </th>
                ))
              }
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              getSortedList().slice((paginationProps.current - 1) * +countOnPage.key, (paginationProps.current - 1) * +countOnPage.key + +countOnPage.key).map(item => {
                return (
                  <tr key={item.id}>
                    {
                      columnsState.map(({ fieldName }) => (
                        <td key={`${item.id}-${fieldName}`}>
                          <Text>{boolToString(item, fieldName)}</Text>
                        </td>
                      ))
                    }
                    <td>
                      <div className={styles.round}>
                        <input
                          type="checkbox"
                          id={`${pre}-${item.id}`}
                          checked={selectedKey === item.id}
                          onChange={() => { setSelectedKey(item.id); }}
                        />
                        <label htmlFor={`${pre}-${item.id}`}></label>
                      </div>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
      <div>
        <Pagination {...paginationProps} />
      </div>
      <div>
        <DefaultButton id='cancelBtn' text="Cancel" onClick={onDismiss} disabled={isNil(selectedKey)}/>
        <PrimaryButton id='okBtn' text="Ok" onClick={() => onSubmit(selectedKey)} disabled={isNil(selectedKey)} />
      </div>
    </Modal>
  );
};

SelectingModal.defaultProps = {
  title: '',
  preselectedKey: null,
};

export default SelectingModal;