import { FunctionComponent } from 'react';
import { auth_tireOrder_moveToInTransit, auth_tireOrder_placeOriginal, auth_tireOrder_placeRetread, auth_tireOrder_tireOrders } from '../../../../consts/programKeys';
import NewPlaceOriginal from './tabComponents/NewPlaceOriginal/NewPlaceOriginal';
import NewPlaceRetread from './tabComponents/NewPlaceRetread/NewPlaceRetread';
import ReturnToInTransit from './tabComponents/ReturnToInTransit/ReturnToInTransit';
import TireOrders from './tabComponents/TireOrders/TireOrders';

export const tireOrderManagementPageSizes = [100, 250, 500];

export const tireOrderManagementTabs: ITireOrderManagementTabs = {
  tireOrders: {
    id: 'tireOrders',
    accessKey: auth_tireOrder_tireOrders,
    name: 'Tire Orders',
    component: TireOrders,
  },
  moveToInTransit: {
    id: 'moveToInTransit',
    accessKey: auth_tireOrder_moveToInTransit,
    name: 'Move to In-Transit',
    component: ReturnToInTransit,
  },
  placeOriginal: {
    id: 'placeOriginal',
    accessKey: auth_tireOrder_placeOriginal,
    name: 'New Place Original',
    component: NewPlaceOriginal,
  },
  placeRetread: {
    id: 'placeRetread',
    accessKey: auth_tireOrder_placeRetread,
    name: 'New Place Retread',
    component: NewPlaceRetread,
  },
};

export interface ITireOrderManagementTabs {
  tireOrders: TabComponent;
  moveToInTransit: TabComponent;
  placeOriginal: TabComponent;
  placeRetread: TabComponent;
}

export interface TabComponent {
  id: string,
  accessKey: string,
  name: string,
  component: FunctionComponent<any> | null
}

export const orderSteps = [
  'Order Details',
  'Shipping Instructions',
  'History And Messages',
];