import { IconButton } from '@fluentui/react';
import { FunctionComponent } from 'react';
import ViewMaintainInvoicesModalComponent from '../../../ViewMaintainInvoicesModalComponent';
import { useBoolean } from '@fluentui/react-hooks';
import { IAvailableInvoice } from '../../../../models/IAvailableInvoice';


interface ViewInvoiceActionProps {
  row: IAvailableInvoice;
}
 
const ViewInvoiceAction: FunctionComponent<ViewInvoiceActionProps> = ({row}) => {  

  const [isModalInvoiceOpen, { toggle: toggleIsModalInvoiceOpen }] = useBoolean(false);  


  return (  
    <>
      <IconButton
        id='viewButton'
        iconProps={{ iconName: 'View' }}
        onClick={toggleIsModalInvoiceOpen}
      />

      <ViewMaintainInvoicesModalComponent
        id='invoiceModal'
        isOpen={isModalInvoiceOpen}
        title='View Invoice'
        invoiceId={row?.invoiceId}
        onCancel={toggleIsModalInvoiceOpen}
        onSubmit={toggleIsModalInvoiceOpen}
        actionType='view'
      />
    </>
  );
};
 
export default ViewInvoiceAction;