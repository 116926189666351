import { IDropdownOption } from '@fluentui/react';
import { HeadCell } from '../../../../../../shared/DataGridComponent/DataGridModels';

export const idleReasonOptions: Array<IDropdownOption> = [
  {
    key: 'Repairs',
    text: 'Repairs',
  },
  {
    key: 'Pending Scrap/Sold',
    text: 'Pending Scrap/Sold',
  },
  {
    key: 'Legal Hold',
    text: 'Legal Hold',
  },
  {
    key: 'Sold/Scrapped',
    text: 'Sold/Scrapped',
  },
  {
    key: 'Needs unregistered',
    text: 'Needs unregistered',
  },
  {
    key: 'No GYs/No Miles',
    text: 'No GYs/No Miles',
  },
  {
    key: 'Other',
    text: 'Other',
  },
];

export const mileageAuditHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Vehicle',
    fieldName: 'vehicle',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Idle Since',
    fieldName: 'idleSince',
    typeField: 'date',
  },
  {
    key: 'column2',
    name: 'Info Recorded',
    fieldName: 'infoRecorded',
    typeField: 'date',
    isEditable: true,
  },
  {
    key: 'column3',
    name: 'Equipped?',
    fieldName: 'equiped',
    typeField: 'number',
  },
  {
    key: 'column4',
    name: 'Idle Reason',
    fieldName: 'idleReason',
    typeField: 'text',
    isEditable: true,
    selectableOptions: idleReasonOptions,
  },
  {
    key: 'column5',
    name: 'Comment',
    fieldName: 'comment',
    typeField: 'text',
    isEditable: true,
  },
];

export const mileageAuditMock = [
  {
    vehicle: 'vehicle',
    idleSince: '03/01/2022',
    infoRecorded: '03/01/2022',
    equiped: 6,
    idleReason: 'idleReason',
    comment: 'comment',
  },
  {
    vehicle: 'vehicle',
    idleSince: '03/01/2022',
    infoRecorded: '03/01/2022',
    equiped: 6,
    idleReason: 'idleReason',
    comment: 'comment',
  },
  {
    vehicle: 'vehicle',
    idleSince: '03/01/2022',
    infoRecorded: '03/01/2022',
    equiped: 6,
    idleReason: 'idleReason',
    comment: 'comment',
  },
  {
    vehicle: 'vehicle',
    idleSince: '03/01/2022',
    infoRecorded: '03/01/2022',
    equiped: 6,
    idleReason: 'idleReason',
    comment: 'comment',
  },
  {
    vehicle: 'vehicle',
    idleSince: '03/01/2022',
    infoRecorded: '03/01/2022',
    equiped: 6,
    idleReason: 'idleReason',
    comment: 'comment',
  },
];

export const auditMonthYearMock = [  
  {
    id: 1,
    month: 'January',
    year: '2022',
  },
  {
    id: 2,
    month: 'February',
    year: '2022',
  },
  {
    id: 3,
    month: 'March',
    year: '2022',
  },
  {
    id: 4,
    month: 'April',
    year: '2022',
  },
];

export const columns = [
  {
    key: 'column0',
    name: 'From Bill Period',
    fieldName: 'fromBillPeriod',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'To Bill Period',
    fieldName: 'toBillPeriod',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: '# of Buses',
    fieldName: 'busesCount',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: '# of ZMV',
    fieldName: 'zmvCount',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'Status',
    fieldName: 'status',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'Completed Status',
    fieldName: 'completedStatus',
    isSorted: false,
    isSortedDescending: false,
  },
];