import React, { FC, ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  Dropdown,
  IColumn,
  IconButton,
  IDropdownOption,
  MessageBarType,
  PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';
import { useSelector } from 'react-redux';
import { useBoolean } from '@fluentui/react-hooks';
import { get, isNil, isNull, isString } from 'lodash';

import apiService from '../../../../../../api';
import useNotifications from '../../../../../../hooks/useNotifications';
import { sortOrder } from '../../../../../../consts/sortOrder';

import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import Pagination from '../../../../../Pagination/Pagination';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';

import { IOem } from './IOem';
import { defaultAddingFields, pageSizes, plantsColumns } from './consts';
import { IOemsProps } from './IOemsProps';
import { IOemsState } from './IOemsState';

import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_maintainPlants_oem } from '../../../../../../consts/programKeys';

import styles from './Oems.module.scss';
import { oemEMHandler } from '../../../../../../shared/TextFieldValidation';
import DataGridComponent from '../../../../../../shared/DataGridComponent';

const Oems: FC<IOemsProps> = (): ReactElement => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_maintainPlants_oem);

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const { addNotification } = useNotifications();

  const [state, setState] = React.useState<IOemsState>({
    items: [],
    selectedItems: [],
    states: [],
    countries: [],
    loading: false,
    foundCount: 0,
  });

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });

  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(plantsColumns);
  const [filters, setFilters] = useState<any>({ oemNumber: '', name: '', city: '', active: true });
  const [addingFields, setAddingFields] = useState<IOem>(defaultAddingFields);
  const [errorMessages, setErrorMessages] = useState<IOem>(defaultAddingFields);
  const [addedFieldId, setAddedFieldId] = useState(null);

  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);

  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const handleSelect = (e: any, item: IOem) => {
    const selectedRows = [...state.selectedItems];
    if (e.target.checked) {
      selectedRows.push(item);
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows }));
      setErrorMessages(defaultAddingFields);
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows.filter(row => row.id !== item.id) }));
    }
  };

  const handleSelectAll = (e: any, items: Array<any>) => {
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedItems: items })) : setState((prev: any) => ({ ...prev, selectedItems: [] }));
  };

  const getSortOrder = () => {
    const column = columnsState.find(({ isSorted }) => isSorted);
    if (isNil(column))
      return {
        column: 'lastmodifiedon',
        order: sortOrder.DESC,
      };
    const { fieldName, isSortedDescending } = column;
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const onChangeAddField = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | number | undefined, field: string, errorMessage: string) => {
    setAddingFields(prev => ({ ...prev, [field]: value }));
    if (value || value === 0) {
      setErrorMessages(prev => ({ ...prev, [field]: '' }));
    }
    else {
      setErrorMessages(prev => ({ ...prev, [field]: errorMessage }));
    }
  };

  const shouldDisableAddUpdateButton = () => {
    return !(addingFields.oemNumber && addingFields.name && addingFields.city && !isNull(addingFields.state) && addingFields.addressLine1 && !isNull(addingFields.country) && addingFields.zip);
  };

  const handleClearAddingFields = (defaultAddingFields: IOem) => {
    setAddingFields(defaultAddingFields);
    if (state.states) {
      setState(prev => ({ ...prev, states: [] }));
    }
  };

  const parseAddingFieldsErrors = (errors: any[]) => {
    let parsedAddingFieldsErrors = {};
    errors.forEach(el => {
      parsedAddingFieldsErrors = {
        ...parsedAddingFieldsErrors,
        [el.field]: el.message,
      };
    });
    setErrorMessages(prev => ({ ...prev, ...parsedAddingFieldsErrors }));
  };

  const fetchCountries = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.getCountries();
      setState((prev: any) => ({ ...prev, countries: get(data, 'countries', []) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching countries error: ${get(response, 'data.message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchStates = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.getStates(get(state, `countries[${addingFields.country}]`));
      await setState((prev: any) => ({ ...prev, states: get(data, 'states', []) }));
      if (data.states.length && isString(addingFields.state)) {
        setAddingFields(prev => ({
          ...prev,
          state: data.states.findIndex((el: any) => el === prev.state),
        }));
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching states error: ${get(response, 'data.message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const addOem = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.maintainOEMsAPI.addOem({
        ...addingFields,
        country: get(state, `countries[${addingFields.country}]`),
        state: get(state, `states[${addingFields.state}]`),
      });
      setAddedFieldId(data.id);
      setAddingFields(defaultAddingFields);
      fetchOems();
      addNotification({
        text: 'OEM was successfully added.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          parseAddingFieldsErrors(response.data.state);
          return addNotification({
            text: 'Unable to add & validate changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Adding OEM error: ${response?.data?.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const updateOem = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      await apiService.maintainOEMsAPI.updateOem({
        ...addingFields,
        country: get(state, `countries[${addingFields.country}]`),
        state: get(state, `states[${addingFields.state}]`),
      });
      setAddingFields(defaultAddingFields);
      fetchOems();
      addNotification({
        text: 'OEM was successfully updated.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          parseAddingFieldsErrors(response.data.state);
          return addNotification({
            text: 'Unable to update & validate changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Updating OEM error: ${response?.data?.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    setState(prev => ({ ...prev, loading: true }));
    try {
      await apiService.maintainOEMsAPI.deleteOEMs(state.selectedItems.map(el => el.id as string));
      fetchOems();
      setState(prev => ({ ...prev, selectedItems: [] }));
      addNotification({
        text: 'Oems successfully deleted.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Oems deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchOems = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.maintainOEMsAPI.get(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        filters,
        sortOrder,
      );
      const foundCount = data.total.found;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching OEMs error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();

      const requestData = {
        sortOrder,
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        filters: {
          ...filters,
          type: 'OEM',
        },
        headerFields: [],
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.maintainOEMsAPI.printExcel(requestData) :
        await apiService.maintainOEMsAPI.printPdf(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    fetchOems();
  }, [
    paginationProps.current,
    countOnPage,
    columnsState,
  ]);

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (get(state, `countries[${addingFields.country}]`)) {
      fetchStates();
    }
  }, [addingFields.country]);

  useEffect(() => {
    if (state.selectedItems.length !== 1) {
      setAddingFields(defaultAddingFields);
      if (state.states) {
        setState(prev => ({ ...prev, states: [] }));
      }
    }
    else {
      const selectedPlant = state.items.find(({ id }) => id === state.selectedItems[0].id) || defaultAddingFields;
      setAddingFields({
        ...selectedPlant,
        country: state.countries.findIndex(el => el === selectedPlant.country),
      });
    }
  }, [state.selectedItems]);

  return (
    <>
      <Text variant="xLarge" className={styles.highlight}>Maintain OEMs - Create/Update OEM Details</Text>
      <SeparatorGy />
      <div className="ms-Grid">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm2">
            <Text variant="xLarge" className={styles.highlight}>Filter By</Text>
            <SeparatorGy />
            <div>
              <TextField
                label="OEM Number"
                value={filters.oemNumber}
                onChange={(_ev, oemNumber) => setFilters((prev: any) => ({ ...prev, oemNumber }))}
              />
              <TextField
                label="Name"
                value={filters.name}
                onChange={(_ev, name) => setFilters((prev: any) => ({ ...prev, name }))}
              />
              <TextField
                label="City"
                value={filters.city}
                onChange={(_ev, city) => setFilters((prev: any) => ({ ...prev, city }))}
              />
            </div>
            <DefaultButton
              id="applyButton"
              className={styles.applyButton}
              text="Apply"
              onClick={fetchOems}
              disabled={!userPermissions.isWrite}
            />
          </div>
          <div className="ms-Grid-col ms-sm10"><div className="ms-Grid">
            <Text variant="xLarge" className={styles.highlight}>Create/Update</Text>
            <SeparatorGy />
            <div className="ms-Grid-row">
              <TextField
                className="ms-Grid-col ms-sm2"
                label="OEM #"
                value={addingFields.oemNumber}
                onChange={(ev, value) => onChangeAddField(ev, value, 'oemNumber', 'Please enter OEM #')}
                onBlur={(ev) => !ev.target.value && setErrorMessages(prev => ({ ...prev, oemNumber: 'Please enter OEM #' }))}
                required
                //errorMessage={errorMessages.oemNumber}
                onGetErrorMessage={oemEMHandler}
              />
              <TextField
                className="ms-Grid-col ms-sm2"
                label="Name"
                value={addingFields.name}
                onChange={(ev, value) => onChangeAddField(ev, value, 'name', 'Please enter Name')}
                onBlur={(ev) => !ev.target.value && setErrorMessages(prev => ({ ...prev, name: 'Please enter Name' }))}
                required
                errorMessage={errorMessages.name}
              />
              <Dropdown
                className="ms-Grid-col ms-sm2"
                label="Country"
                options={state.countries.map((country, index) => ({
                  key: index,
                  text: country,
                }))
                }
                selectedKey={addingFields.country}
                onChange={(ev: any, option) => onChangeAddField(ev, option?.key, 'country', 'Please enter Country')}
                onBlurCapture={() => isNull(addingFields.country) && setErrorMessages(prev => ({ ...prev, country: 'Please enter Country' }))}
                errorMessage={errorMessages.country as string}
                required
              />
              <Dropdown
                className="ms-Grid-col ms-sm2"
                label="State"
                options={state.states.map((state, index) => ({
                  key: index,
                  text: state,
                }))
                }
                disabled={!state.states.length}
                selectedKey={addingFields.state}
                onChange={(ev: any, option) => onChangeAddField(ev, option?.key, 'state', 'Please enter State')}
                onBlurCapture={() => isNull(addingFields.state) && setErrorMessages(prev => ({ ...prev, state: 'Please enter State' }))}
                errorMessage={errorMessages.state as string}
                required
              />
              <TextField
                className="ms-Grid-col ms-sm2"
                label="Contact Person"
                value={addingFields.contactPerson}
                onChange={(_ev, contactPerson) => setAddingFields((prev: any) => ({ ...prev, contactPerson }))}
              />
              <TextField
                className="ms-Grid-col ms-sm2"
                label="Phone"
                value={addingFields.phone}
                onChange={(_ev, phone) => setAddingFields((prev: any) => ({ ...prev, phone }))}
              />
            </div>
            <div className="ms-Grid-row">
              <TextField
                className="ms-Grid-col ms-sm4"
                label="Address Line 1"
                value={addingFields.addressLine1}
                onChange={(ev, value) => onChangeAddField(ev, value, 'addressLine1', 'Please enter Address Line 1')}
                onBlur={(ev) => !ev.target.value && setErrorMessages(prev => ({ ...prev, addressLine1: 'Please enter Address Line 1' }))}
                required
                errorMessage={errorMessages.addressLine1}
              />
              <TextField
                className="ms-Grid-col ms-sm2"
                label="City"
                value={addingFields.city}
                onChange={(ev, value) => onChangeAddField(ev, value, 'city', 'Please enter City')}
                onBlur={(ev) => !ev.target.value && setErrorMessages(prev => ({ ...prev, city: 'Please enter City' }))}
                required
                errorMessage={errorMessages.city}
              />
              <TextField
                className="ms-Grid-col ms-sm2"
                label="Zip"
                value={addingFields.zip}
                onChange={(ev, value) => onChangeAddField(ev, value, 'zip', 'Please enter Zip Code')}
                onBlur={(ev) => !ev.target.value && setErrorMessages(prev => ({ ...prev, zip: 'Please enter Zip Code' }))}
                required
                errorMessage={errorMessages.zip}
              />
              <TextField
                className="ms-Grid-col ms-sm2"
                label="Email"
                value={addingFields.email}
                onChange={(ev, value) => onChangeAddField(ev, value, 'email', 'Please enter Email')}
              />
              <TextField
                className="ms-Grid-col ms-sm2"
                label="Fax"
                value={addingFields.fax}
                onChange={(_ev, value) => setAddingFields((prev: any) => ({ ...prev, fax: value }))}
              />
            </div>
            <div className="ms-Grid-row">
              <TextField
                className="ms-Grid-col ms-sm4"
                label="Address Line 2"
                value={addingFields.addressLine2}
                onChange={(_ev, value) => setAddingFields((prev: any) => ({ ...prev, addressLine2: value }))}
              />
              <div className="ms-Grid-col ms-sm4" />
              <TextField
                className="ms-Grid-col ms-sm4"
                label="Comments"
                multiline
                rows={8}
                cols={66}
                value={addingFields.comment}
                onChange={(_ev, comment) => setAddingFields((prev: any) => ({ ...prev, comment }))}
              />
            </div>
            <SeparatorGy />
            <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
              <DefaultButton
                id="clearButton"
                className={styles.actionButton}
                text="Clear"
                onClick={() => handleClearAddingFields({ ...addingFields, ...defaultAddingFields })}
                disabled={!userPermissions.isWrite}
              />
              <PrimaryButton
                id="updateButton"
                className={styles.actionButton}
                onClick={updateOem}
                text="Update"
                disabled={(shouldDisableAddUpdateButton() || state.selectedItems.length !== 1) || !userPermissions.isWrite}
              />
              <PrimaryButton
                id="addButton"
                className={styles.actionButton}
                onClick={addOem}
                text="+ Add"
                disabled={(shouldDisableAddUpdateButton() || state.selectedItems.length > 0) || !userPermissions.isWrite}
              />
            </div>
          </div>
          </div>
        </div>
      </div>

      <div className={styles.tableHeading}>
        <div>
          <Text variant="xLarge" className={styles.highlight}>Available OEMs</Text>
          <SeparatorGy vertical />
          <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
        </div>
        <div>
          <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
          <Dropdown
            options={pageSizes.map(pageSize => ({
              key: pageSize,
              text: pageSize.toString(),
            }))}
            selectedKey={countOnPage?.key}
            onChange={onChangeCountOnPage}
          />
          <SeparatorGy vertical />
          <Text variant="large" className={styles.highlight}>{state.selectedItems.length} items selected</Text>
          <SeparatorGy vertical />
          <IconButton
            id="toggleDeletingConfirmationButton"
            disabled={!state.selectedItems.length || !userPermissions.isWrite}
            iconProps={{ iconName: 'Delete' }}
            onClick={toggleDeletingConfirmation}
          />
        </div>
      </div>
      <div className={styles['table-wrapper']}>
        <table className={styles.availablePlantsTable}>
          <thead>
            <tr>
              {
                columnsState.map(item => (
                  <th
                    key={item.name}
                    onClick={() => onColumnClick(item)}>
                    <div className={classNames(
                      item.isSorted && item.isSortedDescending ?
                        styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined,
                      styles.resizable,
                    )}>
                      {item.name}
                    </div>
                  </th>
                ))
              }
              <th className={styles.selectColumn}>
                <div className={styles.round}>
                  <input
                    type="checkbox"
                    id="all"
                    checked={state.items.length !== 0 &&
                      (state.selectedItems.length === +countOnPage.key || state.selectedItems.length === state.items.length)}
                    onChange={(e) => handleSelectAll(e, state.items.map(item => item))}
                  />
                  <label htmlFor="all"></label>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {state.items.map(item => (
              <tr
                key={item.id}
                className={classNames(state.selectedItems.find(el => el.id === item.id) ? styles.trSelected : item.id === addedFieldId ? styles.trAdded : styles.trBasic)}
                onMouseOut={() => item.id === addedFieldId && setAddedFieldId(null)}
              >
                <td>{item.oemNumber}</td>
                <td>{item.name}</td>
                <td>{item.addressLine1}</td>
                <td>{item.addressLine2}</td>
                <td>{item.city}</td>
                <td>{item.state}</td>
                <td>{item.country}</td>
                <td>{item.zip}</td>
                <td>{item.phone}</td>
                <td>{item.fax}</td>
                <td>{item.contactPerson}</td>
                <td>{item.email}</td>
                <td>{item.comment}</td>
                <td>
                  <div className={styles.round}>
                    <input
                      type="checkbox"
                      id={`availablePlants-${item.id}`}
                      checked={!!state.selectedItems.find(el => el.id === item.id)}
                      onChange={(e) => handleSelect(e, item)} />
                    <label htmlFor={`availablePlants-${item.id}`}></label>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <SeparatorGy />
      <Pagination {...paginationProps} />
      <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
        <DefaultButton onClick={toggleShowPrintExport} text="Print/Export" />
      </div>
      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        maxWidth="initial"
        title={`Are you sure you want to delete the following ${state.selectedItems.length} OEM(s)?`}
        modalProps={{ isBlocking: true }}>
        <table className={styles.availablePlantsTable}>
          <tbody>
            {state.selectedItems.map(item => (
              <tr key={item.id} className={styles.trBasic} >
                <td>{item.oemNumber}</td>
                <td>{item.name}</td>
                <td>{item.addressLine1}</td>
                <td>{item.addressLine2}</td>
                <td>{item.city}</td>
                <td>{item.state}</td>
                <td>{item.country}</td>
                <td>{item.zip}</td>
                <td>{item.phone}</td>
                <td>{item.fax}</td>
                <td>{item.contactPerson}</td>
                <td>{item.email}</td>
                <td>{item.comment}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <DialogFooter>
          <DefaultButton
            onClick={toggleDeletingConfirmation}
            text="No, cancel" />
          <PrimaryButton id="deleteButton"
            onClick={handleDelete}
            text="Yes, delete" />
        </DialogFooter>
      </Dialog>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default Oems;
