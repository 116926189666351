import { IFormDataField } from '../../shared/FormBuilderComponent';
import { HeadCell } from '../DataGridComponent/DataGridModels';

export const RATE_GROUPS_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'rateGroupNameField',
    properties: {
      id: 'rateGroupName',
      label: 'Rate Group Name',
      name: 'rateGroupName',
      type: 'text',
      disabled: false,
      required: true,
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'bonusOriginField',
    properties: {
      id: 'bonusOrig',
      label: 'Bonus Original',
      name: 'bonusOrig',
      type: 'text',
      disabled: false,
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'bonusRetreadField',
    properties: {
      id: 'bonusRetread',
      label: 'Bonus Retread',
      name: 'bonusRetread',
      type: 'text',
      disabled: false,
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'ebitPercentage',
    properties: {
      id: 'ebitPct',
      label: 'Ebit Percentage',
      name: 'ebitPct',
      type: 'text',
      disabled: false,
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'fixedBaseAverageField',
    properties: {
      id: 'fixedBaseAverage',
      label: 'Fixedbase Average Origin',
      name: 'fixedBaseAverage',
      type: 'text',
      disabled: false,
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'fixedBaseRetreadField',
    properties: {
      id: 'fixedBaseRetread',
      label: 'Fixedbase Retread Origin',
      name: 'fixedBaseRetread',
      type: 'text',
      disabled: false,
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'tireQuoteRateField',
    properties: {
      id: 'tireQuoteRate',
      label: 'Tire Quote Rate',
      name: 'tireQuoteRate',
      type: 'text',
      disabled: false,
      textTransform: 'uppercase',
    },
  },
];

export const termsRateGroupHeadCells: HeadCell[] = [
  {
    key: 'column0',
    name: 'Rate Group',
    fieldName: 'rategroupName',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Bonus Original',
    fieldName: 'bonusOrig',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Bonus Retread',
    fieldName: 'bonusRtrd',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Fixedbase Avg Orig',
    fieldName: 'fixedbaseAvgOrig',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'Fixedbase Avg Rtrd',
    fieldName: 'fixedbaseAvgRtrd',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column5',
    name: 'Tire Quote Rate',
    fieldName: 'tireQuoteRateC',
    typeField: 'text',
    isEditable: false,
  },
];

export const quotesRateGroupHeadCells: HeadCell[] = [
  {
    key: 'column0',
    name: 'Rate Group',
    fieldName: 'rategroupName',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Bonus Original',
    fieldName: 'bonusOrig',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Bonus Retread',
    fieldName: 'bonusRtrd',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'EBIT',
    fieldName: 'dfltEbitPct',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'Tire Quote Rate',
    fieldName: 'tireQuoteRateC',
    typeField: 'text',
    isEditable: false,
  },
];