import { exportTestInstance, quoteTestInstance } from '../axios';

const getCostDetails = (quoteId: any, quoteVersion: any, customerId: any) => 
  quoteTestInstance.get(`/quote/cost/customer/${quoteId}/${quoteVersion}/${customerId}`);

const getCostParams = (customerId: any) => 
  quoteTestInstance.get(`/quote/cost/customer/${customerId}`);

const getForecastData = (quoteId: string, customerId: string, version: string) => 
  quoteTestInstance.get(`/quote/${quoteId}/customer/${customerId}/version/${version}/forecast`);

const updateQuote = (data: any) => {
  return quoteTestInstance.put('/quote/costs/update', data);
};

const updateQuoteHistory = (body: {id: string, comments: string}) => {
  return quoteTestInstance.put('/quote/history', body);
};

const printExcelForecast = (input: any) =>
  exportTestInstance.post('/quotes/forecast/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfForecast = (input: any) =>
  exportTestInstance.post('/quotes/forecast/export-pdf', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const getRateGroups = (pagination: any, sortOrder: any, filterText: string, customerId: string, contractId: string, quoteId: string, quoteVersion: string) => 
  quoteTestInstance.post('/quote/rates/list', { pagination, sortOrder, filterText, customerId, contractId, quoteId, quoteVersion });
 
const addRateGroups = (payload: any) => 
  quoteTestInstance.post('/quote/rate/add', payload);

const updateRateGroups = (rateGroups: any[], customerId: string, quoteId: string, quoteVersion: string) => 
  quoteTestInstance.put('/quote/rate/update', { rates: rateGroups, customerId, quoteId, quoteVersion });
  
const deleteRateGroups = (ids: any[], quoteId: string, quoteVersion: string) => 
  quoteTestInstance.delete('/quote/rate/delete', { data: { ids, quoteId, quoteVersion } });

const getMakeModels = (pagination: any, sortOrder: any, filterText: string, customerId: string, contractId: string, quoteId: string, quoteVersion: string) => 
  quoteTestInstance.post('/quote/fleets/list', { pagination, sortOrder, filterText, customerId, contractId, quoteId, quoteVersion });

const addMakeModels = (payload: any) => 
  quoteTestInstance.post('/quote/fleet-forecast/make-models',payload);

const updateMakeModels = (payload: any) => 
  quoteTestInstance.put('/quote/fleet-forecast/make-models/update',payload);

const deleteMakeModels = (ids: any[], quoteId: string, quoteVersion: string) => 
  quoteTestInstance.delete('/quote/fleet-forecast/make-models/delete', {data: { ids, quoteId, quoteVersion }} );

const updateQuoteStatus = (id: string, status: string, version: number) => 
  quoteTestInstance.post('/quote/status', { quoteStatusModels: [{id, status, version}] });

const generateQuote = (id: string, customerId: string, version: number) => 
  quoteTestInstance.get(`quote/generate/${id}/${version}/${customerId}`);

const cloneMakeModels = (ids: any, quoteId: string, quoteVersion: string) => 
  quoteTestInstance.post('/quote/fleet-forecast/clone', { ids, quoteId, quoteVersion });

const getProducts = (pagination: any, sortOrder: any, fleetId: string, filterText?: string) => 
  quoteTestInstance.post('/quote/fleet/product/list', { pagination, sortOrder, fleetId, filterText });

const getProductCodes = (filters: any) => 
  quoteTestInstance.post('/quote/fleet/product-codes/list', { filters });

const addProduct = (payload: any) => 
  quoteTestInstance.post('/quote/fleet/product/add', payload);

const updateProduct = (payload: any) => 
  quoteTestInstance.put('/quote/fleet/product/update', payload);

const deleteProducts = (productIds: any[], quoteId: string, quoteVersion: string) => 
  quoteTestInstance.delete('/quote/fleet/product/delete', { data: { productIds, quoteId, quoteVersion } });

const deleteAllProducts = (fleetId: string, quoteId: string, quoteVersion: string) => 
  quoteTestInstance.delete('/quote/fleet/product/delete-all', { data: { fleetId, quoteId, quoteVersion } });
  
export default {
  getCostDetails,
  getCostParams,
  getForecastData,
  updateQuote,
  updateQuoteHistory,
  printExcelForecast,
  printPdfForecast,
  getRateGroups,
  addRateGroups,
  deleteRateGroups,
  updateRateGroups,
  getMakeModels,
  addMakeModels,
  updateMakeModels,
  deleteMakeModels,
  updateQuoteStatus,
  generateQuote,
  cloneMakeModels,
  getProducts,
  getProductCodes,
  updateProduct,
  addProduct,
  deleteAllProducts,
  deleteProducts,
};
