import { tireOrderingTestInstance } from '../axios';

const getEquipmentStatuses = () =>
  tireOrderingTestInstance.get('/sections/equipment-statuses');

const getEquipmentDescriptions = () =>
  tireOrderingTestInstance.get('/sections/equipment-descriptions');

const getMaintainLookupValues = (pagination: any, sortOrder: any, filters: any) =>
  tireOrderingTestInstance.post('/sections', { pagination, sortOrder, filters });

const updateMaintainLookupValues = (payload: any) =>
  tireOrderingTestInstance.put('/sections', payload);

const deleteMaintainLookupValues = (ids: any[]) =>
  tireOrderingTestInstance.delete('/sections', { data: { ids } });

export default {
  getEquipmentStatuses,
  getEquipmentDescriptions,
  getMaintainLookupValues,
  updateMaintainLookupValues,
  deleteMaintainLookupValues,
};