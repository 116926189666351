import { exportTestInstance, recordTestInstance } from '../axios';

export const get = (pagination: any, sortOrder: any, customerId: number, locationId: number) =>
  recordTestInstance.post('/miles-on-tires', { pagination, sortOrder, customerId, locationId });

export const getErrors = (pagination: any, sortOrder: any, customerId: number, locationId: number) =>
  recordTestInstance.post('/miles-on-tires/error', { pagination, sortOrder, customerId, locationId });

export const getBrands = (customerId: number, locationId: number) =>
  recordTestInstance.post('/brand', { customerId, locationId });

export const applyMiles = (id: number, consignedTireId: number, brand: any, applyUnits: any, customerId: number, locationId: number) =>
  recordTestInstance.post('/miles-on-tires/apply', { id, consignedTireId, brand, applyUnits, customerId, locationId });

export const getByVehicle = (vehicleNo: string, pagination: any, sortOrder: any, customerId: number, locationId: number) =>
  recordTestInstance.post('/miles-on-tires/processed', { vehicleNo, pagination, sortOrder, customerId, locationId });

export const submit = (customerId: number) =>
  recordTestInstance.post('/miles-on-tires/submit', { customerId });

export const getConsignedInfo = (brand: any, customerId: any) =>
  recordTestInstance.post('/miles-on-tires/consigned-details', { brand, customerId });

const deleteApplyMilesToTires = (ids: Array<any>) =>
  recordTestInstance.delete('/miles-on-tires/processed', { data: { ids } });

const printExcel = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/miles-on-tires/processed/export-excel', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/miles-on-tires/processed/export-pdf', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  get,
  getErrors,
  getBrands,
  applyMiles,
  getByVehicle,
  submit,
  getConsignedInfo,
  delete: deleteApplyMilesToTires,
  printExcel,
  printPdf,
};
