import { HeadCell } from '../../../../shared/DataGridComponent/DataGridModels';
import { IFormDataField } from '../../../../shared/FormBuilderComponent';

export type SearchOptions = 'regionSearch' | 'customerSearch' | 'SAPSearch';

export const SEARCH_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'regionField',
    properties: {
      id: 'region',
      label: 'Region',
      name: 'region',
      type: 'text',
    },
  },
  {
    formFieldName: 'prefixField',
    properties: {
      id: 'prefix',
      label: 'Prefix',
      name: 'prefix',
      type: 'text',
    },
  },
  {
    formFieldName: 'customerNameField',
    properties: {
      id: 'customerName',
      label: 'Customer Name',
      name: 'customerName',
      type: 'text',
    },
  },
];

export const pendingMileageRunsHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'BP',
    fieldName: 'brandPrefix',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Customer Name',
    fieldName: 'customerName',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Last Mileage Run',
    fieldName: 'lastMileageRun',
    typeField: 'date',
  },
  {
    key: 'column3',
    name: 'Last Bill Done On',
    fieldName: 'lastBillDoneOn',
    typeField: 'date',
  },
  {
    key: 'column4',
    name: 'Last Bill Type',
    fieldName: 'lastBillType',
    typeField: 'text',
  },
  {
    key: 'column5',
    name: 'Estimated Bill Start',
    fieldName: 'estimateBillStart',
    typeField: 'date',
  },
  {
    key: 'column6',
    name: 'Estimated Bill End',
    fieldName: 'estimateBillEnd',
    typeField: 'date',
  },
];