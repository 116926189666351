import React from 'react';
import { MessageBar, Text } from '@fluentui/react';
import { useSelector, useDispatch } from 'react-redux';

import { closeToast, notificationsSelector } from '../../redux/notificationsSlice';
import { INotification } from './INotification';

import styles from './NotificationsManager.module.scss';
import { Redirect, useHistory } from 'react-router-dom';

import { MessageBarType } from '@fluentui/react';
import classNames from 'classnames';

const NotificationsManager = () => {
  const dispatch = useDispatch();

  const notifications = useSelector(notificationsSelector);

  //console.log('Notif type: ' + notifications.map(({ type }: any) => type) + ' bar type: ' + MessageBarType.error);
  return (
    <div className={classNames(notifications.map(({ type }: any) => type === MessageBarType.error ? styles.centeredNotificationsWrapper : styles.notificationsWrapper) ) }>
      {notifications.map(({ id, type, text, handleClick = () => { return null; } }: INotification, index: number) => (
        <MessageBar
          key={index}
          messageBarType={type}
          isMultiline
          onDismiss={() => dispatch(closeToast(id))}
          onClick={handleClick}
          dismissButtonAriaLabel="Close"
        >
          <Text variant="large" block>{text}</Text>
        </MessageBar>
      ),
      )}
    </div>
  );
};

export default NotificationsManager;
