import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBoolean } from '@fluentui/react-hooks';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import { debounce } from 'lodash';
import { customerSelector, updateContacts } from '../../../../../redux/customerSlice';
import DataGridComponent from '../../../../../shared/DataGridComponent';
import { contactsHeadCell } from './const';
import { IContactItem, IContactItemAPI } from './IContacts';
import apiService from '../../../../../api';
import useNotifications from '../../../../../hooks/useNotifications';
import {
  MessageBarType,
} from '@fluentui/react';
import classNames from 'classnames';
import styles from './Contacts.module.scss';

import { useUserPermissions } from '../../../../../hooks/useUserPermissions';
import { auth_customer_contacts } from '../../../../../consts/programKeys';
import { useParams } from 'react-router-dom';

function Contacts() {
  const { id: customerId } = useParams<{ id: string }>();
  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_customer_contacts);
  const { addNotification } = useNotifications();
  const customer = useSelector(customerSelector);
  const [contactList, setContactList] = useState<IContactItem[]>([]);
  const [contactsDisplayed, setContactsDisplayed] = useState<IContactItem[]>([]);
  const [updatedContacts, setUpdatedContacts] = useState<IContactItem[]>([]);
  const [page, setPage] = useState(1);
  const [rowsInScreen, setRowsInScreen] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [idsAdded, setIdsAdded] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [enableSorting, setEnableSorting] = useState(true);
  const [textFilter, setTextFilter] = useState('');
  const [localSortOrder, setLocalSortOrder] = useState<{
    order: string,
    column: string,
  }>({
    column: 'name',
    order: 'asc',
  });

  const dispatch = useDispatch();

  const [isEquals, setEquals] = useState(true);
  const [isEditedDialogVisible, { toggle: toggleEditedConfirmation }] = useBoolean(false);

  const fetchCustomers = async () => {
    setIsLoading(true);
    try {
      const { data: { data } }: any = await apiService.getCustomerSearch({}, null, null, customerId);
      dispatch(updateContacts(data[0].contacts));
    } catch (e: any) {
      addNotification({ text: 'Customers fetching error.', type: MessageBarType.error });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAddContacts = async () => {
    const contactsToAdd: IContactItemAPI[] = contactList.filter((contact) => {
      const isAdded = idsAdded.find(toFind => {
        return toFind === contact.contactId;
      });
      return isAdded ? true : false;
    }).map(contact => {
      return {
        contactName: contact.name,
        contactTitle: contact.title,
        contactEmail: contact.email,
        contactPhone1: contact.phone1,
        contactPhone2: contact.phone2,
        contactFax: contact.fax,
      };
    });
    setIsLoading(true);
    try {
      await apiService.customerAPI.addContactsToCustomer({
        customerId: customer.id,
        contacts: contactsToAdd,
      });
    } catch (error) {
      addNotification({
        text: 'Add contacts request error check missing data',
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUpdateContacts = async () => {
    const contactsToUpdate: IContactItemAPI[] = updatedContacts.map(contact => {
      return {
        contactId: contact.contactId,
        contactName: contact.name,
        contactTitle: contact.title,
        contactEmail: contact.email,
        contactPhone1: contact.phone1,
        contactPhone2: contact.phone2,
        contactFax: contact.fax,
      };
    });
    setIsLoading(true);
    try {
      await apiService.customerAPI.updateContactsToCustomer({
        customerId: customerId,
        contacts: contactsToUpdate,
      });
    } catch (error) {
      addNotification({
        text: 'Contacts request error try later',
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDeleteContacts = async (contactsToDelete: any[]) => {
    try {
      setIsLoading(true);
      await apiService.customerAPI.deleteContactsToCustomer({
        customerId: customerId,
        ids: contactsToDelete,
      });
      fetchCustomers();
      addNotification({
        text: 'Contacts deleted successfully',
        type: MessageBarType.success,
      });
    } catch (error) {
      addNotification({
        text: 'Contacts request error try later',
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateContacts = debounce((updated: IContactItem[] = []) => {
    setEnableSorting(false);
    let newUpdated = [...updatedContacts];
    const newContacts = contactList.map((contact) => {
      const element = updated.find((toFind) => {
        return toFind.contactId === contact.contactId;
      });
      if (element) {
        const addedElement = idsAdded.find(id => {
          return id === element.contactId;
        });

        const isAPIElement = addedElement ? false : true;

        if (isAPIElement) {
          const elementToUpdated = updatedContacts.find(toFind => {
            return toFind.contactId === element.contactId;
          });
          if (elementToUpdated) {
            newUpdated = newUpdated.map(uContact => {
              if (uContact.contactId === elementToUpdated.contactId) {
                return { ...element };
              } else {
                return { ...uContact };
              }
            });
          } else {
            newUpdated.push(element);
          }
        }

        return { ...element };
      } else {
        return { ...contact };
      }
    });
    setUpdatedContacts(newUpdated);
    setContactList(newContacts);
    let newContactsDisplayed: IContactItem[];
    if (page === 1) {
      newContactsDisplayed = newContacts.slice(0, rowsInScreen);
    } else {
      const lowerLimitCalc = (page * rowsInScreen - rowsInScreen);
      const lowerLimit = lowerLimitCalc === 0 ? 0 : lowerLimitCalc;
      const upperLimit = page * rowsInScreen;
      newContactsDisplayed = contactList.slice(lowerLimit, upperLimit);
    }

    setContactsDisplayed(newContactsDisplayed);
    setEquals(false);
  }, 1000);

  const handleSaveChanges = async () => {
    try {
      if (idsAdded.length) {
        await fetchAddContacts();
      }
      if (updatedContacts.length) {
        await fetchUpdateContacts();
      }
      await fetchCustomers();
    } catch (error) {
      addNotification({
        text: 'Contacts request error try later',
        type: MessageBarType.error,
      });
    } finally {
      setEnableSorting(true);
      setInitialData();
      setEquals(true);
    }
  };

  const setInitialData = () => {
    setIdsAdded([]);
    setUpdatedContacts([]);
  };

  const addNewContact = () => {
    setEnableSorting(false);
    const newId: number = Math.random() * 10000000000000000;
    const newRow: IContactItem = {
      contactId: newId, //local id won't be sent to the BE
      name: '',
      title: '',
      email: '',
      phone1: '',
      phone2: '',
      fax: '',
    };
    setIdsAdded([...idsAdded, newId]);
    const newArray: IContactItem[] = [];
    newArray.push(newRow);
    setContactList([...newArray, ...contactList]);

    const newContactsDisplayed = [...newArray, ...contactList].slice(0, rowsInScreen);
    setContactsDisplayed(newContactsDisplayed);
  };

  const deleteContacts = async (rows: IContactItem[] = []) => {
    const newContacts = contactList.filter((contact) => {
      const element = rows.find((toFind) => {
        return toFind.contactId === contact.contactId;
      });
      return element ? false : true;
    });
    const newUpdatedContacts = updatedContacts.filter((contact) => {
      const element = rows.find((toFind) => {
        return toFind.contactId === contact.contactId;
      });
      return element ? false : true;
    });
    const newIdsAdded = idsAdded.filter((id) => {
      const element = rows.find((toFind) => {
        return toFind.contactId === id;
      });
      const isNew = element ? false : true;
      return isNew;
    });
    const newDeleted = rows.filter((toFilter) => {
      const elementAdded = idsAdded.find((toFind) => {
        return toFind === toFilter.contactId;
      });
      const isAPIElement = elementAdded ? false : true;
      return isAPIElement;
    }).map(element => element.contactId);

    const deletionCallback = () => {
      setEnableSorting(true);
      setIdsAdded(newIdsAdded);
      setUpdatedContacts(newUpdatedContacts);
      dispatch(updateContacts(newContacts));
    };
    if (newDeleted.length) {
      await fetchDeleteContacts(newDeleted);
      deletionCallback();
    }
    else {
      deletionCallback();
    }
  };

  const getNewContactsDisplayed = (searchedText: any, sortOrder: any) => {
    return contactList.filter((contact: IContactItem) => {
      const find = searchedText.toUpperCase();
      const safeCompareContact: IContactItem = {
        email: contact.email ?? '',
        fax: contact.fax ?? '',
        name: contact.name ?? '',
        phone1: contact.phone1 ?? '',
        phone2: contact.phone2 ?? '',
        title: contact.title ?? '',
      };
      return (
        safeCompareContact.email?.toUpperCase().includes(find) ||
        safeCompareContact.fax?.toUpperCase().includes(find) ||
        safeCompareContact.name?.toUpperCase().includes(find) ||
        safeCompareContact.phone1?.toUpperCase().includes(find) ||
        safeCompareContact.phone2?.toUpperCase().includes(find) ||
        safeCompareContact.title?.toUpperCase().includes(find)
      );
    }).sort((a: IContactItem, b: IContactItem) => {
      const aSafeContact: IContactItem = {
        email: a.email ?? '',
        fax: a.fax ?? '',
        name: a.name ?? '',
        phone1: a.phone1 ?? '',
        phone2: a.phone2 ?? '',
        title: a.title ?? '',
      };
      const bSafeContact: IContactItem = {
        email: b.email ?? '',
        fax: b.fax ?? '',
        name: b.name ?? '',
        phone1: b.phone1 ?? '',
        phone2: b.phone2 ?? '',
        title: b.title ?? '',
      };
      switch (sortOrder?.column) {
        case 'name':
          if (sortOrder?.order === 'asc') {
            return aSafeContact.name?.localeCompare(bSafeContact.name || '') || 0;
          } else {
            return bSafeContact.name?.localeCompare(aSafeContact.name || '') || 0;
          }
        case 'title':
          if (localSortOrder?.order === 'asc') {
            return aSafeContact.title?.localeCompare(bSafeContact.title || '') || 0;
          } else {
            return bSafeContact.title?.localeCompare(aSafeContact.title || '') || 0;
          }
        case 'email':
          if (sortOrder?.order === 'asc') {
            return aSafeContact.email?.localeCompare(bSafeContact.email || '') || 0;
          } else {
            return bSafeContact.email?.localeCompare(aSafeContact.email || '') || 0;
          }
        case 'phone1':
          if (sortOrder?.order === 'asc') {
            return aSafeContact.phone1?.localeCompare(bSafeContact.phone1 || '') || 0;
          } else {
            return bSafeContact.phone1?.localeCompare(aSafeContact.phone1 || '') || 0;
          }
        case 'phone2':
          if (sortOrder?.order === 'asc') {
            return aSafeContact.phone2?.localeCompare(bSafeContact.phone2 || '') || 0;
          } else {
            return bSafeContact.phone2?.localeCompare(aSafeContact.phone2 || '') || 0;
          }
        case 'fax':
          if (sortOrder?.order === 'asc') {
            return aSafeContact.fax?.localeCompare(bSafeContact.fax || '') || 0;
          } else {
            return bSafeContact.fax?.localeCompare(aSafeContact.fax || '') || 0;
          }
        default:
          if (sortOrder?.order === 'asc') {
            return aSafeContact.name?.localeCompare(b.name || '') || 0;
          } else {
            return b.name?.localeCompare(aSafeContact.name || '') || 0;
          }
      }
    });
  };

  const handlePageChange = async (data: any) => {
    const { countOnPage, paginationProps, sortOrder, searchedText } = data;
    setLocalSortOrder(sortOrder);
    setTextFilter(searchedText);
    setRowsInScreen(countOnPage?.key);
    setPage(paginationProps.current);
    const lowerLimitCalc = (paginationProps.current * countOnPage?.key - countOnPage?.key);
    const lowerLimit = lowerLimitCalc === 0 ? 0 : lowerLimitCalc;
    const upperLimit = paginationProps.current * countOnPage?.key;
    const newContactsDisplayed = getNewContactsDisplayed(searchedText, sortOrder)
      .slice(lowerLimit, upperLimit);
    setContactsDisplayed(newContactsDisplayed);
    setTotalRows(getNewContactsDisplayed(searchedText, sortOrder).length ?? newContactsDisplayed.length);
  };

  useEffect(() => {
    let shouldDisableSaveButton = true;
    for (let index = 0; index < contactList.length; index++) {
      const element = contactList[index];
      const isElementAdded = idsAdded.find((toFind) => {
        return toFind === element.contactId;
      });
      const isElementUpdated = updatedContacts.find((toFind) => {
        return toFind.contactId === element.contactId;
      });
      if (element.name && (Boolean(isElementAdded) || Boolean(isElementUpdated))) {
        shouldDisableSaveButton = false;
      } else if (!element.name && (Boolean(isElementAdded) || Boolean(isElementUpdated))) {
        shouldDisableSaveButton = true;
        break;
      }
    }
    setIsSaveDisabled(shouldDisableSaveButton);
  }, [updatedContacts, contactList]);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }, [contactList, contactsDisplayed]);

  useEffect(() => {
    if (customer?.contacts?.length) {
      const newContactsList = [...customer?.contacts]?.sort(sortRows);
      setContactList(newContactsList);
      const newContactsDisplayed = newContactsList.slice(0, rowsInScreen);
      setContactsDisplayed(newContactsDisplayed);
    } else {
      setContactList([]);
      setContactsDisplayed([]);
    }
  }, [customer?.contacts]);

  const sortRows = (a: any, b: any) => {
    switch (localSortOrder?.column) {
      case 'name':
        if (localSortOrder?.order === 'asc') {
          return a[localSortOrder?.column]?.localeCompare(b[localSortOrder?.column] || '') || 0;
        } else {
          return b[localSortOrder?.column]?.localeCompare(a[localSortOrder?.column] || '') || 0;
        }
      case 'title':
        if (localSortOrder?.order === 'asc') {
          return a[localSortOrder?.column]?.localeCompare(b[localSortOrder?.column] || '') || 0;
        } else {
          return b[localSortOrder?.column]?.localeCompare(a[localSortOrder?.column] || '') || 0;
        }
      case 'email':
        if (localSortOrder?.order === 'asc') {
          return a[localSortOrder?.column]?.localeCompare(b[localSortOrder?.column] || '') || 0;
        } else {
          return b[localSortOrder?.column]?.localeCompare(a[localSortOrder?.column] || '') || 0;
        }
      case 'phone1':
        if (localSortOrder?.order === 'asc') {
          return a[localSortOrder?.column]?.localeCompare(b[localSortOrder?.column] || '') || 0;
        } else {
          return b[localSortOrder?.column]?.localeCompare(a[localSortOrder?.column] || '') || 0;
        }
      case 'phone2':
        if (localSortOrder?.order === 'asc') {
          return a[localSortOrder?.column]?.localeCompare(b[localSortOrder?.column] || '') || 0;
        } else {
          return b[localSortOrder?.column]?.localeCompare(a[localSortOrder?.column] || '') || 0;
        }
      case 'fax':
        if (localSortOrder?.order === 'asc') {
          return a[localSortOrder?.column]?.localeCompare(b[localSortOrder?.column] || '') || 0;
        } else {
          return b[localSortOrder?.column]?.localeCompare(a[localSortOrder?.column] || '') || 0;
        }
      default:
        if (localSortOrder?.order === 'asc') {
          return a.name?.localeCompare(b.name || '') || 0;
        } else {
          return b.name?.localeCompare(a.name || '') || 0;
        }
    }
    return 0;
  };

  const getDisplayedList = () => {
    let newDisplayed: IContactItem[];
    let newSortedContacts: IContactItem[];
    if (enableSorting) {
      newSortedContacts = [...contactList].sort(sortRows);
      newDisplayed = contactsDisplayed.sort(sortRows);
    } else {
      newDisplayed = contactsDisplayed;
      newSortedContacts = contactList;
    }
    setContactsDisplayed(newDisplayed);
    setContactList(newSortedContacts);
    setTotalRows(getNewContactsDisplayed(textFilter, localSortOrder).length);
  };

  useEffect(() => {
    if (contactsDisplayed.length) {
      getDisplayedList();
    }
  }, [contactsDisplayed]);

  return (
    <div className="ms-Grid margin-left-rigth-2 marginTop18" dir='ltr'>
      <DataGridComponent
        idTable={'add-modal-table'}
        title='Customers'
        headCells={contactsHeadCell}
        rowsTable={contactsDisplayed}
        totalDataFound={totalRows}
        enablePagination={enableSorting}
        enableSorting={enableSorting}
        enableRowsPerPage
        enableSearching
        enableMultiSelectRow={userPermissions.isWrite}
        enableCheckBox={userPermissions.isWrite}
        enableDeleteOption={userPermissions.isWrite}
        handleChangeDataGridState={handlePageChange}
        isLoading={isLoading}
        handleUpdate={handleUpdateContacts}
        handleDelete={deleteContacts}
      />
      <div className={classNames('ms-Grid-col', 'ms-sm12', styles.submitContainer)}>
        {isLoading ? (
          <Spinner size={SpinnerSize.large} />
        ) : (
          <>
            <DefaultButton
              className='ms-Grid-col ms-sm2'
              text="Add new"
              disabled={idsAdded.length > 0 || !userPermissions.isWrite}
              onClick={addNewContact}
            />
            {isLoading ? (
              <Spinner size={SpinnerSize.large} />
            ) : (
              <PrimaryButton
                className='ms-Grid-col ms-sm2'
                text="Save"
                disabled={isSaveDisabled || !userPermissions.isWrite}
                onClick={handleSaveChanges}
              />
            )}
          </>
        )}
      </div>
      {userPermissions.isWrite && <Dialog
        hidden={!isEditedDialogVisible}
        onDismiss={() => { toggleEditedConfirmation(); setEquals(true); }}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: 'You did not save changes. Do you want to save them?',
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton onClick={() => { handleSaveChanges(); toggleEditedConfirmation(); }} text="Save" />
          <DefaultButton onClick={() => { toggleEditedConfirmation(); setEquals(true); }} text="Cancel" />
        </DialogFooter>
      </Dialog>}
    </div>
  );
}

export default Contacts;
