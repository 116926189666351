import { FunctionComponent } from 'react';
import BillInspCodes from './BillInspCodes';
import OrganizeFleetTerms from './OrganizeFleet';
import BillingBasis from './BillingBasis';
import HistoryComponent from './History';
import TreadDepth from './TreadDepth/TreadDepth';
import RateAdjustment from './RateAdjustment';
import { Misc } from './Misc/Misc';
import General from './General/General';
import Contract from './Contract/Contract';
import OOSAvgs from './OOSAvgs/OOSAvgs';
import {
  auth_terms_billableInspCodes,
  auth_terms_billingBasis,
  auth_terms_contract,
  auth_terms_general,
  auth_terms_history,
  auth_terms_misc,
  auth_terms_oosAvgs,
  auth_terms_organizeFleet,
  auth_terms_rateAdjustment,
  auth_terms_treadDepth,
} from '../../../../consts/programKeys';

export enum QUOTE_TABS {
  GENERAL = 'general',
  BILLING_BASIS = 'billingBasis',
  ORGANIZE_FLEET = 'organizeFleet',
  BILLABLE_INSP_CODES = 'billableInspCodes',
  RATE_ADJUSTMENT = 'rate_Adjustment',
  MISC = 'misc',
  TREADDEPTH = 'treadgDepth',
  HISTORY = 'history',
  OOS_AVGS = 'oosAvgs',
  CONTRACT = 'contract',
};

export const termsTabs: CustomerTabs = {
  general: {
    id: QUOTE_TABS.GENERAL,
    accessKey: auth_terms_general,
    name: 'General',
    component: General,
    actionType: ['add', 'edit'],
    subTabs: null,
  },
  billingBasis: {
    id: QUOTE_TABS.BILLING_BASIS,
    accessKey: auth_terms_billingBasis,
    name: 'Billing Basis',
    component: BillingBasis,
    actionType: ['edit'],
    subTabs: null,
  },
  organizeFleet: {
    id: QUOTE_TABS.ORGANIZE_FLEET,
    accessKey: auth_terms_organizeFleet,
    name: 'Organize Fleet',
    component: OrganizeFleetTerms,
    actionType: ['edit'],
    subTabs: null,
  },
  billableInspCodes: {
    id: QUOTE_TABS.BILLABLE_INSP_CODES,
    accessKey: auth_terms_billableInspCodes,
    name: 'Billable Insp. Codes',
    component: BillInspCodes,
    actionType: ['edit'],
    subTabs: null,
  },
  rateAdjustment: {
    id: QUOTE_TABS.RATE_ADJUSTMENT,
    accessKey: auth_terms_rateAdjustment,
    name: 'Rate Adjustment',
    component: RateAdjustment,
    actionType: ['edit'],
    subTabs: null,
  },
  misc: {
    accessKey: auth_terms_misc,
    id: QUOTE_TABS.MISC,
    name: 'Misc', component: Misc,
    actionType: ['edit'],
    subTabs: null,
  },
  treadDepth: {
    id: QUOTE_TABS.TREADDEPTH,
    accessKey: auth_terms_treadDepth,
    name: 'Tread Depth',
    component: TreadDepth,
    actionType: ['edit'],
    subTabs: null,
  },
  history: {
    id: QUOTE_TABS.HISTORY,
    accessKey: auth_terms_history,
    name: 'History',
    component: HistoryComponent,
    actionType: ['edit'],
    subTabs: null,
  },
  oosAvgs: {
    id: QUOTE_TABS.OOS_AVGS,
    accessKey: auth_terms_oosAvgs,
    name: 'OOS Avgs',
    component: OOSAvgs,
    actionType: ['edit'],
    subTabs: null,
  },
  contract: {
    id: QUOTE_TABS.CONTRACT,
    accessKey: auth_terms_contract,
    name: 'Contract',
    component: Contract,
    actionType: ['edit'],
    subTabs: null,
  },
};

export const getTabById: any = (tabId: string) => Object.values(termsTabs).filter((tab) => tab.id === tabId)[0];
export interface CustomerTabs {
  general: TabComponent;
  billingBasis: TabComponent;
  organizeFleet: TabComponent;
  billableInspCodes: TabComponent;
  rateAdjustment: TabComponent;
  misc: TabComponent;
  treadDepth: TabComponent;
  history: TabComponent;
  oosAvgs: TabComponent;
  contract: TabComponent;
}

export interface TabComponent {
  id: string,
  name: string,
  accessKey?: string,
  component: FunctionComponent<any> | null
  actionType: string[];
  subTabs: any;
}
