import { DefaultButton, IconButton, MessageBarType, Modal, PrimaryButton, Text } from '@fluentui/react';
import classNames from 'classnames';
import { FunctionComponent, useState } from 'react';
import DataGridComponent from '../DataGridComponent';
import styles from './ConsignedTires.module.scss';
import { stockTiresHeadCells, vehicleTiressHeadCells } from './consts';
import apiService from '../../api';
import { INotification } from '../../models/INotification';
import { useBoolean } from '@fluentui/react-hooks';



interface ConsignedTiresModalComponentProps {
  id: string;
  customerId: string;
  locationId: string;
  title: string;
  isOpen: boolean;
  onSubmit: (value?: any) => void;
  onDismiss: () => void;
  successLabel: string;
  cancelLabel: string;
}

const ConsignedTiresModalComponent: FunctionComponent<ConsignedTiresModalComponentProps> = ({
  id,
  customerId,
  locationId,
  title,
  isOpen,
  onSubmit,
  onDismiss,
  successLabel,
  cancelLabel,
}) => {

  const [vehicleTires, setVehicleTires] = useState([]);
  const [stockTires, setStockTires] = useState([]);
  const [tireSelected, setTireSelected] = useState<any>(null);
  const [totalVehicleTiresFound, setTotalVehicleTiresFound] = useState(0);
  const [totalStockTiresFound, setTotalStockTiresFound] = useState(0);
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });

  //toggles
  const [isLoadingVehicleTable, { toggle: toggleShowLoadingVehicleTable }] = useBoolean(false);
  const [isLoadingStockTable, { toggle: toggleShowLoadingStockTable }] = useBoolean(false);

  const fetchVehicleConsignedTires = async (
    pagination: any = { pageSize: 500, pageNumber: 1 },
    filters?: any,
    sortOrder: any = { column: vehicleTiressHeadCells[0].fieldName, order: 'asc' },
  ) => {

    try {
      const { data }: any = await apiService.viewListing.getVehicleConsignedTireList(
        pagination,
        filters,
        sortOrder,
        customerId,
        locationId,
      );
      setVehicleTires(data.data);
      setTotalVehicleTiresFound(data?.total?.found);
    } catch (e: any) {
      setNotification({ text: 'Fetching tires error', type: MessageBarType.error });
    }
  };

  const fetchStockTires = async (
    pagination: any = { pageSize: 500, pageNumber: 1 },
    filters?: any,
    sortOrder: any = { column: stockTiresHeadCells[0].fieldName, order: 'asc' },
  ) => {

    try {
      const { data }: any = await apiService.viewListing.getStockConsignedTireList(
        pagination,
        filters,
        null,
        customerId,
        locationId,
      );
      setStockTires(data.data);
      setTotalStockTiresFound(data?.total?.found);
    } catch (e: any) {
      setNotification({ text: 'Fetching tires error', type: MessageBarType.error });
    }
  };

  const handleChangeVehicleDataGridState = async (dataGridState: any) => {
    if (!isOpen) return;
    toggleShowLoadingVehicleTable();
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      filterText: searchedText,
    };
    await fetchVehicleConsignedTires(pagination, filters, sortOrder);
    toggleShowLoadingVehicleTable();
  };

  const handleChangeStockDataGridState = async (dataGridState: any) => {
    if (!isOpen) return;
    toggleShowLoadingStockTable();
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      filterText: searchedText,
    };
    await fetchStockTires(pagination, filters, sortOrder);
    toggleShowLoadingStockTable();
  };

  const handleSelectRow = (rowSelected: Array<any>) => {
    setTireSelected(rowSelected[0]);
  };

  const handleSubmit = () => {
    const data = {
      prefix: tireSelected?.prefix || tireSelected?.pfx,
      brand: tireSelected?.brand || tireSelected?.brandNo,
      suffix: tireSelected?.suffix || tireSelected?.sfx,
    };

    onSubmit(data);
  };

  const disableSelecBtn = tireSelected == null;

  return (
    <>
      <Modal
        key={id}
        isOpen={isOpen}
        onDismiss={onDismiss}
        containerClassName='modalContainer'
      >
        <div className={styles.modalBody}>
          <div className='modalHeader'>
            <Text variant='xLarge'>{title}</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel='Close popup modal'
              onClick={onDismiss}
            />
          </div>
          <div className={classNames('ms-grid', styles.marginBottom20)} dir='ltr'>
            <div className={classNames('ms-Grid-row')}>
              <div className={classNames('ms-Grid-col', 'ms-sm6', styles.marginTop20)}>
                <div className='tableContainer'>
                  <DataGridComponent
                    idTable={'vehicle-table'}
                    title=''
                    headCells={vehicleTiressHeadCells}
                    rowsTable={vehicleTires}
                    totalDataFound={totalVehicleTiresFound}
                    isLoading={isLoadingVehicleTable}
                    enableCheckBox={true}
                    enableSearching={true}
                    enablePagination={true}
                    enableRowsPerPage={true}
                    handleChangeDataGridState={handleChangeVehicleDataGridState}
                    handleSelectRow={handleSelectRow}
                  />
                </div>
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm6', styles.marginTop20)}>
                <div className='tableContainer'>
                  <DataGridComponent
                    idTable={'vehicle-table'}
                    title=''
                    headCells={stockTiresHeadCells}
                    rowsTable={stockTires}
                    totalDataFound={totalStockTiresFound}
                    isLoading={isLoadingStockTable}
                    enableCheckBox={true}
                    enableSearching={true}
                    enablePagination={true}
                    enableRowsPerPage={true}
                    handleChangeDataGridState={handleChangeStockDataGridState}
                    handleSelectRow={handleSelectRow}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.modalFooter}>
            <DefaultButton
              id='cancelButton'
              text={cancelLabel}
              onClick={onDismiss}
            />
            <PrimaryButton
              id='selectButton'
              text={successLabel}
              onClick={handleSubmit}
              disabled={disableSelecBtn}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConsignedTiresModalComponent;