import { isFunction } from 'lodash';
import { useState } from 'react';
import { formatter } from '../../shared/utils';

type TransformOptions = 'uppercase' | 'lowerCase' | 'currency';
export interface UseFieldProps {
  id: string,
  name: string,
  label?: string,
  type: string,
  description?: string,
  disabled?: boolean,
  required?: boolean,
  textTransform?: TransformOptions;
}

export type UseFieldDefinition = (inputProps: UseFieldProps, defaultValue?: any, onChangeCallback?: any) => {
  value: any;
  getValue: () => any;
  setValue: React.Dispatch<any>;
  onChange: (e: any) => void;
  getRequiredClassName: () => 'requiredField' | '';
  id: string;
  name: string;
  label?: string | undefined;
  type: string;
  description?: string | undefined;
  disabled?: boolean | undefined;
  required?: boolean | undefined;
};

export interface UseFieldReturnDefinition {
  value: any;
  getValue: () => any;
  setValue: React.Dispatch<any>;
  onChange: (e: any) => void;
  getRequiredClassName: () => '' | 'requiredField';
  id: string;
  name: string;
  label?: string | undefined;
  type: string;
  description?: string | undefined;
  disabled?: boolean | undefined;
  required?: boolean | undefined;
}

export const useField: UseFieldDefinition = (inputProps: UseFieldProps, defaultValue: any = null, onChangeCallback?: any): UseFieldReturnDefinition => {
  const [value, setValue] = useState<any>(defaultValue);

  const transformMap = {
    uppercase: (textToTransform: string) => textToTransform.toUpperCase(),
    lowerCase: (textToTransform: string) => textToTransform.toLowerCase(),
    currency: (textToTransform: string) => formatter.format(+textToTransform),
  };

  const buildTextTransform = (transform: TransformOptions, textToTransform: any) => transformMap[transform](textToTransform);

  const onChange = (e: any) => {
    const { value } = e?.target;
    setValue(inputProps.textTransform ? buildTextTransform(inputProps.textTransform, value) : value);
    if (isFunction(onChangeCallback)) {
      onChangeCallback();
    }
  };

  const getValue = () => value;
  const getRequiredClassName = () => inputProps.required ? 'requiredField' : '';

  return {
    ...inputProps,
    value,
    getValue,
    setValue,
    onChange,
    getRequiredClassName,
  };
};