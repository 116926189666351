import React from 'react';
import classNames from 'classnames';

import { IHomeProps } from './IHomeProps';
import { IHomeState } from './IHomeState';

import ExternalLinks from '../Common/Links/ExternalLinks';
import FileList from '../Common/Files/FileList';
import Accounts from '../Common/Accounts/Accounts';

import homePage_img from '../../assets/img/homePage_img.png';

import styles from './Home.module.scss';

//height: 'calc(100% - 200px)',

const Home = (props: IHomeProps) => {

  return (
    <div className={classNames('ms-Grid', styles.homeContainerClass)} >
      <div className={classNames('ms-Grid-row', styles.homeRowClass)}>
        <img className={styles.homePageImgClass} src={homePage_img} alt="logo" />
      </div>
    </div>
  );
};

export default Home;