import { createSlice } from '@reduxjs/toolkit';

import { INotification } from '../components/NotificationsManager/INotification';

const initialState: Array<INotification> = [];

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    pushToast: (state, action) => {
      state.push(action.payload);
    },
    closeToast: (state, action) => {
      let tmp: Array<INotification> = state;
      tmp = tmp.filter(({ id }) => id !== action.payload);
      return tmp;
    },
    setToasts: (state, action) => {
      return action.payload;
    },
  },
});

export const {
  pushToast,
  closeToast,
  setToasts,
} = notificationsSlice.actions;

export const notificationsSelector = (state: any) => state.notifications;

export default notificationsSlice.reducer;
