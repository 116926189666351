import { fieldType } from '../../../../../../../../consts/fieldType';

export const columns = [
  {
    key: 'column1',
    name: 'Fixed',
    fieldName: 'fixed',
    isSorted: true,
    isSortedDescending: false,
    isEditable: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column2',
    name: 'Prefix',
    fieldName: 'prefix',
    isSorted: false,
    isSortedDescending: false,
    isEditable: true,
    type: fieldType.TEXT,
  },
  {
    key: 'column3',
    name: 'Brand Number',
    fieldName: 'brandNumber',
    isSorted: false,
    isSortedDescending: false,
    isEditable: true,
    type: fieldType.TEXT,
  },
  {
    key: 'column4',
    name: 'Suffix',
    fieldName: 'suffix',
    isSorted: false,
    isSortedDescending: false,
    isEditable: true,
    type: fieldType.TEXT,
  },
  {
    key: 'column5',
    name: 'Class',
    fieldName: 'class',
    isSorted: false,
    isSortedDescending: false,
    isEditable: true,
    type: fieldType.TEXT,
  },
  {
    key: 'column6',
    name: 'Type Code',
    fieldName: 'typeCode',
    isSorted: false,
    isSortedDescending: false,
    isEditable: true,
    type: fieldType.TEXT,
  },
  {
    key: 'column7',
    name: 'Tire Size',
    fieldName: 'tireSize',
    isSorted: false,
    isSortedDescending: false,
    isEditable: true,
    type: fieldType.TEXT,
  },

  {
    key: 'column8',
    name: 'PriVeh',
    fieldName: 'priVeh',
    isSorted: false,
    isSortedDescending: false,
    isEditable: true,
    type: fieldType.TEXT,
  },
  {
    key: 'column9',
    name: 'PriPos',
    fieldName: 'priPos',
    isSorted: false,
    isSortedDescending: false,
    isEditable: true,
    type: fieldType.TEXT,
  },
  {
    key: 'column10',
    name: 'PriOff',
    fieldName: 'priOff',
    isSorted: false,
    isSortedDescending: false,
    isEditable: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column11',
    name: 'ChgLoc',
    fieldName: 'chgLoc',
    isSorted: false,
    isSortedDescending: false,
    isEditable: true,
    type: fieldType.TEXT,
  },
  {
    key: 'column12',
    name: 'InvLoc',
    fieldName: 'invLoc',
    isSorted: false,
    isSortedDescending: false,
    isEditable: true,
    type: fieldType.TEXT,
  },
  {
    key: 'column13',
    name: 'TD',
    fieldName: 'td',
    isSorted: false,
    isSortedDescending: false,
    isEditable: true,
    type: fieldType.TEXT,
  },
  {
    key: 'column14',
    name: 'Miles',
    fieldName: 'miles',
    isSorted: false,
    isSortedDescending: false,
    isEditable: true,
    type: fieldType.TEXT,
  },
  {
    key: 'column15',
    name: 'CurrOn',
    fieldName: 'currOn',
    isSorted: false,
    isSortedDescending: false,
    isEditable: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column16',
    name: 'Comments',
    fieldName: 'comments',
    isSorted: false,
    isSortedDescending: false,
    isEditable: false,
    type: fieldType.TEXT,
  },
]; 