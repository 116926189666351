import { FC, FormEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import {
  DatePicker,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IColumn,
  IconButton,
  IDropdownOption,
  MessageBarType,
  PrimaryButton,
  Text,
  TextField,
  Checkbox,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useDispatch, useSelector } from 'react-redux';

import apiService from '../../../../../../api';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../../Pagination/Pagination';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import { sortOrder } from '../../../../../../consts/sortOrder';
import { customerSelector, locationSelector, setTireForReinstate, tireForReinstateSelector } from '../../../../../../redux/recordKeepingSlice';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';

import { IReinstateTabState } from './IReinstateTabState';
import { IReinstateTabProps } from './IReinstateTabProps';
import { columns, emptyTireToBeAdded } from './consts';
import { pageSizes } from '../../../../../../consts/recordKeeping';
import styles from './ReinstateTab.module.scss';
import useNotifications from '../../../../../../hooks/useNotifications';
import ViewTireDetailsModal from '../../../../ViewTireDetailsModal/ViewTireDetailsModal';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_actionOnTires_reinstate } from '../../../../../../consts/programKeys';
import { ITireToBeAdded } from './ITireToBeAdded';
import SelectingModal from '../../../../../SelectingModal/SelectingModal';
import { tireDetailsColumns } from '../../../../ViewTireDetails/consts';
import { transformDate } from '../../../../../../shared/transformDate';
import { get } from 'lodash';
import useKeyPress from '../../../../../../hooks/useKeyPress/useKeyPress';

const ReinstateTab: FC<IReinstateTabProps> = (): ReactElement => {

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };
  
  const dispatch = useDispatch();
  const { addNotification } = useNotifications();
  const { id: customerId, customerName } = useSelector(customerSelector);
  const { id: locationId, locationCode } = useSelector(locationSelector);

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_actionOnTires_reinstate);

  const [state, setState] = useState<IReinstateTabState>({
    items: [],
    selectedItems: [],
    loading: false,
    foundCount: 0,
  });

  const [runningProcess, setRunningProcess] = useState<string>('');

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const [filters, setFilters] = useState<any>({
    searchString: null,
    dateFrom: null,
    dateTo: null,
  });
  const [detailsVisible, { toggle: toggleDetailsVisible }] = useBoolean(false);
  const [reinstateAnyway, { toggle: toggleReinstateAnyway }] = useBoolean(false);
  const [showDeletingConfirmation, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [showAddingAnywayConfirmation, { toggle: toggleShowAddingAnywayConfirmation }] = useBoolean(false);
  const [showCheckingError, { toggle: toggleShowCheckingError }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [parsedErrors, setParsedErrors] = useState<any>([]);
  const [tireToBeAdded, setTireToBeAdded] = useState<ITireToBeAdded>(emptyTireToBeAdded);
  const [consignedTires, setConsignedTires] = useState<Array<any>>([]);
  const [showAModal, { toggle: toggleShowAModal }] = useBoolean(false);


  const setPfxTireToBeAdded = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, pfx: string | undefined) => {
    setTireToBeAdded((prev: any) => ({ ...prev, pfx: pfx?.toUpperCase() }));
  };
  const setBnoTireToBeAdded = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, bno: string | undefined) => {
    setTireToBeAdded((prev: any) => ({ ...prev, bno }));
  };
  const setSfxTireToBeAdded = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, sfx: string | undefined) => {
    setTireToBeAdded((prev: any) => ({ ...prev, sfx: sfx?.toUpperCase() }));
  };

  const setItemToBeEdited = (id: string, field: string, value: any) => {
    setState((prev: any) => ({
      ...prev,
      items: prev.items.map((item: any) => item.id === id ? { ...item, [field]: value } : item),
    }));
  };

  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
      };
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.reinstate.printExcel(requestData) :
        await apiService.reinstate.printPdf(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const openViewTireDetailsWindow = (item: any) => {
    const { consignedTireId, pfx, bno, sfx } = item;
    dispatch(setTireForReinstate({ tireId: consignedTireId, pfx, bno, sfx }));
    toggleDetailsVisible();
  };

  const onChangeUserAgree = useCallback(
    (ev?: FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
      toggleReinstateAnyway();
    },
    [],
  );

  const fetchAllTiresByPfxBnoSfx = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.viewTireDetails.getAllTireDetailsBrand({ prefix: tireToBeAdded.pfx, brand: tireToBeAdded.bno, suffix: tireToBeAdded.sfx }, customerId);
      setConsignedTires(data);
      data.length && toggleShowAModal();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Tire Details fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const setTireDetails = async (consignedTireId: any) => {
    toggleShowAModal();
    setState(prev => ({ ...prev, loading: true }));
    await handleAddTire(consignedTireId);
    setState(prev => ({ ...prev, loading: false }));
  };

  const handleSubmit = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const sortOrder = getSortOrder();
      const { dateTo, dateFrom } = filters;

      const { data: {message}}: any  = await apiService.reinstate.submit(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        { ...filters, dateTo: dateTo ? transformDate(moment(dateTo).format('MM/DD/YYYY')) : null, dateFrom: dateFrom ? transformDate(moment(dateFrom).format('MM/DD/YYYY')) : null },
        sortOrder,
        customerId,
        reinstateAnyway,
      );
  

      addNotification({
        text: message,
        type: MessageBarType.success,
      });

      await paginationProps.onChangePage(1);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Reinstate submitting error: ${response.data.message}`,
        type: MessageBarType.error,
      });      

      // switch (response.status) {
      //   case 498:
      //     return addNotification({
      //       text: `Reinstate submitting error: ${get(response.data, 'state', []).map((el: any) => el.message).join(', ')}`,
      //       type: MessageBarType.error,
      //     });
      //   default:
      //     addNotification({
      //       text: `Reinstate submitting error: ${response.data.message}`,
      //       type: MessageBarType.error,
      //     });
      // }
    } finally {
      await fetchReinstate();
    }
  };

  const fetchReinstate = async () => {
    let timeOut = null;

    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { dateTo, dateFrom } = filters;
      const { data }: any = await apiService.reinstate.get(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        { ...filters, dateTo: dateTo ? transformDate(moment(dateTo).format('MM/DD/YYYY')) : null, dateFrom: dateFrom ? transformDate(moment(dateFrom).format('MM/DD/YYYY')) : null },
        sortOrder,
        customerId,
      );
      const foundCount = data.total.found;
      const items = data.data.map(
        ({ accountable, billed, submit, oosDate, ...others }: any) => ({
          accountable: accountable ? 'Y' : 'N',
          billed: billed ? 'Y' : 'N',
          submit: submit ? 'Y' : 'N',
          oosDate: oosDate ? moment(oosDate).format('MM/DD/YYYY') : '',
          ...others,
        }),
      );
      setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));

      const { data: { runStatus } }: any = await apiService.reinstate.status(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        { ...filters, dateTo: dateTo ? transformDate(moment(dateTo).format('MM/DD/YYYY')) : null, dateFrom: dateFrom ? transformDate(moment(dateFrom).format('MM/DD/YYYY')) : null },
        sortOrder,
        customerId,
      );
      
      if (timeOut) clearInterval(timeOut);
      if (runStatus == 'RUNNING')timeOut= setTimeout(() => fetchReinstate(), 10000);

      setRunningProcess(runStatus);  

    } catch (e: any) {
      const { response } = e;
      if(response)
      {
        if (response?.data?.state?.length) {
          response.data.state.forEach(({ message }: any) => {
            addNotification({
              text: message,
              type: MessageBarType.error,
            });
          });
        } else {
          addNotification({
            text: `Reinstate fetching error: ${response.data.message}`,
            type: MessageBarType.error,
          });
        }
      } else {
        addNotification({
          text: 'No response available.',
          type: MessageBarType.error,
        });
      }
  
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleAddTire: any = async (consignedTireId: string) => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.reinstate.save([{ pfx: tireToBeAdded.pfx, bno: tireToBeAdded.bno, sfx: tireToBeAdded.sfx, consignedTireId }], customerId, locationId, reinstateAnyway );
      addNotification({
        text: 'Tire was successfully added.',
        type: MessageBarType.success,
      });
      await fetchReinstate();
      setTireToBeAdded(emptyTireToBeAdded);
      setTireToBeAdded((prev: any) => ({ ...prev, pfx: tireToBeAdded.pfx }));
    } catch (e: any) {
      setState(prev => ({ ...prev, loading: false }));
      const { response } = e;
      switch (response.status) {
        case 499:
          await fetchAllTiresByPfxBnoSfx();
          return addNotification({
            text: `Reinstate saving error: ${response.data.state[0].message}`,
            type: MessageBarType.error,
          });
          // if (reinstateAnyway) {
          //   return toggleShowAddingAnywayConfirmation();
          // }
          // return toggleShowCheckingError();
        case 498:
          return addNotification({
            text: 'Tire not eligible for reinstatement- check status',
            type: MessageBarType.error,
          });
        case 400:
          setParsedErrors(response.data.state);
          return addNotification({
            text: 'Unable to save changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Reinstate saving error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    }
  };

  useKeyPress({
    handleAdd: handleAddTire,
  });

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.reinstate.delete(state.selectedItems);
      addNotification({
        text: 'Selected tire(s) were successfully deleted.',
        type: MessageBarType.success,
      });
      await paginationProps.onChangePage(1);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Reinstate deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      await fetchReinstate();
    }
  };

  const handleSave = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.reinstate.save([...state.items].map(item => ({ id: item.id, pfx: item.pfx, bno: item.bno, sfx: item.sfx })), customerId, locationId, reinstateAnyway);
      addNotification({
        text: 'Tire was successfully added.',
        type: MessageBarType.success,
      });
      await fetchReinstate();
      setParsedErrors([]);
    } catch (e: any) {
      setState(prev => ({ ...prev, loading: false }));
      const { response } = e;
      switch (response.status) {
        case 499:
          if (reinstateAnyway) {
            return toggleShowAddingAnywayConfirmation();
          }
          return toggleShowCheckingError();
        case 498:
          return addNotification({
            text: 'Tire not eligible for reinstatement- check status',
            type: MessageBarType.error,
          });
        case 400:
          setParsedErrors(response.data.state);
          return addNotification({
            text: 'Unable to save changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Reinstate saving error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    }
  };

  const handleSearch = () => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    fetchReinstate();
  };

  const handleSelect = (e: any, itemId: any) => {
    const selectedRows = [...state.selectedItems];
    if (e.target.checked) {
      selectedRows.push(itemId);
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows.filter(row => row !== itemId) }));
    }
  };

  const handleSelectAll = (e: any, items: Array<any>) => {
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedItems: items })) : setState((prev: any) => ({ ...prev, selectedItems: [] }));
  };

  const parseErrors = (id: string, field: string) => {
    const customError = parsedErrors?.filter((error: { id: string; field: string; }) => error.id == id && error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  const highlightRowIfError = (item: any) => {
    if (parsedErrors.length > 0) {
      return parsedErrors.filter((error: { id: any; }) => error.id == item.id).length > 0;
    }
  };

  useEffect(() => {
    fetchReinstate();
  }, [
    paginationProps.current,
    countOnPage,
    columnsState,
    customerId,
    locationId,
  ]);

  return (
    <>
      <div className="ms-Grid">
        <div className={classNames('ms-Grid-row', styles.headingBlock)}>
          <div className="ms-Grid-col ms-sm2">         
            <div>
              <Text block className={styles.highlight}>Incident Date</Text>
              <Text variant="xLarge" block className={styles.highlight}>{moment().format('MM/DD/YYYY')}</Text>
            </div>
          </div>
          <SeparatorGy vertical />    
          <div className="ms-Grid-col ms-sm7">
            <div className={styles.tiresAddingBlock}>
              <div className="ms-TextField">
                <label>Prefix</label>
                <TextField
                  value={tireToBeAdded.pfx}
                  onChange={setPfxTireToBeAdded}
                />
              </div>
              <div className="ms-TextField">
                <label>Brand Number</label>
                <TextField
                  value={tireToBeAdded.bno}
                  onChange={setBnoTireToBeAdded}
                />
              </div>
              <div className="ms-TextField">
                <label>Suffix</label>
                <TextField
                  value={tireToBeAdded.sfx}
                  onChange={setSfxTireToBeAdded}
                />
              </div>
              <PrimaryButton
                id='addButton'
                disabled={!tireToBeAdded.pfx || !tireToBeAdded.bno || runningProcess != 'IDLE'}
                onClick={() => handleAddTire('')}
                text="Add"
              />
            </div>
          </div>
          <SeparatorGy vertical />
          <div className="ms-Grid-col ms-sm3">
            <Checkbox
              label="Reinstate Tires even when Credit Amount > Original Debit Amount"
              checked={reinstateAnyway}
              onChange={onChangeUserAgree}
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <div className={styles.tableHeading}>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Text variant="xLarge" className={styles.highlight}>Reinstate Tires</Text>
                  <SeparatorGy vertical />
                  <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
                </div>
                <div className={styles.statusContainer}>
                  <Text variant='medium'>Status: { runningProcess }</Text>
                </div>                  
              </div>

            </div>                 
            <div className={styles.tableHeading}>
              <div className={styles.filtersBlock}>
                <TextField
                  id='searchString'
                  placeholder="Enter search string"
                  onChange={(event, searchString) => {
                    setFilters((prev: any) => ({ ...prev, searchString }));
                  }}
                  value={filters.searchString}
                />
                <DatePicker
                  id="oosDateFrom"
                  showMonthPickerAsOverlay={true}
                  placeholder="Date from"
                  ariaLabel="Date from"
                  value={filters.dateFrom ? filters.dateFrom : null}
                  onSelectDate={(dateFrom: Date | null | undefined) => setFilters((prev: any) => ({ ...prev, dateFrom }))}
                  formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                />
                <Text variant="xLarge" className={styles.highlight}>&nbsp;-&nbsp;</Text>
                <DatePicker
                  id="oosDateTo"
                  showMonthPickerAsOverlay={true}
                  placeholder="Date to"
                  ariaLabel="Date to"
                  value={filters.dateTo ? filters.dateTo : null}
                  onSelectDate={(dateTo: Date | null | undefined) => setFilters((prev: any) => ({ ...prev, dateTo }))}
                  formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                />
                <PrimaryButton id="searchButton" className={styles.gyBackground} onClick={handleSearch} text="Search" />
              </div>
              <div>
                <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                <Dropdown
                  options={pageSizes.map(pageSize => ({
                    key: pageSize,
                    text: pageSize.toString(),
                  }))}
                  defaultSelectedKey={pageSizes[0]}
                  selectedKey={countOnPage?.key}
                  onChange={onChangeCountOnPage}
                />
                <SeparatorGy vertical />
                <Text variant="large" className={styles.highlight}>{state.selectedItems.length} items selected</Text>
                <SeparatorGy vertical />
                <IconButton
                  id="toggleDeletingConfirmationButton"
                  disabled={!state.selectedItems.length || !userPermissions.isWrite}
                  iconProps={{ iconName: 'Delete' }}
                  onClick={toggleDeletingConfirmation}
                />
              </div>
            </div>
            <div className={styles['table-wrapper']}>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    {
                      columnsState.map(item => (
                        <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                      ))
                    }
                    <th>
                      <div className={styles.round}>
                        <input type="checkbox" id="all" checked={state.items.length !== 0 &&
                          (state.selectedItems.length === +countOnPage.key || state.selectedItems.length === state.items.length)} onChange={(e) => handleSelectAll(e, state.items.map(item => item.id))} />
                        <label htmlFor="all"></label>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    state.items.map(item => (
                      <tr key={item.id}
                        className={classNames(state.selectedItems.includes(item.id) ? styles.trSelected : styles.trBasic,
                          highlightRowIfError(item) ? styles.trError : styles.trBasic)}>
                        <td>
                          <IconButton
                            id="viewButton"
                            iconProps={{ iconName: 'View' }}
                            onClick={() => openViewTireDetailsWindow(item)}
                          />
                        </td>
                        {
                          columnsState.map((column) => (
                            column.isEditable ?
                              <td>
                                <TextField
                                  id={column.fieldName}
                                  styles={{ fieldGroup: { border: '1px solid transparent' } }}
                                  value={item[column.fieldName]}
                                  onChange={(e, i) => setItemToBeEdited(item.id, column.fieldName, i)}
                                  errorMessage={parseErrors(item.id, column.fieldName)}
                                />
                              </td>
                              :
                              <td>
                                {item[column.fieldName]}
                              </td>
                          ))
                        }
                        <td>
                          <div className={styles.round}>
                            <input type="checkbox" id={item.id} checked={state.selectedItems.includes(item.id)} onChange={(e) => handleSelect(e, item.id)} />
                            <label htmlFor={item.id}></label>
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <SeparatorGy />
            <Pagination {...paginationProps} />
          </div>
        </div>
        <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
          <DefaultButton id='printExportBtn' onClick={toggleShowPrintExport} text="Print/Export" />
          <PrimaryButton id='saveButton' onClick={handleSave} text="Save" disabled={!userPermissions.isWrite} />
          <PrimaryButton id="submitButton" onClick={handleSubmit} text="Submit" disabled={!userPermissions.isWrite || runningProcess != 'IDLE'} />
        </div>
      </div>
      {detailsVisible && <ViewTireDetailsModal isOpened={detailsVisible} onDismiss={toggleDetailsVisible} />}

      <SelectingModal
        isOpen={showAModal}
        title="Available Consigned Tires"
        selectingList={consignedTires.map(tire => ({ id: tire.id, prefix: tire.prefix, brandNo: tire.brandNo, suffix: tire.suffix, status: tire.status,
          chgDate: tire.chgDate, totalMiles: tire.totalMiles, tireSize: tire.tireSize, typeCode: tire.typeCode, prodCode: tire.prodCode, tc: tire.tc,
          locationCode: tire.locationCode, oosAv: tire.oosAv, dotNo: tire.dotNo }))}
        onDismiss={toggleShowAModal}
        onSubmit={setTireDetails}
        columns={tireDetailsColumns}
      />
      <Dialog
        hidden={!showDeletingConfirmation}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to delete (${state.selectedItems.length}) items?`,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={!showAddingAnywayConfirmation}
        onDismiss={toggleShowAddingAnywayConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: 'There are tires in the queue where the credit amount exceeds the original submit these tires to be reinstated?',
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <DefaultButton onClick={toggleShowAddingAnywayConfirmation} text="Cancel" />
          <PrimaryButton onClick={handleSave} text="Submit" />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={!showCheckingError}
        onDismiss={toggleShowCheckingError}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Warning',
        }}
        modalProps={{ isBlocking: true }}
      >
        <Text block className={styles.highlight}>
          Some tires were unable to be reinstated, because the credit amount exceeds the original debit amount.
        </Text>
        <br />
        <Text block className={styles.highlight}>
          If you would like to reinstate these tires please make sure the "Reinstate Tires even when Credit Amount {'>'} Original Debit Amount" box is Checked.
        </Text>
        <DialogFooter>
          <PrimaryButton onClick={toggleShowCheckingError} text="OK" />
        </DialogFooter>
      </Dialog>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default ReinstateTab;
