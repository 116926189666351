

import { Checkbox, ChoiceGroup, DatePicker, DefaultButton, Dialog, DialogFooter, DialogType, IconButton, MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { debounce, get } from 'lodash';
import moment from 'moment';
import { FC, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import apiService from '../../../../api';
import useNotifications from '../../../../hooks/useNotifications';
import DataGridComponent from '../../../../shared/DataGridComponent';
import AutoCompleteField from '../../../Common/Search/AutoCompleteField';
import ErrorsModal from '../../../ErrorsModal/ErrorsModal';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import { downloadFile, printingTypes } from '../../../PrintingModal/consts';
import PrintingModal from '../../../PrintingModal/PrintingModal';
import SelectingModal from '../../../SelectingModal/SelectingModal';
import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import styles from './CloseoutBilling.module.scss';
import { defaultSelectedPostType, locationsColumns, pageSizes, postTypeOptions, tiresAvailableHeadCells } from './consts';
import { ICloseoutBillingProps } from './ICloseoutBillingProps';
import { ICloseoutBillingState, IItem } from './ICloseoutBillingState';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { auth_billing_closeoutBilling } from '../../../../consts/programKeys';
import ContractsModalComponent from '../../../../shared/ContractsModalComponent';
import { HeadCell } from '../../../../shared/DataGridComponent/DataGridModels';

const CloseoutBilling: FC<ICloseoutBillingProps> = (): ReactElement => {

  const { addNotification } = useNotifications();
  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_billing_closeoutBilling);

  const [tiresHeadCell, setTiresHeadCell] = useState<Array<HeadCell>>(tiresAvailableHeadCells);
  const [isLoadingFetchCustomers, { toggle: toggleIsLoadingFetchCustomers }] = useBoolean(false);
  const [customerFilters, setCustomerFilters] = useState<any>(null);
  const [customers, setCustomers] = useState<Array<any>>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<any>({});
  const [customerDetails, setCustomerDetails] = useState<any>();

  const [viewLocationSelection, setLocationSelection] = useState(false);
  const [isLoadingFetchLocations, { toggle: toggleIsLoadingFetchLocations }] = useBoolean(false);
  const [locations, setLocations] = useState<Array<any>>([]);
  const [selectedLocation, setSelectedLocation] = useState<any>({});
  const [showLocationsModal, { toggle: toggleShowLocationsModal }] = useBoolean(false);

  const [openContractsModal, { toggle: toggleOpenContractsModal }] = useBoolean(false);

  const [closeoutDate, setCloseoutDate] = useState<any>(moment().format('MM/DD/YYYY'));
  const [showRetrieve, setShowRetrieve] = useState(false);
  const [postType, setPostType] = useState(defaultSelectedPostType);
  const [isReadyToBillDisabled, setIsReadyToBillDisabled] = useState(true);

  const [selectedItems, setSelectedItems] = useState<IItem[]>([]);
  const [itemsPagination, setItemsPagination] = useState({ pageSize: pageSizes[0], pageNumber: 1 });
  const [itemsSortOrder, setItemsSortOrder] = useState({ column: tiresAvailableHeadCells[0].fieldName, order: 'asc' });

  const [shouldRefreshTable, setShouldRefreshTable] = useState<boolean>(true);
  const [runningProcess, setRunningProcess] = useState<string>('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [isSubmitDisabledValid, setIsSubmitDisabledValid] = useState<boolean>(true);

  const [state, setState] = useState<ICloseoutBillingState>({
    loading: false,
    contractDetails: null,
    items: [],
    foundItems: 0,
    totalAmount: '',
    itemsLoading: false,
    tiresToUpd: [],
  });

  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [showErrorsModal, { toggle: toggleShowErrorsModal }] = useBoolean(false);

  const wrapperRef: any = useRef(null);
  const [closedTarget, setClosedTarget] = useState<any>(null);
  const [isClosedDialogVisible, { toggle: toggleClosedConfirmation }] = useBoolean(false);

  const [isSubmitted, setSubmitted] = useState<boolean>(false);

  const onCustomerTextChange = debounce(async (field: string, value: string) => {
    if (value && get(customerFilters, `${field}`) !== value) {
      fetchCustomers({ [field]: value });
    }
    setCustomerFilters(value ? { [field]: value } : null);
  }, 1000);

  const onCustomerChange = async (newCustomerId: any) => {
    setSelectedCustomer(getListItemById(newCustomerId, customers));
    setSelectedLocation({});
    resetItemsData();
  };

  const resetItemsData = () => {
    if (state.contractDetails) {
      setState(prev => ({ ...prev, items: [], foundItems: 0, totalAmount: '' }));
    }
    setShowRetrieve(false);
    setShouldRefreshTable(true);
    setSubmitted(false);
  };

  const onLocationChange = (newLocationId: any) => {
    setSelectedLocation(getListItemById(newLocationId, locations));
    resetItemsData();
  };

  const getListItemById = (idToFind: number, list: any[]) => {
    const itemToFind = list?.find(({ id }) => id === idToFind);
    return itemToFind ? itemToFind : null;
  };

  const removeEmpty = ({ text }: any) => text !== null;

  const customerPrefixList = useMemo(() => customers ?
    customers.map(({ id, prefix }) => ({
      key: id,
      text: prefix,
    })).filter(removeEmpty) : [], [customers]);


  const customerNameList = useMemo(() => customers ?
    customers.map(({ id, customerName }) => ({
      key: id,
      text: customerName,
    })).filter(removeEmpty) : [], [customers]);

  const locationCodeList = useMemo(() => locations ?
    locations.map(({ id, locationCode }) => ({
      key: id,
      text: locationCode,
    })).filter(removeEmpty) : [], [locations]);

  const locationNameList = useMemo(() => locations ?
    locations.map(({ id, locationName }) => ({
      key: id,
      text: locationName,
    })).filter(removeEmpty) : [], [locations]);


  const onLocationModalSubmit = (newLocationId: any) => {
    onLocationChange(newLocationId);
    toggleShowLocationsModal();
  };

  const onContractModalSubmit = (contract: any) => {
    console.log('oncontractmodalsubmit');
    setIsSubmitDisabledValid(true);
    toggleOpenContractsModal();
    setState((prev: any) => ({
      ...prev,
      contractDetails: {
        contractId: contract.contractId,
        status: contract.status,
        activeStatus: contract.activeStatus,
        contractPeriod: `${moment(contract.effectiveStart).format('MM/DD/YYYY')} - ${moment(contract.effectiveEnd).format('MM/DD/YYYY')}`,
        brandPrefix: contract?.brandPrefix,
      },
    }));
    validateOrigTread(contract.origTread == 'Y');
    if (state.contractDetails?.contractId !== contract.contractId)
      resetItemsData();
  };

  const handleChangeItemsDataGridState = async (dataGridState: any) => {
    if (showRetrieve) {
      const { countOnPage, paginationProps, sortOrder } = dataGridState;
      const pagination = {
        pageSize: countOnPage.key,
        pageNumber: paginationProps.current,
      };
      fetchItems(pagination, sortOrder);
    }
  };

  const handleSelectLineItem = (rowsSelected: any[]) => {
    setSelectedItems(rowsSelected);
  };

  const fetchCustomers = async (filters: any, sortOrder: any = { column: 'custPrefix', order: 'asc' }) => {
    toggleIsLoadingFetchCustomers();
    try {
      const { data: { data } }: any = await apiService.getCustomerList(
        filters,
        undefined,
        sortOrder,
      );
      setCustomers(data);
      setIsSubmitDisabled(true);
      setIsSubmitDisabledValid(true);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Customers fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      toggleIsLoadingFetchCustomers();
    }
  };

  const fetchCustomerData = async (customerId: string) => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data }: any = await apiService.getCustomerInformation(customerId);
      setCustomerDetails(data.data);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Customer data fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchLocations = async () => {
    toggleIsLoadingFetchLocations();
    try {
      const { data }: any = await apiService.getLocationsByCustomerId(
        selectedCustomer.id,
        { pageSize: 5000, pageNumber: 1 },
        {},
      );
      setLocations(data.data);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Locations fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      toggleIsLoadingFetchLocations();
    }
  };

  const fetchContractDetails = async (customerId: string) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.billingAPI.getCloseoutBillingContractDetails(customerId);
      setState(prev => ({ ...prev, contractDetails: data }));
      validateOrigTread(data.origTread == 'Y');
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Contract Details fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const validateOrigTread = (origTread: boolean) => {
    const newHeadCells = tiresHeadCell.map(tire => { return { ...tire, isEditable: tire.fieldName == 'td' && origTread }; });
    setTiresHeadCell(newHeadCells);
  };

  const handleSetDate = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data: { message } }: any = await apiService.billingAPI.setCloseoutBillingDate(state.contractDetails?.contractId, closeoutDate);
      setShowRetrieve(true);
      addNotification({
        text: message,
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Set Date operation error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchItems = async (
    pagination: any = itemsPagination,
    sortOrder: any = itemsSortOrder,
    file?: any,
  ) => {
    setState(prev => ({ ...prev, itemsLoading: true }));
    try {
      const { data: { data, total, totalAmount } }: any = await apiService.billingAPI.getCloseoutBillingTires(
        selectedCustomer.id,
        selectedLocation?.id,
        state.contractDetails?.contractId,
        pagination,
        sortOrder,
        shouldRefreshTable,
        file,
      );
      if (shouldRefreshTable) {
        setShouldRefreshTable(false);
      }
      setState(prev => ({ ...prev, items: data, foundItems: total.found, totalAmount }));
      setItemsPagination(pagination);
      setItemsSortOrder(sortOrder);
      setIsSubmitDisabled(false);
      setIsSubmitDisabledValid(false);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Tires Available for Closeout fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, itemsLoading: false }));
    }
  };

  const handleUploadFromFile = async (file: any) => fetchItems({ pageSize: 5, pageNumber: 1 }, { column: tiresAvailableHeadCells[0].fieldName, order: 'asc' }, file);

  const handleSubmit = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const postTirexferIds = getIdsFromTires(selectedItems);
      const { data: { message } }: any = await apiService.billingAPI.submitCloseoutBilling(postTirexferIds, selectedCustomer?.id, closeoutDate);
      await fetchItems();
      addNotification({
        text: message,
        type: MessageBarType.success,
      });

      setSubmitted(true);
      setIsSubmitDisabled(false);
      setIsSubmitDisabledValid(false);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Submit operation error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleUpdateTires = async () => {
    setState(prev => ({ ...prev, itemsLoading: true }));
    try {
      const { data: { message } }: any = await apiService.billingAPI.updateCloseoutBilling(
        state.tiresToUpd,
        selectedCustomer.id,
      );
      addNotification({
        text: message,
        type: MessageBarType.success,
      });
      await fetchItems();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Update operation error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, itemsLoading: false, tiresToUpd: [] }));
    }
  };

  const handleSetTiresToUpdate = (rowsUpdated: Array<any>) => {
    if (!rowsUpdated.length) return;
    const tiresToUpd = getITiresToUpd(rowsUpdated);
    setState({ ...state, tiresToUpd });
  };

  const handleReadyToBill = async () => {
    setState(prev => ({ ...prev, loading: true }));
    setIsReadyToBillDisabled(true);
    setSubmitted(false);
    try {
      const { data: { message } }: any = await apiService.billingAPI.readyToBillCloseoutBilling({
        customerId: selectedCustomer?.id,
        locationId: selectedLocation?.id,
        contractId: state.contractDetails?.contractId,
        closeoutDate: moment(closeoutDate).format('MM/DD/YYYY'),
      });
      fetchItems();
      addNotification({
        text: message,
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Ready to Bill operation error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const requestData = {
        data: {
          pagination: itemsPagination,
          sortOrder: itemsSortOrder,
          postType: 'CLS',
          brandPrefix: selectedCustomer?.prefix,
        },
        headerFields: [
          { title: 'Customer Name', value: selectedCustomer?.customerName },
          ...(selectedLocation?.locationCode ? [{ title: 'Location Code', value: selectedLocation.locationCode }] : []),
        ],
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.billingAPI.printExcelCloseoutBilling(requestData) :
        await apiService.billingAPI.printPdfCloseoutBilling(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Printing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };
  
  const fetchCloseOutItems = async (
    pagination: any = itemsPagination,
    sortOrder: any = itemsSortOrder,
    file?: any,
  ) => {
    setState(prev => ({ ...prev, foundItems: 0, totalAmount: '', itemsLoading: true }));
    try {
      const { data: { data, total, totalAmount } }: any = await apiService.billingAPI.getCloseoutBillingTiresProcess(
        selectedCustomer.id,
        selectedLocation?.id,
        state.contractDetails?.contractId,
        pagination,
        sortOrder,
        shouldRefreshTable,
        file,
      );
      if (shouldRefreshTable) {
        setShouldRefreshTable(false);
      }
      setState(prev => ({ ...prev, itemsLoading: true }));
      setItemsPagination(pagination);
      setItemsSortOrder(sortOrder);
      addNotification({
        text: 'Getting tires in progress',
        type: MessageBarType.success,
      });

      await fetchRetrieveStatus();
      
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Tires Available for Closeout fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, itemsLoading: false }));
    }
  };
  
  const fetchRetrieveStatus = async () => {
    let timeOut = null;

    setState(prev => ({ ...prev, itemsLoading: true }));
    try {
     
      const { data: { runStatus } }: any =  await apiService.billingAPI.getStatus(
        selectedCustomer.id,
        state.contractDetails?.contractId,
      );

      if (timeOut) clearInterval(timeOut);
      if (runStatus == 'RUNNING')timeOut= setTimeout(() => fetchRetrieveStatus(), 10000);
      if (runStatus == 'RUNNING')
      {
        setShowRetrieve(false);
      }
      else
      {
        setShowRetrieve(true);
      }

      setRunningProcess(runStatus);

    } catch (e: any) {
      const { response } = e;
      if(response)
      {
        if (response?.data?.state?.length) {
          response.data.state.forEach(({ message }: any) => {
            addNotification({
              text: message,
              type: MessageBarType.error,
            });
          });
        } else {
          addNotification({
            text: `Fetching error: ${response.data.message}`,
            type: MessageBarType.error,
          });
        }
      } else {
        addNotification({
          text: 'No response available.',
          type: MessageBarType.error,
        });
      }
  
    }
  };
 
  const fetchItemsv2 = async (
    pagination: any = itemsPagination,
    sortOrder: any = itemsSortOrder,
  ) => {
    setState(prev => ({ ...prev, itemsLoading: true }));
    try {
      const { data: { data, total, totalAmount } }: any = await apiService.billingAPI.getCloseoutBillingTiresv2(
        selectedCustomer.id,
        state.contractDetails?.contractId,
        selectedLocation?.id,
        pagination,
        sortOrder,
      );
      if (shouldRefreshTable) {
        setShouldRefreshTable(false);
      }
      setState(prev => ({ ...prev, items: data, foundItems: total.found, totalAmount }));
      setItemsPagination(pagination);
      setItemsSortOrder(sortOrder);
      setIsSubmitDisabled(false);
      setIsSubmitDisabledValid(false);

    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Tires Available for Closeout fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, itemsLoading: false }));
    }
  };
  
  const handleUploadFromFilev2 = async (file: any) => fetchCloseOutItems({ pageSize: 5, pageNumber: 1 }, { column: tiresAvailableHeadCells[0].fieldName, order: 'asc' }, file);

  useEffect(() => {
    if (!customerFilters) {
      fetchCustomers({});
    }
  }, [customerFilters]);

  useEffect(() => {
    if (selectedCustomer.id) {
      fetchLocations();
      fetchCustomerData(selectedCustomer.id);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (selectedLocation.id) {
      fetchContractDetails(selectedCustomer.id);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (customerDetails && state.contractDetails?.status === 'RUNOUT PENDING') {
      setCloseoutDate(moment(customerDetails?.lastMileageRun).format('MM/DD/YYYY'));
    }
  }, [customerDetails, state.contractDetails]);

  useEffect(() => {
    if (customerDetails?.billBy === 'Location')
      setLocationSelection(true);
    else if (customerDetails)
      fetchContractDetails(selectedCustomer.id);
  }, [customerDetails]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)
        && (event.target.parentElement?.classList.contains('ms-Pivot-linkContent'))
        || event.target.tagName === 'A') {
        toggleClosedConfirmation();
        setClosedTarget(event.target);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [wrapperRef]);

  useEffect(() => {
    if (!isClosedDialogVisible && closedTarget)
      closedTarget.click();
  }, [isClosedDialogVisible]);

  const getIdsFromTires = (tiresArray: Array<IItem>) => tiresArray?.map((tire: any) => tire.postTirexferId);
  const getITiresToUpd = (tiresArray: Array<IItem>) => tiresArray?.map(({ postTirexferId, td }: any) => { return { postTirexferId, td }; });


  useEffect(() => {
    if(userPermissions.isWrite && isSubmitted && state.contractDetails?.status == 'ACTIVE') {
      setIsReadyToBillDisabled(false);
    }
  }, [userPermissions.isWrite, isSubmitted, state.contractDetails?.status]);

  const isSubmiteDisabled = !((userPermissions.isWrite && isSubmitted) && state.contractDetails?.status == 'ACTIVE');
  const statusField = (state.contractDetails?.status && state.contractDetails?.activeStatus) ? `${state.contractDetails?.status}/${state.contractDetails?.activeStatus}` : '';

  
  useEffect(() => {
    if(runningProcess.length > 0 && runningProcess == 'RUNNING')
    {
      setState(prev => ({ ...prev, itemsLoading: true }));
    }
    if(runningProcess.length > 0 && runningProcess == 'COMPLETED')
    {
      fetchItemsv2();
      setRunningProcess('');
      addNotification({
        text: 'Getting tires for closeout is complete.',
        type: MessageBarType.success,
      });
    }
    if(runningProcess.length > 0 && runningProcess == 'FAILED')
    {
      addNotification({
        text: 'Getting tires for closeout failed',
        type: MessageBarType.error,
      });
      setState(prev => ({ ...prev, itemsLoading: false }));
    }
  }, [runningProcess]);

  useEffect(() => {
    if(!userPermissions.isWrite) {
      setIsSubmitDisabled(true);
      setIsSubmitDisabledValid(true);
    }
  }, [userPermissions.isWrite]);
  return (
    <div ref={wrapperRef}>
      <Text variant='xxLarge' className={styles.highlight}>Closeout Billing</Text>
      <div className={styles.detailsRow}>
        <div className={styles.rowWrapper}>
          <Text variant="xLarge" className={styles.highlight}>Customer Details</Text>
          <SeparatorGy />
          <div className={styles.fields}>
            <div className={styles.field}>
              <AutoCompleteField
                label="*Prefix"
                data-testid='customer-prefix'
                icon="FavoriteStar"
                value={selectedCustomer?.id}
                list={customerPrefixList}
                textValue={(value: any) => onCustomerTextChange('custPrefix', value)}
                onChangeAction={onCustomerChange}
                isLoading={isLoadingFetchCustomers}
              />
              <IconButton
                id='searchPrefix'
                className={styles.searchButton}
                iconProps={{ iconName: 'Search' }}
                onClick={() => onCustomerChange(customerPrefixList[0].key)}
              />
            </div>
            <div className={styles.field}>
              <AutoCompleteField
                className={styles.largeField}
                label="*Customer Name"
                data-testid='customer-name'
                icon="Contact"
                value={selectedCustomer?.id}
                list={customerNameList}
                isLoading={isLoadingFetchCustomers}
                disabled
              />
            </div>
            <div className={styles.field}>
              <SeparatorGy vertical />
            </div>
            <div className={styles.field}>
              <TextField
                label="Region"
                value={selectedCustomer?.regionCode}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.rowWrapper}>
          <Checkbox
            label='Set Location for Closeout'
            checked={viewLocationSelection}
            disabled
          />
          <SeparatorGy />
          {viewLocationSelection && <div className={styles.fields}>
            <div className={styles.field}>
              <AutoCompleteField
                label="Loc Code"
                data-testid='customer-loc-code'
                icon="POISolid"
                value={selectedLocation?.id}
                list={locationCodeList}
                onChangeAction={onLocationChange}
                isLoading={isLoadingFetchLocations}
              />
              <IconButton
                id='searchLocCode'
                className={styles.searchButton}
                iconProps={{ iconName: 'Search' }}
                onClick={() => onLocationChange(locationCodeList[0].key)}
              />
            </div>
            <div className={styles.field}>
              <AutoCompleteField
                className={styles.largeField}
                label="Location"
                data-testid='customer-loc'
                icon="POISolid"
                value={selectedLocation?.id}
                list={locationNameList}
                isLoading={isLoadingFetchLocations}
                disabled
              />
            </div>
            <div className={styles.field}>
              <TextField
                label="Ship to #"
                value={selectedLocation?.shipToNumber}
                disabled
              />
            </div>
            <div className={styles.field}>
              <PrimaryButton
                id='availableLocationsBtn'
                onClick={toggleShowLocationsModal}
                text="Available Locations"
              />
            </div>
          </div>}
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.rowWrapper}>
          <Text variant="xLarge" className={styles.highlight}>Contract Details</Text>
          <SeparatorGy />
          <div className={styles.fields}>
            <div className={styles.field}>
              <TextField
                label="Contract ID"
                value={state.contractDetails?.contractId}
                disabled
              />
            </div>
            <div className={styles.field}>
              <TextField
                label="Status"
                value={statusField}
                disabled
              />
            </div>
            <div className={styles.field}>
              <TextField
                className={styles.mediumField}
                label="Contract Period"
                value={state.contractDetails?.contractPeriod}
                disabled
              />
            </div>
            <div className={styles.field}>
              <PrimaryButton
                id="chooseContractBtn"
                onClick={toggleOpenContractsModal}
                text="Choose Contract"
                disabled={viewLocationSelection ? !selectedLocation.id : !selectedCustomer.id}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.rowWrapper}>
          <SeparatorGy />
          <div className={styles.fields}>
            <div className={classNames(styles.field, styles.dateField)}>
              <DatePicker
                label="Closeout Date"
                value={moment(closeoutDate).toDate()}
                onSelectDate={setCloseoutDate}
                formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                isRequired
              />
            </div>
            <div className={styles.field}>
              <PrimaryButton
                id='setDateBtn'
                onClick={handleSetDate}
                text="Set Date"
                disabled={!state.contractDetails?.contractId}
              />
            </div>
            <div className={styles.field}>
              <ChoiceGroup defaultSelectedKey={postType}
                options={postTypeOptions}
                onChange={(_ev: any, option: any) => setPostType(option?.key)}
                label="Post Type"
              />
            </div>
            <div className={styles.field}>
              {postType === 'manual' ?
                <PrimaryButton
                  id='retrieveBtn'
                  onClick={() => fetchCloseOutItems()}
                  text="Retrieve"
                  disabled={!showRetrieve}
                /> :
                <label className={classNames(styles.customButton, !showRetrieve && styles.disabled)}>
                  <TextField
                    className={styles.upload}
                    type="file"
                    disabled={!showRetrieve}
                    onChange={(ev: any) => handleUploadFromFilev2(ev.target.files[0])}
                  />
                  <span>
                    <span>Retrieve</span>
                  </span>
                </label>}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.rowWrapper}>
          <div className={styles.topInfo}>
            <Text variant="xLarge" className={styles.highlight}>Total Amount: ${state.totalAmount}</Text>
          </div>
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.rowWrapper}>
          <DataGridComponent
            idTable={'available-line-items-table'}
            title='Tires Available for Closeout'
            headCells={tiresHeadCell}
            rowsTable={state.items}
            totalDataFound={state.foundItems}
            isLoading={state.itemsLoading}
            defaultRowsPerPage={pageSizes}
            enableMultiSelectRow
            enablePagination
            enableCheckBox
            enableRowsPerPage
            handleChangeDataGridState={handleChangeItemsDataGridState}
            handleSelectRow={handleSelectLineItem}
            handleUpdate={handleSetTiresToUpdate}
          />
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsColumn}>
          <SeparatorGy />
          <div className={styles.fieldsActions}>
            <DefaultButton
              id="printButton"
              text="Print/Export"
              disabled={!isSubmitted}
              onClick={toggleShowPrintExport}
            />
            <DefaultButton
              id="errorsButton"
              text="View Errors"
              disabled={!selectedCustomer.id}
              onClick={toggleShowErrorsModal}
            />
            <PrimaryButton
              id="readyToBillButton"
              text="Ready to Bill"
              disabled={isReadyToBillDisabled}
              onClick={handleReadyToBill}
            />
            <PrimaryButton
              id="updButton"
              text="Update"
              disabled={!state.tiresToUpd.length}
              onClick={handleUpdateTires}
            />
            <PrimaryButton
              id="submitButton"
              text="Submit"
              disabled={(isSubmitDisabled && isSubmitDisabledValid)}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
      <SelectingModal
        isOpen={showLocationsModal}
        title="Customer Name | Available Locations"
        selectingList={locations}
        onDismiss={toggleShowLocationsModal}
        onSubmit={onLocationModalSubmit}
        columns={locationsColumns}
        preselectedKey={selectedLocation?.id}
      />
      <ContractsModalComponent
        id='contractsModal'
        regionCode={selectedLocation?.regionCode}
        customerId={selectedCustomer?.id}
        title='Available Contracts'
        isOpen={openContractsModal}
        onSubmit={onContractModalSubmit}
        onDismiss={toggleOpenContractsModal}
        successLabel='Select Contract'
        cancelLabel='Cancel'
      />
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      <ErrorsModal
        isModalOpen={showErrorsModal}
        hideModal={toggleShowErrorsModal}
        fetchErrorsService={apiService.billingAPI.getCloseoutBillingErrors}
        customer={selectedCustomer}
        location={selectedLocation}
      />
      {userPermissions.isWrite && <Dialog
        hidden={!isClosedDialogVisible}
        onDismiss={() => { toggleClosedConfirmation(); setClosedTarget(null); }}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: 'Do you want to close Closeout Billing module?',
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="closeButton" onClick={toggleClosedConfirmation} text="Close" />
          <DefaultButton onClick={() => { toggleClosedConfirmation(); setClosedTarget(null); }} text="Cancel" />
        </DialogFooter>
      </Dialog>}
    </div>
  );
};

export default CloseoutBilling;
