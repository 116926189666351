export const columns = [
  {
    key: 'column1',
    name: 'Prefix',
    fieldName: 'pfx',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Brand',
    fieldName: 'bno',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'Suffix',
    fieldName: 'sfx',
    isSorted: false,
    isSortedDescending: false,
  },
];