import { HeadCell } from '../../../../../../shared/DataGridComponent/DataGridModels';

export const transferHeadlCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Brand Prefix',
    fieldName: 'intireBrndPfx',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Brand Number',
    fieldName: 'intireBrndNum',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Brand Suffix',
    fieldName: 'intireBrndSfx',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Type Code',
    fieldName: 'typeCodeFK',
    typeField: 'text',
  },
  {
    key: 'column4',
    name: 'Tire Class',
    typeField: 'text',
    fieldName: 'tc',
  },
  {
    key: 'column5',
    name: 'DOT #',
    fieldName: 'dotNum',
    typeField: 'text',
  },
];

export const availableRowsTable: any[] = [];

export interface formData {
  orderNum?: any,
  dateShipped?: any,
  invoiceNum?: any,
  dlNum?: any,
  transFromBrandNum?: any,
  transFromLocationCode?: any,
  transFromName?: any,
  transToBrandNum?: any,
  newLocation?: any,
  newCustomer?: any,
  tireSelectionData?: any,
  availableInTransitTires?: any,
  transferedInTransitTires?: any,
}

export interface orderData {
  orderId?: string,
  orderItemId?: string,
  gdyrDelNo?: string,
  invcNo?: string,
  dataShipped?: string,
}

export interface tireSelectionData {
  rebrand: boolean | false,
  prefix: string | '',
  suffix: string | '',
  rbPrefix: string | '',
  rbSuffix: string | '',
  beginBrandNum: string | '',
  endBrandNum: string | '',
}

export function defaultTireSelectionData(){
  return {
    rebrand: false,
    prefix: '',
    suffix: '',
    rbPrefix: '',
    rbSuffix: '',
    beginBrandNum: '',
    endBrandNum: '',
  };
}

export function defaultAvailableTires() {
  return {
    pagination: {
      pageSize: 5,
      pageNumber: 1,
    },
    filters: {
      orderId: '',
      inTransitTiresSelectRange: {
        doRebrand: false,
        beginBrand: {
          prefix: null,
          brandNo: null,
        },
        endBrand: {
          prefix: null,
          brandNo: null,
        },
      },
    },
    sortOrder: {
      sortOrder: 'orderId',
      order: 'asc',
    },
  };
}

export const initialFormData: formData = {
  orderNum: null,
  dateShipped: null,
  invoiceNum: null,
  dlNum: null,
  transFromBrandNum: null,
  transFromLocationCode: null,
  transFromName: null,
  transToBrandNum: null,
  newLocation: undefined,
  newCustomer: undefined,
  tireSelectionData: null,
  availableInTransitTires: null,
  transferedInTransitTires: null,
};

export const transferedRowsTable: any[] = [];
