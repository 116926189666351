import { Checkbox, DatePicker, Dropdown, Label, PrimaryButton, Text, TextField, IDropdownOption, Icon } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import moment from 'moment';
import { FunctionComponent, useEffect, useState, FormEvent } from 'react';
import DataGridComponent from '../../../../../shared/DataGridComponent';
import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import { frequencyMapping } from '../consts';
import styles from '../SchedulingDetailsModal.module.scss';
import { IRecipientItem } from '../ISchedulingDetailsModalState';
import { isNil } from 'lodash';

import {
  addOrRemoveArrayItem,
  datesExcludedHeadCell,
  dateTimeArrayOptions,
  defaultRowsPerPage,
  defaultRowsPerPageAttac,
  attachmentHeadCell,
  hoursArrayOptions,
  minutesArrayOptions,
} from './consts';

interface FrequencyComponentProps {
  taskData: any;
  dropdownOptions: any;
  handleUpdateConfiguration: (config: any) => void;
  handleChangeOverrideData?: any;
  handleSendTestNotification?: any;
  handleAddRecipients: (data: any) => void;
  handleUpdateRecipient: (data: any) => void;
  handleDeleteRecipients: (data: any) => void;
  overrideData?: any;
  recipientsData?: any;  
};

const templateoptions: IDropdownOption[] = [
  { key: 'GLS STANDARD EMAIL', text: 'GLS STANDARD EMAIL' },
];

const notifywhenoptions: IDropdownOption[] = [
  { key: 'Success Only', text: 'Success Only' },
  { key: 'Failure Only', text: 'Failure Only' },
  { key: 'Success and Failure', text: 'Success and Failure' },
  { key: 'Disabled', text: 'Disabled' },
];

const FrequencyComponent: FunctionComponent<FrequencyComponentProps> = ({ taskData, dropdownOptions, handleUpdateConfiguration, overrideData, handleChangeOverrideData, recipientsData, handleSendTestNotification, handleAddRecipients, handleUpdateRecipient, handleDeleteRecipients }) => {
  const [taskDataState, setTaskDataState] = useState(taskData);
  const [excludedDate, setExcludedDate] = useState<Date>();
  const [isLoadingTable, { toggle: toggleIsLoadingTable }] = useBoolean(false);
  const [isLoadingRecipientTable, { toggle: toggleIsLoadingRecipientTable }] = useBoolean(false);
  const [periodicity, setPeriodicity] = useState<'day' | 'week' | 'lastDay' | null>();
  const [recipientDataState, setrecipientDataState] = useState(recipientsData);
  const [recipientItemState, setrecipientItemState] = useState<IRecipientItem>();
  const [reloadControlsTable, { toggle: toggleReloadControlsTable }] = useBoolean(false);
  const [disableUpdBtn, setdisableUpdBtn ] = useState<boolean>(true);
  const [disableAddBtn, setdisableAddBtn ] = useState<boolean>(true);


  const buildStartDate = (isOverrideForm?: boolean, overrideProcessStart?: boolean) =>

    taskDataState?.frequency &&
    taskDataState?.frequency != frequencyMapping.once && (
      <>
        <div>
          {isOverrideForm && <Label required> Process Date</Label>}
          <DatePicker
            disabled={isOverrideForm ? !overrideProcessStart : false}
            label={!isOverrideForm ? 'Start Date' : ''}
            formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
            value={isOverrideForm ? overrideData?.processStart?.processDate : taskDataState?.startDate}
            onSelectDate={isOverrideForm ? handleChangeProcessDate : handleChangeStartDate}
          />
        </div>
      </>
    );

  const buildStartTime = (isOverrideForm?: boolean, overrideProcessStart?: boolean) => taskDataState?.frequency != null && (
    <div className={styles.startTimeContainer}>
      <Label required>{`${isOverrideForm ? 'Process' : 'Start'} Time`}</Label>
      <div className={styles.row}>
        <Dropdown
          disabled={isOverrideForm ? !overrideProcessStart : false}
          options={hoursArrayOptions}
          selectedKey={(isOverrideForm ? overrideData?.processStart?.processTime : taskDataState?.startTime)?.substring(0, 2)}
          onChange={(e, option) => onChangeHours(e, option, isOverrideForm)}
          className={styles.timeDropdown}
        />
        <Dropdown
          disabled={isOverrideForm ? !overrideProcessStart : false}
          options={minutesArrayOptions}
          selectedKey={(isOverrideForm ? overrideData?.processStart?.processTime : taskDataState?.startTime)?.substring(3, 5)}
          onChange={(e, option) => onChangeMinutes(e, option, isOverrideForm)}
          className={styles.timeDropdown}
        />
        <Dropdown
          disabled={isOverrideForm ? !overrideProcessStart : false}
          options={dateTimeArrayOptions}
          selectedKey={(isOverrideForm ? overrideData?.processStart?.processTime : taskDataState?.startTime)?.substring(6)}
          onChange={(e, option) => onChangeDateTime(e, option, isOverrideForm)}
          className={styles.timeDropdown}
        />
      </div>
    </div>
  );

  const buildRunOn = () => taskDataState?.frequency == frequencyMapping.once && (
    <div className={styles.runOnContainer}>
      <DatePicker
        label="Run On"
        formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
        value={taskDataState?.onceParameters?.runOn}
        onSelectDate={handleChangeOnceDate}
        isRequired
      />
    </div>
  );

  const buildMonths = () => taskDataState?.frequency == frequencyMapping.monthly && (
    <div className={classNames(styles.monthsContainer)}>
      <div className={classNames(styles.row, styles.monthModification)}>
        <Text>Select Months</Text>
        <Checkbox
          label="All Months"
          checked={(taskDataState.monthlyParameters?.months || []).length === 12}
          onChange={(ev: any, value) => handleChangeAllMonth(value)}
        />
      </div>
      <SeparatorGy />
      <div className={classNames(styles.monthsCheckContainer)}>
        {dropdownOptions?.months?.map((i: any) => (
          <Checkbox
            key={i}
            label={i}
            onChange={() => handleChangeMonth(i)}
            checked={(taskDataState?.monthlyParameters?.months || []).includes(i)}
          />
        ))}
      </div>
    </div>
  );

  const buildWeekly = () => taskDataState?.frequency == frequencyMapping?.weekly && (
    <div className={classNames(styles.monthsContainer)}>
      <Text>Select Days</Text>
      <SeparatorGy />
      <div className={classNames('ms-Grid-col', 'ms-sm9', styles.daysOfWeekWrapper)}>
        {dropdownOptions?.days?.map((i: any) => (
          <Checkbox
            key={i}
            label={i}
            checked={(taskDataState.weeklyParameters?.days || []).includes(i)}
            onChange={() => handleChangeDayOfWeek(i)}
          />
        ))}
      </div>
    </div>
  );

  const buildExceptions = () =>
    taskDataState?.scheduleId == '' && (
      <div className={styles.exceptionContainer}>
        <Text variant='large' className={styles.boldText}>Edit Exclution</Text>
        <div className={styles.addExclutionContainer}>
          <DatePicker
            label="Exclude"
            value={excludedDate}
            formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
            onSelectDate={handleChangeExcludeDate}
          />
          <PrimaryButton
            className={styles.addExclutionBtn}
            text="Add new exclution"
            onClick={handleAddExcludeDate}
            disabled={!excludedDate}
          />
        </div>
        <DataGridComponent
          idTable={'task-exclude-table'}
          title='Exclutions'
          enableAutoControls
          enablePagination
          enableRowsPerPage
          enableDeleteOption
          enableCheckBox
          enableMultiSelectRow
          defaultRowsPerPage={defaultRowsPerPage}
          headCells={datesExcludedHeadCell}
          rowsTable={taskDataState?.excludeDates}
          totalDataFound={taskDataState?.excludeDates?.length || 0}
          isLoading={isLoadingTable}
          handleDelete={handleDeleteException}
        />
      </div>
    );

  const buildNotifications = () =>
    taskDataState?.frequency && taskDataState?.frequency != frequencyMapping.once && (
      <div className={styles.notificationContainer}>
        <Text variant='large' className={styles.boldText}>Notification</Text>
        <div className={styles.notificationTemplateContainer}>
          <div style={{ marginTop: '16px' }} className={styles.dayOfMonth}>
            <Checkbox
              id="sendNotificationOption"
              label="SEND NOTIFICATION"
              checked={taskDataState?.sendNotification}
              onChange={handleUpdateSendNotification}
            />
          </div>
          <div>
            <Dropdown
              placeholder='Select a Template'
              options={templateoptions}
              selectedKey={taskDataState?.successEmailTemplate}
              onChange={onChangeSuccessTemplate}
              label="Success Email Template"
              required
            />
          </div>
        	<div>
            <Dropdown
              placeholder='Select a Template'
              options={templateoptions}
              selectedKey={taskDataState?.failedEmailTemplate}
              onChange={onChangeFailureTemplate}
              label="Failure Email Template"
              required
            />
          </div>
          <div style={{ marginTop: '16px' }} >
            <PrimaryButton
              onClick={handleSendTestNotification}
              disabled={!taskDataState?.sendNotification}
              text="Send Test Email to Me."
            />            
          </div>
        </div>
        <SeparatorGy />
        <div className={styles.notificationTemplateContainer}>
          <div>
            <TextField id="txtname" label="Name" value={recipientItemState?.recipientName}  onRenderPrefix={() => <Icon iconName={'Contact'} aria-hidden="true" />} onChange={onChangeRecipientName} required />
          </div>
          <div>
            <TextField id="txtemail" label="Email" value={recipientItemState?.recipientEmail} onRenderPrefix={() => <Icon iconName={'Mail'} aria-hidden="true" />} onChange={onChangeRecipientEmail} required />               
          </div>
          <div>
            <Dropdown
              placeholder='Select a Action'
              options={notifywhenoptions}
              selectedKey={recipientItemState?.notifyWhen}
              onChange={onChangeNotifyWhen}
              label="Notify When?"
              required
            />            
          </div>
          <div style={{ marginTop: '20px' }} >
            <PrimaryButton text='Add' disabled={ disableAddBtn } onClick={() => handleAddRecipients({ mCdEmailRecipientid: '', mCdSchedidFk: taskDataState?.scheduleId, recipientName: recipientItemState?.recipientName, recipientEmail: recipientItemState?.recipientEmail, notifyWhen: recipientItemState?.notifyWhen })} />
          </div>
          <div style={{ marginTop: '20px' }} >
            <PrimaryButton text='Update' disabled={ disableUpdBtn } onClick={() => handleUpdateRecipient({ mCdEmailRecipientid: recipientItemState?.mCdEmailRecipientid, mCdSchedidFk: recipientItemState?.mCdSchedidFk, recipientName: recipientItemState?.recipientName, recipientEmail: recipientItemState?.recipientEmail, notifyWhen: recipientItemState?.notifyWhen })} />
          </div>          
        </div>
        <SeparatorGy />
        <DataGridComponent
          idTable={'notification-recipient-table'}
          title='Recipients'
          enableAutoControls
          enablePagination
          enableRowsPerPage
          enableDeleteOption
          enableCheckBox
          enableMultiSelectRow
          defaultRowsPerPage={defaultRowsPerPageAttac}
          headCells={attachmentHeadCell}
          rowsTable={recipientDataState}
          totalDataFound={recipientDataState?.length || 0}
          isLoading={isLoadingRecipientTable}
          handleDelete={handleDeleteRecipients}
          reloadControlsTable={reloadControlsTable}
          handleChangeDataGridState={handleChangeDataGridState}
          handleSelectRow={handleSelectToUpdateRow}
        />        
      </div>
    );

  const buildEspecificDays = () =>
    taskDataState?.frequency == frequencyMapping.monthly && (
      <div className={styles.monthlyPeriodicity}>
        <Text>Select Periodicity</Text>
        <SeparatorGy />
        <div className={styles.especificDaysWraper}>
          <div className={styles.dayOfMonth}>
            <Checkbox
              id="day"
              label="Day of the month"
              checked={periodicity == 'day'}
              onChange={handleUpdateIsByWeek}
            />
            <TextField
              className={styles.dayInput}
              label="Day of the month(s) (1-31)"
              value={taskDataState?.monthlyParameters?.numberDayOfMonth}
              onChange={onChangeMonthlyDay}
              disabled={!(periodicity == 'day')}
              required
            />
          </div>
          <div className={styles.dayOfWeek}>
            <Checkbox
              id="week"
              label="Day of the week"
              checked={periodicity == 'week'}
              onChange={handleUpdateIsByWeek}
            />
            <div className={styles.weekDayWrapper}>
              <Dropdown
                options={dropdownOptions?.weeks_of_month?.map((i: any) => ({ key: i, text: i }))}
                selectedKey={taskDataState?.monthlyParameters?.weekOfTheMonth}
                onChange={onChangeWeekOfTheMonth}
                label="Week of the Month"
                disabled={!(periodicity == 'week')}
                required
              />
              <Dropdown
                options={dropdownOptions?.days?.map((i: any) => ({ key: i, text: i }))}
                selectedKey={taskDataState?.monthlyParameters?.numberDayOfWeek}
                onChange={onChangeNumberDayOfWeek}
                label="Day of the Week"
                disabled={!(periodicity == 'week')}
                required
              />
            </div>
          </div>
          <div className={styles.dayOfMonth}>
            <Checkbox
              id="lastDay"
              label="Last day of the Month"
              checked={periodicity == 'lastDay'}
              onChange={handleUpdateIsByWeek}
            />
          </div>

        </div>
      </div>
    );

  const onChangeFrequency = (_ev: any, option: any) => {
    const frequency = option?.key;
    setTaskDataState({
      ...taskDataState,
      frequency,
    });
  };

  const onChangeHours = (_ev: any, option: any, isOverrideForm?: boolean) => {
    if (isOverrideForm) {
      const processTime = `${option.key}:${overrideData?.processStart?.processTime?.substring(3, 5)} ${overrideData?.processStart?.processTime?.substring(6)}`;
      handleChangeOverrideData({
        ...overrideData,
        processStart: {
          ...overrideData?.processStart,
          processTime,
        },
      });
    } else {
      const startTime = `${option.key}:${taskDataState?.startTime?.substring(3, 5)} ${taskDataState?.startTime?.substring(6)}`;
      setTaskDataState({
        ...taskDataState,
        startTime,
      });
    }
  };

  const onChangeMinutes = (_ev: any, option: any, isOverrideForm?: boolean) => {
    if (isOverrideForm) {
      const processTime = `${overrideData?.processStart?.processTime?.substring(0, 2)}:${option.key} ${overrideData?.processStart?.processTime?.substring(6)}`;
      handleChangeOverrideData({
        ...overrideData,
        processStart: {
          ...overrideData?.processStart,
          processTime,
        },
      });
    } else {
      const startTime = `${taskDataState?.startTime?.substring(0, 2)}:${option.key} ${taskDataState?.startTime?.substring(6)}`;
      setTaskDataState({
        ...taskDataState,
        startTime,
      });
    }
  };

  const onChangeDateTime = (_ev: any, option: any, isOverrideForm?: boolean) => {
    if (isOverrideForm) {
      const processTime = `${overrideData?.processStart?.processTime?.substring(0, 2)}:${overrideData?.processStart?.processTime?.substring(3, 5)} ${option.key}`;
      handleChangeOverrideData({
        ...overrideData,
        processStart: {
          ...overrideData?.processStart,
          processTime,
        },
      });
    } else {
      const startTime = `${taskDataState?.startTime?.substring(0, 2)}:${taskDataState?.startTime?.substring(3, 5)} ${option.key}`;
      setTaskDataState({
        ...taskDataState,
        startTime,
      });
    }
  };

  const onChangeMonthlyDay = (ev: any, numberDayOfMonth: string | undefined) => {
    setTaskDataState({
      ...taskDataState,
      monthlyParameters: {
        ...taskDataState?.monthlyParameters,
        numberDayOfMonth,
      },
    });
  };

  const onChangeWeekOfTheMonth = (ev: any, option: any) =>
    setTaskDataState({
      ...taskDataState,
      monthlyParameters: {
        ...taskDataState?.monthlyParameters,
        weekOfTheMonth: option.key,
      },
    });

  const onChangeNumberDayOfWeek = (ev: any, option: any) =>
    setTaskDataState({
      ...taskDataState,
      monthlyParameters: {
        ...taskDataState?.monthlyParameters,
        numberDayOfWeek: option.key,
      },
    });

  const handleChangeOnceDate = (runOn: any) =>
    setTaskDataState({ ...taskDataState, onceParameters: { runOn } });

  const handleChangeExcludeDate = (excludeDate: any) =>
    setExcludedDate(excludeDate);

  const handleAddExcludeDate = () => {
    toggleIsLoadingTable();
    const currentExcludeDates = taskDataState?.excludeDates || [];
    currentExcludeDates.push({ date: excludedDate });
    setTaskDataState({ ...taskDataState, excludeDates: currentExcludeDates });
    toggleIsLoadingTable();
  };

  const handleDeleteException = (rowsToDelete: any) => {
    toggleIsLoadingTable();
    let currentExcludeDates = taskDataState?.excludeDates || [];
    currentExcludeDates = currentExcludeDates?.filter((excludeDate: any) => !rowsToDelete?.includes(excludeDate));
    setTaskDataState({ ...taskDataState, excludeDates: currentExcludeDates });
    toggleIsLoadingTable();
  };

  const handleChangeStartDate = (startDate: any) =>
    setTaskDataState({ ...taskDataState, startDate });

  const handleChangeProcessDate = (processDate: any) => {
    handleChangeOverrideData({
      ...overrideData, processStart: {
        ...overrideData?.processStart,
        processDate,
      },
    });
  };

  const handleChangeDayOfWeek = (day: string) => {
    const newDaysList = addOrRemoveArrayItem(taskDataState?.weeklyParameters?.days, day);
    setTaskDataState(
      {
        ...taskDataState,
        weeklyParameters: {
          ...taskDataState.weeklyParameters,
          days: newDaysList,
        },
      },
    );
  };

  const handleChangeMonth = (month: any) => {
    const newMonthList = addOrRemoveArrayItem(taskDataState?.monthlyParameters?.months, month);
    setTaskDataState(
      {
        ...taskDataState,
        monthlyParameters: {
          ...taskDataState.monthlyParameters,
          months: newMonthList,
        },
      },
    );
  };

  const handleChangeAllMonth = (isChecked: any) =>
    setTaskDataState(
      {
        ...taskDataState,
        monthlyParameters: {
          ...taskDataState?.monthlyParameters,
          months: isChecked ? dropdownOptions?.months : [],
        },
      },
    );

  const handleUpdateIsByWeek = ({ target: { id } }: any) => {
    setTaskDataState(
      {
        ...taskDataState,
        monthlyParameters: {
          ...taskDataState?.monthlyParameters,
          isByWeek: id === 'week',
          isTheLastDayOfTheMonth: id === 'lastDay',
        },
      },
    );
    setPeriodicity(id);
  };

  const handleUpdateSendNotification = (sendnotif: any) => {
    setTaskDataState(
      {
        ...taskDataState,
        sendNotification: !taskDataState?.sendNotification,
      },
    );
  };

  const onChangeSuccessTemplate = (_ev: any, option: any) => {
    setTaskDataState({
      ...taskDataState,
      successEmailTemplate: option?.key,
    });
  };

  const onChangeFailureTemplate = (_ev: any, option: any) => {
    setTaskDataState({
      ...taskDataState,
      failedEmailTemplate: option?.key,
    });
  };

  const onChangeNotifyWhen = (_ev: any, option: any) => {
    changeActionButtonState(recipientItemState?.recipientName, recipientItemState?.recipientEmail, option?.key, recipientItemState?.mCdSchedidFk);
    setrecipientItemState((prev: any) => ({ ...prev, notifyWhen: option?.key }));
  };

  const onChangeRecipientName = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, recipientName: string | undefined) => {
    changeActionButtonState(recipientName, recipientItemState?.recipientEmail, recipientItemState?.notifyWhen, recipientItemState?.mCdSchedidFk);
    setrecipientItemState((prev: any) => ({ ...prev, recipientName }));
  };    

  const onChangeRecipientEmail = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, recipientEmail: string | undefined) => {
    changeActionButtonState(recipientItemState?.recipientName, recipientEmail, recipientItemState?.notifyWhen, recipientItemState?.mCdSchedidFk);
    setrecipientItemState((prev: any) => ({ ...prev, recipientEmail }));
  };    
 

  const handleChangeDataGridState = async (dataGridState: any) => {
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: +countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString: searchedText,
    };
  };

  const handleSelectToUpdateRow = (rowsSelected: any) => {    
    if (rowsSelected.length === 1) {
      changeActionButtonState(rowsSelected[0].recipientName, rowsSelected[0].recipientEmail, rowsSelected[0].notifyWhen, rowsSelected[0].mCdEmailRecipientid);
      setrecipientItemState((prev: any) => ({ ...prev, 
        mCdEmailRecipientid: rowsSelected[0].mCdEmailRecipientid,  
        mCdSchedidFk: rowsSelected[0].mCdSchedidFk,  
        recipientName: rowsSelected[0].recipientName,  
        recipientEmail: rowsSelected[0].recipientEmail,  
        notifyWhen: rowsSelected[0].notifyWhen,  
      }));
    } else {
      changeActionButtonState('', '', '', '');
      setrecipientItemState((prev: any) => ({ ...prev, 
        mCdEmailRecipientid: '',  
        mCdSchedidFk: '',  
        recipientName: '',  
        recipientEmail: '',  
        notifyWhen: '',  
      }));
    }
  };
  
  const changeActionButtonState = (name: any, email: any, notify: any, id: any) => {
    const recipname = name;
    const recipemail = email;
    const recipnotif = notify;
    const recipid = id;
    if ((recipname == '' || isNil(recipname) || recipname == undefined) && 
    (recipemail == '' || isNil(recipemail) || recipemail == undefined) &&
    (recipnotif == '' || isNil(recipnotif) || recipnotif == undefined)){
      setdisableAddBtn(true);
      setdisableUpdBtn(true);
    }
    else {
      if (!(recipname == '' || isNil(recipname) || recipname == undefined) && 
      !(recipemail == '' || isNil(recipemail) || recipemail == undefined) &&
      !(recipnotif == '' || isNil(recipnotif) || recipnotif == undefined)){
        if (recipemail.indexOf('@') > 0 && recipemail.indexOf('@') < recipemail.length - 1){
          const aryemail = recipemail.split('@');
          if (aryemail[aryemail.length-1].indexOf('.') > 0){
            if (recipid == '' || isNil(recipid) || recipid == undefined){
              setdisableUpdBtn(true);
              setdisableAddBtn(false);
            }  
            else {
              setdisableUpdBtn(false);
              setdisableAddBtn(true);
            }      
          }
          else {
            setdisableAddBtn(true);
            setdisableUpdBtn(true);      
          }
        }  
        else {
          setdisableAddBtn(true);
          setdisableUpdBtn(true);    
        }
      }
      else {
        setdisableAddBtn(true);
        setdisableUpdBtn(true);  
      }      
    }
  };


  useEffect(() => {
    handleUpdateConfiguration(taskDataState);
  }, [taskDataState]);
  
  useEffect(() => {
    setTaskDataState(taskData);
    const monthlyParams = taskData?.monthlyParameters;
    const periodicity = monthlyParams?.isByWeek ? 'week' :
      monthlyParams?.isTheLastDayOfTheMonth ? 'lastDay' : 'day';
    setPeriodicity(periodicity);
  }, [taskData]);

  useEffect(() => {
    setrecipientDataState(recipientsData);
    setrecipientItemState((prev: any) => ({ ...prev, 
      mCdEmailRecipientid: '',  
      mCdSchedidFk: '',  
      recipientName: '',  
      recipientEmail: '',  
      notifyWhen: '',  
    }));    
  }, [recipientsData]);

  return (
    <div className={styles.generalContainer}>
      <div>
        <Text variant='large' className={styles.boldText}>Edit Schedule</Text>
        <div className={styles.frequencyContainer}>

          <Dropdown
            placeholder='Select a frequency'
            options={dropdownOptions?.frecuencies?.map((i: any) => ({ key: i, text: i }))}
            selectedKey={taskDataState?.frequency}
            onChange={onChangeFrequency}
            label="Frequency"
            required
          />
          {buildStartDate()}
          {buildStartTime()}
          {buildRunOn()}
        </div>
        <div>
          {taskDataState.frequency != frequencyMapping.daily &&
            <div>
              {buildWeekly()}
              {buildMonths()}
              {buildEspecificDays()}
            </div>
          }
        </div>
      </div>
      <div>
        {buildNotifications()}
      </div>
      <div style={{ marginTop: '16px' }}>
        <Text>Override Process</Text>
        <SeparatorGy />
        <div style={{ marginTop: '16px' }} className={styles.dayOfMonth}>
          <Checkbox
            id="overrideDate"
            label="Override Process Start Date"
            checked={overrideData?.overrideProcessStart}
            onChange={() => handleChangeOverrideData({
              ...overrideData,
              overrideProcessStart: !overrideData?.overrideProcessStart,
            })}
          />
        </div>
        <div style={{
          display: 'flex',
          width: '400px',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          margin: '16px 0px 16px 28px',
        }}>
          {buildStartDate(true, overrideData?.overrideProcessStart)}
          {buildStartTime(true, overrideData?.overrideProcessStart)}
        </div>
      </div>
      <div>
        {buildExceptions()}
      </div>
    </div>
  );
};

export default FrequencyComponent;