import moment from 'moment';
import { IFormDataField } from '../../../../../../shared/FormBuilderComponent';

export const emptyPostOosTire = {
  prefix: '',
  brandNumber: '',
  suffix: '',
  a: '',
  b: null,
  disposition: '',
  treadDepth: '',
  docNumber: '',
  oosDate: moment().format('MM/DD/YYYY'),
  plant: null,
  bill: false,
  includeOosAverage: false,
  override: false,
};

export const postOOsTiresColumns = [
  {
    key: 'column1',
    name: 'Prefix',
    fieldName: 'prefix',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Brand Number',
    fieldName: 'brandNumber',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'Suffix',
    fieldName: 'suffix',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'A',
    fieldName: 'a',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'B',
    fieldName: 'b',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column6',
    name: 'Disp.',
    fieldName: 'dispositionCode',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column7',
    name: '32nd.',
    fieldName: 'treadDepth',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column8',
    name: 'OOS Doc.',
    fieldName: 'oosDoc',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column9',
    name: 'OOS Date',
    fieldName: 'oosDate',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column9.1',
    name: 'FAD Doc',
    fieldName: 'addDocNo',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column9.2',
    name: 'FAD Date',
    fieldName: 'addDocDate',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column10',
    name: 'Plant',
    fieldName: 'plant',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column11',
    name: 'Tire Class',
    fieldName: 'tireClass',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column12',
    name: 'Type Code',
    fieldName: 'typeCode',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column13',
    name: 'Cur. Veh.',
    fieldName: 'currentVehicle',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column14',
    name: 'Cur. Pos.',
    fieldName: 'currentPosition',

    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column15',
    name: 'Cur.\u00A0On',
    fieldName: 'currentOn',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column16',
    name: 'Prev. Veh.',
    fieldName: 'previousVehicle',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column17',
    name: 'Prev. Pos.',
    fieldName: 'previousPosition',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column18',
    name: 'Prev.\u00A0Off',
    fieldName: 'previousOff',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column19',
    name: 'Billable',
    fieldName: 'billable',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column20',
    name: 'Incl. Oos Avg',
    fieldName: 'includeOosAvg',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column21',
    name: 'Miles',
    fieldName: 'miles',
    isSorted: false,
    isSortedDescending: false,
  },
];

export const plantsColumns = [
  {
    key: 'column1',
    name: 'Plant Number',
    fieldName: 'plantNo',
  },
  {
    key: 'column2',
    name: 'Name',
    fieldName: 'name',
  },
  {
    key: 'column3',
    name: 'Address 1',
    fieldName: 'address1',
  },
  {
    key: 'column4',
    name: 'Address 2',
    fieldName: 'address2',
  },
  {
    key: 'column5',
    name: 'City',
    fieldName: 'city',
  },
  {
    key: 'column6',
    name: 'State',
    fieldName: 'state',
  },
  {
    key: 'column7',
    name: 'Country',
    fieldName: 'country',
  },
  {
    key: 'column8',
    name: 'Zip',
    fieldName: 'zip',
  },
  {
    key: 'column9',
    name: 'Phone',
    fieldName: 'phone',
  },
  {
    key: 'column10',
    name: 'Email',
    fieldName: 'email',
  },
  {
    key: 'column11',
    name: 'Fax',
    fieldName: 'fax',
  },
];

export const inspectionCodesColumns = [
  {
    key: 'column0',
    name: 'Billable',
    fieldName: 'billable',
  },
  {
    key: 'column1',
    name: 'Inspection code',
    fieldName: 'inspCode',
  },
  {
    key: 'column2',
    name: 'Description',
    fieldName: 'description',
  },
  {
    key: 'column3',
    name: 'Comments',
    fieldName: 'comments',
  },
];

export const TIRE_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'prefixField',
    properties: {
      id: 'prefix',
      label: 'PREFIX',
      name: 'prefix',
      type: 'text',
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'brandNumberField',
    properties: {
      id: 'brandNumber',
      label: 'BRAND NUMBER',
      name: 'brandNumber',
      type: 'text',
    },
  },
  {
    formFieldName: 'suffixField',
    properties: {
      id: 'suffix',
      label: 'SUFFIX',
      name: 'suffix',
      type: 'text',
      textTransform: 'uppercase',
    },
  },
];
