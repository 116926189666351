import { createSlice } from '@reduxjs/toolkit';

interface IBillingState {
  reloadSetBillingDataTab: boolean;
}

const initialState: IBillingState = {
  reloadSetBillingDataTab: false,
};

export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    toggleReloadSetBillingDataTab: (state) => {
      state.reloadSetBillingDataTab = !state.reloadSetBillingDataTab;
    },
  },
});

export const { toggleReloadSetBillingDataTab } = billingSlice.actions;

export const reloadSetBillingDataTabSelector = ({ billing }: any) => billing.reloadSetBillingDataTab;

export default billingSlice.reducer;