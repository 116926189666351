import { DefaultButton, Dropdown, IDropdownOption, MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import AutocompleteInput from '../../../../shared/AutocompleteInput';
import DataGridComponent from '../../../../shared/DataGridComponent';
import { useBoolean } from '@fluentui/react-hooks';
import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import { emptyProduct, productHeadCell } from './const';
import { IMaintainProductProps } from './IMaintainProductProps';
import apiService from '../../../../api';
import styles from './MaintainProduct.module.scss';
import useNotifications from '../../../../hooks/useNotifications';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import { sortOrder } from '../../../../consts/sortOrder';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { auth_maintain_product } from '../../../../consts/programKeys';
import { IAddProductItem, IMaintainProductItem } from './IMaintainProductState';

const MaintainProductComponent: FunctionComponent<IMaintainProductProps> = () => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_maintain_product);

  const { addNotification } = useNotifications();
  const [productList, setProductList] = useState<Array<IMaintainProductItem>>([]);
  const [productItem, setProductItem] = useState<IAddProductItem>(emptyProduct);
  const [isLoadingTable, { toggle: toggleShowLoadingTable }] = useBoolean(false);
  const [totalFound, setTotalFound] = useState(0);
  const [loading, setLoading] = useState(false);
  const [reloadControlsTable, { toggle: toggleReloadControlsTable }] = useBoolean(false);

  const [isEditableMode, setEditableMode] = useState(false);

  const [componentState, setComponentState] = useState<any>({
    pagination: { pageSize: 5, pageNumber: 1 },
    sortOrder: { column: productHeadCell[0].fieldName, order: sortOrder.ASC },
  });

  const [countriesList, setCountriesList] = useState([]);
  const [countrySelected, setCountrySelected] = useState<string>('');
  const [tireTypesList, setTireTypesList] = useState([]);
  const [tireTypeSelected, setTireTypeSelected] = useState<string>('');
  const [radialBiasesList, setRadialBiasesList] = useState([]);
  const [radialBiasSelected, setRadialBiasSelected] = useState<string>('');
  const [filters, setFilters] = useState<any>({});
  const [productDesc, setProductDesc] = useState<any>({});

  const fetchCountries = async () => {
    try {
      const { data } = await apiService.maintainAPI.getCountries();
      setCountriesList(data);
      setCountrySelected(data[1]);
    }
    catch (e: any) {
      addNotification({ text: 'Error fetching countries', type: MessageBarType.error });
    }
  };

  const fetchTireTypes = async () => {
    try {
      const { data } = await apiService.maintainAPI.getTireTypes();
      setTireTypesList(data);
    }
    catch (e: any) {
      addNotification({ text: 'Error fetching tire types', type: MessageBarType.error });
    }
  };

  const fetchRadialBiases = async () => {
    try {
      const { data } = await apiService.maintainAPI.getRadialBias();
      setRadialBiasesList(data);
    }
    catch (e: any) {
      addNotification({ text: 'Error fetching radial biases', type: MessageBarType.error });
    }
  };

  const handleCountryChange = (event: any, item: IDropdownOption<any> | undefined): void => {
    if (item?.text) setCountrySelected(item?.text);
  };

  const fetchProducts = async (
    pagination: any = { pageSize: 5, pageNumber: 1 },
    sortOrder: any = { column: productHeadCell[0].fieldName, order: 'asc' },
    filters?: any,
  ) => {
    try {
      toggleShowLoadingTable();
      const { data }: any = await apiService.maintainAPI.getProducts(countrySelected, pagination, sortOrder, filters);
      setProductList(data?.data);
      setTotalFound(data?.total?.found);
    } catch (error) {
      addNotification({ text: 'Fetching products error', type: MessageBarType.error });
    } finally {
      toggleShowLoadingTable();
    }
  };

  const createProduct = async () => {
    try {
      const { data } = await apiService.maintainAPI.addProduct({ ...productItem, country: countrySelected, tireType: tireTypeSelected, radialBias: radialBiasSelected });
      addNotification({ text: data.message, type: MessageBarType.success });
    } catch (error) {
      addNotification({ text: 'Create products error', type: MessageBarType.error });
    }
  };

  const updateProduct = async () => {
    try {
      const { data } = await apiService.maintainAPI.updateProduct({ ...productItem, country: countrySelected, tireType: tireTypeSelected, radialBias: radialBiasSelected });
      addNotification({ text: data.message, type: MessageBarType.success });
    } catch (error: any) {
      const { response } = error;
      addNotification({ text: `Update products error: ${response.data.message}`, type: MessageBarType.error });
    }
  };

  const deleteProducts = async (productIds: Array<any>) => {
    try {
      const { data } = await apiService.maintainAPI.deleteProducts(countrySelected, productIds);
      addNotification({ text: data.message, type: MessageBarType.success });
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 499:
          if (response.data.state)
            response.data.state.map((currentError: any) => addNotification({
              text: `Prodect code ${currentError.id}. ${currentError.message}`,
              type: MessageBarType.error,
            }));
          break;
        default:
          addNotification({ text: 'Delete products error', type: MessageBarType.error });
      }
    }
  };

  const clearProduct = () => {
    if (productItem) {
      setProductItem({ ...productItem, ...emptyProduct });
    } else {
      setProductItem(emptyProduct);
    }
    setTireTypeSelected('');
    setRadialBiasSelected('');
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    toggleShowLoadingTable();
    const { countOnPage, paginationProps, sortOrder } = dataGridState;
    const pagination = {
      pageSize: +countOnPage.key,
      pageNumber: paginationProps.current,
    };
    setComponentState((prev: any) => ({ ...prev, countOnPage, pagination, sortOrder }));
    await fetchProducts(pagination, sortOrder, filters);
    toggleShowLoadingTable();
  };

  const handleSelectToUpdateRow = (rowsSelected: Array<IMaintainProductItem>) => {
    if (rowsSelected.length === 1) {
      setProductItem(parseSelectedForUpdate(rowsSelected[0]));
      setEditableMode(true);
    } else {
      setProductItem(emptyProduct);
      setTireTypeSelected('');
      setRadialBiasSelected('');
      setEditableMode(false);
    }
  };

  const handleAddProduct = async () => {
    toggleShowLoadingTable();
    await createProduct();
    await fetchProducts(componentState.pagination, componentState.sortOrder);
    toggleShowLoadingTable();
  };

  const handleUpdateProduct = async () => {
    toggleShowLoadingTable();
    await updateProduct();
    await fetchProducts(componentState.pagination, componentState.sortOrder);
    toggleShowLoadingTable();
  };

  const handleDelete = async (rowsToDelete: Array<IMaintainProductItem>) => {
    toggleShowLoadingTable();
    const idList = rowsToDelete?.map(row => row.id);
    await deleteProducts(idList);
    await fetchProducts({ pageSize: 5, pageNumber: 1 }, componentState.sortOrder);
    toggleReloadControlsTable();
    toggleShowLoadingTable();
  };

  const parseSelectedForUpdate = (rowSelected: IMaintainProductItem) => {
    setProductItem(emptyProduct);
    setTireTypeSelected(rowSelected.origOrRtrd);
    setRadialBiasSelected(rowSelected.radialOrBias);
    return {
      ...productItem, productCode: rowSelected.id, country: rowSelected.country, tireSize: rowSelected.tireSize, description: rowSelected.description,
      factoryCost: rowSelected.factoryCost, radialBias: rowSelected.radialOrBias, tireType: rowSelected.origOrRtrd,
    };
  };

  const shouldDisableEdit = () =>
    !productItem?.productCode || !productItem?.description || !productItem?.tireSize || !tireTypeSelected || !radialBiasSelected || !productItem?.factoryCost;

  useEffect(() => {
    async function fetchAPI() {
      setLoading(true);
      await fetchCountries();
      await fetchTireTypes();
      await fetchRadialBiases();
      setLoading(false);
    }
    fetchAPI();
  }, []);

  useEffect(() => {
    fetchProducts(componentState.pagination, componentState.sortOrder);
  }, [countrySelected, filters]);

  return (
    <div className="ms-Grid marginTop18" dir='ltr'>
      <div className='ms-Grid'>
        <div className='ms-Grid-row' dir='ltr'>
          <div className='ms-Grid-col ms-sm9'>
            <Text variant="xLarge" className={styles.highlight}>Filters</Text>
            <SeparatorGy />
            <div className='ms-Grid-row' dir='ltr'>
              <div className='ms-Grid-col ms-sm4'>
                <Dropdown
                  label='Country'
                  options={countriesList.map(country => ({
                    key: country,
                    text: country,
                  }))}
                  selectedKey={countrySelected}
                  onChange={handleCountryChange}
                />
              </div>
            </div>

            <div className='ms-Grid-row' dir='ltr'>
              <div className='ms-Grid-col ms-sm4'>
                <TextField
                  id='productCode'
                  type="text"
                  label="Product code:"
                  value={filters?.productCode}
                  onChange={(ev, value) => setFilters((prev: any) => ({ ...prev, productCode: value }))}
                />
              </div>
              <div className='ms-Grid-col ms-sm4'>
                <TextField
                  id='tireSize'
                  type="text"
                  label="Tire Size:"
                  value={filters?.tireSize}
                  onChange={(ev, value) => setFilters((prev: any) => ({ ...prev, tireSize: value }))}
                />
              </div>
              <div className='ms-Grid-col ms-sm4'>
                <TextField
                  id='description'
                  type="text"
                  label="Description:"
                  value={filters?.description}
                  onChange={(ev, value) => setFilters((prev: any) => ({ ...prev, description: value }))}
                />
              </div>
              <div className='ms-Grid-col ms-sm4'>
                <PrimaryButton
                  id="searchButton"
                  className={styles.buttonTopMargin}
                  text="Search"
                  onClick={() => fetchProducts({ pageSize: componentState.pagination.pageSize, pageNumber: 1 }, componentState.sortOrder, filters)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <SeparatorGy />

      <DataGridComponent
        idTable={'maintain-product-table'}
        title='Available Products for Mileage'
        headCells={productHeadCell}
        rowsTable={productList}
        totalDataFound={totalFound}
        enablePagination={true}
        enableRowsPerPage={true}
        enableMultiSelectRow={userPermissions.isWrite}
        enableCheckBox={userPermissions.isWrite}
        enableDeleteOption={userPermissions.isWrite}
        isLoading={isLoadingTable}
        reloadControlsTable={reloadControlsTable}
        handleChangeDataGridState={handleChangeDataGridState}
        handleSelectRow={handleSelectToUpdateRow}
        handleDelete={handleDelete}
      />
      <div className='ms-Grid'>
        <div className='ms-Grid-row' dir='ltr'>
          <div className='ms-Grid-col ms-sm9'>
            <Text variant="xLarge" className={styles.highlight}>Maintain Product Details</Text>
            <SeparatorGy />
            <div className='ms-Grid-row' dir='ltr'>
              <div className='ms-Grid-col ms-sm4'>
                <TextField
                  id='productCode'
                  label="Product Code"
                  value={productItem?.productCode}
                  onChange={(ev, value) => setProductItem((prev: any) => ({ ...prev, productCode: value }))}
                  required
                />
              </div>
              <div className='ms-Grid-col ms-sm4'>
                <TextField
                  id='tireSize'
                  label="Tire Size"
                  value={productItem?.tireSize}
                  onChange={(ev, value) => setProductItem((prev: any) => ({ ...prev, tireSize: value }))}
                  required
                />
              </div>
              <div className='ms-Grid-col ms-sm4'>
                <AutocompleteInput
                  value={radialBiasSelected}
                  list={
                    radialBiasesList ?
                      radialBiasesList.map((radialBias: { item1: string, item2: string }) => ({
                        key: radialBias.item1,
                        text: `${radialBias.item1}-${radialBias.item2}`,
                      })) : []
                  }
                  chooseCurrentItem={(value) => setRadialBiasSelected(value)}
                  label="Radial Bias"
                  emptyExpanded
                  required
                />
              </div>
            </div>

            <div className='ms-Grid-row' dir='ltr'>
              <div className='ms-Grid-col ms-sm4'>
                <TextField
                  id='productDescription'
                  label="Product Description"
                  value={productItem?.description}
                  onChange={(ev, value) => { setProductDesc(value); setProductItem((prev: any) => ({ ...prev, description: value }));}}
                  errorMessage={productDesc.length > 32 ? 'Product Description must be at least 32 characters' : ''}
                  required
                />
              </div>
              <div className='ms-Grid-col ms-sm4'>
                <AutocompleteInput
                  value={tireTypeSelected}
                  list={
                    tireTypesList ?
                      tireTypesList.map((tireType: { item1: string, item2: string }) => ({
                        key: tireType.item1,
                        text: `${tireType.item1}-${tireType.item2}`,
                      })) : []
                  }
                  chooseCurrentItem={(value) => setTireTypeSelected(value)}
                  label="Tire Type"
                  emptyExpanded
                  required
                />
              </div>
              <div className='ms-Grid-col ms-sm4'>
                <TextField
                  id='factoryCost'
                  label="Factory Cost"
                  value={productItem?.factoryCost}
                  onChange={(ev, value) => setProductItem((prev: any) => ({ ...prev, factoryCost: value }))}
                  required
                />
              </div>
            </div>

            <div className='ms-Grid-row' dir='ltr'>
              {isEditableMode ?
                <div className='ms-Grid-col'>
                  <PrimaryButton
                    id="updateButton"
                    className={styles.buttonTopMargin}
                    text="+ Update"
                    onClick={handleUpdateProduct}
                    disabled={shouldDisableEdit() || !userPermissions.isWrite || productDesc.length > 32}
                  />
                </div> :
                <div className='ms-Grid-col'>
                  <PrimaryButton
                    id="addButton"
                    className={styles.buttonTopMargin}
                    text="+ Add New Product"
                    onClick={handleAddProduct}
                    disabled={shouldDisableEdit() || !userPermissions.isWrite || productDesc.length > 32}
                  />
                </div>
              }
              <div className='ms-Grid-col'>
                <DefaultButton
                  id="clearButton"
                  className={styles.buttonTopMargin}
                  text="Clear"
                  onClick={clearProduct}
                  disabled={!userPermissions.isWrite} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <LoadingScreen />}
    </div>
  );
};

export default MaintainProductComponent;