import {
  Dropdown,
  Icon,
  IconButton, Link,
  MessageBarType, PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { get } from 'lodash';
import { FC, useEffect, useState } from 'react';
import apiService from '../../../../api';
import { permissions } from '../../../../consts/permissions';
import { sortOrder } from '../../../../consts/sortOrder';
import useNotifications from '../../../../hooks/useNotifications';
import DataGridComponent from '../../../../shared/DataGridComponent';
import { ACTION_GROUPS_ENUM } from '../../../../shared/DataGridComponent/ActionsComponent/ActionsGroupEnum';
import DialogComponent from '../../../../shared/DialogComponent';
import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import { pageSizes, allOption, emptyFilters, tableColumns } from './consts';
import { IFiltersState } from './IFiltersState';
import { IUsersProps } from './IUsersProps';
import { IUsersState } from './IUsersState';
import styles from './Users.module.scss';

const Users: FC<IUsersProps> = () => {

  const { addNotification } = useNotifications();

  const [state, setState] = useState<IUsersState>({
    items: [],
    userStatuses: [],
    rolesList: [],
    loading: false,
    foundCount: 0,
    dataGridState: null,
    usersToUpdate: [],
  });

  const [filtersState, setFiltersState] = useState<IFiltersState>(emptyFilters);
  const [showUpdateDialogConfirmation, { toggle: toggleShowUpdateDialogConfirmation }] = useBoolean(false);


  const fetch = async (
    pagination: any = state.dataGridState?.pagination,
    sortOrder: any = state.dataGridState?.sortOrder,
  ) => {
    setState((prev: IUsersState) => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.management.getUsers(
        pagination,
        sortOrder,
        {
          searchText: filtersState.searchedText,
          ...(filtersState.accessType[0] === allOption.key ? {} : { accessTypes: filtersState.accessType }),
          ...(filtersState.userStatus[0] === allOption.key ? {} : { statuses: filtersState.userStatus }),
        },
      );
      const foundCount = data.total.found;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, selectedItem: null, foundCount }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching users error: ${get(response, 'data.message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchRoles = async () => {
    try {
      if (state.rolesList.length) return;
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await apiService.management.getRoles(
        null,
        {
          column: 'name',
          order: sortOrder.ASC,
        },
      );
      setState((prev) => ({ ...prev, rolesList: data.data }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching roles error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchUserStatuses = async () => {
    try {
      if (state.userStatuses.length) return;
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await apiService.management.getUserStatuses();
      setState((prev: IUsersState) => ({ ...prev, userStatuses: data }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching user statuses error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleChangeSearchString = (event: any, searchedText = '') => {
    setFiltersState((prev: IFiltersState) => ({ ...prev, searchedText }));
  };

  const onChangeFilters = (item: any, filter: string, totalCount: number): void => {
    if (item && item.key === allOption.key) {
      setFiltersState((prev: IFiltersState) => ({ ...prev, [filter]: [item.key] }));
    } else if (item) {
      const allPos = get(filtersState, filter, []).indexOf(allOption.key);
      const prevArray = get(filtersState, filter, []);
      const prevArrayWithoutAll = prevArray.filter((m: any, i: number) => i !== allPos);
      setFiltersState((prev: IFiltersState) => ({
        ...prev,
        [filter]: item.selected ?
          [...(prevArrayWithoutAll.length === totalCount - 1 ? [allOption.key] : [item.key, ...prevArrayWithoutAll])] :
          [...prevArray.filter((key: any) => key !== item.key), ...(prevArray.length === 1 ? [allOption.key] : [])],
      }));
    }
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    setState(prev => ({ ...prev, loading: true }));
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    setState({ ...state, dataGridState: { pagination, sortOrder } });
    await fetch(pagination, sortOrder);
    setState(prev => ({ ...prev, loading: false }));
  };

  const handleSearch = () => {
    fetch();
  };

  const fetchAll = async () => {
    await fetchRoles();
    await fetchUserStatuses();
  };

  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <>
      <div className="ms-Grid">
        <div className={classNames('ms-Grid-row', styles.mainInfoBlock)}>
          <div className={classNames('ms-Grid-col', 'ms-sm4')}>
            <Icon iconName='Contact' className={styles.mainIcon} />
            <Text variant='xLarge'>Users</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm3')}></div>
          <div className={classNames('ms-Grid-col', 'ms-sm2', styles.tooltipIconWrapper)}>
            <Icon className={styles.errorIcon} iconName='Error' />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm3')}>
            <Text block>
              Access management is handled in GAIMS. If you want to add or remove access to the users, please visit:
            </Text>
            <Link target='_blank' href='http://go.goodyear.com/cfmx/internal/gaims'>http://go.goodyear.com/cfmx/internal/gaims</Link>
          </div>
        </div>
        <SeparatorGy />
        <div className={classNames('ms-Grid-row', styles.mainRow)}>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <div className={styles.tabFiltersBlock}>
              <TextField
                id="searchedText"
                value={filtersState.searchedText}
                label="Search"
                placeholder="Enter search string"
                onChange={handleChangeSearchString}
              />
              <Dropdown
                label="Filter By Access Types"
                selectedKeys={filtersState.accessType}
                onChange={(ev: any, item: any) => onChangeFilters(item, 'accessType', Object.values(permissions).length)}
                options={[allOption, ...Object.values(state.rolesList).map(({ id, name }: any) => ({ key: id, text: name }))]}
                multiSelect
              />
              <Dropdown
                label="Filter By User Status"
                selectedKeys={filtersState.userStatus}
                onChange={(ev: any, item: any) => onChangeFilters(item, 'userStatus', Object.values(permissions).length)}
                options={[allOption, ...Object.values(state.userStatuses).map((v: any) => ({ key: v, text: v }))]}
                multiSelect
              />
              <IconButton
                iconProps={{ iconName: 'Search' }}
                onClick={handleSearch}
              />
            </div>
            <DataGridComponent
              idTable='users-table'
              title='Available users'
              headCells={tableColumns}
              rowsTable={state.items}
              totalDataFound={state.foundCount}
              isLoading={state.loading}
              actionsGroupName={ACTION_GROUPS_ENUM.USERS_MANAGEMENT}
              defaultRowsPerPage={pageSizes}
              enableRowsPerPage
              enablePagination
              handleChangeDataGridState={handleChangeDataGridState}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
