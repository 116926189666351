import { reportTestInstance } from '../axios';

const getStoredReports = (pagination: any, filters: any, sortOrder: any) =>
  reportTestInstance.post('/schedule-reports/list', { pagination, filters, sortOrder });

const getBillingBackupsReports = (pagination: any, filters: any, sortOrder: any) =>
  reportTestInstance.post('/schedule-backup-reports/list', { pagination, ...filters, sortOrder });

const getScheduledReport = (scheduleReportId: any) =>
  reportTestInstance.post('/schedule-report/download', { scheduleReportId }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const getScheduledBackupReport = (scheduleReportId: any) =>
  reportTestInstance.post('/schedule-backup-reports/download', { scheduleReportId }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  getStoredReports,
  getBillingBackupsReports,
  getScheduledReport,
  getScheduledBackupReport,
};
