

import { DatePicker, DateRangeType, Dropdown, IconButton, MessageBarType, DefaultButton } from '@fluentui/react';
import classNames from 'classnames';
import { debounce, get } from 'lodash';
import moment from 'moment';
import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import apiService from '../../../../api';
import useNotifications from '../../../../hooks/useNotifications';
import DataGridComponent from '../../../../shared/DataGridComponent';
import { ACTION_GROUPS_ENUM } from '../../../../shared/DataGridComponent/ActionsComponent/ActionsGroupEnum';
import AutoCompleteField from '../../../Common/Search/AutoCompleteField';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import styles from './BillingBackups.module.scss';
import { billingBackupsHeadCell } from './consts';


const BillingBackups: FC = (): ReactElement => {

  const { addNotification } = useNotifications();

  const [reports, setReports] = useState([]);
  const [reportsFoundCount, setReportsFoundCount] = useState<number>(0);
  const [reportsPagination, setReportsPagination] = useState<any>({ pageSize: 30, pageNumber: 1 });
  const [reportsSortOrder, setReportsSortOrder] = useState<any>({ column: billingBackupsHeadCell[4].fieldName, order: 'desc' });
  const [defaultPageSize, setDefaultPageSize] = useState<any>([30, 50, 100]);

  const [isFirstLoading, setFirstLoading] = useState<boolean>(true);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isLoadingReports, setLoadingReports] = useState<boolean>(false);
  const [isLoadingCustomers, setLoadingCustomers] = useState<boolean>(false);
  const [filters, setFilters] = useState({ reportType: null, region: null, generateDon: undefined, customer: null});

  const [regions, setRegions] = useState<Array<string>>([]);
  const [billingTypes, setBillingTypes] = useState<Array<string>>([]);
  const [customers, setCustomers] = useState<Array<any>>([]);

  const [customerFilters, setCustomerFilters] = useState<any>(null);

  const handleChangeDataGridState = async (dataGridState: any) => {
    const { countOnPage, paginationProps, sortOrder, searchedText } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    fetchReports(pagination, sortOrder, searchedText);
  };
  
  const onRegionChange = (_ev: any, option: any) => {
    setFilters((prev: any) => ({ ...prev, region: option?.key }));
  };

  const onBillingTypeChange = (_ev: any, option: any) => {
    setFilters((prev: any) => ({ ...prev, reportType: option?.key }));
  };

  const fetchReports = async (pagination: any, sortOrder: any, search?: string) => {
    try {
      setLoadingReports(true);
      const { data }: any = await apiService.reporting.getBillingBackupsReports(
        pagination, 
        { ...filters,
          generateDon: filters.generateDon ? moment(filters.generateDon).format('YYYY-MM') : undefined,
          customer: (filters.customer as any)?.customerName,
          search, 
        }, 
        sortOrder,
      );
      setReports(data.data);
      setReportsFoundCount(data?.total?.found);
      setReportsPagination(pagination);
      setReportsSortOrder(sortOrder);
      setFirstLoading(false);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Reports fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoadingReports(false);
    }
  };

  const fetchRegionCodes = async () => {
    setLoading(true);
    try {
      const { data } = await apiService.billingAPI.getRegionList();
      setRegions(data);
    } catch (e: any) {
      addNotification({ text: 'Error fetching region codes', type: MessageBarType.error });
    }
    finally {
      setLoading(false);
    }
  };


  const fetchBillingTypes = async () => {
    setLoading(true);
    try {
      const { data }: any = await apiService.billingAPI.getBillingTypes();
      setBillingTypes(data);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Billing types fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    }
    finally {
      setLoading(false);
    }
  };

  const fetchCustomers = async (filters: any, sortOrder: any = { column: 'customerName', order: 'asc' }) => {
    setLoadingCustomers(true);
    try {
      const { data: { data } }: any = await apiService.getCustomerList(
        filters,
        undefined,
        sortOrder,
      );
      setCustomers(data);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Customers fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoadingCustomers(false);
    }
  };

  const onCustomerTextChange = debounce(async (field: string, value: string, sortOrder?: any) => {
    if (value && get(customerFilters, `${field}`) !== value) {
      fetchCustomers({ [field]: value }, sortOrder);
    }
    setCustomerFilters(value ? { [field]: value } : null);
  }, 1000);


  const onCustomerChange = async (newCustomerId: any) => {
    setFilters((prev: any) => ({ ...prev, customer: getListItemById(newCustomerId, customers) }));
  };

  const getListItemById = (idToFind: number, list: any[]) => {
    const itemToFind = list?.find(({ id }) => id === idToFind);
    return itemToFind ? itemToFind : null;
  };

  const regionOptions = regions?.map((item: any) => ({
    key: item,
    text: item,
  }));

  const billingTypeOptions = billingTypes?.map((item: any) => ({
    key: item,
    text: item,
  }));

  const removeEmpty = ({ text }: any) => text !== null;

  const customerNameList = useMemo(() => customers ?
    customers.map(({ id, customerName }) => ({
      key: id,
      text: customerName,
    })).filter(removeEmpty) : [], [customers]);

  const customerPrefixList = useMemo(() => customers ?
    customers.map(({ id, prefix }) => ({
      key: id,
      text: prefix,
    })).filter(removeEmpty) : [], [customers]);

  useEffect(() => {
    fetchBillingTypes();
    fetchRegionCodes();
  }, []);
  
  useEffect(() => {
    if(!isFirstLoading)
      fetchReports(reportsPagination, reportsSortOrder);
  }, [filters]);

  useEffect(() => {
    if (!customerFilters) {
      fetchCustomers({});
    }
  }, [customerFilters]);
  
  return (
    <>
      <div className='ms-Grid-row'>
        <div className={classNames('ms-Grid-col', 'ms-sm12')}>
          <div className={styles.filters}>
            <div className={styles.field}>
              <Dropdown
                className={styles.mediumField}
                label="Region"
                options={regionOptions}
                defaultSelectedKey={filters.region}
                onChange={onRegionChange}
              />
              {filters.region && <IconButton
                id='clearRegion'
                className={styles.iconButton}
                iconProps={{ iconName: 'Clear' }}
                onClick={() => setFilters((prev) => ({...prev, region: null}))}
              /> }
            </div>
            <div className={styles.field}>
              <Dropdown
                className={styles.mediumField}
                label="Billing Type"
                options={billingTypeOptions}
                defaultSelectedKey={filters.reportType}
                onChange={onBillingTypeChange}
              />
              {filters.reportType && <IconButton
                id='clearBillingType'
                className={styles.iconButton}
                iconProps={{ iconName: 'Clear' }}
                onClick={() => setFilters((prev) => ({...prev, reportType: null}))}
              /> }
            </div>
            <div className={styles.field}>
              <DatePicker
                label="Period"
                calendarProps={{
                  highlightSelectedMonth: true,
                  isDayPickerVisible: false,
                  dateRangeType: DateRangeType.Month,
                }}
                value={filters.generateDon ? moment(filters.generateDon).toDate() : undefined}
                onSelectDate={(date: Date | null | undefined) => setFilters((prev: any) => ({ ...prev, generateDon: moment(date).format('MM/DD/YYYY') }))}
                formatDate={(date: any) => moment(date).format('MM/YY')}
              />
              {filters.generateDon && <IconButton
                id='clearGenerateDon'
                className={classNames(styles.iconButton, styles.clearCalendarBtn)}
                iconProps={{ iconName: 'Clear' }}
                onClick={() => setFilters((prev) => ({...prev, generateDon: undefined}))}
              /> }
            </div> 
            <div className={styles.field}>
              <AutoCompleteField
                label="Prefix"
                data-testid='prefix'
                icon="FavoriteStar"
                value={get(filters.customer, 'id')}
                list={customerPrefixList}
                textValue={(value: any) => onCustomerTextChange('custPrefix', value, { column: 'prefix', order: 'asc' })}
                onChangeAction={onCustomerChange}
                isLoading={isLoadingCustomers}
              />
              <IconButton
                id='searchPrefix'
                className={styles.searchButton}
                iconProps={{ iconName: 'Search' }}
                onClick={() => onCustomerChange(customerPrefixList[0].key)}
              />
            </div>
            <div className={styles.field}>
              <AutoCompleteField
                className={styles.largeField}
                label="Customer Name"
                data-testid='customer-name'
                icon="Contact"
                value={get(filters.customer, 'id')}
                list={customerNameList}
                textValue={(value: any) => onCustomerTextChange('custName', value, { column: 'customerName', order: 'asc' })}
                onChangeAction={onCustomerChange}
                onBlur={(ev) => filters.customer && !ev.target.value && setFilters((prev) => ({ ...prev, customer: null }))}
                isLoading={isLoadingCustomers}
              />
              <IconButton
                id='searchCustName'
                className={styles.iconButton}
                iconProps={{ iconName: 'Search' }}
                onClick={() => onCustomerChange(customerNameList[0].key)}
              />
            </div>
          </div>
          <div className={styles.clearSearchBtn}>
            <DefaultButton text="Clear Search" onClick={() => setFilters((prev) => ({...prev, reportType: null, region: null, generateDon: undefined, customer: null}))} />
          </div>
          <DataGridComponent
            idTable={'reports-table'}
            title='Reports'
            headCells={billingBackupsHeadCell}
            rowsTable={reports}
            totalDataFound={reportsFoundCount}
            actionsGroupName={ACTION_GROUPS_ENUM.VIEW_PDF_BILLINGREPORTS}
            enablePagination
            enableRowsPerPage
            defaultRowsPerPage={defaultPageSize}
            enableSearching
            isLoading={isLoadingReports}
            defaultSorting={reportsSortOrder}
            handleChangeDataGridState={handleChangeDataGridState}
          />
        </div>
        {isLoading && <LoadingScreen />}
      </div>
    </>
  );
};

export default BillingBackups;
