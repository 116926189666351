export const emptyB73Format = {
  vNo: '',
  wPos: '',
  offPrefix: '',
  offBrandNumber: '',
  offSuffix: '',
  onPrefix: '',
  onBrandNumber: '',
  onSuffix: '',
  onCot: 'N',
  offCot: 'N',
  submit: false,
  chgDate: '',
};

export const columns = [
  {
    key: 'column1',
    name: 'ChgDate',
    fieldName: 'chgDate',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'VNo',
    fieldName: 'vNo',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'WPos',
    fieldName: 'wPos',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'OffPrefix',
    fieldName: 'offPrefix',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'OffBrandNumber',
    fieldName: 'offBrandNumber',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column6',
    name: 'OffSuffix',
    fieldName: 'offSuffix',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column7',
    name: 'OffCOT',
    fieldName: 'offCot',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column8',
    name: 'OnPrefix',
    fieldName: 'onPrefix',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column9',
    name: 'OnBrandNumber',
    fieldName: 'onBrandNumber',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column10',
    name: 'OnSuffix',
    fieldName: 'onSuffix',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column11',
    name: 'OnCOT',
    fieldName: 'onCot',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column12',
    name: 'Submit',
    fieldName: 'submit',
    isSorted: false,
    isSortedDescending: false,
  },
];
