export enum ACTION_GROUPS_ENUM {
  VIEW_INVOICES = 'viewInvoices',
  VIEW_TIRE_SALES = 'viewTireSales',
  EDIT_MANAGE_BILLING_CODES = 'editManageBillingCodes',
  EDIT_MANAGE_BILLING_OPTIONS = 'editManageBillingOptions',
  TASK_SCHEDULER = 'taskScheduler',
  USERS_MANAGEMENT = 'usersManagement',
  DOWNLOAD_AVAILABLE_REPORT = 'downloadAvailableReport',
  DOWNLOAD_REPORT = 'downloadReport',
  VIEW_PDF_BILLINGREPORTS = 'viewPdfBillingReports',
  VIEW_PDF_STOREDREPORTS = 'viewPdfStoredReports'
};