import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import AutoCompleteField from './AutoCompleteField';
import {
  Icon,
  ITextFieldProps,
  Text,
} from '@fluentui/react';
import {
  customerSelector,
  setCustomer,
} from '../../../redux/recordKeepingSlice';
import { ICustomer } from './ICustomer';
import { FC, useEffect, useMemo } from 'react';
import styles from './Search.module.scss';
import { setOrderCode } from '../../../redux/tireOrderingSlice';

interface SearchCustomerProps {
  customersList: ICustomer[] | null;
  fetchCustomers: (filter: any, pagination?: any, sort?: any) => any;
  onPrefixChange?: (prefix: string) => void;
  onNameChange?: (name: string) => void;
  onNumberChange?: (name: string) => void;
  className?: string;
  isSearchPage?: boolean;
}

const SearchCustomer: FC<SearchCustomerProps> = ({
  customersList,
  fetchCustomers,
  onPrefixChange,
  onNameChange,
  onNumberChange,
  className,
  isSearchPage = false,
}) => {
  const customer = useSelector(customerSelector);

  const onRenderPrefixCustomerNumberInput = (
    props: ITextFieldProps | undefined,
  ): JSX.Element => (
    <div>
      <Icon iconName="Tag" aria-hidden="true" />
      <span>{props?.prefix}</span>
    </div>
  );
  const removeEmpty = ({ text }: any) => text !== null;

  const customerPrefixList = useMemo(() => customersList ?
    customersList.map(({ id, prefix }) => ({
      key: id,
      text: prefix,
    })).filter(removeEmpty) : [], [customersList]);

  const customerNameList = useMemo(() => customersList ?
    customersList.map(({ id, customerName, abbrName, corrAddressCountry, corrAddressCity }) => ({
      key: id,
      text: abbrName ?
        customerName + ' - ' + abbrName + ' - ' + corrAddressCity + '(' + corrAddressCountry + ')':
        customerName + ' - ' + corrAddressCity + ' (' + corrAddressCountry + ')',
    })).filter(removeEmpty) : [], [customersList]);

  const customerNumberList = useMemo(() => customersList ?
    customersList.map(({ id, customerNumber }) => ({
      key: id,
      text: customerNumber,
    })).filter(removeEmpty) : [], [customersList]);

  const customerPrefixInputText = (custPrefix: string) => {
    if (!custPrefix) return;
    getCustomers({ custPrefix, fdelete: false });
    if (onPrefixChange) {
      onPrefixChange(custPrefix);
    }
  };

  const customerNameInputText = (custName: string) => {
    if (!custName) return;
    getCustomers({ custName });
    if (onNameChange) {
      onNameChange(custName);
    }
  };

  const customerNumberInputText = (custNumber: string) => {
    if (!custNumber) return;
    getCustomers({ custNumber });
    if (onNumberChange) {
      onNumberChange(custNumber);
    }
  };

  const getCustomers = debounce(async (customerData) => {
    if (customerData.custPrefix) {
      fetchCustomers({ ...customerData, fdelete: false }, null, { column: 'custPrefix', order: 'asc' });
    } else {
      fetchCustomers(customerData);
    }
  }, 1000);

  const getCustomerById = (idToFind: number) => {
    const customerToFind = customersList?.find(({ id }) => id === idToFind);
    return customerToFind ? customerToFind : null;
  };

  const dispatch = useDispatch();

  const onCustomerChange = (newCustomer: any) => {
    dispatch(setCustomer(getCustomerById(newCustomer)));
    dispatch(setOrderCode(null));
  };

  return (
    <div className={className}>
      {
        !isSearchPage && <div>
          <Text variant='xLarge' className={styles.highlight}>Search a Customer</Text>
        </div>
      }
      <div className="search-form-body">
        <AutoCompleteField
          onChangeAction={onCustomerChange}
          initialValue={customer?.prefix}
          list={customerPrefixList}
          label="Customer Prefix"
          data-testid='customer-prefix'
          icon="FavoriteStar"
          textValue={customerPrefixInputText}
        />
        <AutoCompleteField
          onChangeAction={onCustomerChange}
          initialValue={customer?.customerName}
          list={customerNameList}
          label="Customer Name"
          data-testid='customer-name'
          icon="Contact"
          textValue={customerNameInputText}
        />
        <AutoCompleteField
          onChangeAction={onCustomerChange}
          initialValue={customer?.customerNumber}
          textValue={customerNumberInputText}
          list={customerNumberList}
          label="Customer Number"
          data-testid='customer-number'
          onRenderPrefix={onRenderPrefixCustomerNumberInput}
        />
      </div>
    </div>
  );
};

export default SearchCustomer;
