import React, { useEffect, useMemo, useState } from 'react';
import {
  MessageBarType,
  Checkbox,
  DefaultButton,
} from '@fluentui/react';
import AutoCompleteField from '../../../../../Common/Search/AutoCompleteField';
import DataGridComponent from '../../../../../../shared/DataGridComponent';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_billing_setBillingData } from '../../../../../../consts/programKeys';
import { billingOptionsHeadCell } from './const';
import { useCustomersFetch } from '../../../../../../hooks/useCustomersFetch';
import apiService from '../../../../../../api';
import useNotifications from '../../../../../../hooks/useNotifications';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import BillingOptionsModal from './Modal/BillingOptionsModal';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import { ACTION_GROUPS_ENUM } from '../../../../../../shared/DataGridComponent/ActionsComponent/ActionsGroupEnum';
import { reloadSetBillingDataTabSelector, toggleReloadSetBillingDataTab } from '../../../../../../redux/billingSlice';

function ManageBillingOptions() {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_billing_setBillingData);
  const { customersList, loadingCustomers, fetchCustomers } = useCustomersFetch(apiService.getCustomerSearch);
  const [isLocationsModalOpen, setIsLocationsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customerId, setCustomerId] = useState('');
  const [customerLocation, setCustomerLocation] = useState('');
  const { addNotification } = useNotifications();
  const [customerLocations, setCustomerLocations] = useState<any[]>([]);
  const [selectedLocationRow, setSelectedLocationrow] = useState<any>();
  const [billingOptionsList, setBillingOptionsList] = useState<any[]>([]);
  const dispatch = useDispatch();
  const reloadSelector = useSelector(reloadSetBillingDataTabSelector);
  const [billingConditions, setBillingConditions] = useState<{
    customerId?: string,
    mergeAll: boolean,
    allCredits: boolean,
    separateInvoicePerContract: boolean,
    separateInvoicePerService: boolean,
    separateInvoicePerEquipment: boolean,
    vehicleMileageReport: boolean,
    tireMileageReport: boolean
  }>({
    customerId: '',
    mergeAll: false,
    allCredits: false,
    separateInvoicePerContract: false,
    separateInvoicePerService: false,
    separateInvoicePerEquipment: false,
    vehicleMileageReport: false,
    tireMileageReport: false,
  });

  const getLocations = async () => {
    setIsLoading(true);
    try {
      const locations = await apiService.getLocationsByCustomerId(customerId, null, null);
      setCustomerLocations(locations.data.data);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Locations data fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getBillingConditions = async () => {
    setIsLoading(true);
    try {
      const response = await apiService.billingAPI.getBillingConditions({ customerId });
      setBillingConditions(response.data);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Billing-conditions data fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const saveBillingConditions = async () => {
    setIsLoading(true);
    try {
      await apiService.billingAPI.setBillingConditions({ ...billingConditions, customerId });
      addNotification({
        text: 'Billing-conditions update success',
        type: MessageBarType.success,
      });
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Billing-conditions update error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getBillingOptionsList = async (pagination: any, sortOrder: any, filter: string) => {
    setIsLoading(true);
    try {
      const response = await apiService.billingAPI.getBillingOptionsList(pagination, sortOrder, filter, customerId, customerLocation);
      setBillingOptionsList(response.data.data);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Billing options data fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(false);
      if (reloadSelector) {
        dispatch(toggleReloadSetBillingDataTab());
      }
    }
  };

  const removeEmpty = ({ text }: any) => text;
  const sortResults = (a: any, b: any) => {
    return a.text.localeCompare(b.text);
  };

  const customerPrefixList = useMemo(() => customersList ?
    customersList.map(({ id, prefix }) => ({
      key: id,
      text: prefix,
    })).filter(removeEmpty).sort(sortResults) : [], [customersList]);


  const customerNameList = useMemo(() => customersList ?
    customersList.map(({ id, customerName }) => ({
      key: id,
      text: customerName,
    })).filter(removeEmpty).sort(sortResults) : [], [customersList]);


  const regionCodeList = useMemo(() => customersList ?
    customersList.map(({ id, regionCode }) => ({
      key: id,
      text: regionCode,
    })).filter(removeEmpty).sort(sortResults) : [], [customersList]);

  const locationCodeList = useMemo(() => customerLocations ?
    customerLocations.map(({ id, locationCode }) => ({
      key: id,
      text: locationCode,
    })).filter(removeEmpty).sort(sortResults) : [], [customerLocations]);

  const locationNameList = useMemo(() => customerLocations ?
    customerLocations.map(({ id, locationName }) => ({
      key: id,
      text: locationName,
    })).filter(removeEmpty).sort(sortResults) : [], [customerLocations]);

  const onCustomerChange = (newCustomer: any) => {
    setCustomerId(newCustomer);
  };

  const handleCustomerPrefixChange = debounce((custPrefix: string) => {
    fetchCustomers({ custPrefix });
  }, 500);

  const handleCustNameChange = debounce((custName: string) => {
    fetchCustomers({ custName }, undefined, { column: 'customerName', order: 'asc' });
  }, 500);

  const onChangeSearchLocation = (newLocation: any) =>
    setCustomerLocation(newLocation);

  const handleOpenLocationsModal = () => {
    setIsLocationsModalOpen(true);
  };

  const handleCloseLocationsModal = () => {
    setIsLocationsModalOpen(false);
  };

  const handleDataGridChange = debounce(async (data: any) => {
    const { sortOrder } = data;
    if (customerId !== '' || customerLocation !== '') {
      await getBillingOptionsList(null, sortOrder, '');
    }
  }, 500);

  useEffect(() => {
    if (customerId !== '') {
      getLocations();
      getBillingConditions();
      getBillingOptionsList(null, null, '');
    }
  }, [customerId]);

  useEffect(() => {
    if (customerLocation !== '') {
      getBillingOptionsList(null, null, '');
    }
  }, [customerLocation]);

  useEffect(() => {
    if (reloadSelector) {
      getBillingOptionsList(null, null, '');
    }
  }, [reloadSelector]);

  return (
    <div>
      <h1>Customer Details</h1>
      <SeparatorGy />
      <div style={{
        marginTop: '10px',
      }} className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm3">
            <AutoCompleteField
              onChangeAction={onCustomerChange}
              value={customerId}
              list={customerPrefixList}
              label="Customer Prefix"
              data-testid='customer-prefix'
              icon="FavoriteStar"
              textValue={handleCustomerPrefixChange}
            />
          </div>
          <div className="ms-Grid-col ms-sm3">
            <AutoCompleteField
              onChangeAction={onCustomerChange}
              value={customerId}
              list={customerNameList}
              label="Customer Name"
              data-testid='customer-name'
              icon="Contact"
              textValue={handleCustNameChange}
            />
          </div>
          <div className="ms-Grid-col ms-sm1">
            <div style={{
              height: '3rem',
            }}>
              <SeparatorGy vertical />
            </div>
          </div>
          <div className="ms-Grid-col ms-sm3">
            <AutoCompleteField
              onChangeAction={() => { null; }}
              value={customerId}
              list={regionCodeList}
              label="Region"
              data-testid='customer-region'
              disabled
              textValue={''}
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm3">
            <div style={{
              marginTop: '4.2rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
              <Checkbox
                checked={billingConditions.mergeAll}
                label="Merge with all mileage invoice"
                onChange={(event, isChecked) => {
                  setBillingConditions({
                    ...billingConditions,
                    mergeAll: isChecked ?? false,
                  });
                }}
              />
            </div>
          </div>
          <div className="ms-Grid-col ms-sm4">
            <div>
              <h3 style={{
                marginBlockEnd: '0',
              }}>Separate Invoice</h3>
              <SeparatorGy />
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
                <Checkbox
                  checked={billingConditions.separateInvoicePerContract}
                  label="Per contract"
                  onChange={(event, isChecked) => {
                    setBillingConditions({
                      ...billingConditions,
                      separateInvoicePerContract: isChecked ?? false,
                    });
                  }}
                />
                <Checkbox
                  checked={billingConditions.separateInvoicePerService}
                  label="Service"
                  onChange={(event, isChecked) => {
                    setBillingConditions({
                      ...billingConditions,
                      separateInvoicePerService: isChecked ?? false,
                    });
                  }}
                />
                <Checkbox
                  checked={billingConditions.separateInvoicePerEquipment}
                  label="Equipment"
                  onChange={(event, isChecked) => {
                    setBillingConditions({
                      ...billingConditions,
                      separateInvoicePerEquipment: isChecked ?? false,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="ms-Grid-col ms-sm3">
            <div>
              <h3 style={{
                marginBlockEnd: '0',
              }}>Mileage Report Includes</h3>
              <SeparatorGy />
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
                <Checkbox
                  checked={billingConditions.vehicleMileageReport}
                  label="Vehicle mileage report"
                  onChange={(event, isChecked) => {
                    setBillingConditions({
                      ...billingConditions,
                      vehicleMileageReport: isChecked ?? false,
                    });
                  }}
                />
                <Checkbox
                  checked={billingConditions.tireMileageReport}
                  label="Tire Mileage report"
                  onChange={(event, isChecked) => {
                    setBillingConditions({
                      ...billingConditions,
                      tireMileageReport: isChecked ?? false,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm3">
            <div style={{
              marginTop: '2.4rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
              <Checkbox
                label="All credits at the bottom"
                checked={billingConditions.allCredits}
                onChange={(event, isChecked) => {
                  setBillingConditions({
                    ...billingConditions,
                    allCredits: isChecked ?? false,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{
        marginTop: '16px',
      }}>
        <DataGridComponent
          enableResizeColumns
          idTable={'billingOptions'}
          title='Billing Options'
          headCells={billingOptionsHeadCell}
          rowsTable={billingOptionsList}
          totalDataFound={billingOptionsList.length}
          isLoading={isLoading}
          handleChangeDataGridState={handleDataGridChange}
          actionsGroupName={ACTION_GROUPS_ENUM.EDIT_MANAGE_BILLING_OPTIONS}
        />
      </div>
      <div style={{
        display: 'flex',
        marginTop: '1rem',
        flexDirection: 'row-reverse',
      }}>
        <DefaultButton text="Save" onClick={saveBillingConditions} />
      </div>
      <BillingOptionsModal
        isLocationsModalOpen={isLocationsModalOpen}
        customerLocations={customerLocations}
        setSelectedLocationrow={setSelectedLocationrow}
        handleCloseLocationsModal={handleCloseLocationsModal}
        selectedLocationRow={selectedLocationRow}
        enableButton={userPermissions.isWrite}
        setCustomerLocation={setCustomerLocation}
      />
    </div>
  );
};

export default ManageBillingOptions;
