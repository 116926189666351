import React, { FC, FunctionComponent, ReactElement } from 'react';
import classNames from 'classnames';
import { Pivot, PivotItem } from '@fluentui/react';
import { ITireOrderManagementTabProps } from './ITireOrderManagementTabProps';
import { tireOrderManagementTabs } from './consts';
import TabHeader from '../../../RecordKeeping/tabComponents/TabHeader/TabHeader';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { getSubTabsById, TIREORDER_TABS } from '../../consts';
import SeparatorGy from '../../../SeparatorGy/SeparatorGy';

const TireOrderManagementTab: FC<ITireOrderManagementTabProps> = (): ReactElement => {

  const [selectedKey, setSelectedKey] = React.useState(tireOrderManagementTabs.tireOrders.name);

  const { getPermissionsByAccessKey } = useUserPermissions();

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  const renderTab = (TabComponent: FunctionComponent) => {
    if (TabComponent) return <TabComponent />;
  };

  return (
    <div>
      <SeparatorGy />
      <Pivot
        className={classNames('pivot-container')}
        selectedKey={selectedKey}
        onLinkClick={handleLinkClick}
        getTabId={getTabId}
        linkFormat="tabs"
      >

        {Object.values(getSubTabsById(TIREORDER_TABS.TIREORDER_MANAGEMENT)).map(
          ({ accessKey, name, component }: any, index) =>
            getPermissionsByAccessKey(accessKey)?.isAccess && (
              <PivotItem headerText={name} key={index} itemKey={name}>
                <div className="">{renderTab(component)}</div>
              </PivotItem>
            ))}

      </Pivot>
    </div>
  );
};

export default TireOrderManagementTab;
