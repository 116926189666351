import { termTestInstance, exportTestInstance, billingTestInstance } from '../axios';
import { IBillingInspCode } from '../components/Terms/AvailableTerms/Components/BillInspCodes/IBillingInspCodes';

const getAvailableTerms = (pagination: any, sortOrder: any, filterText: string, customerId: string) =>
  termTestInstance.post('terms/list', { pagination, sortOrder, filterText, customerId });

const getContracts = (pagination: any, sortOrder: any, filterText: string, customerId: string, contractId: string) =>
  termTestInstance.post('terms/contracts', { pagination, sortOrder, filterText, customerId, contractId });

const getMiscOptions = (pagination: any, sortOrder: any, filterText: string, contractId: string) =>
  termTestInstance.post('/terms/misc-options/list', { pagination, sortOrder, filterText, contractId });

const getBillingOptions = (pagination: any, sortOrder: any, filterText: string, contractId: string) =>
  termTestInstance.post('/terms/billing-option/list', { pagination, sortOrder, filterText, contractId });

const getBillingInspCodesSort = (pagination: any, sortOrder: any, filterText: string, contractId: string) =>
  termTestInstance.post('/terms/billable-insp-codes/list', { pagination, sortOrder, filterText, contractId });

const getReportOptions = (pagination: any, sortOrder: any, filterText: string, contractId: string) =>
  termTestInstance.post('/terms/report-option/list', { pagination, sortOrder, filterText, contractId });

const getRateGroups = (pagination: any, sortOrder: any, filterText: string, customerId: string, contractId: string, quoteId: string, quoteVersion: string) =>
  termTestInstance.post('/terms/rates/list', { pagination, sortOrder, filterText, customerId, contractId, quoteId, quoteVersion });

const addRateGroups = (payload: any) =>
  termTestInstance.post('/terms/rates/add', payload);

const updateRateGroups = (rateGroups: any[], customerId: string) =>
  termTestInstance.put('/terms/rates/update', { rates: rateGroups, customerId });

const deleteRateGroups = (ids: any[]) =>
  termTestInstance.delete('/terms/rates/delete', { data: { ids } });

const getMakeModels = (pagination: any, sortOrder: any, filterText: string, customerId: string, contractId: string) =>
  termTestInstance.post('/terms/fleets/list', { pagination, sortOrder, filterText, customerId, contractId });

const addMakeModels = (payload: any) =>
  termTestInstance.post('/terms/fleet-forecast/make-models', payload);

const getRatesList = (payload: any) =>
  termTestInstance.post('/terms/all-rates/list', payload);

const updateMakeModels = (payload: any) =>
  termTestInstance.put('/terms/fleet-forecast/make-models/update', payload);

const deleteMakeModels = (ids: any[]) =>
  termTestInstance.delete('/terms/fleet-forecast/make-models/delete', { data: { ids } });

const getRateAdjustment = (pagination: any, sortOrder: any, filterText: string, contractId: string, customerId: string) =>
  termTestInstance.post('/terms/rate-adjustments/list', { pagination, sortOrder, filterText, contractId, customerId });

const deleteRateAdjustment = (contractId: string, rateAdjustment: any) =>
  termTestInstance.delete('/terms/rate-adjustments/delete', {
    data: {
      contractId,
      rateAdjustment,
    },
  });

const addRateAdjustment = (payload: any) =>
  termTestInstance.post('/terms/rate-adjustments/add', payload);

const getRates = (pagination: any, sortOrder: any, filterText: string, contractId: string, customerId: string) =>
  termTestInstance.post('/terms/rates/list', { pagination, sortOrder, filterText, contractId, customerId });

const updateRates = (contractId: string, rateAdjustments: Array<any>) =>
  termTestInstance.put('/terms/rate-adjustments/update', { contractId, rateAdjustments });

const getBillingBasis = (pagination: any, sortOrder: any, filterText: string, contractId: string) =>
  termTestInstance.post('/terms/billingBasis/list', { pagination, sortOrder, filterText, contractId });

const updateBillingBasis = (payload: {
  items: {
    contractId?: string,
    billingBasisId?: string,
    tireState?: string,
    origBilling?: string,
    origTread?: string,
    origQualifier?: string,
    origBasis1?: string,
    origBasis2?: string,
    rtrdBilling?: string,
    rtrdTread?: string,
    rtrdQualifier?: string,
    rtrdBasis1?: string,
    rtrdBasis2?: string,
    origAvgMonths?: string,
    rtrdAvgMonths?: string
  }[]
}) =>
  termTestInstance.put('/terms/billingBasis/update', payload);

const getBillingInspCodes = (contractId = '', pagination: any = {
  pageSize: 20,
  pageNumber: 1,
}, sortOrder: any[] = [
  {
    column: 'contractId',
    order: 'asc',
  },
], filterText = '') =>
  termTestInstance.post('/terms/billable-insp-codes/list', { pagination, sortOrder, filterText, contractId });

const updateBillingInspCodes = (payload: {
  items: IBillingInspCode[],
}) =>
  termTestInstance.put('/terms/billable-insp-codes/update', payload);

const updateBonusBilling = (payload: {
  contractId: string,
  bonusBilling: boolean,
  bonusBillingPeriod: string,
  nextBonusDue: string | null
}) =>
  termTestInstance.put('/terms/bonus-billing-update', payload);

const addTerm = (customerId: string) =>
  termTestInstance.post('/terms/general/add', { customerId });

const updateTermHistory = (body: { contractId: string, comments: string }) => {
  return termTestInstance.put('/terms/history', body);
};

const deleteTerm = (customerId: string, contractId: string) => {
  return termTestInstance.post(`/terms/general/delete/${customerId}`, { customerId, contractId });
};

const updateGoTo = (payload: any) =>
  termTestInstance.put('/terms/general/updateGoTo', payload);

const getRateAdjustmentsDetails = (contractId: string) =>
  termTestInstance.post('/terms/rate-adjustments/customer/details', { contractId });

const generateTerm = (contractId: string, mCbCustomerId: string, shellName: string) =>
  termTestInstance.post('/terms/contract-documents/generate', { contractId, mCbCustomerId, shellName });

const rateAdjustmentPrintExcel = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/terms/rate-adjustments/export-excel', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const rateAdjustmentPrintPdf = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/terms/rate-adjustments/export-pdf', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const miscPrintExcel = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/terms/misc-options/export-excel', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const miscPrintPdf = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/terms/misc-options/export-pdf', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const updateContractStatus = (bodyRequest: any) =>
  billingTestInstance.post('/contract-listing/update-status', bodyRequest);

const revertContractStatus = (contractId: string) =>
  billingTestInstance.post('/contract-listing/revert-status', { contractId });

const cloneMakeModels = (ids: any) =>
  termTestInstance.post('/terms/fleet-forecast/clone', { ids });

const getProducts = (pagination: any, sortOrder: any, fleetId: string, filterText?: string) =>
  termTestInstance.post('/terms/fleet/product/list', { pagination, sortOrder, fleetId, filterText });

const getProductCodes = (filters: any) =>
  termTestInstance.post('/terms/fleet/product-codes/list', { filters });

const addProduct = (payload: any) =>
  termTestInstance.post('/terms/fleet/product/add', payload);

const updateProduct = (payload: any) =>
  termTestInstance.put('/terms/fleet/product/update', payload);

const deleteProducts = (productIds: any[]) =>
  termTestInstance.delete('/terms/fleet/product/delete', { data: { productIds } });

const deleteAllProducts = (fleetId: string) =>
  termTestInstance.delete('/terms/fleet/product/delete-all', { data: { fleetId } });

const updateMiscOptions = (items: any[]) =>
  termTestInstance.put('/terms/misc-options/update', { items });

const updateBillingOptions = (items: any[]) =>
  termTestInstance.put('/terms/billing-option/update', { items });

const updateReportOptions = (items: any[]) =>
  termTestInstance.put('/terms/report-option/update', { items });

const getRateTypeList = () =>
  termTestInstance.get('/terms/misc-options/rate-types');

const getBillByToList = () =>
  termTestInstance.get('/terms/misc-options/bill-by-to');

const getGenOptList = () =>
  termTestInstance.get('/terms/misc-options/gen-opt');

const getBillingBasisDetails = (contractId: string) =>
  termTestInstance.post('/terms/billingBasis/customer/details', { contractId });

const updateBillingBasisDetails = (
  contractId: string,
  bonusBilling: boolean,
  bonusBillingPeriod: number,
  nextBonusDue: any,
) =>
  termTestInstance.put('/terms/billingBasis/customer/update', {
    contractId,
    bonusBilling,
    bonusBillingPeriod,
    nextBonusDue,
  });

const copyToAllBillingBasis = (billingBasisId: string, ids: Array<string>) =>
  termTestInstance.post('/terms/billingBasis/copy-to-all', { billingBasisId, ids });

const resetBillingBasis = (billingBasisId: string) =>
  termTestInstance.post('/terms/billingBasis/reset', { billingBasisId });

const updateDefaultRatesConfig = (payload: any) =>
  termTestInstance.put('/terms/rate-adjustments/customer/update', payload);

const printExcelContractListing = (input: any) =>
  exportTestInstance.post('/contract-listing/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfContractListing = (input: any) =>
  exportTestInstance.post('/contract-listing/export-pdf', input, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });
  
export default {
  getAvailableTerms,
  getMiscOptions,
  getBillingOptions,
  getReportOptions,
  getRateGroups,
  addRateGroups,
  deleteRateGroups,
  updateRateGroups,
  getMakeModels,
  addMakeModels,
  updateMakeModels,
  deleteMakeModels,
  getBillingInspCodes,
  updateBillingInspCodes,
  addTerm,
  updateTermHistory,
  deleteTerm,
  generateTerm,
  getRateAdjustment,
  getBillingBasis,
  rateAdjustmentPrintExcel,
  rateAdjustmentPrintPdf,
  miscPrintExcel,
  miscPrintPdf,
  getRates,
  getRatesList,
  deleteRateAdjustment,
  addRateAdjustment,
  updateBillingBasis,
  getBillingInspCodesSort,
  updateBonusBilling,
  getContracts,
  updateContractStatus,
  revertContractStatus,
  cloneMakeModels,
  updateRates,
  getProducts,
  getProductCodes,
  updateProduct,
  addProduct,
  deleteAllProducts,
  deleteProducts,
  getRateAdjustmentsDetails,
  updateMiscOptions,
  updateBillingOptions,
  updateReportOptions,
  getRateTypeList,
  getBillByToList,
  getGenOptList,
  updateGoTo,
  getBillingBasisDetails,
  updateBillingBasisDetails,
  copyToAllBillingBasis,
  resetBillingBasis,
  updateDefaultRatesConfig,
  printExcelContractListing,
  printPdfContractListing,

};
