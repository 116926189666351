import { IDetailsState } from './IDetailsState';
import { IFiltersState } from './IFiltersState';

export const pageSizes = [100, 250, 500];

export const productCodeColumns = [
  {
    key: 'column1',
    name: 'Product Code',
    fieldName: 'productCode',
  },
  {
    key: 'column2',
    name: 'Tire Size',
    fieldName: 'tireSize',
  },
  {
    key: 'column3',
    name: 'Description',
    fieldName: 'description',
  },
  {
    key: 'column4',
    name: 'Material Number',
    fieldName: 'materialNumber',
  },
  {
    key: 'column5',
    name: 'SpecNo',
    fieldName: 'specNo',
  },
  {
    key: 'column6',
    name: 'Orig/Rtrd',
    fieldName: 'origOrRtrd',
  },
  {
    key: 'column7',
    name: 'Radial Bias',
    fieldName: 'radialOrBias',
  },
  {
    key: 'column8',
    name: 'Ply Rating',
    fieldName: 'plyRating',
  },
];

export const typeCodeColumns = [
  {
    key: 'column0',
    name: 'Type Code',
    fieldName: 'typeCode',
  },
  ...productCodeColumns,
];

export const emptyFilters: IFiltersState = {
  country: 1,
  typeCode: '',
  productCode: '',
  tireSize: '',
};

export const emptyDetails: IDetailsState = {
  plant: null,
  country: 1,
  typeCode: '',
  productCode: null,
};
