import { DefaultButton, IconButton, MessageBarType, Modal, PrimaryButton, Text, TextField } from '@fluentui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import apiService from '../../api';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import useNotifications from '../../hooks/useNotifications';
import { IControlValue } from '../../models/IControlValue';
import DataGridComponent from '../DataGridComponent';
import EditControlValuesModalComponent from '../EditControlValuesModalComponent';
import { useBoolean } from '@fluentui/react-hooks';
import { FormBuilderGroup } from '../FormBuilderComponent';
import { controlValueHeadCells, CONTROL_VALUE_FIELDS } from './consts';
import styles from './MaintainControlValues.module.scss';


interface MaintainControlValuesModalComponentProps {
  id: string;
  isOpen: boolean;
  onDismiss: () => void;
  cancelLabel: string;
}
 
const MaintainControlValuesModalComponent: FunctionComponent<MaintainControlValuesModalComponentProps> = ({
  id,
  isOpen, 
  onDismiss, 
  cancelLabel,
}) => {

  const { addNotification } = useNotifications();
  const [valueList, setValueList] = useState<IControlValue[]>([]);
  const [totalFound, setTotalFound] = useState(0);
  const [valueSelected, setValueSelected] = useState<IControlValue | null>(null);

  const [valueListPagination, setValueListPagination] = useState<any>({ pageSize: 100, pageNumber: 1 });
  const [valueListSortOrder, setValueListSortOrder] = useState<any>({ column: controlValueHeadCells[0].fieldName, order: 'asc' });

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isLoadingTable, setLoadingTable] = useState<boolean>(false);

  const [openEditControlValuesModal, { toggle: toggleOpenEditControlValuesModal }] = useBoolean(false);
  const controlValueFieldsGroup = new FormBuilderGroup(CONTROL_VALUE_FIELDS);

  const fetchData = async(
    pagination: any = valueListPagination, 
    filters?: any, 
    sortOrder: any = valueListSortOrder,
  ) => {
    setLoadingTable(true);
    try {
      const {data}: any = await apiService.sections.getMaintainLookupValues(
        pagination,
        sortOrder, 
        filters, 
      );
      setValueList(data?.data);
      setValueSelected(null);
      setTotalFound(data?.total?.found);
      setValueListPagination(pagination);
      setValueListSortOrder(sortOrder);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text:`Fetching Maintain Control Values error: ${response?.data?.message}`, 
        type: MessageBarType.error,
      });
    } finally {
      setLoadingTable(false);
    }    
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    const {countOnPage, paginationProps, searchedText, sortOrder} = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString : searchedText,
    };
    await fetchData(pagination, filters, sortOrder);
  };

  const handleSelectRow = (rowsSelected: any) => {
    setValueSelected(rowsSelected[0]);
  };

  const onUpdateControlValue = async () => {
    setLoading(true);
    try {
      await apiService.sections.updateMaintainLookupValues({
        sections: [{
          ...valueSelected,
          name: controlValueFieldsGroup.getFieldFormValue('nameField'),
          description: controlValueFieldsGroup.getFieldFormValue('descriptionField'),
        }],
      });
      fetchData();
      addNotification({
        text: 'Maintain Control Value was successfully updated.',
        type: MessageBarType.success,
      });
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Maintain Control Value updating error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteValue = async (rowsToDelete: Array<IControlValue> = []) => {
    setLoading(true); 
    try {
      await apiService.sections.deleteMaintainLookupValues(rowsToDelete.map(el => el.id));
      addNotification({
        text: 'Maintain Control Value(s) were successfully deleted.',
        type: MessageBarType.success,
      });
      fetchData();
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Maintain Control Values(s) deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (valueSelected) {
      controlValueFieldsGroup.setFormValue('nameField', valueSelected?.name);
      controlValueFieldsGroup.setFormValue('descriptionField', valueSelected?.description);
    }
    else {
      controlValueFieldsGroup.cleanFormData();
    }
  }, [valueSelected]);

  return (  
    <>
      <Modal
        key={id}
        isOpen={isOpen}
        onDismiss={onDismiss}
        containerClassName={styles.modalContainer}
      >
        <div className='modalHeader'>
          <Text variant='xLarge'>Maintain Control Values</Text>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel='Close popup modal'
            onClick={onDismiss}
          />
        </div>
        <div className={styles.formContainer}>
          <TextField {...controlValueFieldsGroup.getFieldForm('nameField')} />
          <TextField {...controlValueFieldsGroup.getFieldForm('descriptionField')} />
          <PrimaryButton
            id='editBtn'
            onClick={onUpdateControlValue}
            disabled={!valueSelected}
            text='Edit Control'
          />
        </div>
        <div className='tableContainer'>
          <DataGridComponent
            idTable={'lookup-table'}
            title='Available Lookup Names'
            headCells={controlValueHeadCells}
            rowsTable={valueList}
            totalDataFound={totalFound}
            isLoading={isLoadingTable}
            enableCheckBox
            enableSearching
            enablePagination
            enableRowsPerPage
            enableDeleteOption
            handleChangeDataGridState={handleChangeDataGridState}
            handleSelectRow={handleSelectRow}
            handleDelete={handleDeleteValue}
          />
        </div>    
        <div className={styles.modalFooter}>
          <PrimaryButton
            id='editValuesBtn'
            text='Edit Values'
            onClick={toggleOpenEditControlValuesModal}
            disabled={!valueSelected}
          />
          <DefaultButton
            id='cancelButton'
            text={cancelLabel}
            onClick={onDismiss}
          />
        </div>
        <div>
          {isLoading && <LoadingScreen />}
        </div>
      </Modal>
      <EditControlValuesModalComponent 
        id='editControlValuesModal'
        isOpen={openEditControlValuesModal}
        onDismiss={toggleOpenEditControlValuesModal}
        cancelLabel='Exit'
        controlValue={valueSelected}
      />
    </>
  );
};
 
export default MaintainControlValuesModalComponent;