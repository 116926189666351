import moment from 'moment';
import { HeadCell } from '../../../../shared/DataGridComponent/DataGridModels';
import { IInvoiceDetails, ILineItem } from './ICreateInvoicesState';

export const pageSizes = [5, 10, 20];

export const availableLineItemsHeadCells: HeadCell[] = [
  {
    key: 'column0',
    name: 'QTY',
    fieldName: 'qty',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'MB_Code',
    fieldName: 'mbCode',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Total',
    fieldName: 'total',
    typeField: 'currency',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Description',
    fieldName: 'description',
    typeField: 'text',
    isEditable: false,
  },
];

export const locationsColumns = [
  {
    key: 'column1',
    name: 'Ship to Number',
    fieldName: 'shipToNumber',
  },
  {
    key: 'column2',
    name: 'Location Code',
    fieldName: 'locationCode',
  },
  {
    key: 'column3',
    name: 'Address Line 1',
    fieldName: 'addrLine1',
  },
  {
    key: 'column4',
    name: 'City',
    fieldName: 'city',
  },
  {
    key: 'column5',
    name: 'State',
    fieldName: 'state',
  },
  {
    key: 'column6',
    name: 'Country',
    fieldName: 'country',
  },
];

export const invoiceTypes = ['CR', 'DB'];

export const defaultLineItem: ILineItem = {
  qty: '',
  wheelPosition: '',
  radialOrBias: '',
  mbCode: '',
  unitPrice: '',
  total: '',
  cost: '',
  description: '',
};

export const defaultInvoiceDetails: IInvoiceDetails = {
  date: moment().format('MM/DD/YYYY'),
  additionalDetails1: '',
  type: '',
  debitOrCredit: '',
};