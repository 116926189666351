import { Checkbox, MessageBarType } from '@fluentui/react';
import classNames from 'classnames';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import apiService from '../../../../../api';
import useNotifications from '../../../../../hooks/useNotifications';
import DataGridComponent from '../../../../../shared/DataGridComponent';
import { ISorting } from '../../../../../shared/DataGridComponent/DataGridModels';
import { defaultDataGridPageSizes } from '../../../../../shared/DataGridComponent/utils';
import { columns, defaultSorting } from './consts';
import { IOOSAvgsProps } from './IOOSAvgsProps';
import { IOOSAvgsState } from './IOOSAvgsState';

const OOSAvgs: FC<IOOSAvgsProps> = () => {
  const [state, setState] = useState<IOOSAvgsState>({
    items: [],
    foundCount: 0,
    loading: false,
  });

  const { addNotification } = useNotifications();
  const { contractId } = useParams<{ contractId: string }>();

  const fetchOOSAvgs = async (
    pagination: any = { pageSize: defaultDataGridPageSizes[0], pageNumber: 1 },
    searchedText: any = '',
    sortOrder: ISorting,
  ) => {
    try {
      const { data }: any = await apiService.oosAvgs.getOOSAvgs(
        pagination,
        sortOrder,
        searchedText,
        contractId,
      );
      const foundCount = data.total.found;
      setState(prev => ({ ...prev, items: data.data, foundCount }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `OOS Avgs fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    setState(prev => ({ ...prev, loading: true })); const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    await fetchOOSAvgs(pagination, searchedText, sortOrder);
    setState(prev => ({ ...prev, loading: false }));
  };

  return (
    <div data-testid='OOS Avgs' className="ms-Grid margin-left-rigth-2 marginTop18" dir='ltr'>
      <div className='ms-Grid-row'>
        <div className={classNames('ms-Grid-col', 'ms-sm12')}>
          <Checkbox
            label="Show All Avgs"
          />
        </div>
      </div>
      <div className='ms-Grid-row'>
        <div className={classNames('ms-Grid-col', 'ms-sm12')}>
          <DataGridComponent
            idTable={'add-modal-table'}
            title='OOS Avgs'
            headCells={columns}
            rowsTable={state.items}
            totalDataFound={state.foundCount}
            defaultSorting={defaultSorting}
            enableSearching
            enablePagination
            enableRowsPerPage
            enableMultiSorting
            isLoading={state.loading}
            handleChangeDataGridState={handleChangeDataGridState}
          />
        </div>
      </div>
    </div>
  );
};

export default OOSAvgs;
