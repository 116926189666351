export const pageSizes = [5, 10, 20];

export const columns = [
  {
    key: 'column1',
    name: 'Role',
    fieldName: 'name',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Description',
    fieldName: 'description',
    isSorted: false,
    isSortedDescending: false,
  },
];
