import { DefaultButton, Dropdown, IColumn, IconButton, IDropdownOption, IIconProps, MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import classNames from 'classnames';
import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import LoadingScreen from '../../../../../../../LoadingScreen/LoadingScreen';
import PrintingModal from '../../../../../../../PrintingModal/PrintingModal';
import SeparatorGy from '../../../../../../../SeparatorGy/SeparatorGy';
import { columns } from './consts';
import { pageSizes } from '../../../../../../../../consts/recordKeeping';
import { IRecordAsLostModalProps } from './IRecordAsLostModalProps';
import { IRecordAsLostModalState } from './IRecordAsLostModalState';
import styles from './RecordAsLost.module.scss';
import { useBoolean } from '@fluentui/react-hooks';
import apiService from '../../../../../../../../api';
import useNotifications from '../../../../../../../../hooks/useNotifications';
import { useSelector } from 'react-redux';
import { customerSelector, locationSelector } from '../../../../../../../../redux/recordKeepingSlice';
import Pagination from '../../../../../../../Pagination/Pagination';
import { IPaginationProps } from '../../../../../../../Pagination/IPaginationProps';
import { sortOrder } from '../../../../../../../../consts/sortOrder';
import moment from 'moment';
import { downloadFile, printingTypes } from '../../../../../../../PrintingModal/consts';

const RecordAsLostModal: FC<IRecordAsLostModalProps> = ({
  onDismiss,
  period,
  locationIds,
  postedElement,
}): ReactElement => {
  const [state, setState] = useState<IRecordAsLostModalState>({
    loading: false,
    items: [],
    foundCount: 0,
    fixed: 0,
  });

  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);

  const [initialReconciliationList, setReconciliationList] = useState<Array<any>>([]);

  const [isInvoicedByTD, setInvoicedByTD] = useState<boolean>(false);

  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const closeIcon: IIconProps = { iconName: 'Cancel' };

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return [{
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    }];
  };

  const fetchReconciliationDetails = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.spareStock.getReconciliationDetails(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        { reconcileType: 'LOST', period, customerId, locationIds },
        sortOrder,
      );

      const { data: reconciliationData }: any = await apiService.spareStock.getReconciliationDetails(
        { pageNumber: paginationProps.current, pageSize: 500 },
        { reconcileType: 'LOST', period, customerId, locationIds },
        sortOrder,
      );

      const foundCount = data.total.all;
      const fixed = data.fixed;
      const items = data.data.map(
        ({ priOff, ...others }: any) => ({
          priOff: moment(priOff).format('MM/DD/YYYY h:mm:ss A'),
          ...others,
        }),
      );

      await getContractDetails();
      setReconciliationList(reconciliationData?.data);
      setState((prev: any) => ({ ...prev, items, foundCount, fixed }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      addNotification({
        text: `Posted Spare Stocks fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleUpdateStatus = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.spareStock.updateReconciliationReportStatus({
        customerId,
        locationId,
        postedSSKId: postedElement?.id,
        takenOnDate: postedElement?.invtTakenOn,
      });
      await apiService.spareStock.updateReconciliationDetails({
        reconciliationDetailsList: state.items,
        isRecordAsLost: true,
      });
      addNotification({
        text: 'Status was successfully updated.',
        type: MessageBarType.success,
      });
      await fetchReconciliationDetails();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Status updating error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
    finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleRelease = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.spareStock.releaseRecordAsLost({
        customerId,
        locationId: locationIds[0],
        postedSSKId: postedElement?.id,
        takenOnDate: postedElement?.invtTakenOn,
      });
      addNotification({
        text: 'Tires were successfully released.',
        type: MessageBarType.success,
      });
      await fetchReconciliationDetails();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Releasing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
    finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const setItemToBeEdited = (reconcileTireId: string, field: string, value: any) => {
    setState((prev: any) => ({
      ...prev,
      items: prev.items.map((item: any) => item.reconcileTireId === reconcileTireId ? { ...item, [field]: value } : item),
    }));
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const requestData = {
        paginstion: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        filters: { reconcileType: 'LOST', period, customerId, locationIds },
        sortOrder: sortOrder,
      };
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.spareStock.printExcelRecordAsLost(requestData, []) :
        await apiService.spareStock.printPdfRecordAsLost(requestData, []);
      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Printing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };


  const getContractDetails = async () => {
    try {
      const { data: { data: contractList } }: any = await apiService.terms.getAvailableTerms(
        { pageNumber: 1, pageSize: 100 },
        null,
        '',
        customerId,
      );
      const activeContract = contractList?.length && contractList?.filter((contract: any) => contract.contractStatus === 'ACTIVE')[0];
      if (activeContract) {
        const { data: { data: treadDepthList } }: any = await apiService.treadDepth.getTreadDepth(
          null,
          null,
          '',
          activeContract?.contractId,
        );
        if (treadDepthList.length) {
          setInvoicedByTD(true);
        }
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `${response ?
          `Contract Fetch Error: ${response.data.message}` :
          'An error has occured when fetching contract details'}`,
        type: MessageBarType.error,
      });

    } 
  };


  const isColumnEditable = (column: any, item: any) => {
    if (column?.isEditable) {
      if (column?.fieldName === 'td') {
        if (isInvoicedByTD && (item.fixed === null || item.fixed === undefined || item.fixed === '')) {
          return true;
        } else {
          return false;
        }
      }
      return column?.isEditable;
    }
  };
  const validateTDValues = () => {
    const intiialEmptyTDList: any = initialReconciliationList?.length && initialReconciliationList.filter((item: any) => (item.td === null || item.td === '') && (item.fixed === undefined || item.fixed === null || item.fixed === ''));
    const emptyTDList: any = state.items?.length && state.items.filter((item: any) => (item.td === null || item.td === '') && (item.fixed === undefined || item.fixed === null || item.fixed === ''));

    if (isInvoicedByTD) {
      if (emptyTDList?.length > 0 || intiialEmptyTDList.length > 0) {
        return true;
      }
    }

    return false;

  };

  const validationMessage = () => {
    const filledTDList = state.items?.length && state.items.filter((item: any) => item.td !== null && item.td !== '');
    if (filledTDList.length) {
      return 'Please Update Status to Continue';
    } else return 'Please fill out all required Tread Depth Values then Update Status.';
  };

  useEffect(() => {
    fetchReconciliationDetails();
  }, [paginationProps.current, countOnPage, columnsState]);

  const renderTable = () => {
    return (
      <>
        <div className={styles['table-wrapper']}>
          <table>
            <thead>
              <tr>
                {
                  columnsState.map(item => (
                    <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {
                state.items.map((item: any) => (
                  <tr key={item.reconcileTireId} className={styles.trBasic}>
                    {
                      columnsState.map((column) => (
                        isColumnEditable(column, item) ?
                          <td>
                            <TextField
                              id={column.fieldName}
                              styles={{ fieldGroup: { border: '1px solid transparent' } }}
                              value={item[column.fieldName]}
                              onChange={(e, i) => setItemToBeEdited(item.reconcileTireId, column.fieldName, i)}
                              required={isInvoicedByTD && column.fieldName === 'td'}
                            />
                          </td>
                          :
                          <td>
                            {item[column.fieldName]}
                          </td>
                      ))
                    }
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <SeparatorGy />
        <Pagination {...paginationProps} />
      </>
    );
  };

  return (
    <>
      <div className={classNames(styles.detailsWrapper)}>
        <div className={classNames(styles.detailsBackground)}>
          <div
            className={classNames(styles.detailsContainer)}
            ref={containerRef}
          >
            <div className={classNames(styles.detailsHeader)}>
              <Text variant="xxLarge" className={styles.highlight}>Reconciliation Report - Record As Lost{isInvoicedByTD && ' - Invoiced by Tread Depth'}</Text>
              <IconButton
                id="closeButton"
                iconProps={closeIcon}
                title="close"
                onClick={() => onDismiss()}
              />
            </div>
            <div className={classNames(styles.detailsBody)}>
              <div className={styles.tableHeading}>
                <div>
                  <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
                </div>
                <div>
                  <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                  <Dropdown
                    options={pageSizes.map(pageSize => ({
                      key: pageSize,
                      text: pageSize.toString(),
                    }))}
                    defaultSelectedKey={pageSizes[0]}
                    selectedKey={countOnPage?.key}
                    onChange={onChangeCountOnPage}
                  />
                  <SeparatorGy vertical />
                  <Text variant="xLarge" className={styles.highlight}>{state.fixed} fixed</Text>
                </div>
              </div>
              {renderTable()}
            </div>
            {validateTDValues() && (<div className={styles.detailsWarning}>
              {validationMessage()}
            </div>)}
            <div className={classNames(styles.detailsFooter)}>
              <DefaultButton id="printButton" onClick={toggleShowPrintExport} text="Print/Export" />
              <PrimaryButton id="updateButton" onClick={handleUpdateStatus} text="Update Status" />
              <PrimaryButton id="releaseButton" disabled={validateTDValues()} onClick={handleRelease} text="Release" />
            </div>
            <PrintingModal
              isOpened={showPrintExport}
              onClose={toggleShowPrintExport}
              onPrint={handlePrint}
            />
          </div>
        </div>
      </div>
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default RecordAsLostModal;