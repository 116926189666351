import { FunctionComponent, useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import styles from './Switch.module.scss';

interface SwitchComponentProps {
  id: string;
  initialCheck?: boolean;
  onChange: (property: any, isChecked: boolean) => void;
}
 
const SwitchComponent: FunctionComponent<SwitchComponentProps> = ({
  id,
  initialCheck = false,
  onChange,
}) => {

  const [isChecked, setIsChecked] = useState(initialCheck);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
    onChange(id, !isChecked);
  };

  useEffect(() => {
    setIsChecked(initialCheck);
  }, [initialCheck]);

  return ( 
    <>
      <input
        checked={isChecked}
        onChange={handleOnChange}
        className={styles.reactSwitchCheckbox}
        id={`react-switch-${id}`}
        type='checkbox'
      />
      <label
        style={{ background: isChecked ? '#114375' : '' }}
        className={styles.reactSwitchLabel}
        htmlFor={`react-switch-${id}`}
      >
        <span className={styles.reactSwitchButton} />
      </label>
    </>
  );
};
 
export default SwitchComponent;