import { exportTestInstance, termTestInstance } from '../axios';

const getTreadDepth = (pagination: any, sortOrder: any, filterText: string, contractId: string) =>
  termTestInstance.post('/terms/tread-depth/list', { pagination, sortOrder, filterText, contractId });

const printExcel = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/terms/tread-depth/export-excel', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/terms/tread-depth/export-pdf', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const getTreadDepthIntervals = (pagination: any, sortOrder: any, filterText: string, contractId: string, productCode: string) =>
  termTestInstance.post('terms/tread-depth/intervals/list', { pagination, sortOrder, filterText, contractId, productCode });

const addTreadDepthInterval = (contractId: string, productCode: string) =>
  termTestInstance.put('terms/tread-depth/intervals/add', { contractId, productCode });

const updateTreadDepthInterval = (intervalsToUpd: string) =>
  termTestInstance.post('/terms/tread-depth/intervals/update', { data: intervalsToUpd });

const deleteTreadDepthInterval = (id: string) =>
  termTestInstance.delete('terms/tread-depth/intervals/delete', { data: { id } });

const updateTreads = (treadsToUpd: string) =>
  termTestInstance.post('/terms/tread-depth/update', { data: treadsToUpd });

export default {
  getTreadDepth,
  printExcel,
  printPdf,
  getTreadDepthIntervals,
  addTreadDepthInterval,
  updateTreadDepthInterval,
  deleteTreadDepthInterval,
  updateTreads,
};