import { HeadCell } from '../../../../../../shared/DataGridComponent/DataGridModels';
import { IFormDataField } from '../../../../../../shared/FormBuilderComponent';

export const pageSizes = [500, 750, 1000];

export const TEXT_FIELDS: Array<IFormDataField> = [  
  {
    formFieldName: 'prefixField',
    properties: {
      id: 'prefix',
      label: 'Prefix',
      name: 'prefix',
      type: 'text',
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'brandNoField',
    properties: {
      id: 'brandno',
      label: 'Brand Number',
      name: 'brandNo',
      type: 'text',
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'suffixField',
    properties: {
      id: 'suffix',
      label: 'Suffix',
      name: 'suffix',
      type: 'text',
      textTransform: 'uppercase',
    },
  },
];

export const massDetailsHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Pfx',
    fieldName: 'pfx',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Brand',
    fieldName: 'bno',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Sfx',
    fieldName: 'sfx',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'TC',
    fieldName: 'tc',
    typeField: 'text',
  },
  {
    key: 'column4',
    name: 'Size',
    fieldName: 'tireSize',
    typeField: 'text',
  },
  {
    key: 'column5',
    name: 'DOT#',
    fieldName: 'dotNo',
    typeField: 'text',
  },
  {
    key: 'column6',
    name: 'Miles',
    fieldName: 'miles',
    typeField: 'text',
  },
  {
    key: 'column7',
    name: 'Veh',
    fieldName: 'vehicleNo',
    typeField: 'text',
  },
  {
    key: 'column8',
    name: 'Pos',
    fieldName: 'wheelPosition',
    typeField: 'text',
  },
  {
    key: 'column9',
    name: 'Date',
    fieldName: 'detailDate',
    typeField: 'date',
  },
  {
    key: 'column10',
    name: 'Loc',
    fieldName: 'location',
    typeField: 'text',
  },
  {
    key: 'column11',
    name: 'Oos St',
    fieldName: 'oosSt',
    typeField: 'text',
  },
  {
    key: 'column12',
    name: 'Oos Doc',
    fieldName: 'oosDoc',
    typeField: 'text',
  },
  {
    key: 'column13',
    name: 'Doc Date',
    fieldName: 'docDate',
    typeField: 'date',
  },
  {
    key: 'column14',
    name: 'A',
    fieldName: 'a',
    typeField: 'text',
  },
  {
    key: 'column15',
    name: 'B',
    fieldName: 'b',
    typeField: 'text',
  },
  {
    key: 'column16',
    name: 'D',
    fieldName: 'd',
    typeField: 'text',
  },
  {
    key: 'column17',
    name: 'TD',
    fieldName: 'tireTD',
    typeField: 'text',
  },
];