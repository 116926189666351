export const emptyTireToBeAdded = {
  pfx: '',
  bno: '',
  sfx: '',
};

export const columns = [
  {
    key: 'column1',
    name: 'Pfx',
    fieldName: 'pfx',
    isEditable: true,
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Bno',
    fieldName: 'bno',
    isEditable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'Sfx',
    fieldName: 'sfx',
    isEditable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'OOS State',
    fieldName: 'oosState',
    isEditable: false,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'OOS Date',
    fieldName: 'oosDate',
    isEditable: false,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column6',
    name: 'Doc #',
    fieldName: 'doc',
    isEditable: false,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column7',
    name: 'Billed',
    fieldName: 'billed',
    isEditable: false,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column8',
    name: 'Accountable',
    fieldName: 'accountable',
    isEditable: false,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column9',
    name: 'Credit Greater Debit',
    fieldName: 'creditGreaterDebit',
    isEditable: false,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column10',
    name: 'Submit',
    fieldName: 'submit',
    isEditable: false,
    isSorted: false,
    isSortedDescending: false,
  },
];
