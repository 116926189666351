import { DatePicker, MessageBarType, TextField } from '@fluentui/react';
import classNames from 'classnames';
import { FunctionComponent, useEffect, useState } from 'react';
import apiService from '../../api';
import { useField } from '../../hooks/useField';
import { DEL_NUM_FIELDS, IOrderData } from './const';
import moment from 'moment';
import { INotification } from '../../models/INotification';
import useNotifications from '../../hooks/useNotifications';

interface DelNumComponentProps {
  orderCode: any,
}
 
const DelNumComponent: FunctionComponent<DelNumComponentProps> = ({ orderCode }) => {

  // hooks
  const { addNotification } = useNotifications();
  
  // states
  const [orderData, setOrderData] = useState<IOrderData>({});
  const [notification, setNotification] = useState<INotification>({text: '', type: 0});

  // formControls
  const orderNumberField = useField(DEL_NUM_FIELDS.orderNumberDataField);
  const dateShippedField = useField(DEL_NUM_FIELDS.dateShippedDataField);
  const invoiceNumberField = useField(DEL_NUM_FIELDS.invoiceNumberDataField);
  const dlNumberField = useField(DEL_NUM_FIELDS.dlDataField);  

  const fetchDelNum = async () => {
    try {
      const { data: {data} } = await apiService.inTransitTiresAPI.getDelNumList(orderCode.id);
      setOrderData(data[0]);
    } catch (error) {
      setNotification({text:'Fetching delNum error', type: MessageBarType.error});        
    }
  };

  useEffect(() => {
    if (orderCode) fetchDelNum();
  }, [orderCode]);

  useEffect(() => {    
    if (notification?.text){
      addNotification({... notification});
      setNotification({text:'', type: 0}); 
    }      
  }, [notification]);
 

  return ( 
    <>
      <div className='ms-Grid-row'>
        <div className={classNames('ms-Grid-col')}>
          <TextField {...orderNumberField} value={orderData?.orderId}/>
        </div>
        <div className={classNames('ms-Grid-col')}>
          <DatePicker
            {...dateShippedField}
            value={orderData?.dataShipped ? moment(orderData.dataShipped).toDate() : undefined}
            formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
          />
        </div>
        <div className={classNames('ms-Grid-col')}>
          <TextField {...invoiceNumberField} value={orderData?.invcNo}/>
        </div>
        <div className={classNames('ms-Grid-col')}>
          <TextField {...dlNumberField} value={orderData?.gdyrDelNo}/>
        </div>                
      </div>
    </>
  );
};
 
export default DelNumComponent;
