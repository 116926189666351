import { exportTestInstance, recordTestInstance } from '../axios';

const getVehicleMiles = (filters: any, pagination: any, sortOrder: any, locationId: number, customerId: number) =>
  recordTestInstance.post('/vehicle-miles', { filters, pagination, sortOrder, locationId, customerId });

const getVehicleMilesByVehicle = (billingPeriodId: string, locationId: string, customerId: string) =>
  recordTestInstance.post('/vehicle-miles/by-vehicle', { billingPeriodId, locationId, customerId });

const save = (data: Array<any>, customerId: string, locationId: string) =>
  recordTestInstance.post('/vehicle-miles/save', { data, customerId, locationId });

const submit = (ids: Array<any>, billingPeriodId: string, customerId: string, locationId: string) =>
  recordTestInstance.post('/vehicle-miles/submit', { ids, billingPeriodId, customerId, locationId });

const deleteVehicleMiles = (ids: Array<any>, customerId: string, locationId: string ) =>
  recordTestInstance.delete('/vehicle-miles', { data: { ids, customerId, locationId } });

const release = (billingPeriodId: string, customerId: string, locationId: string) =>
  recordTestInstance.post('/vehicle-miles/release', { billingPeriodId, customerId, locationId });  

const getErrors = (pagination: any, sortOrder: any, customerId: number, locationId: number) =>
  recordTestInstance.post('/vehicle-miles/error', {pagination, sortOrder, customerId, locationId});

const printExcel = (data: any, headerFields?: Array<any>) =>
  exportTestInstance.post('/vehicle-miles/export-excel', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (data: any, headerFields?: Array<any>) =>
  exportTestInstance.post('/vehicle-miles/export-pdf', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  getVehicleMiles,
  getVehicleMilesByVehicle,
  save,
  submit,
  delete: deleteVehicleMiles,
  release,
  getErrors,
  printExcel,
  printPdf,
};