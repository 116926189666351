import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { IconButton, MessageBarType, Text } from '@fluentui/react';
import DataGridComponent from '../../../../../shared/DataGridComponent';
import { logsHeadCell } from './consts';
import { ILogsModalProps } from './ILogsModalProps';

import styles from './LogsModal.module.scss';
import { useBoolean } from '@fluentui/react-hooks';
import apiService from '../../../../../api';
import useNotifications from '../../../../../hooks/useNotifications';
import { defaultDataGridPageSizes } from '../../../../../shared/DataGridComponent/utils';


const LogsModal: FC<ILogsModalProps> = ({ isOpened, onClose, customerId, regionCode, country, ids, invoiceType, currentYear, creditOnly, duringTheMonth, releaseAll, byViewSelection}) => {
  const { addNotification } = useNotifications();
  const [reports, setReports] = useState<any[]>([]);
  const [totalFound, setTotalFound] = useState(0);
  const [isLoading, { toggle: toggleShowLoading }] = useBoolean(false);

  useEffect(() => {
    if (isOpened) {
      setReports([]);
      fetchLogs();
    }
  }, [isOpened]);

  const fetchLogs = async (
  ) => {
    toggleShowLoading();
    try {
      const { data: { total, data } }: any = await apiService.billingAPI.bulkReleaseResults(
        customerId,
        regionCode,
        country,
        ids,
        invoiceType,
        currentYear,
        creditOnly,
        duringTheMonth,
        releaseAll,
        byViewSelection,
        { pageSize: defaultDataGridPageSizes[0], pageNumber: 1 },
        { column: logsHeadCell[0].fieldName, order: 'asc' },
      );
      setReports(data);
      setTotalFound(total.found);
    } catch (e: any) {
      addNotification({
        text: 'Fetching error of availabe logs',
        type: MessageBarType.error,
      });
    }
    toggleShowLoading();
  };

  return (
    <div className={classNames(styles.root, { [styles.closed]: !isOpened })}>
      <div className={classNames('ms-Grid', styles.window)}>
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm11')}>
            <Text variant="xLarge" className={styles.highlight}>Available Logs</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm1')}>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel="Close popup modal"
              onClick={onClose}
            />
          </div>
        </div>
        <div className={styles.detailsRow}>
          <div className={styles.rowWrapper}>
            <DataGridComponent
              idTable={'available-logs'}
              title='Logs'
              headCells={logsHeadCell}
              rowsTable={reports}
              totalDataFound={totalFound}
              enablePagination
              enableSorting
              enableRowsPerPage
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogsModal;
