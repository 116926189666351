import { Dropdown, IDropdownOption, TextField } from '@fluentui/react';
import classNames from 'classnames';
import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import { formatter } from '../../utils';
import ActionsComponent from '../ActionsComponent';
import { ACTION_GROUPS_ENUM } from '../ActionsComponent/ActionsGroupEnum';
import styles from '../DataGrid.module.scss';
import { HeadCell } from '../DataGridModels';
import { DATA_INPUT_TYPES, transformTextObj } from '../utils';


interface RowComponentProps {
  parentTableId: string;
  idxRow: any;
  rowData: any;
  headCells: Array<HeadCell>;
  rowsData: any;
  actionsGroupName: ACTION_GROUPS_ENUM | null;
  enableEditableRows?: boolean;
  enableCheckBox?: boolean;
  enableRowClick?: boolean;
  isItemSelected?: boolean;
  handleRowClick?: (rowSelected: any) => void;
  handleChangeSelectRow: (e: any, row: any) => void;
  handleUpdate: (rowUpdated: any, idx: number) => void;
  setIsFocused: any;
  isFieldFocused: string[] | string;
  setRowFocused: any;
  rowFocused: number;
  isSearchFoucused?: boolean;
  enableLeftCheckbox?: boolean;
}

const RowComponent: FunctionComponent<RowComponentProps> = ({
  parentTableId,
  idxRow,
  rowData,
  headCells,
  actionsGroupName = null,
  enableCheckBox = false,
  isItemSelected = false,
  enableRowClick = false,
  handleChangeSelectRow,
  handleUpdate,
  handleRowClick,
  setIsFocused,
  isFieldFocused,
  setRowFocused,
  rowFocused,
  isSearchFoucused = false,
  enableLeftCheckbox = false,
}) => {

  const [row, setRow] = useState(rowData);
  const [rowUpdated, setRowUpdated] = useState(rowData);

  const getRandomKey = () => (Math.random() + 1).toString(36).substring(7);

  const renderSelectRow = (keyRow: any, isItemSelected: boolean, row: any) => enableCheckBox && (
    <td>
      <div className={styles.round}>
        <input
          id={`row-${keyRow}-${parentTableId}`}
          name={`row-${parentTableId}`}
          type='checkbox'
          checked={isItemSelected}
          onChange={(e) => handleChangeSelectRow(e, row)}
        />
        <label htmlFor={`row-${keyRow}-${parentTableId}`}></label>
      </div>
    </td>
  );

  const renderActionsList = () => actionsGroupName && (
    <td><ActionsComponent idxRow={idxRow} row={row} actionsGroupName={actionsGroupName} /></td>
  );

  const transformTypeValue = (value: any, type: string) => {
    if (type == 'number') return +value;
    return value;
  };

  const onChangeInput = ({ target }: any) => {
    const { name, value, type } = target;
    const currentRow = { ...rowUpdated, [name]: transformTypeValue(value, type) };
    setRowUpdated(currentRow);
    handleUpdate(currentRow, idxRow);
  };

  const onChangeSelectableInput = (event: any, option?: IDropdownOption<any> | undefined, index?: number | undefined): void => {
    const { target: { id } } = event;
    const currentRow = { ...rowUpdated, [id]: option?.key };
    setRowUpdated(currentRow);
    handleUpdate(currentRow, idxRow);
  };

  const onHandleRowClick = () => {
    if (enableRowClick && handleRowClick) {
      handleRowClick(row);
    }
  };

  const buildRowContent = (typeOf: DATA_INPUT_TYPES, value: any) =>
    transformTextObj[typeOf](value);

  const buildDropdownCell = (headCell: HeadCell, isRequired?: boolean) =>
    <Dropdown
      id={headCell.fieldName}
      placeholder='Select...'
      selectedKey={rowUpdated[headCell.fieldName]}
      onChange={onChangeSelectableInput}
      options={headCell.selectableOptions || []}
    />;

  const buildEditableCell = (headCell: HeadCell, isRequired?: boolean) =>
    <TextField
      autoFocus={headCell.fieldName === isFieldFocused && idxRow === rowFocused && !isSearchFoucused}
      onFocus={() => {
        setIsFocused(headCell.fieldName);
        setRowFocused(idxRow);
      }}
      className={classNames(isRequired ? styles.requiredField : styles.editableField)}
      name={headCell.fieldName}
      value={rowUpdated[headCell.fieldName]}
      onChange={onChangeInput}
      type={headCell.typeField}
    />;

  useEffect(() => {
    setRow(rowData);
  }, [rowData]);

  return (
    <>
      <tr
        onDoubleClick={onHandleRowClick} className={isItemSelected ? styles.trSelected : styles.trBasic} key={`${parentTableId}-${idxRow}`}>
        {enableLeftCheckbox && renderSelectRow(getRandomKey(), isItemSelected, row)}
        {renderActionsList()}
        {headCells.map((headCell: HeadCell, idx: any) => {
          const isRequired = headCell.isMandatory && !rowUpdated[headCell.fieldName];
          return headCell.isShowing && (
            <td
              key={idx}
            >
              <div style={{
                minHeight: actionsGroupName === 'editManageBillingOptions' ? '60px' : 0,
              }}>{
                  headCell?.isEditable ?
                    headCell.selectableOptions ?
                      buildDropdownCell(headCell, isRequired) :
                      buildEditableCell(headCell, isRequired)
                    : buildRowContent(headCell.typeField, row[headCell.fieldName])
                }
              </div>
            </td>
          );
        })}
        {!enableLeftCheckbox && renderSelectRow(getRandomKey(), isItemSelected, row)}
      </tr>
    </>
  );
};

export default RowComponent;