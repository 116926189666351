import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import {
  Checkbox,
  DefaultButton,
  ChoiceGroup,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IColumn,
  Icon,
  IconButton,
  IDropdownOption,
  MaskedTextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, isNil, isEqual } from 'lodash';
import AutocompleteInput from '../../../../../../shared/AutocompleteInput';
import apiService from '../../../../../../api';
import useNotifications from '../../../../../../hooks/useNotifications';
import { customerSelector, locationSelector, setTireForReinstate } from '../../../../../../redux/recordKeepingSlice';
import { userSelector } from '../../../../../../redux/userSlice';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../../Pagination/Pagination';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';
import { ITransferTiresTabState } from './ITransferTiresTabState';
import { ITransferTiresTabProps } from './ITransferTiresTabProps';
import { emptyTransferTires, transferTo, selectBy, transferTiresColumns, locationsColumns, BRAND, VEHICLE, CUSTOMER, LOCATION } from './consts';
import { pageSizes } from '../../../../../../consts/recordKeeping';
import styles from './TransferTiresTab.module.scss';
import SelectingModal from '../../../../../SelectingModal/SelectingModal';
import ErrorsModal from '../../../../../ErrorsModal/ErrorsModal';
import moment from 'moment';
import ViewTireDetailsModal from '../../../../ViewTireDetailsModal/ViewTireDetailsModal';
import { AVAILABLE, LOCKED, TRASFER_TIRES_CODE, TRASFER_TIRES_ID } from '../../../../../../consts/modulesCodes';
import { sortOrder } from '../../../../../../consts/sortOrder';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_tireDisposition_transferTires } from '../../../../../../consts/programKeys';
import useKeyPress from '../../../../../../hooks/useKeyPress/useKeyPress';

const TransferTiresTab = (props: ITransferTiresTabProps) => {
  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = columnsState.map(el => ({ ...el }));
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        if (!currColumn.isSorted) currColumn.isSortedDescending = true;
        if (currColumn.isSortedDescending && currColumn.isSorted) {
          currColumn.isSorted = false;
          currColumn.isSortedDescending = true;
        }
        else {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        }
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const dispatch = useDispatch();
  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);
  const { email: userEmail, displayName: userName } = useSelector(userSelector);

  const [state, setState] = useState<ITransferTiresTabState>({
    items: [],
    selectedItems: [],
    loading: false,
    foundCount: 0,
  });

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });

  const [selectedCustomer, setSelectedCustomer] = useState<any>('');
  const [customersList, setCustomersList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState<any>('');
  const [locationsList, setLocationsList] = useState([]);
  const [showLocationsModal, { toggle: toggleShowLocationsModal }] = useBoolean(false);
  const [selectedVehicleNumber, setSelectedVehicleNumber] = useState<any>('');
  const [vehicleNumbersList, setVehicleNumbersList] = useState([]);
  const [selectedKeyTransferTo, setSelectedKeyTransferTo] = useState<string | undefined>(transferTo[0].key);
  const [selectedKeySelectBy, setSelectedKeySelectBy] = useState<string | undefined>(selectBy[0].key);
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(transferTiresColumns);
  const [untouchedItems, setUntouchedItems] = useState<Array<any>>([]);
  const [detailsVisible, { toggle: toggleDetailsVisible }] = useBoolean(false);
  const [hideAddDialog, { toggle: toggleAddDialog }] = useBoolean(true);
  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [showErrorsModal, { toggle: toggleShowErrorsModal }] = useBoolean(false);
  const [tireToBeAdded, setTireToBeAdded] = useState<any>(emptyTransferTires);
  const [parsedAddingErrors, setParsedAddingErrors] = useState<any>([]);
  const [locked, setLocked] = useState<any>({});
  const [isRelocateTiresChecked, setIsRelocateTiresChecked] = useState(false);
  const [transferToCustomer, setTransferToCustomer] = useState(false);
  const [transferByBrand, setTransferByBrand] = useState(false);

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_tireDisposition_transferTires);

  const removeEmpty = ({ text }: any) => !!text;
  
  const customerPrefixList = useMemo(() => customersList ?
    customersList.map(({ id, prefix }) => ({
      key: id,
      text: prefix,
    })).filter(removeEmpty) : [], [customersList]);

  const customerNameList = useMemo(() => customersList ?
    customersList.map(({ id, customerName }) => ({
      key: id,
      text: customerName,
    })).filter(removeEmpty) : [], [customersList]);

  const mountingProcess = async () => {
    await fetchCustomers({});
  };

  useEffect(() => {
    blockAPI(LOCKED);
    mountingProcess();
    return () => {
      blockAPI(AVAILABLE);
    };
  }, []);

  useEffect(() => {
    if (selectedCustomer !== '') {
      setSelectedLocation('');
      onChangeField('newLocationId', '');
      fetchLocations();
    }
  }, [selectedCustomer]);

  useEffect(() => {
    setSelectedCustomer('');
    onChangeField('newCustomerId', null);
    setSelectedLocation('');
    onChangeField('newLocationId', '');
    if (selectedKeyTransferTo === LOCATION) {
      setTransferToCustomer(false);
      fetchLocations();
      onChangeField('relocateTires', false);
    }
    else {
      setTransferToCustomer(true);
      onChangeField('relocateTires', false);
    }
  }, [selectedKeyTransferTo]);

  useEffect(() => {
    
    if (selectedKeySelectBy === VEHICLE) {
      setTransferByBrand(false);
      onChangeField('fromBrand', null);
      onChangeField('toBrand', null);
    } else {
      setTransferByBrand(true);
      onChangeInnerField('fromBrand', 'pfx', '');
      onChangeInnerField('fromBrand', 'bno', '');
      onChangeInnerField('fromBrand', 'sfx', null);
      onChangeInnerField('toBrand', 'pfx', '');
      onChangeInnerField('toBrand', 'bno', '');
      onChangeInnerField('toBrand', 'sfx', null);
    }
    setSelectedVehicleNumber('');
    setVehicleNumbersList([]);
    onChangeField('vehicleNumber', '');
  }, [selectedKeySelectBy]);

  const onChangeField = (field: any, value: any) => {
    setTireToBeAdded((prev: any) => ({ ...prev, [field]: value }));
    if (field === 'relocateTires') {
      setIsRelocateTiresChecked(value);
    }
  };

  const onChangeInnerField = (outerField: any, innerField: any, value: any, regExp?: RegExp) => {
    setTireToBeAdded((prev: any) => ({ ...prev, [outerField]: { ...prev[outerField], [innerField]: regExp ? (regExp.test(value) ? value : outerField[innerField]) : value } }));
  };

  const onChangeSearchCustomer = (newCustomerId: any) => {
    setSelectedCustomer(newCustomerId);
    onChangeField('newCustomerId', newCustomerId);
  };

  const onChangeSearchLocation = (newLocationId: any) => {
    if (selectedKeyTransferTo === LOCATION) {
      onChangeField('newCustomerId', null);
    }
    setSelectedLocation(newLocationId);
    onChangeField('newLocationId', newLocationId);
  };

  const onChangeSearchVehicleNumber = (newVehicle: any) => {
    setSelectedVehicleNumber(newVehicle);
    onChangeField('vehicleNumber', newVehicle);
  };

  const vehicleNumberInputText = (vehicleNuber: string) => {
    const vehicleNuberWithoutSpace = vehicleNuber.replace(/ /g, '');
    if (vehicleNuberWithoutSpace.length > 0) {
      getVehicles(vehicleNuberWithoutSpace);
    }
  };

  const getVehicles = debounce(async (venucleNumber) => {
    fetchVehicles(venucleNumber);
  }, 1000);

  const blockAPI = async (status: string) => {
    try {
      // setState(prev => ({ ...prev, loading: true }));
      const { data } = await apiService.blockAPI(TRASFER_TIRES_ID, TRASFER_TIRES_CODE, userEmail, userName, status, customerId, locationId);
      setLocked(data);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Customers fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    }
    // } finally {
    //   setState(prev => ({ ...prev, loading: false }));
    // }
  };

  const fetchCustomers = async (filter: any, sort: any = null) => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data }: any = await apiService.getCustomerList(filter, { pageSize: 50, pageNumber: 1 }, sort);
      setCustomersList(data.data);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Customers fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const customerPrefixInputText = (custPrefix: string) => {
    if (custPrefix.replace(/ /g, '').length) {
      getCustomers({custPrefix});
    } else {
      getCustomers(custPrefix);
    }
  };

  const customerNameInputText = (custName: string) => {
    if (custName.replace(/ /g, '').length) {
      getCustomers({custName});
    } else {
      getCustomers(custName);
    }
  };

  const getCustomers = debounce(async (customerData) => {
    if (customerData.custPrefix) {
      fetchCustomers({ ...customerData, fdelete: false }, { column: 'custPrefix', order: 'asc' });
    } else {
      fetchCustomers({ ...customerData, fdelete: false });
    }
  }, 1000);

  const fetchLocations = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      if (selectedKeyTransferTo === CUSTOMER) {
        const { data }: any = await apiService.getLocationsByCustomerId(
          selectedCustomer,
          { pageSize: 5000, pageNumber: 1 },
          {},
        );
        setLocationsList(data.data);
      } else {
        const { data }: any = await apiService.getLocationsByCustomerId(
          customerId,
          { pageSize: 5000, pageNumber: 1 },
          {},
        );
        setLocationsList(data.data);
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Locations fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchVehicles = async (venucleNumber: string) => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data }: any = await apiService.getActiveInactiveVehicleNumbers(venucleNumber, customerId);
      setVehicleNumbersList(data.data);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Locations fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const getSortOrder = () => {
    const column = columnsState.find(({ isSorted }) => isSorted);
    if (isNil(column))
      return {
        column: 'createdOn',
        order: sortOrder.ASC,
      };
    const { fieldName, isSortedDescending } = column;
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const fetchTransferTires = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.transferTires.get(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
      );
      const foundCount = data.total.found;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      addNotification({
        text: `Transfer Tires fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },       
        sortOrder,
        customerId,
        locationId,
      };
  
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.transferTires.printExcel(requestData) :
        await apiService.transferTires.printPdf(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const handleAddTire: any = async () => {
    if(selectedKeyTransferTo === CUSTOMER && isRelocateTiresChecked === false) {
      toggleAddDialog();
    }
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.transferTires.add(
        {
          ...tireToBeAdded,
          newCustomerId: selectedCustomer || customerId,
        },
        customerId,
        locationId,
        transferToCustomer,
        transferByBrand,
      );
      setParsedAddingErrors([]);
      if (transferByBrand === true) {
        setTireToBeAdded({ ...tireToBeAdded, fromBrand: { ...tireToBeAdded.fromBrand, bno: '' }, toBrand: { ...tireToBeAdded.toBrand, bno: '' } });
      }
      else {
        setTireToBeAdded({ ...tireToBeAdded, fromBrand: null, toBrand: null });
      }
      await fetchTransferTires();
      addNotification({
        text: 'Tire was successfully added.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          setParsedAddingErrors(response.data.state);
          if (response.data.state) {
            return addNotification({
              text: `Adding form error. ${response.data.state[0].message}`,
              type: MessageBarType.error,
            });
          } else {
            return addNotification({
              text: `Adding form error. ${response.data.desc.message}`,
              type: MessageBarType.error,
            });
          }
        default:
          return addNotification({
            text: `Adding form error. ${response.data.desc.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  useKeyPress({
    handleAdd: handleAddTire,
  });


  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.transferTires.delete(state.selectedItems);
      addNotification({
        text: 'Selected tire(s) were successfully deleted.',
        type: MessageBarType.success,
      });
      if (paginationProps.current === 1) {
        await fetchTransferTires();
      } else {
        await paginationProps.onChangePage(1);
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Transfer Tires deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.transferTires.submit(state.items.map(el => ({...el, xferCustomerId: el.xferCustomerId || customerId})));
      setUntouchedItems(state.items);
      addNotification({
        text: 'Transfer tires were successfully submitted.',
        type: MessageBarType.success,
      });
      if (paginationProps.current === 1) {
        await fetchTransferTires();
      } else {
        await paginationProps.onChangePage(1);
      }
    } catch (e: any) {
      const { response } = e;
      setState(prev => ({ ...prev, loading: false }));
      switch (response.status) {
        case 400:
          if (paginationProps.current === 1) {
            await fetchTransferTires();
          } else {
            await paginationProps.onChangePage(1);
          }
          return addNotification({
            text: `Transfer tires error. ${response.data.desc.message}`,
            type: MessageBarType.error,
          });
        default:
          return addNotification({
            text: response.data.message,
            type: MessageBarType.error,
          });
      }
    }
  };

  const openViewTireDetailsWindow = (item: any) => {
    const { consignedTireId, prefix, brandNumber, suffix } = item;
    dispatch(setTireForReinstate({ tireId: consignedTireId, pfx: prefix, bno: brandNumber, sfx: suffix }));
    toggleDetailsVisible();
  };

  const handleSelect = (e: any, itemId: any) => {
    if (e.target.checked) {
      setState((prev: any) => ({ ...prev, selectedItems: [...state.selectedItems, itemId] }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: state.selectedItems.filter(row => row !== itemId) }));
    }
  };

  const handleSelectAll = (e: any, items: Array<any>) => {
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedItems: items })) : setState((prev: any) => ({ ...prev, selectedItems: [] }));
  };

  const parseAddingErrors = (field: string) => {
    const customError = parsedAddingErrors?.filter((error: { field: string; }) => error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  const validateFromBandNumber = parseInt(tireToBeAdded.fromBrand?.bno) && parseInt(tireToBeAdded.fromBrand?.bno) <= 0;
  const validateToBrandNumber = parseInt(tireToBeAdded.fromBrand?.bno) > parseInt(tireToBeAdded.toBrand?.bno);

  const setLocation = (locationId: any) => {
    onChangeSearchLocation(locationId);
    toggleShowLocationsModal();
  };

  const isItemUntouched = (itemToCheck: any) => {
    const untouchedItem = untouchedItems.find(({ id }: any) => id === itemToCheck.id);
    if (isNil(untouchedItem))
      return false;
    return isEqual(itemToCheck, untouchedItem);
  };

  const clearFields = () => {
    setTireToBeAdded(emptyTransferTires);
    setSelectedCustomer('');
    setSelectedLocation('');
  };

  const isDisabled = () => {
    const customerFilled = !tireToBeAdded?.newCustomerId || !tireToBeAdded?.newLocationId;
    const brandFilled = !tireToBeAdded?.fromBrand?.pfx || !tireToBeAdded?.fromBrand?.bno;
    if (selectedKeyTransferTo === CUSTOMER && selectedKeySelectBy === BRAND) {
      if (customerFilled || !tireToBeAdded?.docDate || !tireToBeAdded?.docNum || brandFilled) {
        return true;
      }
    }
    if (selectedKeyTransferTo === LOCATION && selectedKeySelectBy === BRAND) {
      if (!tireToBeAdded?.newLocationId || !tireToBeAdded?.docDate || !tireToBeAdded?.docNum || brandFilled) {
        return true;
      }
    }
    if (selectedKeyTransferTo === CUSTOMER && selectedKeySelectBy === VEHICLE) {
      if (customerFilled || !tireToBeAdded?.docDate || !tireToBeAdded?.docNum || !tireToBeAdded?.vehicleNumber) {
        return true;
      }
    }
    if (selectedKeyTransferTo === LOCATION && selectedKeySelectBy === VEHICLE) {
      if (!tireToBeAdded?.newLocationId || !tireToBeAdded?.docDate || !tireToBeAdded?.docNum || !tireToBeAdded?.vehicleNumber) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    fetchTransferTires();
  }, [
    paginationProps.current,
    countOnPage,
    columnsState,
    customerId,
    locationId,
  ]);

  return (
    <>
      {
        !locked.isLocked ?
          <div className="ms-Grid">
            <div className={classNames(styles.headingBlock, styles.tiresAddingBlock, 'ms-Grid-row')}>
              <div className={classNames('ms-Grid-col', 'ms-sm1')}>
                <Checkbox
                  label="Relocate Tires"
                  checked={tireToBeAdded.relocateTires}
                  onChange={(e, relocateTires) => onChangeField('relocateTires', relocateTires)}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <ChoiceGroup defaultSelectedKey={selectedKeyTransferTo} options={transferTo} onChange={(e, option) => setSelectedKeyTransferTo(option?.key)} label="Transfer to" />
                {
                  selectedKeyTransferTo === CUSTOMER &&
                            <>
                              <div className={classNames(styles.alignInline)}>
                                <AutocompleteInput
                                  label="Customer Prefix"
                                  value={selectedCustomer}
                                  list={customerPrefixList}
                                  chooseCurrentItem={onChangeSearchCustomer}
                                  errorMessage={parseAddingErrors('newCustomerId')}
                                  required
                                  emptyExpanded
                                  textValue={customerPrefixInputText}
                                />
                                <AutocompleteInput
                                  label="Customer Name"
                                  value={selectedCustomer}
                                  list={customerNameList}
                                  chooseCurrentItem={onChangeSearchCustomer}
                                  errorMessage={parseAddingErrors('newCustomerId')}
                                  required
                                  emptyExpanded
                                  textValue={customerNameInputText}
                                />
                              </div>
                              <div className={classNames(styles.alignInline)}>
                                <AutocompleteInput
                                  label="Location Code"
                                  value={selectedLocation}
                                  list={
                                    locationsList.map(({ id, locationCode }) => ({
                                      key: id,
                                      text: locationCode,
                                    }))
                                  }
                                  chooseCurrentItem={onChangeSearchLocation}
                                  errorMessage={parseAddingErrors('newLocationId')}
                                  required
                                  emptyExpanded
                                  disabled={!selectedCustomer}
                                />
                                <AutocompleteInput
                                  label="Location"
                                  value={selectedLocation}
                                  list={
                                    locationsList.map(({ id, locationName }) => ({
                                      key: id,
                                      text: locationName,
                                    }))
                                  }
                                  chooseCurrentItem={onChangeSearchLocation}
                                  errorMessage={parseAddingErrors('newLocationId')}
                                  required
                                  emptyExpanded
                                  disabled={!selectedCustomer}
                                />
                                <IconButton
                                  id="searchLocation"
                                  iconProps={{ iconName: 'Search' }}
                                  onClick={toggleShowLocationsModal}
                                />
                              </div>
                            </>
                }
                {
                  selectedKeyTransferTo === LOCATION &&
                            <div className={classNames(styles.alignInline)}>
                              <AutocompleteInput
                                label="Location Code"
                                value={selectedLocation}
                                list={
                                  locationsList.map(({ id, locationCode }) => ({
                                    key: id,
                                    text: locationCode,
                                  }))
                                }
                                chooseCurrentItem={onChangeSearchLocation}
                                errorMessage={parseAddingErrors('locationCode')}
                                required
                                emptyExpanded
                              />
                              <AutocompleteInput
                                label="Location"
                                value={selectedLocation}
                                list={
                                  locationsList.map(({ id, locationName }) => ({
                                    key: id,
                                    text: locationName,
                                  }))
                                }
                                chooseCurrentItem={onChangeSearchLocation}
                                errorMessage={parseAddingErrors('location')}
                                required
                                emptyExpanded
                              />
                              <IconButton
                                id="searchLocation"
                                iconProps={{ iconName: 'Search' }}
                                onClick={toggleShowLocationsModal}
                              />
                            </div>
                }
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm2')}>
                <MaskedTextField
                  id="docDate"
                  mask="99/99/9999"
                  label="Doc Date"
                  value={tireToBeAdded.docDate}
                  onChange={(e, docDate) => onChangeField('docDate', docDate)}
                  onRenderPrefix={() => <Icon iconName="Calendar" />}
                  errorMessage={parseAddingErrors('docDate')}
                  required
                />
                <div>
                  <TextField
                    id="docNum"
                    label="Doc #"
                    prefix="#"
                    value={tireToBeAdded.docNum}
                    onChange={(e, docNum) => onChangeField('docNum', docNum)}
                    errorMessage={parseAddingErrors('docNum')}
                    required
                    maxLength={10}
                  />
                </div>
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm5')}>
                <ChoiceGroup defaultSelectedKey={selectedKeySelectBy} options={selectBy} onChange={(e, option) => setSelectedKeySelectBy(option?.key)} label="Select by" />
                {
                  selectedKeySelectBy === BRAND &&
                  <>
                    <div className={classNames(styles.alignInline)}>
                      <TextField
                        label="Prefix"
                        maxLength={4}
                        value={tireToBeAdded.fromBrand?.pfx}
                        onChange={(e, pfx) => { onChangeInnerField('fromBrand', 'pfx', pfx?.toUpperCase(), /^[a-zA-Z0-9]*$/); onChangeInnerField('toBrand', 'pfx', pfx?.toUpperCase(), /^[a-zA-Z0-9]*$/); }}
                        errorMessage={parseAddingErrors('fromBrand.pfx')}
                        required
                      />
                      <TextField
                        label="From Brand Number"
                        maxLength={6}
                        value={tireToBeAdded.fromBrand?.bno}
                        onChange={(e, bno) => onChangeInnerField('fromBrand', 'bno', bno, /^[0-9]*$/)}
                        errorMessage={validateFromBandNumber ? 'Should be positive' : ''}
                        required
                      />
                      <TextField
                        label="Suffix"
                        maxLength={5}
                        value={tireToBeAdded.fromBrand?.sfx}
                        onChange={(e, sfx) => { onChangeInnerField('fromBrand', 'sfx', sfx?.toUpperCase(), /^[A-Z0-9]*$/); onChangeInnerField('toBrand', 'sfx', sfx?.toUpperCase(), /^[a-zA-Z0-9]*$/); }}
                        errorMessage={parseAddingErrors('fromBrand.sfx')}
                      />
                    </div>
                    <div className={classNames(styles.alignInline)}>
                      <TextField
                        label="Prefix"
                        maxLength={4}
                        value={tireToBeAdded.toBrand?.pfx?.toUpperCase()}
                        errorMessage={parseAddingErrors('toBrand.pfx')}
                        disabled
                      />
                      <TextField
                        label="To Brand Number"
                        maxLength={6}
                        value={tireToBeAdded.toBrand?.bno}
                        onChange={(e, bno) => onChangeInnerField('toBrand', 'bno', bno, /^[0-9]*$/)}
                        errorMessage={validateToBrandNumber ? 'Should be more than From Brand Number' : ''}
                      />
                      <TextField
                        label="Suffix"
                        maxLength={5}
                        value={tireToBeAdded.toBrand?.sfx?.toUpperCase()}
                        errorMessage={parseAddingErrors('toBrand.sfx')}
                        disabled
                      />
                    </div>
                  </>
                }
                {
                  selectedKeySelectBy === VEHICLE &&
                  <div className={classNames(styles.alignInline)}>
                    <AutocompleteInput
                      chooseCurrentItem={onChangeSearchVehicleNumber}
                      label="Vehicle Number"
                      value={selectedVehicleNumber}
                      list={
                        vehicleNumbersList.map((vehicle: any) => ({
                          key: vehicle.number,
                          text: vehicle.name,
                        }))
                      }
                      errorMessage={parseAddingErrors('vehicleNumber')}
                      required
                      emptyExpanded
                      textValue={vehicleNumberInputText}
                    />
                  </div>
                }
              </div>
              <div className={classNames(styles.columns, 'ms-Grid-col', 'ms-sm1')}>
                <Dialog
                  hidden={hideAddDialog}
                  onDismiss={toggleAddDialog}
                  dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Confirmation',
                    subText: 'WARNING- you are about to change the Tire Class to 4 (Used Tire Transfer). Click OK to proceed.',
                  }}
                  modalProps={{ isBlocking: true }}
                >
                  <DialogFooter>
                    <PrimaryButton id="addButton" onClick={handleAddTire} text="OK" />
                    <DefaultButton onClick={toggleAddDialog} text="Cancel" />
                  </DialogFooter>
                </Dialog>
                <PrimaryButton
                  id="addButton"
                  disabled={isDisabled() || !userPermissions.isWrite}
                  onClick={selectedKeyTransferTo === CUSTOMER && isRelocateTiresChecked === false ? toggleAddDialog : () => handleAddTire()}
                  text="Add"
                />
                <DefaultButton
                  onClick={() => clearFields()}
                  text="Clear"
                  disabled={!userPermissions.isWrite}
                />
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <div className={styles.tableHeading}>
                  <div>
                    <Text variant="xLarge" className={styles.highlight}>Tires Transfered</Text>
                    <SeparatorGy vertical />
                    <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
                  </div>
                  <div>
                    <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                    <Dropdown
                      options={pageSizes.map(pageSize => ({
                        key: pageSize,
                        text: pageSize.toString(),
                      }))}
                      defaultSelectedKey={pageSizes[0]}
                      selectedKey={countOnPage?.key}
                      onChange={onChangeCountOnPage}
                    />
                    <SeparatorGy vertical />
                    <Text variant="large" className={styles.highlight}>{state.selectedItems.length} items selected</Text>
                    <SeparatorGy vertical />
                    <IconButton
                      id="toggleDeletingConfirmationButton"
                      disabled={!state.selectedItems.length || !userPermissions.isWrite}
                      iconProps={{ iconName: 'Delete' }}
                      onClick={toggleDeletingConfirmation}
                    />
                  </div>
                </div>
                <div className={styles['table-wrapper']}>
                  <table className={styles.table}>
                    <thead>
                      <tr>
                        <th></th>
                        {
                          columnsState.map(item => (
                            <th
                              key={item.name}
                              className={
                                item.isSorted && item.isSortedDescending ?
                                  styles.descending :
                                  item.isSorted && !item.isSortedDescending ? styles.ascending : undefined
                              }
                              onClick={() => onColumnClick(item)}
                            >
                              {item.name}
                            </th>
                          ))
                        }
                        <th></th>
                        <th>
                          <div className={styles.round}>
                            <input
                              type="checkbox"
                              id="all"
                              checked={state.items.length !== 0 && (state.selectedItems.length === +countOnPage.key || state.selectedItems.length === state.items.length)}
                              onChange={(e) => handleSelectAll(e, state.items.map(({ id }) => id))}
                            />
                            <label htmlFor="all"></label>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        state.items.map(item => (
                          <tr key={item.id}
                            className={
                              classNames(
                                styles.trBasic,
                                {
                                  [styles.trSelected]: state.selectedItems.includes(item.id),
                                },
                              )
                            }
                          >
                            <td>
                              <IconButton
                                id="viewButton"
                                iconProps={{ iconName: 'View' }}
                                onClick={() => openViewTireDetailsWindow(item)}
                              />
                            </td>
                            <td>{item.prefix}</td>
                            <td>{item.brandNumber}</td>
                            <td>{item.suffix}</td>
                            <td>{item.changeDate && moment(item.changeDate).format('MM/DD/YYYY')}</td>
                            <td>{item.cot}</td>
                            <td>{item.veh}</td>
                            <td>{item.position}</td>
                            <td>{item.accMiles}</td>
                            <td>{item.lastMileageRun && moment(item.lastMileageRun).format('MM/DD/YYYY')}</td>
                            <td>{item.status}</td>
                            <td>{item.incDate && moment(item.incDate).format('MM/DD/YYYY')}</td>
                            <td>{item.incDoc}</td>
                            <td>{item.atLocation}</td>
                            <td>
                              {
                                isItemUntouched(item) ?
                                  <MessageBar
                                    messageBarType={MessageBarType.success}
                                    isMultiline={false}
                                  >
                                    Submitted
                                  </MessageBar> :
                                  <MessageBar
                                    messageBarType={MessageBarType.warning}
                                    isMultiline={false}
                                  >
                                    Unsubmitted
                                  </MessageBar>
                              }
                            </td>
                            <td>
                              <div className={styles.round}>
                                <input type="checkbox" id={item.id} checked={state.selectedItems.includes(item.id)} onChange={(e) => handleSelect(e, item.id)} />
                                <label htmlFor={item.id}></label>
                              </div>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
                <SeparatorGy />
                <Pagination {...paginationProps} />
              </div>
            </div>
            <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
              <DefaultButton 
                id='printExportBtn' 
                onClick={toggleShowPrintExport}
                text="Print/Export" 
              />
              <DefaultButton 
                onClick={toggleShowErrorsModal}
                text="View Errors" 
              />
              <PrimaryButton 
                onClick={handleSubmit} 
                disabled={!userPermissions.isWrite}
                text="Transfer" 
              />
            </div>
          </div>
          : <>
            <div className={styles.columns}>
              <Text variant='xxLarge' className={styles.highlight}>{locked?.lockedBy}</Text>
            </div>
            <div className={styles.columns}>
              <Text variant='xLarge' className={styles.highlight}>{locked?.message}</Text>
            </div>
          </>
      }
      <SelectingModal
        isOpen={showLocationsModal}
        title="Available Locations"
        selectingList={locationsList}
        onDismiss={toggleShowLocationsModal}
        onSubmit={setLocation}
        columns={locationsColumns}
        preselectedKey={selectedLocation}
      />
      <ErrorsModal
        isModalOpen={showErrorsModal}
        hideModal={toggleShowErrorsModal}
        fetchErrorsService={apiService.transferTires.getErrors}
      />
      {detailsVisible && <ViewTireDetailsModal isOpened={detailsVisible} onDismiss={toggleDetailsVisible} />}
      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to delete ${state.selectedItems.length} items?`,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default TransferTiresTab;
