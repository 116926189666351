import React, { FC, ReactElement, useEffect } from 'react';

import styles from './ActionOnTiresTab.module.scss';

import classNames from 'classnames';

import { Pivot, PivotItem } from '@fluentui/react';

import TabHeader from '../TabHeader/TabHeader';
import { IActionOnTiresTabProps } from './ActionOnTiresTabProps';
import { actionOnTiresTabs } from '../../../../consts/recordKeeping';
import ViewListingTab from './tabComponents/ViewListing/ViewListingTab';
import ReinstateTab from './tabComponents/Reinstate/ReinstateTab';
import ViewTireDetailsTab from './tabComponents/ViewTireDetailsTab/ViewTireDetailsTab';
import RebrandTab from './tabComponents/Rebrand/RebrandTab';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { auth_actionOnTires_rebrand,
  auth_actionOnTires_reinstate,
  auth_actionOnTires_viewTireDetails,
  auth_actionOnTires_listing } from '../../../../consts/programKeys';
import { useDispatch, useSelector } from 'react-redux';
import { activeSubTabSelector, setActiveSubTab } from '../../../../redux/recordKeepingSlice';

const ActionOnTiresTab: FC<IActionOnTiresTabProps> = (): ReactElement => {

  const dispatch = useDispatch();

  const [selectedKey, setSelectedKey] = React.useState('rectangleRed');

  const activeSubTab = useSelector(activeSubTabSelector);

  const { hasPermission } = useUserPermissions();
  const userPermissions_rebrand = hasPermission(auth_actionOnTires_rebrand);
  const userPermissions_reinstate = hasPermission(auth_actionOnTires_reinstate);
  const userPermissions_viewTireDetails = hasPermission(auth_actionOnTires_viewTireDetails);
  const userPermissions_listing = hasPermission(auth_actionOnTires_listing);


  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      dispatch(setActiveSubTab(item.props.itemKey!));
    }
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  useEffect(() => {
    setSelectedKey(activeSubTab);
  }, [activeSubTab]);

  return (
    <div>
      <TabHeader
        details
      />
      <Pivot
        className={classNames('pivot-container')}
        selectedKey={selectedKey}
        onLinkClick={handleLinkClick}
        getTabId={getTabId}
        linkFormat="tabs"
      >

        {userPermissions_viewTireDetails.isAccess &&
          <PivotItem headerText={actionOnTiresTabs.viewTireDetails} key={1} itemKey={actionOnTiresTabs.viewTireDetails}>
            <div className="vertical-pivot-container"><ViewTireDetailsTab /></div>
          </PivotItem>
        }

        {userPermissions_listing.isAccess &&
          <PivotItem headerText={actionOnTiresTabs.viewListing} key={0} itemKey={actionOnTiresTabs.viewListing}>
            <div className="vertical-pivot-container"><ViewListingTab /></div>
          </PivotItem>
        }

        {userPermissions_reinstate.isAccess &&
          <PivotItem headerText={actionOnTiresTabs.reinstate} key={2} itemKey={actionOnTiresTabs.reinstate}>
            <div className="vertical-pivot-container"><ReinstateTab /></div>
          </PivotItem>
        }

        {userPermissions_rebrand.isAccess &&
          <PivotItem headerText={actionOnTiresTabs.rebrand} key={3} itemKey={actionOnTiresTabs.rebrand}>
            <div className="vertical-pivot-container"><RebrandTab /></div>
          </PivotItem>
        }
      </Pivot>
    </div>
  );
};

export default ActionOnTiresTab;
