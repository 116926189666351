import { tireOrderingTestInstance, exportTestInstance } from '../axios';

const handleAdd = (bpfx: any, beginBno: any, bsfx: any, endBno: any, customerId: any) =>
  tireOrderingTestInstance.post(
    '/change-type-code',
    { bpfx, beginBno, bsfx, endBno, customerId },
  );

const handleSubmitModal = (changeTypeCodes: Array<any>) =>
  tireOrderingTestInstance.post(
    '/change-type-code/add',
    { changeTypeCodes },
  );

const getTemporaryTable = (pagination: any, sortOrder: any, customerId: any) =>
  tireOrderingTestInstance.post(
    '/change-type-code/temporary',
    {
      pagination,
      sortOrder,
      filters: {
        customerId,
      },
    },
  );

const updateTemporaryTable = (changeTypeCodesUpdate: Array<any>) =>
  tireOrderingTestInstance.put(
    '/change-type-code',
    { changeTypeCodesUpdate },
  );

const deleteChangeTypeCodes = (ids: Array<any>) =>
  tireOrderingTestInstance.delete('/change-type-code', { data: { ids } });

const submit = (ids: Array<any>) =>
  tireOrderingTestInstance.post('/change-type-code/submit', { ids });

const printExcel = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/change-type-code/export-excel', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/change-type-code/export-pdf', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  handleAdd,
  handleSubmitModal,
  getTemporaryTable,
  updateTemporaryTable,
  delete: deleteChangeTypeCodes,
  submit,
  printExcel,
  printPdf,
};
