import { DefaultButton, MessageBarType, PrimaryButton } from '@fluentui/react';
import { get } from 'lodash';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import apiService from '../../../../../../../../api';
import useNotifications from '../../../../../../../../hooks/useNotifications';
import { customerSelector, locationSelector } from '../../../../../../../../redux/recordKeepingSlice';
import AutocompleteInput from '../../../../../../../../shared/AutocompleteInput';
import { IRelocateRegisterVehicleProps } from './IRelocateRegisterVehicleProps';
import styles from './RelocateRegisterVehicle.module.scss';
import { useUserPermissions } from '../../../../../../../../hooks/useUserPermissions';
import { auth_actionOnVehicles_registerVehicles} from '../../../../../../../../consts/programKeys';

const RelocateRegisterVehicle: FC<IRelocateRegisterVehicleProps> = ({ selectedVehicle, setLoading, fetchRegisterVehicles, openAddingMode }): ReactElement => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_actionOnVehicles_registerVehicles);

  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);
  const defaultLocation = useSelector(locationSelector);

  const [locations, setLocations] = useState<Array<any>>([]);
  const [selectedLocation, setSelectedLocation] = useState<any>({});

  const onChangeLocation = (locationId: any) => setSelectedLocation(() => locations.find(({ id }) => id === locationId));

  const handleClear = () => {
    setSelectedLocation({});
    openAddingMode();
  };

  const fetchLocations = async () => {
    try {
      setLoading(true);
      const { data }: any = await apiService.getLocationsByCustomerId(
        customerId,
        { pageSize: 5000, pageNumber: 1 },
        {},
      );
      setLocations(data.data);
      setSelectedLocation(defaultLocation);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Locations fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };
  
  const handleRelocate = async () => {
    setLoading(true);
    try {
      const { data }: any = await apiService.registerVehicles.relocateVehicle(
        selectedVehicle.id,
        selectedLocation.locationCode,
        customerId,
      );
      fetchRegisterVehicles();
      addNotification({
        text: `Vehicle has been successfully relocated: ${data.message}`,
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Relocation vehicle error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <div>
      <div className={styles.fields}>
        <div className={styles.field}>
          <AutocompleteInput
            label="LOCATION CODE"
            prefixIcon="POISolid"
            value={get(selectedLocation, 'id', null)}
            list={
              locations.map(({ id, locationCode }) => ({
                key: id,
                text: locationCode,
              }))
            }
            chooseCurrentItem={onChangeLocation}
            emptyExpanded
          />
          
        </div>
        <div className={styles.field}>
          <AutocompleteInput
            label="LOCATION NAME"
            prefixIcon="POISolid"
            value={get(selectedLocation, 'id', null)}
            list={
              locations.map(({ id, locationName }) => ({
                key: id,
                text: locationName,
              }))
            }
            chooseCurrentItem={onChangeLocation}
            emptyExpanded
          />
        </div>
        <div className={styles.field}>
          <AutocompleteInput
            label="SHIP TO #"
            prefixIcon="NumberSymbol"
            value={get(selectedLocation, 'id', null)}
            list={
              locations.map(({ id, shipToNumber }) => ({
                key: id,
                text: shipToNumber,
              }))
            }
            chooseCurrentItem={onChangeLocation}
            emptyExpanded
          />
        </div>
      </div> 
      <div className={styles.actionButtons}>
        <DefaultButton
          text="Clear"
          id="clearFieldsButton"
          className={styles.actionButton}
          onClick={handleClear}
          disabled={!userPermissions.isWrite}
        />
        <PrimaryButton
          text="Relocate"
          id="relocateButton"
          className={styles.actionButton}
          disabled={get(selectedLocation, 'id', null) === defaultLocation.id || !userPermissions.isWrite}
          onClick={handleRelocate}
        />
      </div>
    </div>
  );
};

export default RelocateRegisterVehicle;