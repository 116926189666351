import { exportTestInstance, recordTestInstance } from '../axios';

const getViewTireDetailsBrand = (
  filters: any,
  customerId: number,
  billedBy = null,
  remainingTreadDepth = 0,
) => recordTestInstance.post('/consigned-tires/tire-details-brand', {
  prefix: filters.prefix,
  brand: filters.brand,
  suffix: filters.suffix,
  customerId,
  billedBy,
  remainingTreadDepth,
});

const getViewTireDetailsId = (
  tireId: string,
  customerId: number,
  billedBy = null,
  remainingTreadDepth = 0,
) => recordTestInstance.post(`/consigned-tires/${tireId}/tire-details`, {
  tireId,
  customerId,
  billedBy,
  remainingTreadDepth,
});

const getBillingPriceDetails = (filters: any, billedBy: any, remainingTreadDepth: any) =>
  recordTestInstance.post('/consigned-tires/billing-price/details', { filters, billedBy, remainingTreadDepth });

const getBilledBy = () =>
  recordTestInstance.get('/consigned-tires/billing-price/billed-by');

const printExcelGeneral = (input: any) =>
  exportTestInstance.post('/consigned-tires/general/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfGeneral = (input: any) =>
  exportTestInstance.post('/consigned-tires/general/export-pdf', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const printExcelHistory = (input: any) =>
  exportTestInstance.post('/consigned-tires/history/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfHistory = (input: any) =>
  exportTestInstance.post('/consigned-tires/history/export-pdf', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const printExcelContract = (input: any) =>
  exportTestInstance.post('/consigned-tires/contract/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfContract = (input: any) =>
  exportTestInstance.post('/consigned-tires/contract/export-pdf', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const printExcelFinancial = (input: any) =>
  exportTestInstance.post('/consigned-tires/financial/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfFinancial = (input: any) =>
  exportTestInstance.post('/consigned-tires/financial/export-pdf', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const getAllTireDetailsBrand = (filters: any, customerId: number) =>
  recordTestInstance.post('/consigned-tires/all-tire-details-by-brand', { bpfx: filters.prefix, bno: filters.brand, bsfx: filters.suffix, customerId });

const getTireDetails = (requestBody: any) =>
  recordTestInstance.post('consigned-tires/active-tire-details', requestBody);

export default {
  getViewTireDetailsBrand,
  getViewTireDetailsId,
  getBillingPriceDetails,
  getBilledBy,
  printExcelGeneral,
  printPdfGeneral,
  printExcelHistory,
  printPdfHistory,
  printExcelContract,
  printPdfContract,
  printExcelFinancial,
  printPdfFinancial,
  getAllTireDetailsBrand,
  getTireDetails,
};