import { HeadCell, ISorting } from '../../shared/DataGridComponent/DataGridModels';

export const searchCustomerHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Customer Number',
    fieldName: 'customerNumber',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Prefix',
    fieldName: 'prefix',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Customer Name',
    fieldName: 'customerName',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'Region Code',
    fieldName: 'regionCode',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column5',
    name: 'Contract Status',
    fieldName: 'contractStatus',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column6',
    name: 'Active Status',
    fieldName: 'activeStatus',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column7',
    name: 'Effective Start Date',
    fieldName: 'effectiveStartDate',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column7',
    name: 'Effective End Date',
    fieldName: 'effectiveEndDate',
    typeField: 'text',
    isEditable: false,
  },
];


export const sortMapper = {
  'businessName': 'bName',
  'abbrName': 'abbrname',
  'corrAddressCity': 'city',
  'corrAddressState': 'state',
  'corrAddressCountry': 'country',
  'corrAddressZip': 'zipcode',
  'prefix': 'custPrefix',
  'customerNumber': 'customerNumber',
  'customerName': 'custName',
  'regionCode': 'regionCode',
  'billToCity': 'billToCity',
  'billToState': 'billToState',
  'billToZip': 'billToZip',
};

export const defaultSorting: ISorting = {
  column: searchCustomerHeadCell[2].fieldName,
  order: 'desc',
};