import { DefaultButton, Dialog, DialogFooter, DialogType, MessageBarType, Pivot, PivotItem, PrimaryButton, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { isEmpty, isNil } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import apiService from '../../../../api';
import useNotifications from '../../../../hooks/useNotifications';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { customerSelector, removeCustomer, setCustomer } from '../../../../redux/customerSlice';
import { selectedQuoteSelector, setSelectedQuote } from '../../../../redux/quoteSlice';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import { quoteStatuses } from '../../const';
import { quoteTabs } from './consts';
import styles from './TabComponents.module.scss';


interface QuoteTabsComponentProps {
  value?: string;
}

const QuoteTabsComponent: FunctionComponent<QuoteTabsComponentProps> = () => {
  const { id } = useParams<{ id: string }>();
  const { quoteId } = useParams<{ quoteId: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { addNotification } = useNotifications();
  const { getPermissionsByAccessKey } = useUserPermissions();

  const { id: customerId } = useSelector(customerSelector);
  const selectedQuote = useSelector(selectedQuoteSelector);
  const [selectedKey, setSelectedKey] = useState(quoteTabs.organizeFleet.name);
  const [actionType, setActionType] = useState('');
  const [newContractID, setNewContractID] = useState(''); //newly created quote
  const [isLoading, { toggle: toggleIsLoading }] = useBoolean(false);

  const [showAuthorizeConfirmationModal, { toggle: toggleShowAuthorizeConfirmationModal }] = useBoolean(false);
  const [showCreateConfirmationModal, { toggle: toggleShowCreateConfirmationModal }] = useBoolean(false);
  const customer = useSelector(customerSelector);

  const fetchCustomers = async () => {
    try {
      toggleIsLoading();
      const { data: { data } }: any = await apiService.getCustomerSearch({}, null, null, id);
      dispatch(setCustomer(data[0]));
    } catch (e: any) {
      addNotification({ text: 'Customers fetching error.', type: MessageBarType.error });
    } finally {
      toggleIsLoading();
    }
  };

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  };

  const renderTab = (TabComponent: FunctionComponent<{ actionType: string }>) => {
    if (TabComponent) return <TabComponent actionType={actionType} key={TabComponent.name + 'tab'} />;
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  const handleBackToSearch = () => {
    dispatch(removeCustomer());
    dispatch(setSelectedQuote(null));
    history.push('/quote');
  };

  const handleBackToListing = () => history.push(`/quote/${id}`);
  const handleBackToTerm = () => {
    (isNil(selectedQuote.contractId) || isEmpty(selectedQuote.contractId)) ?  history.push(`/terms/${id}/list/${newContractID}`) : history.push(`/terms/${id}/list/${selectedQuote.contractId}`);
    window.location.reload();
  };

  const generateQuote = async (status: string) => {
    toggleIsLoading();
    try {
      await apiService.quotesAPI.generateQuote(quoteId, customerId, selectedQuote.version);
      dispatch(setSelectedQuote({ ...selectedQuote, status }));
      addNotification({
        text: 'Quote was successfully generated.',
        type: MessageBarType.success,
      });
    } catch (error: any) {
      const { response : { data }} = error;
      const { message, state } = data;
      if (isEmpty(state) || isNil(state)) {
        addNotification({
          text: `Quote generating error: ${message}`,
          type: MessageBarType.error,
        });
      } else {
        state.forEach(({ message: errorMessage }: any) => {
          addNotification({
            text: `Quote generating error: ${errorMessage}`,
            type: MessageBarType.error,
          });
        });
      }
    } finally {
      toggleIsLoading();
    }
  };

  const updateQuoteStatus = async (status: string) => {
    toggleIsLoading();
    try {
      const { data } = await apiService.quotesAPI.updateQuoteStatus(quoteId, status, selectedQuote.version);
      dispatch(setSelectedQuote({ ...selectedQuote, status }));
      setNewContractID(data.contractId);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Quote status updating error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      toggleIsLoading();
    }
  };

  useEffect(() => {
    if (!selectedQuote) {
      handleBackToListing();
    }
  }, []);

  useEffect(() => {
    setActionType(id == 'add' ? id : 'edit');
    if (id != 'add') fetchCustomers();
  }, [id, selectedKey]);

  return (
    <div className={classNames(styles.quoteContainer)}>
      <div className={classNames(styles.backButtonContainer)}>
        <DefaultButton
          onClick={handleBackToSearch}
          text="Back to Search"
        />
        <DefaultButton
          onClick={handleBackToListing}
          text="Back to Listing"
        />

        {selectedQuote ? <div className={styles.quotesInfoContainer}>
          <div className={styles.infoField}>
            <Text variant="large" className={classNames('ms-fontWeight-semibold',styles.highlight)}>Quote #:&nbsp;</Text>
            <Text variant="large" className={styles.highlight}>{selectedQuote.id}</Text>
          </div>
          <div className={styles.infoField}>
            <Text variant="large" className={classNames('ms-fontWeight-semibold',styles.highlight)}>Status:&nbsp;</Text>
            <Text variant="large" className={styles.highlight}>{selectedQuote.status}</Text>
          </div>
          <div className={styles.infoField}>
            <Text variant="large" className={classNames('ms-fontWeight-semibold',styles.highlight)}>Version:&nbsp;</Text>
            <Text variant="large" className={styles.highlight}>{selectedQuote.version}</Text>
          </div>
          <div className={styles.infoField}>
            <Text variant="large" className={classNames('ms-fontWeight-semibold',styles.highlight)}>Expiration Date:&nbsp;</Text>
            <Text variant="large" className={styles.highlight}>{selectedQuote.expirationDate}</Text>
          </div>
        </div> : ''}
      </div>


      {
        isLoading ?
          <LoadingScreen /> :
          <div className={styles.tabsContainer}>
            <div className={styles.customerHeaderWrapper}>
              {isEmpty(customer?.contractStatus) ? customer && <h2 className={styles.customerHeader}>{customer?.customerName} </h2> :
                customer && <h2 className={styles.customerHeader}>{customer?.customerName} - {customer?.contractStatus} </h2>}
            </div>
            <Pivot
              className={'pivot-gy-colors'}
              selectedKey={selectedKey}
              onLinkClick={handleLinkClick}
              getTabId={getTabId}
              linkFormat="tabs"
            >
              {Object.values(quoteTabs).map(({ accessKey, name, component, actionType: actionTypeList }, index) => {
                const showTab = actionTypeList.includes(actionType);
                return showTab && getPermissionsByAccessKey(accessKey)?.isAccess &&
                  (<PivotItem headerText={name} key={index} itemKey={name}>
                    <div className={styles.pivotBodyContainer}>
                      {renderTab(component)}
                    </div>
                  </PivotItem>);
              })}
            </Pivot>
          </div>
      }
      <div className={classNames('ms-Grid-row', styles.marginRow)}>
        <div className={classNames(styles.buttonsWrapper)}>
          {selectedQuote?.status === quoteStatuses.CREATED && <DefaultButton text="Go to Term" onClick={handleBackToTerm} />}
          {selectedQuote?.status === quoteStatuses.AUTHORIZED && <PrimaryButton id="createBtn" text="Create" onClick={toggleShowCreateConfirmationModal} />}
          {selectedQuote?.status === quoteStatuses.GENERATED && <PrimaryButton text="Authorize" onClick={toggleShowAuthorizeConfirmationModal} />}
          {(selectedQuote?.status === quoteStatuses.NEW || selectedQuote?.status === quoteStatuses.REGENERATED) &&
            <PrimaryButton text="Generate" onClick={() => generateQuote(quoteStatuses.GENERATED)} />}
        </div>
      </div>
      <Dialog
        hidden={!showAuthorizeConfirmationModal}
        onDismiss={toggleShowAuthorizeConfirmationModal}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Authorize Quote',
          subText: 'Editing will be disabled and all previous versions will be deleted. Continue?',
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              updateQuoteStatus(quoteStatuses.AUTHORIZED);
              toggleShowAuthorizeConfirmationModal();
            }}
            text="Authorize"
          />
          <DefaultButton onClick={toggleShowAuthorizeConfirmationModal} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={!showCreateConfirmationModal}
        onDismiss={toggleShowCreateConfirmationModal}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Create Contract',
          subText: 'A contract will be created from the quote. Continue?',
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton
            id="confirmBtn"
            onClick={() => {
              updateQuoteStatus(quoteStatuses.CREATED);
              toggleShowCreateConfirmationModal();
            }}
            text="Create"
          />
          <DefaultButton onClick={toggleShowCreateConfirmationModal} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default QuoteTabsComponent; 