

import { MessageBarType, Text, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { debounce, get } from 'lodash';
import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import apiService from '../../../../api';
import useNotifications from '../../../../hooks/useNotifications';
import BackoutMileageComponent from '../../../../shared/BackoutMileageComponent/BackoutMileageComponent';
import AutoCompleteField from '../../../Common/Search/AutoCompleteField';
import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import styles from './BackoutMileageRun.module.scss';

const BackoutMileageRun: FC = (): ReactElement => {

  const { addNotification } = useNotifications();

  const [isLoadingFetchCustomers, { toggle: toggleIsLoadingFetchCustomers }] = useBoolean(false);
  const [customerFilters, setCustomerFilters] = useState<any>(null);
  const [customers, setCustomers] = useState<Array<any>>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<any>({});

  const onCustomerTextChange = debounce(async (field: string, value: string, sortOrder?: any) => {
    if (value && get(customerFilters, `${field}`) !== value) {
      fetchCustomers({ [field]: value }, sortOrder);
    }
    setCustomerFilters(value ? { [field]: value } : null);
  }, 1000);

  const onCustomerChange = async (newCustomerId: any) => setSelectedCustomer(getListItemById(newCustomerId, customers));

  const getListItemById = (idToFind: number, list: any[]) => {
    const itemToFind = list?.find(({ id }) => id === idToFind);
    return itemToFind ? itemToFind : null;
  };

  const removeEmpty = ({ text }: any) => text !== null;

  const customerPrefixList = useMemo(() => customers ?
    customers.map(({ id, prefix }) => ({
      key: id,
      text: prefix,
    })).filter(removeEmpty) : [], [customers]);


  const customerNameList = useMemo(() => customers ?
    customers.map(({ id, customerName }) => ({
      key: id,
      text: customerName,
    })).filter(removeEmpty) : [], [customers]);


  const fetchCustomers = async (filters: any, sortOrder: any = { column: 'custPrefix', order: 'asc' }) => {
    toggleIsLoadingFetchCustomers();
    try {
      const { data: { data } }: any = await apiService.getCustomerList(
        filters,
        undefined,
        sortOrder,
      );
      setCustomers(data);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Customers fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      toggleIsLoadingFetchCustomers();
    }
  };


  useEffect(() => {
    if (!customerFilters) {
      fetchCustomers({});
    }
  }, [customerFilters]);

  return (
    <div>
      <Text variant='xxLarge' className={styles.highlight}>Back Out Last Mileage Run</Text>
      <div className={styles.detailsRow}>
        <div className={styles.rowWrapper}>
          <Text variant="large" className={styles.highlight}>Customer Details</Text>
          <SeparatorGy />
          <div className={styles.fields}>
            <div className={styles.field}>
              <AutoCompleteField
                label="*Prefix"
                data-testid='customer-prefix'
                icon="FavoriteStar"
                value={selectedCustomer?.id}
                list={customerPrefixList}
                textValue={(value: any) => onCustomerTextChange('custPrefix', value)}
                onChangeAction={onCustomerChange}
                isLoading={isLoadingFetchCustomers}
              />
            </div>
            <div className={styles.field}>
              <AutoCompleteField
                className={styles.largeField}
                label="*Customer Name"
                data-testid='customer-name'
                icon="Contact"
                value={selectedCustomer?.id}
                list={customerNameList}
                textValue={(value: any) => onCustomerTextChange('custName', value, { column: 'customerName', order: 'asc' })}
                onChangeAction={onCustomerChange}
                isLoading={isLoadingFetchCustomers}
              />
            </div>
            <div className={styles.field}>
              <SeparatorGy vertical />
            </div>
            <div className={styles.field}>
              <TextField
                label="Region"
                value={selectedCustomer?.regionCode}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.rowWrapper}>
          {selectedCustomer.id && <BackoutMileageComponent
            customerId={selectedCustomer?.id}
            detailsAPI={apiService.billingAPI.getBackoutDetails}
            startAPI={apiService.billingAPI.startBackout}
            startAPIStatus={apiService.billingAPI.startBackoutStatus}
            startAPIDetails={apiService.billingAPI.startBackoutDetails}
          />}
        </div>
      </div>
    </div>
  );
};

export default BackoutMileageRun;
