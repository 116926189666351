import {
  auth_inTransitTires,
  auth_mtc,
  auth_tireOrder,
} from '../../consts/programKeys';
import { ITabComponent } from '../../models/ITabComponent';
import ChangeTypeCodeTab from './tabComponents/ChangeTypeCodeTab/ChangeTypeCodeTab';
import { inTransitTiresTabs } from './tabComponents/InTransitTiresTab/consts';
import InTransitTiresTab from './tabComponents/InTransitTiresTab/InTransitTiresTab';
import { tireOrderManagementTabs } from './tabComponents/TireOrderManagementTab/consts';
import TireOrderManagementTab from './tabComponents/TireOrderManagementTab/TireOrderManagementTab';

export enum TIREORDER_TABS {
  TIREORDER_MANAGEMENT = 'tireOrderManagement',
  CHANGE_TYPECODES = 'changeTypeCodes',
  INTRANSIT_TIRES = 'inTransitTires',
};

/** Each tab into left menu must have a component */
export const tireOrderTabs: ITireOrderTabs = {
  tireOrderManagement: {
    id: TIREORDER_TABS.TIREORDER_MANAGEMENT,
    accessKey: auth_tireOrder,
    name: 'Tire Order Management',
    component: TireOrderManagementTab,
    subTabs: tireOrderManagementTabs,
  },
  maintainTypeCodes: {
    id: TIREORDER_TABS.CHANGE_TYPECODES,
    accessKey: auth_mtc,
    name: 'Change Type Code',
    component: ChangeTypeCodeTab,
    subTabs: null,
  },
  inTransitTires: {
    id: TIREORDER_TABS.INTRANSIT_TIRES,
    accessKey: auth_inTransitTires,
    name: 'In-Transit Tires',
    component: InTransitTiresTab,
    subTabs: inTransitTiresTabs,
  },
};

export const getTabById: any = (tabId: string) => Object.values(tireOrderTabs).filter((tab) => tab.id === tabId)[0];
export const getSubTabsById: any = (tabId: string) => getTabById(tabId)?.subTabs;

export interface ITireOrderTabs {
  tireOrderManagement: ITabComponent;
  maintainTypeCodes: ITabComponent;
  inTransitTires: ITabComponent;
}