import { HeadCell } from '../DataGridComponent/DataGridModels';

export const pageSizes = [100, 250, 500];
export interface ShipToOemModalComponentProps {
    id: string;
    title: string;
    isOpen: boolean;
    onSubmit: (value?:any) => void;
    onDismiss: () => void;
    successLabel: string;
    cancelLabel: string;
  }

export const shipToOemHeadCell: HeadCell[] = [
  {
    key: 'column1',
    typeField: 'text',
    name: 'OEM #',
    fieldName: 'oemNumber',
  },
  {
    key: 'column2',
    typeField: 'text',
    name: 'Name',
    fieldName: 'name',
  },
  {
    key: 'column3',
    typeField: 'text',
    name: 'Address Line 1',
    fieldName: 'addressLine1',
  },
  {
    key: 'column4',
    typeField: 'text',
    name: 'Address Line 2',
    fieldName: 'addressLine2', 
  },
  {
    key: 'column5',
    typeField: 'text',
    name: 'Country',
    fieldName: 'country',
  },
  {
    key: 'column6',
    typeField: 'text',
    name: 'State',
    fieldName: 'state',
  },
  {
    key: 'column7',
    typeField: 'text',
    name: 'City',
    fieldName: 'city',
  },
  {
    key: 'column8',
    typeField: 'text',
    name: 'Zip',
    fieldName: 'zip',
    
  },
  {
    key: 'column9',
    typeField: 'text',
    name: 'Phone',
    fieldName: 'phone',
  },
  {
    key: 'column10',
    typeField: 'text',
    name: 'Fax',
    fieldName: 'fax',
  },
  {
    key: 'column11',
    typeField: 'text',
    name: 'Contact Person',
    fieldName: 'contactPerson',
    
  },
  {
    key: 'column12',
    typeField: 'text',
    name: 'Email',
    fieldName: 'email',
  },
  {
    key: 'column13',
    typeField: 'text',
    name: 'Comments',
    fieldName: 'comment',
    
  },
];

export const mockShipTo = {
  total: {
    all: 2,
    found: 2,
  },
  data: [
    {
      id: 'string',
      plantId: 'string',
      oemNumber: 'string',
      name: 'string',
      addressLine1: 'string',
      addressLine2: 'string',
      city: 'string',
      state: 'string',
      country: 'string',
      zip: 'string',
      phone: 'string',
      fax: 'string',
      contactPerson: 'string',
      comment: 'string',
      email: 'string',
      active: true,
    },
    {
      id: 'string',
      plantId: 'string',
      oemNumber: 'string',
      name: 'string',
      addressLine1: 'string',
      addressLine2: 'string',
      city: 'string',
      state: 'string',
      country: 'string',
      zip: 'string',
      phone: 'string',
      fax: 'string',
      contactPerson: 'string',
      comment: 'string',
      email: 'string',
      active: true,
    },
  ],
};