import { FC, FormEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { isEmpty, isNil } from 'lodash';
import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
  Label,
  MessageBarType,
  PrimaryButton,
  Separator,
  Text,
} from '@fluentui/react';
import DataGridComponent from '../../../../../shared/DataGridComponent';
import { reportsHeadCell } from './consts';
import { ACTION_GROUPS_ENUM } from '../../../../../shared/DataGridComponent/ActionsComponent/ActionsGroupEnum';

import { IReportPrintingModalProps } from './IReportPrintingModalProps';

import styles from './ReportPrintingModal.module.scss';
import { useBoolean } from '@fluentui/react-hooks';
import { INotification } from '../../../../../models/INotification';
import apiService from '../../../../../api';
import useNotifications from '../../../../../hooks/useNotifications';


const ReportPrintingModal: FC<IReportPrintingModalProps> = ({ isOpened, onPrint, onClose, reportTypes, printingTypes, invoiceId }) => {
  const { addNotification } = useNotifications();
  const [reportType, setReportType] = useState<IDropdownOption | undefined>(undefined);
  const [exportFormat, setExportFormat] = useState<IDropdownOption | undefined>(undefined);
  const [reports, setReports] = useState<any[]>([]);
  const [totalFound, setTotalFound] = useState(0);
  const [isLoading, { toggle: toggleShowLoading }] = useBoolean(false);

  useEffect(() => {
    if (isOpened) {
      setExportFormat({ key: -1, text: '' });
      setReportType({ key: -1, text: '' });
      setReports([]);
    }
  }, [isOpened]);

  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 240 } };

  const onChangeReportType = async (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): Promise<void> => {
    setReportType(item);

    if (exportFormat?.key !== -1) {
      await fetchReports(invoiceId[0], item?.key, 'Billing', exportFormat?.key);
    }
  };

  const onChangeExportFormat = async (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): Promise<void> => {
    setExportFormat(item);

    if (!isEmpty(reportType?.text)) {
      await fetchReports(invoiceId[0], reportType?.key, 'Billing', item?.key);
    }
  };

  const fetchReports = async (
    invoiceId: string,
    reportType: any,
    module: string,
    reportFormat: any,
  ) => {
    toggleShowLoading();
    try {
      const { data: { data, total } } = await apiService.billingAPI.getAvailableReports(invoiceId, reportType, module, reportFormat);
      setReports(data);
      setTotalFound(total.found);
    } catch (e: any) {
      addNotification({
        text: 'Fetching error of My reports',
        type: MessageBarType.error,
      });
    }
    toggleShowLoading();
  };

  return (
    <div className={classNames(styles.root, { [styles.closed]: !isOpened })}>
      <div className={classNames('ms-Grid', styles.window)}>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col">
            <Text variant="xLarge" className={styles.highlight}>Print Invoice Report</Text>
          </div>
        </div>
        <div className={classNames(styles.mainBlock, 'ms-Grid-row')}>
          <div className={classNames('ms-Grid-col', styles.centeredContentColumn)}>
            <Label htmlFor="exportToMethod">Export To:</Label>
            <Dropdown
              id="exportToMethod"
              selectedKey={exportFormat ? exportFormat.key : undefined}
              onChange={onChangeExportFormat}
              placeholder="Select an option"
              options={Object.keys(printingTypes)?.map(e => ({ key: e, text: printingTypes[e] }))}
              styles={dropdownStyles}
            />
            <Label htmlFor="reportTypeMethod">Report Type:</Label>
            <Dropdown
              id="reportTypeMethod"
              selectedKey={reportType ? reportType.key : undefined}
              onChange={onChangeReportType}
              placeholder="Select an option"
              options={reportTypes?.map(e => ({ key: e, text: e }))}
              styles={dropdownStyles}
            />
          </div>

          <div className="ms-Grid-col">
            <Separator className={styles.separator} vertical />
          </div>

          <div className={classNames('ms-Grid-col', styles.centeredContentColumn)}>
            <PrimaryButton
              id='printExportModalBtn'
              text="Export"
              iconProps={{ iconName: 'Installation' }}
              onClick={() => onPrint(reportType?.key, exportFormat?.key)}
              disabled={isNil(reportType) || reportType.key === -1}
              className={styles.button}
            />
            <DefaultButton
              text="Cancel"
              onClick={onClose}
              className={styles.button}
            />
          </div>
        </div>
        <div className={styles.detailsRow}>
          <div className={styles.rowWrapper}>
            <DataGridComponent
              actionsGroupName={ACTION_GROUPS_ENUM.DOWNLOAD_AVAILABLE_REPORT}
              idTable={'available-reports'}
              title='Available Reports'
              headCells={reportsHeadCell}
              rowsTable={reports}
              totalDataFound={totalFound}
              enablePagination
              enableSorting
              enableRowsPerPage
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportPrintingModal;
