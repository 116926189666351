import { FC, ReactElement, useState } from 'react';
import styles from './ViewTireDetailsTab.module.scss';
import { IViewTireDetailsTabProps } from './ViewTireDetailsTabProps';
import classNames from 'classnames';
import { Text } from '@fluentui/react';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import { useSelector } from 'react-redux';
import { customerSelector } from '../../../../../../redux/recordKeepingSlice';
import ViewTireDetails from '../../../../ViewTireDetails/ViewTireDetails';
import { viewTireDetailsTabs } from '../../../../../../consts/recordKeeping';

const ViewTireDetailsTab: FC<IViewTireDetailsTabProps> = (): ReactElement => {
  const { prefix, customerName } = useSelector(customerSelector);
  const [selectedTab, setSelectedTab] = useState(viewTireDetailsTabs.general);

  return (
    <>
      <div className={classNames(styles.viewTireDetailsTabContainer)}>
        <div className={classNames(styles.viewTireDetailsTabHeader)}>
          <div>
            <Text variant="xLarge" className={styles.highlight}>{prefix}</Text>
            <SeparatorGy vertical />
            <Text variant="xLarge" className={styles.highlight}>{customerName}</Text>
          </div>
        </div>
        <div className={classNames(styles.viewTireDetailsTabBody)}>
          <ViewTireDetails 
            isSearchable={true} 
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>
      </div>
    </>
  );
};

export default ViewTireDetailsTab;

