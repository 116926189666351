import { Pivot, PivotItem } from '@fluentui/react';
import { get } from 'lodash';
import { FC, FunctionComponent, ReactElement, useState } from 'react';
import { searchTabs } from './consts';
import { ISearchProps } from './ISearchProps';

const SearchCustomerLocationOrder: FC<ISearchProps> = ({ callbackLoading }): ReactElement => {

  const [currentTab, setCurrentTab] = useState(searchTabs.searchACustomer.name);

  const handleLinkClick = (item?: PivotItem) => {
    const newTab = get(item, 'props.itemKey', '');
    setCurrentTab(newTab);
  };

  const renderTab = (TabComponent: FunctionComponent) => {
    if (TabComponent) return <TabComponent />;
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  return (
    <Pivot
      aria-label="Separately Rendered Content Pivot Example"
      selectedKey={currentTab}
      onLinkClick={handleLinkClick}
      getTabId={getTabId}
      linkFormat="tabs"
      linkSize="large"
    >
      {Object.values(searchTabs).map(({ name, component }, index) =>
        <PivotItem headerText={name} key={index} itemKey={name}>
          <div className="pivotBodyContainer">
            {renderTab(component)}
          </div>
        </PivotItem>)}
    </Pivot>

  );
};

export default SearchCustomerLocationOrder;