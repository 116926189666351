import { FunctionComponent, useState } from 'react';
import styles from './Actions.module.scss';
import { ACTION_GROUPS_ENUM } from './ActionsGroupEnum';
import { getActionsByGroupName } from './consts';

interface ActionsComponentProps {
  row?: any;
  idxRow?: any;
  actionsGroupName: ACTION_GROUPS_ENUM;
};

const ActionsComponent: FunctionComponent<ActionsComponentProps> = ({ row, actionsGroupName, idxRow }) => {
  const [actionsList, setactionsList] = useState<Array<FunctionComponent<any>>>(getActionsByGroupName(actionsGroupName));

  const renderAction = (ActionComponent: FunctionComponent<any>) => {
    if (ActionComponent) return <ActionComponent row={row} index={idxRow} actionsGroupName={actionsGroupName} />;
  };

  return (
    <>
      <div className={styles.actionsContainer}>
        {actionsList?.map((action) => renderAction(action))}
      </div>
    </>
  );
};

export default ActionsComponent;