export const emptyFilterModalWindowState = {
  loading: false,
};

export const initialFilterState = {
  account: '',
  loc: '',
  annual: '',
  inventoryDateFrom: '',
  inventoryDateTo: '',
  submit:'',
};

