import React from 'react';
import { MIDAS_MODULES } from '../../models/EnumMidasModules';
import SearchCustomerComponent from '../../shared/SearchCustomerComponent/SearchCustomerComponent';
import { ITermsProps } from './ITermsProps';
import apiService from '../../api';

export const Terms: React.FC<ITermsProps> = () =>
  <SearchCustomerComponent 
    parentComponent={MIDAS_MODULES.TERMS} 
    printExcelAPI={apiService.terms.printExcelContractListing}
    printPdfAPI={apiService.terms.printPdfContractListing}
  />;