import { InteractionType, IPublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalAuthenticationTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import BillingComponent from './components/Billing/Billing';
import { Customer } from './components/Customer/Customer';
import { CustomerView } from './components/Customer/View';
import Error from './components/Error/Error';
import Home from './components/Home/Home';
import Maintain from './components/Maintain/Maintain';
import Leftnav from './components/Nav/Leftnav';
import Navbar from './components/Nav/Navbar';
import MyReports from './components/MyReports/MyReports';
import NotificationsManager from './components/NotificationsManager/NotificationsManager';
import QuoteListingComponent from './components/Quote/Components/Listing/Listing';
import QuoteTabsComponent from './components/Quote/Components/tabComponents';
import { Quote } from './components/Quote/Quote';
import RecordKeeping from './components/RecordKeeping/RecordKeeping';
import TaskScheduler from './components/TaskScheduler/TaskScheduler';
import { AvailableTerms } from './components/Terms/AvailableTerms/AvailableTerms';
import TermsTabsComponent from './components/Terms/AvailableTerms/Components';
import { Terms } from './components/Terms/Terms';
import TireOrder from './components/TireOrder/TireOrder';
import UserAccessManagement from './components/UserAccessManagement/UserAccessManagement';
import ReportingComponent from './components/Reporting/Reporting';

//test comment
//here's the code for the sign in sign out feature.

interface AppProps {
  pca: IPublicClientApplication;
}

const App: FunctionComponent<AppProps> = ({ pca }) => {

  const [openMenu, { toggle: toggleOpenMenu }] = useBoolean(true);

  return (
    <>
      <NotificationsManager />
      <MsalProvider instance={pca}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <AuthenticatedTemplate>
            <HashRouter>
              <Navbar pca={pca} />
              <div className={'stackStyles'}>
                <Leftnav isOpen={openMenu} toggleOpenMenu={toggleOpenMenu} />
                <div className={classNames('switchStyles', openMenu ? 'openMenu' : 'closeMenu')}>
                  <Switch>
                    <Route path="/" component={Home} exact />
                    <Route path="/customer" exact component={Customer} />
                    <Route path="/customer/:id" exact component={CustomerView} />
                    <Route path="/quote" exact component={Quote} />
                    <Route path="/quote/:id" exact component={QuoteListingComponent} />
                    <Route path="/quote/:id/:quoteId" exact component={QuoteTabsComponent} />
                    <Route path="/terms" exact component={Terms} />
                    <Route path="/terms/:id/list" exact component={AvailableTerms} />
                    <Route path="/terms/:id/list/:contractId" exact component={TermsTabsComponent} />
                    <Route path="/tireorders" component={TireOrder} />
                    <Route path="/recordkeeping" component={RecordKeeping} />
                    <Route path="/billing" component={BillingComponent} />
                    <Route path="/reports" component={ReportingComponent} />
                    <Route path="/myreports" component={MyReports} />
                    <Route path="/user-access-management" exact component={UserAccessManagement} />
                    <Route path="/task-scheduler" exact component={TaskScheduler} />
                    <Route path="/maintain" exact component={Maintain} />
                    <Route component={Error} />
                  </Switch>
                </div>
              </div>
            </HashRouter>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <p>No users are signed in!</p>
          </UnauthenticatedTemplate>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </>
  );
};


export default App;