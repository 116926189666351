import { HeadCell, ISorting } from '../../../../../shared/DataGridComponent/DataGridModels';

export const columns: HeadCell[] = [
  {
    key: 'column0',
    name: 'Rate',
    fieldName: 'rgName',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Month Of Entry',
    fieldName: 'monthOfEntry',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Orig # Of Tires Current',
    fieldName: 'origTiresCurr',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Orig # Of Avg Current',
    fieldName: 'origAvgCurr',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'Orig Tires 6 Months',
    fieldName: 'origTires6Month',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column5',
    name: 'Orig Avg 6 Months',
    fieldName: 'origAvg6Month',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column6',
    name: 'Orig Tires 12 Months',
    fieldName: 'origTires12Month',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column7',
    name: 'Orig Avg 12 Months',
    fieldName: 'origAvg12Month',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column8',
    name: 'Retread Tires Current',
    fieldName: 'rtrdTiresCurr',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column9',
    name: 'Retread Avg Current',
    fieldName: 'rtrdAvgCurr',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column10',
    name: 'Retread Tires 6 Months',
    fieldName: 'rtrdTires6Month',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column11',
    name: 'Retread Avg 6 Months',
    fieldName: 'rtrdAvg6Month',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column12',
    name: 'Retread Tires 12 Months',
    fieldName: 'rtrdTires12Month',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column13',
    name: 'Retread Avg 12 Months',
    fieldName: 'rtrdAvg12Month',
    typeField: 'text',
    isEditable: false,
  },
];

export const mockOOSAvgs = {
  data: [
    {
      rgName: 'string',
      monthOfEntry: 'string',
      origTiresCurr: 10,
      origAvgCurr: 20,
      origTires6Month: 30,
      origAvg6Month: 40,
      origTires12Month: 50,
      origAvg12Month: 60,
      rtrdTiresCurr: 70,
      rtrdAvgCurr: 80,
      rtrdTires6Month: 90,
      rtrdAvg6Month: 0,
      rtrdTires12Month: 0,
      rtrdAvg12Month: 0,
    },
  ],
  total: {
    all: 1,
    found: 1,
  },
};

export const defaultSorting: Array<ISorting> = [
  {
    column: columns[1].fieldName,
    order: 'desc',
  },
  {
    column: columns[0].fieldName,
    order: 'asc',
  },
];