import { fieldType } from '../../../../../../consts/fieldType';
import { IPlant } from './IPlant';

export const pageSizes = [100, 250, 500];

export const plantsColumns = [
  {
    key: 'column1',
    name: 'Plant Number',
    fieldName: 'plantNo',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column2',
    name: 'Name',
    fieldName: 'name',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column3',
    name: 'Address 1',
    fieldName: 'addrLine1',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column4',
    name: 'Address 2',
    fieldName: 'addrLine2',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column5',
    name: 'City',
    fieldName: 'city',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column6',
    name: 'State',
    fieldName: 'state',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column7',
    name: 'Country',
    fieldName: 'country',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column8',
    name: 'Zip',
    fieldName: 'zip',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column9',
    name: 'Phone',
    fieldName: 'phone1',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column10',
    name: 'Fax',
    fieldName: 'fax',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column11',
    name: 'Contact Person',
    fieldName: 'contactName',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column12',
    name: 'Email',
    fieldName: 'email',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
];

export const addingTabs = {
  general: 'General',
  comments: 'Comments',
};

export const defaultAddingFields: IPlant = {
  plantNo: '',
  name: '',
  type: 'RETREAD',
  addrLine1: '',
  addrLine2: '',
  city: '',
  zip: '',
  contactName: '',
  phone1: '',
  phone2: '',
  email: '',
  fax: '',
  state: null,
  country: null,
};

export const defaultFilters = { 
  type: 'RETREAD', 
  closeTo: '', 
  name: '', 
  city: '',
  active: true,
};