import { tireOrderingTestInstance, customerTestInstance } from '../axios';
import { IContactItemAPI } from '../components/Customer/Components/tabComponents/Contacts/IContacts';
import { GeneralViewData } from '../components/Customer/Components/tabComponents/General/consts';

const getCountries = () => {
  return tireOrderingTestInstance.post('/sections/countries');
};

const getStates = (country: string | undefined) => {
  return tireOrderingTestInstance.post('/sections/states', { country: country });
};

const getRegionCodes = (country?: string | undefined) => {
  return tireOrderingTestInstance.post('/sections/region-codes', { country: country });
};

const getCurrencies = () => {
  return tireOrderingTestInstance.post('/sections/currencies');
};

const getUnitsOfMeasure = () => {
  return tireOrderingTestInstance.post('/sections/unit-of-measures');
};

const getOperationTypes = () => {
  return tireOrderingTestInstance.post('/sections/operation-types');
};

const addCustomer = (input: GeneralViewData) => {
  return customerTestInstance.post('/customer/general/add', input);
};

const getAvailableSubsidiaries = (filters: any) => {
  return customerTestInstance.post('/customer/common-owner/search/basic', { filters });
};

const getCustomerSubsidiaries = (pagination: any, filters: any, sortOrder: any, customerId: string) => {
  return customerTestInstance.post('/customer/common-owner/search/by-customer', { pagination, filters, sortOrder, customerId });
};

const deleteCustomer = (ids: Array<any>) => customerTestInstance.delete('/customer/delete', { data: { ids } });

const addNewSubsidiary = (body: { customerId: string, customerOwnerId: string }) => {
  return customerTestInstance.put('/customer/common-owner/update', body);
};

const deleteSubsidiary = (body: any) => {
  return customerTestInstance.delete('/customer/common-owner/delete', { data: body });
};

const updateCustomer = (input: GeneralViewData) => {
  return customerTestInstance.put('/customer/general/update', input);
};

const addContactsToCustomer = (payload: {
  customerId: string,
  contacts: IContactItemAPI[]
}) => {
  return customerTestInstance.post('/customer/contact/add', payload);
};

const updateContactsToCustomer = (payload: {
  customerId: string,
  contacts: IContactItemAPI[]
}) => {
  return customerTestInstance.put('/customer/contact/update', payload);
};
const deleteContactsToCustomer = (payload: {
  customerId: string,
  ids: (number | undefined)[]
}) => {
  return customerTestInstance.delete('/customer/contact/delete', { data: payload });
};

const updateCustomerHistory = (body: { id: string, comments: string }) => {
  return customerTestInstance.put('/customer/general/history', body);
};

const getEquipments = (pagination: any, sortOrder: any, mCbCustomerId: string, filters: string) =>
  customerTestInstance.post('/customer/equipment', { pagination, sortOrder, mCbCustomerId, filters });

const addEquipment = (body: { tagNumber: string, status: string, description: string, acqDate: string, comments: string, mCbCustomerId: string, locationId: string }) =>
  customerTestInstance.post('/customer/equipment/add', body);

const updateEquipment = (body: { equipmentId: string, tagNumber: string, status: string, description: string, acqDate: string, comments: string, mCbCustomerId: string, locationId: string }) =>
  customerTestInstance.put('/customer/equipment/update', body);

const deleteEquipments = (ids: Array<{ equipmentId: string }>) =>
  customerTestInstance.delete('/customer/equipment/delete', { data: { customerEquipmentIds: ids } });

const transferEquipments = (body: {
  equipmentId: string, tagNumber: string, status: string, description: string, acqDate: string, comments: string,
  location: { locationId: string, shipToNumber: string, mCbCustomerId: string }, newLocation: { locationId: string, shipToNumber: string, mCbCustomerId: string }
}) =>
  customerTestInstance.post('/customer/equipment/transfer', body);

const getKeyCharacteristics = (mCbCustomerId: string) => {
  return customerTestInstance.post('/customer/key-characteristics', { mCbCustomerId });
};

const updateKeyCharacteristics = (kcData: any) => {
  return customerTestInstance.put('/customer/key-characteristics/update', kcData);
};

const clearFleets = (body: { customerId: string }) => {
  return customerTestInstance.post('/customer/fleet-forecast/clear', body);
};

const cloneFleets = (input: any) => {
  return customerTestInstance.post('/customer/fleet-forecast/clone', input);
};

const getFleetFilters = (input: any) => {
  return customerTestInstance.post('/customer/fleets/filter-by', input);
};

const getFleetModels = () => {
  return customerTestInstance.get('/customer/fleet-forecast/make-models');
};

const listMakeModels = (input: any) => {
  return customerTestInstance.post('/customer/fleet-forecast/make-models/list', input);
};

const listProducts = (input: any) => {
  return customerTestInstance.post('/customer/fleet-forecast/product-codes/list', input);
};

const addFleets = (input: any) => {
  return customerTestInstance.put('/customer/fleet-forecast/make-model', input);
};

const updateFleets = (input: any) => {
  return customerTestInstance.put('/customer/fleet-forecast/make-model/update', input);
};

const deleteFleets = (ids: Array<any>) => customerTestInstance.delete('/customer/fleet-forecast/make-models/delete', { data: { ids } });

const makeModels = (input: any) => {
  return customerTestInstance.post('/customer/fleet-forecast/make-models', input);
};

const exportFleets = (input: any) => {
  return customerTestInstance.post('/customer/fleet-forecast/export-fleets', input);
};

const getFleets = (input: any) => {
  return customerTestInstance.post('/customer/fleets/list', input);
};

const getProducts = (pagination?: any, sortOrder?: any, fleetId?: any, searchedText?: any) =>
  customerTestInstance.post('/customer/fleet/product/list', { pagination, sortOrder, fleetId, searchedText });

const addProduct = (input: any) =>
  customerTestInstance.post('/customer/fleet/product/add', input);

const updateProduct = (input: any) =>
  customerTestInstance.put('/customer/fleet/product/update', input);

const deleteProducts = (input: any) =>
  customerTestInstance.post('/customer/fleet/product/delete', {
    productIds: input,
  });

const deleteAllProducts = (input: any) =>
  customerTestInstance.post('/customer/fleet/product/deleteall', { fleetId: input });

const getProductCodes = () =>
  customerTestInstance.get('/customer/fleet-forecast/product-codes');

const addProducts = (input: any) => {
  return customerTestInstance.post('​/customer​/fleet-forecast​/auth-products​/validate', input);
};

export default {
  getCountries,
  getStates,
  getRegionCodes,
  getCurrencies,
  getUnitsOfMeasure,
  getOperationTypes,
  addCustomer,
  deleteCustomer,
  updateCustomer,
  getCustomerSubsidiaries,
  getAvailableSubsidiaries,
  addNewSubsidiary,
  updateCustomerHistory,
  addContactsToCustomer,
  updateContactsToCustomer,
  deleteContactsToCustomer,
  getKeyCharacteristics,
  clearFleets,
  makeModels,
  getFleetModels,
  listMakeModels,
  listProducts,
  exportFleets,
  getFleets,
  getFleetFilters,
  getProducts,
  cloneFleets,
  addFleets,
  updateFleets,
  deleteFleets,
  addProduct,
  updateProduct,
  deleteProducts,
  deleteAllProducts,
  getProductCodes,
  getEquipments,
  addEquipment,
  updateEquipment,
  deleteEquipments,
  transferEquipments,
  updateKeyCharacteristics,
  deleteSubsidiary,
};