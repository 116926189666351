import {
  Checkbox,
  DatePicker,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IColumn,
  IconButton,
  IDropdownOption,
  MessageBarType,
  PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { isNil, isNumber, uniqueId } from 'lodash';
import moment from 'moment';
import { FC, FormEvent, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import apiService from '../../../../../../api';
import { auth_spareStock_postSpareStock } from '../../../../../../consts/programKeys';
import { pageSizes } from '../../../../../../consts/recordKeeping';
import useKeyPress from '../../../../../../hooks/useKeyPress/useKeyPress';
import useNotifications from '../../../../../../hooks/useNotifications';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { addPostSpareStockItems, clearPostSpareStockItems, editPostSpareStockItem, postSpareStockSelector, removePostSpareStockItems, replacePostSpareStockItems } from '../../../../../../redux/postSpareStockSlice';
import { customerInformationSelector, customerSelector, locationSelector, setTireForReinstate } from '../../../../../../redux/recordKeepingSlice';
import { transformDate } from '../../../../../../shared/transformDate';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';
import Pagination from '../../../../../Pagination/Pagination';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import ViewTireDetailsModal from '../../../../ViewTireDetailsModal/ViewTireDetailsModal';
import { columns } from './consts';
import { IPostSpareStock } from './IPostSpareStock';
import { IPostSpareStockProps } from './IPostSpareStockProps';
import { IPostSpareStockState } from './IPostSpareStockState';
import styles from './PostSpareStock.module.scss';


const PostSpareStock: FC<IPostSpareStockProps> = (): ReactElement => {

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        sortItems(currColumn.fieldName, currColumn.isSortedDescending);
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const sortItems = (fieldName: any, isSortedDescending: boolean) => dispatch(replacePostSpareStockItems(
    [...allItems].sort((a: any, b: any) =>
      isNumber(a[fieldName]) && isNumber(b[fieldName]) ? sortNumbers(a[fieldName], b[fieldName], isSortedDescending)
        : sortStrings(`${a[fieldName]}`, `${b[fieldName]}`, isSortedDescending))));

  const sortStrings = (a: string, b: string, isDesc: boolean) => isDesc ? b.localeCompare(a) : a.localeCompare(b);
  const sortNumbers = (a: number, b: number, isDesc: boolean) => isDesc ? b - a : a - b;


  const { addNotification } = useNotifications();

  const dispatch = useDispatch();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);
  const { lastAnnualDone } = useSelector(customerInformationSelector);

  const allItems: Array<IPostSpareStock> = useSelector(postSpareStockSelector);

  const [state, setState] = useState<IPostSpareStockState>({
    selectedItems: [],
    loading: false,
  });
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [parsedErrors, setParsedErrors] = useState<any>([]);
  const [takenInfo, setTakenInfo] = useState<any>({ takenOn: new Date(), takenBy: '' });
  const [addFields, setAddFields] = useState<any>({ prefix: '', fromBrandNumber: null, toBrandNumber: null, suffix: '' });
  const [useAnnual, { toggle: toggleUseAnnual }] = useBoolean(false);
  const [controlPrefix, setControlPrefix] = useState<string>('');
  const [detailsVisible, { toggle: toggleDetailsVisible }] = useBoolean(false);
  const [showDeletingConfirmation, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [nanValue, setNanValue] = useState<any>('');

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_spareStock_postSpareStock);

  const onChangeTakenBy = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, takenBy: string | undefined) => {
    setTakenInfo((prev: any) => ({ ...prev, takenBy }));
  };

  const onChangeAddFieldPrefix = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, prefix: string | undefined) => {
    setAddFields((prev: any) => ({ ...prev, prefix: prefix?.toUpperCase() }));
  };

  const onChangeAddFieldFromBrandNumber = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, fromBrandNumber: string | undefined) => {
    setAddFields((prev: any) => ({ ...prev, fromBrandNumber }));
  };

  const onChangeAddFieldToBrandNumber = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, toBrandNumber: string | undefined) => {
    setAddFields((prev: any) => ({ ...prev, toBrandNumber }));
  };

  const onChangeAddFieldSuffix = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, suffix: string | undefined) => {
    setAddFields((prev: any) => ({ ...prev, suffix: suffix?.toUpperCase() }));
  };

  const onChangeControlPrefix = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: any) => {
    setControlPrefix(value);
    state.selectedItems.forEach(el => allItems.find(item => item.id === el && setItemToBeEdited(item.id, 'pfx', value)));
  };

  const setItemToBeEdited = (id: number, field: string, value: any) => dispatch(editPostSpareStockItem({ id, field, value }));

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {

      const payload: any = {
        data: allItems, headerFields: [], locationId, customerId,
      };
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.spareStock.printExcelSpareStock({ ...payload }) :
        await apiService.spareStock.printPdfSpareStock({ ...payload });
      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Printing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };


  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    item && setCountOnPage(item);
  };

  const handleSelect = (e: any, item: any) => {
    const selectedRows = [...state.selectedItems];
    if (e.target.checked) {
      selectedRows.push(item);
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows.filter(row => row !== item) }));
    }
  };

  const handleSelectAll = (e: any, items: Array<any>) => {
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedItems: items })) : setState((prev: any) => ({ ...prev, selectedItems: [] }));
  };

  const parseErrors = (id: number, field: string) => {
    const customError = parsedErrors?.filter((error: { id: number; field: string; }) => error.id === id && error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  const highlightRowIfError = (item: any) => {
    if (parsedErrors.length > 0) {
      return parsedErrors.filter((error: { id: any; }) => error.id === item.id).length > 0;
    }
  };

  const openViewTireDetailsWindow = (item: any) => {
    const { pfx, bno, sfx } = item;
    dispatch(setTireForReinstate({ pfx, bno, sfx }));
    toggleDetailsVisible();
  };

  const validatePositiveNumber = (value: number) => value && value <= 0;

  const validateToBrandNumber = parseInt(addFields.fromBrandNumber) > parseInt(addFields.toBrandNumber);

  const shouldDisableAddButton = !(addFields.prefix && addFields.fromBrandNumber && takenInfo.takenBy)
    || validatePositiveNumber(parseInt(addFields.fromBrandNumber)) || validateToBrandNumber;

  const handleAddBatch = async () => {
    setState(prev => ({ ...prev, loading: true }));
    dispatch(addPostSpareStockItems(Array.from({ length: addFields.toBrandNumber ? (addFields.toBrandNumber - addFields.fromBrandNumber) + 1 : 1 }, (_el, i) => ({
      id: `newSpareStock-${uniqueId()}`,
      pfx: addFields.prefix,
      bno: parseInt(addFields.fromBrandNumber) + i,
      sfx: addFields.suffix,
      annual: useAnnual,
      takenBy: takenInfo.takenBy,
      takenOn: transformDate(moment(takenInfo.takenOn).format('MM/DD/YYYY')),
    }))));
    await paginationProps.onChangePage(1);
    setState(prev => ({ ...prev, loading: false }));
    setAddFields((prev: any) => ({ ...prev, fromBrandNumber: null, toBrandNumber: null, suffix: '' }));
    document.getElementById('fromBrandNumber')?.focus();
  };

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    setState(prev => ({ ...prev, loading: true }));
    dispatch(removePostSpareStockItems(state.selectedItems));
    addNotification({
      text: 'Selected tire(s) were successfully deleted.',
      type: MessageBarType.success,
    });
    await paginationProps.onChangePage(1);
    setState(prev => ({ ...prev, loading: false, selectedItems: [] }));
  };

  const handleSave = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.spareStock.postSpareStockBatchSave(
        allItems.map(el => ({ ...el, id: undefined })),
        customerId,
        locationId,
      );
      dispatch(clearPostSpareStockItems());
      addNotification({
        text: 'Spare Stock tires were successfully saved.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          setParsedErrors(response.data.state);
          return addNotification({
            text: 'Unable to save changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Spare Stock saving error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  useKeyPress({
    handleAdd: handleAddBatch,
  });

  useEffect(() => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1, total: Math.ceil(allItems.length / +countOnPage.key) }));
  }, [countOnPage, allItems.length]);

  useEffect(() => {
    dispatch(clearPostSpareStockItems());
  }, []);

  useEffect(() => {
    const mappedSelectedItems: any[] = state.selectedItems.map(el => allItems.find(item => item.id === el));
    if (state.selectedItems.length > 0 && mappedSelectedItems.every((el, i, arr) => el.pfx === arr[0].pfx))
      setControlPrefix(mappedSelectedItems[0]?.pfx);
    else
      setControlPrefix('');
  }, [state.selectedItems]);
  
  useEffect(() => {
    if(allItems.length != 0) {
      if(isNaN(allItems[allItems.length - 1].bno)) {
        const selectedRows = [...state.selectedItems];
        selectedRows.push(allItems[allItems.length - 1].id);
        setState((prev: any) => ({ ...prev, selectedItems: selectedRows }));
      } 
    }
    setNanValue(allItems?.find(({bno})=> isNaN(bno)));
  }, [allItems]);
  return (
    <div>
      <div>
        <div className={styles.actionsBlock}>
          <div className={styles.addBlock}>
            <Text variant="xLarge" className={styles.highlight}>Add a series</Text>
            <SeparatorGy />
            <div className={styles.addingFields}>
              <div>
                <div className={styles.fromToFields}>
                  <TextField
                    id='fromPrefix'
                    className={styles.addingField}
                    label="From Prefix"
                    value={addFields.prefix}
                    onChange={onChangeAddFieldPrefix}
                    autoFocus
                    required
                  />
                  <TextField
                    id='fromBrandNumber'
                    className={styles.addingField}
                    label="From Brand Number"
                    min="1"
                    errorMessage={validatePositiveNumber(parseInt(addFields.fromBrandNumber)) ? 'Should be positive' : ''}
                    value={addFields.fromBrandNumber}
                    onChange={onChangeAddFieldFromBrandNumber}
                    required
                  />
                  <TextField
                    id="fromSuffix"
                    className={styles.addingField}
                    label="From Suffix"
                    value={addFields.suffix}
                    onChange={onChangeAddFieldSuffix}
                  />
                </div>
              </div>
              <div>
                <div className={styles.fromToFields}>
                  <TextField
                    className={styles.addingField}
                    label="To Prefix"
                    value={addFields.prefix}
                    disabled
                  />
                  <TextField
                    id='toBrandNumber'
                    className={styles.addingField}
                    label="To Brand Number"
                    min={'0'}
                    value={addFields.toBrandNumber}
                    onChange={onChangeAddFieldToBrandNumber}
                    errorMessage={
                      validatePositiveNumber(parseInt(addFields.toBrandNumber)) ?
                        'Should be positive'
                        : (validateToBrandNumber ? 'Should be more than From Brand Number' : '')
                    }
                  />
                  <TextField
                    className={styles.addingField}
                    label="To Suffix"
                    value={addFields.suffix}
                    disabled
                  />
                </div>
              </div>
            </div>
            <PrimaryButton
              id="addButton"
              className={styles.addButton}
              text="Add"
              disabled={shouldDisableAddButton || !userPermissions.isWrite}
              onClick={handleAddBatch}
            />
          </div>
          <div className={styles.annualInfo}>
            <Text variant="xLarge" className={styles.highlight}>Spare Stock Inventory</Text>
            <SeparatorGy />
            <div className={styles.annualFields}>
              <Checkbox
                id="convertToAnual"
                className={styles.annualField}
                label="Convert to Annual"
                checked={useAnnual}
                onChange={toggleUseAnnual}
              />
              <DatePicker
                id="takenOn"
                className={styles.annualField}
                showMonthPickerAsOverlay={true}
                label="Taken On"
                value={takenInfo.takenOn}
                onSelectDate={(date: Date | null | undefined) => setTakenInfo((prev: any) => ({ ...prev, takenOn: date }))}
                formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                maxDate={moment().utc().toDate()}
              />
              <TextField
                id="takenBy"
                className={styles.annualField}
                label="Taken By"
                value={takenInfo.takenBy}
                onChange={onChangeTakenBy}
                required
              />
              <Text className={classNames(styles.annualAsInfo, styles.highlight)} variant="medium">
                Annual as of: <br />
                {moment(lastAnnualDone).format('MM/DD/YYYY')}
              </Text>
            </div>
          </div>
        </div>
        <div className={styles.tableHeading}>
          <TextField
            id='controlPrefix'
            label="Control Prefix"
            value={controlPrefix}
            onChange={onChangeControlPrefix}
          />
        </div>
        <div className={styles.tableHeading}>
          <div>
            <Text variant="xLarge" className={styles.highlight}>Spare Stock</Text>
            <SeparatorGy vertical />
            <Text variant="xLarge" className={styles.highlight}>{allItems.length} found</Text>
          </div>
          <div>
            <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
            <Dropdown
              options={pageSizes.map(pageSize => ({
                key: pageSize,
                text: pageSize.toString(),
              }))}
              defaultSelectedKey={pageSizes[0]}
              selectedKey={countOnPage?.key}
              onChange={onChangeCountOnPage} />
            <SeparatorGy vertical />
            <Text variant="large" className={styles.highlight}>{state.selectedItems.length} items selected</Text>
            <SeparatorGy vertical />
            <IconButton
              id="toggleDeletingConfirmationButton"
              disabled={!state.selectedItems.length || !userPermissions.isWrite}
              iconProps={{ iconName: 'Delete' }}
              onClick={toggleDeletingConfirmation} />
          </div>
        </div>
        <div className={styles['table-wrapper']}>
          <table className={styles.spareStockTable}>
            <thead>
              <tr>
                <th className={styles.viewColumn}></th>
                {
                  columnsState.map(item => (
                    <th
                      key={item.name}
                      className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined}
                      onClick={() => onColumnClick(item)}>
                      {item.name}
                    </th>
                  ))
                }
                <th>
                  <div className={styles.round}>
                    <input
                      type="checkbox"
                      id="all"
                      checked={allItems.length !== 0 &&
                        (state.selectedItems.length === +countOnPage.key || state.selectedItems.length === allItems.length)}
                      onChange={(e) =>
                        handleSelectAll(e, allItems
                          .slice((paginationProps.current - 1) * +countOnPage.key, (paginationProps.current - 1) * +countOnPage.key + +countOnPage.key).map(el => el.id))}
                    />
                    <label htmlFor="all"></label>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                allItems.slice((paginationProps.current - 1) * +countOnPage.key, (paginationProps.current - 1) * +countOnPage.key + +countOnPage.key).map(item => (
                  <tr key={item.id}
                    className={classNames(state.selectedItems.includes(item.id) ? styles.trSelected : styles.trBasic,
                      highlightRowIfError(item) ? styles.trError : styles.trBasic)}
                  >
                    <td className={styles.viewCell}>
                      <IconButton
                        id="viewButton"
                        className={classNames(styles.viewButton)}
                        iconProps={{ iconName: 'View' }}
                        onClick={() => openViewTireDetailsWindow(item)}
                      />
                    </td>
                    <td>
                      <TextField
                        id="prefix"
                        styles={{ fieldGroup: { border: '1px solid transparent' } }}
                        value={item.pfx}
                        onChange={(e, pfx) => setItemToBeEdited(item.id, 'pfx', pfx)}
                        errorMessage={parseErrors(item.id, 'pfx')}
                      />
                    </td>
                    <td>
                      <TextField
                        id="bnd"
                        styles={{ fieldGroup: { border: '1px solid transparent' } }}
                        value={item.bno + ''}
                        min="1"
                        onChange={(e, bno) => setItemToBeEdited(item.id, 'bno', bno)}
                        errorMessage={parseErrors(item.id, 'bno')}
                      />
                    </td>
                    <td>
                      <TextField
                        id="suffix"
                        styles={{ fieldGroup: { border: '1px solid transparent' } }}
                        value={item.sfx}
                        onChange={(e, sfx) => setItemToBeEdited(item.id, 'sfx', sfx)}
                        errorMessage={parseErrors(item.id, 'sfx')}
                      />
                    </td>
                    <td>
                      <div className={styles.round}>
                        <input type="checkbox" id={item.id} checked={state.selectedItems.includes(item.id)} onChange={(e) => handleSelect(e, item.id)} />
                        <label htmlFor={item.id}></label>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <SeparatorGy />
        <Pagination {...paginationProps} />
        <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
          <DefaultButton
            id="printButton"
            onClick={toggleShowPrintExport}
            text="Print/Export"
          />
          <PrimaryButton
            id="saveButton"
            onClick={handleSave}
            text="Save"
            disabled={!(locationId && userPermissions.isWrite) || state.selectedItems.length != 0}
          /> 
        </div>
        {
          !isNil(nanValue) &&
          <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}> 
            <Text variant='small' className = {styles.warningMsg}> All "NaN" values must be resolved before Saving </Text>
          </div>
        }
      </div>
      <Dialog
        hidden={!showDeletingConfirmation}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to delete (${state.selectedItems.length}) items?`,
        }}
        modalProps={{ isBlocking: true }}>
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      {detailsVisible && <ViewTireDetailsModal isOpened={detailsVisible} onDismiss={toggleDetailsVisible} />}
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint} />
      {state.loading && <LoadingScreen />}
    </div>
  );
};

export default PostSpareStock;