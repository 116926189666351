
import { FunctionComponent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import { isNil } from 'lodash';

import useNotifications from '../../../../../../../hooks/useNotifications';
import { useField } from '../../../../../../../hooks/useField';
import { locationSelector } from '../../../../../../../redux/recordKeepingSlice';

import { ISeries } from '../utils';

import { FROM_BRAND_NUMBER_FIELDS, TO_BRAND_NUMBER_FIELDS } from './consts';

import styles from './AddSeriesComponent.module.scss';

interface AddSeriesComponentProps {
  onSubmit: (rowsToAdd: any) => void;
  hasEmptyFormData: boolean;
}

const AddSeriesComponent: FunctionComponent<AddSeriesComponentProps> = ({ onSubmit, hasEmptyFormData }) => {

  const [series, setSeries] = useState<Array<ISeries>>([]);
  const [error, setError] = useState<boolean>(false);
  const maxseries = 100;

  const { id: locationId } = useSelector(locationSelector);
  const { addNotification } = useNotifications();

  const fromPrefix = useField(FROM_BRAND_NUMBER_FIELDS.fromPrefixDataField);
  const fromBrandNumber = useField(FROM_BRAND_NUMBER_FIELDS.fromBrandNumberDataField);
  const fromSufix = useField(FROM_BRAND_NUMBER_FIELDS.fromSufixDataField);

  const toPrefix = useField(TO_BRAND_NUMBER_FIELDS.toPrefixDataField);
  const toBrandNumber = useField(TO_BRAND_NUMBER_FIELDS.toBrandNumberDataField);
  const toSufix = useField(TO_BRAND_NUMBER_FIELDS.toSufixDataField);

  const getPrefixValue = fromPrefix.getValue();
  const getSufixValue = fromSufix.getValue();


  const disableAddBtn = hasEmptyFormData || series.length == maxseries || !fromPrefix.getValue() || !fromBrandNumber.getValue();

  const validateData = () => {
    const hasError = toBrandNumber.getValue() && +toBrandNumber.getValue() < +fromBrandNumber.getValue();
    setError(hasError);
    return !hasError;
  };

  const buildSeries = () => {
    if (!validateData()) return;
    const initialBrandNumber = +fromBrandNumber.getValue();
    const finalBrandNumber = +toBrandNumber.getValue() || initialBrandNumber;
    const totalCurrentSeries = series.length;
    let totalSeriesToAdd = finalBrandNumber - initialBrandNumber;

    if (totalCurrentSeries + totalSeriesToAdd > maxseries) totalSeriesToAdd = maxseries - totalCurrentSeries;

    const currentSeries = [...series];
    for (let i = initialBrandNumber; i <= initialBrandNumber + totalSeriesToAdd; i++) {
      currentSeries.push({
        intireBpfx: fromPrefix.getValue().toUpperCase(),
        intireBno: i,
        intireBsfx: fromSufix.getValue() ? fromSufix.getValue().toUpperCase() : fromSufix.getValue(),
      });
    }

    onSubmit(currentSeries);
  };

  useEffect(() => {
    if (!disableAddBtn && isNil(locationId))
      addNotification({
        text: 'Please, select a location for adding a tire.',
        type: MessageBarType.error,
      });
  }, [disableAddBtn]);

  return (
    <>
      <div className="ms-Grid-row marginTop20">
        <div className={classNames('ms-Grid-col', 'ms-sm4')}>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <Text variant="xLarge" className={styles.highlight}>From</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm4', fromPrefix.getRequiredClassName())}>
            <TextField {...fromPrefix} value={fromPrefix?.value?.toUpperCase()} />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm4', fromBrandNumber.getRequiredClassName())}>
            <TextField {...fromBrandNumber} className={error ? styles.errorInput : ''} />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm4')}>
            <TextField {...fromSufix} value={fromSufix?.value?.toUpperCase()} />
          </div>
        </div>

        <div className={classNames('ms-Grid-col', 'ms-sm6')}>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <Text variant="xLarge" className={styles.highlight}>To (Series)</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm3')}>
            <TextField {...toPrefix} value={fromPrefix?.value?.toString()?.toUpperCase()} min={+fromBrandNumber.getValue()} />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm3')}>
            <TextField {...toBrandNumber} className={error ? styles.errorInput : ''} />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm3')}>
            <TextField {...toSufix} value={fromSufix?.value?.toString()?.toUpperCase()} />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm', styles.addContainerBtn)}>
            <div className={classNames('ms-Grid-row', styles.addButtonContainer)}>
              <PrimaryButton
                id='AddSeriesBtn'
                text="+ Add"
                disabled={disableAddBtn || isNil(locationId)}
                onClick={buildSeries}
              />
            </div>
          </div>
        </div>
        <div className={classNames('ms-Grid-col', 'ms-sm12')}>
          {error && (
            <Text variant="medium" color='danger' className={error ? styles.errorLabel : ''}>To Brand number value must be greather than From Brand number value</Text>
          )}
        </div>

      </div>
    </>
  );
};

export default AddSeriesComponent;