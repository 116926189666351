import { FC, useEffect, useState } from 'react';
import { IEditPlaceRetreadProps } from './IEditPlaceRetreadProps';
import apiService from '../../../../../../../../api';
import { IEditPlaceRetreadState } from './IEditPlaceRetreadState';
import useNotifications from '../../../../../../../../hooks/useNotifications';
import { DefaultButton, MessageBarType, PrimaryButton, TextField } from '@fluentui/react';
import LoadingScreen from '../../../../../../../LoadingScreen/LoadingScreen';
import { orderDetailsColumns, emptyPlaceRetreadOrder, emptyPlaceRetreadOrderItem } from './consts';
import { useBoolean } from '@fluentui/react-hooks';
import Stepper from '../../../../../../../../shared/Stepper';
import HistoryAndMessages from '../../../../Components/HistoryAndMessages/HistoryAndMessages';
import ShippingInstructions from '../../../../Components/ShippingInstructions/ShippingInstructions';
import { orderSteps } from '../../../../consts';
import OrderDetails from './Components/OrderDetails/OrderDetails';
import styles from './EditPlaceRetread.module.scss';
import classNames from 'classnames';
import SeparatorGy from '../../../../../../../SeparatorGy/SeparatorGy';
import OrderHeader from './Components/OrderHeader/OrderHeader';
import { downloadFile, printingTypes } from '../../../../../../../PrintingModal/consts';
import PrintingModal from '../../../../../../../PrintingModal/PrintingModal';


const EditPlaceRetread: FC<IEditPlaceRetreadProps> = ({order, onDismiss, mode}) => {
  const [state, setState] = useState<IEditPlaceRetreadState>({
    orders: [],
    loading: false,
  });

  const [currentStep, setCurrentStep] = useState<number>(0);

  const [typeCodes, setTypeCodes] = useState<any>([]);
  const [plants, setPlants] = useState<any>([]);
  const [parsedErrors, setParsedErrors] = useState<any>([]);

  const [orderToBeAdded, setOrderToBeAdded] = useState<any>(emptyPlaceRetreadOrder);
  const [orderItemToBeAdded, setOrderItemToBeAdded] = useState<any>(emptyPlaceRetreadOrderItem);

  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [isFirstLoading, { setTrue: wasFirstLoading, setFalse: wasNotFirstLoading }] = useBoolean(false);

  const { addNotification } = useNotifications();

  const renderStepAccordingKey = (stepKey: number) => {
    switch (stepKey) {
      case 0:
        return <OrderDetails
          isFirstLoading={isFirstLoading}
          wasNotFirstLoading={wasNotFirstLoading}
          typeCodes={typeCodes}
          setTypeCodes={setTypeCodes}
          orderToBeAdded={orderToBeAdded}
          setOrderToBeAdded={setOrderToBeAdded}
          orderItemToBeAdded={orderItemToBeAdded}
          setOrderItemToBeAdded={setOrderItemToBeAdded}
          parsedErrors={parsedErrors}
          mode={mode} />;
      case 1:
        return <ShippingInstructions orderToBeAdded={order} mode={mode} />;
      case 2:
        return <HistoryAndMessages orderToBeAdded={order} />;
      default:
        return stepKey;
    }
  };

  const fetchRetreadOrder = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await apiService.tireOrders.getRetreadOrder(order.id);
      setOrderToBeAdded(data.order);
      setOrderItemToBeAdded(data.orderItem);
      wasFirstLoading();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching retread order error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSaveOrder = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.tireOrders.saveRetreadOrder(
        orderToBeAdded,
        orderItemToBeAdded,
        true,
      );
      addNotification({
        text: 'Order tires were successfully saved.',
        type: MessageBarType.success,
      });
      setParsedErrors([]);
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          setParsedErrors(response.data.state);
          return addNotification({
            text: 'Unable to save changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Order saving error: ${response?.data?.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const requestData = {
        id: orderToBeAdded.id,
      };
      
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.tireOrders.printExcelRetread(requestData, []) :
        await apiService.tireOrders.printPdfRetread(requestData, []);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const renderTable = () => {
    return (
      <>
        <table className={styles.editableTable}>
          <thead>
            <tr>
              {
                orderDetailsColumns.map(item => (
                  <th key={item.name}>{item.name}</th>
                ))
              }
            </tr>
          </thead>
          <tbody>
            <tr>
              {
                orderDetailsColumns.map((column) => (
                  <td>
                    {orderItemToBeAdded[column.fieldName]}
                  </td>
                ))
              }
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  useEffect(() => {
    order.id !== null && fetchRetreadOrder();
  }, [order.id]);

  return (
    <>
      <div className={classNames(styles.mainBlock)}>
        <div>
          <div>
            <Stepper 
              currentStep={currentStep} 
              steps={orderSteps} 
              setCurrentStep={setCurrentStep}
            /> 
            <div>{renderStepAccordingKey(currentStep)}</div>
          </div>
          <SeparatorGy vertical />
          <div>
            <OrderHeader
              plants={plants}
              setPlants={setPlants}
              orderToBeAdded={orderToBeAdded} 
              setOrderToBeAdded={setOrderToBeAdded} 
              orderItemToBeAdded={orderItemToBeAdded}  
              setOrderItemToBeAdded={setOrderItemToBeAdded}
              parsedErrors={parsedErrors}
              mode={mode} 
            />
          </div>
        </div>
        {renderTable()} 
      </div> 
      <div className={styles.buttonsWrapper}>
        { 
          mode !== 'view' && currentStep === 0 ?
            <div>
              <PrimaryButton text="Save Order" onClick={handleSaveOrder} />
            </div> :  
            <div>
              <DefaultButton onClick={toggleShowPrintExport} text="Print/Export" />
            </div>
        } 
      </div>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      {state.loading && <LoadingScreen />}      
    </>
  );
};
    
export default EditPlaceRetread;