export const unflageConsignedTiresColumns = [
  {
    key: 'column1',
    name: 'Prefix',
    fieldName: 'pfx',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Brand Number',
    fieldName: 'bno',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'Suffix',
    fieldName: 'sfx',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'OOS Status',
    fieldName: 'oosSt',
    isSorted: false,
    isSortedDescending: false,
  },
];
