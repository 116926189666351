import { Text } from '@fluentui/react';
import classNames from 'classnames';
import React, {
  FC,
  ReactElement,
} from 'react';
import { useSelector } from 'react-redux';

import { get, isNil } from 'lodash';

import { customerInformationSelector } from '../../../../redux/recordKeepingSlice';

import SeparatorGy from '../../../SeparatorGy/SeparatorGy';

import { ITabHeaderProps } from './ITabHeaderProps';

import styles from './TabHeader.module.scss';
import moment from 'moment';

const TabHeader: FC<ITabHeaderProps> = (
  { title, details },
): ReactElement => {
  const customerDetails = useSelector(customerInformationSelector);

  return (
    <>
      <SeparatorGy />
      <div className={classNames('ms-Grid-row', `${!title && styles.detailsOnly}`, styles.flex, styles.centeredAlignItems)}>
        {title && (
          <div className={classNames('ms-Grid-col', 'ms-lg7', 'ms-xs12')}>
            <Text variant="xxLarge" block className={styles.highlight}>
              {title}
            </Text>
          </div>
        )}
        {details && <div className={classNames('ms-Grid-col', 'ms-lg5', 'ms-xs12')}>
          <div
            className={classNames(styles.flex, styles.spaceBetweenContent)
            }>
            <div>
              <Text variant="large" block className={styles.highlight}>Last Mileage Run</Text>
              <Text variant="mediumPlus" block className={styles.highlight}>{!isNil(get(customerDetails, 'lastMileageRun', null)) && moment(customerDetails.lastMileageRun).format('MM/DD/YYYY')}</Text>
            </div>
            <div>
              <Text variant="large" block className={styles.highlight}>Last Mileage Billing Done On</Text>
              <Text variant="mediumPlus" block className={styles.highlight}>{!isNil(get(customerDetails, 'lastMileageBillingDone', null)) && moment(customerDetails.lastMileageBillingDone).format('MM/DD/YYYY')}</Text>
            </div>
            <div>
              <Text variant="large" block className={styles.highlightDetails}>Billing Done</Text>
              <Text variant="mediumPlus" block className={styles.highlightDetails}>{get(customerDetails, 'billingDone', null)}</Text>
            </div>
          </div>
          {title && <SeparatorGy />}
        </div>}
      </div>
      {title && <SeparatorGy />}
    </>
  );
};

TabHeader.defaultProps = {
  details: false,
};

export default TabHeader;
