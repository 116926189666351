import { FunctionComponent } from 'react';
import OrganizeFleetQuote from './OrganizeFleet/index';
import History from './History/History';
import Forecast from './Forecast/Forecast';
import CostDetails from './CostDetails/CostDetails';
import {
  auth_quote_costDetails,
  auth_quote_forecast,
  auth_quote_history,
  auth_quote_organizeFleet,
} from '../../../../consts/programKeys';

export enum QUOTE_TABS {
  ORGANIZE_FLEET = 'organizeFleet',
  COST_DETAILS = 'costDetails',
  HISTORY = 'history',
  FORECAST = 'forecast',
};

export const quoteTabs: QuoteTabs = {
  organizeFleet: {
    id: QUOTE_TABS.ORGANIZE_FLEET,
    accessKey: auth_quote_organizeFleet,
    name: 'Organize Fleet',
    component: OrganizeFleetQuote,
    actionType: ['edit'],
    subTabs: null,
  },
  costDetails: {
    id: QUOTE_TABS.COST_DETAILS,
    accessKey: auth_quote_costDetails,
    name: 'Cost Details',
    component: CostDetails,
    actionType: ['edit'],
    subTabs: null,
  },
  history: {
    id: QUOTE_TABS.HISTORY,
    accessKey: auth_quote_history,
    name: 'History',
    component: History,
    actionType: ['edit'],
    subTabs: null,
  },
  forecast: {
    id: QUOTE_TABS.FORECAST,
    name: 'Forecast',
    accessKey: auth_quote_forecast,
    component: Forecast,
    actionType: ['edit'],
    subTabs: null,
  },
};

export const getTabById: any = (tabId: string) => Object.values(quoteTabs).filter((tab) => tab.id === tabId)[0];
export interface QuoteTabs {
  organizeFleet: TabComponent;
  costDetails: TabComponent;
  history: TabComponent;
  forecast: TabComponent;
}

export interface TabComponent {
  id: string,
  name: string,
  accessKey?: string,
  component: FunctionComponent<any> | null
  actionType: string[];
  subTabs: any;
}