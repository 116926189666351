import { DatePicker, DefaultButton, IconButton, MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { debounce } from 'lodash';
import moment from 'moment';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import apiService from '../../../../../../api';
import { auth_inTransitTires_add } from '../../../../../../consts/programKeys';
import { useField } from '../../../../../../hooks/useField';
import useNotifications from '../../../../../../hooks/useNotifications';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { INotification } from '../../../../../../models/INotification';
import { ITemporaryTires } from '../../../../../../models/ITemporaryTires';
import { TemporaryTireStatusesEnum } from '../../../../../../models/TemporaryTireStatusesEnum';
import { customerSelector, locationSelector } from '../../../../../../redux/recordKeepingSlice';
import { inTransitOrderCodeSelector, setActiveTab } from '../../../../../../redux/tireOrderingSlice';
import DataGridComponent from '../../../../../../shared/DataGridComponent';
import DialogComponent from '../../../../../../shared/DialogComponent';
import { invoiceDelivNoteEMHandler } from '../../../../../../shared/TextFieldValidation';
import { transformDate } from '../../../../../../shared/transformDate';
import TypeCodeModalComponent from '../../../../../../shared/TypeCodeModalComponent';
import WarehouseModalComponent from '../../../../../../shared/WarehouseModalComponent';
import AutoCompleteField from '../../../../../Common/Search/AutoCompleteField';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import { tireOrderTabs } from '../../../../consts';
import { intransitPageSizes } from '../../consts';
import styles from './AddComponent.module.scss';
import AddSeriesComponent from './AddSeriesComponent';
import { IAddComponentProps } from './IAddComponentProps';
import { inTransitTiresHeadCell, IN_TRANSIT_DETAILS_FIELDS, ISeries, ITemporaryTiresToAdd, ORDER_DETAILS_FIELDS } from './utils';

const AddComponent: FunctionComponent<IAddComponentProps> = () => {
  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_inTransitTires_add);
  const { addNotification } = useNotifications();
  const dispatch = useDispatch();
  const [headCells] = useState(inTransitTiresHeadCell);
  const [temporaryTires, setTemporaryTires] = useState<Array<ITemporaryTires>>([]);
  const [temporaryTiresSelected, setTemporaryTiresSelected] = useState<Array<ITemporaryTires>>([]);
  const [temporaryTiresToUpdate, setTemporaryTiresToUpdate] = useState<Array<ITemporaryTires>>([]);
  const [totalFound, setTotalFound] = useState(0);
  const [typeCodeSelected, setTypeCodeSelected] = useState<any>({});
  const [plantSelected, setPlantSelected] = useState<any>({});
  const { regionCode, id: customerId, corrAddressCountry: country } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });
  const [paginationPropsAvailable, setPaginationPropsAvailable] = useState<any>();
  const [typeCodeList, setTypeCodeList] = useState([]);
  const [plantsList, setPlantsList] = useState([]);
  const [isScreenLoading, setIsScreenLoading] = useState(false);

  // toggle
  const [reloadControlsTable, { toggle: toggleReloadControlsTable }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [showTypeCodeModal, { toggle: toggleShowTypeCodeModal }] = useBoolean(false);
  const [showWarehouseModal, { toggle: toggleShowWarehouseModal }] = useBoolean(false);
  const [isLoading, { toggle: toggleShowLoading }] = useBoolean(false);
  const [isUpdatingDialogVisible, { toggle: toggleUpdatingConfirmation }] = useBoolean(false);
  const [isSubmitingDialogVisible, { toggle: toggleSubmitingConfirmation }] = useBoolean(false);
  const [isLoadingFetchTypeCode, { toggle: toggleIsLoadingFetchTypeCode }] = useBoolean(false);
  const [isLoadingFetchPlants, { toggle: toggleIsLoadingFetchPlants }] = useBoolean(false);
  const [isFirstLoading, { setFalse: wasFirstLoading }] = useBoolean(true);

  const orderCode = useSelector(inTransitOrderCodeSelector);

  const fetchData = async (
    pagination: any = paginationPropsAvailable.pagination,
    filters: any = { customerId, orderId: orderCode?.id || ' ', status: TemporaryTireStatusesEnum.PENDING_ADD },
    sortOrder: any = paginationPropsAvailable.sortOrder,
  ) => {
    try {
      const { data }: any = await apiService.inTransitTiresAPI.getTemporaryTires(pagination, filters, sortOrder, isFirstLoading);
      wasFirstLoading();
      setTemporaryTires(data?.data);
      setTotalFound(data?.total?.found);
    } catch (error) {
      setNotification({ text: 'Fetching tires error', type: MessageBarType.error });
    }
  };

  const fetchTypeCode = async (pagination: any, filters?: any, sortOrder?: any) => {
    try {
      toggleIsLoadingFetchTypeCode();
      const { data }: any = await apiService.maintainTypeCodes.get({ ...filters, country }, pagination, sortOrder, customerId);
      setTypeCodeList(data?.data);
    } catch (error) {
      setNotification({ text: 'Fetching typeCodes error', type: MessageBarType.error });
    } finally {
      toggleIsLoadingFetchTypeCode();
    }
  };

  const fetchPlants = async (pagination: any, filters?: any, sortOrder?: any) => {
    try {
      toggleIsLoadingFetchPlants();
      if (typeCodeSelected?.type) filters = { ...filters, type: typeCodeSelected.type == 'R' ? 'RETREAD' : 'WAREHOUSE', active: true };
      const { data }: any = await apiService.plantWareHouseAPI.getPlantsToAdd(pagination, filters, sortOrder);
      setPlantsList(data?.data);
    } catch (error) {
      setNotification({ text: 'Fetching Plants/Warehouse error', type: MessageBarType.error });
    } finally {
      toggleIsLoadingFetchPlants();
    }
  };

  const updateTemporaryTires = async () => {
    try {
      await apiService.inTransitTiresAPI.updateTemporaryTires(temporaryTiresToUpdate);
      setNotification({ text: `${temporaryTiresToUpdate.length} tires updated successfully`, type: MessageBarType.success });
    } catch (error) {
      setNotification({ text: 'Updating tires error', type: MessageBarType.error });
    }
  };

  const deleteTemporaryTires = async (idList: Array<any>) => {
    try {
      await apiService.inTransitTiresAPI.deleteTemporaryTires(idList);
      setNotification({ text: `${idList.length} tires deleted succesfully`, type: MessageBarType.success });
    } catch (error) {
      setNotification({ text: 'Deleting tires error', type: MessageBarType.error });
    }
  };

  const submitTemporaryTires = async (bodyRequest: any) => {
    setIsScreenLoading(true);
    try {
      await apiService.inTransitTiresAPI.submitTires(bodyRequest);
      setNotification({
        text: `${bodyRequest?.inTransitTiresIds.length} tires submitted successfully`,
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      switch (response?.status) {
        case 400:
          return setNotification({
            text: `Bad request: ${response.data.state[0].message}`,
            type: MessageBarType.error,
          });
        default:
          return setNotification({
            text: response?.status === 499 && response?.data?.state[0].message == 'Duplicate input records' ? 'Duplicate brand found.' : `Submitting tires error: ${response?.data?.state[0].message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setIsScreenLoading(false);
    }
  };

  const addTemporaryTires = async (inTransitTires: Array<ISeries>) => {
    const bodyRequest: ITemporaryTiresToAdd = {
      customerId,
      locationId,
      orderId: orderCode?.id,
      plantId: plantSelected?.id,
      typeCodeId: typeCodeSelected?.typeCode,
      tc: typeCodeSelected?.tireClass,
      cost: +getFieldFormValue('tireCostField'),
      inTransitTires,
    };
    try {
      await apiService.inTransitTiresAPI.addTemporaryTires(bodyRequest);
      setNotification({ text: `${inTransitTires.length} tires added successfully`, type: MessageBarType.success });
    } catch (error: any) {
      const text = error?.response?.data?.message || 'Adding series error';
      setNotification({ text, type: MessageBarType.error });
    }

  };

  const handlePrint = async (printingType: any) => {
    try {
      const inTransitTiresGetInput = {
        pagination: paginationPropsAvailable.pagination,
        filters: {
          customerId: customerId,
          locationId: regionCode,
        },
      };

      const inTransitTiresAltGetInput = {
        pagination: paginationPropsAvailable.pagination,
        filters: {
          customerId: customerId,
          locationId: regionCode,
        },
      };

      const headerFields = [
        { title: 'customerId', value: customerId },
        { title: 'locationId', value: regionCode },
      ];

      const requestData = {
        inTransitTiresGetInput: inTransitTiresGetInput,
        inTransitTiresAltGetInput: inTransitTiresAltGetInput,
        headerFields: headerFields,
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.inTransitTiresAPI.printExcel(requestData) :
        await apiService.inTransitTiresAPI.printPdf(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
      toggleShowPrintExport();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    }
  };

  const handleSelectRow = (rowsSelected: Array<ITemporaryTires> = []) => {
    setTemporaryTiresSelected(rowsSelected);
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    toggleShowLoading();
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString: searchedText,
      customerId,
      orderId: orderCode?.id || ' ',
      status: TemporaryTireStatusesEnum.PENDING_ADD,
    };
    setPaginationPropsAvailable({ pagination, filters, sortOrder });
    await fetchData(pagination, filters, sortOrder);
    toggleShowLoading();
  };

  const handleUpdate = async () => {
    toggleShowLoading();
    await updateTemporaryTires();
    await fetchData();
    toggleReloadControlsTable();
    toggleUpdatingConfirmation();
    setTemporaryTiresToUpdate([]);
    toggleShowLoading();
  };

  const handleDelete = async (rowsToDelete: Array<ITemporaryTires>) => {
    toggleShowLoading();
    const idList = rowsToDelete?.map(row => row.inTransitTireAltId);
    await deleteTemporaryTires(idList);
    toggleReloadControlsTable();
    toggleShowLoading();
  };

  const handleOnSubmitAddTires = async (inTransitTires: Array<ISeries>) => {
    toggleShowLoading();
    await addTemporaryTires(inTransitTires);
    await fetchData();
    toggleReloadControlsTable();
    toggleShowLoading();
  };

  const handleRedirect = () => {
    dispatch(setActiveTab(tireOrderTabs.tireOrderManagement.name));
  };

  const handleOnSubmit = async () => {
    toggleSubmitingConfirmation();
    const submitInTransitObject = {
      customerId,
      locationId,
      invoiceNo: getFieldFormValue('invoiceNumberField'),
      dataShipped: transformDate(moment(getFieldFormValue('dateShippedField')).format('MM/DD/YYYY')),
      inTransitTiresIds: temporaryTiresSelected.map(row => row.inTransitTireAltId),
    };
    await submitTemporaryTires(submitInTransitObject);
    handleRedirect();
  };

  const handleSelectTypeCode = (rowSelected: any) => {
    setTypeCodeList([]);
    setTypeCodeSelected(rowSelected[0]);
    toggleShowTypeCodeModal();
  };

  const handleSelectWarehouse = (rowSelected: any) => {
    setPlantsList([]);
    setPlantSelected(rowSelected[0]);
    toggleShowWarehouseModal();
  };

  const removeEmpty = ({ text }: any) => text !== null;

  const getTypeCoderNameList = typeCodeList.length ?
    typeCodeList.map(({ id, typeCode }) => ({
      key: id,
      text: typeCode,
    })).filter(removeEmpty) : [
      {
        key: typeCodeSelected?.id,
        text: typeCodeSelected?.typeCode,
      },
    ];

  const typeCodeNameInputText = (text: string) => {
    const typeCode = text.toUpperCase();
    delayedFetchTypeCode({ typeCode });
  };

  const delayedFetchTypeCode = useCallback(debounce((filterData) => fetchTypeCode(undefined, filterData), 1000), []);

  const onTypeCodeChange = async (newTypeCodeId: any) => {
    setTypeCodeSelected(getTypeCodeById(newTypeCodeId));
  };

  const getPlantsNameList = plantsList.length ?
    plantsList.map(({ id, plantNo }) => ({
      key: id,
      text: plantNo,
    })).filter(removeEmpty) : [
      {
        key: plantSelected?.id,
        text: plantSelected?.plantNo,
      },
    ];


  const plantNameInputText = (text: string) => {
    const plantNo = text.toUpperCase();
    delayedFetchPlants({ plantNo });
  };

  const delayedFetchPlants = useCallback(debounce((filterData) => fetchPlants(undefined, filterData), 1000), []);

  const onPlantChange = async (newPlantId: any) => {
    setPlantSelected(getPlantById(newPlantId));
  };

  /** form validations */

  const formFieldsGroup: any = {
    invoiceNumberField: useField(ORDER_DETAILS_FIELDS.invoiceNumberDataField),
    dateShippedField: useField(ORDER_DETAILS_FIELDS.dateShippedDataField),
    typeCodeField: useField(IN_TRANSIT_DETAILS_FIELDS.typeCodeDataField),
    tireSizeField: useField(IN_TRANSIT_DETAILS_FIELDS.tireSizeDataField),
    tireClassField: useField(IN_TRANSIT_DETAILS_FIELDS.tireClassDataField),
    plantWarehouseField: useField(IN_TRANSIT_DETAILS_FIELDS.plantWarehouseDataField),
    tireCostField: useField(IN_TRANSIT_DETAILS_FIELDS.tireCostDataField),
  };

  const getFieldForm: any = (field: string) => formFieldsGroup[field] || {};
  const getFieldFormValue = (field: string) => formFieldsGroup[field].getValue() || null;
  const getFieldFormClassName = (field: string) => formFieldsGroup[field]?.getRequiredClassName() || '';
  const setFormValue = (fieldForm: string, value: any) => formFieldsGroup[fieldForm]?.setValue(value);
  const getTypeCodeById = (idToFind: number) => typeCodeList?.find(({ id }) => id === idToFind) || null;
  const getPlantById = (idToFind: number) => plantsList?.find(({ id }) => id === idToFind) || null;

  const hasEmptyFormData = Object.keys(formFieldsGroup).filter(formKey => {
    return formFieldsGroup[formKey]?.required && !formFieldsGroup[formKey].getValue();
  }).length > 0;

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  useEffect(() => {
    setFormValue('typeCodeField', typeCodeSelected.typeCode);
    setFormValue('tireSizeField', typeCodeSelected.tireSize);
    setFormValue('tireClassField', typeCodeSelected.tireClass);
    fetchPlants(undefined);
  }, [typeCodeSelected]);

  useEffect(() => {
    if (!plantSelected) return;
    setFormValue('plantWarehouseField', plantSelected.id);
  }, [plantSelected]);

  return (
    <>
      <div className="ms-grid margin-left-rigth-2">
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm')}>
            <Text variant="xLarge" className={styles.highlight}>Order Details</Text>
            <SeparatorGy />
            <div className="ms-Grid">
              <div className="ms-Grid-row">
                <div className={classNames('ms-Grid-col', getFieldFormClassName('invoiceNumberField'))}>
                  <TextField {...getFieldForm('invoiceNumberField')} onGetErrorMessage={invoiceDelivNoteEMHandler} />
                </div>
                <div className={classNames('ms-Grid-col', getFieldFormClassName('dateShippedField'))}>
                  <DatePicker
                    {...getFieldForm('dateShippedField')}
                    showMonthPickerAsOverlay={true}
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    onSelectDate={
                      (addedDateFrom: Date | null | undefined) =>
                        setFormValue('dateShippedField', addedDateFrom)
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={classNames('ms-Grid-col', 'ms-sm')}>
            <Text variant="xLarge" className={styles.highlight}>In-transit tire Details</Text>
            <SeparatorGy />

            <div className={classNames('ms-Grid-col', 'inputGroup', 'inputWithButton')}>
              <AutoCompleteField
                label='Type Code'
                onChangeAction={onTypeCodeChange}
                onBlur={(ev) => typeCodeSelected?.id && !ev.target.value && setTypeCodeSelected({})}
                value={typeCodeSelected?.id}
                list={getTypeCoderNameList}
                textValue={typeCodeNameInputText}
                isLoading={isLoadingFetchTypeCode}
                className={styles.upperCase}
                data-testid='tire-order-type-code'
                required
              />
              <IconButton
                iconProps={{ iconName: 'Search' }}
                onClick={toggleShowTypeCodeModal}
              />
            </div>
            <div className={classNames('ms-Grid-col', getFieldFormClassName('tireSizeField'))}>
              <TextField {...getFieldForm('tireSizeField')} value={typeCodeSelected?.tireSize} />
            </div>
            <div className={classNames('ms-Grid-col', getFieldFormClassName('tireClassField'))}>
              <TextField {...getFieldForm('tireClassField')} value={typeCodeSelected?.tireClass} />
            </div>
            <div className={classNames('ms-Grid-col', 'inputGroup', 'inputWithButton')}>
              <AutoCompleteField
                label='Plant/Warehouse'
                onChangeAction={onPlantChange}
                onBlur={(ev) => plantSelected?.id && !ev.target.value && setPlantSelected({})}
                value={plantSelected?.id}
                list={getPlantsNameList}
                textValue={plantNameInputText}
                isLoading={isLoadingFetchPlants}
                className={styles.upperCase}
                data-testid='tire-order-plant-selected'
                required
              />
              <IconButton
                iconProps={{ iconName: 'Search' }}
                onClick={toggleShowWarehouseModal}
              />
            </div>
            <div className={classNames('ms-Grid-col', getFieldFormClassName('tireCostField'))}>
              <TextField {...getFieldForm('tireCostField')} />
            </div>
          </div>
        </div>

        <AddSeriesComponent onSubmit={handleOnSubmitAddTires} hasEmptyFormData={hasEmptyFormData} />

        <div className="ms-Grid-row table-container">
          <div className={styles.tableContainer}>
            <DataGridComponent
              idTable={'resume-intransit-table'}
              title='Move Tires to In-Transit'
              headCells={headCells}
              rowsTable={temporaryTires}
              defaultRowsPerPage={intransitPageSizes}
              totalDataFound={totalFound}
              isLoading={isLoading}
              enableCheckBox={userPermissions.isWrite}
              enableSearching={true}
              enableMultiSelectRow={userPermissions.isWrite}
              enableDeleteOption={userPermissions.isWrite}
              enablePagination={true}
              enableRowsPerPage={true}
              enableEditableRows={userPermissions.isWrite}
              reloadControlsTable={reloadControlsTable}
              handleSelectRow={handleSelectRow}
              handleChangeDataGridState={handleChangeDataGridState}
              handleDelete={handleDelete}
              handleUpdate={setTemporaryTiresToUpdate}
            />
          </div>
        </div>
        <div className={classNames('ms-Grid-col', 'ms-sm12', styles.buttonsWrapper)}>
          <DefaultButton onClick={toggleShowPrintExport} text="Print/Export" />
          <PrimaryButton onClick={toggleUpdatingConfirmation} text="Update" disabled={!temporaryTiresToUpdate.length || !userPermissions.isWrite} />
          <PrimaryButton onClick={toggleSubmitingConfirmation} text="Submit" disabled={!temporaryTiresSelected.length || !userPermissions.isWrite} />
        </div>
      </div>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      <TypeCodeModalComponent
        id='typeCodeModal'
        title='Available Type Codes'
        isOpen={showTypeCodeModal}
        onSubmit={handleSelectTypeCode}
        onDismiss={toggleShowTypeCodeModal}
        successLabel='Select Typecode'
        cancelLabel='Cancel'
      />
      <WarehouseModalComponent
        id='wareHouseModal'
        title='Available Plant/Warehouse'
        type={typeCodeSelected?.origOrRtrd}
        isOpen={showWarehouseModal}
        onSubmit={handleSelectWarehouse}
        onDismiss={toggleShowWarehouseModal}
        successLabel='Select Plant/Warehouse'
        cancelLabel='Cancel'
      />
      <DialogComponent
        isOpen={isUpdatingDialogVisible}
        onCancel={toggleUpdatingConfirmation}
        onSubmit={handleUpdate}
        title={'Confirmation'}
        subText={`Are you sure you want to update ${temporaryTiresToUpdate.length} items?`}
        onSubmitLabel={'Update'}
        onCancelLabel={'Cancel'}
      />
      <DialogComponent
        isOpen={isSubmitingDialogVisible}
        onCancel={toggleSubmitingConfirmation}
        onSubmit={handleOnSubmit}
        title={'Confirmation'}
        subText={`Are you sure you want to submit ${temporaryTiresSelected.length} items?
          You will be redirected to 'Tire Order Management' tab.`}
        onSubmitLabel={'Submit'}
        onCancelLabel={'Cancel'}
      />
      {isScreenLoading && <LoadingScreen />}
    </>
  );
};

export default AddComponent;
