import { recordTestInstance, exportTestInstance } from '../axios';

const get = (pagination: any, filters: any, sortOrder: any, customerId: number) =>
  recordTestInstance.post('/rebrand', { pagination, filters, sortOrder, customerId });

const deleteRebrand = (ids: Array<any>) =>
  recordTestInstance.delete('/rebrand', { data: { ids } });

const submit = (data: Array<any>, customerId: string, locationId: string) =>
  recordTestInstance.post('/rebrand/submit', { data, customerId, locationId });

const check = (tireToBeChecked: any) =>
  recordTestInstance.post('/rebrand/check', tireToBeChecked);

const save = (data: Array<any>, customerId: string, locationId: string, isAdd = true) =>
  recordTestInstance.post('/rebrand/save', { data, customerId, locationId, isAdd });

const printExcel = (input: any, headerFields: Array<any>) =>
  exportTestInstance.post('/Rebrand/export-excel', { input, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (input: any, headerFields: Array<any>) =>
  exportTestInstance.post('/Rebrand/export-pdf', { input, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  get,
  delete: deleteRebrand,
  submit,
  check,
  save,
  printExcel,
  printPdf,
};
