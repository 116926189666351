import { FC, ReactElement, useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { get } from 'lodash';
import {
  IconButton,
  IIconProps,
  Text,
  DefaultButton,
  DatePicker,
  ChoiceGroupOption,
  Label,
  ChoiceGroup,
  IChoiceGroupOption,
} from '@fluentui/react';

import { emptyFilters } from '../consts';

import {
  IFilterModalWindowProps,
} from './FilterModalWindowProps';
import styles from './FilterModalWindow.module.scss';
import SeparatorGy from '../../../../../../SeparatorGy/SeparatorGy';
import { orderStatusOptions, viewOptions } from './consts';

const FilterModalWindow: FC<IFilterModalWindowProps> = ({
  isOpened,
  onDismiss,
  filters,
  setFilters,
}): ReactElement => {
  const [filtersState, setFiltersState] = useState({ ...filters });

  const closeIcon: IIconProps = { iconName: 'Cancel' };

  const applyFilters = () => {
    setFilters({ ...filtersState });
  };

  const clearFilters = () => {
    setFiltersState({ ...emptyFilters });
  };

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        onDismiss();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);

  useEffect(() => {
    if (isOpened) {
      setFiltersState({ ...filters });
    }
  }, [isOpened]);

  const handleChangeDateFrom = (dateFrom: Date | null | undefined): void => {
    setFiltersState((prevState: any) => ({ ...prevState, dateFrom }));
  };

  const handleChangeDateTo = (dateTo: Date | null | undefined): void => {
    setFiltersState((prevState: any) => ({ ...prevState, dateTo }));
  };

  return isOpened ? (
    <>
      <div id="filterModalWindow" className={classNames(styles.filterWrapper)}>
        <div className={classNames(styles.filterBackground)}>
          <div className={classNames(styles.filterContainer)} ref={containerRef}>
            <div className={classNames(styles.filterHeader)}>
              <Text variant="xxLarge">Filter</Text>
              <IconButton
                id="closeButton"
                iconProps={closeIcon}
                title="close"
                onClick={onDismiss}
              />
            </div>
            <div className={classNames(styles.filterBody)}>
              <div className={classNames(styles.row)}>
                <div className={classNames(styles.column, styles.displayFlexEnd)}>
                  <ChoiceGroupOption
                    itemKey="test"
                    id="allDates"
                    text=""
                    checked={filtersState.allDates}
                    onClick={() => setFiltersState((prev: any) => ({ ...prev, allDates: true }))}
                  />
                  <Label htmlFor="allDates">All</Label>
                </div>
              </div>
              <div className={classNames(styles.row)}>
                <div className={classNames(styles.displayFlexCenter)}>
                  <ChoiceGroupOption
                    itemKey="test"
                    id="dateRange"
                    text=""
                    checked={!filtersState.allDates}
                    onClick={() => setFiltersState((prev: any) => ({ ...prev, allDates: false }))}
                  />
                  <div
                    className={classNames(
                      styles.column,
                      styles.calendarContainer,
                    )}
                  >
                    <DatePicker
                      id="changeDateFrom"
                      showMonthPickerAsOverlay={true}
                      label="Date from"
                      placeholder="Select a date..."
                      ariaLabel="Select a date"
                      value={filtersState.dateFrom}
                      onSelectDate={handleChangeDateFrom}
                      formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                    />
                  </div>
                  <div
                    className={classNames(
                      styles.column,
                      styles.calendarContainer,
                    )}
                  >
                    <DatePicker
                      id="changeDateTo"
                      showMonthPickerAsOverlay={true}
                      label="Date to"
                      placeholder="Select a date..."
                      ariaLabel="Select a date"
                      value={filtersState.dateTo}
                      onSelectDate={handleChangeDateTo}
                      formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                    />
                  </div>
                </div>
              </div>
              <SeparatorGy />
              <div className={classNames(styles.row)}>
                <div className={classNames(styles.column)}>
                  <Text variant="xLarge" block className={styles.highlight}>View</Text>
                  <ChoiceGroup
                    selectedKey={filtersState.view}
                    options={viewOptions}
                    onChange={(ev: any, option: IChoiceGroupOption | undefined) => setFiltersState((prev) => ({ ...prev, view: get(option, 'key', 'ALL') }))}
                  />
                </div>
              </div>
              <SeparatorGy />
              <div className={classNames(styles.row)}>
                <div className={classNames(styles.column)}>
                  <Text variant="xLarge" block className={styles.highlight}>Filter on</Text>
                  <ChoiceGroup
                    selectedKey={filtersState.orderStatus}
                    options={orderStatusOptions}
                    onChange={(ev: any, option: IChoiceGroupOption | undefined) => setFiltersState((prev) => ({ ...prev, orderStatus: get(option, 'key', 'ALL') }))}
                  />
                </div>
              </div>
            </div>

            <div className={classNames(styles.filterFooter)}>
              <DefaultButton
                id="clearButton"
                onClick={clearFilters}
                text="Clear Filters"
              />
              <DefaultButton
                id="applyButton"
                onClick={applyFilters}
                disabled={
                  (filtersState.dateFrom && filtersState.dateTo && (moment(filtersState.dateTo).isBefore(filtersState.dateFrom)))
                }
                text="Apply"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : <></>;
};

export default FilterModalWindow;

