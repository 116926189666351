import { FC, ReactElement, useState } from 'react';
import styles from './ViewTireDetailsModal.module.scss';
import { IViewTireDetailsModalProps } from './IViewTireDetailsModalProps';
import classNames from 'classnames';
import {
  Text,
  IconButton,
  IIconProps,
} from '@fluentui/react';
import moment from 'moment';
import ViewTireDetails from '../ViewTireDetails/ViewTireDetails';
import { viewTireDetailsTabs } from '../../../consts/recordKeeping';


const ViewTireDetailsModal: FC<IViewTireDetailsModalProps> = ({
  isOpened,
  onDismiss,
}): ReactElement => {
  const [selectedTab, setSelectedTab] = useState(viewTireDetailsTabs.general);

  return (
    <>
      {isOpened && (
        <div className={classNames(styles.detailsWrapper)}>
          <div className={classNames(styles.detailsBackground)}>
            <div
              className={classNames(styles.detailsContainer)}
            >
              <div className={classNames(styles.detailsHeader)}>
                <Text variant="xxLarge" className={styles.highlight}>View Tire Details</Text>
                <IconButton
                  id='closeButton'
                  iconProps={{ iconName: 'Cancel' }}
                  title="close"
                  onClick={() => onDismiss()}
                />
              </div>
              <div className={classNames(styles.detailsBody)}>
                <ViewTireDetails 
                  isSearchable={false} 
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewTireDetailsModal;
