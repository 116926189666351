import React, { ReactElement } from 'react';
import { ToolbarSlot } from '@react-pdf-viewer/default-layout';
import { RotateDirection } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


const renderCustomToolbar = (Toolbar: (props: any) => ReactElement, fileName: any) => (
  <Toolbar>
    {(slots: ToolbarSlot) => {
      const {
        CurrentPageInput,
        Download,
        EnterFullScreen,
        GoToNextPage,
        GoToPreviousPage,
        NumberOfPages,
        Print,
        ShowSearchPopover,
        Zoom,
        ZoomIn,
        ZoomOut,
        Rotate,
      } = slots;
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <div style={{ padding: '0px 2px' }}>
            <ShowSearchPopover />
          </div>
          <div style={{ padding: '0px 2px' }}>
            {fileName}
          </div>
          <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
            <GoToPreviousPage />
          </div>
          <div style={{ padding: '0px 2px', display: 'flex', alignItems: 'center' }}>
            <CurrentPageInput /> / <NumberOfPages />
          </div>
          <div style={{ padding: '0px 2px' }}>
            <GoToNextPage />
          </div>
          <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
            <ZoomOut />
          </div>
          <div style={{ padding: '0px 2px' }}>
            <Zoom />
          </div>
          <div style={{ padding: '0px 2px' }}>
            <ZoomIn />
          </div>
          <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
            <EnterFullScreen />
          </div>
          <div style={{ padding: '0px 2px' }}>
            <Rotate direction={RotateDirection.Forward} />
          </div>
          <div style={{ padding: '0px 2px', marginLeft: 'auto'  }}>
            <Download />
          </div>
          <div style={{ padding: '0px 2px' }}>
            <Print />
          </div>
        </div>
      );
    }}
  </Toolbar>
);

export default renderCustomToolbar;