import { exportTestInstance, tireOrderingTestInstance } from '../axios';

const deleteOEMs = (ids: string[]) =>
  tireOrderingTestInstance.delete('/maintain-oem/delete', { data: { ids } });

const get = (pagination: any, filters?: any, sortOrder?: any) =>
  tireOrderingTestInstance.post('/maintain-oem/search', { pagination, filters, sortOrder });

const addOem = (oem: any) =>
  tireOrderingTestInstance.post('/maintain-oem/add', oem);

const updateOem = (oem: any) =>
  tireOrderingTestInstance.put('/maintain-oem/update', oem);

const printExcel = (input: any) =>
  exportTestInstance.post('/plant/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (input: any) =>
  exportTestInstance.post('/plant/export-pdf', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  deleteOEMs,
  get,
  addOem,
  updateOem,
  printExcel,
  printPdf,
};