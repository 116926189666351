import { IDropdownOption } from '@fluentui/react';
import { HeadCell } from '../../../../shared/DataGridComponent/DataGridModels';
import { IFiltersState } from './IFiltersState';

export const pageSizes = [50, 75, 100];

export const tableColumns: Array<HeadCell> = [
  {
    key: 'column0',
    name: 'Full Name',
    fieldName: 'fullName',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Email',
    fieldName: 'email',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'UserId',
    fieldName: 'userId',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Access',
    fieldName: 'accessTypes',
    typeField: 'text',
  },
  {
    key: 'column4',
    name: 'Status',
    fieldName: 'status',
    typeField: 'text',
  },
];

export const allOption: IDropdownOption = {
  key: 'All',
  text: 'All',
};

export const emptyFilters: IFiltersState = {
  searchedText: '',
  userStatus: ['VALID'],
  accessType: [allOption.key],
};

