export const locationColumns = [
  {
    key: 'column0',
    name: 'Ship To Number',
    fieldName: 'shipToNumber',
  },
  {
    key: 'column1',
    name: 'Location Code',
    fieldName: 'locationCode',
  },
  {
    key: 'column2',
    name: 'Location Name',
    fieldName: 'locationName',
  },
  {
    key: 'column3',
    name: 'Address Line 1',
    fieldName: 'addrLine1',
  },
  {
    key: 'column4',
    name: 'Address Line 2',
    fieldName: 'addrLine2',
  },
  {
    key: 'column5',
    name: 'City',
    fieldName: 'city',
  },
];

export const columns = [
  {
    key: 'column0',
    name: 'vNo',
    fieldName: 'vNo',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'Miles',
    fieldName: 'miles',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'odoStart',
    fieldName: 'odoStart',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'odoEnd',
    fieldName: 'odoEnd',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'Submit',
    fieldName: 'submit',
    isSorted: false,
    isSortedDescending: false,
  },
];