import { createSlice } from '@reduxjs/toolkit';

import { IUser } from '../components/RecordKeeping/IUser';

const initialState: {
  user: IUser | null,
  permissions: Array<any> | null,
  modulesAllowed: Array<any> | null,
} = {
  user: {
    displayName: '',
    email: '',
    roles: '',
    avatar: '',
    initial: '',
    jobtitle: '',
  },
  permissions: null,
  modulesAllowed: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setModulesAllowed: (state, action) => {
      state.modulesAllowed = action.payload;
    },
  },
});

export const {
  setUser,
  setUserPermissions,
  setModulesAllowed,
} = userSlice.actions;

export const userSelector = (state: any) => state.user.user;
export const userPermissionsSelector = (state: any) => state.user.permissions;
export const modulesPermissionsSelector = (state: any) => state.user.modulesAllowed;

export default userSlice.reducer;
