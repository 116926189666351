export const emptyPlaceRetreadOrder = {
  typeCode: '',
  quantity: '',
  orderDate: '',
  recvDate: '',
  shipDate: '',
  status: '',
};
  
export const emptyPlaceRetreadOrderItem = {
  beginBrandPrefix: '',
  beginBrandNumber: '',
  beginBrandSuffix: '',
  endBrandPrefix: '',
  endBrandNumber: '',
  endBrandSuffix: '',
  warehouse: '',
  invoiceId: '',
};

export const orderDetailsColumns = [
  {
    key: 'column1',
    name: 'Invoice',
    fieldName: 'invoiceId',
  },
  {
    key: 'column2',
    name: 'Warehouse',
    fieldName: 'warehouseNo',
  },
  {
    key: 'column3',
    name: 'Status',
    fieldName: 'status',
  },
  {
    key: 'column4',
    name: 'Begin Brand Prefix',
    fieldName: 'beginBrandPrefix',
  },
  {
    key: 'column5',
    name: 'Begin Brand Number',
    fieldName: 'beginBrandNumber',
  },
  {
    key: 'column6',
    name: 'Begin Brand Suffix',
    fieldName: 'beginBrandSuffix',
  },
  {
    key: 'column7',
    name: 'End Brand Prefix',
    fieldName: 'endBrandPrefix',
  },
  {
    key: 'column8',
    name: 'End Brand Number',
    fieldName: 'endBrandNumber',
  },
  {
    key: 'column9',
    name: 'End Brand Suffix',
    fieldName: 'endBrandSuffix',
  },
  {
    key: 'column10',
    name: 'Quantity',
    fieldName: 'quantity',
  },
];