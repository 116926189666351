import { FunctionComponent } from 'react';
import ViewMaintainInvoicesModalComponent from '../../../ViewMaintainInvoicesModalComponent';
import { useBoolean } from '@fluentui/react-hooks';
import { IconButton } from '@fluentui/react';
import { IAvailableInvoice } from '../../../../models/IAvailableInvoice';
import SchedulingDetailsModal from '../../../../components/TaskScheduler/components/SchedulingDetailsModal/SchedulingDetailsModal';
import styles from '../Actions.module.scss';
import ViewingLogsModal from '../../../../components/TaskScheduler/components/ViewingLogsModal/ViewingLogsModal';


interface LogsTaskSchedulerActionProps {
  row: IAvailableInvoice;
}

const LogsTaskSchedulerAction: FunctionComponent<LogsTaskSchedulerActionProps> = ({ row }) => {
  const [isModalOpen, { toggle: toggleIsModalOpen }] = useBoolean(false);

  return (
    <>
      <IconButton
        id='viewButton'
        iconProps={{ iconName: 'Error' }}
        onClick={toggleIsModalOpen}
        className={styles.iconBtn}
      />

      <ViewingLogsModal
        isModalOpen={isModalOpen}
        hideModal={toggleIsModalOpen}
        taskInfo={row}
      />
    </>
  );
};

export default LogsTaskSchedulerAction;