import { IAddingState } from './IAddingState';

export const columns = [
  {
    key: 'column1',
    name: 'Prefix',
  },
  {
    key: 'column2',
    name: 'Brand',
  },
  {
    key: 'column3',
    name: 'Suffix',
  },
  {
    key: 'column4',
    name: 'Current TypeCode',
  },
  {
    key: 'column5',
    name: 'New TypeCode',
  },
];

export const emptyAddingState: IAddingState = {
  bpfx: '',
  beginBno: '',
  bsfx: '',
  endBno: '',
};
