import { DropdownMenuItemType, ITag } from '@fluentui/react';
import ActionOnTiresTab from '../components/RecordKeeping/tabComponents/ActionOnTiresTab/ActionOnTiresTab';
import ActionOnVehiclesTab from '../components/RecordKeeping/tabComponents/ActionOnVehiclesTab/ActionOnVehiclesTab';
import MileageRunTab from '../components/RecordKeeping/tabComponents/MileageRunTab/MileageRunTab';
import RecordKeepingTab from '../components/RecordKeeping/tabComponents/RecordKeepingTab/RecordKeepingTab';
import SpareStockTab from '../components/RecordKeeping/tabComponents/SpareStockTab/SpareStockTab';
import TireChangeTab from '../components/RecordKeeping/tabComponents/TireChangeTab/TireChangeTab';
import TireDispositionTab from '../components/RecordKeeping/tabComponents/TireDispositionTab/TireDispositionTab';
import VehicleMilesTab from '../components/RecordKeeping/tabComponents/VehicleMilesTab/VehicleMilesTab';
import SpareStockPostingHistoryTab from '../components/RecordKeeping/tabComponents/SpareStockPostingHistoryTab/SpareStockPostingHistoryTab';
import { ITabComponent } from '../models/ITabComponent';
import {
  auth_actionOnTires,
  auth_actionOnVehicles,
  auth_customerInformation,
  auth_mileageRun,
  auth_spareStock,
  auth_spareStockPostingHistory,
  auth_tireChange,
  auth_tireDisposition,
  auth_vehicleMiles,
} from './programKeys';

export const pageSizes = [500, 750, 1000];

export const dropdownControlledExampleOptions = [
  {
    key: 'fruitsHeader',
    text: 'Fruits',
    itemType: DropdownMenuItemType.Header,
  },
  { key: 'apple', text: 'Apple' },
  { key: 'banana', text: 'Banana' },
  { key: 'orange', text: 'Orange', disabled: true },
  { key: 'grape', text: 'Grape' },
  { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
  {
    key: 'vegetablesHeader',
    text: 'Vegetables',
    itemType: DropdownMenuItemType.Header,
  },
  { key: 'broccoli', text: 'Broccoli' },
  { key: 'carrot', text: 'Carrot' },
  { key: 'lettuce', text: 'Lettuce' },
];

export const testTags: ITag[] = [
  'black',
  'blue',
  'brown',
  'cyan',
  'green',
  'magenta',
  'mauve',
  'orange',
  'pink',
  'purple',
  'red',
  'rose',
  'violet',
  'white',
  'yellow',
].map((item) => ({ key: item, name: item }));

enum RECORDKEEPING_TABS {
  RECORD_KEEPING = 'recordKeeping',
  ACTION_ON_VEHICLES = 'actionOnVehicles',
  ACTION_ON_TIRES = 'actionOnTires',
  TIRE_CHANGE = 'tireChange',
  TIRE_DISPOSITION = 'tireDisposition',
  SPARE_STOCK = 'spareStock',
  VEHICLE_MILES = 'vehicleMiles',
  MILEAGE_RUN = 'mileageRun',
  SPARE_STOCK_POSTING_HISTORY = 'spareStockPostingHistory',
}

interface IRecordKeepingTabs {
  recordKeeping?: ITabComponent;
  actionOnVehicles?: ITabComponent;
  actionOnTires?: ITabComponent;
  tireChange?: ITabComponent;
  tireDisposition?: ITabComponent;
  spareStock?: ITabComponent;
  vehicleMiles?: ITabComponent;
  mileageRun?: ITabComponent;
  spareStockPostingHistory?: ITabComponent;
}


/** Each tab into left menu must have a component */
export const recordKeepingTabs: IRecordKeepingTabs = {
  recordKeeping: {
    id: RECORDKEEPING_TABS.RECORD_KEEPING,
    accessKey: auth_customerInformation,
    name: 'Record Keeping',
    component: RecordKeepingTab,
    subTabs: null,
  },
  actionOnVehicles: {
    id: RECORDKEEPING_TABS.ACTION_ON_VEHICLES,
    accessKey: auth_actionOnVehicles,
    name: 'Action on Vehicles',
    component: ActionOnVehiclesTab,
    subTabs: null,
  },
  actionOnTires: {
    id: RECORDKEEPING_TABS.ACTION_ON_TIRES,
    accessKey: auth_actionOnTires,
    name: 'Action on Tires',
    component: ActionOnTiresTab,
    subTabs: null,
  },
  tireChange: {
    id: RECORDKEEPING_TABS.TIRE_CHANGE,
    accessKey: auth_tireChange,
    name: 'Tire Change',
    component: TireChangeTab,
    subTabs: null,
  },
  tireDisposition: {
    id: RECORDKEEPING_TABS.TIRE_DISPOSITION,
    accessKey: auth_tireDisposition,
    name: 'Tire Disposition',
    component: TireDispositionTab,
    subTabs: null,
  },
  spareStock: {
    id: RECORDKEEPING_TABS.SPARE_STOCK,
    accessKey: auth_spareStock,
    name: 'Spare Stock',
    component: SpareStockTab,
    subTabs: null,
  },
  vehicleMiles: {
    id: RECORDKEEPING_TABS.VEHICLE_MILES,
    accessKey: auth_vehicleMiles,
    name: 'Vehicle Miles',
    component: VehicleMilesTab,
    subTabs: null,
  },
  mileageRun: {
    id: RECORDKEEPING_TABS.MILEAGE_RUN,
    accessKey: auth_mileageRun,
    name: 'Mileage Run',
    component: MileageRunTab,
    subTabs: null,
  },
  spareStockPostingHistory: {
    id: RECORDKEEPING_TABS.SPARE_STOCK_POSTING_HISTORY,
    accessKey: auth_spareStockPostingHistory,
    name: 'Spare Stock Posting History',
    component: SpareStockPostingHistoryTab,
    subTabs: null,
  },
};

export const getTabById: any = (tabId: string) => Object.values(recordKeepingTabs).filter((tab) => tab.id === tabId)[0];

interface IActionOnTiresTabsTabs {
  viewListing: string;
  viewTireDetails: string;
  reinstate: string;
  rebrand: string;
}

export const actionOnTiresTabs: IActionOnTiresTabsTabs = {
  viewListing: 'View Listing',
  viewTireDetails: 'View Tire Details',
  reinstate: 'Reinstate',
  rebrand: 'Rebrand',
};

interface IViewTireDetailsTabs {
  general: string;
  tireHistory: string;
  contract: string;
  financialDetails: string;
}

export const viewTireDetailsTabs: IViewTireDetailsTabs = {
  general: 'General',
  tireHistory: 'Tire History',
  contract: 'Contract, Order, Transfer',
  financialDetails: 'Financial Details/Billing Price',
};

interface ISpareStockTabs {
  postSpareStock: string;
  viewPostedSpareStock: string;
  massDetail: string;
}

export const spareStockTabs: ISpareStockTabs = {
  postSpareStock: 'Post Spare Stock',
  viewPostedSpareStock: 'View Posted Spare Stock',
  massDetail: 'Mass Details',
};

export const dataHistoryColumns: Array<any> = [
  {
    key: 'column0',
    name: 'Trans Date',
    fieldName: 'transDate',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'Description',
    fieldName: 'description',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Vehicle',
    fieldName: 'vehicle',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'Pos',
    fieldName: 'pos',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'Miles',
    fieldName: 'miles',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'Additional Info',
    fieldName: 'additionalInfo',
    isSorted: false,
    isSortedDescending: false,
  },
];

export const mileageRunColumns: Array<any> = [
  {
    key: 'column0',
    name: 'Vehicle',
    fieldName: 'vehicle',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'Position',
    fieldName: 'position',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'From Date',
    fieldName: 'fromDate',
    isSorted: true,
    isSortedDescending: true,
  },
  {
    key: 'column3',
    name: 'To Date',
    fieldName: 'toDate',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'Units Run',
    fieldName: 'unitsRun',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'Location',
    fieldName: 'location',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column6',
    name: 'OOS',
    fieldName: 'oos',
    isSorted: false,
    isSortedDescending: false,
  },
];

export const tireChangeColumns: Array<any> = [
  {
    key: 'column0',
    name: 'Vehicle',
    fieldName: 'vehicle',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'Position',
    fieldName: 'position',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'From Date',
    fieldName: 'fromDate',
    isSorted: true,
    isSortedDescending: true,
  },
  {
    key: 'column3',
    name: 'To Date',
    fieldName: 'toDate',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'Units Run',
    fieldName: 'unitsRun',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'Location',
    fieldName: 'location',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column6',
    name: 'OOS',
    fieldName: 'oos',
    isSorted: false,
    isSortedDescending: false,
  },
];

export const DATAHISTORY = 'DATAHISTORY';
export const MILEAGERUN = 'MILEAGERUN';
export const TIRECHANGE = 'TIRECHANGE';

export const VIEWS = [DATAHISTORY, MILEAGERUN, TIRECHANGE] as const;
export type VIEW_TYPE = typeof VIEWS[number];

export const viewByOptions = [
  { key: DATAHISTORY, text: 'Data History' },
  { key: MILEAGERUN, text: 'Mileage Run' },
  { key: TIRECHANGE, text: 'Tire Change' },
];

export const idleReason = [
  { key: 'Repairs', text: 'Repairs' },
  { key: 'Pending Scrap/Sold', text: 'Pending Scrap/Sold' },
  { key: 'Legal Hold', text: 'Legal Hold' },
  { key: 'Sold/Scrapped', text: 'Sold/Scrapped' },
  { key: 'Needs unregistered', text: 'Needs unregistered' },
  { key: 'No GYs/No Miles', text: 'No GYs/No Miles' },
  { key: 'Other', text: 'Other' },
];

export const dataPresentationMap = {
  [DATAHISTORY]: {
    text: viewByOptions[0].text,
    columns: dataHistoryColumns,
    view: DATAHISTORY,
  },
  [MILEAGERUN]: {
    text: viewByOptions[1].text,
    columns: mileageRunColumns,
    view: MILEAGERUN,
  },
  [TIRECHANGE]: {
    text: viewByOptions[2].text,
    columns: tireChangeColumns,
    view: TIRECHANGE,
  },
};

export const getDefaultPresentationView = (view: VIEW_TYPE) => {
  return dataPresentationMap[view];
};

interface ICurrentPositionExampleData {
  vehicle: string;
  position: string;
  onDate: string;
  locationCode: string;
}

export const currentPositionExampleData: ICurrentPositionExampleData = {
  vehicle: '???',
  position: 'STK',
  onDate: '02/12/2019',
  locationCode: 'DTT',
};

interface IFlaggedAsDamagedExampleData {
  A: string;
  B: string;
  fadDate: string;
  fadDcc: string;
}

export const flaggedAsDamagedExampleData: IFlaggedAsDamagedExampleData = {
  A: '???',
  B: '???',
  fadDate: '02/12/2019',
  fadDcc: 'DTT',
};