import { IFiltersState } from './IFiltersState';

export const pageSizes = [100, 250, 500];

export const OTHERS = 'OTHERS';

export const cancelReasons = [
  { key: 'CREDIT_DENIED', text: 'Credit Denied' },
  { key: 'CUSTOMER_REQUEST', text: 'Customer Request' },
  { key: 'UNAVAILABILITY', text: 'Unavailability' },
  { key: 'OTHERS', text: 'Others' },
];

export const tableColumns = [
  {
    key: 'column0',
    name: 'Order',
    fieldName: 'id',
  },
  {
    key: 'column1',
    name: 'Order Date',
    fieldName: 'orderDate',
    isSorted: true,
    isSortedDescending: true,
  },
  {
    key: 'column2',
    name: 'Status',
    fieldName: 'status',
  },
  {
    key: 'column3',
    name: 'Type Code',
    fieldName: 'typeCode',
  },
  {
    key: 'column4',
    name: 'Tire Class',
    fieldName: 'tc',
  },
  {
    key: 'column5',
    name: 'Begin Brand',
    fieldName: 'beginBrand',
  },
  {
    key: 'column6',
    name: 'End Brand',
    fieldName: 'endBrand',
  },
  {
    key: 'column7',
    name: 'Quantity',
    fieldName: 'quantity',
  },
  {
    key: 'column8',
    name: 'Transfer Quantity',
    fieldName: 'xferQuantity',
  },
  {
    key: 'column9',
    name: 'Short Quantity',
    fieldName: 'shortQuantity',
  },
  {
    key: 'column10',
    name: 'Whse Quantity',
    fieldName: 'warhauseQuantity',
  },
  {
    key: 'column11',
    name: 'Dot Quantity',
    fieldName: 'dotQuantity',
  },
  {
    key: 'column12',
    name: 'Rec Quantity',
    fieldName: 'recvQuantity',
  },
  {
    key: 'column13',
    name: 'Created By',
    fieldName: 'createdBy',
  },
  {
    key: 'column14',
    name: 'Created Date',
    fieldName: 'createdOn',
  },
  {
    key: 'column15',
    name: 'Last Modified By',
    fieldName: 'lastModifiedBy',
  },
  {
    key: 'column16',
    name: 'Last Modified Date',
    fieldName: 'lastModifiedOn',
  },
];

export const emptyFilters: IFiltersState = {
  view: 'ALL',
  orderStatus: 'ALL',
  dateFrom: undefined,
  dateTo: undefined,
  allDates: true,
  byCustomer: true,
  id: '',
  dlNoteId: '',
  gdyrId: '',
  invoiceId: '',
  brandPrefix: '',
  name: '',
  searchString: '',
};