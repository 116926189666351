import { Pivot, PivotItem } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { get } from 'lodash';
import { FunctionComponent, useState } from 'react';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import { MIDAS_MODULES } from '../../models/EnumMidasModules';
import ModuleTitleComponent from '../../shared/ModuleTitleComponent';
import { getMenuOptionById } from '../Nav/consts';
import styles from './Billing.module.scss';
import { billingTabs } from './consts';


interface BillingComponentProps {
  value?: any;
};

const BillingComponent: FunctionComponent<BillingComponentProps> = () => {

  const { getPermissionsByAccessKey } = useUserPermissions();

  const [isLoading, { toggle: toggleShowLoading }] = useBoolean(false);
  const [currentTab, setCurrentTab] = useState(billingTabs.viewInvoices.name);

  const handleLinkClick = (item?: PivotItem) => {
    const newTab = get(item, 'props.itemKey', '');
    setCurrentTab(newTab);
  };

  const getTabId = (itemKey: string) => `ShapeColorPivot_${itemKey}`;

  const renderTab = (TabComponent: FunctionComponent) => {
    if (TabComponent) return <TabComponent />;
  };

  const moduleInfo = getMenuOptionById(MIDAS_MODULES.BILLING);

  return (
    <>
      <div className="ms-Grid record-keeping-container mainContainer" dir="ltr">
        <ModuleTitleComponent moduleInfo={moduleInfo} />
        <div className='ms-Grid-row'>
          <div className={classNames(
            'ms-Grid-col',
            'ms-lg12',
            'tabulatorWrapper',
            styles.headerTabsContainer,
          )}>
            <Pivot
              aria-label='Separately Rendered Content Pivot Example'
              className='pivot-gy-colors'
              selectedKey={currentTab}
              onLinkClick={handleLinkClick}
              getTabId={getTabId}
              linkFormat='tabs'
              linkSize='large'
            >
              {Object.values(billingTabs).map(({ accessKey, name, component }, index) =>
                getPermissionsByAccessKey(accessKey)?.isAccess && (
                  <PivotItem headerText={name} key={index} itemKey={name}>
                    <div className={styles.pivotBodyContainer}>
                      {renderTab(component)}
                    </div>
                  </PivotItem>
                ))}
            </Pivot>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingComponent;