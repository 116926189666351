import { HeadCell } from '../../../../../../shared/DataGridComponent/DataGridModels';

export interface ISeries {
  intireBpfx: string;
  intireBno: number;
  intireBsfx: string;
}

export interface ITemporaryTires {
  inTransitTireAltId: string,
  intireBrndPfx: string,
  intireBrndNum: 0,
  intireBrndSfx: string,
  customerId: string,
  locationId: string,
  contractId: string,
  plantIdFK: string,
  oem: string,
  typeCodeFK: string,
  tc: string,
  dotNum: string,
  cost: number,
  oldBrndPfx: string,
  oldBrndNum: number,
  oldBrndSfx: string,
  shortDoc: string,
  shortDate: Date,
  comments: string,
  fShort: string,
  createdBy: string,
  createdOn: Date,
  lastModifiedBy: string,
  lastModifiedOn: any,
  origConsignedTireId: string,
  prevConsignedTireId: string,
}

export interface ITemporaryTiresToAdd {
  customerId: string,
  locationId: string,
  orderId: string,
  contractId?: string,
  plantId: string,
  typeCodeId: string,
  tc: string,
  cost: number,
  inTransitTires: Array<ISeries>
}

export interface ISeries {
  intireBpfx: string;
  intireBno: number;
  intireBsfx: string;
}

export const ORDER_DETAILS_FIELDS: any = {
  invoiceNumberDataField: {
    id: 'invoiceNumber',
    label: 'Invoice # Delivery Note',
    name: 'invoiceNumber',
    type: 'text',
    required: true,
    textTransform: 'uppercase',
  },
  dateShippedDataField: {
    id: 'dateShipped',
    label: 'Date Shipped',
    name: 'dateShipped',
    type: 'date',
    required: true,
  },
};

export const IN_TRANSIT_DETAILS_FIELDS = {
  typeCodeDataField: {
    id: 'typeCode',
    label: 'Type Code',
    name: 'typeCode',
    type: 'text',
    required: true,
  },
  tireSizeDataField: {
    id: 'tireSize',
    label: 'Tire size',
    name: 'tireSize',
    type: 'text',
    disabled: true,
    required: true,
  },
  tireClassDataField: {
    id: 'tireClass',
    label: 'Tire class',
    name: 'tireClass',
    type: 'number',
    disabled: true,
    required: true,
  },
  plantWarehouseDataField: {
    id: 'plantWarehouse',
    label: 'Plant/Warehouse',
    name: 'plantWarehouse',
    type: 'text',
    required: true,
  },
  tireCostDataField: {
    id: 'tireCost',
    label: 'Tire cost',
    name: 'tireCost',
    type: 'number',
    required: true,
  },
};

export const inTransitTiresHeadCell: HeadCell[] = [
  {
    key: 'column0',
    typeField: 'text',
    isEditable: true,
    name: 'Prefix',
    fieldName: 'intireBrndPfx',
  },
  {
    key: 'column1',
    typeField: 'number',
    isEditable: true,
    name: 'Brand number',
    fieldName: 'intireBrndNum',
  },
  {
    key: 'column2',
    typeField: 'text',
    isEditable: true,
    name: 'Suffix',
    fieldName: 'intireBrndSfx',
  },
  {
    key: 'column3',
    typeField: 'text',
    name: 'Type Code',
    isEditable: true,
    fieldName: 'typeCodeFK',
  },
  {
    key: 'column4',
    typeField: 'text',
    isEditable: true,
    name: 'Old Prefix',
    fieldName: 'oldBrndPfx',
  },
  {
    key: 'column5',
    typeField: 'number',
    name: 'Old Brand',
    isEditable: true,
    fieldName: 'oldBrndNum',
  },
  {
    key: 'column6',
    typeField: 'text',
    isEditable: true,
    name: 'Old Suffix',
    fieldName: 'oldBrndSfx',
  },
  {
    key: 'column7',
    typeField: 'number',
    name: 'Tire Class',
    fieldName: 'tc',
  },
  {
    key: 'column8',
    typeField: 'number',
    name: 'Tire Cost',
    fieldName: 'cost',
  },
];
