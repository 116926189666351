import { IChoiceGroupOption } from '@fluentui/react';

export const viewOptions: IChoiceGroupOption[] = [
  { key: 'ALL', text: 'All' },
  { key: 'ORIGINAL', text: 'Original only' },
  { key: 'RETREAD', text: 'Retread only' },
];

export const orderStatusOptions: IChoiceGroupOption[] = [
  { key: 'ALL', text: 'All' },
  { key: 'PENDING', text: 'Pending' },
  { key: 'INTRANSIT', text: 'In-Transit' },
  { key: 'CANCEL', text: 'Cancel' },
  { key: 'FULFILLED', text: 'Fulfilled' },

];
