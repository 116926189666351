import { HeadCell } from '../../../../shared/DataGridComponent/DataGridModels';
import { IFormDataField } from '../../../../shared/FormBuilderComponent';

export const CUSTOMER_FIELDS: Array<IFormDataField> = [  
  {
    formFieldName: 'prefixField',
    properties: {
      id: 'prefix',
      label: 'Prefix',
      name: 'prefix',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'customerNameField',
    properties: {
      id: 'customerName',
      label: 'Customer Name',
      name: 'customerName',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'customerNumberField',
    properties: {
      id: 'customerNumber',
      label: 'Customer Number',
      name: 'customerNumber',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'regionField',
    properties: {
      id: 'region',
      label: 'Region',
      name: 'region',
      type: 'text',
      disabled: true,
    },
  },
];

export const availableQuotesHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'QID',
    fieldName: 'quoteId',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Request Date',
    fieldName: 'requestDate',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Number of Tires',
    fieldName: 'numberOfTires',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Total Price',
    fieldName: 'totalPrice',
    typeField: 'text',
  },
  {
    key: 'column4',
    name: 'Valid',
    fieldName: 'valid',
    typeField: 'text',
  },
  {
    key: 'column5',
    name: 'Billed',
    fieldName: 'billed',
    typeField: 'text',
  },
  {
    key: 'column6',
    name: 'Created On',
    fieldName: 'createdOn',
    typeField: 'date',
  },
  {
    key: 'column7',
    name: 'Comments',
    fieldName: 'comments',
    typeField: 'text',
  },
  {
    key: 'column8',
    name: 'Sold Date',
    fieldName: 'soldDate',
    typeField: 'date',
  },
];