import { Checkbox, DatePicker, DefaultButton, Dropdown, IDropdownOption, MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { debounce } from 'lodash';
import moment from 'moment';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import apiService from '../../../../api';
import { auth_billing_postMileageAdjustments } from '../../../../consts/programKeys';
import useNotifications from '../../../../hooks/useNotifications';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { INotification } from '../../../../models/INotification';
import DataGridComponent from '../../../../shared/DataGridComponent';
import { initalDataGridState } from '../../../../shared/DataGridComponent/utils';
import DialogComponent from '../../../../shared/DialogComponent';
import { FormBuilderGroup } from '../../../../shared/FormBuilderComponent';
import LocationsModalComponent from '../../../../shared/LocationsModalComponent';
import AutoCompleteField from '../../../Common/Search/AutoCompleteField';
import { ICustomer } from '../../../Common/Search/ICustomer';
import { downloadFile, printingTypes } from '../../../PrintingModal/consts';
import PrintingModal from '../../../PrintingModal/PrintingModal';
import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import { CUSTOMER_FIELDS, FILTER_FIELDS, FILTER_TYPES, IMileageAdjustments, mileageAdjustmentsHeadCell, MILEAGE_ADJUSTMENT_FIELDS } from './consts';
import styles from './PostMileageAdjustments.module.scss';


interface PostMileageAdjustmentsProps {
  value?: any;
}
const PostMileageAdjustments: FunctionComponent<PostMileageAdjustmentsProps> = () => {

  //hooks 
  const { addNotification } = useNotifications();

  //states
  const [customerSelected, setCustomerSelected] = useState<any>();
  const [customersList, setCustomerList] = useState<ICustomer[]>([]);
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });
  const [dataGridState, setDataGridState] = useState(initalDataGridState);
  const [mileageList, setMileageList] = useState<IMileageAdjustments[]>([]);
  const [mileageAdjustmentUpdated, setMileageAdjustmentUpdated] = useState<IMileageAdjustments[]>([]);
  const [selectedMileage, setSelectedMileage] = useState<IMileageAdjustments[]>([]);
  const [totalMileage, setTotalMileage] = useState<number>(0);
  const [locationId, setLocationId] = useState();
  const [errorMessage, setErrorMessage] = useState<string>('');

  //toggles
  const [isLoadingFetchCustomers, { toggle: toggleIsLoadingFetchCustomers }] = useBoolean(false);
  const [isLoadingMileageTable, { toggle: toggleLoadingMileageTable }] = useBoolean(false);
  const [reloadControlsTable, { toggle: toggleReloadControlsTable }] = useBoolean(false);
  const [isDBChecked, { toggle: toggleDBChecked }] = useBoolean(true);
  const [isBillableChecked, { toggle: toggleBillableChecked }] = useBoolean(true);
  const [isLocationModalOpen, { toggle: toggleLocationModal }] = useBoolean(false);
  const [isDialogComponentOpen, { toggle: toggleDialogComponent }] = useBoolean(false);
  const [isPrintModalOpen, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [parsedErrors, setParsedErrors] = useState<any>([]);

  //form validators 
  const customerDetailsFormFieldsGroup = new FormBuilderGroup(CUSTOMER_FIELDS);
  const mileageAdjustmentsFormFieldsGroup = new FormBuilderGroup(MILEAGE_ADJUSTMENT_FIELDS);
  const filterFormFieldsGroup = new FormBuilderGroup(FILTER_FIELDS);

  //permission
  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_billing_postMileageAdjustments);

  const fetchDeleteMileage = async (ids: Array<string>) => {
    try {
      const { data } = await apiService.billingAPI.deletePostMileageAdjustments(ids);
    } catch (e: any) {
      setNotification({ text: 'Error deleting Post Mileage', type: MessageBarType.error });
    }
  };

  //Customer Prefix & Name
  const onCustomerChange = async (newCustomerId: any) => {
    customerDetailsFormFieldsGroup.cleanFormData();
    setCustomerSelected(getCustomerById(newCustomerId));
    customerDetailsFormFieldsGroup.setFormValue('regionField', getCustomerById(newCustomerId)?.regionCode);
    listPostMileageAdjustment(newCustomerId, dataGridState);
  };

  const getCustomerById = (idToFind: number) => customersList?.find(({ id }) => id === idToFind) || null;

  const removeEmpty = ({ text }: any) => text !== null;

  const customerPrefixInputText = (custPrefix: string) => {
    if (custPrefix.replace(/ /g, '').length) {
      getCustomersDebounce({ custPrefix });
    }
  };

  const customerNameInputText = (custName: string) => {
    if (custName.replace(/ /g, '').length) {
      getCustomersDebounce({ custName }, { column: 'customerName', order: 'asc' });
    }
  };

  const getCustomersDebounce = debounce(async (customerData, sortOrder?) => {
    fetchCustomers(customerData, undefined, sortOrder);
  }, 500);

  const getCustomerPrefixList = useMemo(() => customersList ?
    customersList.map(({ id, prefix }) => ({
      key: id,
      text: prefix,
    })).filter(removeEmpty) : [], [customersList]);

  const getCustomerNameList = useMemo(() => customersList ?
    customersList.map(({ id, customerName }) => ({
      key: id,
      text: customerName,
    })).filter(removeEmpty) : [], [customersList]);

  const fetchCustomers = async (
    filter: any,
    pagination?: any,
    sort: any = { column: 'custPrefix', order: 'asc' }) => {
    try {
      toggleIsLoadingFetchCustomers();
      const { data }: any = await apiService.getCustomerList(filter, pagination, sort);
      setCustomerList(data.data);
      toggleIsLoadingFetchCustomers();
    } catch (e: any) {
      setNotification({ text: 'Customers fetching error.', type: MessageBarType.error });
    }
  };

  const handleOnLocationSubmit = (location: any) => {
    toggleLocationModal();
    setLocationId(location.id);
    customerDetailsFormFieldsGroup.setFormValue('locCodeField', location.locationCode);
    customerDetailsFormFieldsGroup.setFormValue('locationField', location.locationName);
  };

  //Table Controls
  const handleChangeDataGridState = async (dataGridState: any) => {
    await setDataGridState(dataGridState);
    if (customerSelected)
      listPostMileageAdjustment(customerSelected.id, dataGridState);
  };

  const handleSelectRow = (mileageRowsSelected: Array<IMileageAdjustments>) => {
    setSelectedMileage(mileageRowsSelected);
    if (mileageRowsSelected.length == 1) {
      const row = mileageRowsSelected[0];
      mileageAdjustmentsFormFieldsGroup.setFormValue('vehicleNumberField', row.vehicleNumber);
      mileageAdjustmentsFormFieldsGroup.setFormValue('wheelPositionField', row.wheelPosition);
      mileageAdjustmentsFormFieldsGroup.setFormValue('prefixField', row.prefix);
      mileageAdjustmentsFormFieldsGroup.setFormValue('brandNumberField', row.brandNumber);
      mileageAdjustmentsFormFieldsGroup.setFormValue('suffixField', row.suffix);
      mileageAdjustmentsFormFieldsGroup.setFormValue('fromDateField', row.fromDate ? new Date(row.fromDate).toISOString().slice(0, 10) : new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toISOString().slice(0, 10));
      mileageAdjustmentsFormFieldsGroup.setFormValue('toDateField', row.toDate ? new Date(row.toDate).toISOString().slice(0, 10) : new Date().toISOString().slice(0, 10));
      mileageAdjustmentsFormFieldsGroup.setFormValue('milesField', row.miles);
      mileageAdjustmentsFormFieldsGroup.setFormValue('commentsField', row.comments);
    }
    else {
      mileageAdjustmentsFormFieldsGroup.cleanFormData();
      mileageAdjustmentsFormFieldsGroup.setFormValue('fromDateField', new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toISOString().slice(0, 10));
      mileageAdjustmentsFormFieldsGroup.setFormValue('toDateField', new Date().toISOString().slice(0, 10));
    };
  };

  //Filters
  const onFilterChange = async ({ target: { id } }: any, item: IDropdownOption<any> | undefined): Promise<any> => {
    filterFormFieldsGroup.setFormValue('filterField', item?.text);
  };

  //DBCR / Billable
  const handleDBCRClicked = () => {
    toggleDBChecked();
    mileageAdjustmentsFormFieldsGroup.setFormValue('dbcrField', isDBChecked ? 'DB' : 'CR');
  };

  const handleBillableClicked = () => {
    toggleBillableChecked();
    mileageAdjustmentsFormFieldsGroup.setFormValue('billableField', isBillableChecked ? 'Y' : 'N');
  };

  const handleUpdate = (rows: any) => {
    setMileageAdjustmentUpdated(rows);
  };

  //CRUD
  const addPostMileage = async () => {
    toggleLoadingMileageTable();
    const tireBrand = {
      pfx: mileageAdjustmentsFormFieldsGroup.getFieldFormValue('prefixField'),
      bno: mileageAdjustmentsFormFieldsGroup.getFieldFormValue('brandNumberField'),
      sfx: mileageAdjustmentsFormFieldsGroup.getFieldFormValue('suffixField'),
    };
    const payload = {
      customerId: customerSelected?.id,
      locationId: locationId,
      locationCode: customerDetailsFormFieldsGroup.getFieldFormValue('locCodeField'),
      vehicleNumber: mileageAdjustmentsFormFieldsGroup.getFieldFormValue('vehicleNumberField'),
      tiresNumber: 0,
      tireBrand: tireBrand,
      fromDate: mileageAdjustmentsFormFieldsGroup.getFieldFormValue('fromDateField'),
      toDate: mileageAdjustmentsFormFieldsGroup.getFieldFormValue('toDateField'),
      position: mileageAdjustmentsFormFieldsGroup.getFieldFormValue('wheelPositionField'),
      dbOrCr: isDBChecked,
      billable: isBillableChecked,
      miles: +mileageAdjustmentsFormFieldsGroup.getFieldFormValue('milesField'),
      comments: mileageAdjustmentsFormFieldsGroup.getFieldFormValue('commentsField'),
    };

    try {
      await apiService.billingAPI.addPostMileageAdjustments(payload);
      await listPostMileageAdjustment(customerSelected.id, dataGridState);
      setNotification({ text: 'Post mileage added successfully', type: MessageBarType.success });
    } catch (e: any) {
      const { response } = e;
      let locationError;
      setParsedErrors([]);
      switch (response.status) {
        case 400:
          setParsedErrors(response.data.state);
          locationError = parsedErrors?.filter((error: { field: string; }) => error.field === 'locationCode')[0];
          if (locationError) {
            setNotification({ text: 'Location Code mustn\'t be empty', type: MessageBarType.error });
          } else {
            setNotification({
              text:
                `${response.data.message}: ${response?.data?.state?.length >= 1 ?
                  `${formatErrorMessages(response?.data?.state)}` :
                  ''}`, type: MessageBarType.error,
            });
          }
          break;
        default:
          setNotification({ text: `Error adding Post Mileage ${response.data.message}`, type: MessageBarType.error });
          break;
      }
    }
    finally {
      toggleLoadingMileageTable();
    }
  };

  const updatePostMileage = async () => {
    toggleLoadingMileageTable();
    const mileage = selectedMileage[0];
    const payload = {
      data: [{
        id: mileage.id,
        customerId: mileage.customerId,
        locationId: mileage.locationId,
        locationCode: mileage.locationCode,
        vehicleId: mileage.vehicleId,
        vehicleNumber: mileageAdjustmentsFormFieldsGroup.getFieldFormValue('vehicleNumberField'),
        prefix: mileageAdjustmentsFormFieldsGroup.getFieldFormValue('prefixField'),
        brandNumber: +mileageAdjustmentsFormFieldsGroup.getFieldFormValue('brandNumberField'),
        suffix: mileageAdjustmentsFormFieldsGroup.getFieldFormValue('suffixField'),
        consignedTireId: mileage.consignedTireId,
        wheelPosition: mileageAdjustmentsFormFieldsGroup.getFieldFormValue('wheelPositionField'),
        numberOfTires: mileage.numberOfTires,
        fromDate: mileageAdjustmentsFormFieldsGroup.getFieldFormValue('fromDateField'),
        toDate: mileageAdjustmentsFormFieldsGroup.getFieldFormValue('toDateField'),
        miles: mileageAdjustmentsFormFieldsGroup.getFieldFormValue('milesField'),
        debitOrCredit: isDBChecked ? 'DB' : 'CR',
        billable: isBillableChecked ? 'Y' : 'N',
        postAdjustId: mileage.postAdjustId,
        approval: mileage.approval,
        submit: mileage.submit,
        processed: mileage.processed,
        processedDate: mileage.processedDate,
        comments: mileageAdjustmentsFormFieldsGroup.getFieldFormValue('commentsField'),
        postedDate: mileage.postedDate,
        postedBy: mileage.postedBy,
        approvalDate: mileage.approvalDate,
        approvedBy: mileage.approvedBy,
        submitDate: mileage.submitDate,
        submittedBy: mileage.submittedBy,
      }],
    };
    try {
      await apiService.billingAPI.updatePostMileageAdjustments(payload);
      await listPostMileageAdjustment(customerSelected.id, dataGridState);
      setNotification({ text: 'Post mileage updated successfully', type: MessageBarType.success });
    } catch (e: any) {
      const { response } = e;
      let locationError;
      setParsedErrors([]);
      switch (response.status) {
        case 400:
          setParsedErrors(response.data.state);
          locationError = parsedErrors?.filter((error: { field: string; }) => error.field === 'locationCode')[0];
          if (locationError) {
            setNotification({ text: 'Location Code mustn\'t be empty', type: MessageBarType.error });
          } else {
            setNotification({ text: response.data.message, type: MessageBarType.error });
          }
          break;
        default:
          setNotification({ text: 'Error updating Post Mileage', type: MessageBarType.error });
      }
    }
    finally {
      toggleLoadingMileageTable();
    }
  };

  const parseErrors = (field: string) => {
    const customError = parsedErrors?.filter((error: { field: string; }) => error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  const listPostMileageAdjustment = async (customerId: string, dataGridState: any) => {
    toggleLoadingMileageTable();
    const pagination = {
      pageSize: dataGridState.countOnPage.text,
      pageNumber: dataGridState.paginationProps.current,
    };
    const sortOrder = {
      column: dataGridState.sortOrder?.column,
      order: dataGridState.sortOrder?.order,
    };
    const filterValue = filterFormFieldsGroup.getFieldFormValue('filterField');
    const filters = {
      approved: filterValue == 'Approved' ? true : false,
      waitApproval: filterValue == 'Wait Approval' ? true : false,
      showAll: filterValue == 'Show All' ? true : false,
    };
    const payload = {
      pagination: pagination,
      sortOrder: sortOrder,
      filters: filters,
      customerId: customerId ? customerId : customerSelected.id,
    };
    try {
      const { data }: any = await apiService.billingAPI.listPostMileageAdjustments(payload);
      await setMileageList(data?.data);
      await setTotalMileage(data?.total.found);
    } catch (e: any) {
      setNotification({ text: 'Fetching tires error', type: MessageBarType.error });
    }
    finally {
      toggleLoadingMileageTable();
    }
  };

  const handleDeleteMileage = async (rowsToDelete: any[] = []) => {

    toggleIsLoadingFetchCustomers();
    const mileagesIds = getIdsFromMileages(rowsToDelete);

    if (validateMileageBeforDelete(rowsToDelete)) {
      await fetchDeleteMileage(mileagesIds);
      await listPostMileageAdjustment(customerSelected.id, dataGridState);
    };

    toggleIsLoadingFetchCustomers();
  };

  const validateMileageBeforDelete = (rowsToDelete: Array<IMileageAdjustments>) => {
    let rowsCanBeDeleted = true;

    if (hasSubmitedApprovedMileages(rowsToDelete)) {
      setNotification({
        text: 'Mileages SUBMITED or APPROVED can not be deleted.',
        type: MessageBarType.error,
      });
      rowsCanBeDeleted = false;
    };

    return rowsCanBeDeleted;
  };

  const approvePostMileage = async () => {
    const ids = getIdsFromMileages(selectedMileage);
    try {
      const { data } = await apiService.billingAPI.approveMiles({ ids });
      setErrorMessage('');
      verifyApproval(data.data);
      await listPostMileageAdjustment(customerSelected.id, dataGridState);
    } catch (e: any) {
      setNotification({ text: 'Error approving Post Mileage', type: MessageBarType.error });
    }
  };

  const verifyApproval = (responseArray: any[]) => {
    let isVerifyCorrect = true;
    const errorArray: string[] = [];
    responseArray.forEach(row => {
      if (row.message != 'Approved') {
        isVerifyCorrect = false;
        errorArray.push(row.id);
      }
    });
    if (!isVerifyCorrect) {
      setErrorMessage('You cannot approve the following mileage adjustments: ' + errorArray.join(','));
      toggleDialogComponent();
    }
    else
      setNotification({ text: 'All mileage adjustments approved successfully', type: MessageBarType.success });
  };

  const onSubmitClicked = async () => {
    toggleLoadingMileageTable();
    const payload = {
      data: selectedMileage,
    };
    try {
      const { data } = await apiService.billingAPI.submitMiles(payload);
      setErrorMessage('');
      verifySubmit(data.data);
      await listPostMileageAdjustment(customerSelected.id, dataGridState);
    } catch (e: any) {
      setNotification({ text: 'Error submiting miles', type: MessageBarType.error });
    }
    finally {
      toggleLoadingMileageTable();
    }
  };

  const verifySubmit = (responseArray: any[]) => {
    let isVerifyCorrect = true;
    const errorArray: string[] = [];
    responseArray.forEach(row => {
      if (row.message != 'Submitted') {
        isVerifyCorrect = false;
        errorArray.push(row.id);
      }
    });
    if (!isVerifyCorrect) {
      setErrorMessage('Failure submitting the following mileage adjustments: ' + errorArray.join(','));
      toggleDialogComponent();
    }
    else
      setNotification({ text: 'All mileage adjustments submitted successfully', type: MessageBarType.success });
  };


  const formatErrorMessages = (errors: any[]): string => {
    return errors.map((error: { message: string }) => {
      return `${error.message} \n`;
    }).join();
  };

  const calculateMiles = async () => {
    const payload = {
      customerId: customerSelected.id,
      vehicleNumber: mileageAdjustmentsFormFieldsGroup.getFieldFormValue('vehicleNumberField'),
      fromDate: mileageAdjustmentsFormFieldsGroup.getFieldFormValue('fromDateField'),
      toDate: mileageAdjustmentsFormFieldsGroup.getFieldFormValue('toDateField'),
    };
    try {
      const { data } = await apiService.billingAPI.calculateMiles(payload);
      mileageAdjustmentsFormFieldsGroup.setFormValue('milesField', data.miles);
    } catch (e: any) {
      const { response } = e;
      let locationError;
      setParsedErrors([]);
      switch (response.status) {
        case 400:
          setParsedErrors(response.data.state);
          locationError = parsedErrors?.filter((error: { field: string; }) => error.field === 'locationCode')[0];
          if (locationError) {
            setNotification({ text: 'Location Code mustn\'t be empty', type: MessageBarType.error });
          } else {
            setNotification({
              text:
                `${response.data.message} ${response?.data?.state?.length > 1 ?
                  `Check form for errors \n ${formatErrorMessages(response?.data?.state)}` :
                  ''}`, type: MessageBarType.error,
            });
          }
          break;
        default:
          setNotification({ text: `Error calculating Post Mileage ${response.data.message}`, type: MessageBarType.error });
      }
    }
  };

  const saveComments = async () => {
    toggleLoadingMileageTable();
    const data: any[] = [];
    mileageAdjustmentUpdated.forEach(row => {
      const element = {
        id: row.id,
        comment: row.comments,
      };
      data.push(element);
    });
    const payload = {
      data: data,
    };
    try {
      await apiService.billingAPI.saveCommentsPostMileage(payload);
      setMileageAdjustmentUpdated([]);
      listPostMileageAdjustment(customerSelected.id, dataGridState);
      setNotification({ text: 'Success submitting comments', type: MessageBarType.success });
    } catch (e: any) {
      setNotification({ text: 'Error submitting comments', type: MessageBarType.error });
    }
    finally {
      toggleLoadingMileageTable();
    }
  };

  const discardChanges = () => {
    setMileageAdjustmentUpdated([]);
    listPostMileageAdjustment(customerSelected.id, dataGridState);
  };

  const handlePrint = async (printingType: any) => {
    toggleLoadingMileageTable();
    const filterValue = filterFormFieldsGroup.getFieldFormValue('filterField');
    const pagination = {
      pageSize: dataGridState.countOnPage.text,
      pageNumber: dataGridState.paginationProps.current,
    };
    const sortOrder = {
      column: dataGridState.sortOrder?.column,
      order: dataGridState.sortOrder?.order,
    };
    const filters = {
      approved: filterValue == 'Approved' ? true : false,
      waitApproval: filterValue == 'Wait Approval' ? true : false,
      showAll: filterValue == 'Show All' ? true : false,
    };
    const mileageAdjustmentsSearch = {
      pagination: pagination,
      sortOrder: sortOrder,
      filters: filters,
      customerId: customerSelected.id,
    };
    const headerFields = [
      { title: 'Customer Name', value: customerSelected.customerName },
      { title: 'Location Code', value: customerDetailsFormFieldsGroup.getFieldFormValue('locCodeField') },
    ];
    const payload = {
      mileageAdjustmentsSearch: mileageAdjustmentsSearch,
      headerFields: headerFields,
    };
    try {
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.billingAPI.printExcelPostMileage(payload) :
        await apiService.billingAPI.printPDFPostMileage(payload);
      downloadFile(data, printingType);
      setNotification({ text: 'Table exported successfully', type: MessageBarType.success });
    } catch (e: any) {
      setNotification({ text: 'Error printing table', type: MessageBarType.error });
    }
    finally {
      toggleLoadingMileageTable();
      toggleShowPrintExport();
    }
  };

  //useEffects
  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  useEffect(() => {
    filterFormFieldsGroup.setFormValue('filterField', 'Not Processed');
    mileageAdjustmentsFormFieldsGroup.setFormValue('dbcrField', 'DB');
    mileageAdjustmentsFormFieldsGroup.setFormValue('billableField', 'Y');
    mileageAdjustmentsFormFieldsGroup.setFormValue('fromDateField', new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toISOString().slice(0, 10));
    mileageAdjustmentsFormFieldsGroup.setFormValue('toDateField', new Date().toISOString().slice(0, 10));
  }, []);

  useEffect(() => {
    if (!customerSelected) return;
    listPostMileageAdjustment(customerSelected.id, dataGridState);
  }, [filterFormFieldsGroup.getFieldFormValue('filterField')]);

  const getIdsFromMileages = (mileagesArray: Array<IMileageAdjustments>) => mileagesArray?.map((mileage: any) => mileage.id);
  const hasSubmitedApprovedMileages = (mileagesArray: Array<IMileageAdjustments>) =>
    mileagesArray?.find((mileage: any) => mileage.submit == 'Y' || mileage.approval == 'Y') != undefined;
  const disableSaveComments = mileageAdjustmentUpdated.length == 0;
  const disableAvailableLocations = customerSelected == null;
  const disableUpdateButton = selectedMileage.length != 1;
  const disableApproveButton = !(selectedMileage[0]?.approval === 'N' && selectedMileage[0]?.submit === 'N' && selectedMileage[0]?.processed === 'N');
  const disableSubmitButton = !(selectedMileage[0]?.approval === 'Y' && selectedMileage[0]?.submit === 'N' && selectedMileage[0]?.processed === 'N');

  return (
    <>
      <div className="ms-Grid">
        {/* Customer Details */}
        <>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              <Text variant='xLarge' className={styles.title}>Post Mileage Adjustments</Text>
            </div>
          </div>
          <br />
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              <Text variant='xLarge' className={styles.highlight}>Customer Details</Text>
              <SeparatorGy />
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm6">
              <div className={styles.customerDetailsContainer}>
                <div className={classNames(styles.smallAutocomplete, styles.marginTopAutocomplete)}>
                  <AutoCompleteField
                    onChangeAction={onCustomerChange}
                    value={customerSelected?.id}
                    list={getCustomerPrefixList}
                    label="Customer Prefix"
                    data-testid='customer-prefix'
                    icon="FavoriteStar"
                    textValue={customerPrefixInputText}
                    isLoading={isLoadingFetchCustomers}
                  />
                </div>
                <div className={classNames(styles.bigAutocomplete, styles.marginTopAutocomplete)}>
                  <AutoCompleteField
                    onChangeAction={onCustomerChange}
                    value={customerSelected?.id}
                    list={getCustomerNameList}
                    label="Customer Name"
                    data-testid='customer-name'
                    icon="Contact"
                    textValue={customerNameInputText}
                    isLoading={isLoadingFetchCustomers}
                  />
                </div>
                <div className={styles.marginTop30}>
                  <SeparatorGy vertical />
                </div>
                <div className={styles.smallAutocomplete}>
                  <TextField {...customerDetailsFormFieldsGroup.getFieldForm('regionField')} />
                </div>
              </div>
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm6">
              <div className={styles.customerDetailsContainer}>
                <div className={classNames(styles.smallAutocomplete, styles.marginTopAutocomplete)}>
                  <TextField {...customerDetailsFormFieldsGroup.getFieldForm('locCodeField')} />
                </div>
                <div className={classNames(styles.bigAutocomplete, styles.marginTopAutocomplete)}>
                  <TextField {...customerDetailsFormFieldsGroup.getFieldForm('locationField')} />
                </div>
                <div className={styles.marginTop30}>
                  <PrimaryButton text='Available Locations' disabled={disableAvailableLocations} onClick={toggleLocationModal} />
                </div>
              </div>
            </div>
          </div>
        </>

        {/* Post Mileage Adjusting Details */}
        <>
          <div className={styles.mileageContainer}>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <Text variant='xLarge' className={styles.highlight}>Post Mileage Adjusting Details</Text>
                <SeparatorGy />
              </div>
            </div>

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm9">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12">
                    <div className={styles.postMileageDetailsContainer}>
                      <TextField label='Vehicle #' {...mileageAdjustmentsFormFieldsGroup.getFieldForm('vehicleNumberField')} errorMessage={parseErrors('vehicleNumber')} />
                      <TextField label='Wheel Position' {...mileageAdjustmentsFormFieldsGroup.getFieldForm('wheelPositionField')} errorMessage={parseErrors('position')} />
                      <div className={styles.postMileageTireBrandContainer}>
                        <TextField label='Tire Brand' {...mileageAdjustmentsFormFieldsGroup.getFieldForm('prefixField')} errorMessage={parseErrors('tireBrand.pfx')} />
                        <TextField label='Brand Number'  {...mileageAdjustmentsFormFieldsGroup.getFieldForm('brandNumberField')} errorMessage={parseErrors('tireBrand.BrandNo')} />
                        <TextField label='Suffix' {...mileageAdjustmentsFormFieldsGroup.getFieldForm('suffixField')} />
                      </div>
                      <div className={styles.postMileageDBCRVerticalContainer}>
                        <Text variant='medium' className={styles.highlight}>DB/CR</Text>
                        <div className={styles.postMileageDBCRHorizontalContainer}>
                          <Checkbox label='DB' checked={isDBChecked} onChange={handleDBCRClicked} defaultChecked />
                          <Checkbox label='CR' checked={!isDBChecked} onChange={handleDBCRClicked} />
                        </div>
                      </div>

                      <div className={styles.postMileageDBCRVerticalContainer}>
                        <Text variant='medium' className={styles.highlight}>Billable</Text>
                        <div className={styles.postMileageDBCRHorizontalContainer}>
                          <Checkbox label='Yes' checked={isBillableChecked} onChange={handleBillableClicked} defaultChecked />
                          <Checkbox label='No' checked={!isBillableChecked} onChange={handleBillableClicked} />
                        </div>
                      </div>
                    </div>
                    <div className={styles.postMileageDetailsContainer}>
                      <DatePicker
                        {...mileageAdjustmentsFormFieldsGroup.getFieldForm('fromDateField')}
                        value={moment(mileageAdjustmentsFormFieldsGroup.getFieldFormValue('fromDateField')).toDate()}
                        onSelectDate={date => mileageAdjustmentsFormFieldsGroup.setFormValue('fromDateField', date)}
                        formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                        errorMessage={parseErrors('fromDateField')} 
                      />
                      <DatePicker
                        {...mileageAdjustmentsFormFieldsGroup.getFieldForm('toDateField')}
                        value={moment(mileageAdjustmentsFormFieldsGroup.getFieldFormValue('toDateField')).toDate()}
                        onSelectDate={date => mileageAdjustmentsFormFieldsGroup.setFormValue('toDateField', date)}
                        formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                        errorMessage={parseErrors('toDateField')} 
                      />
                      <TextField {...mileageAdjustmentsFormFieldsGroup.getFieldForm('milesField')} />
                      <div className={styles.marginTop25}>
                        <PrimaryButton text='Calculate' onClick={calculateMiles} />
                      </div>
                      <div className={styles.marginTop20}>
                        <TextField {...mileageAdjustmentsFormFieldsGroup.getFieldForm('commentsField')}
                          autoAdjustHeight
                          multiline
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ms-Grid-col ms-sm1">
                <SeparatorGy vertical />
              </div>
              <div className="ms-Grid-col ms-sm2">
                <div className={styles.editCommentContainer}>
                  <PrimaryButton text='+ Add' disabled={!disableUpdateButton || !userPermissions.isWrite} onClick={addPostMileage} />
                  <PrimaryButton text='Update' disabled={disableUpdateButton || !userPermissions.isWrite} onClick={updatePostMileage} />
                </div>
              </div>
            </div>

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <div className={styles.filterEditingContainer}>
                  <Dropdown
                    {...filterFormFieldsGroup.getFieldForm('filterField')}
                    label='Filter'
                    options={FILTER_TYPES?.map(filter => ({
                      key: filter,
                      text: filter,
                    }))}
                    onChange={onFilterChange}
                    placeHolder='Not Processed'
                  />
                  <div className={styles.editCommentContainer}>
                    <PrimaryButton text="Stop Editing & Save" disabled={disableSaveComments || !userPermissions.isWrite} onClick={saveComments} />
                    <DefaultButton text='Stop Editing & Discard Changes' onClick={discardChanges} />
                  </div>
                </div>
              </div>
            </div>

            {/* Datagrid */}
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <DataGridComponent
                  idTable={'mileage-adjustments-table'}
                  title='Mileage Adjustments'
                  headCells={mileageAdjustmentsHeadCell}
                  rowsTable={mileageList}
                  totalDataFound={totalMileage}
                  isLoading={isLoadingMileageTable}
                  enablePagination={true}
                  enableRowsPerPage={true}
                  enableManageHeadCells={true}
                  enableDeleteOption={userPermissions.isWrite}
                  handleUpdate={handleUpdate}
                  handleChangeDataGridState={handleChangeDataGridState}
                  handleDelete={handleDeleteMileage}
                  enableCheckBox={true}
                  handleSelectRow={handleSelectRow}
                />
              </div>
            </div>
          </div>
        </>

        {/* Button row */}
        <>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              <div className={styles.buttonsContainer}>
                <DefaultButton text='Print/Export' onClick={toggleShowPrintExport} />
                <PrimaryButton text='Approve' onClick={approvePostMileage} disabled={!(userPermissions.isWrite && !disableApproveButton)} />
                <PrimaryButton text='Submit' onClick={onSubmitClicked} disabled={!(userPermissions.isWrite && !disableSubmitButton)} />
              </div>
            </div>
          </div>
        </>
      </div>
      <LocationsModalComponent
        id='locationModal'
        isOpen={isLocationModalOpen}
        title='Locations'
        customerId={customerSelected?.id}
        onSubmit={handleOnLocationSubmit}
        onDismiss={toggleLocationModal}
        successLabel='Select Location'
        cancelLabel='Cancel'
      />

      <DialogComponent
        isOpen={isDialogComponentOpen}
        onCancel={toggleDialogComponent}
        onSubmit={toggleDialogComponent}
        title={'MIDAS Error'}
        subText={errorMessage}
        onSubmitLabel={'Accept'}
        onCancelLabel={'Cancel'}
      />

      <PrintingModal
        isOpened={isPrintModalOpen}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
    </>
  );
};

export default PostMileageAdjustments;
