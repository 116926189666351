
import { HeadCell } from '../../../../../shared/DataGridComponent/DataGridModels';

export const commonOwnerHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Customen Number',
    fieldName: 'customerNumber',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Customer Prefix',
    fieldName: 'prefix',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Name',
    fieldName: 'customerName',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Region',
    fieldName: 'regionCode',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'City',
    fieldName: 'corrAddressCity',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'State',
    fieldName: 'corrAddressState',
    typeField: 'text',
  },
];