import { IPublicClientApplication } from '@azure/msal-browser';
import { Callout, Icon, Separator, Text } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { CommandBarButton, DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { IPersonaSharedProps, Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import classNames from 'classnames';
import jwt_decode from 'jwt-decode';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LeasingLogo from '../../assets/img/GoodyearLeasingLogo_Yellow.png';
import { getUserDetails } from '../../auth/GraphService';
import { getToken } from '../../axios';
import { auth_admin_taskScheduler, auth_admin_userManagement } from '../../consts/programKeys';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import { setUser } from '../../redux/userSlice';
import MaintainControlValuesModalComponent from '../../shared/MaintainControlValuesModalComponent';
import styles from './Navbar.module.scss';
import { acquireAccessToken } from './NavbarService';


const stackNavTokens: IStackTokens = { childrenGap: 20 };

type INavbarProps = {
  pca: IPublicClientApplication;
}

type INavbarState = {
  error: any;
  isAuthenticated: boolean;
  user: any;
  UserGroups: any[];
  token: any;
}

const Navbar = (props: INavbarProps) => {
  const [valueINavbarState, setINavbarState] = useState<INavbarState>({ error: null, isAuthenticated: false, user: {}, UserGroups: [], token: null });
  const [isAccountCalloutVisible, { toggle: toggleIsAccountCalloutVisible }] = useBoolean(false);
  const [isAdminCalloutVisible, { toggle: toggleIsAdminCalloutVisible }] = useBoolean(false);
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [hidePermissionDialog, { toggle: togglePermissionDialog }] = useBoolean(true);
  const labelId = useId('callout-label');
  const [openMaintainControlValuesModal, { toggle: toggleOpenMaintainControlValuesModal }] = useBoolean(false);
  const [availableRoles, setAvailableRoles] = useState([]);


  const history = useHistory();
  const dispatch = useDispatch();
  const { hasPermission, loadingPermissions } = useUserPermissions();

  useEffect(() => {
    acquireAccessToken(props.pca).then((accesstoken: any) => {
      getUserDetails(accesstoken).then((userdetails: any) => {
        const strGiven: string = isNil(userdetails.userProfile.givenName) ? '' : userdetails.userProfile.givenName;
        const strSurname: string = isNil(userdetails.userProfile.surname) ? '' : userdetails.userProfile.surname;
        const strInit: string = strGiven.substr(0, 1) + strSurname.substr(0, 1);

        dispatch(setUser({
          displayName: userdetails.userProfile.displayName,
          email: userdetails.userProfile.mail || userdetails.userProfile.userPrincipalName,
          roles: userdetails.appRoles,
          avatar: userdetails.userProfile.avatar,
          initial: strInit,
          jobtitle: userdetails.userProfile.jobTitle,
        }));
        setINavbarState({
          isAuthenticated: true,
          user: {
            displayName: userdetails.userProfile.displayName,
            email: userdetails.userProfile.mail || userdetails.userProfile.userPrincipalName,
            roles: userdetails.appRoles,
            avatar: userdetails.userProfile.avatar,
            initial: strInit,
            jobtitle: userdetails.userProfile.jobTitle,
          },
          error: null,
          UserGroups: userdetails.UserGroups,
          token: accesstoken,
        });

        const { idTokenSecret } = getToken();
        const { roles }: any = jwt_decode(idTokenSecret);
        setAvailableRoles(roles || []);
      });
    });
  }, [props.pca]);

  const userPersona: IPersonaSharedProps = {
    imageUrl: valueINavbarState.user.avatar,
    imageInitials: valueINavbarState.user.initial,
    text: valueINavbarState.user.displayName,
    secondaryText: valueINavbarState.user.email,
  };

  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Close sessions',
    closeButtonAriaLabel: 'Close',
    subText: 'You are about to close out of (4) open sessions. Are you sure you want to proceed?',
  };

  const dialogContentPermission = {
    type: DialogType.normal,
    title: 'Unauthorized!',
    closeButtonAriaLabel: 'Close',
    subText: 'You do not have an admin access. Please contact your Administrator.',
  };

  const openUserAccessManagement = () => {
    toggleIsAdminCalloutVisible();
    history.push('/user-access-management');
  };

  const openTaskScheduler = () => {
    toggleIsAdminCalloutVisible();
    history.push('/task-scheduler');
  };

  const openMaintainControlValues = () => {
    toggleIsAdminCalloutVisible();
    toggleOpenMaintainControlValuesModal();
  };

  const userPermissions_userManagement = hasPermission(auth_admin_userManagement);
  const userPermissions_taskScheduler = hasPermission(auth_admin_taskScheduler);

  const onPersonaClick = () => {
    if (isAdminCalloutVisible) {
      toggleIsAdminCalloutVisible();
    }
    toggleIsAccountCalloutVisible();
  };

  const onGearClick = () => {
    if (isAccountCalloutVisible) {
      toggleIsAccountCalloutVisible();
    }
    toggleIsAdminCalloutVisible();
    togglePermissionDialog();
  };

  const handleLogout = () => props.pca.logout();

  return (
    <div className={styles.header}>
      <Stack className={styles.stackNavStyles} horizontal tokens={stackNavTokens} >
        <img className={styles.LogoClass} src={LeasingLogo} alt="logo" />
        <Persona
          id='callout-button'
          className={styles.IUserStyles}
          {...userPersona}
          size={PersonaSize.size24}
          showInitialsUntilImageLoads={true}
          onClick={onPersonaClick}
        />

        {isAccountCalloutVisible &&
          <Callout
            ariaLabelledBy={labelId}
            className={styles.callout}
            onDismiss={toggleIsAccountCalloutVisible}
            target={'#callout-button'}
            isBeakVisible={false}
            setInitialFocus
          >
            <Text className={styles.title} block variant="xLarge">
              Goodyear
            </Text>
            <Persona
              className={styles.IUserPopupStyles}
              {...userPersona}
              size={PersonaSize.size48}
              showUnknownPersonaCoin={false}
            />
            <Stack verticalAlign="start" className={styles.commandBarButton}>
              Available Azure roles:
              {availableRoles.map((role) => (
                <label className={styles.rolLabel}>{role}</label>
              ))}
            </Stack>
            <Stack horizontal horizontalAlign="space-between">
              <CommandBarButton className={styles.commandBarButton} onClick={toggleHideDialog}>Close all open sessions</CommandBarButton>
              <CommandBarButton className={styles.commandBarButton} onClick={handleLogout}>Sign Out</CommandBarButton>
            </Stack>
          </Callout>
        }
        <FontIcon
          iconName="Settings"
          id="admin-callout-button"
          className={styles.SettingsWhite}
          onClick={onGearClick}
        />
        {isAdminCalloutVisible &&
          <Callout
            ariaLabelledBy={labelId}
            className={styles.callout}
            onDismiss={toggleIsAdminCalloutVisible}
            target="#admin-callout-button"
            isBeakVisible={false}
            setInitialFocus
          >
            {(!loadingPermissions) &&
              (
                <>
                  {
                    userPermissions_userManagement.isAccess &&
                    <CommandBarButton
                      className={classNames(styles.commandBarButton, styles.fullWidthButton)}
                      onClick={openUserAccessManagement}
                    >
                      <Icon iconName='AccountManagement' className={styles.inlineIcon} />
                      Manage User Access
                    </CommandBarButton>
                  }
                  {
                    userPermissions_userManagement.isAccess && userPermissions_taskScheduler.isAccess &&
                    <Separator />
                  }
                  {
                    userPermissions_taskScheduler.isAccess &&
                    <CommandBarButton
                      className={classNames(styles.commandBarButton, styles.fullWidthButton)}
                      onClick={openTaskScheduler}
                    >
                      <Icon iconName='ClipboardList' className={styles.inlineIcon} />
                      Task Scheduler
                    </CommandBarButton>
                  }
                  {
                    userPermissions_userManagement.isAccess &&
                    <Separator />
                  }
                  {
                    userPermissions_userManagement.isAccess &&
                    <CommandBarButton
                      className={classNames(styles.commandBarButton, styles.fullWidthButton)}
                      onClick={openMaintainControlValues}
                    >
                      <Icon iconName='AccountManagement' className={styles.inlineIcon} />
                      Maintain Control Values
                    </CommandBarButton>
                  }
                </>
              )
            }
          </Callout>
        }

        {(!userPermissions_userManagement.isAccess || !userPermissions_taskScheduler.isAccess) && (!loadingPermissions) && //if user has no admin rights, display dialog
          (
            <>
              <Dialog
                hidden={hidePermissionDialog}
                onDismiss={togglePermissionDialog}
                dialogContentProps={dialogContentPermission}
              >
                <DialogFooter>
                  <PrimaryButton onClick={togglePermissionDialog} text="Close" />
                </DialogFooter>
              </Dialog>
            </>
          )
        }

        <FontIcon iconName="Unknown" className={styles.HelpWhite} />

      </Stack >
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={toggleHideDialog} text="Close" />
          <DefaultButton onClick={toggleHideDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>

      <MaintainControlValuesModalComponent
        id='maintainControlValuesModal'
        isOpen={openMaintainControlValuesModal}
        onDismiss={toggleOpenMaintainControlValuesModal}
        cancelLabel='Exit'
      />
    </div >
  );
};

export default Navbar;