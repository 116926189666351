import { HeadCell } from '../DataGridComponent/DataGridModels';

export const contractHeadCells: Array<HeadCell> = [
  {
    key: 'column0',
    name: 'Customer No',
    fieldName: 'customerNumber',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Region Code',
    fieldName: 'regionCode',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Cust BP',
    fieldName: 'customerBrandPrefix',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Name',
    fieldName: 'customerName',
    typeField: 'text',
  },
  {
    key: 'column4',
    name: 'Status',
    fieldName: 'status',
    typeField: 'text',
  },
  {
    key: 'column5',
    name: 'Active Status',
    fieldName: 'activeStatus',
    typeField: 'text',
  },
  {
    key: 'column6',
    name: 'Effective Start Date',
    fieldName: 'effectiveStart',
    typeField: 'date',
  },
  {
    key: 'column7',
    name: 'Effective End Date',
    fieldName: 'effectiveEnd',
    typeField: 'text',
  },
];