import { DefaultButton, IconButton, MessageBarType, Modal, PrimaryButton, Spinner, SpinnerSize, Text, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import moment from 'moment';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { default as api, default as apiService } from '../../api';
import { ICustomer } from '../../components/Common/Search/ICustomer';
import { downloadFile, printingTypes } from '../../components/PrintingModal/consts';
import PrintingModal from '../../components/PrintingModal/PrintingModal';
import SeparatorGy from '../../components/SeparatorGy/SeparatorGy';
import useNotifications from '../../hooks/useNotifications';
import { ILineItem } from '../../models/ILineItem';
import { INotification } from '../../models/INotification';
import DataGridComponent from '../DataGridComponent';
import { FormBuilderGroup } from '../FormBuilderComponent';
import {
  availableLineItemsHeadCell,
  CONTRACT_FIELDS,
  CREDIT_FIELDS,
  CUSTOMER_FIELDS,
  INVOICES_ACTION_TYPES,
  INVOICE_FIELDS,
  LINEITEMS_FIELDS,
} from './consts';
import styles from './ViewMaintainInvoices.module.scss';


interface ViewMaintainInvoicesModalComponentProps {
  id: string,
  title: string,
  invoiceId: string,
  actionType: INVOICES_ACTION_TYPES,
  isOpen: boolean,
  onSubmit: () => void,
  onCancel: () => void,
};

const ViewMaintainInvoicesModalComponent: FunctionComponent<ViewMaintainInvoicesModalComponentProps> = ({
  id,
  title,
  invoiceId,
  actionType,
  isOpen,
  onSubmit,
  onCancel,
}) => {

  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });
  const [totalFound, setTotalFound] = useState(0);
  const [amounts, setAmounts] = useState<any>({ totalAmount: 0, totalCostAmount: 0 });
  const [availableLineItemsList, setAvailableLineItemsList] = useState<Array<any>>([]);
  const [lineItemSelected, setLineItemSelected] = useState<ILineItem>();
  const [isMaintain, setIsMaintain] = useState(actionType == 'maintain' ? true : false);
  const [customerId, setCustomerId] = useState();
  const [locationId, setLocationId] = useState();
  const [taxExempt, setTaxExempt] = useState();

  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const { addNotification } = useNotifications();
  const [customerSelected, setCustomerSelected] = useState<any>();
  const [customersList, setCustomerList] = useState<ICustomer[]>([]);

  const [isLoadingAvailableTable, { toggle: toggleShowLoadingAvailableTable }] = useBoolean(false);
  const [isLoadingInitialData, { toggle: toggleShowLoadingAvailableInitialData }] = useBoolean(false);

  const [isTotalFetchDisabled, setTotalFetchDisabled] = useState<boolean>(false);

  //form validators
  const customerFormFieldsGroup = new FormBuilderGroup(CUSTOMER_FIELDS);
  const contractFormFieldsGroup = new FormBuilderGroup(CONTRACT_FIELDS);
  const invoiceFormFieldsGroup = new FormBuilderGroup(INVOICE_FIELDS);
  const creditFormFieldsGroup = new FormBuilderGroup(CREDIT_FIELDS);
  const lineItemsFormFieldsGroup = new FormBuilderGroup(LINEITEMS_FIELDS);

  const fetchAvailableLineItems = async (
    pagination?: any,
    sortOrder: any = { column: availableLineItemsHeadCell[0].fieldName, order: 'asc' },
  ) => {
    toggleShowLoadingAvailableTable();
    try {
      const { data:

        { data:
          {
            data,
            total,
            totalAmount,
            totalCostAmount,
          },
        },
      }: any = await apiService.billingAPI.getInvoiceItems(
        invoiceId,
        pagination,
        sortOrder,
      );

      setAvailableLineItemsList(data);
      setAmounts({ totalAmount, totalCostAmount });
      setTotalFound(total?.found);
    } catch (error) {
      setNotification({ text: 'Fetching tires error', type: MessageBarType.error });
    } finally {
      toggleShowLoadingAvailableTable();
    }
  };

  const fetchCustomerDetails = async (invoiceId: string) => {
    try {
      const { data }: any = await apiService.billingAPI.getMaintainInvoiceCustomerDetails(invoiceId);
      setCustomerDetails(data);
    } catch (e: any) {
      setNotification({ text: 'Customers details fetching error.', type: MessageBarType.error });
    }
  };

  const fetchContractDetails = async (invoiceId: string) => {
    try {
      const { data }: any = await apiService.billingAPI.getMaintainInvoiceContractDetails(invoiceId, customerId || '');
      setContractDetails(data);
    } catch (e: any) {
      setNotification({ text: 'Customers details fetching error.', type: MessageBarType.error });
    }
  };

  const fetchInvoiceDetails = async (invoiceId: string) => {
    try {
      const { data }: any = await apiService.billingAPI.getInvoiceDetails(invoiceId);
      setInvoicetDetails(data);
    } catch (e: any) {
      setNotification({ text: 'Customers details fetching error.', type: MessageBarType.error });
    }
  };

  const fetchCreditAgainst = async (invoiceId: string) => {
    try {
      const { data }: any = await apiService.billingAPI.getCreditAgainst(invoiceId);
      setCreditAgainst(data);
    } catch (e: any) {
      setNotification({ text: 'Customers details fetching error.', type: MessageBarType.error });
    }
  };

  const fetchTotalAndDescription = async () => {
    try {
      const { data: { lineTotal } } = await apiService.billingAPI.getTotalAndDescription(
        invoiceId || '',
        +lineItemsFormFieldsGroup.getFieldFormValue('qtyField'),
        +lineItemsFormFieldsGroup.getFieldFormValue('unitPriceField'),
      );
      const { data: { description } }: any = await apiService.billingAPI.getDefaultInvoiceValues(
        invoiceId || '',
        lineItemsFormFieldsGroup.getFieldFormValue('radialOrBiasField'),
        +lineItemsFormFieldsGroup.getFieldFormValue('qtyField'),
        +lineItemsFormFieldsGroup.getFieldFormValue('unitPriceField'),
      );

      lineItemsFormFieldsGroup.setFormValue(
        'descriptionField',
        description || lineItemsFormFieldsGroup.getFieldFormValue('descriptionField'),
      );
      lineItemsFormFieldsGroup.setFormValue(
        'totalField',
        parseFloat(lineTotal || lineItemsFormFieldsGroup.getFieldFormValue('totalField')).toFixed(2),
      );

    } catch (e: any) {
      const { response } = e;
      setNotification({
        text: `Fetch total error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const fetchMBCode = async () => {
    try {
      const { data: { mbCode } } = await apiService.billingAPI.getMBCode(
        invoiceId,
        lineItemsFormFieldsGroup.getFieldFormValue('radialOrBiasField'),
      );
      lineItemsFormFieldsGroup.setFormValue(
        'mbCodeField',
        mbCode ||
        lineItemsFormFieldsGroup.getFieldFormValue('mbCodeField'),
      );
    } catch (e: any) {
      const { response } = e;
      setNotification({
        text: `Fetch MBCode error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const setCustomerDetails = (data: any) => {
    const { customerId, prefix, customerName, locationCode, location, region } = data;
    setCustomerId(customerId);
    customerFormFieldsGroup.setFormValue('prefixField', prefix);
    customerFormFieldsGroup.setFormValue('customerNameField', customerName);
    customerFormFieldsGroup.setFormValue('locationCodeField', locationCode);
    customerFormFieldsGroup.setFormValue('locationNameField', location);
    customerFormFieldsGroup.setFormValue('regionField', region);
  };

  const setContractDetails = (data: any) => {
    const { contractId, status, contractPeriod } = data;
    contractFormFieldsGroup.setFormValue('contractIdField', contractId);
    contractFormFieldsGroup.setFormValue('statusField', status);
    contractFormFieldsGroup.setFormValue('contractPeriodField', contractPeriod);
  };

  const setInvoicetDetails = async (data: any) => {
    const {
      invoiceNumber,
      status,
      date,
      type,
      locationId,
      taxExempt,
      debitOrCredit,
      additionalDetails1,
      additionalDetails2,
      additionalDetails3,
    } = data;

    setLocationId(locationId);
    setTaxExempt(taxExempt);

    const aditionalDetails =
      `${additionalDetails1 || ''}\n${additionalDetails2 || ''}\n${additionalDetails3 || ''}`;

    invoiceFormFieldsGroup.setFormValue('invoiceNumberField', invoiceNumber);
    invoiceFormFieldsGroup.setFormValue('statusField', status);
    invoiceFormFieldsGroup.setFormValue('dateField', moment(date).format('MM/DD/YYYY'));
    invoiceFormFieldsGroup.setFormValue('typeField', type);
    invoiceFormFieldsGroup.setFormValue('debitOrCreditField', debitOrCredit);
    invoiceFormFieldsGroup.setFormValue('aditionalDetailsField', aditionalDetails);


    const isDebitInvoice = debitOrCredit == 'DB';

    if (!isDebitInvoice) await fetchCreditAgainst(invoiceId);
  };

  const setCreditAgainst = (data: any) => {
    const { type, goodyearNumber, date, total } = data;
    creditFormFieldsGroup.setFormValue('typeField', type);
    creditFormFieldsGroup.setFormValue('statusField', status);
    creditFormFieldsGroup.setFormValue('goodyearNumberField', goodyearNumber);
    creditFormFieldsGroup.setFormValue('dateField', moment(date).format('MM/DD/YYYY'));
    creditFormFieldsGroup.setFormValue('totalField', total);
  };

  const setLineItemInfo = (lineItem: any) => {
    const {
      qty,
      wheelPosition,
      radialOrBias,
      mbCode,
      unitPrice,
      total,
      cost,
      description,
    } = lineItem;
    lineItemsFormFieldsGroup.setFormValue('qtyField', qty);
    lineItemsFormFieldsGroup.setFormValue('wheelPositionField', wheelPosition);
    lineItemsFormFieldsGroup.setFormValue('radialOrBiasField', radialOrBias);
    lineItemsFormFieldsGroup.setFormValue('mbCodeField', mbCode);
    lineItemsFormFieldsGroup.setFormValue('unitPriceField', unitPrice);
    lineItemsFormFieldsGroup.setFormValue('totalField', parseFloat(total).toFixed(2));
    lineItemsFormFieldsGroup.setFormValue('costField', cost);
    lineItemsFormFieldsGroup.setFormValue('descriptionField', description);
  };

  const handleRowClick = (rowsSelected: any) => {
    if (rowsSelected.length) {
      setTotalFetchDisabled(true);
      setLineItemSelected(rowsSelected[0]);
    }
    else {
      setLineItemSelected(undefined);
      clearLineItemFields();
    }
  };

  const loadInitialData = async () => {
    toggleShowLoadingAvailableInitialData();
    await fetchCustomerDetails(invoiceId);
    await fetchContractDetails(invoiceId);
    await fetchInvoiceDetails(invoiceId);
    await fetchAvailableLineItems();
    toggleShowLoadingAvailableInitialData();
  };

  const clearLineItemFields = () => {
    lineItemsFormFieldsGroup.cleanFormData();
  };

  const onAddClicked = async () => {
    toggleShowLoadingAvailableTable();
    const payload = {
      customerId: customerId,
      locationId: locationId,
      contractId: contractFormFieldsGroup.getFieldFormValue('contractIdField'),
      invoiceId: invoiceId,
      qty: lineItemsFormFieldsGroup.getFieldFormValue('qtyField'),
      whlPos: lineItemsFormFieldsGroup.getFieldFormValue('wheelPositionField'),
      rorB: lineItemsFormFieldsGroup.getFieldFormValue('radialOrBiasField'),
      mbCode: lineItemsFormFieldsGroup.getFieldFormValue('mbCodeField'),
      unitPrice: +lineItemsFormFieldsGroup.getFieldFormValue('unitPriceField'),
      total: lineItemsFormFieldsGroup.getFieldFormValue('totalField'),
      cost: +lineItemsFormFieldsGroup.getFieldFormValue('costField'),
      description: lineItemsFormFieldsGroup.getFieldFormValue('descriptionField'),
    };

    try {
      await api.billingAPI.addInvoiceItem(payload);
      fetchAvailableLineItems();
      addNotification({ text: 'Added line Item', type: MessageBarType.success });
    } catch (e: any) {
      addNotification({ text: 'Fetching item error', type: MessageBarType.error });
    }
    finally {
      toggleShowLoadingAvailableTable();
    }
  };

  const onUpdateClicked = async () => {
    toggleShowLoadingAvailableTable();
    const payload = {
      id: lineItemSelected?.id,
      contractId: contractFormFieldsGroup.getFieldFormValue('contractIdField'),
      invoiceId: invoiceId,
      qty: lineItemsFormFieldsGroup.getFieldFormValue('qtyField'),
      whlPos: lineItemsFormFieldsGroup.getFieldFormValue('wheelPositionField'),
      rorB: lineItemsFormFieldsGroup.getFieldFormValue('radialOrBiasField'),
      mbCode: lineItemsFormFieldsGroup.getFieldFormValue('mbCodeField'),
      unitPrice: +lineItemsFormFieldsGroup.getFieldFormValue('unitPriceField'),
      total: lineItemsFormFieldsGroup.getFieldFormValue('totalField'),
      cost: +lineItemsFormFieldsGroup.getFieldFormValue('costField'),
      description: lineItemsFormFieldsGroup.getFieldFormValue('descriptionField'),
    };

    try {
      await api.billingAPI.updateInvoiceItem(payload);
      fetchAvailableLineItems();
      addNotification({ text: 'Updated line Item', type: MessageBarType.success });
    } catch (e: any) {
      addNotification({ text: 'Fetching item error', type: MessageBarType.error });
    }
    finally {
      toggleShowLoadingAvailableTable();
    }
  };

  const onDeleteClicked = async () => {
    toggleShowLoadingAvailableTable();
    const items: any = [];
    items.push(lineItemSelected?.id);
    try {
      await api.billingAPI.deleteInvoiceItem(items);
      fetchAvailableLineItems();
      addNotification({ text: 'Deleted line Item', type: MessageBarType.success });
    } catch (e: any) {
      addNotification({ text: 'Deleting item error', type: MessageBarType.error });
    }
    finally {
      toggleShowLoadingAvailableTable();
    }
  };

  const onSave = async () => {

    toggleShowLoadingAvailableTable();

    const lines: Array<string> = invoiceFormFieldsGroup.getFieldFormValue('aditionalDetailsField').split('\n');

    const payload = {
      invoiceId: invoiceId,
      customerId: customerId,
      locationId: locationId,
      contractId: contractFormFieldsGroup.getFieldFormValue('contractIdField'),
      additionalDetails1: lines[0] ? lines[0] : null,
      additionalDetails2: lines[1] ? lines[1] : null,
      additionalDetails3: lines[2] ? lines[2] : null,
      invDate: invoiceFormFieldsGroup.getFieldFormValue('dateField'),
      type: invoiceFormFieldsGroup.getFieldFormValue('typeField'),
      taxExmpted: taxExempt,
      debitOrCredit: invoiceFormFieldsGroup.getFieldFormValue('debitOrCreditField'),
    };

    try {
      await apiService.billingAPI.saveInvoice(payload);
      setNotification({ text: 'Saved invoice Item', type: MessageBarType.success });
      clearStates();
      onSubmit();
    } catch (e: any) {
      setNotification({ text: 'Saveing invoice error', type: MessageBarType.error });
    }
    finally {
      toggleShowLoadingAvailableTable();
    }
  };

  const getCustomerById = (idToFind: number) => customersList?.find(({ id }) => id === idToFind) || null;

  const handlePrint = async (printingType: any) => {
    toggleShowPrintExport();

    setCustomerSelected(getCustomerById(customerFormFieldsGroup.getFieldFormValue('prefixField')));

    const requestData = {
      invoiceSearch: {
        invoiceId: invoiceId,
        pagination: null,
        sortOrder: null,
      },
      headerFields: [],
    };
    try {
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.billingAPI.printExcelInvoiceItems(requestData) :
        await apiService.billingAPI.printPdfInvoiceItems(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch {
      addNotification({
        text: 'Error: File was not downloaded.',
        type: MessageBarType.error,
      });
    }
  };

  const clearStates = () => {
    setCustomerId(undefined);
    setLineItemSelected(undefined);
    setTaxExempt(undefined);
    setLocationId(undefined);
  };

  const isMBCodeCorrect = lineItemsFormFieldsGroup.getFieldFormValue('mbCodeField')?.length == 9;

  const disableAddLineBtn = !!lineItemSelected || isMBCodeCorrect
    || !lineItemsFormFieldsGroup.getFieldFormValue('qtyField')
    || !lineItemsFormFieldsGroup.getFieldFormValue('unitPriceField')
    || !lineItemsFormFieldsGroup.getFieldFormValue('costField')
    || !lineItemsFormFieldsGroup.getFieldFormValue('descriptionField');

  useEffect(() => {
    if (isOpen) loadInitialData();
  }, [isOpen]);

  useEffect(() => {
    if (lineItemSelected) setLineItemInfo(lineItemSelected);
  }, [lineItemSelected]);

  useEffect(() => {
    if((lineItemsFormFieldsGroup.getFieldFormValue('qtyField') &&
      lineItemsFormFieldsGroup.getFieldFormValue('radialOrBiasField') &&
      lineItemsFormFieldsGroup.getFieldFormValue('unitPriceField') &&
      !isTotalFetchDisabled)) {
      fetchTotalAndDescription();
    }
    setTotalFetchDisabled(false);
  }, [
    lineItemsFormFieldsGroup.getFieldFormValue('radialOrBiasField'),
    lineItemsFormFieldsGroup.getFieldFormValue('qtyField'),
    lineItemsFormFieldsGroup.getFieldFormValue('unitPriceField'),
  ]);

  useEffect(() => {
    if (
      !lineItemsFormFieldsGroup.getFieldFormValue('radialOrBiasField')
      || lineItemSelected
    ) return;
    fetchMBCode();
  }, [lineItemsFormFieldsGroup.getFieldFormValue('radialOrBiasField')]);

  return (
    <>
      <Modal
        key={id}
        isOpen={isOpen}
        onDismiss={onCancel}
        containerClassName={styles.modalContainer}
      >
        <div className={styles.modalBody}>
          <div className='modalHeader'>
            <Text variant='xLarge'>{title}</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel='Close popup modal'
              onClick={() => {
                clearStates();
                onCancel();
              }}
            />
          </div>
          {isLoadingInitialData ?
            <Spinner size={SpinnerSize.large} className={styles.spinner} /> :
            (<>
              <div className={classNames('ms-grid', styles.marginBottom20)} dir='ltr'>
                <div className={classNames('ms-Grid-row')}>
                  <div className={classNames('ms-Grid-col', 'ms-sm6', styles.marginTop20, styles.leftContainer)}>
                    <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                      <TextField {...customerFormFieldsGroup.getFieldForm('prefixField')} />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm8')}>
                      <TextField {...customerFormFieldsGroup.getFieldForm('customerNameField')} />
                    </div>
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm2', styles.marginTop20, styles.rightContainer)}>
                    <TextField {...customerFormFieldsGroup.getFieldForm('regionField')} />
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm6', styles.marginTop20, styles.leftContainer)}>
                    <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                      <TextField {...customerFormFieldsGroup.getFieldForm('locationCodeField')} />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm8')}>
                      <TextField {...customerFormFieldsGroup.getFieldForm('locationNameField')} />
                    </div>
                  </div>
                </div>
              </div>

              <SeparatorGy />

              <div className={classNames('ms-grid', styles.marginTop20, styles.marginBottom20)} dir='ltr'>
                <div className={classNames('ms-Grid-row')}>
                  <div className={classNames('ms-Grid-col', 'ms-sm5', styles.marginTop20)}>
                    <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                      <TextField {...contractFormFieldsGroup.getFieldForm('contractIdField')} />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                      <TextField {...contractFormFieldsGroup.getFieldForm('statusField')} />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                      <TextField {...contractFormFieldsGroup.getFieldForm('contractPeriodField')} />
                    </div>
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm7', styles.marginTop20)}>
                    <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                      <TextField {...invoiceFormFieldsGroup.getFieldForm('invoiceNumberField')} />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                      <TextField {...invoiceFormFieldsGroup.getFieldForm('statusField')} />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                      <TextField {...invoiceFormFieldsGroup.getFieldForm('dateField')} />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm2')}>
                      <TextField {...invoiceFormFieldsGroup.getFieldForm('typeField')} />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm1')}>
                      <TextField {...invoiceFormFieldsGroup.getFieldForm('debitOrCreditField')} />
                    </div>
                  </div>
                </div>
                <div style={{
                  marginTop: '8px',
                }} className={classNames('ms-Grid-row')}>
                  <div className={classNames('ms-Grid-col', 'ms-sm11')}>
                  </div>
                </div>
              </div>

              <SeparatorGy />

              <div className={classNames('ms-grid', styles.marginTop20, styles.marginBottom20)} dir='ltr'>
                <div className={classNames('ms-Grid-row')}>
                  <div className={classNames('ms-Grid-col', 'ms-sm8', styles.marginTop20)}>
                    <DataGridComponent
                      idTable={'available-line-items'}
                      title='Available Line Items'
                      headCells={availableLineItemsHeadCell}
                      rowsTable={availableLineItemsList}
                      totalDataFound={totalFound}
                      isLoading={isLoadingAvailableTable}
                      enableCheckBox
                      enableShowFound
                      enableDeleteOption
                      handleSelectRow={handleRowClick}
                      handleDelete={onDeleteClicked}
                    />
                    <div className={classNames(styles.amountContainer)}>
                      <Text variant='mediumPlus'><b>Total Cost Amount: </b>{`$${amounts.totalCostAmount}`}</Text>
                      <Text variant='mediumPlus'><b>Total Amount: </b>{`$${amounts.totalAmount}`}</Text>
                    </div>
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm4', styles.marginTop20)}>
                    <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                      <Text variant='large'>Credit Against</Text>
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                      <TextField {...creditFormFieldsGroup.getFieldForm('typeField')} />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                      <TextField {...creditFormFieldsGroup.getFieldForm('goodyearNumberField')} />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                      <TextField {...creditFormFieldsGroup.getFieldForm('dateField')} />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                      <TextField {...creditFormFieldsGroup.getFieldForm('totalField')} />
                    </div>
                  </div>
                  {/**here */}
                  <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop50)}>
                    <div className={classNames('ms-Grid-col')}>
                      <TextField {...lineItemsFormFieldsGroup.getFieldForm('qtyField')} disabled={!isMaintain} required />
                    </div>
                    <div className={classNames('ms-Grid-col')}>
                      <TextField {...lineItemsFormFieldsGroup.getFieldForm('wheelPositionField')} disabled={!isMaintain} />
                    </div>
                    <div className={classNames('ms-Grid-col')}>
                      <TextField {...lineItemsFormFieldsGroup.getFieldForm('radialOrBiasField')} disabled={!isMaintain} />
                    </div>
                    <div className={classNames('ms-Grid-col')}>
                      <TextField {...lineItemsFormFieldsGroup.getFieldForm('mbCodeField')} disabled={!isMaintain} required />
                    </div>
                    <div className={classNames('ms-Grid-col')}>
                      <TextField {...lineItemsFormFieldsGroup.getFieldForm('unitPriceField')} disabled={!isMaintain} required />
                    </div>
                    <div className={classNames('ms-Grid-col')}>
                      <TextField {...lineItemsFormFieldsGroup.getFieldForm('totalField')} disabled={!isMaintain} />
                    </div>
                    <div className={classNames('ms-Grid-col')}>
                      <TextField {...lineItemsFormFieldsGroup.getFieldForm('costField')} disabled={!isMaintain} required />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                      <TextField {...lineItemsFormFieldsGroup.getFieldForm('descriptionField')} disabled={!isMaintain} required />
                    </div>
                  </div>
                  {isMaintain && <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
                    <div className={styles.editBtnContainer}>
                      <PrimaryButton text='Clear' onClick={clearLineItemFields} />
                      <PrimaryButton text='Add New' onClick={onAddClicked} disabled={lineItemSelected != undefined || disableAddLineBtn} />
                      <PrimaryButton text='Update' onClick={onUpdateClicked} disabled={!isMBCodeCorrect && lineItemSelected != undefined} />
                    </div>
                  </div>}
                </div>
              </div>

              <SeparatorGy />

              <div className={classNames('ms-grid', styles.marginTop50)} dir='ltr'>
                <div className={classNames('ms-Grid-row')}>
                  <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                    <Text variant='large'>Additional Details</Text><br />
                    <Text variant='small'>(to be shown at the bottom of the invoice)</Text>
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                    <TextField
                      {...invoiceFormFieldsGroup.getFieldForm('aditionalDetailsField')}
                      multiline
                      rows={8}
                      disabled={!isMaintain}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.modalFooter}>
                <DefaultButton
                  text='Cancel'
                  onClick={onCancel} />
                {actionType != 'view' && <PrimaryButton
                  id='saveButton'
                  text='Save'
                  onClick={onSave}
                />}
                <DefaultButton
                  id='printExportBtn'
                  text='Print/Export'
                  onClick={toggleShowPrintExport}
                />
              </div>
            </>
            )}
        </div>
      </Modal>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
    </>
  );
};

export default ViewMaintainInvoicesModalComponent;