import classNames from 'classnames';
import { FC, FormEvent, ReactElement, useState } from 'react';
import { IContractShellModalProps } from './IContractShellModalProps';
import { IContractShellModalState } from './IContractShellModalState';
import styles from './ContractShellModal.module.scss';
import { Text, Label, Dropdown, Separator, PrimaryButton, DefaultButton, IDropdownStyles, IDropdownOption } from '@fluentui/react';
import { contractShellList } from './consts';

const ContractShellModal: FC<IContractShellModalProps> = ({ isOpened, method, onClose }): ReactElement => {
  const [state, setState] = useState<IContractShellModalState>({
    contractShellName: null,
  });

  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 150 } };

  const onChangeContractShellName = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any>): void => {
    setState((prev: any) => ({ ...prev, contractShellName: item.key }));
  };

  return (
    <div className={classNames(styles.root, { [styles.closed]: !isOpened })}>
      <div className={classNames('ms-Grid', styles.window)}>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col">
            <Text variant="xLarge">Select Contract Shell</Text>
          </div>
        </div>
        <div className={classNames(styles.mainBlock, 'ms-Grid-row')}>
          <div className={classNames('ms-Grid-col', styles.centeredContentColumn)}>
            <Label htmlFor="printMethod">Select Shell Name:</Label>
            <Dropdown
              id="printMethod"
              onChange={(e, item: any) => onChangeContractShellName(e, item)}
              placeholder="Select an option"
              options={contractShellList.map(el => ({
                key: el,
                text: el,
              }))}
              selectedKey={state.contractShellName}
              styles={dropdownStyles}
            />
          </div>

          <div className="ms-Grid-col">
            <Separator className={styles.separator} vertical />
          </div>

          <div className={classNames('ms-Grid-col', styles.centeredContentColumn)}>
            <PrimaryButton
              id='printExportModalBtn'
              text={method.name}
              className={styles.button}
              onClick={() => method.function(state.contractShellName)}
              disabled={state.contractShellName === null}
            />
            <DefaultButton
              text="Cancel"
              onClick={onClose}
              className={styles.button}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractShellModal;