import { FC, ReactElement, useState, useEffect, FormEvent } from 'react';
import classNames from 'classnames';
import { DefaultButton, PrimaryButton, MessageBarType, IconButton, Modal, TextField, Text, Dialog, DialogFooter, DialogType, Checkbox } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useSelector } from 'react-redux';

import useNotifications from '../../hooks/useNotifications';
import { customerSelector, locationSelector } from '../../redux/recordKeepingSlice';

import LoadingScreen from '../LoadingScreen/LoadingScreen';
import SeparatorGy from '../SeparatorGy/SeparatorGy';

import { IAddingModalProps } from './IAddingModalProps';
import { IAddingModalState, IEditedRow } from './IAddingModalState';

import styles from './AddingModal.module.scss';
import { fieldType } from '../../consts/fieldType';

const AddingModal: FC<IAddingModalProps> = ({ isModalOpen, hideModal, saveTableData, fetchAfterAdding, columns, additionalFieldsToBody, useDefaultsProps = false }): ReactElement => {

  // Fill by default values
  useEffect(() => {
    const object: any = {};
    columns.forEach(column => object[column.fieldName] = '');
    setEmptyObject({...object});
    setState(prev => ({ ...prev, items: [{...object}] }));
    setDefaults({...object});
  }, []);

  const [emptyObject, setEmptyObject] = useState<any>({});

  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);
  const { addNotification } = useNotifications();
  const [state, setState] = useState<IAddingModalState>({
    items: [{}],
    selectedItems: [],
    loading: false,
  });

  const [editedCell, setEditedCell] = useState<IEditedRow>({ index: -1, column: '' });
  const [editedRow, setEditedRow] = useState<number>(-1);
  const [parsedErrors, setParsedErrors] = useState<any>([]);
  const [isClosingDialogVisible, { toggle: toggleClosingConfirmation }] = useBoolean(false);
  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [useDefaults, { toggle: toggleUseDefaults }] = useBoolean(false);
  const [defaultCell, setDefaultCell] = useState<any>('');
  const [defaults, setDefaults] = useState<any>({});

  const updateDefaultValues = (defaultValues: any, field: string, value: any | undefined) => {
    if (field == 'pfx' || field == 'newPfx' || field == 'sfx' || field == 'newSfx') {
      defaultValues[field] = value?.toUpperCase();
    } else {
      defaultValues[field] = value;
    }
    
    return defaultValues;
  };

  const setDefaultCheckboxValues = (ev?: FormEvent<HTMLElement | HTMLInputElement>, value?: boolean | undefined) => {
    setDefaults(updateDefaultValues({...defaults}, defaultCell, value));
  };

  const setDefaultTextValues = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | undefined) => {
    setDefaults(updateDefaultValues({...defaults}, defaultCell, value));
  };

  const onEdit = (indexEditedRow: number) => {
    console.log('defval');
    console.log(defaults);
    if (indexEditedRow !== editedRow && useDefaults) {
      setEditedRow(indexEditedRow);
      setState(prev => ({ ...prev, items: [...state.items].map((item, index) => index === indexEditedRow ? {...defaults} : item) }));
    }       
  };

  const updateRowsData = (currentState: any, field: string, value: string | boolean | undefined) => {
    return currentState.map((item: { [x: string]: string | boolean | undefined; }, index: number) => {
      if (editedCell.index === index) {
        item[field] = value;
      }
      return item;
    });
  };

  const setValueToTextField = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], editedCell.column, value?.toUpperCase())}));
  };

  const setValueToCheckboxField = (ev?: FormEvent<HTMLElement | HTMLInputElement>, value?: boolean | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], editedCell.column, value)}));
  };

  const handleSelectAdding = (e: any, itemId: any) => {
    const selectedRows = [...state.selectedItems];
    if (e.target.checked) {
      selectedRows.push(itemId);
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows.filter(row => row !== itemId) }));
    }
  };

  const handleSelectAllAdding = (e: any, items: Array<any>) => {
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedItems: items })) : setState((prev: any) => ({ ...prev, selectedItems: [] }));
  };

  const parseErrors = (index: number, field: string) => {
    const customError = parsedErrors?.filter((error: { index: number; field: string; }) => error.index === index && error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  const highlightRowIfError = (index: any) => {
    if (parsedErrors.length > 0) {
      return parsedErrors.filter((error: { index: any; }) => error.index === index).length > 0;
    }
  };

  const handleSave = async () => {
    const currentState = [...state.items];
    const lastRow = state.items[state.items.length - 1];
    if(currentState.length <= 10 && Object.values(lastRow).every(cellValue => cellValue === '')) {
      currentState.pop();
    }
    try {
      setState(prev => ({ ...prev, loading: true }));
      // If row number < 10 then remove last empty
      if (additionalFieldsToBody) {
        await saveTableData(currentState.map(item => ({...item, ...additionalFieldsToBody })), customerId, locationId);
      } else {
        await saveTableData(currentState, customerId, locationId);
      }
      addNotification({
        text: 'Records were successfully added.',
        type: MessageBarType.success,
      });
      setState(prev => ({ ...prev, items: [{...emptyObject}], loading: false }));
      setParsedErrors([]);
      await fetchAfterAdding();
    } catch (e: any) {
      setState(prev => ({ ...prev, loading: false }));
      const { response } = e;
      switch (response.status) {
        case 499:
          return addNotification({
            text: 'Tire brand number is still active. Unable to add',
            type: MessageBarType.error,
          });
        case 498:
          return addNotification({
            text: 'Tire not eligible for rebrandment - check status',
            type: MessageBarType.error,
          });
        case 400:
          setParsedErrors(response.data.state);
          return addNotification({
            text: 'Unable to save changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Rebrand saving error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    }
  };

  const handleDelete: any = async () => {
    const currentState = [...state.items];
    setState(prev => ({ ...prev, items: currentState.filter((item, index) => !state.selectedItems.includes(index)) }));
    setState((prev: any) => ({ ...prev, selectedItems: [] }));
    setParsedErrors([]);
    toggleDeletingConfirmation();
  };

  const handleClose = () => {
    setState(prev => ({ ...prev, items: [{...emptyObject}], loading: false }));
    setParsedErrors([]);
    toggleClosingConfirmation();
    hideModal();
  };

  const renderLargeLabel = (props: any) => (
    <Text variant="large">{props.label}</Text>
  );

  useEffect(() => {
    const currentState = [...state.items];
    const lastRow = state.items[state.items.length - 1];
    // Allow to generate empty rows if less then 10 and last row not empty. Check for undefined - if user deleted empty row
    if(lastRow === undefined || (currentState.length < 10 && !Object.values(lastRow).every(cellValue => cellValue === ''))) {
      currentState.push({...emptyObject});
      setState(prev => ({ ...prev, items: currentState }));
    }
  }, [state.items]);

  return state.loading ? <LoadingScreen /> : (
    <>
      <Modal
        isOpen={isModalOpen}
        isBlocking={true}
        containerClassName={styles.modalContainer}
      >
        <div className={styles.modalBody}>
          <div className={styles.tableHeading}>
            <div />
            {
              useDefaultsProps && 
              <div className={styles.useDefaults}>
                <Checkbox id="useDefaults" label="Use Defaults:" onRenderLabel={renderLargeLabel} checked={useDefaults} onChange={toggleUseDefaults} />
                <div>
                  {
                    columns.map((column) => {
                      switch(column.type) {
                        case fieldType.CHECKBOX:
                          return (
                            <td
                              key={column.fieldName}
                              onFocus={() => setDefaultCell(column.fieldName)}>
                              <Checkbox
                                label={column.name}
                                checked={defaults[column.fieldName]}
                                onChange={setDefaultCheckboxValues}
                              />
                            </td>
                          );
                        default:
                          return (
                            <td
                              key={column.fieldName}
                              onFocus={() => setDefaultCell(column.fieldName)}>
                              <TextField
                                label={column.name}
                                value={defaults[column.fieldName]}
                                onChange={setDefaultTextValues}
                              />
                            </td>
                          );
                      }
                    })
                  }
                </div>
              </div>
            }
            <div>
              <Text variant="large">{state.selectedItems.length} items selected</Text>
              <SeparatorGy vertical />
              <IconButton
                id="deleteIcon"
                disabled={!state.selectedItems.length}
                iconProps={{ iconName: 'Delete' }}
                onClick={toggleDeletingConfirmation}
              />
            </div>
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  {
                    columns.map(item => (
                      <th key={item.name}>{item.name}</th>
                    ))
                  }
                  <th>
                    <div className={styles.round}>
                      <input type="checkbox" id="allAdding" checked={state.selectedItems.length === state.items.length} onChange={(e) => handleSelectAllAdding(e, state.items.map((item, index) => index))} />
                      <label htmlFor="allAdding"></label>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  state.items.map((item, index) => (
                    <tr key={index} 
                      className={classNames(state.selectedItems.includes(index) ? styles.trSelected : styles.trBasic, 
                        highlightRowIfError(index) ? styles.trError : styles.trBasic)}
                      onFocus={() => onEdit(index)}>
                      {
                        columns.map((column) => {
                          switch(column.type) {
                            case fieldType.CHECKBOX:
                              return (
                                <td
                                  onFocus={() => setEditedCell((prev: any) => ({ ...prev, index, column: column.fieldName }))}>
                                  <Checkbox
                                    label={item[column.name]}
                                    checked={item[column.fieldName]}
                                    onChange={setValueToCheckboxField}
                                  />
                                </td>
                              );
                            default:
                              return (
                                <td
                                  onFocus={() => setEditedCell((prev: any) => ({ ...prev, index, column: column.fieldName }))}>
                                  <TextField
                                    id={column.fieldName}
                                    styles={ { fieldGroup: { border: '1px solid grey' }}}
                                    value={item[column.fieldName]}
                                    onChange={setValueToTextField}
                                    errorMessage={parseErrors(index, column.fieldName)}
                                  />
                                </td>
                              );
                          }
                        })
                      }
                      <td>
                        <div className={styles.round}>
                          <input type="checkbox" id={'Add' + index.toString()} checked={state.selectedItems.includes(index)} onChange={(e) => handleSelectAdding(e, index)} />
                          <label htmlFor={'Add' + index.toString()}></label>
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <SeparatorGy />
          <div className={styles.modalFooter}>
            <DefaultButton text="Close" onClick={() => state.items.length > 1 ? toggleClosingConfirmation() : hideModal()} />
            <PrimaryButton id="saveAdded" text="Save" onClick={() => handleSave()} disabled={state.items[0] === undefined || Object.values(state.items[0]).every(cellValue => cellValue === '')} />
          </div>
        </div>
      </Modal>
      <Dialog
        hidden={!isClosingDialogVisible}
        onDismiss={toggleClosingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: 'Are you sure you want to close Adding modal?',
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton onClick={() => handleClose()} text="Close" />
          <DefaultButton onClick={toggleClosingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to delete ${state.selectedItems.length} items?`,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteDialogButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default AddingModal;
