import { HeadCell } from '../../../../../shared/DataGridComponent/DataGridModels';

export const defaultRowsPerPage = [5, 10, 20];
export const defaultRowsPerPageAttac = [20, 50, 100];

export const hoursArrayOptions = Array
  .from(Array(13).keys())
  .slice(1)
  .map((i: any) => {
    const value = i.toString().length === 1 ? `0${i}` : i.toString();
    return ({ key: value, text: value });
  });

export const minutesArrayOptions = Array
  .from(Array(60).keys())
  .map((i: any) => {
    const value = i.toString().length === 1 ? `0${i}` : i.toString();
    return ({ key: value, text: value });
  });

export const dateTimeArrayOptions =
  ['am', 'pm'].map((i: any) => ({ key: i, text: i }));

export const datesExcludedHeadCell: Array<HeadCell> = [
  {
    key: 'column1',
    name: 'Date',
    fieldName: 'date',
    typeField: 'date',
    isEditable: false,
  },
];

export const attachmentHeadCell: Array<HeadCell> = [
  {
    key: 'column1',
    name: 'Name',
    fieldName: 'recipientName',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Email',
    fieldName: 'recipientEmail',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Notify When',
    fieldName: 'notifyWhen',
    typeField: 'text',
    isEditable: false,
  },
];

export const addOrRemoveArrayItem = (array: Array<any>, itemToReview: string) => {
  let currentArray = array || [];
  if (currentArray.includes(itemToReview))
    currentArray = currentArray.filter((currentItem: string) => currentItem != itemToReview);
  else currentArray.push(itemToReview);
  return currentArray;
};

export const defaultOnceParameters = { runOn: null };
export const defaultDailyParameters = { numberDay: '' };
export const defaultWeekyParameters = { days: [] };
export const defaultMonthlyParameters = { months: [], isByWeek: false, numberDayOfMonth: '', weekOfTheMonth: '', numberDayOfWeek: '' };