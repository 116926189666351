import { MessageBarType } from '@fluentui/react';
import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import { useField } from '../../../../../hooks/useField';
import { useBoolean } from '@fluentui/react-hooks';
import { INotification } from '../../../../../models/INotification';
import useNotifications from '../../../../../hooks/useNotifications';
import HistoryForm from '../../../../../shared/HistoryForm';
import { HISTORY_FIELDS } from '../../../../../shared/HistoryForm/consts';
import apiService from '../../../../../api';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { contractorSelector, setContractor } from '../../../../../redux/contractSlice';
interface HistoryComponentProps {
  actionType: string
}

const HistoryComponent: FunctionComponent<HistoryComponentProps> = ({ actionType }) => {

  const contractSelected = useSelector(contractorSelector);
  const dispatch = useDispatch();
  const parentComponentName = 'TERMS';

  const { addNotification } = useNotifications();
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });

  // form validations
  const commentsField = useField(HISTORY_FIELDS.commentsDataField);
  const createdByField = useField(HISTORY_FIELDS.createdByDataField);
  const createdOnField = useField(HISTORY_FIELDS.createdOnDataField);
  const lastUpdatedByField = useField(HISTORY_FIELDS.lastUpdatedNameDataField);
  const lastUpdatedOnField = useField(HISTORY_FIELDS.lastUpdatedDateDataField);
  const [isLoading, { toggle: toggleShowLoading }] = useBoolean(false);
  const { contractId, id } = useParams<{ contractId: any, id: any }>();


  const handleUpdateComments = async () => {
    try {
      toggleShowLoading();
      const bodyRequest = { contractId: contractId, comments: commentsField.getValue() };
      await apiService.terms.updateTermHistory(bodyRequest);
      const { data: { data } } = await apiService.terms.getContracts(null, null, '', id, contractId);
      dispatch(setContractor(data.find((contract: any) => contract.contractId === contractId)));
      setNotification({ text: 'Comments updated successfully', type: MessageBarType.success });
    } catch (error) {
      setNotification({ text: 'Updating comments error', type: MessageBarType.error });
    } finally {
      toggleShowLoading();
    }
  };

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  useEffect(() => {
    if (!contractSelected) return;
    const { comments, createdBy, createdOn, updatedBy, lastModOn } = contractSelected;
    commentsField.setValue(comments);
    createdByField.setValue(createdBy);
    createdOnField.setValue(moment(createdOn).format('MM/DD/YYYY'));
    lastUpdatedByField.setValue(updatedBy);
    lastUpdatedOnField.setValue(moment(lastModOn).format('MM/DD/YYYY'));
  }, [contractSelected]);

  return (
    <div id="history-form">
      <HistoryForm
        actionType={actionType}
        comments={contractSelected.comments}
        handleUpdateComments={handleUpdateComments}
        isLoading={isLoading}
        commentsField={commentsField}
        createdByField={createdByField}
        createdOnField={createdOnField}
        lastUpdatedByField={lastUpdatedByField}
        lastUpdatedOnField={lastUpdatedOnField}
        parentComponentName={parentComponentName}
      />
    </div>
  );
};

export default HistoryComponent;
