import { DefaultButton, MessageBarType, PrimaryButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import apiService from '../../../../../api';
import useNotifications from '../../../../../hooks/useNotifications';
import DataGridComponent from '../../../../../shared/DataGridComponent';
import { defaultDataGridPageSizes } from '../../../../../shared/DataGridComponent/utils';
import { downloadFile, printingTypes } from '../../../../PrintingModal/consts';
import PrintingModal from '../../../../PrintingModal/PrintingModal';
import { columns, intervalsHeadCells } from './consts';
import { ITreadDepthProps } from './ITreadDepthProps';
import { ITreadDepthState } from './ITreadDepthState';
import { useUserPermissions } from '../../../../../hooks/useUserPermissions';
import { auth_terms_treadDepth } from '../../../../../consts/programKeys';
import styles from './TreadDepth.module.scss';

const TreadDepth: FC<ITreadDepthProps> = () => {
  const { addNotification } = useNotifications();
  const { id } = useParams<{ id: string }>();
  const { contractId } = useParams<{ contractId: string }>();

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_terms_treadDepth);

  const [state, setState] = useState<ITreadDepthState>({
    items: [],
    foundCount: 0,
    loading: false,
    intervals: [],
    intervalsFound: 0,
    loadingIntervals: false,
  });
  const [productSelected, setproductSelected] = useState<any>(null);
  const [intervalsToUpd, setIntervalsToUpd] = useState<any>([]);
  const [treadsToUpd, setTreadsToUpd] = useState<any>([]);
  const [paginationProps, setPaginationProps] = useState<any>();
  const [sortOrder, setSortOrder] = useState();
  const [searchedText, setSearchedText] = useState();
  const [tableSize, setTableSize] = useState(5);

  //toggle
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);

  const fetchTreadDepth = async (
    pagination: any = { pageSize: defaultDataGridPageSizes[0], pageNumber: 1 },
    searchedText?: any,
    sortOrder: any = { column: columns[0].fieldName, order: 'asc' },
  ) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.treadDepth.getTreadDepth(
        pagination,
        sortOrder,
        searchedText,
        contractId,
      );
      const foundCount = data.total.found;
      setState(prev => ({ ...prev, items: data.data, foundCount, intervals: [] }));
      setproductSelected(null);
      setTreadsToUpd([]);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Tread Depth fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchUpdateTreads = async () => {
    try {
      const { data } = await apiService.treadDepth.updateTreads(
        treadsToUpd,
      );
      addNotification({
        text: 'Treads Depth successfully updated',
        type: MessageBarType.success,
      });
      setTreadsToUpd([]);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Treads Depth updating error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    };
  };

  const fetchTreadDepthIntervals = async (
    pagination: any = { pageSize: defaultDataGridPageSizes[0], pageNumber: 1 },
    filters?: any,
    sortOrder: any = { column: intervalsHeadCells[0].fieldName, order: 'asc' },
  ) => {
    try {
      const { data }: any = await apiService.treadDepth.getTreadDepthIntervals(
        pagination,
        sortOrder,
        filters,
        contractId,
        productSelected.productCode,
      );
      setState({ ...state, intervals: data.data, intervalsFound: data.total.found });
      setIntervalsToUpd([]);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Tread Depth intervals fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loadingIntervals: false }));
    }
  };

  const fetchAddInterval = async () => {
    try {
      const { data } = await apiService.treadDepth.addTreadDepthInterval(
        contractId,
        productSelected.productCode,
      );
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Tread Depth intervals adding error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    };
  };

  const fetchUpdateInterval = async () => {
    try {
      const { data } = await apiService.treadDepth.updateTreadDepthInterval(
        intervalsToUpd,
      );
      addNotification({
        text: 'Tread Depth interval successfully updated',
        type: MessageBarType.success,
      });
      setIntervalsToUpd([]);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Tread Depth intervals updating error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    };
  };

  const fetchDeleteInterval = async (intervalToDelete: any) => {
    try {
      const { data }: any = await apiService.treadDepth.deleteTreadDepthInterval(
        intervalToDelete.id,
      );
      addNotification({
        text: 'Tread Depth interval successfully deleted',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Tread Depth intervals deleting error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    };
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const requestData = {
        contractId,
        pagination: { pageNumber: paginationProps.current, pageSize: tableSize },
        sortOrder: [sortOrder],
        filterText: searchedText,
      };
      const headerFields = [
        { title: 'customerId', value: id },
      ];
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.treadDepth.printExcel(requestData, headerFields) :
        await apiService.treadDepth.printPdf(requestData, headerFields);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleChangeTreadsDataGridState = async (dataGridState: any) => {
    setState({ ...state, loading: true });
    const { countOnPage, paginationProps, sortOrder, searchedText } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    setSortOrder(sortOrder);
    setPaginationProps(paginationProps);
    setSearchedText(searchedText);
    setTableSize(+countOnPage.key);
    await fetchTreadDepth(pagination, searchedText, sortOrder);
  };

  const handleChangeIntervalDataGridState = async (dataGridState: any) => {
    if (!productSelected) return;
    setState({ ...state, loadingIntervals: true });
    const { countOnPage, paginationProps, sortOrder, searchedText } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    await fetchTreadDepthIntervals(pagination, searchedText, sortOrder);
  };

  const handleChangeProductSelected = async () => {
    setState({ ...state, loadingIntervals: true });
    await fetchTreadDepthIntervals();
  };

  const handleSelectRow = (rowsSelected: Array<any>) => {
    if (!rowsSelected.length) return;
    setproductSelected(rowsSelected[0]);
  };

  const handleUpdateTreads = async () => {
    setState({ ...state, loading: true });
    await fetchUpdateTreads();
    await fetchTreadDepth();
  };

  const handleAddInterval = async () => {
    setState({ ...state, loadingIntervals: true });
    await fetchAddInterval();
    await fetchTreadDepthIntervals();
  };

  const handleUpdateInterval = async () => {
    setState({ ...state, loadingIntervals: true });
    await fetchUpdateInterval();
    await fetchTreadDepthIntervals();
  };

  const handleDeleteInterval = async (rowsToDelete: Array<any>) => {
    if (!isLastInterval(rowsToDelete[0])) {
      addNotification({
        text: 'Only last interval can be deleted',
        type: MessageBarType.error,
      });
      return;
    };
    setState({ ...state, loadingIntervals: true });
    await fetchDeleteInterval(rowsToDelete[0]);
    await fetchTreadDepthIntervals();
  };

  useEffect(() => {
    if (!productSelected) return;
    handleChangeProductSelected();
  }, [productSelected]);

  const isLastInterval = (interval: any) => {
    const lastInterval = state.intervals.sort((a, b) => b.id - a.id)[0];
    return JSON.stringify(lastInterval) == JSON.stringify(interval);
  };


  return (
    <>
      <div data-testid='Tread Depth' className="ms-Grid margin-left-rigth-2 marginTop18" dir='ltr'>
        <div className='ms-Grid-row'>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <DataGridComponent
              idTable={'add-modal-table'}
              title='Tread Depth'
              headCells={columns}
              rowsTable={state.items}
              totalDataFound={state.foundCount}
              enableSearching
              enablePagination
              enableRowsPerPage
              enableCheckBox
              isLoading={state.loading}
              handleChangeDataGridState={handleChangeTreadsDataGridState}
              handleSelectRow={handleSelectRow}
              handleUpdate={setTreadsToUpd}
              handleDelete={handleDeleteInterval}
            />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.buttonsWrapper)}>
            <PrimaryButton
              onClick={handleUpdateTreads}
              text="Update Treads"
              disabled={treadsToUpd.length == 0 || !userPermissions.isWrite}
            />
            <DefaultButton onClick={toggleShowPrintExport} text="Print/Export" />
          </div>
        </div>

        <div className='ms-Grid-row'>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <DataGridComponent
              idTable={'rate-adjustment-table'}
              title='Intervals'
              headCells={intervalsHeadCells}
              rowsTable={state.intervals}
              totalDataFound={state.intervalsFound}
              enableSearching
              enablePagination
              enableRowsPerPage
              enableDeleteOption
              enableCheckBox
              handleChangeDataGridState={handleChangeIntervalDataGridState}
              handleUpdate={setIntervalsToUpd}
              handleDelete={handleDeleteInterval}
              isLoading={state.loadingIntervals}
            />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.intervalButtons)}>
            <PrimaryButton
              onClick={handleAddInterval}
              text="+ Add new Interval"
              disabled={productSelected == null || !userPermissions.isWrite}
            />
            <PrimaryButton
              onClick={handleUpdateInterval}
              text="Update intervals"
              disabled={intervalsToUpd.length == 0  || !userPermissions.isWrite}
            />
          </div>
        </div>
      </div>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
    </>
  );
};

export default TreadDepth;
