import {
  DefaultButton,
  Dropdown,
  IColumn,
  IconButton, IDropdownOption, IIconProps, MessageBarType, PrimaryButton, Text, TextField,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import moment from 'moment';
import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import apiService from '../../../../../../../../api';
import { sortOrder } from '../../../../../../../../consts/sortOrder';
import useNotifications from '../../../../../../../../hooks/useNotifications';
import { customerSelector, locationSelector } from '../../../../../../../../redux/recordKeepingSlice';
import LoadingScreen from '../../../../../../../LoadingScreen/LoadingScreen';
import { IPaginationProps } from '../../../../../../../Pagination/IPaginationProps';
import Pagination from '../../../../../../../Pagination/Pagination';
import { downloadFile, printingTypes } from '../../../../../../../PrintingModal/consts';
import PrintingModal from '../../../../../../../PrintingModal/PrintingModal';
import SeparatorGy from '../../../../../../../SeparatorGy/SeparatorGy';
import { pageSizes } from '../../../../../../../../consts/recordKeeping';
import { IReconciliationDetail } from './IReconciliationDetail';
import { IReconciliationDetailsModalProps } from './IReconciliationDetailsModalProps';
import { IReconciliationDetailsModalState } from './IReconciliationDetailsModalState';
import styles from './ReconciliationDetailsModal.module.scss';
import { columns } from './const';

const ReconciliationDetailsModal: FC<IReconciliationDetailsModalProps> = ({
  onDismiss,
  reconcileType,
  period,
  title,
  locationIds,
  postedElement,
}): ReactElement => {

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const { addNotification } = useNotifications();

  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);

  const [state, setState] = useState<IReconciliationDetailsModalState>({
    loading: false,
    items: [],
    foundCount: 0,
    fixed: 0,
  });

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);

  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return [{
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    }];
  };

  const setItemToBeEdited = (reconcileTireId: string, field: string, value: any) => {
    setState((prev: any) => ({
      ...prev,
      items: prev.items.map((item: IReconciliationDetail) => item.reconcileTireId === reconcileTireId ? { ...item, [field]: value } : item),
    }));
  };

  const closeIcon: IIconProps = { iconName: 'Cancel' };

  const fetchReconciliationDetails = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.spareStock.getReconciliationDetails(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        { reconcileType, period, customerId, locationIds },
        sortOrder,
      );
      const foundCount = data.total.all;
      const fixed = data.fixed;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, foundCount, fixed }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      addNotification({
        text: `Posted Spare Stocks fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleUpdateStatus = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.spareStock.updateReconciliationDetails({
        reconciliationDetailsList: state.items, 
        isRecordAsLost: false,
      });
      const { data }: any = await apiService.spareStock.updateReconciliationReportStatus({
        customerId,
        locationId,
        postedSSKId: postedElement?.id,
        takenOnDate: postedElement?.invtTakenOn,
      });
      await fetchReconciliationDetails();
      addNotification({
        text: `Operation was successful. ${data?.recUpdated} Records updated.`,
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Status updating error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
    finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        filters: {
          locationIds,
          customerId,
          reconcileType,
          period,
        },
        sortOrder,
      };
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.spareStock.printExcelReconciliationDetails(requestData, []) :
        await apiService.spareStock.printPdfReconciliationDetails(requestData, []);
      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Printing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    fetchReconciliationDetails();
  }, [paginationProps.current, countOnPage, columnsState]);

  return (
    <>
      <div className={classNames(styles.detailsWrapper)}>
        <div className={classNames(styles.detailsBackground)}>
          <div
            className={classNames(styles.detailsContainer)}
            ref={containerRef}
          >
            <div className={classNames(styles.detailsHeader)}>
              <Text variant="xxLarge" className={styles.highlight}>Reconciliation Report Details: {title}</Text>
              <IconButton
                id="closeButton"
                iconProps={closeIcon}
                title="close"
                onClick={() => onDismiss()}
              />
            </div>
            <div className={classNames(styles.detailsBody)}>
              <div className={styles.tableHeading}>
                <div>
                  <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
                </div>
                <div>
                  <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                  <Dropdown
                    options={pageSizes.map(pageSize => ({
                      key: pageSize,
                      text: pageSize.toString(),
                    }))}
                    defaultSelectedKey={pageSizes[0]}
                    selectedKey={countOnPage?.key}
                    onChange={onChangeCountOnPage}
                  />
                  <SeparatorGy vertical />
                  <Text variant="xLarge" className={styles.highlight}>{state.fixed} fixed</Text>
                </div>
              </div>
              <div className={styles['table-wrapper']}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      {
                        columnsState.map(item => (
                          <th
                            key={item.name}
                            className={classNames(
                              item.isSorted && item.isSortedDescending ?
                                styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined,
                              styles[item.key],
                            )}
                            onClick={() => onColumnClick(item)}>{item.name}
                          </th>
                        ))
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {state.items.map((item) => (
                      <tr key={item.reconcileTireId} className={styles.trBasic} >
                        <td>
                          <TextField
                            id="fixed"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.fixed}
                            onChange={(e, fixed = '') => setItemToBeEdited(item.reconcileTireId, 'fixed', fixed.toUpperCase())}
                          />
                        </td>
                        <td>{item.prefix}</td>
                        <td>{item.brandNumber}</td>
                        <td>{item.suffix}</td>
                        <td>{item.class}</td>
                        <td>{item.typeCode}</td>
                        <td>{item.tireSize}</td>
                        <td>{item.priVeh}</td>
                        <td>{item.priPos}</td>
                        <td>{moment(item.priOff).format('MM/DD/YYYY h:mm:ss A')}</td>
                        <td>{item.chgLoc}</td>
                        <td>{item.invLoc}</td>
                        <td>{item.td}</td>
                        <td>{item.miles}</td>
                        <td>
                          <TextField
                            id="comments"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.comments}
                            onChange={(e, comments) => setItemToBeEdited(item.reconcileTireId, 'comments', comments)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <SeparatorGy />
              <Pagination {...paginationProps} />
            </div>
            <div className={classNames(styles.detailsFooter)}>
              <DefaultButton id="printButton" text="Print/Export" onClick={toggleShowPrintExport} />
              <PrimaryButton
                id="updateStatusButton"
                text="Update Status"
                onClick={handleUpdateStatus} />
            </div>
            <PrintingModal
              isOpened={showPrintExport}
              onClose={toggleShowPrintExport}
              onPrint={handlePrint} />
          </div>
        </div>
      </div>
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default ReconciliationDetailsModal;
