import { IChoiceGroupOption } from '@fluentui/react';

import { HeadCell } from '../../../../../shared/DataGridComponent/DataGridModels';

export const RateAdjustmentsHeadCells: HeadCell[] = [
  {
    key: 'column0',
    name: 'Interval',
    fieldName: 'interval',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Eff From',
    fieldName: 'effFrom',
    typeField: 'date',
    isEditable: false,
    isSorted: true,
    isSortedDescending: true,
  },
  {
    key: 'column2',
    name: 'Eff To',
    fieldName: 'effTo',
    typeField: 'date',
    isEditable: false,
  },
];

export const intervalOptions = [
  {
    key: 0,
    text: 'Select  new interval',
  },
  {
    key: 6,
    text: '6 Months',
  },
  {
    key: 12,
    text: '12 Months',
  },
  {
    key: 24,
    text: '24 Months',
  },
  {
    key: 36,
    text: '36 Months',
  },
];

export const getOptionByKey = (keyToFind: number | null) =>
  intervalOptions.find(option => option.key === keyToFind) ||
  intervalOptions[0];

export const options: IChoiceGroupOption[] = [
  { key: 'fixedRate', text: 'Fixed Rate' },
  { key: 'variableRate', text: 'Variable Rate' },
];

export const pageSizes = [100, 250, 500];
