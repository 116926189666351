import { Dropdown, IColumn, IDropdownOption, MessageBarType, Text } from '@fluentui/react';
import { FC, ReactElement, useEffect, useState } from 'react';
import { sortOrder } from '../../../../../../../../../consts/sortOrder';
import useNotifications from '../../../../../../../../../hooks/useNotifications';
import { IPaginationProps } from '../../../../../../../../Pagination/IPaginationProps';
import { tireMileageColumns } from './consts';
import { pageSizes } from '../../../../../../../../../consts/recordKeeping';
import { ITireMileageProps } from './ITireMileageProps';
import { ITireMileageState } from './ITireMileageState';
import apiService from '../../../../../../../../../api';
import styles from './TireMileage.module.scss';
import SeparatorGy from '../../../../../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../../../../../Pagination/Pagination';
import classNames from 'classnames';
import LoadingScreen from '../../../../../../../../LoadingScreen/LoadingScreen';

const TireMileage: FC<ITireMileageProps> = ({ 
  billPeriodId, 
  setViewReportTirePaginationProps,
  setReportTireSortOrder,
  setReportTireSortOrderCountOnPage,
}): ReactElement => {

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const { addNotification } = useNotifications();

  const [state, setState] = useState<ITireMileageState>({
    items: [],
    loading: false,
    foundCount: 0,
  });

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => {
      setPaginationProps((prev: any) => ({ ...prev, current: newPage }));
    },
  });
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(tireMileageColumns);

  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const fetchTireMileageReport = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.mileageRun.getTireMileageReport(
        billPeriodId,
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
      );
      const foundCount = data.total.found;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
      setViewReportTirePaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
      setReportTireSortOrder(sortOrder);
      setReportTireSortOrderCountOnPage(countOnPage);
    } catch (e: any) {
      addNotification({
        text: `Tire Mileage Report fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    fetchTireMileageReport();
  }, [paginationProps.current, countOnPage, columnsState, billPeriodId]);

  return (
    <div>
      <div className={styles.tableHeading}>
        <div>
          <Text variant="xLarge" className={styles.highlight}>Tire Mileage</Text>
          <SeparatorGy vertical />
          <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
        </div>
        <div>
          <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
          <Dropdown
            options={pageSizes.map(pageSize => ({
              key: pageSize,
              text: pageSize.toString(),
            }))}
            defaultSelectedKey={pageSizes[0]}
            selectedKey={countOnPage?.key}
            onChange={onChangeCountOnPage}
          />
        </div>
      </div>
      <div className={styles['table-wrapper']}>
        <table className={styles.table}>
          <thead>
            <tr>
              {
                columnsState.map(item => (
                  <th
                    key={item.name}
                    className={classNames(
                      item.isSorted && item.isSortedDescending ?
                        styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined,
                      styles[item.key],
                    )}
                    onClick={() => onColumnClick(item)}>{item.name}
                  </th>
                ))
              }
            </tr>
          </thead>
          <tbody>
            {state.items.map((item, i) => (
              <tr key={i} className={styles.trBasic} >
                <td>{item.pfx}</td>
                <td>{item.brandNo}</td>
                <td>{item.sfx}</td>
                <td>{item.veh}</td>
                <td>{item.pos}</td>
                <td>{item.totMiles}</td>
                <td>{item.tireSize}</td>
                <td>{item.typeCode}</td>
                <td>{item.prodCode}</td>
                <td>{item.tc}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <SeparatorGy />
      <Pagination {...paginationProps} />
      {state.loading && <LoadingScreen />}
    </div> 
  );
};

export default TireMileage;