import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';
import { Icon, Pivot, PivotItem, Text } from '@fluentui/react';

import SeparatorGy from '../SeparatorGy/SeparatorGy';
import { useUserPermissions } from '../../hooks/useUserPermissions';

import PermissionRoles from './tabComponents/PermissionRoles/PermissionRoles';
import Users from './tabComponents/Users/Users';
import { IUserAccessManagementProps } from './IUserAccessManagementProps';
import { userAccessManagementTabs } from './consts';

import styles from './UserAccessManagement.module.scss';

const UserAccessManagement: FC<IUserAccessManagementProps> = (): ReactElement => {
  const [selectedKey, setSelectedKey] = React.useState(userAccessManagementTabs.permissionRoles);

  const { hasPermission } = useUserPermissions();

  const accessToAdminPage = hasPermission('Admin.ViewPage');

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.headerBlock}>
        <Icon iconName='AccountManagement' className={styles.mainIcon} />
        <Text variant='xxLarge'>Access Management</Text>
      </div>
      {accessToAdminPage.isRead && (
        <>
          <SeparatorGy />
          <Pivot
            className={classNames('vertical-pivot', 'pivot-gy-colors')}
            selectedKey={selectedKey}
            onLinkClick={handleLinkClick}
            getTabId={getTabId}
            linkFormat="tabs"
          >
            <PivotItem
              itemIcon='Group'
              headerText={userAccessManagementTabs.permissionRoles}
              itemKey={userAccessManagementTabs.permissionRoles}
            >
              <div className="vertical-pivot-container"><PermissionRoles /></div>
            </PivotItem>
            <PivotItem
              itemIcon='Contact'
              headerText={userAccessManagementTabs.users}
              itemKey={userAccessManagementTabs.users}
            >
              <div className="vertical-pivot-container"><Users /></div>
            </PivotItem>
          </Pivot>
        </>
      )}
    </div>
  );
};

export default UserAccessManagement;
