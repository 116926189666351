import React, { FunctionComponent, useState } from 'react';
import { Text, Dropdown, TextField, PrimaryButton, DefaultButton, Icon } from '@fluentui/react';
import { tireSelectionData, defaultTireSelectionData } from '../../consts';
import classNames from 'classnames';

import styles from './TireSelection.module.scss';

interface TireSelectionProps {
  onTireSelectionChanged: (data: any) => void;
  handleChangeSeries: () => void;
}

const TireSelection: FunctionComponent<TireSelectionProps> = ({ onTireSelectionChanged, handleChangeSeries }) => {
  const [rebrand, setRebrand] = useState(false);
  const [tireSelection, setTireSelection] = useState<tireSelectionData>(defaultTireSelectionData());

  const onCheckBoxClicked = () => {
    setRebrand(!rebrand);
    setTireSelection((prev: any) => ({ ...prev, rebrand: !rebrand }));
  };

  const onTextChange = ({ target }: any) => {
    const { name, value } = target;
    const newState = { ...tireSelection, [name]: value.toUpperCase() };
    setTireSelection(newState);
    onTireSelectionChanged(newState);
  };

  return (
    <>
      <div className="ms-Grid">
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm5')}>
            <Text variant="xLarge" className={styles.highlight}>Original Begin</Text>
            <div className={classNames('ms-Grid-row', styles.rowMarginTop)}>
              <div className={classNames('ms-Grid-col')}>
                <div className="ms-Grid-row">
                  <div className={classNames('ms-Grid-col', 'ms-sm12', 'ms-md12', 'ms-lg4')}>
                    <TextField id='beginPrefix' name="prefix" value={tireSelection.prefix} onChange={onTextChange} label="Prefix" />
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm12', 'ms-md12', 'ms-lg4')}>
                    <TextField id="beginBrandNum" name="beginBrandNum" value={tireSelection.beginBrandNum} onChange={onTextChange} label="Brand #" />
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm12', 'ms-md12', 'ms-lg4')}>
                    <TextField name="suffix" value={tireSelection.suffix} onChange={onTextChange} label="Suffix" />
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div className={classNames('ms-Grid-col', 'ms-sm12', styles.rowMarginTop)}>
                    <Text variant="xLarge" className={styles.highlight}>Original End</Text>
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm12', 'ms-md12', 'ms-lg4')}>
                    <TextField value={tireSelection.prefix} name="prefix" onChange={onTextChange} label="Prefix" disabled />
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm12', 'ms-md12', 'ms-lg4')}>
                    <TextField value={tireSelection.endBrandNum} name="endBrandNum" onChange={onTextChange} label="Brand #" />
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm12', 'ms-md12', 'ms-lg4')}>
                    <TextField value={tireSelection.suffix} name="suffix" onChange={onTextChange} label="Suffix" disabled />
                  </div>
                </div>
              </div>                          
            </div> 
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm2', styles.divIcon)}>
            <div className={classNames(styles.box)}>
              <Icon iconName="Forward" />
            </div>

            <div className={classNames(styles.box)}>
              <Icon iconName="Forward" />
            </div>
          </div>  
          <div className={classNames('ms-Grid-col', 'ms-sm5')}>
            <Text variant="medium" className={styles.highlight}>
              <input type="checkbox" onChange={onCheckBoxClicked} checked={tireSelection.rebrand ? true : false} />
              Rebrand Tires
            </Text>
            <div className={classNames('ms-Grid-row', styles.rowMarginTop)}>
              <div className={classNames('ms-Grid-col')}>
                <div className="ms-Grid-row">
                  <div className={classNames('ms-Grid-col', 'ms-sm12', 'ms-md12', 'ms-lg4')}>
                    <TextField name="rbPrefix" value={tireSelection.rbPrefix} onChange={onTextChange} label="Prefix" disabled={!rebrand} />
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm12', 'ms-md12', 'ms-lg4')}>
                    <TextField
                      name="beginBrandNum"
                      value={rebrand ? tireSelection.beginBrandNum : ''}
                      onChange={onTextChange}
                      label="Brand #"
                      disabled
                    />
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm12', 'ms-md12', 'ms-lg4')}>
                    <TextField name="rbSuffix" value={tireSelection.rbSuffix} onChange={onTextChange} label="Suffix" disabled={!rebrand} />
                  </div>
                </div>
                <div className={classNames('ms-Grid-row', styles.marginTopRebrand)}>
                  <div className={classNames('ms-Grid-col', 'ms-sm12', 'ms-md12', 'ms-lg4')}>
                    <TextField name="rbPrefix" value={tireSelection.rbPrefix} onChange={onTextChange} label="Prefix" disabled />
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm12', 'ms-md12', 'ms-lg4')}>
                    <TextField
                      name="endBrandNum"
                      value={rebrand ? tireSelection.endBrandNum : ''}
                      onChange={onTextChange}
                      label="Brand #"
                      disabled
                    />
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm12', 'ms-md12', 'ms-lg4')}>
                    <TextField name="rbSuffix" onChange={onTextChange} value={tireSelection.rbSuffix} label="Suffix" disabled />
                  </div>
                </div>            
              </div>
            </div>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.selectSeriesContainer)}>
            <PrimaryButton 
              id="selectSeriesBtn" 
              text="Select series" 
              onClick={() => {handleChangeSeries();}}
            />
          </div>
        </div>        
      </div>
    </>
  );
};

export default TireSelection;
