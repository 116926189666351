import { fieldType } from '../../../../consts/fieldType';

export const pageSizes = [100, 250, 500];

export const emptyRebrandTire = {
  pfx: '',
  bno: '',
  sfx: '',
  newPfx: '',
  newBrand: '',
  newSfx: '',
};

export const columns = [
  {
    key: 'column1',
    name: 'Pfx',
    fieldName: 'bpfx',
    isSorted: true,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column2',
    name: 'Brand',
    fieldName: 'bno',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column3',
    name: 'Sfx',
    fieldName: 'bsfx',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column4',
    name: 'Current Type Code',
    fieldName: 'currentTypeCode',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column5',
    name: 'New Type Code',
    fieldName: 'newTypeCode',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
];