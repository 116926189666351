import { fieldType } from '../../../../../../consts/fieldType';

export const pageSizes = [100, 250, 500];

export const shortageDetailsReasons = {
  W: 'Returned to warehouse',
  S: 'Lost/Damaged',
};

export const defaultShortageDetails = {
  reason: { key: 'W', text: shortageDetailsReasons.W},
  shortDoc: '',
  shortDate: null,
  comments: '',
};

export const availableInTransitTiresColumns = [
  {
    key: 'availableColumn1',
    name: 'Brand Prefix',
    fieldName: 'intireBrndPfx',
    isSorted: true,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'availableColumn2',
    name: 'Brand Number',
    fieldName: 'intireBrndNum',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'availableColumn3',
    name: 'Brand Suffix',
    fieldName: 'intireBrndSfx',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'availableColumn4',
    name: 'Type Code',
    fieldName: 'typeCodeFK',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'availableColumn5',
    name: 'Tire Class',
    fieldName: 'tc',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'availableColumn6',
    name: 'DOT #',
    fieldName: 'dotNum',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
];

export const shortInTransitTiresColumns = [
  {
    key: 'shortColumn1',
    name: 'Brand Prefix',
    fieldName: 'intireBrndPfx',
    isSorted: true,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'shortColumn2',
    name: 'Brand Number',
    fieldName: 'intireBrndNum',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'shortColumn3',
    name: 'Brand Suffix',
    fieldName: 'intireBrndSfx',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'shortColumn4',
    name: 'Type Code',
    fieldName: 'typeCodeFK',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'shortColumn5',
    name: 'Tire Class',
    fieldName: 'tc',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'shortColumn6',
    name: 'DOT #',
    fieldName: 'dotNum',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'shortColumn7',
    name: 'Short Doc #',
    fieldName: 'shortDoc',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'shortColumn8',
    name: 'Incident Date',
    fieldName: 'shortDate',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
];