export const mockCostDetails = {
  mCbQuoteid: 1111111,
  mCbQuoteVersion: 1.0,
  quoteStatus: 'NEW',
  fquotevalid: 'Y',
  expirationdate: '2022-11-11T13: 59: 29Z',
  createdon: '2022-05-11T13: 59: 29Z',
  createdby: 'ZA70099',
  lastmodifiedon: '2022-05-13T13: 23: 57Z',
  lastmodifiedby: 'ZA11111',
  activatedon: null,
  activatedby: null,
  authorizedon: null,
  authorizedby: null,
  mceRate: null,
  eqpRate: null,
  eqpPeriod: null,
  eqpCost: null,
  scrapRate: null,
  scrapPeriod: null,
  scrapCost: null,
  serviceRate: null,
  servicePeriod: null,
  serviceCost: null,
  rmIncentiveRate: null,
  rmIncentivePeriod: null,
  rmIncentiveCost: null,
  asIncentiveRate: null,
  asIncentivePeriod: null,
  asIncentiveCost: null,
  otherRate: null,
  otherPeriod: null,
  otherCost: null,
  comments: null,
  defaultEbitPct: null,
  pctOfMinEbit: null,
  minEbitPct: null,
  pctOfMaxEbit: null,
  maxEbitPct: null,
  stndrdEffctPeriod: null,
  taxRate: null,
  pctOfB252: null,
  pctAllInCost: null,
  totVehMilesC: null,
  totApproxTireMilesC: null,
  totCostAllTiresC: null,
  adjustmentMethod: 'NONE',
  other2Rate: null,
  other2Period: null,
  other2Cost: null,
  mCbCustomeridFk: 111111,
  customerDto: null,
};

export const fields = [
  'eqp',
  'asIncentive',
  'rmIncentive',
  'other',
  'other2',
  'scrap',
];

export const mockCostParams = {
  mce: [0.003722, 0.0, 0.003349, 0.002233, 0.001489, 0.004466, 0.002978],
  rmIncentive: [
    {min: 1, max: 10, value: 0},
    {min: 11, max: 25, value: 50},
    {min: 26, max: 50, value: 125},
    {min: 51, max: null, value: 250},
  ],
  asIncentive: [
    {min: 1, max: 64999, value: 25},
    {min: 65000,max: 200000, value: 50},
    {min: 200001,max: null, value: 200},
  ],
  scrap: {cost: 0.0, period: 1.0},
};