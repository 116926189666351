import { recordTestInstance, exportTestInstance } from '../axios';

const getStartDetails = (filters: any, pagination: any, sortOrder: any, customerId: number, locationId: number) =>
  recordTestInstance.post('/mileage-run/start/details', { filters, pagination, sortOrder, customerId, locationId });

const getMileageAuditDetails = (auditYear: any, pagination: any, sortOrder: any, customerId: number, locationId: number) =>
  recordTestInstance.post('/mileage-audit-period', { auditYear, pagination, sortOrder, customerId, locationId });

const getMileageAuditDetailsByPeriodId = (pagination: any, sortOrder: any, billingPeriodId: string, customerId: number, locationId: number) =>
  recordTestInstance.post('/mileage-audit-details', { pagination, sortOrder, billingPeriodId, customerId, locationId });

const submitMileageAuditDetails = (data: Array<any>, mileageAuditPeriodsId: string, customerId: string, locationId: string) =>
  recordTestInstance.put('/mileage-audit-details/update', { data, mileageAuditPeriodsId, customerId, locationId });

const getBillingYearList = () =>
  recordTestInstance.get('/mileage-run/start/billingYearList');

const getBackoutDetails = (customerId: number) =>
  recordTestInstance.post('/mileage-run/back-out/details', { customerId });

const startBackout = (del: any, backOutMessage: string, invoiceId: number, customerId: number) =>
  recordTestInstance.post('/mileage-run/back-out/start', { delete: del, backOutMessage, invoiceId, customerId });

const getErrors = (pagination: any, sortOrder: any, customerId: number, locationId: number) =>
  recordTestInstance.post('/mileage-run/start/errors', { pagination, sortOrder, customerId, locationId });

const startMileage = (billPeriodId: string, customerId: string, startMode: string, processStage: string) =>
  recordTestInstance.post('/mileage-run/start/start', { billPeriodId, customerId, startMode, processStage });

const getMileageAuditStatus = (billingPeriodId: string) =>
  recordTestInstance.post('/mileage-audit-status', { billingPeriodId });

const getTireMileageReport = (billPeriodId: string, pagination: any, sortOrder: any) =>
  recordTestInstance.post('/mileage-run/view-report/report', { billPeriodId, pagination, sortOrder});

const getVehiclesMileageReport = (billPeriodId: string, pagination: any, sortOrder: any) =>
  recordTestInstance.post('/mileage-run/view-report/report-vehicles', { billPeriodId, pagination, sortOrder});

const getStartStatus = (customerId: string, billPeriodId = null, processStage = null, startMode = null) =>
  recordTestInstance.post('/mileage-run/start/status', { customerId, billPeriodId, processStage, startMode});

const printExcelStart = (startDetailsListInput: any, headerFields: Array<any>) =>
  exportTestInstance.post('/mileage-run/start/export-excel', { startDetailsListInput, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfStart = (startDetailsListInput: any, headerFields: Array<any>) =>
  exportTestInstance.post('/mileage-run/start/export-pdf', { startDetailsListInput, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const printExcelBackout = (backOutDetailsInput: any, headerFields: Array<any>) =>
  exportTestInstance.post('/mileage-run/back-out/export-excel', { backOutDetailsInput, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfBackout = (backOutDetailsInput: any, headerFields: Array<any>) =>
  exportTestInstance.post('/mileage-run/back-out/export-pdf', { backOutDetailsInput, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const printExcelMileageRunReport = (data: any) =>
  exportTestInstance.post('/mileage-run/view-report/export-excel', data, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfMileageRunReport = (data: any) =>
  exportTestInstance.post('/mileage-run/view-report/export-pdf', data, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  getStartDetails,
  getMileageAuditDetails,
  getMileageAuditDetailsByPeriodId,
  submitMileageAuditDetails,
  getBillingYearList,
  getErrors,
  startMileage,
  getMileageAuditStatus,
  getBackoutDetails,
  startBackout,
  getTireMileageReport,
  getVehiclesMileageReport,
  getStartStatus,
  printExcelStart,
  printPdfStart,
  printExcelBackout,
  printPdfBackout,
  printExcelMileageRunReport,
  printPdfMileageRunReport,
};
