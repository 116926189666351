import { HeadCell } from '../../../../../shared/DataGridComponent/DataGridModels';

export const BillInspCodesHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Billable',
    fieldName: 'billable',
    typeField: 'text',
    isEditable: true,
  },
  {
    key: 'column1',
    name: 'Insp Code',
    fieldName: 'inspCode',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Description',
    fieldName: 'description',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Comments',
    fieldName: 'comments',
    typeField: 'text',
    isEditable: false,
  },
];