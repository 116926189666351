import React, { useState, useMemo, useEffect } from 'react';
import AutoCompleteField from '../../../../../Common/Search/AutoCompleteField';
import {
  Separator,
  PrimaryButton,
  Modal,
  DefaultButton,
  MessageBarType,
} from '@fluentui/react';
import apiService from '../../../../../../api';
import DataGridComponent from '../../../../../../shared/DataGridComponent';
import { billingCodesHeadCell, locationsHeadCells } from './const';
import styles from './ManageBillingCodes.module.scss';
import { useCustomersFetch } from '../../../../../../hooks/useCustomersFetch';
import useNotifications from '../../../../../../hooks/useNotifications';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import { ACTION_GROUPS_ENUM } from '../../../../../../shared/DataGridComponent/ActionsComponent/ActionsGroupEnum';
import { debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { reloadSetBillingDataTabSelector, toggleReloadSetBillingDataTab } from '../../../../../../redux/billingSlice';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_billing_setBillingData } from '../../../../../../consts/programKeys';


function ManageBillingCodes() {
  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_billing_setBillingData);
  const { customersList, loadingCustomers, fetchCustomers } = useCustomersFetch(apiService.getCustomerSearch);
  const [isLocationsModalOpen, setIsLocationsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [customerId, setCustomerId] = useState('');
  const [customerLocation, setCustomerLocation] = useState('');
  const { addNotification } = useNotifications();
  const [customerLocations, setCustomerLocations] = useState<any[]>([]);
  const [selectedLocationRow, setSelectedLocationrow] = useState<any>();
  const [billingCodesList, setBillingCodesList] = useState<any[]>([]);
  const dispatch = useDispatch();
  const reloadSelector = useSelector(reloadSetBillingDataTabSelector);
  const [billBy, setBillBy] = useState('');

  const getBillingOptionsList = async (pagination: any, sortOrder: any, filter: string) => {
    setIsLoading(true);
    try {
      const response = await apiService.billingAPI.getBillingOptionsList(pagination, sortOrder, filter, customerId, customerLocation);
      setBillBy(response.data.data[0].billBy);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Billing options data fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(false);
      if (reloadSelector) {
        dispatch(toggleReloadSetBillingDataTab());
      }
    }
  };


  const getLocations = async () => {
    setIsLoading(true);
    try {
      const locations = await apiService.getLocationsByCustomerId(customerId, null, null);
      setCustomerLocations(locations.data.data);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Locations data fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const getBillingCodesList = async (pagination: any, sortOrder: any, filter: string) => {
    setIsLoading(true);
    setIsDataLoading(true);
    try {
      const response = await apiService.billingAPI.getBillingCodesList(pagination, sortOrder, filter, customerId, customerLocation);
      setBillingCodesList(response.data.data);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Billing codes data fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setIsLoading(false);
      setIsDataLoading(false);
      if (reloadSelector) {
        dispatch(toggleReloadSetBillingDataTab());
      }
    }
  };

  const removeEmpty = ({ text }: any) => text !== null && text !== '';
  const sortResults = (a: any, b: any) => {
    return a.text.localeCompare(b.text);
  };

  const customerPrefixList = useMemo(() => customersList ?
    customersList.map(({ id, prefix }) => ({
      key: id,
      text: prefix,
    })).filter(removeEmpty).sort(sortResults) : [], [customersList]);


  const customerNameList = useMemo(() => customersList ?
    customersList.map(({ id, customerName }) => ({
      key: id,
      text: customerName,
    })).filter(removeEmpty).sort(sortResults) : [], [customersList]);


  const regionCodeList = useMemo(() => customersList ?
    customersList.map(({ id, regionCode }) => ({
      key: id,
      text: regionCode,
    })).filter(removeEmpty).sort(sortResults) : [], [customersList]);

  const locationCodeList = useMemo(() => customerLocations ?
    customerLocations.map(({ id, locationCode }) => ({
      key: id,
      text: locationCode,
    })).filter(removeEmpty).sort(sortResults) : [], [customerLocations]);

  const locationNameList = useMemo(() => customerLocations ?
    customerLocations.map(({ id, locationName }) => ({
      key: id,
      text: locationName,
    })).filter(removeEmpty).sort(sortResults) : [], [customerLocations]);

  const handleOpenLocationsModal = () => {
    setIsLocationsModalOpen(true);
  };
  const handleCloseLocationsModal = () => {
    setIsLocationsModalOpen(false);
  };
  const onCustomerChange = (newCustomer: any) => {
    setCustomerId(newCustomer);
  };
  const onChangeSearchLocation = (newLocation: any) =>
    setCustomerLocation(newLocation);


  const handleCustNameChange = debounce((custName: string) => {
    fetchCustomers({ custName }, undefined, { column: 'customerName', order: 'asc' });
  }, 500);

  const handleCustomerPrefixChange = (custPrefix: string) => {
    fetchCustomers({ custPrefix });
  };

  const handleDataGridChange = debounce(async (data: any) => {
    const { sortOrder } = data;
    if (customerId !== '' || customerLocation !== '') {
      await getBillingCodesList(null, sortOrder, '');
    }
  }, 500);

  useEffect(() => {
    if (customerId !== '') {
      setCustomerLocation('');
      getLocations();
      setBillingCodesList([]);
      getBillingOptionsList(null, null, '');
    }
  }, [customerId]);

  useEffect(() => {
    if (billBy === 'Customer') {
      getBillingCodesList(null, null, '');
      setBillBy('');
    }
  }, [billBy]);

  useEffect(() => {
    if (customerLocation !== '') {
      getBillingCodesList(null, null, '');
    }
  }, [customerLocation]);

  useEffect(() => {
    if (reloadSelector) {
      getBillingCodesList(null, null, '');
    }
  }, [reloadSelector]);

  return (
    <div>
      <h1>Customer Details</h1>
      <Separator />
      <div style={{
        marginTop: '10px',
      }} className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm3">
            <AutoCompleteField
              onChangeAction={onCustomerChange}
              value={customerId}
              list={customerPrefixList}
              label="Customer Prefix"
              data-testid='customer-prefix'
              icon="FavoriteStar"
              textValue={handleCustomerPrefixChange}
            />
          </div>
          <div className="ms-Grid-col ms-sm3">
            <AutoCompleteField
              onChangeAction={onCustomerChange}
              value={customerId}
              list={customerNameList}
              label="Customer Name"
              data-testid='customer-name'
              icon="Contact"
              textValue={handleCustNameChange}
            />
          </div>
          <div className="ms-Grid-col ms-sm1">
            <Separator style={{
              marginTop: '1rem',
              height: '57px',
            }} vertical />
          </div>
          <div className="ms-Grid-col ms-sm3">
            <AutoCompleteField
              onChangeAction={() => { null; }}
              value={customerId}
              list={regionCodeList}
              label="Region"
              data-testid='customer-region'
              disabled
              textValue={''}
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm3">
            <AutoCompleteField
              onChangeAction={onChangeSearchLocation}
              value={customerLocation}
              list={locationCodeList}
              label="Loc Code"
              data-testid='loc-code'
              icon="POISolid"
              textValue={''}
            />
          </div>
          <div className="ms-Grid-col ms-sm3">
            <AutoCompleteField
              onChangeAction={onChangeSearchLocation}
              value={customerLocation}
              list={locationNameList}
              label="Location"
              data-testid='customer-location'
              icon="POISolid"
              textValue={''}
            />
          </div>
          <div className="ms-Grid-col ms-sm3">
            <PrimaryButton onClick={handleOpenLocationsModal} style={{
              marginTop: '24px',
            }}>
              Available Locations
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div style={{
        marginTop: '16px',
      }}>
        <DataGridComponent
          idTable={'billingCodes'}
          title='Billing Codes'
          headCells={billingCodesHeadCell}
          rowsTable={billingCodesList}
          totalDataFound={billingCodesList.length}
          isLoading={isDataLoading}
          actionsGroupName={ACTION_GROUPS_ENUM.EDIT_MANAGE_BILLING_CODES}
          handleChangeDataGridState={handleDataGridChange}

        />
      </div>
      <Modal
        titleAriaId={'titleId'}
        isOpen={isLocationsModalOpen}
        onDismiss={() => { null; }}
        isBlocking={false}
        dragOptions={undefined}
        containerClassName={styles.locationsModal}
      >
        <div>
          <div style={{
            marginTop: '10px',
          }} className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <div style={{
                display: 'flex',
              }} className="ms-Grid-col ms-sm3">
                <p style={{ color: '#114375' }}> Customer Name</p>
                <Separator vertical />
              </div>
              <div style={{
                display: 'flex',
                height: '45px',
                alignItems: 'center',
                color: '#114375',
              }} className="ms-Grid-col ms-sm3">
                Available Locations
              </div>
            </div>
          </div>
          <div style={{
            marginTop: '0.5rem',
          }}>
            <DataGridComponent
              idTable={'add-modal-table'}
              title='Locations'
              headCells={locationsHeadCells}
              rowsTable={customerLocations}
              totalDataFound={0}
              enableRowClick
              enableCheckBox
              isLoading={false}
              handleSelectRow={(row: any) => {
                if (row.length) {
                  setSelectedLocationrow(row[0]);
                }
              }}
            />
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: '1rem',
          }}>
            <DefaultButton style={{
              marginRight: '0.5rem',
            }} onClick={handleCloseLocationsModal}>
              Cancel
            </DefaultButton>
            <PrimaryButton disabled={!selectedLocationRow || !userPermissions.isWrite} onClick={() => {
              if (selectedLocationRow) {
                setCustomerLocation(selectedLocationRow.id);
                handleCloseLocationsModal();
              }
            }}>
              Select as billing location
            </PrimaryButton>
          </div>
        </div>
      </Modal>
      {isLoading || loadingCustomers && <LoadingScreen />}
    </div>
  );
};

export default ManageBillingCodes;
