import { useEffect, useState } from 'react';
import { IKeyAction, KeyHandlerClass } from './consts';

const useKeyPress = (
  keyHandle: IKeyAction,
  node = null,
) => {

  const keyHandlerInstance = new KeyHandlerClass(keyHandle);

  const [isMac] = useState(navigator.userAgent.includes('Mac'));

  const handleKeyPress = (({ key, altKey, ctrlKey }: any) => {
    if (!actionMustBeFired(key, altKey, ctrlKey)) return;
    const keyHandler = keyHandlerInstance.getActionByKey(key.toLowerCase());
    if (key.toLowerCase() === keyHandler?.key && keyHandler?.action) keyHandler.action();
  });

  const actionMustBeFired = (
    key: any,
    altKey: boolean,
    ctrlKey: boolean,
  ) => (isMac && ctrlKey) || (!isMac && altKey) || key === 'Enter';


  useEffect(() => {
    const targetNode = node ?? document;
    targetNode && targetNode.addEventListener('keydown', handleKeyPress);

    return () =>
      targetNode &&
      targetNode.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress, node]);
};

export default useKeyPress;