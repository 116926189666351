import { FC, FormEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { get, isNil } from 'lodash';
import { useBoolean } from '@fluentui/react-hooks';
import {
  DefaultButton,
  Dropdown,
  IColumn,
  Icon,
  IconButton,
  IDropdownOption,
  MessageBarType,
  Modal,
  Text,
  TextField,
} from '@fluentui/react';

import apiService from '../../../../api';
import useNotifications from '../../../../hooks/useNotifications';
import { sortOrder } from '../../../../consts/sortOrder';

import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import Pagination from '../../../Pagination/Pagination';
import PrintingModal from '../../../PrintingModal/PrintingModal';
import { IPaginationProps } from '../../../Pagination/IPaginationProps';
import { downloadFile, printingTypes } from '../../../PrintingModal/consts';

import { IViewingLogsModalProps } from './IViewingLogsModalProps';
import { IViewingLogsModalState } from './IViewingLogsModalState';
import { tableColumns, emptyFilters, allOption, pageSizes, severityClassNameMapping } from './consts';
import { IFiltersState } from './IFiltersState';

import styles from './ViewingLogsModal.module.scss';

const ViewingLogsModal: FC<IViewingLogsModalProps> = ({ isModalOpen, hideModal, taskInfo }) => {

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const { addNotification } = useNotifications();

  const [state, setState] = useState<IViewingLogsModalState>({
    items: [],
    loading: false,
    foundCount: 0,
    severities: [],
  });

  const [columnsState, setColumnsState] = useState<Array<any>>(tableColumns);
  const [filtersState, setFiltersState] = useState<IFiltersState>(emptyFilters);
  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);

  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const fetch = async () => {
    setState((prev: IViewingLogsModalState) => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.taskScheduler.getHistory({
        scheduleId: taskInfo.mCdSchedid,
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        searchText: filtersState.searchString,
        ...(filtersState.severities[0] === allOption.key ? {} : { severities: filtersState.severities }),
      });
      const foundCount = data.total.found;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, selectedItem: null, foundCount }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching logs error: ${get(response, 'data.message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: IViewingLogsModalState) => ({ ...prev, loading: false }));
    }
  };

  const fetchSelectOptions = async () => {
    setState((prev: IViewingLogsModalState) => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.taskScheduler.getSelections();
      setState((prev: any) => ({ ...prev, severities: data?.severities }));
    } catch (e: any) {
      addNotification({
        text: `Options for selecting fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: IViewingLogsModalState) => ({ ...prev, loading: false }));
    }
  };

  const handleSearch = () => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    fetch();
  };

  const handleChangeSearchString = (event: any, searchString = '') => {
    setFiltersState((prev: IFiltersState) => ({ ...prev, searchString }));
  };

  const onChangeFilters = (item: any, filter: string, totalCount: number): void => {
    if (item && item.key === allOption.key) {
      setFiltersState((prev: IFiltersState) => ({ ...prev, [filter]: [item.key] }));
    } else if (item) {
      const allPos = get(filtersState, filter, []).indexOf(allOption.key);
      const prevArray = get(filtersState, filter, []);
      const prevArrayWithoutAll = prevArray.filter((m: any, i: number) => i !== allPos);
      setFiltersState((prev: IFiltersState) => ({
        ...prev,
        [filter]: item.selected ?
          [...(prevArrayWithoutAll.length === totalCount - 1 ? [allOption.key] : [item.key, ...prevArrayWithoutAll])] :
          [...prevArray.filter((key: any) => key !== item.key), ...(prevArray.length === 1 ? [allOption.key] : [])],
      }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState((prev: IViewingLogsModalState) => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        scheduleId: taskInfo.mCdSchedid,
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        searchText: filtersState.searchString,
        ...(filtersState.severities[0] === allOption.key ? {} : { severities: filtersState.severities }),
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.taskScheduler.printSchedulerFunctionHistoryExcel(requestData) :
        await apiService.taskScheduler.printSchedulerFunctionHistoryPdf(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Printing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: IViewingLogsModalState) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (!isNil(taskInfo) && isModalOpen) {
      setFiltersState(emptyFilters);
      fetch();
      fetchSelectOptions();
    }
  }, [
    taskInfo,
    columnsState,
    paginationProps.current,
    isModalOpen,
  ]);

  return (
    <Modal
      isOpen={isModalOpen}
      containerClassName={styles.modalContainer}
      onDismiss={hideModal}
    >
      <div className="ms-Grid">
        <div className={styles.modalHeader}>
          <Text variant="xxLarge" id="title">Task History</Text>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel="Close Modal"
            onClick={hideModal}
            id="closeButton"
          />
        </div>
        <SeparatorGy />
        <div className={classNames('ms-Grid-row', styles.mainInfoBlock, styles.row)}>
          <div className={classNames('ms-Grid-col', 'ms-sm4')}>
            <Text variant="xLarge" id="title">{taskInfo?.jobName}</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm8')}></div>
        </div>
        <div className={classNames('ms-Grid-row', styles.row)}>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <div className="ms-Grid">
              <div className={classNames('ms-Grid-row', styles.mainRow)}>
                <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                  <div className={styles.tabFiltersBlock}>
                    <TextField
                      id="searchString"
                      value={filtersState.searchString}
                      label="Search"
                      placeholder="Enter search string"
                      onChange={handleChangeSearchString}
                    />
                    <Dropdown
                      label="Filter By Sevirity"
                      selectedKeys={filtersState.severities}
                      onChange={(ev: any, item: any) => onChangeFilters(item, 'severities', Object.values(state.severities).length)}
                      options={[allOption, ...Object.values(state.severities).map((v: any) => ({ key: v, text: v }))]}
                      multiSelect
                    />
                    <IconButton
                      iconProps={{ iconName: 'Search' }}
                      onClick={handleSearch}
                    />
                  </div>
                  <div className={styles.tableHeading}>
                    <div>
                      <Icon iconName='Clock' className={styles.tableHeaderIcon} />
                      <Text variant="large">Task History</Text>
                    </div>
                    <div>
                      <Text variant="large">Show # of rows:&nbsp;</Text>
                      <Dropdown
                        options={pageSizes.map(pageSize => ({
                          key: pageSize,
                          text: pageSize.toString(),
                        }))}
                        defaultSelectedKey={pageSizes[0]}
                        selectedKey={countOnPage?.key}
                        onChange={onChangeCountOnPage}
                      />
                      <SeparatorGy vertical />
                      <Text variant="xLarge">{state.foundCount} found</Text>
                    </div>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        {
                          columnsState.map(item => (
                            <th
                              key={item.name}
                              className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined}
                              onClick={() => onColumnClick(item)}
                            >
                              {item.name}
                            </th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        state.items.map((item: any) => {
                          const { id, message, severity, description, startDate, endDate } = item;
                          return (
                            <tr
                              key={id}
                              className={styles.trBasic}
                            >
                              <td>{message}</td>
                              <td><Text className={styles[get(severityClassNameMapping, severity)]}>{severity}</Text></td>
                              <td>{description}</td>
                              <td>{startDate && moment(startDate).format('MM/DD/YYYY h:mm:ss a')}</td>
                              <td>{endDate && moment(endDate).format('MM/DD/YYYY h:mm:ss a')}</td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>

                  {state.foundCount > countOnPage?.key && (
                    <>
                      <SeparatorGy />
                      <Pagination {...paginationProps} />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.buttonsWrapper)}>
            <DefaultButton onClick={toggleShowPrintExport} text="Print/Export" />
          </div>
        </div>
      </div>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      {state.loading && <LoadingScreen />}
    </Modal>
  );
};

export default ViewingLogsModal;
