import { IDropdownOption } from '@fluentui/react';

export const pageSizes = [25, 50, 100];

export const tableColumns = [
  {
    key: 'column0',
    name: 'Priority',
    fieldName: 'priority',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'Job Name',
    fieldName: 'runFunction',
  },
  {
    key: 'column2',
    name: 'Status',
    fieldName: 'status',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'Triggers (Inherited from parent task)',
    fieldName: 'trigger',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'Next Run Time',
    fieldName: 'nextTimeExecuted',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'Last Run Time',
    fieldName: 'lastTimeExecuted',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column6',
    name: 'Last Run Result',
    fieldName: 'lastRunResult',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column7',
    name: 'Job Enabled?',
    fieldName: 'enabled',
    isSorted: false,
    isSortedDescending: false,
  },
];

export const allOption: IDropdownOption = {
  key: 'All',
  text: 'All',
};
