import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';
import { Pivot, PivotItem } from '@fluentui/react';

import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import {
  auth_mileageRun_start,
  auth_mileageRun_viewMileageReport,
} from '../../../../consts/programKeys';

import TabHeader from '../TabHeader/TabHeader';
import StartMileage from './tabComponents/StartMileage/StartMileage';
import MileageRunReport from './tabComponents/MileageRunReport/MileageRunReport';
import { IMileageRunTabProps } from './IMileageRunTabProps';
import { mileageRunTabs } from './consts';

const MileageRunTab: FC<IMileageRunTabProps> = (): ReactElement => {
  const [selectedKey, setSelectedKey] = React.useState(mileageRunTabs.start);

  const { hasPermission } = useUserPermissions();
  const userPermissions_start = hasPermission(auth_mileageRun_start);
  const userPermissions_viewMileageReport = hasPermission(auth_mileageRun_viewMileageReport);

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  return (
    <div>
      <TabHeader
        details
      />
      <Pivot
        className={classNames('pivot-container')}
        selectedKey={selectedKey}
        onLinkClick={handleLinkClick}
        getTabId={getTabId}
        linkFormat="tabs"
      >
        {userPermissions_start.isAccess && <PivotItem headerText={mileageRunTabs.start} itemKey={mileageRunTabs.start}>
          <div className="vertical-pivot-container"><StartMileage /></div>
        </PivotItem>}
        {userPermissions_viewMileageReport.isAccess && <PivotItem headerText={mileageRunTabs.mileageRunReport} itemKey={mileageRunTabs.mileageRunReport}>
          <div className="vertical-pivot-container"><MileageRunReport /></div>
        </PivotItem>}
      </Pivot>
    </div>
  );
};

export default MileageRunTab;
