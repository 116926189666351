export const tireDispositionTabs: ITireDispositionTabs = {
  postOosTires: 'Post OOS Tires',
  postFlaggedAsDamaged: 'Post Flagged as Damaged',
  unflagConsignedTire: 'Unflag Consigned Tires',
  transferTires: 'Transfer Tires',
};

export interface ITireDispositionTabs {
    postFlaggedAsDamaged: string;
    postOosTires: string;
    unflagConsignedTire: string;
    transferTires: string;
}
