export const renewalInitiators = [
  'GOODYEAR ONLY',
  'OPERATOR ONLY',
  'EITHER PARTY',
];

export const paymentTerms = [
  'Net 10th Prox',
  'Net 30 days',
  'Net 45 days',
  'Net 60 days',
  'Other',
];

export const processMethods = [
  {text:'Detail',key:'N'},
  {text:'Limited',key:'1'},
];

export const goToSelection = [
  'NORMAL',
  'OPTION',
  'NORMAL RUNOUT',
  'RUNOUT/PAYMENT PLAN',
  'PENDING CLOSEOUT',
];

export const rkTypes = [
  {text:'Detail',key:'1'},
  {text:'Flexible',key:'2'},
  {text:'Limited OOS',key:'3'},
  {text:'Tire Counts',key:'4'},
];