import { FC, FormEvent, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { customerSelector, locationSelector } from '../../../../../../../../redux/recordKeepingSlice';
import { INotifyProps } from './INotifyProps';
import apiService from '../../../../../../../../api';
import { INotifyState } from './INotifyState';
import { Text, Label, Dropdown, Separator, PrimaryButton, DefaultButton, IDropdownStyles, IDropdownOption, MessageBarType } from '@fluentui/react';
import classNames from 'classnames';
import styles from './Notify.module.scss';
import useNotifications from '../../../../../../../../hooks/useNotifications';
import { listBox } from './consts';
import { downloadFile } from '../../../../../../../PrintingModal/consts';


const Notify: FC<INotifyProps> = ({ isOpened, onClose, period }): ReactElement => {
  const [state, setState] = useState<INotifyState>({
    typeOfLetter: null,
    loading: false,
  });

  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);

  const { addNotification } = useNotifications();

  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 250 } };

  const notify = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.spareStock.notify(
        customerId,
        locationId,
        period,
        state.typeOfLetter,
      );
      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, 'docx');
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Reconciliation Summary fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const onChangeTypeOfLetter = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any>): void => {
    setState((prev: any) => ({ ...prev, typeOfLetter: item.key }));
  };

  return (
    <div className={classNames(styles.root, { [styles.closed]: !isOpened })}>
      <div className={classNames('ms-Grid', styles.window)}>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col">
            <Text variant="xLarge" className={styles.highlight}>Notify</Text>
          </div>
        </div>
        <div className={classNames(styles.mainBlock, 'ms-Grid-row')}>
          <div className={classNames('ms-Grid-col', styles.centeredContentColumn)}>
            <Label htmlFor="printMethod">Select BUS:</Label>
            <Dropdown
              id="options"
              onChange={(e, item: any) => onChangeTypeOfLetter(e, item)}
              placeholder="Select an option"
              options={listBox.map(el => ({
                key: el.value,
                text: el.name,
              }))}
              selectedKey={state.typeOfLetter}
              styles={dropdownStyles}
            />
          </div>

          <div className="ms-Grid-col">
            <Separator className={styles.separator} vertical />
          </div>

          <div className={classNames('ms-Grid-col', styles.centeredContentColumn)}>
            <PrimaryButton
              id='notifyModalBtn'
              text="Notify"
              iconProps={{ iconName: 'Installation' }}
              onClick={notify}
              className={styles.button}
              disabled={state.typeOfLetter === null}
            />
            <DefaultButton
              text="Cancel"
              onClick={onClose}
              className={styles.button}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notify;