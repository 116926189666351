import { IChoiceGroupOption } from '@fluentui/react';

export const postTypeOptions: IChoiceGroupOption[] = [
  { key: 'byVehicle', text: 'By Vehicle' },
  { key: 'fromFile', text: 'From File' },
];

export const locationColumns = [
  {
    key: 'column0',
    name: 'Ship To Number',
    fieldName: 'shipToNumber',
  },
  {
    key: 'column1',
    name: 'Location Code',
    fieldName: 'locationCode',
  },
  {
    key: 'column2',
    name: 'Location Name',
    fieldName: 'locationName',
  },
  {
    key: 'column3',
    name: 'Address Line 1',
    fieldName: 'addrLine1',
  },
  {
    key: 'column4',
    name: 'Address Line 2',
    fieldName: 'addrLine2',
  },
  {
    key: 'column5',
    name: 'City',
    fieldName: 'city',
  },
];

export const columns = [
  {
    key: 'column0',
    name: 'vNo',
    fieldName: 'vNo',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'Idle Since',
    fieldName: 'idleSince',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Info Recorded',
    fieldName: 'infoRecorded',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'Equipped?',
    fieldName: 'equipped',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'Idle Reason',
    fieldName: 'idleReason',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'Comment',
    fieldName: 'comment',
    isSorted: false,
    isSortedDescending: false,
  },
];

export const LOCATION = 'Location';
