import { DefaultButton, Dropdown, IDropdownOption, MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { debounce, isNil } from 'lodash';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import apiService from '../../../../../api';
import { auth_customer_fleetData } from '../../../../../consts/programKeys';
import useNotifications from '../../../../../hooks/useNotifications';
import { useUserPermissions } from '../../../../../hooks/useUserPermissions';
import { INotification } from '../../../../../models/INotification';
import { customerSelector } from '../../../../../redux/customerSlice';
import DataGridComponent from '../../../../../shared/DataGridComponent';
import { initalDataGridState } from '../../../../../shared/DataGridComponent/utils';
import DialogComponent from '../../../../../shared/DialogComponent';
import { FormBuilderGroup } from '../../../../../shared/FormBuilderComponent';
import MakeModelTable from '../../../../../shared/MakeModelComponent/MakeModelTable';
import AutoCompleteField from '../../../../Common/Search/AutoCompleteField';
import { ICustomer } from '../../../../RecordKeeping/ICustomer';
import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import ProductModal from './components/ProductModal';
import { FILTERS_FIELDS, fleetsTableHeadCells, FLEET_FIELDS, IFleetData, initialFleet, INVOICE_STATUSES, IProductData, IQuoteId, IVehicle } from './consts';
import styles from './Fleet.module.scss';



interface FleetProps {
  value?: string;
}

const Fleet: FunctionComponent<FleetProps> = () => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_customer_fleetData);

  const customer = useSelector(customerSelector);
  const [hasFleets, setHasFleets] = useState(false);
  const [hasSelectedFleets, setHasSelectedFleets] = useState(false);
  const [selectedFleet, setSelectedFleet] = useState<IFleetData[]>([]);
  const [fleets, setFleets] = useState<Array<IFleetData>>([]);
  const [custName, setCustName] = useState<string>();
  const [custId, setCustId] = useState<string>();
  const [dataGridState, setDataGridState] = useState<any>(initalDataGridState);
  const [totalFleets, setTotalFleets] = useState<number>(0);
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });

  const [isLoadingFetchCustomers, { toggle: toggleIsLoadingFetchCustomers }] = useBoolean(false);

  const [vehicles, setVehicles] = useState<IVehicle[]>([]);
  const [selectedVehicleMake, setSelectedVehicleMake] = useState<any>(null);
  const [selectedVehicleModel, setSelectedVehicleModel] = useState<string>('');
  const [vehicleMakes, setVehicleMakes] = useState<any[]>([]);
  const [models, setModels] = useState<string[]>([]);

  //Filters
  const [filterStatus, setFilterStatus] = useState<string>();
  const [contractIds, setContractIds] = useState<string[]>();
  const [quoteIds, setQuoteIds] = useState<IQuoteId[]>();
  const [versions, setVersions] = useState<string[]>();
  const [isShowAllSelected, setIsShowAllSelected] = useState(true);
  const [isFirskMakesLoading, setIsFirskMakesLoading] = useState<boolean>(true);

  //Toggles
  const [isLoading, { toggle: toggleLoading }] = useBoolean(false);
  const [loadingMakes, { toggle: toggleMakeLoading }] = useBoolean(false);
  const [loadingModels, { toggle: toggleModelsLoading }] = useBoolean(false);
  const [showProductModal, { toggle: toggleShowProductModal }] = useBoolean(false);
  const [showClearModal, { toggle: toggleShowClearModal }] = useBoolean(false);

  //Notifications
  const { addNotification } = useNotifications();

  //Form Validators
  const fleetsFormFieldsGroup = new FormBuilderGroup(FLEET_FIELDS);
  const filterFormFieldsGroup = new FormBuilderGroup(FILTERS_FIELDS);

  const [customersList, setCustomerList] = useState<ICustomer[]>([]);

  const fetchFleets = async (
    pagination: any = { pageSize: 100, pageNumber: 1 },
    filterText?: any,
    sortOrder?: any,
  ) => {
    const payload = {
      customerId: customer.m_CB_CUSTOMERID,
      showAll: isShowAllSelected,
      contractId: filterStatus == 'Contract Id' ?
        (isNil(filterFormFieldsGroup.getFieldFormValue('contractIdField')) ? '' :
          (filterFormFieldsGroup.getFieldFormValue('contractIdField') === 'Any' ? ''
            : filterFormFieldsGroup.getFieldFormValue('contractIdField')))
        : null,
      baseline: filterStatus == 'Baseline',
      quoteId: filterStatus == 'Quote Id' ?
        (isNil(filterFormFieldsGroup.getFieldFormValue('quoteIdField')) ? '' :
          (filterFormFieldsGroup.getFieldFormValue('quoteIdField') === 'Any' ? '' :
            filterFormFieldsGroup.getFieldFormValue('quoteIdField')))
        : null,
      quoteVersion: filterStatus == 'Quote Id' ?
        isNil(filterFormFieldsGroup.getFieldFormValue('versionField')) ? '' :
          (filterFormFieldsGroup.getFieldFormValue('versionField') === 'Any' ? '' :
            filterFormFieldsGroup.getFieldFormValue('versionField'))
        : null,
      pagination,
      sortOrder,
      filterText,
    };
    toggleLoading();
    try {
      const { data } = await apiService.customerAPI.getFleets(payload);
      const userFleets: IFleetData[] = [];
      setTotalFleets(data.total.found);
      data.data.forEach((fleet: IFleetData) => {
        const newFleet: IFleetData = {
          fleetId: fleet.mCbFleetid,
          vehicleMake: fleet.vehicleMake,
          vehicleModel: fleet.vehicleModel,
          numberOfWheels: fleet.numberOfWheels,
          numberOfVehicleUnits: fleet.numberOfVehicleUnits,
          monthlyVehicleUnits: fleet.monthlyVehicleUnits,
          quoteId: fleet.quoteId,
          contractId: fleet.contractId,
          products: fleet.products,
        };
        userFleets.push(newFleet);
      });
      setHasFleets(true);
      setFleets(userFleets);
      toggleLoading();
    } catch (e: any) {
      setNotification({ text: 'Error getting fleets', type: MessageBarType.error });
    }
  };

  const getFleetFilters = async () => {
    toggleLoading();
    const payload = {
      customerId: customer.m_CB_CUSTOMERID,
    };
    try {
      const { data } = await apiService.customerAPI.getFleetFilters(payload);
      const anyContractOption = ['Any'];
      const newContractIds = [...anyContractOption, ...data.availableContractIds];
      const anyQuoteOption = [{ quoteId: 'Any', versions: ['Any'] }];
      const newQuoteIds = [...anyQuoteOption, ...data.availableQuotes];
      setQuoteIds(newQuoteIds);
      setContractIds(newContractIds);
    } catch (e: any) {
      setNotification({ text: 'Error getting filters', type: MessageBarType.error });
    }
    finally {
      toggleLoading();
    };
  };

  const getModels = async (filter?: string, shouldReturnValue?: boolean) => {
    toggleMakeLoading();
    if (isFirskMakesLoading) setIsFirskMakesLoading(false);
    try {
      const payload = {
        filters: {
          make: filter,
        },
      };
      const { data } = await apiService.customerAPI.listMakeModels(payload);
      if (shouldReturnValue) return data.data;
      else setVehicleMakes(data.data);
    } catch (e: any) {
      setNotification({ text: 'Error getting models', type: MessageBarType.error });
    }
    finally {
      toggleMakeLoading();
    }
  };

  const fetchCustomers = async (filter?: any, pagination?: any, sort?: any) => {
    try {
      toggleIsLoadingFetchCustomers();
      const { data }: any = await apiService.getCustomerList(filter, pagination, sort);
      setCustomerList(data.data);
      toggleIsLoadingFetchCustomers();
    } catch (e: any) {
      setNotification({ text: 'Customers fetching error.', type: MessageBarType.error });
    }
  };

  const addFleet = async () => {
    toggleLoading();
    const data = {
      id: null,
      vehicleMake: selectedVehicleMake?.make,
      vehicleModel: selectedVehicleModel,
      numberOfWheels: +fleetsFormFieldsGroup.getFieldFormValue('numberOfWheelsField'),
      numberOfVehicleUnits: +fleetsFormFieldsGroup.getFieldFormValue('numberOfVehicleUnitsField'),
      monthlyVehicleUnits: +fleetsFormFieldsGroup.getFieldFormValue('monthlyVehicleUnitsField'),
      quoteId: fleetsFormFieldsGroup.getFieldFormValue('quoteIdField'),
      contractId: fleetsFormFieldsGroup.getFieldFormValue('contractIdField'),
      customerId: customer.m_CB_CUSTOMERID,
      authProducts: [],
    };
    try {
      await apiService.customerAPI.addFleets(data);
      await fetchFleets();
    } catch (e: any) {
      setNotification({ text: 'Error adding fleet', type: MessageBarType.error });
    }
    toggleLoading();
  };

  const updateFleet = async () => {
    toggleLoading();
    const data = {
      id: selectedFleet[0]?.fleetId,
      vehicleMake: selectedVehicleMake?.make,
      vehicleModel: selectedVehicleModel,
      numberOfWheels: +fleetsFormFieldsGroup.getFieldFormValue('numberOfWheelsField'),
      numberOfVehicleUnits: +fleetsFormFieldsGroup.getFieldFormValue('numberOfVehicleUnitsField'),
      monthlyVehicleUnits: +fleetsFormFieldsGroup.getFieldFormValue('monthlyVehicleUnitsField'),
      quoteId: fleetsFormFieldsGroup.getFieldFormValue('quoteIdField'),
      contractId: fleetsFormFieldsGroup.getFieldFormValue('contractIdField'),
      customerId: customer.m_CB_CUSTOMERID,
      authProducts: selectedFleet[0].products,
    };
    try {
      await apiService.customerAPI.updateFleets(data);
      setNotification({ text: 'Fleet updated successfully.', type: MessageBarType.success });
      await fetchFleets();
    } catch (e: any) {
      setNotification({ text: 'Error updating fleet', type: MessageBarType.error });
    }
    finally {
      toggleLoading();
    }
  };

  const handleUpdateProducts = async (products: IProductData[]) => {
    selectedFleet[0].products = products;
    updateFleet();
  };

  const deleteFleet = async () => {
    toggleLoading();
    const ids: any[] = [];
    selectedFleet.forEach(fleet => {
      ids.push(fleet.fleetId);
    });
    try {
      await apiService.customerAPI.deleteFleets(ids);
      await fetchFleets();
      addNotification({
        text: 'Item(s) is successfully deleted.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      setNotification({ text: 'Error deleting fleet', type: MessageBarType.error });
    }
    finally {
      toggleLoading();
    }
  };

  const handleSelectRow = async (rowsSelected: any) => {
    const hasRowsSelected = rowsSelected.length > 0;
    setHasSelectedFleets(hasRowsSelected);
    setSelectedFleet(rowsSelected);
    if (rowsSelected.length) {
      const vehicleMake = await getModels(rowsSelected[0]?.vehicleMake, true);
      setSelectedVehicleMake(vehicleMake[0]);
      if (vehicleMake.length) {
        setVehicleMakes(vehicleMake);
        setSelectedVehicleModel(rowsSelected[0]?.vehicleModel);
        fleetsFormFieldsGroup.setFormValue('numberOfWheelsField', rowsSelected[0]?.numberOfWheels);
        fleetsFormFieldsGroup.setFormValue('numberOfVehicleUnitsField', rowsSelected[0]?.numberOfVehicleUnits);
        fleetsFormFieldsGroup.setFormValue('monthlyVehicleUnitsField', rowsSelected[0]?.monthlyVehicleUnits);
        fleetsFormFieldsGroup.setFormValue('quoteIdField', rowsSelected[0]?.quoteId);
        fleetsFormFieldsGroup.setFormValue('contractIdField', rowsSelected[0]?.contractId);
      }
    }
    else {
      fleetsFormFieldsGroup.cleanFormData();
      setSelectedVehicleMake(null);
      setSelectedVehicleModel('');
    }
  };

  const handleSelectProducts = (rowSelected: any) => {
    toggleShowProductModal();
    toggleLoading();
    const selectedFleetSnapshot = { ...selectedFleet };
    selectedFleetSnapshot[0].products = rowSelected;
    handleUpdateFleets([selectedFleetSnapshot[0]]);
    toggleLoading();
  };

  const deleteAll = async () => {
    toggleLoading();
    try {
      await apiService.customerAPI.clearFleets({
        customerId: customer.m_CB_CUSTOMERID,
      });
      setNotification({ text: 'Cleared fleets', type: MessageBarType.success });
    }
    catch (e: any) {
      setNotification({ text: 'Error clearing fleets', type: MessageBarType.error });
    }
    finally {
      toggleLoading();
    }
    toggleShowClearModal();
    await fetchFleets();
  };

  const handleUpdateFleets = debounce((updated: IFleetData[] = []) => {
    const newFleets = fleets.map((fleet) => {
      const element = updated.find((toFind) => {
        return toFind.fleetId === fleet.fleetId;
      });
      return (element ? { ...element } : { ...fleet });
    });
    setFleets(newFleets);
  }, 1500);

  const handleChangeDataGridState = async (dataGridState: any) => {
    setDataGridState(dataGridState);

    toggleLoading();
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    await fetchFleets(pagination, searchedText, sortOrder);
    toggleLoading();
  };

  const onCloneFleet = async () => {
    toggleLoading();
    try {
      const fleetIds: any[] = [];
      selectedFleet.forEach(fleet => {
        fleetIds.push(fleet.fleetId);
      });
      const payload = {
        ids: fleetIds,
      };
      await apiService.customerAPI.cloneFleets(payload);
      fetchFleets();
    } catch (e: any) {
      setNotification({ text: 'Error cloning fleets', type: MessageBarType.error });
    }
    finally {
      toggleLoading();
    }
  };

  const onExport = async () => {
    toggleLoading();
    try {
      const data = {
        fromCustomerId: customer.m_CB_CUSTOMERID,
        toCustomerId: custId,
      };
      await apiService.customerAPI.exportFleets(data);
      setNotification({ text: 'Exported fleets', type: MessageBarType.success });
    }
    catch (e: any) {
      setNotification({ text: 'Error exporting fleets', type: MessageBarType.error });
    }
    finally {
      toggleLoading();
    }
  };

  const removeEmpty = ({ text }: any) => text !== null;

  const customerNameList = useMemo(() => customersList ?
    customersList.map(({ id, customerName }) => ({
      key: id,
      text: customerName,
    })).filter(removeEmpty) : [], [customersList]);

  const makeNameList = vehicleMakes?.map(({ make }: any) => ({
    key: make,
    text: make,
  })).filter(removeEmpty);

  const modelNameList = models?.map((model: any) => ({
    key: model?.model,
    text: model?.model,
  })).filter(removeEmpty);

  const customerNameInputText = (custName: string) => {
    getCustomers({ custName });
    if (onNameChange) {
      onNameChange(custName);
    }
  };

  const vehicleMakeInputText = (vehicleMake: string) => {
    if (!isFirskMakesLoading)
      delayedFetchModels(vehicleMake);
  };

  const onNameChange = (name: string) => {
    const chosenCustomer = customerNameList.find(customer => customer.text === name);
    setCustId(chosenCustomer?.key?.toString());
  };

  const onCustomerChange = (newCustomer: any) => {
    setCustName(newCustomer);
  };

  const onVehicleMakeChange = async (make: any) => {
    setSelectedVehicleMake(getVehicleMakeByName(make));
  };

  const onVehicleModelChange = async (model: any) => {
    setSelectedVehicleModel(model);
  };

  const getVehicleMakeByName = (textToFind: string) => vehicleMakes?.find(({ make }: any) => make === textToFind);

  const getCustomers = debounce(async (customerData) => {
    fetchCustomers(customerData);
  }, 1000);

  const delayedFetchModels = useCallback(debounce((makeName) => getModels(makeName), 1000), []);

  const onFilterChange = async ({ target: { id } }: any, item: IDropdownOption<any> | undefined) => {
    filterFormFieldsGroup.setFormValue(`${id}Field`, item?.text);
    if (id == 'status') {
      if (item?.text !== 'Quote Id') {
        filterFormFieldsGroup.setFormValue('quoteIdField', '');
        filterFormFieldsGroup.setFormValue('versionField', '');
      }
      if (item?.text !== 'Contract Id') {
        filterFormFieldsGroup.setFormValue('contractIdField', '');
      }
      if (item?.text !== 'Show All') {
        setIsShowAllSelected(false);
      }
      await setFilterStatus(item?.text);
    }
    if (id == 'quoteId') {
      quoteIds?.forEach(async quote => {
        if (quote.quoteId == item?.text) {
          if (quote.quoteId !== 'Any') {
            const anyVersionOption = ['Any'];
            const newVersionOption = [...anyVersionOption, ...quote.versions.map(String)];
            setVersions(newVersionOption);
          }
          else {
            setVersions(quote.versions.map(String));
          }
        }
      });
    };
  };

  const reloadFleets = async () => {
    toggleLoading();
    await fetchFleets();
    toggleLoading();
  };

  const loadInitialData = async () => {
    await getFleetFilters();
  };

  useEffect(() => {
    loadInitialData();
  }, [customer]);

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  useEffect(() => {
    if (
      filterFormFieldsGroup.getFieldFormValue('contractIdField') != '' ||
      filterFormFieldsGroup.getFieldFormValue('quoteIdField') != '' ||
      filterFormFieldsGroup.getFieldFormValue('versionField')
    ) reloadFleets();
  }, [
    filterStatus,
    filterFormFieldsGroup.getFieldFormValue('contractIdField'),
    filterFormFieldsGroup.getFieldFormValue('quoteIdField'),
    filterFormFieldsGroup.getFieldFormValue('versionField'),
  ]);

  useEffect(() => {
    if (!customersList.length) fetchCustomers();
  }, []);

  useEffect(() => {
    setModels(selectedVehicleMake?.models || []);
    if (!selectedVehicleMake) {
      getModels();
    }
  }, [selectedVehicleMake]);

  const hasOneFleetSelected = selectedFleet.length === 1;
  const disableUpdBtn = selectedFleet.length !== 1 || !userPermissions.isWrite || !isNil(selectedFleet[0]?.quoteId) || !isNil(selectedFleet[0]?.contractId);
  const disableAddBtn = hasOneFleetSelected || !userPermissions.isWrite;
  const disableEditProducts = !(hasSelectedFleets && hasOneFleetSelected) || !userPermissions.isWrite;

  return (
    <>
      <div className={styles.wrapper}>
        <div className='ms-Grid' dir='ltr'>
          <div className='ms-Grid-row'>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-lg12 ms-sm12">
                <div className={styles.fieldDropdownBox}>
                  <div className={styles.divVehicleMakes}>
                    <AutoCompleteField
                      data-testid='vehicle-makes'
                      initialValue={selectedVehicleMake?.make}
                      onChangeAction={onVehicleMakeChange}
                      onBlur={(ev) => selectedVehicleMake?.make && !ev.target.value && setSelectedVehicleMake(null)}
                      list={makeNameList}
                      label="Vehicle Makes"
                      icon="car"
                      isLoading={loadingMakes}
                      textValue={vehicleMakeInputText}
                    />
                  </div>
                  <div className={styles.divVehicleModels}>
                    <AutoCompleteField
                      data-testid='vehicle-models'
                      initialValue={selectedVehicleModel}
                      onChangeAction={onVehicleModelChange}
                      onBlur={(ev) => selectedVehicleModel && !ev.target.value && setSelectedVehicleModel('')}
                      list={modelNameList}
                      label="Vehicle Models"
                      icon="car"
                      isLoading={loadingModels}
                    />
                  </div>
                  <div className={styles.divTextFields}>
                    <TextField {...fleetsFormFieldsGroup.getFieldForm('numberOfWheelsField')} />
                  </div>
                  <div className={styles.divTextFields}>
                    <TextField {...fleetsFormFieldsGroup.getFieldForm('numberOfVehicleUnitsField')} />
                  </div>
                  <div className={styles.divTextFields}>
                    <TextField {...fleetsFormFieldsGroup.getFieldForm('monthlyVehicleUnitsField')} />
                  </div>
                  <div className={styles.divButton}>
                    <PrimaryButton text='Add new fleet' disabled={disableAddBtn} onClick={addFleet} />
                  </div>
                  <div className={styles.divButton}>
                    <PrimaryButton text='Update fleet' disabled={disableUpdBtn} onClick={updateFleet} />
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <div className={styles.filterDropdownBox}>
                  <Dropdown
                    {...filterFormFieldsGroup.getFieldForm('statusField')}
                    options={INVOICE_STATUSES.map(status => ({
                      key: status,
                      text: status,
                    }))}
                    onChange={onFilterChange}
                    selectedKey={filterFormFieldsGroup.getFieldFormValue('statusField')}
                    placeHolder='Select a status...'
                  />
                  {filterStatus == 'Contract Id' &&
                    <div className={styles.filterInnerDropdownBox}>
                      <Dropdown
                        {...filterFormFieldsGroup.getFieldForm('contractIdField')}
                        options={contractIds ? contractIds.map(status => ({
                          key: status,
                          text: status,
                        })) : []}
                        onChange={onFilterChange}
                        selectedKey={filterFormFieldsGroup.getFieldFormValue('contractIdField')}
                        placeHolder='Any'
                        disabled={filterStatus != 'Contract Id'}
                      />
                    </div>
                  }
                  {filterStatus == 'Quote Id' &&
                    <>
                      <div className={styles.filterInnerDropdownBox}>
                        <Dropdown
                          {...filterFormFieldsGroup.getFieldForm('quoteIdField')}
                          options={quoteIds ? quoteIds.map(status => ({
                            key: status.quoteId,
                            text: status.quoteId,
                          })) : []}
                          onChange={onFilterChange}
                          selectedKey={filterFormFieldsGroup.getFieldFormValue('quoteIdField')}
                          placeHolder='Any'
                          disabled={filterStatus != 'Quote Id'}
                          className={styles.dropdownBox}
                        />
                      </div>
                      <div className={styles.filterInnerDropdownBox}>

                        <Dropdown
                          {...filterFormFieldsGroup.getFieldForm('versionField')}
                          options={versions ? versions.map(status => ({
                            key: status,
                            text: status,
                          })) : []}
                          onChange={onFilterChange}
                          selectedKey={filterFormFieldsGroup.getFieldFormValue('versionField')}
                          placeHolder='Any'
                          disabled={filterStatus != 'Quote Id'}
                          className={styles.dropdownBox}
                        />
                      </div>

                    </>
                  }
                </div>
              </div>
            </div>
            <div className='ms-Grid-col ms-sm12'>
              <div id='dataGridContainer'>


                <MakeModelTable
                  makeModelList={fleets}
                  parentComponentName={'fleet'}
                  permissionKey={userPermissions}
                  isLoading={isLoading}
                  makeModelsSelected={selectedFleet}
                  handleSelectRow={handleSelectRow}
                  handleDelete={deleteFleet}
                  totalDataFound={totalFleets}
                  fetchMakeModels={fetchFleets}
                />
                {/* <DataGridComponent
                  idTable={'fleets-table'}
                  title='Fleets'
                  totalDataFound={totalFleets}
                  headCells={fleetsTableHeadCells}
                  rowsTable={fleets}
                  enableCheckBox={userPermissions.isWrite}
                  enableMultiSelectRow={userPermissions.isWrite}
                  enableDeleteOption={userPermissions.isWrite}
                  enableSearching
                  enablePagination
                  enableRowsPerPage
                  enableEditableRows
                  enableMultiSorting
                  handleDelete={deleteFleet}
                  handleChangeDataGridState={handleChangeDataGridState}
                  handleSelectRow={handleSelectRow}
                  isLoading={isLoading} */}
                {/* /> */}
              </div>
            </div>
          </div>
          <div className={classNames('ms-Grid-row', styles.marginRow)}>
            <div className="ms-Grid-col ms-sm12">
              <PrimaryButton onClick={onCloneFleet} disabled={!(hasFleets && hasSelectedFleets) || !userPermissions.isWrite} className={styles.btnContainer} text='Clone Fleet' />
              <DefaultButton className={styles.btnContainer} onClick={toggleShowClearModal} disabled={!userPermissions.isWrite} text='Clear all' />
            </div>
          </div>
          <div className={classNames('ms-Grid-row', styles.marginRow)}>
            <div className="ms-Grid-col ms-sm6">
              <Text variant='xLarge' className={styles.highlight}>Export Customer Fleet</Text>
              <SeparatorGy />
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm8">
                  <div className={styles.divCustomerName}>
                    <AutoCompleteField
                      onChangeAction={onCustomerChange}
                      list={customerNameList}
                      label="Customer Name"
                      data-testid='customer-name'
                      icon="Contact"
                      textValue={customerNameInputText}
                    />
                  </div>
                </div>
                <div className="ms-Grid-col ms-sm4">
                  <PrimaryButton text='Export' onClick={onExport} className={styles.exportBtn} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductModal
        fleetId={selectedFleet[0]?.fleetId}
        products={selectedFleet[0]?.products}
        id='productModal'
        title={selectedFleet[0]?.vehicleMake}
        isOpen={showProductModal}
        onSubmit={handleSelectProducts}
        onUpdateProduct={handleUpdateProducts}
        onDismiss={toggleShowProductModal}
        successLabel='Save Products'
        cancelLabel='Cancel'
      />
      <DialogComponent
        isOpen={showClearModal}
        title='Clear fleet'
        onCancel={toggleShowClearModal}
        onSubmit={deleteAll}
        subText='Do you really want to clear fleet table? Fleet records tied to quote/contract will not be removed'
        onSubmitLabel='Clear all'
        onCancelLabel='Cancel'
      />
    </>
  );
};

export default Fleet;