import { createSlice } from '@reduxjs/toolkit';

import { ICustomer } from '../components/RecordKeeping/ICustomer';
import { ILocation } from '../components/RecordKeeping/ILocation';
import { ICustomerInformation } from '../components/RecordKeeping/ICustomerInformation';
import { ITireReinstate } from '../components/RecordKeeping/ITireReinstate';
import { ISearchState } from '../components/RecordKeeping/tabComponents/SpareStockPostingHistoryTab/ISearchState';

const initialState: {
  customer: ICustomer | null,
  location: ILocation | null,
  availableLocations: ILocation[],
  customerInformation: ICustomerInformation | null,
  tireForReinstate: ITireReinstate,
  activeTab: string,
  activeSubTab: string,
  isRkUsage: boolean,
  SpareStockPostingHistoryItems: ISearchState | null,
} = {
  customer: null,
  location: { id: null },
  customerInformation: {
    periodType: '',
    billTo: '',
    billBy: '',
    lastMileageRun: '',
    lastMileageBillingDone: '',
    billingDone: '',
    lastAnnualDone: '',
    notificationFrequency: null,
    notificationLetterSent: null,
    nextAnnualDue:  null,
    autoXferToLocationFound: false,
    autoRelocateSubdToSubdTransfer: false,
    postAgainstVehicles: false,
    postOdometerReading: false,
    warnIfMonthlyUnitsAreMoreThan: false,
    units: 0,
  },
  tireForReinstate: {
    tireId: '',
    pfx: '',
    bno: '',
    sfx: '',
  },
  activeTab: 'Record Keeping',
  activeSubTab: '',
  availableLocations: [],
  isRkUsage: false,
  SpareStockPostingHistoryItems: {
    loading: false,
    prefix: '',
    bNo: '',
    suffix: '',
    items: null,
    foundCount:0,
  },
};

const recordKeepingSlice = createSlice({
  name: 'recordKeeping',
  initialState,
  reducers: {
    setCustomer: (state, action) => {
      state.customer = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setAvailableLocations: (state, action) => {
      state.availableLocations = action.payload;
    },
    setCustomerInformation: (state, action) => {
      state.customerInformation = action.payload;
    },
    setTireForReinstate: (state, action) => {
      state.tireForReinstate = action.payload;
    },
    clearRecordKeeping: (state) => {
      state.customer = null;
      state.location = { id: null, locationCode: '', locationName: '', shipToNumber: '', customerId: '', addrLine1: '', addrLine2: '', city: '' };
      state.customerInformation = null;
      state.tireForReinstate = {
        tireId: '',
        pfx: '',
        bno: '',
        sfx: '',
      };
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setActiveSubTab: (state, action) => {
      state.activeSubTab = action.payload;
    },
    setRkUsage: (state, action) => {
      state.isRkUsage = action.payload;
    },
    setSpareStockPostingHistoryItems: (state, action) => {
      state.SpareStockPostingHistoryItems = action.payload;
    },
  },
});

export const {
  setCustomer,
  setLocation,
  setAvailableLocations,
  setCustomerInformation,
  setTireForReinstate,
  clearRecordKeeping,
  setActiveTab,
  setActiveSubTab,
  setRkUsage,
  setSpareStockPostingHistoryItems,
} = recordKeepingSlice.actions;

export const customerSelector = (state: any) => state.recordKeeping.customer;
export const locationSelector = (state: any) => state.recordKeeping.location;
export const availableLocationsSelector = (state: any) => state.recordKeeping.availableLocations;
export const customerInformationSelector = (state: any) => state.recordKeeping.customerInformation;
export const tireForReinstateSelector = (state: any) => state.recordKeeping.tireForReinstate;
export const activeTabSelector = (state: any) => state.recordKeeping.activeTab;
export const activeSubTabSelector = (state: any) => state.recordKeeping.activeSubTab;
export const isRkUsageSelector = (state: any) => state.recordKeeping.isRkUsage;
export const spareStockPostingHistoryItemsSelector = (state: any) => state.recordKeeping.SpareStockPostingHistoryItems;

export default recordKeepingSlice.reducer;
