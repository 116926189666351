import { Icon, MessageBarType, Text } from '@fluentui/react';
import classNames from 'classnames';
import { FC, ReactElement, useState } from 'react';
import apiService from '../../api';
import useNotifications from '../../hooks/useNotifications';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import DataGridComponent from '../../shared/DataGridComponent';
import { ACTION_GROUPS_ENUM } from '../../shared/DataGridComponent/ActionsComponent/ActionsGroupEnum';
import { pageSizes, taskSchedulerHeadCell } from './consts';
import { ITaskSchedulerProps } from './ITaskSchedulerProps';
import { ITaskSchedulerState } from './ITaskSchedulerState';
import styles from './TaskScheduler.module.scss';

const TaskScheduler: FC<ITaskSchedulerProps> = (): ReactElement => {
  const { addNotification } = useNotifications();
  const { hasPermission } = useUserPermissions();

  const [state, setState] = useState<ITaskSchedulerState>({
    items: [],
    loading: false,
    foundCount: 0,
    dataGridState: null,
  });

  const fetchTask = async (
    pagination: any = state.dataGridState?.pagination,
    sortOrder: any = state.dataGridState.sortOrder,
  ) => {
    setState((prev: ITaskSchedulerState) => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.taskScheduler.getTasks(
        pagination,
        sortOrder,
        null,
      );
      const foundCount = data.total.found;
      const items = data.data;
      setState((prev: ITaskSchedulerState) => ({ ...prev, items, foundCount }));
    } catch (e: any) {
      addNotification({
        text: `Jobs fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: ITaskSchedulerState) => ({ ...prev, loading: false }));
    }
  };

  const handleChangeItemsDataGridState = async (dataGridState: any) => {
    const { countOnPage, paginationProps, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    setState({
      ...state,
      dataGridState: {
        countOnPage,
        sortOrder,
        pagination,
      },
    });
    await fetchTask(pagination, sortOrder);
  };

  const accessToAdminPage = hasPermission('Admin.ViewPage');

  const builTableRows = state.items.map((item: any) => {
    return { ...item, onClose: fetchTask };
  });

  return (
    <>
      <div className={styles.mainWrapper}>
        <div className={styles.headerBlock}>
          <Icon iconName='ClipboardList' className={styles.mainIcon} />
          <Text variant='xxLarge'>Task Scheduler</Text>
        </div>
        {accessToAdminPage.isRead && (
          <div className="ms-Grid">
            <div className="ms-Grid-row">
              <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                <DataGridComponent
                  idTable={'task-scheduler-table'}
                  title='Available Tasks'
                  headCells={taskSchedulerHeadCell}
                  rowsTable={builTableRows}
                  totalDataFound={state.foundCount}
                  defaultRowsPerPage={pageSizes}
                  actionsGroupName={ACTION_GROUPS_ENUM.TASK_SCHEDULER}
                  handleChangeDataGridState={handleChangeItemsDataGridState}
                  isLoading={state.loading}
                  enableMultiSelectRow
                  enablePagination
                  enableRowsPerPage
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TaskScheduler;
