export const DEL_NUM_FIELDS = {
  orderNumberDataField : {
    id: 'orderNumber',
    label: 'Order #',
    name: 'orderNumber',
    type: 'text',
    disabled: true,
  },
  dateShippedDataField : {
    id: 'dateShipped',
    label: 'Date Shipped',
    name: 'dateShipped',
    type: 'date',
    disabled: true,
  },
  invoiceNumberDataField : {
    id: 'invoiceNumber',
    label: 'Invoice #',
    name: 'invoiceNumber',
    type: 'text',
    disabled: true,
  },
  dlDataField : {
    id: 'dl',
    label: 'DL #',
    name: 'dl',
    type: 'text',
    disabled: true,
  },
};

export interface IOrderData {
  orderId?: string,
  orderItemId?: string,
  gdyrDelNo?: string,
  invcNo?: string,
  dataShipped?: string,
}