import { HeadCell } from '../../../../../shared/DataGridComponent/DataGridModels';

export const columns: HeadCell[] = [
  {
    key: 'column0',
    name: 'Product Code',
    fieldName: 'productCode',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Tire Type',
    fieldName: 'tireType',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Tire Size',
    fieldName: 'tireSize',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Description',
    fieldName: 'description',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'Tread Depth',
    fieldName: 'treadDepth',
    typeField: 'text',
    isEditable: true,
  },
  {
    key: 'column5',
    name: 'Price',
    fieldName: 'price',
    typeField: 'text',
    isEditable: true,
  },
  {
    key: 'column6',
    name: 'Casing Value',
    fieldName: 'casingValue',
    typeField: 'text',
    isEditable: true,
  },
];

export const intervalsHeadCells: HeadCell[] = [
  {
    key: 'column0',
    name: 'Interval',
    fieldName: 'interval',
    typeField: 'text',
    isEditable: true,
  },
  {
    key: 'column1',
    name: 'Eff From',
    fieldName: 'effFrom',
    typeField: 'date',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Eff To',
    fieldName: 'effTo',
    typeField: 'date',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Price',
    fieldName: 'billingPrice',
    typeField: 'text',
    isEditable: true,
  },
];

export const mockTreadDepth = {
  data: [
    {
      id: '123',
      productCode: 'productCode',
      tireType: 'tireType',
      description: 'description',
      tireSize: 'tireSize',
      treadDepth: 20,
      country: 'country',
      casingValue: 0,
      price: 0,
      prices: [
        {
          modifiedState: 0,
          id: 'string',
          interval: 0,
          effFrom: 'string',
          effTo: 'string',
          billingPrice: 0,
        },
      ],
    },
  ],
  total: {
    all: 1,
    found: 1,
  },
};