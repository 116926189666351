import React, { FC } from 'react';
import classNames from 'classnames';

import { CommandBarButton, IconButton, Text } from '@fluentui/react';

import { IPaginationProps } from './IPaginationProps';

import styles from './Pagination.module.scss';

const Pagination: FC<IPaginationProps> = ({ total, current, onChangePage }) => {
  const DOTS = <Text className={styles.pagesSeparator}>...</Text>;

  const renderPageButton = (pageNumber: number) => (
    <CommandBarButton
      key={pageNumber}
      className={classNames({ [styles.activePage]: pageNumber === current })}
      onClick={() => onChangePage(pageNumber)}
    >
      {pageNumber}
    </CommandBarButton>
  );

  const range = (start: number, end: number) => {
    const length = end - start + 1;
    /*
            Create an array of certain length and set the elements within it from
          start value to end value.
        */
    return Array.from({ length }, (_, idx) => idx + start);
  };

  const renderPages = () => {
    const siblingCount = 1;

    // Pages count is determined as siblingCount + firstPage + lastPage + current + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
            Case 1:
            If the number of pages is less than the page numbers we want to show in our
            paginationComponent, we return the range [1..total]
        */
    if (totalPageNumbers >= total) {
      return range(1, total);
    }

    /*
            Calculate left and right sibling index and make sure they are within range 1 and total
        */
    const leftSiblingIndex = Math.max(current - siblingCount, 1);
    const rightSiblingIndex = Math.min(current + siblingCount, total);

    /*
            We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and total. 
            Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < total - 2
        */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < total - 2;

    const firstPageIndex = 1;
    const lastPageIndex = total;

    /*
            Case 2: No left dots to show, but rights dots to be shown
        */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);
      return [...leftRange, DOTS, total];
    }

    /*
            Case 3: No right dots to show, but left dots to be shown
        */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(total - rightItemCount + 1, total);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
            Case 4: Both left and right dots to be shown
        */
    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  };

  return (
    <div className={classNames(styles.root, { [styles.emptyPagination]: total < 2 })} id='paginationContainer'>
      <IconButton
        iconProps={{ iconName: 'Back' }}
        ariaLabel="Back"
        disabled={current === 1}
        onClick={() => onChangePage(current - 1)}
      />
      {renderPages()?.map(pageNumber => {
        if (pageNumber === DOTS) {
          return DOTS;
        }
        return renderPageButton(+pageNumber);
      })}
      <IconButton
        iconProps={{ iconName: 'Forward' }}
        ariaLabel="Forward"
        disabled={current === total}
        onClick={() => onChangePage(current + 1)}
      />
    </div>
  );
};

export default Pagination;
