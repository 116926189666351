import { Separator } from '@fluentui/react';
import React, {
  FC,
  ReactElement,
} from 'react';

import { ISeparatorGyProps } from './ISeparatorGyProps';

import styles from './SeparatorGy.module.scss';

const SeparatorGy: FC<ISeparatorGyProps> = (props): ReactElement => (
  <Separator {...props} className={styles.separator} />
);

export default SeparatorGy;
