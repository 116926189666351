import { DefaultButton, MaskedTextField, MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { FunctionComponent, useEffect, useState } from 'react';
import { useField } from '../../../../../../hooks/useField';
import DataGridComponent from '../../../../../../shared/DataGridComponent';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import { dotNumDateRegex, dotNumFieldFormat, DOT_FIELDS, inTransitTiresUpdateHeadCell, SERIES_FIELDS } from './consts';
import { inTransitOrderCodeSelector, setActiveSubTab, setActiveTab } from '../../../../../../redux/tireOrderingSlice';
import apiService from '../../../../../../api';
import styles from './UpdateWithDOT.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import DelNumComponent from '../../../../../../shared/DelNumComponent';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import { IAvailableTires } from '../../../../../../models/IAvailableTires';
import { INotification } from '../../../../../../models/INotification';
import useNotifications from '../../../../../../hooks/useNotifications';
import DialogComponent from '../../../../../../shared/DialogComponent';
import { TireStatusesEnum } from '../../../../../../models/TireStatusesEnum';
import { TemporaryTireStatusesEnum } from '../../../../../../models/TemporaryTireStatusesEnum';
import { ITemporaryTires } from '../../../../../../models/ITemporaryTires';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import { customerSelector } from '../../../../../../redux/recordKeepingSlice';
import { IUpdateWithDOTProps } from './IUpdateWithDOTProps';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_inTransitTires_updateWithDOT } from '../../../../../../consts/programKeys';
import { intransitDOTPageSizes } from './consts';
import { tireOrderTabs } from '../../../../consts';


const UpdateWithDOTComponent: FunctionComponent<IUpdateWithDOTProps> = () => {

  // redux
  const { regionCode, id: customerId } = useSelector(customerSelector);
  const orderCode = useSelector(inTransitOrderCodeSelector);

  // hooks
  const { addNotification } = useNotifications();

  // states
  const [availableTiresList, setAvailableTiresList] = useState([]);
  const [temporaryTires, setTemporaryTires] = useState<Array<ITemporaryTires>>([]);
  const [totalFound, setTotalFound] = useState(0);
  const [totalTemporaryFound, setTotalTemporaryFound] = useState(0);
  const [availableTiresSelected, setAvailableTiresSelected] = useState<Array<IAvailableTires>>([]);
  const [toUpdateTiresSelected, setToUpdateTiresSelected] = useState<Array<IAvailableTires>>([]);
  const [showRegexError, setShowRegexError] = useState(false);
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });
  const [paginationPropsAvailable, setPaginationPropsAvailable] = useState<any>();
  const [paginationPropsUpdated, setPaginationPropsUpdated] = useState<any>();
  const [availableTiresSeriesSelected, setAvailableTiresSeriesSelected] = useState<Array<IAvailableTires>>([]);

  // toogles
  const [isLoadingAvailableTable, { toggle: toggleShowLoadingAvailableTable }] = useBoolean(false);
  const [isLoadingTemporaryTable, { toggle: toggleShowLoadingTemporaryTable }] = useBoolean(false);
  const [reloadControlsTable, { toggle: toggleReloadControlsTable }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [isUpdatingDialogVisible, { toggle: toggleUpdatingConfirmation }] = useBoolean(false);

  // formControls
  const pstcField = useField(DOT_FIELDS.pstcDataField, '');
  const wyField = useField(DOT_FIELDS.wyDataField, '');

  const beginPrefixField = useField(SERIES_FIELDS.beginPrefixDataField);
  const beginBrandField = useField(SERIES_FIELDS.beginBrandDataField);
  const beginSufixField = useField(SERIES_FIELDS.beginSufixDataField);
  const endPrefixField = useField(SERIES_FIELDS.endPrefixDataField);
  const endBrandField = useField(SERIES_FIELDS.endBrandDataField);
  const endSufixField = useField(SERIES_FIELDS.endSufixDataField);

  // authorization
  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_inTransitTires_updateWithDOT);

  const dispatch = useDispatch();

  const fetchData = async (
    pagination: any = { pageSize: intransitDOTPageSizes[0], pageNumber: 1 },
    filters: any = { status: TireStatusesEnum.INTRANSIT },
    sortOrder: any = { column: inTransitTiresUpdateHeadCell[0].fieldName, order: 'asc' },
    shouldAutoSelectRows = false,
  ) => {
    try {
      if (!shouldAutoSelectRows) {
        setAvailableTiresList([]);
        setAvailableTiresSelected([]);
        setAvailableTiresSeriesSelected([]);
        setTotalFound(0);
      }
      filters = {
        ...filters,
        customerId,
        orderId: orderCode?.id,
      };
      const { data }: any = await apiService.inTransitTiresAPI.getAvailableInTransitTires(pagination, filters, sortOrder);
      if (shouldAutoSelectRows) {
        setAvailableTiresSeriesSelected(data?.data);
      }
      else {
        setAvailableTiresList(data?.data);
        setTotalFound(data?.total?.found);
      }
    } catch (error) {
      setNotification({ text: 'Fetching tires error', type: MessageBarType.error });
    }
  };

  const getTemporaryTires = async (
    pagination: any = { pageSize: intransitDOTPageSizes[0], pageNumber: 1 },
    filters: any = { status: TemporaryTireStatusesEnum.PENDING_UPDATEWITHDOTNO, customerId: customerId },
    sortOrder: any = { column: inTransitTiresUpdateHeadCell[0].fieldName, order: 'asc' },
  ) => {
    try {
      setTemporaryTires([]);
      setTotalTemporaryFound(0);
      setToUpdateTiresSelected([]);
      const { data }: any = await apiService.inTransitTiresAPI.getTemporaryTires(pagination, filters, sortOrder);
      setTemporaryTires(data?.data);
      setTotalTemporaryFound(data?.total?.found);
    } catch (error) {
      setNotification({ text: 'Fetching tires error', type: MessageBarType.error });
    }

  };

  const moveTiresToTemporary = async () => {
    try {
      const inTransitTireIds = getIdsFromTires(availableTiresSelected);
      const bodyRequest = {
        status: TemporaryTireStatusesEnum.PENDING_UPDATEWITHDOTNO,
        ids: inTransitTireIds,
        dotNum: newDotNum || toUpdateTiresSelected.map(t => t.dotNum),
      };
      await apiService.inTransitTiresAPI.moveTiresToTemporary(bodyRequest);
      setNotification({ text: `${inTransitTireIds.length} tires moved successfully`, type: MessageBarType.success });
    } catch (error) {
      setNotification({ text: 'Moving tires error', type: MessageBarType.error });
    }
  };

  const moveTiresBackToAvailable = async () => {
    try {
      const inTransitTireIds = getIdsFromTires(toUpdateTiresSelected);
      await apiService.inTransitTiresAPI.moveTiresBackToMainTable(inTransitTireIds);
      setNotification({ text: `${inTransitTireIds.length} tires moved back successfully`, type: MessageBarType.success });
    } catch (error) {
      setNotification({ text: 'Moving tires error', type: MessageBarType.error });
    }
  };

  const updateTiresDotNum = async () => {
    try {
      const inTransitTireIds = getIdsFromTires(toUpdateTiresSelected);
      const bodyRequest = {
        ids: inTransitTireIds,
      };
      await apiService.inTransitTiresAPI.updateTiresDotNum(bodyRequest);
      setNotification({ text: `${inTransitTireIds.length} tires updated successfully`, type: MessageBarType.success });
    } catch (error) {
      setNotification({ text: 'Updating tires error', type: MessageBarType.error });
    }
  };

  const handlePrint = async (printingType: any) => {
    try {

      const inTransitTiresGetInput = {
        pagination: {
          pageSize: paginationPropsAvailable?.countOnPage?.text,
          pageNumber: paginationPropsAvailable?.paginationProps?.current,
        },
        filters: {
          customerId: customerId,
          locationId: regionCode,
        },
      };

      const inTransitTiresAltGetInput = {
        pagination: {
          pageSize: paginationPropsUpdated?.countOnPage?.text,
          pageNumber: paginationPropsUpdated?.paginationProps?.current,
        },
        filters: {
          customerId: customerId,
          locationId: regionCode,
        },
      };

      const headerFields = [
        { title: 'customerId', value: customerId },
        { title: 'locationId', value: regionCode },
      ];

      const requestData = {
        inTransitTiresGetInput: inTransitTiresGetInput,
        inTransitTiresAltGetInput: inTransitTiresAltGetInput,
        headerFields: headerFields,
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.inTransitTiresAPI.printExcel(requestData) :
        await apiService.inTransitTiresAPI.printPdf(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
      toggleShowPrintExport();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    }
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    toggleShowLoadingAvailableTable();
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString: searchedText,
      status: TireStatusesEnum.INTRANSIT,
    };
    setPaginationPropsAvailable(dataGridState);
    if (orderCode) await fetchData(pagination, filters, sortOrder);
    toggleShowLoadingAvailableTable();
  };

  const handleChangeTemporaryDataGridState = async (dataGridState: any) => {
    toggleShowLoadingTemporaryTable();
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString: searchedText,
      status: TemporaryTireStatusesEnum.PENDING_UPDATEWITHDOTNO,
      customerId: customerId,
    };
    setPaginationPropsUpdated(dataGridState);
    if (orderCode) await getTemporaryTires(pagination, filters, sortOrder);
    toggleShowLoadingTemporaryTable();
  };

  const handleUpdateTires = async () => {
    const { tireOrderManagement: { name } } = tireOrderTabs;
    toggleUpdatingConfirmation();
    toggleShowLoadingAvailableTable();
    toggleShowLoadingTemporaryTable();
    await updateTiresDotNum();
    await fetchData();
    await getTemporaryTires();
    toggleReloadControlsTable();
    toggleShowLoadingAvailableTable();
    toggleShowLoadingTemporaryTable();
    dispatch(setActiveTab(name));
    dispatch(setActiveSubTab('Tire Orders'));
  };

  const validateUpdateTires = async () => {
    if (validateDotNumber) {
      setShowRegexError(false);
      toggleUpdatingConfirmation();
    } else {
      setShowRegexError(true);
    }
  };

  const handleChangeSeries = async () => {
    toggleShowLoadingAvailableTable();
    const inTransitTiresSelectRange = getRangeObject();
    await fetchData(null, {
      inTransitTiresSelectRange,
      status: TireStatusesEnum.INTRANSIT,
    }, null, true);
    toggleShowLoadingAvailableTable();
  };

  const handleSelectAvailableRow = (rowsSelected: Array<IAvailableTires>) => {
    setAvailableTiresSelected(rowsSelected);
  };

  const handleSelectToUpdateRow = (rowsSelected: Array<IAvailableTires>) => {
    setToUpdateTiresSelected(rowsSelected);
  };

  const getRangeObject = () => {
    return !disableSelectSeriesBtn ?
      {
        beginBrand: {
          prefix: beginPrefixField.getValue(),
          brandNo: beginBrandField.getValue(),
          sufix: beginSufixField.getValue(),
        },
        endBrand: {
          prefix: beginPrefixField.getValue(),
          brandNo: endBrandField.getValue(),
          sufix: beginSufixField.getValue(),
        },
      } : null;
  };

  const transferTiresToAvailable = async () => {
    toggleShowLoadingAvailableTable();
    toggleShowLoadingTemporaryTable();
    toggleReloadControlsTable();
    await moveTiresBackToAvailable();
    await getTemporaryTires();
    await fetchData();
    toggleShowLoadingAvailableTable();
    toggleShowLoadingTemporaryTable();
  };

  const transferTiresToUpdate = async () => {
    toggleShowLoadingAvailableTable();
    toggleShowLoadingTemporaryTable();
    toggleReloadControlsTable();
    await moveTiresToTemporary();
    await getTemporaryTires();
    await fetchData();
    toggleShowLoadingAvailableTable();
    toggleShowLoadingTemporaryTable();
  };

  const getIdsFromTires = (tiresArray: any) => tiresArray?.map((tire: any) => tire.inTransitTireId || tire.inTransitTireAltId);
  const validateDotNumDateRegex = (newDotNumDate: string) => dotNumDateRegex.test(newDotNumDate);
  const newDotNum = `${pstcField.getValue()} ${wyField.getValue()}`;
  const getPrefixValue = beginPrefixField.getValue();
  const getSufixValue = beginSufixField.getValue();
  const disableToUpdateButton = availableTiresSelected?.length !== 0;
  const disableToAvailableAButton = toUpdateTiresSelected.length === 0;
  const disableSelectSeriesBtn = !beginPrefixField.getValue();
  const disableUpdateBtn = toUpdateTiresSelected.length !== 0;
  const validateDotNumber = pstcField.getValue().length === 9 && validateDotNumDateRegex(wyField.getValue());

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  return (
    <>
      <div className='ms-grid margin-left-rigth-2'>
        <div className='ms-Grid-row'>
          <div className={classNames('ms-Grid-col', 'ms-sm6')}>
            <Text variant='xLarge' className={styles.highlight}>Order Details</Text>
            <SeparatorGy />
            <div className='ms-Grid'>
              <DelNumComponent orderCode={orderCode} />
              <div className={classNames('ms-Grid-row', 'ms-sm12', styles.marginTop10)}>
                <Text variant='xLarge' className={styles.highlight}>Select series</Text>
                <SeparatorGy />
                <div className='ms-Grid'>
                  <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                    <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                      <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                        <Text variant='medium' className={styles.highlight}>Begin</Text>
                      </div>
                      <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                        <TextField {...beginPrefixField} />
                      </div>
                      <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                        <TextField {...beginBrandField} />
                      </div>
                      <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                        <TextField {...beginSufixField} />
                      </div>
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                      <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                        <Text variant='medium' className={styles.highlight}>End</Text>
                      </div>
                      <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                        <TextField {...endPrefixField} value={getPrefixValue} />
                      </div>
                      <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                        <TextField {...endBrandField} />
                      </div>
                      <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                        <TextField {...endSufixField} value={getSufixValue} />
                      </div>
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm4', 'marginTop18')}>
                      <PrimaryButton
                        id="selectSeriesBtn"
                        text="Select series"
                        onClick={() => { handleChangeSeries(); }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm6')}>
            <Text variant='xLarge' className={styles.highlight}>DOT#</Text>
            <SeparatorGy />
            <div className='ms-Grid'>
              <div className='ms-Grid-row'>
                <div className={classNames('ms-Grid-col', styles.dotNumLabel)}>
                  <MaskedTextField
                    {...pstcField}
                    mask='** ** ***'
                    maxLength={9}
                    maskChar=''
                    maskFormat={dotNumFieldFormat}
                    required
                  />
                </div>
                <div className={classNames('ms-Grid-col', styles.dotNumLabel)}>
                  <MaskedTextField
                    {...wyField}
                    className={showRegexError ? styles.errorInput : ''}
                    onChange={(_e, value) => { wyField.setValue(value); setShowRegexError(false); }}
                    mask='9999'
                    maskChar=''
                    required
                  />
                </div>
              </div>
              {showRegexError && (
                <p className={styles.errorLabel}>The new DOT # doesn't match with the correct format. Please see the examples.</p>
              )}
            </div>
          </div>
          <div className={classNames('ms-Grid-row')}>
            <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop10)}>
              <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                <div className={classNames('top-table', styles.marginTop10)}>
                  <PrimaryButton
                    id="transferToUpdateBtn"
                    text="Apply DOT# to selected"
                    onClick={() => { transferTiresToUpdate(); }}
                    disabled={!(validateDotNumber && disableToUpdateButton && userPermissions.isWrite)}
                  />
                </div>
                <div className={classNames('tableContainer', styles.marginTop10)}>
                  <DataGridComponent
                    idTable={'available-intransit-table'}
                    title='Available In-Transit Tires'
                    headCells={inTransitTiresUpdateHeadCell}
                    rowsTable={availableTiresList}
                    defaultRowsPerPage={intransitDOTPageSizes}
                    preselectedRows={availableTiresSeriesSelected}
                    totalDataFound={totalFound}
                    isLoading={isLoadingAvailableTable}
                    enableCheckBox={true}
                    enableSearching={true}
                    enableMultiSelectRow={true}
                    enablePagination={true}
                    enableRowsPerPage={true}
                    reloadControlsTable={reloadControlsTable}
                    handleChangeDataGridState={handleChangeDataGridState}
                    handleSelectRow={handleSelectAvailableRow}
                  />
                </div>
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                <div className={classNames('top-table', styles.marginTop10)}>
                  <PrimaryButton
                    id="transferToAvailableBtn"
                    text="Move selected back to available"
                    onClick={() => { transferTiresToAvailable(); }}
                    disabled={disableToAvailableAButton || !userPermissions.isWrite}
                  />
                </div>
                <div className={classNames('tableContainer', styles.marginTop10)}>
                  <DataGridComponent
                    idTable={'updated-intransit-table'}
                    title='Updated In-Transit Tires'
                    headCells={inTransitTiresUpdateHeadCell}
                    rowsTable={temporaryTires}
                    defaultRowsPerPage={intransitDOTPageSizes}
                    totalDataFound={totalTemporaryFound}
                    isLoading={isLoadingTemporaryTable}
                    enableCheckBox={true}
                    enableSearching={true}
                    enableMultiSelectRow={true}
                    enablePagination={true}
                    enableRowsPerPage={true}
                    reloadControlsTable={reloadControlsTable}
                    handleSelectRow={handleSelectToUpdateRow}
                    handleChangeDataGridState={handleChangeTemporaryDataGridState}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames('ms-Grid-col', 'ms-sm12', 'buttonsWrapper')}>
          <DefaultButton onClick={toggleShowPrintExport} text="Print/Export" />
          <PrimaryButton
            id='updateBtn'
            onClick={validateUpdateTires}
            text="Submit"
            disabled={!(validateDotNumber && disableUpdateBtn && userPermissions.isWrite)}
          />
        </div>
      </div>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      <DialogComponent
        isOpen={isUpdatingDialogVisible}
        onCancel={toggleUpdatingConfirmation}
        onSubmit={handleUpdateTires}
        title={'Confirmation'}
        subText={`Are you sure you want to update ${toUpdateTiresSelected.length} number of items?`}
        onSubmitLabel={'Update'}
        onCancelLabel={'Cancel'}
      />
    </>
  );
};

export default UpdateWithDOTComponent;