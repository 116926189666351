import { IDropdownOption } from '@fluentui/react';
import { IFiltersState } from './IFiltersState';

export const pageSizes = [5, 10, 20];

export const tableColumns = [
  {
    key: 'column0',
    name: 'Full Name',
    fieldName: 'fullName',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'Email',
    fieldName: 'email',
  },
  {
    key: 'column2',
    name: 'UserId',
    fieldName: 'userId',
  },
  {
    key: 'column3',
    name: 'Access',
    fieldName: 'accessTypes',
  },
];

export const allOption: IDropdownOption = {
  key: 'All',
  text: 'All',
};

export const emptyFilters: IFiltersState = {
  searchString: '',
  userStatus: [allOption.key],
};
