/* eslint-disable @typescript-eslint/no-empty-interface */
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { IconButton, Persona, PersonaSize } from '@fluentui/react';
import { IStackStyles, Stack } from '@fluentui/react/lib/Stack';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import { userSelector } from '../../redux/__mocks__/userSlice';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { MENU_LISTING } from './consts';
import styles from './LeftNav.module.scss';
import { ScrollablePane, ScrollbarVisibility } from '@fluentui/react/lib/ScrollablePane';

initializeIcons();
export interface ILeftnavProps {
  isOpen: boolean;
  toggleOpenMenu: any;
}
export interface ILeftnavState {
}

const Leftnav = ({ isOpen, toggleOpenMenu }: ILeftnavProps) => {

  const userPersona = useSelector(userSelector);

  const {
    fetchPermissions,
    userPermissions,
    hasModuleAccess,
    loadingPermissions,
  } = useUserPermissions();


  const [activeMenu, setActiveMenu] = useState('');
  const location = useLocation();

  const stackLeftNavStyles: Partial<IStackStyles> = {
    root: {
      position: 'fixed',
      width: isOpen ? '15%' : '4%',
      height: '100%',
      zIndex: 5,
      backgroundColor: '#fff',
      margin: '0px',
      boxShadow: 'rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px',
      transition: '0.5s',
    },
  };

  const isActiveTab = (currentMenu: string) => activeMenu == currentMenu;

  useEffect(() => {
    if (!userPermissions) fetchPermissions();
    MENU_LISTING.map(({ id, routePath }) => {
      if (routePath.replaceAll('/', '') == location.pathname.split('/')[1]) {
        setActiveMenu(id);
      }
    });
  }, []);


  return (
    <Stack styles={stackLeftNavStyles} >
      <ScrollablePane  scrollbarVisibility={ScrollbarVisibility.auto}>
        <div className={classNames(styles.sidebarMenuWrapper)}>
          <div className={classNames(styles.userProfileWrapper)}>
            <Stack className="">
              {userPersona.avatar ?
                <div className={classNames(styles.profilePicture)}>
                  <img
                    src={userPersona.avatar}
                    className={
                      classNames(isOpen ?
                        styles.profilePicture_opened :
                        styles.profilePicture_closed,
                      )}
                  />
                </div> :
                <Persona
                  id='callout-button'
                  className={classNames(styles.headshot)}
                  {...userPersona}
                  size={isOpen ? PersonaSize.size56 : PersonaSize.size48}
                  showInitialsUntilImageLoads={true}
                />}
            </Stack>
            {isOpen && <div className={classNames(styles.userDetails)}>
              <div className={classNames(styles.userName)}>
                {userPersona.displayName}
              </div>
              <div className={classNames(styles.userTitle)}>
                {userPersona.jobTitle}
              </div>
            </div>}
          </div>
          <div className={classNames(styles.sidebarTabsWrapper)} >
            {MENU_LISTING.map(({ id, name, routePath, iconSideBarSrc }) => (hasModuleAccess(id) || name === 'My Reports') && (
              <div id={id} className={classNames(styles.linkWrapper, `${isActiveTab(id) ? styles.sidebarTabActive : styles.sidebarTab}`)}>
                <NavLink className={styles.leftNavLink} to={routePath} key={`${id}_menuOption`} onClick={() => setActiveMenu(id)}></NavLink>
                <div className={classNames(styles.sidebarTabsLeft)}></div>
                <div className={classNames(styles.sidebarTabIconWrapper)}>
                  <img className={classNames(styles.sidebarTabIcon)} src={iconSideBarSrc} width="24px" alt={name} />
                </div>
                {isOpen && <div className={classNames(styles.sidebarTabsText)}>{name}</div>}
              </div>
                
            ))}
          </div>
        </div>
      </ScrollablePane>
      <div className={classNames(styles.menuToggle, isOpen ? styles.menuOpened : styles.menuClosed)} onClick={toggleOpenMenu}>
        <IconButton
          id="toggleMenuIcon"
          width={24}
          iconProps={{ iconName: isOpen ? 'ChevronLeftSmall' : 'ChevronRightSmall' }}
          className={classNames(styles.customIcon)}
        />
      </div>
      {loadingPermissions && <LoadingScreen />}
    </Stack>
  );
};

export default Leftnav;
