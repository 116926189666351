export const columns = [
  {
    key: 'column0',
    name: 'Position Name',
    fieldName: 'whlPosName',
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: 'column1',
    name: 'Description',
    fieldName: 'description',
    minWidth: 100,
    isResizable: true,
  },
];

