import { DefaultButton, IconButton, MessageBarType, Modal, PrimaryButton, Text } from '@fluentui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import DataGridComponent from '../DataGridComponent';
import { wareHouseHeadCell, WarehouseModalComponentProps } from './consts';
import styles from './WarehouseModalComponent.module.scss';
import apiService from '../../api';
import useNotifications from '../../hooks/useNotifications';


const WarehouseModalComponent: FunctionComponent<WarehouseModalComponentProps> = ({
  id,
  title,
  type,
  isOpen,
  onSubmit,
  onDismiss,
  successLabel,
  cancelLabel }) => {
  const { addNotification } = useNotifications();
  const [wareHouseList, setWareHouseList] = useState([]);
  const [totalFound, setTotalFound] = useState(0);
  const [wareHouseSelected, setWareHouseSelected] = useState({});
  const [notification, setNotification] = useState({ text: '', type: 0 });
  const [isLoading, { toggle: toggleChangeLoading }] = useBoolean(false);

  const fetchData = async (pagination: any, filters?: any, sortOrder?: any) => {
    try {
      if (type) filters = { ...filters, type: type == 'R' ? 'RETREAD' : 'WAREHOUSE' };
      const { data }: any = await apiService.plantWareHouseAPI.getPlantsToAdd(pagination, filters, sortOrder);
      setWareHouseList(data?.data);
      setTotalFound(data?.total?.found);
    } catch (error) {
      setNotification({ text: 'Fetching Plants/Warehouse error', type: MessageBarType.error });
    }
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    toggleChangeLoading();
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString: searchedText,
      active: true,
    };
    await fetchData(pagination, filters, sortOrder);
    toggleChangeLoading();
  };

  const handleSelectRow = (rowsSelected: any) => {
    setWareHouseSelected(rowsSelected);
  };

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  return (
    <>
      <Modal
        key={id}
        isOpen={isOpen}
        onDismiss={onDismiss}
        containerClassName='modalContainer'
      >
        <div className={styles.modalBody}>
          <div className='modalHeader'>
            <Text variant="xLarge" className={styles.highlight}>{title}</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel="Close popup modal"
              onClick={onDismiss}
            />
          </div>
          <div className='tableContainer'>
            <DataGridComponent
              idTable={'wareHouse-table'}
              title=''
              headCells={wareHouseHeadCell}
              rowsTable={wareHouseList}
              totalDataFound={totalFound}
              isLoading={isLoading}
              enableCheckBox={true}
              enableSearching={true}
              enablePagination={true}
              enableRowsPerPage={true}
              handleChangeDataGridState={handleChangeDataGridState}
              handleSelectRow={handleSelectRow}
            />
          </div>
          <div className={styles.modalFooter}>
            <DefaultButton
              id="cancelButton"
              text={cancelLabel}
              onClick={onDismiss}
            />
            <PrimaryButton
              id="selectButton"
              text={successLabel}
              onClick={(e) => onSubmit(wareHouseSelected)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default WarehouseModalComponent;