import { DefaultButton, MessageBarType, Pivot, PivotItem, Text } from '@fluentui/react';
import classNames from 'classnames';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import apiService from '../../../api';
import useNotifications from '../../../hooks/useNotifications';
import { INotification } from '../../../models/INotification';
import { customerFilterSelector, customerSelector, removeCustomer, setCustomer, setShouldFilterRun } from '../../../redux/customerSlice';
import { customerTabs } from './consts';
import styles from './TabComponents.module.scss';

import { useUserPermissions } from '../../../hooks/useUserPermissions';
import { get, isEmpty } from 'lodash';

interface CustomerTabsComponentProps {
  value?: string;
}

const CustomerTabsComponent: FunctionComponent<CustomerTabsComponentProps> = () => {

  const { getPermissionsByAccessKey } = useUserPermissions();

  const { id } = useParams<{ id: string }>();
  const { addNotification } = useNotifications();
  const customer = useSelector(customerSelector);

  const history = useHistory();
  const dispatch = useDispatch();
  const customerFilter = useSelector(customerFilterSelector);

  const [selectedKey, setSelectedKey] = useState(customerTabs?.general?.name);
  const [actionType, setActionType] = useState('');
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });

  const fetchCustomers = async () => {
    try {
      const filters = {
        status: get(customerFilter, 'status', null),
      };
      const { data: { data } }: any = await apiService.getCustomerSearch(filters, null, null, id);
      dispatch(setCustomer(data[0]));
      data[0].fProspect == 'Y' ? setActionType('active') : setActionType('edit');
    } catch (e: any) {
      setNotification({ text: 'Customers fetching error.', type: MessageBarType.error });
    }
  };

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  };

  const renderTab = (TabComponent: FunctionComponent<{ actionType: string }>) => {
    if (TabComponent) return <TabComponent actionType={actionType} />;
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  const handleBackToSearch = () => {
    dispatch(setShouldFilterRun(true));
    dispatch(removeCustomer());
    history.push('/customer');
  };

  useEffect(() => {
    setActionType(id == 'add' ? id : 'edit');
    if (id !== 'add') fetchCustomers();
  }, [id]);

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  return (
    <>
      <div className={classNames(styles.customerContainer)}>
        <div className={classNames(styles.backButtonContainer)}>
          <DefaultButton
            onClick={() => handleBackToSearch()}
            text="Back to Search"
          />
        </div>
        <div className={styles.tabsContainer}>
          <div className={styles.customerHeaderWrapper}>
            {isEmpty(customer?.contractStatus) ? customer && <h2 className={styles.customerHeader}>{customer?.customerName} </h2> :
              customer && <h2 className={styles.customerHeader}>{customer?.customerName} - {customer?.contractStatus} </h2>}
          </div>
          <Pivot
            className={'pivot-gy-colors'}
            selectedKey={selectedKey}
            onLinkClick={handleLinkClick}
            getTabId={getTabId}
            linkFormat="tabs"
          >
            {Object.values(customerTabs).map(({ accessKey, name, component, actionType: actionTypeList }, index) => {
              const showTab = actionTypeList.includes(actionType);
              return showTab && getPermissionsByAccessKey(accessKey)?.isAccess &&
                (<PivotItem headerText={name} key={index} itemKey={name}>
                  <div className={styles.pivotBodyContainer}>
                    {renderTab(component)}
                  </div>
                </PivotItem>);
            })}

          </Pivot>
        </div>

      </div>
    </>
  );
};

export default CustomerTabsComponent;
