import { FunctionComponent } from 'react';
import { ACTION_GROUPS_ENUM } from './ActionsGroupEnum';
import MaintainInvoiceAction from './MaintainInvoiceAction';
import MaintainTireSalesAction from './MaintainTireSalesAction';
import ViewInvoiceAction from './ViewInvoiceAction';
import ViewTireSalesAction from './ViewTireSalesAction';
import EditManageBillingCodes from './EditManageBillingCodes';
import EditManageBillingOptions from './EditManageBillingOptions';
import LogsTaskSchedulerAction from './LogsTaskSchedulerAction';
import ManualTaskSchedulerAction from './ManualTaskSchedulerAction';
import MaintainTaskSchedulerAction from './MaintainTaskSchedulerAction';
import UsersManagementAction from './UsersManagementAction';
import DownloadAvailableReportAction from './DownloadAvailableReport';
import StoredReportsViewPDF from './ViewPdfAction/StoredReportsViewPDF';
import BillingReportsViewPDF from './ViewPdfAction/BillingReportsViewPDF';


const ACTION_GROUPS: Array<IActionTypes> = [
  {
    actionGroupName: ACTION_GROUPS_ENUM.VIEW_INVOICES,
    actionsList: [
      ViewInvoiceAction,
      MaintainInvoiceAction,
    ],
  },
  {
    actionGroupName: ACTION_GROUPS_ENUM.VIEW_TIRE_SALES,
    actionsList: [
      ViewTireSalesAction,
      MaintainTireSalesAction,
    ],
  },
  {
    actionGroupName: ACTION_GROUPS_ENUM.EDIT_MANAGE_BILLING_CODES,
    actionsList: [
      EditManageBillingCodes,
    ],
  },
  {
    actionGroupName: ACTION_GROUPS_ENUM.EDIT_MANAGE_BILLING_OPTIONS,
    actionsList: [
      EditManageBillingOptions,
    ],
  },
  {
    actionGroupName: ACTION_GROUPS_ENUM.TASK_SCHEDULER,
    actionsList: [
      MaintainTaskSchedulerAction,
      ManualTaskSchedulerAction,
      LogsTaskSchedulerAction,
    ],
  },
  {
    actionGroupName: ACTION_GROUPS_ENUM.USERS_MANAGEMENT,
    actionsList: [
      UsersManagementAction,
    ],
  },
  {
    actionGroupName: ACTION_GROUPS_ENUM.DOWNLOAD_REPORT,
    actionsList: [
      DownloadAvailableReportAction,
    ],
  },
  {
    actionGroupName: ACTION_GROUPS_ENUM.DOWNLOAD_AVAILABLE_REPORT,
    actionsList: [
      DownloadAvailableReportAction,
    ],
  },
  {
    actionGroupName: ACTION_GROUPS_ENUM.VIEW_PDF_BILLINGREPORTS,
    actionsList: [
      BillingReportsViewPDF,
      DownloadAvailableReportAction,
    ],
  },
  {
    actionGroupName: ACTION_GROUPS_ENUM.VIEW_PDF_STOREDREPORTS,
    actionsList: [
      StoredReportsViewPDF,
    ],
  },
];

export const getActionsByGroupName = (groupName: ACTION_GROUPS_ENUM) =>
  ACTION_GROUPS.filter(actionGRoup => actionGRoup.actionGroupName == groupName)[0]?.actionsList;

interface IActionTypes {
  actionGroupName: ACTION_GROUPS_ENUM,
  actionsList: Array<FunctionComponent<any>>
}