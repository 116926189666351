import { HeadCell } from '../../../../../shared/DataGridComponent/DataGridModels';

export const columns: HeadCell[]  = [
  {
    key: 'column0',
    name: 'Name',
    fieldName: 'name',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Type',
    fieldName: 'type',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Created On',
    fieldName: 'createdOn',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Created By',
    fieldName: 'createdBy',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'Last Modified On',
    fieldName: 'lastModifiedOn',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column5',
    name: 'Last Modified By',
    fieldName: 'lastModifiedBy',
    typeField: 'text',
    isEditable: false,
  },
];

export const mockContract = {
  data: [
    {
      contractDocumentId: 'string',
      name: 'contractName',
      type: 'contractTYpe',
      createdOn: 'string',
      createdBy: 'string',
      lastModifiedOn: 'string',
      lastModifiedBy: 'string',
    },
  ],
  total: {
    all: 1,
    found: 1,
  },
};