import React from 'react';
import { DefaultButton, IconButton, MessageBarType, Modal, PrimaryButton, Text } from '@fluentui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import DataGridComponent from '../DataGridComponent';
import styles from './ContractsModal.module.scss';
import { useBoolean } from '@fluentui/react-hooks';
import { contractHeadCells } from './consts';
import apiService from '../../api';
import useNotifications from '../../hooks/useNotifications';


interface ContractsModalComponentProps {
  id: string;
  regionCode: string;
  customerId: string;
  title: string;
  isOpen: boolean;
  onSubmit: (value?: any) => void;
  onDismiss: () => void;
  successLabel: string;
  cancelLabel: string;
}

const ContractsModalComponent: FunctionComponent<ContractsModalComponentProps> = ({
  id,
  regionCode,
  customerId,
  title,
  isOpen,
  onSubmit,
  onDismiss,
  successLabel,
  cancelLabel,
}) => {

  const { addNotification } = useNotifications();
  const [contractList, setContractList] = useState([]);
  const [totalFound, setTotalFound] = useState(0);
  const [contractSelected, setContractSelected] = useState({});
  const [notification, setNotification] = useState({ text: '', type: 0 });
  const [isLoading, { toggle: toggleChangeLoading }] = useBoolean(false);

  const fetchData = async (pagination: any, filters?: any, sortOrder?: any) => {
    try {
      const { data }: any = await apiService.billingAPI.getContractList(
        pagination,
        sortOrder,
        filters,
        customerId,
        regionCode,
      );
      setContractList(data?.data);
      setTotalFound(data?.total?.found);
    } catch (error) {
      setNotification({ text: 'Fetching Contracts error', type: MessageBarType.error });
    }
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    toggleChangeLoading();
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString: searchedText,
    };
    await fetchData(pagination, filters, sortOrder);
    toggleChangeLoading();
  };

  const handleSelectRow = (rowsSelected: any) => {
    setContractSelected(rowsSelected[0]);
  };

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  return (
    <>
      <Modal
        key={id}
        isOpen={isOpen}
        onDismiss={onDismiss}
        containerClassName={styles.modalContainer}
      >
        <div className='modalHeader'>
          <Text variant='xLarge'>{title}</Text>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel='Close popup modal'
            onClick={onDismiss}
          />
        </div>
        <div className='tableContainer'>
          <DataGridComponent
            idTable={'contract-table'}
            title=''
            headCells={contractHeadCells}
            rowsTable={contractList}
            totalDataFound={totalFound}
            isLoading={isLoading}
            enableCheckBox={true}
            enableSearching={true}
            enablePagination={true}
            enableRowsPerPage={true}
            handleChangeDataGridState={handleChangeDataGridState}
            handleSelectRow={handleSelectRow}
          />
        </div>
        <div className={styles.modalFooter}>
          <DefaultButton
            id='cancelButton'
            text={cancelLabel}
            onClick={onDismiss}
          />
          <PrimaryButton
            id='selectButton'
            text={successLabel}
            onClick={(e) => onSubmit(contractSelected)}
          />
        </div>
      </Modal>
    </>
  );
};

export default ContractsModalComponent;