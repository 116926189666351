import moment from 'moment';
import { formatter } from '../utils';
import { ACTION_GROUPS_ENUM } from './ActionsComponent/ActionsGroupEnum';
import { HeadCell, IDataGridState, ISorting, Order } from './DataGridModels';

export type DATA_INPUT_TYPES = 'text' | 'number' | 'date' | 'boolean' | 'currency' | 'decimal';

export const defaultDataGridPageSizes = [10, 20, 30];

export const directionSortingList = ['asc', 'desc'];

export const descendingTieComparator = (a: any, b: any, orderBy: any) => {
  const firstIndex = orderBy[0];
  const secondIndex = orderBy[1];
  return b[firstIndex] === a[firstIndex] ? (b[secondIndex] > a[secondIndex] ? -1 : 1) :
    b[firstIndex] < a[firstIndex] ? -1 : 1;
};

export const descendingComparator = (a: any, b: any, orderBy: any) =>
  b[orderBy] === a[orderBy] ? 0 : b[orderBy] < a[orderBy] ? -1 : 1;

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: string | Array<ISorting>,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
  if (typeof orderBy === 'string') {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  } else
    return order === 'desc'
      ? (a, b) => descendingTieComparator(a, b, orderBy)
      : (a, b) => -descendingTieComparator(a, b, orderBy);
};

export interface DataGridComponentProps {
  /** id of the table */
  idTable: string;

  /** Title of the table */
  title: string;

  /** List of columns */
  headCells: HeadCell[];

  /** Object/Array to have a sorting by default */
  defaultSorting?: ISorting | ISorting[] | null;

  /** Array to have default rows per page listing */
  defaultRowsPerPage?: number[];

  /** Array to have preselected rows */
  preselectedRows?: any[];

  /** List of rows */
  rowsTable: any[];

  /** Total of rows found */
  totalDataFound: number;

  actionsGroupName?: ACTION_GROUPS_ENUM;

  /** Boolean to enable loading */
  isLoading?: boolean;

  /** Boolean to enable checkbox option */
  enableCheckBox?: boolean;

  /** Boolean to enable search option */
  enableSearching?: boolean;

  /** Boolean to check more tha one row per time */
  enableMultiSelectRow?: boolean;

  /** Boolean to enable delete option */
  enableDeleteOption?: boolean;

  /** Boolean to enable pagination */
  enablePagination?: boolean;

  /** Boolean to enable rows per page list */
  enableRowsPerPage?: boolean;

  /** Boolean to allow rows to edit */
  enableEditableRows?: boolean;

  /** Boolean to enable row clicking functionality */
  enableRowClick?: boolean;

  /** Boolean to enable update options */
  enableUpdate?: boolean;

  /** Boolean to enable searchString to upper case */
  enableSearchStringUpperCase?: boolean;

  /** Boolean to enable internally management of data */
  enableAutoControls?: boolean;

  /** Boolean to enable show/hide columns option */
  enableManageHeadCells?: boolean;

  /** Boolean to show total rows found */
  enableShowFound?: boolean;

  /** Boolean to enable resize columns option */
  enableResizeColumns?: boolean;

  /** Boolean to enable sorting (only one field) */
  enableSorting?: boolean;

  /** Boolean to enable multi-sorting (multiple fields) */
  enableMultiSorting?: boolean;

  /** Boolean to enable refresh table */
  enableRefreshOption?: boolean;
  
  /** Boolean to enable left checkbox */
  enableLeftCheckbox?: boolean;

  /** Boolean to reset controls of the table to initial values */
  reloadControlsTable?: boolean;

  /** Boolean to disable title label */
  disableTitle?: boolean;

  /**
   * callBack to fire when general state was modified
   * @params state: general state of the component
   */
  handleChangeDataGridState?: (state: IDataGridState) => void;

  /**
   * callBack to fire when a row list were selected
   * @params rowsSelected: list of rows selected
   */
  handleSelectRow?: (rowsSelected: any[]) => void;

  /**
   * callBack to fire when rows should be deleted
   * @params rowsSelected: list of rows to delete
   */
  handleDelete?: (rowsSelected: any[]) => void | Promise<void>;

  /**
   * callBack to fire when rows were modified
   * @params rowsToUpdate: list of rows updated
   */
  handleUpdate?: (rowsToUpdate: any) => void;

  /**
   * callBack to fire when user click two times in row
   * @params rowsToUpdate: list of rows clicked
   */
  handleRowClick?: (rowsClicked: any) => void;


  

}

export const initalDataGridState: IDataGridState = {
  searchedText: '',
  countOnPage: { key: defaultDataGridPageSizes[0], text: defaultDataGridPageSizes[0].toString() },
  paginationProps: {
    total: 1,
    current: 1,
  },
  sortOrder: {
    column: '',
    order: '',
  },
};

export const transformTextObj: any = {
  text: (value: any) => value,
  boolean: (value: any) => value ? 'Y' : 'N',
  date: (value: any) => value ? moment(value).format('MM/DD/YYYY') : '',
  number: (value: any) => +value,
  currency: (value: any) => formatter.format(value),
  decimal: (value: any) => (+value)?.toFixed(6),
};

