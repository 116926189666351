import { useEffect, useState, useCallback } from 'react';
import useNotifications from './useNotifications';
import {
  MessageBarType,
} from '@fluentui/react';
import { ICustomer } from '../components/Common/Search/ICustomer';

export const useCustomersFetch = (api: any, filter?: any, customerId?: any) => {
  const [customersList, setCustomerList] = useState<ICustomer[]>([]);
  const [totalFound, setTotalFound] = useState<number>(0);
  const [loadingCustomers, setLoadingCustomers] = useState<boolean>(false);
  const { addNotification } = useNotifications();

  const fetchCustomers = useCallback(async (
    filter: any, 
    pagination?: any, 
    sort: any = { column: 'custPrefix', order: 'asc' }) => {
    let result;
    let total;
    if (filter) {
      try {
        setLoadingCustomers(true);
        const { data }: any = await api(filter, pagination, sort, customerId);
        result = data.data;
        total = data.total.found || 0;
        setTotalFound(data?.total?.found ?? 0);
        setCustomerList(result);
      } catch (e: any) {
        const { response } = e;
        addNotification({
          text: `Customers fetching error: ${response?.data?.message}`,
          type: MessageBarType.error,
        });
      } finally {
        setLoadingCustomers(false);
      }
    }
    return { result, total, totalFound };
  }, [setTotalFound, setCustomerList, addNotification, setLoadingCustomers]);
  useEffect(() => {
    fetchCustomers(filter || {});
  }, []);

  return { customersList, loadingCustomers, fetchCustomers, totalFound };
};

