import { FunctionComponent } from 'react';
import ViewMaintainInvoicesModalComponent from '../../../ViewMaintainInvoicesModalComponent';
import { useBoolean } from '@fluentui/react-hooks';
import { IconButton } from '@fluentui/react';
import { IAvailableInvoice } from '../../../../models/IAvailableInvoice';
import SchedulingDetailsModal from '../../../../components/TaskScheduler/components/SchedulingDetailsModal/SchedulingDetailsModal';
import styles from '../Actions.module.scss';
import ManualRunModal from '../../../../components/TaskScheduler/components/ManualRunModal/ManualRunModal';


interface ManualTaskSchedulerActionProps {
  row: IAvailableInvoice;
}

const ManualTaskSchedulerAction: FunctionComponent<ManualTaskSchedulerActionProps> = ({ row }) => {
  const [isModalOpen, { toggle: toggleIsModalOpen }] = useBoolean(false);

  return (
    <>
      <IconButton
        id='viewButton'
        iconProps={{ iconName: 'RecurringTask' }}
        onClick={toggleIsModalOpen}
        className={styles.iconBtn}
      />

      <ManualRunModal
        isModalOpen={isModalOpen}
        hideModal={toggleIsModalOpen}
        taskInfo={row}
      />
    </>
  );
};

export default ManualTaskSchedulerAction;