import { IFormDataField } from '../../../../shared/FormBuilderComponent';
import { isNil } from 'lodash';

export const pageSizes = [500, 750, 1000];

export const SELL_BY_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'prefixField',
    properties: {
      id: 'prefix',
      label: '',
      name: 'prefix',
      type: 'text',
      disabled: false,
      description: '',
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'fromBrandNumberField',
    properties: {
      id: 'fromBrandNumber',
      label: '',
      name: 'fromBrandNumber',
      type: 'text',
      disabled: false,
      description: '',
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'suffixField',
    properties: {
      id: 'suffix',
      label: '',
      name: 'suffix',
      type: 'text',
      disabled: false,
      description: '',
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'toBrandNumberField',
    properties: {
      id: 'toBrandNumber',
      label: '',
      name: 'toBrandNumber',
      type: 'text',
      disabled: true,
      description: '*Brand Number',
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'vehicleNumberField',
    properties: {
      id: 'vehicleNumber',
      label: 'Vehicle #',
      name: 'vehicleNumber',
      type: 'text',
      disabled: true,
      textTransform: 'uppercase',
    },
  },
];

export const columns = [
  {
    key: 'column0',
    name: 'Account',
    fieldName: 'Account',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'Loc',
    fieldName: 'loc',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Inventory Date',
    fieldName: 'inventoryDate',
    isSorted: true,
    isSortedDescending: true,
  },
  {
    key: 'column3',
    name: 'Inventory By',
    fieldName: 'inventoryBy',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'Annual?',
    fieldName: 'annual',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'Submit Info',
    fieldName: 'submitInfo',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column6',
    name: 'Submit?',
    fieldName: 'submit',
    isSorted: false,
    isSortedDescending: false,
  },
];

export const printingTypes = {
  excel: 'Excel',
  pdf: 'PDF', 
};

export const downloadFile = (dataToBePrinted: any, printingType: any, reportName?: any) => {
  const typeFilePrinted = printingTypes.excel?'data:application/vnd.ms-excel;base64':'application/pdf';
  const url = window.URL.createObjectURL(new Blob([dataToBePrinted], { type: typeFilePrinted }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download', 
    `${isNil(reportName) ? 'report' : reportName}.${
      printingType === printingTypes.excel ? 'xlsx' : 
        printingType === printingTypes.pdf ? 'pdf' :
          printingType === 'Document' ? 'doc' :
            'docx'
    }`,
  );
  document.body.appendChild(link);
  link.click();
};
