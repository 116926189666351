import { PrimaryButton, TextField, Text, IconButton, Dropdown, MessageBarType, IDropdownStyles, Dialog, DialogType, DialogFooter, DefaultButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { FC, useEffect, useState } from 'react';
import AutocompleteInput from '../../../../../../../../shared/AutocompleteInput';
import BrandModalComponent from '../../../../../../../../shared/BrandSelectionModalComponent';
import PlantModalComponent from '../../../../../../../../shared/PlantSelectionModalComponent';
import TypeCodeModalComponent from '../../../../../../../../shared/TypeCodeModalComponent';
import { INewOrderDetailsProps } from './INewOrderDetailsProps';
import { INewOrderDetailsState } from './INewOrderDetailsState';
import apiService from '../../../../../../../../api';
import { useSelector } from 'react-redux';
import { customerSelector } from '../../../../../../../../redux/recordKeepingSlice';
import useNotifications from '../../../../../../../../hooks/useNotifications';
import { gdyrDelNoEMHandler } from '../../../../../../../../shared/TextFieldValidation';
import { debounce } from 'lodash';
import styles from './NewOrderDetails.module.scss';
import { transformDate } from '../../../../../../../../shared/transformDate';
import moment from 'moment';
import LoadingScreen from '../../../../../../../LoadingScreen/LoadingScreen';
import { contractInformationSelector } from '../../../../../../../../redux/tireOrderingSlice';


const NewOrderDetails: FC<INewOrderDetailsProps> = ({
  plant,
  setPlant,
  typeCodes,
  setTypeCodes,
  plants,
  setPlants,
  orderToBeAdded,
  setOrderToBeAdded,
  orderItemToBeAdded, 
  setOrderItemToBeAdded, 
}) => {
  const [state, setState] = useState<INewOrderDetailsState>({
    loading: false,
    typeCodes: [],
    plants: [],
    batchNumbers: [],
    billToSundres: [],
  });

  const { addNotification } = useNotifications();
  const { id: customerId, corrAddressCountry: country } = useSelector(customerSelector);

  const contractInformation = useSelector(contractInformationSelector);

  const [isSaveDisabled, { setTrue: disableSaving, setFalse: enableSaving }] = useBoolean(true);
  const [contractBrandPrefix, setContractBrandPrefix] = useState('');

  const [showBrandModal, { toggle: toggleShowBrandModal }] = useBoolean(false);
  const [showPlantModal, { toggle: toggleShowPlantModal }] = useBoolean(false);
  const [showTypeCodeModal, { toggle: toggleShowTypeCodeModal }] = useBoolean(false);
  const [showSubmitConfirmation, { toggle: toggleShowSubmitConfirmation }] = useBoolean(false);

  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 150 } };

  const fetchTypeCode = async (typeCode?: string) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.typeCodeAPI.getTypeCodeAuthList(
        null,
        {},
        { searchString: typeCode, originalOrRetread: 'O', country: country },
        customerId,
      );
      const items = data.data;
      setState((prev: any) => ({ ...prev, typeCodes: items }));
      setContractBrandPrefix(data.contractBrandPrefix);
      setTypeCodes(items);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching type codes error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchBatchNumber = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.tireOrders.getBatchNumberList();
      setState((prev: any) => ({ ...prev, batchNumbers: data }));
      setOrderItemToBeAdded((prev: any) => ({ ...prev, batch: data[0] }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching batch numbers error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchPlant = async (plant?: string) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.plantWareHouseAPI.getAvailablePlants(
        null,
        { searchString: plant, type: 'WAREHOUSE', active: true },
        {},
      );
      const items = data.data;
      setState((prev: any) => ({ ...prev, plants: items }));
      setPlants(items);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching plants error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const selectBrandNumbers = async (brand: any) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.brandAPI.selectBrandNumber(
        brand,
      );
      setOrderItemToBeAdded((prev: any) => ({ 
        ...prev, 
        beginBrandPrefix: contractBrandPrefix, 
        beginBrandNumber: data.brandBegin,
        endBrandPrefix: contractBrandPrefix,
        endBrandNumber: data.brandEnd,
        quantity: data.quantity,
      }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: ` Brand Numbers selecting error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSaveOrder = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await apiService.tireOrders.saveOriginalOrder(
        {
          ...orderToBeAdded,
          estimatedDeliveryDate: orderToBeAdded.estimatedDeliveryDate ? transformDate(moment(orderToBeAdded.estimatedDeliveryDate).format('MM/DD/YYYY')) : null,
        },
        [
          {
            ...orderItemToBeAdded,
            requestedDeliveryDate: transformDate(moment(orderItemToBeAdded.requestedDeliveryDate).format('MM/DD/YYYY')),
          }],
        true,
      );
      setState(prev => ({...prev, orderId: data.order.id}));
      setOrderToBeAdded((prev: any) => ({...prev, id: data.order.id}));                                                                                          
      addNotification({
        text: 'Order tire was successfully saved.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Order saving error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSelectTypeCode = (rowSelected: any) => {
    setOrderItemToBeAdded((prev: any) => ({ ...prev, typeCode: rowSelected[0].typeCode }));
    fetchTypeCode(rowSelected[0].typeCode);
    toggleShowTypeCodeModal();
  };

  const handleSelectPlant = (rowSelected: any) => {
    setPlant(rowSelected.plantNo);
    setOrderItemToBeAdded((prev: any) => ({ ...prev, warehouse: rowSelected.id }));
    fetchPlant(rowSelected.plantNo);
    toggleShowPlantModal();
  };

  const handleSelectBrand = (rowSelected: any) => {
    selectBrandNumbers(rowSelected);
    toggleShowBrandModal();
  };

  const typeCodeInputText = (typeCode: string) => {
    if (typeCode.length > 0) {
      getTypeCode(typeCode);
    }
  };

  const getTypeCode = debounce(async (typeCode) => {
    fetchTypeCode(typeCode);
  }, 1000);

  const plantInputText = (plant: string) => {
    if (plant.length > 0) {
      getPlant(plant);
    }
  };

  const getPlant = debounce(async (plant) => {
    fetchPlant(plant);
  }, 1000);

  const onChangeOrderItemField = (field: any, value: any, regExp?: RegExp) =>
    setOrderItemToBeAdded((prev: any) => ({ ...prev, [field]: regExp ? (regExp.test(value) ? value : prev[field]) : value }));

  const onChangeOrderItemAutocompleteField = (field: any, value: any) => {
    setOrderItemToBeAdded((prev: any) => ({ ...prev, [field]: value }));
  };

  const enteredManuallyValidation = (): boolean => {
    return orderToBeAdded.enteredManually ? orderToBeAdded.gdyrId : true;
  };
  
  const gdyrDelNoValidation = (): boolean => {
    return !orderItemToBeAdded.gdyrDelNo || orderItemToBeAdded.gdyrDelNo.match('^\\d{10}$');
  };
  
  const gdyrIdValidation = (): boolean => {
    return !orderToBeAdded.gdyrId || orderToBeAdded.gdyrId.match('^\\d{10}$');
  };

  const saveOrderValidation = async () => {
    if (new Date().toJSON().slice(0,10) > orderItemToBeAdded.requestedDeliveryDate) {
      toggleShowSubmitConfirmation();
    } else {
      handleSaveOrder();
    }
  };

  const changeRequestedDeliveryDate = async () => {
    setOrderItemToBeAdded((prev: any) => ({ ...prev, requestedDeliveryDate: new Date().toJSON().slice(0,10) }));
    handleSaveOrder();
  };

  useEffect(() => {
    if (
      orderItemToBeAdded.typeCode &&
      orderItemToBeAdded.warehouse &&
      orderItemToBeAdded.beginBrandPrefix &&
      orderItemToBeAdded.beginBrandNumber &&
      orderItemToBeAdded.endBrandPrefix &&
      orderItemToBeAdded.endBrandNumber &&
      orderItemToBeAdded.requestedDeliveryDate &&
      orderItemToBeAdded.quantity &&
      gdyrDelNoValidation() &&
      gdyrIdValidation() &&
      enteredManuallyValidation()
    ) {
      enableSaving();
    } else {
      disableSaving();
    }
  }, [orderToBeAdded, orderItemToBeAdded]);
  
  useEffect(() => {
    setState((prev: any) => ({ ...prev, typeCodes: typeCodes, plants: plants }));
    orderItemToBeAdded.requestedDeliveryDate ? null : setOrderItemToBeAdded((prev: any) => ({ ...prev, requestedDeliveryDate: new Date().toJSON().slice(0,10) }));
    fetchPlant();
    fetchTypeCode();
    fetchBatchNumber();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div>
          <div>
            <TextField
              label="GDYR delivery note #"
              maxLength={10}
              value={orderItemToBeAdded.gdyrDelNo}
              onChange={(e, gdyrDelNo: any) => onChangeOrderItemField('gdyrDelNo', gdyrDelNo, /^[0-9]*$/)}
              onGetErrorMessage={gdyrDelNoEMHandler}
            />
          </div>
          <div>
            <AutocompleteInput
              label="Type Code"
              initialValue={orderItemToBeAdded.typeCode}
              list={state.typeCodes.map((typeCode: any) => ({
                key: typeCode.id,
                text: typeCode.typeCode,
              }))}
              emptyExpanded
              required
              chooseCurrentItem={(typeCode: any) => onChangeOrderItemAutocompleteField('typeCode', typeCode)}
              textValue={typeCodeInputText}
              upperCase
            /> 
            <IconButton
              id='searchPrefix'
              onClick={toggleShowTypeCodeModal}
              iconProps={{ iconName: 'Search' }}
            />
          </div>
          <div>
            <Dropdown
              label="Batch"
              defaultSelectedKey={state.batchNumbers.findIndex((value) => value === orderItemToBeAdded.batch)}
              options={state.batchNumbers.map((batchNumber: any, index: number) => ({
                key: index,
                text: batchNumber,
              }))}
              onChange={(e, { text: batch }: any) => onChangeOrderItemField('batch', batch)}
              styles={dropdownStyles}
            />
          </div>
        </div>
        <div>
          <div>
            <TextField
              label="QTY"
              value={orderItemToBeAdded.quantity}
              disabled
            />
          </div>
          <div>
            <AutocompleteInput
              label="Warehouse"
              initialValue={plant}
              list={state.plants.map((plant: any) => ({
                key: plant.id,
                text: plant.plantNo,
              }))}
              emptyExpanded
              required
              chooseCurrentItem={(warehouse: any) => onChangeOrderItemAutocompleteField('warehouse', warehouse)}
              textValue={plantInputText}
              upperCase
            /> 
            <IconButton
              id="searchPrefix"
              onClick={toggleShowPlantModal}
              iconProps={{ iconName: 'Search' }}
            />
          </div>
          <div>
            <TextField  
              id="requestedDeliveryDate"
              label="Rqst Dlv Date"
              type="date"
              onChange={(e, requestedDeliveryDate: any) => onChangeOrderItemField('requestedDeliveryDate', requestedDeliveryDate)}
              value={orderItemToBeAdded.requestedDeliveryDate}
              required
            />
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <TextField
              label="Prefix begin"
              maxLength={4}
              value={orderItemToBeAdded.beginBrandPrefix}
              disabled
            />
          </div>
          <div>
            <TextField
              label="Brand begin"
              maxLength={6}
              value={orderItemToBeAdded.beginBrandNumber}
              disabled
            />
          </div>
          <div>
            <TextField
              label="Suffix begin"
              maxLength={5}
              value={orderItemToBeAdded.beginBrandSuffix}
              disabled
            />
          </div>
          <div className={styles.searchButton}>
            <IconButton
              id='searchPrefix'
              onClick={toggleShowBrandModal}
              iconProps={{ iconName: 'Search' }}
            />
          </div>
        </div>
        <div>
          <div>
            <TextField
              label="Prefix end"
              maxLength={4}
              value={orderItemToBeAdded.endBrandPrefix}
              disabled
            />
          </div>
          <div>
            <TextField
              label="Brand end"
              maxLength={6}
              value={orderItemToBeAdded.endBrandNumber}
              disabled
            />
          </div>
          <div>
            <TextField
              label="Suffix end"
              maxLength={5}
              value={orderItemToBeAdded.endBrandSuffix}
              disabled
            />
          </div>
          <div></div>
        </div>
        <div className={styles.buttonsWrapper}>
          <div>
            <PrimaryButton 
              text="Add" 
              onClick={saveOrderValidation} 
              disabled={contractInformation?.activeStatus === 'NORMAL RUNOUT' || isSaveDisabled}
            />
          </div>
          <div></div>
        </div>
      </div>   
      <BrandModalComponent
        isOpen={showBrandModal}
        onSubmit={handleSelectBrand}
        onDismiss={toggleShowBrandModal}
        brandPrefix={contractBrandPrefix}
      />
      <PlantModalComponent
        id='wareHouseModal'
        title='View Warehouse Details'
        type='WAREHOUSE'
        isOpen={showPlantModal}
        onSubmit={handleSelectPlant}
        onDismiss={toggleShowPlantModal}
      />
      <TypeCodeModalComponent
        id='typeCodeModal'
        title='Type Codes'
        isOpen={showTypeCodeModal}
        onSubmit={handleSelectTypeCode}
        onDismiss={toggleShowTypeCodeModal}
        successLabel='Select Type Code'
        cancelLabel='Cancel'
        originalOrRetread='O'
        isUserDependent
      />
      <Dialog
        hidden={!showSubmitConfirmation}
        onDismiss={toggleShowSubmitConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: 'Do you want to use today as requested delivery date?',
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <DefaultButton onClick={toggleShowSubmitConfirmation} text="No" />
          <PrimaryButton onClick={() => { toggleShowSubmitConfirmation(); changeRequestedDeliveryDate(); }} text="Yes" />
        </DialogFooter>
      </Dialog>
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default NewOrderDetails;