import { FC, ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { get } from 'lodash';
import { DefaultButton, Dropdown, IColumn, IDropdownOption, MessageBarType, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useSelector } from 'react-redux';

import apiService from '../../../../../../api';
import useNotifications from '../../../../../../hooks/useNotifications';
import { auth_mileageRun_viewMileageReport } from '../../../../../../consts/programKeys';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { sortOrder } from '../../../../../../consts/sortOrder';
import { customerSelector, locationSelector } from '../../../../../../redux/recordKeepingSlice';

import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../../Pagination/Pagination';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';

import ViewMileageRunReport from './ViewMileageRunReport/ViewMileageRunReport';
import { IMileageRunReportProps } from './IMileageRunReportProps';
import { columns } from './consts';
import { pageSizes } from '../../../../../../consts/recordKeeping';
import { tireMileageColumns } from './ViewMileageRunReport/tabComponents/tireMileage/consts';
import { vehicleMileageColumns } from './ViewMileageRunReport/tabComponents/vehicleMileage/consts';
import { IMileageRunReportState } from './IMileageRunReportState';

import styles from './MileageRunReport.module.scss';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';

const MileageRunReport: FC<IMileageRunReportProps> = (): ReactElement => {

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);

  const [state, setState] = useState<IMileageRunReportState>({
    items: [],
    selectedItem: null,
    loading: false,
    foundCount: 0,
  });

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });

  const [viewReportTirePaginationProps, setViewReportTirePaginationProps] = useState({
    total: 0,
    current: 1,
  });
  const [reportTireSortOrder, setReportTireSortOrder] = useState({
    column: tireMileageColumns[0].fieldName,
    order: tireMileageColumns[0].isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
  });
  const [reportTireSortOrderCountOnPage, setReportTireSortOrderCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });

  const [viewReportVehiclePaginationProps, setViewReportVehiclePaginationProps] = useState({
    total: 0,
    current: 1,
  });
  const [reportVehicleSortOrder, setReportVehicleSortOrder] = useState({
    column: vehicleMileageColumns[0].fieldName,
    order: vehicleMileageColumns[0].isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
  });
  const [reportVehicleSortOrderCountOnPage, setReportVehicleSortOrderCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });

  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const [billingYearList, setBillingYearList] = useState<Array<any>>([]);
  const [filters, setFilters] = useState<any>({ year: 0 });
  const [isSelectMileageRunExpanded, setIsSelectMileageRunExpanded] = useState(true);
  const [isViewMileageRunReportExpanded, setIsViewMileageRunReportExpanded] = useState(false);

  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);

  const { hasPermission } = useUserPermissions();
  const userPermissionsViewMileageReport = hasPermission(auth_mileageRun_viewMileageReport);

  const handleSelect = (e: any, item: any) => {
    if (e.target.checked) {
      setState((prev: any) => ({ ...prev, selectedItem: item }));
      setIsSelectMileageRunExpanded(false);
      setIsViewMileageRunReportExpanded(true);
    }
  };

  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const collapseSelectMileageRun = () => {
    setIsSelectMileageRunExpanded(!isSelectMileageRunExpanded);
    setIsViewMileageRunReportExpanded(!isViewMileageRunReportExpanded);
  };

  const fetchBillingYearList = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.mileageRun.getBillingYearList();
      setBillingYearList(data.data);
      setFilters((prev: any) => ({ ...prev, year: '' + new Date().getFullYear() }));
    } catch (e: any) {
      addNotification({
        text: `Mileage Run fetching billing period error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchMileageRunList = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.mileageRun.getStartDetails(
        filters,
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
      );
      const foundCount = data.total.found;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      addNotification({
        text: `Mileage Run fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {

      const viewReportTireInput = {
        billPeriodId: state.selectedItem.billPeriodId,
        pagination: { pageNumber: viewReportTirePaginationProps.current, pageSize: +reportTireSortOrderCountOnPage.key },
        sortOrder: reportTireSortOrder,
      };

      const viewReportVehicleInput = {
        billPeriodId: state.selectedItem.billPeriodId,
        pagination: { pageNumber: viewReportVehiclePaginationProps.current, pageSize: +reportVehicleSortOrderCountOnPage.key },
        sortOrder: reportVehicleSortOrder,
      };


      const requestData = {
        viewReportTireInput: viewReportTireInput,
        viewReportVehicleInput: viewReportVehicleInput,
        customerId,
        locationId,
        headerFields: [],
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.mileageRun.printExcelMileageRunReport(requestData) :
        await apiService.mileageRun.printPdfMileageRunReport(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    fetchBillingYearList();
  }, []);

  useEffect(() => {
    if (filters.year) {
      fetchMileageRunList();
    }
  }, [paginationProps.current, countOnPage, columnsState, filters, customerId, locationId]);

  return (
    <div>
      <Text variant="xLarge" className={styles.highlight}>Select Mileage Run</Text>
      <div className={!isSelectMileageRunExpanded ? styles.collapsedSelectMileage : undefined}>
        <div className={styles.tableHeading}>
          <div>
            <Text variant="xLarge" className={styles.highlight}> Mileage Run Log</Text>
            <SeparatorGy vertical />
            <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
          </div>
          <div>
            <Text variant="large" className={styles.highlight}>Year</Text>
            <SeparatorGy vertical />
            <Dropdown
              options={billingYearList.map(item => ({
                key: item.year,
                text: item.year,
              }))}
              defaultSelectedKey={filters.year}
              selectedKey={filters.year}
              onChange={(e, selectedOption: any) => setFilters((prev: any) => ({ ...prev, year: selectedOption.key }))}
              styles={{ dropdown: { width: 80 } }}
            />
          </div>
          <div>
            <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
            <Dropdown
              options={pageSizes.map(pageSize => ({
                key: pageSize,
                text: pageSize.toString(),
              }))}
              defaultSelectedKey={pageSizes[0]}
              selectedKey={countOnPage?.key}
              onChange={onChangeCountOnPage}
            />
          </div>
        </div>
        <div className={styles['table-wrapper']}>
          <table className={styles.table}>
            <thead>
              <tr>
                {
                  columnsState.map(item => (
                    <th
                      key={item.name}
                      className={classNames(
                        item.isSorted && item.isSortedDescending ?
                          styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined,
                        styles[item.key],
                      )}
                      onClick={() => onColumnClick(item)}>{item.name}
                    </th>
                  ))
                }
                <th></th>
              </tr>
            </thead>
            <tbody>
              {state.items.map(item => (
                <tr key={item.id} className={classNames(get(state.selectedItem, 'id') === item.id ? styles.trSelected : styles.trBasic)} >
                  <td>{item.fromBillPeriod ? moment(item.fromBillPeriod).format('MM/DD/YYYY') : ''}</td>
                  <td>{item.toBillPeriod ? moment(item.toBillPeriod).format('MM/DD/YYYY') : ''}</td>
                  <td>{item.startMode}</td>
                  <td>{item.startedOn ? moment(item.startedOn).format('MM/DD/YYYY h:mm:ss A') : ''}</td>
                  <td>{item.status}</td>
                  <td>{item.completedStatus}</td>
                  <td>{item.completedOn ? moment(item.completedOn).format('MM/DD/YYYY h:mm:ss A') : ''}</td>
                  <td>{item.billType}</td>
                  <td>
                    <div className={styles.round}>
                      <input
                        type="checkbox"
                        id={`postedSpareStock-${item.id}`}
                        checked={get(state.selectedItem, 'id') === item.id}
                        onChange={(e) => handleSelect(e, item)} />
                      <label htmlFor={`postedSpareStock-${item.id}`}></label>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <SeparatorGy />
        <Pagination {...paginationProps} />
      </div>
      <SeparatorGy alignContent="end">
        <DefaultButton id="collapseMileageRunLog" onClick={collapseSelectMileageRun} >
          {isSelectMileageRunExpanded ? 'Collapse' : 'Expand'}
        </DefaultButton>
      </SeparatorGy>
      {state.selectedItem &&
        <div>
          <Text variant="xLarge" className={styles.highlight}>View Mileage Run Report</Text>
          <div className={classNames(!isViewMileageRunReportExpanded ? styles.collapsedViewMileageReport : undefined, styles.viewMileageRunReport)}>
            <ViewMileageRunReport 
              mileageRunItem={state.selectedItem} 
              setViewReportTirePaginationProps={setViewReportTirePaginationProps}
              setReportTireSortOrder={setReportTireSortOrder}
              setReportTireSortOrderCountOnPage={setReportTireSortOrderCountOnPage}
              setViewReportVehiclePaginationProps={setViewReportVehiclePaginationProps}
              setReportVehicleSortOrder={setReportVehicleSortOrder}
              setReportVehicleSortOrderCountOnPage={setReportVehicleSortOrderCountOnPage}
            />
          </div>
          <SeparatorGy alignContent="end">
            <DefaultButton id="collapseMileageRunReport" onClick={collapseSelectMileageRun} >
              {isViewMileageRunReportExpanded ? 'Collapse' : 'Expand'}
            </DefaultButton>
          </SeparatorGy>
        </div>
      }
      <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
        <DefaultButton
          disabled={!state.selectedItem || !userPermissionsViewMileageReport.isRead}
          onClick={toggleShowPrintExport}
          text="Print/Export"
        />
      </div>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint} />
      {state.loading && <LoadingScreen />}
    </div>
  );
};

export default MileageRunReport;