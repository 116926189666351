import { Checkbox, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IColumn, IconButton, IDropdownOption, MessageBarType, PrimaryButton, Text } from '@fluentui/react';
import { FC, FormEvent, ReactElement, useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';
import { IViewPostedSpareStockProps } from './IViewPostedSpareStockProps';
import { columns, locationsColumns } from './consts';
import { pageSizes } from '../../../../../../consts/recordKeeping';
import { IPostedSpareStockState } from './IPostedSpareStockState';
import { sortOrder } from '../../../../../../consts/sortOrder';
import apiService from '../../../../../../api';
import { useSelector } from 'react-redux';
import { customerSelector, locationSelector, customerInformationSelector } from '../../../../../../redux/recordKeepingSlice';
import useNotifications from '../../../../../../hooks/useNotifications';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import styles from './ViewPostedSpareStock.module.scss';
import classNames from 'classnames';
import Pagination from '../../../../../Pagination/Pagination';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import SelectingModal from '../../../../../SelectingModal/SelectingModal';
import { get } from 'lodash';
import AutocompleteInput from '../../../../../../shared/AutocompleteInput';
import moment from 'moment';
import ReconciliationReport from './ReconciliationReport/ReconciliationReport';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import PostSpareStockInventory from './PostSpareStockInventory/PostSpareStockInventory';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_spareStock_viewPostedSpareStock} from '../../../../../../consts/programKeys';

const ViewPostedSpareStock: FC<IViewPostedSpareStockProps> = (): ReactElement => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_spareStock_viewPostedSpareStock);

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);
  const { lastAnnualDone } = useSelector(customerInformationSelector);


  const [state, setState] = useState<IPostedSpareStockState>({
    items: [],
    selectedItems: [],
    loading: false,
    foundCount: 0,
  });

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [postedElement, setPostedElement] = useState<any>({ viewAnnualOnly: false, location: null, monthYear: '' });
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const [filters, setFilters] = useState<any>({ viewAnnualOnly: false, location: null });
  const [locations, setLocations] = useState<Array<any>>([]);
  const [period, setPeriod] = useState<string>('');
  const [selectedLocations, setSelectedLocations] = useState<Array<any>>([]);

  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [showReconciliationReport, { toggle: toggleShowReconciliationReport }] = useBoolean(false);
  const [showPostSpareStockInventory, { toggle: toggleShowPostSpareStockInventory }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [showLocationsModal, { toggle: toggleShowLocationsModal }] = useBoolean(false);
  const [inventoryTakenOn, setInventoryTakenOn] = useState<string>('');
  const [annual, setAnnual] = useState<string>('');

  const handleSelect = (e: any, selectedItem: any) => {
    const selectedRows: any[] = [];
    setPeriod(moment(selectedItem.invtTakenOn).format('MM/DD/YYYY'));
    if (e.target.checked) {
      setInventoryTakenOn(moment(selectedItem.invtTakenOn).format('MM/DD/YYYY'));
      setSelectedLocations([selectedItem.locationId]);
      setAnnual(selectedItem.annual);
      selectedRows.push(selectedItem.id);
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows.filter(row => row !== selectedItem.id) }));
    }
  };

  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const onChangeLocation = (location: any) => setFilters((prev: any) => ({ ...prev, location: locations.find(({ locationCode, addrLine1 }) => 
    locationCode === location || addrLine1 === location) || null }));

  const setNewSelectedLocation = (locationId: any) => {
    setFilters((prev: any) => ({ ...prev, location: locations.find(({ id }) => id === locationId) }));
    toggleShowLocationsModal();
  };

  const getLocationAutocompleteList = () => {
    const locationList = locations.map(({ locationCode, addrLine1 }: any) => ({ key: locationCode, text: `${locationCode} | ${addrLine1}` }));
    if(filters.location) {
      locationList.unshift({key: 'all', text: 'All'});
    }
    return locationList;
  };

  const setViewAnnualOnly = (ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => 
    setFilters((prev: any) => ({ ...prev, viewAnnualOnly: checked }));

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const openReconciliationReport = (el: any) => {
    setPostedElement(state.items.find((item: any) => item.id === el));
    toggleShowReconciliationReport();
  };
  
  const openPostSpareStockInventory = (el: any) => {
    setPostedElement(state.items.find((item: any) => item.id === el));
    toggleShowPostSpareStockInventory();
  };

  const closePostSpareStockInventory = async () => {
    try {
      toggleShowPostSpareStockInventory();
      await fetchPostedSpareStocks();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Posted Spare Stocks fetching error: ${response?.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, selectedItems: state.selectedItems }));
    }
  };

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.spareStock.deletePostedSpareStocks(state.selectedItems);
      addNotification({
        text: 'Selected item(s) were successfully deleted.',
        type: MessageBarType.success,
      });
      if(paginationProps.current === 1) {
        await fetchPostedSpareStocks();
      }
      else {
        await paginationProps.onChangePage(1);
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Posted Spare Stock deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
    finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchPostedSpareStocks = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.spareStock.getPostedSpareStockList(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        {...filters, location: get(filters.location, 'locationCode')},
        sortOrder,
        customerId,
        null,
      );
      const foundCount = data.total.found;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      setSelectedLocations([]);
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Posted Spare Stocks fetching error: ${response?.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchLocations = async () => {
    try {
      const { data }: any = await apiService.getLocationsByCustomerId(
        customerId,
        { pageSize: 5000, pageNumber: 1 },
        { column: 'locationCode', order: 'asc'},
      );
      setLocations(data.data);
    } catch (e: any) {
      setState((prev: any) => ({ ...prev, loading: false }));
      const { response } = e;
      addNotification({
        text: `Locations fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        filters: {...filters, location: get(filters.location, 'locationCode')},
        sortOrder,
        customerId,
        locationId,
      };
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.spareStock.printExcelViewPostedSpareStock(requestData, []) :
        await apiService.spareStock.printPdfViewPostedSpareStock(requestData, []);
      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Printing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  // const validationOfSelectedReports = (filters.location && state.selectedItems.length == 1) || 
  //   (!filters.location && 
  //     selectedLocations.length <= locations.length && 
  //     selectedLocations.length > 0 && 
  //     new Set(selectedLocations).size == selectedLocations.length);

  const validationOfSelectedReports = state.selectedItems.length == 1;

  const isAnnual = (): boolean => {
    for(const selectedItem of state.selectedItems) {
      for(const item of state.items) {
        if (item.id == selectedItem && item.annual == 'Y') {
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    fetchPostedSpareStocks();
  }, [paginationProps.current, countOnPage, columnsState, filters, customerId, locationId]);

  useEffect(() => {
    fetchLocations();
  }, [customerId, locationId]);

  return (
    <div>
      <div>
        <div className={styles.filtersBlock}>
          <div className={styles.autocompleteWithTableBlock}>
            <AutocompleteInput
              label="*Location"
              prefixIcon="POISolid"
              value={get(filters, 'location.locationCode', null)}
              list={getLocationAutocompleteList()}
              chooseCurrentItem={onChangeLocation}
              emptyExpanded
            />
            <IconButton
              id="searchLocations"
              iconProps={{ iconName: 'Search' }}
              onClick={toggleShowLocationsModal}
            />
          </div>
          <Checkbox
            label="View Annual Only"
            checked={filters.viewAnnualOnly} 
            onChange={setViewAnnualOnly}
          />
          <div className={styles.annualAsInfo}>
            <IconButton
              id="refreshButton"
              iconProps={{ iconName: 'Refresh' }} 
              onClick={fetchPostedSpareStocks} 
            />
            <SeparatorGy vertical/>
            <Text className={classNames(styles.annualAsLabel,styles.highlight)} variant="medium">
            Annual as of: <br />
              {moment(lastAnnualDone).format('MM/DD/YYYY')}
            </Text>
          </div>
        </div>
        <div className={styles.tableHeading}>
          <div>
            <Text variant="xLarge" className={styles.highlight}>Available Posted Spare Stock</Text>
            <SeparatorGy vertical />
            <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
          </div>
          <div>
            <Text variant="large" className={styles.highlight}> Show # of rows:&nbsp;</Text>
            <Dropdown
              options={pageSizes.map(pageSize => ({
                key: pageSize,
                text: pageSize.toString(),
              }))}
              defaultSelectedKey={pageSizes[0]}
              selectedKey={countOnPage?.key}
              onChange={onChangeCountOnPage}
            />
            <SeparatorGy vertical />
            <Text variant="large" className={styles.highlight}>{state.selectedItems.length} items selected</Text>
            <SeparatorGy vertical />
            <IconButton
              id="toggleDeletingConfirmationButton"
              disabled={!state.selectedItems.length}
              iconProps={{ iconName: 'Delete' }}
              onClick={toggleDeletingConfirmation || !userPermissions.isWrite}
            />
          </div>
        </div>
        <div className={styles['table-wrapper']}>
          <table className={styles.postedSpareStockTable}>
            <thead>
              <tr>
                {
                  columnsState.map(item => (
                    <th
                      key={item.name}
                      className={classNames(
                        item.isSorted && item.isSortedDescending ?
                          styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined,
                        styles[item.key],
                      )}
                      onClick={() => onColumnClick(item)}>{item.name}
                    </th>
                  ))
                }
                <th className={styles.selectColumn}>
                  <div className={styles.round}>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {state.items.map(item => (
                <tr key={item.id} className={classNames(state.selectedItems.includes(item.id) ? styles.trSelected : styles.trBasic)} >
                  <td>{item.locationCode}</td>
                  <td>{item.annual}</td>
                  <td>{moment(item.invtTakenOn).format('MM/DD/YYYY')}</td>
                  <td>{item.invtTakenBy}</td>
                  <td>{item.sysInv}</td>
                  <td>{item.matched}</td>
                  <td>{item.errors}</td>
                  <td>{item.reconciled}</td>
                  <td>{item.lost}</td>
                  <td>
                    <div className={styles.round}>
                      <input
                        type="checkbox"
                        id={`postedSpareStock-${item.id}`}
                        checked={state.selectedItems.includes(item.id)}
                        onChange={(e) => handleSelect(e, item)} />
                      <label htmlFor={`postedSpareStock-${item.id}`}></label>
                    </div>
                  </td>
                </tr> 
              ))}
            </tbody>
          </table>
        </div>
        <SeparatorGy />
        <Pagination {...paginationProps} />
        <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
          <DefaultButton id="printButton" onClick={toggleShowPrintExport} text="Print/Export" />
          <PrimaryButton
            id="reconciliationReportButton" 
            text="View Reconciliation Report" 
            onClick={() => openReconciliationReport(state.selectedItems[0])}
            disabled={!validationOfSelectedReports}
          />
          <PrimaryButton
            id="postButton" 
            onClick={() => openPostSpareStockInventory(state.selectedItems[0])} 
            text="Post" 
            disabled={!period || state.selectedItems.length != 1 || !userPermissions.isWrite}
          />
        </div>
      </div>
      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to delete (${state.selectedItems.length}) items?`,
        }}
        modalProps={{ isBlocking: true }}>
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <SelectingModal
        isOpen={showLocationsModal}
        title="Available Locations"
        selectingList={locations}
        onDismiss={toggleShowLocationsModal}
        onSubmit={setNewSelectedLocation}
        columns={locationsColumns}
        preselectedKey={get(filters, 'location.id', null)}
      />
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint} 
      />
      {showReconciliationReport && <ReconciliationReport onDismiss={toggleShowReconciliationReport} selectedLocations={selectedLocations} period={period} isAnnual={isAnnual()} postedElement={postedElement}/>}
      {showPostSpareStockInventory && <PostSpareStockInventory postedElement={postedElement} selectedItems={state.selectedItems[0]} selectedLocations={selectedLocations} annual={annual} period={period} isAnnual={isAnnual()} inventoryTakenOn={inventoryTakenOn} onDismiss={closePostSpareStockInventory} />}
      {state.loading && <LoadingScreen />}
    </div>
  );
};

export default ViewPostedSpareStock;