import { exportTestInstance } from '../axios';
import {recordTestInstance} from '../axios';

const getSpareStockPostingHistory = (pagination: any, sortOrder: any, filters: any, prefix: string, brandno: string, suffix: string) =>
  recordTestInstance.post('/sparestock-posting-history/list', { pagination, sortOrder, filters, prefix, brandno, suffix });

const printSpareStockPostingHistoryExcel = (input: any) =>
  exportTestInstance.post('/sparestock-posting-history/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printSpareStockPostingHistoryPdf = (input: any) =>
  exportTestInstance.post('/sparestock-posting-history/export-pdf', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  getSpareStockPostingHistory,
  printSpareStockPostingHistoryExcel,
  printSpareStockPostingHistoryPdf,
};
  