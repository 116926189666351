import { HeadCell } from '../../../../shared/DataGridComponent/DataGridModels';
import { IFormDataField } from '../../../../shared/FormBuilderComponent';

export interface MakeModelMaintainProps {
  value?: string;
}

export interface IMake {
  id: string,
  make: string,
  description?: string,
};

export interface IModel {
  id: string,
  makeId: string,
  model: string,
  description?: string,
};

export const makeTableHeadCells: HeadCell[] = [
  {
    key: 'column0',
    typeField: 'text',
    isEditable: false,
    name: 'Vehicle Make',
    fieldName: 'make',
  },
  {
    key: 'column1',
    typeField: 'text',
    isEditable: false,
    name: 'Description',
    fieldName: 'description',
  },
];

export const modelTableHeadCells: HeadCell[] = [
  {
    key: 'column0',
    typeField: 'text',
    isEditable: false,
    name: 'Vehicle Model',
    fieldName: 'model',
  },
  {
    key: 'column1',
    typeField: 'text',
    isEditable: false,
    name: 'Description',
    fieldName: 'description',
  },
];

export const MAKE_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'makeIdField',
    properties: {
      id: 'makeId',
      label: 'Make Id',
      name: 'makeId',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'makeField',
    properties: {
      id: 'make',
      label: 'Make',
      name: 'make',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'descriptionField',
    properties: {
      id: 'description',
      label: 'Description',
      name: 'description',
      type: 'text',
      disabled: false,
    },
  },
];

export const MODEL_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'idField',
    properties: {
      id: 'id',
      label: 'Id',
      name: 'id',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'makeIdField',
    properties: {
      id: 'makeId',
      label: 'Make Id',
      name: 'makeId',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'modelField',
    properties: {
      id: 'model',
      label: 'Model',
      name: 'model',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'descriptionField',
    properties: {
      id: 'description',
      label: 'Description',
      name: 'description',
      type: 'text',
      disabled: false,
    },
  },
];