import React, { FC, useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IColumn, IconButton, IDropdownOption, MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import { get, isNil } from 'lodash';

import apiService from '../../../../../api';
import useNotifications from '../../../../../hooks/useNotifications';
import { sortOrder } from '../../../../../consts/sortOrder';

import LoadingScreen from '../../../../LoadingScreen/LoadingScreen';
import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../Pagination/Pagination';
import { IPaginationProps } from '../../../../Pagination/IPaginationProps';

import ShipToEditingModal from './components/ShipToEditingModal/ShipToEditingModal';
import { IShipToProps } from './IShipToProps';
import { IShipToState } from './IShipToState';
import { columns, pageSizes } from './consts';

import styles from './ShipTo.module.scss';

import { useUserPermissions } from '../../../../../hooks/useUserPermissions';
import { auth_customer_shipTo} from '../../../../../consts/programKeys';

const ShipTo: FC<IShipToProps> = () => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_customer_shipTo);

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const [state, setState] = useState<IShipToState>({
    items: [],
    selectedItems: [],
    searchString: '',
    foundCount: 0,
    loading: false,
  });
  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [editingModalState, setEditingModalState] = useState<any>({ show: false, item: null });

  const [showDeletingConfirmation, { toggle: toggleDeletingConfirmation }] = useBoolean(false);

  const { id } = useParams<{ id: string }>();
  const { addNotification } = useNotifications();

  const getSortOrder = () => {
    const column = columnsState.find(({ isSorted }) => isSorted);
    if (isNil(column))
      return {
        column: columns[0].fieldName,
        order: sortOrder.DESC,
      };
    const { fieldName, isSortedDescending } = column;
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const fetch = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.shipToAPI.getShipTo(
        id,
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder.column ? {
          column: sortOrder.column,
          order: sortOrder.order,
        } : undefined,
        { searchString: state.searchString },
      );
      const items = data.data;
      const foundCount = data.total.found;
      setState((prev: any) => ({
        ...prev,
        items,
        foundCount,
        selectedItems: [],
      }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Location fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleChangeSearchString = (event: any, searchString = '') => {
    setState((prev: any) => ({ ...prev, searchString }));
  };

  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const handleSelect = (e: any, itemId: any) => {
    const selectedRows = [...state.selectedItems];
    if (e.target.checked) {
      selectedRows.push(itemId);
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows.filter(row => row !== itemId) }));
    }
  };

  const handleDelete = async () => {
    toggleDeletingConfirmation();
    setState(prev => ({ ...prev, loading: true }));
    try {
      await apiService.shipToAPI.deleteRecord(
        id, 
        state.selectedItems,
      );
      addNotification({
        text: 'Selected tire(s) were successfully deleted.',
        type: MessageBarType.success,
      });
      await paginationProps.onChangePage(1);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Ship To deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      await fetch();
    }
  };

  const handleClose = async (fclosedout: string) => {
    try {
      setState(prev => ({ ...prev, loading: true }));

      await apiService.shipToAPI.closeRecords(state.selectedItems, fclosedout);

      addNotification({
        text: `Ship To item(s) was successfully ${fclosedout === 'Y' ? 'closed' : 'unclosed'}.`,
        type: MessageBarType.success,
      });
      fetch();
    } catch (e: any) {
      const { response: { data } } = e;
      const { message } = data;

      addNotification({
        text: `Ship To closing error: ${message}`,
        type: MessageBarType.error,
      });
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSearch = () => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    fetch();
  };

  useEffect(() => {
    if (!editingModalState.show)
      fetch();
  }, [
    paginationProps.current,
    editingModalState.show,
    countOnPage,
    columnsState,
  ]);

  return (
    <>
      <div className="ms-Grid margin-left-rigth-2 marginTop18" dir='ltr'>
        <div className={classNames('ms-Grid-row', styles.marginRow)}>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.tableHeading)}>
            <div>
              <TextField
                id="searchString"
                value={state.searchString}
                placeholder="Enter search string"
                onChange={handleChangeSearchString}
              />
              <IconButton
                id="searchShipTo"
                iconProps={{ iconName: 'Search' }}
                onClick={handleSearch}
              />
              <SeparatorGy vertical />
              <Text variant="xLarge">&nbsp;{state.foundCount} found</Text>
            </div>
            <div>
              <Text variant="large">Show # of rows:&nbsp;</Text>
              <Dropdown
                options={pageSizes.map(pageSize => ({
                  key: pageSize,
                  text: pageSize.toString(),
                }))}
                selectedKey={countOnPage?.key}
                onChange={onChangeCountOnPage}
              />
              <SeparatorGy vertical />
              <Text variant="large" className={styles.highlight}>{state.selectedItems.length} Selected</Text>
              <SeparatorGy vertical />
              <IconButton
                id="toggleDeletingConfirmationButton"
                disabled={!state.selectedItems.length}
                iconProps={{ iconName: 'Delete' }}
                onClick={toggleDeletingConfirmation}
              />
            </div>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <table>
              <thead>
                <tr>
                  <th />
                  {columnsState.map(item => (
                    <th
                      key={item.name}
                      className={classNames(
                        item.isSorted && item.isSortedDescending ?
                          styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined,
                        styles[item.key],
                      )}
                      onClick={() => onColumnClick(item)}>{item.name}
                    </th>
                  ))}
                  <th />
                </tr>
              </thead>
              <tbody>
                {state.items.map((item: any) => (
                  <tr
                    key={item.locationId}
                    className={state.selectedItems.includes(item.locationId) ? styles.trSelected : styles.trBasic}
                  >
                    <td>
                      <IconButton
                        id="editButton"
                        iconProps={{ iconName: 'Edit' }}
                        className={classNames(styles.viewButton)}
                        onClick={() => setEditingModalState({ show: true, item })}
                        disabled={!userPermissions.isWrite} 
                      />
                    </td>
                    {columnsState.map(({ fieldName }) => (
                      <td>{item[fieldName]}</td>
                    ))}
                    <td>
                      <div className={styles.round}>
                        <input
                          type="checkbox"
                          id={`shipTo-listing-${item.locationId}`}
                          checked={state.selectedItems.includes(item.locationId)}
                          onChange={(e) => handleSelect(e, item.locationId)} />
                        <label htmlFor={`shipTo-listing-${item.locationId}`}></label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {state.foundCount > countOnPage.key && (
              <>
                <SeparatorGy />
                <Pagination {...paginationProps} />
              </>
            )}
          </div>
        </div>
        <div className={classNames('ms-Grid-row', styles.marginRow)}>
          <div className={classNames(styles.buttonsGroup, 'ms-Grid-col', 'ms-sm12')}>
            <DefaultButton
              id="closingButton"
              text="Close"
              disabled={
                state.selectedItems.length === 0 ||
                state.selectedItems.some((selectedLocationId) => get(state.items.find(({ locationId }) => locationId === selectedLocationId), 'fClosedOut') === 'Y'||
                !userPermissions.isWrite)
              }
              onClick={() => handleClose('Y')}
            />
            <DefaultButton
              id="unclosingButton"
              text="Unclose"
              disabled={
                state.selectedItems.length === 0 ||
                state.selectedItems.some((selectedLocationId) => get(state.items.find(({ locationId }) => locationId === selectedLocationId), 'fClosedOut') === 'N'||
                !userPermissions.isWrite)
              }
              onClick={() => handleClose('N')}
            />
            <PrimaryButton
              id="addingButton"
              text="Add"
              onClick={() => setEditingModalState({ show: true, item: null })}
              disabled={!userPermissions.isWrite}
            />
          </div>
        </div>
      </div>
      <ShipToEditingModal
        isModalOpen={editingModalState.show}
        hideModal={() => setEditingModalState({ show: false, item: null })}
        itemInfo={editingModalState.item}
        customerId={id}
      />
      <Dialog
        hidden={!showDeletingConfirmation}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to delete ${state.selectedItems.length} items?`,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default ShipTo;