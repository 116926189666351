import { createSlice } from '@reduxjs/toolkit';

import { IUser } from '../../components/RecordKeeping/IUser';

const initialState: {
  user: IUser | null,
} = {
  user: {
    displayName: 'Display name',
    email: 'user@email.gy',
    roles: 'Role',
    avatar: 'Picture',
    initial: 'GY',
    jobtitle: 'Developer',
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const {
  setUser,
} = userSlice.actions;

export const userSelector = (state: any) => state.user.user;

export default userSlice.reducer;
