import { FunctionComponent } from 'react';
import AddComponent from './tabComponents/Add';
import TransferComponent from './tabComponents/Transfer';
import RecordShortageInShipment from './tabComponents/RecordShortageInShipment/RecordShortageInShipment';
import UpdateWithDOTComponent from './tabComponents/UpdateWithDOT';
import ViewShortInShipmentTires from './tabComponents/ViewShortInShipmentTires/ViewShortInShipmentTires';
import UpdateWithTRR from './tabComponents/UpdateWithTRR/UpdateWithTRR';
import { auth_inTransitTires_add, auth_inTransitTires_recordShortageInShipment, auth_inTransitTires_transfer, auth_inTransitTires_updateWithDOT, auth_inTransitTires_updateWithTRR, auth_inTransitTires_viewShortInShipmentTires } from '../../../../consts/programKeys';

export const intransitPageSizes = [100, 250, 500];

/** Each tab into left menu must have a component */
export const inTransitTiresTabs: IInTransitTireTabs = {
  transfer: {
    id: 'transfer',
    accessKey: auth_inTransitTires_transfer,
    name: 'Transfer',
    component: TransferComponent,
  },
  add: {
    id: 'add',
    accessKey: auth_inTransitTires_add,
    name: 'Add',
    component: AddComponent,
  },
  updateWithDOT: {
    id: 'updateWithDOT',
    accessKey: auth_inTransitTires_updateWithDOT,
    name: 'Update with DOT #',
    component: UpdateWithDOTComponent,
  },
  updateWithTRR: {
    id: 'updateWithTRR',
    accessKey: auth_inTransitTires_updateWithTRR,
    name: 'Update with TRR',
    component: UpdateWithTRR,
  },
  recordShortageInShipment: {
    id: 'recordShortageInShipment',
    accessKey: auth_inTransitTires_recordShortageInShipment,
    name: 'Record Shortage In Shipment',
    component: RecordShortageInShipment,
  },
  viewShortageInShipmentTires: {
    id: 'ViewShortInShimpment',
    accessKey: auth_inTransitTires_viewShortInShipmentTires,
    name: 'View Short in Shipment In-Transit Tires',
    component: ViewShortInShipmentTires,
  },
};


export interface IInTransitTireTabs {
  transfer: TabComponent;
  add: TabComponent;
  updateWithDOT: TabComponent;
  updateWithTRR: TabComponent;
  recordShortageInShipment: TabComponent;
  viewShortageInShipmentTires: TabComponent
}

export interface TabComponent {
  id: string,
  accessKey: string,
  name: string,
  component: FunctionComponent<any> | null
}
