export const HISTORY_FIELDS = {
  commentsDataField : {
    id: 'commentsField',
    name: 'comments',
    type: 'textArea',
  },
  createdByDataField : {
    id: 'createdByField',
    label: 'Created By',
    name: 'createdBy',
    type: 'text',
    disabled: true,
  },
  createdOnDataField : {
    id: 'createdOnField',
    label: 'Created On',
    name: 'createdOn',
    type: 'text',
    disabled: true,
  },
  lastUpdatedNameDataField : {
    id: 'lastUpdatedNameField',
    label: 'Update By',
    name: 'lastUpdatedName',
    type: 'text',
    disabled: true,
  },
  lastUpdatedDateDataField : {
    id: 'lastUpdatedDateField',
    label: 'Updated On',
    name: 'lastUpdatedDateField',
    type: 'text',
    disabled: true,
  },
};