import React, { useRef, useState } from 'react';
import { IconButton, IDropdownOption, Dropdown, MessageBarType, TextField, IDropdownStyles, Text } from '@fluentui/react';
import apiService from '../../../../api';
import useNotifications from '../../../../hooks/useNotifications';
import { useDispatch } from 'react-redux';
import { toggleReloadSetBillingDataTab } from '../../../../redux/billingSlice';
import moment from 'moment';
import { applicableOptions, genOptOptions, billByOptions, billToOptions } from './const';

function EditManageBillingCodes({ row, index }: any) {
  const [selectedApplicable, setSelectedApplicable] = React.useState<IDropdownOption>();
  const [selectedRegenerate, setSelectedRegenerate] = React.useState<IDropdownOption>();
  const [selectedBillBy, setSelectedBillBy] = React.useState<IDropdownOption>();
  const [selectedBillTo, setSelectedBillTo] = React.useState<IDropdownOption>();
  const [showEditButton, setShowEditButton] = useState(true);
  const [showConfirmActions, setShowConfirmActions] = useState(false);
  const [billingOption] = useState(row.billingOption);
  const [genOpt, setGenOpt] = useState<IDropdownOption>();
  const [showRow, setShowRow] = useState(false);
  const { addNotification } = useNotifications();
  const dispatch = useDispatch();
  const iconsRef = useRef<any>(null);
  const table = document.getElementById('billingOptions');
  const tdWidth = table?.getElementsByTagName('tbody')[0]?.getElementsByTagName('tr')[index]?.getElementsByTagName('td')[0].offsetWidth || 99;
  const tdHeight = table?.getElementsByTagName('tbody')[0]?.getElementsByTagName('tr')[index]?.getElementsByTagName('td')[0].offsetHeight || 60;


  const updateBillingOptions = async () => {
    try {
      await apiService.billingAPI.updateBillingOptionsList({
        billingOptionId: row.billingOptionId,
        locationId: row.locationId,
        billBy: selectedBillBy?.text,
        billTo: selectedBillTo?.text,
        applicable: selectedApplicable?.text === 'Y' ? true : false,
        regenerate: selectedRegenerate?.text === 'Y' ? true : false,
        holdBilling: true,
        genOpt: genOpt?.text,
      });
      dispatch(toggleReloadSetBillingDataTab());
      addNotification({
        text: 'Billing-conditions update success',
        type: MessageBarType.success,
      });
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Billing Options data updating error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const onSelectedApplicableChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined): void => {
    setSelectedApplicable(option);
  };
  const onSelectedBillByChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined): void => {
    setSelectedBillBy(option);
  };
  const onSelectedBillToChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined): void => {
    setSelectedBillTo(option);
  };
  const onSelectedRegenerateChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined): void => {
    setSelectedRegenerate(option);
  };
  const onSelectedGenOptChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined): void => {
    setGenOpt(option);
  };
  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: tdWidth },
  };
  const dropdownLastStyles: Partial<IDropdownStyles> = {
    dropdown: { width: tdWidth },
  };
  return (
    <div
      ref={iconsRef}
      style={{
        position: 'relative',
        display: 'flex',
      }}>
      {
        showEditButton && <IconButton
          id='viewButton'
          iconProps={{ iconName: 'Edit' }}
          onClick={() => {
            setShowEditButton(false);
            setShowConfirmActions(true);
            setShowRow(true);
          }}
        />
      }
      {
        showConfirmActions && (
          <div>
            <IconButton
              iconProps={{ iconName: 'CheckMark' }}
              onClick={() => {
                setShowEditButton(true);
                setShowConfirmActions(false);
                setShowRow(false);
                updateBillingOptions();
              }}
            />
            <IconButton
              iconProps={{ iconName: 'StatusCircleBlock' }}
              onClick={() => {
                setShowEditButton(true);
                setShowConfirmActions(false);
                setShowRow(false);
              }}
            />
          </div>
        )
      }
      {

        showRow && (
          <div style={{
            position: 'absolute',
            background: 'white',
            left: (tdWidth - 10) + 'px',
            top: ((((iconsRef?.current?.clientHeight) - (tdHeight)) / 2) + 1) + 'px',
            height: (tdHeight - 2) + 'px',
            width: ((table?.clientWidth || 1214)) - ((tdWidth + 10) || 99) + 'px',
            zIndex: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '5px',
            paddingRight: '5px',
          }}>
            <Text>
              {billingOption}
            </Text>
            <Dropdown
              selectedKey={selectedApplicable ? selectedApplicable.key : undefined}
              onChange={onSelectedApplicableChange}
              placeholder="Select an option"
              options={applicableOptions}
              styles={dropdownStyles}
            />
            <Dropdown
              selectedKey={selectedBillBy ? selectedBillBy.key : undefined}
              onChange={onSelectedBillByChange}
              placeholder="Select an option"
              options={billByOptions}
              styles={dropdownStyles}
            />
            <Dropdown
              selectedKey={selectedBillTo ? selectedBillTo.key : undefined}
              onChange={onSelectedBillToChange}
              placeholder="Select an option"
              options={billToOptions}
              styles={dropdownStyles}
            />
            <Dropdown
              selectedKey={genOpt ? genOpt.key : undefined}
              onChange={onSelectedGenOptChange}
              placeholder="Select an option"
              options={genOptOptions}
              styles={dropdownStyles}
            />
            <TextField
              disabled
              style={{
                width: tdWidth + 'px',
              }}
              value={row.locationId} />
            <Dropdown
              selectedKey={selectedRegenerate ? selectedRegenerate.key : undefined}
              onChange={onSelectedRegenerateChange}
              placeholder="Select an option"
              options={applicableOptions}
              styles={dropdownLastStyles}
            />
            <div
              style={{
                paddingLeft: '8px',
                width: tdWidth + 'px',
              }} >
              {row.lastModifiedBy}
            </div>
            <div
              style={{
                width: tdWidth + 'px',
              }} >
              {moment(row.lastModifiedOn).format('MM/DD/YYYY')}
            </div>
          </div>
        )
      }
    </div>
  );
};

export default EditManageBillingCodes;
