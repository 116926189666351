import { HeadCell } from '../../../../../../shared/DataGridComponent/DataGridModels';

export const columns: HeadCell[] = [
  {
    key: 'column0',
    name: 'Begin Pfx',
    fieldName: 'bpfx',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Begin Bno',
    fieldName: 'bno',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Begin Sfx',
    fieldName: 'sfx',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Created By',
    fieldName: 'createdby',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'Created On',
    fieldName: 'createdon',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column5',
    name: 'Last Modified By',
    fieldName: 'lastmodifiedby',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column6',
    name: 'Last Modified On',
    fieldName: 'lastmodifiedon',
    typeField: 'text',
    isEditable: false,
  },
];

export const mockMoveToInIntransitTires = {
  total: {
    'all': 7,
    'found': 7,
  },
  data: [
    {
      'id': 0,
      'consignedtireId': 'string',
      'customerId': 'string',
      'userId': 'string',
      'bpfx': 'string',
      'bno': 0,
      'bsfx': 'string',
      'createdby': 'string',
      'createdon': '2022-05-24T16:37:07.328Z',
      'lastmodifiedby': 'string',
      'lastmodifiedon': '2022-05-24T16:37:07.328Z',
    },
    {
      'id': 0,
      'consignedtireId': 'string',
      'customerId': 'string',
      'userId': 'string',
      'bpfx': 'string',
      'bno': 0,
      'bsfx': 'string',
      'createdby': 'string',
      'createdon': '2022-05-24T16:37:07.328Z',
      'lastmodifiedby': 'string',
      'lastmodifiedon': '2022-05-24T16:37:07.328Z',
    },
    {
      'id': 0,
      'consignedtireId': 'string',
      'customerId': 'string',
      'userId': 'string',
      'bpfx': 'string',
      'bno': 0,
      'bsfx': 'string',
      'createdby': 'string',
      'createdon': '2022-05-24T16:37:07.328Z',
      'lastmodifiedby': 'string',
      'lastmodifiedon': '2022-05-24T16:37:07.328Z',
    },
    {
      'id': 0,
      'consignedtireId': 'string',
      'customerId': 'string',
      'userId': 'string',
      'bpfx': 'string',
      'bno': 0,
      'bsfx': 'string',
      'createdby': 'string',
      'createdon': '2022-05-24T16:37:07.328Z',
      'lastmodifiedby': 'string',
      'lastmodifiedon': '2022-05-24T16:37:07.328Z',
    },
    {
      'id': 0,
      'consignedtireId': 'string',
      'customerId': 'string',
      'userId': 'string',
      'bpfx': 'string',
      'bno': 0,
      'bsfx': 'string',
      'createdby': 'string',
      'createdon': '2022-05-24T16:37:07.328Z',
      'lastmodifiedby': 'string',
      'lastmodifiedon': '2022-05-24T16:37:07.328Z',
    },
    {
      'id': 0,
      'consignedtireId': 'string',
      'customerId': 'string',
      'userId': 'string',
      'bpfx': 'string',
      'bno': 0,
      'bsfx': 'string',
      'createdby': 'string',
      'createdon': '2022-05-24T16:37:07.328Z',
      'lastmodifiedby': 'string',
      'lastmodifiedon': '2022-05-24T16:37:07.328Z',
    },
    {
      'id': 0,
      'consignedtireId': 'string',
      'customerId': 'string',
      'userId': 'string',
      'bpfx': 'string',
      'bno': 0,
      'bsfx': 'string',
      'createdby': 'string',
      'createdon': '2022-05-24T16:37:07.328Z',
      'lastmodifiedby': 'string',
      'lastmodifiedon': '2022-05-24T16:37:07.328Z',
    },
  ],
};