export interface GeneralViewData {
  id?: string;
  customerId: number;
  brandPrefix: string | undefined;
  name: string | undefined;
  oprType: string | undefined;
  custNumber: string | undefined;
  abbrvatdName?: string | undefined;
  businessName?: string | undefined;
  customerNumber: string | undefined; 
  corrAddrLine1: string | undefined;
  corrAddrLine2?: string | undefined;
  corrAddrCountry: string | undefined;
  fcomOwnr: string;
  fprospect: string | undefined;
  corrAddrState: string | undefined;
  corrAddrCity: string | undefined;
  corrAddrZip: string | undefined;
  corrAddrPhone?: string | undefined;
  corrAddrFax?: string | undefined;
  regionCode: string | undefined;
  unitOfMeasure: string | undefined;
  currency: string | undefined;
  originatedBy?: string | undefined;
  lastContactDate?: Date | undefined | null;
}

export interface ISectionData {
  countries: string[];
  states: string[];
  regionCodes: string[];
  currencies: string[];
  unitsOfMeasures: string[];
  operationTypes: { operationTypes: string, operationTypeNames: string }[];
}

export const defaultSectionData: ISectionData = {
  countries: [],
  states: [],
  regionCodes: [],
  currencies: [],
  unitsOfMeasures: [],
  operationTypes: [{ operationTypes: '', operationTypeNames: '' }],
};

export const GENERAL_DATA_FIELDS = {
  prefixDataField: {
    id: 'brandPrefix',
    label: 'Prefix',
    name: 'brandPrefix',
    type: 'text',
    required: false,
  },
  nameDataField: {
    id: 'name',
    label: 'Customer Name',
    name: 'name',
    type: 'text',
    required: true,
  },
  customerNumberDataField: {
    id: 'customerNumber',
    label: 'Customer #',
    name: 'customerNumber',
    type: 'text',
    required: false,
  },
  abbrvatdNameDataField: {
    id: 'abbrvatdName',
    label: 'Abbreviated Name',
    name: 'abbrvatdName',
    type: 'text',
    required: false,
  },
  businessNameDataField: {
    id: 'businessName',
    label: 'Business Name / DBA',
    name: 'businessName',
    type: 'text',
    required: false,
  },
  corrAddrLine1DataField: {
    id: 'corrAddrLine1',
    label: 'Address line 1',
    name: 'corrAddrLine1',
    type: 'text',
    required: true,
  },
  corrAddrLine2DataField: {
    id: 'corrAddrLine2',
    label: 'Address line 2',
    name: 'corrAddrLine2',
    type: 'text',
    required: false,
  },
  corrAddrCityDataField: {
    id: 'corrAddrCity',
    label: 'City',
    name: 'corrAddrCity',
    type: 'text',
    required: true,
  },
  corrAddrZipDataField: {
    id: 'corrAddrZip',
    label: 'ZIP',
    name: 'corrAddrZip',
    type: 'text',
    required: true,
  },
  corrAddrPhoneDataField: {
    id: 'corrAddrPhone',
    label: 'Phone',
    name: 'corrAddrPhone',
    type: 'text',
    required: false,
  },
  corrAddrFaxDataField: {
    id: 'corrAddrFax',
    label: 'Fax',
    name: 'corrAddrFax',
    type: 'text',
    required: false,
  },
  originatedByDataField: {
    id: 'originatedBy',
    label: 'Originated By',
    name: 'originatedBy',
    type: 'text',
    required: false,
  },
  operationTypeDataField: {
    id: 'operationType',
    label: 'Operation Type',
    name: 'operationType',
    type: 'text',
    required: true,
  },
  countryDataField: {
    id: 'country',
    label: 'Country',
    name: 'country',
    type: 'text',
    required: true,
  },
  stateDataField: {
    id: 'state',
    label: 'State',
    name: 'state',
    type: 'text',
    required: true,
  },
  regionCodeDataField: {
    id: 'regionCode',
    label: 'Region Code',
    name: 'regionCode',
    type: 'text',
    required: true,
  },
  unitOfMeasureDataField: {
    id: 'unitOfMeasure',
    label: 'Unit of Measure',
    name: 'unitOfMeasure',
    type: 'text',
    required: true,
  },
  currencyDataField: {
    id: 'currency',
    label: 'Currency',
    name: 'currency',
    type: 'text',
    required: true,
  },
  lastContactDateDataField: {
    id: 'lastContact',
    label: 'Last Contact Date',
    name: 'lastContact',
    type: 'date',
    required: false,
  },
  commonOwnerDataField: {
    id: 'commonOwner',
    label: 'Common Owner',
    name: 'commonOwner',
    type: 'text',
    required: false,
  },
};

export const InitialGeneralViewData: GeneralViewData = {
  customerId: 0,
  brandPrefix: '',
  name: '',
  oprType: 'EMERGENCY ROAD SERVICE',
  custNumber: '',
  abbrvatdName: '',
  businessName: '',
  customerNumber: '',
  corrAddrLine1: '',
  corrAddrLine2: '',
  corrAddrCountry: 'USA',
  fcomOwnr: 'N',
  fprospect: 'Y',
  corrAddrState: 'PR',
  corrAddrCity: '',
  corrAddrZip: '',
  corrAddrPhone: '',
  corrAddrFax: '',
  regionCode: '0901',
  unitOfMeasure: 'Kilometers',
  currency: 'US Dollars',
  originatedBy: '',
  lastContactDate: new Date(),
};
