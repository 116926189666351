import * as graph from '@microsoft/microsoft-graph-client';
import axios from 'axios';

function getAuthenticatedClient(accessToken: string) {
  const client = graph.Client.init({
    authProvider: (done: any) => {
      done(null, accessToken);
    },
  });
  return client;
}

export async function getUserDetails(accessToken: string) {
  const client = getAuthenticatedClient(accessToken);
  const user = await client.api('/me').get();
  user.avatar = null;

  try {
    const response = await axios(
      'https://graph.microsoft.com/v1.0/me/photos/96x96/$value',
      {
        headers: { Authorization: `Bearer ${accessToken}` },
        responseType: 'arraybuffer',
      },
    );

    const photo =
      'data:' +
      response.headers['content-type'] +
      ';base64,' +
      new Buffer(response.data, 'binary').toString('base64');

    user.avatar = photo;
  } catch (err) {
    console.log(err);
  }

  let roles = '';
  try {
    const appRoles = await client
      .api('users/' + user.id + '/appRoleAssignments')
      .version('beta')
      .get();
    roles = appRoles.value
      .filter((role: any) => {
        return role.resourceId === '9107b670-2a33-456b-bd0d-46a2954e941f';
      })
      .map((role: any) => {
        return role.appRoleId;
      });
  } catch (exroles) {
    console.log(exroles);
  }

  let Groups = [];
  try {
    //true to specify that only security groups that the entity is a member of should be returned; 
    //false to specify that all groups and directory roles that the entity is a member of should be returned. Note: The function can only be called on a user if the parameter is true.
    const string = {
      securityEnabledOnly: true,
    };
    const appGroups = await client.api('/me/getMemberGroups')
      .post(string);
    Groups = appGroups.value;
  }
  catch (exgroups) {
    console.log(exgroups);
  }

  //Get Group Memberships
  
  const DirGroups: any[] = [];
  try {
    const appDirGroups = await client.api('/me/memberOf').get();
    const DirectGroups = appDirGroups.value;
    
    DirectGroups.forEach((elemgroup: any) => {
      const groupInfo = {
        id: elemgroup.id,
        displayName: elemgroup.displayName,
        mail: elemgroup.mail,
        securityIdentifier: elemgroup.securityIdentifier,
      };
      DirGroups.push(groupInfo);
    });
  }
  catch (exdirgroups) {
    console.log(exdirgroups);
  }


  const userInfo = {
    userProfile: user,
    appRoles: roles,
    UserGroups: DirGroups,
    Groups: Groups,
  };

  return userInfo;
}


export async function getGroupSearch(accessToken: string, searchstring: string, limit: number) {
  const client = getAuthenticatedClient(accessToken);

  let Groups = [];

  try {
    if (limit === 0) {
      const grouplist2 = await client.api('/groups')
        .select('id,displayName')
        .get();
      Groups = grouplist2.value;

    }
    else {

      if (searchstring !== '') {
        const grouplist = await client.api('/groups')
          .filter('startswith(displayName, \'' + searchstring + '\')')
          .select('id,displayName')
          .top(limit)
          .get();

        Groups = grouplist.value;
      }
      else {
        const grouplist2 = await client.api('/groups')
          .select('id,displayName')
          .top(limit)
          .get();
        Groups = grouplist2.value;
      }

    }
  }
  catch (exgroups) {
    console.log(exgroups);
  }

  return Groups;

}

export async function getUserSearch(accessToken: string, searchstring: string, limit: number) {
  const client = getAuthenticatedClient(accessToken);

  let Users = [];
  try {
    if (limit === 0) {
      const userlist2 = await client.api('/users')
        .select('id,displayName,mail,surname,givenName')
        .get();
      Users = userlist2.value;

    }
    else {

      if (searchstring !== '') {
        const userlist = await client.api('/users')
          .filter('startswith(displayName, \'' + searchstring + '\') or startswith(mail, \'' + searchstring + '\') ')
          .select('id,displayName,mail,surname,givenName')
          .top(limit)
          .get();

        Users = userlist.value;
      }
      else {
        const userlist3 = await client.api('/groups')
          .select('id,displayName,mail,surname,givenName')
          .top(limit)
          .get();
        Users = userlist3.value;
      }


    }
  }
  catch (exgroups) {
    console.log(exgroups);
  }

  return Users;
}

export async function getUserInfoByEmail(accessToken: string, emailaddress: string) {
  const client = getAuthenticatedClient(accessToken);
  const userlist = await client.api('/users/' + emailaddress)
    .select('id,displayName,mail,surname,givenName')
    .get();
  return userlist;
}


export async function CheckGroupName(accessToken: string, groupnamevalue: string) {
  const client = getAuthenticatedClient(accessToken);
  let exist = false;
  let Groups = [];
  try {
    const grouplist = await client.api('/groups')
      .filter('displayName+eq+\'' + groupnamevalue + '\'')
      .select('id,displayName')
      .get();
    Groups = grouplist.value;
    if (Groups.length > 0) {
      exist = true;
    }
    else {
      exist = false;
    }
  }
  catch (exgroups) {
    console.log(exgroups);
    exist = false;
  }
  return exist;
}

