import { DefaultButton, MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import classNames from 'classnames';
import { FunctionComponent, useEffect, useState } from 'react';
import DataGridComponent from '../../../../../../shared/DataGridComponent';
import { FormBuilderGroup } from '../../../../../../shared/FormBuilderComponent';
import { pageSizes, massDetailsHeadCell, TEXT_FIELDS } from './consts';
import styles from './MassDetail.module.scss';
import { useBoolean } from '@fluentui/react-hooks';
import apiService from '../../../../../../api';
import useNotifications from '../../../../../../hooks/useNotifications';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_spareStock_massDetails } from '../../../../../../consts/programKeys';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import { useSelector } from 'react-redux';
import { customerSelector, locationSelector } from '../../../../../../redux/recordKeepingSlice';
import DialogComponent from '../../../../../../shared/DialogComponent';
import useKeyPress from '../../../../../../hooks/useKeyPress/useKeyPress';
import MassUpload from '../../../../../MassUpload/MassUpload';

interface MassDetailsProps {
    value?: any;
}

const MassDetails: FunctionComponent<MassDetailsProps> = () => {

  //hooks
  const { addNotification } = useNotifications();

  const [availableMassDetails, setAvailableMassDetails] = useState<Array<any>>([]);
  const [totalFound, setTotalFound] = useState(0);
  const [tiresSelected, setTiresSelected] = useState<Array<any>>([]);
  const [dataGridState, setDataGridState] = useState<any>({});
  const [massDetailsPagination, setMassDetailsPagination] = useState<any>({ pageSize: 500, pageNumber: 1 });
  const [massDetailsSortOrder, setMassDetailsSortOrder] = useState<any>({ column: 'modifiedDate', order: 'asc' });
  const [showUpload, { toggle: toggleShowUpload }] = useBoolean(false);
  const [runningStatus, setRunningStatus] = useState<string>('IDLE');
  const [uploadRunningStatus, setUploadRunningStatus] = useState<string>('IDLE');

  //toggles
  const [isLoadingAvailableTable, { toggle: toggleShowLoadingAvailableTable }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [showClearAllDialog, { toggle: toggleShowClearAllDialog }] = useBoolean(false);

  //permission
  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_spareStock_massDetails);

  const { id: customerId, customerName } = useSelector(customerSelector);
  const { id: locationId, locationCode } = useSelector(locationSelector);

  const massDetailsFormFieldsGroup = new FormBuilderGroup(TEXT_FIELDS);

  const fetchAvailableMassDetails = async (
    pagination: any = massDetailsPagination,
    sortOrder: any = massDetailsSortOrder,
  ) => {
    try {
      toggleShowLoadingAvailableTable();
      const { data: { data, total } } = await apiService.spareStock.getMassDetailsList(
        pagination,
        sortOrder,
        customerId,
        locationId,
        massDetailsFormFieldsGroup.getFieldFormValue('prefixField'),
        massDetailsFormFieldsGroup.getFieldFormValue('brandNoField'),
        massDetailsFormFieldsGroup.getFieldFormValue('suffixField'));

      toggleShowLoadingAvailableTable();
      
      setAvailableMassDetails(data);
      setTotalFound(total.found);
      setMassDetailsPagination(massDetailsPagination);
      setMassDetailsSortOrder(massDetailsSortOrder);

    } catch (e: any) {
      addNotification({
        text: 'Fetching error of Mass Details',
        type: MessageBarType.error,
      });
    }
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    const { countOnPage, paginationProps, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    setDataGridState({ pagination, sortOrder });
    await fetchAvailableMassDetails(pagination, sortOrder);
  };

  const handleSelectRow = (tiresSelected: Array<any>) => setTiresSelected(tiresSelected);

  const handleAdd = async () => {
    massDetailsFormFieldsGroup.setFormValue('brandNoField', '');
    try {
      const { data: {data, desc: {message} }}: any = await apiService.spareStock.addMassDetails(
        customerId,
        locationId,
        massDetailsFormFieldsGroup.getFieldFormValue('prefixField'),
        massDetailsFormFieldsGroup.getFieldFormValue('brandNoField'),
        massDetailsFormFieldsGroup.getFieldFormValue('suffixField'),
      );
      await fetchAvailableMassDetails();
    } catch (e: any) {
      const { response } = e;
      addNotification({ text: `Error adding tire: ${response.data.message}`, type: MessageBarType.error });
    }
  };

  const handleClearAll = async () => {
    massDetailsFormFieldsGroup.setFormValue('prefixField', '');
    massDetailsFormFieldsGroup.setFormValue('brandNoField', '');
    toggleShowClearAllDialog();
    try {
      toggleShowLoadingAvailableTable();
      await apiService.spareStock.clearAllMassDetails(customerId, locationId);
      addNotification({ text: 'Tires are deleted successfully', type: MessageBarType.success });

      await fetchAvailableMassDetails();
      toggleShowLoadingAvailableTable();
    } catch (e: any) {
      addNotification({ text: 'Deleting tires error', type: MessageBarType.error });
    }
  };

  const handleDeleteMassDetails = async () => {
    toggleShowLoadingAvailableTable();
    const massDetailIds = getIdsFromMassDetails(tiresSelected);
    await fetchDeleteMassDetails(massDetailIds);
    toggleShowLoadingAvailableTable();
  };

  const getIdsFromMassDetails = (tiresArray: Array<any>) => tiresArray?.map((massDetails: any) => massDetails.massDetailsNo);

  const fetchDeleteMassDetails = async (ids: Array<string>) => {
    try {
      await apiService.spareStock.deleteMassDetails(ids);
      addNotification({ text: `${ids.length} tire/s deleted successfully`, type: MessageBarType.success });
    } catch (error) {
      addNotification({ text: 'Deleting tires error', type: MessageBarType.error });
    }
  };

  const handleGetMassDetails = async () => {
    fetchMassDetails();
  };

  const fetchMassDetails = async () => {
    try {
      const { data: { message } }: any = await apiService.spareStock.getMassDetails(customerId, locationId);
      await fetchStatus();
      if (runningStatus == 'IDLE') {
        addNotification({ text: message, type: MessageBarType.success });
      }
    }
    catch (e: any) {
      addNotification({ text: 'Error while fetching mass details', type: MessageBarType.error });
    }
  };

  const fetchStatus = async () => {
    let timeOut = null;
    try {
      const { data: { runStatus } }: any = await apiService.spareStock.getMassDetailsStatus(customerId, locationId);

      if (timeOut) clearInterval(timeOut);
      if (runStatus == 'RUNNING') timeOut = setTimeout(() => fetchStatus(), 10000);

      setRunningStatus(runStatus);
    } catch (e: any) {
      addNotification({ text: 'Error fetching status', type: MessageBarType.error });
    }
    finally {
      await fetchAvailableMassDetails();
    }
  };

  const setInitialState = () => {
    setAvailableMassDetails([]);
    if (runningStatus) {
      setRunningStatus('IDLE');
    }
  };

  const handlePrint = async (printingType: any) => {
    toggleShowPrintExport();
    toggleShowLoadingAvailableTable();
    const { sortOrder } = dataGridState;
    try {
      const input = {
        pagination: massDetailsPagination,
        sortOrder,
        customerId,
        locationId,
      };
      const headerFields = [
        { title: 'Customer Name', value: customerName },
        { title: 'Location Code', value: locationCode },
      ];
      const requestData = {
        input: input,
        headerFields: headerFields,
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.spareStock.printMassDetailsExcel(requestData) :
        await apiService.spareStock.printMassDetailsPdf(requestData);

      downloadFile(data, printingType);
      addNotification({ text: 'File was successfully received.', type: MessageBarType.success });
    } catch (e: any) {
      const { response } = e;
      addNotification({ text: `Printing error: ${response.data.message}`, type: MessageBarType.error });
    } finally {
      toggleShowLoadingAvailableTable();
    };
  };

  useKeyPress({
    handleAdd: handleAdd,
  });

  useEffect(() => {
    setInitialState();
    fetchAvailableMassDetails();
  }, [
    customerId,
    locationId,
  ]);

  return (
    <>
      <div className={classNames('ms-grid', styles.marginTop10)} dir='ltr'>
        <div className={classNames('ms-Grid-row')}>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
            <div className={classNames('ms-Grid-col', 'ms-sm2')}>
              <TextField {...massDetailsFormFieldsGroup.getFieldForm('prefixField')} />
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm2')}>
              <TextField {...massDetailsFormFieldsGroup.getFieldForm('brandNoField')} />
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm2')}>
              <TextField {...massDetailsFormFieldsGroup.getFieldForm('suffixField')} />
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm2', styles.marginTop25)}>
              <PrimaryButton
                id='addBtn'
                text='+Add'
                onClick={handleAdd}
                disabled={!userPermissions.isWrite || uploadRunningStatus == 'RUNNING'}
              />
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm4', styles.statusWrapper)}>
              <Text variant='mediumPlus' className={styles.statusWrapper}>UPLOAD STATUS: { uploadRunningStatus } </Text>
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm4', styles.statusWrapper)}>
              <Text variant='mediumPlus' className={styles.statusWrapper}>STATUS: { runningStatus } </Text>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames('ms-grid', 'table-wrapper')} dir='ltr'>
        <div className={classNames('ms-Grid-row')}>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop10)}>
            <DataGridComponent
              idTable={'mass-detail'}
              title='Details'
              headCells={massDetailsHeadCell}
              rowsTable={availableMassDetails}
              totalDataFound={totalFound}
              isLoading={isLoadingAvailableTable}
              defaultRowsPerPage={pageSizes}
              enableCheckBox={true}
              enableMultiSelectRow={true}
              enablePagination={true}
              enableRowsPerPage={true}
              enableDeleteOption={userPermissions.isWrite}
              enableResizeColumns={true}
              handleChangeDataGridState={handleChangeDataGridState}
              handleDelete={handleDeleteMassDetails}
              handleSelectRow={handleSelectRow}
              defaultSorting={massDetailsSortOrder}
            />
          </div>
        </div>
      </div>

      <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
        <DefaultButton
          id="uploadButton"
          onClick={toggleShowUpload}
          text="Upload"
          disabled={uploadRunningStatus == 'RUNNING'}
        />
        <DefaultButton
          id='printExportBtn'
          text='Print/Export'
          onClick={toggleShowPrintExport}
          disabled={uploadRunningStatus == 'RUNNING'}
        />
        <PrimaryButton
          id='clearBtn'
          text='Clear All Details'
          onClick={toggleShowClearAllDialog}
          disabled={!userPermissions.isWrite || availableMassDetails.length == 0 || uploadRunningStatus == 'RUNNING'}
        />
        <PrimaryButton
          id='getMassDetailsBtn'
          text='Get Mass Details'
          onClick={handleGetMassDetails}
          disabled={!userPermissions.isWrite || uploadRunningStatus == 'RUNNING'}
        />
      </div>

      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />

      <MassUpload
        isModalOpen={showUpload}
        hideModal={toggleShowUpload}
        formType={'Mass Details'}
        fetch={fetchAvailableMassDetails}
        location={locationId}
        getUploadRunningStatus={setUploadRunningStatus}
      />

      <DialogComponent
        key='showClearAllDialog'
        isOpen={showClearAllDialog}
        onCancel={toggleShowClearAllDialog}
        onSubmit={handleClearAll}
        title='Confirmation'
        subText='Are you sure you want to delete all mass details?'
        onCancelLabel='Return'
        onSubmitLabel='OK'
      />
    </>
  );
};

export default MassDetails;