export const applicableOptions = [
  { key: 'Y', text: 'Y' },
  { key: 'N', text: 'N' },
];

export const billByOptions = [
  { key: 'Customer', text: 'Customer' },
  { key: 'Location', text: 'Location' },
];

export const billToOptions = [
  { key: 'Customer', text: 'Customer' },
  { key: 'Location', text: 'Location' },
  { key: 'Common Owner', text: 'Common Owner' },
];
export const genOptOptions = [
  { key: 'Sep Invoice', text: 'Sep Invoice' },
  { key: 'Sep Line Items', text: 'Sep Line Items' },
];