import classNames from 'classnames';
import { FunctionComponent, useState } from 'react';
import RateGroupComponent from '../../shared/RateGroupComponent';
import MakeModelComponent from '../MakeModelComponent';
import { apiModules, OrganizeFleetHOCProps, userPermissionKeys } from './consts';


const OrganizeFleetHOC: FunctionComponent<OrganizeFleetHOCProps> = ({
  customerId,
  parentComponentName,
  disableEditing,
}) => {

  const [refreshRateGroups, setRefreshRateGroups] = useState<boolean>(false);

  return (
    <>
      <div className='ms-Grid margin-left-rigth-2 marginTop18' dir='ltr'>
        <div className='ms-Grid-row'>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <RateGroupComponent
              customerId={customerId}
              fetchAPI={apiModules[parentComponentName].getRateGroupsAPI}
              deleteAPI={apiModules[parentComponentName].deleteRateGroupsAPI}
              addAPI={apiModules[parentComponentName].addRateGroupAPI}
              updateAPI={apiModules[parentComponentName].updateRateGroupsAPI}
              onChangeRateGroup={() => setRefreshRateGroups(true)}
              disableEditing={disableEditing}
              permissionKey={userPermissionKeys[parentComponentName]}
              parentComponentName={parentComponentName}
            />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <MakeModelComponent
              customerId={customerId}
              fetchAPI={apiModules[parentComponentName].getMakeModelsAPI}
              deleteAPI={apiModules[parentComponentName].deleteMakeModelsAPI}
              addAPI={apiModules[parentComponentName].addMakeModelAPI}
              updateAPI={apiModules[parentComponentName].updateMakeModelsAPI}
              cloneAPI={apiModules[parentComponentName].cloneMakeModelsAPI}
              getRatesListAPI={apiModules[parentComponentName].getRateGroupsAPI}
              disableEditing={disableEditing}
              permissionKey={userPermissionKeys[parentComponentName]}
              parentComponentName={parentComponentName}
              refreshRateGroupList={refreshRateGroups}
              onRefreshRateGroupList={() => setRefreshRateGroups(false)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizeFleetHOC;