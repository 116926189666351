import { managementTestInstance } from '../axios';

const getProducts = (country: string, pagination: any, sortOrder: any, filters: any) => 
  managementTestInstance.post('/maintain-product', { country, pagination, sortOrder, filters });

const updateProduct = (data: any) => 
  managementTestInstance.put('/maintain-product', data);

const deleteProducts = (country: string, ids: Array<string>) => 
  managementTestInstance.delete('/maintain-product', { data: { country, ids }});

const addProduct = (data: any) =>
  managementTestInstance.post('/maintain-product/add', data);

const getCountries = () => 
  managementTestInstance.post('/maintain-product/countries');

const getTireTypes = () => 
  managementTestInstance.post('/maintain-product/tire-types');

const getRadialBias = () => 
  managementTestInstance.post('/maintain-product/radial-bias');

export default {
  getProducts,
  updateProduct,
  deleteProducts,
  addProduct,
  getCountries,
  getTireTypes,
  getRadialBias,
};