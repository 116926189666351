import { FC, FormEvent, ReactElement, useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { DefaultButton, Dropdown, IconButton, IDropdownOption, MessageBarType, Modal, PrimaryButton, Text, TextField } from '@fluentui/react';
import { get } from 'lodash';
import apiService from '../../../../../../../../../api';
import useNotifications from '../../../../../../../../../hooks/useNotifications';
import LoadingScreen from '../../../../../../../../LoadingScreen/LoadingScreen';
import SeparatorGy from '../../../../../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../../../../../Pagination/Pagination';
import { IPaginationProps } from '../../../../../../../../Pagination/IPaginationProps';
import { columns } from './consts';
import { pageSizes } from '../../../../../../../../../consts/recordKeeping';
import { IFootprintModalProps } from './IFootprintModalProps';
import { IFootprintModalState } from './IFootprintModalState';

import { useUserPermissions } from '../../../../../../../../../hooks/useUserPermissions';
import { auth_actionOnVehicles_registerVehicles} from '../../../../../../../../../consts/programKeys';

import styles from './FootprintModal.module.scss';

const FootprintModal: FC<IFootprintModalProps> = ({ isModalOpen, hideModal, onSubmit, defaultSelected }): ReactElement => {
  const { addNotification } = useNotifications();
  const [state, setState] = useState<IFootprintModalState>({
    items: [],
    footprintNames: [],
    loading: false,
    foundCount: 0,
    selectedItem: null,
  });

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_actionOnVehicles_registerVehicles);

  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });

  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const fetchFootprints = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data }: any = await apiService.registerVehicles.getWheelPositionsByFootprintId(
        get(state, 'selectedItem.id', null),
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        null,
      );
      const foundCount = data.total.found;
      const items = data.data.map(({ date, ...other }: any) => ({ date: moment(date).format('MM/DD/YYYY'), ...other }));
      setState((prev: any) => ({ ...prev, items, foundCount }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Footpints fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchFootprintNames = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data }: any = await apiService.registerVehicles.getFootprints();
      setState((prev: any) => ({ ...prev, footprintNames: data }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Footpints names fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const onDismiss = () => {
    hideModal();
    setCountOnPage({ key: pageSizes[0], text: pageSizes[0].toString() });
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchFootprints();
    }
  }, [
    paginationProps.current,
    countOnPage,
    isModalOpen,
    defaultSelected,
    state.selectedItem,
  ]);

  useEffect(() => {
    setState((prev) => ({ ...prev, selectedItem: defaultSelected }));
  }, [
    defaultSelected,
  ]);



  useEffect(() => {
    fetchFootprintNames();
  }, []);

  return state.loading ? <LoadingScreen /> : (
    <Modal
      isOpen={isModalOpen}
      onDismiss={onDismiss}
      isBlocking={false}
      containerClassName={styles.modalContainer}
    >
      <div className={styles.modalBody}>
        <div className={styles.modalHeader}>
          <Text variant="xLarge" className={styles.highlight}>Select Vehicle Footprint</Text>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel="Close popup modal"
            onClick={hideModal}
            disabled={!userPermissions.isWrite}
          />
        </div>
        <div className={classNames(styles.tableHeader, styles.filtersBlock)}>
          <div>
            <Dropdown
              styles={{dropdown: { width: 200 }}}
              label="Footprint Name"
              id="footprintName"
              options={state.footprintNames.map(({ id, footprintName }) => ({ key: id, text: footprintName }))}
              selectedKey={get(state, 'selectedItem.id', null)}
              onChange={(ev, { key: footprintId }: any) => setState((prev) => ({ ...prev, selectedItem: state.footprintNames.find(({ id }) => id === footprintId) }))}
            />
          </div>
          <TextField
            label="Vehicle Type"
            value={get(state, 'selectedItem.vehType', '')}
            disabled
          />
          <TextField
            label="# of Wheel Positions"
            value={get(state, 'selectedItem.wheels', '')}
            disabled
          />
        </div>
        <div className={styles.tableHeader}>
          <Text variant="large" block className={styles.highlight}>Available Wheel Positions</Text>
          <div>
            <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
            <Dropdown
              id="countOnFootprintModal"
              options={pageSizes.map(pageSize => ({
                key: pageSize,
                text: pageSize.toString(),
              }))}
              defaultSelectedKey={pageSizes[0]}
              selectedKey={countOnPage?.key}
              onChange={onChangeCountOnPage}
            />
            <SeparatorGy vertical />
            <Text variant="large" className={styles.highlight}>{state.foundCount} Positions Identified</Text>
          </div>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                {
                  columns.map(item => (
                    <th key={item.name}>
                      {item.name}
                    </th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {
                state.items.map((item: any) => (
                  <tr
                    key={item.id}
                    className={styles.trBasic}
                  >
                    <td>{item.name}</td>
                    <td>{item.description}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <SeparatorGy />
          <Pagination {...paginationProps} />
        </div>
        <div className={styles.modalFooter}>
          <DefaultButton
            id="cancelButton"
            text="Cancel"
            onClick={onDismiss}
            disabled={!userPermissions.isWrite}
          />
          <PrimaryButton
            id="selectButton"
            text="Select"
            onClick={() => onSubmit(state.selectedItem)}
            disabled={!userPermissions.isWrite}
          />
        </div>
      </div>
    </Modal>
  );
};

export default FootprintModal;
