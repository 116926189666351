import { recordTestInstance } from '../axios';

const get = (pagination: any, sortOrder: any, customerId: number, locationId: number, filters: any = { searchString: null } ) =>
  recordTestInstance.post('/unflag-consigned-tire', { pagination, filters, sortOrder, customerId, locationId });
  
const deleteDamagedTires = (ids: Array<any>) =>
  recordTestInstance.delete('/unflag-consigned-tire', { data: { ids } });

export default {
  get,
  delete: deleteDamagedTires,
};
