import { IChoiceGroupOption } from '@fluentui/react';

export const columns = [
  {
    key: 'column1',
    name: 'From Bill Period',
    fieldName: 'fromBillPeriod',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'To Bill Period',
    fieldName: 'toBillPeriod',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'Start Mode',
    fieldName: 'startMode',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'Started On',
    fieldName: 'startedOn',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'Status',
    fieldName: 'status',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column6',
    name: 'Completed Status',
    fieldName: 'completedStatus',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column7',
    name: 'Completed On',
    fieldName: 'completedOn',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column8',
    name: 'Bill Type',
    fieldName: 'billType',
    isSorted: false,
    isSortedDescending: false,
  },
];

export const selectMode: IChoiceGroupOption[] = [
  { key: 'Online', text: 'Online' },
  { key: 'Batch', text: 'Batch' },
];

export const STATUSES_NOT_ALLOWED = [
  'S',
  'I',
  'B',
  'W',
];