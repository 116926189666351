import { HeadCell } from '../DataGridComponent/DataGridModels';
import { IFormDataField } from '../FormBuilderComponent';

export type INVOICES_ACTION_TYPES = 'view' | 'maintain';

export const availableLineItemsHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'QTY',
    fieldName: 'qty',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'MB_Code',
    fieldName: 'mbCode',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Total',
    fieldName: 'total',
    typeField: 'currency',
  },
  {
    key: 'column3',
    name: 'Description',
    fieldName: 'description',
    typeField: 'text',
  },
];

export const CUSTOMER_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'prefixField',
    properties: {
      id: 'prefix',
      label: 'Prefix',
      name: 'prefix',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'customerNameField',
    properties: {
      id: 'customerName',
      label: 'Customer Name',
      name: 'customerName',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'locationCodeField',
    properties: {
      id: 'locationCode',
      label: 'Location Code',
      name: 'locationCode',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'locationNameField',
    properties: {
      id: 'locationName',
      label: 'Location Name',
      name: 'locationName',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'regionField',
    properties: {
      id: 'region',
      label: 'Region',
      name: 'region',
      type: 'text',
      disabled: true,
    },
  },
];

export const CONTRACT_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'contractIdField',
    properties: {
      id: 'contractId',
      label: 'Contract ID',
      name: 'contractId',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'statusField',
    properties: {
      id: 'status',
      label: 'Status',
      name: 'status',
      type: 'status',
      disabled: true,
    },
  },
  {
    formFieldName: 'contractPeriodField',
    properties: {
      id: 'contractPeriod',
      label: 'Contract Period',
      name: 'contractPeriod',
      type: 'text',
      disabled: true,
    },
  },
];

export const INVOICE_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'invoiceNumberField',
    properties: {
      id: 'invoiceNumber',
      label: 'Invoice #',
      name: 'invoiceNumber',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'statusField',
    properties: {
      id: 'status',
      label: 'Status',
      name: 'status',
      type: 'status',
      disabled: true,
    },
  },
  {
    formFieldName: 'dateField',
    properties: {
      id: 'date',
      label: 'Date',
      name: 'date',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'typeField',
    properties: {
      id: 'type',
      label: 'Type',
      name: 'type',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'debitOrCreditField',
    properties: {
      id: 'debitOrCredit',
      label: 'DB/CR',
      name: 'debitOrCredit',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'aditionalDetailsField',
    properties: {
      id: 'aditionalDetails',
      name: 'additionalDetails',
      type: 'textarea',
      disabled: true,
    },
  },
];

export const CREDIT_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'typeField',
    properties: {
      id: 'type',
      label: 'Type',
      name: 'type',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'goodyearNumberField',
    properties: {
      id: 'goodyearNumber',
      label: 'GDYR #',
      name: 'goodyearNumber',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'dateField',
    properties: {
      id: 'date',
      label: 'Date',
      name: 'date',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'totalField',
    properties: {
      id: 'total',
      label: 'Total',
      name: 'total',
      type: 'text',
      disabled: true,
    },
  },
];

export const LINEITEMS_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'qtyField',
    properties: {
      id: 'qty',
      label: 'QTY',
      name: 'qty',
      type: 'number',
      disabled: true,
    },
  },
  {
    formFieldName: 'wheelPositionField',
    properties: {
      id: 'wheelPosition',
      label: 'Wheel Position',
      name: 'wheelPosition',
      type: 'number',
      disabled: true,
    },
  },
  {
    formFieldName: 'radialOrBiasField',
    properties: {
      id: 'radialOrBias',
      label: 'R/B',
      name: 'radialOrBias',
      type: 'radialOrBias',
      disabled: true,
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'mbCodeField',
    properties: {
      id: 'mbCode',
      label: 'MB Code',
      name: 'mbCode',
      type: 'number',
      disabled: true,
      description: 'MB Code must be 9 digits',
    },
  },
  {
    formFieldName: 'unitPriceField',
    properties: {
      id: 'unitPrice',
      label: 'Unit Price',
      name: 'unitPrice',
      type: 'number',
      disabled: true,
    },
  },
  {
    formFieldName: 'totalField',
    properties: {
      id: 'total',
      label: 'Total',
      name: 'total',
      type: 'number',
      disabled: true,
    },
  },
  {
    formFieldName: 'costField',
    properties: {
      id: 'cost',
      label: 'Cost',
      name: 'cost',
      type: 'number',
      disabled: true,
    },
  },
  {
    formFieldName: 'descriptionField',
    properties: {
      id: 'description',
      label: 'Description',
      name: 'description',
      type: 'text',
      disabled: true,
    },
  },
];