import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { customerSelector, locationSelector } from '../../../../../../redux/recordKeepingSlice';
import Stepper from '../../../../../../shared/Stepper';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import OrderHeader from './Components/OrderHeader/OrderHeader';
import HistoryAndMessages from '../../Components/HistoryAndMessages/HistoryAndMessages';
import NewOrderDetails from './Components/OrderDetails/NewOrderDetails';
import NewShippingInstructions from '../../Components/ShippingInstructions/ShippingInstructions';
import { emptyPlaceOriginalOrder, emptyPlaceOriginalOrderItem } from './consts';
import { INewPlaceOriginalProps } from './INewPlaceOriginalProps';
import styles from './NewPlaceOriginal.module.scss';
import { orderSteps } from '../../consts';
import { MessageBarType, PrimaryButton } from '@fluentui/react';
import useNotifications from '../../../../../../hooks/useNotifications';


const NewPlaceOriginal: FC<INewPlaceOriginalProps> = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);

  const [orderToBeAdded, setOrderToBeAdded] = useState(emptyPlaceOriginalOrder);
  const [orderItemToBeAdded, setOrderItemToBeAdded] = useState(emptyPlaceOriginalOrderItem);

  const [typeCodes, setTypeCodes] = useState<any>([]);
  const [plants, setPlants] = useState<any>([]);
  const [plant, setPlant] = useState<any>(null);
  const [shipToOem, setShipToOem] = useState(null);

  const renderStepAccordingKey = (stepKey: number) => {
    switch (stepKey) {
      case 0:
        return <NewOrderDetails
          plant={plant}
          setPlant={setPlant}
          typeCodes={typeCodes}
          setTypeCodes={setTypeCodes}
          plants={plants}
          setPlants={setPlants}
          shipToOem={shipToOem}
          setShipToOem={setShipToOem}
          orderToBeAdded={orderToBeAdded}
          setOrderToBeAdded={setOrderToBeAdded}
          orderItemToBeAdded={orderItemToBeAdded}
          setOrderItemToBeAdded={setOrderItemToBeAdded} 
        />;
      case 1:
        return <NewShippingInstructions orderToBeAdded={orderToBeAdded} />;
      case 2:
        return <HistoryAndMessages orderToBeAdded={orderToBeAdded} />;
      default:
        return stepKey;
    }
  };

  const finish = () => {
    setCurrentStep(0);
    setOrderToBeAdded(emptyPlaceOriginalOrder);
    setOrderItemToBeAdded(emptyPlaceOriginalOrderItem);
    setPlant(null);
    addNotification({
      text: 'Order tire was successfully added.',
      type: MessageBarType.success,
    });
  };

  useEffect(() => {
    setOrderToBeAdded((prev: any) => ({ ...prev, customerId, locationId }));
  }, [customerId, locationId]);
  
  return (
    <>
      <div className={classNames(styles.mainBlock)}>
        <div>
          <Stepper currentStep={currentStep} steps={orderSteps} setCurrentStep={setCurrentStep}/>
          <div >{renderStepAccordingKey(currentStep)}</div>
        </div>
        <SeparatorGy vertical />
        <div>
          <OrderHeader orderToBeAdded={orderToBeAdded} setOrderToBeAdded={setOrderToBeAdded} shipToOem={shipToOem} setShipToOem={setShipToOem} />
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <div>
          <PrimaryButton text="Finish" onClick={finish} disabled={!orderToBeAdded.id} />
        </div>
      </div>
    </>
  );
};

export default NewPlaceOriginal;
