import { IChoiceGroupOption } from '@fluentui/react';
import { HeadCell } from '../../../../shared/DataGridComponent/DataGridModels';

export const pageSizes = [100, 250, 500];

export const tiresAvailableHeadCells: HeadCell[] = [
  {
    key: 'column0',
    name: 'Pfx',
    fieldName: 'pfx',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Brand',
    fieldName: 'bno',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Sfx',
    fieldName: 'sfx',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'TireSize',
    fieldName: 'tireSize',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'O_R',
    fieldName: 'origOrRtrdC',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column5',
    name: 'Td',
    fieldName: 'td',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column6',
    name: 'BasisUnits',
    fieldName: 'basisUnits',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column7',
    name: 'AccMiles',
    fieldName: 'accUnits',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column8',
    name: 'BillableMiles',
    fieldName: 'billableUnits',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column9',
    name: 'Rate',
    fieldName: 'rate',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column10',
    name: 'BillingPrice',
    fieldName: 'billingPrice',
    typeField: 'currency',
    isEditable: false,
  },
  {
    key: 'column11',
    name: 'CasVal',
    fieldName: 'casingval',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column12',
    name: 'TotalPrice',
    fieldName: 'totPrice',
    typeField: 'currency',
    isEditable: false,
  },
  {
    key: 'column12',
    name: 'Submit',
    fieldName: 'submit',
    typeField: 'text',
    isEditable: false,
  },
];

export const locationsColumns = [
  {
    key: 'column1',
    name: 'Ship to Number',
    fieldName: 'shipToNumber',
  },
  {
    key: 'column2',
    name: 'Location Code',
    fieldName: 'locationCode',
  },
  {
    key: 'column3',
    name: 'Address Line 1',
    fieldName: 'addrLine1',
  },
  {
    key: 'column5',
    name: 'City',
    fieldName: 'city',
  },
];

export const postTypeOptions: IChoiceGroupOption[] = [
  { key: 'manual', text: 'Manual' },
  { key: 'fromFile', text: 'From File' },
];

export const defaultSelectedPostType = 'manual';
