import { DefaultButton, Dialog, DialogFooter, DialogType, MessageBarType, PrimaryButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import apiService from '../../../api';
import { auth_terms_viewListing } from '../../../consts/programKeys';
import useNotifications from '../../../hooks/useNotifications';
import { useUserPermissions } from '../../../hooks/useUserPermissions';
import { TERMSSTATUS_ENUM } from '../../../models/EnumTermsStatuses';
import { setContractor } from '../../../redux/contractSlice';
import { customerSelector, setCustomer, setShouldFilterRun } from '../../../redux/customerSlice';
import DataGridComponent from '../../../shared/DataGridComponent';
import styles from './AvailableTerms.module.scss';
import { availableStatuses, defaultSorting, searchTermHeadCell, termsActionsList } from './consts';
import { IAvailableTermsState } from './IAvailableTermsState';


export const AvailableTerms: React.FC = () => {

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();
  const { addNotification } = useNotifications();
  const customer = useSelector(customerSelector);

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_terms_viewListing);

  const [state, setState] = useState<IAvailableTermsState>({
    items: [],
    foundCount: 0,
  });
  const [actionType, setActionType] = useState('');
  const [contractSelected, setContractSelected] = useState<any>();
  const [status, setStatus] = useState<any>({ previousStatus: '', nextStatus: '' });
  const [dataGridState, setDataGridState] = useState({});

  const [showActionsList, { toggle: toggleShowingActionsList }] = useBoolean(false);
  const [isLoadingAvailableTable, { toggle: toggleShowLoadingAvailableTable }] = useBoolean(false);
  const [showDeletingConfirmation, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const disableButtons = useMemo(() =>
    isNil(contractSelected) || (contractSelected.contractStatus !== TERMSSTATUS_ENUM.UNDERPREPARATION && contractSelected.contractStatus !== TERMSSTATUS_ENUM.GENERATED), [contractSelected]);

  const fetchCustomers = async () => {
    try {
      const { data: { data } }: any = await apiService.getCustomerSearch({}, null, null, id);
      dispatch(setCustomer(data[0]));
    } catch (e: any) {
      addNotification({
        text: 'Customers fetching error',
        type: MessageBarType.error,
      });
    }
  };

  const fetchAvailableTerms = async (
    paginationProps: any = { pageSize: 5, pageNumber: 1 },
    sortOrder: any = defaultSorting,
    filters?: any,
  ) => {
    try {
      setDataGridState({ paginationProps, sortOrder, filters });
      const { data }: any = await apiService.terms.getAvailableTerms(
        paginationProps,
        [sortOrder],
        filters,
        id,
      );
      const items = data.data;
      const foundCount = data.total.found;
      setState((prev: any) => ({
        ...prev,
        items,
        foundCount,
      }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Available Terms fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    };
  };

  const fetchUpdateContractStatus = async (newStatus: string) => {
    try {
      const bodyRequest = {
        contractId: contractSelected?.contractId,
        newStatus,
        rejectedReason: '',
      };
      await apiService.terms.updateContractStatus(bodyRequest);
      await fetchAvailableTerms();
      addNotification({
        text: 'Status updated successfully',
        type: MessageBarType.success,
      });
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Update status error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    };
  };

  const fetchRevertContractStatus = async () => {
    try {
      await apiService.terms.revertContractStatus(contractSelected?.contractId);
      await fetchAvailableTerms();
      addNotification({
        text: 'Status reverted successfully',
        type: MessageBarType.success,
      });
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Revert status error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    };
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    toggleShowLoadingAvailableTable();
    setDataGridState(dataGridState);
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    await fetchAvailableTerms(pagination, sortOrder, searchedText);
    toggleShowLoadingAvailableTable();
  };

  const handleAddTerm = async () => {
    toggleShowLoadingAvailableTable();
    try {
      await apiService.terms.addTerm(id);
      await fetchAvailableTerms();
      await fetchCustomers();
      addNotification({
        text: 'Term was successfully added.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Term adding error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      toggleShowLoadingAvailableTable();
    }
  };

  const handleRowClick = (rowsSelected: any) => {
    dispatch(setContractor(rowsSelected));
    history.push({ pathname: `${location.pathname}/${rowsSelected.contractId}`, state: { contractStatus: rowsSelected.contractStatus } });
  };

  const handleSelectRow = (rowsSelected: any) => {
    if (showActionsList) toggleShowingActionsList();
    setContractSelected(rowsSelected[0]);
  };

  const handleUpdateStatus = async (action: string) => {
    toggleShowLoadingAvailableTable();
    toggleShowingActionsList();
    const { contractStatus } = contractSelected;
    const index = availableStatuses.indexOf(contractStatus);
    const newStatus = action == 'nextStatus' ? availableStatuses[index + 1] : availableStatuses[index - 1];
    if (action == 'revertStatus') await fetchRevertContractStatus();
    else await fetchUpdateContractStatus(newStatus);

    await fetchAvailableTerms();
    toggleShowLoadingAvailableTable();
  };

  const handleDeleteTerm: any = async () => {
    toggleDeletingConfirmation();
    toggleShowLoadingAvailableTable();
    try {
      await apiService.terms.deleteTerm(id, contractSelected.contractId);
      await fetchAvailableTerms();
      await fetchCustomers();
      dispatch(setContractor(null));
      addNotification({
        text: 'Selected term was successfully deleted.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Terms Listing deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      toggleShowLoadingAvailableTable();
    }
  };

  const handleShowDetails = () => {
    dispatch(setContractor(contractSelected));
    history.push({ pathname: `${location.pathname}/${contractSelected.contractId}`, state: { contractStatus: contractSelected.contractStatus } });
  };

  const handleBackToSearch = () => {
    dispatch(setShouldFilterRun(true));
    history.push('/terms');
  };

  useEffect(() => {
    setActionType(id == 'add' ? id : 'edit');
    if (id !== 'add') fetchCustomers();
  }, [id]);

  useEffect(() => {
    if (!contractSelected) return;
    const { contractStatus } = contractSelected;
    const index = availableStatuses.indexOf(contractStatus);
    const nextStatus = availableStatuses[index + 1];
    const previousStatus = index == 0 ? '' : availableStatuses[index - 1];
    setStatus({ nextStatus, previousStatus });
  }, [contractSelected]);

  const getStatusLabel = (idx: number) => status[Object.keys(status)[idx]] || '';

  return (
    <>
      <div className={styles.availableTermsContainer}>
        <div className={styles.topActionButtons}>
          <DefaultButton
            id='backToSearchButton'
            onClick={handleBackToSearch}
            text="Back to Search"
          />
        </div>
        <div className={styles.titleContainer}>
          <div className={styles.customerHeaderWrapper}>
            {customer &&
              <>
                {isEmpty(customer?.contractStatus) ? customer && <h1 className={styles.customerHeader}>{customer?.customerName} </h1> :
                  customer && <h1 className={styles.customerHeader}>{customer?.customerName} - {customer?.contractStatus} </h1>}
                <h2>Terms Listing</h2>
              </>
            }
          </div>
        </div>
        <div>
          <DataGridComponent
            idTable={'available-terms-table'}
            title=''
            headCells={searchTermHeadCell}
            defaultSorting={defaultSorting}
            rowsTable={state.items}
            totalDataFound={state.foundCount}
            enablePagination
            enableRowClick
            enableRowsPerPage
            enableSearching
            enableCheckBox
            handleRowClick={handleRowClick}
            handleSelectRow={handleSelectRow}
            handleChangeDataGridState={handleChangeDataGridState}
            isLoading={isLoadingAvailableTable}
          />
        </div>
        <div className={styles.bottomActionButtons}>
          <div className={styles.changeStatusesContainer}>
            <PrimaryButton
              text='Delete'
              disabled={disableButtons || !userPermissions.isWrite}
              onClick={toggleDeletingConfirmation}
            />
            <PrimaryButton
              id="addButton"
              text="+ Add New"
              onClick={handleAddTerm}
              disabled={!userPermissions.isWrite || isLoadingAvailableTable}
            />
            <PrimaryButton
              id="showDetailsButton"
              text="Show Details"
              disabled={isNil(contractSelected)}
              onClick={handleShowDetails}
            />
            <div className={classNames(styles.customListContainer, styles.marginLeftRight)} id='updatestatusContainer'>
              <DefaultButton
                id='updateButton'
                text="Update Status"
                onClick={toggleShowingActionsList}
                disabled={contractSelected == null || !userPermissions.isWrite}
              />
              {showActionsList && <div className={styles.customListOptionsContainer}>
                {termsActionsList.map((termsAction, idx) => (
                  <div
                    className={styles.customListOption}
                    key={`option-${idx}`}
                    onClick={(e) => handleUpdateStatus(termsAction.action)}
                  >
                    {termsAction.label}<b>{getStatusLabel(idx)}</b>
                  </div>
                ))}
              </div>}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        hidden={!showDeletingConfirmation}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: 'Are you sure you want to delete selected item?',
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDeleteTerm} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </>
  );
};
