import { FunctionComponent, useEffect, useMemo, useState, FormEvent } from 'react';
import classNames from 'classnames';
import { Checkbox, DatePicker, DefaultButton, IconButton, MessageBarType, Modal, PrimaryButton, Spinner, SpinnerSize, Text, TextField, DateRangeType, ChoiceGroup, Dialog, DialogFooter, DialogType, Dropdown, IColumn, IDropdownOption, MaskedTextField, IIconProps, Label } from '@fluentui/react';
import styles from './SpareStockPostingHistoryTab.module.scss';
import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import { SELL_BY_FIELDS, pageSizes, columns, printingTypes, downloadFile } from './consts';
import { FormBuilderGroup } from '../../../../shared/FormBuilderComponent';
import { useBoolean } from '@fluentui/react-hooks';
import { ISpareStockPostingHistoryTabState } from './ISparStockPostingHistoryTabState';
import { sortOrder } from '../../../../consts/sortOrder';
import apiService from '../../../../api';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import PrintingModal from '../../../PrintingModal/PrintingModal';
import useNotifications from '../../../../hooks/useNotifications';
import FilterModalWindow from './FilterModalWindow/FilterModalWindow';
import { initialFilterState } from './FilterModalWindow/consts';
import TabHeader from '../TabHeader/TabHeader';
import { useDispatch, useSelector } from 'react-redux';
import { ISearchState } from './ISearchState';
import { spareStockPostingHistoryItemsSelector, setSpareStockPostingHistoryItems } from '../../../../redux/recordKeepingSlice';
import moment from 'moment';
import useKeyPress from '../../../../hooks/useKeyPress/useKeyPress';

const filterIcon : IIconProps = {iconName: 'Equalizer'};

interface SpareStockPostingHistoryTabProps {
    value?: any;
  };

const SpareStockPostingHistoryTab: FunctionComponent<SpareStockPostingHistoryTabProps> = () => {

  const currentItem = useSelector(spareStockPostingHistoryItemsSelector);

  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const { addNotification } = useNotifications();
  
  const [paginationProps, setPaginationProps] = useState<any>({
    total: 0,
    current: 1,
  });

  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filtersState, setFiltersState] = useState<any>(initialFilterState);

  // form validators
  const sellByFieldsGroup = new FormBuilderGroup(SELL_BY_FIELDS);

  const dispatch = useDispatch();

  const [searchState, setSearchState] = useState<ISearchState>({
    loading: false,
    prefix: '',
    bNo: '',
    suffix: '',
    items: null,
    foundCount: 0,
  });

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(
      ({ isSorted }) => isSorted,
    );
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const handlePrint = async (printingType: any) => {
    setSearchState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const prefix = (currentItem.prefix) ? currentItem.prefix : searchState.prefix;
      const brandno = (currentItem.bNo) ? currentItem.bNo : searchState.bNo;
      const suffix = (currentItem.suffix != null || currentItem.suffix != '') ? currentItem.suffix : searchState.suffix;
      const headerFields = [
        { title: 'Customer Prefix', value: prefix },
      ];
      const sortOrder = getSortOrder();
      const input = {
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        filters: {...filtersState},
        prefix,
        brandno,
        suffix,
      };
      const requestData = {
        input: input,
        headerFields: headerFields,
      };
      
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.addInsAPI.printSpareStockPostingHistoryExcel(requestData) :
        await apiService.addInsAPI.printSpareStockPostingHistoryPdf(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setSearchState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSearch = async () => {
    setSearchState((prev) => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
  
      const { data }: any = await apiService.addInsAPI.getSpareStockPostingHistory(
        { pageSize: +countOnPage.key, pageNumber: paginationProps.current },
        sortOrder,
        {...filtersState},
        (currentItem.prefix) ? currentItem.prefix : searchState.prefix,
        (currentItem.bNo) ? currentItem.bNo : searchState.bNo,
        (currentItem.suffix != null || currentItem.suffix != '') ? currentItem.suffix : searchState.suffix,
      );

      const foundCount = data.total.found;
      const items = data.data;
      const newItem = {
        loading: true,
        prefix: (currentItem.prefix) ? currentItem.prefix : searchState.prefix,
        bNo: (currentItem.bNo) ? currentItem.bNo : searchState.bNo,
        suffix: (currentItem.suffix != null || currentItem.suffix != '') ? currentItem.suffix : searchState.suffix,
        items: items,
        foundCount: foundCount,
      };
      dispatch(setSpareStockPostingHistoryItems(newItem));
      setSearchState((prev: any) => ({...prev, items: items, foundCount, loading: false}));
    } catch (e: any) {
      const { response } = e;
    } finally {
      setSearchState((prev: any) => ({...prev, loading: false}));
    }   
  };

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const handlePrefixChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | undefined) => {
    dispatch(setSpareStockPostingHistoryItems({loading: false, prefix: value!.toUpperCase(), bNo: searchState.bNo, suffix: searchState.suffix, items: searchState.items, foundCount: searchState.foundCount}));
    setSearchState((prev: any) => ({ ...prev, prefix: value!.toUpperCase() }));
  };

  const handleBNoChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | undefined) => {
    dispatch(setSpareStockPostingHistoryItems({loading: false, prefix: searchState.prefix, bNo: value!.toUpperCase(), suffix: searchState.suffix, items: searchState.items, foundCount: searchState.foundCount}));
    setSearchState((prev: any) => ({ ...prev, bNo: value!.toUpperCase() }));
  };

  const handleSuffixChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | undefined) => {
    dispatch(setSpareStockPostingHistoryItems({loading: false, prefix: searchState.prefix, bNo: searchState.bNo, suffix: value!.toUpperCase(), items: searchState.items, foundCount: searchState.foundCount}));
    setSearchState((prev: any) => ({ ...prev, suffix: value!.toUpperCase() }));
  };

  useKeyPress({handleEnter: handleSearch});

  useEffect(() => {handleSearch(); }, [columnsState, filtersState]);

  return (
    <>
      <TabHeader
        title="Spare Stock Posting History"
      />
      <div>
        <Text variant="xLarge" className={styles.highlight}>Tire To Lookup</Text>
      </div>
      
      <div className={classNames(styles.searchPanelWrapper)}>
        <div className={classNames(styles.searchPanel)}>
          
          <div>
            <Label>Prefix</Label>
            <TextField
              value={currentItem.prefix ? currentItem.prefix : undefined}
              onChange={handlePrefixChange}
            />
          </div>
          <div>
            <Label>Brand Number</Label>
            <TextField
              value={currentItem.bNo ? currentItem.bNo : undefined}
              onChange={handleBNoChange}
            />
          </div>
          <div>
            <Label>Suffix</Label>
            <TextField
              value={currentItem.suffix ? currentItem.suffix : undefined}
              onChange={handleSuffixChange}
            />
          </div>
          <div>
            <PrimaryButton
              id='consignedTiresButton'
              text='Search'
              onClick={handleSearch}
            />
          </div>

        </div>
      </div>

      <div className={classNames('ms-Grid-col', 'ms-sm12')}>
        <div>
          <DefaultButton
            id="filterButton"
            className={classNames(styles.filterButton)}
            text="Filter"
            iconProps={filterIcon}
            onClick={() => setFilterVisible(true)}
          />
        </div>

        <div>
          <div className={styles.tableHeading}>
            <div>
              <Text variant="xLarge" className={styles.highlight}>Spare Stock Posting</Text>
              <SeparatorGy vertical />
              <Text variant="xLarge" className={styles.highlight}>{currentItem.foundCount} found</Text>
            </div>
            <div>
              <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
              <Dropdown
                options={pageSizes.map(pageSize => ({
                  key: pageSize,
                  text: pageSize.toString(),
                }))}
                defaultSelectedKey={pageSizes[0]}
                selectedKey={countOnPage?.key}
                onChange={onChangeCountOnPage}
              />

            </div>
          </div>

          <div className = {styles['table-wrapper']}>
            <table>
              <thead>
                <tr>
                  {columnsState.map(item => (<th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>))}
                </tr>
              </thead>
              <tbody>
                { (currentItem.items) &&
                    currentItem.items.map((item: any, key: any) => (
                      <tr key={key} className={styles.trBasic}>
                        <td className={styles.vNo}>
                          {item.account}
                        </td>
                        <td className={styles.vNo}>
                          {item.loc}
                        </td>
                        <td className={styles.vNo}>
                          <DatePicker
                            value={(moment(item.inventoryDate).toDate())}
                            formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                            disabled
                          />
                        </td>
                        <td className={styles.vNo}>
                          {item.inventoryBy}
                        </td>
                        <td className={styles.vNo}>
                          {item.annual}
                        </td>
                        <td className={styles.vNo}>
                          {item.submitInfo}
                        </td>
                        <td className={styles.vNo}>
                          {item.submit}
                        </td>
                      </tr>
                    ))                  
                }
              </tbody>
            </table>
          </div>

        </div>

        <SeparatorGy />

        <div className={classNames(styles.buttonsWrapper)}>
          <DefaultButton
            id="printExportButton"
            onClick={toggleShowPrintExport}
            text="Print/Export"
          />
        </div>
      </div>
      
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />

      {filterVisible && <FilterModalWindow isOpened={setFilterVisible} filtersState={filtersState} setFiltersState={setFiltersState} />}

      {searchState.loading && <LoadingScreen />}
    </>
  );
};

export default SpareStockPostingHistoryTab;