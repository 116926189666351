import { HeadCell } from '../../../../../shared/DataGridComponent/DataGridModels';
import { IFormDataField } from '../../../../../shared/FormBuilderComponent';

export const miscOptionsHeadCells: HeadCell[] = [
  {
    key: 'column0',
    name: 'Allow',
    fieldName: 'allow',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Misc Type',
    fieldName: 'miscType',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Rate Type',
    fieldName: 'rateType',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Price',
    fieldName: 'rateCharge',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'Eff Date',
    fieldName: 'effDate',
    typeField: 'date',
    isEditable: false,
  },
  {
    key: 'column5',
    name: 'Duration',
    fieldName: 'validity',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column6',
    name: 'Cost',
    fieldName: 'cost',
    typeField: 'text',
    isEditable: false,
  },
];

export const billingOptionsHeadCells: HeadCell[] = [
  {
    key: 'column0',
    name: 'Billing Option',
    fieldName: 'name',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Applicable',
    fieldName: 'applicable',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Bill By',
    fieldName: 'billBy',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Bill To',
    fieldName: 'billTo',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'Gen Opt',
    fieldName: 'genOpt',
    typeField: 'text',
    isEditable: false,
  },
];
  
export const reportOptionsHeadCells: HeadCell[] = [
  {
    key: 'column0',
    name: 'Reports',
    fieldName: 'name',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Autogenerate',
    fieldName: 'autogenerate',
    typeField: 'text',
    isEditable: false,
  },
];

export const MISC_OPTIONS_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'allowField',
    properties: {
      id: 'allow',
      label: 'Allow',
      name: 'allow',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'rateTypeField',
    properties: {
      id: 'rateType',
      label: 'Rate Type',
      name: 'rateType',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'rateChargeField',
    properties: {
      id: 'rateCharge',
      label: 'Price',
      name: 'rateCharge',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'effDateField',
    properties: {
      id: 'effDate',
      label: 'Eff Date',
      name: 'effDate',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'validityField',
    properties: {
      id: 'validity',
      label: 'Duration',
      name: 'validity',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'costField',
    properties: {
      id: 'cost',
      label: 'Cost',
      name: 'cost',
      type: 'text',
      disabled: false,
    },
  },
];

export const BILLING_OPTIONS_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'applicableField',
    properties: {
      id: 'applicable',
      label: 'Applicable',
      name: 'applicable',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'billByField',
    properties: {
      id: 'billBy',
      label: 'Bill By',
      name: 'billBy',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'billToField',
    properties: {
      id: 'billTo',
      label: 'Bill To',
      name: 'billTo',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'genOptField',
    properties: {
      id: 'genOpt',
      label: 'Gen Opt',
      name: 'genOpt',
      type: 'text',
      disabled: false,
    },
  },
];

export const REPORT_OPTIONS_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'autogenerateField',
    properties: {
      id: 'autogenerate',
      label: 'Autogenerate',
      name: 'autogenerate',
      type: 'text',
      disabled: false,
    },
  },
];

export const booleanOptions = ['Y', 'N'];
