import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IContractorState {
  contract: any;
}

const initialState: IContractorState = {
  contract: null,
};

export const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    setContractor: (state, action: PayloadAction<any>) => {
      state.contract = { ...state.contract, ...action.payload };
    },
  },
});

export const { setContractor } = contractSlice.actions;

export const contractorSelector = ({ contract }: any) => contract.contract;

export default contractSlice.reducer;