import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';
import { Pivot, PivotItem } from '@fluentui/react';

import TabHeader from '../TabHeader/TabHeader';

import { IActionOnVehiclesTabProps } from './IActionOnVehiclesTabProps';
import { actionOnVehiclesTabs } from './consts';

import RegisterVehicles from './tabComponents/RegisterVehicles/RegisterVehicles';

import styles from './ActionOnVehiclesTab.module.scss';
import ViewVehicleHistory from './tabComponents/ViewVehicleHistory/ViewVehicleHistory';

import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { auth_actionOnVehicles_registerVehicles, auth_actionOnVehicles_viewVehicleHistory} from '../../../../consts/programKeys';

const ActionOnVehiclesTab: FC<IActionOnVehiclesTabProps> = (): ReactElement => {

  const { hasPermission } = useUserPermissions();
  const userPermissions_registerVehicle = hasPermission(auth_actionOnVehicles_registerVehicles);
  const userPermissions_viewVehicleHistory = hasPermission(auth_actionOnVehicles_viewVehicleHistory);


  const [selectedKey, setSelectedKey] = React.useState(actionOnVehiclesTabs.registerVehicles);

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  return (
    <div>
      <TabHeader
        details
      />
      <Pivot
        className={classNames('pivot-container')}
        selectedKey={selectedKey}
        onLinkClick={handleLinkClick}
        getTabId={getTabId}
        linkFormat="tabs"
      >

        {userPermissions_registerVehicle.isAccess && <PivotItem headerText={'Register Vehicles'} itemKey={'Register Vehicles'}>
          <div className="vertical-pivot-container"><RegisterVehicles /></div>
        </PivotItem>}
        {userPermissions_viewVehicleHistory.isAccess && <PivotItem headerText={'View Vehicle History'} itemKey={'View Vehicle History'}>
          <div className="vertical-pivot-container"><ViewVehicleHistory /></div>
        </PivotItem>}

      </Pivot>
    </div>
  );
};

export default ActionOnVehiclesTab;
