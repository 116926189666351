import { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { DefaultButton, IconButton, MessageBarType, Modal, PrimaryButton, Text, TextField } from '@fluentui/react';
import DataGridComponent from '../../../../../../shared/DataGridComponent';
import { useBoolean } from '@fluentui/react-hooks';
import { productTableHeadCells, IProductData, ProductModalComponentProps, PRODUCT_FIELDS } from '../consts';
import useNotifications from '../../../../../../hooks/useNotifications';
import styles from './ProductModal.module.scss';
import { debounce } from 'lodash';
import apiService from '../../../../../../api';
import { FormBuilderGroup } from '../../../../../../shared/FormBuilderComponent';
import { INotification } from '../../../../../../models/INotification';
import { initalDataGridState } from '../../../../../../shared/DataGridComponent/utils';
import AutoCompleteField from '../../../../../Common/Search/AutoCompleteField';

const ProductModalComponent: FunctionComponent<ProductModalComponentProps> = ({
  fleetId,
  products,
  id,
  title,
  isOpen,
  onSubmit,
  onUpdateProduct,
  onDismiss,
  successLabel,
  cancelLabel }) => {

  //States
  const [productList, setProductList] = useState<Array<IProductData>>(products ? products : []);
  const { addNotification } = useNotifications();
  const [productSelected, setProductSelected] = useState<IProductData[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<IProductData[]>([]);
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });
  const hasOneProductSelected = productSelected.length == 1;
  const [dataGridState, setdataGridState] = useState(initalDataGridState);
  const [totalProducts, setTotalProducts] = useState(0);
  const [codes, setCodes] = useState<string[]>([]);
  const [completeProducts, setCompleteProducts] = useState<any[]>([]);
  const [selectedProductCode, setSelectedProductCode] = useState<string>();

  //Toggles
  const [isLoading, { toggle: toggleLoading }] = useBoolean(false);
  const [isLoadingCodes, { toggle: toggleLoadingCodes }] = useBoolean(false);

  //Forms
  const productFormFieldsGroup = new FormBuilderGroup(PRODUCT_FIELDS);

  const handleSelectRow = async (rowsSelected: any) => {
    setProductSelected(rowsSelected);
    productCodeInputText(rowsSelected[0]?.productCode);
    if (rowsSelected.length == 1) {
      productFormFieldsGroup.setFormValue('productIdField', rowsSelected[0]?.authprodId);
      productFormFieldsGroup.setFormValue('productCodeField', rowsSelected[0]?.productCode);
      productFormFieldsGroup.setFormValue('productDescriptionField', rowsSelected[0]?.description);
      productFormFieldsGroup.setFormValue('tireSizeField', rowsSelected[0]?.tireSize);
      productFormFieldsGroup.setFormValue('bogeyField', rowsSelected[0]?.bogey);
      productFormFieldsGroup.setFormValue('usageField', rowsSelected[0]?.percUsage);
    }
    else {
      productFormFieldsGroup.cleanFormData();
    }
  };

  const getProducts = async (dataGridState: any) => {
    const pagination = {
      pageSize: dataGridState.countOnPage.text,
      pageNumber: dataGridState.paginationProps.current,
    };
    const sortOrder = {
      column: dataGridState.sortOrder?.column === '' ? 'productCode' : (dataGridState.sortOrder?.column === 'bogey' ? 'bogeyUnits' :(dataGridState.sortOrder?.column === 'percUsage' ? 'pctUsage': dataGridState.sortOrder?.column)),
      order: dataGridState.sortOrder?.order,
    };
    const payload = {
      fleetId: fleetId,
      pagination: pagination,
      sortOrder: sortOrder,
      filterText: dataGridState.searchedText,
    };
    try {
      // const { data } = await apiService.customerAPI.getProducts(payload);
      // setTotalProducts(data.total.found);
      // setProductList(data.data);
    } catch (e: any) {
      setNotification({ text: 'Error getting products', type: MessageBarType.error });
    }
  };

  const addProduct = async () => {
    toggleLoading();
    const payload = {
      fleetId,
      productCode: productFormFieldsGroup.getFieldFormValue('productCodeField'),
      tireSize: productFormFieldsGroup.getFieldFormValue('tireSizeField'),
      description: productFormFieldsGroup.getFieldFormValue('productDescriptionField'),
      percUsage: +productFormFieldsGroup.getFieldFormValue('usageField'),
      bogey: productFormFieldsGroup.getFieldFormValue('bogeyField'),
    };
    try {
      await apiService.customerAPI.addProduct(payload);
      await getProducts(dataGridState);
      addNotification({ text: 'Products added successfully', type: MessageBarType.success });
    }
    catch (e: any) {
      const { response } = e;
      const errorState = response?.data?.state;
      const errorMsg = errorState?.length ? errorState[0]?.message : errorState?.message;
      addNotification({ text: `Error adding products: ${errorMsg}`, type: MessageBarType.error });
    }
    finally {
      toggleLoading();
    }
  };

  const updateProduct = async () => {
    toggleLoading();
    const payload = {
      fleetId: fleetId,
      productId: productFormFieldsGroup.getFieldFormValue('productIdField'),
      productCode: productFormFieldsGroup.getFieldFormValue('productCodeField'),
      tireSize: productFormFieldsGroup.getFieldFormValue('tireSizeField'),
      description: productFormFieldsGroup.getFieldFormValue('productDescriptionField'),
      percUsage: +productFormFieldsGroup.getFieldFormValue('usageField'),
      bogey: productFormFieldsGroup.getFieldFormValue('bogeyField'),
    };
    try {
      await apiService.customerAPI.updateProduct(payload);
      setNotification({ text: 'Product updated successfully.', type: MessageBarType.success });
      await getProducts(dataGridState);
    } catch (e: any) {
      setNotification({ text: 'Error updating products', type: MessageBarType.error });
    }
    finally {
      toggleLoading();
    }
  };

  const handleStateChange = async (newState: any) => {
    toggleLoading();
    if (dataGridState.countOnPage.key != newState.countOnPage.key)
      newState.paginationProps.current = 1;
    setdataGridState(newState);
    await getProducts(newState);
    toggleLoading();
  };

  const handleDelete = async () => {
    toggleLoading();
    const ids: any[] = [];
    productSelected.forEach(product => {
      ids.push(product.authprodId);
    });
    const payload = {
      productIds: ids,
    };
    try {
      await apiService.customerAPI.deleteProducts(payload);
      await getProducts(dataGridState);
      addNotification({
        text: 'Item(s) is successfully deleted.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      setNotification({ text: 'Error deleting fleet', type: MessageBarType.error });
      console.log('not deleted');
    }
    finally {
      toggleLoading();
    }
  };

  const deleteAll = async () => {
    toggleLoading();
    const payload = {
      fleetId: fleetId,
    };
    try {
      await apiService.customerAPI.deleteAllProducts(payload);
      await getProducts(dataGridState);
      setNotification({ text: 'All Products deleted', type: MessageBarType.success });
    } catch (e: any) {
      setNotification({ text: 'Error deleting all products', type: MessageBarType.error });
    }
    finally {
      toggleLoading();
    };
  };

  const getProductCodeByText = (textToFind: string) => codes?.find(element => element === textToFind);

  const removeEmpty = ({ text }: any) => text !== null;

  const getProductCodes = async (filter?: string) => {
    toggleLoadingCodes();
    try {
      const payload = {
        filters: {
          code: filter,
        },
      };

      const { data } = await apiService.customerAPI.listProducts(payload);
      const productCodes: any = [];
      data.data.forEach((row: any) => {
        productCodes.push(row.code);
      });
      setCodes(productCodes);
      setCompleteProducts(data.data);
    } catch (e: any) {
      setNotification({ text: 'Error getting models', type: MessageBarType.error });
    }
    finally {
      toggleLoadingCodes();
    }
  };

  const productCodeList = selectedProducts?.length == 1 ?
    [
      {
        key: productFormFieldsGroup.getFieldFormValue('productCodeField'),
        text: productFormFieldsGroup.getFieldFormValue('productCodeField'),
      },
    ] :
    codes?.map((code: any) => ({
      key: code,
      text: code,
    })).filter(removeEmpty);

  const onProductCodeChange = (product: any) => {
    setProductFieldsByCode(product);
    setSelectedProductCode(getProductCodeByText(product));
    productFormFieldsGroup.setFormValue('productCodeField', product);
  };

  const setProductFieldsByCode = (code: string) => {
    const found = completeProducts.find(e => e.code === code);
    productFormFieldsGroup.setFormValue('productDescriptionField', found?.description);
    productFormFieldsGroup.setFormValue('tireSizeField', found?.tireSize);
  };

  const productCodeInputText = (text: string) => {
    getProductsDebounce(text);
    if (onProductCodeChange) {
      onProductCodeChange(text);
    }
  };

  const getProductsDebounce = debounce(async (productCode: any) => {
    getProductCodes(productCode);
  }, 1000);

  useEffect(() => {
    if (!fleetId) return;
    getProducts(dataGridState);
    getProductCodes();
  }, [fleetId]);


  return (
    <>
      <Modal
        key={id}
        isOpen={isOpen}
        onDismiss={onDismiss}
        containerClassName='modalContainer'
      >
        <div className={styles.modalBody}>
          <div className='modalHeader'>
            <Text variant="xLarge">{title}</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel="Close popup modal"
              onClick={onDismiss}
            />
          </div>
          <div className={styles.formContainer}>
            <div className={styles.divAutoCompleteInput}>
              <AutoCompleteField
                initialValue={productFormFieldsGroup.getFieldFormValue('productCodeField')}
                onChangeAction={onProductCodeChange}
                list={productCodeList}
                label="Product Code"
                data-testid='product-code'
                icon="car"
                isLoading={isLoadingCodes}
                textValue={productCodeInputText}
              />
            </div>
            <TextField {...productFormFieldsGroup.getFieldForm('productDescriptionField')} />
            <TextField {...productFormFieldsGroup.getFieldForm('tireSizeField')} />
            <TextField {...productFormFieldsGroup.getFieldForm('bogeyField')} />
            <TextField {...productFormFieldsGroup.getFieldForm('usageField')} />
          </div>
          <div className={styles.modalButtonsDiv}>
            <PrimaryButton
              id='addButton'
              text='Add Product'
              disabled={hasOneProductSelected}
              onClick={addProduct}
            />
            <PrimaryButton
              id='editButton'
              text='Update Product'
              disabled={!hasOneProductSelected}
              onClick={updateProduct}
            />
            <DefaultButton
              id='deleteAll'
              text='Clear all Products'
              onClick={deleteAll}
            />
          </div>
          <div className='tableContainer'>
            <DataGridComponent
              idTable={'productTable'}
              title={'Products'}
              headCells={productTableHeadCells}
              rowsTable={productList}
              totalDataFound={totalProducts}
              isLoading={isLoading}
              enableDeleteOption={true}
              enableMultiSelectRow={true}
              enableEditableRows={false}
              enableCheckBox={true}
              enableSearching={true}
              enablePagination={true}
              enableRowsPerPage={true}
              handleDelete={handleDelete}
              reloadControlsTable={true}
              handleChangeDataGridState={handleStateChange}
              handleSelectRow={handleSelectRow}
            />
          </div>

          <div className={styles.modalFooter}>
            <DefaultButton
              id="cancelButton"
              text={cancelLabel}
              onClick={onDismiss}
            />
            <PrimaryButton
              id="selectButton"
              text={successLabel}
              onClick={(e) => onSubmit(productList)}
            />
          </div>
        </div>
      </Modal>
    </>);
};

export default ProductModalComponent;