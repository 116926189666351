import { IDropdownOption, MessageBarType, PrimaryButton, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { debounce } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import apiService from '../../api';
import AutoCompleteField from '../../components/Common/Search/AutoCompleteField';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { quoteStatuses } from '../../components/Quote/const';
import useKeyPress from '../../hooks/useKeyPress/useKeyPress';
import useNotifications from '../../hooks/useNotifications';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import { IMakeModel } from '../../models/IMakeModel';
import { selectedQuoteSelector, setSelectedQuote } from '../../redux/quoteSlice';
import AutocompleteInput from '../AutocompleteInput';
import DataGridComponent from '../DataGridComponent';
import { FormBuilderGroup } from '../FormBuilderComponent';
import ProductModalComponent from '../ProductsModalComponent';
import { integerEMHandler } from '../TextFieldValidation';
import { makeModelHeadCells, MAKE_MODEL_GROUPS_FIELDS, fleetsTableHeadCells } from './consts';
import styles from './MakeModel.module.scss';
import MakeModelTable from './MakeModelTable';

interface MakeModelComponentProps {
  customerId: string,
  fetchAPI: any,
  deleteAPI: any,
  addAPI: any,
  updateAPI: any,
  cloneAPI: any,
  getRatesListAPI: any,
  disableEditing?: boolean,
  permissionKey: string,
  parentComponentName: string,
  refreshRateGroupList: boolean,
  onRefreshRateGroupList: any,
}

const MakeModelComponent: FunctionComponent<MakeModelComponentProps> = ({
  customerId,
  fetchAPI,
  deleteAPI,
  addAPI,
  updateAPI,
  cloneAPI,
  getRatesListAPI,
  disableEditing,
  permissionKey,
  parentComponentName,
  refreshRateGroupList,
  onRefreshRateGroupList,
}) => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(permissionKey);

  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { contractId } = useParams<{ contractId: string }>();
  const selectedQuote = parentComponentName === 'quote' ? useSelector(selectedQuoteSelector) : undefined;

  const { addNotification } = useNotifications();

  // state
  const [isLoading, setLoading] = useState<boolean>(false);
  const [makeModelList, setMakeModelList] = useState<Array<IMakeModel>>([]);
  const [totalFound, setTotalFound] = useState(0);
  const [makeModelListPagination, setMakeModelListPagination] = useState<any>({ pageSize: 10, pageNumber: 1 });
  const [makeModelListSortOrder, setMakeModelListSortOrder] = useState<any>({ column: makeModelHeadCells[0].fieldName, order: 'asc' });
  const [makeModelsSelected, setMakeModelsSelected] = useState<Array<IMakeModel>>([]);
  const [vehicleMakes, setVehicleMakes] = useState<any>([]);
  const [models, setModels] = useState<string[]>([]);
  const [selectedVehicleMake, setSelectedVehicleMake] = useState<string>();
  const [selectedVehicleModel, setSelectedVehicleModel] = useState<string>();
  const [allRates, setAllRates] = useState<any>([]);

  const [isLoadingTable, setLoadingTable] = useState<boolean>(false);
  const [loadingModels, { toggle: toggleModelsLoading }] = useBoolean(false);

  const [showProductModal, { toggle: toggleShowProductModal }] = useBoolean(false);

  //form validators
  const makeModelFieldsGroup = new FormBuilderGroup(MAKE_MODEL_GROUPS_FIELDS);

  const getAllModels = async (filter?: string) => {
    toggleModelsLoading();
    try {
      const payload = {
        filters: {
          make: filter,
        },
        quoteId: selectedQuote?.id,
        quoteVersion: selectedQuote?.version,
      };
      const { data } = await apiService.customerAPI.listMakeModels(payload);
      setVehicleMakes(data.data);
    } catch (e: any) {
      addNotification({ text: 'Error getting models', type: MessageBarType.error });
    }
    finally {
      toggleModelsLoading();
    }
  };

  const fetchMakeModels = async (
    pagination: any = makeModelListPagination,
    filterText?: any,
    sortOrder: any = makeModelListSortOrder,
  ) => {
    setLoadingTable(true);
    try {
      const { data: { data, total: { found } } }: any = await fetchAPI(pagination, [sortOrder], filterText, id, contractId, selectedQuote?.id, selectedQuote?.version);
      setMakeModelList(data);
      setTotalFound(found);
      setMakeModelListPagination(pagination);
      setMakeModelListSortOrder(sortOrder);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Make Models fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoadingTable(false);
    }
  };

  const fetchRatesList = async () => {
    const { data }: any = await getRatesListAPI(undefined, undefined, undefined, customerId, contractId, selectedQuote?.id, selectedQuote?.version);
    setAllRates(data.data);
    if (refreshRateGroupList) {
      onRefreshRateGroupList();
    }
  };

  const handleChangeTable = async (dataGridState: any) => {
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    fetchMakeModels(pagination, searchedText, sortOrder);
  };

  const onAddMakeModel = async () => {
    setLoading(true);
    try {
      await addAPI({
        customerId,
        quoteId: selectedQuote?.id,
        quoteVersion: selectedQuote?.version,
        data: [{
          contractId: contractId,
          quoteId: selectedQuote?.id,
          quoteVersion: selectedQuote?.version,
          rateGroupId: makeModelFieldsGroup.getFieldFormValue('rateField'),
          monthlyVehicleUnits: makeModelFieldsGroup.getFieldFormValue('monthlyVehicleUnitsField'),
          numberOfVehicleUnits: makeModelFieldsGroup.getFieldFormValue('numberOfVehicleUnitsField'),
          numberOfWheels: makeModelFieldsGroup.getFieldFormValue('numberOfWheelsField'),
          products: [],
          vehicleMake: makeModelFieldsGroup.getFieldFormValue('vehicleMakeField'),
          vehicleModel: makeModelFieldsGroup.getFieldFormValue('vehicleModelField'),
        }],
      });
      if (selectedQuote?.status === quoteStatuses.GENERATED)
        dispatch(setSelectedQuote({ ...selectedQuote, status: quoteStatuses.REGENERATED }));
      addNotification({
        text: 'Make Model was successfully added.',
        type: MessageBarType.success,
      });
      fetchMakeModels();
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Make Model adding error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const onUpdateMakeModel = async () => {
    setLoading(true);
    try {
      await updateAPI({
        customerId,
        quoteId: selectedQuote?.id,
        quoteVersion: selectedQuote?.version,
        data: [{
          ...makeModelsSelected[0],
          quoteId: selectedQuote?.id,
          rateGroupId: makeModelFieldsGroup.getFieldFormValue('rateField'),
          monthlyVehicleUnits: makeModelFieldsGroup.getFieldFormValue('monthlyVehicleUnitsField'),
          numberOfVehicleUnits: makeModelFieldsGroup.getFieldFormValue('numberOfVehicleUnitsField'),
          numberOfWheels: makeModelFieldsGroup.getFieldFormValue('numberOfWheelsField'),
          vehicleMake: makeModelFieldsGroup.getFieldFormValue('vehicleMakeField'),
          vehicleModel: makeModelFieldsGroup.getFieldFormValue('vehicleModelField'),
        }],
      });
      if (selectedQuote?.status === quoteStatuses.GENERATED)
        dispatch(setSelectedQuote({ ...selectedQuote, status: quoteStatuses.REGENERATED }));
      addNotification({
        text: 'Make Model was successfully updated.',
        type: MessageBarType.success,
      });
      fetchMakeModels();
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Make Model updating error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteMakeModel = async (rowsToDelete: Array<IMakeModel> = []) => {
    setLoadingTable(true);
    try {
      await deleteAPI(rowsToDelete.map(el => el.mCbFleetid), selectedQuote?.id, selectedQuote?.version);
      if (selectedQuote?.status === quoteStatuses.GENERATED)
        dispatch(setSelectedQuote({ ...selectedQuote, status: quoteStatuses.REGENERATED }));
      addNotification({
        text: 'Make Model(s) were successfully deleted.',
        type: MessageBarType.success,
      });
      setMakeModelsSelected([]);
      fetchMakeModels();
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Make Model(s) deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoadingTable(false);
    }
  };

  const onCloneMakeModel = async () => {
    setLoadingTable(true);
    try {
      await cloneAPI(makeModelsSelected.map(el => el.mCbFleetid), selectedQuote?.id, selectedQuote?.version);
      if (selectedQuote?.status === quoteStatuses.GENERATED)
        dispatch(setSelectedQuote({ ...selectedQuote, status: quoteStatuses.REGENERATED }));
      addNotification({
        text: 'Make Model(s) were successfully cloned.',
        type: MessageBarType.success,
      });
      setMakeModelsSelected([]);
      fetchMakeModels();
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Make Model(s) cloning error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoadingTable(false);
    }
  };

  const removeEmpty = ({ text }: any) => text !== null;

  const makeNameList = selectedVehicleMake?.length == 1 ?
    [
      {
        key: makeModelFieldsGroup.getFieldFormValue('vehicleMakeField'),
        text: makeModelFieldsGroup.getFieldFormValue('vehicleMakeField'),
      },
    ] :
    vehicleMakes?.map(({ make }: any) => ({
      key: make,
      text: make,
    })).filter(removeEmpty);

  const modelNameList = selectedVehicleModel?.length == 1 ?
    [{
      key: makeModelFieldsGroup.getFieldFormValue('vehicleModelField'),
      text: makeModelFieldsGroup.getFieldFormValue('vehicleModelField'),
    }] :
    models ? models?.map((model: any) => ({
      key: model,
      text: model,
    })).filter(removeEmpty) : [];

  const vehicleMakeInputText = (vehicleMake: string) => {
    getAllMakes(vehicleMake);
    if (onVehicleMakeChange) {
      onVehicleMakeChange(vehicleMake);
    }
  };

  const vehicleModelInputText = (vehicleModel: string) => {
    if (!vehicleMakes.length) return;
    const modelsFiltered = filterModelByText(vehicleModel.toLocaleLowerCase())?.map(({ model }: any) => (model));
    setModels(modelsFiltered);
    if (onVehicleModelChange) {
      onVehicleModelChange(vehicleModel);
    }
  };

  const onChangeRate = (item: IDropdownOption<any> | undefined): void => makeModelFieldsGroup.setFormValue('rateField', item);
  const getVehicleMakeByText = (textToFind: string) => vehicleMakes?.find((element: string) => element === textToFind);
  const getVehicleModelByText = (textToFind: string) => models?.find(element => element === textToFind);
  const getVehicleModelByVehicleMake = (textToFind: string) => vehicleMakes?.find(({ make }: any) => make === textToFind);
  const filterModelByText = (textToFind: string) => vehicleMakes[0]?.models?.filter(({ model }: any) => model.toLowerCase().includes(textToFind));


  const onVehicleMakeChange = async (make: any) => {
    setSelectedVehicleMake(getVehicleMakeByText(make));
    makeModelFieldsGroup.setFormValue('vehicleMakeField', make);
    makeModelFieldsGroup.setFormValue('vehicleModelField', '');
    const modelsFiltered = getVehicleModelByVehicleMake(make);
    const models: string[] = [];
    modelsFiltered?.models.forEach((row: any) => {
      models.push(row.model);
    });
    setModels(models);
  };

  const onVehicleModelChange = async (model: any) => {
    setSelectedVehicleModel(getVehicleModelByText(model));
    makeModelFieldsGroup.setFormValue('vehicleModelField', model);
  };

  const onClearFields = () => {
    makeModelFieldsGroup.setFormValue('idField', '');
    makeModelFieldsGroup.setFormValue('rateField', '');
    makeModelFieldsGroup.setFormValue('vehicleMakeField', '');
    makeModelFieldsGroup.setFormValue('vehicleModelField', '');
    makeModelFieldsGroup.setFormValue('numberOfWheelsField', '');
    makeModelFieldsGroup.setFormValue('numberOfVehicleUnitsField', '');
    makeModelFieldsGroup.setFormValue('monthlyVehicleUnitsField', '');
  };

  const getAllMakes = debounce(async (makeName) => {
    getAllModels(makeName);
  }, 1000);

  useEffect(() => {
    if (makeModelsSelected.length == 1) {
      makeModelFieldsGroup.setFormValue('idField', makeModelsSelected[0]?.id);
      makeModelFieldsGroup.setFormValue('rateField', makeModelsSelected[0]?.mCbRategroupidFk);
      makeModelFieldsGroup.setFormValue('vehicleMakeField', makeModelsSelected[0]?.vehicleMake);
      makeModelFieldsGroup.setFormValue('vehicleModelField', makeModelsSelected[0]?.vehicleModel);
      makeModelFieldsGroup.setFormValue('numberOfWheelsField', `${makeModelsSelected[0]?.numberOfWheels}`);
      makeModelFieldsGroup.setFormValue('numberOfVehicleUnitsField', `${makeModelsSelected[0]?.numberOfVehicleUnits}`);
      makeModelFieldsGroup.setFormValue('monthlyVehicleUnitsField', `${makeModelsSelected[0]?.monthlyVehicleUnits}`);
    }
    else {
      makeModelFieldsGroup.cleanFormData();
    }
  }, [makeModelsSelected]);

  useKeyPress({
    handleAdd: onAddMakeModel,
    handleSubmit: onClearFields,
  });

  useEffect(() => {
    fetchRatesList();
    fetchMakeModels();
  }, []);

  useEffect(() => {
    if (refreshRateGroupList) {
      fetchRatesList();
      fetchMakeModels();
    }
  }, [refreshRateGroupList]);


  const isMakeModelFieldsGroupValid = makeModelFieldsGroup.getFieldFormValue('vehicleMakeField')
    && makeModelFieldsGroup.getFieldFormValue('vehicleModelField')
    && makeModelFieldsGroup.getFieldFormValue('numberOfWheelsField')
    && makeModelFieldsGroup.getFieldFormValue('numberOfVehicleUnitsField')
    && makeModelFieldsGroup.getFieldFormValue('monthlyVehicleUnitsField');


  const isMakeModelFormFilled = makeModelFieldsGroup.getFieldFormValue('rateField')
    || makeModelFieldsGroup.getFieldFormValue('vehicleMakeField')
    || makeModelFieldsGroup.getFieldFormValue('vehicleModelField')
    || makeModelFieldsGroup.getFieldFormValue('numberOfWheelsField')
    || makeModelFieldsGroup.getFieldFormValue('numberOfVehicleUnitsField')
    || makeModelFieldsGroup.getFieldFormValue('monthlyVehicleUnitsField');

  return (
    <>
      <div className='table-container'>
        <div className={styles.formContainer}>
          <AutocompleteInput
            label='Rate'
            value={makeModelFieldsGroup.getFieldFormValue('rateField')}
            list={allRates.map((rate: any) => ({
              key: rate.id,
              text: rate.rategroupName,
            }))}
            chooseCurrentItem={onChangeRate}
            emptyExpanded
            upperCase
          />
          <AutoCompleteField
            initialValue={makeModelFieldsGroup.getFieldFormValue('vehicleMakeField')}
            onChangeAction={onVehicleMakeChange}
            list={makeNameList}
            label="Vehicle Makes"
            data-testid='vehicle-makes'
            icon="car"
            isLoading={loadingModels}
            textValue={vehicleMakeInputText}
            required
            upperCase
          />
          <AutoCompleteField
            initialValue={makeModelFieldsGroup.getFieldFormValue('vehicleModelField')}
            onChangeAction={onVehicleModelChange}
            list={modelNameList}
            label="Vehicle Models"
            data-testid='vehicle-models'
            icon="car"
            isLoading={loadingModels}
            textValue={vehicleModelInputText}
            required
            upperCase
            
          />
          <TextField {...makeModelFieldsGroup.getFieldForm('numberOfWheelsField')} onGetErrorMessage={integerEMHandler} />
          <TextField {...makeModelFieldsGroup.getFieldForm('numberOfVehicleUnitsField')} onGetErrorMessage={integerEMHandler} />
          <TextField {...makeModelFieldsGroup.getFieldForm('monthlyVehicleUnitsField')} onGetErrorMessage={integerEMHandler} />
        </div>
        <div className={styles.buttonContainer}>
          {!disableEditing && <div className={styles.btnContainer}>
            <PrimaryButton
              id='addBtn'
              onClick={onAddMakeModel}
              disabled={!(userPermissions.isWrite && makeModelsSelected.length !== 1 && isMakeModelFieldsGroupValid)}
              text='Add new Make/Model'
            />
            <PrimaryButton
              id='updateBtn'
              onClick={onUpdateMakeModel}
              disabled={!(userPermissions.isWrite && makeModelsSelected.length === 1 && isMakeModelFieldsGroupValid)}
              text='Update Make/Model'
            />
            <PrimaryButton
              id='cloneBtn'
              onClick={onCloneMakeModel}
              disabled={!(userPermissions.isWrite && makeModelsSelected.length !== 0)}
              text='Clone'
            />
          </div>}
        </div>
        <MakeModelTable
          makeModelList={makeModelList}
          parentComponentName={parentComponentName}
          permissionKey={permissionKey}
          isLoading={isLoadingTable}
          makeModelsSelected={makeModelsSelected}
          handleSelectRow={setMakeModelsSelected}
          handleDelete={handleDeleteMakeModel}
          totalDataFound={totalFound}
          fetchMakeModels={fetchMakeModels}
        />
      </div>
      {
        <ProductModalComponent
          id='productModal'
          title={makeModelsSelected[0]?.vehicleMake}
          fleetId={makeModelsSelected[0]?.mCbFleetid as string}
          isOpen={showProductModal}
          onDismiss={toggleShowProductModal}
          cancelLabel='Cancel'
          permissionKey={permissionKey}
          parentComponentName={parentComponentName}
        />
      }
      {isLoading && <LoadingScreen />}
    </>
  );
};

export default MakeModelComponent;