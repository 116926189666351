import { FunctionComponent, useEffect } from 'react';
import ViewMaintainInvoicesModalComponent from '../../../ViewMaintainInvoicesModalComponent';
import { useBoolean } from '@fluentui/react-hooks';
import { IconButton } from '@fluentui/react';
import { IAvailableInvoice } from '../../../../models/IAvailableInvoice';
import SchedulingDetailsModal from '../../../../components/TaskScheduler/components/SchedulingDetailsModal/SchedulingDetailsModal';
import styles from '../Actions.module.scss';


interface MaintainTaskSchedulerActionProps {
  row: IAvailableInvoice;
}

const MaintainTaskSchedulerAction: FunctionComponent<MaintainTaskSchedulerActionProps> = ({ row }) => {
  const [isModalOpen, { toggle: toggleIsModalOpen }] = useBoolean(false);

  const handleChangeVisibility = () => {
    const newState = !isModalOpen;
    if (!newState) row.onClose();
    toggleIsModalOpen();
  };

  return (
    <>
      <IconButton
        id='viewButton'
        iconProps={{ iconName: 'ProcessMetaTask' }}
        onClick={toggleIsModalOpen}
        className={styles.iconBtn}
      />

      <SchedulingDetailsModal
        isModalOpen={isModalOpen}
        hideModal={handleChangeVisibility}
        taskInfo={row}
      />
    </>
  );
};

export default MaintainTaskSchedulerAction;