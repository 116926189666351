import { Pivot, PivotItem } from '@fluentui/react';
import classNames from 'classnames';
import { get } from 'lodash';
import { FunctionComponent, useState } from 'react';
import { MIDAS_MODULES } from '../../models/EnumMidasModules';
import ModuleTitleComponent from '../../shared/ModuleTitleComponent';
import { getMenuOptionById } from '../Nav/consts';
import { reportingTabs } from './consts';
import styles from './Reporting.module.scss';


const ReportingComponent: FunctionComponent = () => {

  const moduleInfo = getMenuOptionById(MIDAS_MODULES.REPORTING);

  const [currentTab, setCurrentTab] = useState(reportingTabs.billingBackups.name);

  const handleLinkClick = (item?: PivotItem) => {
    const newTab = get(item, 'props.itemKey', '');
    setCurrentTab(newTab);
  };

  const renderTab = (TabComponent: FunctionComponent) => {
    if (TabComponent) return <TabComponent />;
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  return (
    <>
      <div className={classNames('ms-Grid', styles.mainContainer)} dir='ltr'>
        <ModuleTitleComponent moduleInfo={moduleInfo} />
        <div className='ms-Grid-row'>
          <div className={classNames(
            'ms-Grid-col',
            'ms-lg12',
            'tabulatorWrapper',
            styles.headerTabsContainer,
          )}>
            <Pivot
              selectedKey={currentTab}
              onLinkClick={handleLinkClick}
              getTabId={getTabId}
              linkFormat="tabs"
              linkSize="large"
            >
              {Object.values(reportingTabs).map(({ name, component }, index) =>
                <PivotItem headerText={name} key={index} itemKey={name}>
                  <div className="pivotBodyContainer">
                    {renderTab(component)}
                  </div>
                </PivotItem>)}
            </Pivot>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportingComponent;