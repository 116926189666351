import { recordTestInstance, exportTestInstance } from '../axios';

export const get = (pagination: any, sortOrder: any, customerId: number, locationId: number) =>
  recordTestInstance.post('/tire-change/b73format', { pagination, sortOrder, customerId, locationId });
export const deleteB73format = (ids: Array<any>) =>
  recordTestInstance.delete('/tire-change/b73format/delete', { data: { ids } });
export const submit = (data: Array<any>, customerId: number, locationId: number) =>
  recordTestInstance.post('/tire-change/b73format/submit', { data, customerId, locationId });
export const getErrors = (pagination: any, sortOrder: any, customerId: number, locationId: number, lastMileageRun?: string) =>
  recordTestInstance.post('/tire-change/b73format/error', { pagination, sortOrder, customerId, locationId, lastMileageRun });
export const save = (data: Array<any>, customerId: number, locationId: number) =>
  recordTestInstance.post('/tire-change/b73format/save', { data, customerId, locationId });
export const getPage = (pagination: any, sortOrder: any, customerId: number, locationId: number, postTableId: string) =>
  recordTestInstance.post('/tire-change/b73format/number-page', { pagination, sortOrder, customerId, locationId, postTableId });

const printExcel = (input: any) =>
  exportTestInstance.post('/tire-change/b73format/export-excel', { input }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (input: any) =>
  exportTestInstance.post('/tire-change/b73format/export-pdf', { input }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });


export default {
  get,
  delete: deleteB73format,
  submit,
  getErrors,
  save,
  getPage,
  printExcel,
  printPdf,
};
