import { HeadCell } from '../DataGridComponent/DataGridModels';

export const typeCodePageSizes = [100, 250, 500];
export interface TypeCodeModalComponentProps {
  id: string;
  title: string;
  isOpen: boolean;
  onSubmit: (value?: any) => void;
  onDismiss: () => void;
  successLabel: string;
  cancelLabel: string;
  originalOrRetread?: string;
  isUserDependent?: boolean;
}

export const typeCodeHeadCell: HeadCell[] = [
  {
    key: 'column0',
    typeField: 'text',
    name: 'Type Code',
    fieldName: 'typeCode',
  },
  {
    key: 'column1',
    typeField: 'text',
    name: 'Product Code',
    fieldName: 'productCode',
  },
  {
    key: 'column2',
    typeField: 'text',
    name: 'Tire Size',
    fieldName: 'tireSize',
  },
  {
    key: 'column3',
    typeField: 'text',
    name: 'Description',
    fieldName: 'description',
  },
  {
    key: 'column4',
    typeField: 'text',
    name: 'Material Number',
    fieldName: 'materialNo',
  },
  {
    key: 'column5',
    typeField: 'text',
    name: 'SpecNo',
    fieldName: 'specNo',
  },
  {
    key: 'column6',
    typeField: 'text',
    name: 'Original/Retread',
    fieldName: 'origOrRtrd',
  },
  {
    key: 'column7',
    typeField: 'text',
    name: 'Radial Bias',
    fieldName: 'radialOrBias',
  },
  {
    key: 'column8',
    typeField: 'text',
    name: 'Ply Rating',
    fieldName: 'plyRating',
  },
];