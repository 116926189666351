import { FC, FormEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useBoolean } from '@fluentui/react-hooks';
import {
  Dropdown,
  IDropdownOption,
  MessageBarType,
  PrimaryButton,
  Text,
} from '@fluentui/react';
import { isEmpty, isNil } from 'lodash';
import { useParams } from 'react-router-dom';

import apiService from '../../../../../api';
import useNotifications from '../../../../../hooks/useNotifications';
import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import LoadingScreen from '../../../../LoadingScreen/LoadingScreen';
import { useCustomersFetch } from '../../../../../hooks/useCustomersFetch';

import { IAccountingState } from './IAccountingState';
import { IAccountingProps } from './IAccountingProps';

import { useUserPermissions } from '../../../../../hooks/useUserPermissions';
import { auth_customer_accounting } from '../../../../../consts/programKeys';

import styles from './Accounting.module.scss';

const Accounting: FC<IAccountingProps> = () => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_customer_accounting);

  const { id } = useParams<{ id: string }>();
  const { loadingCustomers, customersList } = useCustomersFetch(apiService.getCustomerSearch, null, id);
  const { addNotification } = useNotifications();

  const [state, setState] = useState<IAccountingState>({
    customerGroups: [],
    expenseCodes: [],
    customerGroup: null,
    expenseCode: null,
    loading: false,
  });

  const handleSave = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.customerAccounting.save(customersList[0].id, state.customerGroup, state.expenseCode);
      addNotification({
        text: 'Customer\'s Accounting was successfully saved.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response: { data } } = e;
      const { message, state } = data;
      if (isEmpty(state) || isNil(state)) {
        addNotification({
          text: `Customer's Accounting saving error: ${message}`,
          type: MessageBarType.error,
        });
      } else {
        state.forEach(({ message: errorMessage }: any) => {
          addNotification({
            text: `Customers Accounting saving error: ${errorMessage}`,
            type: MessageBarType.error,
          });
        });
      }
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchCustomerInfo = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data: { data } }: any = await apiService.customerAccounting.get(customersList[0].id);
      const { customerGroup, expenseCode } = data;
      setState((prev: any) => ({ ...prev, customerGroup, expenseCode }));
    } catch (e: any) {
      addNotification({
        text: `Fetching customer information error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchLists = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data: customerGroups }: any = await apiService.customerAccounting.getCustomerGroups();
      const { data: expenseCodes }: any = await apiService.customerAccounting.getExpenseCodes();
      setState((prev: any) => ({ ...prev, customerGroups, expenseCodes }));
    } catch (e: any) {
      addNotification({
        text: `Fetching lists error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const mountingMethod = async () => {
    await fetchCustomerInfo();
    await fetchLists();
  };

  useEffect(() => {
    customersList.length && mountingMethod();
  }, [customersList]);

  const onChangeCustomerGroup = (event: FormEvent<HTMLDivElement>, customerGroup: IDropdownOption<any> | undefined): void => {
    customerGroup && setState((prev: any) => ({ ...prev, customerGroup: customerGroup.key }));
  };

  const onChangeExpenseCode = (event: FormEvent<HTMLDivElement>, expenseCode: IDropdownOption<any> | undefined): void => {
    expenseCode && setState((prev: any) => ({ ...prev, expenseCode: expenseCode.key }));
  };

  return (
    <div>
      <div className="ms-Grid">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <Text variant='xLarge' className={styles.highlight}>Customer Group</Text>
            <SeparatorGy />
          </div>
        </div>
        <div className={classNames('ms-Grid-row', styles.row)}>
          <div className="ms-Grid-col ms-sm2">
            <Dropdown
              id="customerGroupDropdown"
              options={state.customerGroups.map((item: any) => ({
                key: item,
                text: item,
              }))}
              selectedKey={state.customerGroup}
              onChange={onChangeCustomerGroup}
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <Text variant='xLarge' className={styles.highlight}>Expense Code</Text>
            <SeparatorGy />
          </div>
        </div>
        <div className={classNames('ms-Grid-row', styles.row)}>
          <div className="ms-Grid-col ms-sm2">
            <Dropdown
              id="expenseCodesDropdown"
              options={state.expenseCodes.map((item: any) => ({
                key: item,
                text: item,
              }))}
              selectedKey={state.expenseCode}
              onChange={onChangeExpenseCode}
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.buttonsRow)}>
            <PrimaryButton
              id="savingButton"
              iconProps={{ iconName: 'Edit' }}
              text='Save Customer'
              onClick={handleSave}
              disabled={!userPermissions.isWrite}
            />
          </div>
        </div>
      </div>
      {(state.loading || loadingCustomers) && <LoadingScreen />}
    </div>
  );
};

export default Accounting;
