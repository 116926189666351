import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNil } from 'lodash';
import { MessageBarType } from '@fluentui/react';

import useNotifications from './useNotifications';

import apiService from '../api';
import { modulesPermissionsSelector, setModulesAllowed, setUserPermissions, userPermissionsSelector } from '../redux/userSlice';
import { MIDAS_MODULES } from '../models/EnumMidasModules';

export const useUserPermissions = () => {
  const [loadingPermissions, setLoadingPermissions] = useState<boolean>(false);

  const { addNotification } = useNotifications();

  const userPermissions = useSelector(userPermissionsSelector);
  const modulesAllowed = useSelector(modulesPermissionsSelector);
  const dispatch = useDispatch();

  const defaultPermissions = {
    isAccess: false,
    isRead: false,
    isWrite: false,
  };

  const fetchPermissions = async () => {
    try {
      setLoadingPermissions(true);
      const { data: { permissions, moduleAllowed } }: any = await apiService.getUserPermissions();
      dispatch(setUserPermissions(permissions));
      dispatch(setModulesAllowed(moduleAllowed));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `User permissions fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoadingPermissions(false);
    }
  };

  const hasPermission = (app: string) => {

    if (isNil(userPermissions) || isEmpty(userPermissions)) return defaultPermissions;

    const tmp = userPermissions.filter(
      ({ application = '' }: any) => application === '*' || application === app,
    );

    return {
      isAccess: tmp.find(({ isAccess = false }: any) => isAccess) ? true : false,
      isRead: tmp.find(({ isRead = false }: any) => isRead) ? true : false,
      isWrite: tmp.find(({ isWrite = false }: any) => isWrite) ? true : false,
    };
  };

  const getPermissionsByAccessKey = (accessKey: string) =>
    userPermissions?.filter(({ application }: any) =>
      application == accessKey)[0] || defaultPermissions;

  const hasModuleAccess = (moduleKeyToFind: string) => {
    if (moduleKeyToFind == MIDAS_MODULES.HOME) return true;
    return modulesAllowed?.find(({ moduleKey }: any) => moduleKey == moduleKeyToFind) != undefined;
  };

  return {
    userPermissions,
    loadingPermissions,
    modulesAllowed,
    fetchPermissions,
    hasPermission,
    getPermissionsByAccessKey,
    hasModuleAccess,
  };
};
