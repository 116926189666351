import {
  Checkbox,
  DefaultButton,
  Dropdown,
  IconButton,
  IDropdownOption,
  IDropdownStyles,
  Label,
  Modal,
  PrimaryButton,
  Separator,
  Text,
} from '@fluentui/react';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { FC, FormEvent, useEffect, useState } from 'react';
import { printingTypes } from './consts';
import { IPrintingModalInvoiceProps } from './IPrintingModalInvoiceProps';
import styles from './PrintingModalInvoice.module.scss';


const PrintingModalInvoice: FC<IPrintingModalInvoiceProps> = ({ isOpened, onPrint, onClose }) => {

  const [printingMethod, setPrintingMethod] = useState<IDropdownOption>();
  const [printOriginal, setPrintOriginal] = useState(false);

  const onChangePrintMethod = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPrintingMethod(item);
  };

  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 150 } };

  useEffect(() => {
    if (!isOpened) {
      setPrintingMethod(undefined);
      setPrintOriginal(false);
    }
  }, [isOpened]);

  return (
    <Modal
      key={'modalID'}
      isOpen={isOpened}
      onDismiss={onClose}
      containerClassName={styles.modalContainer}
    >
      <div className={styles.modalBody}>
        <div className='modalHeader'>
          <Text variant="xLarge">Print/Export</Text>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel='Close popup modal'
            onClick={onClose}
          />
        </div>
        <div className={classNames(styles.mainBlock, 'ms-Grid-row')}>
          <div className={classNames('ms-Grid-col', styles.centeredContentColumn)}>
            <Checkbox
              label='Print Original'
              className={styles.printOriginalCheckbox}
              onChange={(_ev, value) => setPrintOriginal(!!value)}
              checked={printOriginal}
            />
            <Label htmlFor="printMethod">Export to:</Label>
            <Dropdown
              id="printMethod"
              selectedKey={printingMethod ? printingMethod.key : undefined}
              onChange={onChangePrintMethod}
              placeholder="Select an option"
              options={Object.values(printingTypes).map(printingType => ({
                key: printingType,
                text: printingType,
              }))}
              styles={dropdownStyles}
            />
          </div>

          <div className={classNames('ms-Grid-col', styles.marginTop20)}>
            <Separator className={styles.separator} vertical />
          </div>

          <div className={classNames('ms-Grid-col', styles.centeredContentColumn)}>
            <PrimaryButton
              id='printExportModalBtn'
              text="Export"
              iconProps={{ iconName: 'Installation' }}
              onClick={() => onPrint(printingMethod?.key, printOriginal)}
              disabled={isNil(printingMethod)}
              className={styles.button}
            />
            <DefaultButton
              text="Cancel"
              onClick={onClose}
              className={styles.button}
            />
          </div>
        </div>

      </div>
    </Modal>
  );
};

export default PrintingModalInvoice;
