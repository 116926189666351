import { HeadCell } from '../../../../../../shared/DataGridComponent/DataGridModels';

export const dotNumDateRegex = /^(5[0-3]|[1-4][0-9]|0[1-9])([0-9]{2})$/;

export const dotNumFieldFormat = {'*': /[a-zA-Z0-9|*]/};

export const ORDER_DETAILS_FIELDS = {
  orderNumberDataField : {
    id: 'orderNumber',
    label: 'Order #',
    name: 'orderNumber',
    type: 'text',
  },
  dateShippedDataField : {
    id: 'dateShipped',
    label: 'Date Shipped',
    name: 'dateShipped',
    type: 'date',
    disabled: true,
  },
  invoiceNumberDataField : {
    id: 'invoiceNumber',
    label: 'Invoice #',
    name: 'invoiceNumber',
    type: 'text',
    disabled: true,
  },
  dlDataField : {
    id: 'dl',
    label: 'DL #',
    name: 'dl',
    type: 'text',
  },
};

export const DOT_FIELDS: any = {	
  pstcDataField : {
    id: 'pstc',
    label: 'PPP SS TTTC',
    name: 'pstc',
    type: 'text',
    description: 'Format: XX YY ZZZ',
    textTransform: 'uppercase',
  },
  wyDataField : {
    id: 'wy',
    label: 'WW YY',
    name: 'wy',
    type: 'text',
    description: 'Format: 0000',
  },
};

export const SERIES_FIELDS: any = {
  beginPrefixDataField : {
    id: 'beginPrefix',
    description: 'Prefix',
    name: 'beginPrefix',
    type: 'text',
    textTransform: 'uppercase',
  },
  beginBrandDataField : {
    id: 'beginBrand',
    description: 'Brand',
    name: 'beginBrand',
    type: 'text',
  },
  beginSufixDataField : {
    id: 'beginSufix',
    description: 'Suffix',
    name: 'beginSufix',
    type: 'text',
    textTransform: 'uppercase',
  },
  endPrefixDataField : {
    id: 'endPrefix',
    description: 'Prefix',
    name: 'endPrefix',
    type: 'text',
    disabled: true,
    textTransform: 'uppercase',
  },
  endBrandDataField : {
    id: 'endBrand',
    description: 'Brand',
    name: 'endBrand',
    type: 'text',
  },
  endSufixDataField : {
    id: 'endSufix',
    description: 'Suffix',
    name: 'endSufix',
    type: 'text',
    disabled: true,
    textTransform: 'uppercase',
  },
};

export const inTransitTiresUpdateHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Brand Prefix',
    fieldName: 'intireBrndPfx',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Brand number',
    fieldName: 'intireBrndNum',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Brand Suffix',
    fieldName: 'intireBrndSfx',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Type code',
    fieldName: 'typeCodeFK',
    typeField: 'text',
  },
  {
    key: 'column4',
    name: 'DOT #',
    fieldName: 'dotNum',
    typeField: 'text',
  },
];

export const intransitDOTPageSizes = [500, 1000];
