import { HeadCell } from '../../../../shared/DataGridComponent/DataGridModels';

export const billingBackupsHeadCell: Array<HeadCell> = [
  {
    key: 'column5',
    name: 'Customer Name',
    fieldName: 'customer',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Report Name',
    fieldName: 'reportName',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Billing Type',
    fieldName: 'reportType',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Region',
    fieldName: 'region',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'Created On',
    fieldName: 'generateDon',
    typeField: 'date',
    isEditable: false,
  },
];
