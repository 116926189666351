import { FC, useEffect, useMemo, useState } from 'react';
import { DefaultButton, ITextFieldProps, PrimaryButton, Icon, MessageBarType } from '@fluentui/react';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import useNotifications from '../../hooks/useNotifications';
import apiService from '../../api';
import AutoCompleteField from '../../components/Common/Search/AutoCompleteField';
import { customerFilterSelector, customerShouldFilterSelector, removeCustomer, setFilter, setNewCustomer } from '../../redux/customerSlice';

import { IAutoComplete, ISearchCustomerForm, typeOfStatuses } from './ISearchCustomerForm';
import { anyPrefixRegex, blockName } from './const';

import './SearchCustomerForm.scss';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';

import { useUserPermissions } from '../../hooks/useUserPermissions';
import { auth_customer_searchCustomer } from '../../consts/programKeys';

const searchHeaderClass = mergeStyles({
  marginTop: '5px',
  paddingTop: '10px',
  fontSize: '20px',
  fontFamily: 'Arial, Helvetica, sans-serif',
  color: '#114375',
  fontWeight: 'bold',
});

const fieldClass = mergeStyles({
  marginBottom: '10px',
  fontSize: '12px',
  fontFamily: 'Arial, Helvetica, sans-serif',
  color: '#114375',
  fontWeight: 'bold',
});

const buttonClass = mergeStyles({
  backgroundColor: '#114375',
  border: '#114375 2px solid',
});

const SearchCustomerForm: FC<ISearchCustomerForm> = ({ customersList, fetchData, onSearchFormClick: onSearchForm, isLoading: isSearching }) => {
  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_customer_searchCustomer);

  const customerFilter = useSelector(customerFilterSelector);
  const shouldFilterRun = useSelector(customerShouldFilterSelector);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLocalSearch, setIsLocalSearch] = useState<boolean>(false);
  const [custPrefix, setCustPrefix] = useState<string>(shouldFilterRun ? customerFilter.custPrefix : '');
  const [custNumber, setCustNumber] = useState<string>(shouldFilterRun ? customerFilter.custNumber : '');
  const [custName, setCustName] = useState<string>(shouldFilterRun ? customerFilter.custName : '');
  const [bName, setBName] = useState<string>(shouldFilterRun ? customerFilter.bName : '');
  const [status, setStatus] = useState<string>(shouldFilterRun ? customerFilter.status : '');
  const [abbrname, setAbbrname] = useState<string>(shouldFilterRun ? customerFilter.abbrname : '');
  const [regionCode, setRegionCode] = useState<string>(shouldFilterRun ? customerFilter.regionCode : '');
  const [country, setCountry] = useState<string>(shouldFilterRun ? customerFilter.country : '');
  const [state, setState] = useState<string>(shouldFilterRun ? customerFilter.state : '');
  const [city, setCity] = useState<string>(shouldFilterRun ? customerFilter.city : '');
  const [zipcode, setZipcode] = useState<string>(shouldFilterRun ? customerFilter.zipcode : '');
  const [isCustomerPage, setIsCustomerPage] = useState<boolean>(false);
  const [countriesList, setCountriesList] = useState<Array<any>>([]);

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { addNotification } = useNotifications();

  const onRenderPrefixCustomerNumberInput = (
    props: ITextFieldProps | undefined,
  ): JSX.Element => (
    <div>
      <Icon iconName="Tag" aria-hidden="true" />
      <span>{props?.prefix}</span>
    </div>
  );

  const removeEmpty = ({ text }: IAutoComplete) => text;
  const sortRows = (a: any, b: any) => {
    return a?.text?.localeCompare(b?.text);
  };

  const fetchCountries = async () => {
    try {
      setIsLoading(true);
      const { data: { countries } } = await apiService.customerAPI.getCountries();
      setCountriesList([
        {
          key: 'anyCountry',
          text: 'ANY COUNTRY',
        },
        ...countries
          .map((countryElement: any) => ({
            key: countryElement,
            text: countryElement,
          })),
      ]);
    } catch (e: any) {
      addNotification({ text: 'Error fetching countries', type: MessageBarType.error });
    } finally {
      setIsLoading(false);
    }
  };

  const onBussinesNameChange = debounce(async (bName: string) => {
    setIsLocalSearch(true);
    setBName(bName);
    if (!bName) {
      return;
    }
    fetchData({ bName, fdelete: false });
  }, 1000);

  const onStatusChange = debounce(async (status: string) => {
    setIsLocalSearch(true);
    setStatus(status);
    if (!status) {
      return;
    }
    if (status !== 'ANY STATUS') {
      fetchData({ status, fdelete: false });
    } else {
      fetchData({ status: '', fdelete: false });
      //setStatus('');
    }
  }, 1000);

  const businessNameList = useMemo(() => customersList ?
    customersList.map(({ businessName }) => ({
      key: businessName,
      text: businessName,
    })).filter(removeEmpty).sort(sortRows) : [], [customersList]);

  const onAbbNameChange = debounce(async (abbrname: string) => {
    setIsLocalSearch(true);
    setAbbrname(abbrname);
    if (!abbrname) {
      return;
    }
    fetchData({ abbrname, fdelete: false });
  }, 1000);

  const abbNameList = useMemo(() => customersList ?
    customersList.map(({ abbrName }) => ({
      key: abbrName,
      text: abbrName,
    })).filter(removeEmpty).sort(sortRows) : [], [customersList]);

  const onRegionChange = debounce(async (regionCode: string) => {
    setIsLocalSearch(true);
    setRegionCode(regionCode);
    if (!regionCode) {
      return;
    }
    if (regionCode !== 'ANY REGION CODE') {
      fetchData({ regionCode, fdelete: false });
    } else {
      fetchData({ regionCode: '', fdelete: false });
    }
  }, 1000);

  const rCodeList = useMemo(() => customersList ?
    customersList.map(({ regionCode }) => ({
      key: regionCode,
      text: regionCode,
    })).sort(sortRows).reduce((acc, current) => {
      const element: any = acc.find((rcode) => rcode?.text === current.text);
      if (element) {
        return acc;
      } else {
        if (current.text !== null) {
          return acc.concat(current);
        }
      }
      return acc;
    }, [{
      key: 'anyRCode',
      text: 'ANY REGION CODE',
    }] as IAutoComplete[]).filter(removeEmpty) : [], [customersList]);

  const onCountryChange = debounce(async (country: string) => {
    setIsLocalSearch(true);
    setCountry(country);
    if (!country) {
      return;
    }
    if (country !== 'ANY COUNTRY') {
      fetchData({ country, fdelete: false });
    } else {
      fetchData({ country: '', fdelete: false });
    }
  }, 1000);

  const onCityChange = debounce(async (city: string) => {
    setIsLocalSearch(true);
    setCity(city);
    if (!city) {
      return;
    }
    if (city !== 'ANY CITY') {
      fetchData({ city, fdelete: false });
    } else {
      fetchData({ city: '' });
    }
  }, 1000);

  const citiesList = useMemo(() => customersList ?
    customersList.map(({ corrAddressCity }) => ({
      key: corrAddressCity,
      text: corrAddressCity,
    })).sort(sortRows).reduce((acc, current) => {
      const element: any = acc.find((city) => city?.text === current.text);
      if (element) {
        return acc;
      } else {
        if (current.text !== null) {
          return acc.concat(current);
        }
      }
      return acc;
    }, [{
      key: 'anyCity',
      text: 'ANY CITY',
    }] as IAutoComplete[]).filter(removeEmpty) : [], [customersList]);

  const onStateChange = debounce(async (state: string) => {
    setIsLocalSearch(true);
    setState(state);
    if (!state) return;
    if (state !== 'ANY STATE') {
      fetchData({ state, fdelete: false });
    } else {
      fetchData({ state: '', fdelete: false });
    }
  }, 1000);

  const statesList = useMemo(() => customersList ?
    customersList.map(({ corrAddressState }) => ({
      key: corrAddressState,
      text: corrAddressState,
    })).sort(sortRows).reduce((acc, current) => {
      const element: any = acc.find((city) => city?.text === current.text);
      if (!element && current.text) {
        return acc.concat(current);
      }
      return acc;
    }, [{
      key: 'anyState',
      text: 'ANY STATE',
    }] as IAutoComplete[]).filter(removeEmpty) : [], [customersList]);

  const onZipCodeChange = debounce(async (zipcode: string) => {
    setIsLocalSearch(true);
    setZipcode(zipcode);
    if (!zipcode) return;
    if (zipcode !== 'ANY ZIP CODE') {
      fetchData({ zipcode, fdelete: false });
    } else {
      fetchData({ zipcode: '', fdelete: false });
    }
  }, 1000);

  const zipCodeList = useMemo(() => customersList ?
    customersList.map(({ corrAddressZip }) => ({
      key: corrAddressZip,
      text: corrAddressZip,
    })).sort(sortRows).reduce((acc, current) => {
      const element: any = acc.find((zc) => zc?.text === current.text);
      if (!element && current.text) {
        return acc.concat(current);
      }
      return acc;
    }, [{
      key: 'anyZip',
      text: 'ANY ZIP CODE',
    }] as IAutoComplete[]).filter(removeEmpty) : [], [customersList]);

  const customerPrefixList = useMemo(() => customersList ?
    customersList.map(({ prefix }) => ({
      key: prefix,
      text: prefix,
    })).filter(removeEmpty).sort(sortRows) : [], [customersList]);

  const customerNameList = useMemo(() => customersList ?
    customersList.map(({ customerName }) => ({
      key: customerName?.trim(),
      text: customerName?.trim(),
    })).filter(removeEmpty).sort(sortRows) : [], [customersList]);


  const customerNumberList = useMemo(() => customersList ?
    customersList.map(({ customerNumber }) => ({
      key: customerNumber,
      text: customerNumber,
    })).filter(removeEmpty).sort(sortRows) : [], [customersList]);

  const customerPrefixInputText = debounce((custPrefix: string) => {
    setIsLocalSearch(true);
    setCustPrefix(custPrefix);
    if (!custPrefix) return;
    fetchData({ custPrefix, fdelete: false });
  }, 1000);

  const customerNameInputText = debounce((custName: string) => {
    setIsLocalSearch(true);
    setCustName(custName);
    if (!custName) return;
    fetchData({ custName, fdelete: false });
  }, 1000);

  const customerNumberInputText = debounce((custNumber: string) => {
    setIsLocalSearch(true);
    setCustNumber(custNumber);
    if (!custNumber) return;
    fetchData({ custNumber, fdelete: false });
  }, 1000);

  const onSearchFormClick = () => {
    dispatch(setFilter({
      custPrefix,
      custNumber,
      custName,
      country,
      city,
      state,
      zipcode,
      bName,
      abbrname,
      regionCode,
      status,
    }));
    onSearchForm({
      custPrefix,
      custNumber,
      custName,
      country: anyPrefixRegex.test(country) ? '' : country,
      city: anyPrefixRegex.test(city) ? '' : city,
      state: anyPrefixRegex.test(state) ? '' : state,
      zipcode: anyPrefixRegex.test(zipcode) ? '' : zipcode,
      bName,
      abbrname,
      regionCode: anyPrefixRegex.test(regionCode) ? '' : regionCode,
      status: anyPrefixRegex.test(status) ? '' : status,
    });
  };

  const handleAddNew = () => {
    dispatch(setNewCustomer({
      prefix: custPrefix,
      businessName: bName,
      abbrName: abbrname,
      billToCity: city,
      billToState: state,
      billToCountry: country,
      billToZip: zipcode,
      customerNumber: custNumber,
      customerName: custName,
      regionCode,
    }));
    dispatch(removeCustomer());
    history.push('/customer/add');
  };

  const onCustPrefixChange = (custPrefix: any) => {
    const customerSelected = customersList.find(customer => {
      return customer.prefix === custPrefix;
    });
    if (customerSelected) {
      setCustName(customerSelected?.customerName || '');
      setCustNumber(customerSelected?.customerNumber || '');
    }
  };
  const onCustNameChange = (custName: any) => {
    const customerSelected = customersList.find(customer => {
      return customer.customerName === custName;
    });
    if (customerSelected) {
      setCustPrefix(customerSelected?.prefix || '');
      setCustNumber(customerSelected?.customerNumber || '');
    }
  };
  const onCustNumberChange = (custNumber: any) => {
    const customerSelected = customersList.find(customer => {
      return customer.customerNumber === custNumber;
    });
    if (customerSelected) {
      setCustPrefix(customerSelected?.prefix || '');
      setCustName(customerSelected?.customerName || '');
    }
  };


  useEffect(() => {
    if (location.pathname === '/customer') {
      setIsCustomerPage(true);
    } else {
      setIsCustomerPage(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <>
      <div>
        <div className={searchHeaderClass}>Search a Customer</div>
      </div>
      <div className={`${blockName} ms-Grid`} dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm6">
            <div className="ms-Grid-col ms-sm6">
              <AutoCompleteField
                isLoading={isSearching}
                initialValue={shouldFilterRun && !isLocalSearch ? customerFilter.custPrefix : custPrefix}
                list={customerPrefixList}
                label="PREFIX"
                icon="FavoriteStar"
                textValue={customerPrefixInputText}
                className={fieldClass}
                onChangeAction={onCustPrefixChange}
                data-testid='customer-prefix'
              />
              <AutoCompleteField
                isLoading={isSearching}
                initialValue={shouldFilterRun && !isLocalSearch ? customerFilter.custNumber : custNumber}
                textValue={customerNumberInputText}
                list={customerNumberList}
                label="CUSTOMER NUMBER"
                onRenderPrefix={onRenderPrefixCustomerNumberInput}
                className={fieldClass}
                onChangeAction={onCustNumberChange}
                data-testid='customer-number'
              />
              <AutoCompleteField
                isLoading={isSearching}
                initialValue={shouldFilterRun && !isLocalSearch ? customerFilter.status : status}
                list={typeOfStatuses}
                icon="StatusCircleRing"
                label="STATUS"
                textValue={onStatusChange}
                className={fieldClass}
                data-testid='customer-status'
              />
            </div>
            <div className="ms-Grid-col ms-sm6">
              <AutoCompleteField
                isLoading={isSearching}
                initialValue={shouldFilterRun && !isLocalSearch ? customerFilter.custName : custName}
                list={customerNameList}
                label="CUSTOMER NAME"
                icon="Contact"
                textValue={customerNameInputText}
                className={fieldClass}
                onChangeAction={onCustNameChange}
                data-testid='customer-name'
              />
              <AutoCompleteField
                isLoading={isSearching}
                initialValue={shouldFilterRun && !isLocalSearch ? customerFilter.abbrname : abbrname}
                list={abbNameList}
                icon="FontColorA"
                label="ABBREVIATED NAME"
                textValue={onAbbNameChange}
                className={fieldClass}
                data-testid='customer-abb-name'
              />
              <AutoCompleteField
                isLoading={isSearching}
                initialValue={shouldFilterRun && !isLocalSearch ? customerFilter.bName : bName}
                list={businessNameList}
                icon="Contact"
                label="DBA"
                textValue={onBussinesNameChange}
                className={fieldClass}
                data-testid='customer-dba'
              />
            </div>
          </div>
          <div className="ms-Grid-col ms-sm6">
            <div className="ms-Grid-row">

              <div className="ms-Grid-col ms-sm4">
                <AutoCompleteField
                  isLoading={isSearching}
                  initialValue={shouldFilterRun && !isLocalSearch ? customerFilter.country : country}
                  list={countriesList}
                  icon="Flag"
                  label="COUNTRY"
                  textValue={onCountryChange}
                  className={fieldClass}
                  data-testid='customer-country'
                />
              </div>
              <div className="ms-Grid-col ms-sm4">
                <AutoCompleteField
                  isLoading={isSearching}
                  initialValue={shouldFilterRun && !isLocalSearch ? customerFilter.state : state}
                  list={statesList}
                  textValue={onStateChange}
                  icon="Bank"
                  label="STATE"
                  className={fieldClass}
                  data-testid='customer-state'
                />
              </div>
              <div className="ms-Grid-col ms-sm4">
                <AutoCompleteField
                  isLoading={isSearching}
                  initialValue={shouldFilterRun && !isLocalSearch ? customerFilter.regionCode : regionCode}
                  list={rCodeList}
                  icon="Location"
                  label="REGION CODE"
                  textValue={onRegionChange}
                  className={fieldClass}
                  data-testid='customer-region-code'
                />
              </div>
            </div>

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6">
                <AutoCompleteField
                  isLoading={isSearching}
                  initialValue={shouldFilterRun && !isLocalSearch ? customerFilter.city : city}
                  list={citiesList}
                  textValue={onCityChange}
                  icon="CityNext"
                  label="CITY"
                  className={fieldClass}
                  data-testid='customer-city'
                />
              </div>
              <div className="ms-Grid-col ms-sm6">
                <AutoCompleteField
                  isLoading={isSearching}
                  initialValue={shouldFilterRun && !isLocalSearch ? customerFilter.zipcode : zipcode}
                  list={zipCodeList}
                  textValue={onZipCodeChange}
                  icon="MailSolid"
                  label="ZIP CODE"
                  className={fieldClass}
                  data-testid='customer-zip-code'
                />
              </div>
            </div>

            <div className={`${blockName}--small-margin-top ms-Grid-row`}>
              <div className="ms-Grid-col ms-sm6">
              </div>
              <div className={`${blockName}__actions ms-Grid-col ms-sm6`}>
                <div className={`${blockName}__actions__buttons`}>
                  {
                    isCustomerPage &&
                    <DefaultButton
                      className={`${blockName}--small-margin-right`}
                      text="Add new" onClick={handleAddNew}
                      disabled={!userPermissions.isWrite} />
                  }
                  <PrimaryButton id="search-button" className={buttonClass} text="Search" onClick={onSearchFormClick} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading && <LoadingScreen />}
    </>

  );
};

export default SearchCustomerForm;
