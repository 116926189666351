import activateCustomer from './activateCustomer';
import getCustomerList from './getCustomerList';
import getCustomerSearch from './getCustomerSearch';
import getLocationsByCustomerId from './getLocationsByCustomerId';
import getLocationList from './getLocationList';
import getBillingPeriodList from './getBillingPeriod';
import getCustomerInformation from './getCustomerInformation';
import updateCustomerDetails from './updateCustomerDetails';
import getCountries from './getCountries';
import getUserPermissions from './getUserPermissions';
import getStates from './getStates';

import rebrand from './rebrandAPI';
import reinstate from './reinstateAPI';
import postBackdatedChanges from './postBackdatedChangesAPI';
import customerAPI from './customerAPI';
import postByFootprint from './postByFootprintAPI';
import viewOpenWheels from './viewOpenWheelsAPI';
import viewListing from './viewListingAPI';
import viewTireHistory from './viewTireHistoryAPI';
import b37Format from './b37FormatAPI';
import postFlaggedAsDamaged from './postFlaggedAsDamagedAPI';
import spareStock from './spareStockAPI';
import postOosTires from './postOosTiresAPI';
import transferTires from './transferTiresAPI';
import applyMilesToTires from './applyMilesToTiresAPI';
import mileageRun from './mileageRunAPI';
import vehicleMiles from './vehicleMilesAPI';
import registerVehicles from './registerVehiclesAPI';
import viewVehicleHistory from './viewVehicleHistory';
import massUpload from './massUploadAPI';
import tireOrders from './tireOrdersAPI';
import customerAccounting from './customerAccountingAPI';
import customerBillTo from './customerBillToAPI';
import getActiveVehicleNumbers from './getActiveVehicleNumbers';
import getActiveInactiveVehicleNumbers from './getActiveInactiveVehicleNumbers';
import getInspectionCodes from './getInspectionCodes';
import getInspectionCodesByCustomer from './getInspectionCodesByCustomer';
import blockAPI from './blockerAPI';
import typeCodeAPI from './typeCodeAPI';
import plantAPI from './plantAPI';
import quoteListing from './quoteListingAPI';
import inTransitTiresAPI from './inTransitTiresAPI';
import plantWareHouseAPI from './plantWareHouseAPI';
import maintainTypeCodes from './maintainTypeCodesAPI';
import changeTypeCode from './changeTypeCodeAPI';
import getProductCodes from './getProductCodes';
import postMonthlyMiles from './postMonthlyMilesAPI';
import maintainOEMsAPI from './maintainOEMsAPI';
import viewTireDetails from './viewTireDetailsAPI';
import quotesAPI from './quotesAPI';
import shipToAPI from './shipToAPI';
import sections from './sectionsAPI';
import generalAPI from './generalAPI';
import terms from './termsAPI';
import oosAvgs from './oosAvgsAPI';
import billingAPI from './billingAPI';
import treadDepth from './treadDepthAPI';
import contract from './contractAPI';
import management from './managementAPI';
import taskScheduler from './taskSchedulerAPI';
import commonAPI from './commonAPI';
import unflagConsignedTiresAPI from './unflagConsignedTiresAPI';
import maintainAPI from './maintainAPI';
import brandAPI from './brandAPI';
import reporting from './reportingAPI';
import mileageAuditAPI from './mileageAuditAPI';
import addInsAPI from './addInsAPI';

export default {
  activateCustomer,
  getCustomerList,
  getLocationsByCustomerId,
  getCustomerInformation,
  updateCustomerDetails,
  getUserPermissions,
  getLocationList,
  getBillingPeriodList,
  getCountries,
  getStates,
  customerAPI,
  viewTireHistory,
  postBackdatedChanges,
  reinstate,
  rebrand,
  viewOpenWheels,
  postByFootprint,
  viewListing,
  viewTireDetails,
  b37Format,
  postFlaggedAsDamaged,
  spareStock,
  postOosTires,
  transferTires,
  applyMilesToTires,
  mileageRun,
  vehicleMiles,
  registerVehicles,
  viewVehicleHistory,
  massUpload,
  tireOrders,
  maintainTypeCodes,
  customerAccounting,
  customerBillTo,
  changeTypeCode,
  getProductCodes,
  getActiveVehicleNumbers,
  getActiveInactiveVehicleNumbers,
  getInspectionCodes,
  getInspectionCodesByCustomer,
  blockAPI,
  typeCodeAPI,
  plantAPI,
  inTransitTiresAPI,
  plantWareHouseAPI,
  postMonthlyMiles,
  maintainOEMsAPI,
  quoteListing,
  getCustomerSearch,
  quotesAPI,
  shipToAPI,
  sections,
  generalAPI,
  terms,
  oosAvgs,
  billingAPI,
  treadDepth,
  contract,
  management,
  taskScheduler,
  commonAPI,
  unflagConsignedTiresAPI,
  maintainAPI,
  brandAPI,
  reporting,
  mileageAuditAPI,
  addInsAPI,
};
