import { IconButton } from '@fluentui/react';
import { FunctionComponent } from 'react';
import ViewMaintainInvoicesModalComponent from '../../../ViewMaintainInvoicesModalComponent';
import { useBoolean } from '@fluentui/react-hooks';
import ViewMaintainTireSalesModalComponent from '../../../ViewMaintainTireSalesModalComponent';


interface ViewTireSalesActionProps {
  row: any;
}
 
const ViewTireSalesAction: FunctionComponent<ViewTireSalesActionProps> = ({row}) => {  

  const [isModalTireSalesOpen, { toggle: toggleIsModalTireSalesOpen }] = useBoolean(false);  


  return (  
    <>
      <IconButton
        id='viewButton'
        iconProps={{ iconName: 'View' }}
        onClick={toggleIsModalTireSalesOpen}
      />

      <ViewMaintainTireSalesModalComponent
        id='invoiceModal'
        parentQuoteId={row?.quoteId}
        isOpen={isModalTireSalesOpen}
        title='View Tire Sales Quote'
        onCancel={toggleIsModalTireSalesOpen}
        onSubmit={toggleIsModalTireSalesOpen}
        actionType='view'
      />
    </>
  );
};
 
export default ViewTireSalesAction;