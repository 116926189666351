import { DatePicker, DefaultButton, Icon, Label, MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import React, { FormEvent, FunctionComponent, useEffect, useState } from 'react';
import AutocompleteInput from '../../../../../shared/AutocompleteInput';
import DataGridComponent from '../../../../../shared/DataGridComponent';
import { useBoolean } from '@fluentui/react-hooks';
import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import { emptyEquipment, equipmentHeadCell } from './const';
import { IEquipmentProps } from './IEquipmentProps';
import { IEquipmentItem } from './IEquipmentState';
import apiService from '../../../../../api';
import styles from './Equipment.module.scss';
import useNotifications from '../../../../../hooks/useNotifications';
import LoadingScreen from '../../../../LoadingScreen/LoadingScreen';
import moment from 'moment';
import { sortOrder } from '../../../../../consts/sortOrder';
import { debounce } from 'lodash';
import { useCustomersFetch } from '../../../../../hooks/useCustomersFetch';
import { useUserPermissions } from '../../../../../hooks/useUserPermissions';
import { auth_customer_equipment } from '../../../../../consts/programKeys';
import { transformDate } from '../../../../../shared/transformDate';
import { useParams } from 'react-router-dom';

const EquipmentComponent: FunctionComponent<IEquipmentProps> = () => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_customer_equipment);

  const { id: customerId } = useParams<{ id: string }>();
  const { addNotification } = useNotifications();
  const [equipmentList, setEquipmentList] = useState<Array<IEquipmentItem>>([]);
  const [isLoadingTable, { toggle: toggleShowLoadingTable }] = useBoolean(false);
  const [totalFound, setTotalFound] = useState(0);
  const [loading, setLoading] = useState(false);
  const [locationsList, setLocationsList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState<any>('');
  const [descriptionsList, setDescriptionsList] = useState([]);
  const [selectedDescription, setSelectedDescription] = useState<any>('');
  const [statusesList, setStatusesList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState<any>('');
  const { customersList, fetchCustomers } = useCustomersFetch(apiService.getCustomerSearch);
  const [selectedCustomer, setSelectedCustomer] = useState<any>('');
  const [selectedNewLocation, setSelectedNewLocation] = useState<any>('');
  const [equipmentItem, setEquipmentItem] = useState<any>(emptyEquipment);
  const [equipmentTransfer, setEquipmentTransfer] = useState<any>({});
  const [reloadControlsTable, { toggle: toggleReloadControlsTable }] = useBoolean(false);

  const [componentState, setComponentState] = useState<any>({
    pagination: { pageSize: 100, pageNumber: 1 },
    sortOrder: { column: equipmentHeadCell[0].fieldName, order: sortOrder.ASC },
  });

  const onChangeEquipmentTag = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, tagNumber: string | undefined) => {
    setEquipmentItem((prev: any) => ({ ...prev, tagNumber }));
  };

  const onChangeEquipmentDescription = (item: any) => {
    setSelectedDescription(item);
  };

  const onChangeEquipmentStatus = (item: any) => {
    setSelectedStatus(item);
  };

  const onChangeEquipmentLocation = (item: any) => {
    setSelectedLocation(item);
  };

  const onChangeEquipmentDate = (acqDate: Date | null | undefined) => {
    setEquipmentItem((prev: any) => ({ ...prev, acqDate: transformDate(moment(acqDate).format('MM/DD/YYYY')) }));
  };

  const onChangeEquipmentComments = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, comments: string | undefined) => {
    setEquipmentItem((prev: any) => ({ ...prev, comments }));
  };

  const fetchEquipments = async (
    pagination: any = componentState.pagination,
    sortOrder: any = componentState.sortOrder,
    filters?: any,
  ) => {
    toggleShowLoadingTable();
    try {
      const { data }: any = await apiService.customerAPI.getEquipments(pagination, sortOrder, customerId, filters);
      setEquipmentList(data?.data);
      setTotalFound(data?.total?.found);
      setComponentState((prev: any) => ({ ...prev, pagination, sortOrder }));
    } catch (error: any) {
      const { response } = error;
      addNotification({ text: `Fetching equipments error: ${response.data?.message}`, type: MessageBarType.error });
    }
    finally {
      toggleShowLoadingTable();
    }
  };

  const createEquipment = async () => {
    try {
      const { data } = await apiService.customerAPI.addEquipment({ ...equipmentItem, status: selectedStatus, description: selectedDescription, mCbCustomerId: customerId, locationId: selectedLocation });
      fetchEquipments();
      addNotification({ text: data.message, type: MessageBarType.success });
    } catch (error: any) {
      const { response } = error;
      addNotification({ text: `Create equipments error: ${response.data?.message}`, type: MessageBarType.error });
    }
  };

  const updateEquipment = async () => {
    try {
      const { data } = await apiService.customerAPI.updateEquipment({ ...equipmentItem, status: selectedStatus, description: selectedDescription, mCbCustomerId: customerId, locationId: selectedLocation });
      fetchEquipments();
      addNotification({ text: data.message, type: MessageBarType.success });
    } catch (error: any) {
      const { response } = error;
      addNotification({ text: `Update equipments error: ${response.data?.message}`, type: MessageBarType.error });
    }
  };

  const deleteEquipments = async (equipmentIds: Array<any>) => {
    try {
      const { data } = await apiService.customerAPI.deleteEquipments(equipmentIds);
      fetchEquipments();
      addNotification({ text: data.message, type: MessageBarType.success });
    } catch (error: any) {
      const { response } = error;
      addNotification({ text: `Delete equipments error: ${response.data?.message}`, type: MessageBarType.error });
    }
  };

  const transferEquipments = async () => {
    try {
      const newLocation: any = locationsList.filter((location: any) => location.id === selectedNewLocation)[0];
      const { data } = await apiService.customerAPI.transferEquipments({ ...equipmentTransfer, newLocation: { locationId: newLocation?.id, shipToNumber: newLocation?.shipToNumber, mCbCustomerId: selectedCustomer } });
      fetchEquipments();
      addNotification({ text: data.message, type: MessageBarType.success });
    } catch (error: any) {
      const { response } = error;
      addNotification({ text: `Transfer equipments error: ${response.data?.message}`, type: MessageBarType.error });
    }
  };

  const clearEquipment = () => {
    if (equipmentItem?.equipmentId) {
      setEquipmentItem({ ...equipmentItem, ...emptyEquipment });
    } else {
      setEquipmentItem(emptyEquipment);
    }
    setSelectedDescription('');
    setSelectedStatus('');
    setSelectedLocation('');
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: +countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString: searchedText,
    };
    await fetchEquipments(pagination, sortOrder, filters);
  };

  const handleSelectToUpdateRow = (rowsSelected: Array<IEquipmentItem>) => {
    if (rowsSelected.length === 1) {
      setEquipmentItem(parseSelectedForUpdate(rowsSelected[0]));
      setEquipmentTransfer(parseForTransfer(rowsSelected[0]));
    } else {
      setEquipmentItem(emptyEquipment);
      setSelectedDescription('');
      setSelectedStatus('');
      setSelectedLocation('');
      setSelectedCustomer('');
      setSelectedNewLocation('');
    }
  };

  const handleAddEquipment = async () => {
    setLoading(true);
    await createEquipment();
    setLoading(false);
  };

  const handleUpdateEquipment = async () => {
    setLoading(true);
    await updateEquipment();
    setLoading(false);
  };

  const handleDelete = async (rowsToDelete: Array<IEquipmentItem>) => {
    setLoading(true);
    const idList = rowsToDelete?.map(row => ({ equipmentId: row.equipmentid }));
    await deleteEquipments(idList);
    toggleReloadControlsTable();
    setLoading(false);
  };

  const handleTransfer = async () => {
    setLoading(true);
    await transferEquipments();
    setLoading(false);
  };

  const parseSelectedForUpdate = (rowSelected: IEquipmentItem) => {
    setSelectedDescription(rowSelected.description);
    setSelectedStatus(rowSelected.status);
    setSelectedLocation(rowSelected.location);
    return {
      ...equipmentItem, mCbCustomerId: customerId, equipmentId: rowSelected.equipmentid, tagNumber: rowSelected.tag, description: rowSelected.description,
      status: rowSelected.status, acqDate: moment(rowSelected.acqDate).format('MM/DD/YYYY'), comments: rowSelected.comments, locationId: rowSelected.location,
    };
  };

  const parseForTransfer = (rowSelected: IEquipmentItem) => {
    const location: any = locationsList.filter((location: any) => location.id === rowSelected.location)[0];
    return {
      equipmentId: rowSelected.equipmentid, tagNumber: rowSelected.tag, description: rowSelected.description,
      status: rowSelected.status, acqDate: moment(rowSelected.acqDate).format('MM/DD/YYYY'), comments: rowSelected.comments,
      location: { locationId: location?.id, shipToNumber: location?.shipToNumber, mCbCustomerId: customerId },
    };
  };

  const fetchLocations = async () => {
    try {
      setLoading(true);
      const { data }: any = await apiService.getLocationsByCustomerId(
        customerId,
        { pageSize: 5000, pageNumber: 1 },
        {},
      );
      setLocationsList(data.data);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Locations fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchDescriptions = async () => {
    try {
      setLoading(true);
      const { data }: any = await apiService.sections.getEquipmentDescriptions();
      setDescriptionsList(data.equipmentDescriptions);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Descriptions fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchStatuses = async () => {
    try {
      setLoading(true);
      const { data }: any = await apiService.sections.getEquipmentStatuses();
      setStatusesList(data.equipmentStatuses);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Locations fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const getCustomers = debounce(async (cusromerData) => {
    fetchCustomers(cusromerData);
  }, 1000);

  const customerNameInputText = (custName: string) => {
    if (custName.replace(/ /g, '').length > 0) {
      getCustomers({ custName });
    }
  };

  const fetchNewLocations = async () => {
    try {
      setLoading(true);
      const { data }: any = await apiService.getLocationsByCustomerId(
        selectedCustomer,
        { pageSize: 5000, pageNumber: 1 },
        {},
      );
      setLocationsList(data.data);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Locations fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const shouldDisableEdit = () =>
    !equipmentItem?.tagNumber || !selectedDescription || !selectedStatus || !selectedLocation || !equipmentItem?.acqDate || !equipmentItem?.comments;

  useEffect(() => {
    async function fetchAPI() {
      await fetchLocations();
      await fetchDescriptions();
      await fetchStatuses();
      await fetchCustomers({});
    }
    fetchAPI();
  }, []);

  useEffect(() => {
    if (selectedCustomer !== '') {
      fetchNewLocations();
      setSelectedNewLocation('');
    }
  }, [selectedCustomer]);

  return (
    <div className="ms-Grid margin-left-rigth-2 marginTop18" dir='ltr'>
      <DataGridComponent
        idTable={'equipment-table'}
        title='Equipments'
        headCells={equipmentHeadCell}
        rowsTable={equipmentList}
        totalDataFound={totalFound}
        enableSearching={true}
        enablePagination={true}
        enableRowsPerPage={true}
        enableMultiSelectRow={userPermissions.isWrite}
        enableCheckBox={userPermissions.isWrite}
        enableDeleteOption={userPermissions.isWrite}
        isLoading={isLoadingTable}
        reloadControlsTable={reloadControlsTable}
        handleChangeDataGridState={handleChangeDataGridState}
        handleSelectRow={handleSelectToUpdateRow}
        handleDelete={handleDelete}
      />
      <div className='ms-Grid'>
        <div className='ms-Grid-row' dir='ltr'>
          <div className='ms-Grid-col ms-sm6'>
            <Text variant="xLarge" className={styles.highlight}>Equipment Details</Text>
            <SeparatorGy />
            <div className='ms-Grid-row' dir='ltr'>
              <div className='ms-Grid-col ms-sm6'>
                <TextField
                  id='tagId'
                  label="Tag Number:"
                  onRenderPrefix={() => <Icon iconName={'Tag'} aria-hidden="true" />}
                  value={equipmentItem?.tagNumber}
                  onChange={onChangeEquipmentTag}
                  required
                />
              </div>
              <div className='ms-Grid-col ms-sm6'>
                <AutocompleteInput
                  value={selectedDescription}
                  list={
                    descriptionsList ?
                      descriptionsList.map((description) => ({
                        key: description,
                        text: description,
                      })) : []
                  }
                  chooseCurrentItem={onChangeEquipmentDescription}
                  label="Description"
                  prefixIcon="Edit"
                  emptyExpanded
                  required
                />
              </div>
            </div>
            <div className='ms-Grid-row' dir='ltr'>
              <div className='ms-Grid-col ms-sm4'>
                <AutocompleteInput
                  value={selectedStatus}
                  list={
                    statusesList ?
                      statusesList.map((status) => ({
                        key: status,
                        text: status,
                      })) : []
                  }
                  chooseCurrentItem={onChangeEquipmentStatus}
                  label="Status"
                  prefixIcon="Info"
                  emptyExpanded
                  required
                />
              </div>
              <div className='ms-Grid-col ms-sm4'>
                <AutocompleteInput
                  value={selectedLocation}
                  list={
                    locationsList ?
                      locationsList.map(({ id, locationName }) => ({
                        key: id,
                        text: locationName,
                      })) : []
                  }
                  chooseCurrentItem={onChangeEquipmentLocation}
                  label="Location"
                  prefixIcon="POISolid"
                  emptyExpanded
                  required
                />
              </div>
              <div className='ms-Grid-col ms-sm4'>
                <Label htmlFor="acqDate" required>Acq Date:</Label>
                <DatePicker
                  id='acqDate'
                  value={equipmentItem.acqDate ? moment(equipmentItem.acqDate).toDate() : undefined}
                  onSelectDate={onChangeEquipmentDate}
                  formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                />
              </div>
            </div>
            <div className='ms-Grid-row' dir='ltr'>
              <div className='ms-Grid-col ms-sm12'>
                <TextField
                  id='comments'
                  label="Comments:"
                  value={equipmentItem?.comments}
                  onChange={onChangeEquipmentComments}
                  multiline
                  rows={5}
                  required
                />
              </div>
            </div>
            <div className='ms-Grid-row' dir='ltr'>
              {equipmentItem?.equipmentId ?
                <div className='ms-Grid-col'>
                  <PrimaryButton
                    id="updateButton"
                    className={styles.buttonTopMargin}
                    text="+ Update"
                    onClick={handleUpdateEquipment}
                    disabled={shouldDisableEdit() || !userPermissions.isWrite}
                  />
                </div> :
                <div className='ms-Grid-col'>
                  <PrimaryButton
                    id="addButton"
                    className={styles.buttonTopMargin}
                    text="+ Add New Equipment"
                    onClick={handleAddEquipment}
                    disabled={shouldDisableEdit() || !userPermissions.isWrite}
                  />
                </div>
              }
              <div className='ms-Grid-col'>
                <DefaultButton
                  id="clearButton"
                  className={styles.buttonTopMargin}
                  text="Clear"
                  onClick={clearEquipment}
                  disabled={!userPermissions.isWrite} />
              </div>
            </div>
          </div>
        </div>
        {equipmentItem?.equipmentId && <div className='ms-Grid-row marginTop18' dir='ltr'>
          <div className='ms-Grid-col ms-sm6'>
            <Text variant="xLarge" className={styles.highlight}>Transfer Equipment</Text>
            <SeparatorGy />
            <div className='ms-Grid-row' dir='ltr'>
              <div className='ms-Grid-col ms-sm6'>
                <AutocompleteInput
                  value={selectedCustomer}
                  list={
                    customersList ?
                      customersList.map(({ id, customerName }) => ({
                        key: id,
                        text: customerName,
                      })) : []
                  }
                  chooseCurrentItem={setSelectedCustomer}
                  label="Customer"
                  prefixIcon="Contact"
                  emptyExpanded
                  required
                  textValue={customerNameInputText}
                />
              </div>
              <div className='ms-Grid-col ms-sm6'>
                <AutocompleteInput
                  value={selectedNewLocation}
                  list={
                    locationsList ?
                      locationsList.map(({ id, locationName }) => ({
                        key: id,
                        text: locationName,
                      })) : []
                  }
                  chooseCurrentItem={setSelectedNewLocation}
                  label="New Location"
                  prefixIcon="POISolid"
                  emptyExpanded
                  required
                  disabled={!selectedCustomer}
                />
              </div>
            </div>
            <div className='ms-Grid-row' dir='ltr'>
              <div className='ms-Grid-col'>
                <PrimaryButton
                  className={styles.buttonTopMargin}
                  text="Transfer"
                  onClick={handleTransfer}
                  disabled={!selectedCustomer || !selectedNewLocation || !userPermissions.isWrite}
                />
              </div>
            </div>
          </div>
        </div>
        }
      </div>
      {loading && <LoadingScreen />}
    </div>
  );
};

export default EquipmentComponent;