import { Checkbox, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IColumn, IconButton, IDropdownOption, MaskedTextField, MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortOrder } from '../../../../../consts/sortOrder';
import { customerInformationSelector, customerSelector, locationSelector, setLocation } from '../../../../../redux/recordKeepingSlice';
import { IPaginationProps } from '../../../../Pagination/IPaginationProps';
import { IPostedVehicleMilesProps } from './IPostedVehicleMilesProps';
import { IPostedVehicleMilesState } from './IPostedVehicleMilesState';
import { useBoolean } from '@fluentui/react-hooks';
import apiService from '../../../../../api';
import styles from './PostedVehicleMiles.module.scss';
import { locationColumns, columns } from './consts';
import { pageSizes } from '../../../../../consts/recordKeeping';
import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../Pagination/Pagination';
import classNames from 'classnames';
import AutocompleteInput from '../../../../../shared/AutocompleteInput';
import LoadingScreen from '../../../../LoadingScreen/LoadingScreen';
import SelectingModal from '../../../../SelectingModal/SelectingModal';
import useNotifications from '../../../../../hooks/useNotifications';
import { LOCATION } from '../postedMonthlyMiles/consts';
import AddingModal from '../../../../AddingModal/AddingModal';
import PrintingModal from '../../../../PrintingModal/PrintingModal';
import ErrorsModal from '../../../../ErrorsModal/ErrorsModal';
import { downloadFile, printingTypes } from '../../../../PrintingModal/consts';

const PostedVehicleMiles: FC<IPostedVehicleMilesProps> = ({ billingPeriodId, filters }): ReactElement => {

  const [state, setState] = useState<IPostedVehicleMilesState>({
    loading: false,
    items: [],
    selectedItems: [],
    foundCount: 0,
  });

  const [locationState, setLocationState] = useState<Array<any>>([]);

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });

  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);
  const { billBy } = useSelector(customerInformationSelector);

  const [location, setCurrentLocation] = useState<any>(null);
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [showLocationModal, { toggle: toggleShowLocationModal }] = useBoolean(false);
  const [showAddingModal, { toggle: toggleShowAddingModal }] = useBoolean(false);
  const [isNotReported, { toggle: toggleIsNotReported }] = useBoolean(false);
  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [showErrorsModal, { toggle: toggleShowErrorsModal }] = useBoolean(false);

  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const dispatch = useDispatch();

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(
      ({ isSorted }) => isSorted,
    );
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const setTireToBeEdited = (id: string, field: string, value: any, regExp?: RegExp) => {
    if (!regExp || (regExp && regExp.test(value))) {
      setState((prev: any) => ({
        ...prev,
        items: prev.items.map((item: any) => item.id === id ? { ...item, [field]: value } : item),
      }));
    }
  };

  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const fetchLocations = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data }: any = await apiService.getLocationsByCustomerId(
        customerId,
        { pageSize: 5000, pageNumber: 1 },
        {},
      );
      setLocationState(data.data);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Location fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchVehicleMiles = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data } = await apiService.vehicleMiles.getVehicleMiles(
        { billingPeriodId, isNotReported },
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        location,
        customerId,
      );
      const foundCount = data?.total.found;
      const items = data?.data;
      setState((prev: any) => ({
        ...prev,
        items,
        foundCount,
        selectedItems: [],
      }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Vehicle Miles fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const saveVehicleMiles = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      await apiService.vehicleMiles.save(
        state.items.map(item => ({...item, billingId: billingPeriodId})),
        customerId,
        location,
      );
      addNotification({
        text: 'Vehicle Miles saved.',
        type: MessageBarType.success,
      });
      mountingProcessForData();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Vehicle Miles save error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const submitVehicleMiles = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      await apiService.vehicleMiles.submit(
        state.items.map(items => items.id),
        billingPeriodId,
        customerId,
        location || locationId,
      );
      addNotification({
        text: 'Monthly Miles submited.',
        type: MessageBarType.success,
      });
      mountingProcessForData();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Vehicle Miles submit error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const releaseVehicleMiles = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      await apiService.vehicleMiles.release(
        billingPeriodId,
        customerId,
        location,
      );
      addNotification({
        text: 'Monthly Miles released.',
        type: MessageBarType.success,
      });
      mountingProcessForData();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Vehicle Miles release error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.vehicleMiles.delete(state.selectedItems, customerId, location);
      addNotification({
        text: 'Selected tire(s) were successfully deleted.',
        type: MessageBarType.success,
      });
      if (paginationProps.current === 1) {
        await fetchVehicleMiles();
      } else {
        await paginationProps.onChangePage(1);
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Monthly Miles deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        filters: { billingPeriodId: billingPeriodId },
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        location,
      };
   
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.vehicleMiles.printExcel(requestData) :
        await apiService.vehicleMiles.printPdf(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSelect = (e: any, itemId: any) => {
    if (e.target.checked) {
      setState((prev: any) => ({ ...prev, selectedItems: [...state.selectedItems, itemId] }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: state.selectedItems.filter(row => row !== itemId) }));
    }
  };

  const handleSelectAll = (e: any, items: Array<any>) => {
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedItems: items })) : setState((prev: any) => ({ ...prev, selectedItems: [] }));
  };

  const setNewLocation = (locationId: string) => {
    setCurrentLocation(() => (locationId));
    dispatch(setLocation(locationState?.find(({ id }) => id === locationId)));
    toggleShowLocationModal();
  };

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  
    setColumnsState(newColumns);
  };

  const onLocationClear = (text: string) => {
    if (!text) {
      setCurrentLocation(null);
    }
  };

  const selectLocation = (locationId: string) => {
    setCurrentLocation(locationId);
    dispatch(setLocation(locationState?.find(({ id }) => id === locationId)));
  };

  const mountingProcess = async () => {
    await fetchLocations();
    await apiService.postMonthlyMiles.getPostMonthlyMilesByVehicle(
      billingPeriodId,
      location,
      customerId,
    );
  };

  const mountingProcessForData = async () => {
    await fetchVehicleMiles();
  };

  useEffect(() => {
    mountingProcess();
  }, []);

  useEffect(() => {
    mountingProcessForData();
  }, [billingPeriodId, location, paginationProps.current, columnsState, countOnPage, isNotReported]);

  return (
    <>
      <div className={styles.modalContainer}>
        <div className={styles.modalBody}>
          <div>
            <div>
              <div className={styles.totalInfo}>
                <div>
                  <Checkbox
                    className={classNames(styles.baselineItems, styles.paddedElement)}
                    checked={billBy == 'Location'}
                    disabled
                  />
                  <Text variant="large" className={styles.highlight}>Mileage by location&nbsp;</Text>
                </div>
              </div>
              <div className={styles.location}>
                <div>
                  <div>
                    <AutocompleteInput
                      value={location}
                      list={locationState.map(((location: any) => ({ key: location.id, text: location.locationName })))}
                      chooseCurrentItem={selectLocation}
                      emptyExpanded
                      disabled={billBy !== LOCATION}
                      textValue={onLocationClear}
                    />
                    <IconButton
                      iconProps={{ iconName: 'Search' }}
                      onClick={toggleShowLocationModal}
                      disabled={billBy !== LOCATION}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.postTypeOptions}>
              <DefaultButton text="+ Add" onClick={toggleShowAddingModal} disabled={billBy === LOCATION && !location}/>
            </div>
            <div className={styles.billingPeriod}>
              <Text variant="large" className={styles.highlight}>Select Billing Period</Text>
              <div>
                <div>
                  <Text variant="medium" className={styles.highlight}>From</Text>
                  <MaskedTextField
                    value={filters.from}
                    mask="99/99/9999"
                    disabled
                  />
                </div>
                <div>
                  <Text variant="medium" className={styles.highlight}>To</Text>
                  <MaskedTextField
                    value={filters.to}
                    mask="99/99/9999"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          
          <div>
            <div className={styles.tableHeading}>
              <div>
                <Text variant="xLarge" className={styles.highlight}>Posted Vehicle Miles</Text>
                <SeparatorGy vertical />
                <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
              </div>
              <div>
                <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                <Dropdown
                  options={pageSizes.map(pageSize => ({
                    key: pageSize,
                    text: pageSize.toString(),
                  }))}
                  defaultSelectedKey={pageSizes[0]}
                  selectedKey={countOnPage?.key}
                  onChange={onChangeCountOnPage}
                />
                <SeparatorGy vertical />
                <Text variant="large" className={styles.highlight}>{state.selectedItems.length} items selected</Text>
                <SeparatorGy vertical />
                <IconButton
                  id="toggleDeletingConfirmationButton"
                  disabled={!state.selectedItems.length}
                  iconProps={{ iconName: 'Delete' }}
                  onClick={toggleDeletingConfirmation}
                />
              </div>
            </div>
            <div className={styles['table-wrapper']}>
              <table>
                <thead>
                  <tr>
                    {
                      columnsState.map(item => (
                        <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                      ))
                    }
                    <th>
                      <div className={styles.round}>
                        <input
                          type="checkbox"
                          id="all"
                          checked={state.items.length !== 0 && (state.selectedItems.length === +countOnPage.key || state.selectedItems.length === state.items.length)}
                          onChange={(e) => handleSelectAll(e, state.items.map(({ id }) => id))}
                        />
                        <label htmlFor="all"></label>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    state.items.map(item => (
                      <tr key={item.id} className={styles.trBasic}>
                        <td>
                          <TextField
                            value={item.vNo}
                            onChange={(e, value) => setTireToBeEdited(item.id, 'vNo', value)}
                          />
                        </td>
                        <td>
                          <TextField
                            value={item.miles}
                            onChange={(e, value) => setTireToBeEdited(item.id, 'miles', value)}
                          />
                        </td>
                        <td>
                          <TextField
                            value={item.odoStart}
                            onChange={(e, value) => setTireToBeEdited(item.id, 'odoStart', value)}
                          />
                        </td>
                        <td>
                          <TextField
                            value={item.odoEnd}
                            onChange={(e, value) => setTireToBeEdited(item.id, 'odoEnd', value)}
                          />
                        </td>
                        <td>{item.submit ? 'Y' : 'N'}</td>
                        <td>
                          <div className={styles.round}>
                            <input type="checkbox" id={item.id} checked={state.selectedItems.includes(item.id)} onChange={(e) => handleSelect(e, item.id)} />
                            <label htmlFor={item.id}></label>
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <SeparatorGy />
            <Pagination {...paginationProps} />
            <div className={classNames(styles.buttonsWrapper)}>
              <PrimaryButton
                id="saveButton"
                onClick={saveVehicleMiles}
                text="Save"
                disabled={billBy == 'Location' && !location}
              />
              <PrimaryButton
                id="submitButton"
                onClick={submitVehicleMiles}
                text="Submit"
                disabled={billBy == 'Location' && !location}
              />
              <PrimaryButton
                id="releaseButton"
                onClick={releaseVehicleMiles}
                text="Release"
                disabled={billBy == 'Location' && !location}
              />
            </div>
          </div>
        </div>
        <div className={classNames(styles.buttonsWrapper)}>
          <DefaultButton
            id="printExportButton"
            onClick={toggleShowPrintExport}
            text="Print/Export"
          />
          <DefaultButton
            id="viewErrorsButton"
            onClick={toggleShowErrorsModal}
            text="View Errors"
          />
        </div>
      </div>
      <AddingModal
        isModalOpen={showAddingModal}
        hideModal={toggleShowAddingModal}
        saveTableData={apiService.vehicleMiles.save}
        fetchAfterAdding={fetchVehicleMiles}
        columns={columns.filter(item => item.fieldName == 'vNo' || item.fieldName == 'miles')}
        additionalFieldsToBody={{billingId: billingPeriodId}}
      />
      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to delete ${state.selectedItems.length} items?`,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <SelectingModal
        isOpen={showLocationModal}
        title="Available Locations"
        selectingList={locationState.map((location: any) => ({...location}))}
        onDismiss={toggleShowLocationModal}
        onSubmit={setNewLocation}
        columns={locationColumns}
        preselectedKey={location}
      />
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      <ErrorsModal
        isModalOpen={showErrorsModal}
        hideModal={toggleShowErrorsModal}
        fetchErrorsService={apiService.vehicleMiles.getErrors}
      />
      {state.loading && <LoadingScreen />}
    </>
  );};

export default PostedVehicleMiles;

