import { INVOICESTATUS_ENUM } from '../../../../models/EnumInvoiceStatuses';
import { HeadCell } from '../../../../shared/DataGridComponent/DataGridModels';
import { IFormDataField } from '../../../../shared/FormBuilderComponent';

export type SearchOptions = 'regionSearch' | 'customerSearch' | 'SAPSearch';
export type CheckboxOptions = 'releaseAll' | 'byViewSelection';

export const availableInvoicesHeadCell: HeadCell[] = [
  {
    key: 'column1',
    name: 'Brand Prefix',
    fieldName: 'brandPrefix',
    typeField: 'text',
  },
  {
    key: 'column0',
    name: 'Customer Name',
    fieldName: 'name',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Region',
    fieldName: 'region',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Location',
    fieldName: 'location',
    typeField: 'text',
  },
  {
    key: 'column4',
    name: 'SAP Invoice #',
    fieldName: 'invoiceNumber',
    typeField: 'text',
  },
  {
    key: 'column5',
    name: 'MIDAS #',
    fieldName: 'invoiceId',
    typeField: 'text',
  },
  {
    key: 'column6',
    name: 'Invoice Date',
    fieldName: 'invoiceDate',
    typeField: 'date',
  },
  {
    key: 'column7',
    name: 'Billing Type',
    fieldName: 'billingType',
    typeField: 'text',
  },
  {
    key: 'column8',
    name: 'Status',
    fieldName: 'status',
    typeField: 'text',
  },
  {
    key: 'column9',
    name: 'CRDB',
    fieldName: 'crDb',
    typeField: 'text',
  },
  {
    key: 'column10',
    name: 'Amount',
    fieldName: 'amount',
    typeField: 'currency',
  },
  {
    key: 'column11',
    name: 'Period End',
    fieldName: 'periodEnd',
    typeField: 'text',
  },
  {
    key: 'column12',
    name: 'Created By',
    fieldName: 'createdBy',
    typeField: 'text',
  },
  {
    key: 'column13',
    name: 'Release Date',
    fieldName: 'releaseDate',
    typeField: 'text',
  },
  {
    key: 'column14',
    name: 'Hold Date',
    fieldName: 'holdDate',
    typeField: 'text',
  },
  {
    key: 'column15',
    name: 'Failure Date',
    fieldName: 'failureDate',
    typeField: 'text',
  },
  {
    key: 'column16',
    name: 'Success Date',
    fieldName: 'successDate',
    typeField: 'text',
  },
];

export interface IActionType {
  keyword: string,
  text: string,
};

export const ReleaseAction: IActionType = {
  keyword: INVOICESTATUS_ENUM.RELEASED,
  text: 'Are you sure you want to change the state of the selected invoices to RELEASED?',
};

export const SEARCH_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'regionField',
    properties: {
      id: 'region',
      label: 'Region',
      name: 'region',
      type: 'text',
    },
  },
  {
    formFieldName: 'prefixField',
    properties: {
      id: 'prefix',
      label: 'Prefix',
      name: 'prefix',
      type: 'text',
    },
  },
  {
    formFieldName: 'customerNameField',
    properties: {
      id: 'customerName',
      label: 'Customer Name',
      name: 'customerName',
      type: 'text',
    },
  },
  {
    formFieldName: 'sapInvoiceNoField',
    properties: {
      id: 'sapInvoiceNo',
      label: 'SAP Invoice #',
      name: 'sapInvoiceNo',
      type: 'text',
    },
  },
];

export const FILTERS_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'monthOfField',
    properties: {
      id: 'monthOf',
      label: 'During the month of',
      name: 'monthOf',
      type: 'text',
    },
  },
  {
    formFieldName: 'currentYearField',
    properties: {
      id: 'currentYear',
      label: 'Current year',
      name: 'currentYear',
      type: 'date',
    },
  },
  {
    formFieldName: 'statusField',
    properties: {
      id: 'status',
      label: 'Filter by Status',
      name: 'status',
      type: 'text',
    },
  },
  {
    formFieldName: 'invoiceTypeField',
    properties: {
      id: 'invoiceType',
      label: 'Invoice type',
      name: 'invoiceType',
      type: 'text',
    },
  },
  {
    formFieldName: 'creditOnlyField',
    properties: {
      id: 'creditOnly',
      label: 'View credit only',
      name: 'creditOnly',
      type: 'checkbox',
    },
  },
];

export interface ICustomFilters {
  byDate: {
    currentYear: boolean | null,
    duringTheMonth: Date | null,
  },
  status: string | null,
  invoiceType: string | null,
};