import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { isEqual, isNil, sortBy } from 'lodash';
import { DefaultButton, PrimaryButton, MessageBarType, Modal, Text, Icon, TextField, Dialog, DialogType, DialogFooter, IconButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';

import useNotifications from '../../../../../../hooks/useNotifications';
import apiService from '../../../../../../api';

import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';

import { IPermissionEditingModalProps } from './IPermissionEditingModalProps';
import { IPermissionEditingModalState } from './IPermissionEditingModalState';

import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_admin_userManagement, auth_admin_taskScheduler } from '../../../../../../consts/programKeys';

import styles from './PermissionEditingModal.module.scss';

const PermissionEditingModal: FC<IPermissionEditingModalProps> = ({ isModalOpen, hideModal, roleData }): ReactElement => {

  const { hasModuleAccess } = useUserPermissions();

  const { addNotification } = useNotifications();
  const [state, setState] = useState<IPermissionEditingModalState>({
    loading: false,
    roleName: '',
    grantedModules: [],
    originGrantedModules: [],
    selectedModules: [],
    modulesList: [],
  });
  const [showCancelConfirmation, { toggle: toggleShowCancelConfirmation }] = useBoolean(false);
  const [showSubmitConfirmation, { toggle: toggleShowSubmitConfirmation }] = useBoolean(false);

  const creatingNewRole = useMemo(() => isNil(roleData), [roleData]);

  const disableAdministratorRights = (hasModuleAccess('Administrator') && state.roleName === 'Administrator') ;
  
  const isSubmitDisabled = isEqual(sortBy(state.originGrantedModules), sortBy(state.grantedModules)) || 
  (creatingNewRole && (state.roleName.length === 0 || state.grantedModules.length === 0));

  const fetchModules = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await apiService.management.getModules();
      setState((prev) => ({ ...prev, modulesList: data.data }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching modules error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchRoleModules = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await apiService.management.getRoleModules(roleData.id);
      setState((prev) => ({
        ...prev,
        grantedModules: data.data.map(({ moduleId }: any) => moduleId),
        originGrantedModules: data.data.map(({ moduleId }: any) => moduleId),
        selectedModules: [],
        roleName: roleData.name,
      }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching modules error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    fetchModules();
  }, []);

  useEffect(() => {
    if (isModalOpen && !creatingNewRole) {
      fetchRoleModules();
    } else if (creatingNewRole) {
      setState((prev: IPermissionEditingModalState) => ({
        ...prev,
        roleName: '',
        grantedModules: [],
        selectedModules: [],
        originGrantedModules: [],
      }));
    }
  }, [
    isModalOpen,
  ]);

  const handleSubmit = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const modules = state.modulesList
        .filter(({ id }: any) => state.grantedModules.includes(id))
        .map(({ id }: any) => ({
          moduleId: id,
          isAllow: true,
        }));

      if (creatingNewRole) {
        await apiService.management.createNewRole(state.roleName, modules);
      } else {
        await apiService.management.updateRoleModules(roleData.id, modules);
      }

      addNotification({
        text: `Role was successfully ${creatingNewRole ? 'created' : 'updated'}.`,
        type: MessageBarType.success,
      });
      setState(prev => ({ ...prev, loading: false }));
      hideModal();
    } catch (e: any) {
      setState(prev => ({ ...prev, loading: false }));
      const { response } = e;
      addNotification({
        text: `Role ${creatingNewRole ? 'creating' : 'updating'} error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const handleChangeSelectedModule = (moduleName: any) => {
    const pos = state.selectedModules.indexOf(moduleName);
    const tmp = state.selectedModules;
    if (pos !== -1) {
      tmp.splice(pos, 1);
    } else {
      tmp.push(moduleName);
    }
    setState((prev: IPermissionEditingModalState) => ({
      ...prev,
      selectedModules: tmp,
    }));
  };

  const handleAdd = () => {
    setState((prev: IPermissionEditingModalState) => ({
      ...prev,
      selectedModules: prev.selectedModules.filter((p) => prev.grantedModules.includes(p)),
      grantedModules: [...prev.grantedModules, ...prev.selectedModules.filter((p) => !prev.grantedModules.includes(p))],
    }));
  };

  const handleRemove = () => {
    setState((prev: IPermissionEditingModalState) => ({
      ...prev,
      selectedModules: prev.selectedModules.filter((p) => !prev.grantedModules.includes(p)),
      grantedModules: prev.grantedModules.filter((p) => !prev.selectedModules.includes(p)),
    }));
  };

  const handleCancelClick = () => {
    if (
      (creatingNewRole && state.roleName.length !== 0) ||
      !isEqual(sortBy(state.originGrantedModules), sortBy(state.grantedModules))
    ) {
      toggleShowCancelConfirmation();
    } else {
      hideModal();
    }
  };

  return state.loading ?
    (<LoadingScreen />) : (
      <>
        <Modal
          isOpen={isModalOpen}
          onDismiss={handleCancelClick}
          containerClassName={styles.modalContainer}
        >
          <div className='modalHeader'>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel="Close popup modal"
              onClick={hideModal}
            />
          </div>
          <Text block variant="xLarge" id="title">
            {creatingNewRole ? 'Create a new role' : 'Edit role'}
          </Text>
          <SeparatorGy />
          <div className={styles.modalBody}>
            <div className={styles.blockWrapper}>
              <TextField
                value={state.roleName}
                onChange={(ev: any, roleName: any) => setState((prev) => ({ ...prev, roleName }))}
                label={`${creatingNewRole ? 'New ' : ''}Role Name`}
                disabled={!creatingNewRole}
                errorMessage={creatingNewRole && state.roleName.length === 0 ? 'Please enter a role name' : undefined}
              />
              <div className={styles.copyingInstruction}>
                <Icon iconName="Error" className={styles.instructionIcon} />
                <Text>Add permissions to modules for this role by selecting the desired module on the left and move it to the "Added Modules" section on the right.</Text>
              </div>
              <div>
                <Text variant='large'>Modules</Text>
                <div className={styles.permissionsWrapper}>
                  {state.modulesList
                    .filter((p) => !state.grantedModules.includes(p.id))
                    .map((p: any) => (
                      <DefaultButton
                        toggle
                        className={styles.permissionButton}
                        text={p.name}
                        checked={state.selectedModules.includes(p.id)}
                        onClick={() => handleChangeSelectedModule(p.id)}
                      />
                    ))}
                </div>
              </div>
            </div>
            <div className={styles.buttonsWrapper}>
              <PrimaryButton
                iconProps={{ iconName: 'ChromeBackMirrored' }}
                onClick={handleAdd}
                text="Add"
                disabled={disableAdministratorRights}
              />
              <DefaultButton
                iconProps={{ iconName: 'ChromeBack' }}
                onClick={handleRemove}
                text="Remove"
                disabled={disableAdministratorRights}
              />
            </div>
            <div className={styles.blockWrapper}>
              <div />
              <div className={styles.copyingInstruction}>
                <Icon iconName="Error" className={styles.instructionIcon} />
                <Text>
                  All permissions to modules are read-only by default. You must edit this permission group after creation for any further customization.
                  <br />
                  <br />
                  Remove permissions to modules for this role by selecting the desired module on the right and move it back to the "Modules" section on the left.
                </Text>
              </div>
              <div>
                <Text variant='large'>Added Modules</Text>
                <div className={styles.permissionsWrapper}>
                  {state.modulesList
                    .filter((p) => state.grantedModules.includes(p.id))
                    .map((p: any) => (
                      <DefaultButton
                        toggle
                        className={styles.permissionButton}
                        text={p.name}
                        checked={state.selectedModules.includes(p.id)}
                        onClick={() => handleChangeSelectedModule(p.id)}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
          <SeparatorGy />
          <div className={styles.modalFooter}>
            <DefaultButton
              id="cancelCopyingRoleButton"
              text="Cancel"
              onClick={handleCancelClick}
            />
            <PrimaryButton
              id="submitButton"
              text={creatingNewRole ? 'Create' : 'Save Updates'}
              onClick={creatingNewRole ? toggleShowSubmitConfirmation : handleSubmit}
              disabled={isSubmitDisabled}
            />
          </div>
        </Modal>
        <Dialog
          hidden={!showCancelConfirmation}
          onDismiss={toggleShowCancelConfirmation}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Confirmation',
            subText: creatingNewRole ?
              'Are you sure you would like to cancel? This group will not be created.' :
              'Are you sure you would like to cancel discard all changes made?',
          }}
          modalProps={{ isBlocking: true }}
        >
          <DialogFooter>
            <DefaultButton onClick={toggleShowCancelConfirmation} text="No - Return" />
            <PrimaryButton onClick={() => { toggleShowCancelConfirmation(); hideModal(); }} text="Yes - Cancel" />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!showSubmitConfirmation}
          onDismiss={toggleShowSubmitConfirmation}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Confirmation',
            subText: 'Are you sure you would like to proceed and create this new role?',
          }}
          modalProps={{ isBlocking: true }}
        >
          <Text><b>The role name cannot be modified upon creation. Be sure to confirm the role name before creating the group.</b></Text>
          <DialogFooter>
            <DefaultButton onClick={toggleShowSubmitConfirmation} text="No - Return" />
            <PrimaryButton onClick={() => { toggleShowSubmitConfirmation(); handleSubmit(); }} text="Yes - Create role" />
          </DialogFooter>
        </Dialog>
      </>
    );
};

export default PermissionEditingModal;
