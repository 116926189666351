import { FC, ReactElement, useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useBoolean } from '@fluentui/react-hooks';
import { Text, MessageBarType, TextField, Toggle, PrimaryButton, DefaultButton, Spinner } from '@fluentui/react';
import { useDispatch } from 'react-redux';
import { IBackoutMileageComponentProps } from './IBackoutMileageComponentProps';
import { IBackoutMileageComponentState } from './IBackoutMileageComponentState';
import apiService from '../../api';
import styles from './BackoutMileageComponent.module.scss';
import { downloadFile, printingTypes } from '../../components/PrintingModal/consts';
import SeparatorGy from '../../components/SeparatorGy/SeparatorGy';
import PrintingModal from '../../components/PrintingModal/PrintingModal';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { pushToast } from '../../redux/notificationsSlice';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import useNotifications from '../../hooks/useNotifications';
import { auth_mileageRun_backout } from '../../consts/programKeys';
import DialogComponent from '../DialogComponent';
import { BACKOUT_MESSAGES } from './consts';

let startDetailsInterval: any = null;
const BackoutMileageComponent: FC<IBackoutMileageComponentProps> = ({
  customerId,
  locationId,
  detailsAPI,
  startAPI,
  title,
}): ReactElement => {

  const [state, setState] = useState<IBackoutMileageComponentState>({
    loading: false,
    data: [{
      id: 0,
      invoiceNumber: 0,
      invoiceDate: 0,
      billingType: '',
      creditOrDebit: '',
      amount: '',
      status: '',
      information: '',
      periodEnd: '',
    }],
  });

  const [options, setOptions] = useState<any>({
    isDeleted: false,
    information: '',
  });
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [parsedErrors, setParsedErrors] = useState<any>([]);
  const dispatch = useDispatch();
  const { addNotification } = useNotifications();
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [isRunningMessageOpen, setIsRunningMessageOpen] = useState(false);
  const [showBackoutInfo, setShowBackoutInfo] = useState(false);
  const [backoutMessage, setBackoutMessage] = useState('');
  const [completedStatus, setCompletedStatus] = useState('');
  const [runStatus, setRunStatus] = useState('');
  const { hasPermission } = useUserPermissions();
  const userPermissionsBackOut = hasPermission(auth_mileageRun_backout);

  const onChangeField = (field: any, value: any, regExp?: RegExp) =>
    setOptions((prev: any) => ({ ...prev, [field]: regExp ? (regExp.test(value) ? value : prev[field]) : value }));

  const fetchBackoutDetails = async () => {
    setState((prev: any) => ({ ...prev, loading: true }));
    try {
      const { data: details }: any = await detailsAPI(customerId);
      setRunStatus(details.data[0].runStatus);
      setState((prev: any) => ({
        ...prev,
        data: details.data.map((item: any) => ({
          id: item.id,
          invoiceNumber: item.invoiceNumber,
          invoiceDate: item.invoiceDate,
          billingType: item.billingType,
          creditOrDebit: item.creditOrDebit,
          amount: item.amount,
          status: item.status,
          information: item.information,
          periodEnd: item.periodEnd,
        })),
      }));
      if (details.data[0].id != null) {
        await fetchBackoutStartDetailsInterval();
        addNotification({
          text: `Customer ${customerId}, run status ${details.data[0].runStatus}`,
          type: MessageBarType.success,
        });
        if (details.data[0].runStatus === 'BACKOUT MILEAGE RUNNING' || details.data[0].runStatus === 'MILEAGE RUNNING') {
          setIsRunningMessageOpen(true);
        }
        setShowBackoutInfo(details.data[0].runStatus !== 'IDLE');
        setBackoutMessage(`${BACKOUT_MESSAGES[details.data[0].runStatus]} ${details.data[0].runStatus === 'FAILED' ? details.data[0].tcerrors : ''}`);
        setCompletedStatus(details.data[0].complStatus);
      }
    } catch (e: any) {
      const { response } = e;
      dispatch(
        pushToast({
          text: `Mileage Run fetching error: ${response.data.message}`,
          type: MessageBarType.error,
        }),
      );
      clearStartDetailsInterval();
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const getInvoiceData = (field: string) => {
    const fieldValues: any[] = state.data.map((item: any) => item[field]);
    if ((field === 'invoiceNumber' || field == 'information' || field === 'amount') && fieldValues.length > 1) {
      return fieldValues.join(', ');
    } else if (field === 'invoiceDate' || field === 'periodEnd') {
      return fieldValues[0] ? moment(fieldValues[0]).format('MM/DD/YYYY') : 'N/A';
    } else {
      return fieldValues[0];
    }
  };

  const fetchBackoutStartDetailsInterval = async () => {
    if (!startDetailsInterval) {
      startDetailsInterval = setInterval(fetchBackoutDetails, 10000);
    }
  };

  const clearStartDetailsInterval = () => {
    clearInterval(startDetailsInterval);
    startDetailsInterval = null;
  };

  const handleManageStartWarning = () => {
    if (state.data[0].status === 'SUCCESS') {
      setIsWarningOpen(true);
      return;
    };
    handleStart();
  };

  const handleStart = async () => {
    setIsWarningOpen(false);
    try {
      setState((prev: any) => ({ ...prev, loading: true }));
      const { data }: any = await startAPI(
        options.isDeleted,
        options.information,
        Array.from(state.data, (item: any) => item.id),
        customerId,
      );
      await fetchBackoutDetails();
      setParsedErrors([]);
      addNotification({
        text: data.message,
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          setParsedErrors(response.data.state);
          return addNotification({
            text: 'Mileage run starting error.',
            type: MessageBarType.error,
          });
        default:
          return addNotification({
            text: response.data.message,
            type: MessageBarType.error,
          });
      }
    }
    finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const requestData = {
        customerId,
      };
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.mileageRun.printExcelBackout(requestData, []) :
        await apiService.mileageRun.printExcelBackout(requestData, []);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Printing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const parseErrors = (field: string) => {
    const customError = parsedErrors?.filter((error: { field: string; }) => error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  useEffect(() => {
    if (customerId) {
      clearStartDetailsInterval();
      fetchBackoutDetails();
    }
  }, [customerId, locationId]);

  useEffect(() => {
    return () => clearStartDetailsInterval();
  }, []);

  const disableStartButton =
    !(options.information && userPermissionsBackOut.isWrite)
    || isRunningMessageOpen
    || !completedStatus;

  return (
    <>
      {!showBackoutInfo && <div>
        <div>
          <div className="ms-Grid-row">
            {title && <div className="ms-Grid-col ms-lg12 ms-xs12">
              <Text variant="xLarge" className={styles.highlight}>{title}</Text>
            </div>}
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-lg4 ms-xs12">
              <Text variant="large" className={styles.highlight}>Last Mileage Run Details</Text>
              <SeparatorGy />
              <div className={styles.detailsField}>
                <Text variant='large' block className={styles.highlight}>Invoice Number(s)</Text>
                <Text variant='xLarge' block className={styles.highlight}>{getInvoiceData('invoiceNumber')}</Text>
              </div>
              <div className={styles.detailsField}>
                <Text variant='large' block className={styles.highlight}>Invoice Date</Text>
                <Text variant='xLarge' block className={styles.highlight}>{getInvoiceData('invoiceDate')}</Text>
              </div>
              <div className={styles.detailsField}>
                <Text variant='large' block className={styles.highlight}>Billing Type</Text>
                <Text variant='xLarge' block className={styles.highlight}>{getInvoiceData('billingType')}</Text>
              </div>
              <div className={styles.detailsField}>
                <Text variant='large' block className={styles.highlight}>Credit/Debit?</Text>
                <Text variant='xLarge' block className={styles.highlight}>{getInvoiceData('creditOrDebit')}</Text>
              </div>
              <div className={styles.detailsField}>
                <Text variant='large' block className={styles.highlight}>Amount(s)</Text>
                <Text variant='xLarge' block className={styles.highlight}>{getInvoiceData('amount')}</Text>
              </div>
              <div className={styles.detailsField}>
                <Text variant='large' block className={styles.highlight}>Status</Text>
                <Text variant='xLarge' block className={styles.highlight}>{getInvoiceData('status')}</Text>
              </div>
              <div className={styles.detailsField}>
                <Text variant='large' block className={styles.highlight}>Additional Information(s)</Text>
                <Text variant='xLarge' block className={styles.highlight}>{getInvoiceData('information')}</Text>
              </div>
              <div>
                <Text variant='large' block className={styles.highlight}>Period End</Text>
                <Text variant='xLarge' block className={styles.highlight}>{getInvoiceData('periodEnd')}</Text>
              </div>
            </div>
            <div className="ms-Grid-col ms-lg8 ms-xs12">
              <Text variant="large" className={styles.highlight}>Back Out Options</Text>
              <SeparatorGy />
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-lg6 ms-xs12">
                  <TextField
                    id="information"
                    label="Back out information for log"
                    onChange={(e, value) => onChangeField('information', value)}
                    multiline
                    rows={8}
                    errorMessage={parseErrors('backOutMessage')}
                    required
                  />
                </div>
                <div className="ms-Grid-col ms-lg6 ms-xs12">
                  <Toggle id="deleteToggle" label="Delete Post Vehicle Miles?" onText="Delete" offText="Do Not Delete" onChange={(e, value) => onChangeField('isDeleted', value)} />
                </div>
              </div>
            </div>
          </div>

          <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
            <DefaultButton
              onClick={toggleShowPrintExport}
              text="Print/Export"
            />
            <PrimaryButton
              id='startButton'
              onClick={handleManageStartWarning}
              text="Start back out"
              disabled={disableStartButton}
            />
            <Spinner />
          </div>
        </div>
      </div>
      }

      {showBackoutInfo && <div className="ms-Grid-row">
        {title && <div className="ms-Grid-col ms-lg12 ms-xs12">
          <Text variant="xLarge" className={styles.highlight}>{title}</Text>
        </div>}
        <div className={styles.backoutContainer}>
          <p className={styles.processRunning}>
            {isRunningMessageOpen && <Spinner />}
          </p>
          <div>
            <p>{backoutMessage}</p>
          </div>
        </div>
      </div>
      }

      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint} />
      {state.loading && <LoadingScreen />}
      {
        <DialogComponent
          isOpen={isWarningOpen}
          onCancel={() => setIsWarningOpen(false)}
          onSubmit={handleStart}
          title="Mileage Back out Warning"
          subText="SUCCESS invoice found, Invoice will need a manual Credit Adjustment, Gather info needed for this credit BEFORE continuing as invoice will not be available later, Press OK to Back Out or Cancel to Exit"
          onSubmitLabel="OK"
          onCancelLabel="CANCEL"
        />
      }
    </>
  );
};

export default BackoutMileageComponent;