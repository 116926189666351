import { termTestInstance } from '../axios';

const getContractDocuments = (pagination: any, sortOrder: any, filterText: string, customerId: string, contractId: string) =>
  termTestInstance.post('/terms/contract-documents/list', { pagination, sortOrder, filterText, customerId, contractId });

const downloadContractDocument = (customerId: string, contractId: string, name: string) =>
  termTestInstance.post('/contract-document/download', { customerId, contractId, name }, {
    headers:
      {
        'Content-Disposition': 'attachment; filename=template.docx',
        'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      },
    responseType: 'arraybuffer',
  });


export default {
  getContractDocuments,
  downloadContractDocument,
};