import { IconButton } from '@fluentui/react';
import { FunctionComponent } from 'react';
import ViewMaintainInvoicesModalComponent from '../../../ViewMaintainInvoicesModalComponent';
import { useBoolean } from '@fluentui/react-hooks';
import ViewMaintainTireSalesModalComponent from '../../../ViewMaintainTireSalesModalComponent';


interface MaintainTireSalesActionProps {
  row: any;
}
 
const MaintainTireSalesAction: FunctionComponent<MaintainTireSalesActionProps> = ({row}) => {  

  const [isModalTireSalesOpen, { toggle: toggleIsModalTireSalesOpen }] = useBoolean(false);  

  const disableAction = row?.billed != 'N';


  return (  
    <>
      <IconButton
        id='viewButton'
        iconProps={{ iconName: 'EditSolid12' }}
        onClick={toggleIsModalTireSalesOpen}
        disabled={disableAction}
      />

      <ViewMaintainTireSalesModalComponent
        id='invoiceModal'
        parentQuoteId={row?.quoteId}
        isOpen={isModalTireSalesOpen}
        title='Maintain Tire Sales Quote'
        onCancel={toggleIsModalTireSalesOpen}
        onSubmit={toggleIsModalTireSalesOpen}
        actionType='maintain'
      />
    </>
  );
};
 
export default MaintainTireSalesAction;