import { exportTestInstance, tireOrderingTestInstance } from '../axios';

const getAvailablePlants = (pagination: any, filters?: any, sortOrder?: any) =>
  tireOrderingTestInstance.post('/plant', { pagination, filters, sortOrder });

const getPlantsToAdd = (pagination: any, filters?: any, sortOrder?: any) =>
  tireOrderingTestInstance.post('/plant/listToAdd', { pagination, filters, sortOrder });

const addPlant = (plant: any) =>
  tireOrderingTestInstance.post('/plant/add', plant);

const updatePlant = (plant: any) =>
  tireOrderingTestInstance.put('/plant', plant);

const printExcel = (input: any) =>
  exportTestInstance.post('/plant/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (input: any) =>
  exportTestInstance.post('/plant/export-pdf', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  getAvailablePlants,
  getPlantsToAdd,
  addPlant,
  updatePlant,
  printExcel,
  printPdf,
};