import { DefaultButton, MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { default as api, default as apiService } from '../../../../../../api';
import { ICustomer } from '../../../../../../components/Common/Search/ICustomer';
import { ILocation } from '../../../../../../components/Common/Search/ILocation';
import useNotifications from '../../../../../../hooks/useNotifications';
import { IAvailableTires } from '../../../../../../models/IAvailableTires';
import { INotification } from '../../../../../../models/INotification';
import { ITemporaryTires } from '../../../../../../models/ITemporaryTires';
import { TemporaryTireStatusesEnum } from '../../../../../../models/TemporaryTireStatusesEnum';
import { TireStatusesEnum } from '../../../../../../models/TireStatusesEnum';
import { customerSelector, locationSelector } from '../../../../../../redux/recordKeepingSlice';
import DataGridComponent from '../../../../../../shared/DataGridComponent';
import DelNumComponent from '../../../../../../shared/DelNumComponent';
import DialogComponent from '../../../../../../shared/DialogComponent';
import AutoCompleteField from '../../../../../Common/Search/AutoCompleteField';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import TireSelection from './components/TireSelection/TireSelection';
import { formData, initialFormData, transferHeadlCell } from './consts';
import styles from './Transfer.module.scss';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_inTransitTires_transfer } from '../../../../../../consts/programKeys';
import { intransitPageSizes } from '../../consts';
import { ITransferComponentProps } from './ITransferComponentProps';
import { inTransitOrderCodeSelector } from '../../../../../../redux/tireOrderingSlice';

const TransferComponent: FunctionComponent<ITransferComponentProps> = () => {


  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_inTransitTires_transfer);

  const { addNotification } = useNotifications();
  const {
    prefix: brandPrefix,
    customerName: customerName,
    regionCode,
    id: customerId,
  } = useSelector(customerSelector);

  const [availableTiresList, setAvailableTiresList] = useState([]);
  const [temporaryTires, setTemporaryTires] = useState<Array<ITemporaryTires>>([]);
  const [totalFound, setTotalFound] = useState(0);
  const [totalTemporaryFound, setTotalTemporaryFound] = useState(0);
  const [availableTiresSelected, setAvailableTiresSelected] = useState<Array<IAvailableTires>>([]);
  const [toTransferTiresSelected, setToTransferTiresSelected] = useState<Array<IAvailableTires>>([]);
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });
  const [customersList, setCustomerList] = useState<ICustomer[]>([]);
  const [locationList, setLocationList] = useState<Array<ILocation>>([]);
  const [paginationPropsAvailable, setPaginationPropsAvailable] = useState<any>();
  const [paginationPropsUpdated, setPaginationPropsUpdated] = useState<any>();
  const [availableTiresSeriesSelected, setAvailableTiresSeriesSelected] = useState<Array<IAvailableTires>>([]);

  // toogles
  const [isLoadingAvailableTable, { toggle: toggleShowLoadingAvailableTable }] = useBoolean(false);
  const [isLoadingTemporaryTable, { toggle: toggleShowLoadingTemporaryTable }] = useBoolean(false);
  const [reloadControlsTable, { toggle: toggleReloadControlsTable }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [isTransferDialogVisible, { toggle: toggleTransferConfirmation }] = useBoolean(false);
  const [isLoadingFetchCustomers, { toggle: toggleIsLoadingFetchCustomers }] = useBoolean(false);
  const [isLoadingFetchLocations, { toggle: toggleIsLoadingFetchLocations }] = useBoolean(false);

  const [formData, setFormData] = useState<formData>(initialFormData);
  const { locationCode } = useSelector(locationSelector);
  const orderCode = useSelector(inTransitOrderCodeSelector);


  const getAvailableTires = async (
    pagination: any = { pageSize: intransitPageSizes[0], pageNumber: 1 },
    filters: any = {
      status: TireStatusesEnum.INTRANSIT,
    },
    sortOrder: any = { column: transferHeadlCell[0].fieldName, order: 'asc' },
    shouldAutoSelectRows = false,
  ) => {
    try {
      if (!shouldAutoSelectRows) {
        setAvailableTiresList([]);
        setAvailableTiresSelected([]);
        setAvailableTiresSeriesSelected([]);
        setTotalFound(0);
      }
      filters = { ...filters, customerId, orderId: orderCode.id };
      const { data }: any = await apiService.inTransitTiresAPI.getAvailableInTransitTires(pagination, filters, sortOrder);
      if (shouldAutoSelectRows) {
        setAvailableTiresSeriesSelected(data?.data);
      }
      else {
        setAvailableTiresList(data?.data);
        setTotalFound(data?.total?.found);
      }
    } catch (error) {
      setNotification({ text: 'Fetching tires error', type: MessageBarType.error });
    }
  };

  const getTemporaryTires = async (
    pagination: any = { pageSize: intransitPageSizes[0], pageNumber: 1 },
    filters: any = { status: TemporaryTireStatusesEnum.PENDING_TRANSFER, customerId: customerId },
    sortOrder: any = { column: transferHeadlCell[0].fieldName, order: 'asc' },
  ) => {
    try {
      const { data }: any = await apiService.inTransitTiresAPI.getTemporaryTires(pagination, filters, sortOrder);
      setTemporaryTires(data?.data);
      setTotalTemporaryFound(data?.total?.found);
    } catch (error) {
      setNotification({ text: 'Fetching temporary tires error', type: MessageBarType.error });
    }

  };

  const fetchCustomers = async (filter: any, pagination?: any, sort?: any) => {
    try {
      toggleIsLoadingFetchCustomers();
      const { data }: any = await api.getCustomerList(filter, pagination, sort);
      setCustomerList(data.data);
      toggleIsLoadingFetchCustomers();
    } catch (e: any) {
      setNotification({ text: 'Customers fetching error.', type: MessageBarType.error });
    }
  };

  const fetchLocations = async (customerId: any) => {
    try {
      toggleIsLoadingFetchLocations();
      const { data }: any = await apiService.getLocationsByCustomerId(
        customerId,
        { pageSize: 5000, pageNumber: 1 },
        {},
      );
      setLocationList(data.data);
      toggleIsLoadingFetchLocations();
    } catch (e: any) {
      setNotification({ text: 'Locations fetching error.', type: MessageBarType.error });
    }
  };

  const moveTiresToTemporary = async () => {
    try {
      const inTransitTireIds = getIdsFromTires(availableTiresSelected);
      const bodyRequest = {
        status: TemporaryTireStatusesEnum.PENDING_TRANSFER,
        ids: inTransitTireIds,
      };
      await apiService.inTransitTiresAPI.moveTiresToTemporary(bodyRequest);
      setNotification({ text: `${inTransitTireIds.length} tires moved successfully`, type: MessageBarType.success });
    } catch (error) {
      setNotification({ text: 'Moving tires error', type: MessageBarType.error });
    }
  };

  const moveTiresBackToAvailable = async () => {
    try {
      const inTransitTireIds = getIdsFromTires(toTransferTiresSelected);
      await apiService.inTransitTiresAPI.moveTiresBackToMainTable(inTransitTireIds);
      setNotification({ text: `${inTransitTireIds.length} tires moved back successfully`, type: MessageBarType.success });
    } catch (error) {
      setNotification({ text: 'Moving tires error', type: MessageBarType.error });
    }
  };

  const submitTransfer = async () => {
    const {
      newCustomer: { id: newCustomerId },
      newLocation: { id: newLocationId },
      tireSelectionData,
    } = formData;

    try {
      const inTransitTireIds = getIdsFromTires(toTransferTiresSelected);
      const bodyRequest = {
        newCustomerId,
        newLocationId,
        rebrand: {
          pfx: tireSelectionData?.rbPrefix,
          sfx: tireSelectionData?.rbSuffix,
        },
        ids: inTransitTireIds,
      };
      await apiService.inTransitTiresAPI.transferInTransitTires(bodyRequest);
      setNotification({ text: `${inTransitTireIds.length} tires transfered successfully`, type: MessageBarType.success });
    } catch (error) {
      setNotification({ text: 'Transfering tires error', type: MessageBarType.error });
    }
  };

  const handlePrint = async (printingType: any) => {
    try {

      const inTransitTiresGetInput = {
        pagination: {
          pageSize: paginationPropsAvailable?.countOnPage?.text,
          pageNumber: paginationPropsAvailable?.paginationProps?.current,
        },
        filters: {
          customerId: customerId,
          locationId: regionCode,
        },
      };

      const inTransitTiresAltGetInput = {
        pagination: {
          pageSize: paginationPropsUpdated?.countOnPage?.text,
          pageNumber: paginationPropsUpdated?.paginationProps?.current,
        },
        filters: {
          customerId: customerId,
          locationId: regionCode,
        },
      };

      const headerFields = [
        { title: 'customerId', value: customerId },
        { title: 'locationId', value: regionCode },
      ];

      const requestData = {
        inTransitTiresGetInput: inTransitTiresGetInput,
        inTransitTiresAltGetInput: inTransitTiresAltGetInput,
        headerFields: headerFields,
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.inTransitTiresAPI.printExcel(requestData) :
        await apiService.inTransitTiresAPI.printPdf(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
      toggleShowPrintExport();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    }
  };

  const handleSelectAvailableRow = (rowsSelected: Array<IAvailableTires>) => {
    setAvailableTiresSelected(rowsSelected);
  };
  const handleSelectToUpdateRow = (rowsSelected: Array<IAvailableTires>) => {
    setToTransferTiresSelected(rowsSelected);
  };

  const onTextChange = ({ target }: any) => {
    const { name, value } = target;
    setFormData({ ...formData, [name]: value });
  };

  const onTireSelectionChanged = (data: any) => {
    setFormData({ ...formData, tireSelectionData: data });
  };

  const transferTiresToAvailable = async () => {
    toggleShowLoadingAvailableTable();
    toggleShowLoadingTemporaryTable();
    toggleReloadControlsTable();
    await moveTiresBackToAvailable();
    await getAvailableTires();
    await getTemporaryTires();
    toggleShowLoadingAvailableTable();
    toggleShowLoadingTemporaryTable();
  };

  const transferTiresToTemporary = async () => {
    toggleShowLoadingAvailableTable();
    toggleShowLoadingTemporaryTable();
    toggleReloadControlsTable();
    await moveTiresToTemporary();
    await getAvailableTires();
    await getTemporaryTires();
    toggleShowLoadingAvailableTable();
    toggleShowLoadingTemporaryTable();
  };

  const getRangeObject = () => {
    const beginBrandNum = formData?.tireSelectionData?.beginBrandNum;
    const endBrandNum = formData?.tireSelectionData?.endBrandNum;
    return formData?.tireSelectionData?.prefix ?
      {
        beginBrand: {
          prefix: formData?.tireSelectionData?.prefix,
          brandNo: beginBrandNum ? +beginBrandNum : null,
          sufix: formData?.tireSelectionData?.sufix,
        },
        endBrand: {
          prefix: formData?.tireSelectionData?.prefix,
          brandNo: endBrandNum ? +endBrandNum : null,
          sufix: formData?.tireSelectionData?.sufix,
        },
      } : null;
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    toggleShowLoadingAvailableTable();
    const { countOnPage, paginationProps, sortOrder, searchedText } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString: searchedText,
      status: TireStatusesEnum.INTRANSIT,
      customerId,
    };
    setPaginationPropsAvailable(dataGridState);
    if (orderCode) await getAvailableTires(pagination, filters, sortOrder);
    toggleShowLoadingAvailableTable();
  };

  const handleChangeTemporaryDataGridState = async (dataGridState: any) => {
    toggleShowLoadingTemporaryTable();
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString: searchedText,
      status: TemporaryTireStatusesEnum.PENDING_TRANSFER,
      customerId,
    };
    setPaginationPropsUpdated(dataGridState);
    if (orderCode) await getTemporaryTires(pagination, filters, sortOrder);
    toggleShowLoadingTemporaryTable();
  };

  const handleChangeSeries = async () => {
    toggleShowLoadingAvailableTable();
    const inTransitTiresSelectRange = getRangeObject();
    await getAvailableTires(null, {
      inTransitTiresSelectRange,
      status: TireStatusesEnum.INTRANSIT,
    }, null, true);
    toggleShowLoadingAvailableTable();
  };

  const handlesubmitTransfer = async () => {
    toggleTransferConfirmation();
    toggleShowLoadingAvailableTable();
    toggleShowLoadingTemporaryTable();
    await submitTransfer();
    await getAvailableTires();
    await getTemporaryTires();
    toggleReloadControlsTable();
    toggleShowLoadingAvailableTable();
    toggleShowLoadingTemporaryTable();
  };

  const removeEmpty = ({ text }: any) => text !== null;

  const getCustomerPrefixList = React.useMemo(() => customersList ?
    customersList.map(({ id, prefix }) => ({
      key: id,
      text: prefix,
    })).filter(removeEmpty) : [], [customersList]);


  const getCustomerNameList = React.useMemo(() => customersList ?
    customersList.map(({ id, customerName }) => ({
      key: id,
      text: customerName,
    })).filter(removeEmpty) : [], [customersList]);

  const getLocationCodeList = React.useMemo(() => locationList ?
    locationList.map(({ id, locationName }) => ({
      key: id,
      text: locationName,
    })).filter(removeEmpty) : [], [locationList]);

  const customerPrefixInputText = (custPrefix: string) => {
    if (custPrefix.replace(/ /g, '').length) {
      getCustomersDebounce({ custPrefix });
    }
  };

  const customerNameInputText = (custName: string) => {
    if (custName.replace(/ /g, '').length) {
      getCustomersDebounce({ custName });
    }
  };

  const getCustomersDebounce = debounce(async (customerData) => {
    fetchCustomers(customerData);
  }, 500);

  const onCustomerChange = async (newCustomer: any) => {
    setFormData({
      ...formData,
      newCustomer: getCustomerById(newCustomer),
      newLocation: null,
    });
    await fetchLocations(newCustomer);
  };

  const getTires = () => {
    const pagination = {
      pageSize: intransitPageSizes[0],
      pageNumber: 1,
    };
    const availableTirefilters = {
      status: TireStatusesEnum.INTRANSIT,
      customerId,
    };
    const temporaryTiresfilters = {
      status: TemporaryTireStatusesEnum.PENDING_TRANSFER,
      customerId,
    };
    if (orderCode) {
      getAvailableTires(pagination, availableTirefilters, { column: transferHeadlCell[0].fieldName, order: 'asc' });
      getTemporaryTires(pagination, temporaryTiresfilters, { column: transferHeadlCell[0].fieldName, order: 'asc' });
    }
  };

  useEffect(() => {
    if (orderCode) {
      getTires();
    }
  }, [orderCode]);

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  const onLocationChange = (newLocation: any) => setFormData({ ...formData, newLocation: getLocationById(newLocation) });
  const getIdsFromTires = (tiresArray: any) => tiresArray?.map((tire: any) => tire.inTransitTireId || tire.inTransitTireAltId);
  const getCustomerById = (idToFind: number) => customersList?.find(({ id }) => id === idToFind) || null;
  const getLocationById = (idToFind: number) => locationList?.find(({ id }) => id === idToFind) || null;
  const disableTransferBtn = !formData.newCustomer || !formData.newLocation || !toTransferTiresSelected.length;

  return (
    <>
      <div className="ms-Grid">
        <div className="ms-Grid-row">
          {/* Right Column - Upper Row */}
          <div className={classNames('ms-Grid-col', 'ms-sm6', styles.marginTop20)}>
            <Text variant="xLarge" className={styles.highlight}>Order Details</Text>
            <SeparatorGy />

            <div className="ms-Grid">
              <DelNumComponent orderCode={orderCode} />
              {/* Tire Selection */}
              <div className={classNames('ms-Grid-row', styles.marginTop20)}>
                <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                  <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                    <Text variant="xLarge" className={styles.highlight}>Tire Selection</Text>
                    <SeparatorGy />
                  </div>
                  <div className={classNames('ms-Grid-col')}>
                    <TireSelection onTireSelectionChanged={onTireSelectionChanged} handleChangeSeries={handleChangeSeries} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Left Column - Upper Row */}
          <div className={classNames('ms-Grid-col', 'ms-sm6', styles.marginTop20)}>
            <div className="ms-Grid-row">
              <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                <Text variant="xLarge" className={styles.highlight}>Transfer From</Text>
                <SeparatorGy />
              </div>
              <div className={classNames('ms-Grid-col')}>
                <TextField value={brandPrefix} name="transFromBrandNum" onChange={onTextChange} label="Brand Prefix" disabled />
              </div>
              <div className={classNames('ms-Grid-col')}>
                <TextField value={locationCode} name="transFromLocationCode" onChange={onTextChange} label="Location code" disabled />
              </div>
              <div className={classNames('ms-Grid-col')}>
                <TextField value={customerName} name="transFromName" onChange={onTextChange} label="Name" disabled />
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
                <Text variant="xLarge" className={styles.highlight}>Transfer To</Text>
                <SeparatorGy />
              </div>
              <div className={classNames('ms-Grid-col')}>
                <AutoCompleteField
                  onChangeAction={onCustomerChange}
                  value={formData?.newCustomer?.id}
                  list={getCustomerPrefixList}
                  label="Customer Prefix"
                  data-testid='customer-prefix'
                  icon="FavoriteStar"
                  textValue={customerPrefixInputText}
                  isLoading={isLoadingFetchCustomers}
                />
              </div>
              <div className={classNames('ms-Grid-col')}>
                <AutoCompleteField
                  onChangeAction={onCustomerChange}
                  value={formData?.newCustomer?.id}
                  list={getCustomerNameList}
                  label="Customer Name"
                  data-testid='customer-name'
                  icon="Contact"
                  textValue={customerNameInputText}
                  isLoading={isLoadingFetchCustomers}
                />
              </div>
              <div className={classNames('ms-Grid-col')}>
                <AutoCompleteField
                  onChangeAction={onLocationChange}
                  value={formData?.newLocation?.id}
                  list={getLocationCodeList}
                  label="Location Code"
                  data-testid='customer-loc'
                  icon="POISolid"
                  isLoading={isLoadingFetchLocations}
                />
              </div>
            </div>
          </div>
          {/* Bottom Column */}
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <div className={classNames('ms-Grid-row', styles.rowTables)}>
              <div className={classNames('ms-Grid-col', 'ms-sm12', 'ms-md12', 'ms-lg6')}>
                <div className="ms-Grid">
                  <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                    <PrimaryButton
                      id='transferToTemporaryBtn'
                      disabled={availableTiresSelected.length == 0 || !userPermissions.isWrite}
                      onClick={transferTiresToTemporary} text="Transfer Selected" />
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
                    <DataGridComponent
                      idTable={'available-intransit-table'}
                      title="Available In-Transit Tires"
                      headCells={transferHeadlCell}
                      rowsTable={availableTiresList}
                      defaultRowsPerPage={intransitPageSizes}
                      preselectedRows={availableTiresSeriesSelected}
                      totalDataFound={totalFound}
                      isLoading={isLoadingAvailableTable}
                      enableCheckBox={userPermissions.isWrite}
                      enableSearching={true}
                      enableMultiSelectRow={userPermissions.isWrite}
                      enablePagination={true}
                      enableRowsPerPage={true}
                      reloadControlsTable={reloadControlsTable}
                      handleSelectRow={handleSelectAvailableRow}
                      handleChangeDataGridState={handleChangeDataGridState}
                    />
                  </div>
                </div>
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm12', 'ms-md12', 'ms-lg6')}>
                <div className="ms-Grid">
                  <div className="ms-Grid-row">
                    <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                      <PrimaryButton
                        id='transferToAvailableBtn'
                        disabled={toTransferTiresSelected.length == 0 || !userPermissions.isWrite}
                        onClick={transferTiresToAvailable}
                        text="Move selected back to available"
                      />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
                      <DataGridComponent
                        idTable={'transfered-intransit-table'}
                        title="Transfered In-Transit Tires"
                        headCells={transferHeadlCell}
                        rowsTable={temporaryTires}
                        defaultRowsPerPage={intransitPageSizes}
                        totalDataFound={totalTemporaryFound}
                        isLoading={isLoadingTemporaryTable}
                        enableCheckBox={userPermissions.isWrite}
                        enableSearching={true}
                        enableMultiSelectRow={userPermissions.isWrite}
                        enablePagination={true}
                        enableRowsPerPage={true}
                        reloadControlsTable={reloadControlsTable}
                        handleSelectRow={handleSelectToUpdateRow}
                        handleChangeDataGridState={handleChangeTemporaryDataGridState}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.submitCol)}>
            <DefaultButton onClick={toggleShowPrintExport} text="Print/Export" />
            <PrimaryButton
              id='submitTransferBtn'
              disabled={disableTransferBtn || !userPermissions.isWrite}
              onClick={toggleTransferConfirmation}
              text="Transfer" />
          </div>
        </div>
      </div>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      <DialogComponent
        isOpen={isTransferDialogVisible}
        onCancel={toggleTransferConfirmation}
        onSubmit={handlesubmitTransfer}
        title={'Confirmation'}
        subText={`Are you sure you want to transfer ${toTransferTiresSelected.length} items ?`}
        onSubmitLabel={'Transfer'}
        onCancelLabel={'Cancel'}
      />
    </>
  );
};

export default TransferComponent;
