import { FunctionComponent, useEffect, useState } from 'react';
import { CostDetailsProps } from './CostDetailsProps';
import apiService from '../../../../../api';
import useNotifications from '../../../../../hooks/useNotifications';
import { MessageBarType, TextField, Text, Icon, Dropdown, DefaultButton } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import styles from './CostDetails.module.scss';
import { CostDetailsState } from './CostDetailsState';
import LoadingScreen from '../../../../LoadingScreen/LoadingScreen';
import Tooltip from '../../../../../shared/Tooltip';
import { selectedQuoteSelector, setSelectedQuote } from '../../../../../redux/quoteSlice';
import { useDispatch, useSelector } from 'react-redux';
import { quoteStatuses } from '../../../const';
import { useUserPermissions } from '../../../../../hooks/useUserPermissions';
import { auth_quote_costDetails} from '../../../../../consts/programKeys';
import { fields } from './consts';

const CostDetails: FunctionComponent<CostDetailsProps> = () => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_quote_costDetails);
  const dispatch = useDispatch();
  
  const [state, setState] = useState<CostDetailsState>({
    mceList: [],
    scrap: {},
    totalMonthlyVehicleUnits: 0,
    totalTiresEqualized: 0,
    totalNumberVehicleUnits: 0,
    loading: false,
  });

  const [costDetailsData, setCostDetailsData] = useState<any>({
    id: null,
    eqpCost: null,
    eqpPeriod: null,
    eqpRate: null,
    asIncentiveCost: null,
    asIncentivePeriod: null,
    asIncentiveRate: null,
    rmIncentiveCost: null,
    rmIncentivePeriod: null,
    rmIncentiveRate: null,
    otherCost: null,
    otherPeriod: null,
    otherRate: null,
    other2Cost: null,
    other2Period: null,
    other2Rate: null,
    scrapCost: null,
    scrapRate: null,
    mceRate: null,
  });

  const { addNotification } = useNotifications();
  const { id } = useParams<{ id: string }>();
  const { contractId } = useParams<{ contractId: string }>();

  const selectedQuote = useSelector(selectedQuoteSelector);

  const fetchCostDetails = async () => {
    setState(prev => ({ ...prev, loading: true}));
    try {
      const { data }: any = await apiService.quotesAPI.getCostDetails(selectedQuote.id, selectedQuote.version, id);
      setCostDetailsData(() => ({ ...data.data, id: selectedQuote.id }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Cost Details fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false}));
    }
  };

  const fetchCostParams = async () => {
    setState(prev => ({ ...prev, loading: true}));
    try {
      const { data }: any = await apiService.quotesAPI.getCostParams(id);
      setState(prev => ({ 
        ...prev, 
        mceList: data.data.mce.sort(),
        scrap: data.data.scrap,
      }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Cost Details fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false}));
    }
  };

  const fetchMakeModels = async () => {
    setState(prev => ({ ...prev, loading: true}));
    try {
      const { data }: any = await apiService.quotesAPI.getMakeModels(
        { pageSize: 100, pageNumber: 1 },
        [],
        '',
        id, 
        contractId, 
        selectedQuote.id, 
        selectedQuote.version,
      );
      let totalNumberVehicleUnits = 0;
      for (const makeModel of data.data) {
        totalNumberVehicleUnits = +makeModel.numberOfVehicleUnits;
      }
      setState(prev => ({ 
        ...prev, 
        totalMonthlyVehicleUnits: data.totalMonthlyVehicleUnits,
        totalTiresEqualized: data.totalTiresEqualized,
        totalNumberVehicleUnits: totalNumberVehicleUnits,
      }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Cost Details fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false}));
    }
  };

  const handleSave = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      await apiService.quotesAPI.updateQuote({
        ...costDetailsData,
        quoteId: selectedQuote?.id,
        quoteVersion: selectedQuote?.version,
      });
      if(selectedQuote?.status === quoteStatuses.GENERATED)
        dispatch(setSelectedQuote({ ...selectedQuote, status: quoteStatuses.REGENERATED }));
      addNotification({
        text: 'Quote saved.',
        type: MessageBarType.success,
      });
      fetchCostDetails();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Quote save error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const onChangeField = (field: any, value: any, regExp?: RegExp) => {
    setCostDetailsData((prev: any) => ({ ...prev, [field]: regExp ? (regExp.test(value) ? value : prev.data[field]) : value }));
  };

  const onChangeScrapField = (value: any) => {
    setCostDetailsData((prev: any) => ({ ...prev, scrapCost: value, scrapPeriod: state.scrap.cost === value ? state.scrap.period : '' }));
  };

  const calculateRates = () => {
    for (const field of fields) {
      if (costDetailsData[field + 'Cost'] && costDetailsData[field + 'Period']) {
        if (field === 'scrap') {
          const rate = (costDetailsData[field + 'Cost'] / costDetailsData[field + 'Period']) * state.totalTiresEqualized / state.totalNumberVehicleUnits;
          setCostDetailsData((prev: any) => ({ ...prev, [field + 'Rate']: rate}));
        } else {
          const rate = (costDetailsData[field + 'Cost'] / costDetailsData[field + 'Period']) / state.totalMonthlyVehicleUnits;
          setCostDetailsData((prev: any) => ({ ...prev, [field + 'Rate']: rate}));
        }
      } else {
        setCostDetailsData((prev: any) => ({ ...prev, [field + 'Rate']: ''}));
      }
    }
  };

  useEffect(() => {
    console.log('aaa');
    calculateRates();
  }, [
    costDetailsData.eqpCost, costDetailsData.eqpPeriod,
    costDetailsData.asIncentiveCost, costDetailsData.asIncentivePeriod,
    costDetailsData.rmIncentiveCost, costDetailsData.rmIncentivePeriod,
    costDetailsData.otherCost, costDetailsData.otherPeriod,
    costDetailsData.other2Cost, costDetailsData.other2Period,
    costDetailsData.scrapCost,
  ]);

  useEffect(() => {
    async function fetchAPI() {
      await fetchCostParams();
      await fetchMakeModels();
      await fetchCostDetails();
    }
    fetchAPI();
  }, []);

  return (
    <>
      <div className="ms-Grid margin-left-rigth-2 marginTop18" dir='ltr'>
        <div className={classNames('ms-Grid-row')}>
          <div className="ms-Grid-col ms-sm12">
            <Text variant='xLarge' className={styles.highlight}>Cost Details - Vehicle Cost</Text>
            <SeparatorGy />
          </div>
        </div>
        <div className={classNames('"ms-Grid-row', styles.details)}>
          <div className='ms-Grid-col ms-sm10'>
            <div className="ms-Grid-row">
              <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                <Text variant='large' className={styles.highlight}>Equipment</Text>
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <TextField
                  id="eqpCost"
                  value={costDetailsData.eqpCost}
                  onChange={(e, eqpCost) => onChangeField('eqpCost', eqpCost)}
                  prefix="$"
                  disabled={selectedQuote?.status === quoteStatuses.AUTHORIZED}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <TextField
                  value={costDetailsData.eqpPeriod}
                  onChange={(e, eqpPeriod) => onChangeField('eqpPeriod', eqpPeriod)}
                  onRenderPrefix={() => <Icon iconName="Calendar" />}
                  disabled={selectedQuote?.status === quoteStatuses.AUTHORIZED}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <TextField
                  value={costDetailsData.eqpRate}
                  onRenderPrefix={() => <Icon iconName="Calculator" />}
                  disabled
                />
              </div> 
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <Tooltip content={'Equipment Rate = (Equipment Cost / Month) / Total Monthly Vehicle Units'} />
              </div>                   
            </div>
          </div>
          <div className='ms-Grid-col ms-sm10'>
            <div className="ms-Grid-row">
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <Text variant='large' className={styles.highlight}>AS Cost</Text>
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <TextField
                  value={costDetailsData.asIncentiveCost}
                  onChange={(e, asIncentiveCost) => onChangeField('asIncentiveCost', asIncentiveCost)}
                  prefix="$"
                  disabled={selectedQuote?.status === quoteStatuses.AUTHORIZED}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <TextField
                  value={costDetailsData.asIncentivePeriod}
                  onChange={(e, asIncentivePeriod) => onChangeField('asIncentivePeriod', asIncentivePeriod)}
                  onRenderPrefix={() => <Icon iconName="Calendar" />}
                  disabled={selectedQuote?.status === quoteStatuses.AUTHORIZED}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <TextField
                  value={costDetailsData.asIncentiveRate}
                  onRenderPrefix={() => <Icon iconName="Calculator" />}
                  disabled
                />
              </div>  
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <Tooltip content={'AS Rate = (AS Incentive Cost / Month) / Total Monthly Vehicle Units'} />
              </div>                    
            </div>
          </div>
          <div className='ms-Grid-col ms-sm10'>
            <div className="ms-Grid-row">
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <Text variant='large' className={styles.highlight}>RM Incentive</Text>
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <TextField
                  value={costDetailsData.rmIncentiveCost}
                  onChange={(e, rmIncentiveCost) => onChangeField('rmIncentiveCost', rmIncentiveCost)}
                  prefix="$"
                  disabled={selectedQuote?.status === quoteStatuses.AUTHORIZED}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <TextField
                  value={costDetailsData.rmIncentivePeriod}
                  onChange={(e, rmIncentivePeriod) => onChangeField('rmIncentivePeriod', rmIncentivePeriod)}
                  onRenderPrefix={() => <Icon iconName="Calendar" />}
                  disabled={selectedQuote?.status === quoteStatuses.AUTHORIZED}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <TextField
                  value={costDetailsData.rmIncentiveRate}
                  onRenderPrefix={() => <Icon iconName="Calculator" />}
                  disabled
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <Tooltip content={'RM Rate = (RM Incentive Cost / Month) / Total Monthly Vehicle Units'} />
              </div>                  
            </div>
          </div>
          <div className='ms-Grid-col ms-sm10'>
            <div className="ms-Grid-row">
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <Text variant='large' className={styles.highlight}>Other</Text>
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <TextField
                  value={costDetailsData.otherCost}
                  onChange={(e, otherCost) => onChangeField('otherCost', otherCost)}
                  prefix="$"
                  disabled={selectedQuote?.status === quoteStatuses.AUTHORIZED}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <TextField
                  value={costDetailsData.otherPeriod}
                  onChange={(e, otherPeriod) => onChangeField('otherPeriod', otherPeriod)}
                  onRenderPrefix={() => <Icon iconName="Calendar" />}
                  disabled={selectedQuote?.status === quoteStatuses.AUTHORIZED}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <TextField
                  value={costDetailsData.otherRate}
                  onRenderPrefix={() => <Icon iconName="Calculator" />}
                  disabled
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <Tooltip content={'Other Rate = (Other Cost / Month) / Total Monthly Vehicle Units'} />
              </div>                      
            </div>
          </div>
          <div className='ms-Grid-col ms-sm10'>
            <div className="ms-Grid-row">
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <Text variant='large' className={styles.highlight}>Other 2</Text>
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <TextField
                  value={costDetailsData.other2Cost}
                  onChange={(e, other2Cost) => onChangeField('other2Cost', other2Cost)}
                  prefix="$"
                  disabled={selectedQuote?.status === quoteStatuses.AUTHORIZED}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <TextField
                  value={costDetailsData.other2Period}
                  onChange={(e, other2Period) => onChangeField('other2Period', other2Period)}
                  onRenderPrefix={() => <Icon iconName="Calendar" />}
                  disabled={selectedQuote?.status === quoteStatuses.AUTHORIZED}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <TextField
                  value={costDetailsData.other2Rate}
                  onRenderPrefix={() => <Icon iconName="Calculator" />}
                  disabled
                />
              </div>    
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <Tooltip content={'Other 2 Rate = (Other 2 Cost / Month) / Total Monthly Vehicle Units'} />
              </div>                  
            </div>
          </div>
          <div className='ms-Grid-col ms-sm10'>
            <div className="ms-Grid-row">
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <Text variant='large' className={styles.highlight}>Scrap</Text>
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <Dropdown
                  options={[
                    {
                      key: '', 
                      text: '',
                    },
                    {
                      key: state.scrap.cost,
                      text: state.scrap.cost,
                    },
                  ]}
                  selectedKey={costDetailsData.scrapCost}
                  onChange={(e, option) => onChangeScrapField(option?.key)}
                  placeholder='$'
                  disabled={selectedQuote?.status === quoteStatuses.AUTHORIZED}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <TextField
                  value={costDetailsData.scrapRate}
                  onRenderPrefix={() => <Icon iconName="Calculator" />}
                  disabled
                />
              </div>  
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <Tooltip content={'Scrap Cost for Master Data Tab: (Units entered / Month) * tires equalized / Total # of monthly vehicle miles'} />
              </div>                    
            </div>
          </div>
          <div className='ms-Grid-col ms-sm10'>
            <div className="ms-Grid-row">
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <Text variant='large' className={styles.highlight}>MCE Rate</Text>
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                <Dropdown
                  options={state.mceList.map((item: any) => ({
                    key: item,
                    text: item,
                  }))}
                  selectedKey={costDetailsData.mceRate}
                  onChange={(e, option) => onChangeField('mceRate', option?.key)}
                  placeholder='$'
                  disabled={selectedQuote?.status === quoteStatuses.AUTHORIZED}
                />
              </div>                  
            </div>
          </div>
        </div>
      </div>
      <div className={classNames(styles.buttonsWrapper)}>
        {selectedQuote?.status !== quoteStatuses.AUTHORIZED && <DefaultButton id="saveBtn" text="Save Quote" onClick={handleSave} disabled={!userPermissions.isWrite}/>}
      </div>
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default CostDetails;