import { fieldType } from '../../../../../../consts/fieldType';

export const columns = [
  {
    key: 'column1',
    name: 'Location Code',
    fieldName: 'locationCode',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Annual',
    fieldName: 'annual',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'Inventory Taken On',
    fieldName: 'invtTakenOn',
    isSorted: true,
    isSortedDescending: true,
  },
  {
    key: 'column4',
    name: 'Inventory Taken By',
    fieldName: 'invtTakenBy',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'System Inventory',
    fieldName: 'sysInv',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column6',
    name: 'Matched',
    fieldName: 'matched',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column7',
    name: 'Errors',
    fieldName: 'errors',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column8',
    name: 'Reconciled',
    fieldName: 'reconciled',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column9',
    name: 'Lost',
    fieldName: 'lost',
    isSorted: false,
    isSortedDescending: false,
  },
];

export const locationsColumns = [
  {
    key: 'column1',
    name: 'Ship to Number',
    fieldName: 'shipToNumber',
  },
  {
    key: 'column2',
    name: 'Location Code',
    fieldName: 'locationCode',
  },
  {
    key: 'column3',
    name: 'Address Line 1',
    fieldName: 'addrLine1',
  },
  {
    key: 'column5',
    name: 'City',
    fieldName: 'city',
  },
];

export const defaultLocation = {
  locationCode: 'all',
};