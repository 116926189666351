import moment from 'moment';
import { IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';

export const CUSTOMER = 'customer';
export const LOCATION = 'location';
export const BRAND = 'brand';
export const VEHICLE = 'vehicle';

export const emptyTransferTires = {
  relocateTires: false,
  newCustomerId: '',
  newLocationId: '',
  docDate: moment().format('MM/DD/YYYY'),
  docNum: '',
  fromBrand: {
    pfx: '',
    bno: '',
    sfx: null,
  },
  toBrand: {
    pfx: '',
    bno: '',
    sfx: null,
  },
  vehicleNumber: '',
};

export const transferTo: IChoiceGroupOption[] = [
  { key: CUSTOMER, text: 'Other Customer' },
  { key: LOCATION, text: 'Other Location' },
];

export const selectBy: IChoiceGroupOption[] = [
  { key: BRAND, text: 'Brand Number' },
  { key: VEHICLE, text: 'Vehicle Number' },
];

export const transferTiresColumns = [
  {
    key: 'column1',
    name: 'Prefix',
    fieldName: 'prefix',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Brand Number.',
    fieldName: 'brandNumber',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'Suffix',
    fieldName: 'suffix',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'Change Date',
    fieldName: 'changeDate',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'COT',
    fieldName: 'cot',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column6',
    name: 'Veh',
    fieldName: 'veh',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column7',
    name: 'Position',
    fieldName: 'position',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column8',
    name: 'AccMiles',
    fieldName: 'accMiles',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column9',
    name: 'Last Mileage Run',
    fieldName: 'lastMileageRun',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column10',
    name: 'Status',
    fieldName: 'status',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column11',
    name: 'incDate',
    fieldName: 'incDate',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column12',
    name: 'incDoc',
    fieldName: 'incDoc',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column13',
    name: 'At Location',
    fieldName: 'atLocation',
    isSorted: false,
    isSortedDescending: false,
  },
];

export const locationsColumns = [
  {
    key: 'column14',
    name: 'Ship To Number',
    fieldName: 'shipToNumber',
  },
  {
    key: 'column15',
    name: 'Location Code',
    fieldName: 'locationCode',
  },
  {
    key: 'column16',
    name: 'Address Line',
    fieldName: 'addrLine1',
  },
  {
    key: 'column17',
    name: 'City',
    fieldName: 'city',
  },
  {
    key: 'column18',
    name: 'State',
    fieldName: 'state',
  },
  {
    key: 'column19',
    name: 'Country',
    fieldName: 'country',
  },
  {
    key: 'column20',
    name: 'Available Status',
    fieldName: 'available',
  },
];
