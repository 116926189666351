import { Spinner, SpinnerSize, Text } from '@fluentui/react';
import { FunctionComponent } from 'react';
import styles from './CostReport.module.scss';

interface CostSummaryReportProps {
   isLoading: boolean;
   costReportDetails: [];
};


const CostSummaryReport: FunctionComponent<CostSummaryReportProps> = ({isLoading, costReportDetails}) => {
  return (
    <>
      <Text variant='xLarge' className={styles.highlight}>Cost Report Summary</Text>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {isLoading &&
            <tr key={'loading'}>
              <td colSpan={2}>
                <span >
                  <Spinner size={SpinnerSize.large} />
                </span>
              </td>
            </tr>
          }
          {!isLoading && (
            <>
              {costReportDetails?.map((item: any) => (
                <tr>
                  <td>{item.label}</td>
                  <td>{item.value}</td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </>
  );
};

export default CostSummaryReport;