import { tireOrderingTestInstance, exportTestInstance } from '../axios';

const get = (pagination: any, filters: any, sortOrder: any, customerId: any, locationId: any) =>
  tireOrderingTestInstance.post('/order', { pagination, filters, sortOrder, customerId, locationId });

const getOrderById = (id: any) =>
  tireOrderingTestInstance.get(`/order/${id}`);

const getRetreadOrder = (id: any) =>
  tireOrderingTestInstance.post('/order/retread', { id });

const getBillToSundry = () =>
  tireOrderingTestInstance.post('/order/retread/sundry');

const getErrors = (pagination: any, sortOrder: any, filters: any) =>
  tireOrderingTestInstance.post('/order/error', { pagination, sortOrder, filters });

const getOrderText = (orderId: any) =>
  tireOrderingTestInstance.post('/order/text', { orderId });

const getBatchNumberList = () =>
  tireOrderingTestInstance.post('/order/batch-numbers');

const getSequence = (orderId: any) =>
  tireOrderingTestInstance.post('/order/text/next-seq', { orderId });

const saveOriginalOrder = (order: any, items: any, allowNotNormalStatus: boolean) =>
  tireOrderingTestInstance.post('/order/save-full', { order, items, allowNotNormalStatus });

const saveRetreadOrder = (order: any, orderItem: any, allowNotNormalStatus: boolean) =>
  tireOrderingTestInstance.post('/order/retread/save', { order, orderItem, allowNotNormalStatus });

const saveShipInstructions = (orderId: any, data: any) =>
  tireOrderingTestInstance.post('/order/text/save', { orderId, data });

const copyShipInstructions = (orderId: any, fromOrderId: any) =>
  tireOrderingTestInstance.post('/order/text/copy', { orderId, fromOrderId });

const cancelOrder = (orderId: any, reason: any, reasonString?: any) =>
  tireOrderingTestInstance.post('/order/cancel', { ids: [orderId], reason, reasonString });

const removeOrder = (orderId: any) =>
  tireOrderingTestInstance.delete('/order', { data: { ids: [orderId] } });

const getGBSErrorList = (payload: any) =>
  tireOrderingTestInstance.post('/gbs-errors/search', payload);

const getGBSOrder = (payload: any) =>
  tireOrderingTestInstance.post('/gbs-errors/order/search', payload);

const addGBSError = (payload: any) =>
  tireOrderingTestInstance.post('/gbs-errors/add', payload);

const updateGBSError = (payload: any) =>
  tireOrderingTestInstance.put('/gbs-errors/update', payload);

const deleteGBSError = (payload: any) =>
  tireOrderingTestInstance.post('/gbs-errors/delete', payload);

const printExcelTireOrders = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/tire-order/export-excel', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfTireOrders = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/tire-order/export-pdf', { data, headerFields }, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const printExcelOriginal = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/order/item/export-excel', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfOriginal = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/order/item/export-pdf', { data, headerFields }, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const printExcelRetread = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/order/retread/export-excel', { data, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfRetread = (data: any, headerFields: Array<any>) =>
  exportTestInstance.post('/order/retread/export-pdf', { data, headerFields }, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const printExcelGBS = (input: any) =>
  exportTestInstance.post('/gbs-errors/export-excel', { ...input }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfGBS = (input: any) =>
  exportTestInstance.post('/gbs-errors/export-pdf', { ...input }, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const downloadBrandingSheet = (input: any) => 
  tireOrderingTestInstance.post('/branding-sheet/download', input, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const getKeyCharacteristicsServiceProviders = () =>
  tireOrderingTestInstance.get('sections/key-characteristics-service-providers');

const getKeyCharacteristicsLevelsOfService = () =>
  tireOrderingTestInstance.get('sections/key-characteristics-levels-of-service');

const getContractInformation = (customerId: string) =>
  tireOrderingTestInstance.get(`/order/${customerId}/details`);

const getLookupValueParts = (pagination: any, sortOrder: any, filters: string) =>
  tireOrderingTestInstance.post('/parts', { pagination, sortOrder, filters });

const updateLookupValueParts = (payload: any) =>
  tireOrderingTestInstance.put('/parts', payload);

const addLookupValueParts = (payload: any) =>
  tireOrderingTestInstance.post('/parts/add', payload);

const deleteLookupValueParts = (ids: any[]) =>
  tireOrderingTestInstance.delete('/parts', { data: { ids } });
  
export default {
  get,
  getOrderById,
  getRetreadOrder,
  cancelOrder,
  removeOrder,
  getBillToSundry,
  getErrors,
  copyShipInstructions,
  saveOriginalOrder,
  saveShipInstructions,
  saveRetreadOrder,
  getOrderText,
  getBatchNumberList,
  getSequence,
  getGBSErrorList,
  getGBSOrder,
  addGBSError,
  updateGBSError,
  deleteGBSError,
  printExcelGBS,
  printPdfGBS,
  printExcelTireOrders,
  printPdfTireOrders,
  printExcelOriginal,
  printPdfOriginal,
  printExcelRetread,
  printPdfRetread,
  downloadBrandingSheet,
  getKeyCharacteristicsServiceProviders,
  getKeyCharacteristicsLevelsOfService,
  getContractInformation,
  getLookupValueParts,
  updateLookupValueParts,
  addLookupValueParts,
  deleteLookupValueParts,
};
