import { HeadCell } from '../../../../../../../shared/DataGridComponent/DataGridModels';


export const seriesHeadCell: HeadCell[] = [
  {
    key: 'column0',
    typeField: 'text',
    name: 'Prefix',
    fieldName: 'intireBpfx',
  },
  {
    key: 'column1',
    typeField: 'number',
    name: 'Brand number',
    fieldName: 'intireBno',
  },
  {
    key: 'column2',
    typeField: 'text',
    name: 'Suffix',
    fieldName: 'intireBsfx',
  },
];

export const FROM_BRAND_NUMBER_FIELDS = {
  fromPrefixDataField : {
    id: 'fromPrefix',
    label: 'Prefix',
    name: 'fromPrefix',
    type: 'text',
    required: true,
  },
  fromBrandNumberDataField : {
    id: 'fromBrandNumber',
    label: 'Brand Number',
    name: 'fromBrandNumber',
    type: 'number',
    required: true,
  },
  fromSufixDataField : {
    id: 'fromSufix',
    label: 'Suffix',
    name: 'fromSuffix',
    type: 'text',
  },
};

export const TO_BRAND_NUMBER_FIELDS = {
  toPrefixDataField : {
    id: 'toPrefix',
    label: 'Prefix',
    name: 'toPrefix',
    type: 'text',
    disabled: true,
  },
  toBrandNumberDataField : {
    id: 'toBrandNumber',
    label: 'Brand Number',
    name: 'toBrandNumber',
    type: 'number',
  },
  toSufixDataField : {
    id: 'toSufix',
    label: 'Suffix',
    name: 'toSuffix',
    type: 'text',
    disabled: true,
  },
};