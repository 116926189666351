import {
  IconButton,
  IColumn,
  IDropdownOption,
  MessageBarType,
  DefaultButton,
  Text,
  Dropdown,
  IIconProps,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { FC, FormEvent, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IViewListingTabProps } from './IViewListingTabProps';
import classNames from 'classnames';
import FilterModalWindow from './FilterModalWindow/FilterModalWindow';
import apiService from '../../../../../../api';
import { IViewListingTabState } from './IViewListingTabState';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../../Pagination/Pagination';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';
import { sortOrder } from '../../../../../../consts/sortOrder';
import { customerSelector, locationSelector, setTireForReinstate } from '../../../../../../redux/recordKeepingSlice';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import moment from 'moment';
import useNotifications from '../../../../../../hooks/useNotifications';
import { vehicleTiresColumns, stockTiresColumns, pageSizesVehicles, pageSizesStock, showHideColumns } from './consts';
import styles from './ViewListingTab.module.scss';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import ViewTireDetailsModal from '../../../../ViewTireDetailsModal/ViewTireDetailsModal';
import { initialFilterState } from './FilterModalWindow/consts';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import { transformDate } from '../../../../../../shared/transformDate';

const filterIcon : IIconProps = {iconName: 'Equalizer'};

const ViewListingTab: FC<IViewListingTabProps> = (): ReactElement => {

  const onVehicleColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...vehicleColumnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setVehicleColumnsState(newColumns);
  };

  const onStockColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...stockColumnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setStockColumnsState(newColumns);
  };

  const hideShowColumns = (column: any): void => {
    column.isVisible = !column.isVisible;
    const newVehicleColumns: any[] = [...vehicleColumnsState].map((vehicleColumn: any) => {
      if (vehicleColumn.name == column.name) {
        vehicleColumn.isVisible = column.isVisible;
      }
      return vehicleColumn;
    });
    setVehicleColumnsState(newVehicleColumns);
    const newStockColumns: any[] = [...stockColumnsState].map((stockColumn: any) => {
      if (stockColumn.name == column.name) {
        stockColumn.isVisible = column.isVisible;
      }
      return stockColumn;
    });
    setStockColumnsState(newStockColumns);
  };

  const dispatch = useDispatch();
  const [filterVisible, setFilterVisible] = useState(false);
  const [detailsVisible, { toggle: toggleDetailsVisible }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);

  const [state, setState] = useState<IViewListingTabState>({
    loading: false,
    vehicleConsignedTiresList: [],
    vehicleFoundCount: 0,
    stockConsignedTiresList: [],
    stockFoundCount: 0,
  });

  const [vehiclePaginationProps, setVehiclePaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setVehiclePaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });

  const [stockPaginationProps, setStockPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setStockPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });

  const [countOnPageVehicle, setCountOnPageVehicle] = useState<IDropdownOption>({ key: pageSizesVehicles[0], text: pageSizesVehicles[0].toString() });
  const [countOnPageStock, setCountOnPageStock] = useState<IDropdownOption>({ key: pageSizesStock[0], text: pageSizesStock[0].toString() });
  const [vehicleColumnsState, setVehicleColumnsState] = useState<Array<any>>(vehicleTiresColumns);
  const [stockColumnsState, setStockColumnsState] = useState<Array<any>>(stockTiresColumns);

  const [filtersState, setFiltersState] = useState<any>(initialFilterState);

  const { addNotification } = useNotifications();

  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);

  const getVehicleSortOrder = () => {
    const { fieldName, isSortedDescending } = vehicleColumnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const getStockSortOrder = () => {
    const { fieldName, isSortedDescending } = stockColumnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const fetchVehicleConsignedTires = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getVehicleSortOrder();
      const { data }: any = await apiService.viewListing.getVehicleConsignedTireList(
        { pageNumber: vehiclePaginationProps.current, pageSize: +countOnPageVehicle.key },
        {
          ...filtersState,
          addedDateFrom: filtersState.addedDateFrom && transformDate(moment(filtersState.addedDateFrom).format('MM/DD/YYYY')),
          addedDateTo: filtersState.addedDateTo && transformDate(moment(filtersState.addedDateTo).format('MM/DD/YYYY')),
          changeDateFrom: filtersState.changeDateFrom && transformDate(moment(filtersState.changeDateFrom).format('MM/DD/YYYY')),
          changeDateTo: filtersState.changeDateTo && transformDate(moment(filtersState.changeDateTo).format('MM/DD/YYYY')),
        },
        sortOrder,
        customerId,
        null,
      );
      const foundCount = data.total.found;
      const items = data.data.map((item: any) => ({...item, chgDate: moment(item.chgDate).format('MM/DD/YYYY') }));
      setState((prev: any) => ({ ...prev, vehicleConsignedTiresList: items, vehicleFoundCount: foundCount }));
      if (Math.ceil(foundCount / +countOnPageStock.key) < vehiclePaginationProps.current) {
        await vehiclePaginationProps.onChangePage(1);
      }
      setVehiclePaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPageStock.key) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Vehicle Consigned Tires fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchStockConsignedTires = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getStockSortOrder();
      const { data }: any = await apiService.viewListing.getStockConsignedTireList(
        { pageNumber: stockPaginationProps.current, pageSize: +countOnPageStock.key },
        {
          ...filtersState,
          addedDateFrom: filtersState.addedDateFrom && transformDate(moment(filtersState.addedDateFrom).format('MM/DD/YYYY')),
          addedDateTo: filtersState.addedDateTo && transformDate(moment(filtersState.addedDateTo).format('MM/DD/YYYY')),
          changeDateFrom: filtersState.changeDateFrom && transformDate(moment(filtersState.changeDateFrom).format('MM/DD/YYYY')),
          changeDateTo: filtersState.changeDateTo && transformDate(moment(filtersState.changeDateTo).format('MM/DD/YYYY')),
        },
        sortOrder,
        customerId,
        null,
      );
      const foundCount = data.total.found;
      const items = data.data.map((item: any) => ({...item, chgDate: moment(item.chgDate).format('MM/DD/YYYY') }));
      setState((prev: any) => ({ ...prev, stockConsignedTiresList: items, stockFoundCount: foundCount }));
      if (Math.ceil(foundCount / +countOnPageStock.key) < stockPaginationProps.current) {
        await stockPaginationProps.onChangePage(1);
      }
      setStockPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPageStock.key) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Stock Consigned Tires fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const vehicleSortOrder = getVehicleSortOrder();
      const vehicleRequestData = {
        pagination: { pageNumber: vehiclePaginationProps.current, pageSize: +countOnPageVehicle.key },
        filters: {
          ...filtersState,
          addedDateFrom: filtersState.addedDateFrom && transformDate(moment(filtersState.addedDateFrom).format('MM/DD/YYYY')),
          addedDateTo: filtersState.addedDateTo && transformDate(moment(filtersState.addedDateTo).format('MM/DD/YYYY')),
          changeDateFrom: filtersState.changeDateFrom && transformDate(moment(filtersState.changeDateFrom).format('MM/DD/YYYY')),
          changeDateTo: filtersState.changeDateTo && transformDate(moment(filtersState.changeDateTo).format('MM/DD/YYYY')),
        },
        vehicleSortOrder,
        customerId,
        locationId,
      };
      const stockSortOrder = getStockSortOrder();
      const stockRequestData = {
        pagination: { pageNumber: vehiclePaginationProps.current, pageSize: +countOnPageVehicle.key },
        filters: {
          ...filtersState,
          addedDateFrom: filtersState.addedDateFrom && transformDate(moment(filtersState.addedDateFrom).format('MM/DD/YYYY')),
          addedDateTo: filtersState.addedDateTo && transformDate(moment(filtersState.addedDateTo).format('MM/DD/YYYY')),
          changeDateFrom: filtersState.changeDateFrom && transformDate(moment(filtersState.changeDateFrom).format('MM/DD/YYYY')),
          changeDateTo: filtersState.changeDateTo && transformDate(moment(filtersState.changeDateTo).format('MM/DD/YYYY')),
        },
        stockSortOrder,
        customerId,
        locationId,
      };
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.viewListing.printExcel(vehicleRequestData, stockRequestData, vehicleColumnsState, stockColumnsState, []) :
        await apiService.viewListing.printPdf(vehicleRequestData, stockRequestData, vehicleColumnsState, stockColumnsState, []);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Printing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const openViewTireDetailsWindow = (id: any, pfx: any, bno: any, sfx: any) => {
    dispatch(setTireForReinstate({ tireId: id, pfx, bno, sfx }));
    toggleDetailsVisible();
  };

  const onChangeCountOnPageVehicle = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setVehiclePaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPageVehicle(item);
  };

  const onChangeCountOnPageStock = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setStockPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPageStock(item);
  };

  useEffect(() => {
    // if (JSON.stringify(filtersState) !== JSON.stringify(initialFilterState))
    fetchVehicleConsignedTires();
  }, [vehiclePaginationProps.current, countOnPageVehicle, vehicleColumnsState, filtersState, customerId]);

  useEffect(() => {
    // if (JSON.stringify(filtersState) !== JSON.stringify(initialFilterState))
    fetchStockConsignedTires();
  }, [stockPaginationProps.current, countOnPageStock, stockColumnsState, filtersState, customerId]);

  return (
    <>
      <div className={classNames(styles.viewListingTabContainer)}>
        <div className={classNames(styles.viewListingSettings)}>
          <div>
            <DefaultButton
              id="filterButton"
              className={classNames(styles.filterButton)}
              text="Filter"
              iconProps={filterIcon}
              onClick={() => setFilterVisible(true)}
            />
          </div>
          <div className={classNames(styles.columnsList)}>
            {showHideColumns.map((column: any, index: number) => (
              <div key={index} id={column.name} className={column.isVisible ? styles.itemSelected : styles.itemNotSelected} onClick={() => hideShowColumns(column)}>{column.name}</div>
            ))}
          </div>
        </div>
        <div className={classNames(styles.viewListingContent)}>
          <div className="ms-Grid">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <div className={styles.tableHeading}>
                  <div>
                    <Text variant="xLarge" className={styles.highlight}>Tires on Vehicles</Text>
                    <SeparatorGy vertical />
                    <Text variant="xLarge" className={styles.highlight}>{state.vehicleFoundCount} found</Text>
                  </div>
                  <div>
                    <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                    <Dropdown
                      options={pageSizesVehicles.map(pageSize => ({
                        key: pageSize,
                        text: pageSize.toString(),
                      }))}
                      defaultSelectedKey={pageSizesVehicles[0]}
                      selectedKey={countOnPageVehicle?.key}
                      onChange={onChangeCountOnPageVehicle}
                    />
                  </div>
                </div>
                <div className={styles['table-wrapper']}>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        {
                          vehicleColumnsState.map(item => item.isVisible && (
                            <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onVehicleColumnClick(item)}>{item.name}</th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        state.vehicleConsignedTiresList.map(item => (
                          <tr>
                            <td>
                              <IconButton
                                id="viewButton"
                                iconProps={{ iconName: 'View' }}
                                onClick={() => openViewTireDetailsWindow(item.id, item.pfx, item.brand, item.sfx)}
                              />
                            </td>
                            {
                              vehicleColumnsState.map(vehicleColumn => vehicleColumn.isVisible && (
                                <td>{item[vehicleColumn.fieldName]}</td>
                              ))
                            }
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
                <SeparatorGy />
                <Pagination {...vehiclePaginationProps} />
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <div className={styles.tableHeading}>
                  <div>
                    <Text variant="xLarge" className={styles.highlight}>Tires in Location Spare Stock</Text>
                    <SeparatorGy vertical />
                    <Text variant="xLarge" className={styles.highlight}>{state.stockFoundCount} found</Text>
                  </div>
                  <div>
                    <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                    <Dropdown
                      options={pageSizesStock.map(pageSize => ({
                        key: pageSize,
                        text: pageSize.toString(),
                      }))}
                      defaultSelectedKey={pageSizesStock[0]}
                      selectedKey={countOnPageStock?.key}
                      onChange={onChangeCountOnPageStock}
                    />
                  </div>
                </div>
                <div className={styles['table-wrapper']}> 
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        {
                          stockColumnsState.map(item => item.isVisible && (
                            <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onStockColumnClick(item)}>{item.name}</th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        state.stockConsignedTiresList.map(item => (
                          <tr>
                            <td>
                              <IconButton
                                id="viewButton"
                                iconProps={{ iconName: 'View' }}
                                onClick={() => openViewTireDetailsWindow(item.id, item.prefix, item.brandNo, item.suffix)}
                              />
                            </td>
                            {
                              stockColumnsState.map(stockColumn => stockColumn.isVisible && (
                                <td>{item[stockColumn.fieldName]}</td>
                              ))
                            }
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
                <SeparatorGy />
                <Pagination {...stockPaginationProps} />
              </div>
            </div>
            <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
              <DefaultButton id='printExportBtn' onClick={toggleShowPrintExport} text="Print/Export" />
            </div>
          </div>
        </div>
      </div>
      {filterVisible && <FilterModalWindow isOpened={setFilterVisible} filtersState={filtersState} setFiltersState={setFiltersState} />}
      {detailsVisible && <ViewTireDetailsModal isOpened={detailsVisible} onDismiss={toggleDetailsVisible} />}
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default ViewListingTab;

