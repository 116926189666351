import { Text, IconButton } from '@fluentui/react';
import classNames from 'classnames';
import { FC, useRef, useState } from 'react';
import { IEditingModalWindowProps } from './IEditingModalWindowProps';
import styles from './EditingModalWindow.module.scss';
import EditPlaceRetread from './EditPlaceRetread/EditPlaceRetread';
import EditPlaceOriginal from './EditPlaceOriginal/EditPlaceOriginal';


const EditingModalWindow: FC<IEditingModalWindowProps> = ({isOpened, onDismiss, order, mode}) => {

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {isOpened &&
        <div className={classNames(styles.detailsWrapper)}>
          <div className={classNames(styles.detailsBackground)}>
            <div className={classNames(styles.detailsContainer)} ref={containerRef}>
              <div className={classNames(styles.detailsHeader)}>
                <Text variant="xxLarge"  className={styles.highlight}>{ mode === 'view' ? 'View' : 'Edit' } Tire Order</Text>
                <IconButton
                  iconProps={{ iconName: 'Cancel' }}
                  title="close"
                  onClick={() => onDismiss()}
                />
              </div>
              <div className={classNames(styles.detailsBody)}>
                {
                  order.tc === '2' ?
                    <EditPlaceRetread order={order} onDismiss={onDismiss} mode={mode}/> :
                    <EditPlaceOriginal order={order} onDismiss={onDismiss} mode={mode}/>
                }
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default EditingModalWindow;