import {
  Pivot,
  PivotItem,
} from '@fluentui/react';
import classNames from 'classnames';
import { get } from 'lodash';
import { FC, FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import { MIDAS_MODULES } from '../../models/EnumMidasModules';
import { customerSelector, setCustomer, setLocation, setRkUsage } from '../../redux/recordKeepingSlice';
import { activeTabSelector, clearTireOrdering, setActiveTab, setOrderCode } from '../../redux/tireOrderingSlice';
import ModuleTitleComponent from '../../shared/ModuleTitleComponent';
import SearchCustomerLocationOrder from '../Common/Search/SearchCustomerLocationOrder';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { getMenuOptionById } from '../Nav/consts';
import { tireOrderTabs } from './consts';
import { ITireOrderProps } from './ITireOrderProps';
import { ITireOrderState } from './ITireOrderState';
import './TireOrder.scss';

const TireOrder: FC<ITireOrderProps> = (): ReactElement => {

  const dispatch = useDispatch();
  const selectedCustomer = useSelector(customerSelector);
  const activeTab = useSelector(activeTabSelector);

  const [tireOrderState, setTireOrderState] = useState<ITireOrderState>({ loading: false, currentTab: tireOrderTabs.tireOrderManagement.name });

  const { loadingPermissions, getPermissionsByAccessKey } = useUserPermissions();

  const setLoading = (isLoading: any) => {
    setTireOrderState(prev => ({ ...prev, loading: isLoading }));
  };

  const handleLinkClick = (item?: PivotItem) => {
    const newTab = get(item, 'props.itemKey', '');
    dispatch(setActiveTab(newTab));
  };

  const renderTab = (TabComponent: FunctionComponent) => {
    if (TabComponent) return <TabComponent />;
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  useEffect(() => {
    setTireOrderState({ ...tireOrderState, currentTab: activeTab });
  }, [activeTab]);

  useEffect(() => {
    return () => {
      dispatch(setCustomer(null));
      dispatch(setLocation({ id: null, locationCode: '', locationName: '', shipToNumber: '', customerId: '', addrLine1: '', addrLine2: '', city: '' }));
      dispatch(setOrderCode(null));
      dispatch(clearTireOrdering());
    };
  }, []);

  useEffect(() => {
    dispatch(clearTireOrdering());
    dispatch(setRkUsage(false));
  }, [selectedCustomer]);

  const moduleInfo = getMenuOptionById(MIDAS_MODULES.TIRE_ORDERING);

  return (
    <>
      <div className="ms-Grid record-keeping-container mainContainer" dir="ltr">
        <div style={{ paddingBottom: '1rem' }}><ModuleTitleComponent moduleInfo={moduleInfo} /></div>
        <SearchCustomerLocationOrder callbackLoading={setLoading} />
        {selectedCustomer &&
          <div className="ms-Grid-row">
            <div
              className={classNames('ms-Grid-col', 'ms-lg12', 'tabulatorWrapper')}
            >
              <Pivot
                aria-label="Separately Rendered Content Pivot Example"
                selectedKey={tireOrderState.currentTab}
                onLinkClick={handleLinkClick}
                getTabId={getTabId}
                linkFormat="tabs"
                linkSize="large"
              >
                {Object.values(tireOrderTabs).map(({ accessKey, name, component }, index) =>
                  getPermissionsByAccessKey(accessKey)?.isAccess && (
                    <PivotItem headerText={name} key={index} itemKey={name}>
                      <div className="pivotBodyContainer">
                        {renderTab(component)}
                      </div>
                    </PivotItem>))}
              </Pivot>
            </div>
          </div>}
      </div>
      {(tireOrderState.loading || loadingPermissions) && <LoadingScreen />}
    </>
  );
};

export default TireOrder;