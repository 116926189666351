import React, { FC, ReactElement } from 'react';

import { IconButton, Stack, Text } from '@fluentui/react';
import { AnimationClassNames } from '@fluentui/style-utilities';

import { accordion, accordionConent, accordionHeader } from './consts';
import { IAccordionProps } from './IAccordionProps';


const Accordion: FC<IAccordionProps> = ({ id, isOpened, children, headerText, onHeaderClick }): ReactElement => {
  return (
    <>
      <Stack id={`${id}-accordion_header`} horizontal={false} styles={accordion}>
        <Stack.Item styles={accordionHeader}>
          <Stack horizontal={true} onClick={onHeaderClick}>
            <Stack.Item>
              <IconButton
                iconProps={{
                  iconName: isOpened
                    ? 'ChevronDown'
                    : 'ChevronRight',
                }}
              />
            </Stack.Item>
            <Stack.Item align='center'><Text variant="large">{headerText}</Text></Stack.Item>
          </Stack>
        </Stack.Item>
        {isOpened && (
          <Stack.Item
            className={AnimationClassNames.slideDownIn20}
            styles={accordionConent}
          >
            {children}
          </Stack.Item>
        )}
      </Stack>
    </>
  );
};

export default Accordion;
