import {
  IconButton,
  Slider,
  Text,
  Dropdown,
  DefaultButton,
  Checkbox,
  DatePicker,
  IDropdownOption,
  MessageBarType,
  TextField,
} from '@fluentui/react';
import { FC, FormEvent, ReactElement, useEffect, useRef, useState } from 'react';
import {
  IFilterModalWindowProps,
} from './FilterModalWindowProps';
import classNames from 'classnames';
import apiService from '../../../../../../../api';
import styles from './FilterModalWindow.module.scss';
import { emptyFilterModalWindowState, initialFilterState } from './consts';
import { IFilterModalWindowState } from './IFilterModalWindowState';
import useNotifications from '../../../../../../../hooks/useNotifications';
import { useSelector } from 'react-redux';
import { customerSelector } from '../../../../../../../redux/recordKeepingSlice';
import moment from 'moment';

const FilterModalWindow: FC<IFilterModalWindowProps> = ({
  isOpened,
  filtersState,
  setFiltersState,
}): ReactElement => {
  const [currentFilters, setCurrentFilters] = useState<any>(filtersState);
  const { id: customerId } = useSelector(customerSelector);
  
  const { addNotification } = useNotifications();

  const [state, setState] = useState<IFilterModalWindowState>(emptyFilterModalWindowState);

  const applyFilters = () => {
    setFiltersState({ ...currentFilters });
    isOpened(false);
  };

  const clearFilters = () => {
    setCurrentFilters({ ...initialFilterState });
  };

  const containerRef = useRef(null);

  const handleClickOutside = (event: any) => {
    if (!(containerRef.current as any).contains(event.target)) {
      isOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [containerRef]);

  const fetchLocations = async () => {
    try {
      const { data }: any = await apiService.getLocationsByCustomerId(
        customerId,
        { pageSize: 5000, pageNumber: 1 },
        {},
      );
      const currentLocation = data.data.find((location: any) => location.locationCode === currentFilters.locationCode);
      setState(prev => ({ ...prev, locations: data.data, loading: false }));
      setCurrentFilters((prev: any) => ({ 
        ...prev, 
        locationCode: currentLocation ? currentLocation.locationCode : '',
      }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Vehicle Consigned Tires fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchTireSizes = async () => {
    try {
      const location = state.locations.find((location) => (location.locationCode === currentFilters.locationCode));
      const { data }: any = await apiService.viewListing.getTireSizes(
        customerId,
        location ? location.id : null,
      );
      setState(prev => ({ ...prev, tireSizes: data.data, loading: false }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Vehicle Consigned Tires fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchTireClasses = async () => {
    try {
      const { data }: any = await apiService.viewListing.getTireClasses();
      setState(prev => ({ ...prev, tireClasses: data }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Vehicle Consigned Tires fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    fetchLocations();
    fetchTireClasses();
  }, []);

  useEffect(() => {
    fetchTireSizes();
  }, [currentFilters.locationCode]);

  const handleLocationChange = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setCurrentFilters({ ...currentFilters, locationCode: item?.key });
  };

  const handleTireSizeChange = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setCurrentFilters({ ...currentFilters, tireSize: item?.key });
  };

  const handleTireClassChange = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setCurrentFilters({ ...currentFilters, tireClass: item?.key });
  };

  const onChangeRange = (_: unknown, range: [number, number] | undefined) => {
    if (range) {
      setCurrentFilters({ ...currentFilters, totalMilesFrom: range[0] });
      setCurrentFilters({ ...currentFilters, totalMilesTo: range[1] });
    }
  };

  return (
    <div id="filterModalWindow" className={classNames(styles.filterWrapper)}>
      <div className={classNames(styles.filterBackground)}>
        <div className={classNames(styles.filterContainer)} ref={containerRef}>
          <div className={classNames(styles.filterHeader)}>
            <Text variant="xxLarge">Filter</Text>
            <IconButton
              id="closeButton"
              iconProps={{iconName: 'Cancel'}}
              title="close"
              onClick={() => isOpened(false)}
            />
          </div>
          <div className={classNames(styles.filterBody)}>
            <div className={classNames(styles.row)}>
              <div className={classNames(styles.column)}>
                <div>
                  <label>
                    <Text variant="large">Location Code</Text>
                  </label>
                  <Dropdown
                    id="locationCode"
                    selectedKey={currentFilters.locationCode}
                    onChange={handleLocationChange}
                    options={state.locations.map(location => ({
                      key: location.locationCode,
                      text: location.locationCode,
                    }))}
                  />
                </div>
              </div>
              <div className={classNames(styles.column)}>
                <div>
                  <label>
                    <Text variant="large">Location Name</Text>
                  </label>
                  <Dropdown
                    id="locationName"
                    selectedKey={currentFilters.locationCode}
                    options={state.locations.map(location => ({
                      key: location.locationCode,
                      text: location.locationName,
                    }))} 
                    disabled  
                  />
                </div>
              </div>
            </div>
            <div className={classNames(styles.row)}>
              <div className={classNames(styles.column)}>
                <div>
                  <label>
                    <Text variant="large">Tire Class</Text>
                  </label>
                  <Dropdown 
                    id="tireClass"
                    selectedKey={currentFilters.tireClass}
                    onChange={handleTireClassChange}
                    options={state.tireClasses.map(tireClass => ({
                      key: tireClass.item1,
                      text: `${tireClass.item1} - ${tireClass.item2}`,
                    }))} 
                  />
                </div>
              </div>
              <div className={classNames(styles.column)}>
                <div>
                  <label>
                    <Text variant="large">Tire Size</Text>
                  </label>
                  <Dropdown 
                    id="tireSize"
                    selectedKey={currentFilters.tireSize}
                    onChange={handleTireSizeChange}
                    options={state.tireSizes.map(tireSize => ({
                      key: tireSize,
                      text: tireSize,
                    }))} 
                  />
                </div>
              </div>
            </div>
            <div className={classNames(styles.row)}>
              <div className={classNames(styles.column)}>
                <div>
                  <label>
                    <Text variant="large">Type Code</Text>
                  </label>
                  <TextField
                    id="typeCode"
                    value={currentFilters.typeCode}
                    onChange={(e, typeCode) => setCurrentFilters({ ...currentFilters, typeCode })}
                  />
                </div>
              </div>
              <div className={classNames(styles.column)}>
                <div>
                  <label>
                    <Text variant="large">Prod Code</Text>
                  </label>
                  <TextField
                    id="productCode"
                    value={currentFilters.productCode}
                    onChange={(e, productCode) => setCurrentFilters({ ...currentFilters, productCode })}
                  />
                </div>
              </div>
            </div>
            <div className={classNames(styles.row)}>
              <div className={classNames(styles.column)}>
                <div>
                  <label>
                    <Text variant="large">Dot #</Text>
                  </label>
                  <TextField
                    id="dotNo"
                    value={currentFilters.dotNo}
                    onChange={(e, dotNo) => setCurrentFilters({ ...currentFilters, dotNo })}
                  />
                </div>
              </div>
            </div>
            
            <div className={classNames(styles.row)}>
              <div className={classNames(styles.column)}>
                <div
                  className={classNames(
                    styles.column,
                    styles.calendarContainer,
                  )}
                >
                  <label>
                    <Text variant="large">Date Added</Text>
                  </label>
                  <div>
                    <label>
                      <Text variant="medium">From</Text>
                    </label>
                    <DatePicker
                      id="dateAddedFrom"
                      showMonthPickerAsOverlay={true}
                      placeholder="Select a date..."
                      ariaLabel="Select a date"
                      value={currentFilters.addedDateFrom ? currentFilters.addedDateFrom : null}
                      onSelectDate={(addedDateFrom: Date | null | undefined) => setCurrentFilters({ ...currentFilters, addedDateFrom })}
                      formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                    />
                  </div>
                  <div>
                    <label>
                      <Text variant="medium">To</Text>
                    </label>
                    <DatePicker
                      id="dateAddedTo"
                      showMonthPickerAsOverlay={true}
                      placeholder="Select a date..."
                      ariaLabel="Select a date"
                      value={currentFilters.addedDateTo ? currentFilters.addedDateTo : null}
                      onSelectDate={(addedDateTo: Date | null | undefined) => setCurrentFilters({ ...currentFilters, addedDateTo })}
                      formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                    />
                  </div>
                </div>
              </div>
              <div className={classNames(styles.column)}>
                <div
                  className={classNames(
                    styles.column,
                    styles.calendarContainer,
                  )}
                >
                  <label>
                    <Text variant="large">Change Date</Text>
                  </label>
                  <div>
                    <label>
                      <Text variant="medium">From</Text>
                    </label>
                    <DatePicker
                      id="changeDateFrom"
                      showMonthPickerAsOverlay={true}
                      placeholder="Select a date..."
                      ariaLabel="Select a date"
                      value={currentFilters.changeDateFrom ? currentFilters.changeDateFrom : null}
                      onSelectDate={(changeDateFrom: Date | null | undefined) => setCurrentFilters({ ...currentFilters, changeDateFrom })}
                      formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                    />
                  </div>
                  <div>
                    <label>
                      <Text variant="medium">To</Text>
                    </label>
                    <DatePicker
                      id="changeDateTo"
                      showMonthPickerAsOverlay={true}
                      placeholder="Select a date..."
                      ariaLabel="Select a date"
                      value={currentFilters.changeDateTo ? currentFilters.changeDateTo : null}
                      onSelectDate={(changeDateTo: Date | null | undefined) => setCurrentFilters({ ...currentFilters, changeDateTo })}
                      formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames(styles.row)}>
              <div className={classNames(styles.column)}>
                <label>
                  <Text variant="large">Total Miles</Text>
                </label>
                <Slider
                  id='slider'
                  min={0}
                  max={500000}
                  ranged
                  value={currentFilters.totalMilesTo}
                  lowerValue={currentFilters.totalMilesFrom}
                  showValue
                  onChange={onChangeRange}
                />
              </div>
            </div>
            <div className={classNames(styles.row)}>
              <div className={classNames(styles.column, styles.checkboxes)}>
                <div>
                  <Checkbox id="allOOS" label="All OOS" checked={currentFilters.allOos} onChange={(_, allOos) => setCurrentFilters({ ...currentFilters, allOos })}/>
                </div>
                <div>
                  <Checkbox id="billed" label="Billed" checked={currentFilters.billed} onChange={(_, billed) => setCurrentFilters({ ...currentFilters, billed })}/>
                </div>
              </div>
            </div>
            <div className={classNames(styles.row)}>
              <div className={classNames(styles.column, styles.checkboxes)}>
                <div className={classNames(styles.row)}>
                  <div className={classNames(styles.column)}>
                    <div>
                      <Checkbox id="flgAsDmg" label="Flg as Dmg" checked={currentFilters.flgasDmg} onChange={(_, flgasDmg) => setCurrentFilters({ ...currentFilters, flgasDmg })}/>
                    </div>
                    <div>
                      <Checkbox id="dmg" label="DMG" checked={currentFilters.dmg} onChange={(_, dmg) => setCurrentFilters({ ...currentFilters, dmg })}/>
                    </div>
                  </div>
                  <div className={classNames(styles.column)}>
                    <div>
                      <Checkbox id="sold" label="Sold" checked={currentFilters.sold} onChange={(_, sold) => setCurrentFilters({ ...currentFilters, sold })}/>
                    </div>
                    <div>
                      <Checkbox id="earlyRemoval" label="Early Removal" checked={currentFilters.earlyRemoval} onChange={(_, earlyRemoval) => setCurrentFilters({ ...currentFilters, earlyRemoval })}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classNames(styles.column, styles.checkboxes)}>
                <div className={classNames(styles.row)}>
                  <div className={classNames(styles.column)}>
                    <div>
                      <Checkbox id="retreadAdjust" label="Retread Adjust" checked={currentFilters.retreadAdjust} onChange={(_, retreadAdjust) => setCurrentFilters({ ...currentFilters, retreadAdjust })}/>
                    </div>
                    <div>
                      <Checkbox id="lost" label="Lost" checked={currentFilters.lost} onChange={(_, lost) => setCurrentFilters({ ...currentFilters, lost })}/>
                    </div>
                  </div>
                  <div className={classNames(styles.column)}>
                    <div>
                      <Checkbox id="transferTo" label="Transfer To" checked={currentFilters.transferTo} onChange={(_, transferTo) => setCurrentFilters({ ...currentFilters, transferTo })}/>
                    </div>
                    <div>
                      <Checkbox id="closeOut" label="Close Out" checked={currentFilters.closeOut} onChange={(_, closeOut) => setCurrentFilters({ ...currentFilters, closeOut })}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(styles.filterFooter)}>
            <DefaultButton
              id="clearButton"
              onClick={clearFilters}
              text={'Clear Filters'}
            />
            <DefaultButton
              id="applyButton"
              onClick={applyFilters}
              text={'Apply'}
              disabled={
                (currentFilters.addedDateFrom !== null && currentFilters.addedDateTo !== null && currentFilters.addedDateFrom > currentFilters.addedDateTo) ||
                (currentFilters.changeDateFrom !== null && currentFilters.changeDateTo !== null && currentFilters.changeDateFrom > currentFilters.changeDateTo)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterModalWindow;

