import { recordTestInstance, exportTestInstance } from '../axios';

const getMileageAuditDetails = (auditYear: any, pagination: any, sortOrder: any, customerId: number, locationId: number) =>
  recordTestInstance.post('/mileage-audit-period', { auditYear, pagination, sortOrder, customerId, locationId });

const getMileageAuditDetailsByPeriodId = (pagination: any, sortOrder: any, billingPeriodId: string, customerId: number, locationId: number) =>
  recordTestInstance.post('/mileage-audit-details', { pagination, sortOrder, billingPeriodId, customerId, locationId });

const submitMileageAuditDetails = (data: Array<any>, mileageAuditPeriodsId: string, customerId: string, locationId: string) =>
  recordTestInstance.put('/mileage-audit-details/update', { data, mileageAuditPeriodsId, customerId, locationId });

const getMileageAuditStatus = (billingPeriodId: string) =>
  recordTestInstance.post('/mileage-audit-status', { billingPeriodId });

const printExcelMileageAuditReport = (mileageAuditDetailsInput: any, mileageAuditPeriodInput: any,  headerFields: Array<any>) =>
  exportTestInstance.post('/mileage-audit/export-excel', {mileageAuditDetailsInput, mileageAuditPeriodInput, headerFields}, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfMileageAuditReport = (mileageAuditDetailsInput: any, mileageAuditPeriodInput: any,  headerFields: Array<any>) =>
  exportTestInstance.post('/mileage-audit/export-pdf', {mileageAuditDetailsInput, mileageAuditPeriodInput, headerFields}, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  getMileageAuditDetails,
  getMileageAuditDetailsByPeriodId,
  submitMileageAuditDetails,
  getMileageAuditStatus,
  printExcelMileageAuditReport,
  printPdfMileageAuditReport,
};
