import { HeadCell } from '../../../../../shared/DataGridComponent/DataGridModels';

export const reportsHeadCell: HeadCell[] = [
  {
    key: 'column1',
    name: 'Report Name',
    fieldName: 'reportName',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Report Type',
    fieldName: 'reportType',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Start Time',
    fieldName: 'startTime',
    typeField: 'date',
  },
];