import React, { FC, ReactElement, useEffect } from 'react';
import classNames from 'classnames';
import { MessageBarType, Pivot, PivotItem } from '@fluentui/react';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import TabHeader from '../TabHeader/TabHeader';

import PostOosTiresTab from './tabComponents/PostOosTires/PostOosTiresTab';
import { ITireDispositionTabProps } from './ITireDispositionTabProps';
import { tireDispositionTabs } from './consts';

import PostFlaggedAsDamaged from './tabComponents/PostFlaggedAsDamaged/PostFlaggedAsDamaged';
import TransferTiresTab from './tabComponents/TransferTires/TransferTiresTab';
import UnflagConsignedTiresTab from './tabComponents/UnflagConsignedTires/UnflagConsignedTiresTab';
import apiService from '../../../../api';
import { auth_tireDisposition_postFlaggedAsDamaged, auth_tireDisposition_postOosTires, auth_tireDisposition_transferTires, auth_tireDisposition_unflagConsignedTires } from '../../../../consts/programKeys';
import { useDispatch, useSelector } from 'react-redux';
import { customerSelector, setCustomerInformation } from '../../../../redux/recordKeepingSlice';
import useNotifications from '../../../../hooks/useNotifications';


const TireDispositionTab: FC<ITireDispositionTabProps> = (): ReactElement => {
  const [selectedKey, setSelectedKey] = React.useState(tireDispositionTabs.postOosTires);

  const { hasPermission } = useUserPermissions();
  const userPermissionsUnflagConsignedTire = hasPermission(auth_tireDisposition_unflagConsignedTires);
  const userPermissionsPostFlaggedAsDamaged = hasPermission(auth_tireDisposition_postFlaggedAsDamaged);
  const userPermissionsPostOosTires = hasPermission(auth_tireDisposition_postOosTires);
  const userPermissionsTransferTires = hasPermission(auth_tireDisposition_transferTires);
  const { id: customerId } = useSelector(customerSelector);
  const dispatch = useDispatch();
  const { addNotification } = useNotifications();

  const fetchCustomerData = async () => {
    try {
      const { data }: any = await apiService.getCustomerInformation(customerId);
      dispatch(setCustomerInformation(data.data));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Customer data fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  useEffect(() => {
    fetchCustomerData();
  }, []);

  return (
    <div>
      <TabHeader
        details
      />
      <Pivot
        className={classNames('pivot-container', 'pivot-gy-colors')}
        selectedKey={selectedKey}
        onLinkClick={handleLinkClick}
        getTabId={getTabId}
        linkFormat="tabs"
      >
        {userPermissionsPostOosTires.isAccess && <PivotItem headerText={tireDispositionTabs.postOosTires} itemKey={tireDispositionTabs.postOosTires}>
          <div className="vertical-pivot-container"><PostOosTiresTab /></div>
        </PivotItem>}
        {userPermissionsPostFlaggedAsDamaged.isAccess && <PivotItem headerText={tireDispositionTabs.postFlaggedAsDamaged} itemKey={tireDispositionTabs.postFlaggedAsDamaged}>
          <div className="vertical-pivot-container"><PostFlaggedAsDamaged /></div>
        </PivotItem>}
        {userPermissionsUnflagConsignedTire.isAccess && <PivotItem headerText={tireDispositionTabs.unflagConsignedTire} itemKey={tireDispositionTabs.unflagConsignedTire}>
          <div className="vertical-pivot-container"><UnflagConsignedTiresTab /></div>
        </PivotItem>}
        {userPermissionsTransferTires.isAccess && <PivotItem headerText={tireDispositionTabs.transferTires} itemKey={tireDispositionTabs.transferTires}>
          <div className="vertical-pivot-container"><TransferTiresTab /></div>
        </PivotItem>}
      </Pivot>
    </div>
  );
};

export default TireDispositionTab;
