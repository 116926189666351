import { createSlice } from '@reduxjs/toolkit';
import { IOrderCode } from '../components/TireOrder/IOrderCode';

const initialState: {
  orderCode: IOrderCode | any,
  inTransitOrderCode: IOrderCode | any,
  activeTab: string,
  activeSubTab: string,
  contractInformation: any,
} = {
  orderCode: null,
  inTransitOrderCode: null,
  activeTab: 'Tire Order Management',
  activeSubTab: '',
  contractInformation: null,
};

const tireOrderingSlice = createSlice({
  name: 'tireOrdering',
  initialState,
  reducers: {
    setOrderCode: (state, action) => {
      state.orderCode = action.payload;
    },
    setInTransitOrderCode: (state, action) => {
      state.inTransitOrderCode = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setActiveSubTab: (state, action) => {
      state.activeSubTab = action.payload;
    },
    setContractInformation: (state, action) => {
      state.contractInformation = action.payload;
    },
    clearTireOrdering: (state) => {
      state.activeTab = 'Tire Order Management';
      state.activeSubTab = '';
      state.contractInformation = null;
    },
  },
});

export const {
  setOrderCode,
  setInTransitOrderCode,
  setActiveTab,
  setActiveSubTab,
  setContractInformation,
  clearTireOrdering,
} = tireOrderingSlice.actions;

export const orderCodeSelector = (state: any) => state.tireOrdering.orderCode;
export const inTransitOrderCodeSelector = (state: any) => state.tireOrdering.inTransitOrderCode;
export const activeTabSelector = (state: any) => state.tireOrdering.activeTab;
export const activeSubTabSelector = (state: any) => state.tireOrdering.activeSubTab;
export const contractInformationSelector = (state: any) => state.tireOrdering.contractInformation;


export default tireOrderingSlice.reducer;
