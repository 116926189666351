import { recordTestInstance, exportTestInstance } from '../axios';
exportTestInstance;

const getVehicleConsignedTireList = (
  pagination: any,
  filters: any,
  sortOrder: any,
  customerId: any,
  locationId: any,
) =>
  recordTestInstance.post('/vehicle/consigned-tires', {
    pagination,
    filters,
    sortOrder,
    customerId,
    locationId,
  });

const getStockConsignedTireList = (
  pagination: any,
  filters: any,
  sortOrder: any,
  customerId: any,
  locationId: any,
) =>
  recordTestInstance.post('/stock/consigned-tires', {
    pagination,
    filters,
    sortOrder,
    customerId,
    locationId,
  });

const getTireSizes = (
  customerId: any,
  locationId: any,
) =>
  recordTestInstance.post('/consigned-tires/tire-sizes', {
    customerId,
    locationId,
  });

const getTireClasses = () =>
  recordTestInstance.post('/consigned-tires/tire-classes');

const printExcel = (consignedTiresInput: any, spareStockInput: any, vehicleColumnState: any, stockColumnState: any, headerFields: Array<any>) =>
  exportTestInstance.post('/consigned-tires/export-excel', { consignedTiresInput, spareStockInput, vehicleColumnState, stockColumnState, headerFields}, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (consignedTiresInput: any, spareStockInput: any, vehicleColumnState: any, stockColumnState: any, headerFields: Array<any>) =>
  exportTestInstance.post('/consigned-tires/export-pdf', { consignedTiresInput, spareStockInput, vehicleColumnState, stockColumnState, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  getVehicleConsignedTireList,
  getStockConsignedTireList,
  getTireSizes,
  getTireClasses,
  printExcel,
  printPdf,
};
