;import { Checkbox, DatePicker, DateRangeType, DefaultButton, Dropdown, IDropdownOption, MessageBarType, PrimaryButton, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import apiService from '../../../../api';
import useNotifications from '../../../../hooks/useNotifications';
import DataGridComponent from '../../../../shared/DataGridComponent';
import DialogComponent from '../../../../shared/DialogComponent';
import { IAvailableInvoice } from '../../../../models/IAvailableInvoice';
import { ICustomerDetails } from '../../../../models/ICustomerDetails';
import { INotification } from '../../../../models/INotification';
import { FormBuilderGroup } from '../../../../shared/FormBuilderComponent';
import { INVOICESTATUS_ENUM } from '../../../../models/EnumInvoiceStatuses';
import { ACTION_GROUPS_ENUM } from '../../../../shared/DataGridComponent/ActionsComponent/ActionsGroupEnum';
import { defaultDataGridPageSizes, initalDataGridState } from '../../../../shared/DataGridComponent/utils';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { auth_billing_viewInvoices } from '../../../../consts/programKeys';

import AutoCompleteField from '../../../Common/Search/AutoCompleteField';
import filterIcon from '../../../../assets/icons/filterIcon.svg';
import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import { ICustomer } from '../../../Common/Search/ICustomer';

import {
  availableInvoicesHeadCell,
  FILTERS_FIELDS,
  SearchOptions,
  CheckboxOptions,
  SEARCH_FIELDS,
  ReleaseAction,
  IActionType,
} from './consts';

import styles from './BulkRelease.module.scss';
import moment from 'moment';
import LogsModal from './LogsModal/LogsModal';

interface BulkReleaseComponentProps {
  value?: any;
};

const BulkReleaseComponent: FunctionComponent<BulkReleaseComponentProps> = () => {

  // hooks
  const { addNotification } = useNotifications();

  //state
  const [availableInvoicesList, setAvailableInvoicesList] = useState<Array<IAvailableInvoice>>([]);
  const [availableInvoicesSelected, setavailableInvoicesSelected] = useState<Array<IAvailableInvoice>>([]);
  const [countries, setCountries] = useState([]);
  const [regionCodes, setRegionCodes] = useState([]);
  const [customersList, setCustomerList] = useState<ICustomer[]>([]);
  const [invoiceTypes, setInvoiceTypes] = useState([]);
  const [customerDetails, setcustomerDetails] = useState<ICustomerDetails>();
  const [customSearchOption, setCustomSearchOption] = useState<SearchOptions>('regionSearch');
  const [customFilters, setcustomFilters] = useState<any>(null);
  const [customerSelected, setCustomerSelected] = useState<any>();
  const [countrySelected, setCountrySelected] = useState<string>('');
  const [totalFound, setTotalFound] = useState(0);
  const [totalAmount, setTotalAmount] = useState({ totalDebit: 0, totalCredit: 0 });
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });
  const [selectedAction, setSelectedAction] = useState<IActionType>(ReleaseAction);
  const [dataGridState, setDataGridState] = useState(initalDataGridState);
  const [releaseAllInvoices, setReleaseAllInvoices] = useState(true);
  const [selectedCheckBoxOption, setSelectedCheckBoxOption] = useState<CheckboxOptions>('releaseAll');
  const [runningStatus, setRunningStatus] = useState<string>('IDLE');
  const [submittedInvoices, setSubmittedInvoices] = useState<Array<IAvailableInvoice>>([]);

  // toggles
  const [isDialogComponentOpen, { toggle: toggleDialogComponent }] = useBoolean(false);
  const [isLoadingAvailableTable, { toggle: toggleShowLoadingAvailableTable }] = useBoolean(false);
  const [isLoadingFetchCustomers, { toggle: toggleIsLoadingFetchCustomers }] = useBoolean(false);
  const [isLoadingFetchCustomerDetails, { toggle: toggleIsLoadingFetchCustomerDetails }] = useBoolean(false);
  const [viewCreditOnly, { toggle: toggleViewCreditOnly }] = useBoolean(false);
  const [reloadControlsTable, { toggle: toggleReloadControlsTable }] = useBoolean(false);
  const [viewCurrentYear, { toggle: toggleViewCurrentYear }] = useBoolean(false);
  const [showLogs, { toggle: toggleLogsGrid }] = useBoolean(false);

  //permission
  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_billing_viewInvoices);

  //form validators
  const searchFormFieldsGroup = new FormBuilderGroup(SEARCH_FIELDS);
  const filterFormFieldsGroup = new FormBuilderGroup(FILTERS_FIELDS);

  const fetchAvailableInvoices = async (
    pagination: any = { pageSize: defaultDataGridPageSizes[0], pageNumber: 1 },
    filters?: any,
    sortOrder: any = { column: availableInvoicesHeadCell[0].fieldName, order: 'asc' },
  ) => {
    try {
      const { data: { data, total, totalCredit, totalDebit } }: any = await apiService.billingAPI.getAvailableInvoices(
        pagination,
        sortOrder,
        filters,
        customSearchOption == 'customerSearch' ? customerSelected?.id : null,
        null,
        customSearchOption == 'regionSearch' ? searchFormFieldsGroup.getFieldFormValue('regionField') : null,
        customSearchOption == 'regionSearch' ? countrySelected : null,
      );
      setAvailableInvoicesList(data);
      setTotalAmount({ totalDebit, totalCredit });
      setTotalFound(total?.found);
    } catch (error) {
      setNotification({ text: 'Fetching tires error', type: MessageBarType.error });
    }
  };

  const fetchDeleteInvoices = async (ids: Array<string>) => {
    try {
      await apiService.billingAPI.deleteAvailableInvoices(ids);
      setNotification({ text: `${ids.length} invoices deleted successfully`, type: MessageBarType.success });
    } catch (error) {
      setNotification({ text: 'Deleting invoices error', type: MessageBarType.error });
    }
  };

  const fetchCountries = async () => {
    try {
      const { data: { countries } } = await apiService.customerAPI.getCountries();
      setCountries(countries);
      setCountrySelected(countries[1]);
    }
    catch (e: any) {
      setNotification({ text: 'Error fetching countries', type: MessageBarType.error });
    }
  };

  const fetchRegionCodes = async () => {
    try {
      const { data: { regionCodes } } = await apiService.customerAPI.getRegionCodes(countrySelected);
      setRegionCodes(regionCodes);
    } catch (e: any) {
      setNotification({ text: 'Error fetching region codes', type: MessageBarType.error });
    }
  };

  const fetchCustomers = async (
    filter: any,
    pagination?: any,
    sort: any = { column: 'custPrefix', order: 'asc' }) => {
    toggleIsLoadingFetchCustomers();
    try {
      const { data }: any = await apiService.getCustomerList(filter, pagination, sort);
      setCustomerList(data.data);
    } catch (e: any) {
      setNotification({ text: 'Customers fetching error.', type: MessageBarType.error });
    }
    toggleIsLoadingFetchCustomers();
  };

  const fetchInvoiceTypes = async () => {
    try {
      const { data }: any = await apiService.billingAPI.getInvoiceTypes();
      setInvoiceTypes(data);
    } catch (e: any) {
      setNotification({ text: 'Invoice types fetching error.', type: MessageBarType.error });
    }
  };

  const fetchCustomerDetails = async (invoiceId: string) => {
    toggleIsLoadingFetchCustomerDetails();
    try {
      const { data }: any = await apiService.billingAPI.getViewInvoiceCustomerDetails(invoiceId);
      setcustomerDetails(data);
    } catch (e: any) {
      setNotification({ text: 'Customers details fetching error.', type: MessageBarType.error });
    }
    toggleIsLoadingFetchCustomerDetails();
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    toggleShowLoadingAvailableTable();
    toggleReloadControlsTable();
    setDataGridState(dataGridState);
    const { countOnPage, paginationProps, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    if (validateFetchAvailableInvoices) {
      await fetchAvailableInvoices(pagination, customFilters, sortOrder);
    };
    toggleShowLoadingAvailableTable();
    toggleReloadControlsTable();
  };

  const handleSearch = async () => {
    toggleShowLoadingAvailableTable();
    toggleReloadControlsTable();
    const filters = {
      byDate: {},
      status: INVOICESTATUS_ENUM.PENDING,
      invoiceType: '',
      creditOnly: '',
    };
    setcustomFilters(filters);
    await fetchAvailableInvoices(undefined, filters);
    handleCleanFilters();
    toggleShowLoadingAvailableTable();
    toggleReloadControlsTable();
  };

  const handleFilterInvoices = async () => {
    toggleShowLoadingAvailableTable();
    toggleReloadControlsTable();
    const currentYear = viewCurrentYear ? viewCurrentYear : null;
    const duringTheMonth = currentYear ? null : filterFormFieldsGroup.getFieldFormValue('monthOfField');
    const filters = {
      byDate: {
        currentYear,
        duringTheMonth,
      },
      status: INVOICESTATUS_ENUM.PENDING,
      invoiceType: filterFormFieldsGroup.getFieldFormValue('invoiceTypeField'),
      creditOnly: viewCreditOnly,
    };
    setcustomFilters(filters);
    await fetchAvailableInvoices(undefined, filters);
    toggleShowLoadingAvailableTable();
    toggleReloadControlsTable();

  };

  const handleSelectRow = (invoicesSelected: Array<IAvailableInvoice>) => {
    setavailableInvoicesSelected(invoicesSelected);
    setcustomerDetails(undefined);
    if (invoicesSelected.length == 1) {
      fetchCustomerDetails(invoicesSelected[0].invoiceId);
    };
  };

  const handleDeleteInvoices = async (rows: any[] = []) => {
    toggleShowLoadingAvailableTable();
    toggleReloadControlsTable();
    const invoicesIds = getIdsFromInvoices(availableInvoicesSelected);

    if (validateRowsBeforeDelete(rows)) {
      await fetchDeleteInvoices(invoicesIds);
      await fetchAvailableInvoices(undefined, customFilters);
    };

    toggleShowLoadingAvailableTable();
    toggleReloadControlsTable();
  };

  const handleChangeCustomSearch = (property: any) => {
    setCustomSearchOption(property || '');
  };

  const handleCheckboxSelection = (selectedOption: any) => {
    setSelectedCheckBoxOption(selectedOption);
    if (selectedOption === 'releaseAll') {
      setReleaseAllInvoices(true);
    }
    else {
      setReleaseAllInvoices(false);
    }
  };

  const handleCleanSearch = () => {
    setCustomerSelected(null);
    searchFormFieldsGroup.cleanFormData();
  };

  const handleCleanFilters = () => {
    filterFormFieldsGroup.cleanFormData();
    if (viewCurrentYear) toggleViewCurrentYear();
    if (viewCreditOnly) toggleViewCreditOnly();
  };

  const validateRowsBeforeDelete = (rowsToDelete: any) => {
    let rowsCanBeDeleted = true;
    if (hasSuccessInvoices(rowsToDelete)) {
      setNotification({
        text: 'Invoices with status "SUCCESS" can not be deleted.',
        type: MessageBarType.error,
      });
      rowsCanBeDeleted = false;
    };
    if (hasInvoicesCreatedByMidas(rowsToDelete)) {
      setNotification({
        text: 'Invoices created by MIDAS can not be deleted.',
        type: MessageBarType.error,
      });
      rowsCanBeDeleted = false;
    };
    return rowsCanBeDeleted;
  };

  const removeEmpty = ({ text }: any) => text !== null;

  const getCustomerPrefixList = useMemo(() => customersList ?
    customersList.map(({ id, prefix }) => ({
      key: id,
      text: prefix,
    })).filter(removeEmpty) : [], [customersList]);


  const getCustomerNameList = useMemo(() => customersList ?
    customersList.map(({ id, customerName }) => ({
      key: id,
      text: customerName,
    })).filter(removeEmpty) : [], [customersList]);

  const customerPrefixInputText = (custPrefix: string) => {
    if (custPrefix.replace(/ /g, '').length) {
      getCustomersDebounce({ custPrefix });
    }
  };

  const customerNameInputText = (custName: string) => {
    if (custName.replace(/ /g, '').length) {
      getCustomersDebounce({ custName }, { column: 'customerName', order: 'asc' });
    }
  };

  const getCustomersDebounce = debounce(async (customerData, sortOrder?) => {
    fetchCustomers(customerData, undefined, sortOrder);
  }, 500);

  const onCustomerChange = async (newCustomerId: any) => {
    setCustomerSelected(getCustomerById(newCustomerId));
  };

  const handleCountryChange = (event: any, item: IDropdownOption<any> | undefined): void => {
    if (item?.text) setCountrySelected(item?.text);
  };

  const onFilterChange = ({ target: { id } }: any, item: IDropdownOption<any> | undefined): void => {
    filterFormFieldsGroup.setFormValue(`${id}Field`, item?.text);
  };

  const onSearchChange = ({ target: { id } }: any, item: IDropdownOption<any> | undefined): void => {
    searchFormFieldsGroup.setFormValue(`${id}Field`, item?.text);
  };

  const onSubmitClicked = async (functionString: string | undefined) => {
    toggleShowLoadingAvailableTable();
    toggleDialogComponent();
    toggleReloadControlsTable();
    await fetchReleaseAll();
  };

  const onLogsClicked = async () => {
    toggleLogsGrid();
  };

  const fetchReleaseAll = async () => {
    const currentYear = viewCurrentYear ? viewCurrentYear : null;
    const duringTheMonth = currentYear ? null : filterFormFieldsGroup.getFieldFormValue('monthOfField');
    const invoicesIds = getIdsFromInvoices(availableInvoicesSelected);

    try {
      const { data: { message } }: any = await apiService.billingAPI.bulkRelease(
        customSearchOption == 'customerSearch' ? customerSelected?.id : null,
        customSearchOption == 'regionSearch' ? searchFormFieldsGroup.getFieldFormValue('regionField') : null,
        countrySelected,
        releaseAllInvoices ? [] : invoicesIds,
        filterFormFieldsGroup.getFieldFormValue('invoiceTypeField'),
        currentYear,
        viewCreditOnly,
        duringTheMonth,
        releaseAllInvoices ? true : false,
        !releaseAllInvoices ? true : false,
      );

      if (runningStatus == 'IDLE') {
        setNotification({ text: message, type: MessageBarType.success });
      }

      await fetchStatus();
    } catch (e: any) {
      setNotification({ text: 'Error changing the state of the selected invoices', type: MessageBarType.error });
    }
  };

  const fetchStatus = async () => {
    let timeOut = null;
    const currentYear = viewCurrentYear ? viewCurrentYear : null;
    const duringTheMonth = currentYear ? null : filterFormFieldsGroup.getFieldFormValue('monthOfField');
    const invoicesIds = getIdsFromInvoices(availableInvoicesSelected);
    console.log(releaseAllInvoices);
    
    try {
      const { data: { runStatus }}: any = await apiService.billingAPI.bulkReleaseStatus(
        customSearchOption == 'customerSearch' ? customerSelected?.id : null,
        customSearchOption == 'regionSearch' ? searchFormFieldsGroup.getFieldFormValue('regionField') : null,
        countrySelected,
        releaseAllInvoices ? [] : invoicesIds,
        filterFormFieldsGroup.getFieldFormValue('invoiceTypeField'),
        currentYear,
        viewCreditOnly,
        duringTheMonth,
        releaseAllInvoices ? true : false,
        !releaseAllInvoices ? true : false,
      );

      setSubmittedInvoices(invoicesIds);

      if (timeOut) clearInterval(timeOut);
      if (runStatus == 'RUNNING') timeOut= setTimeout(() => fetchStatus(), 10000);

      setRunningStatus(runStatus);
    } catch (e: any) {
      setNotification({ text: 'Error fetching status', type: MessageBarType.error });
    }
    finally {
      await fetchAvailableInvoices(undefined, customFilters);
      toggleShowLoadingAvailableTable();
      toggleReloadControlsTable();
    }
  };

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  useEffect(() => {
    if (countrySelected) fetchRegionCodes();
  }, [countrySelected]);

  useEffect(() => {
    if (!countries?.length) fetchCountries();
    if (!invoiceTypes?.length) fetchInvoiceTypes();
  }, []);

  const getCustomerById = (idToFind: number) => customersList?.find(({ id }) => id === idToFind) || null;
  const getIdsFromInvoices = (invoicesArray: Array<IAvailableInvoice>) => invoicesArray?.map((invoice: any) => invoice.invoiceId);
  const hasSuccessInvoices = (invoicesArray: Array<IAvailableInvoice>) => invoicesArray?.find((invoice: any) => invoice.status == 'SUCCESS') != undefined;
  const hasInvoicesCreatedByMidas = (invoicesArray: Array<IAvailableInvoice>) => invoicesArray?.find((invoice: any) => invoice.createdBy == 'MIDAS') != undefined;

  const enableSearchButton =
    (customSearchOption == 'regionSearch' && searchFormFieldsGroup.getFieldFormValue('regionField')) ||
    (customSearchOption == 'customerSearch' && customerSelected) ||
    (customSearchOption == 'SAPSearch' && searchFormFieldsGroup.getFieldFormValue('sapInvoiceNoField'));

  const enableFilterButton =
    viewCreditOnly || viewCurrentYear ||
    filterFormFieldsGroup.getFieldFormValue('monthOfField') ||
    filterFormFieldsGroup.getFieldFormValue('currentYearField') ||
    filterFormFieldsGroup.getFieldFormValue('statusField') ||
    filterFormFieldsGroup.getFieldFormValue('invoiceTypeField');

  const validateFetchAvailableInvoices = enableSearchButton || enableFilterButton;

  const disableReleaseBtn = (!userPermissions.isWrite || (!releaseAllInvoices && availableInvoicesSelected.length == 0) || runningStatus == 'RUNNING');
  const disableLogsBtn = (!userPermissions.isWrite || runningStatus == 'RUNNING');


  return (
    <>
      <div className='ms-grid'>
        <div className='ms-Grid-row'>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
            <Text variant='xxLarge' className={styles.highlight}>Bulk Release</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm11', styles.marginTop20)}>
            <Text variant='xLarge' className={styles.highlight}>Search Pending Invoices</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm1', styles.marginTop20)}>
            <Text variant='mediumPlus' className={styles.highlight}>STATUS: { runningStatus } </Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <div className={classNames('ms-Grid-col', 'ms-sm3', styles.marginTop20)}>
              <Dropdown
                label='Country'
                options={countries.map(country => ({
                  key: country,
                  text: country,
                }))}
                selectedKey={countrySelected}
                onChange={handleCountryChange}
                disabled={runningStatus == 'RUNNING'}
              />
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm9', styles.customerDetailsContainer)}>
              <SeparatorGy />
            </div>
          </div>

          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
            <div className={classNames('ms-Grid-col', 'ms-sm3', styles.searchOptionContainer)}>
              <div className={classNames('ms-Grid-col', 'ms-sm12', styles.customerSearchOption_title)}>
                <Checkbox
                  onChange={() => handleChangeCustomSearch('regionSearch')}
                  checked={customSearchOption == 'regionSearch'}
                  disabled={runningStatus == 'RUNNING'}
                />
                <Text variant='mediumPlus' className={styles.highlight}>Search by Region</Text>
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                <Dropdown
                  {...searchFormFieldsGroup.getFieldForm('regionField')}
                  placeholder='Select a region'
                  options={regionCodes.map(region => ({
                    key: region,
                    text: region,
                  }))}
                  onChange={onSearchChange}
                  selectedKey={searchFormFieldsGroup.getFieldFormValue('regionField')}
                  disabled={customSearchOption != 'regionSearch' || runningStatus == 'RUNNING'}
                />
              </div>
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm4', styles.searchOptionContainer)}>
              <div className={classNames('ms-Grid-col', 'ms-sm12', styles.customerSearchOption_title)}>
                <Checkbox
                  onChange={() => handleChangeCustomSearch('customerSearch')}
                  checked={customSearchOption == 'customerSearch'}
                  disabled={runningStatus == 'RUNNING'}
                />
                <Text variant='mediumPlus' className={styles.highlight}>Search by Customer</Text>
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                <AutoCompleteField
                  onChangeAction={onCustomerChange}
                  value={customerSelected?.id}
                  list={getCustomerPrefixList}
                  label="Customer Prefix"
                  data-testid='customer-prefix'
                  icon="FavoriteStar"
                  textValue={customerPrefixInputText}
                  isLoading={isLoadingFetchCustomers}
                  disabled={customSearchOption != 'customerSearch' || runningStatus == 'RUNNING'}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                <AutoCompleteField
                  onChangeAction={onCustomerChange}
                  value={customerSelected?.id}
                  list={getCustomerNameList}
                  label="Customer Name"
                  data-testid='customer-name'
                  icon="Contact"
                  textValue={customerNameInputText}
                  isLoading={isLoadingFetchCustomers}
                  disabled={customSearchOption != 'customerSearch' || runningStatus == 'RUNNING'}
                />
              </div>
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm2', styles.searchBtnContainer)}>
              <PrimaryButton
                id="searchBtn"
                text="Search"
                onClick={handleSearch}
                disabled={!enableSearchButton || runningStatus == 'RUNNING'}
              />
              <DefaultButton onClick={handleCleanSearch} text="Clear Search" disabled = {runningStatus == 'RUNNING'}/>
            </div>
          </div>
        </div>
      </div>

      <SeparatorGy />

      <div className='ms-grid'>
        <div className='ms-Grid-row'>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
            <img className={classNames(styles.filterIcon)} src={filterIcon} width="18px" />
            <Text variant='xLarge' className={styles.highlight}>Filter Search</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
            <div className={classNames('ms-Grid-col', 'ms-sm3', styles.searchOptionContainer)}>
              <div className={classNames('ms-Grid-col', 'ms-sm8')}>
                <DatePicker
                  {...filterFormFieldsGroup.getFieldForm('monthOfField')}
                  placeholder={'Select a month...'}
                  calendarProps={{
                    highlightSelectedMonth: true,
                    isDayPickerVisible: false,
                    dateRangeType: DateRangeType.Month,
                  }}
                  value={filterFormFieldsGroup.getFieldFormValue('monthOfField') ?
                    moment(filterFormFieldsGroup.getFieldFormValue('monthOfField')).toDate() : undefined}
                  onSelectDate={date => filterFormFieldsGroup.setFormValue('monthOfField', date)}
                  formatDate={(date: any) => moment(date).format('MM/YY')}
                  disabled = {runningStatus == 'RUNNING'}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm4', styles.checkBoxContainer)}>
                <Checkbox
                  {...filterFormFieldsGroup.getFieldForm('currentYearField')}
                  onChange={toggleViewCurrentYear}
                  checked={viewCurrentYear}
                  disabled = {runningStatus == 'RUNNING'}
                />
              </div>
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm4', styles.searchOptionContainer)}>
              <div className={classNames('ms-Grid-col', 'ms-sm8')}>
                <Dropdown
                  {...filterFormFieldsGroup.getFieldForm('invoiceTypeField')}
                  options={invoiceTypes?.map(invoiceType => ({
                    key: invoiceType,
                    text: invoiceType,
                  }))}
                  onChange={onFilterChange}
                  selectedKey={filterFormFieldsGroup.getFieldFormValue('invoiceTypeField')}
                  placeHolder='Select a Invoice type...'
                  disabled = {runningStatus == 'RUNNING'}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm4', styles.checkBoxContainer)}>
                <Checkbox
                  {...filterFormFieldsGroup.getFieldForm('creditOnlyField')}
                  onChange={toggleViewCreditOnly}
                  checked={viewCreditOnly}
                  disabled = {runningStatus == 'RUNNING'}
                />
              </div>
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm2', styles.filterBtnContainer)}>
              <PrimaryButton
                id="filterBtn"
                text="Apply Filters"
                onClick={handleFilterInvoices}
                disabled={!enableFilterButton || runningStatus == 'RUNNING'}
              />
              <DefaultButton onClick={handleCleanFilters} text="Clear Filters" disabled = {runningStatus == 'RUNNING'} />
            </div>
          </div>
        </div>
        <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop10)}>
          <div className={classNames('ms-Grid-col', 'ms-sm3', styles.filterSearchOption)}>
            <Checkbox
              onChange={() => handleCheckboxSelection('releaseAll')}
              checked={selectedCheckBoxOption == 'releaseAll'}
              disabled={availableInvoicesList.length === 0 || runningStatus == 'RUNNING'}
            />
            <Text variant='mediumPlus' className={styles.highlight}>Release All</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm9', styles.filterSearchOption)}>
            <Checkbox
              onChange={() => handleCheckboxSelection('byViewSelection')}
              checked={selectedCheckBoxOption == 'byViewSelection'}
              disabled={availableInvoicesList.length === 0 || runningStatus == 'RUNNING'}
            />
            <Text variant='mediumPlus' className={styles.highlight}>By View Selection</Text>
          </div>
        </div>
      </div>

      <div className='ms-grid'>
        <div className='ms-Grid-row'>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
            <DataGridComponent
              idTable={'available-invoices-table'}
              title='Available Invoices'
              headCells={availableInvoicesHeadCell}
              rowsTable={availableInvoicesList}
              totalDataFound={totalFound || 0}
              actionsGroupName={ACTION_GROUPS_ENUM.VIEW_INVOICES}
              isLoading={isLoadingAvailableTable}
              enableCheckBox={!releaseAllInvoices}
              enablePagination
              enableRowsPerPage
              enableManageHeadCells
              enableResizeColumns
              enableDeleteOption={userPermissions.isWrite}
              enableMultiSelectRow
              handleChangeDataGridState={handleChangeDataGridState}
              handleDelete={handleDeleteInvoices}
              handleSelectRow={handleSelectRow}
              enableLeftCheckbox
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <div className={styles.buttonContainer}>
              <PrimaryButton text='Release' id='releaseBtn' disabled={disableReleaseBtn}
                onClick={() => {
                  setSelectedAction(ReleaseAction);
                  toggleDialogComponent();
                }} />
              <PrimaryButton
                text="Logs"
                onClick={() => {onLogsClicked();}}
                disabled={disableLogsBtn}
              />
            </div>
          </div>
        </div>

        {userPermissions.isWrite && <LogsModal
          isOpened={showLogs}
          onClose={toggleLogsGrid}
          customerId={customSearchOption == 'customerSearch' ? customerSelected?.id : null}
          regionCode={customSearchOption == 'regionSearch' ? searchFormFieldsGroup.getFieldFormValue('regionField') : null}
          country={countrySelected}
          ids={releaseAllInvoices ? [] : submittedInvoices}
          invoiceType={filterFormFieldsGroup.getFieldFormValue('invoiceTypeField')}
          currentYear={viewCurrentYear ? viewCurrentYear : null}
          creditOnly={viewCreditOnly}
          duringTheMonth={viewCurrentYear ? null : filterFormFieldsGroup.getFieldFormValue('monthOfField')}
          releaseAll={releaseAllInvoices ? true : false}
          byViewSelection={!releaseAllInvoices ? true : false}
        />}
      </div>

      <DialogComponent
        isOpen={isDialogComponentOpen}
        onCancel={toggleDialogComponent}
        onSubmit={() => onSubmitClicked(selectedAction.keyword)}
        title={'Confirmation'}
        subText={selectedAction.text}
        onSubmitLabel={'Accept'}
        onCancelLabel={'Cancel'}
      />
    </>
  );
};

export default BulkReleaseComponent;