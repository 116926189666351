import { DefaultButton, IconButton, MessageBarType, Modal, PrimaryButton, Spinner, SpinnerSize, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { FunctionComponent, useEffect, useState } from 'react';
import apiService from '../../../../../../../../api';
import { downloadFile, printingTypes } from '../../../../../../../PrintingModal/consts';
import PrintingModal from '../../../../../../../PrintingModal/PrintingModal';
import { INotification } from '../../../../../../../../models/INotification';
import DataGridComponent from '../../../../../../../../shared/DataGridComponent';
import { costReportHeadCell, dataGridPageSizes } from './consts';
import styles from './CostReport.module.scss';
import CostSummaryReport from './CostReportSummary';
import { isEmpty, isNil } from 'lodash';
import { ISorting } from '../../../../../../../../shared/DataGridComponent/DataGridModels';

interface CostReportModalComponentProps {
  isOpened: boolean,
  onClose: () => void,
  period: string,
  customerId: string,
  locationId: string,
}

const CostReportModal: FunctionComponent<CostReportModalComponentProps> = ({
  isOpened,
  onClose,
  period,
  customerId,
  locationId,
}) => {

  const [costReportDetails, setCostReportDetails] = useState<any>();
  const [costReportList, setCostReportList] = useState<Array<any>>([]);
  const [costSummaryReportDetails, setCostSummaryReportDetails] = useState<any>();
  const [totalFound, setTotalFound] = useState(0);
  const [costReportPagination, setCostReportPagination] = useState({ pageSize: dataGridPageSizes[1], pageNumber: 1 });
  const [costReportSortOrder, setCostReportSortOrder] = useState({ column: costReportHeadCell[1].fieldName, order: 'asc' });
  const defaultSorting: ISorting[] = [{column: costReportHeadCell[1].fieldName, order: 'asc'}];
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });
  const [dataGridState, setDataGridState] = useState<any>({});
  const [runningStatus, setRunningStatus] = useState<string>('IDLE');

  const [isLoadingFetchCostReportDetails, { toggle: toggleIsLoadingFetchCostReportDetails }] = useBoolean(false);
  const [showLoadingFetchCostReport, { toggle: toggleShowLoadingFetchCostReport }] = useBoolean(false);
  const [isLoadingInitialData, { toggle: toggleShowLoadingAvailableInitialData }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  
  const fetchCostReportDetails = async () => {
    try {
      const { data }: any = await apiService.spareStock.getCostReportDetails(
        customerId,
        locationId,
        period,
      );
      setCostReportDetails(data);
    } catch (error) {
      setNotification({ text: 'Fetching tires error', type: MessageBarType.error });
    }
  };

  const fetchCostReport = async (
    pagination: any = costReportPagination,
    sortOrder: any = costReportSortOrder,
  ) => {
    try {
      setDataGridState({ costReportPagination, costReportSortOrder });
      const { data }: any = await apiService.spareStock.getCostReportList(
        pagination,
        sortOrder,
        customerId,
        locationId,
        period,
      );
      setCostReportList(data.data);
      setTotalFound(data?.total?.found);
      setCostReportPagination(costReportPagination);
      setCostReportSortOrder(costReportSortOrder);

    } catch (error) {
      setNotification({ text: 'Fetching tires error', type: MessageBarType.error });
    }
  };

  const fetchCostSummaryDetails = async () => {
    toggleIsLoadingFetchCostReportDetails();
    try {
      const { data }: any = await apiService.spareStock.getCostSummaryReportDetails(costReportPagination, costReportSortOrder, customerId, locationId, period);
      setCostSummaryReportDetails(data);
    } catch (e: any) {
      setNotification({ text: 'Cost summary details fetching error.', type: MessageBarType.error });
    }
    toggleIsLoadingFetchCostReportDetails();
  };

  const fetchGenerateCostReport = async () => {
    try {
      const { data: { message } }: any = await apiService.spareStock.generateNewCostReport(
        customerId,
        locationId,
        period,
      );

      await fetchStatus();
      if (runningStatus == 'IDLE') {
        setNotification({ text: message, type: MessageBarType.success });
      }
    }
    catch (e: any) {
      setNotification({ text: 'Error on report generation', type: MessageBarType.error });
    }
  };

  const fetchStatus = async () => {
    let timeOut = null;
    try {
      const { data: { runStatus } }: any = await apiService.spareStock.generateNewCostReportStatus(
        customerId,
        locationId,
        period,
      );

      if (timeOut) clearInterval(timeOut);
      if (runStatus == 'RUNNING') timeOut = setTimeout(() => fetchStatus(), 10000);

      setRunningStatus(runStatus);
    } catch (e: any) {
      setNotification({ text: 'Error fetching status', type: MessageBarType.error });
    }
    finally {
      await fetchCostReport();
      await fetchCostReportDetails();
      await fetchCostSummaryDetails();
    }
  };

  const setInitialState = () => {
    setCostReportList([]);
    if (runningStatus) {
      setRunningStatus('IDLE');
    }
  };

  const handleCloseModal = () => {
    setInitialState();
    onClose();
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    if (!isOpened) return;
    const { countOnPage, paginationProps, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    toggleShowLoadingFetchCostReport();
    setDataGridState({ pagination, sortOrder });
    await fetchCostReport(pagination, sortOrder);
    toggleShowLoadingFetchCostReport();
  };

  const handleGenerate = async () => {
    fetchGenerateCostReport();
  };

  const handlePrint = async (printingType: any) => {
    toggleShowPrintExport();
    toggleShowLoadingAvailableInitialData();
    try {
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.spareStock.downloadAvailableCostReport({reportId: costReportDetails.costReportNo, reportName: costReportDetails.reportName, userId: 'MIDAS'}) :
        await apiService.spareStock.downloadAvailableCostReport({reportId: costReportDetails.costReportNo, reportName: costReportDetails.reportName, userId: 'MIDAS'});

      downloadFile(data, printingTypes.excel);
      setNotification({ text: 'File was successfully received.', type: MessageBarType.success });
    } catch (e: any) {
      const { response } = e;
      setNotification({ text: `Printing error: ${response.data.message}`, type: MessageBarType.error });
    } finally {
      toggleShowLoadingAvailableInitialData();
    };
  };

  useEffect(() => {
    if (!isOpened) return;
    fetchCostReport();
    fetchCostReportDetails();
    fetchCostSummaryDetails();
  }, [isOpened]);

  return (
    <>
      <Modal
        isOpen={isOpened}
        onDismiss={handleCloseModal}
        containerClassName={styles.modalContainer}
      >
        <div className={styles.modalBody}>
          <div className="modalHeader">
            <Text variant='xLarge' className={styles.highlight} > {'COST VALUE REPORT'}</Text>
          </div>

          <div className={classNames('ms-grid', styles.marginBottom20)} dir='ltr'>
            <div className={classNames('ms-Grid-row')}>
              <div className={classNames('ms-Grid-col', 'ms-sm11')}>
                <Text variant='large'> {'Cost Report Name: '} {costReportDetails?.reportName}</Text>
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm1')}>
                <Text variant='medium' className={styles.highlight} > STATUS: {runningStatus} </Text>
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                <Text variant='large' > {'Inventory Taken On: '} {isNil(costReportDetails?.reportName) ? '' : period}</Text>
              </div>
            </div>
          </div>

          <div className='modalHeader'>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel='Close popup modal'
              onClick={handleCloseModal}
            />
          </div>
          {isLoadingInitialData ? <Spinner size={SpinnerSize.large} className={styles.spinner} /> :
            <>
              <div className={classNames('ms-grid', styles.marginBottom20)} dir='ltr'>
                <div className={classNames('ms-Grid-row')}>
                  <div className={classNames('ms-Grid-col', 'ms-sm9', styles.marginTop5)}>
                    <DataGridComponent
                      idTable={'cost-report-tires'}
                      title='Tires'
                      headCells={costReportHeadCell}
                      rowsTable={costReportList}
                      totalDataFound={totalFound}
                      isLoading={showLoadingFetchCostReport}
                      enablePagination
                      enableRowsPerPage
                      enableRefreshOption
                      enableResizeColumns
                      handleChangeDataGridState={handleChangeDataGridState}
                      defaultSorting={defaultSorting}
                      defaultRowsPerPage={dataGridPageSizes}
                    />
                  </div>

                  <div className={classNames('ms-Grid-col', 'ms-sm3', styles.marginTop30)}>
                    <CostSummaryReport
                      isLoading={isLoadingFetchCostReportDetails}
                      costReportDetails={costSummaryReportDetails}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.modalFooter}>
                <PrimaryButton
                  id='generateBtn'
                  text={isEmpty(costReportList) ? 'Generate' : 'Regenerate'}
                  disabled={runningStatus == 'RUNNING'}
                  onClick={handleGenerate}
                />
                {!isNil(costReportDetails?.reportFile) && <DefaultButton
                  id='printExportBtn'
                  text='Print/Export'
                  disabled={runningStatus == 'RUNNING'}
                  onClick={toggleShowPrintExport}
                />}

                <PrintingModal
                  isOpened={showPrintExport}
                  onClose={toggleShowPrintExport}
                  onPrint={handlePrint}
                  isFileTypeExcel={true}
                />
              </div>
            </>}
        </div>

      </Modal>
    </>
  );
};

export default CostReportModal;