import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import moment from 'moment';
import { debounce, isNil } from 'lodash';
import {
  Checkbox,
  DatePicker,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  MaskedTextField,
  MessageBarType,
  PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';
import { useSelector } from 'react-redux';

import apiService from '../../../../../../api';
import useNotifications from '../../../../../../hooks/useNotifications';
import AutocompleteInput from '../../../../../../shared/AutocompleteInput';
import { auth_tireChange_deleteButton, auth_tireChange_postByFootprint } from '../../../../../../consts/programKeys';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { customerSelector, locationSelector } from '../../../../../../redux/recordKeepingSlice';

import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import ErrorsModal from '../../../../../ErrorsModal/ErrorsModal';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';

import { IPostByFootprintState } from './IPostByFootprintState';
import { IPostByFootprintProps } from './IPostByFootprintProps';
import { columns, emptyMakeModel } from './consts';

import styles from './PostByFootprint.module.scss';
import { transformDate } from '../../../../../../shared/transformDate';
import useKeyPress from '../../../../../../hooks/useKeyPress/useKeyPress';

const PostByFootprint = (props: IPostByFootprintProps) => {

  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);

  const [state, setState] = useState<IPostByFootprintState>({
    items: [],
    selectedItems: [],
    loading: false,
    foundCount: 0,
  });

  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [viewErrors, { toggle: toggleViewErrors }] = useBoolean(false);
  const [useDefaults, { toggle: toggleUseDefaults }] = useBoolean(false);
  const [defaults, setDefaults] = useState<any>({ pfx: '', date: null });
  const [parsedErrors, setParsedErrors] = useState<any>([]);
  const [editedRowId, setEditedRowId] = useState<number>(-1);
  const [makeModel, setMakeModel] = useState(emptyMakeModel);
  const [shouldSelectEditedRow, setShouldSelectEditedRow] = useState<boolean>(false); 

  const [selectedVehicleNumber, setSelectedVehicleNumber] = useState<any>('');
  const [vehicleNumbersList, setVehicleNumbersList] = useState<any[]>([]);
  const [printVehicleNumber, setPrintVehicleNumber] = useState<any>();

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_tireChange_postByFootprint);
  const deletePermissions = hasPermission(auth_tireChange_deleteButton);
  const onChangeDefaultsPrefix = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, pfx: string | undefined) => {
    setDefaults((prev: any) => ({ ...prev, pfx: pfx?.toUpperCase() }));
  };

  const updateRowsData = (currentState: any, field: string, value: string | undefined) => {
    return currentState.map((item: { [x: string]: string | undefined; id: any; }) => {
      if (editedRowId === item.id) {
        item[field] = value;
        if (useDefaults) {
          if (field == 'cot' && value == 'Y') {
            item.onPrefix = '';
            if (!isNil(defaults.date)) {
              item.changeDate = moment(defaults.date).format('MM/DD/YYYY');
            }
          }
          if (field == 'onBrand' && value != '') {
            item.onPrefix = defaults.pfx;
            if (!isNil(defaults.date)) {
              item.changeDate = moment(defaults.date).format('MM/DD/YYYY');
            }
          } else if (field == 'onBrand' && value == '') {
            item.onPrefix = '';
            item.changeDate = '';
          }
        }
      }
      return item;
    });
  };

  const setOnPrefixTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, onPrefix: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'onPrefix', onPrefix?.toUpperCase()) }));
  };
  const setOnBrandTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, onBrand: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'onBrand', onBrand?.toUpperCase()) }));
  };
  const setOnSuffixTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, onSuffix: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'onSuffix', typeof onSuffix === 'string' && !onSuffix.length ? undefined : onSuffix?.toUpperCase()) }));
  };
  const setCotTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, cot: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'cot', cot?.toUpperCase()) }));
  };
  const setChgDateTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, changeDate: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'changeDate', changeDate?.toUpperCase()) }));
    setShouldSelectEditedRow(true);
  };

  const onBlurChgDate = (itemId: string) => {
    if(shouldSelectEditedRow)
      setState((prev: any) => ({ ...prev, selectedItems: [...state.selectedItems, itemId] }));
    setShouldSelectEditedRow(false);
  };

  const renderLargeLabel = (props: any) => (
    <Text variant="large" className={styles.highlight}>{props.label}</Text>
  );

  const handleSubmit = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const selectedItems = state.items.filter(({ id }) => state.selectedItems.includes(id));
      await apiService.postByFootprint.submit(
        selectedItems.map(item => ({
          ...item, changeDate: /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/gm.test(item.changeDate) ? transformDate(item.changeDate) : item.changeDate,
          cot: item.cot == 'Y' ? true : item.cot == 'N' ? false : undefined,
        })),
        customerId,
        locationId,
      );
      addNotification({
        text: 'Post By Footprint tires were successfully submitted.',
        type: MessageBarType.success,
      });
      await fetchPostByFootprint();
    } catch (e: any) {
      const { response } = e;
      setState(prev => ({ ...prev, loading: false }));
      switch (response.status) {
        case 400:
        case 499:
          setParsedErrors(response.data.state);
          await fetchPostByFootprint();
          return addNotification({
            text: `Post By Footprint submitting error: ${response.data.state[0].message}`,
            type: MessageBarType.error,
          });
         
        default:
          await fetchPostByFootprint();
          addNotification({
            text: `Post By Footprint submitting error: ${response.data.message}`,
            type: MessageBarType.error,
          });
        
      }
    }
  };
  const handleSave = async () => {
    try {
      const itemsForSave: Array<any> = [];
      state.selectedItems.map(selectedItem => {
        itemsForSave.push(state.items.find(item => item.id == selectedItem));
      });
      setState(prev => ({ ...prev, loading: true }));
      await apiService.postByFootprint.save(itemsForSave.map(item => ({
        ...item, changeDate: /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/gm.test(item.changeDate) ? transformDate(item.changeDate) : item.changeDate,
        cot: item.cot == 'Y' ? true : item.cot == 'N' ? false : undefined,
      })),
      customerId,
      locationId);
      addNotification({
        text: 'Post By Footprint tires were successfully saved.',
        type: MessageBarType.success,
      });
      await fetchPostByFootprint();
      setParsedErrors([]);
    } catch (e: any) {
      const { response } = e;
      setState(prev => ({ ...prev, loading: false }));
      switch (response.status) {
        case 400:
        case 499:
          setParsedErrors(response.data.state);
          return addNotification({
            text: 'Unable to save changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Saving error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    }
  };

  const fetchPostByFootprint = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.postByFootprint.get(
        { pageNumber: 1, pageSize: 20 },
        null,
        customerId,
        locationId,
        selectedVehicleNumber,
      );
      const foundCount = data.total.found;
      const items = data.data.map((item: any) => ({ ...item, cot: item.cot ? 'Y' : 'N', changeDate: moment(item.changeDate).format('MM/DD/YYYY') }));
      setMakeModel(data.vehicle);
      setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      setShouldSelectEditedRow(false);
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 499:
          setMakeModel(emptyMakeModel);
          setState((prev: any) => ({ ...prev, selectedItems: [] }));
          return addNotification({
            text: 'Nothing was found with current vehicle number',
            type: MessageBarType.error,
          });
        case 400:
          setMakeModel(emptyMakeModel);
          setState((prev: any) => ({ ...prev, selectedItems: [] }));
          return addNotification({
            text: `${response.data.state.map((el: any) => el?.message).join('\n')}`,
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Post By Footprint fetching error: ${e?.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    try {
      const currentState = [...state.items];
      state.selectedItems.map(selectedItem => {
        currentState.map(item => {
          if (item.id == selectedItem) {
            item.onPrefix = '';
            item.onBrand = '';
            item.onSuffix = '';
            item.cot = '';
            item.changeDate = '';
          }
          return item;
        });
      });
      setState(prev => ({ ...prev, items: currentState, selectedItems: [] }));
      addNotification({
        text: 'Selected tire(s) were successfully deleted.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      setState(prev => ({ ...prev, loading: false }));
      addNotification({
        text: `Post By Footprint deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const requestData = {
        pagination: { pageNumber: 1, pageSize: 20 },
        sortOrder: null,
        customerId,
        locationId,
        filters: {
          vehicleNo: printVehicleNumber,
        },
      };
      const headerFields = [
        { title: 'vehicle #', value: selectedVehicleNumber },
      ];

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.postByFootprint.printExcel(requestData, headerFields) :
        await apiService.postByFootprint.printPdf(requestData, headerFields);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      const decodedData = JSON.parse(new TextDecoder().decode(new Uint8Array(response.data)));
      addNotification({
        text: `Printing error: ${decodedData?.state[0]?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSelect = (e: any, itemId: any) => {
    if (e.target.checked) {
      setState((prev: any) => ({ ...prev, selectedItems: [...state.selectedItems, itemId] }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: state.selectedItems.filter(row => row !== itemId) }));
    }
  };

  const handleSelectAll = (e: any, items: Array<any>) => {
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedItems: items })) : setState((prev: any) => ({ ...prev, selectedItems: [] }));
  };

  const parseErrors = (id: string | null, field: string) => {
    const customError = parsedErrors?.filter((error: { id: string; field: string; }) => error.id === id && error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  const highlightRowIfError = (item: any) => {
    if (parsedErrors.length > 0) {
      return parsedErrors.filter((error: { id: any; }) => error.id == item.id).length > 0;
    }
  };

  const vehicleNumberInputText = (vehicleNuber: string) => setPrintVehicleNumber(vehicleNuber.replace(/ /g, ''));

  const delayedFetchVehicles = useCallback(debounce(vehicleNumber => fetchVehicles(vehicleNumber), 1000), []);

  const fetchVehicles = async (vehicleNumber: string) => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data }: any = await apiService.getActiveVehicleNumbers(vehicleNumber, customerId);
      setVehicleNumbersList(data.data);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Vehicles fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleFetchErrors = (pagination: any, sortOrder: any, customerId: string, locationId: string) => 
    apiService.postByFootprint.getErrors(pagination, sortOrder, customerId, locationId, selectedVehicleNumber);

  useKeyPress({
    handleSubmit: handleSubmit,
    handleAdd: handleSave,
  });

  useEffect(() => {
    if (selectedVehicleNumber != '') {
      fetchPostByFootprint();
    }
  }, [
    selectedVehicleNumber,
  ]);

  useEffect(() => {
    setSelectedVehicleNumber('');
    setState((prev: any) => ({ ...prev, items: [], foundCount: 0, selectedItems: [] }));
  }, [
    customerId,
    locationId,
  ]);

  useEffect(() => {
    if (printVehicleNumber?.length > 0) {
      delayedFetchVehicles(printVehicleNumber);
    }
  }, [printVehicleNumber]);

  return (
    <div>
      <div className="ms-Grid">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <div className={styles.tableHeading}>
              <div>
                <Text variant="xLarge" className={styles.highlight}>Posted Tire Changes</Text>
                <SeparatorGy vertical />
                <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>

              </div>
              <div>
                <div className={styles.refreshButton}>
                  <IconButton
                    id="refreshButton"
                    iconProps={{ iconName: 'Refresh' }} 
                    onClick={fetchPostByFootprint} 
                    height={100}
                    width={100}
                    disabled={!selectedVehicleNumber}
                  />
                  <SeparatorGy vertical />
                </div>
                <AutocompleteInput
                  label="Vehicle #"
                  value={selectedVehicleNumber}
                  list={
                    vehicleNumbersList ?
                      vehicleNumbersList.map((vehicle: any) => ({
                        key: vehicle.number,
                        text: vehicle.name,
                      })) : []
                  }
                  chooseCurrentItem={(vehicle) => setSelectedVehicleNumber(vehicle)}
                  required
                  emptyExpanded
                  textValue={vehicleNumberInputText}
                />
                <div className={styles.makeModel}>
                  <div>
                    <Text variant="mediumPlus" className={styles.highlight}>Make/Model</Text>
                  </div>
                  <div>
                    <Text variant="xLarge" className={styles.highlight}>{makeModel.make}/{makeModel.model}</Text>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.tableHeading}>
              <div className={styles.useDefaults}>
                <Checkbox id="useDefaults" label="Use Defaults:" onRenderLabel={renderLargeLabel} checked={useDefaults} onChange={toggleUseDefaults} />
                <div>
                  <TextField id='prefixDefault' label="Ctrl Pfx" value={defaults.pfx} onChange={onChangeDefaultsPrefix} />
                  <DatePicker
                    id="dateDefault"
                    showMonthPickerAsOverlay={true}
                    label="MM/DD/YY"
                    value={defaults.date}
                    onSelectDate={(date: Date | null | undefined) => setDefaults((prev: any) => ({ ...prev, date }))}
                    formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                  />
                </div>
              </div>
              <div>
                <Text variant="large" className={styles.highlight}>{state.selectedItems.length} items selected</Text>
                <SeparatorGy vertical />
                <IconButton
                  id="toggleDeletingConfirmationButton"
                  disabled={!(state.selectedItems.length && userPermissions.isWrite && deletePermissions.isWrite)}
                  iconProps={{ iconName: 'Delete' }}
                  onClick={toggleDeletingConfirmation}
                />
              </div>
            </div>
            <div className={styles['table-wrapper']}>
              <table className={styles.table} >
                <thead>
                  <tr>
                    {
                      columns.map(item => (
                        <th key={item.name} className={styles[item.key]}>{item.name}</th>
                      ))
                    }
                    <th>
                      <div className={styles.round}>
                        <input type="checkbox" id="all" checked={state.items.length !== 0 &&
                          (state.selectedItems.length === state.items.length)} onChange={(e) => handleSelectAll(e, state.items.map(item => item.id))} />
                        <label htmlFor="all"></label>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    state.items.map(item => (
                      <tr
                        key={item.id}
                        className={classNames(state.selectedItems.includes(item.id) ? styles.trSelected : styles.trBasic,
                          highlightRowIfError(item) ? styles.trError : styles.trBasic)}
                        onFocus={() => setEditedRowId(item.id)}
                      >
                        <td>{item.position}</td>
                        <td>{item.prefix}</td>
                        <td>{item.brand}</td>
                        <td>{item.suffix}</td>
                        <td>{item.onCot ? 'Y' : 'N'}</td>
                        <td>{item.dateOn && moment(item.dateOn).format('MM/DD/YYYY')}</td>
                        <td>
                          <TextField
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.onPrefix}
                            onChange={setOnPrefixTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'onPrefix')}
                          />
                        </td>
                        <td>
                          <TextField
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.onBrand}
                            onChange={setOnBrandTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'onBrand')}
                          />
                        </td>
                        <td>
                          <TextField
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.onSuffix}
                            onChange={setOnSuffixTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'onSuffix')}
                          />
                        </td>
                        <td>
                          <TextField
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.cot}
                            onChange={setCotTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'cot')}
                          />
                        </td>
                        <td>
                          <MaskedTextField
                            mask="__/__/____"
                            maskFormat={{ '_': /[0-9]/ }}
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.changeDate}
                            onChange={setChgDateTireToBeEdited}
                            onBlur={() => onBlurChgDate(item.id)}
                            errorMessage={parseErrors(item.id, 'changeDate')} />
                        </td>
                        <td>
                          <div className={styles.round}>
                            <input type="checkbox" id={item.id} checked={state.selectedItems.includes(item.id)} onChange={(e) => handleSelect(e, item.id)} />
                            <label htmlFor={item.id}></label>
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <SeparatorGy />
          </div>
        </div>
        <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
          <DefaultButton
            id="printExportButton"
            onClick={toggleShowPrintExport}
            text="Print/Export"
          />
          <DefaultButton id="viewErrorsButton" onClick={toggleViewErrors} text="View Errors" />
          <PrimaryButton
            id="saveButton"
            onClick={handleSave}
            disabled={!userPermissions.isWrite || state.selectedItems.length == 0}
            text="Save"
          />
          <PrimaryButton
            id="submitButton"
            disabled={state.selectedItems.length === 0 || !userPermissions.isWrite}
            onClick={handleSubmit}
            text="Submit"
          />
        </div>
      </div>
      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to delete ${state.selectedItems.length} items?`,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      <ErrorsModal
        isModalOpen={viewErrors}
        hideModal={toggleViewErrors}
        fetchErrorsService={handleFetchErrors}
      />
      {state.loading && <LoadingScreen />}
    </div>
  );
};

export default PostByFootprint;
