import { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useBoolean } from '@fluentui/react-hooks';
import { debounce, get, isNil } from 'lodash';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IColumn,
  IconButton,
  IDropdownOption,
  MessageBarType,
  PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';
import apiService from '../../../../api';
import useNotifications from '../../../../hooks/useNotifications';
import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../Pagination/Pagination';
import PrintingModal from '../../../PrintingModal/PrintingModal';
import { downloadFile, printingTypes } from '../../../PrintingModal/consts';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import { sortOrder } from '../../../../consts/sortOrder';
import { IPaginationProps } from '../../../Pagination/IPaginationProps';

import { IFiltersState } from './IFiltersState';
import { IMaintainTypeCodesProps } from './IMaintainTypeCodesProps';
import { IMaintainTypeCodesState } from './IMaintainTypeCodesState';
import { pageSizes, emptyFilters, emptyDetails, typeCodeColumns } from './consts';

import styles from './MaintainTypeCodes.module.scss';
import { IDetailsState } from './IDetailsState';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { auth_maintain_typeCodes } from '../../../../consts/programKeys';
import { typeEMHandler, productEMHandler } from '../../../../shared/TextFieldValidation';
import ProductCodesModal from './ProductCodesModal/ProductCodesModal';
import AutocompleteInput from '../../../../shared/AutocompleteInput';

const MaintainTypeCodes: FC<IMaintainTypeCodesProps> = () => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_maintain_typeCodes);

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    setColumnsState(newColumns);
  };

  const { addNotification } = useNotifications();

  const [state, setState] = useState<IMaintainTypeCodesState>({
    items: [],
    countries: [],
    productCodes: [],
    selectedItems: [],
    loading: false,
    foundCount: 0,
  });

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });

  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(typeCodeColumns);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [filtersState, setFiltersState] = useState<IFiltersState>(emptyFilters);
  const [detailsState, setDetailsState] = useState<IDetailsState>(emptyDetails);
  const [parsedDetailsErrors, setParsedDetailsErrors] = useState<any>([]);
  const [showProductCodesModal, { toggle: toggleShowProductCodesModal }] = useBoolean(false);
  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);

  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const getSortOrder = () => {
    const column = columnsState.find(({ isSorted }) => isSorted);
    if (isNil(column))
      return;
    const { fieldName, isSortedDescending } = column;
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const fetchData = async () => {
    await fetchCountries();
    await fetchProductCodes();
  };

  const fetchProductCodes = async (searchString?: string) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.getProductCodes({
        searchProductCode: searchString,
      });
      setState(prev => ({ ...prev, productCodes: data.data }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching product codes error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchCountries = async () => {
    setState((prev: IMaintainTypeCodesState) => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.getCountries();
      setState((prev: any) => ({ ...prev, countries: get(data, 'countries', []) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching countries error: ${get(response, 'data.message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetch = async () => {
    setState((prev: IMaintainTypeCodesState) => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.maintainTypeCodes.get(
        {
          ...filtersState,
          country: get(state.countries, `[${filtersState.country}]`, null),
        },
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
      );
      if (get(data, 'desc.code', 200) !== 200) {
        addNotification({
          text: `Fetching type codes error: ${get(data, 'desc.message', '')}`,
          type: MessageBarType.error,
        });
      } else {
        const foundCount = data.total.found;
        const newItems = data.data;
        setState((prev: IMaintainTypeCodesState) => { return ({ ...prev, items: newItems, selectedItems: [], foundCount }); });
        setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching type codes error: ${get(response, 'data.message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: IMaintainTypeCodesState) => ({ ...prev, loading: false }));
      
    }
  };

  const debouncedFetch = debounce(fetch, 1000);

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    try {
      setState((prev: any) => ({ ...prev, loading: true }));
      await apiService.typeCodeAPI.deleteTypeCode(state.selectedItems);
      addNotification({
        text: 'Selected type code was successfully deleted.',
        type: MessageBarType.success,
      });
      await paginationProps.onChangePage(1);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Codes deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      await fetch();
    }
  };

  const handleSelect = (e: any, itemId: any) => {
    const selectedRows = [...state.selectedItems];
    if (e.target.checked) {
      selectedRows.push(itemId);
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows.filter(row => row !== itemId) }));
    }
  };

  const [filter,setFilter] = useState<IFiltersState>(emptyFilters);


  const handleSearchClick = () => {
    setFiltersState((prev: any) => ({ ...prev, ...filter }));
    fetch();
  };

  const onFilterChange = (ev: any, value: any) => {
    setFilter((prev: any) => ({ ...prev, [ev.target.name]: value }));
  };

  const updateFilterState = (ev: any, value:any) => {
    setFiltersState((prev: any) => ({ ...prev, [ev.target.name]: value }));
  };

  const updateCountryFilter = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setFilter((prev: any) => ({ ...prev, country: item?.key }));
    setFiltersState((prev: any) => ({ ...prev, country: item?.key }));
  };

  const updateCountryDetails = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setDetailsState((prev: any) => ({ ...prev, country: item?.key, productCode: null }));
  };

  const parseAddingErrors = (field: string) => {
    const customError = parsedDetailsErrors?.filter((error: { field: string; }) => error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  const handleClearClick = () => setState((prev: any) => ({ ...prev, selectedItems: [] }));

  const handleAddClick = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.maintainTypeCodes.add({
        typeCode: get(detailsState, 'typeCode'),
        productCode: get(detailsState, 'productCode.productCode'),
        country: state.countries[get(detailsState, 'country')],
      });
      addNotification({
        text: 'Type code was successfully added.',
        type: MessageBarType.success,
      });
      setParsedDetailsErrors([]);
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          setParsedDetailsErrors(response.data.state);
          return addNotification({
            text: 'Unable to add.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Adding error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setDetailsState(emptyDetails);
      fetch();
      fetchProductCodes();
    }
  };

  const handleUpdateClick = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.maintainTypeCodes.update({
        oldTypeCode: get(state.items.find(({ id }) => id === state.selectedItems[0]), 'typeCode'),
        newTypeCode: get(detailsState, 'typeCode'),
        productCode: get(detailsState, 'productCode.productCode'),
        country: state.countries[get(detailsState, 'country')],
      });
      addNotification({
        text: 'Type code was successfully updated.',
        type: MessageBarType.success,
      });
      setParsedDetailsErrors([]);
      setDetailsState(emptyDetails);
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          setParsedDetailsErrors(response.data.state);
          return addNotification({
            text: 'Unable to update.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `updating error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      fetch();
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
      };
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.changeTypeCode.printExcel(requestData, []) :
        await apiService.changeTypeCode.printPdf(requestData, []);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSelectProductCode = (productCode: any) => {
    setDetailsState((prev: any) => ({ ...prev, productCode }));
    fetchProductCodes(productCode.productCode);
    toggleShowProductCodesModal();
  };

  const productCodeInputText = (productCode: string) => {
    if (productCode.length > 0) {
      getProductCode(productCode);
    }
  };

  const getProductCode = debounce(async (productCode) => {
    fetchProductCodes(productCode);
  }, 1000);

  useEffect(() => {
    if (!state.loading && state.countries.length) {
      debouncedFetch();
      setParsedDetailsErrors([]);
    }
  }, [
    paginationProps.current,
    columnsState,
    countOnPage,
  ]);

  useEffect(() => {
    if (state.selectedItems.length !== 1) {
      setDetailsState(emptyDetails);
    }
    else {
      const { country, typeCode, ...productCode } = state.items.find(({ id }) => id === state.selectedItems[0]);
      setDetailsState((prev: IDetailsState) => ({ ...prev, country: state.countries.findIndex((item) => item === country ), typeCode, productCode }));
    }
  }, [state.selectedItems]);

  useEffect(() => {
    if(state.countries.length)
      debouncedFetch();
  }, [filtersState, state.countries]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="ms-Grid">
        <div className={classNames('ms-Grid-row')}>
          <div className={classNames('ms-Grid-col', 'ms-sm4')}>
            <Text variant="xLarge" className={styles.highlight}>Search</Text>
            <SeparatorGy />
            <div className="ms-Grid">
              <div className={classNames('ms-Grid-row')}>
                <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                  <Dropdown
                    label="Country"
                    options={
                      state.countries.map((country, index) => ({
                        key: index,
                        text: country,
                      }))
                    }
                    selectedKey={filtersState.country}
                    onChange={updateCountryFilter}
                  />
                </div>
                <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                  <TextField
                    id="typeCodeSearch"
                    name="typeCode"
                    value={filter.typeCode.toUpperCase()}
                    label="Type code"
                    placeholder="Enter Type code"
                    onChange={onFilterChange}
                  />
                </div>
              </div>
              <div className={classNames('ms-Grid-row')}>
                <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                  <TextField
                    id="productCode"
                    name="productCode"
                    value={filter.productCode}
                    label="Product code"
                    placeholder="Enter Product code"
                    onChange={onFilterChange}
                  />
                </div>
                <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                  <TextField
                    id="tireSize"
                    name="tireSize"
                    value={filter.tireSize}
                    label="Tire Size"
                    placeholder="Enter Tire Size"
                    onChange={onFilterChange}
                  />
                </div>
              </div>
              <div className={classNames('ms-Grid-row')}>
                <div className={classNames('ms-Grid-col')}>
                  <PrimaryButton
                    text="Search"
                    className={styles.buttonTopMargin}
                    id="searchButton"
                    onClick={handleSearchClick}
                    style={{ marginTop: '12px' }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm1')} />
          <div className={classNames('ms-Grid-col', 'ms-sm7')}>
            <Text variant="xLarge" className={styles.highlight}>Details</Text>
            <SeparatorGy />

            <div className="ms-Grid">
              <div className="ms-Grid-row">
                <div className={classNames('ms-Grid-col', 'ms-sm3')}>
                  <Dropdown
                    label="Country"
                    options={
                      state.countries.map((country, index) => ({
                        key: index,
                        text: country,
                      }))
                    }
                    selectedKey={detailsState.country}
                    onChange={updateCountryDetails}
                  />
                </div>
                <div className="ms-Grid-col">
                  <TextField
                    label="Type code"
                    id="typeCodeDetails"
                    onChange={(ev: any, typeCode: any) => setDetailsState((prev: IDetailsState) => ({ ...prev, typeCode }))}
                    value={detailsState.typeCode.toUpperCase()}
                    errorMessage={parseAddingErrors('typeCode')}
                    onGetErrorMessage={typeEMHandler}
                  />
                </div>
                <div className={classNames('ms-Grid-col', styles.formBlock)}>
                  <AutocompleteInput
                    label="Product code"
                    initialValue={detailsState.productCode?.productCode}
                    list={state.productCodes.map((productCode: any) => ({
                      key: productCode,
                      text: productCode.productCode,
                    }))}
                    emptyExpanded
                    required
                    chooseCurrentItem={(productCode: any) => setDetailsState((prev: IDetailsState) => ({ ...prev, productCode }))}
                    textValue={productCodeInputText}
                  /> 
                  <IconButton
                    id="searchProductCodes"
                    iconProps={{ iconName: 'Search' }}
                    onClick={toggleShowProductCodesModal}
                  />
                </div>
                <div className="ms-Grid-col">
                  <TextField
                    label="Tire size"
                    value={get(detailsState, 'productCode.tireSize')}
                    disabled
                  />
                </div>
                <div className="ms-Grid-col">
                  <TextField
                    label="Description"
                    value={get(detailsState, 'productCode.description')}
                    disabled
                  />
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col">
                  <TextField
                    label="Ply rating"
                    value={get(detailsState, 'productCode.plyRating')}
                    disabled
                  />
                </div>
                <div className="ms-Grid-col">
                  <TextField
                    label="Material #"
                    value={get(detailsState, 'productCode.materialNo')}
                    disabled
                  />
                </div>
                <div className="ms-Grid-col">
                  <TextField
                    label="Spec #"
                    value={get(detailsState, 'productCode.specNo')}
                    disabled
                  />
                </div>
              </div>
              <div className={classNames('ms-Grid-row', styles.formBlock)}>
                <div className="ms-Grid-col">
                  <TextField
                    label="Radial Bias"
                    value={get(detailsState, 'productCode.radialOrBias')}
                    disabled
                  />
                </div>
                <div className="ms-Grid-col">
                  <TextField
                    label="Orig/Rtrd"
                    value={get(detailsState, 'productCode.origOrRtrd')}
                    disabled
                  />
                </div>
                <div className="ms-Grid-col">
                  <TextField
                    label="Tube Type"
                    value={get(detailsState, 'productCode.tubeType')}
                    disabled
                  />
                </div>
                <div className="ms-Grid-col">
                  <DefaultButton
                    text="Clear"
                    id="clearButton"
                    onClick={handleClearClick}
                    disabled={(state.selectedItems.length > 1 || state.selectedItems.length === 0)
                      && (detailsState.typeCode.length === 0 && isNil(detailsState.productCode)) || !userPermissions.isWrite
                    }
                  />
                </div>
                <div className="ms-Grid-col">
                  <PrimaryButton
                    text="Update"
                    id="updateButton"
                    onClick={handleUpdateClick}
                    disabled={state.selectedItems.length !== 1 || !userPermissions.isWrite}
                  />
                </div>
                <div className="ms-Grid-col">
                  <PrimaryButton
                    text="Add"
                    id="addButton"
                    onClick={handleAddClick}
                    disabled={state.selectedItems.length === 1 || !userPermissions.isWrite}
                  />
                </div>
              </div>
            </div>
          </div>
        </div >
        <div className={classNames('ms-Grid-row', styles.mainRow)}>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <div className={styles.tableHeading}>
              <div>
                <Text variant="xLarge" className={styles.highlight}>Available Type Codes</Text>
                <SeparatorGy vertical />
                <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
              </div>
              <div>
                <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                <Dropdown
                  options={pageSizes.map(pageSize => ({
                    key: pageSize,
                    text: pageSize.toString(),
                  }))}
                  defaultSelectedKey={pageSizes[0]}
                  selectedKey={countOnPage?.key}
                  onChange={onChangeCountOnPage}
                />
                <SeparatorGy vertical />
                <IconButton
                  id="deleteIcon"
                  disabled={state.selectedItems.length === 0 || !userPermissions.isWrite}
                  iconProps={{ iconName: 'Delete' }}
                  onClick={toggleDeletingConfirmation}
                />
              </div>
            </div>
            <div className={styles['table-wrapper']}>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    {
                      columnsState.map(item => (
                        <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                      ))
                    }
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    state.items.map((item: any) => {
                      const isItemSelected = state.selectedItems.indexOf(item.id) !== -1;
                      return (
                        <tr key={item.id + item.country}
                          className={classNames(isItemSelected ? styles.trSelected : styles.trBasic)}
                        >
                          <td></td>
                          <td>{item.typeCode}</td>
                          <td>{item.productCode}</td>
                          <td>{item.tireSize}</td>
                          <td>{item.description}</td>
                          <td>{item.materialNo}</td>
                          <td>{item.specNo}</td>
                          <td>{item.origOrRtrd}</td>
                          <td>{item.radialOrBias}</td>
                          <td>{item.plyRating}</td>
                          <td>
                            <div className={styles.round}>
                              <input
                                type="checkbox"
                                id={item.id}
                                checked={isItemSelected}
                                onChange={(e) => handleSelect(e, item.id)}
                              />
                              <label htmlFor={item.id}></label>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
            <SeparatorGy />
            <Pagination {...paginationProps} />
          </div>
        </div>
      </div >
      <div className="ms-Grid-row">
        <div className={classNames('ms-Grid-col', 'ms-sm12', styles.buttonsWrapper)}>
          <DefaultButton onClick={toggleShowPrintExport} text="Print/Export" />
        </div>
      </div>
      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: 'Are you sure you want to delete selected item?',
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteDialogButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      <ProductCodesModal
        isOpen={showProductCodesModal}
        onDismiss={toggleShowProductCodesModal}
        onSubmit={handleSelectProductCode}
        preselectedKey={get(detailsState, 'productCode', null)}
        country={state.countries[detailsState.country]}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default MaintainTypeCodes;
