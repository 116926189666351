import { ITabComponent } from '../../../../models/ITabComponent';
import ManageBillingCodes from './tabComponents/ManageBillingCodes/ManageBillingCodes';
import ManageBillingOptions from './tabComponents/ManageBillingOptions/ManageBillingOptions';

export const setBillingDatatabs: ISetBillingDataTabs = {
  manageBillingOptions: {
    name: 'Manage Billing Options',
    component: ManageBillingOptions,
    subTabs: null,
  },
  manageBillingCodes: {
    name: 'Manage Billing Codes',
    component: ManageBillingCodes,
    subTabs: null,
  },
};

export interface ISetBillingDataTabs {
  manageBillingOptions: ITabComponent,
  manageBillingCodes: ITabComponent,
}