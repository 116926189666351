import { Spinner, SpinnerSize, Text } from '@fluentui/react';
import { FunctionComponent } from 'react';
import { ICustomerDetails } from '../../../../../models/ICustomerDetails';
import { formatter } from '../../../../../shared/utils';
import styles from '../ViewInvoices.module.scss';


interface CustomerDetailsProps {
  isLoading: boolean;
  customerDetails: ICustomerDetails | undefined;
  totalAmount: { totalDebit: any, totalCredit: any };
};

const CustomerDetails: FunctionComponent<CustomerDetailsProps> = ({ isLoading, customerDetails, totalAmount }) => {
  return (
    <>
      <Text variant='xLarge' className={styles.highlight}>Customer details</Text>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {isLoading &&
            <tr key={'loading'}>
              <td colSpan={2}>
                <span >
                  <Spinner size={SpinnerSize.large} />
                </span>
              </td>
            </tr>
          }
          {!isLoading && (
            <>
              <tr>
                <td>Customer No</td>
                <td>{customerDetails?.customerNumber || ''}</td>
              </tr>
              <tr>
                <td>Customer Group</td>
                <td>{customerDetails?.customerGroup || ''}</td>
              </tr>
              <tr>
                <td>Region</td>
                <td>{customerDetails?.region || ''}</td>
              </tr>
              <tr>
                <td>Pct Equip</td>
                <td>{customerDetails?.pctEquip || ''}</td>
              </tr>
              <tr>
                <td>Billing Period</td>
                <td>{customerDetails?.billingPeriod || ''}</td>
              </tr>
              <tr>
                <td>Last Milleage</td>
                <td>{customerDetails?.lastMileageDate || ''}</td>
              </tr>
              <tr>
                <td>Last Billing</td>
                <td>{customerDetails?.lastBilling || ''}</td>
              </tr>
              <tr>
                <td>Done On</td>
                <td>{customerDetails?.doneOn || ''}</td>
              </tr>
              <tr>
                <td>Sales Tax #	</td>
                <td>{customerDetails?.salesTaxNumber || ''}</td>
              </tr>
              <tr>
                <td>Fed Tax #	</td>
                <td>{customerDetails?.fedTaxNumber || ''}</td>
              </tr>
              <tr>
                <td>Waste Tax	</td>
                <td>{customerDetails?.wasteTaxNumber || ''}</td>
              </tr>
              <tr>
                <td>Rate Letter</td>
                <td>{customerDetails?.rateLetterDue || ''}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      <div className={styles.amountLabel}>
        <Text variant='xLarge' >{`Total Debit: ${formatter.format(totalAmount.totalDebit)}`}</Text>
        <Text variant='xLarge' >{`Total Credit: ${formatter.format(totalAmount.totalCredit)}`}</Text>
      </div>

    </>
  );
};

export default CustomerDetails;