export const contractShellList = [
  '710-1a Coach',
  '710-LR Coach',
  '710-1 Coach',
  '710-1 Transit',
  '710-1a Transit',
  '710-LR Transit',
  '710-LR TR FB',
  '710-LR C FB',
  '710-5 Std',
];