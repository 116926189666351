import { IViewListingTabState } from './tabComponents/ActionOnTiresTab/tabComponents/ViewListing/IViewListingTabState';
import { IRecordKeepingState } from './IRecordKeepingState';

export const emptyRecordKeepingState: IRecordKeepingState = {
  loading: false,
  customersList: null,
  locationsList: null,
};

export const emptyViewListingTabState: IViewListingTabState = {
  loading: false,
  stockConsignedTiresList: [],
  stockFoundCount: 0,
  vehicleConsignedTiresList: [],
  vehicleFoundCount: 0,
};

export const mockCustomerList = [
  {id: 1, customerNumber: '000568012', customerName: 'NYC Transit Authority', businessName: '', prefix: 'N', abbrName: 'NYC TRANS', city: 'New York', country: 'USA', state: 'New York', regionCode: '0801'}, 
  {id: 2, customerNumber: '000568013', customerName: 'Los Angeles Transit Authority', businessName: '', prefix: 'L', abbrName: 'LA TRANS', city: 'Los Angeles', country: 'USA', state: 'California', regionCode: '0802'}, 
  {id: 3, customerNumber: '000568014', customerName: 'Akron Transit Authority', businessName: '', prefix: 'A', abbrName: '', city: 'Akron', country: 'USA', state: 'Ohio', regionCode: '0803'},
];

export const mockLocationsByCustomerId = [
  {id: 6, locationCode: '0006', locationName: 'Location Name 6', shipToNumber: '0000624574', customerId: 3, addrLine1: 'Akron Transit Authority Address Test 1', addrLine2: 'Address Test 1', city: 'Akron'},
];
