import { FC, ReactElement, useState } from 'react';
import { Modal, Text, Icon, Link, Pivot, PivotItem, IconButton } from '@fluentui/react';
import classNames from 'classnames';

import PermissionsSubtab from './components/PermissionsSubtab/PermissionsSubtab';
import UsersSubtab from './components/UsersSubtab/UsersSubtab';

import { IExpandedPermissionModalProps } from './IExpandedPermissionModalProps';
import { expandedPermissionModalTabs } from './consts';

import styles from './ExpandedPermissionModal.module.scss';

const ExpandedPermissionModal: FC<IExpandedPermissionModalProps> = ({ isModalOpen, hideModal, roleData }): ReactElement => {

  const [selectedKey, setSelectedKey] = useState(expandedPermissionModalTabs.permissions);

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  return (
    <Modal
      isOpen={isModalOpen}
      containerClassName={styles.modalContainer}
      onDismiss={hideModal}
    >
      <div className='modalHeader'>
        <IconButton
          iconProps={{ iconName: 'Cancel' }}
          ariaLabel="Close popup modal"
          onClick={hideModal}
        />
      </div>
      <div className="ms-Grid">
        <div className={classNames('ms-Grid-row', styles.mainInfoBlock, styles.row)}>
          <div className={classNames('ms-Grid-col', 'ms-sm4')}>
            <Text variant="xLarge" id="title">{roleData?.name}</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm3')}></div>
          <div className={classNames('ms-Grid-col', 'ms-sm2', styles.tooltipIconWrapper)}>
            <Icon className={styles.errorIcon} iconName='Error' />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm3', styles.accessManagementText)}>
            <Text block>
              Access management is handled in GAIMS. If you want to add or remove access to the users, please visit:
            </Text>
            <Link target="_blank" href='http://go.goodyear.com/cfmx/internal/gaims'>http://go.goodyear.com/cfmx/internal/gaims</Link>
          </div>
        </div>
        <div className={classNames('ms-Grid-row', styles.row)}>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <Pivot
              className="pivot-gy-colors"
              selectedKey={selectedKey}
              onLinkClick={handleLinkClick}
              getTabId={getTabId}
              linkFormat="tabs"
            >
              <PivotItem
                headerText={expandedPermissionModalTabs.permissions}
                itemKey={expandedPermissionModalTabs.permissions}
              >
                <PermissionsSubtab roleId={roleData?.id} roleName={roleData?.name}/>
              </PivotItem>
              <PivotItem
                headerText={expandedPermissionModalTabs.users}
                itemKey={expandedPermissionModalTabs.users}
              >
                <UsersSubtab roleId={roleData?.id} />
              </PivotItem>
            </Pivot>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ExpandedPermissionModal;
