import { recordTestInstance } from '../axios';

const getViewTireHistory = (
  pagination: any,
  filters: any,
  sortOrder: any, 
  nameOfView: string,
  locationId: number,
  customerId: number,
) => recordTestInstance.post('/consigned-tires/history', {
  pagination,
  filters,
  sortOrder,
  nameOfView,
  locationId,
  customerId,
});

const getViewTireHistoryId = (
  pagination: any,
  tireId: any,
  sortOrder: any, 
  nameOfView: string,
  locationId: number,
  customerId: number,
) => recordTestInstance.post(`/consigned-tires/${tireId}/history`, {
  pagination,
  sortOrder,
  nameOfView,
  locationId,
  customerId,
});

export default { getViewTireHistory, getViewTireHistoryId };
