import { 
  DefaultButton, 
  IDropdownOption, 
  MessageBarType, 
  Pivot, 
  PivotItem, 
  TextField,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { FC, FormEvent, ReactElement, useEffect, useState } from 'react';
import {  
  TIRECHANGE, 
  viewTireDetailsTabs,
  getDefaultPresentationView,
  recordKeepingTabs,
  actionOnTiresTabs,
} from '../../../consts/recordKeeping';
import SeparatorGy from '../../SeparatorGy/SeparatorGy';
import ContractDetailsTab from './tabComponents/ContractDetailsTab/ContractDetailsTab';
import FinancialDetailsTab from './tabComponents/FinancialDetailsTab/FinancialDetailsTab';
import GeneralDetailsTab from './tabComponents/GeneralDetailsTab/GeneralDetailsTab';
import TireHistoryDetailsTab from './tabComponents/TireHistoryDetailsTab/TireHistoryDetailsTab';
import { IViewTireDetailsProps } from './ViewTireDetailsProps';
import styles from './ViewTireDetails.module.scss';
import apiService from '../../../api';
import useNotifications from '../../../hooks/useNotifications';
import { customerSelector, locationSelector, setActiveSubTab, setActiveTab, tireForReinstateSelector } from '../../../redux/recordKeepingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { sortOrder } from '../../../consts/sortOrder';
import PrintingModal from '../../PrintingModal/PrintingModal';
import { downloadFile, printingTypes } from '../../PrintingModal/consts';
import { useUserPermissions } from '../../../hooks/useUserPermissions';
import { IPaginationProps } from '../../Pagination/IPaginationProps';
import { pageSizes } from '../../../consts/recordKeeping';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { auth_actionOnTires_viewTireDetails } from '../../../consts/programKeys';
import SelectingModal from '../../SelectingModal/SelectingModal';
import { tireDetailsColumns } from './consts';
import useKeyPress from '../../../hooks/useKeyPress/useKeyPress';
import { get } from 'lodash';

const ViewTireDetails: FC<IViewTireDetailsProps> = ({
  isSearchable,
  selectedTab,
  setSelectedTab,
}): ReactElement => {

  const dispatch = useDispatch();
    
  const defaultView = getDefaultPresentationView(TIRECHANGE);
  const [selectedKey, setSelectedKey] = useState('General');
  const [state, setState] = useState({
    general: null,
    financial: null,
    tireHistory: {
      items: [],
      foundCount: 0,
    },
    contactOrderTransfer: null,
    billedByOptions: [],
    loading: false,
    count: 0,
  });

  const [status, setStatus] = useState(null);
  const [viewBy, setViewBy] = useState(defaultView.view);
  const [searchParams, setSearchParams] = useState({
    prefix: '',
    brand: '',
    suffix: '',
  });
  const [billedBy, setBilledBy] = useState(null);
  const [remainingTreadDepth, setRemainingTreadDepth] = useState('0');
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ 
    key: pageSizes[0], 
    text: pageSizes[0].toString(), 
  });
  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_actionOnTires_viewTireDetails);
  const { addNotification } = useNotifications();
  const { id: customerId, customerName } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);
  const { tireId, pfx, bno, sfx } = useSelector(tireForReinstateSelector);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [isFirstLoading, { setFalse: wasFirstLoading }] = useBoolean(true);
  const [columnsState, setColumnsState] = useState<Array<any>>(defaultView.columns);
  const [consignedTires, setConsignedTires] = useState<Array<any>>([]);
  const [showAModal, { toggle: toggleShowAModal }] = useBoolean(false);

  const getData = async () => {
    await fetchTireDetails();
    await fetchTireHistory();
  };

  const getFilters = () => {
    if (isSearchable) {
      return searchParams;
    } else {
      return {
        prefix: pfx,
        brand: bno,
        suffix: sfx,
      };
    }
  };
  
  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const fetchAllTiresByPfxBnoSfx = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const filters = getFilters();
      const { data }: any = await apiService.viewTireDetails.getAllTireDetailsBrand(filters, customerId);
      setConsignedTires(data);
      (isSearchable && data.length) && toggleShowAModal();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Tire Details fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchTireDetails = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      if (isSearchable || !tireId) {
        const filters = getFilters();
        const { data }: any = await apiService.viewTireDetails.getViewTireDetailsBrand(
          filters,
          customerId,
        );
        setState((prev: any) => ({ ...prev, general: data.general, financial: data.financial, contactOrderTransfer: data.contactOrderTransfer }));
        setBilledBy(data.financial.billedBy);
        setStatus(data.general.oosDetails.status);

        if (data.count > 1 ) {
          await fetchAllTiresByPfxBnoSfx();
        }
        wasFirstLoading();
      } else {
        await fetchTireDetailsById(tireId);
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Tire fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
      setState({
        general: null,
        financial: null,
        tireHistory: {
          items: [],
          foundCount: 0,
        },
        contactOrderTransfer: null,
        billedByOptions: [],
        loading: false,
        count: 0,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchTireDetailsById = async(tireId: any) => {
    const { data }: any = await apiService.viewTireDetails.getViewTireDetailsId(
      tireId,
      customerId,
    );
    setState((prev: any) => ({ ...prev, general: data.general, financial: data.financial, contactOrderTransfer: data.contactOrderTransfer }));
    setBilledBy(data.financial.billedBy);
    setStatus(data.general.oosDetails.status);
    wasFirstLoading();
  };

  const fetchTireHistory = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      if (isSearchable || !tireId) {
        const sortOrder = getSortOrder();
        const filters = getFilters();
        const { data }: any = await apiService.viewTireHistory.getViewTireHistory(
          { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
          filters,
          sortOrder,
          viewBy,
          locationId,
          customerId,
        );
        const foundCount = data?.total?.found;
        const items = data?.data;
        setState((prev: any) => ({ ...prev, tireHistory: {items, foundCount } }));
        setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
      } else {
        await fetchTireHistoryById(tireId);
      }
      
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Tire History fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchTireHistoryById = async(tireId: any) => {
    const sortOrder = getSortOrder();
    const { data }: any = await apiService.viewTireHistory.getViewTireHistoryId(
      { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
      tireId,
      sortOrder,
      viewBy,
      locationId,
      customerId,
    );
    const foundCount = data?.total?.found ? data.total.found : 0;
    const items = data?.data ? data.data : [];
    setState((prev: any) => ({ ...prev, tireHistory: {items, foundCount } }));
    setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
  };

  const fetchBilledBy = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.viewTireDetails.getBilledBy();
      setState((prev: any) => ({ ...prev, billedByOptions: data }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Billed By fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchBillingPriceDetails = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const filters = getFilters();
      const { data }: any = await apiService.viewTireDetails.getBillingPriceDetails(
        filters,
        billedBy,
        remainingTreadDepth,
      );
      setState((prev: any) => (
        { 
          ...prev, 
          financial: {
            accounted: prev.financial.accounted,
            billed: prev.financial.billed,
            billedBy: prev.financial.billedBy,
            billing: data,
            details: prev.financial.details,
            takeover: prev.financial.takeover,
            treadDepth: prev.financial.treadDepth,
          },
        }
      ));
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          return addNotification({
            text: `Billing Details fetching error: ${response?.data?.state[0]?.message}`,
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Billing Details fetching error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleReinstate = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const filters = getFilters();
      await apiService.reinstate.save(
        [{
          pfx: filters.prefix,
          bno: filters.brand,
          sfx: filters.suffix,
        }], 
        customerId, 
        locationId,
      );
      handleRedirectToReinstate();
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 499:
          return addNotification({
            text: response.data.state[0].message,
            type: MessageBarType.error,
          });
        case 498:
          return addNotification({
            text: 'Tire not eligible for reinstatement- check status',
            type: MessageBarType.error,
          });
        case 400:
          return addNotification({
            text: 'Unable to reinstate changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Reinstate saving error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const filters = getFilters();
      const input = {
        prefix: filters.prefix,
        brand: filters.brand,
        suffix: filters.suffix,
        customerId,
      };
      const headerFields = [
        { title: 'Customer Name', value: customerName },
        { title: 'Prefix', value: filters.prefix },
        { title: 'Brand Number', value: filters.brand},
      ];
      const requestData = {
        input,
        headerFields,
      };
      const historyRequestData = {
        input: {
          filters,
          customerId,
          locationId: '',
          nameOfView: viewBy,
          pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
          sortOrder,
        },
        headerFields,
      };
      const { data }: any = printingType === printingTypes.excel ?
        selectedTab === viewTireDetailsTabs.general ?
          await apiService.viewTireDetails.printExcelGeneral(requestData) :
          selectedTab === viewTireDetailsTabs.tireHistory ?
            await apiService.viewTireDetails.printExcelHistory(historyRequestData) :
            selectedTab === viewTireDetailsTabs.contract ?
              await apiService.viewTireDetails.printExcelContract(requestData) :
              await apiService.viewTireDetails.printExcelFinancial(requestData) : 
        selectedTab === viewTireDetailsTabs.general ?
          await apiService.viewTireDetails.printPdfGeneral(requestData) :
          selectedTab === viewTireDetailsTabs.tireHistory ?
            await apiService.viewTireDetails.printPdfHistory(historyRequestData) :
            selectedTab === viewTireDetailsTabs.contract ?
              await apiService.viewTireDetails.printPdfContract(requestData) :
              await apiService.viewTireDetails.printPdfFinancial(requestData);
        
      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      const decodedData = JSON.parse(new TextDecoder().decode(new Uint8Array(response.data)));
      addNotification({
        text: `Printing error: ${decodedData?.state[0]?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handlePrefixChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | undefined) => {
    setSearchParams({ ...searchParams, prefix: value!.toUpperCase() });
  };

  const handleBrandNumberChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | undefined) => {
    setSearchParams({ ...searchParams, brand: value! });
  };

  const handleSuffixChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | undefined) => {
    setSearchParams({ ...searchParams, suffix: value!.toUpperCase() });
  };

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
      if(setSelectedTab) {
        setSelectedTab(item.props.itemKey!);
      }
    }
  };
    
  const renderTabAccordingKey = (tabKey: string) => {
    switch (tabKey) {
      case viewTireDetailsTabs.general:
        return ( 
          <GeneralDetailsTab 
            details={state.general} 
          />
        );
      case viewTireDetailsTabs.tireHistory:
        return (
          <TireHistoryDetailsTab 
            details={state.tireHistory} 
            viewBy={viewBy} setViewBy={setViewBy}
            currentCountOnPage={countOnPage} setCurrentCountOnPage={setCountOnPage} 
            currentPaginationProps={paginationProps}
            setCurrentPaginationProps={setPaginationProps}
            columnsState={columnsState} setColumnsState={setColumnsState}
          />
        );
      case viewTireDetailsTabs.contract:
        return (
          <ContractDetailsTab
            details={state.contactOrderTransfer}
          />
        );
      case viewTireDetailsTabs.financialDetails:
        return (
          <FinancialDetailsTab 
            details={state.financial}
            billedByOptions={state.billedByOptions}
            billedBy={billedBy}
            setBilledBy={setBilledBy}
            remainingTreadDepth={remainingTreadDepth}
            setRemainingTreadDepth={setRemainingTreadDepth}
          />
        );
    }
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  const handleSearch = () => (searchParams.prefix && searchParams.brand) && getData();

  const setTireDetails = async (tireId: any) => {
    toggleShowAModal();
    setState(prev => ({ ...prev, loading: true }));
    await fetchTireDetailsById(tireId);
    await fetchTireHistoryById(tireId);
    setState(prev => ({ ...prev, loading: false }));
  };
  
  const handleRedirectToReinstate = () => {
    dispatch(setActiveTab(recordKeepingTabs.actionOnTires?.name));
    dispatch(setActiveSubTab(actionOnTiresTabs.reinstate));
  };

  useKeyPress({
    handleEnter: handleSearch,
  });
  

  useEffect(() => {
    async function fetchAPI() {
      await fetchBilledBy();
      !isSearchable && await fetchTireDetails();
    }

    fetchAPI();
  }, []);

  useEffect(() => {
    async function fetchAPI() {
      await fetchBilledBy();
      await fetchTireHistory();
    }

    (!isSearchable || (searchParams.prefix && searchParams.brand)) && fetchAPI();
  }, [columnsState, paginationProps.current, countOnPage, viewBy]);

  useEffect(() => {
    const timer = setTimeout(() => {
      !isFirstLoading && billedBy && remainingTreadDepth !== '' && fetchBillingPriceDetails();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [billedBy, remainingTreadDepth]);

  useEffect(() => {
    setSearchParams({prefix: '', brand: '', suffix: ''});
    setState({
      general: null,
      financial: null,
      tireHistory: {
        items: [],
        foundCount: 0,
      },
      contactOrderTransfer: null,
      billedByOptions: [],
      loading: false,
      count: 0,
    });
  }, [customerId]);

  return(
    <>
      <Pivot
        aria-label="Separately Rendered Content Pivot Example"
        selectedKey={selectedKey}
        onLinkClick={handleLinkClick}
        getTabId={getTabId}
        linkFormat="tabs"
        linkSize="large"
      >
        {Object.values(viewTireDetailsTabs).map((value) => (
          <PivotItem headerText={value} itemKey={value} key={value}>
            <SeparatorGy />
            {
              isSearchable && 
            <div className={classNames(styles.searchPanelWrapper)}>
              <div className={classNames(styles.searchPanel)}>
                <div>
                  <label>Prefix</label>
                  <TextField
                    id="pfx-input"
                    value={searchParams.prefix}
                    onChange={handlePrefixChange}
                  />
                </div>
                <div>
                  <label>Brand Number</label>
                  <TextField
                    id="bno-input"
                    value={searchParams.brand}
                    onChange={handleBrandNumberChange}
                  />
                </div>
                <div>
                  <label>Suffix</label>
                  <TextField
                    id="sfx-input"
                    value={searchParams.suffix}
                    onChange={handleSuffixChange}
                  />
                </div>
                <div>
                  <DefaultButton id="searchButton" text="Search" onClick={handleSearch} disabled={!searchParams.prefix || !searchParams.brand}/>
                </div>
              </div>
            </div>
            }
            {renderTabAccordingKey(value)}
          </PivotItem>
        ))}
      </Pivot>
      <SeparatorGy />
      <div className={classNames(styles.detailsFooter)}>
        <DefaultButton 
          id='reinstateButton'
          onClick={handleReinstate} 
          text="Reinstate" 
          disabled={
            (!status ||
                  status === 'LST' ||
                  status === 'SLD') || !userPermissions.isWrite
          }
        />
        {
          selectedTab === viewTireDetailsTabs.tireHistory ? 
            <DefaultButton
              id="printExport"
              onClick={toggleShowPrintExport} 
              text="Print/Export" 
              disabled={!state.tireHistory?.items?.length} 
            /> :
            <DefaultButton id="printExport" onClick={toggleShowPrintExport} text="Print/Export" />
        }
      </div>
      <SelectingModal
        isOpen={showAModal}
        title="Available Consigned Tires"
        selectingList={consignedTires.map(tire => ({ id: tire.id, prefix: tire.prefix, brandNo: tire.brandNo, suffix: tire.suffix, status: tire.status,
          chgDate: tire.chgDate, totalMiles: tire.totalMiles, tireSize: tire.tireSize, typeCode: tire.typeCode, prodCode: tire.prodCode, tc: tire.tc,
          locationCode: tire.locationCode, oosAv: tire.oosAv, dotNo: tire.dotNo }))}
        onDismiss={toggleShowAModal}
        onSubmit={setTireDetails}
        columns={tireDetailsColumns}
      />
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default ViewTireDetails;