import { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import OrganizeFleetHOC from '../../../../../shared/OrganizeFleetHOC';

interface OrganizeFleetTermsProps {
  value?: string
};

const OrganizeFleetTerms: FunctionComponent<OrganizeFleetTermsProps> = () => {

  const parentComponentName = 'terms';

  const { id } = useParams<{ id: string }>();

  return (
    <>
      <OrganizeFleetHOC
        customerId={id}
        parentComponentName={parentComponentName}
      />
    </>
  );
};

export default OrganizeFleetTerms;