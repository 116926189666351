import { Text } from '@fluentui/react';
import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { IMenuOption } from '../../components/Nav/consts';
import styles from './ModuleTitle.module.scss';

interface ModuleTitleComponentProps {
  moduleInfo: IMenuOption;
}

const ModuleTitleComponent: FunctionComponent<ModuleTitleComponentProps> = ({ moduleInfo }) => {
  return (
    <>
      <div className='ms-Grid-row record-keeping-header'>
        <div className={classNames('ms-Grid-col', 'ms-lg12', styles.titleContainer)}>
          <div className={classNames(styles.iconContainer)}>
            <img src={moduleInfo?.iconSideBarSrc} />
          </div>
          <Text variant='xxLargePlus' className={styles.highlight}>{moduleInfo?.name}</Text>
        </div>
      </div>
    </>
  );
};

export default ModuleTitleComponent;