import { DefaultButton, Pivot, PivotItem, Icon, MessageBarType } from '@fluentui/react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { customerSelector, setCustomer } from '../../../../redux/customerSlice';
import { contractorSelector, setContractor } from '../../../../redux/contractSlice';
import { termsTabs } from './consts';
import styles from './TabComponents.module.scss';
import apiService from '../../../../api';
import useNotifications from '../../../../hooks/useNotifications';
interface TermsTabsComponentProps {
  value?: string;
}

const TermsTabsComponent: FunctionComponent<TermsTabsComponentProps> = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const customer = useSelector(customerSelector);
  const contractSelected = useSelector(contractorSelector);
  const dispatch = useDispatch();
  const { addNotification } = useNotifications();
  const { getPermissionsByAccessKey } = useUserPermissions();

  const [selectedKey, setSelectedKey] = useState(termsTabs.general.name);
  const [actionType, setActionType] = useState('');

  const fetchCustomers = async () => {
    try {
      const { data: { data } }: any = await apiService.getCustomerSearch({}, null, null, id);
      dispatch(setCustomer(data[0]));
    } catch (e: any) {
      addNotification({
        text: 'Customers fetching error',
        type: MessageBarType.error,
      });
    }
  };

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  };

  const renderTab = (TabComponent: FunctionComponent<{ actionType: string }>) => {
    if (TabComponent) return <TabComponent actionType={actionType} />;
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  const handleBackToSearch = () => {
    history.push('/terms');
  };

  const handleBackToListing = () => history.push(`/terms/${id}/list`);

  useEffect(() => {
    setActionType(id == 'add' ? id : 'edit');
    fetchCustomers();
  }, [id]);

  return (
    <>
      <div className={classNames(styles.termsContainer)}>
        <div className={classNames(styles.backButtonContainer)}>
          <DefaultButton
            onClick={() => handleBackToSearch()}
          >
            Back to Search
          </DefaultButton>
          <DefaultButton
            onClick={handleBackToListing}
          >
            Back to Listing
          </DefaultButton>
        </div>
        <div className={styles.tabsContainer}>
          <div className={styles.customerHeaderWrapper}>
            {isEmpty(customer?.contractStatus) ? customer && <h2 className={styles.customerHeader}>{customer?.customerName} </h2> :
              customer && <h2 className={styles.customerHeader}>{customer?.customerName} - {contractSelected?.contractStatus} </h2>}
          </div>
          <Pivot
            className={'pivot-gy-colors'}
            selectedKey={selectedKey}
            onLinkClick={handleLinkClick}
            getTabId={getTabId}
            linkFormat="tabs"
          >
            {Object.values(termsTabs).map(({ accessKey, name, component, actionType: actionTypeList }, index) => {
              const showTab = actionTypeList.includes(actionType);
              return showTab && getPermissionsByAccessKey(accessKey)?.isAccess &&
                (<PivotItem headerText={name} key={index} itemKey={name}>
                  <div className={styles.pivotBodyContainer}>
                    {renderTab(component)}
                  </div>
                </PivotItem>);
            })}
          </Pivot>
        </div>

      </div>
    </>
  );
};

export default TermsTabsComponent;