import { DefaultButton, Dropdown, IColumn, IDropdownOption, MessageBarType, Text } from '@fluentui/react';
import { FC, FormEvent, ReactElement, useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { columns } from './consts';
import { pageSizes } from '../../../../../../consts/recordKeeping';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';

import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import ErrorsModal from '../../../../../ErrorsModal/ErrorsModal';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../../Pagination/Pagination';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';

import apiService from '../../../../../../api';
import useNotifications from '../../../../../../hooks/useNotifications';
import { customerSelector, locationSelector } from '../../../../../../redux/recordKeepingSlice';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { pushToast } from '../../../../../../redux/notificationsSlice';
import { sortOrder } from '../../../../../../consts/sortOrder';

import { IViewOpenWheelsProps } from './IViewOpenWheelsProps';
import { IViewOpenWheelsState } from './IViewOpenWheelsState';

import styles from './ViewOpenWheels.module.scss';

const ViewOpenWheels: FC<IViewOpenWheelsProps> = (): ReactElement => {
  const { addNotification } = useNotifications();
  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const dispatch = useDispatch();
  const { id: customerId, customerName } = useSelector(customerSelector);
  const { id: locationId, locationCode } = useSelector(locationSelector);

  const [state, setState] = useState<IViewOpenWheelsState>({
    items: [],
    loading: false,
    foundCount: 0,
  });
  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);

  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [showErrorsModal, { toggle: toggleShowErrorsModal }] = useBoolean(false);

  const { hasPermission } = useUserPermissions();

  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const fetchReinstate = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.viewOpenWheels.get(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
      );
      const foundCount = data.total.found;
      const items = data.data.map(
        ({ tireChanged, billed, ...other }: any) => (
          {
            ...other,
            billed: billed ? 'Y' : 'N',
            tireChanged: tireChanged ? 'Y' : 'N',
          }
        ),
      );
      setState((prev: any) => ({ ...prev, items, foundCount }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      const { response } = e;
      dispatch(pushToast({
        text: `View Open Wheels fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      }));
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
      };
      const headerFields = [
        { title: 'Customer Name', value: customerName },
        { title: 'Location Code', value: locationCode },
      ];
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.viewOpenWheels.printExcel(requestData, headerFields) :
        await apiService.viewOpenWheels.printPdf(requestData, headerFields);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    fetchReinstate();
  }, [
    paginationProps.current,
    countOnPage,
    columnsState,
    customerId,
    locationId,
  ]);

  return (
    <>
      <div>
        <div>
          <div className={styles.tableHeading}>
            <div>
              <Text variant="xLarge" className={styles.highlight}>Available open wheel positions</Text>
              <SeparatorGy vertical />
              <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
            </div>
            <div>
              <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
              <Dropdown
                options={pageSizes.map(pageSize => ({
                  key: pageSize,
                  text: pageSize.toString(),
                }))}
                defaultSelectedKey={pageSizes[0]}
                selectedKey={countOnPage?.key}
                onChange={onChangeCountOnPage}
              />
            </div>
          </div>
          <div className={styles['table-wrapper']}>
            <table>
              <thead>
                <tr>
                  {
                    columnsState.map(item => (
                      <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                  state.items.map(item => (
                    <tr>
                      <td>{item.vehicleNumber}</td>
                      <td>{item.position}</td>
                      <td>{item.from && moment(item.from).format('MM/DD/YYYY')}</td>
                      <td>{item.to && moment(item.to).format('MM/DD/YYYY')}</td>
                      <td>{item.accMiles}</td>
                      <td>{item.tireChange}</td>
                      <td>{item.billed}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <SeparatorGy />
          <Pagination {...paginationProps} />
        </div>
        <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
          <DefaultButton id="printButton" onClick={toggleShowPrintExport} text="Print/Export" />
          <DefaultButton onClick={toggleShowErrorsModal} text="View Errors" />
        </div>
      </div >
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      <ErrorsModal
        isModalOpen={showErrorsModal}
        hideModal={toggleShowErrorsModal}
        fetchErrorsService={apiService.viewOpenWheels.getErrors}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default ViewOpenWheels;