import { DefaultButton, MessageBarType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { useState } from 'react';
import DataGridComponent from '../../../../../shared/DataGridComponent';
import { columns } from './consts';
import { IContractProps } from './IContractProps';
import { IContractState } from './IContractState';
import apiService from '../../../../../api';
import { useParams } from 'react-router-dom';
import useNotifications from '../../../../../hooks/useNotifications';
import { debounce } from 'lodash';
import styles from './Contract.module.scss';
import { downloadFile } from '../../../../PrintingModal/consts';


const Contract: React.FC<IContractProps> = () => {
  const [state, setState] = useState<IContractState>({
    items: [],
    foundCount: 0,
    loading: false,
  });

  const { addNotification } = useNotifications();
  const { id } = useParams<{ id: string }>();
  const { contractId } = useParams<{ contractId: string }>();

  const [contractSelected, setContractSelected] = useState<any>(null);

  const fetchContract = debounce(async (data: any) => {
    const { searchedText, countOnPage, paginationProps, sortOrder } = data;
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.contract.getContractDocuments(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        searchedText,
        id,
        contractId,
      );
      const foundCount = data.total.found;
      setState(prev => ({ ...prev, items: data.data, foundCount }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Contract Documents fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  }, 500);

  const downloadContract = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.contract.downloadContractDocument(
        id,
        contractId,
        contractSelected.name,
      );
      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      const fileExtensionName = contractSelected.name.substr(
        contractSelected.name.lastIndexOf('.') + 1);
      const printType = fileExtensionName === 'doc' ? 'Document' : 'docx';
      downloadFile(data, printType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Reconciliation Summary fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleSelectRow = (rowsSelected: any) => {
    if (rowsSelected.length === 1) {
      setContractSelected(rowsSelected[0]);
    } else {
      setContractSelected(null);
    }
  };

  return (
    <>
      <div data-testid='Contract' className="ms-Grid margin-left-rigth-2 marginTop18" dir='ltr'>
        <div className='ms-Grid-row'>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <DataGridComponent
              idTable={'add-modal-table'}
              title='Contract'
              headCells={columns}
              rowsTable={state.items}
              totalDataFound={state.foundCount}
              enableSearching
              enablePagination
              enableCheckBox
              enableRowsPerPage
              enableMultiSorting
              handleSelectRow={handleSelectRow}
              isLoading={state.loading}
              handleChangeDataGridState={fetchContract}
            />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.buttonsWrapper)}>
            <DefaultButton data-testid='download-button' onClick={downloadContract} text="Download Contract" disabled={contractSelected === null} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contract;
