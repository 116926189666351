import { MessageBarType, PrimaryButton, TextField } from '@fluentui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { quoteStatuses } from '../../components/Quote/const';
import useKeyPress from '../../hooks/useKeyPress/useKeyPress';
import useNotifications from '../../hooks/useNotifications';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import { IRateGroup } from '../../models/IRateGroup';
import { selectedQuoteSelector, setSelectedQuote } from '../../redux/quoteSlice';
import { FormBuilderGroup } from '../../shared/FormBuilderComponent';
import DataGridComponent from '../DataGridComponent';
import { makeModelHeadCells } from '../MakeModelComponent/consts';
import { termsRateGroupHeadCells, quotesRateGroupHeadCells, RATE_GROUPS_FIELDS } from './consts';
import styles from './RateGroupComponent.module.scss';

interface RateGroupComponentProps {
  fetchAPI: any,
  deleteAPI: any,
  addAPI: any,
  updateAPI: any,
  disableEditing?: boolean,
  customerId: string,
  permissionKey: string,
  parentComponentName: string,
  onChangeRateGroup: any,
}

const RateGroupComponent: FunctionComponent<RateGroupComponentProps> = ({
  fetchAPI,
  deleteAPI,
  addAPI,
  updateAPI,
  disableEditing,
  customerId,
  permissionKey,
  parentComponentName,
  onChangeRateGroup,
}) => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(permissionKey);
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();
  const { contractId } = useParams<{ contractId: string }>();
  const { quoteId } = useParams<{ quoteId: string }>();
  const selectedQuote = parentComponentName === 'quote' ? useSelector(selectedQuoteSelector) : undefined;

  const { addNotification } = useNotifications();

  // state
  const [isLoading, setLoading] = useState<boolean>(false);
  const [rateGroupList, setRateGroupList] = useState<Array<IRateGroup>>([]);
  const [rateGroupListPagination, setRateGroupListPagination] = useState<any>({ pageSize: 100, pageNumber: 1 });
  const [rateGroupListSortOrder, setRateGroupListSortOrder] = useState<any>({ column: makeModelHeadCells[0].fieldName, order: 'asc' });
  const [totalFound, setTotalFound] = useState(0);
  const [rowsSelected, setRowsSelected] = useState<Array<IRateGroup>>([]);

  //toggles
  const [isLoadingTable, setLoadingTable] = useState<boolean>(false);

  //form validators
  const rateGroupsFieldsGroup = new FormBuilderGroup(RATE_GROUPS_FIELDS);

  // fetch
  const fetchRateGroups = async (
    pagination: any = rateGroupListPagination,
    filterText?: any,
    sortOrder: any = rateGroupListSortOrder,
  ) => {
    setLoadingTable(true);
    try {
      const { data: { data, total: { found } } }: any = await fetchAPI(pagination, [sortOrder], filterText, id, contractId, selectedQuote?.id, selectedQuote?.version);
      setRateGroupList(data);
      setTotalFound(found);
      setRateGroupListPagination(pagination);
      setRateGroupListSortOrder(sortOrder);
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Rate Groups fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoadingTable(false);
    }
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    fetchRateGroups(pagination, searchedText, sortOrder);
  };

  const handleAddNewRateGroup = async () => {
    setLoading(true);
    try {
      await addAPI({
        quoteId,
        quoteVersion: selectedQuote?.version,
        customerId,
        contractId,
        rates: [{
          rategroupName: rateGroupsFieldsGroup.getFieldFormValue('rateGroupNameField'),
          bonusOrig: +rateGroupsFieldsGroup.getFieldFormValue('bonusOriginField'),
          bonusRtrd: +rateGroupsFieldsGroup.getFieldFormValue('bonusRetreadField'),
          dfltEbitPct: +rateGroupsFieldsGroup.getFieldFormValue('ebitPercentage'),
          fixedbaseAvgOrig: +rateGroupsFieldsGroup.getFieldFormValue('fixedBaseAverageField'),
          fixedbaseAvgRtrd: +rateGroupsFieldsGroup.getFieldFormValue('fixedBaseRetreadField'),
          tireQuoteRateC: +rateGroupsFieldsGroup.getFieldFormValue('tireQuoteRateField'),
        }],
      });
      if(selectedQuote?.status === quoteStatuses.GENERATED)
        dispatch(setSelectedQuote({ ...selectedQuote, status: quoteStatuses.REGENERATED }));
      addNotification({
        text: 'Rate Group was successfully added.',
        type: MessageBarType.success,
      });
      fetchRateGroups();
      onChangeRateGroup();
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Rate Group adding error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateRateGroup = async () => {
    setLoading(true);
    try {
      await updateAPI([{
        ...rowsSelected[0],
        rategroupName: rateGroupsFieldsGroup.getFieldFormValue('rateGroupNameField'),
        bonusOrig: +rateGroupsFieldsGroup.getFieldFormValue('bonusOriginField'),
        bonusRtrd: +rateGroupsFieldsGroup.getFieldFormValue('bonusRetreadField'),
        dfltEbitPct: +rateGroupsFieldsGroup.getFieldFormValue('ebitPercentage'),
        fixedbaseAvgOrig: +rateGroupsFieldsGroup.getFieldFormValue('fixedBaseAverageField'),
        fixedbaseAvgRtrd: +rateGroupsFieldsGroup.getFieldFormValue('fixedBaseRetreadField'),
        tireQuoteRateC: +rateGroupsFieldsGroup.getFieldFormValue('tireQuoteRateField'),
      }], customerId, selectedQuote?.id, selectedQuote?.version);
      if(selectedQuote?.status === quoteStatuses.GENERATED)
        dispatch(setSelectedQuote({ ...selectedQuote, status: quoteStatuses.REGENERATED }));
      addNotification({
        text: 'Rate Group was successfully updated.',
        type: MessageBarType.success,
      });
      fetchRateGroups();
      onChangeRateGroup();
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Rate Group updating error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRateGroup = async (rowsToDelete: Array<IRateGroup> = []) => {
    setLoading(true);
    try {
      await deleteAPI(rowsToDelete.map(el => el.id), selectedQuote?.id, selectedQuote?.version);
      if(selectedQuote?.status === quoteStatuses.GENERATED)
        dispatch(setSelectedQuote({ ...selectedQuote, status: quoteStatuses.REGENERATED }));
      addNotification({
        text: 'Rate Group(s) were successfully deleted.',
        type: MessageBarType.success,
      });
      fetchRateGroups();
      onChangeRateGroup();
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Rate Group(s) deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const onClearFields = () => {
    rateGroupsFieldsGroup.setFormValue('rateGroupIdField', '');
    rateGroupsFieldsGroup.setFormValue('rateGroupNameField', '');
    rateGroupsFieldsGroup.setFormValue('bonusOriginField', '');
    rateGroupsFieldsGroup.setFormValue('bonusRetreadField', '');
    rateGroupsFieldsGroup.setFormValue('ebitPercentage', '');
    rateGroupsFieldsGroup.setFormValue('fixedBaseAverageField', '');
    rateGroupsFieldsGroup.setFormValue('fixedBaseRetreadField', '');
    rateGroupsFieldsGroup.setFormValue('tireQuoteRateField', '');
  };

  useEffect(() => {
    if (rowsSelected.length == 1) {
      rateGroupsFieldsGroup.setFormValue('rateGroupIdField', rowsSelected[0]?.id);
      rateGroupsFieldsGroup.setFormValue('rateGroupNameField', rowsSelected[0]?.rategroupName);
      rateGroupsFieldsGroup.setFormValue('bonusOriginField', rowsSelected[0]?.bonusOrig);
      rateGroupsFieldsGroup.setFormValue('bonusRetreadField', rowsSelected[0]?.bonusRtrd);
      rateGroupsFieldsGroup.setFormValue('ebitPercentage', rowsSelected[0]?.dfltEbitPct);
      rateGroupsFieldsGroup.setFormValue('fixedBaseAverageField', rowsSelected[0]?.fixedbaseAvgOrig);
      rateGroupsFieldsGroup.setFormValue('fixedBaseRetreadField', rowsSelected[0]?.fixedbaseAvgRtrd);
      rateGroupsFieldsGroup.setFormValue('tireQuoteRateField', rowsSelected[0]?.tireQuoteRateC);
    }
    else {
      rateGroupsFieldsGroup.cleanFormData();
    }
  }, [rowsSelected]);

  useKeyPress({
    handleAdd: handleAddNewRateGroup,
    handleClear: onClearFields,
  });

  if (parentComponentName === 'terms') {
    return (
      <>
        <div className={styles.formContainer}>
          <TextField {...rateGroupsFieldsGroup.getFieldForm('rateGroupNameField')} />
          <TextField {...rateGroupsFieldsGroup.getFieldForm('bonusOriginField')} />
          <TextField {...rateGroupsFieldsGroup.getFieldForm('bonusRetreadField')} />
          <TextField {...rateGroupsFieldsGroup.getFieldForm('fixedBaseAverageField')} />
          <TextField {...rateGroupsFieldsGroup.getFieldForm('fixedBaseRetreadField')} />
          <TextField {...rateGroupsFieldsGroup.getFieldForm('tireQuoteRateField')} />
        </div>
        <div className={styles.buttonContainer}>
          {!disableEditing && <PrimaryButton
            id='updateRateGroupBtn'
            onClick={handleUpdateRateGroup}
            text='Update Rate Group'
            disabled={!(userPermissions.isWrite && rowsSelected.length == 1 && rateGroupsFieldsGroup.getFieldFormValue('rateGroupNameField'))}
          />}
          {!disableEditing && <PrimaryButton
            id='newRateGroupBtn'
            onClick={handleAddNewRateGroup}
            text='Add new Rate Group'
            disabled={!(userPermissions.isWrite && rowsSelected.length !== 1 && rateGroupsFieldsGroup.getFieldFormValue('rateGroupNameField'))}
          />}
        </div>
        <div>
          <DataGridComponent
            idTable={'rate-group-table'}
            title='Rate Groups'
            headCells={termsRateGroupHeadCells}
            rowsTable={rateGroupList}
            totalDataFound={totalFound}
            isLoading={isLoadingTable}
            enableCheckBox={!disableEditing}
            enableMultiSelectRow={!disableEditing}
            enableDeleteOption={!disableEditing}
            enableSearching
            enablePagination
            enableRowsPerPage
            handleChangeDataGridState={handleChangeDataGridState}
            handleDelete={handleDeleteRateGroup}
            handleSelectRow={setRowsSelected}
          />
        </div>
        {isLoading && <LoadingScreen />}
      </>
    );
  }
  else if (parentComponentName === 'quote') {
    return (
      <>
        <div className={styles.formContainer}>
          <TextField {...rateGroupsFieldsGroup.getFieldForm('rateGroupNameField')} />
          <TextField {...rateGroupsFieldsGroup.getFieldForm('bonusOriginField')} />
          <TextField {...rateGroupsFieldsGroup.getFieldForm('bonusRetreadField')} />
          <TextField {...rateGroupsFieldsGroup.getFieldForm('ebitPercentage')} />
          <TextField {...rateGroupsFieldsGroup.getFieldForm('tireQuoteRateField')} />
        </div>
        <div className={styles.buttonContainer}>
          {!disableEditing && <PrimaryButton
            id='updateRateGroupBtn'
            onClick={handleUpdateRateGroup}
            text='Update Rate Group'
            disabled={!(userPermissions.isWrite && rowsSelected.length == 1 && rateGroupsFieldsGroup.getFieldFormValue('rateGroupNameField'))}
          />}
          {!disableEditing && <PrimaryButton
            id='newRateGroupBtn'
            onClick={handleAddNewRateGroup}
            text='Add new Rate Group'
            disabled={!(userPermissions.isWrite && rowsSelected.length !== 1 && rateGroupsFieldsGroup.getFieldFormValue('rateGroupNameField'))}
          />}
        </div>
        <div>
          <DataGridComponent
            idTable={'rate-group-table'}
            title='Rate Group'
            headCells={quotesRateGroupHeadCells}
            rowsTable={rateGroupList}
            totalDataFound={totalFound}
            isLoading={isLoadingTable}
            enableCheckBox={!disableEditing}
            enableMultiSelectRow={!disableEditing}
            enableDeleteOption={!disableEditing}
            enableSearching
            enablePagination
            enableRowsPerPage
            handleChangeDataGridState={handleChangeDataGridState}
            handleDelete={handleDeleteRateGroup}
            handleSelectRow={setRowsSelected}
          />
        </div>
        {isLoading && <LoadingScreen />}
      </>
    );
  }
  else
    return <></>;

};

export default RateGroupComponent;