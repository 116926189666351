import React, {
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  Checkbox,
  DatePicker,
  Text,
  Stack,
  DefaultButton,
  PrimaryButton,
  MessageBarType,
  TextField,
} from '@fluentui/react';
import classNames from 'classnames';
import { useBoolean } from '@fluentui/react-hooks';
import { get, isEmpty, isEqual, isNil } from 'lodash';

import apiService from '../../../../api';
import useNotifications from '../../../../hooks/useNotifications';
import { customerInformationSelector, customerSelector, setCustomerInformation } from '../../../../redux/recordKeepingSlice';

import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';

import TabHeader from '../TabHeader/TabHeader';

import { IRecordKeepingTabProps } from './IRecordKeepingTabProps';

import styles from './RecordKeepingTab.module.scss';

import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { auth_customerInformation} from '../../../../consts/programKeys';
import { transformDate } from '../../../../shared/transformDate';

const RecordKeepingTab: FC<IRecordKeepingTabProps> = (): ReactElement => {

  const dispatch = useDispatch();
  const customerDetails = useSelector(customerInformationSelector);
  const { id: customerId } = useSelector(customerSelector);
  const { addNotification } = useNotifications();

  const [localState, setLocalState] = useState<any>(customerDetails);
  const [loading, { setTrue: startLoading, setFalse: finishLoading }] = useBoolean(false);

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_customerInformation);

  useEffect(() => {
    setLocalState(customerDetails);
  }, [
    customerDetails,
  ]);

  const renderLargeLabel = (labelProps: any) => (
    <Text variant='large' className={styles.highlight}>{labelProps.label}</Text>
  );

  const renderLargeLabelWithTextField = (labelProps: any, textFieldProps?: any | undefined) => (
    <>
      <Text variant='large' className={styles.highlight}>{labelProps.label}</Text>
      {textFieldProps && <TextField {...textFieldProps} />}
    </>
  );

  const onChangeTextInput = (event: any, regExp: RegExp) => {
    setLocalState((prev: any) => ({ ...prev, [event.target.name]: regExp.test(event.target.value) && event.target.value }));
  };

  const onChangeDate = (target: any, value: Date | null | undefined) => {
    setLocalState((prev: any) => ({ ...prev, [target]: value?.toString() }));
  };

  const onChangeCheckbox = (event: any) => {
    if (event.target.name === 'warnIfMonthlyUnitsAreMoreThan' && get(localState, 'warnIfMonthlyUnitsAreMoreThan', false)) {
      setLocalState((prev: any) => ({ ...prev, units: 0 }));
    }

    setLocalState((prev: any) => ({ ...prev, [event.target.name]: !get(prev, event.target.name) }));
  };

  const monthlyUnitsProps = {
    name: 'units',
    maxLength: 6,
    value: `${get(localState, 'units', 0)}`,
    onChange: (e: any) => onChangeTextInput(e, /^[0-9]*$/),
    disabled: !get(localState, 'warnIfMonthlyUnitsAreMoreThan', false),
  };

  const stateWasChanged = useMemo(() => !isEqual(customerDetails, localState), [customerDetails, localState]);

  const updateRecordKeeping = async () => {
    try {
      startLoading();
      const {
        notificationLetterSent,
        nextAnnualDue,
        autoXferToLocationFound,
        autoRelocateSubdToSubdTransfer,
        postAgainstVehicles,
        postOdometerReading,
        warnIfMonthlyUnitsAreMoreThan,
        units,
      } = localState;
      await apiService.updateCustomerDetails({
        customerId,
        notificationLetterSent: isNil(notificationLetterSent) ? null : transformDate(moment(localState.notificationLetterSent).format('MM/DD/YYYY')),
        nextAnnualDue: isNil(nextAnnualDue) ? null : transformDate(moment(localState.nextAnnualDue).format('MM/DD/YYYY')),
        autoXferToLocationFound,
        autoRelocateSubdToSubdTransfer,
        postAgainstVehicles,
        postOdometerReading,
        warnIfMonthlyUnitsAreMoreThan,
        units,
      });
      dispatch(setCustomerInformation({
        ...customerDetails,
        notificationLetterSent,
        nextAnnualDue,
        autoXferToLocationFound,
        autoRelocateSubdToSubdTransfer,
        postAgainstVehicles,
        postOdometerReading,
        warnIfMonthlyUnitsAreMoreThan,
        units,
      }));
      addNotification({
        text: 'Record keeping details were updated.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      return addNotification({
        text: `Record keeping details updating error: ${response.data.message}`,
        type: MessageBarType.error,
      });

    } finally {
      finishLoading();
    }
  };

  return (
    <>
      <div className='ms-Grid'>
        <TabHeader
          title='Record Keeping Details'
          details
        />
        <div className='ms-Grid-row'>
          <div className={classNames('ms-Grid-col', 'ms-xs12')}>
            <Text variant='xxLarge' block className={styles.highlight}>
              Record Keeping - Customer Information
            </Text>
          </div>
        </div>
        {localState &&
          <div
            className={classNames('ms-Grid-row', styles.customerInformationWrapper)}
          >
            <div className={classNames('ms-Grid-col', 'ms-lg8', 'ms-xs12')}>
              <div className='ms-Grid-row'>
                <div className={classNames('ms-Grid-col', styles.nonFloat)}>
                  <Text variant='xLarge' className={styles.highlight}>Mileage Billing</Text>
                  <div
                    className={classNames(styles.flex, styles.spaceBetweenContent, styles.borderedBlock, styles.detailsBlock)
                    }>
                    <div>
                      <Text variant='large' block className={styles.highlight}>Period Type</Text>
                      <Text variant='xLarge' block className={styles.highlight}>{localState.periodType}</Text>
                    </div>
                    <div>
                      <Text variant='large' block className={styles.highlight}>Bill To</Text>
                      <Text variant='xLarge' block className={styles.highlight}>{localState.billTo}</Text>
                    </div>
                    <div>
                      <Text variant='large' block className={styles.highlight}>Bill By</Text>
                      <Text variant='xLarge' block className={styles.highlight}>{localState.billBy}</Text>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classNames('ms-Grid-row', styles.optionsWrapper)}>
                <div className={classNames('ms-Grid-col')}>
                  <Text variant='xLarge' className={styles.highlight}>Tire Disposition Options</Text>
                  <div className={classNames(styles.borderedBlock, styles.detailsBlock)}>
                    <Checkbox
                      label='Auto-xfer to location found'
                      onRenderLabel={renderLargeLabel}
                      className={styles.baselineItems}
                      name='autoXferToLocationFound'
                      checked={localState.autoXferToLocationFound}
                      onChange={onChangeCheckbox}
                    />
                    <SeparatorGy />
                    <Checkbox
                      label='Auto-relocate subsidiary to subsidiary transfer'
                      onRenderLabel={renderLargeLabel}
                      className={styles.baselineItems}
                      name='autoRelocateSubdToSubdTransfer'
                      checked={localState.autoRelocateSubdToSubdTransfer}
                      onChange={onChangeCheckbox}
                    />
                  </div>
                </div>
                <div className={classNames('ms-Grid-col')}>
                  <Text variant='xLarge' className={styles.highlight}>Vehicle Miles Options</Text>
                  <div className={classNames(styles.borderedBlock, styles.detailsBlock)}>
                    <Checkbox
                      label='Post against vehicles'
                      onRenderLabel={renderLargeLabel}
                      className={classNames(styles.baselineItems, styles.paddedElement)}
                      name='postAgainstVehicles'
                      checked={localState.postAgainstVehicles}
                      onChange={onChangeCheckbox}
                    />
                    <Checkbox
                      label='Post Odometer Reading'
                      onRenderLabel={renderLargeLabel}
                      className={classNames(styles.baselineItems, styles.paddedElement)}
                      name='postOdometerReading'
                      checked={localState.postOdometerReading}
                      onChange={onChangeCheckbox}
                    />
                    <Checkbox
                      label='Warn if monthly units are more than'
                      onRenderLabel={(labelProps) => renderLargeLabelWithTextField(labelProps, monthlyUnitsProps)}
                      className={classNames(styles.baselineItems, styles.paddedElement)}
                      name='warnIfMonthlyUnitsAreMoreThan'
                      checked={localState.warnIfMonthlyUnitsAreMoreThan}
                      onChange={onChangeCheckbox}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames('ms-Grid-col', 'ms-lg4', 'ms-xs12', styles.buttonsWrapperBlock)}>
              <div>
                <Text variant='xLarge' className={styles.highlight}>Spare Stock Options</Text>
                <div className={classNames(styles.borderedBlock, styles.detailsBlock)}>
                  <div className='ms-Grid-row'>
                    <div className={classNames('ms-Grid-col', 'ms-lg6', 'ms-xs12')}>
                      <Text variant="large" block className={styles.highlight}>Last Annual Done</Text>
                      <Text variant='xLarge' block className={styles.highlight}>
                        {localState.lastAnnualDone && moment(localState.lastAnnualDone).format('MM/DD/YYYY')}
                      </Text>
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-lg6', 'ms-xs12')}>
                      <Text variant="large" block className={styles.highlight}>Notification Frequency</Text>
                      <Text variant='xLarge' block className={styles.highlight}>
                        {localState.notificationFrequency}
                      </Text>
                    </div>
                  </div>
                  <SeparatorGy />
                  <div className='ms-Grid-row'>
                    <div className={classNames('ms-Grid-col', 'ms-lg6', 'ms-xs12')}>
                      <Text block className={styles.highlight}>Notification Letter Sent</Text>
                      <DatePicker
                        placeholder='Select a date'
                        value={isNil(localState.notificationLetterSent) || isEmpty(localState.notificationLetterSent) ? undefined : new Date(localState.notificationLetterSent)}
                        onSelectDate={(newDate: Date | null | undefined) => onChangeDate('notificationLetterSent', newDate)}
                        formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                      />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-lg6', 'ms-xs12')}>
                      <Text block className={styles.highlight}>Next Annual Due</Text>
                      <DatePicker
                        placeholder='Select a date'
                        value={isNil(localState.nextAnnualDue) || isEmpty(localState.nextAnnualDue) ? undefined : new Date(localState.nextAnnualDue)}
                        onSelectDate={(newDate: Date | null | undefined) => onChangeDate('nextAnnualDue', newDate)}
                        formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Stack horizontal className={styles.buttonsGroup}>
                <DefaultButton onClick={() => { setLocalState(customerDetails); }} text='Cancel' disabled={!stateWasChanged || !userPermissions.isWrite} />
                <PrimaryButton
                  id="saveButton"
                  onClick={updateRecordKeeping}
                  text='Save'
                  disabled={!stateWasChanged || !userPermissions.isWrite}
                />
              </Stack>
            </div>
          </div>}
      </div>
      {loading && <LoadingScreen />}
    </>
  );
};

export default RecordKeepingTab;
