import { IDropdownOption } from '@fluentui/react';
import { HeadCell, ISorting } from '../../../../../shared/DataGridComponent/DataGridModels';

export const defaultSorting: ISorting = {
  column: 'tireState',
  order: 'desc',
};

export const selectedBillingBasisForOptions: Array<IDropdownOption> = [
  { key: 'both', text: 'Both' },
  { key: 'orig', text: 'Orig' },
  { key: 'rtrd', text: 'Rtrd' },
];

export const selectedQualifierOptions: Array<IDropdownOption> = [
  { key: 'Higher', text: 'Higher' },
  { key: 'Lower', text: 'Lower' },
  { key: 'Use', text: 'Use' },
];

export const selectedBasisOptions: Array<IDropdownOption> = [
  { key: 'Avg', text: 'Avg' },
  { key: 'NAM', text: 'NAM' },
  { key: 'Fixed Base', text: 'Fixed Base' },
  { key: 'Bonus', text: 'Bonus' },
];

const selectedAvgMonthsOptions: Array<IDropdownOption> = [
  { key: 6, text: '6' },
  { key: 12, text: '12' },
];

export const yesNotOptions: Array<IDropdownOption> = [
  { key: 'Y', text: 'Y' },
  { key: 'N', text: 'N' },
];

export const BillinBasisHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Billing Basis',
    fieldName: 'tireState',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Orig Billing',
    fieldName: 'origBilling',
    typeField: 'text',
    isEditable: true,
    selectableOptions: yesNotOptions,
  },
  {
    key: 'column2',
    name: 'Orig Tread',
    fieldName: 'origTread',
    typeField: 'text',
    isEditable: true,
    selectableOptions: yesNotOptions,
  },
  {
    key: 'column3',
    name: 'Orig Qualifier',
    fieldName: 'origQualifier',
    typeField: 'text',
    isEditable: true,
    selectableOptions: selectedQualifierOptions,
  },
  {
    key: 'column4',
    name: 'Orig Basis 1',
    fieldName: 'origBasis1',
    typeField: 'text',
    isEditable: true,
    selectableOptions: selectedBasisOptions,
  },
  {
    key: 'column5',
    name: 'Orig Basis 2',
    fieldName: 'origBasis2',
    typeField: 'text',
    isEditable: true,
    selectableOptions: selectedBasisOptions,
  },
  {
    key: 'column6',
    name: 'Rtrd Billing',
    fieldName: 'rtrdBilling',
    typeField: 'text',
    isEditable: true,
    selectableOptions: yesNotOptions,
  },
  {
    key: 'column7',
    name: 'Rtrd Tread',
    fieldName: 'rtrdTread',
    typeField: 'text',
    isEditable: true,
    selectableOptions: yesNotOptions,
  },
  {
    key: 'column8',
    name: 'Rtrd Qualifier',
    fieldName: 'rtrdQualifier',
    typeField: 'text',
    isEditable: true,
    selectableOptions: selectedQualifierOptions,
  },
  {
    key: 'column9',
    name: 'Rtrd Basis 1',
    fieldName: 'rtrdBasis1',
    typeField: 'text',
    isEditable: true,
    selectableOptions: selectedBasisOptions,
  },
  {
    key: 'column10',
    name: 'Rtrd Basis 2',
    fieldName: 'rtrdBasis2',
    typeField: 'text',
    isEditable: true,
    selectableOptions: selectedBasisOptions,
  },
  {
    key: 'column11',
    name: 'Orig Avg Months',
    fieldName: 'origAvgMonths',
    typeField: 'text',
    isEditable: true,
    selectableOptions: selectedAvgMonthsOptions,
  },
  {
    key: 'column12',
    name: 'Rtrd Avg Months',
    fieldName: 'rtrdAvgMonths',
    typeField: 'text',
    isEditable: true,
    selectableOptions: selectedAvgMonthsOptions,
  },
];

export const getOptionKeyByText = (array: any, textToFind: any) =>
  array.filter(({ text }: any) => text === textToFind)[0]?.key;

export const getOptionTextByKey = (array: any, keyToFind: any) =>
  array.filter(({ key }: any) => key === keyToFind)[0]?.text;

export const getBillingBasisForKey = (isOrigBilling: boolean, isRtrdBilling: boolean) => {
  if (isOrigBilling && isRtrdBilling) return 'both';
  if (isOrigBilling) return 'orig';
  if (isRtrdBilling) return 'rtrd';
};