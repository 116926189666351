import { exportTestInstance, managementTestInstance } from '../axios';

const getRoles = (pagination: any, sortOrder: any) =>
  managementTestInstance.post('/security/roles/list', { pagination, sortOrder });

const cloneRole = (roleId: any, newRoleName: any) =>
  managementTestInstance.post('/security/roles/clone', { roleId, newRoleName });

const getModules = () =>
  managementTestInstance.post('/security/modules/list', { pagination: { pageSize: 100, pageNumber: 1 }, sortOrder: null });

const getRoleModules = (rolId: any) =>
  managementTestInstance.post('/security/roles/modules/list', { rolId, pagination: { pageSize: 100, pageNumber: 1 }, sortOrder: null });

const getRolePermissions = (rolId: any, pagination: any, sortOrder: any, filters: any) =>
  managementTestInstance.post('/security/roles/permissions/list', { rolId, pagination, sortOrder, filters });

const createNewRole = (name: any, modules: any) =>
  managementTestInstance.post('/security/roles/permissions/add', {
    roles: [{
      name,
      modules,
    }],
  });

const updateRolePermissions = (id: any, applications: any) =>
  managementTestInstance.put('/security/roles/permissions/update', {
    roles: [{
      id,
      applications,
    }],
  });

const updateRoleModules = (id: any, modules: any) =>
  managementTestInstance.put('/security/roles/permissions/update', {
    roles: [{
      id,
      modules,
    }],
  });

const getUsers = (pagination: any, sortOrder: any, filters: any) =>
  managementTestInstance.post('/user/list', { pagination, sortOrder, filters });

const generateAuthorizationReportExcel = (input: any) =>
  exportTestInstance.post('/authorization-report/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const generateAuthorizationReportPdf = (input: any) =>
  exportTestInstance.post('/authorization-report/export-pdf', input, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const printRolePermissionsExcel = (input: any) =>
  exportTestInstance.post('/role-permission-report/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printRolePermissionsPdf = (input: any) =>
  exportTestInstance.post('/role-permission-report/export-pdf', input, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const listMakes = (input: any) =>
  managementTestInstance.post('/maintain-make', input);

const addMake = (input: any) =>
  managementTestInstance.post('/maintain-make/add', input);

const updateMake = (input: any) =>
  managementTestInstance.put('/maintain-make', input);

const deleteMakes = (ids: Array<any>) =>
  managementTestInstance.delete('/maintain-make', { data: { ids } });

const listModels = (input: any) =>
  managementTestInstance.post('/maintain-model', input);

const addModel = (input: any) =>
  managementTestInstance.post('/maintain-model/add', input);

const updateModel = (input: any) =>
  managementTestInstance.put('/maintain-model', input);

const deleteModels = (ids: Array<any>) =>
  managementTestInstance.delete('/maintain-model', { data: { ids } });

const getUserStatuses = () =>
  managementTestInstance.get('/user/statuses');

const updateUsers = (payload: any) =>
  managementTestInstance.put('/user/update', payload);

export default {
  getRoles,
  cloneRole,
  getModules,
  getRoleModules,
  getRolePermissions,
  createNewRole,
  updateRoleModules,
  updateRolePermissions,
  getUsers,
  printRolePermissionsExcel,
  printRolePermissionsPdf,
  generateAuthorizationReportExcel,
  generateAuthorizationReportPdf,
  getUserStatuses,
  listMakes,
  addMake,
  updateMake,
  deleteMakes,
  listModels,
  addModel,
  updateModel,
  deleteModels,
  updateUsers,
};
