import apiService from '../../api';
import { auth_quote_organizeFleet, auth_terms_organizeFleet } from '../../consts/programKeys';

export interface OrganizeFleetHOCProps {
  customerId: string,
  parentComponentName: 'terms' | 'quote';
  disableEditing?: boolean;
};

export const apiModules = {
  terms: {
    getRateGroupsAPI: apiService.terms.getRateGroups,
    getMakeModelsAPI: apiService.terms.getMakeModels,
    deleteRateGroupsAPI: apiService.terms.deleteRateGroups,
    deleteMakeModelsAPI: apiService.terms.deleteMakeModels,
    addRateGroupAPI: apiService.terms.addRateGroups,
    addMakeModelAPI: apiService.terms.addMakeModels,
    updateMakeModelsAPI: apiService.terms.updateMakeModels,
    updateRateGroupsAPI: apiService.terms.updateRateGroups,
    cloneMakeModelsAPI: apiService.terms.cloneMakeModels,
  },
  quote: {
    getRateGroupsAPI: apiService.quotesAPI.getRateGroups,
    getMakeModelsAPI: apiService.quotesAPI.getMakeModels,
    deleteRateGroupsAPI: apiService.quotesAPI.deleteRateGroups,
    deleteMakeModelsAPI: apiService.quotesAPI.deleteMakeModels,
    addRateGroupAPI: apiService.quotesAPI.addRateGroups,
    addMakeModelAPI: apiService.quotesAPI.addMakeModels,
    updateMakeModelsAPI: apiService.quotesAPI.updateMakeModels,
    updateRateGroupsAPI: apiService.quotesAPI.updateRateGroups,
    cloneMakeModelsAPI: apiService.quotesAPI.cloneMakeModels,
  },
};

export const userPermissionKeys = {
  terms: auth_terms_organizeFleet,
  quote: auth_quote_organizeFleet,
};
  
