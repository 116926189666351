export interface IKeyAction {
  handleAdd?: any;
  handleClear?: any;
  handleSubmit?: any;
  handleClose?: any;
  handleEnter?: any;
}

export interface IKeyHandler {
  key: string,
  action: any
};

export class KeyHandlerClass {

  private keysHandler: Array<IKeyHandler> = [];

  constructor(keysActions: IKeyAction) {
    this.keysHandler = [
      {
        key: 'l',
        action: keysActions.handleClear,
      },
      {
        key: 'a',
        action: keysActions.handleAdd,
      },
      {
        key: 's',
        action: keysActions.handleSubmit,
      },
      {
        key: 'c',
        action: keysActions.handleClose,
      },
      {
        key: 'enter',
        action: keysActions.handleEnter,
      },
    ];
  }

  getKeysHandler = () => this.keysHandler;
  getActionByKey = (keyTonFind: string) => this.keysHandler.find(({ key }: any) => key === keyTonFind);
};