export const tireChangeTabs: ITireChangeTabs = {
  postByFootprint: 'Post by Footprint',
  postByB73Format: 'Post by B-73 Format',
  viewOpenWheels: 'View Open Wheels',
  postBackdatedChanges: 'Post Backdated Changes',
};

export interface ITireChangeTabs {
    postByFootprint: string;
    postByB73Format: string;
    viewOpenWheels: string;
    postBackdatedChanges: string;
}

export const downloadOptions = [
  {key: 'monthlyMileage', text: 'Monthly Mileage'},
  {key: 'tireChange', text: 'Tire Change'},
];