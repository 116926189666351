import { DefaultButton, Dropdown, IColumn, IconButton, IDropdownOption, MessageBarType, Modal, PrimaryButton, Text, TextField } from '@fluentui/react';
import { debounce, isNil } from 'lodash';
import { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import useNotifications from '../../../../../hooks/useNotifications';
import { IPaginationProps } from '../../../../Pagination/IPaginationProps';
import Pagination from '../../../../Pagination/Pagination';
import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import { productCodeColumns } from '../consts';
import { defaultCountOnPage, pageSizes } from './consts';
import { IProductCodesModalProps } from './IProductCodesModalProps';
import { IProductCodesModalState } from './IProductCodesModalState';
import styles from './ProductCodesModal.module.scss';
import apiService from '../../../../../api';
import { sortOrder } from '../../../../../consts/sortOrder';
import LoadingScreen from '../../../../LoadingScreen/LoadingScreen';


const ProductCodesModal: FC<IProductCodesModalProps> = ({ isOpen, onDismiss, onSubmit, preselectedKey, country }) => {
  const [state, setState] = useState<IProductCodesModalState>({
    productCodes: [],
    foundCount: 0,
    loading: false,
  });

  const [selectedKey, setSelectedKey] = useState<any>(preselectedKey);
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>(defaultCountOnPage);
  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [columnsState, setColumnsState] = useState<Array<any>>(productCodeColumns);

  const [searchedText, setSearchedText] = useState<any>();

  const { addNotification } = useNotifications();

  const getSortOrder = () => {
    const column = columnsState.find(({ isSorted }) => isSorted);
    if (isNil(column))
      return;
    const { fieldName, isSortedDescending } = column;
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const fetchProductCodes = async (pagination: any, sortOrder:any, searchString: string) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.getProductCodes({
        country,
        pagination,
        sortOrder,
        searchString,
      });
      const foundCount = data.total.found;
      setState(prev => ({ ...prev, productCodes: data.data, foundCount }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching product codes error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };
  
  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };
  
  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  
    setColumnsState(newColumns);
  };

  const handleOnChangeSearch = (_ev: any, value: any) => setSearchedText(value);

  const delayedFetchProductCodes = useCallback(debounce((pagination, sortOrder, searchString) => fetchProductCodes(pagination, sortOrder, searchString), 1000), [country]);

  useEffect(() => {
    fetchProductCodes({ pageNumber: paginationProps.current, pageSize: +countOnPage.key }, getSortOrder(), searchedText);
    setSelectedKey(preselectedKey);
  }, [paginationProps.current, countOnPage, columnsState]);

  useEffect(() => {
    delayedFetchProductCodes({ pageNumber: 1, pageSize: +countOnPage.key }, getSortOrder(), searchedText);
  }, [searchedText]);

  useEffect(() => {
    if(!isOpen) {
      setSearchedText(undefined);
      setCountOnPage(defaultCountOnPage);
    }
  }, [isOpen]);
  
  return (
    <Modal
      isOpen={isOpen}
      isBlocking={true}
      containerClassName={styles.modalContainer}
    >
      <div>
        <Text variant="xLarge">Available Product codes</Text>
        <IconButton
          id='closeModalButton'
          iconProps={{ iconName: 'Cancel' }}
          ariaLabel="Close popup modal"
          onClick={onDismiss}
        />
      </div>
      <div>
        <TextField
          id="searchString"
          className={styles.searchString}
          value={searchedText}
          placeholder="Enter search string"
          onChange={handleOnChangeSearch}
        />
        <Text variant="large">Show # of rows:&nbsp;</Text>
        <Dropdown
          options={pageSizes.map(pageSize => ({
            key: pageSize,
            text: pageSize.toString(),
          }))}
          selectedKey={countOnPage?.key}
          onChange={onChangeCountOnPage}
        />
        <SeparatorGy vertical />
        <Text variant="large">{state.foundCount} found</Text>
      </div>
      <div className={styles['table-wrapper']}>
        <table>
          <thead>
            <tr>
              {
                columnsState.map((item: any) => (
                  <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                ))
              }
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              state.foundCount ? state.productCodes.map((item: any) => (
                <tr>
                  {
                    columnsState.map((column: any) => (
                      <td>{(item[column.fieldName])}</td>
                    ))
                  }
                  <td>
                    <div className={styles.round}>
                      <input
                        type="checkbox"
                        id={item.productCode}
                        checked={selectedKey?.productCode === item.productCode}
                        onChange={() => setSelectedKey(item)}
                      />
                      <label htmlFor={item.productCode}></label>
                    </div>
                  </td>
                </tr>
              )) :
                <tr><td colSpan={columnsState.length + 1}><span className={styles.emptyRowsTable}>No records found</span></td></tr>
            }
          </tbody>
        </table>
      </div>
      <div>
        <Pagination {...paginationProps} />
      </div>
      <div>
        <DefaultButton id='cancelBtn' text="Cancel" onClick={onDismiss} />
        <PrimaryButton id='okBtn' text="Ok" onClick={() => onSubmit(selectedKey)} disabled={isNil(selectedKey)} />
      </div>
      <div>
        {state.loading && <LoadingScreen />}
      </div>
    </Modal>
  );
};

export default ProductCodesModal;