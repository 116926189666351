import { HeadCell } from '../../../../../../shared/DataGridComponent/DataGridModels';

export const billingOptionsHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Billing Option',
    fieldName: 'billingOption',
    typeField: 'text',
    isShowing: true,
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Applicable',
    fieldName: 'applicable',
    typeField: 'boolean',
    isEditable: false,
    isShowing: true,
  },
  {
    key: 'column2',
    name: 'Bill By',
    fieldName: 'billBy',
    typeField: 'text',
    isEditable: false,
    isShowing: true,
  },
  {
    key: 'column3',
    name: 'Bill To',
    fieldName: 'billTo',
    typeField: 'text',
    isEditable: false,
    isShowing: true,
  },
  {
    key: 'column4',
    name: 'GenOpt',
    fieldName: 'genOpt',
    typeField: 'text',
    isEditable: false,
    isShowing: true,
  },
  {
    key: 'column9',
    name: 'LocCode',
    fieldName: 'locationId',
    typeField: 'text',
    isEditable: false,
    isShowing: true,
  },
  {
    key: 'column5',
    name: 'Regenerate',
    fieldName: 'regenerate',
    typeField: 'boolean',
    isEditable: false,
    isShowing: true,
  },
  {
    key: 'column6',
    name: 'Last Modified By',
    fieldName: 'lastModifiedBy',
    typeField: 'text',
    isEditable: false,
    isShowing: true,
  },
  {
    key: 'column7',
    name: 'Last Modification date',
    fieldName: 'lastModifiedOn',
    typeField: 'text',
    isEditable: false,
    isShowing: true,
  },
];
