import { FC, FormEvent, useState } from 'react';
import classNames from 'classnames';
import {
  DefaultButton,
  Dropdown,
  IconButton,
  IDropdownOption,
  IDropdownStyles,
  Label,
  Modal,
  PrimaryButton,
  Separator,
  Text,
} from '@fluentui/react';
import { isNil } from 'lodash';

import { IPrintingModalProps } from './IPrintingModalProps';
import { printingTypes } from './consts';

import styles from './PrintingModal.module.scss';

const PrintingModal: FC<IPrintingModalProps> = ({ isOpened, onPrint, onClose, isFileTypeExcel }) => {
  const [printingMethod, setPrintingMethod] = useState<IDropdownOption>();
  const excel = 'Excel';

  const onChangePrintMethod = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPrintingMethod(item);
  };

  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 150 } };

  return (
    <Modal
      key={'modalID'}
      isOpen={isOpened}
      onDismiss={onClose}
      containerClassName={styles.modalContainer}
    >
      <div className={styles.modalBody}>
        <div className='modalHeader'>
          <Text variant="xLarge">Print/Export</Text>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel='Close popup modal'
            onClick={onClose}
          />
        </div>
        <div className={classNames(styles.mainBlock, 'ms-Grid-row')}>
          <div className={classNames('ms-Grid-col', styles.centeredContentColumn)}>
            <Label htmlFor="printMethod">Export to:</Label>

            <Dropdown
              id="printMethod"
              selectedKey={printingMethod ? printingMethod.key : isFileTypeExcel ? excel : undefined}
              onChange={onChangePrintMethod}
              placeholder="Select an option"
              options={Object.values(printingTypes).map(printingType => ({
                key: printingType,
                text: printingType,
              }))}
              disabled={isFileTypeExcel ? true : false}
              styles={dropdownStyles}
            />
          </div>

          <div className={classNames('ms-Grid-col', styles.marginTop20)}>
            <Separator className={styles.separator} vertical />
          </div>

          <div className={classNames('ms-Grid-col', styles.centeredContentColumn)}>
            <PrimaryButton
              id='printExportModalBtn'
              text="Export"
              iconProps={{ iconName: 'Installation' }}
              onClick={() => onPrint(isFileTypeExcel ? excel : printingMethod?.key)}
              disabled={isFileTypeExcel ? false : isNil(printingMethod)}
              className={styles.button}
            />
            <DefaultButton
              text="Cancel"
              onClick={onClose}
              className={styles.button}
            />
          </div>
        </div>

      </div>
    </Modal>
  );
};

export default PrintingModal;
