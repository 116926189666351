import { FC, FormEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  DefaultButton,
  Dropdown,
  IconButton,
  IDropdownOption,
  IDropdownStyles,
  Label,
  Modal,
  PrimaryButton,
  Separator,
  Text,
} from '@fluentui/react';
import { isNil } from 'lodash';

import { IPrintingModalReportTypeProps } from './IPrintingModalReportTypeProps';
import { printingTypes, reportTypes } from './consts';

import styles from './PrintingModalReportType.module.scss';

const PrintingModalReportType: FC<IPrintingModalReportTypeProps> = ({ isOpened, onPrint, onClose }) => {
  const [printingMethod, setPrintingMethod] = useState<IDropdownOption>();
  const [reportType, setReportType] = useState<IDropdownOption>({
    key: reportTypes.midas,
    text: reportTypes.midas,
  });

  const onChangePrintMethod = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPrintingMethod(item);
  };

  const onChangeReportType = (event: FormEvent<HTMLDivElement>, item: any): void => {
    setReportType(item);
  };

  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 150 } };

  useEffect(() => {
    if (!isOpened) {
      setPrintingMethod(undefined);
      setReportType({
        key: reportTypes.midas,
        text: reportTypes.midas,
      });
    }
  }, [isOpened]);

  return (
    <Modal
      key={'modalID'}
      isOpen={isOpened}
      onDismiss={onClose}
      containerClassName={styles.modalContainer}
    >
      <div className={styles.modalBody}>
        <div className='modalHeader'>
          <Text variant="xLarge">Print/Export</Text>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel='Close popup modal'
            onClick={onClose}
          />
        </div>
        <div className={classNames(styles.mainBlock, 'ms-Grid-row')}>
          <div className={classNames('ms-Grid-col', styles.centeredContentColumn)}>
            <Label htmlFor="printMethod">Export to:</Label>
            <Dropdown
              id="printMethod"
              selectedKey={printingMethod ? printingMethod.key : undefined}
              onChange={onChangePrintMethod}
              placeholder="Select an option"
              options={Object.values(printingTypes).map(printingType => ({
                key: printingType,
                text: printingType,
              }))}
              styles={dropdownStyles}
            />
            <Label htmlFor="reportType">Report Type:</Label>
            <Dropdown
              id="reportType"
              selectedKey={reportType ? reportType.key : undefined}
              onChange={onChangeReportType}
              placeholder="Select an option"
              options={Object.values(reportTypes).map(reportType => ({
                key: reportType,
                text: reportType,
              }))}
              styles={dropdownStyles}
            />
          </div>

          <div className={classNames('ms-Grid-col', styles.marginTop20)}>
            <Separator className={styles.separator} vertical />
          </div>

          <div className={classNames('ms-Grid-col', styles.centeredContentColumn)}>
            <PrimaryButton
              id='printExportModalBtn'
              text="Export"
              iconProps={{ iconName: 'Installation' }}
              onClick={() => onPrint(printingMethod?.key, reportType?.key)}
              disabled={isNil(printingMethod)}
              className={styles.button}
            />
            <DefaultButton
              text="Cancel"
              onClick={onClose}
              className={styles.button}
            />
          </div>
        </div>

      </div>
    </Modal>
  );
};

export default PrintingModalReportType;
