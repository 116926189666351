interface IPlaceOriginalOrder {
  id: string,
  customerId: string,
  locationId: string,
  typeCode: string,
  billToSundry: string,
  enteredManually: boolean,
  sentToR3: boolean;
}

interface IPlaceOriginalOrderItem {
  invoiceId: string,
  warehouse: string,
  beginBrandPrefix: string,
  beginBrandNumber: string,
  endBrandNumber: string,
  beginBrandSuffix: string,
  quantity: string,
}

export const emptyPlaceOriginalOrder: IPlaceOriginalOrder = {
  id: '',
  customerId: '',
  locationId: '',
  typeCode: '',
  billToSundry: '',
  enteredManually: true,
  sentToR3: false,
};

export const emptyPlaceOriginalOrderItem: IPlaceOriginalOrderItem = {
  invoiceId: '',
  warehouse: '',
  beginBrandPrefix: '',
  beginBrandNumber: '',
  endBrandNumber: '',
  beginBrandSuffix: '',
  quantity: '',
};