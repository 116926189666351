import { auth_tireOrder_gbsErrorManagement } from '../../../consts/programKeys';
import { ITabComponent } from '../../../models/ITabComponent';
import GBSErrorManagementTab from '../../TireOrder/tabComponents/GBSErrorManagementTab';
import SearchCustomerLocation from './SearchCustomerLocation';
import SearchOrder from './SearchOrder';

export const emptySearchState = {
  id: '',
  dlNoteId: '',
  gdyrId: '',
  invoiceId: '',
};

export const emptyOrderInfo = {
  customerId: '',
  customerPrefix: '',
  customerName: '',
  customerNumber: '',
  locationId: '',
  locationCode: '',
  locationName: '',
  shipToNumber: '',
};

export const mockCustomerList = [
  {id: 1, customerNumber: '000568012', customerName: 'NYC Transit Authority', businessName: '', prefix: 'N', abbrName: 'NYC TRANS', city: 'New York', country: 'USA', state: 'New York', regionCode: '0801'}, 
  {id: 2, customerNumber: '000568013', customerName: 'Los Angeles Transit Authority', businessName: '', prefix: 'L', abbrName: 'LA TRANS', city: 'Los Angeles', country: 'USA', state: 'California', regionCode: '0802'}, 
  {id: 3, customerNumber: '000568014', customerName: 'Akron Transit Authority', businessName: '', prefix: 'A', abbrName: '', city: 'Akron', country: 'USA', state: 'Ohio', regionCode: '0803'},
];

export const mockCustomerSearch = [
  {id: 1, customerNumber: 'string', customerName: 'string', prefix: 'string', abbrName: 'string', regionCode: 'string'}, 
];

export const mockLocationsByCustomerId = [
  {id: 1, locationCode: 'string', locationName: 'string', shipToNumber: 'string', customerId: 1, addrLine1: 'string', addrLine2: 'string', city: 'string'},
];

export const mockTireOrders = [{
  beginBrand: 'string',
  billToSundry: 'string',
  contractId: 'string',
  createdBy: 'string',
  createdOn: 'string',
  customerId: 'string',
  dotQuantity: 'string',
  endBrand: 'string',
  fweb: 'string',
  gdyrId: 'string',
  id: 'string',
  lastModifiedBy: 'string',
  lastModifiedOn: 'string',
  locationId: 'string',
  orderDate: 'string',
  quantity: 1,
  recvDate: 'string',
  recvQuantity: 'string',
  shipDate: 'string',
  shipInstrs: 'string',
  shipToOem: 'string',
  shortQuantity: 'string',
  status: 'string',
  tc: 'string',
  typeCode: 'string',
  warhauseQuantity: 'string',
  xferQuantity: 'string',
}];

export enum SEARCH_TABS {
  SEARCH_A_CUSTOMER = 'searchACustomer',
  SEARCH_AN_ORDER = 'searchAnOrder',
  GBS_ORDER_ERROR_MANAGEMENT = 'gbsOrderErrorManagement',
};

export const searchTabs: ISearchTabs = {
  searchACustomer: {
    id: SEARCH_TABS.SEARCH_A_CUSTOMER,
    name: 'Search a customer',
    component: SearchCustomerLocation,
    subTabs: null,
  },
  searchAnOrder: {
    id: SEARCH_TABS.SEARCH_AN_ORDER,
    name: 'Search an Order',
    component: SearchOrder,
    subTabs: null,
  },
  gbsOrderErrorManagement: {
    id: SEARCH_TABS.GBS_ORDER_ERROR_MANAGEMENT,
    accessKey: auth_tireOrder_gbsErrorManagement,
    name: 'GBS Order Error Management',
    component: GBSErrorManagementTab,
    subTabs: null,
  },
};

export interface ISearchTabs {
  searchACustomer: ITabComponent;
  searchAnOrder: ITabComponent;
  gbsOrderErrorManagement: ITabComponent;
}