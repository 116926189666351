import { HeadCell } from '../DataGridComponent/DataGridModels';

export const errorsHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Error Type',
    fieldName: 'type',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Error description',
    fieldName: 'description',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Additional Info',
    fieldName: 'additionalInfo',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Date',
    fieldName: 'date',
    typeField: 'date',
  },
];