import { HeadCell } from '../DataGridComponent/DataGridModels';
import { IFormDataField } from '../FormBuilderComponent';

export const controlValueHeadCells: Array<HeadCell> = [
  {
    key: 'column0',
    name: 'Name',
    fieldName: 'name',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Description',
    fieldName: 'description',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Created By',
    fieldName: 'createdBy',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Created ON',
    fieldName: 'createdOn',
    typeField: 'text',
  },
  {
    key: 'column4',
    name: 'Last Modified By',
    fieldName: 'lastModifiedBy',
    typeField: 'text',
  },
  {
    key: 'column5',
    name: 'Last Modified On',
    fieldName: 'lastModifiedOn',
    typeField: 'text',
  },
];

export const CONTROL_VALUE_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'nameField',
    properties: {
      id: 'name',
      label: 'Name',
      name: 'key',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'descriptionField',
    properties: {
      id: 'description',
      label: 'Description',
      name: 'description',
      type: 'text',
      disabled: false,
    },
  },
];
