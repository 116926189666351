export const columns  = [
  {
    key: 'column0',
    name: 'Prefix',
    fieldName: 'pfx',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'Brand Number',
    fieldName: 'bno',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Suffix',
    fieldName: 'sfx',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'Submit Info',
    fieldName: 'submitInfo',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'Submit',
    fieldName: 'submit',
    isSorted: false,
    isSortedDescending: false,
  },
];

export const mockSpareStockList = [
  {
    id: 'string',
    pfx: 'string',
    bno: 0,
    sfx: 'string',
    submitInfo: 'string',
    submit: 'string',
    consignedTireId: 'string',
  },
];