import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { FunctionComponent, useEffect, useState } from 'react';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import { MIDAS_MODULES } from '../../models/EnumMidasModules';
import DataGridComponent from '../../shared/DataGridComponent';
import ModuleTitleComponent from '../../shared/ModuleTitleComponent';
import { getMenuOptionById } from '../Nav/consts';
import styles from './MyReports.module.scss';
import moment from 'moment';
import { ACTION_GROUPS_ENUM } from '../../shared/DataGridComponent/ActionsComponent/ActionsGroupEnum';
import { reportsHeadCell } from './consts';
import apiService from '../../api';
import useNotifications from '../../hooks/useNotifications';
import { MessageBarType } from '@fluentui/react';
import { INotification } from '../../models/INotification';


const MyReports: FunctionComponent = () => {
  const { addNotification } = useNotifications();
  const [reports, setReports] = useState<any[]>([]);
  const [isLoading, { toggle: toggleShowLoading }] = useBoolean(false);
  const [totalFound, setTotalFound] = useState(0);
  const [availableReportsSelected, setAvailableReportsSelected] = useState<Array<any>>([]);
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });


  const moduleInfo = getMenuOptionById(MIDAS_MODULES.MY_REPORTS);

  const fetchReports = async (
    pagination?: any,
    sortOrder?: any,
    searchedText?: string,
  ) => {
    try {
      const { data: { data, total } } = await apiService.billingAPI.getMyReports(pagination, sortOrder);
      setReports(data);
      setTotalFound(total.found);
    } catch (e: any) {
      addNotification({
        text: 'Fetching error of My reports',
        type: MessageBarType.error,
      });
    }
  };

  const handleSelectRow = (reportsSelected: Array<any>) => setAvailableReportsSelected(reportsSelected);

  const handleDeleteReports = async (rows: any[] = []) => {
    toggleShowLoading();
    const reportIds = getIdsFromReports(availableReportsSelected);
    await fetchDeleteReports(reportIds);
    toggleShowLoading();
  };

  const fetchDeleteReports = async (ids: Array<string>) => {
    try {
      await apiService.billingAPI.deleteReports(ids);
      setNotification({ text: `${ids.length} quotes deleted successfully`, type: MessageBarType.success });
    } catch (error) {
      setNotification({ text: 'Deleting quotes error', type: MessageBarType.error });
    }
  };

  /** TODO: Integrate sorting, pagination and rows per page */
  const handleChangeDataGridState = async (dataGridState: any) => {
    toggleShowLoading();
    const { countOnPage, paginationProps, sortOrder, searchedText } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    await fetchReports(pagination, sortOrder);
    toggleShowLoading();
  };

  const getIdsFromReports = (reportsArray: Array<any>) => reportsArray?.map((reports: any) => reports.mCdScheduleReportFileQueueId);

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  return (
    <>
      <div className="ms-Grid record-keeping-container mainContainer" dir="ltr">
        <ModuleTitleComponent moduleInfo={moduleInfo} />
        <div className='ms-Grid-row'>
          <div className={classNames(
            'ms-Grid-col',
            'ms-lg12',
            'tabulatorWrapper',
            styles.headerTabsContainer,
          )}>
            <div style={{
              marginTop: '2rem',
            }}>
              <DataGridComponent
                actionsGroupName={ACTION_GROUPS_ENUM.DOWNLOAD_REPORT}
                idTable={'my-reports'}
                title='My Reports'
                headCells={reportsHeadCell}
                rowsTable={reports}
                totalDataFound={totalFound}
                enableCheckBox
                enableMultiSelectRow
                enableRefreshOption
                enablePagination
                enableSorting
                enableRowsPerPage
                enableDeleteOption
                handleChangeDataGridState={handleChangeDataGridState}
                handleDelete={handleDeleteReports}
                handleSelectRow={handleSelectRow}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyReports;