import { HeadCell } from '../DataGridComponent/DataGridModels';

export interface WarehouseModalComponentProps {
  id: string;
  title: string;
  type: string;
  isOpen: boolean;
  onSubmit: (value?:any) => void;
  onDismiss: () => void;
  successLabel: string;
  cancelLabel: string;
}

export const wareHouseHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Plant Number',
    fieldName: 'plantNo',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Name',
    fieldName: 'name',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Address 1',
    fieldName: 'addrLine1',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Address 2',
    fieldName: 'addrLine2',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'City',
    fieldName: 'city',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'State',
    fieldName: 'state',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Country',
    fieldName: 'country',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Radial Bias',
    fieldName: 'radialOrBias',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Zip',
    fieldName: 'zip',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Phone',
    fieldName: 'phone1',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Fax',
    fieldName: 'fax',
    typeField: 'text',
  },
];