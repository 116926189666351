import { FunctionComponent } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { SelectionMode } from '@react-pdf-viewer/selection-mode';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import renderCustomToolbar from './CustomToolbar/CustomToolbar';
import { IPdfViewerProps } from './IPdfViewerProps';

const PdfViewer: FunctionComponent<IPdfViewerProps> = ({ file, displayedFileName }) => {
  const customToolbarInstance = defaultLayoutPlugin({
    renderToolbar: (...params) => renderCustomToolbar(...params, displayedFileName),
    toolbarPlugin: {
      getFilePlugin: {
        fileNameGenerator: () => displayedFileName,
      },
      searchPlugin: {
        keyword: 'PDF',
      },
      selectionModePlugin: {
        selectionMode: SelectionMode.Hand,
      },
    },
  });

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
      <Viewer
        fileUrl={file}
        plugins={[customToolbarInstance]}
      />
    </Worker>

  );
};

export default PdfViewer;
