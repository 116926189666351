import { recordTestInstance, exportTestInstance } from '../axios';

const get = (id: string, vehNo: string, pagination: any, sortOrder: any, customerId: string) =>
  recordTestInstance.post('/registered-tire/history', { id, vehNo, pagination, sortOrder, customerId });

const printExcel = (registeredVehicleInput: any, headerFields: Array<any>) =>
  exportTestInstance.post('/registered-tire/history/export-excel', { registeredVehicleInput, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (registeredVehicleInput: any, headerFields: Array<any>) =>
  exportTestInstance.post('/registered-tire/history/export-pdf', { registeredVehicleInput, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  get,
  printExcel,
  printPdf,
};