export const pageSizes = [100, 250, 500];

export const columns = [
  {
    key: 'column0',
    name: 'Type',
    fieldName: 'type',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'Description',
    fieldName: 'description',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Additional Info',
    fieldName: 'additionalInfo',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'Date',
    fieldName: 'date',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'Time',
    fieldName: 'time',
    isSorted: false,
    isSortedDescending: false,
  },
];
