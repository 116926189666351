export const tireDetailsColumns = [
  {
    key: 'column1',
    name: 'Prefix',
    fieldName: 'prefix',
  },
  {
    key: 'column2',
    name: 'Brand',
    fieldName: 'brandNo',
  },
  {
    key: 'column3',
    name: 'Suffix',
    fieldName: 'suffix',
  },
  {
    key: 'column4',
    name: 'Status',
    fieldName: 'status',
  },
  {
    key: 'column5',
    name: 'Change Date',
    fieldName: 'chgDate',
  },
  {
    key: 'column6',
    name: 'Total Miles',
    fieldName: 'totalMiles',
  },
  {
    key: 'column7',
    name: 'Tire Size',
    fieldName: 'tireSize',
  },
  {
    key: 'column8',
    name: 'Type Code',
    fieldName: 'typeCode',
  },
  {
    key: 'column9',
    name: 'Prod Code',
    fieldName: 'prodCode',
  },
  {
    key: 'column10',
    name: 'TC',
    fieldName: 'tc',
  },
  {
    key: 'column11',
    name: 'Loc',
    fieldName: 'locationCode',
  },
  {
    key: 'column12',
    name: 'OOS Av',
    fieldName: 'oosAv',
  },
  {
    key: 'column13',
    name: 'Dot #',
    fieldName: 'dotNo',
  },
];
