import { FC, FormEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { debounce, get, isNil } from 'lodash';
import { useBoolean } from '@fluentui/react-hooks';
import {
  Dropdown,
  IColumn,
  IconButton,
  IDropdownOption,
  MessageBarType,
  Persona,
  PersonaSize,
  Text,
  TextField,
} from '@fluentui/react';

import apiService from '../../../../../../../../api';
import useNotifications from '../../../../../../../../hooks/useNotifications';
import { permissions } from '../../../../../../../../consts/permissions';
import { sortOrder } from '../../../../../../../../consts/sortOrder';

import SeparatorGy from '../../../../../../../SeparatorGy/SeparatorGy';
import LoadingScreen from '../../../../../../../LoadingScreen/LoadingScreen';
import Pagination from '../../../../../../../Pagination/Pagination';
import { IPaginationProps } from '../../../../../../../Pagination/IPaginationProps';

import UserDetailsModal from '../../../../../Users/UserDetailsModal/UserDetailsModal';

import { IUsersSubtabProps } from './IUsersSubtabProps';
import { IUsersSubtabState } from './IUsersSubtabState';
import { tableColumns, emptyFilters, allOption, pageSizes } from './consts';
import { IFiltersState } from './IFiltersState';

import styles from './UsersSubtab.module.scss';

const UsersSubtab: FC<IUsersSubtabProps> = ({ roleId }) => {

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const { addNotification } = useNotifications();

  const [state, setState] = useState<IUsersSubtabState>({
    items: [],
    userStatuses: [],
    loading: false,
    foundCount: 0,
  });

  const [columnsState, setColumnsState] = useState<Array<any>>(tableColumns);
  const [filtersState, setFiltersState] = useState<IFiltersState>(emptyFilters);
  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [userDetailsModalState, setUserDetailsModalState] = useState<any>({
    isOpened: false,
    userData: null,
  });
  const [isFirstLoading, { setFalse: wasFirstLoading }] = useBoolean(true);

  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const fetch = async () => {
    setState((prev: IUsersSubtabState) => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.management.getUsers(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        {
          accessTypes: [roleId],
          searchText: filtersState.searchString,
          ...(filtersState.userStatus[0] === allOption.key ? {} : { statuses: filtersState.userStatus }),
        },
      );
      const foundCount = data.total.found;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, selectedItem: null, foundCount }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching users error: ${get(response, 'data.message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchUserStatuses = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await apiService.management.getUserStatuses();
      setState((prev: IUsersSubtabState) => ({ ...prev, userStatuses: data }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching user statuses error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSearch = () => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    fetch();
  };

  const handleChangeSearchString = (event: any, searchString = '') => {
    setFiltersState((prev: IFiltersState) => ({ ...prev, searchString }));
  };

  const onChangeFilters = (item: any, filter: string, totalCount: number): void => {
    if (item && item.key === allOption.key) {
      setFiltersState((prev: IFiltersState) => ({ ...prev, [filter]: [item.key] }));
    } else if (item) {
      const allPos = get(filtersState, filter, []).indexOf(allOption.key);
      const prevArray = get(filtersState, filter, []);
      const prevArrayWithoutAll = prevArray.filter((m: any, i: number) => i !== allPos);
      setFiltersState((prev: IFiltersState) => ({
        ...prev,
        [filter]: item.selected ?
          [...(prevArrayWithoutAll.length === totalCount - 1 ? [allOption.key] : [item.key, ...prevArrayWithoutAll])] :
          [...prevArray.filter((key: any) => key !== item.key), ...(prevArray.length === 1 ? [allOption.key] : [])],
      }));
    }
  };

  const handleExpandUser = (userData: any) => {
    setUserDetailsModalState({
      isOpened: true,
      userData,
    });
  };

  const fetchAll = async () => {
    await fetchUserStatuses();
    await fetch();
    wasFirstLoading();
  };

  useEffect(() => {
    if (!isNil(roleId)) {
      if (isFirstLoading) {
        fetchAll();
      } else {
        fetch();
      }
    }
  }, [
    roleId,
    columnsState,
    paginationProps.current,
  ]);

  return (
    <>
      <div className="ms-Grid">
        <div className={classNames('ms-Grid-row', styles.mainRow)}>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <div className={styles.tabFiltersBlock}>
              <TextField
                id="searchString"
                value={filtersState.searchString}
                label="Search"
                placeholder="Enter search string"
                onChange={handleChangeSearchString}
              />
              <Dropdown
                label="Filter By User Status"
                selectedKeys={filtersState.userStatus}
                onChange={(ev: any, item: any) => onChangeFilters(item, 'userStatus', Object.values(permissions).length)}
                options={[allOption, ...Object.values(state.userStatuses).map((v: any) => ({ key: v, text: v }))]}
                multiSelect
              />
              <IconButton
                iconProps={{ iconName: 'Search' }}
                onClick={handleSearch}
              />
            </div>
            <div className={styles.tableHeading}>
              <div>
                <Text variant="xLarge">Users</Text>
                <SeparatorGy vertical />
                <Text variant="xLarge">{state.foundCount} found</Text>
              </div>
              <div>
                <Text variant="large">Show # of rows:&nbsp;</Text>
                <Dropdown
                  options={pageSizes.map(pageSize => ({
                    key: pageSize,
                    text: pageSize.toString(),
                  }))}
                  defaultSelectedKey={pageSizes[0]}
                  selectedKey={countOnPage?.key}
                  onChange={onChangeCountOnPage}
                />
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  {
                    columnsState.map(item => (
                      <th
                        key={item.name}
                        className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined}
                        onClick={() => onColumnClick(item)}
                      >
                        {item.name}
                      </th>
                    ))
                  }
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  state.items.map((item: any) => {
                    const { id, fullName, email, userId, accessTypes } = item;
                    return (
                      <tr key={id}
                        className={styles.trBasic}
                      >
                        <td>
                          <Persona
                            className={styles.personaInline}
                            showUnknownPersonaCoin
                            size={PersonaSize.size32}
                          // imageUrl={TestImages.personaFemale}
                          />
                          {fullName}
                        </td>
                        <td>{email}</td>
                        <td>{userId}</td>
                        <td>{accessTypes}</td>
                        <td className={styles.expandButtonContainer}>
                          <IconButton
                            iconProps={{ iconName: 'OpenInNewWindow' }}
                            onClick={() => handleExpandUser(item)}
                          />
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>

            {state.foundCount > countOnPage?.key && (
              <>
                <SeparatorGy />
                <Pagination {...paginationProps} />
              </>
            )}
          </div>
        </div>
      </div>
      {state.loading && <LoadingScreen />}
      <UserDetailsModal
        {...userDetailsModalState}
        onClose={() => setUserDetailsModalState({ userData: null, isOpened: false })}
      />
    </>
  );
};

export default UsersSubtab;
