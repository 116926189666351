import { HeadCell } from '../../shared/DataGridComponent/DataGridModels';

export const QuoteHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Customer Number',
    fieldName: 'customerNumber',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Prefix',
    fieldName: 'prefix',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Customer Name',
    fieldName: 'customerName',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Abbreviated Name',
    fieldName: 'abbrName',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'Region Code',
    fieldName: 'regionCode',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column5',
    name: 'City',
    fieldName: 'billToCity',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column6',
    name: 'State',
    fieldName: 'billToState',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column7',
    name: 'Zip Code',
    fieldName: 'billToZip',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column8',
    name: 'Business Name',
    fieldName: 'businessName',
    typeField: 'text',
    isEditable: false,
  },
];

type SortColumn = 'businessName' | 'abbrName' | 'billToCity' |
  'billToState' | 'billToCountry' | 'billToZip' | 'prefix' |
  'customerNumber' | 'customerName' | 'regionCode';

export const getSortColumn = (column: SortColumn) => {
  return sortMapper[column];
};

const sortMapper = {
  'businessName': 'bName',
  'abbrName': 'abbrname',
  'billToCity': 'city',
  'billToState': 'state',
  'billToCountry': 'country',
  'billToZip': 'zipcode',
  'prefix': 'custPrefix',
  'customerNumber': 'custName',
  'customerName': 'custName',
  'regionCode': 'regionCode',
};

export const quoteStatuses = {
  NEW: 'NEW',
  CREATED: 'CREATED',
  GENERATED: 'GENERATED',
  ACTIVATED: 'ACTIVATED',
  AUTHORIZED: 'AUTHORIZED',
  REGENERATED: 'REGENERATED',
};
