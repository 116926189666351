import { configureStore } from '@reduxjs/toolkit';
import notificationsReducer from './notificationsSlice';
import recordKeepingReducer from './recordKeepingSlice';
import tireOrderingReducer from './tireOrderingSlice';
import userReducer from './userSlice';
import massUploadSlice from './massUploadSlice';
import customerSlice from './customerSlice';
import quoteSlice from './quoteSlice';
import billingSlice from './billingSlice';
import contractSlice from './contractSlice';
import postSpareStockSlice from './postSpareStockSlice';

export const store = configureStore({
  reducer: {
    notifications: notificationsReducer,
    recordKeeping: recordKeepingReducer,
    tireOrdering: tireOrderingReducer,
    user: userReducer,
    massUpload: massUploadSlice,
    customer: customerSlice,
    quote: quoteSlice,
    billing: billingSlice,
    contract: contractSlice,
    postSpareStock: postSpareStockSlice, 
  },
});