import { recordTestInstance, exportTestInstance } from '../axios';

const deleteBackdated = (deletingList: Array<any>) =>
  recordTestInstance.delete('/tire-change/backdated/delete', { data: { ids: deletingList } });

const save = (data: Array<any>, customerId: string, locationId: string) =>
  recordTestInstance.post('/tire-change/backdated/save', { data, customerId, locationId });

const release = (data: Array<any>, customerId: any, locationId: any) =>
  recordTestInstance.post('/tire-change/backdated/release', {
    data,
    customerId,
    locationId,
  });

const get = (pagination: any, sortOrder: any, customerId: any, locationId: any) =>
  recordTestInstance.post('/tire-change/backdated', {
    pagination,
    sortOrder,
    customerId,
    locationId,
  });

const getErrors = (pagination: any, sortOrder: any, customerId: string, locationId: string) =>
  recordTestInstance.post('/tire-change/backdated/error', { pagination, sortOrder, customerId, locationId });

export const getPage = (pagination: any, sortOrder: any, customerId: number, locationId: number, postTableId: string) =>
  recordTestInstance.post('/tire-change/backdated/number-page', { pagination, sortOrder, customerId, locationId, postTableId });

const printExcel = (input: any, headerFields: Array<any>) =>
  exportTestInstance.post('/tire-change/post-back-changes/export-excel', { input, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (input: any, headerFields: Array<any>) =>
  exportTestInstance.post('/tire-change/post-back-changes/export-pdf', { input, headerFields }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  delete: deleteBackdated,
  save,
  release,
  get,
  getErrors,
  printExcel,
  printPdf,
  getPage,
};
