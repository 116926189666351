import { DefaultButton, MessageBarType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { FC, ReactElement, useState } from 'react';
import { IViewShortInShipmentTiresProps } from './IViewShortInShipmentTiresProps';
import { IViewShortInShipmentTiresState } from './IViewShortInShipmentTiresState';
import apiService from '../../../../../../api';
import { columns } from './consts';
import useNotifications from '../../../../../../hooks/useNotifications';
import moment from 'moment';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import DataGridComponent from '../../../../../../shared/DataGridComponent';
import { intransitPageSizes } from '../../consts';
import { inTransitOrderCodeSelector } from '../../../../../../redux/tireOrderingSlice';
import { useSelector } from 'react-redux';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import styles from './ViewShortInShipmentTires.module.scss';
import { initalDataGridState } from '../../../../../../shared/DataGridComponent/utils';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';


const ViewShortInShipmentTires: FC<IViewShortInShipmentTiresProps> = (): ReactElement => {
  const [state, setState] = useState<IViewShortInShipmentTiresState>({
    items: [],
    foundCount: 0,
    loading: false,
  });

  const [dataGridState, setDataGridState] = useState(initalDataGridState);

  const orderCode = useSelector(inTransitOrderCodeSelector);
  
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [showPrint, { toggle: toggleShowPrint }] = useBoolean(false);

  const [printOption, setPrintOption] = useState<'b36' | 'printShort'>('b36');

  const { addNotification } = useNotifications();

  const handleChangeDataGridState = async (dataGridState: any) => {
    await setDataGridState(dataGridState);
    if (orderCode) await fetchShortTires(dataGridState);
  };

  const fetchShortTires = async (dataGridState: any) => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.inTransitTiresAPI.getShortTires(
        orderCode.id,
        {
          pageSize: dataGridState.countOnPage.text,
          pageNumber: dataGridState.paginationProps.current,
        },
        dataGridState.sortOrder,
      );
      const foundCount = data.total.found;
      const items = data.data.map(({ lostDate, recvDate, lastModifiedOn, ...other }: any) => ({
        lostDate: moment(lostDate).format('MM/DD/YYYY'),
        recvDate: moment(recvDate).format('MM/DD/YYYY'),
        lastModifiedOn: moment(lastModifiedOn).format('MM/DD/YYYY'),
        ...other,
      }));
      setState((prev: any) => ({ ...prev, items, selectedItem: null, foundCount: foundCount }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching short tires error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const requestData = {
        shortTiresGetInput: {
          orderId: orderCode.id,
          pagination: {
            pageSize: dataGridState.countOnPage.text,
            pageNumber: dataGridState.paginationProps.current,
          },
          sortOrder: dataGridState.sortOrder,
        },
        headerFields: [],
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.inTransitTiresAPI.printExcel(requestData) :
        await apiService.inTransitTiresAPI.printPdf(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
      toggleShowPrintExport();
    } catch (e: any) {
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handlePrintOption = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const requestData = {
        input: {
          orderId: orderCode.id,
          pagination: {
            pageSize: dataGridState.countOnPage.text,
            pageNumber: dataGridState.paginationProps.current,
          },
          sortOrder: dataGridState.sortOrder,
        },
        headerFields: [],
      };

      let result;
      if (printOption === 'b36') {
        result = printingType === printingTypes.excel ?
          await apiService.inTransitTiresAPI.printExcelB36InTransitTires(requestData) :
          await apiService.inTransitTiresAPI.printPDFB36InTransitTires(requestData);
      } else {
        result = printingType === printingTypes.excel ?
          await apiService.inTransitTiresAPI.printExcelShortInTransitTires(requestData) :
          await apiService.inTransitTiresAPI.printPDFShortInTransitTires(requestData);
      }

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(result?.data, printingType);
      toggleShowPrint();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  return (
    <>
      <DataGridComponent
        idTable={'table'}
        title='Short In-Transit Tires'
        headCells={columns}
        rowsTable={state.items}
        defaultRowsPerPage={intransitPageSizes}
        totalDataFound={state.foundCount}
        enablePagination
        enableRowsPerPage
        handleChangeDataGridState={handleChangeDataGridState}
        isLoading={state.loading}
      />
      <div className={styles.buttonsWrapper}>
        <DefaultButton onClick={toggleShowPrintExport} text="Print/Export" />
        <DefaultButton onClick={() => { 
          setPrintOption('b36');       
          toggleShowPrint();
        }} 
        text="Print B-36 Corrected" 
        />
        <DefaultButton onClick={() => {
          setPrintOption('printShort');
          toggleShowPrint();
        }} 
        text="Print Short In Shipment" 
        />
      </div>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      <PrintingModal
        isOpened={showPrint}
        onClose={toggleShowPrint}
        onPrint={handlePrintOption}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default ViewShortInShipmentTires;