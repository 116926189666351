import { recordTestInstance, exportTestInstance } from '../axios';

const get = (pagination: any, filters: any, sortOrder: any, customerId: number) =>
  recordTestInstance.post('/reinstate', { pagination, filters, sortOrder, customerId });

const deleteReinstate = (ids: Array<any>) =>
  recordTestInstance.delete('/reinstate/delete', { data: { ids } });

const check = (data: Array<any>, customerId: string, locationId: string, reinstateAnyway?: boolean) =>
  recordTestInstance.post('/reinstate/check', { data, customerId, locationId, reinstateAnyway });

const save = (data: Array<any>, customerId: string, locationId: string, reinstateAnyway?: boolean) =>
  recordTestInstance.post('/reinstate/save', { data, customerId, locationId, reinstateAnyway });

const submit = (pagination: any, filters: any, sortOrder: any, customerId: number, reinstateAnyway?: boolean) =>
  recordTestInstance.post('/reinstate/submit', { pagination, filters, sortOrder, customerId, reinstateAnyway });

const status = (pagination: any, filters: any, sortOrder: any, customerId: number) =>
  recordTestInstance.post('/reinstate/status', { pagination, filters, sortOrder, customerId });

const printExcel = (input: any) =>
  exportTestInstance.post('/reinstate/export-excel', { input }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (input: any) =>
  exportTestInstance.post('/reinstate/export-pdf', { input }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });


export default {
  get,
  delete: deleteReinstate,
  check,
  save,
  submit,
  printExcel,
  printPdf,
  status,
};
