import { FC, ReactElement, useEffect, useState } from 'react';
import { ISearchProps } from './ISearchProps';
import { DefaultButton, Icon, IIconProps, MessageBarType, PrimaryButton, Separator, Text, TextField } from '@fluentui/react';
import apiService from '../../../api';
import styles from './Search.module.scss';
import SeparatorGy from '../../SeparatorGy/SeparatorGy';
import classNames from 'classnames';
import useNotifications from '../../../hooks/useNotifications';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { customerSelector, locationSelector, setAvailableLocations, setCustomer, setLocation } from '../../../redux/recordKeepingSlice';
import { emptyOrderInfo, emptySearchState } from './consts';
import { orderCodeSelector, setOrderCode } from '../../../redux/tireOrderingSlice';

const SearchOrder: FC<ISearchProps> = ({ callbackLoading }): ReactElement => {
  const [state, setState] = useState({
    loading: false,
  });

  const [searchState, setSearchState] = useState(emptySearchState);

  const selectedCustomer = useSelector(customerSelector);
  const selectedLocation = useSelector(locationSelector);
  const orderCode = useSelector(orderCodeSelector);

  const [orderInfo, setOrderInfo] = useState(emptyOrderInfo);

  const [isSearchCollapsed, setIsSearchCollapsed] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { addNotification } = useNotifications();

  const upIcon: IIconProps = { iconName: 'ChevronUp' };
  const downIcon: IIconProps = { iconName: 'ChevronDown' };

  const fetch = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.tireOrders.get(
        { pageNumber: 1, pageSize: 1 },
        searchState,
        null,
        null,
        null,
      );
      const item = data.data[0];
      if (item) {
        dispatch(setOrderCode(item));
        setSearchState((prev) => ({ ...prev, id: item.id, gdyrId: item.gdyrId }));
        fetchCustomer(item.customerId);
        fetchLocations(item.customerId, item.locationId);
      } else {
        dispatch(setCustomer(null));
        dispatch(setLocation({ id: null }));
        setSearchState(emptySearchState);
        setOrderInfo(emptyOrderInfo);
        addNotification({
          text: 'Fetching orders error: Order doesn\'t exist',
          type: MessageBarType.error,
        });
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching orders error: ${get(response, 'data.message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const fetchCustomer = async (customerId: string) => {
    try {
      setSearchState(prev => ({ ...prev, loading: true }));
      const { data }: any = await apiService.getCustomerSearch(
        null,
        null,
        null,
        customerId,
      );
      const item = data.data.length ? data.data[0] : null;
      item ? setOrderInfo((prev) => ({ ...prev, customerPrefix: item.prefix, customerName: item.customerName, customerNumber: item.customerNumber })) : null;
      dispatch(setCustomer(item));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Customer fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setSearchState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchLocations = async (customerId: string, locationId: string) => {
    try {
      setSearchState(prev => ({ ...prev, loading: true }));
      const { data }: any = await apiService.getLocationsByCustomerId(
        customerId,
        { pageSize: 5000, pageNumber: 1 },
        {},
      );
      const items = data.data;
      const location = locationId && items.length ? items.find((item: any) => item.id === locationId) : null;
      location ? setOrderInfo((prev) => ({ ...prev, locationCode: location.locationCode, locationName: location.locationName, shipToNumber: location.shipToNumber })) : null;
      location ? dispatch(setLocation(location)) : null;
      dispatch(setAvailableLocations(items));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Locations fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setSearchState(prev => ({ ...prev, loading: false }));
    }
  };

  const onChangeSearchField = (field: any, value: any, regExp?: RegExp) => {
    setSearchState((prev: any) => ({ ...prev, [field]: regExp ? (regExp.test(value) ? value : prev[field]) : value }));
  };

  useEffect(() => {
    if (callbackLoading) {
      callbackLoading(state.loading);
    }
  }, [state.loading]);

  useEffect(() => {
    if (orderCode) setSearchState((prev) => ({ ...prev, id: orderCode.id, gdyrId: orderCode.gdyrId }));
  }, []);

  return (
    <div>
      {!isSearchCollapsed && <SeparatorGy />}
      <div
        className={classNames('ms-Grid-row', 'record-keeping-body', {
          'collapsed-block': isSearchCollapsed,
        })}
      >
        <div className={classNames('ms-Grid-col ms-lg5')}>
          <div className="search-form-container">
            <div>
              <Text variant='xLarge' className={styles.highlight}>Search an Order</Text>
            </div>
            <div className="search-form-body">
              <TextField
                label="ORDER ID"
                value={searchState?.id}
                onChange={(e, value: any) => onChangeSearchField('id', value)}
              />
              <TextField
                label="DL Note #"
                onChange={(e, value: any) => onChangeSearchField('dlNoteId', value)}
              />
            </div>
            <div className="search-form-body">
              <TextField
                label="GDYR ORDER #"
                value={searchState?.gdyrId}
                onChange={(e, value: any) => onChangeSearchField('gdyrId', value)}
              />
              <TextField
                label="Invoice #"
                onChange={(e, value: any) => onChangeSearchField('invoiceId', value)}
              />
            </div>
          </div>
          <div className={classNames(styles.buttonsWrapper)}>
            <PrimaryButton
              id="searchButton"
              onClick={() => fetch()}
              text="Search"
              disabled={searchState.id === '' && searchState.dlNoteId === '' && searchState.gdyrId === '' && searchState.invoiceId === ''}
            />
          </div>
        </div>
        <div className={classNames('ms-Grid-col ms-lg1')}>
          <Separator className="separator" vertical />
        </div>
        <div className={classNames('ms-Grid-col ms-lg6')}>
          <div className="search-form-container">
            <div>
              <Text variant='xLarge' className={styles.highlight}>Order Info</Text>
            </div>
            <div className="search-form-body">
              <TextField
                label="Customer Prefix"
                value={selectedCustomer?.prefix}
                onRenderPrefix={() => <Icon iconName="FavoriteStar" />}
                disabled
              />
              <TextField
                label="Customer Name"
                value={selectedCustomer?.customerName}
                onRenderPrefix={() => <Icon iconName="Contact" />}
                disabled
              />
              <TextField
                label="Customer Number"
                value={selectedCustomer?.customerNumber}
                onRenderPrefix={() => <Icon iconName="Tag" />}
                disabled
              />
            </div>
          </div>
          <div className="search-form-container">
            <div className="search-form-body">
              <TextField
                label="Location Code"
                value={selectedLocation?.locationCode}
                onRenderPrefix={() => <Icon iconName="POISolid" />}
                disabled
              />
              <TextField
                label="Location Name"
                value={selectedLocation?.locationName}
                onRenderPrefix={() => <Icon iconName="POISolid" />}
                disabled
              />
              <TextField
                label="Ship to #"
                value={selectedLocation?.shipToNumber}
                onRenderPrefix={() => <Icon iconName="NumberSymbol" />}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <SeparatorGy alignContent="end" />
      <div className={styles.collapseActionContainer}>
        <DefaultButton
          onClick={() => setIsSearchCollapsed(!isSearchCollapsed)}
          iconProps={isSearchCollapsed ? downIcon : upIcon}
        >
          {isSearchCollapsed ? 'Expand' : 'Collapse'}
        </DefaultButton>
      </div>
    </div>
  );
};

export default SearchOrder;
