export const vehicleMilesTabs: IVehicleMilesTabs = {
  postMonthlyMiles: 'Post Monthly Miles',
  postPriorVehicleMiles: 'Post Prior Vehicle Miles',
  applyMilesToTires: 'Apply Miles To Tires',
  mileageAudit: 'Mileage Audit',
};

export interface IVehicleMilesTabs {
  postMonthlyMiles: string;
  postPriorVehicleMiles: string;
  applyMilesToTires: string;
  mileageAudit: string;
}
