import React, { FC, ReactElement, useEffect } from 'react';
import classNames from 'classnames';
import { MessageBarType, Pivot, PivotItem } from '@fluentui/react';

import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import {
  auth_tireChange_postBackdatedChanges,
  auth_tireChange_postByB73Format,
  auth_tireChange_postByFootprint,
  auth_tireChange_viewOpenWheels,
} from '../../../../consts/programKeys';
import apiService from '../../../../api';

import TabHeader from '../TabHeader/TabHeader';

import ViewOpenWheels from './tabComponents/ViewOpenWheels/ViewOpenWheels';
import PostByFootprint from './tabComponents/PostByFootprint/PostByFootprint';
import PostBackdatedChanges from './tabComponents/PostBackdatedChanges/PostBackdatedChanges';
import PostByB73Format from './tabComponents/PostByB73Format/PostByB73Format';


import { ITireChangeTabProps } from './TireChangeTabProps';
import { tireChangeTabs } from './consts';
import { customerSelector, setCustomerInformation } from '../../../../redux/recordKeepingSlice';
import { useDispatch, useSelector } from 'react-redux';
import useNotifications from '../../../../hooks/useNotifications';

const TireChangeTab: FC<ITireChangeTabProps> = (): ReactElement => {
  const [selectedKey, setSelectedKey] = React.useState(tireChangeTabs.postByFootprint);
  const dispatch = useDispatch();

  const { hasPermission } = useUserPermissions();
  const { id: customerId } = useSelector(customerSelector);
  const { addNotification } = useNotifications();
  const userPermissions_postBackdatedChanges = hasPermission(auth_tireChange_postBackdatedChanges);
  const userPermissions_postByB73Format = hasPermission(auth_tireChange_postByB73Format);
  const userPermissions_postByFootprint = hasPermission(auth_tireChange_postByFootprint);
  const userPermissions_viewOpenWheels = hasPermission(auth_tireChange_viewOpenWheels);

  const fetchCustomerData = async () => {
    try {
      const { data }: any = await apiService.getCustomerInformation(customerId);
      dispatch(setCustomerInformation(data.data));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Customer data fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  useEffect(() => {
    fetchCustomerData();
  }, []);

  return (
    <div>
      <TabHeader
        details
      />
      <Pivot
        className={classNames('pivot-container', 'pivot-gy-colors')}
        selectedKey={selectedKey}
        onLinkClick={handleLinkClick}
        getTabId={getTabId}
        linkFormat="tabs"
      >
        {userPermissions_postByFootprint.isAccess && <PivotItem headerText={tireChangeTabs.postByFootprint} itemKey={tireChangeTabs.postByFootprint}>
          <div className="vertical-pivot-container"><PostByFootprint /></div>
        </PivotItem>}
        {userPermissions_postByB73Format.isAccess && <PivotItem headerText={tireChangeTabs.postByB73Format} itemKey={tireChangeTabs.postByB73Format}>
          <div className="vertical-pivot-container"><PostByB73Format /></div>
        </PivotItem>}
        {userPermissions_viewOpenWheels.isAccess && <PivotItem headerText={tireChangeTabs.viewOpenWheels} itemKey={tireChangeTabs.viewOpenWheels}>
          <div className="vertical-pivot-container"><ViewOpenWheels /></div>
        </PivotItem>}
        {userPermissions_postBackdatedChanges.isAccess && <PivotItem headerText={tireChangeTabs.postBackdatedChanges} itemKey={tireChangeTabs.postBackdatedChanges}>
          <div className="vertical-pivot-container"><PostBackdatedChanges /></div>
        </PivotItem>}
      </Pivot>
    </div>
  );
};

export default TireChangeTab;
