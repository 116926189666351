import { FormEvent, useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import moment from 'moment';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IColumn,
  IconButton,
  IDropdownOption,
  MessageBarType,
  PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import apiService from '../../../../../../api';
import useNotifications from '../../../../../../hooks/useNotifications';
import { sortOrder } from '../../../../../../consts/sortOrder';
import { customerSelector, locationSelector, setTireForReinstate } from '../../../../../../redux/recordKeepingSlice';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../../Pagination/Pagination';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';
import { IRebrandTabState } from './IRebrandTabState';

import { IRebrandTabProps } from './IRebrandTabProps';
import styles from './RebrandTab.module.scss';
import { columns, emptyRebrandTire } from './consts';
import { pageSizes } from '../../../../../../consts/recordKeeping';
import ViewTireDetailsModal from '../../../../ViewTireDetailsModal/ViewTireDetailsModal';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_actionOnTires_rebrand } from '../../../../../../consts/programKeys';
import SelectingModal from '../../../../../SelectingModal/SelectingModal';
import { tireDetailsColumns } from '../../../../ViewTireDetails/consts';
import { IRebrandTire } from './IRebrandTire';


const RebrandTab = (props: IRebrandTabProps) => {

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_actionOnTires_rebrand);

  const dispatch = useDispatch();
  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);

  const [state, setState] = useState<IRebrandTabState>({
    items: [],
    selectedItems: [],
    loading: false,
    foundCount: 0,
  });

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const [filters, setFilters] = useState<any>({ searchString: null });
  const [detailsVisible, { toggle: toggleDetailsVisible }] = useBoolean(false);
  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [isEditedDialogVisible, { toggle: toggleEditedConfirmation }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [parsedErrors, setParsedErrors] = useState<any>([]);
  const [consignedTires, setConsignedTires] = useState<Array<any>>([]);
  const [showAModal, { toggle: toggleShowAModal }] = useBoolean(false);
  const [tireToBeAdded, setTireToBeAdded] = useState<IRebrandTire>(emptyRebrandTire);

  const setPfxTireToBeAdded = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, pfx: string | undefined) => {
    setTireToBeAdded((prev: any) => ({ ...prev, pfx: pfx?.toUpperCase() }));
  };
  const setBnoTireToBeAdded = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, bno: string | undefined) => {
    setTireToBeAdded((prev: any) => ({ ...prev, bno }));
  };
  const setSfxTireToBeAdded = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, sfx: string | undefined) => {
    setTireToBeAdded((prev: any) => ({ ...prev, sfx: sfx?.toUpperCase() }));
  };
  const setNewPfxTireToBeAdded = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newPfx: string | undefined) => {
    setTireToBeAdded((prev: any) => ({ ...prev, newPfx: newPfx?.toUpperCase() }));
  };
  const setNewBnoTireToBeAdded = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newBrand: string | undefined) => {
    setTireToBeAdded((prev: any) => ({ ...prev, newBrand }));
  };
  const setNewSfxTireToBeAdded = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newSfx: string | undefined) => {
    setTireToBeAdded((prev: any) => ({ ...prev, newSfx: newSfx?.toUpperCase() }));
  };

  const setItemToBeEdited = (id: string, field: string, value: any) => {
    setState((prev: any) => ({
      ...prev,
      items: prev.items.map((item: any) => item.id === id ? { ...item, [field]: value } : item),
    }));
  };

  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
      };
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.rebrand.printExcel(requestData, []) :
        await apiService.rebrand.printPdf(requestData, []);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const openViewTireDetailsWindow = (item: any) => {
    const { consignedTireId, pfx, bno, sfx } = item;
    dispatch(setTireForReinstate({ tireId: consignedTireId, pfx, bno, sfx }));
    toggleDetailsVisible();
  };

  const handleSave = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.rebrand.save([...state.items], customerId, locationId, false);
      addNotification({
        text: 'Tire was successfully added.',
        type: MessageBarType.success,
      });
      await fetchRebrand();
    } catch (e: any) {
      setState(prev => ({ ...prev, loading: false }));
      const { response } = e;
      setParsedErrors([]);
      switch (response.status) {
        case 400:
          setParsedErrors(response.data.state);
          return addNotification({
            text: 'Unable to save changes.',
            type: MessageBarType.error,
          });
        case 499:
          setParsedErrors(response.data.state);
          return addNotification({
            text: 'Unable to save changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Rebrand saving error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    }
  };

  const fetchAllTiresByPfxBnoSfx = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.viewTireDetails.getAllTireDetailsBrand({ prefix: tireToBeAdded.pfx, brand: tireToBeAdded.bno, suffix: tireToBeAdded.sfx }, customerId);
      setConsignedTires(data);
      data.length && toggleShowAModal();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Tire Details fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const setTireDetails = async (consignedTireId: any) => {
    toggleShowAModal();
    setState(prev => ({ ...prev, loading: true }));
    await handleAdd(consignedTireId);
    setState(prev => ({ ...prev, loading: false }));
  };

  const handleAdd = async (consignedTireId: string) => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.rebrand.save([{ pfx: tireToBeAdded.pfx, bno: tireToBeAdded.bno, sfx: tireToBeAdded.sfx, 
        newPfx: tireToBeAdded.newPfx, newBrand: tireToBeAdded.newBrand, newSfx: tireToBeAdded.newSfx, consignedTireId }], customerId, locationId, true);
      addNotification({
        text: 'Tire was successfully added.',
        type: MessageBarType.success,
      });
      await fetchRebrand();
      setTireToBeAdded(emptyRebrandTire);
    } catch (e: any) {
      setState(prev => ({ ...prev, loading: false }));
      const { response } = e;
      switch (response.status) {
        case 400:
          setParsedErrors(response.data.state);
          return addNotification({
            text: 'Unable to save changes.',
            type: MessageBarType.error,
          });
        case 499:
          await fetchAllTiresByPfxBnoSfx();
          return addNotification({
            text: `Rebrand saving error: ${response.data.state[0].message}`,
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Rebrand saving error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.rebrand.submit([...state.items], customerId, locationId);
      addNotification({
        text: 'Rebrand tires were successfully submitted.',
        type: MessageBarType.success,
      });
      if (paginationProps.current === 1) {
        await fetchRebrand();
      } else {
        await paginationProps.onChangePage(1);
      }
      setParsedErrors([]);
    } catch (e: any) {
      setState(prev => ({ ...prev, loading: false }));
      const { response } = e;
      setParsedErrors([]);
      switch (response.status) {
        case 400:
          setParsedErrors(response.data.state);
          return addNotification({
            text: 'Unable to submit changes.',
            type: MessageBarType.error,
          });
        case 499:
          setParsedErrors(response.data.state);
          return addNotification({
            text: `Rebrand submit error: ${response.data.state[0].message}`,
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Rebrand submit error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    }
  };

  const fetchRebrand = async () => {
    setParsedErrors([]);
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.rebrand.get(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        filters,
        sortOrder,
        customerId,
      );
      const foundCount = data.total.found;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      addNotification({
        text: `Rebrand fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.rebrand.delete(state.selectedItems);
      addNotification({
        text: 'Selected tire(s) were successfully deleted.',
        type: MessageBarType.success,
      });
      if (paginationProps.current === 1) {
        await fetchRebrand();
      } else {
        await paginationProps.onChangePage(1);
      }
    } catch (e: any) {
      setState(prev => ({ ...prev, loading: false }));
      const { response } = e;
      addNotification({
        text: `Rebrand deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const handleSearch = () => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    fetchRebrand();
  };

  const handleSelect = (e: any, itemId: any) => {
    const selectedRows = [...state.selectedItems];
    if (e.target.checked) {
      selectedRows.push(itemId);
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows.filter(row => row !== itemId) }));
    }
  };

  const handleSelectAll = (e: any, items: Array<any>) => {
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedItems: items })) : setState((prev: any) => ({ ...prev, selectedItems: [] }));
  };

  const parseErrors = (index: number, field: string) => {
    const customError = parsedErrors?.filter((error: { index: number; field: string; }) => error.index == index && error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  const highlightRowIfError = (index: number) => {
    if (parsedErrors.length > 0) {
      return parsedErrors.filter((error: { index: any; }) => error.index == index).length > 0;
    }
  };
  useEffect(() => {
    fetchRebrand();
  }, [
    paginationProps.current,
    countOnPage,
    columnsState,
    customerId,
    locationId,
  ]);

  return (
    <>
      <div className="ms-Grid">
        <div className={classNames('ms-Grid-row', styles.headingBlock)}>
          <div className="ms-Grid-col ms-sm2">
            <div>
              <Text block className={styles.highlight}>Incident Date</Text>
              <Text variant="xLarge" block className={styles.highlight}>{moment().format('MM/DD/YYYY')}</Text>
            </div>
          </div>
          <SeparatorGy vertical />
          <div className="ms-Grid-col ms-sm10">
            <div className={styles.tiresAddingBlock}>
              <div className="ms-TextField">
                <label>Prefix</label>
                <TextField
                  value={tireToBeAdded.pfx}
                  onChange={setPfxTireToBeAdded}
                />
              </div>
              <div className="ms-TextField">
                <label>Brand Number</label>
                <TextField
                  value={tireToBeAdded.bno}
                  onChange={setBnoTireToBeAdded}
                />
              </div>
              <div className="ms-TextField">
                <label>Suffix</label>
                <TextField
                  value={tireToBeAdded.sfx}
                  onChange={setSfxTireToBeAdded}
                />
              </div>
              <div className="ms-TextField">
                <label>New Prefix</label>
                <TextField
                  value={tireToBeAdded.newPfx}
                  onChange={setNewPfxTireToBeAdded}
                />
              </div>
              <div className="ms-TextField">
                <label>New Brand Number</label>
                <TextField
                  value={tireToBeAdded.newBrand}
                  onChange={setNewBnoTireToBeAdded}
                />
              </div>
              <div className="ms-TextField">
                <label>New Suffix</label>
                <TextField
                  value={tireToBeAdded.newSfx}
                  onChange={setNewSfxTireToBeAdded}
                />
              </div>
              <PrimaryButton
                id="addButton"
                onClick={() => handleAdd('')}
                text="Add"
                disabled={!tireToBeAdded.pfx || !tireToBeAdded.bno || !tireToBeAdded.newPfx || !tireToBeAdded.newBrand || !userPermissions.isWrite}
              />
            </div>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <div className={styles.tableHeading}>
              <div>
                <Text variant="xLarge" className={styles.highlight}>Rebrand Tires</Text>
                <SeparatorGy vertical />
                <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
              </div>
            </div>
            <div className={styles.tableHeading}>
              <div className={styles.filtersBlock}>
                <TextField
                  id="searchString"
                  placeholder="Enter search string"
                  onChange={(event, searchString) => {
                    setFilters((prev: any) => ({ ...prev, searchString }));
                  }}
                  value={filters.searchString}
                />
                <PrimaryButton id="searchButton" className={styles.gyBackground} onClick={handleSearch} text="Search" />
              </div>
              <div>
                <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                <Dropdown
                  options={pageSizes.map(pageSize => ({
                    key: pageSize,
                    text: pageSize.toString(),
                  }))}
                  defaultSelectedKey={pageSizes[0]}
                  selectedKey={countOnPage?.key}
                  onChange={onChangeCountOnPage}
                />
                <SeparatorGy vertical />
                <Text variant="large" className={styles.highlight}>{state.selectedItems.length} items selected</Text>
                <SeparatorGy vertical />
                <IconButton
                  id="toggleDeletingConfirmationButton"
                  disabled={!state.selectedItems.length || !userPermissions.isWrite}
                  iconProps={{ iconName: 'Delete' }}
                  onClick={toggleDeletingConfirmation}
                />
              </div>
            </div>
            <div className={styles['table-wrapper']}>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    {
                      columnsState.map(item => (
                        <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                      ))
                    }
                    <th></th>
                    <th>
                      <div className={styles.round}>
                        <input type="checkbox" id="all" checked={state.items.length !== 0 &&
                          (state.selectedItems.length === +countOnPage.key || state.selectedItems.length === state.items.length)} onChange={(e) => handleSelectAll(e, state.items.map(item => item.id))} />
                        <label htmlFor="all"></label>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    state.items.map((item, index) => (
                      <tr key={item.id}
                        className={classNames(state.selectedItems.includes(item.id) ? styles.trSelected : styles.trBasic,
                          highlightRowIfError(index) ? styles.trError : styles.trBasic)}>
                        <td>
                          <IconButton
                            id="viewButton"
                            iconProps={{ iconName: 'View' }}
                            onClick={() => openViewTireDetailsWindow(item)}
                          />
                        </td>
                        {
                          columnsState.map((column) => (
                            <td>
                              <TextField
                                id={column.fieldName}
                                styles={{ fieldGroup: { border: '1px solid transparent' } }}
                                value={item[column.fieldName]}
                                onChange={(e, i) => setItemToBeEdited(item.id, column.fieldName, i)}
                                errorMessage={parseErrors(item.id, column.fieldName)}
                              />
                            </td>
                          ))
                        }
                        <td>{parseErrors(index, 'id')}</td>
                        <td>
                          <div className={styles.round}>
                            <input type="checkbox" id={item.id} checked={state.selectedItems.includes(item.id)} onChange={(e) => handleSelect(e, item.id)} />
                            <label htmlFor={item.id}></label>
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <SeparatorGy />
            <Pagination {...paginationProps} />
          </div>
        </div>
        <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
          <DefaultButton id='printExportBtn' onClick={toggleShowPrintExport} text="Print/Export" />
          <PrimaryButton id="saveButton" onClick={handleSave} text="Save" disabled={!userPermissions.isWrite} />
          <PrimaryButton id="submitButton" onClick={handleSubmit} text="Submit" disabled={!userPermissions.isWrite} />
        </div>
      </div>
      {detailsVisible && <ViewTireDetailsModal isOpened={detailsVisible} onDismiss={toggleDetailsVisible} />}
      <SelectingModal
        isOpen={showAModal}
        title="Available Consigned Tires"
        selectingList={consignedTires.map(tire => ({ id: tire.id, prefix: tire.prefix, brandNo: tire.brandNo, suffix: tire.suffix, status: tire.status,
          chgDate: tire.chgDate, totalMiles: tire.totalMiles, tireSize: tire.tireSize, typeCode: tire.typeCode, prodCode: tire.prodCode, tc: tire.tc,
          locationCode: tire.locationCode, oosAv: tire.oosAv, dotNo: tire.dotNo }))}
        onDismiss={toggleShowAModal}
        onSubmit={setTireDetails}
        columns={tireDetailsColumns}
      />
      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to delete ${state.selectedItems.length} items?`,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default RebrandTab;
