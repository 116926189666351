import { fieldType } from '../../../../../../../../../consts/fieldType';

export const tireMileageColumns = [
  {
    key: 'column1',
    name: 'Pfx',
    fieldName: 'pfx',
    isSorted: true,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column2',
    name: 'BrandNo',
    fieldName: 'brandNo',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column3',
    name: 'Sfx',
    fieldName: 'sfx',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column4',
    name: 'Veh',
    fieldName: 'veh',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column5',
    name: 'Pos',
    fieldName: 'pos',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column6',
    name: 'TotMiles',
    fieldName: 'totMiles',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column7',
    name: 'TireSize',
    fieldName: 'tireSize',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column8',
    name: 'TypeCode',
    fieldName: 'typeCode',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column9',
    name: 'ProdCode',
    fieldName: 'prodCode',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column10',
    name: 'TC',
    fieldName: 'tc',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
        
];