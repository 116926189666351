import { HeadCell } from '../../../../shared/DataGridComponent/DataGridModels';

export const pageSizes = [5, 10, 20];

export const listingHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Quote ID',
    fieldName: 'id',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Version',
    fieldName: 'version',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Status',
    fieldName: 'status',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Valid',
    fieldName: 'isValid',
    typeField: 'text',
  },
  {
    key: 'column4',
    name: 'Created On',
    fieldName: 'createdOn',
    typeField: 'date',
  },
  {
    key: 'column5',
    name: 'Created By',
    fieldName: 'createdBy',
    typeField: 'text',
  },
  {
    key: 'column6',
    name: 'Updated On',
    fieldName: 'lastUpdatedOn',
    typeField: 'date',
  },
  {
    key: 'column7',
    name: 'Updated By',
    fieldName: 'lastUpdatedBy',
    typeField: 'text',
  },
];
