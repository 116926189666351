export const columns = [
  {
    key: 'column0',
    name: 'ChgDate',
    fieldName: 'chgDate',
    isEditable: true,
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'VNo',
    fieldName: 'vno',
    isEditable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'WPos',
    fieldName: 'wPos',
    isEditable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'Off Prefix',
    fieldName: 'offPrefix',
    isEditable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'Off Brand Number',
    fieldName: 'offBrandNumber',
    isEditable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'Off Suffix',
    fieldName: 'offSuffix',
    isEditable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column6',
    name: 'OffCOT',
    fieldName: 'offCot',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column7',
    name: 'On Prefix',
    fieldName: 'onPrefix',
    isEditable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column8',
    name: 'On Brand Number',
    fieldName: 'onBrandNumber',
    isEditable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column9',
    name: 'On Suffix',
    fieldName: 'onSuffix',
    isEditable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column10',
    name: 'On COT',
    fieldName: 'onCot',
    isEditable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column11',
    name: 'Submit',
    fieldName: 'submit',
    isEditable: true,
    isSorted: false,
    isSortedDescending: false,
  },
];