import { recordTestInstance, exportTestInstance } from '../axios';

const get = (pagination: any, sortOrder: any, customerId: number, locationId: number, filters: any = { searchString: null } ) =>
  recordTestInstance.post('/transfer-tire', { pagination, filters, sortOrder, customerId, locationId });

const getErrors = (pagination: any, sortOrder: any, customerId: string, locationId: string) =>
  recordTestInstance.post('/transfer-tire/error', { pagination, sortOrder, customerId, locationId });

const add = (data: any, customerId: string, locationId: string, transferToCustomer: boolean, transferByBrand: boolean) =>
  recordTestInstance.post('/transfer-tire/add', { ...data, customerId, locationId, transferToCustomer, transferByBrand});

const submit = (data: Array<any>) =>
  recordTestInstance.post('/transfer-tire/submit', { data });
  
const deleteTransferTires = (ids: Array<any>) =>
  recordTestInstance.delete('/transfer-tire/delete', { data: { ids } });

const printExcel = (input: any, headerFields?: Array<any>) =>
  exportTestInstance.post('/transfer-tire/export-excel', { input }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (input: any, headerFields?: Array<any>) =>
  exportTestInstance.post('/transfer-tire/export-pdf', { input }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  get,
  getErrors,
  add,
  submit,
  delete: deleteTransferTires,
  printExcel,
  printPdf,
};
