import { DefaultButton, IconButton, MessageBarType, Modal, Text } from '@fluentui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import PdfViewer from '../../components/Reporting/components/PdfViewer/PdfViewer';
import { useBoolean } from '@fluentui/react-hooks';
import apiService from '../../api';
import styles from './ViewPdfModalComponent.module.scss';
import useNotifications from '../../hooks/useNotifications';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { isNil } from 'lodash';
import classNames from 'classnames';


interface ViewPdfModalComponentProps {
  id: string;
  title: string;
  reportId: any;
  displayedFileName: string;
  isOpen: boolean;
  onDismiss: () => void;
  cancelLabel: string;
  parentComponentName: string;
}

const ViewPdfModalComponent: FunctionComponent<ViewPdfModalComponentProps> = ({
  id,
  title,
  reportId, 
  displayedFileName,
  isOpen,
  onDismiss,
  cancelLabel,
  parentComponentName,
}) => {

  const { addNotification } = useNotifications();
    
  const [pdfFile, setPdfFile] = useState<any>(null);
  const [isLoading, { toggle: toggleIsLoading }] = useBoolean(false);

  const loadPdf = async () => {
    try {
      toggleIsLoading();
      const { data }: any = parentComponentName === 'BILLING_BACKUPS' ?  await apiService.reporting.getScheduledBackupReport(reportId) : await apiService.reporting.getScheduledReport(reportId);
      const file = new Blob([data], { type: 'application/pdf;base64' });
      const fileURL = URL.createObjectURL(file);
      setPdfFile(fileURL);
    } catch (e: any) {
      const { response } = e;
      const decodedData = JSON.parse(new TextDecoder().decode(new Uint8Array(response.data)));
      addNotification({
        text: decodedData?.message,
        type: MessageBarType.error,
      });
    } finally {
      toggleIsLoading();
    }
  };

  useEffect(() => {
    if(isOpen)
      loadPdf();
  }, [isOpen]);
  

  return (
    <>
      <Modal
        key={id}
        isOpen={isOpen}
        onDismiss={onDismiss}
        containerClassName={classNames(styles.pdfModalContainer, 'modalContainer')}
      >
        <div className={styles.modalBody}>
          <div className='modalHeader'>
            <Text variant='xLarge'>{title}</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel='Close popup modal'
              onClick={onDismiss}
            />
          </div>
          <div className={styles.pdfViewerBody}>
            { !isNil(pdfFile) && <PdfViewer file={pdfFile} displayedFileName={displayedFileName} /> }
          </div>
          <div className={styles.modalFooter}>
            <DefaultButton
              id='cancelButton'
              text={cancelLabel}
              onClick={onDismiss}
            />
          </div>
        </div>
        {isLoading && <LoadingScreen />}
      </Modal>
    </>
  );
};

export default ViewPdfModalComponent;