import { customerTestInstance, tireOrderingTestInstance } from '../axios';

const get = (customerId: any) => customerTestInstance.post('/customer/bill-to', { customerId });

const save = (billingData: any) => customerTestInstance.post('/customer/bill-to/save', { ...billingData });

const getCountries = () => tireOrderingTestInstance.post('/sections/countries');

const getStates = (country: any) => tireOrderingTestInstance.post('/sections/states', { country });

const getRegionCodes = (country: any) => tireOrderingTestInstance.post('/sections/region-codes', { country });

export default {
  get,
  save,
  getCountries,
  getStates,
  getRegionCodes,
};
