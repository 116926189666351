import { HeadCell } from '../../../../shared/DataGridComponent/DataGridModels';
import { IFormDataField } from '../../../../shared/FormBuilderComponent';

export const CUSTOMER_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'prefixField',
    properties: {
      id: 'prefix',
      label: 'Prefix',
      name: 'prefix',
      type: 'text',
    },
  },
  {
    formFieldName: 'customerNameField',
    properties: {
      id: 'customerName',
      label: 'Customer Name',
      name: 'customerName',
      type: 'text',
    },
  },
  {
    formFieldName: 'regionField',
    properties: {
      id: 'region',
      label: 'Region',
      name: 'region',
      type: 'text',
    },
  },
  {
    formFieldName: 'locCodeField',
    properties: {
      id: 'locCode',
      label: 'Loc Code',
      name: 'locCode',
      type: 'text',
    },
  },
  {
    formFieldName: 'locationField',
    properties: {
      id: 'location',
      label: 'Location',
      name: 'location',
      type: 'text',
    },
  },
  {
    formFieldName: 'locationIdField',
    properties: {
      id: 'locationId',
      label: 'Location Id',
      name: 'locationId',
      type: 'text',
    },
  },
];

export const FILTER_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'filterField',
    properties: {
      id: 'filter',
      label: 'Filter',
      name: 'filter',
      type: 'text',
    },
  },
];

export const MILEAGE_ADJUSTMENT_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'vehicleNumberField',
    properties: {
      id: 'vehicleNumber',
      label: 'Vehicle Number',
      name: 'vehicleNumber',
      type: 'text',
    },
  },
  {
    formFieldName: 'wheelPositionField',
    properties: {
      id: 'wheelPosition',
      label: 'Wheel Position',
      name: 'wheelPosition',
      type: 'text',
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'prefixField',
    properties: {
      id: 'prefix',
      label: 'Tire Brand',
      name: 'prefix',
      type: 'text',
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'brandNumberField',
    properties: {
      id: 'brandNumber',
      label: 'Brand Number',
      name: 'brandNumber',
      type: 'text',
    },
  },
  {
    formFieldName: 'suffixField',
    properties: {
      id: 'suffix',
      label: 'Suffix',
      name: 'suffix',
      type: 'text',
    },
  },
  {
    formFieldName: 'fromDateField',
    properties: {
      id: 'fromDate',
      label: 'From Date',
      name: 'fromDate',
      type: 'text',
    },
  },
  {
    formFieldName: 'toDateField',
    properties: {
      id: 'toDate',
      label: 'To Date',
      name: 'toDate',
      type: 'text',
    },
  },
  {
    formFieldName: 'milesField',
    properties: {
      id: 'miles',
      label: 'Miles',
      name: 'miles',
      type: 'text',
    },
  },
  {
    formFieldName: 'commentsField',
    properties: {
      id: 'comments',
      label: 'Comments',
      name: 'comments',
      type: 'text',
    },
  },
  {
    formFieldName: 'dbcrField',
    properties: {
      id: 'dbcr',
      label: 'DB/CR',
      name: 'dbcr',
      type: 'text',
    },
  },
  {
    formFieldName: 'billableField',
    properties: {
      id: 'billable',
      label: 'Billable',
      name: 'billable',
      type: 'text',
    },
  },
];

export const mileageAdjustmentsHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Location Code',
    fieldName: 'locationCode',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Vehicle Number',
    fieldName: 'vehicleNumber',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Prefix',
    fieldName: 'prefix',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Brand Number',
    fieldName: 'brandNumber',
    typeField: 'text',
  },
  {
    key: 'column4',
    name: 'Suffix',
    fieldName: 'suffix',
    typeField: 'text',
  },
  {
    key: 'column5',
    name: 'Wheel Position',
    fieldName: 'wheelPosition',
    typeField: 'text',
  },
  {
    key: 'column6',
    name: 'From Date',
    fieldName: 'fromDate',
    typeField: 'text',
  },
  {
    key: 'column7',
    name: 'To Date',
    fieldName: 'toDate',
    typeField: 'text',
  },
  {
    key: 'column8',
    name: 'Miles',
    fieldName: 'miles',
    typeField: 'text',
  },
  {
    key: 'column9',
    name: 'Debit/Credit',
    fieldName: 'debitOrCredit',
    typeField: 'text',
  },
  {
    key: 'column10',
    name: 'Billable',
    fieldName: 'billable',
    typeField: 'text',
  },
  {
    key: 'column11',
    name: 'Approval',
    fieldName: 'approval',
    typeField: 'text',
  },
  {
    key: 'column12',
    name: 'Submit',
    fieldName: 'submit',
    typeField: 'text',
  },
  {
    key: 'column13',
    name: 'Processed',
    fieldName: 'processed',
    typeField: 'text',
  },
  {
    key: 'column14',
    name: 'Processed Date',
    fieldName: 'processedDate',
    typeField: 'text',
  },
  {
    key: 'column15',
    name: 'Comments',
    fieldName: 'comments',
    typeField: 'text',
    isEditable: true,
  },
];

export const FILTER_TYPES = [
  'Approved',
  'Wait Approval',
  'Show All',
  'Not Processed',
];

export const mileageAdjustmentsList: IMileageAdjustments[] = [
  {
    locationCode: 'location',
    vehicleNumber: 'vehicleNumber',
    prefix: 'prefix',
    brandNumber: 'brandNumber',
    suffix: 'suffix',
    wheelPosition: 'position',
    fromDate: null,
    toDate: null,
    miles: 0,
    debitOrCredit: 'dbcr',
    billable: 'billable',
    approval: 'approved',
    submit: 'submitted',
    processed: 'processed',
    processedDate: null,
    comments: 'comments',
  },
];

export interface IMileageAdjustments {
  id?: string,
  approval?: string,
  approvalDate?: string,
  approvedBy?: string,
  billable?: string,
  brandNumber?: string,
  comments?: string,
  consignedTireId?: string,
  customerId?: string,
  debitOrCredit?: string,
  fromDate?: Date | undefined | null,
  locationCode?: string,
  locationId?: string,
  miles?: number,
  numberOfTires?: number,
  postAdjustId?: string | null,
  postedBy?: string,
  postedDate?: Date,
  prefix?: string,
  processed?: string,
  processedDate?: Date | null,
  submit?: string,
  submitDate?: Date,
  submittedBy?: string,
  suffix?: string,
  toDate?: Date | undefined | null,
  vehicleId?: string,
  vehicleNumber?: string,
  wheelPosition?: string,
};