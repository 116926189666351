import React, { FunctionComponent, useState, Fragment, useEffect } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import CollapsibleRow from './components/CollapsibleRow';
import { makeModelHeadCells, fleetsTableHeadCells } from '../consts';
import styles from '../MakeModel.module.scss';
import { Dropdown, IconButton, Spinner, Text, TextField, SpinnerSize } from '@fluentui/react';
import SeparatorGy from '../../../components/SeparatorGy/SeparatorGy';
import { isEqual } from 'lodash';
import { defaultDataGridPageSizes } from '../../DataGridComponent/utils';
import DeleteModalComponent from '../../DeleteModalComponent';
import Pagination from '../../../components/Pagination/Pagination';
import classNames from 'classnames';

const MakeModelTable: FunctionComponent<{
  makeModelList: any,
  parentComponentName: any,
  permissionKey: any,
  isLoading: boolean,
  totalDataFound: number,
  makeModelsSelected?: any,
  handleSelectRow?: any,
  handleDelete?: any,
  fetchMakeModels?: any,
  makeModelListPagination?: any,
}> = ({ makeModelList, parentComponentName, permissionKey, isLoading, totalDataFound, makeModelsSelected, handleSelectRow, handleDelete, fetchMakeModels }) => {

  const tableHeadCells = parentComponentName === 'fleet' ? fleetsTableHeadCells : makeModelHeadCells;
  const defaultSorting = { column: tableHeadCells[0].fieldName, order: 'asc' };
  /** state to show/hide delete modal */
  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [list, setList] = useState<Array<any>>(makeModelList);
  const [isCollapsed, setCollapse] = useState<boolean>(false);
  const [isSelectedAll, setSelectedAll] = useState<boolean>(false);
  const [selectedItems, setSelectedItem] = useState<any>([]);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<any>('');
  const [paginationProps, setPaginationProps] = useState<any>({
    current: 1,
    total: Math.ceil(totalDataFound / +defaultDataGridPageSizes[0]),
  });
  const [sortOrder, setSortOrder] = useState<any>(defaultSorting);

  const [perPageCount, setPerPageCount] = useState<any>({
    key: defaultDataGridPageSizes[0],
    text: defaultDataGridPageSizes[0].toString(),
  });
  const handleClick = (index: any) => {
    const updatedState = makeModelList[index];

    if (updatedState.isExpanded) {
      delete updatedState.isExpanded;
      setCollapse((pre: any) => {
        return pre + 1;
      });
    } else {
      updatedState.isExpanded = true;
      setCollapse((pre: any) => {
        return pre + 1;
      });
    }
  };


  const handleExpandAll = () => {
    makeModelList.map((makeModel: any) => makeModel.isExpanded = true);
    setCollapse((pre: any) => {
      return pre + 1;
    });
  };


  const handleCollapseAll = () => {
    makeModelList.map((makeModel: any) => delete makeModel.isExpanded);
    setCollapse((pre: any) => {
      return pre + 1;
    });
  };


  /** Handler of searching */
  const handleOnChangeSearch = ({ target: { value } }: any) => {
    setSearchText(value);
    const currentRows = [...makeModelList];
    if (!currentRows) return;
    const filteredRows = currentRows.filter((row: any) => {
      return JSON.stringify(row)
        .toLowerCase()
        .includes(value.toLowerCase());
    });
    setList(filteredRows);
  };

  /** In charge of evaluate if we can render search option */
  const renderSearchOption = () => (
    <TextField
      id="searchString"
      value={searchText}
      autoFocus={isInputFocused}
      onBlur={() => setIsInputFocused(false)}
      onFocus={() => setIsInputFocused(true)}
      placeholder="Enter search string"
      onChange={(e) => handleOnChangeSearch(e)}
    />
  );
  const onChangePerPageCount = (e: any, item: any) => {
    setPerPageCount(item);
    const pagination = {
      pageSize: item.key,
      pageNumber: paginationProps.current,
    };
    setPaginationProps({
      current: 1,
      total: Math.ceil(totalDataFound / item?.key),
    });
    fetchMakeModels(pagination, '', parentComponentName === 'fleet' ? [sortOrder] : sortOrder);
  };

  /** In charge of evaluate if we can render rows per page option */
  const renderRowsPerPage = () => (
    <>
      <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
      <Dropdown
        id='rowsPerPageDropdown'
        className={styles.marginLeftRight}
        options={defaultDataGridPageSizes.map(pageSize => ({
          key: pageSize,
          text: pageSize.toString(),
        }))}
        defaultSelectedKey={defaultDataGridPageSizes[0]}
        selectedKey={perPageCount?.key}
        onChange={onChangePerPageCount}
      />
    </>
  );

  /** In charge of evaluate if we can render selected items label */
  const renderSelectedItems = () => (
    <>
      <SeparatorGy vertical />
      <Text variant='large' className={`${styles.marginLeftRight} ${styles.highlight}`}>{`${selectedItems.length} Selected`}</Text>
    </>
  );

  /** Handler of deleting row (fires the callBack for delete ) */
  const handleOnDelete = async () => {
    toggleDeletingConfirmation();
    await handleDelete(selectedItems);
    setSelectedItem([]);
    onChangePage(selectedItems.length === list.length ?
      paginationProps.current > 1 ? paginationProps.current - 1 : 1
      : paginationProps.current);
  };

  /** In charge of evaluate if we can render delete option */
  const renderDeleteOption = () => (
    <>
      <SeparatorGy vertical />
      <IconButton
        className={styles.marginLeftRight}
        id="deleteIcon"
        disabled={!selectedItems.length}
        iconProps={{ iconName: 'Delete' }}
        onClick={toggleDeletingConfirmation}
      />
    </>
  );

  /** Handler of changes page */
  const onChangePage = (newPage: number) => {
    setTimeout(() => {
      setPaginationProps({ ...paginationProps, current: newPage });
      const pagination = {
        pageSize: perPageCount?.key,
        pageNumber: newPage,
      };
      fetchMakeModels(pagination, '', parentComponentName === 'fleet' ? [sortOrder] : sortOrder);
    }, 500);
  };

  /** Create a sort handler */
  const handleSorting = ({ target: { id: property } }: any) => {
    const sortingObj = {
      order: sortOrder?.order === 'asc' ? 'desc' : 'asc',
      column: property,
    };
    const pagination = {
      pageSize: perPageCount.key,
      pageNumber: paginationProps.current,
    };
    setSortOrder(sortingObj);


    fetchMakeModels(pagination, '', parentComponentName === 'fleet' ? [sortingObj] : sortingObj);
  };

  const getClassSorting = (property: string, disableHeadSorting = false) => {
    const sortingObj = {
      ...sortOrder,
      column: property,
    };
    if (sortOrder.column === property) return sortingObj ? sortingObj.order == 'asc' ? styles.ascending : styles.descending : '';
  };

  useEffect(() => {
    if (!totalDataFound) return;
    const totalPages = Math.ceil(totalDataFound / perPageCount?.key);
    setPaginationProps({
      current: totalPages > 1
        ? paginationProps.current : 1,
      total: totalPages,
    });
  }, [totalDataFound, perPageCount]);

  useEffect(() => setList(makeModelList), [makeModelList]);
  useEffect(() => setSelectedItem(makeModelsSelected), [makeModelsSelected]);
  return (
    <>
      <div className={styles.tableHeading}>
        <div>
          <div className={styles.tableHeadingFlex}>
            <div>
              <Text variant='xLarge' className={styles.highlight}>{parentComponentName === 'fleet' ? 'Fleet' : 'Make/Model'}</Text>
              <Text variant='xLarge' className={`${styles.marginLeftRight} ${styles.highlight}`}>{`${totalDataFound} Found`}</Text>
            </div>
            <div className={styles.expandCollapseContainer}>
              <Text className={`${styles.highlight} ${styles.expandCollapseCursor}`} onClick={handleExpandAll}>Expand All </Text>
              |
              <Text className={`${styles.highlight} ${styles.expandCollapseCursor}`} onClick={handleCollapseAll}> Collapse All</Text>
            </div>
          </div>
          <div className={styles.searchInput}>
            {renderSearchOption()}
          </div>
        </div>
        <div className={styles.tableHeadingFlex}>
          {renderRowsPerPage()}
          {renderSelectedItems()}
          {renderDeleteOption()}
        </div>
      </div>
      <div className={styles.tableWrapper}>
        <table>
          <thead>
            <tr style={{ zIndex: 50 }}>

              {tableHeadCells.map((headCell, key) => (
                headCell.disableSorting ?
                  <th key={key}>{headCell.name}</th> :
                  <th
                    key={key}
                    id={headCell.fieldName}
                    onClick={handleSorting}
                  >
                    <div
                      id={headCell.fieldName}
                      className={classNames(
                        getClassSorting(headCell.fieldName, headCell.disableSorting),
                      )}
                      onClick={handleSorting}
                    >{headCell.name}</div></th>
              ))}
              <th>
                <div className={styles.round}>
                  <input
                    id={'row-action'}
                    name={'row-action'}
                    type='checkbox'
                    checked={isSelectedAll}
                    onChange={() => {
                      if (!isSelectedAll) {
                        setSelectedItem(makeModelList);
                        handleSelectRow(makeModelList);
                      } else {
                        setSelectedItem([]);
                        handleSelectRow([]);
                      }
                      setSelectedAll(!isSelectedAll);
                    }}
                  />
                  <label htmlFor={'row-action'}></label>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? <tr key={'loading'}>
              <td colSpan={parentComponentName === 'fleet' ? 8 : 7}>
                <span className={styles.emptyRowsTable}>
                  <Spinner id='loadingSpinner' size={SpinnerSize.large} className={styles.spinner} />
                </span>
              </td>
            </tr> : (list.length ? list : makeModelList).map((makeModel: any, key: any) => {
              const vehicleMake = makeModel?.vehicleMake;
              const fleetId = parentComponentName === 'fleet' ? makeModel?.fleetId : makeModel?.mCbFleetid;
              const isSelected = selectedItems.find((item: any) => item === makeModel);
              return (<Fragment key={`${key}${makeModel.id}`}>
                <tr>
                  {tableHeadCells.map((headCell: any) => (
                    <td onClick={() => handleClick(key)}>
                      {makeModel[headCell.fieldName]}
                    </td>
                  ))}
                  <td>
                    <div className={styles.round}>
                      <input
                        id={`row-${key}-${makeModel.contractId}`}
                        name={`row-${makeModel.contractId}`}
                        type='checkbox'
                        checked={isSelected}
                        onChange={() => {
                          let currentSelected: any[] = [...selectedItems];
                          if (currentSelected.find(el => isEqual(el, makeModel))) currentSelected = currentSelected.filter(current => !isEqual(current, makeModel));
                          else currentSelected.push(makeModel);
                          setSelectedItem(currentSelected);
                          handleSelectRow(currentSelected);
                        }}
                      />
                      <label htmlFor={`row-${key}-${makeModel.contractId}`}></label>
                    </div>
                  </td>
                </tr>
                {makeModel.isExpanded ? (
                  <tr>
                    <td colSpan={parentComponentName === 'fleet' ? 8 : 7}>
                      <CollapsibleRow
                        title={vehicleMake}
                        fleetId={fleetId as string}
                        cancelLabel='Cancel'
                        permissionKey={permissionKey}
                        parentComponentName={parentComponentName}
                        onDismiss={() => handleClick(key)} />
                    </td>
                  </tr>
                ) : null}
              </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      <SeparatorGy />
      {
        Boolean(totalDataFound) && (<Pagination {...paginationProps} onChangePage={onChangePage} />)
      }
      <DeleteModalComponent
        isOpen={isDeletingDialogVisible}
        onCancel={toggleDeletingConfirmation}
        onSubmit={handleOnDelete}
        title={'Confirmation'}
        subText={'Are you sure you want to delete the following items?'}
        headcells={tableHeadCells}
        rowsToDelete={selectedItems}
        onSubmitLabel={'Delete'}
        onCancelLabel={'Cancel'}
      />
    </>
  );
};

export default MakeModelTable;