import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';
import { Pivot, PivotItem } from '@fluentui/react';
import TabHeader from '../../../RecordKeeping/tabComponents/TabHeader/TabHeader';

import { IMaintainPlantsProps } from './IMaintainPlantsProps';
import { maintainPlantsTabs } from './consts';

import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { auth_maintain_oem, auth_maintain_retread, auth_maintain_whs } from '../../../../consts/programKeys';

import Oems from './tabComponents/Oems/Oems';
import Retread from './tabComponents/Retread/Retread';
import Warehouse from './tabComponents/Warehouse/Warehouse';

const MaintainPlantsTab: FC<IMaintainPlantsProps> = (): ReactElement => {

  const { hasPermission } = useUserPermissions();
  const userPermissions_maintainOEM = hasPermission(auth_maintain_oem);
  const userPermissions_maintainPlants_retread = hasPermission(auth_maintain_retread);
  const userPermissions_maintainPlants_whs = hasPermission(auth_maintain_whs);


  const [selectedKey, setSelectedKey] = React.useState(maintainPlantsTabs.oems.name);

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  return (
    <div>
      <TabHeader title="Maintain Plants" />
      <Pivot
        className={classNames('vertical-pivot')}
        selectedKey={selectedKey}
        onLinkClick={handleLinkClick}
        getTabId={getTabId}
        linkFormat="tabs"
      >

        {userPermissions_maintainOEM.isAccess &&
          <PivotItem headerText={'OEMs'} itemKey={'Oems'}>
            <div className="vertical-pivot-container"><Oems /></div>
          </PivotItem>
        }

        {userPermissions_maintainPlants_retread.isAccess &&
          <PivotItem headerText={'Retread'} itemKey={'Retread'}>
            <div className="vertical-pivot-container"><Retread /></div>
          </PivotItem>
        }

        {userPermissions_maintainPlants_whs.isAccess && 
          <PivotItem headerText={'Warehouse'} itemKey={'Warehouse'}>
            <div className="vertical-pivot-container"><Warehouse /></div>
          </PivotItem>
        }

      </Pivot>
    </div>
  );
};

export default MaintainPlantsTab;
