import { HeadCell, ISorting } from '../DataGridComponent/DataGridModels';
import { IFormDataField } from '../FormBuilderComponent';

export const pageSizes = [500, 750, 1000];

export const CUSTOMER_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'prefixField',
    properties: {
      id: 'prefix',
      label: 'Prefix',
      name: 'prefix',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'customerNameField',
    properties: {
      id: 'customerName',
      label: 'Customer Name',
      name: 'customerName',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'locationCodeField',
    properties: {
      id: 'locationCode',
      label: 'Location Code',
      name: 'locationCode',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'locationNameField',
    properties: {
      id: 'locationName',
      label: 'Location Name',
      name: 'locationName',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'regionField',
    properties: {
      id: 'region',
      label: 'Region',
      name: 'region',
      type: 'text',
      disabled: true,
    },
  },
];

export const QUOTE_DETAILS_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'quoteIdField',
    properties: {
      id: 'quoteId',
      label: 'Quote ID',
      name: 'quoteId',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'quoteReqDateField',
    properties: {
      id: 'quoteReqDate',
      label: 'Quote Req. Date',
      name: 'quoteReqDate',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'numberOfTiresField',
    properties: {
      id: 'numberOfTiress',
      label: '# of Tires',
      name: 'numberOfTires',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'soldDateField',
    properties: {
      id: 'soldDate',
      label: 'Sold Date',
      name: 'soldDate',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'totalPriceField',
    properties: {
      id: 'totalPrice',
      label: 'Total Price',
      name: 'totalPrice',
      type: 'text',
      textTransform: 'currency',
      disabled: true,
    },
  },
  {
    formFieldName: 'soldOrLostField',
    properties: {
      id: 'soldOrLost',
      label: 'Sold Or Lost',
      name: 'soldOrLost',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'threadDeptField',
    properties: {
      id: 'threadDept',
      label: 'Thread Dept',
      name: 'threadDept',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'commentsField',
    properties: {
      id: 'comments',
      label: 'Comments',
      name: 'comments',
      type: 'text',
      disabled: true,
    },
  },
];

export const SELL_BY_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'prefixField',
    properties: {
      id: 'prefix',
      label: '',
      name: 'prefix',
      type: 'text',
      disabled: true,
      description: '*Prefix',
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'fromBrandNumberField',
    properties: {
      id: 'fromBrandNumber',
      label: '',
      name: 'fromBrandNumber',
      type: 'text',
      disabled: true,
      description: '*Brand Number',
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'suffixField',
    properties: {
      id: 'suffix',
      label: '',
      name: 'suffix',
      type: 'text',
      disabled: true,
      description: '*Suffix',
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'toBrandNumberField',
    properties: {
      id: 'toBrandNumber',
      label: '',
      name: 'toBrandNumber',
      type: 'text',
      disabled: true,
      description: '*Brand Number',
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'vehicleNumberField',
    properties: {
      id: 'vehicleNumber',
      label: 'Vehicle #',
      name: 'vehicleNumber',
      type: 'text',
      disabled: true,
      textTransform: 'uppercase',
    },
  },
];

export const selectedTiresHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Prefix',
    fieldName: 'prefix',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Brand',
    fieldName: 'brand',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Suffix',
    fieldName: 'suffix',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Change Date',
    fieldName: 'changeDate',
    typeField: 'date',
  },
  {
    key: 'column4',
    name: 'COT',
    fieldName: 'cot',
    typeField: 'boolean',
  },
  {
    key: 'column5',
    name: 'Veh',
    fieldName: 'veh',
    typeField: 'text',
  },
  {
    key: 'column6',
    name: 'Pos',
    fieldName: 'pos',
    typeField: 'text',
  },
  {
    key: 'column7',
    name: 'Last Mileage',
    fieldName: 'lastMileageRun',
    typeField: 'date',
  },
  {
    key: 'column8',
    name: 'Submit',
    fieldName: 'submit',
    typeField: 'boolean',
  },
  {
    key: 'column9',
    name: 'BillTD',
    fieldName: 'billTd',
    typeField: 'text',
  },
  {
    key: 'column10',
    name: 'Basis Units',
    fieldName: 'basisUnits',
    typeField: 'text',
  },
  {
    key: 'column11',
    name: 'AccMiles',
    fieldName: 'accMiles',
    typeField: 'text',
  },
  {
    key: 'column12',
    name: 'Billable Miles',
    fieldName: 'billableMiles',
    typeField: 'text',
  },
  {
    key: 'column13',
    name: 'Rate',
    fieldName: 'rate',
    typeField: 'decimal',
  },
  {
    key: 'column14',
    name: 'Billing Price',
    fieldName: 'billingPrice',
    typeField: 'currency',
  },
  {
    key: 'column15',
    name: 'Casing Value',
    fieldName: 'casValue',
    typeField: 'currency',
  },
  {
    key: 'column16',
    name: 'Total Price',
    fieldName: 'totalPrice',
    typeField: 'currency',
  },
];

export const defaultSorting: ISorting[] = [{
  column: selectedTiresHeadCell[5].fieldName,
  order: 'asc',
}, {
  column: selectedTiresHeadCell[6].fieldName,
  order: 'asc',
}];
