import { Checkbox, DatePicker, DefaultButton, IconButton, MessageBarType, Modal, PrimaryButton, Spinner, SpinnerSize, Text, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import apiService from '../../api';
import AutoCompleteField from '../../components/Common/Search/AutoCompleteField';
import { ILocation } from '../../components/Common/Search/ILocation';
import { downloadFile, printingTypes } from '../../components/PrintingModal/consts';
import PrintingModal from '../../components/PrintingModal/PrintingModal';
import SeparatorGy from '../../components/SeparatorGy/SeparatorGy';
import useNotifications from '../../hooks/useNotifications';
import { INotification } from '../../models/INotification';
import ConsignedTiresModalComponent from '../ConsignedTiresModalComponent';
import DataGridComponent from '../DataGridComponent';
import { HeadCell } from '../DataGridComponent/DataGridModels';
import { defaultDataGridPageSizes } from '../DataGridComponent/utils';
import DialogComponent from '../DialogComponent';
import { FormBuilderGroup } from '../FormBuilderComponent';
import LocationsModalComponent from '../LocationsModalComponent';
import { transformDate } from '../transformDate';
import { formatter } from '../utils';
import VehiclesModalComponent from '../VehiclesModalComponent';
import ViewErrorsModalComponent from '../ViewErrorsModalComponent';
import { CUSTOMER_FIELDS, defaultSorting, pageSizes, QUOTE_DETAILS_FIELDS, selectedTiresHeadCell, SELL_BY_FIELDS } from './consts';
import styles from './ViewMaintainTireSales.module.scss';
import ThreadMassUpload from '../../components/MassUpload/ThreadMassUpload';


interface ViewMaintainTireSalesModalComponentProps {
  id: string,
  parentCustomerId?: string,
  parentQuoteId?: string,
  title: string,
  actionType: 'create' | 'view' | 'maintain',
  isOpen: boolean,
  onSubmit: () => void,
  onCancel: () => void,
}

const ViewMaintainTireSalesModalComponent: FunctionComponent<ViewMaintainTireSalesModalComponentProps> = ({
  id,
  parentCustomerId,
  parentQuoteId,
  title,
  actionType,
  isOpen,
  onSubmit,
  onCancel,
}) => {

  //hooks
  const { addNotification } = useNotifications();

  const [quoteId, setQuoteId] = useState<string>('');
  const [isViewAction] = useState<boolean>(actionType == 'view');
  const [isSoldTires, setIsSoldTires] = useState(false);
  const [isManualThread, setIsManualThread] = useState(false);
  const [tiresQuoteList, setTiresQuoteList] = useState<Array<any>>([]);
  const [selectedTires, setSelectedTires] = useState<Array<any>>([]);
  const [tiresPendingToVerify, setTiresPendingToVerify] = useState<Array<any>>([]);
  const [tiresHeadCells, setTiresHeadCells] = useState<Array<HeadCell>>(selectedTiresHeadCell);
  const [totalFound, setTotalFound] = useState(0);
  const [tiresQuoteFilters, setTiresQuoteFilters] = useState({});
  const [tiresQuotePagination, setTiresQuotePagination] = useState({ pageSize: defaultDataGridPageSizes[0], pageNumber: 1 });
  const [tiresQuoteSortOrder, setTiresQuoteSortOrder] = useState<any[]>([{ column: selectedTiresHeadCell[5].fieldName, order: 'asc' }, { column: selectedTiresHeadCell[6].fieldName, order: 'asc' }]);

  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });
  const [customerId, setCustomerId] = useState<any>();
  const [locationList, setLocationList] = useState<Array<ILocation>>([]);
  const [locationSelected, setLocationSelected] = useState<any>();
  const [vehicleSelected, setVehicleSelected] = useState<any>();
  const [customAddOption, setCustomAddOption] = useState<'brand' | 'vehicle' | 'file'>('brand');
  const [dataGridState, setdataGridState] = useState<any>({});
  const [selectedTireError, setSelectedTireError] = useState<any>('');
  const [preSelectedTires, setPreselectedTires] = useState<Array<any>>([]);
  const [runningStatus, setRunningStatus] = useState<string>('IDLE');
 

  //toggles
  const [isLoadingTiresTable, { toggle: toggleShowLoadingTiresTable }] = useBoolean(false);
  const [isLoadingInitialData, { toggle: toggleShowLoadingAvailableInitialData }] = useBoolean(false);
  const [isLoadingFetchLocations, { toggle: toggleIsLoadingFetchLocations }] = useBoolean(false);
  const [reloadControlsTable, { toggle: toggleReloadControlsTable }] = useBoolean(false);
  const [openVehiclesModal, { toggle: toggleOpenVehiclesModal }] = useBoolean(false);
  const [openLocationsModal, { toggle: toggleOpenLocationsModal }] = useBoolean(false);
  const [openErrorsModal, { toggle: toggleOpenErrorsModal }] = useBoolean(false);
  const [openConsignedTiresModal, { toggle: toggleOpenConsignedTiresModal }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [isDataApplied, setIsDataApplied] = useState(false);
  const [showVerifyWarning, { toggle: toggleShowVerifyWarning }] = useBoolean(false);
  const [showReleaseConfirmation, {toggle: toggleReleaseConfirmation}] = useBoolean(false);
  const [showUpload, { toggle: toggleShowUpload }] = useBoolean(false);

  //form validators
  const customerFormFieldsGroup = new FormBuilderGroup(CUSTOMER_FIELDS);
  const quoteDetailsFieldsGroup = new FormBuilderGroup(QUOTE_DETAILS_FIELDS);
  const sellByFieldsGroup = new FormBuilderGroup(SELL_BY_FIELDS);

  const fetchCreateQuote = async () => {
    try {
      const { data } = await apiService.billingAPI.createQuote(customerId || '');
      return data;
    } catch (error) {
      setNotification({ text: 'Creating quote error', type: MessageBarType.error });
    }
  };

  const fetchQuoteDetails = async (newQuoteId?: string) => {
    try {
      const { data }: any = await apiService.billingAPI.getQuoteDetails(newQuoteId || quoteId);
      return data;
    } catch (error) {
      setNotification({ text: 'Fetching tires error', type: MessageBarType.error });
    }
  };

  const fetchTiresQuote = async (
    newQuoteId?: string,
    pagination: any = tiresQuotePagination,
    filters?: any,
    sortOrder: any[] = tiresQuoteSortOrder,
  ) => {
    try {
      if (!newQuoteId && !quoteId) return;
      setdataGridState({ pagination, sortOrder, filters });
      const { data }: any = await apiService.billingAPI.getTiresQuote(
        newQuoteId || quoteId,
        pagination,
        sortOrder,
        filters,
      );
      setTiresQuoteList(data.data);
      setTotalFound(data?.total?.found);
      setTiresQuotePagination(pagination);
      setTiresQuoteSortOrder(sortOrder);
      setTiresQuoteFilters(filters);
    } catch (error) {
      setNotification({ text: 'Fetching tires error', type: MessageBarType.error });
    }
  };

  const fetchLocations = async (customerId: any) => {
    try {
      toggleIsLoadingFetchLocations();
      const { data }: any = await apiService.getLocationsByCustomerId(
        customerId,
        { pageSize: 5000, pageNumber: 1 },
        {},
      );
      setLocationList(data.data);
      toggleIsLoadingFetchLocations();
    } catch (e: any) {
      setNotification({ text: 'Locations fetching error.', type: MessageBarType.error });
    }
  };

  const fetchUpdateQuoteDetails = async (bodyRequest: any) => {
    try {
      toggleShowLoadingAvailableInitialData();
      await apiService.billingAPI.updateQuoteDetails(bodyRequest);
      setNotification({ text: 'Details updated successfully.', type: MessageBarType.success });
    } catch (e: any) {
      const { response } = e;
      setNotification({
        text: `Details updating error. ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      toggleShowLoadingAvailableInitialData();
    }
  };

  const fetchAddNewTires = async (bodyRequest: any) => {
    try {
      await apiService.billingAPI.addNewTires(bodyRequest);
      setNotification({ text: 'New Tires added successfully.', type: MessageBarType.success });
    } catch (error: any) {
      const {
        response: {
          data: { state: errorsArray, message },
        },
      } = error;

      const errors = errorsArray?.map((err: {
        message: string;
      }) => {
        return err.message;
      });

      setNotification({ text: `Tires adding error. ${errors?.join('\n') || message}`, type: MessageBarType.error });
    }
  };

  const fetchDeleteTires = async (bodyRequest: any) => {
    try {
      const { data: { message } } = await apiService.billingAPI.deleteTires(bodyRequest);
      setNotification({ text: message, type: MessageBarType.success });
    } catch (error: any) {
      const {
        response: {
          data: { state: errorsArray, message },
        },
      } = error;

      const errors = errorsArray?.map((err: {
        message: string;
      }) => {
        return err.message;
      });

      setNotification({ text: `Tires Deleting error. ${errors?.join('\n') || message}`, type: MessageBarType.error });
    }
  };

  const fetchVerifyQuote = async (bodyRequest: any) => {
    try {
      toggleShowLoadingAvailableInitialData();
      const { data: { message } } = await apiService.billingAPI.verifyQuote(bodyRequest);
      setNotification({ text: message, type: MessageBarType.success });
    } catch ({ response: { data: { message, state } } }: any) {
      const text = typeof message === 'string' ? message : 'Quote verification error.';
      const errorFromArray = Array.isArray(state) ? state[0]?.message : text;
      setNotification({ text: errorFromArray, type: MessageBarType.error });
    } finally {
      toggleShowLoadingAvailableInitialData();
    }
  };

  const fetchReleaseQuote = async (bodyRequest: any) => {
    try {
      toggleShowLoadingAvailableInitialData();
      const { data: { message } } = await apiService.billingAPI.releaseQuote(bodyRequest);
      setNotification({ text: message, type: MessageBarType.success });
      onSubmit();
    } catch ({ response: { data: { message, state } } }: any) {
      const text = typeof message === 'string' ? message : 'Quote verification error.';
      const errorFromArray = Array.isArray(state) ? state[0]?.message : text;
      setNotification({ text: errorFromArray, type: MessageBarType.error });
    } finally {
      toggleShowLoadingAvailableInitialData();
    }
  };

  const manageInitialData = async () => {
    if (actionType == 'create' && parentCustomerId) await createQuote();
    else setQuoteDetails(parentQuoteId);
  };

  const createQuote = async () => {
    toggleShowLoadingAvailableInitialData();
    const { quoteId: newQuoteId } = await fetchCreateQuote();
    setQuoteId(newQuoteId);
    await setQuoteDetails(newQuoteId);
    toggleShowLoadingAvailableInitialData();
  };

  const setQuoteDetails = async (newQuoteId?: string) => {
    if (!newQuoteId && !quoteId) return;
    toggleShowLoadingAvailableInitialData();
    const {
      locCode,
      locName,
      customerPrefix,
      customerId,
      customerName,
      region,
      reqDate,
      numberOfTires,
      soldDate,
      totPrice,
      comments,
      manual,
      sold,
      applied,
      billedByTreadDepth,
    } = await fetchQuoteDetails(newQuoteId);
    customerFormFieldsGroup.setFormValue('prefixField', customerPrefix);
    customerFormFieldsGroup.setFormValue('customerNameField', customerName);
    customerFormFieldsGroup.setFormValue('regionField', region);
    customerFormFieldsGroup.setFormValue('locationCodeField', locCode);
    customerFormFieldsGroup.setFormValue('locationNameField', locName);

    quoteDetailsFieldsGroup.setFormValue('quoteIdField', newQuoteId || quoteId);
    quoteDetailsFieldsGroup.setFormValue('quoteReqDateField', reqDate);
    quoteDetailsFieldsGroup.setFormValue('numberOfTiresField', numberOfTires);
    quoteDetailsFieldsGroup.setFormValue('soldDateField', soldDate);
    quoteDetailsFieldsGroup.setFormValue('totalPriceField', formatter.format(+totPrice));
    quoteDetailsFieldsGroup.setFormValue('commentsField', comments);

    setCustomerId(customerId);
    setIsSoldTires(sold);
    setIsManualThread(manual);
    manageHeadCells(billedByTreadDepth);
    setIsDataApplied(applied);

    await fetchTiresQuote(newQuoteId);
    await fetchLocations(customerId);

    toggleShowLoadingAvailableInitialData();
  };

  const manageHeadCells = (billedByTreadDepth: boolean) => {
    const newHeadCells = [...tiresHeadCells];
    newHeadCells.forEach((headCell: HeadCell) => {
      if (headCell.fieldName == 'billTd') headCell.isEditable = billedByTreadDepth;
    });
    setTiresHeadCells(newHeadCells);
  };

  const setInitialState = () => {
    setQuoteId('');
    customerFormFieldsGroup.cleanFormData();
    quoteDetailsFieldsGroup.cleanFormData();
    sellByFieldsGroup.cleanFormData();
    setTiresQuoteList([]);
    setSelectedTires([]);
    setLocationSelected(undefined);
    setVehicleSelected(undefined);
    setCustomAddOption('brand');
  };

  const handleCloseModal = () => {
    setInitialState();
    onCancel();
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    if (!isOpen) return;
    toggleShowLoadingTiresTable();
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      filterText: searchedText,
    };
    setdataGridState({ pagination, sortOrder, filters });
    if (hasChangesPendingToVerify) {
      toggleShowVerifyWarning();
      toggleShowLoadingTiresTable();
      return;
    };
    await fetchTiresQuote(undefined, pagination, filters, sortOrder);
    toggleShowLoadingTiresTable();
  };

  const handleUpdate = (rowsToUpdate: Array<any>) => {
    const tiresUpdated = [...tiresQuoteList].map((currentTire: any) => {
      const tireToFind = rowsToUpdate.find((tireToFind: any) => tireToFind.tireId === currentTire.tireId);
      if (tireToFind) currentTire.billTd = tireToFind.billTd;
      return currentTire;
    });
    setTiresPendingToVerify(rowsToUpdate);
    setTiresQuoteList(tiresUpdated);
  };

  const handleSelectLocation = (locationSelected: any) => {
    setLocationSelected(locationSelected);
    toggleOpenLocationsModal();
  };

  const handleSelectTire = ({ prefix, brand, suffix }: any) => {
    sellByFieldsGroup.setFormValue('prefixField', prefix);
    sellByFieldsGroup.setFormValue('fromBrandNumberField', brand);
    sellByFieldsGroup.setFormValue('suffixField', suffix);
    toggleOpenConsignedTiresModal();
  };

  const handleSelectVehicle = (vehicleSelected: any) => {
    setVehicleSelected(vehicleSelected);
    sellByFieldsGroup.setFormValue('vehicleNumberField', vehicleSelected.vehicleNumber);
    toggleOpenVehiclesModal();
  };

  const handleApplyDetails = async () => {
    const bodyRequest = {
      quoteId,
      sold: isSoldTires,
      manual: isManualThread,
      comments: quoteDetailsFieldsGroup.getFieldFormValue('commentsField'),
      soldDate: transformDate(moment(quoteDetailsFieldsGroup.getFieldFormValue('soldDateField')).format('MM/DD/YYYY')),
      requestDate: transformDate(moment(quoteDetailsFieldsGroup.getFieldFormValue('quoteReqDateField')).format('MM/DD/YYYY')),
      numberOfTires: quoteDetailsFieldsGroup.getFieldFormValue('numberOfTiresField'),
      totPrice: quoteDetailsFieldsGroup.getFieldFormValue('totalPriceField'),
    };

    await fetchUpdateQuoteDetails(bodyRequest);
    await setQuoteDetails();
  };

  const handleAddTires = async () => {
    toggleShowLoadingTiresTable();
    const isAddingByBrand = customAddOption == 'brand';

    const bodyRequest = {
      quoteId,
      sold: isSoldTires,
      manual: isManualThread,
      vehicleNumber: isAddingByBrand ?
        null :
        vehicleSelected?.vehicleNumber || sellByFieldsGroup.getFieldFormValue('vehicleNumberField'),
      fromBrand: {
        pfx: isAddingByBrand ? sellByFieldsGroup.getFieldFormValue('prefixField') : null,
        bno: isAddingByBrand ? sellByFieldsGroup.getFieldFormValue('fromBrandNumberField') : null,
        sfx: isAddingByBrand ? sellByFieldsGroup.getFieldFormValue('suffixField') : null,
      },
      toBrand: {
        pfx: isAddingByBrand ? sellByFieldsGroup.getFieldFormValue('prefixField') : null,
        bno: isAddingByBrand ? sellByFieldsGroup.getFieldFormValue('toBrandNumberField') : null,
        sfx: isAddingByBrand ? sellByFieldsGroup.getFieldFormValue('suffixField') : null,
      },
    };

    await fetchAddNewTires(bodyRequest);
    await fetchTiresQuote();
    sellByFieldsGroup.cleanFormData();
    toggleShowLoadingTiresTable();
  };

  const handleDeleteTires = async (tiresToDelete: Array<any>) => {
    toggleShowLoadingTiresTable();
    const bodyRequest = {
      ids: getTiresId(tiresToDelete),
      fclosedout: '',
    };
    await fetchDeleteTires(bodyRequest);
    await setQuoteDetails();
    toggleShowLoadingTiresTable();
  };

  const handleRelease = async () => {
    toggleReleaseConfirmation();
    const bodyRequest = buildBodyRequest();
    await fetchReleaseQuote(bodyRequest);
    await setQuoteDetails();
  };

  const handleVerify = async () => {
    if (showVerifyWarning) toggleShowVerifyWarning();
    const bodyRequest = buildBodyRequest();
    await fetchVerifyQuote(bodyRequest);
    await setQuoteDetails();
    setTiresPendingToVerify([]);
  };

  const handlePrint = async (printingType: any) => {
    toggleShowPrintExport();
    toggleShowLoadingAvailableInitialData();
    try {
      const requestData = {
        quoteId,
        pagination: tiresQuotePagination,
        sortOrder: tiresQuoteSortOrder,
        filters: tiresQuoteFilters,
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.billingAPI.printExcelTireSaleQuotes(requestData) :
        await apiService.billingAPI.printPdfViewTireSaleQuotes(requestData);

      downloadFile(data, printingType);
      setNotification({ text: 'File was successfully received.', type: MessageBarType.success });
    } catch (e: any) {
      const { response } = e;
      setNotification({ text: `Printing error: ${response.data.message}`, type: MessageBarType.error });
    } finally {
      toggleShowLoadingAvailableInitialData();
    };
  };

  const handleDiscardChanges = async () => {
    toggleShowVerifyWarning();
    setTiresPendingToVerify([]);
    toggleShowLoadingTiresTable();
    const { pagination, sortOrder, filters } = dataGridState;
    await fetchTiresQuote(undefined, pagination, filters, sortOrder);
    toggleShowLoadingTiresTable();
  };

  const buildBodyRequest = () => ({
    customerId,
    quoteId,
    locationId: locationSelected?.id,
    requestDate: quoteDetailsFieldsGroup.getFieldFormValue('quoteReqDateField'),
    data: tiresPendingToVerify || selectedTires,
  });

  const removeEmpty = ({ text }: any) => text !== null;

  const getLocationNameList = locationList ?
    locationList.map(({ id, locationName }) => ({
      key: id,
      text: locationName,
    })).filter(removeEmpty) : [];

  const getLocationCodeList = locationList ?
    locationList.map(({ id, locationCode }) => ({
      key: id,
      text: locationCode,
    })).filter(removeEmpty) : [];

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  useEffect(() => {
    if (!isOpen) return;
    manageInitialData();
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;
    fetchTiresQuote();
  }, [quoteId]);

  useEffect(() => {
    if (selectedTireError) {
      toggleOpenErrorsModal();
      setPreselectedTires(selectedTireError);
    }
  }, [selectedTireError]);

  useEffect(() => {
    if (parentCustomerId) setCustomerId(parentCustomerId);
    if (parentQuoteId) setQuoteId(parentQuoteId);
  }, [parentCustomerId, parentQuoteId]);

  const onLocationChange = (newLocation: any) => setLocationSelected(getLocationById(newLocation));
  const getLocationById = (idToFind: number) => locationList?.find(({ id }) => id === idToFind) || null;
  const getTiresId = (tiresArray: Array<any>) => tiresArray.map(({ tireId }: any) => { return tireId; });
  const hasChangesPendingToVerify = tiresPendingToVerify.length > 0;

  return (
    <>
      <Modal
        key={id}
        isOpen={isOpen}
        onDismiss={handleCloseModal}
        containerClassName={styles.modalContainer}
      >
        <div className={styles.modalBody}>
          <div className='modalHeader'>
            <Text variant='xLarge'>{title}</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel='Close popup modal'
              onClick={handleCloseModal}
            />
          </div>
          {isLoadingInitialData ? <Spinner size={SpinnerSize.large} className={styles.spinner} /> :
            <>
              <div className={classNames('ms-grid', styles.marginBottom20)} dir='ltr'>
                <div className={classNames('ms-Grid-row')}>
                  <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
                    <Text variant='xLarge'>Customer Details</Text>
                    <SeparatorGy />
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm6', styles.marginTop20)}>
                    <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                      <TextField
                        {...customerFormFieldsGroup.getFieldForm('prefixField')}
                      />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm8')}>
                      <TextField
                        {...customerFormFieldsGroup.getFieldForm('customerNameField')}
                      />
                    </div>
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm2', styles.marginTop20, styles.rightContainer)}>
                    <TextField
                      {...customerFormFieldsGroup.getFieldForm('regionField')}
                    />
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm9', styles.marginTop20)}>
                    <div className={classNames('ms-Grid-col', 'ms-sm4', styles.marginTop10)}>
                      <AutoCompleteField
                        onChangeAction={onLocationChange}
                        value={locationSelected?.id}
                        list={getLocationCodeList}
                        label="Location Code"
                        data-testid='customer-loc-code'
                        icon="POISolid"
                        isLoading={isLoadingFetchLocations}
                        disabled={isViewAction}
                      />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm4', styles.marginTop10)}>
                      <AutoCompleteField
                        onChangeAction={onLocationChange}
                        value={locationSelected?.id}
                        list={getLocationNameList}
                        label="Location Name"
                        data-testid='customer-loc-name'
                        icon="POISolid"
                        isLoading={isLoadingFetchLocations}
                        disabled={isViewAction}
                      />
                    </div>
                    {!isViewAction && (
                      <div className={classNames('ms-Grid-col', 'ms-sm4', styles.marginTop25)}>
                        <PrimaryButton
                          id='locationsBtn'
                          text='Available Locations'
                          onClick={toggleOpenLocationsModal}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <Text variant='xLarge'>Quote Details</Text>
              <SeparatorGy />

              <div className={classNames('ms-grid', styles.marginBottom20)} dir='ltr'>
                <div className={classNames('ms-Grid-row')}>
                  <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
                    <Text variant='large'>General</Text>
                    <SeparatorGy />
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
                    <div className={classNames('ms-Grid-col', 'ms-sm3', styles.marginTop20)}>
                      <TextField
                        {...quoteDetailsFieldsGroup.getFieldForm('quoteIdField')}
                      />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm2', styles.marginTop20)}>
                      <DatePicker
                        label='Quote Req. Date'
                        value={new Date(quoteDetailsFieldsGroup.getFieldFormValue('quoteReqDateField'))}
                        showMonthPickerAsOverlay={true}
                        ariaLabel="Select a date"
                        onSelectDate={
                          (addedDateFrom: Date | null | undefined) =>
                            quoteDetailsFieldsGroup.setFormValue('quoteReqDateField', addedDateFrom)
                        }
                        disabled={isViewAction}
                        formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                      />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm2', styles.marginTop20)}>
                      <TextField
                        {...quoteDetailsFieldsGroup.getFieldForm('numberOfTiresField')}
                      />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm2', styles.marginTop20)}>
                      <DatePicker
                        label='Sold Date'
                        value={new Date(quoteDetailsFieldsGroup.getFieldFormValue('soldDateField'))}
                        showMonthPickerAsOverlay={true}
                        ariaLabel="Select a date"
                        onSelectDate={
                          (addedDateFrom: Date | null | undefined) =>
                            quoteDetailsFieldsGroup.setFormValue('soldDateField', addedDateFrom)
                        }
                        disabled={isViewAction}
                        formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                      />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm2', styles.marginTop20)}>
                      <TextField
                        {...quoteDetailsFieldsGroup.getFieldForm('totalPriceField')}
                      />
                    </div>
                  </div>

                  <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
                    <div className={classNames('ms-Grid-col', 'ms-sm2', styles.marginTop20, styles.checkBoxContainer)}>
                      <label htmlFor="">Sold or Lost?</label>
                      <Checkbox
                        label='Sold Tires'
                        onChange={(e) => setIsSoldTires(true)}
                        checked={isSoldTires}
                        disabled={isDataApplied}
                      />
                      <Checkbox
                        label='Lost Tires'
                        onChange={(e) => setIsSoldTires(false)}
                        checked={!isSoldTires}
                        disabled={isDataApplied}
                      />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm2', styles.marginTop20, styles.checkBoxContainer)}>
                      <label htmlFor="">Tread Dept</label>
                      <Checkbox
                        label='Manual'
                        onChange={(e) => setIsManualThread(true)}
                        checked={isManualThread}
                        disabled={isDataApplied}
                      />
                      <Checkbox
                        label='50%'
                        onChange={(e) => setIsManualThread(false)}
                        checked={!isManualThread}
                        disabled={isDataApplied}
                      />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm5', styles.marginTop20)}>
                      <TextField
                        {...quoteDetailsFieldsGroup.getFieldForm('commentsField')}
                        disabled={isViewAction}
                        multiline
                        rows={4}
                      />
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm2', styles.applyContainer)}>
                      <PrimaryButton
                        id='applyButton'
                        text='Apply'
                        onClick={handleApplyDetails}
                        disabled={isViewAction}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={classNames('ms-grid', styles.marginBottom20, styles.upperCaseInputs)} dir='ltr'>
                <div className={classNames('ms-Grid-row')}>
                  <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
                    <Text variant='large'>Sell By</Text>
                    <SeparatorGy />
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm7', styles.marginTop20)}>
                    {!isViewAction && <div className={classNames('ms-Grid-col', 'ms-sm12', styles.customerSwitch)}>
                      <Checkbox
                        label='Brand'
                        onChange={(e) => setCustomAddOption('brand')}
                        checked={customAddOption == 'brand'}
                      />
                    </div>}

                    <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                      <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                        <label htmlFor="">* From</label>
                      </div>
                      <div className={classNames('ms-Grid-col', 'ms-sm3', styles.marginTop20)}>
                        <TextField
                          {...sellByFieldsGroup.getFieldForm('prefixField')}
                          disabled={isViewAction || customAddOption != 'brand'}
                        />
                      </div>
                      <div className={classNames('ms-Grid-col', 'ms-sm3', styles.marginTop20)}>
                        <TextField
                          {...sellByFieldsGroup.getFieldForm('fromBrandNumberField')}
                          disabled={isViewAction || customAddOption != 'brand'}
                        />
                      </div>
                      <div className={classNames('ms-Grid-col', 'ms-sm3', styles.marginTop20)}>
                        <TextField
                          {...sellByFieldsGroup.getFieldForm('suffixField')}
                          disabled={isViewAction || customAddOption != 'brand'}
                        />
                      </div>
                      {!isViewAction &&
                        <div className={classNames('ms-Grid-col', 'ms-sm3', styles.consignedTiresBtnContainer)}>
                          <PrimaryButton
                            id='consignedTiresButton'
                            text='View Consigned Tires'
                            onClick={toggleOpenConsignedTiresModal}
                            disabled={isViewAction || customAddOption != 'brand'}
                          />
                        </div>
                      }
                    </div>
                    <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                      <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                        <label htmlFor="">To (Series)</label>
                      </div>
                      <div className={classNames('ms-Grid-col', 'ms-sm3', styles.marginTop20)}>
                        <TextField
                          {...sellByFieldsGroup.getFieldForm('prefixField')}
                          disabled={true}
                        />
                      </div>
                      <div className={classNames('ms-Grid-col', 'ms-sm3', styles.marginTop20)}>
                        <TextField
                          {...sellByFieldsGroup.getFieldForm('toBrandNumberField')}
                          disabled={isViewAction || customAddOption != 'brand'}
                        />
                      </div>
                      <div className={classNames('ms-Grid-col', 'ms-sm3', styles.marginTop20)}>
                        <TextField
                          {...sellByFieldsGroup.getFieldForm('suffixField')}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={classNames('ms-Grid-col', 'ms-sm2', styles.marginTop20)}>
                    {!isViewAction && <div className={classNames('ms-Grid-col', 'ms-sm12', styles.customerSwitch)}>
                      <Checkbox
                        label='File'
                        onChange={(e) => setCustomAddOption('file')}
                        checked={customAddOption == 'file'}
                      />
                    </div>}
                    <DefaultButton
                      text="Upload"
                      onClick={toggleShowUpload}
                      disabled={isViewAction || customAddOption != 'file'}
                    />
                    <div className={classNames('ms-Grid-col', 'ms-sm8', styles.marginTop20)}>
                      <Text variant='mediumPlus' className={styles.highlight}>STATUS: { runningStatus } </Text>
                    </div>
                  </div>

                  <div className={classNames('ms-Grid-col', 'ms-sm3', styles.marginTop20)}>
                    {!isViewAction && <div className={classNames('ms-Grid-col', 'ms-sm12', styles.customerSwitch)}>
                      <Checkbox
                        id='vehicleCheck'
                        label='Vehicle #'
                        onChange={(e) => setCustomAddOption('vehicle')}
                        checked={customAddOption == 'vehicle'}
                      />
                    </div>}
                    <div className={classNames('ms-Grid-col', 'ms-sm4', styles.marginTop10)}>
                      <TextField
                        {...sellByFieldsGroup.getFieldForm('vehicleNumberField')}
                        disabled={isViewAction || customAddOption != 'vehicle'}
                      />
                    </div>
                    {!isViewAction && (
                      <div className={classNames('ms-Grid-col', 'ms-sm8', styles.registryButtons)}>
                        <PrimaryButton
                          id='vehicleRegistryButton'
                          text='Vehicle Registry'
                          onClick={toggleOpenVehiclesModal}
                          disabled={isViewAction || customAddOption != 'vehicle'}
                        />
                        <PrimaryButton
                          id='addVehicleRegistryButton'
                          text='+ Add'
                          onClick={handleAddTires}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className={classNames('ms-grid', styles.marginBottom20)} dir='ltr'>
                <div className={classNames('ms-Grid-row')}>
                  <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
                    <DataGridComponent
                      idTable={'quotes-selected-tires'}
                      title='Selected Tires'
                      preselectedRows={preSelectedTires}
                      headCells={tiresHeadCells}
                      rowsTable={tiresQuoteList}
                      totalDataFound={totalFound}
                      isLoading={isLoadingTiresTable}
                      reloadControlsTable={reloadControlsTable}
                      defaultSorting={defaultSorting}
                      defaultRowsPerPage={pageSizes}
                      enableCheckBox={actionType != 'view'}
                      enableMultiSelectRow
                      enableMultiSorting
                      enablePagination
                      enableRowsPerPage
                      enableRefreshOption
                      enableDeleteOption={actionType != 'view'}
                      enableResizeColumns
                      handleChangeDataGridState={handleChangeDataGridState}
                      handleUpdate={handleUpdate}
                      handleDelete={handleDeleteTires}
                      handleSelectRow={setSelectedTires}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.modalFooter}>
                <DefaultButton
                  id='printExportBtn'
                  text='Print/Export'
                  onClick={toggleShowPrintExport}
                />
                <PrimaryButton
                  id='errorsButton'
                  text='View Errors'
                  onClick={toggleOpenErrorsModal}
                />
                {!isViewAction && (
                  <>
                    <PrimaryButton
                      disabled={!(selectedTires.length > 0)}
                      id='verifyButton'
                      text='Verify'
                      onClick={handleVerify}
                    />

                    <PrimaryButton
                      disabled={!(selectedTires.length > 0)}
                      id='releaseButton'
                      text='Release'
                      onClick={toggleReleaseConfirmation}
                    />
                  </>
                )}
              </div>
            </>}
        </div>

      </Modal>
      <VehiclesModalComponent
        id='vehiclesModal'
        customerId={customerId}
        locationId={locationSelected?.id}
        title='Available Vehicles'
        isOpen={openVehiclesModal}
        onSubmit={handleSelectVehicle}
        onDismiss={toggleOpenVehiclesModal}
        successLabel='Select Vehicle'
        cancelLabel='Cancel'
      />
      <LocationsModalComponent
        id='locationsModal'
        customerId={customerId}
        title='Available Locations'
        isOpen={openLocationsModal}
        onSubmit={handleSelectLocation}
        onDismiss={toggleOpenLocationsModal}
        successLabel='Select Location'
        cancelLabel='Cancel'
      />
      <ViewErrorsModalComponent
        id='errorsModal'
        quoteId={quoteId}
        tireList={tiresQuoteList}
        setSelectedTireError={setSelectedTireError}
        title='Sold & Lost Tire Quotes/Billing | Errors'
        isOpen={openErrorsModal}
        onSubmit={toggleOpenErrorsModal}
        onDismiss={toggleOpenErrorsModal}
        successLabel='Close'
      />
      <ConsignedTiresModalComponent
        id='consignedTiressModal'
        customerId={customerId}
        locationId={locationSelected?.id}
        title='Sold & Lost Tire Quotes/Billing | Errors'
        isOpen={openConsignedTiresModal}
        onSubmit={handleSelectTire}
        onDismiss={toggleOpenConsignedTiresModal}
        successLabel='Select Tire'
        cancelLabel='Cancel'
      />
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      <DialogComponent
        isOpen={showVerifyWarning}
        title='Confirmation'
        subText='There are some changes pending to verify, are you sure to continue?.'
        onCancelLabel='Discard changes'
        onSubmitLabel='Verify'
        onCancel={handleDiscardChanges}
        onSubmit={handleVerify}
      />
      <ThreadMassUpload
        isModalOpen={showUpload}
        hideModal={toggleShowUpload}
        quoteId={quoteId}
        sold={isSoldTires}
        manual={isManualThread}
        fetchTiresQuote={fetchTiresQuote}
        getUploadRunningStatus={setRunningStatus}
      />
      <DialogComponent
        isOpen={showReleaseConfirmation}
        title='Confirmation'
        subText='You are about to release the selected tire/s, are you sure to continue?'
        onCancelLabel='Cancel'
        onSubmitLabel='Release'
        onCancel={() => toggleReleaseConfirmation()}
        onSubmit={handleRelease}
      />
    </>
  );
};

export default ViewMaintainTireSalesModalComponent;