import { ITabComponent } from '../../models/ITabComponent';
import CreateInvoices from './tabComponents/CreateInvoices/CreateInvoices';
import ViewInvoicesComponent from './tabComponents/ViewInvoices';
import ViewPendingMileageRun from './tabComponents/ViewPendingMileageRun/ViewPendingMileageRun';
import ViewTireSalesComponent from './tabComponents/ViewTireSales';
import PostMileageAdjustments from './tabComponents/PostMileageAdjustments';
import SetBillingData from './tabComponents/SetBillingData/SetBillingData';
import CloseoutBilling from './tabComponents/CloseoutBilling/CloseoutBilling';
import BackoutMileageRun from './tabComponents/BackoutMileageRun/BackoutMileageRun';
import BulkReleaseComponent from './tabComponents/BulkRelease';
import { auth_billing_closeoutBilling, auth_billing_createInvoices, auth_billing_postMileageAdjustments, auth_billing_regenerateInvoice, auth_billing_setBillingData, auth_billing_soldLostTireQuotesBilling, auth_billing_viewInvoices, auth_billing_viewPendingMileageRun, auth_mileageRun_backout } from '../../consts/programKeys';

interface IBillingTabs {
  soldLostTireQuotes: ITabComponent;
  viewInvoices: ITabComponent;
  bulkRelease: ITabComponent;
  viewPendingMileageRun: ITabComponent;
  createInvoices: ITabComponent;
  postMileageAdjustments: ITabComponent;
  //regenerateInvoice: ITabComponent; //not an MVP
  closeoutBilling: ITabComponent;
  setBillingData: ITabComponent;
  backoutMileageRun: ITabComponent;
}

export enum BILLING_TABS {
  VIEW_INVOICES = 'viewInvoices',
  BULK_RELEASE = 'bulkRelease',
  VIEW_PENDING_MILEAGE_RUN = 'viewPendingMileageRun',
  CREATE_INVOICES = 'createInvoices',
  SOLD_LOST_TIRE_QUOTES = 'soldLostTireQuotes',
  POST_MILEAGE_ADJUSTMENTS = 'postMileageAdjustments',
  //REGENERATE_INVOICE = 'regenerateInvoice',
  CLOSEOUT_BILLING = 'closeoutBilling',
  SET_BILLING_DATA = 'setBillingData',
  BACKOUT_MILEAGERUN = 'backoutMileageRun',
};


/** Each tab into left menu must have a component */
export const billingTabs: IBillingTabs = {
  viewInvoices: {
    id: BILLING_TABS.VIEW_INVOICES,
    accessKey: auth_billing_viewInvoices,
    name: 'View Invoices',
    component: ViewInvoicesComponent,
    subTabs: null,
  },
  bulkRelease: {
    id: BILLING_TABS.BULK_RELEASE,
    accessKey: auth_billing_viewInvoices,
    name: 'Bulk Release',
    component: BulkReleaseComponent,
    subTabs: null,
  },
  viewPendingMileageRun: {
    id: BILLING_TABS.VIEW_PENDING_MILEAGE_RUN,
    accessKey: auth_billing_viewPendingMileageRun,
    name: 'View Pending Mileage Run',
    component: ViewPendingMileageRun,
    subTabs: null,
  },
  createInvoices: {
    id: BILLING_TABS.CREATE_INVOICES,
    accessKey: auth_billing_createInvoices,
    name: 'Create Invoices',
    component: CreateInvoices,
    subTabs: null,
  },
  soldLostTireQuotes: {
    id: BILLING_TABS.SOLD_LOST_TIRE_QUOTES,
    accessKey: auth_billing_soldLostTireQuotesBilling,
    name: 'Sold & Lost Tire Quotes/Billing',
    component: ViewTireSalesComponent,
    subTabs: null,
  },
  postMileageAdjustments: {
    id: BILLING_TABS.POST_MILEAGE_ADJUSTMENTS,
    accessKey: auth_billing_postMileageAdjustments,
    name: 'Post Mileage Adjustments',
    component: PostMileageAdjustments,
    subTabs: null,
  },
  // regenerateInvoice: {
  //   id: BILLING_TABS.REGENERATE_INVOICE,
  //   accessKey: auth_billing_regenerateInvoice,
  //   name: 'Regenerate Invoices',
  //   component: null,
  //   subTabs: null,
  // },
  closeoutBilling: {
    id: BILLING_TABS.CLOSEOUT_BILLING,
    accessKey: auth_billing_closeoutBilling,
    name: 'Closeout Billing',
    component: CloseoutBilling,
    subTabs: null,
  },
  setBillingData: {
    id: BILLING_TABS.SET_BILLING_DATA,
    accessKey: auth_billing_setBillingData,
    name: 'Set Billing Data',
    component: SetBillingData,
    subTabs: null,
  },
  backoutMileageRun: {
    id: BILLING_TABS.BACKOUT_MILEAGERUN,
    accessKey: auth_mileageRun_backout,
    name: 'Backout Mileage Run',
    component: BackoutMileageRun,
    subTabs: null,
  },
};

export const getTabById: any = (tabId: string) => Object.values(billingTabs).filter((tab) => tab.id === tabId)[0];
