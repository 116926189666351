import { Dropdown, TextField, Text, MessageBarType, IconButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { IOrderHeaderProps } from './IOrderHeaderProps';
import apiService from '../../../../../../../../../../api';
import { IOrderHeaderState } from './IOrderHeaderState';
import useNotifications from '../../../../../../../../../../hooks/useNotifications';
import styles from './OrderHeader.module.scss';
import LoadingScreen from '../../../../../../../../../LoadingScreen/LoadingScreen';
import AutocompleteInput from '../../../../../../../../../../shared/AutocompleteInput';
import { debounce } from 'lodash';
import PlantModalComponent from '../../../../../../../../../../shared/PlantSelectionModalComponent';


const OrderHeader: FC<IOrderHeaderProps> = ({ 
  plants,
  setPlants,
  orderToBeAdded, 
  setOrderToBeAdded, 
  orderItemToBeAdded, 
  setOrderItemToBeAdded,
  parsedErrors,
  mode, 
}) => {
  const [state, setState] = useState<IOrderHeaderState>({
    loading: false,
    locationCode: '',
    plants: [],
    billToSundres: [],
  });

  const [showPlantModal, { toggle: toggleShowPlantModal }] = useBoolean(false);

  const { addNotification } = useNotifications();

  const fetchBillToSundry = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await apiService.tireOrders.getBillToSundry();
      setState((prev: any) => ({ ...prev, billToSundres: data }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching bill to sundry error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchPlant = async (plant: string) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.plantWareHouseAPI.getAvailablePlants(
        null,
        { searchString: plant, type: 'RETREAD', active: true },
        {},
      );
      const items = data.data;
      setState((prev: any) => ({ ...prev, plants: items }));
      setPlants(items);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching plants error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchLocations = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data }: any = await apiService.getLocationsByCustomerId(
        orderToBeAdded.customerId,
        { pageSize: 5000, pageNumber: 1 },
        {},
      );
      const orderLocation = data.data.find((location: any) => location.id === orderToBeAdded.locationId).locationCode;
      setState((prev) => ({ ...prev, locationCode: orderLocation }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Locations fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const plantInputText = (plant: string) => {
    if (plant.length > 0) {
      getPlant(plant);
    }
  };
    
  const getPlant = debounce(async (plant) => {
    fetchPlant(plant);
  }, 1000);

  const handleSelectPlant = (rowSelected: any) => {
    setOrderItemToBeAdded((prev: any) => ({ ...prev, warehouse: rowSelected.id, warehouseNo: rowSelected.plantNo }));
    fetchPlant(rowSelected.plantNo);
    toggleShowPlantModal();
  };

  const onChangeOrderField = (field: any, value: any, regExp?: RegExp) => {
    setOrderToBeAdded((prev: any) => ({ ...prev, [field]: regExp ? (regExp.test(value) ? value : prev[field]) : value }));
  };

  const onChangeOrderItemField = (field: any, value: any, regExp?: RegExp) => {
    setOrderItemToBeAdded((prev: any) => ({ ...prev, [field]: regExp ? (regExp.test(value) ? value : prev[field]) : value }));
  };

  const onChangeWarehouse = (id: any) => {
    const plant = state.plants.find((value: any) => value.id === id);
    setOrderItemToBeAdded((prev: any) => ({ ...prev, warehouse: plant.id, warehouseNo: plant.plantNo }));
  };

  const parseErrors = (field: string) => {
    const customError = parsedErrors?.filter((error: { field: string; }) => error.field === field)[0];
    if (customError) {
      return 'Mandatory field is empty';
    }
  };

  useEffect(() => {
    if (orderToBeAdded.customerId) fetchLocations();
  }, [orderToBeAdded]);

  useEffect(() => {
    setState((prev: any) => ({ ...prev, plants: plants }));
    fetchBillToSundry();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <Text variant="xLarge" className={styles.highlight}>Order Header</Text>
        <div>
          <div>
            <TextField
              label="Order #"
              value={orderToBeAdded.id}
              disabled
            />
          </div>
          {
            orderToBeAdded.shipToOemName ? 
              <div>
                <TextField
                  label="Ship To OEM"
                  value={orderToBeAdded.shipToOemName}
                  disabled
                />
              </div> :
              <div>
                <TextField
                  label="Location"
                  value={state.locationCode}
                  disabled
                />
              </div>
          }
          <div>
            <TextField
              label="Status"
              value={orderToBeAdded.status}
              disabled  
            />
          </div>
        </div>
        <div>
          <div>
            <TextField
              label="Date ordered"
              value={orderToBeAdded.orderDate ? moment(orderToBeAdded.orderDate).format('MM/DD/YYYY') : 'N/A'}
              disabled  
            />
          </div>
          <div>
            <TextField
              label="Date shipped"
              value={orderToBeAdded.shipDate ? moment(orderToBeAdded.shipDate).format('MM/DD/YYYY') : 'N/A'}
              disabled  
            />
          </div>
          <div>
            <TextField
              label="Date recived"
              value={orderToBeAdded.recvDate ? moment(orderToBeAdded.recvDate).format('MM/DD/YYYY') : 'N/A'}
              disabled  
            />
          </div>
        </div>
        <div>
          <div>
            <TextField
              label="Invoice"
              value={orderItemToBeAdded.invoiceId}
              onChange={(e, invoiceId: any) => onChangeOrderItemField('invoiceId', invoiceId)}
              disabled={mode === 'view' ? true : false}
            />
          </div>
          <div>
            <AutocompleteInput
              label="Retread plant"
              initialValue={orderItemToBeAdded.warehouseNo}
              list={state.plants.map((plant: any) => ({
                key: plant.id,
                text: plant.plantNo,
              }))}
              emptyExpanded
              required
              chooseCurrentItem={(warehouse: any) => onChangeWarehouse(warehouse)}
              textValue={plantInputText}
              upperCase
              errorMessage={parseErrors('warehouse')}
              disabled={mode === 'view' ? true : false}
            /> 
            <IconButton
              id='searchPrefix'
              onClick={toggleShowPlantModal}
              iconProps={{ iconName: 'Search' }}
              disabled={mode === 'view' ? true : false}
            />
          </div>
          <div>
            <Dropdown
              label="Bill to Sundry"
              options={state.billToSundres.map((billToSundry, index) => ({
                key: index,
                text: billToSundry,
              }))}
              defaultSelectedKey={state.billToSundres.findIndex((billToSundre) => billToSundre === orderToBeAdded.billToSundry)}
              onChange={(e, { text: billToSundry }: any) => onChangeOrderField('billToSundry', billToSundry)}
              errorMessage={parseErrors('billToSundry')}
              required
              disabled={mode === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
      <PlantModalComponent
        id='wareHouseModal'
        title='View Retread Details'
        type='RETREAD'
        isOpen={showPlantModal}
        onSubmit={handleSelectPlant}
        onDismiss={toggleShowPlantModal}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default OrderHeader;