import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { get, isEmpty, isNil } from 'lodash';
import { DefaultButton, IconButton, MessageBarType, Modal, PrimaryButton, Text, TextField } from '@fluentui/react';

import useNotifications from '../../../../../hooks/useNotifications';
import apiService from '../../../../../api';

import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import LoadingScreen from '../../../../LoadingScreen/LoadingScreen';

import { IAddingModalProps } from './IAddingModalProps';
import { IAddingModalState } from './IAddingModalState';
import { columns, emptyAddingState } from './consts';
import { IAddingState } from './IAddingState';

import styles from './AddingModal.module.scss';
import { useSelector } from 'react-redux';
import { customerSelector } from '../../../../../redux/recordKeepingSlice';

const AddingModal: FC<IAddingModalProps> = ({ isOpen, onDismiss }) => {
  const [addingFormState, setAddingFormState] = useState<IAddingState>(emptyAddingState);
  const [parsedAddingErrors, setParsedAddingErrors] = useState<any>([]);
  const [parsedErrors, setParsedErrors] = useState<any>([]);
  const [editedRowId, setEditedRowId] = useState<number>(-1);
  const [state, setState] = useState<IAddingModalState>({
    items: [],
    selectedItems: [],
    loading: false,
    foundCount: 0,
  });

  const { id: customerId } = useSelector(customerSelector);
  const { addNotification } = useNotifications();

  const handleSelect = (e: any, itemId: any) => {
    const selectedRows = [...state.selectedItems];
    if (e.target.checked) {
      selectedRows.push(itemId);
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows.filter(row => row !== itemId) }));
    }
  };

  const handleSelectAll = (e: any) => {
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedItems: state.items.map(({ id }) => id) })) : setState((prev: any) => ({ ...prev, selectedItems: [] }));
  };

  const parseAddingErors = (field: string) => {
    const customError = parsedAddingErrors?.filter((error: { field: string; }) => error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  const handleAdd: any = async () => {
    try {
      setState(prev => ({ ...prev, loading: true, selectedItems: [] }));
      const { bpfx, beginBno, bsfx, endBno } = addingFormState;
      const { data } = await apiService.changeTypeCode.handleAdd(
        bpfx, beginBno, isNil(bsfx) || isEmpty(bsfx) ? null : bsfx, endBno || beginBno, customerId,
      );
      setState((prev: any) => ({
        ...prev,
        items: data.changeTypeCodes.map((item: any) => ({ ...item, newTypeCode: '' })),
      }));
      setParsedAddingErrors([]);
      setAddingFormState(emptyAddingState);
      if (data.changeTypeCodes.length === 0) {
        addNotification({
          text: 'TypeCodes weren\'t found.',
          type: MessageBarType.error,
        });
      } else {
        addNotification({
          text: 'TypeCodes were successfully added.',
          type: MessageBarType.success,
        });
      }
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          setParsedAddingErrors(response.data.state);
          return addNotification({
            text: 'Adding TypeCode error.',
            type: MessageBarType.error,
          });
        default:
          return addNotification({
            text: response.data.message,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSubmitClick = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const selectedTypeCodes = state.selectedItems.map((selectedId: any) => {
        const { typeCode: currentTypeCode, ...other } = state.items.find(({ id }) => selectedId === id);
        return ({ ...other, currentTypeCode, customerId });
      });
      await apiService.changeTypeCode.handleSubmitModal(selectedTypeCodes);
      addNotification({
        text: 'Type Code changes were successfully added.',
        type: MessageBarType.success,
      });
      handleDeleteChangingItem();
      setParsedErrors([]);
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          setParsedErrors(response.data.state);
          return addNotification({
            text: 'Unable to add Type Code changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Type Code changes saving error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleDeleteChangingItem = () => {
    setState((prev: any) => ({
      ...prev,
      items: state.items.filter(({ id }) => state.selectedItems.indexOf(id) === -1),
      selectedItems: [],
    }));
  };

  const parseErrors = (id: string, field: string) => {
    const customError = parsedErrors?.filter((error: { id: string; field: string; }) => error.id == id && error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  const highlightRowIfError = (item: any) => {
    if (parsedErrors.length > 0) {
      return parsedErrors.filter((error: { id: any; }) => error.id == item.id).length > 0;
    }
  };

  const updateRowsData = ({ target: { name, value } }: any) => {
    setState((prev: any) => ({
      ...prev,
      items: state.items.map((item: { [x: string]: string | undefined; id: any; }) => {
        if (editedRowId === item.id) {
          item[name] = value;
        }
        return item;
      }),
    }));
  };

  useEffect(
    () => {
      if (!isOpen) {
        setAddingFormState(emptyAddingState);
        setState((prev: any) => ({ ...prev, items: [], selectedItems: [] }));
      }
    },
    [isOpen],
  );

  return (
    <Modal
      isOpen={isOpen}
      isBlocking={true}
      containerClassName={styles.modalContainer}
    >
      <div>
        <Text variant="xxLarge" className={styles.highlight}>Change TypeCode</Text>
        <IconButton
          id='closeModalButton'
          iconProps={{ iconName: 'Cancel' }}
          ariaLabel="Close popup modal"
          onClick={onDismiss}
        />
      </div>
      <SeparatorGy />
      <div>
        <div className="ms-Grid-col ms-sm4">
          <Text block className={styles.highlight}>Add up to 100 tires. Start entering the tires into the table.</Text>
          <br />
          <Text variant="xLarge" className={styles.highlight}>Add a series</Text>
          <SeparatorGy />
          <br />
          <Text variant="xLarge" className={styles.highlight}>From Brand Number</Text>
          <div className={styles.addingFormLine}>
            <TextField
              id="bpfx"
              label="Prefix"
              value={addingFormState.bpfx}
              onChange={(ev: any, bpfx: any) => setAddingFormState((prev: any) => ({ ...prev, bpfx: bpfx?.toUpperCase() }))}
              errorMessage={parseAddingErors('bpfx')}
              required
            />
            <TextField
              id="beginBno"
              label="Brand Number"
              value={addingFormState.beginBno}
              onChange={(ev: any, beginBno: any) => setAddingFormState((prev: any) => ({ ...prev, beginBno }))}
              errorMessage={parseAddingErors('beginBno')}
              required
            />
            <TextField
              id="bsfx"
              label="Suffix"
              value={addingFormState.bsfx}
              onChange={(ev: any, bsfx: any) => setAddingFormState((prev: any) => ({ ...prev, bsfx }))}
              errorMessage={parseAddingErors('bsfx')}
            />
          </div>
          <br />
          <Text variant="xLarge" className={styles.highlight}>To Brand Number</Text>
          <div className={styles.addingFormLine}>
            <TextField
              label="Prefix"
              value={addingFormState.bpfx}
              disabled
            />
            <TextField
              id="endBno"
              label="Brand Number"
              value={addingFormState.endBno}
              onChange={(ev: any, endBno: any) => setAddingFormState((prev: any) => ({ ...prev, endBno }))}
              errorMessage={parseAddingErors('endBno')}
            />
            <TextField
              label="Suffix"
              value={addingFormState.bsfx}
              disabled
            />
          </div>
          <br />
          <PrimaryButton
            id="addingButton"
            text="+ Add"
            disabled={
              isEmpty(addingFormState.bpfx) ||
              isEmpty(addingFormState.beginBno)
            }
            onClick={handleAdd}
          />

        </div>
        <div className="ms-Grid-col ms-sm8">
          <div className={styles.endAlignedFlexBlock}>
            <Text block variant="large" className={styles.highlight}>{state.selectedItems.length} items selected</Text>
            <SeparatorGy vertical />
            <IconButton
              id="deleteChangingItem"
              disabled={!state.selectedItems.length}
              iconProps={{ iconName: 'Delete' }}
              onClick={handleDeleteChangingItem}
            />
            <SeparatorGy vertical />
            <Text block variant="large" className={styles.highlight}>{state.items.length} Found</Text>
          </div>
          <div className={styles.tableWrapper}>
            <table>
              <thead>
                <tr>
                  {
                    columns.map(item => (
                      <th key={item.name}>
                        {item.name}
                      </th>
                    ))
                  }
                  <th>
                    <div className={styles.round}>
                      <input
                        id="change-type-code-adding-modal-all"
                        type="checkbox"
                        checked={state.items.length !== 0 && state.selectedItems.length === state.items.length}
                        onChange={handleSelectAll}
                      />
                      <label htmlFor="change-type-code-adding-modal-all"></label>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  state.items.map(item => (
                    <tr key={item.id}
                      className={classNames(state.selectedItems.includes(item.id) ? styles.trSelected : styles.trBasic,
                        highlightRowIfError(item) ? styles.trError : styles.trBasic)}
                      onFocus={() => { setEditedRowId(item.id); }}>
                      <td>
                        <TextField
                          name="bpfx"
                          styles={{ fieldGroup: { border: '1px solid transparent' } }}
                          value={item.bpfx}
                          onChange={updateRowsData}
                          errorMessage={parseErrors(item.id, 'bpfx')}
                        />
                      </td>
                      <td>
                        <TextField
                          name="bno"
                          styles={{ fieldGroup: { border: '1px solid transparent' } }}
                          value={item.bno}
                          onChange={updateRowsData}
                          errorMessage={parseErrors(item.id, 'bno')}
                        />
                      </td>
                      <td>
                        <TextField
                          name="bsfx"
                          styles={{ fieldGroup: { border: '1px solid transparent' } }}
                          value={item.bsfx}
                          onChange={updateRowsData}
                          errorMessage={parseErrors(item.id, 'bsfx')}
                        />
                      </td>
                      <td>
                        <Text>{get(item, 'typeCode')}</Text>
                      </td>
                      <td>
                        <TextField
                          name="newTypeCode"
                          styles={{ fieldGroup: { border: '1px solid transparent' } }}
                          value={item.newTypeCode}
                          onChange={updateRowsData}
                          errorMessage={parseErrors(item.id, 'newTypeCode')}
                        />
                      </td>
                      <td>
                        <div className={styles.round}>
                          <input type="checkbox" id={item.id} checked={state.selectedItems.includes(item.id)} onChange={(e) => handleSelect(e, item.id)} />
                          <label htmlFor={item.id}></label>
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className={classNames(styles.endAlignedFlexBlock)}>
            <DefaultButton text="Cancel" onClick={onDismiss} />
            <PrimaryButton
              text="Submit - Add Tires"
              id="submitAddingButton"
              onClick={handleSubmitClick}
              disabled={state.selectedItems.length === 0}
            />
          </div>
        </div>
      </div>
      {state.loading && <LoadingScreen />}
    </Modal>
  );
};

export default AddingModal;