import { DefaultButton, IconButton, MessageBarType, Modal, PrimaryButton, Text, TextField } from '@fluentui/react';
import { FunctionComponent, useState } from 'react';
import styles from './BrandModalComponent.module.scss';
import apiService from '../../api';
import useNotifications from '../../hooks/useNotifications';
import { brandNumbersSelectionHeadCell, BrandSelectionModalComponentProps, BrandSelectionModalComponentState, defaultSorting, emptySelectedBrand, pageSizes } from './consts';
import DataGridComponent from '../DataGridComponent';
import { customerSelector } from '../../redux/recordKeepingSlice';
import { useSelector } from 'react-redux';


const BrandModalComponent: FunctionComponent<BrandSelectionModalComponentProps> = ({
  isOpen,
  onSubmit,
  onDismiss,
  brandPrefix,
}) => {

  const [state, setState] = useState<BrandSelectionModalComponentState>({
    loading: false,
    brandNumbers: [],
    foundCount: 0,
  });

  const { addNotification } = useNotifications();
  const [brandSelected, setBrandSelected] = useState<any>(emptySelectedBrand);

  const { id: customerId } = useSelector(customerSelector);

  const removeDuplicates = (arr: Array<any>) => {
    const result: Array<any> = [];
    const duplicatesIndices: Array<number> = [];

    arr.forEach((current, index) => {
      if (duplicatesIndices.includes(index)) return;

      result.push(current);

      for (let comparisonIndex = index + 1; comparisonIndex < arr.length; comparisonIndex++) {
        const comparison = arr[comparisonIndex];
        const currentKeys = Object.keys(current);
        const comparisonKeys = Object.keys(comparison);

        if (currentKeys.length !== comparisonKeys.length) continue;

        const currentKeysString = currentKeys.sort().join('').toLowerCase();
        const comparisonKeysString = comparisonKeys.sort().join('').toLowerCase();

        if (currentKeysString !== comparisonKeysString) continue;

        let valuesEqual = true;
        for (let i = 0; i < currentKeys.length; i++) {
          const key = currentKeys[i];
          if (current[key] !== comparison[key]) {
            valuesEqual = false;
            break;
          }
        }
        if (valuesEqual) {
          duplicatesIndices.push(comparisonIndex);
        }
      }
    });
    return result;
  };

  const fetchData = async (pagination: any, sortOrder?: any) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.brandAPI.getAvailableBrands(
        customerId,
        brandPrefix,
        pagination,
        sortOrder,
      );
      const foundCount = data.total.found;
      setState((prev: any) => ({ ...prev, brandNumbers: removeDuplicates(data.data), foundCount }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Available Brand Numbers fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleChangeDataGridState = (dataGridState: any) => {
    const { countOnPage, paginationProps, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    fetchData(pagination, sortOrder);
  };

  const handleSelectRow = (rowSelected: any) => {
    if (rowSelected.length) {
      const selectedBrand = rowSelected[0];
      setBrandSelected((prev: any) => ({
        ...prev,
        startNumber: selectedBrand.startNumber,
        endNumber: selectedBrand.endNumber,
        countNumber: selectedBrand.count,
      }));
    }
    else {
      setBrandSelected((prev: any) => ({
        ...prev,
        startNumber: null,
        endNumber: null,
      }));
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onDismiss={() => {
          onDismiss();
        }}
        containerClassName='modalContainer'
      >
        <div className={styles.modalBody}>
          <div className='modalHeader'>
            <Text variant="xLarge" className={styles.highlight}>Available Brand Numbers</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel="Close popup modal"
              onClick={() => {
                onDismiss();
              }}
            />
          </div>
          <div className={styles.searchParams}>
            <div className={styles.brandPrefix}>
              <TextField
                label='Contract Brand Prefix'
                value={brandPrefix}
                disabled
              />
            </div>
            <div className={styles.brandPrefix}>
              <TextField
                label='Locate count of'
                value={brandSelected.locateCount}
                onChange={(e, locateCount: any) => setBrandSelected((prev: any) => ({ ...prev, locateCount }))}
              />
            </div>
          </div>
          <div className='tableContainer'>
            <DataGridComponent
              idTable={'retread-plant-table'}
              title=''
              headCells={brandNumbersSelectionHeadCell}
              rowsTable={state.brandNumbers}
              totalDataFound={state.foundCount}
              isLoading={state.loading}
              enableCheckBox
              enablePagination
              enableRowsPerPage
              defaultRowsPerPage={pageSizes}
              defaultSorting={defaultSorting}
              handleChangeDataGridState={handleChangeDataGridState}
              handleSelectRow={handleSelectRow}
            />
          </div>
          <div className={styles.modalFooter}>
            <DefaultButton
              id="cancelButton"
              text="Close"
              onClick={() => {
                onDismiss();
              }}
            />
            <PrimaryButton
              id="selectButton"
              text="Select"
              onClick={(e) => {
                if (brandSelected.locateCount < 0 || brandSelected.locateCount > brandSelected.countNumber) {
                  addNotification({
                    text: 'Locate Count Of value should not be greater than Count value',
                    type: MessageBarType.error,
                  });
                }
                else { onSubmit(brandSelected); }
              }}
              disabled={!brandSelected.startNumber || !brandSelected.locateCount}
            />
          </div>
        </div>
      </Modal>
    </>
  );

};

export default BrandModalComponent;