import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { customerSelector, locationSelector } from '../../../../../../redux/recordKeepingSlice';
import Stepper from '../../../../../../shared/Stepper';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import OrderHeader from './Components/OrderHeader/OrderHeader';
import HistoryAndMessages from '../../Components/HistoryAndMessages/HistoryAndMessages';
import NewOrderDetails from './Components/OrderDetails/NewOrderDetails';
import ShippingInstructions from '../../Components/ShippingInstructions/ShippingInstructions';
import { emptyPlaceRetreadOrder, emptyPlaceRetreadOrderItem } from './consts';
import { INewPlaceRetreadProps } from './INewPlaceRetreadProps';
import styles from './NewPlaceRetread.module.scss';
import { orderSteps } from '../../consts';
import { MessageBarType, PrimaryButton } from '@fluentui/react';
import useNotifications from '../../../../../../hooks/useNotifications';


const NewPlaceRetread: FC<INewPlaceRetreadProps> = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);

  const [orderToBeAdded, setOrderToBeAdded] = useState(emptyPlaceRetreadOrder);
  const [orderItemToBeAdded, setOrderItemToBeAdded] = useState(emptyPlaceRetreadOrderItem);

  const [typeCodes, setTypeCodes] = useState<any>([]);
  const [plants, setPlants] = useState<any>([]);
  const [plant, setPlant] = useState<any>(null);

  const renderStepAccordingKey = (stepKey: number) => {
    switch (stepKey) {
      case 0:
        return <NewOrderDetails
          typeCodes={typeCodes}
          setTypeCodes={setTypeCodes}
          plants={plants}
          setPlants={setPlants}
          orderToBeAdded={orderToBeAdded}
          setOrderToBeAdded={setOrderToBeAdded}
          orderItemToBeAdded={orderItemToBeAdded}
          setOrderItemToBeAdded={setOrderItemToBeAdded} 
        />;
      case 1:
        return <ShippingInstructions orderToBeAdded={orderToBeAdded} />;
      case 2:
        return <HistoryAndMessages orderToBeAdded={orderToBeAdded} />;
      default:
        return stepKey;
    }
  };

  const finish = () => {
    setCurrentStep(0);
    setOrderToBeAdded(emptyPlaceRetreadOrder);
    setOrderItemToBeAdded(emptyPlaceRetreadOrderItem);
    setPlant(null);
    addNotification({
      text: 'Order tire was successfully added.',
      type: MessageBarType.success,
    });
  };

  useEffect(() => {
    setOrderToBeAdded((prev: any) => ({ ...prev, customerId, locationId }));
  }, [customerId, locationId]);
  
  return (
    <>
      <div className={classNames(styles.mainBlock)}>
        <div>
          <Stepper currentStep={currentStep} steps={orderSteps} setCurrentStep={setCurrentStep}/>
          <div >{renderStepAccordingKey(currentStep)}</div>
        </div>
        <SeparatorGy vertical />
        <div>
          <OrderHeader orderToBeAdded={orderToBeAdded} setOrderToBeAdded={setOrderToBeAdded} orderItemToBeAdded={orderItemToBeAdded} setOrderItemToBeAdded={setOrderItemToBeAdded} plant={plant} setPlant={setPlant} plants={plants} setPlants={setPlants} />
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <div>
          <PrimaryButton text="Finish" onClick={finish} disabled={!orderToBeAdded.id} />
        </div>
      </div>
    </>
  );
};

export default NewPlaceRetread;