import { Checkbox, ChoiceGroup, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IColumn, IconButton, IDropdownOption, MaskedTextField, MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { sortOrder } from '../../../../../consts/sortOrder';
import { customerInformationSelector, customerSelector } from '../../../../../redux/recordKeepingSlice';
import { IPaginationProps } from '../../../../Pagination/IPaginationProps';
import { IPostedMonthlyMilesProps } from './IPostedMonthlyMilesProps';
import { IPostedMonthlyMilesState } from './IPostedMonthlyMilesState';
import { useBoolean } from '@fluentui/react-hooks';
import apiService from '../../../../../api';
import styles from './PostedMonthlyMiles.module.scss';
import { locationColumns, postTypeOptions, columns, LOCATION } from './consts';
import { pageSizes } from '../../../../../consts/recordKeeping';
import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../Pagination/Pagination';
import classNames from 'classnames';
import AutocompleteInput from '../../../../../shared/AutocompleteInput';
import LoadingScreen from '../../../../LoadingScreen/LoadingScreen';
import SelectingModal from '../../../../SelectingModal/SelectingModal';
import MassUpload from '../../../../MassUpload/MassUpload';
import useNotifications from '../../../../../hooks/useNotifications';
import PrintingModal from '../../../../PrintingModal/PrintingModal';
import ErrorsModal from '../../../../ErrorsModal/ErrorsModal';
import { downloadFile, printingTypes } from '../../../../PrintingModal/consts';
import { usePrevious } from '../../../../../hooks/usePrevious';

const PostedMonthlyMiles: FC<IPostedMonthlyMilesProps> = ({ billingPeriodId, filters }): ReactElement => {

  const [state, setState] = useState<IPostedMonthlyMilesState>({
    loading: false,
    items: [],
    selectedItems: [],
    foundCount: 0,
  });

  const [changedItems, setChangedItem] = useState<Array<any>>([]);
  const [originalItems, setOriginalItem] = useState<Array<any>>([]);
  const [manualSave, setManualSave] = useState<boolean>(false);

  const [locationState, setLocationState] = useState<Array<any>>([]);
  const [showUpload, { toggle: toggleShowUpload }] = useBoolean(false);

  const [paginationProps, setPaginationProps] = useState<any>({
    total: 0,
    current: 1,
  });
  const [totalMiles, setTotalMiles] = useState<any>({ actual: 0, total: 0 });

  const { addNotification } = useNotifications();
  const { id: customerId, customerName } = useSelector(customerSelector);
  const { billBy, warnIfMonthlyUnitsAreMoreThan, units } = useSelector(customerInformationSelector);

  const [postType, setPostType] = useState<string>(postTypeOptions[0].key);
  const [location, setLocation] = useState<any>(null);
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [showLocationModal, { toggle: toggleShowLocationModal }] = useBoolean(false);
  const [isNotReported, { toggle: toggleIsNotReported }] = useBoolean(false);
  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [showErrorsModal, { toggle: toggleShowErrorsModal }] = useBoolean(false);
  const prevAmount = usePrevious({ billingPeriodId });

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(
      ({ isSorted }) => isSorted,
    );
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const setTireToBeEdited = (id: string, field: string, value: any, regExp?: RegExp) => {

    const findItem = state.items.findIndex(item => item.id === id);
    onFieldChange(state.items[findItem], field, value, findItem);

    if (!regExp || (regExp && regExp.test(value))) {
      setTotalMiles((prev: any) => ({ ...prev, total: Number.parseFloat(`${totalMiles.total - state.items.find(item => item.id == id).miles + +value}`).toFixed(2) }));
      setState((prev: any) => ({
        ...prev,
        items: prev.items.map((item: any) => item.id === id ? { ...item, [field]: value } : item),
      }));
    }
    if (!!warnIfMonthlyUnitsAreMoreThan && (+units < +value)) {
      addNotification({
        text: `Monthly units are more then ${units}. Actual is ${value}`,
        type: MessageBarType.warning,
      });
    }
  };


  const decimalChangeHandler = (id: string, field: string, value: any, digits: number, decimals: number) => {
    autoSaveMonthlyMiles();
    if (!value || new RegExp(`^\\d{1,${digits}}(?:[.]\\d{0,${decimals}})?$`).test(value))
      setTireToBeEdited(id, field, value, new RegExp(`^\\d{1,${digits}}(?:[.]\\d{0,${decimals}})?$`));
  };

  const transformDecimalValue = (id: string, field: string, value: string) => {
    if (/^\d+.?$/.test(value))
      setTireToBeEdited(id, field, Number.parseFloat(value).toFixed(2), /^\d+.\d+$/);
  };

  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const fetchLocations = async () => {
    try {
      const { data }: any = await apiService.getLocationsByCustomerId(
        customerId,
        { pageSize: 5000, pageNumber: 1 },
        {},
      );
      setLocationState(data.data);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Location fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const fetchTotalMiles = async () => {
    try {
      const { data } = await apiService.postMonthlyMiles.getPostMonthlyMilesTotal(
        billingPeriodId,
        location,
        customerId,
      );
      setTotalMiles((prev: any) => ({ ...prev, total: data.total }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Monthly Miles total fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const fetchMonthlyMiles = async () => {
    try {
      const sortOrder = getSortOrder();
      const { data } = await apiService.postMonthlyMiles.getMonthlyMiles(
        { billingPeriodId, isNotReported },
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        location,
        customerId,
      );
      const foundCount = data.total.found;
      const items = data?.data;
      setOriginalItem(items);
      setState((prev: any) => ({
        ...prev,
        items,
        foundCount,
        selectedItems: [],
      }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Monthly Miles fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const saveMonthlyMiles = async (hasPageChanged?: boolean) => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      await apiService.postMonthlyMiles.save(
        changedItems,
        customerId,
        location,
      );
      addNotification({
        text: 'Monthly Miles saved.',
        type: MessageBarType.success,
      });
      setManualSave(false);
      setChangedItem([]);
      if (!hasPageChanged) mountingProcessForData();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Monthly Miles save error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const submitMonthlyMiles = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      await apiService.postMonthlyMiles.submit(
        [],
        billingPeriodId,
        customerId,
        location,
      );
      addNotification({
        text: 'Monthly Miles submited.',
        type: MessageBarType.success,
      });
      mountingProcessForData();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Monthly Miles save error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.postMonthlyMiles.delete(state.selectedItems, customerId, location);
      addNotification({
        text: 'Selected tire(s) were successfully deleted.',
        type: MessageBarType.success,
      });
      if (paginationProps.current === 1) {
        await fetchMonthlyMiles();
        await fetchTotalMiles();
      } else {
        await onChangePage(1);
      }
      setState((prev) => ({ ...prev, loading: false }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Monthly Miles deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        filters: { billingPeriodId, isNotReported },
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId: location,
      };
      const headerFields = [
        { title: 'Customer Name', value: customerName },
        { title: 'Location Code', value: billBy == 'Location' ? locationState.filter(loc => location == loc.id)[0]?.locationCode : '' },
      ];
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.postMonthlyMiles.printExcel(requestData, headerFields) :
        await apiService.postMonthlyMiles.printPdf(requestData, headerFields);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSelect = (e: any, itemId: any) => {
    if (e.target.checked) {
      setState((prev: any) => ({ ...prev, selectedItems: [...state.selectedItems, itemId] }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: state.selectedItems.filter(row => row !== itemId) }));
    }
  };

  const handleSelectAll = (e: any, items: Array<any>) => {
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedItems: items })) : setState((prev: any) => ({ ...prev, selectedItems: [] }));
  };

  const setNewLocation = (location: any) => {
    setLocation(() => (location));
    toggleShowLocationModal();
  };

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const onLocationClear = (text: string) => {
    if (!text) {
      setLocation(null);
    }
  };

  const mountingProcess = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    await fetchLocations();
    await apiService.postMonthlyMiles.getPostMonthlyMilesByVehicle(
      billingPeriodId,
      location,
      customerId,
    );
    await fetchMonthlyMiles();
    await fetchTotalMiles();
    setState((prev) => ({ ...prev, loading: false }));
  };

  const mountingProcessForData = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    await fetchMonthlyMiles();
    await fetchTotalMiles();
    setState((prev) => ({ ...prev, loading: false }));
  };


  const onFieldChange = (item: any, field: string, value: string, itemIndex: number) => {
    const tempArray = [];
    const newItem = {
      ...item,
      [field]: value,
    };
    tempArray.push(newItem);
    const newItemMilesType = typeof newItem.miles;
    const hasNoChange = newItem.miles == originalItems[itemIndex]?.miles ||
      newItem.miles == originalItems[itemIndex]?.miles.toFixed(2) ||
      newItemMilesType === 'string' &&
      parseInt(newItem.miles)?.toFixed(2) == originalItems[itemIndex]?.miles.toFixed(2);

    if (!hasNoChange) {
      if (changedItems?.length) {
        const tempChangedItems = changedItems;
        const changedItemIndex = tempChangedItems.findIndex(cItem => cItem.id === newItem.id);
        if (changedItemIndex > -1) {
          if (hasNoChange) {
            tempChangedItems.splice(changedItemIndex, 1);
            setChangedItem(tempChangedItems);
          } else {
            tempChangedItems[changedItemIndex] = {
              ...newItem,
            };
            setChangedItem(tempChangedItems);
          }
        } else {
          tempChangedItems.push(newItem);
          setChangedItem(tempChangedItems);
        }
      } else {
        setChangedItem([...tempArray, ...changedItems]);
      }
    } else {
      if (changedItems?.length) {
        const tempChangedItems = changedItems;
        const changedItemIndex = tempChangedItems.findIndex(cItem => cItem.id === newItem.id);
        if (changedItemIndex > -1) {
          tempChangedItems.splice(changedItemIndex, 1);
          setChangedItem(tempChangedItems);
        }
      }
    }

  };


  const onChangePage = (newPage: number) => {
    if (!manualSave && changedItems?.length) {
      saveMonthlyMiles(true);
    }
    setPaginationProps((prev: any) => ({ ...prev, current: newPage }));
  };

  const debounce = (fn: any, timeout = 5000) => {
    const timer: any = useRef();
    useEffect(() => {
      if (!manualSave) clearTimeout(timer.current);
    }, [manualSave, paginationProps?.current]);
    return useCallback(
      (...args: any) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => { fn.apply(this, args); }, timeout);
      }, [fn, timeout],
    );
  };

  const debounceAutoSave = () => {
    if (!manualSave && changedItems?.length) {
      saveMonthlyMiles();
    }
  };

  const autoSaveMonthlyMiles = debounce(() => debounceAutoSave());

  useEffect(() => {
    if (prevAmount?.billingPeriodId !== billingPeriodId) {
      mountingProcess();
    } else {
      mountingProcessForData();
    }
  }, [billingPeriodId, postType, location, paginationProps.current, columnsState, countOnPage, isNotReported]);

  return (
    <>
      <div className={styles.modalContainer}>
        <div className={styles.modalBody}>
          <div>
            <div>
              <div className={styles.totalInfo}>
                <div>
                  <Checkbox
                    className={classNames(styles.baselineItems, styles.paddedElement)}
                    checked={billBy == 'Location'}
                    disabled
                  />
                  <Text variant="large" className={styles.highlight}>Mileage by location&nbsp;</Text>
                </div>
              </div>
              <div className={styles.location}>
                <div>
                  <div>
                    <AutocompleteInput
                      value={location}
                      list={locationState.map(((location: any) => ({ key: location.id, text: location.locationName })))}
                      chooseCurrentItem={setLocation}
                      emptyExpanded
                      disabled={billBy !== LOCATION}
                      textValue={onLocationClear}
                    />
                    <IconButton
                      iconProps={{ iconName: 'Search' }}
                      onClick={toggleShowLocationModal}
                      disabled={billBy !== LOCATION}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.postTypeOptions}>
              <Text variant="large" className={styles.highlight}>Post Type</Text>
              <div>
                <ChoiceGroup defaultSelectedKey={postType} options={postTypeOptions} onChange={(e, item: any) => setPostType(item.key)} />
                {postType == 'fromFile' && <DefaultButton text="Upload" onClick={toggleShowUpload} disabled={billBy == 'Location' && !location} />}
              </div>
            </div>
            <div className={styles.totalInfo}>
              <div>
                <div>
                  <Text variant="medium" className={styles.highlight}>User Total</Text>
                  <TextField
                    id="userTotal" value={totalMiles.actual} onChange={(e, actual) => setTotalMiles((prev: any) => ({ ...prev, actual }))}
                  />
                </div>
                <div>
                  <Text variant="medium" className={styles.highlight}>System Total</Text>
                  <TextField
                    id="systemTotal" value={totalMiles.total} disabled
                  />
                </div>
              </div>
            </div>
            <div className={styles.billingPeriod}>
              <Text variant="large" className={styles.highlight}>Select Billing Period</Text>
              <div>
                <div>
                  <Text variant="medium" className={styles.highlight}>From</Text>
                  <MaskedTextField
                    value={filters.from}
                    mask="99/99/9999"
                    disabled
                  />
                </div>
                <div>
                  <Text variant="medium" className={styles.highlight}>To</Text>
                  <MaskedTextField
                    value={filters.to}
                    mask="99/99/9999"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className={styles.totalInfo}>
              <div>
                <Checkbox
                  className={classNames(styles.baselineItems, styles.paddedElement)}
                  name='vehicleMilesOptions.postAgainstMonthlys'
                  checked={isNotReported}
                  onChange={toggleIsNotReported}
                />
                <Text variant="large" className={styles.highlight}>View vehicles not reported&nbsp;</Text>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.tableHeading}>
              <div>
                <Text variant="xLarge" className={styles.highlight}>Posted Monthly Miles</Text>
                <SeparatorGy vertical />
                <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
              </div>
              <div>
                <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                <Dropdown
                  options={pageSizes.map(pageSize => ({
                    key: pageSize,
                    text: pageSize.toString(),
                  }))}
                  defaultSelectedKey={pageSizes[0]}
                  selectedKey={countOnPage?.key}
                  onChange={onChangeCountOnPage}
                />
                <SeparatorGy vertical />
                <Text variant="large" className={styles.highlight}>{state.selectedItems.length} items selected</Text>
                <SeparatorGy vertical />
                <IconButton
                  id="toggleDeletingConfirmationButton"
                  disabled={!state.selectedItems.length}
                  iconProps={{ iconName: 'Delete' }}
                  onClick={toggleDeletingConfirmation}
                />
              </div>
            </div>
            <div className={styles['table-wrapper']}>
              <table>
                <thead>
                  <tr>
                    {
                      columnsState.map(item => (
                        <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                      ))
                    }
                    <th>
                      <div className={styles.round}>
                        <input
                          type="checkbox"
                          id="all"
                          checked={state.items.length !== 0 && (state.selectedItems.length === +countOnPage.key || state.selectedItems.length === state.items.length)}
                          onChange={(e) => handleSelectAll(e, state.items.map(({ id }) => id))}
                        />
                        <label htmlFor="all"></label>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    state.items.map(item => (
                      <tr key={item.id} className={styles.trBasic}>
                        <td>
                          <TextField
                            value={item.vNo}
                            disabled
                          />
                        </td>
                        <td>
                          <TextField
                            id="miles"
                            value={item.miles}
                            onChange={(ev, value) => decimalChangeHandler(item.id, 'miles', value, 20, 2)}
                            onBlur={() => transformDecimalValue(item.id, 'miles', item.miles)}
                          />
                        </td>
                        <td>{item.submit ? 'Y' : 'N'}</td>
                        <td>
                          <div className={styles.round}>
                            <input type="checkbox" id={item.id} checked={state.selectedItems.includes(item.id)} onChange={(e) => handleSelect(e, item.id)} />
                            <label htmlFor={item.id}></label>
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <SeparatorGy />
            <Pagination {...paginationProps} onChangePage={onChangePage} />
            <div className={classNames(styles.buttonsWrapper)}>
              <PrimaryButton
                id="saveButton"
                onClick={() => {
                  setManualSave(true);
                  saveMonthlyMiles();
                }}
                text="Save"
                disabled={(billBy == 'Location' && !location) || (changedItems?.length === 0)}
              />
              <PrimaryButton
                id="submitButton"
                onClick={submitMonthlyMiles}
                text="Submit"
                disabled={(billBy == 'Location' && !location) || (totalMiles.actual != totalMiles.total)}
              />
            </div>

          </div>
        </div>
        <div className={classNames(styles.buttonsWrapper)}>
          <DefaultButton
            id="printExportButton"
            onClick={toggleShowPrintExport}
            text="Print/Export"
          />
          <DefaultButton
            id="viewErrorsButton"
            onClick={toggleShowErrorsModal}
            text="View Errors"
          />
        </div>
      </div>
      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to delete ${state.selectedItems.length} items?`,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <MassUpload
        isModalOpen={showUpload}
        hideModal={toggleShowUpload}
        formType={'Monthly Mileage'}
        fetch={mountingProcessForData}
        period={billingPeriodId}
        location={location}
      />
      <SelectingModal
        isOpen={showLocationModal}
        title="Available Locations"
        selectingList={locationState.map((location: any) => ({ ...location }))}
        onDismiss={toggleShowLocationModal}
        onSubmit={setNewLocation}
        columns={locationColumns}
        preselectedKey={location}
      />
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      <ErrorsModal
        isModalOpen={showErrorsModal}
        hideModal={toggleShowErrorsModal}
        fetchErrorsService={apiService.postMonthlyMiles.getErrors}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default PostedMonthlyMiles;

