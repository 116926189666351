export const columns = [
  {
    key: 'column0',
    name: 'From Bill Period',
    fieldName: 'fromBillPeriod',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'To Bill Period',
    fieldName: 'toBillPeriod',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Start Mode',
    fieldName: 'startMode',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'Started On',
    fieldName: 'startedOn',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'Status',
    fieldName: 'status',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'Completed Status',
    fieldName: 'completedStatus',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column6',
    name: 'Completed On',
    fieldName: 'completedOn',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column7',
    name: 'Bill Type',
    fieldName: 'billType',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column8',
    name: 'Bill Done On',
    fieldName: 'billDoneOn',
    isSorted: false,
    isSortedDescending: false,
  },
];