import { FormEvent, useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import moment from 'moment';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IColumn,
  IconButton,
  IDropdownOption,
  MessageBarType,
  PrimaryButton,
  Text,
  TextField,
  Checkbox,
  DatePicker,
  MaskedTextField,
} from '@fluentui/react';
import { useSelector } from 'react-redux';
import apiService from '../../../../../../api';
import useNotifications from '../../../../../../hooks/useNotifications';
import { sortOrder } from '../../../../../../consts/sortOrder';
import { customerInformationSelector, customerSelector, locationSelector } from '../../../../../../redux/recordKeepingSlice';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../../Pagination/Pagination';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';
import { IPostByB73FormatState } from './IPostByB73FormatState';
import ErrorsModal from '../../../../../ErrorsModal/ErrorsModal';
import { IPostByB73FormatProps } from './IPostByB73FormatProps';
import styles from './PostByB73Format.module.scss';
import { emptyB73Format, columns } from './consts';
import { pageSizes } from '../../../../../../consts/recordKeeping';
import { IPostByB73Format } from './IPostByB73Format';
import { isNil } from 'lodash';
import MassUpload from '../../../../../MassUpload/MassUpload';
import { auth_tireChange_deleteButton, auth_tireChange_postByB73Format } from '../../../../../../consts/programKeys';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';

const PostByB73Format = (props: IPostByB73FormatProps) => {

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);
  const { billBy } = useSelector(customerInformationSelector);

  const [state, setState] = useState<IPostByB73FormatState>({
    items: [],
    selectedItems: [],
    loading: false,
    foundCount: 0,
  });

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [showErrorsModal, { toggle: toggleShowErrorsModal }] = useBoolean(false);
  const [useDefaults, { toggle: toggleUseDefaults }] = useBoolean(false);
  const [defaults, setDefaults] = useState<any>({ pfx: '', date: null });
  const [tireToBeAdded, setTireToBeAdded] = useState<IPostByB73Format>(emptyB73Format);
  const [parsedErrors, setParsedErrors] = useState<any>([]);
  const [editedRowId, setEditedRowId] = useState<number>(-1);
  const [showUpload, { toggle: toggleShowUpload }] = useBoolean(false);
  const [postTableId, setPostTableId] = useState<any>(null);

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_tireChange_postByB73Format);
  const deletePermissions = hasPermission(auth_tireChange_deleteButton);

  const updateAddValues = (defaultValues: any, field: string, value: string | boolean | undefined) => {
    defaultValues[field] = value;
    return defaultValues;
  };

  const onChangeDefaultsPrefix = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, pfx: string | undefined) => {
    setDefaults((prev: any) => ({ ...prev, pfx }));
  };

  const updateRowsData = (currentState: any, field: string, value: string | undefined) => {
    return currentState.map((item: { [x: string]: string | undefined; id: any; }) => {
      if (editedRowId === item.id) {
        item[field] = value;
      }
      return item;
    });
  };

  const setChgDateTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, chgDate: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'chgDate', chgDate) }));
  };
  const setVNoTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, vNo: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'vNo', vNo) }));
  };
  const setWPosTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, wPos: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'wPos', wPos) }));
  };
  const setOffPrefixTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, offPrefix: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'offPrefix', offPrefix?.toUpperCase()) }));
  };
  const setOffBrandNumberTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, offBrandNumber: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'offBrandNumber', offBrandNumber?.toUpperCase()) }));
  };
  const setOffSuffixTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, offSuffix: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'offSuffix', offSuffix?.toUpperCase()) }));
  };
  const setOffCotTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, offCot: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'offCot', offCot?.toUpperCase()) }));
  };
  
  const setOnPrefixTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, onPrefix: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'onPrefix', onPrefix?.toUpperCase()) }));
  };
  const setOnBrandNumberTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, onBrandNumber: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'onBrandNumber', onBrandNumber?.toUpperCase()) }));
  };
  const setOnSuffixTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, onSuffix: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'onSuffix', onSuffix?.toUpperCase()) }));
  };
  const setOnCotTireToBeEdited = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, onCot: string | undefined) => {
    setState(prev => ({ ...prev, items: updateRowsData([...state.items], 'onCot', onCot?.toUpperCase()) }));
  };

  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const onEdit = (itemId: any) => {
    setEditedRowId(itemId);
    if (useDefaults) {
      const newItems = [...state.items].map(item => {
        if (item.id == itemId) {
          if (!item.submit) {
            item.onPrefix = defaults.pfx;
            item.offPrefix = defaults.pfx;
          }
          if (!isNil(defaults.date)) {
            item.chgDate = moment(defaults.date).format('MM/DD/YYYY');
          }
        }
        return item;
      });
      setState(prev => ({ ...prev, items: newItems }));
    }
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const renderLargeLabel = (props: any) => (
    <Text variant="large" className={styles.highlight}>{props.label}</Text>
  );

  const handleSave = async () => {
    const currentItems = [...state.items];
    currentItems.map((item: { onCot: string | boolean; offCot: string | boolean; }) => {
      if (item.offCot === 'Y') {
        item.offCot = true;
      }
      if (item.offCot === 'N') {
        item.offCot = false;
      }
      if (item.onCot === 'Y') {
        item.onCot = true;
      }
      if (item.onCot === 'N') {
        item.onCot = false;
      }
      return item;
    });
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.b37Format.save([...state.items], customerId, locationId);
      addNotification({
        text: 'Tires were successfully updated.',
        type: MessageBarType.success,
      });
      await paginationProps.onChangePage(1);
      setParsedErrors([]);
    } catch (e: any) {
      const { response } = e;
      const locationId = response.data.state.filter((error: any) => error.field == 'locationId');
      switch (response.status) {
        case 400:
          setParsedErrors(response.data.state);
          if (locationId.length) {
            return addNotification({
              text: 'Location must not be empty',
              type: MessageBarType.error,
            });
          }
          return addNotification({
            text: 'Unable to save changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Saving error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      await fetchB37Format();
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
      };
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.b37Format.printExcel(requestData) :
        await apiService.b37Format.printPdf(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSubmit = async () => {
    const currentItems = [...state.items];
    currentItems.map((item: { onCot: string | boolean; offCot: string | boolean; }) => {
      if (item.offCot === 'Y') {
        item.offCot = true;
      }
      if (item.offCot === 'N') {
        item.offCot = false;
      }
      if (item.onCot === 'Y') {
        item.onCot = true;
      }
      if (item.onCot === 'N') {
        item.onCot = false;
      }
      return item;
    });
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.b37Format.submit(state.selectedItems.map(id => state.items.filter(item => item.id === id)[0]), customerId, locationId);
      addNotification({
        text: 'Tires were successfully submitted.',
        type: MessageBarType.success,
      });
      await paginationProps.onChangePage(1);
      setParsedErrors([]);
    } catch (e: any) {
      const { response } = e;
      setState(prev => ({ ...prev, loading: false }));
      const locationId = response.data.state.filter((error: any) => error.field == 'locationId');
      setParsedErrors(response.data.state);
      switch (response.status) {
        case 400:
          if (locationId.length) {
            return addNotification({
              text: 'Location must not be empty',
              type: MessageBarType.error,
            });
          }
          return addNotification({
            text: 'Unable to save changes.',
            type: MessageBarType.error,
          });
        case 499:
          if (response.data.state) {
            return addNotification({
              text: `Submitting error: ${response.data.state[0].message}`,
              type: MessageBarType.error,
            });
          }
          return addNotification({
            text: 'Unable to save changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Submitting error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      await fetchB37Format();
    }
  };

  const fetchB37Format = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.b37Format.get(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
      );
      const foundCount = data.total.found;
      const items = data.data.map((item: { chgDate: string; onCot: string; offCot: string; }) => {
        item.chgDate = moment(item.chgDate).format('MM/DD/YYYY');
        item.onCot = item.onCot ? 'Y' : 'N';
        item.offCot = item.offCot ? 'Y' : 'N';
        return item;
      });
      if (postTableId) {
        setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [items.find((item: any) => item.id == postTableId)?.id] }));
        setPostTableId(null);
      } else {
        setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      }
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      addNotification({
        text: `B73 fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.b37Format.delete(state.selectedItems);
      addNotification({
        text: 'Selected tire(s) were successfully deleted.',
        type: MessageBarType.success,
      });
      if (paginationProps.current === 1) {
        await fetchB37Format();
      } else {
        await paginationProps.onChangePage(1);
      }
    } catch (e: any) {
      const { response } = e;
      setState(prev => ({ ...prev, loading: false }));
      addNotification({
        text: `B37 Format deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const handleAddTire: any = async (ev: any) => {
    ev.preventDefault();
    const tireForAdding = { ...tireToBeAdded };
    tireForAdding.chgDate = tireToBeAdded.chgDate;
    if (tireForAdding.offCot === 'Y') {
      tireForAdding.offCot = true;
    }
    if (tireForAdding.offCot === 'N') {
      tireForAdding.offCot = false;
    }
    if (tireForAdding.onCot === 'Y') {
      tireForAdding.onCot = true;
    }
    if (tireForAdding.onCot === 'N') {
      tireForAdding.onCot = false;
    }
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.b37Format.save([{ ...tireForAdding }], customerId, locationId);
      addNotification({
        text: 'Tire was successfully added.',
        type: MessageBarType.success,
      });
      if (useDefaults) {
        setTireToBeAdded({...emptyB73Format, chgDate: moment(defaults.date).format('MM/DD/YYYY'), offPrefix: defaults.pfx, onPrefix: defaults.pfx});
      } else {
        setTireToBeAdded(emptyB73Format);
      }
      setParsedErrors([]);
    } catch (e: any) {
      const { response } = e;
      const locationError = response.data.state.filter((error: any) => error.field === 'locationId');
      switch (response.status) {
        case 400:
          if (locationError.length) {
            return addNotification({
              text: 'Unable to save changes. Location can not be empty',
              type: MessageBarType.error,
            });
          } else {
            setParsedErrors(response.data.state);
            return addNotification({
              text: 'Unable to save changes.',
              type: MessageBarType.error,
            });
          }
        default:
          addNotification({
            text: `Saving error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      await fetchB37Format();
      document.getElementById('chgDate')?.focus();
    }
  };

  const handleSelect = (e: any, itemId: any) => {
    const selectedRows = [...state.selectedItems];
    if (e.target.checked) {
      selectedRows.push(itemId);
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows.filter(row => row !== itemId) }));
    }
  };

  const handleSelectAll = (e: any) => {
    const filteredArray = state.items.filter(item => !item.submit);
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedItems: filteredArray.map(item => item.id) })) : setState((prev: any) => ({ ...prev, selectedItems: [] }));
  };

  const parseErrors = (id: string | null, field: string) => {
    const customError = parsedErrors?.filter((error: { id: string; field: string; }) => error.id == id && error.field == field)[0];
    if (customError) {
      return customError.message;
    }
  };

  const highlightRowIfError = (item: any) => {
    if (parsedErrors.length > 0) {
      return parsedErrors.filter((error: { index: any; }) => error.index == item.id).length > 0;
    }
  };

  const searchRecordWithError = async (postTableId: any, response?: boolean) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.b37Format.getPage(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
        postTableId,
      );
      setPostTableId(postTableId);
      if (data.pageNumber != paginationProps.current) {
        await paginationProps.onChangePage(data.pageNumber);
      } else {
        setState((prev: any) => ({ ...prev, selectedItems: [state.items.find((item: any) => item.id == postTableId)?.id] }));
        setState((prev) => ({ ...prev, items: prev.items.map(el => el.id === postTableId ? ({ ...el, response}) : el) }));
        setPostTableId(null);
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `B73 fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    fetchB37Format();
  }, [paginationProps.current, countOnPage, columnsState, customerId, locationId]);

  useEffect(() => {
    if(useDefaults) {
      setTireToBeAdded((prev: any) => ({ ...prev, chgDate: moment(defaults.date).format('MM/DD/YYYY'), offPrefix: defaults.pfx, onPrefix: defaults.pfx }));
    } else {
      setTireToBeAdded((prev: any) => ({ ...prev, chgDate: '', offPrefix: '', onPrefix: '' }));
    }
  }, [useDefaults]);

  return (
    <>
      <div className={styles.table}>
        <div className={styles.tableHeading}>
          <div>
            <Text variant="xLarge" className={styles.highlight}>Posted Tire Changes</Text>
            <SeparatorGy vertical />
            <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
          </div>
        </div>
        <div className={styles.tableHeading}>
          <form className={styles.tiresAddingBlock} onSubmit={handleAddTire}>
            <div className="ms-TextField">
              <label>ChgDate</label>
              <MaskedTextField
                id="chgDate"
                value={tireToBeAdded.chgDate}
                mask="99/99/9999"
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'chgDate', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'chgDate')}
              />
            </div>
            <div className="ms-TextField">
              <label>VNo</label>
              <TextField
                value={'' + tireToBeAdded.vNo}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'vNo', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'vNo')}
              />
            </div>
            <div className="ms-TextField">
              <label>WPos</label>
              <TextField
                value={tireToBeAdded.wPos}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'wPos', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'wPos')}
              />
            </div>
            <div className="ms-TextField">
              <label>OffPrefix</label>
              <TextField
                value={tireToBeAdded.offPrefix}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'offPrefix', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'offPrefix')}
              />
            </div>
            <div className="ms-TextField">
              <label>OffBrandNumber</label>
              <TextField
                value={'' + tireToBeAdded.offBrandNumber}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'offBrandNumber', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'offBrandNumber')}
              />
            </div>
            <div className="ms-TextField">
              <label>OffSuffix</label>
              <TextField
                value={tireToBeAdded.offSuffix}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'offSuffix', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'offSuffix')}
              />
            </div>
            <div className="ms-TextField">
              <label>OffCOT</label>
              <TextField
                value={'' + tireToBeAdded.offCot}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'offCot', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'offCot')}
              />
            </div>
            <div className="ms-TextField">
              <label>OnPrefix</label>
              <TextField
                value={tireToBeAdded.onPrefix}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'onPrefix', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'onPrefix')}
              />
            </div>
            <div className="ms-TextField">
              <label>OnBrandNumber</label>
              <TextField
                value={'' + tireToBeAdded.onBrandNumber}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'onBrandNumber', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'onBrandNumber')}
              />
            </div>
            <div className="ms-TextField">
              <label>OnSuffix</label>
              <TextField
                value={tireToBeAdded.onSuffix}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'onSuffix', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'onSuffix')}
              />
            </div>
            <div className="ms-TextField">
              <label>OnCOT</label>
              <TextField
                value={'' + tireToBeAdded.onCot}
                onChange={(e, value) => setTireToBeAdded(updateAddValues({ ...tireToBeAdded }, 'onCot', value?.toUpperCase()))}
                errorMessage={parseErrors('null', 'onCot')}
              />
            </div>
            <PrimaryButton
              // disabled={!tireToBeAdded.pfx || !tireToBeAdded.bno || !tireToBeAdded.newPfx || !tireToBeAdded.newBrand}
              disabled={!userPermissions.isWrite}
              id="addButton"
              text="Add"
              type='submit'
            />
          </form>
        </div>
        <div className={styles.tableHeading}>
          <div className={styles.useDefaults}>
            <Checkbox id="useDefaults" label="Use Defaults:" onRenderLabel={renderLargeLabel} checked={useDefaults} onChange={toggleUseDefaults} />
            <div>
              <TextField id="prefixDefault" label="Ctrl Pfx" value={defaults.pfx} onChange={onChangeDefaultsPrefix} />
              <DatePicker
                id="dateDefault"
                showMonthPickerAsOverlay={true}
                label="MM/DD/YY"
                value={defaults.date}
                onSelectDate={(date: Date | null | undefined) => setDefaults((prev: any) => ({ ...prev, date }))}
                formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
              />
            </div>
          </div>
          <div>
            <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
            <Dropdown
              options={pageSizes.map(pageSize => ({
                key: pageSize,
                text: pageSize.toString(),
              }))}
              defaultSelectedKey={pageSizes[0]}
              selectedKey={countOnPage?.key}
              onChange={onChangeCountOnPage}
            />
            <SeparatorGy vertical />
            <Text variant="large" className={styles.highlight}>{state.selectedItems.length} items selected</Text>
            <SeparatorGy vertical />
            <IconButton
              id="toggleDeletingConfirmationButton"
              disabled={!(state.selectedItems.length && userPermissions.isWrite && deletePermissions.isWrite)}
              iconProps={{ iconName: 'Delete' }}
              onClick={toggleDeletingConfirmation}
            />
          </div>
        </div>
        <div className={styles['table-wrapper']}>
          <table>
            <thead>
              <tr>
                {
                  columnsState.map(item => (
                    <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                  ))
                }
                <th>
                  {!!state.items.filter(item => !item.submit).length &&
                    <div className={styles.round}>
                      <input type="checkbox" id="all" checked={state.selectedItems.length === state.items.filter(item => !item.submit).length} onChange={(e) => handleSelectAll(e)} />
                      <label htmlFor="all"></label>
                    </div>
                  }
                </th>
              </tr>
            </thead>
            <tbody>
              {
                state.items.map(item => (
                  item.submit ?
                    <tr
                      key={item.id}
                      className={classNames(state.selectedItems.includes(item.id) ? styles.trSelected : styles.trBasic,
                        highlightRowIfError(item) ? styles.trError : styles.trBasic)}
                      onFocus={() => onEdit(item.id)}
                    >
                      <td>{item.chgDate && moment(item.chgDate).format('MM/DD/YYYY')}</td>
                      <td>{item.vNo}</td>
                      <td>{item.wPos}</td>
                      <td>{item.offPrefix}</td>
                      <td>{item.offBrandNumber}</td>
                      <td>{item.offSuffix}</td>
                      <td>{item.offCot}</td>
                      <td>{item.onPrefix}</td>
                      <td>{item.onBrandNumber}</td>
                      <td>{item.onSuffix}</td>
                      <td>{item.onCot}</td>
                      <td>{item.submit ? 'Y' : 'N'}</td>
                      <td>
                        {!item.submit &&
                          <div className={styles.round}>
                            <input type="checkbox" id={item.id} checked={state.selectedItems.includes(item.id)} onChange={(e) => handleSelect(e, item.id)} />
                            <label htmlFor={item.id}></label>
                          </div>
                        }
                      </td>
                    </tr> :
                    item.id.length <= 9 ? 
                      <tr
                        key={item.id}
                        className={classNames(state.selectedItems.includes(item.id) ? styles.trSelected : styles.trBasic,
                          highlightRowIfError(item) ? styles.trError : styles.trBasic)}
                        onFocus={() => onEdit(item.id)}
                      > 
                        <td>{item.chgDate && moment(item.chgDate).format('MM/DD/YYYY')}</td>
                        <td>{item.vNo}</td>
                        <td>{item.wPos}</td>
                        <td>{item.offPrefix}</td>
                        <td>{item.offBrandNumber}</td>
                        <td>{item.offSuffix}</td>
                        <td>{item.offCot}</td> 
                        <td>
                          <TextField
                            id="onPrefix"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.onPrefix}
                            onChange={setOnPrefixTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'onPrefix')}
                          />
                        </td>
                        <td>
                          <TextField
                            id="onBrandNumber"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.onBrandNumber}
                            onChange={setOnBrandNumberTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'onBrandNumber')}
                          />
                        </td>
                        <td>
                          <TextField
                            id="onSuffix"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.onSuffix}
                            onChange={setOnSuffixTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'onSuffix')}
                          />
                        </td>
                        <td>
                          <TextField
                            id="onCot"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.onCot}
                            onChange={setOnCotTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'onCot')}
                          />
                        </td>
                        <td>{item.submit ? 'Y' : 'N'}</td>
                        <td>
                          {!item.submit &&
                            <div className={styles.round}>
                              <input type="checkbox" id={item.id} checked={state.selectedItems.includes(item.id)} onChange={(e) => handleSelect(e, item.id)} />
                              <label htmlFor={item.id}></label>
                            </div>
                          }
                        </td>
                      </tr> :
                      <tr
                        key={item.id}
                        className={classNames(state.selectedItems.includes(item.id) ? styles.trSelected : styles.trBasic,
                          highlightRowIfError(item) ? styles.trError : styles.trBasic)}
                        onFocus={() => onEdit(item.id)}
                      >
                        <td>
                          <TextField
                            id="chgDate"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.chgDate}
                            onChange={setChgDateTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'chgDate')}
                          />
                        </td>
                        <td>
                          <TextField
                            id="vNo"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.vNo}
                            onChange={setVNoTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'vNo')}
                          />
                        </td>
                        <td>
                          <TextField
                            id="wPos"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.wPos}
                            onChange={setWPosTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'wPos')}
                          />
                        </td>
                        <td>
                          <TextField
                            id="offPrefix"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.offPrefix}
                            onChange={setOffPrefixTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'offPrefix')}
                          />
                        </td>
                        <td>
                          <TextField
                            id="offBrandNumber"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.offBrandNumber}
                            onChange={setOffBrandNumberTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'offBrandNumber')}
                          />
                        </td>
                        <td>
                          <TextField
                            id="offSuffix"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.offSuffix}
                            onChange={setOffSuffixTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'offSuffix')}
                          />
                        </td>
                        <td>
                          <TextField
                            id="offCot"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.offCot}
                            onChange={setOffCotTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'offCot')}
                          />
                        </td> 
                        <td>
                          <TextField
                            id="onPrefix"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.onPrefix}
                            onChange={setOnPrefixTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'onPrefix')}
                          />
                        </td>
                        <td>
                          <TextField
                            id="onBrandNumber"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.onBrandNumber}
                            onChange={setOnBrandNumberTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'onBrandNumber')}
                          />
                        </td>
                        <td>
                          <TextField
                            id="onSuffix"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.onSuffix}
                            onChange={setOnSuffixTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'onSuffix')}
                          />
                        </td>
                        <td>
                          <TextField
                            id="onCot"
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.onCot}
                            onChange={setOnCotTireToBeEdited}
                            errorMessage={parseErrors(item.id, 'onCot')}
                          />
                        </td>
                        <td>{item.submit ? 'Y' : 'N'}</td>
                        <td>
                          {!item.submit &&
                            <div className={styles.round}>
                              <input type="checkbox" id={item.id} checked={state.selectedItems.includes(item.id)} onChange={(e) => handleSelect(e, item.id)} />
                              <label htmlFor={item.id}></label>
                            </div>
                          }
                        </td>
                      </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <SeparatorGy />
        <Pagination {...paginationProps} />
      </div>
      <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
        <DefaultButton
          id="uploadButton"
          onClick={toggleShowUpload}
          disabled={(billBy == 'Location' && !locationId) || !userPermissions.isWrite}
          text="Upload"
        />
        <DefaultButton id="printButton" onClick={toggleShowPrintExport} text="Print/Export" />
        <DefaultButton onClick={toggleShowErrorsModal} text="View Errors" />
        <PrimaryButton id="saveButton" disabled={!userPermissions.isWrite} onClick={handleSave} text="Save" />
        <PrimaryButton id="submitButton" onClick={handleSubmit} text="Submit" disabled={state.selectedItems.length === 0 || !userPermissions.isWrite} />
      </div>
      <MassUpload
        isModalOpen={showUpload}
        hideModal={toggleShowUpload}
        formType={'Tire Change'}
        fetch={fetchB37Format}
        location={locationId}
      />
      <ErrorsModal
        isModalOpen={showErrorsModal}
        hideModal={toggleShowErrorsModal}
        fetchErrorsService={apiService.b37Format.getErrors}
        searchError={searchRecordWithError}
        shouldConfirm={true}
      />
      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to delete ${state.selectedItems.length} items?`,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default PostByB73Format;
