import { FC, ReactElement, useState } from 'react';
import { IViewMileageRunReportProps } from './IViewMileageRunReportProps';
import { Pivot, PivotItem } from '@fluentui/react';
import { viewMileageRunReportTabs } from './consts';
import SeparatorGy from '../../../../../../SeparatorGy/SeparatorGy';
import TireMileage from './tabComponents/tireMileage/TireMileage';
import VehicleMileage from './tabComponents/vehicleMileage/VehicleMileage';

const ViewMileageRunReport: FC<IViewMileageRunReportProps> = ({ 
  mileageRunItem, 
  setViewReportTirePaginationProps,
  setReportTireSortOrder,
  setReportTireSortOrderCountOnPage,
  setViewReportVehiclePaginationProps,
  setReportVehicleSortOrder,
  setReportVehicleSortOrderCountOnPage,
}): ReactElement => {

  const [selectedKey, setSelectedKey] = useState('rectangleRed');

  const handleLinkClick = (item?: PivotItem) => {
    item && setSelectedKey(item.props.itemKey!);
  };
  
  const renderTabAccordingKey = (tabKey: string) => {
    switch (tabKey) {
      case viewMileageRunReportTabs.tireMileage:
        return <TireMileage 
          billPeriodId={mileageRunItem.billPeriodId} 
          setViewReportTirePaginationProps={setViewReportTirePaginationProps}
          setReportTireSortOrder={setReportTireSortOrder}
          setReportTireSortOrderCountOnPage={setReportTireSortOrderCountOnPage}
        />;
      case viewMileageRunReportTabs.vehicleMileage:
        return <VehicleMileage 
          billPeriodId={mileageRunItem.billPeriodId} 
          setViewReportVehiclePaginationProps={setViewReportVehiclePaginationProps}
          setReportVehicleSortOrder={setReportVehicleSortOrder}
          setReportVehicleSortOrderCountOnPage={setReportVehicleSortOrderCountOnPage}
        />;
    }
  };
  
  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  return (
    <Pivot
      aria-label="Separately Rendered Content Pivot Example"
      selectedKey={selectedKey}
      onLinkClick={handleLinkClick}
      getTabId={getTabId}
      linkFormat="tabs"
      linkSize="large"
    >
      {Object.values(viewMileageRunReportTabs).map((value) => (
        <PivotItem headerText={value} itemKey={value}>
          <SeparatorGy />
          {renderTabAccordingKey(value)}
        </PivotItem>
      ))}
    </Pivot>
  );
};

export default ViewMileageRunReport;