import { DatePicker, DefaultButton, Dropdown, MessageBarType, PrimaryButton, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import apiService from '../../../../../api';
import useNotifications from '../../../../../hooks/useNotifications';
import DataGridComponent from '../../../../../shared/DataGridComponent';
import { FormBuilderGroup } from '../../../../../shared/FormBuilderComponent';
import LoadingScreen from '../../../../LoadingScreen/LoadingScreen';
import { downloadFile, printingTypes } from '../../../../PrintingModal/consts';
import PrintingModal from '../../../../PrintingModal/PrintingModal';
import { billingOptionsHeadCells, BILLING_OPTIONS_FIELDS, booleanOptions, miscOptionsHeadCells, MISC_OPTIONS_FIELDS, reportOptionsHeadCells, REPORT_OPTIONS_FIELDS } from './consts';
import { IMiscState } from './IMiscState';
import styles from './Misc.module.scss';

export const Misc: React.FC = () => {
  const { contractId, id } = useParams<{ contractId: string, id: string }>();
  const { addNotification } = useNotifications();

  const defaultTotal = { all: 0, found: 0 };
  const [state, setState] = useState<IMiscState>({
    miscOptions: [],
    billingOptions: [],
    reportOptions: [],
    miscOptionsSelected: [],
    billingOptionsSelected: [],
    reportOptionsSelected: [],
    miscOptionsTotal: defaultTotal,
    billingOptionsTotal: defaultTotal,
    reportOptionsTotal: defaultTotal,
    miscOptionsLoading: false,
    billingOptionsLoading: false,
    reportOptionsLoading: false,
  });

  const [miscPagination, setMiscPagination] = useState<any>({ pageSize: 100, pageNumber: 1 });
  const [miscSortOrder, setMiscSortOrder] = useState<any>({ column: miscOptionsHeadCells[0].fieldName, order: 'asc' });
  const [billingPagination, setBillingPagination] = useState<any>({ pageSize: 100, pageNumber: 1 });
  const [billingSortOrder, setBillingSortOrder] = useState<any>({ column: billingOptionsHeadCells[0].fieldName, order: 'asc' });
  const [reportPagination, setReportPagination] = useState<any>({ pageSize: 100, pageNumber: 1 });
  const [reportSortOrder, setReportSortOrder] = useState<any>({ column: reportOptionsHeadCells[0].fieldName, order: 'asc' });

  const [rateTypeList, setRateTypesList] = useState<string[]>([]);
  const [billByToList, setBillByToList] = useState<string[]>([]);
  const [getOptList, setGetOptList] = useState<string[]>([]);
  const [isLoading, setLoading] = useState(false);

  //form validators
  const miscOptionsFieldsGroup = new FormBuilderGroup(MISC_OPTIONS_FIELDS);
  const billingOptionsFieldsGroup = new FormBuilderGroup(BILLING_OPTIONS_FIELDS);
  const reportOptionsFieldsGroup = new FormBuilderGroup(REPORT_OPTIONS_FIELDS);

  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);

  const handleChangeMiscDataGridState = async (dataGridState: any) => {
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    fetchMiscOptions(pagination, searchedText, sortOrder);
  };

  const fetchMiscOptions = async (
    pagination: any = miscPagination,
    filterText?: any,
    sortOrder: any = miscSortOrder,
  ) => {
    setState(prev => ({ ...prev, miscOptionsLoading: true }));
    try {
      const { data }: any = await apiService.terms.getMiscOptions(
        pagination,
        [sortOrder],
        filterText,
        contractId,
      );
      const items = data.data;
      setState((prev: any) => ({ ...prev, miscOptions: items, miscOptionsTotal: data.total }));
      setMiscPagination(pagination);
      setMiscSortOrder(sortOrder);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Misc Options fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, miscOptionsLoading: false }));
    }
  };

  const updateMiscOption = async () => {
    setLoading(true);
    try {
      await apiService.terms.updateMiscOptions([{
        ...state.miscOptionsSelected[0],
        allow: miscOptionsFieldsGroup.getFieldFormValue('allowField'),
        rateType: miscOptionsFieldsGroup.getFieldFormValue('rateTypeField'),
        rateCharge: miscOptionsFieldsGroup.getFieldFormValue('rateChargeField'),
        effDate: miscOptionsFieldsGroup.getFieldFormValue('effDateField'),
        validity: miscOptionsFieldsGroup.getFieldFormValue('validityField'),
        cost: miscOptionsFieldsGroup.getFieldFormValue('costField'),
      }]);
      addNotification({
        text: 'Misc option was successfully updated.',
        type: MessageBarType.success,
      });
      fetchMiscOptions();
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Misc option updating error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeBillingDataGridState = async (dataGridState: any) => {
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    fetchBillingOptions(pagination, searchedText, sortOrder);
  };

  const fetchBillingOptions = async (
    pagination: any = billingPagination,
    filterText?: any,
    sortOrder: any = billingSortOrder,
  ) => {
    setState(prev => ({ ...prev, billingOptionsLoading: true }));
    try {
      const { data }: any = await apiService.terms.getBillingOptions(
        pagination,
        [sortOrder],
        filterText,
        contractId,
      );
      const items = data.data;
      setState((prev: any) => ({ ...prev, billingOptions: items, billingOptionsTotal: data.total }));
      setBillingPagination(pagination);
      setBillingSortOrder(sortOrder);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Billing Options fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, billingOptionsLoading: false }));
    }
  };

  const updateBillingOption = async () => {
    setLoading(true);
    try {
      await apiService.terms.updateBillingOptions([{
        ...state.billingOptionsSelected[0],
        applicable: billingOptionsFieldsGroup.getFieldFormValue('applicableField'),
        billBy: billingOptionsFieldsGroup.getFieldFormValue('billByField'),
        billTo: billingOptionsFieldsGroup.getFieldFormValue('billToField'),
        genOpt: billingOptionsFieldsGroup.getFieldFormValue('genOptField'),
      }]);
      addNotification({
        text: 'Billing option was successfully updated.',
        type: MessageBarType.success,
      });
      fetchBillingOptions();
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Billing option updating error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeReportDataGridState = async (dataGridState: any) => {
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    fetchReportOptions(pagination, searchedText, sortOrder);
  };

  const fetchReportOptions = async (
    pagination: any = reportPagination,
    filterText?: any,
    sortOrder: any = reportSortOrder,
  ) => {
    setState(prev => ({ ...prev, reportOptionsLoading: true }));
    setReportPagination(pagination);
    setReportSortOrder(sortOrder);
    try {
      const { data }: any = await apiService.terms.getReportOptions(
        pagination,
        [sortOrder],
        filterText,
        contractId,
      );
      const items = data.data;
      setState((prev: any) => ({ ...prev, reportOptions: items, reportOptionsTotal: data.total }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Report Options fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, reportOptionsLoading: false }));
    }
  };

  const updateReportOption = async () => {
    setLoading(true);
    try {
      await apiService.terms.updateReportOptions([{
        ...state.reportOptionsSelected[0],
        autogenerate: reportOptionsFieldsGroup.getFieldFormValue('autogenerateField'),
      }]);
      addNotification({
        text: 'Report option was successfully updated.',
        type: MessageBarType.success,
      });
      fetchReportOptions();
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Report option updating error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = async (printingType: any) => {
    setLoading(true);
    toggleShowPrintExport();
    try {
      const requestData = {
        contractId,
        miscPagination,
        filterText: '',
      };
      const headerFields = [
        { title: 'customerId', value: id },
        { title: 'contractId', value: contractId },
      ];
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.terms.miscPrintExcel(requestData, headerFields) :
        await apiService.terms.miscPrintPdf(requestData, headerFields);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchRateTypeList = async () => {
    setLoading(true);
    try {
      const { data }: any = await apiService.terms.getRateTypeList();
      setRateTypesList(['', ...data]);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Rate Type list fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchBillByToList = async () => {
    setLoading(true);
    try {
      const { data }: any = await apiService.terms.getBillByToList();
      setBillByToList(data);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Bill By/To list fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchGenOptList = async () => {
    setLoading(true);
    try {
      const { data }: any = await apiService.terms.getGenOptList();
      setGetOptList(['', ...data]);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Gen Opt list fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRateTypeList();
    fetchBillByToList();
    fetchGenOptList();
  }, []);

  useEffect(() => {
    if (state.miscOptionsSelected.length == 1) {
      miscOptionsFieldsGroup.setFormValue('allowField', state.miscOptionsSelected[0]?.allow);
      miscOptionsFieldsGroup.setFormValue('rateTypeField', state.miscOptionsSelected[0]?.rateType);
      miscOptionsFieldsGroup.setFormValue('rateChargeField', state.miscOptionsSelected[0]?.rateCharge);
      miscOptionsFieldsGroup.setFormValue('effDateField', state.miscOptionsSelected[0]?.effDate);
      miscOptionsFieldsGroup.setFormValue('validityField', state.miscOptionsSelected[0]?.validity);
      miscOptionsFieldsGroup.setFormValue('costField', state.miscOptionsSelected[0]?.cost);
    }
    else {
      miscOptionsFieldsGroup.cleanFormData();
    }
  }, [state.miscOptionsSelected]);

  useEffect(() => {
    if (state.billingOptionsSelected.length == 1) {
      billingOptionsFieldsGroup.setFormValue('applicableField', state.billingOptionsSelected[0]?.applicable);
      billingOptionsFieldsGroup.setFormValue('billByField', state.billingOptionsSelected[0]?.billBy);
      billingOptionsFieldsGroup.setFormValue('billToField', state.billingOptionsSelected[0]?.billTo);
      billingOptionsFieldsGroup.setFormValue('genOptField', state.billingOptionsSelected[0]?.genOpt);
    }
    else {
      billingOptionsFieldsGroup.cleanFormData();
    }
  }, [state.billingOptionsSelected]);

  useEffect(() => {
    if (state.reportOptionsSelected.length == 1) {
      reportOptionsFieldsGroup.setFormValue('autogenerateField', state.reportOptionsSelected[0]?.autogenerate);
    }
    else {
      reportOptionsFieldsGroup.cleanFormData();
    }
  }, [state.reportOptionsSelected]);

  return (
    <>
      <div className={styles.miscData}>
        <div className={styles.actionButtons}>
          <DefaultButton onClick={toggleShowPrintExport} text="Print/Export" />
        </div>
        <PrintingModal
          isOpened={showPrintExport}
          onClose={toggleShowPrintExport}
          onPrint={handlePrint}
        />
        <div className={styles.formContainer}>
          <Dropdown
            {...miscOptionsFieldsGroup.getFieldForm('allowField')}
            options={booleanOptions.map(el => ({
              key: el,
              text: el,
            }))}
            selectedKey={miscOptionsFieldsGroup.getFieldFormValue('allowField')}
            onChange={(_ev, option) => miscOptionsFieldsGroup.setFormValue('allowField', option?.key)}
          />
          <Dropdown
            {...miscOptionsFieldsGroup.getFieldForm('rateTypeField')}
            className={styles.mediumDropdown}
            options={rateTypeList.map(el => ({
              key: el,
              text: el,
            }))}
            selectedKey={miscOptionsFieldsGroup.getFieldFormValue('rateTypeField')}
            onChange={(_ev, option) => miscOptionsFieldsGroup.setFormValue('rateTypeField', option?.key)}
          />
          <TextField {...miscOptionsFieldsGroup.getFieldForm('rateChargeField')} />
          <DatePicker
            {...miscOptionsFieldsGroup.getFieldForm('effDateField')}
            value={miscOptionsFieldsGroup.getFieldFormValue('effDateField')
              ? moment.utc(miscOptionsFieldsGroup.getFieldFormValue('effDateField')).toDate() : undefined}
            onSelectDate={(date) => miscOptionsFieldsGroup.setFormValue('effDateField', date)}
            formatDate={(date: any) => moment.utc(date).format('MM/DD/YYYY')}
          />
          <TextField {...miscOptionsFieldsGroup.getFieldForm('validityField')} />
          <TextField {...miscOptionsFieldsGroup.getFieldForm('costField')} />
        </div>
        <div className={styles.buttonContainer}>
          <PrimaryButton
            text='Update'
            onClick={updateMiscOption}
            disabled={!(state.miscOptionsSelected.length)}
          />
        </div>
        <div className={styles.tableData}>
          <DataGridComponent
            idTable={'services-offered-table'}
            title='Services Offered'
            headCells={miscOptionsHeadCells}
            rowsTable={state.miscOptions}
            totalDataFound={state.miscOptionsTotal.found}
            handleChangeDataGridState={handleChangeMiscDataGridState}
            enablePagination
            enableRowsPerPage
            enableSearching
            enableCheckBox
            defaultSorting={null}
            handleSelectRow={(rows) => setState((prev) => ({ ...prev, miscOptionsSelected: rows }))}
            isLoading={state.miscOptionsLoading}
          />
        </div>
        <div className={styles.formContainer}>
          <Dropdown
            {...billingOptionsFieldsGroup.getFieldForm('applicableField')}
            options={booleanOptions.map(el => ({
              key: el,
              text: el,
            }))}
            selectedKey={billingOptionsFieldsGroup.getFieldFormValue('applicableField')}
            onChange={(_ev, option) => billingOptionsFieldsGroup.setFormValue('applicableField', option?.key)}
          />
          <Dropdown
            {...billingOptionsFieldsGroup.getFieldForm('billByField')}
            className={styles.mediumDropdown}
            options={billByToList.map(el => ({
              key: el,
              text: el,
            }))}
            selectedKey={billingOptionsFieldsGroup.getFieldFormValue('billByField')}
            onChange={(_ev, option) => billingOptionsFieldsGroup.setFormValue('billByField', option?.key)}
          />
          <Dropdown
            {...billingOptionsFieldsGroup.getFieldForm('billToField')}
            className={styles.mediumDropdown}
            options={billByToList.map(el => ({
              key: el,
              text: el,
            }))}
            selectedKey={billingOptionsFieldsGroup.getFieldFormValue('billToField')}
            onChange={(_ev, option) => billingOptionsFieldsGroup.setFormValue('billToField', option?.key)}
          />
          <Dropdown
            {...billingOptionsFieldsGroup.getFieldForm('genOptField')}
            className={styles.mediumDropdown}
            options={getOptList.map(el => ({
              key: el,
              text: el,
            }))}
            selectedKey={billingOptionsFieldsGroup.getFieldFormValue('genOptField')}
            onChange={(_ev, option) => billingOptionsFieldsGroup.setFormValue('genOptField', option?.key)}
          />
        </div>
        <div className={styles.buttonContainer}>
          <PrimaryButton
            text='Update'
            onClick={updateBillingOption}
            disabled={!(state.billingOptionsSelected.length)}
          />
        </div>
        <div className={styles.tableData}>
          <DataGridComponent
            idTable={'billing-options-table'}
            title='Billing Options'
            headCells={billingOptionsHeadCells}
            rowsTable={state.billingOptions}
            totalDataFound={state.billingOptionsTotal.found}
            handleChangeDataGridState={handleChangeBillingDataGridState}
            enablePagination
            enableRowsPerPage
            enableSearching
            enableCheckBox
            handleSelectRow={(rows) => setState((prev) => ({ ...prev, billingOptionsSelected: rows }))}
            isLoading={state.billingOptionsLoading}
          />
        </div>
        <div className={styles.formContainer}>
          <Dropdown
            {...reportOptionsFieldsGroup.getFieldForm('autogenerateField')}
            options={booleanOptions.map(el => ({
              key: el,
              text: el,
            }))}
            selectedKey={reportOptionsFieldsGroup.getFieldFormValue('autogenerateField')}
            onChange={(_ev, option) => reportOptionsFieldsGroup.setFormValue('autogenerateField', option?.key)}
          />
        </div>
        <div className={styles.buttonContainer}>
          <PrimaryButton
            text='Update'
            onClick={updateReportOption}
            disabled={!(state.reportOptionsSelected.length)}
          />
        </div>
        <div className={styles.tableData}>
          <DataGridComponent
            idTable={'report-options-table'}
            title='Report Options'
            headCells={reportOptionsHeadCells}
            rowsTable={state.reportOptions}
            totalDataFound={state.reportOptionsTotal.found}
            handleChangeDataGridState={handleChangeReportDataGridState}
            enablePagination
            enableRowsPerPage
            enableSearching
            enableCheckBox
            handleSelectRow={(rows) => setState((prev) => ({ ...prev, reportOptionsSelected: rows }))}
            isLoading={state.reportOptionsLoading}
          />
        </div>
      </div>
      {isLoading && <LoadingScreen />}
    </>
  );
};
