import { FunctionComponent } from 'react';
import ViewMaintainInvoicesModalComponent from '../../../ViewMaintainInvoicesModalComponent';
import { useBoolean } from '@fluentui/react-hooks';
import { IconButton } from '@fluentui/react';
import { IAvailableInvoice } from '../../../../models/IAvailableInvoice';


interface MaintainInvoiceActionProps {
  row: IAvailableInvoice;
}

const MaintainInvoiceAction: FunctionComponent<MaintainInvoiceActionProps> = ({ row }) => {
  const [isModalInvoiceOpen, { toggle: toggleIsModalInvoiceOpen }] = useBoolean(false);

  const disableAction = row?.status?.toLowerCase() != 'pending';


  return (
    <>
      <IconButton
        id='viewButton'
        iconProps={{ iconName: 'EditSolid12' }}
        onClick={toggleIsModalInvoiceOpen}
        disabled={disableAction}
      />

      <ViewMaintainInvoicesModalComponent
        id='invoiceModal'
        isOpen={isModalInvoiceOpen}
        title='Maintain Invoice'
        invoiceId={row?.invoiceId}
        onCancel={toggleIsModalInvoiceOpen}
        onSubmit={toggleIsModalInvoiceOpen}
        actionType='maintain'
      />
    </>
  );
};

export default MaintainInvoiceAction;