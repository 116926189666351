import React from 'react';
import ReactDOM from 'react-dom';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from './App';
import { store } from './redux';

import './index.scss';

//import { ThemeProvider } from '@material-ui/core/styles';
//import { MsalProvider } from '@azure/msal-react';

// MSAL configuration
//redirectUri: 'http://localhost:3000',
//postLogoutRedirectUri: 'http://localhost:3000',

//Creating a dropdown field for Search Customer

export const configuration: Configuration = {
  auth: {
    //clientId: 'b7d6a913-10e4-4d56-b955-1cc56822300a',
    clientId: '4bda296b-3930-4f6e-ba3f-37991a8f83f6',
    //redirectUri: 'http://localhost:3000/customer',
    authority: 'https://login.microsoftonline.com/939e8966-9285-4a9a-9f04-0887efe8aae0',
  },
};
console.log(process.env.REACT_APP_SWA_DEV);

export const msalInstance = new PublicClientApplication(configuration);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App pca={msalInstance} />
    </Router>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
