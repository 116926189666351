import { MessageBarType, Pivot, PivotItem } from '@fluentui/react';
import classNames from 'classnames';
import { FC, ReactElement, useEffect, useState } from 'react';
import apiService from '../../../../api';
import { spareStockTabs } from '../../../../consts/recordKeeping';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { auth_spareStock_postSpareStock, auth_spareStock_viewPostedSpareStock, auth_spareStock_massDetails } from '../../../../consts/programKeys';

import TabHeader from '../TabHeader/TabHeader';
import { useDispatch, useSelector } from 'react-redux';
import PostSpareStock from './tabComponents/PostSpareStock/PostSpareStock';
import ViewPostedSpareStock from './tabComponents/ViewPostedSpareStock/ViewPostedSpareStock';
import { ISpareStockTabProps } from './ISpareStockTabProps';
import MassDetails from './tabComponents/MassDetails';

import './SpareStockTab.module.scss';
import { customerSelector, setCustomerInformation } from '../../../../redux/recordKeepingSlice';
import useNotifications from '../../../../hooks/useNotifications';

const SpareStockTab: FC<ISpareStockTabProps> = (): ReactElement => {

  const [selectedKey, setSelectedKey] = useState('rectangleRed');
  const { id: customerId } = useSelector(customerSelector);
  const { hasPermission } = useUserPermissions();
  const dispatch = useDispatch();
  const { addNotification } = useNotifications();
  const userPermissionsPostSpareStock = hasPermission(auth_spareStock_postSpareStock);
  const userPermissionsViewPostedSpareStock = hasPermission(auth_spareStock_viewPostedSpareStock);
  const userPermissionsMassDetails = hasPermission(auth_spareStock_massDetails);

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  };

  const fetchCustomerData = async () => {
    try {
      const { data }: any = await apiService.getCustomerInformation(customerId);
      dispatch(setCustomerInformation(data.data));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Customer data fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  useEffect(() => {
    fetchCustomerData();
  }, []);

  return (
    <div>
      <TabHeader
        details
      />
      <Pivot
        className={classNames('pivot-container', 'pivot-gy-colors')}
        selectedKey={selectedKey}
        onLinkClick={handleLinkClick}
        getTabId={getTabId}
        linkFormat="tabs"
      >
        {userPermissionsPostSpareStock.isAccess && <PivotItem headerText={spareStockTabs.postSpareStock} itemKey={spareStockTabs.postSpareStock}>
          <div className="vertical-pivot-container"><PostSpareStock /></div>
        </PivotItem>}
        {userPermissionsViewPostedSpareStock.isAccess && <PivotItem headerText={spareStockTabs.viewPostedSpareStock} itemKey={spareStockTabs.viewPostedSpareStock}>
          <div className="vertical-pivot-container"><ViewPostedSpareStock /></div>
        </PivotItem>}
        {userPermissionsMassDetails.isAccess && <PivotItem headerText={spareStockTabs.massDetail} itemKey={spareStockTabs.massDetail}>
          <div className="vertical-pivot-container"><MassDetails /></div>
        </PivotItem>}
      </Pivot>
    </div>
  );
};

export default SpareStockTab;