import apiService from '../../../../api';
import { ACTION_GROUPS_ENUM } from '../ActionsGroupEnum';

interface DownloadProps {
  row: any,
  actionsGroupName: string
}

export const downloadReport = ({ row, actionsGroupName }: DownloadProps) => {
  switch (actionsGroupName) {
    case ACTION_GROUPS_ENUM.DOWNLOAD_AVAILABLE_REPORT:
      return apiService.billingAPI.downloadAvailableReport({
        reportId: row.mCdScheduleReportHistoryid,
        reportName: row.reportName,
        userId: row.generatedBy,
      });
    case ACTION_GROUPS_ENUM.DOWNLOAD_REPORT:
      return apiService.billingAPI.downloadMyReport({
        reportId: row.mCdScheduleReportFileQueueId,
        reportName: row.reportName,
        userId: row.userId,
      });
    case ACTION_GROUPS_ENUM.VIEW_PDF_BILLINGREPORTS:
      return apiService.reporting.getScheduledBackupReport(row.mCdScheduleBackupreportId);
    default:
      break;
  }
};

export const reportFormat = ({ row, actionsGroupName }: DownloadProps) => {
  if (actionsGroupName === ACTION_GROUPS_ENUM.DOWNLOAD_REPORT) {
    return row?.reportName;
  } else if (actionsGroupName === ACTION_GROUPS_ENUM.VIEW_PDF_BILLINGREPORTS) {
    return 'pdf';
  } else {
    return row?.reportFormat;
  }
};