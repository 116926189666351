import { MessageBarType } from '@fluentui/react';
import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import apiService from '../../../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { useField } from '../../../../../hooks/useField';
import { selectedQuoteSelector, setSelectedQuote } from '../../../../../redux/quoteSlice';
import { INotification } from '../../../../../models/INotification';
import useNotifications from '../../../../../hooks/useNotifications';
import { useBoolean } from '@fluentui/react-hooks';
import HistoryForm from '../../../../../shared/HistoryForm';
import { HISTORY_FIELDS } from '../../../../../shared/HistoryForm/consts';
import { customerSelector } from '../../../../../redux/customerSlice';
import { quoteStatuses } from '../../../const';

interface HistoryComponentProps {
  actionType: string
}

const History: FunctionComponent<HistoryComponentProps> = ({ actionType }) => {
  const { addNotification } = useNotifications();
  const dispatch = useDispatch();
  const customer = useSelector(customerSelector);
  const parentComponentName = 'QUOTE';

  const selectedQuote = useSelector(selectedQuoteSelector);
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });

  // form validations
  const commentsField = useField(HISTORY_FIELDS.commentsDataField);
  const createdByField = useField(HISTORY_FIELDS.createdByDataField);
  const createdOnField = useField(HISTORY_FIELDS.createdOnDataField);
  const lastUpdatedByField = useField(HISTORY_FIELDS.lastUpdatedNameDataField);
  const lastUpdatedOnField = useField(HISTORY_FIELDS.lastUpdatedDateDataField);
  const [isLoading, { toggle: toggleShowLoading }] = useBoolean(false);

  const handleUpdateComments = async () => {
    try {
      toggleShowLoading();
      const bodyRequest = { 
        id: selectedQuote.id, 
        comments: commentsField.getValue(),
        quoteId: selectedQuote?.id,
        quoteVersion: selectedQuote?.version, 
      };
      await apiService.quotesAPI.updateQuoteHistory(bodyRequest);
      updateQuote();
      setNotification({ text: 'Comments updated successfully', type: MessageBarType.success });
    } catch (error) {
      setNotification({ text: 'Updating comments error', type: MessageBarType.error });
    } finally {
      toggleShowLoading();
    }
  };

  const updateQuote = () => {
    const newQuote = { 
      ...selectedQuote, 
      comments: commentsField.getValue(), 
      status: selectedQuote?.status === quoteStatuses.GENERATED ? quoteStatuses.REGENERATED : selectedQuote?.status, 
    };
    dispatch(setSelectedQuote(newQuote));
  };

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  useEffect(() => {
    if (!selectedQuote) return;
    const { comments, createdBy, createdOn, lastUpdatedBy, lastUpdatedOn } = selectedQuote;
    commentsField.setValue(comments);
    createdByField.setValue(createdBy);
    createdOnField.setValue(moment(createdOn).format('MM/DD/YYYY'));
    lastUpdatedByField.setValue(lastUpdatedBy);
    lastUpdatedOnField.setValue(moment(lastUpdatedOn).format('MM/DD/YYYY'));
  }, [selectedQuote]);

  const parentComments: string = selectedQuote?.comments;

  return (
    <HistoryForm
      actionType={actionType}
      comments={parentComments}
      handleUpdateComments={handleUpdateComments}
      isLoading={isLoading}
      commentsField={commentsField}
      createdByField={createdByField}
      createdOnField={createdOnField}
      lastUpdatedByField={lastUpdatedByField}
      lastUpdatedOnField={lastUpdatedOnField}
      disableEditing={selectedQuote?.status === quoteStatuses.AUTHORIZED}
      parentComponentName={parentComponentName}
    />
  );
};

export default History;
