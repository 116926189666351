import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { IPostSpareStockInventoryProps } from './IPostSpareStockInventoryProps';
import { IPostSpareStockInventoryState } from './IPostSpareStockInventoryState';
import apiService from '../../../../../../../api';
import { Text, IconButton, MessageBarType, PrimaryButton, TextField, IDropdownOption, IColumn, Dropdown, Dialog, DialogType, DialogFooter, DefaultButton, Checkbox } from '@fluentui/react';
import useNotifications from '../../../../../../../hooks/useNotifications';
import { useSelector } from 'react-redux';
import { customerSelector, locationSelector } from '../../../../../../../redux/recordKeepingSlice';
import { columns } from './consts';
import classNames from 'classnames';
import styles from './PostSpareStockInventory.module.scss';
import SeparatorGy from '../../../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../../../Pagination/Pagination';
import { IPaginationProps } from '../../../../../../Pagination/IPaginationProps';
import { pageSizes } from '../../../../../../SelectingModal/consts';
import { sortOrder } from '../../../../../../../consts/sortOrder';
import LoadingScreen from '../../../../../../LoadingScreen/LoadingScreen';
import ReconciliationReport from '../ReconciliationReport/ReconciliationReport';

const PostSpareStockInventory: FC<IPostSpareStockInventoryProps> = ({ postedElement, selectedItems, period, selectedLocations, isAnnual, onDismiss, inventoryTakenOn, annual}): ReactElement => {
  const [state, setState] = useState<IPostSpareStockInventoryState>({
    items: [],
    selectedItems: [],
    loading: false,
    foundCount: 0,
  });

  const [addFields, setAddFields] = useState<any>({ prefix: '', brandNumber: null, suffix: '' });

  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);
  const { addNotification } = useNotifications();

  const [postedSpareStockElement, setPostedSpareStockElement] = useState<any>({ viewAnnualOnly: false, location: null, monthYear: '' });
  const [showReconciliationReport, { toggle: toggleShowReconciliationReport }] = useBoolean(false);

  const [convertToAnnual, setConvertToAnnual] = useState(isAnnual ? 'Y' : 'N');
  const [runningProcess, setRunningProcess] = useState<string>('');
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });

  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const handleSelect = (e: any, itemId: any) => {
    const selectedRows = [...state.selectedItems];
    if (e.target.checked) {
      selectedRows.push(itemId);
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows.filter(row => row !== itemId) }));
    }
  };

  const handleSelectAll = (e: any, items: Array<any>) => {
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedItems: items })) : setState((prev: any) => ({ ...prev, selectedItems: [] }));
  };

  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const toggleConvertToAnnual = (e:any) => {
    if (e.target.checked) {
      setConvertToAnnual(isAnnual ? 'N' : 'Y');
    } else {
      setConvertToAnnual(isAnnual ? 'Y' : 'N');
    }
  };

  const openReconciliationReport = (el: any) => {
    setPostedSpareStockElement(state.items.find((item: any) => item.id === el));
    toggleShowReconciliationReport();
  };

  const fetchSpareStock = async () => {
    let timeOut = null;

    setState(prev => ({ ...prev, loading: true }));
    const sortOrder = getSortOrder();
    try {
      const { data }: any = await apiService.spareStock.getSpareStockList(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
        postedElement?.id,
      );

      const { data: { runStatus } }: any = await apiService.spareStock.getPostStatus(
        [postedElement?.id],
      );

      if (timeOut) clearTimeout(timeOut);
      if (runStatus == 'RUNNING') timeOut = setTimeout(() => fetchSpareStock(), 10000);

      setRunningProcess(runStatus);
      const foundCount = data.total.found;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      addNotification({
        text: `Spare stock fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handlePost = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.spareStock.submitSpareStockBatch(state.selectedItems);
      addNotification({
        text: 'Selected item(s) were successfully posted.',
        type: MessageBarType.success,
      });
      fetchSpareStock();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Posted Spare Stock posting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
    finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleAddBatch = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      await apiService.spareStock.postSpareStockBatchAdd(
        Array.from({ length: addFields.toBrandNumber ? (addFields.toBrandNumber - addFields.brandNumber) + 1 : 1 }, (_el, i) => ({
          pfx: addFields.prefix,
          bno: parseInt(addFields.brandNumber) + i,
          sfx: addFields.suffix,
        })),
        customerId,
        locationId,
        postedElement?.id,
      );
      setAddFields({ prefix: null, brandNumber: null, toBrandNumber: null ,suffix: '' });
      addNotification({
        text: 'Spare Stock was successfully added',
        type: MessageBarType.success,
      });
      fetchSpareStock();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Spare Stock saving error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.spareStock.deleteSpareStockList(state.selectedItems);
      addNotification({
        text: 'Selected tire(s) were successfully deleted.',
        type: MessageBarType.success,
      });
      fetchSpareStock();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Spare Stock deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const onChangeField = (field: any, value: any, regExp?: RegExp) =>
    setAddFields((prev: any) => ({ ...prev, [field]: regExp ? (regExp.test(value) ? value : prev[field]) : value }));

  const validateToBrandNumber = parseInt(addFields.brandNumber) > parseInt(addFields.toBrandNumber);


  useEffect(() => {
    fetchSpareStock();
  }, [paginationProps.current, countOnPage, columnsState]);

  const isRunningProcess = runningProcess === 'RUNNING';

  return (
    <>
      <div className={classNames(styles.detailsWrapper)}>
        <div className={classNames(styles.detailsBackground)}>
          <div className={classNames(styles.detailsContainer)} ref={containerRef}>
            <div className={classNames(styles.detailsHeader)}>
              <Text variant="xxLarge" className={styles.highlight}>Post Spare Stock Inventory</Text>
              <IconButton
                iconProps={{ iconName: 'Cancel' }}
                title="close"
                onClick={() => onDismiss()}
              />
            </div>
            <div className={classNames(styles.detailsBody)}>
              <div className='ms-Grid-row'>
                <div className={classNames('ms-Grid-col', 'ms-sm6', styles.tireDetailsBlock)}>
                  <div className={styles.addBlock}>
                    <div className={styles.statusContainer}>
                      <Text variant="xLarge" className={styles.highlight}>Tire Details</Text>
                      <Text  variant="xLarge" className={styles.highlight}>
                    Status: 
                        <Text variant='xLarge'className={runningProcess === 'RUNNING' ? styles.statusRunning : runningProcess === 'COMPLETED' ? styles.statusComplete : styles.statusDefault}> {runningProcess}</Text>
                      </Text>
                    </div>

                    <SeparatorGy />
                    <div className={styles.addingFields}>
                      <div>
                        <div className={styles.fromToFields}>
                          <TextField
                            id='fromPrefix'
                            className={styles.addingField}
                            label="From Prefix"
                            value={addFields.prefix}
                            onChange={(e, prefix) => onChangeField('prefix', prefix?.toUpperCase())}
                            autoFocus
                            required
                          />
                          <TextField
                            id='fromBrandNumber'
                            className={styles.addingField}
                            label="From Brand Number"
                            min="1"
                            value={addFields.brandNumber}
                            onChange={(e, brandNumber) => onChangeField('brandNumber', brandNumber, /^[0-9]*$/)}
                            required
                          />
                          <TextField
                            id="fromSuffix"
                            className={styles.addingField}
                            label="From Suffix"
                            value={addFields.suffix}
                            onChange={(e, suffix) => onChangeField('suffix', suffix?.toUpperCase())}
                          />
                        </div>
                      </div> 
                      <div>
                        <div className={styles.fromToFields}>
                          <TextField
                            className={styles.addingField}
                            label="To Prefix"
                            value={addFields.prefix}
                            disabled
                          />
                          <TextField
                            id='toBrandNumber'
                            className={styles.addingField}
                            label="To Brand Number"
                            min={'0'}
                            value={addFields.toBrandNumber}
                            onChange={(e, toBrandNumber) => onChangeField('toBrandNumber', toBrandNumber, /^[0-9]*$/)}
                            errorMessage={(validateToBrandNumber ? 'Should be more than From Brand Number' : '')}
                          />
                          <TextField
                            className={styles.addingField}
                            label="To Suffix"
                            value={addFields.suffix}
                            disabled
                          />
                        </div>
                      </div>
                      <div className={styles.addButtonBlock}>
                        <PrimaryButton
                          id="addButton"
                          className={styles.addButton}
                          text="Add"
                          disabled={!addFields.prefix || !addFields.brandNumber}
                          onClick={handleAddBatch}
                        />
                        <Checkbox
                          id="convertToAnnual"
                          className={styles.annualField}
                          label={isAnnual ? 'Convert to Normal' : 'Convert to Annual'}
                          onChange={(e) => toggleConvertToAnnual(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classNames('ms-Grid-col', 'ms-sm6', styles.tirePostedSpareStockBlock)}>
                  <div>
                    <Text variant="xLarge"> Inventory Taken On: {inventoryTakenOn}</Text>
                  </div>
                  <div>
                    <Text variant="xLarge"> Annual: {annual}</Text>
                  </div>
                </div>
              </div>
              <div className='ms-Grid-row'>
                <div className={styles.tableHeading}>
                  <div>
                    <Text variant="xLarge" className={styles.highlight}>Spare Stock</Text>
                    <SeparatorGy vertical />
                    <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
                  </div>
                  <div>
                    <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                    <Dropdown
                      options={pageSizes.map(pageSize => ({
                        key: pageSize,
                        text: pageSize.toString(),
                      }))}
                      defaultSelectedKey={pageSizes[0]}
                      selectedKey={countOnPage?.key}
                      onChange={onChangeCountOnPage}
                    />
                    <SeparatorGy vertical />
                    <Text variant="large" className={styles.highlight}>{state.selectedItems.length} items selected</Text>
                    <SeparatorGy vertical />
                    <IconButton
                      id="toggleDeletingConfirmationButton"
                      disabled={!state.selectedItems.length}
                      iconProps={{ iconName: 'Delete' }}
                      onClick={toggleDeletingConfirmation}
                    />
                    <IconButton
                      id="refreshButton"
                      className={styles.refreshButton}
                      iconProps={{ iconName: 'Refresh' }}
                      onClick={fetchSpareStock}
                    />
                  </div>
                </div>
              </div>
              <div className={styles['table-wrapper']}>
                <table>
                  <thead>
                    <tr>
                      {
                        columnsState.map(item => (
                          <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                        ))
                      }
                      <th>
                        <div className={styles.round}>
                          <input
                            type="checkbox"
                            id="allItems"
                            checked={state.items.length !== 0 && (state.selectedItems.length === +countOnPage.key || state.selectedItems.length === state.items.length)}
                            onChange={(e) => handleSelectAll(e, state.items.map(({ id }) => id))}
                          />
                          <label htmlFor="allItems"></label>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      state.items.map((item: any) => (
                        <tr key={item.id} className={classNames(state.selectedItems.includes(item.id) ? styles.trSelected : styles.trBasic)}>
                          {
                            columnsState.map((column) => (
                              <td>
                                {item[column.fieldName]}
                              </td>
                            ))
                          }
                          <td>
                            <div className={styles.round}>
                              <input type="checkbox" id={item.id} checked={state.selectedItems.includes(item.id)} onChange={(e) => handleSelect(e, item.id)} />
                              <label htmlFor={item.id}></label>
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
              <SeparatorGy />
              <Pagination {...paginationProps} />
              <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
                <PrimaryButton
                  id="reconciliationReportButton" 
                  text="View Reconciliation Report" 
                  onClick={() => openReconciliationReport(selectedItems)}
                  disabled={isRunningProcess}
                />
                <PrimaryButton onClick={handlePost} text="Submit" disabled={!state.selectedItems.length || isRunningProcess} />
              </div>
            </div>
          </div>
        </div>
        {showReconciliationReport && <ReconciliationReport onDismiss={toggleShowReconciliationReport} selectedLocations={selectedLocations} period={period} isAnnual={isAnnual} postedElement={postedElement}/>}
      </div>
      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to delete ${state.selectedItems.length} items?`,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default PostSpareStockInventory;