import { IconButton } from '@fluentui/react';
import { FunctionComponent } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import ViewPdfModalComponent from '../../../ViewPdfModalComponent';
import { IReport } from '../../../../models/IReport';


interface BillingReportsViewPDFProps {
  row: IReport;
}
 
const BillingReportsViewPDF: FunctionComponent<BillingReportsViewPDFProps> = ({row}) => {  

  const [isModalViewPdfOpen, { toggle: toggleIsModalViewPdfOpen }] = useBoolean(false);  

  return (  
    <>
      <IconButton
        id='viewButton'
        iconProps={{ iconName: 'View' }}
        onClick={toggleIsModalViewPdfOpen}
      />

      <ViewPdfModalComponent
        id='viewPdfModal'
        isOpen={isModalViewPdfOpen}
        title='View PDF'
        reportId={row?.id || row?.mCdScheduleBackupreportId}
        displayedFileName={row?.reportName}
        onDismiss={toggleIsModalViewPdfOpen}
        cancelLabel='Close'
        parentComponentName={'BILLING_BACKUPS'}
      />
    </>
  );
};
 
export default BillingReportsViewPDF;