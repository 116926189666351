import { DefaultButton, IconButton, MessageBarType, Modal, PrimaryButton, Text } from '@fluentui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import DataGridComponent from '../DataGridComponent';
import { availableInvoicesHeadCell, defaultSorting } from './consts';
import styles from './InvoiceModal.module.scss';
import { useBoolean } from '@fluentui/react-hooks';
import apiService from '../../api';
import useNotifications from '../../hooks/useNotifications';



interface InvoiceModalComponentProps {
  id: string;
  customerId: string;
  invoiceType: string;
  isOpen: boolean;
  onSubmit: (value?: any) => void;
  onDismiss: () => void;
}

const InvoiceModalComponent: FunctionComponent<InvoiceModalComponentProps> = ({
  id,
  customerId,
  invoiceType,
  isOpen,
  onSubmit,
  onDismiss,
}) => {

  //hooks
  const { addNotification } = useNotifications();

  //state
  const [invoicesList, setInvoicesList] = useState([]);
  const [invoiceSelected, setInvoiceSelected] = useState(null);
  const [totalFound, setTotalFound] = useState(0);
  const [notification, setNotification] = useState({ text: '', type: 0 });

  //toggle
  const [isLoadingAvailableTable, { toggle: toggleShowLoadingAvailableTable }] = useBoolean(false);

  const fetchAvailableInvoices = async (
    pagination: any = { pageSize: 5, pageNumber: 1 },
    filters?: any,
    sortOrder: any = { column: availableInvoicesHeadCell[0].fieldName, order: 'asc' },
  ) => {
    try {
      setInvoicesList([]);
      const { data }: any = await apiService.billingAPI.getCreditAgainstInvoices(
        pagination,
        sortOrder,
        filters,
        customerId,
        invoiceType,
      );
      setInvoicesList(data?.data);
      setTotalFound(data?.total?.found);
    } catch (error) {
      setNotification({ text: 'Fetching tires error', type: MessageBarType.error });
    }
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    toggleShowLoadingAvailableTable();
    const { countOnPage, paginationProps, sortOrder, searchedText } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    await fetchAvailableInvoices(pagination, searchedText, sortOrder);
    toggleShowLoadingAvailableTable();
  };

  const handleSelectRow = (rowsSelected: any) => {
    if (rowsSelected.length) setInvoiceSelected(rowsSelected[0]);
  };

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  const handleSubmit = () => onSubmit(invoiceSelected);
  const disableSelecBtn = invoiceSelected == null;

  return (
    <>
      <>
        <Modal
          key={id}
          isOpen={isOpen}
          onDismiss={onDismiss}
          containerClassName='modalContainer'
        >
          <div className={styles.modalBody}>
            <div className='modalHeader'>
              <Text variant='xLarge'>Find Invoice</Text>
              <IconButton
                iconProps={{ iconName: 'Cancel' }}
                ariaLabel='Close popup modal'
                onClick={onDismiss}
              />
            </div>
            <div className='tableContainer'>
              <DataGridComponent
                idTable={'invoice-table'}
                title=''
                headCells={availableInvoicesHeadCell}
                defaultSorting={defaultSorting}
                rowsTable={invoicesList}
                totalDataFound={totalFound}
                isLoading={isLoadingAvailableTable}
                enableCheckBox={true}
                enableSearching={true}
                enablePagination={true}
                enableRowsPerPage={true}
                handleChangeDataGridState={handleChangeDataGridState}
                handleSelectRow={handleSelectRow}
              />
            </div>
            <div className={styles.modalFooter}>
              <DefaultButton
                id='cancelButton'
                text='Cancel'
                onClick={onDismiss}
              />
              <PrimaryButton
                id='selectButton'
                text='Select Invoice'
                onClick={handleSubmit}
                disabled={disableSelecBtn}
              />
            </div>
          </div>
        </Modal>
      </>
    </>
  );
};

export default InvoiceModalComponent;