import { HeadCell } from '../../../../../shared/DataGridComponent/DataGridModels';

export const logsHeadCell: HeadCell[] = [
  {
    key: 'column1',
    name: 'Sequence',
    fieldName: 'seq',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Invoice ID',
    fieldName: 'recordId',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Details',
    fieldName: 'addDetails',
    typeField: 'text',
  },
  {
    key: 'column4',
    name: 'Status',
    fieldName: 'status',
    typeField: 'text',
  },
  {
    key: 'column5',
    name: 'Message',
    fieldName: 'message',
    typeField: 'text',
  },
  {
    key: 'column6',
    name: 'Created By',
    fieldName: 'createdby',
    typeField: 'text',
  },
  {
    key: 'column7',
    name: 'Created On',
    fieldName: 'createdon',
    typeField: 'date',
  },
];