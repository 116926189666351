import {
  IconButton,
  Slider,
  Text,
  Dropdown,
  DefaultButton,
  Checkbox,
  DatePicker,
  IDropdownOption,
  MessageBarType,
  TextField,
} from '@fluentui/react';
import { FC, FormEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { IFilterModalWindowProps } from './FilterModalWindowProps';
import classNames from 'classnames';
import apiService from '../../../../../api';
import styles from './FilterModalWindow.module.scss';
import { emptyFilterModalWindowState, initialFilterState } from './consts';
import { IFilterModalWindowState } from './IFilterModalWindowState';
import useNotifications from '../../../../../hooks/useNotifications';
import { useSelector } from 'react-redux';
import moment from 'moment';

const FilterModalWindow: FC<IFilterModalWindowProps> = ({
  isOpened,
  filtersState,
  setFiltersState,
}): ReactElement => {
  const [currentFilters, setCurrentFilters] = useState<any>(filtersState);

  const { addNotification } = useNotifications();

  const [state, setState] = useState<IFilterModalWindowState>(emptyFilterModalWindowState);

  const applyFilters = () => {
    setFiltersState({ ...currentFilters });
    isOpened(false);
  };

  const clearFilters = () => {
    setCurrentFilters({ ...initialFilterState });
  };

  const containerRef = useRef(null);

  const handleClickOutside = (event: any) => {
    if (!(containerRef.current as any).contains(event.target)) {
      isOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [containerRef]);

  return (
    <div id="filterModalWindow" className={classNames(styles.filterWrapper)}>
      <div className={classNames(styles.filterBackground)}>
        <div className={classNames(styles.filterContainer)} ref={containerRef}>
          <div className={classNames(styles.filterHeader)}>
            <Text variant="xxLarge">Filter</Text>
            <IconButton
              id="closeButton"
              iconProps={{iconName: 'Cancel'}}
              title="close"
              onClick={() => isOpened(false)}
            />
          </div>
          <div className={classNames(styles.filterBody)}>
            <div className={classNames(styles.row)}>
              <div className={classNames(styles.column)}>
                <div>
                  <label>
                    <Text variant="large">Account</Text>
                  </label>
                  <TextField
                    id="account"
                    value={currentFilters.account}
                    onChange={(e, account) => setCurrentFilters({ ...currentFilters, account })}
                  />
                </div>
              </div>
              <div className={classNames(styles.column)}>
                <div>
                  <label>
                    <Text variant="large">Loc</Text>
                  </label>
                  <TextField
                    id="loc"
                    value={currentFilters.loc}
                    onChange={(e, loc) => setCurrentFilters({ ...currentFilters, loc })}
                  />
                </div>
              </div>
            </div>
            <div className={classNames(styles.row)}>
              <div className={classNames(styles.column)}>
                <div>
                  <label>
                    <Text variant="large">Annual</Text>
                  </label>
                  <TextField
                    id="annual"
                    value={currentFilters.annual}
                    onChange={(e, annual) => setCurrentFilters({ ...currentFilters, annual })}
                  />
                </div>
              </div>
              <div className={classNames(styles.column)}>
                <div>
                  <label>
                    <Text variant="large">Submit</Text>
                  </label>
                  <TextField
                    id="submit"
                    value={currentFilters.submit}
                    onChange={(e, submit) => setCurrentFilters({ ...currentFilters, submit })}
                  />
                </div>
              </div>
            </div>
            <div className={classNames(styles.row)}>
              <div className={classNames(styles.column)}>
                <div>
                  <label>
                    <Text variant="large">Inventory Date From</Text>
                  </label>
                  <DatePicker
                    id="InventoryDateFrom"
                    showMonthPickerAsOverlay={true}
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    value={currentFilters.inventoryDateFrom ? currentFilters.inventoryDateFrom : null}
                    onSelectDate={(inventoryDateFrom: Date | null | undefined) => setCurrentFilters({ ...currentFilters, inventoryDateFrom })}
                    formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                  />
                </div>
              </div>
              <div className={classNames(styles.column)}>
                <div>
                  <label>
                    <Text variant="large">Inventory Date To</Text>
                  </label>
                  <DatePicker
                    id="InventoryDateTo"
                    showMonthPickerAsOverlay={true}
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    value={currentFilters.inventoryDateTo ? currentFilters.inventoryDateTo : null}
                    onSelectDate={(inventoryDateTo: Date | null | undefined) => setCurrentFilters({ ...currentFilters, inventoryDateTo })}
                    formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                  />
                </div>
              </div>
            </div>
            
          </div>
          <div className={classNames(styles.filterFooter)}>
            <DefaultButton
              id="clearButton"
              onClick={clearFilters}
              text={'Clear Filters'}
            />
            <DefaultButton
              id="applyButton"
              onClick={applyFilters}
              text={'Apply'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterModalWindow;

