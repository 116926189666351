import { ITabComponent } from '../../models/ITabComponent';
import BillingBackups from './tabComponents/BillingBackups/BillingBackups';
import StoredReports from './tabComponents/StoredReports/StoredReports';

export enum REPORTING_TABS {
    BILLING_BACKUPS = 'billingBackups',
    STORED_REPORTS = 'storedReports',
  };

export const reportingTabs: IReportingTabs = {
  billingBackups: {
    id: REPORTING_TABS.BILLING_BACKUPS,
    name: 'Billing Backups',
    component: BillingBackups,
    subTabs: null,
  },
  storedReports: {
    id: REPORTING_TABS.STORED_REPORTS,
    name: 'Stored Reports',
    component: StoredReports,
    subTabs: null,
  },
};

export interface IReportingTabs {
    billingBackups: ITabComponent;
    storedReports: ITabComponent;
  }
  