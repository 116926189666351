import { fieldType } from '../../../../../../../../../consts/fieldType';

export const vehicleMileageColumns = [
  {
    key: 'column1',
    name: 'Vno',
    fieldName: 'vno',
    isSorted: true,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column2',
    name: 'RptUnits',
    fieldName: 'rptUnits',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column3',
    name: 'GdyrUnits',
    fieldName: 'gdyrUnits',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column4',
    name: 'MiscUnits',
    fieldName: 'miscUnits',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
];