import { HeadCell } from '../../../../shared/DataGridComponent/DataGridModels';
import { IAddProductItem } from './IMaintainProductState';

const productHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Product code',
    fieldName: 'id',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Tire Size',
    fieldName: 'tireSize',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Description',
    fieldName: 'description',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Tire type',
    fieldName: 'origOrRtrd',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'Radial bias',
    fieldName: 'radialOrBias',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column5',
    name: 'Product cost',
    fieldName: 'factoryCost',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column6',
    name: 'Tread',
    fieldName: 'treadDepth',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column7',
    name: 'Effective date',
    fieldName: 'dateEffective',
    typeField: 'date',
    isEditable: false,
  },
];

type SortColumn = 'id' | 'tireSize' |
  'description' | 'origOrRtrd' | 'radialOrBias' |
  'factoryCost' | 'treadDepth' | 'updatedOn'

const getSortColumn = (column:SortColumn) => {
  return sortMapper[column];
};

const sortMapper = {
  'id': 'id',
  'tireSize': 'tireSize',
  'description': 'description',
  'origOrRtrd': 'origOrRtrd',
  'radialOrBias': 'radialOrBias',
  'factoryCost': 'factoryCost',
  'treadDepth': 'treadDepth',
  'updatedOn': 'updatedOn',
};

const emptyProduct: IAddProductItem = {
  productCode: '',
  country: '',
  tireSize: '',
  description: '',
  factoryCost: '',
  tireType: '',
  radialBias: '',
};

export {
  productHeadCell,
  getSortColumn,
  emptyProduct,
};