
import React from 'react';
import { Text } from '@fluentui/react';

const Error = () => {
  return (
    <div>
      <Text variant="xxLargePlus">Error: Page does not exist!</Text>
    </div>    
  );  

};

export default Error;