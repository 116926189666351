import { Dropdown, IconButton, IDropdownStyles, MessageBarType, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { debounce } from 'lodash';
import { FC, useEffect, useState } from 'react';
import AutocompleteInput from '../../../../../../../../../../shared/AutocompleteInput';
import { gdyrDelNoEMHandler } from '../../../../../../../../../../shared/TextFieldValidation';
import { IOrderDetailsProps } from './IOrderDetailsProps';
import styles from './OrderDetails.module.scss';
import apiService from '../../../../../../../../../../api';
import { IOrderDetailsState } from './IOrderDetailsState';
import useNotifications from '../../../../../../../../../../hooks/useNotifications';
import TypeCodeModalComponent from '../../../../../../../../../../shared/TypeCodeModalComponent';
import { useSelector } from 'react-redux';
import { customerSelector } from '../../../../../../../../../../redux/recordKeepingSlice';
import PlantModalComponent from '../../../../../../../../../../shared/PlantSelectionModalComponent';
import BrandModalComponent from '../../../../../../../../../../shared/BrandSelectionModalComponent';

const OrderDetails: FC<IOrderDetailsProps> = ({ 
  typeCodes,
  setTypeCodes,
  plants,
  setPlants,
  orderItemToBeAdded, 
  setOrderItemToBeAdded,
  parsedErrors,
  mode, 
}) => {
  const [state, setState] = useState<IOrderDetailsState>({
    loading: false,
    typeCodes: [],
    plants: [],
    batchNumbers: [],
    billToSundres: [],
  });

  const [contractBrandPrefix, setContractBrandPrefix] = useState('');  

  const [showTypeCodeModal, { toggle: toggleShowTypeCodeModal }] = useBoolean(false);
  const [showPlantModal, { toggle: toggleShowPlantModal }] = useBoolean(false);
  const [showBrandModal, { toggle: toggleShowBrandModal }] = useBoolean(false);

  const { addNotification } = useNotifications();
  const { id: customerId, corrAddressCountry: country } = useSelector(customerSelector);
  
  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 150 } };

  const fetchTypeCode = async (typeCode?: string) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.typeCodeAPI.getTypeCodeAuthList(
        null,
        {},
        { searchString: typeCode, originalOrRetread: 'O', country: country },
        customerId,
      );
      const items = data.data;
      setState((prev: any) => ({ ...prev, typeCodes: items }));
      setContractBrandPrefix(data.contractBrandPrefix);
      setTypeCodes(items);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching type codes error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  
  const fetchPlant = async (plant: string) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.plantWareHouseAPI.getAvailablePlants(
        null,
        { searchString: plant, type: 'WAREHOUSE', active: true },
        {},
      );
      const items = data.data;
      setState((prev: any) => ({ ...prev, plants: items }));
      setPlants(items);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching plants error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };


  const fetchBatchNumber = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.tireOrders.getBatchNumberList();
      setState((prev: any) => ({ ...prev, batchNumbers: data }));
      orderItemToBeAdded.batch || setOrderItemToBeAdded((prev: any) => ({ ...prev, batch: data[0] }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching batch numbers error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const selectBrandNumbers = async (brand: any) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.brandAPI.selectBrandNumber(
        brand,
      );
      setOrderItemToBeAdded((prev: any) => ({ 
        ...prev, 
        beginBrandPrefix: contractBrandPrefix, 
        beginBrandNumber: data.brandBegin,
        endBrandPrefix: contractBrandPrefix,
        endBrandNumber: data.brandEnd,
        quantity: data.quantity,
      }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: ` Brand Numbers selecting error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSelectTypeCode = (rowSelected: any) => {
    setOrderItemToBeAdded((prev: any) => ({ ...prev, typeCode: rowSelected[0].typeCode }));
    fetchTypeCode(rowSelected[0].typeCode);
    toggleShowTypeCodeModal();
  };

  const handleSelectPlant = (rowSelected: any) => {
    setOrderItemToBeAdded((prev: any) => ({ ...prev, warehouse: rowSelected.id, warehouseNo: rowSelected.plantNo }));
    fetchPlant(rowSelected.plantNo);
    toggleShowPlantModal();
  };

  const handleSelectBrand = (rowSelected: any) => {
    selectBrandNumbers(rowSelected);
    toggleShowBrandModal();
  };

  const typeCodeInputText = (typeCode: string) => {
    if (typeCode.length > 0) {
      getTypeCode(typeCode);
    }
  };

  const getTypeCode = debounce(async (typeCode) => {
    fetchTypeCode(typeCode);
  }, 1000);

  const plantInputText = (plant: string) => {
    if (plant.length > 0) {
      getPlant(plant);
    }
  };

  const getPlant = debounce(async (typeCode) => {
    fetchPlant(typeCode);
  }, 1000);

  const onChangeOrderItemField = (field: any, value: any, regExp?: RegExp) =>
    setOrderItemToBeAdded((prev: any) => ({ ...prev, [field]: regExp ? (regExp.test(value) ? value : prev[field]) : value }));

  const onChangeWarehouse = (id: any) => {
    const plant = state.plants.find((value: any) => value.id === id);
    setOrderItemToBeAdded((prev: any) => ({ ...prev, warehouse: plant.id, warehouseNo: plant.plantNo }));
  };

  const onChangeOrderItemAutocompleteField = (field: any, value: any) =>
    setOrderItemToBeAdded((prev: any) => ({ ...prev, [field]: value }));

  const parseErrors = (field: string) => {
    const customError = parsedErrors?.filter((error: { field: string; }) => error.field === field)[0];
    if (customError) {
      return 'Mandatory field is empty';
    }
  };

  useEffect(() => {
    setState((prev: any) => ({ ...prev, typeCodes: typeCodes, plants: plants }));
    fetchTypeCode();
    fetchBatchNumber();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div>
          <div>
            <TextField
              label="GDYR delivery note #"
              maxLength={10}
              value={orderItemToBeAdded.gdyrDelNo}
              onChange={(e, gdyrDelNo: any) => onChangeOrderItemField('gdyrDelNo', gdyrDelNo, /^[0-9]*$/)}
              onGetErrorMessage={gdyrDelNoEMHandler}
              disabled={mode === 'view' ? true : false}
            />
          </div>
          <div>
            <AutocompleteInput
              label="Type Code"
              initialValue={orderItemToBeAdded.typeCode}
              list={state.typeCodes.map((typeCode: any) => ({
                key: typeCode.id,
                text: typeCode.typeCode,
              }))}
              emptyExpanded
              required
              chooseCurrentItem={(typeCode: any) => onChangeOrderItemAutocompleteField('typeCode', typeCode)}
              textValue={typeCodeInputText}
              upperCase
              errorMessage={parseErrors('typeCode')}
              disabled={mode === 'view' ? true : false}
            /> 
            <IconButton
              id='searchPrefix'
              onClick={toggleShowTypeCodeModal}
              iconProps={{ iconName: 'Search' }}
              disabled={mode === 'view' ? true : false}
            />
          </div>
          <div>
            <Dropdown
              label="Batch"
              defaultSelectedKey={state.batchNumbers.findIndex((value) => value === orderItemToBeAdded.batch)}
              options={state.batchNumbers.map((batchNumber: any, index: number) => ({
                key: index,
                text: batchNumber,
              }))}
              onChange={(e, { text: batch }: any) => onChangeOrderItemField('batch', batch)}
              styles={dropdownStyles}
              disabled={mode === 'view' ? true : false}
            />
          </div>
        </div>
        <div>
          <div>
            <TextField
              label="QTY"
              value={orderItemToBeAdded.quantity}
              disabled
            />
          </div>
          <div>
            <AutocompleteInput
              label="Warehouse"
              initialValue={orderItemToBeAdded.warehouseNo}
              list={state.plants.map((plant: any) => ({
                key: plant.id,
                text: plant.plantNo,
              }))}
              emptyExpanded
              required
              chooseCurrentItem={(warehouse: any) => onChangeWarehouse(warehouse)}
              textValue={plantInputText}
              upperCase
              errorMessage={parseErrors('warehouse')}
              disabled={mode === 'view' ? true : false}
            /> 
            <IconButton
              id="searchPrefix"
              onClick={toggleShowPlantModal}
              iconProps={{ iconName: 'Search' }}
              disabled={mode === 'view' ? true : false}
            />
          </div>
          <div>
            <TextField  
              id="requestedDeliveryDate"
              label="Rqst Dlv Date"
              type="date"
              onChange={(e, requestedDeliveryDate: any) => onChangeOrderItemField('requestedDeliveryDate', requestedDeliveryDate)}
              value={orderItemToBeAdded.requestedDeliveryDate}
              required
              errorMessage={parseErrors('requestedDeliveryDate')}
              disabled={mode === 'view' ? true : false}
            />
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <TextField
              label="Prefix begin"
              maxLength={4}
              value={orderItemToBeAdded.beginBrandPrefix}
              disabled
            />
          </div>
          <div>
            <TextField
              label="Brand begin"
              maxLength={6}
              value={orderItemToBeAdded.beginBrandNumber}
              disabled
            />
          </div>
          <div>
            <TextField
              label="Suffix begin"
              maxLength={5}
              value={orderItemToBeAdded.beginBrandSuffix}
              disabled
            />
          </div>
          <div className={styles.searchButton}>
            <IconButton
              id='searchPrefix'
              onClick={toggleShowBrandModal}
              iconProps={{ iconName: 'Search' }}
              disabled={mode === 'view' ? true : false}
            />
          </div>
        </div>
        <div>
          <div>
            <TextField
              label="Prefix end"
              maxLength={4}
              value={orderItemToBeAdded.endBrandPrefix}
              disabled
            />
          </div>
          <div>
            <TextField
              label="Brand end"
              maxLength={6}
              value={orderItemToBeAdded.endBrandNumber}
              disabled
            />
          </div>
          <div>
            <TextField
              label="Suffix end"
              maxLength={5}
              value={orderItemToBeAdded.endBrandSuffix}
              disabled
            />
          </div>
          <div></div>
        </div>
      </div>
      <TypeCodeModalComponent
        id='typeCodeModal'
        title='Type Codes'
        isOpen={showTypeCodeModal}
        onSubmit={handleSelectTypeCode}
        onDismiss={toggleShowTypeCodeModal}
        successLabel='Select Type Code'
        cancelLabel='Cancel'
        originalOrRetread='O'
        isUserDependent
      />
      <PlantModalComponent
        id='wareHouseModal'
        title='View Warehouse Details'
        type='WAREHOUSE'
        isOpen={showPlantModal}
        onSubmit={handleSelectPlant}
        onDismiss={toggleShowPlantModal}
      />
      <BrandModalComponent
        isOpen={showBrandModal}
        onSubmit={handleSelectBrand}
        onDismiss={toggleShowBrandModal}
        brandPrefix={contractBrandPrefix}
      />
    </>
  );
};

export default OrderDetails;