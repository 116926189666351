import { recordTestInstance, tireOrderingTestInstance, exportTestInstance } from '../axios';

const get = (pagination: any, sortOrder: any, customerId: number, locationId: number, filters: any = { milesTo: Number.MAX_SAFE_INTEGER } ) =>
  recordTestInstance.post('/oos-tire', { pagination, filters: { ...filters, milesFrom: null, searchString: null}, sortOrder, customerId, locationId });

const getErrors = (pagination: any, sortOrder: any, customerId: string, locationId: string) =>
  recordTestInstance.post('/oos-tire/error', { pagination, sortOrder, customerId, locationId });

const add = (data: any, customerId: string, locationId: string) =>
  recordTestInstance.post('/oos-tire/add', { ...data, customerId, locationId });

const update = (data: Array<any>, customerId: string, locationId: string) =>
  recordTestInstance.put('/oos-tire/save', { data, customerId, locationId });

const submit = (data: Array<any>, customerId: string, locationId: string) =>
  recordTestInstance.post('/oos-tire/submit', { data, customerId, locationId });

const deletePostOosTires = (ids: Array<any>) =>
  recordTestInstance.delete('/oos-tire/delete', { data: { ids } });

const getDisposition = () =>
  recordTestInstance.get('/disposition');

const getPlants = (filters: any, pagination = null , sortOrder = null) => tireOrderingTestInstance.post('/plant', { filters, pagination, sortOrder });

const printExcel = (input: any) =>
  exportTestInstance.post('/oos-tire/export-excel', { input }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdf = (input: any) =>
  exportTestInstance.post('/oos-tire/export-pdf', { input }, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

export default {
  get,
  getErrors,
  add,
  update,
  submit,
  delete: deletePostOosTires,
  getDisposition,
  getPlants,
  printExcel,
  printPdf,
};
