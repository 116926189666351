import { DefaultButton, Dialog, DialogFooter, Dropdown, IColumn, IconButton, IDropdownOption, MessageBarType, Pivot, PivotItem, PrimaryButton, Text, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { get, isNil, isNull, isString } from 'lodash';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import apiService from '../../../../../../api';
import { sortOrder } from '../../../../../../consts/sortOrder';
import useNotifications from '../../../../../../hooks/useNotifications';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';
import Pagination from '../../../../../Pagination/Pagination';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import { addingTabs, defaultAddingFields, defaultFilters, pageSizes, plantsColumns } from './consts';
import { IPlant } from './IPlant';
import { IRetreadProps } from './IRetreadProps';
import { IRetreadState } from './IRetreadState';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_maintainPlants_retread } from '../../../../../../consts/programKeys';
import styles from './Retread.module.scss';

const Retread: FC<IRetreadProps> = (): ReactElement => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_maintainPlants_retread);

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const { addNotification } = useNotifications();

  const [state, setState] = React.useState<IRetreadState>({
    items: [],
    selectedItems: [],
    states: [],
    countries: [],
    loading: false,
    foundCount: 0,
  });

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });

  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(plantsColumns);
  const [filters, setFilters] = useState<any>(defaultFilters);
  const [addingFields, setAddingFields] = useState<IPlant>(defaultAddingFields);
  const [errorMessages, setErrorMessages] = useState<IPlant>(defaultAddingFields);
  const [selectedKey, setSelectedKey] = useState('rectangleRed');
  const [addedFieldId, setAddedFieldId] = useState(null);
  const [selectedInitialPlantNumber, setInitialSelectedPlantNumber] = useState('');

  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);

  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const handleSelect = (e: any, item: IPlant) => {
    const selectedRows = [...state.selectedItems];
    if (e.target.checked) {
      selectedRows.push(item);
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows }));
      setErrorMessages(defaultAddingFields);
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows.filter(row => row.id !== item.id) }));
    }
  };
  const handleSelectAll = (e: any, items: Array<any>) => {
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedItems: items })) : setState((prev: any) => ({ ...prev, selectedItems: [] }));
  };

  const getSortOrder = () => {
    const column = columnsState.find(({ isSorted }) => isSorted);
    if (isNil(column))
      return {
        column: 'lastmodifiedon',
        order: sortOrder.DESC,
      };
    const { fieldName, isSortedDescending } = column;
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  const onChangeAddField = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | number | undefined, field: string, errorMessage: string) => {
    setAddingFields(prev => ({ ...prev, [field]: value }));
    if (value || value === 0) {
      setErrorMessages(prev => ({ ...prev, [field]: '' }));
    }
    else {
      setErrorMessages(prev => ({ ...prev, [field]: errorMessage }));
    }
  };

  const shouldDisableAddUpdateButton = () => {
    return !(addingFields.plantNo
      && addingFields.name
      && addingFields.type
      && addingFields.city
      && !isNull(addingFields.state)
      && addingFields.addrLine1
      && !isNull(addingFields.country)
      && addingFields.zip);
  };

  const handleClearAddingFields = (defaultAddingFields: IPlant) => {
    setAddingFields(defaultAddingFields);
    if (state.states) {
      setState(prev => ({ ...prev, states: [] }));
    }
  };

  const parseAddingFieldsErrors = (errors: any[]) => {
    let parsedAddingFieldsErrors = {};
    errors.forEach(el => {
      parsedAddingFieldsErrors = {
        ...parsedAddingFieldsErrors,
        [el.field]: el.message,
      };
    });

    setErrorMessages(prev => ({ ...prev, ...parsedAddingFieldsErrors }));
  };

  const fetchCountries = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.getCountries();
      setState((prev: any) => ({ ...prev, countries: get(data, 'countries', []) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching countries error: ${get(response, 'data.message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchStates = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.getStates(get(state, `countries[${addingFields.country}]`));
      await setState((prev: any) => ({ ...prev, states: get(data, 'states', []) }));
      if (data.states.length && isString(addingFields.state)) {
        setAddingFields(prev => ({
          ...prev,
          state: data.states.findIndex((el: any) => el === prev.state),
        }));
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching states error: ${get(response, 'data.message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const addPlant = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.plantWareHouseAPI.addPlant({
        ...addingFields,
        country: get(state, `countries[${addingFields.country}]`),
        state: get(state, `states[${addingFields.state}]`),
      });
      setAddedFieldId(data.id);
      setAddingFields(defaultAddingFields);
      fetchPlants();
      addNotification({
        text: 'Plant was successfully added.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          parseAddingFieldsErrors(response.data.state);
          return addNotification({
            text: 'Unable to add & validate changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Adding plant error: ${response?.data?.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const updatePlant = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const newPlantNo = selectedInitialPlantNumber !== addingFields.plantNo ? addingFields.plantNo : '';
      await apiService.plantWareHouseAPI.updatePlant({
        ...addingFields,
        country: get(state, `countries[${addingFields.country}]`),
        state: get(state, `states[${addingFields.state}]`),
        newPlantNo: newPlantNo,
      });
      setAddingFields(defaultAddingFields);
      fetchPlants();
      addNotification({
        text: 'Plant was successfully updated.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          parseAddingFieldsErrors(response.data.state);
          return addNotification({
            text: 'Unable to update & validate changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Updating plant error: ${response?.data?.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    setState(prev => ({ ...prev, loading: true }));
    try {
      await apiService.maintainOEMsAPI.deleteOEMs(state.selectedItems.map(el => el.id as string));
      fetchPlants();
      setState(prev => ({ ...prev, selectedItems: [] }));
      addNotification({
        text: 'Retread successfully deleted.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Retread deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchPlants = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.plantWareHouseAPI.getAvailablePlants(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        filters,
        sortOrder,
      );
      const foundCount = data.total.found;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching plants error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const filters = {
        type: 'RETREAD',
      };

      const requestData = {
        data: null,
        headerFields: [],
        filters: filters,
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.plantWareHouseAPI.printExcel(requestData) :
        await apiService.plantWareHouseAPI.printPdf(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
      setState(prev => ({ ...prev, loading: false }));
      toggleShowPrintExport();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    }
  };

  useEffect(() => {
    fetchPlants();
  }, [
    paginationProps.current,
    countOnPage,
    columnsState,
  ]);

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (get(state, `countries[${addingFields.country}]`)) {
      fetchStates();
    }
  }, [addingFields.country]);

  useEffect(() => {
    if (state.selectedItems.length !== 1) {
      setAddingFields(defaultAddingFields);
      setInitialSelectedPlantNumber('');
      if (state.states) {
        setState(prev => ({ ...prev, states: [] }));
      }
    }
    else {
      const selectedPlant = state.items.find(({ id }) => id === state.selectedItems[0].id) || defaultAddingFields;
      setInitialSelectedPlantNumber(selectedPlant.plantNo);
      setAddingFields({
        ...selectedPlant,
        country: state.countries.findIndex(el => el === selectedPlant.country),
      });
    }
  }, [state.selectedItems]);

  return (
    <>
      <Text variant="xLarge" className={styles.highlight}>Maintain Plants - Create/Update Retread Details</Text>
      <SeparatorGy />
      <div className={styles.actions}>
        <div className={styles.filters}>
          <Text variant="xLarge" className={styles.highlight}>Filter By</Text>
          <SeparatorGy />
          <div className={styles.filterFields}>
            <TextField
              className={styles.filterField}
              label="Close To"
              value={filters.closeTo}
              onChange={(_ev, value) => setFilters((prev: any) => ({ ...prev, closeTo: value }))}
            />
            <TextField
              className={styles.filterField}
              label="Name"
              value={filters.name}
              onChange={(_ev, value) => setFilters((prev: any) => ({ ...prev, name: value }))}
            />
            <TextField
              className={styles.filterField}
              label="City"
              value={filters.city}
              onChange={(_ev, value) => setFilters((prev: any) => ({ ...prev, city: value }))}
            />
          </div>
          <div className={styles.filtersActions}>
            <DefaultButton
              id="applyButton"
              text="Apply"
              onClick={fetchPlants}
              disabled={!userPermissions.isWrite}
            />
          </div>
        </div>
        <div className={styles.adding}>
          <Pivot
            aria-label="Separately Rendered Content Pivot Example"
            selectedKey={selectedKey}
            onLinkClick={handleLinkClick}
            getTabId={getTabId}
            linkFormat="tabs"
            linkSize="normal"
          >
            <PivotItem headerText={addingTabs.general} itemKey={addingTabs.general} >
              <div className={styles.addingFields}>
                <TextField
                  className={styles.addingField}
                  label="Plant #"
                  value={addingFields.plantNo}
                  onChange={(ev, value) => onChangeAddField(ev, value, 'plantNo', 'Please enter Plant #')}
                  onBlur={(ev) => !ev.target.value && setErrorMessages(prev => ({ ...prev, plantNo: 'Please enter Plant #' }))}
                  required
                  errorMessage={errorMessages.plantNo}
                />
                <TextField
                  className={classNames(styles.addingField, styles.longAddingField)}
                  label="Plant Name"
                  value={addingFields.name}
                  onChange={(ev, value) => onChangeAddField(ev, value, 'name', 'Please enter Plant Name')}
                  onBlur={(ev) => !ev.target.value && setErrorMessages(prev => ({ ...prev, name: 'Please enter Plant Name' }))}
                  required
                  errorMessage={errorMessages.name}
                />
                <TextField
                  className={styles.addingField}
                  label="Type"
                  value={addingFields.type}
                  onChange={(ev, value) => onChangeAddField(ev, value, 'type', 'Please enter Type')}
                  onBlur={(ev) => !ev.target.value && setErrorMessages(prev => ({ ...prev, type: 'Please enter Type' }))}
                  required
                  disabled
                  errorMessage={errorMessages.type === 'RETREAD' ? '' : errorMessages.type}
                />
                <TextField
                  className={styles.addingField}
                  label="City"
                  value={addingFields.city}
                  onChange={(ev, value) => onChangeAddField(ev, value, 'city', 'Please enter City')}
                  onBlur={(ev) => !ev.target.value && setErrorMessages(prev => ({ ...prev, city: 'Please enter City' }))}
                  required
                  errorMessage={errorMessages.city}
                />
                <Dropdown
                  className={styles.addingField}
                  label="State"
                  options={state.states.map((state, index) => ({
                    key: index,
                    text: state,
                  }))
                  }
                  disabled={!state.states.length}
                  selectedKey={addingFields.state}
                  onChange={(ev: any, option) => onChangeAddField(ev, option?.key, 'state', 'Please enter State')}
                  onBlurCapture={() => isNull(addingFields.state) && setErrorMessages(prev => ({ ...prev, state: 'Please enter State' }))}
                  errorMessage={errorMessages.state as string}
                  required
                />
              </div>
              <div className={styles.addingFields}>
                <TextField
                  className={classNames(styles.addingField, styles.longAddingField)}
                  label="Address Line 1"
                  value={addingFields.addrLine1}
                  onChange={(ev, value) => onChangeAddField(ev, value, 'addrLine1', 'Please enter Address Line 1')}
                  onBlur={(ev) => !ev.target.value && setErrorMessages(prev => ({ ...prev, addrLine1: 'Please enter Address Line 1' }))}
                  required
                  errorMessage={errorMessages.addrLine1}
                />
                <TextField
                  className={classNames(styles.addingField, styles.longAddingField)}
                  label="Address Line 2"
                  value={addingFields.addrLine2}
                  onChange={(_ev, value) => setAddingFields((prev: any) => ({ ...prev, addrLine2: value }))}
                />
                <Dropdown
                  className={styles.addingField}
                  label="Country"
                  options={state.countries.map((country, index) => ({
                    key: index,
                    text: country,
                  }))
                  }
                  selectedKey={addingFields.country}
                  onChange={(ev: any, option) => onChangeAddField(ev, option?.key, 'country', 'Please enter Country')}
                  onBlurCapture={() => isNull(addingFields.country) && setErrorMessages(prev => ({ ...prev, country: 'Please enter Country' }))}
                  errorMessage={errorMessages.country as string}
                  required
                />
                <TextField
                  className={styles.addingField}
                  label="Zip/Postal Code"
                  value={addingFields.zip}
                  onChange={(ev, value) => onChangeAddField(ev, value, 'zip', 'Please enter Zip/Postal Code')}
                  onBlur={(ev) => !ev.target.value && setErrorMessages(prev => ({ ...prev, zip: 'Please enter Zip/Postal Code' }))}
                  required
                  errorMessage={errorMessages.zip}
                />
              </div>
              <div className={styles.addingFields}>
                <TextField
                  className={styles.addingField}
                  label="Contact"
                  value={addingFields.contactName}
                  onChange={(_ev, value) => setAddingFields((prev: any) => ({ ...prev, contactName: value }))}
                />
                <TextField
                  className={styles.addingField}
                  label="Phone 1"
                  value={addingFields.phone1}
                  onChange={(_ev, value) => setAddingFields((prev: any) => ({ ...prev, phone1: value }))}
                />
                <TextField
                  className={styles.addingField}
                  label="Phone 2"
                  value={addingFields.phone2}
                  onChange={(_ev, value) => setAddingFields((prev: any) => ({ ...prev, phone2: value }))}
                />
                <TextField
                  className={styles.addingField}
                  label="Email"
                  value={addingFields.email}
                  onChange={(ev, value) => onChangeAddField(ev, value, 'email', 'Please enter Email')}
                  onBlur={(ev) => !ev.target.value && setErrorMessages(prev => ({ ...prev, email: 'Please enter Email' }))}
                />
                <TextField
                  className={styles.addingField}
                  label="Fax"
                  value={addingFields.fax}
                  onChange={(_ev, value) => setAddingFields((prev: any) => ({ ...prev, fax: value }))}
                />
              </div>
            </PivotItem>
            <PivotItem headerText={addingTabs.comments} itemKey={addingTabs.comments} >
              <div className={styles.commentsAddingFields}>
                <TextField
                  className={styles.addingField}
                  label="Close To"
                  multiline
                  rows={8}
                  cols={66}
                  value={addingFields.closeTo}
                  onChange={(_ev, value) => setAddingFields((prev: any) => ({ ...prev, closeTo: value }))}
                />
                <TextField
                  className={styles.addingField}
                  label="Comments"
                  multiline
                  rows={8}
                  cols={66}
                  value={addingFields.comments}
                  onChange={(_ev, value) => setAddingFields((prev: any) => ({ ...prev, comments: value }))}
                />
              </div>
            </PivotItem>
          </Pivot>
          <SeparatorGy />
          <div className={styles.actionsButtons}>
            <DefaultButton
              id="clearButton"
              className={styles.actionButton}
              text="Clear"
              onClick={() => handleClearAddingFields({ ...addingFields, ...defaultAddingFields })}
              disabled={!userPermissions.isWrite}
            />
            <PrimaryButton
              id="updateButton"
              className={styles.actionButton}
              onClick={updatePlant}
              text="Update"
              disabled={(shouldDisableAddUpdateButton() || state.selectedItems.length !== 1) || !userPermissions.isWrite}
            />
            <PrimaryButton
              id="addButton"
              className={styles.actionButton}
              onClick={addPlant}
              text="+ Add"
              disabled={(shouldDisableAddUpdateButton() || state.selectedItems.length > 0) || !userPermissions.isWrite}
            />
          </div>
        </div>
      </div>
      <div className={styles.tableHeading}>
        <div>
          <Text variant="xLarge" className={styles.highlight}>Available Plants</Text>
          <SeparatorGy vertical />
          <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
        </div>
        <div>
          <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
          <Dropdown
            options={pageSizes.map(pageSize => ({
              key: pageSize,
              text: pageSize.toString(),
            }))}
            selectedKey={countOnPage?.key}
            onChange={onChangeCountOnPage}
          />
          <SeparatorGy vertical />
          <Text variant="large" className={styles.highlight}>{state.selectedItems.length} items selected</Text>
          <SeparatorGy vertical />
          <IconButton
            id="toggleDeletingConfirmationButton"
            disabled={!state.selectedItems.length || !userPermissions.isWrite}
            iconProps={{ iconName: 'Delete' }}
            onClick={toggleDeletingConfirmation}
          />
        </div>
      </div>
      <div className={styles['table-wrapper']}>
        <table className={styles.availablePlantsTable}>
          <thead>
            <tr>
              {
                columnsState.map(item => (
                  <th
                    key={item.name}
                    onClick={() => onColumnClick(item)}>
                    <div className={classNames(
                      item.isSorted && item.isSortedDescending ?
                        styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined,
                      styles.resizable,
                    )}>
                      {item.name}
                    </div>
                  </th>
                ))
              }
              <th className={styles.selectColumn}>
                <div className={styles.round}>
                  <input
                    type="checkbox"
                    id="all"
                    checked={state.items.length !== 0 &&
                      (state.selectedItems.length === +countOnPage.key || state.selectedItems.length === state.items.length)}
                    onChange={(e) => handleSelectAll(e, state.items.map(item => item))}
                  />
                  <label htmlFor="all"></label>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {state.items.map(item => (
              <tr
                key={item.id}
                className={classNames(state.selectedItems.find(el => el.id === item.id) ? styles.trSelected : item.id === addedFieldId ? styles.trAdded : styles.trBasic)}
                onMouseOut={() => item.id === addedFieldId && setAddedFieldId(null)}
              >
                <td>{item.plantNo}</td>
                <td>{item.name}</td>
                <td>{item.addrLine1}</td>
                <td>{item.addrLine2}</td>
                <td>{item.city}</td>
                <td>{item.state}</td>
                <td>{item.country}</td>
                <td>{item.zip}</td>
                <td>{item.phone1}</td>
                <td>{item.fax}</td>
                <td>{item.contactName}</td>
                <td>{item.email}</td>
                <td>
                  <div className={styles.round}>
                    <input
                      type="checkbox"
                      id={`availablePlants-${item.id}`}
                      checked={!!state.selectedItems.find(el => el.id === item.id)}
                      onChange={(e) => handleSelect(e, item)} />
                    <label htmlFor={`availablePlants-${item.id}`}></label>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <SeparatorGy />
      <Pagination {...paginationProps} />
      <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
        <DefaultButton onClick={toggleShowPrintExport} text="Print/Export" />
      </div>
      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        maxWidth="initial"
        title={`Are you sure you want to delete the following ${state.selectedItems.length} plant(s)?`}
        modalProps={{ isBlocking: true }}>
        <table className={styles.availablePlantsTable}>
          <tbody>
            {state.selectedItems.map(item => (
              <tr key={item.id} className={styles.trBasic} >
                <td>{item.plantNo}</td>
                <td>{item.name}</td>
                <td>{item.addrLine1}</td>
                <td>{item.addrLine2}</td>
                <td>{item.city}</td>
                <td>{item.state}</td>
                <td>{item.country}</td>
                <td>{item.zip}</td>
                <td>{item.phone1}</td>
                <td>{item.fax}</td>
                <td>{item.contactName}</td>
                <td>{item.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <DialogFooter>
          <DefaultButton
            onClick={toggleDeletingConfirmation}
            text="No, cancel" />
          <PrimaryButton id="deleteButton"
            onClick={handleDelete}
            text="Yes, delete" />
        </DialogFooter>
      </Dialog>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default Retread;
