import { FC, FormEvent, ReactElement, useEffect, useState } from 'react';
import moment from 'moment';
import { Dropdown, IColumn, IconButton, IDropdownOption, MessageBarType, Modal, Text } from '@fluentui/react';
import { useSelector } from 'react-redux';

import useNotifications from '../../hooks/useNotifications';
import { customerInformationSelector, customerSelector, locationSelector } from '../../redux/recordKeepingSlice';

import LoadingScreen from '../LoadingScreen/LoadingScreen';
import SeparatorGy from '../SeparatorGy/SeparatorGy';
import Pagination from '../Pagination/Pagination';
import { IPaginationProps } from '../Pagination/IPaginationProps';

import { columns, pageSizes } from './consts';
import { IErrorsModalProps } from './IErrorsModalProps';
import { IErrorsModalState } from './IErrorsModalState';

import styles from './ErrorsModal.module.scss';
import { sortOrder } from '../../consts/sortOrder';
import classNames from 'classnames';
import DialogComponent from '../../shared/DialogComponent';
import { useBoolean } from '@fluentui/react-hooks';

const ErrorsModal: FC<IErrorsModalProps> = ({
  isModalOpen,
  hideModal,
  fetchErrorsService,
  searchError,
  customer,
  location,
  shouldConfirm,
  enableSelectError = false,
}): ReactElement => {
  const { id: customerId, customerName } = customer || useSelector(customerSelector);
  const { id: locationId } = location || useSelector(locationSelector);
  const { addNotification } = useNotifications();
  const [state, setState] = useState<IErrorsModalState>({
    items: [],
    loading: false,
    foundCount: 0,
  });

  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [selectedKey, setSelectedKey] = useState<string | null | undefined>(null);
  const { lastMileageRun } = useSelector(customerInformationSelector) || '';

  const [showSubmitConfirmation, { toggle: toggleSubmitConfirmation }] = useBoolean(false);
  const [confirmationTitle, setConfirmationTitle] = useState<string>('');

  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const fetchErrors = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await fetchErrorsService(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
        lastMileageRun,
      );
      const foundCount = data.total.found;
      const items = data.data.map(({ date, ...other }: any) => ({ date: moment(date).format('MM/DD/YYYY'), ...other }));
      setState((prev: any) => ({ ...prev, items, foundCount }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Errors fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleSelect = (item: any) => {
    setSelectedKey(item.postTableId);
    if (shouldConfirm && item.type === 'PRMT') {
      setConfirmationTitle(item.description);
      toggleSubmitConfirmation();
    }
    else {
      searchError(item.postTableId);
      hideModal();
    }
  };

  const handleSubmitConfirmation = (submitted: boolean) => {
    searchError(selectedKey, submitted);
    setSelectedKey('');
    toggleSubmitConfirmation();
    hideModal();
  };

  useEffect(() => {
    if (isModalOpen)
      fetchErrors();
  }, [
    paginationProps.current,
    countOnPage,
    columnsState,
    isModalOpen,
  ]);

  return <Modal
    isOpen={isModalOpen}
    onDismiss={hideModal}
    isBlocking={false}
    containerClassName={styles.modalContainer}
  >
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <Text variant="xLarge">Errors - {customerName}</Text>
        <IconButton
          iconProps={{ iconName: 'Cancel' }}
          ariaLabel="Close popup modal"
          onClick={hideModal}
        />
      </div>
      <div className={styles.tableHeader}>
        <Text variant="large" block>Errors</Text>
        <div>
          <Text variant="large">Show # of rows:&nbsp;</Text>
          <Dropdown
            options={pageSizes.map(pageSize => ({
              key: pageSize,
              text: pageSize.toString(),
            }))}
            defaultSelectedKey={pageSizes[0]}
            selectedKey={countOnPage?.key}
            onChange={onChangeCountOnPage}
          />
          <SeparatorGy vertical />
          <Text variant="large">{state.foundCount} Errors Identified</Text>
        </div>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              {
                columnsState.map(item => (
                  <th
                    key={item.name}
                    className={classNames(
                      item.isSorted && item.isSortedDescending ?
                        styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined,
                      styles[item.key],
                    )}
                    onClick={() => onColumnClick(item)}>
                    {item.name}
                  </th>
                ))
              }
              {(enableSelectError || searchError) && <th></th>}
            </tr>
          </thead>
          <tbody>
            {
              state.items.map(item => (
                <tr
                  key={item.postTableId}
                >
                  <td>{item.type}</td>
                  <td>{item.description}</td>
                  <td>{item.additionalInfo}</td>
                  <td>{moment(item.date).format('MM/DD/YYYY')}</td>
                  <td>{item.time}</td>
                  {(enableSelectError || searchError) && <td>
                    <div className={styles.round}>
                      <input type="checkbox" id={`${item.postTableId}-${item.postTableId}`} checked={selectedKey === item.postTableId} onChange={() => handleSelect(item)} />
                      <label htmlFor={`${item.postTableId}-${item.postTableId}`}></label>
                    </div>
                  </td>}
                </tr>
              ))
            }
          </tbody>
        </table>
        <SeparatorGy />
        <Pagination {...paginationProps} />
      </div>
      {state.loading && <LoadingScreen />}
      <DialogComponent
        key='submitConfirmationDialog'
        isOpen={showSubmitConfirmation}
        onCancel={() => handleSubmitConfirmation(false)}
        onSubmit={() => handleSubmitConfirmation(true)}
        title='Confirmation'
        subText={confirmationTitle}
        onCancelLabel='No'
        onSubmitLabel='Yes'
      />
    </div>
  </Modal>;
};

export default ErrorsModal;
