import { HeadCell } from '../../../DataGridComponent/DataGridModels';
import { IFormDataField } from '../../../FormBuilderComponent';

export interface ProductModalComponentProps {  
  id: string;
  title?: string;
  fleetId: string;
  isOpen: boolean;
  onDismiss: () => void;
  cancelLabel: string;
  permissionKey: string;
  parentComponentName: string;
}



export const fleetsTableHeadCells: HeadCell[] = [
  {
    key: 'column0',
    typeField: 'text',
    isEditable: false,
    name: 'Vehicle Make',
    fieldName: 'vehicleMake',
  },
  {
    key: 'column1',
    typeField: 'text',
    isEditable: false,
    name: 'Vehicle Model',
    fieldName: 'vehicleModel',
  },
  {
    key: 'column2',
    typeField: 'text',
    isEditable: false,
    name: 'Number of Wheels',
    fieldName: 'numberOfWheels',
  },
  {
    key: 'column3',
    typeField: 'text',
    isEditable: false,
    name: 'Number of Vehicle Units',
    fieldName: 'numberOfVehicleUnits',
  },
  {
    key: 'column4',
    typeField: 'text',
    isEditable: false,
    name: 'Monthly Vehicle Units',
    fieldName: 'monthlyVehicleUnits',
  },
  {
    key: 'column5',
    typeField: 'text',
    isEditable: false,
    name: 'Quote ID',
    fieldName: 'quoteId',
  },
  {
    key: 'column6',
    typeField: 'text',
    isEditable: false,
    name: 'Contract ID',
    fieldName: 'contractId',
  },
];

export const PRODUCTS_GROUPS_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'productCodeField',
    properties: {
      id: 'productCode',
      label: 'Product Code',
      name: 'productCode',
      type: 'text',
      disabled: false,
      required: true,
    },
  },
  {
    formFieldName: 'descriptionField',
    properties: {
      id: 'description',
      label: 'Product Description',
      name: 'description',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'tireSizeField',
    properties: {
      id: 'tireSize',
      label: 'Tire Size',
      name: 'tireSize',
      type: 'text',
      disabled: true,
    },
  },
  {
    formFieldName: 'bogeyField',
    properties: {
      id: 'bogey',
      label: 'Bogey',
      name: 'bogey',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'usageField',
    properties: {
      id: 'percUsage',
      label: 'Usage',
      name: 'percUsage',
      type: 'text',
      disabled: false,
    },
  },
];
  

export const productTableHeadCells: HeadCell[] = [
  {
    key: 'column0',
    typeField: 'text',
    isEditable: false,
    name: 'Product Code',
    fieldName: 'productCode',
  },
  {
    key: 'column1',
    typeField: 'text',
    isEditable: false,
    name: 'Product Description',
    fieldName: 'description',
  },
  {
    key: 'column2',
    typeField: 'text',
    isEditable: false,
    name: 'Tire Size',
    fieldName: 'tireSize',
  },
  {
    key: 'column3',
    typeField: 'text',
    isEditable: false,
    name: 'Bogey',
    fieldName: 'bogey',
  },
  {
    key: 'column4',
    typeField: 'text',
    isEditable: false,
    name: 'Usage',
    fieldName: 'percUsage',
  },
];