import { DatePicker, DefaultButton, Dialog, DialogFooter, DialogType, MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { get, isNil } from 'lodash';
import moment from 'moment';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import apiService from '../../../../../../api';
import useNotifications from '../../../../../../hooks/useNotifications';
import { customerSelector } from '../../../../../../redux/recordKeepingSlice';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import { columns } from './consts';
import { IReturnToInTransitProps } from './IReturnToInTransitProps';
import { IReturnToInTransitState } from './IReturnToInTransitState';
import styles from './ReturnToInTransit.module.scss';
import DataGridComponent from '../../../../../../shared/DataGridComponent';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_tireOrder_moveToInTransit } from '../../../../../../consts/programKeys';
import { tireOrderManagementPageSizes } from '../../consts';

const ReturnToInTransit: FC<IReturnToInTransitProps> = (): ReactElement => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_tireOrder_moveToInTransit);

  const { addNotification } = useNotifications();
  const { regionCode, id: customerId } = useSelector(customerSelector);

  const [state, setState] = useState<IReturnToInTransitState>({
    items: [],
    selectedItems: [],
    foundCount: 0,
    loading: false,
  });

  const [defaultCountOnPage, setDefaultCountOnPage] = useState({});
  const [defaultPaginationProps, setDefaultPaginationProps] = useState<any>({ pageSize: tireOrderManagementPageSizes[0], pageNumber: 1 });
  const [defaultSortOrder, setDefaultSortOrder] = useState({});

  const [addingFields, setAddingFields] = useState<any>({});

  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);

  const [isWarningDialogVisible, { toggle: toggleWarningConfirmation }] = useBoolean(false);
  const [isFirstLoading, { setFalse: wasFirstLoading }] = useBoolean(true);

  const onAddSeriesFieldChange = (selectionType: string, selectionField: string, value: any) => {
    setAddingFields((prev: any) => ({ ...prev, [selectionType]: { ...prev[selectionType], [selectionField]: value } }));
  };
  const handleSelect = (items: any) => {
    state.selectedItems = items.map((item: any) => Number(item.id));
  };

  const handleAdd = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.inTransitTiresAPI.addMoveToInIntransitTire({
        customerId,
        bpfx: addingFields.beginBrand?.prefix,
        beginBno: addingFields.beginBrand?.brandNo,
        bsfx: addingFields.beginBrand?.suffix,
        endBno: addingFields.endBrand?.brandNo,
        moveToIntransitRange: addingFields.endBrand?.brandNo ? 1 : 0,
      });
      await fetchTires();
      addNotification({
        text: 'Tire was successfully added.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Tires adding error: ${response?.data?.state[0]?.message}`,
        type: MessageBarType.error,
      });
    }
    finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleSubmit = async (confirmed = false) => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.inTransitTiresAPI.submitMoveToInIntransitTire(
        confirmed,
        state.selectedItems.map((item: any) => Number(item)),
      );
      await fetchTires();
      addNotification({
        text: 'Tire was successfully submitted.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 409:
          return response.data.warning ? toggleWarningConfirmation() : undefined;
        default:
          addNotification({
            text: `Tires submitting error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    }
    finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleDelete = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.inTransitTiresAPI.deleteMoveToInIntransitTires(state.selectedItems);
      await fetchTires();
      addNotification({
        text: 'Selected item(s) were successfully deleted.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Tires deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
    finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchTires = async (data: any = { countOnPage: defaultCountOnPage, paginationProps: defaultPaginationProps, sortOrder: defaultSortOrder }) => {
    const { countOnPage, paginationProps, sortOrder } = data;
    setDefaultCountOnPage(countOnPage);
    setDefaultSortOrder(sortOrder);
    setDefaultPaginationProps(paginationProps);
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.inTransitTiresAPI.getMoveToInIntransitTires(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        { customerId },
        sortOrder,
      );
      const foundCount = data.total.found;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      wasFirstLoading();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Tires fetching error: ${response?.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const headerFields = [
        { title: 'customerId', value: customerId },
        { title: 'locationId', value: regionCode },
      ];

      const requestData = {
        paginationProps: defaultPaginationProps,
        filters: { customerId },
        sortOrder: defaultSortOrder,
        headerFields: headerFields,
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.inTransitTiresAPI.printExcel(requestData) :
        await apiService.inTransitTiresAPI.printPdf(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Printing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (!isFirstLoading) fetchTires();
  }, [customerId]);

  return (
    <>
      <div className={styles.actions}>
        <DatePicker
          label="Incident Date"
          value={moment().toDate()}
          formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
          disabled={true}
        />
        <div className={styles.addSeries}>
          <Text variant="xLarge" className={styles.highlight}>Add a series</Text>
          <SeparatorGy />
          <div className={styles.selectSeries}>
            <div>
              <Text variant="xLarge" className={styles.highlight}>From Brand Number</Text>
              <div id="beginSeriesFields" className={styles.selectFields}>
                <TextField
                  id='fromPrefixInput'
                  className={styles.selectField}
                  label="Prefix"
                  maxLength={4}
                  value={get(addingFields, 'beginBrand.prefix')}
                  onChange={(_ev, value) => onAddSeriesFieldChange('beginBrand', 'prefix', value?.toUpperCase())}
                />
                <TextField
                  id='fromBrandInput'
                  className={styles.selectField}
                  label="Brand"
                  type="number"
                  value={get(addingFields, 'beginBrand.brandNo')}
                  onChange={(_ev, value) => onAddSeriesFieldChange('beginBrand', 'brandNo', parseInt(value as any))}
                />
                <TextField
                  id='fromSuffixInput'
                  className={styles.selectField}
                  label="Suffix"
                  maxLength={5}
                  value={get(addingFields, 'beginBrand.suffix')}
                  onChange={(_ev, value) => onAddSeriesFieldChange('beginBrand', 'suffix', value)}
                />
              </div>
            </div>
            <div>
              <Text variant="xLarge" className={styles.highlight}>To Brand Number</Text>
              <div id="endSeriesFields" className={styles.selectFields}>
                <TextField
                  id='toPrefixInput'
                  className={styles.selectField}
                  label="Prefix"
                  disabled
                  value={get(addingFields, 'endBrand.prefix')}
                  onChange={(_ev, value) => onAddSeriesFieldChange('endBrand', 'prefix', value)}
                />
                <TextField
                  id='toBrandInput'
                  className={styles.selectField}
                  label="Brand"
                  type="number"
                  value={get(addingFields, 'endBrand.brandNo')}
                  onChange={(_ev, value) => onAddSeriesFieldChange('endBrand', 'brandNo', parseInt(value as any))}
                />
                <TextField
                  id='toSuffixInput'
                  className={styles.selectField}
                  label="Suffix"
                  disabled
                  value={get(addingFields, 'endBrand.suffix')}
                  onChange={(_ev, value) => onAddSeriesFieldChange('endBrand', 'suffix', value)}
                />
              </div>
            </div>
            <PrimaryButton
              id="addButton"
              className={styles.actionButton}
              text="+ Add"
              onClick={handleAdd}
              disabled={!userPermissions.isWrite}
            />
          </div>
        </div>
      </div>
      <div>
        <DataGridComponent
          idTable={'table'}
          title='Move Tires To In-Transit'
          headCells={columns}
          rowsTable={state.items}
          defaultRowsPerPage={tireOrderManagementPageSizes}
          totalDataFound={state.foundCount}
          isLoading={state.loading}
          enableCheckBox={userPermissions.isWrite}
          enablePagination={true}
          enableRowsPerPage={true}
          enableDeleteOption={userPermissions.isWrite}
          enableMultiSelectRow={userPermissions.isWrite}
          handleDelete={handleDelete}
          handleChangeDataGridState={fetchTires}
          handleSelectRow={(items) => { handleSelect(items); }}
        />
        <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
          <DefaultButton onClick={toggleShowPrintExport} text="Print/Export" />
          <PrimaryButton id="submitButton" onClick={() => handleSubmit()} text="Submit" disabled={state.items.length === 0 || !userPermissions.isWrite} />
        </div>
        <PrintingModal
          isOpened={showPrintExport}
          onClose={toggleShowPrintExport}
          onPrint={handlePrint}
        />
        {userPermissions.isWrite &&
          <Dialog
            maxWidth={700}
            hidden={!isWarningDialogVisible}
            onDismiss={() => { toggleWarningConfirmation(); }}
            dialogContentProps={{
              type: DialogType.normal,
              title: 'Some of the tires you entered are reported in spare stock. Are you sure you would like to continue?',
              subText: 'Please be sure to contact the record keeper for these tires and receive confirmation to return to in-transit.',
            }}
            modalProps={{ isBlocking: true }}
          >
            <DialogFooter>
              <DefaultButton
                onClick={() => { toggleWarningConfirmation(); }}
                text="Don't return these tires to in transit"
              />
              <PrimaryButton
                id="saveButton"
                onClick={() => { handleSubmit(true); toggleWarningConfirmation(); }}
                text="Continue, I have confirmation from the record keeper."
              />
            </DialogFooter>
          </Dialog>}
        {state.loading && <LoadingScreen />}
      </div>
    </>
  );
};

export default ReturnToInTransit;