import { useField, UseFieldProps } from '../../hooks/useField';

interface IFormField {
  formFieldName: string,
  management: any,
}

export interface IFormDataField {
  formFieldName: string,
  properties: UseFieldProps,
}

export class FormBuilderGroup {

  formFieldList: any;
  formFieldsGroup: Array<IFormField> = [];

  constructor( formFieldList: Array<IFormDataField> ){
    this.formFieldList = formFieldList;

    this.formFieldList.forEach(({formFieldName, properties}: any) => {
      this.formFieldsGroup.push({
        formFieldName,
        management: useField(properties),
      });
    });
  };

  /** Get the hook of a unique FieldForm */
  getFieldForm = (formFieldName: string) => this.formFieldsGroup.filter(
    (formField: IFormField) => formField.formFieldName == formFieldName)[0]?.management;

  /** Get the value of a FieldForm */
  getFieldFormValue = (formFieldName: string) => this.getFieldForm(formFieldName).getValue() || null;

  /** Get a className of a FieldForm */
  getFieldFormClassName = (formFieldName: string) => this.getFieldForm(formFieldName)?.getRequiredClassName() || '';

  /** Set a new value of a FieldForm */
  setFormValue = (formFieldName: string, value: any) => this.getFieldForm(formFieldName)?.setValue(value);

  /** Validate if FormGroup has a empty required value */
  hasEmptyFormData = () => this.formFieldsGroup.filter(formField => {
    return formField.management?.required && !formField.management?.getValue();
  }).length > 0;

  cleanFormData = () =>  this.formFieldsGroup.forEach(formField => formField.management.setValue(''));
};