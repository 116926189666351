import { DefaultButton, IconButton, MessageBarType, Modal, PrimaryButton, Text } from '@fluentui/react';
import apiService from '../../api';
import { FunctionComponent, useEffect, useState } from 'react';
import styles from './TypeCodeModalComponent.module.scss';
import DataGridComponent from '../DataGridComponent';
import { useBoolean } from '@fluentui/react-hooks';
import { typeCodeHeadCell, TypeCodeModalComponentProps, typeCodePageSizes } from './consts';
import useNotifications from '../../hooks/useNotifications';
import { customerSelector } from '../../redux/recordKeepingSlice';
import { useSelector } from 'react-redux';

const TypeCodeModalComponent: FunctionComponent<TypeCodeModalComponentProps> = ({
  id,
  title,
  isOpen,
  onSubmit,
  onDismiss,
  successLabel,
  cancelLabel,
  originalOrRetread,
  isUserDependent,
}) => {
  const { addNotification } = useNotifications();
  const [typeCodeList, setTypeCodeList] = useState([]);
  const [totalFound, setTotalFound] = useState(0);
  const [typeCodeSelected, setTypeCodeSelected] = useState({});
  const [notification, setNotification] = useState({ text: '', type: 0 });
  const [isLoading, { toggle: toggleShowLoadingScreen }] = useBoolean(false);

  const { id: customerId, corrAddressCountry: country } = useSelector(customerSelector);

  const fetchData = async (pagination: any, filters?: any, sortOrder?: any) => {
    try {
      toggleShowLoadingScreen();
      const { data }: any = isUserDependent ?
        await apiService.typeCodeAPI.getTypeCodeAuthList(pagination, sortOrder, filters, customerId) :
        await apiService.maintainTypeCodes.get(filters, pagination, sortOrder, customerId);
      setTypeCodeList(data?.data);
      setTotalFound(data?.total?.found);
      toggleShowLoadingScreen();
    } catch (error) {
      setNotification({ text: 'Fetching typeCodes error', type: MessageBarType.error });
    }
  };

  const handleChangeDataGridState = (dataGridState: any) => {
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString: searchedText,
      originalOrRetread: originalOrRetread,
      country: country,
    };
    fetchData(pagination, filters, sortOrder);
  };

  const handleSelectRow = (rowsSelected: any) => {
    setTypeCodeSelected(rowsSelected);
  };

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  return (
    <>
      <Modal
        key={id}
        isOpen={isOpen}
        onDismiss={onDismiss}
        containerClassName='modalContainer'
      >
        <div className={styles.modalBody}>
          <div className='modalHeader'>
            <Text variant="xLarge" className={styles.highlight}>{title}</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel="Close popup modal"
              onClick={onDismiss}
            />
          </div>
          <div className='tableContainer'>
            <DataGridComponent
              idTable={'typeCode-table'}
              title=''
              headCells={typeCodeHeadCell}
              rowsTable={typeCodeList}
              defaultRowsPerPage={typeCodePageSizes}
              totalDataFound={totalFound}
              isLoading={isLoading}
              enableCheckBox
              enableSearching
              enablePagination
              enableRowsPerPage
              enableSearchStringUpperCase
              handleChangeDataGridState={handleChangeDataGridState}
              handleSelectRow={handleSelectRow}
            />
          </div>
          <div className={styles.modalFooter}>
            <DefaultButton
              id="cancelButton"
              text={cancelLabel}
              onClick={onDismiss}
            />
            <PrimaryButton
              id="selectButton"
              text={successLabel}
              onClick={(e) => onSubmit(typeCodeSelected)}
            />
          </div>
        </div>
      </Modal>
    </>);
};

export default TypeCodeModalComponent;