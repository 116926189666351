import { quoteTestInstance } from '../axios';

const getList = (requestBody: any) =>
  quoteTestInstance.post('/quotes', requestBody);

const deleteQuote = (quoteInfo: any) =>
  quoteTestInstance.delete('/quote', { data: quoteInfo });

const copy = (requestBody: any) =>
  quoteTestInstance.post('/quote/copy', requestBody);

const add = (requestBody: any) =>
  quoteTestInstance.post('/quote/add', requestBody);

const version = (requestBody: any) =>
  quoteTestInstance.post('/quote/new-version', requestBody);

export default {
  getList,
  delete: deleteQuote,
  copy,
  add,
  version,
};
