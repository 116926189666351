import { fieldType } from '../../../../../../consts/fieldType';
import { IOem } from './IOem';

export const pageSizes = [100, 250, 500];

export const plantsColumns = [
  {
    key: 'column1',
    name: 'OEM #',
    fieldName: 'oemNumber',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column2',
    name: 'Name',
    fieldName: 'name',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column3',
    name: 'Address Line 1',
    fieldName: 'addressLine1',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column4',
    name: 'Address Line 2',
    fieldName: 'addressLine2',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column5',
    name: 'Country',
    fieldName: 'country',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },

  {
    key: 'column6',
    name: 'State',
    fieldName: 'state',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column7',
    name: 'City',
    fieldName: 'city',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column8',
    name: 'Zip',
    fieldName: 'zip',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column9',
    name: 'Phone',
    fieldName: 'phone',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column10',
    name: 'Fax',
    fieldName: 'fax',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column11',
    name: 'Contact Person',
    fieldName: 'contactPerson',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column12',
    name: 'Email',
    fieldName: 'email',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
  {
    key: 'column13',
    name: 'Comments',
    fieldName: 'comment',
    isSorted: false,
    isSortedDescending: false,
    type: fieldType.TEXT,
  },
];

export const defaultAddingFields: IOem = {
  plantId: '',
  oemNumber: '',
  name: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  zip: '',
  contactPerson: '',
  phone: '',
  email: '',
  fax: '',
  comment: '',
  state: null,
  country: null,
};
