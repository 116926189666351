import { HeadCell } from '../../../../../shared/DataGridComponent/DataGridModels';

export const tireHeadCells: HeadCell[] = [
  {
    key: 'column0',
    name: 'VG',
    fieldName: 'index',
    typeField: 'number',
    disableSorting: true,
  },
  {
    key: 'column1',
    name: 'Product Code',
    fieldName: 'prodCode',
    disableSorting: true,
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Tire Size',
    fieldName: 'tireSize',
    disableSorting: true,
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Bogey',
    fieldName: 'bogey',
    disableSorting: true,
    typeField: 'text',
  },
  {
    key: 'column4',
    name: 'Approx Tire Units',
    fieldName: 'approxTireMiles',
    disableSorting: true,
    typeField: 'text',
  },
  {
    key: 'column5',
    name: 'Tires Equal',
    fieldName: 'tiresEqualized',
    disableSorting: true,
    typeField: 'text',
  },
  {
    key: 'column6',
    name: 'Factory Cost',
    fieldName: 'factoryCost',
    disableSorting: true,
    typeField: 'text',
  },
];