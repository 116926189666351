import React, { FC, ReactElement } from 'react';
import { ITireHistoryDetailsTabProps } from './TireHistoryDetailsTabProps';
import styles from './TireHistoryDetailsTab.module.scss';

import classNames from 'classnames';
import { Text, Dropdown, IColumn, IDropdownOption } from '@fluentui/react';
import {
  viewByOptions,
  dataPresentationMap,
  VIEW_TYPE,
} from '../../../../../consts/recordKeeping';
import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../Pagination/Pagination';
import moment from 'moment';
import { pageSizes } from '../../../../../consts/recordKeeping';

const TireHistoryDetailsTab: FC<ITireHistoryDetailsTabProps> =
  ({details, viewBy, setViewBy, currentCountOnPage, setCurrentCountOnPage, 
    currentPaginationProps, setCurrentPaginationProps,
    columnsState, setColumnsState}): ReactElement => {


    const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
      setCurrentPaginationProps((prev: any) => ({ ...prev, current: 1 }));
      item && setCurrentCountOnPage(item);
    };

    const onColumnClick = (column: IColumn): void => {
      const newColumns: IColumn[] = [...columnsState];
      const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
  
      setColumnsState(newColumns);
    };

    const convertToDate = (val: any) => {
      if(/[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/gm.test(val)) {
        return moment(val).format('MM/DD/YYYY');
      } else {
        return val;
      }
    };

    const renderTable = () => {
      return (
        <>
          <div className={styles['table-wrapper']}>
            <table>
              <thead>
                <tr>
                  {
                    columnsState.map((item: any) => (
                      <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                  details && details.items ? details.items.map((item: any) => (
                    <tr>
                      {
                        columnsState.map((column: any) => (
                          <td>{convertToDate(item[column.fieldName])}</td>
                        ))
                      }
                    </tr>
                  )) :
                    <tr><td colSpan={columnsState.length}><span className={styles.emptyRowsTable}>No records found</span></td></tr>
                }
              </tbody>
            </table>
          </div>
          {
            details && details.items && (
              <>
                <SeparatorGy />
                <Pagination {...currentPaginationProps} />
              </>
            )
          }
        </>
      );
    };

    return (
      <>
        <div className={classNames(styles.settings)}>
          <div>
            <label>View by</label>
            <Dropdown
              id="nameOfView"
              placeholder="Select an option"
              options={viewByOptions}
              defaultSelectedKey={viewBy}
              onChange={(e, selectedOption: any) => {
                const selectedView = dataPresentationMap[selectedOption.key as VIEW_TYPE];
                setColumnsState(selectedView.columns);
                setViewBy(selectedOption.key);
              }}
            />
          </div>
        </div>
        <div className={classNames(styles.container)}>
          <div>
            <div className="ms-Grid">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  <div className={styles.tableHeading}>
                    <div>
                      <Text variant="xLarge">Tires</Text>
                      <SeparatorGy vertical />
                      <Text variant="xLarge">{details?.foundCount} found</Text>
                    </div>
                    <div>
                      <Text variant="large">Show # of rows:&nbsp;</Text>
                      <Dropdown
                        options={pageSizes.map(pageSize => ({
                          key: pageSize,
                          text: pageSize.toString(),
                        }))}
                        defaultSelectedKey={pageSizes[0]}
                        selectedKey={currentCountOnPage?.key}
                        onChange={onChangeCountOnPage}
                      />
                    </div>
                  </div>
                  {renderTable()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

export default TireHistoryDetailsTab;
