import { HeadCell, ISorting } from '../DataGridComponent/DataGridModels';

export const availableInvoicesHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Customer Name',
    fieldName: 'name',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Brand Prefix',
    fieldName: 'brandPrefix',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Region',
    fieldName: 'region',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Location',
    fieldName: 'location',
    typeField: 'text',
  },
  {
    key: 'column4',
    name: 'SAP Invoice #',
    fieldName: 'invoiceNumber',
    typeField: 'text',
  },
  {
    key: 'column5',
    name: 'MIDAS #',
    fieldName: 'invoiceId',
    typeField: 'text',
  },
  {
    key: 'column6',
    name: 'Invoice Date',
    fieldName: 'invoiceDate',
    typeField: 'date',
  },
  {
    key: 'column7',
    name: 'Billing Type',
    fieldName: 'billingType',
    typeField: 'text',
  },
  {
    key: 'column8',
    name: 'Status',
    fieldName: 'status',
    typeField: 'text',
  },
  {
    key: 'column9',
    name: 'CRDB',
    fieldName: 'crDb',
    typeField: 'text',
  },
  {
    key: 'column10',
    name: 'Amount',
    fieldName: 'amount',
    typeField: 'text',
  },
  {
    key: 'column11',
    name: 'Period End',
    fieldName: 'periodEnd',
    typeField: 'text',
  },
  {
    key: 'column12',
    name: 'Created By',
    fieldName: 'createdBy',
    typeField: 'text',
  },
  {
    key: 'column13',
    name: 'Release Date',
    fieldName: 'releaseDate',
    typeField: 'text',
  },
  {
    key: 'column14',
    name: 'Hold Date',
    fieldName: 'holdDate',
    typeField: 'text',
  },
  {
    key: 'column15',
    name: 'Failure Date',
    fieldName: 'failureDate',
    typeField: 'text',
  },
  {
    key: 'column16',
    name: 'Success Date',
    fieldName: 'successDate',
    typeField: 'text',
  },
];

export const defaultSorting: ISorting = {
  column: availableInvoicesHeadCell[13].fieldName,
  order: 'desc',
};