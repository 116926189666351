import React from 'react';
import ProductDetailComponent from './ProductDetailComponent';

const CollapsibleRow: React.FC<any> = ({ vehicleMake, fleetId, permissionKey, parentComponentName, onDismiss }) => {
  return (
    <>
      <ProductDetailComponent
        id='productModal'
        title={vehicleMake}
        fleetId={fleetId}
        cancelLabel='Cancel'
        permissionKey={permissionKey}
        parentComponentName={parentComponentName}
        onDismiss={onDismiss}
      />
    </>
  );
};

export default CollapsibleRow;