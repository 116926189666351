import { FC, FormEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  Checkbox,
  Dropdown,
  Icon,
  IDropdownOption,
  MessageBarType,
  PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';

import apiService from '../../../../../api';
import useNotifications from '../../../../../hooks/useNotifications';
import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import LoadingScreen from '../../../../LoadingScreen/LoadingScreen';
import { useCustomersFetch } from '../../../../../hooks/useCustomersFetch';

import { IBillToState } from './IBillToState';
import { IBillToProps } from './IBillToProps';

import { useUserPermissions } from '../../../../../hooks/useUserPermissions';
import { auth_customer_billTo } from '../../../../../consts/programKeys';

import styles from './BillTo.module.scss';

const BillTo: FC<IBillToProps> = () => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_customer_billTo);

  const { id } = useParams<{ id: string }>();
  const { loadingCustomers, customersList } = useCustomersFetch(apiService.getCustomerSearch, null, id);
  const { addNotification } = useNotifications();

  const [state, setState] = useState<IBillToState>({
    countries: [],
    states: [],
    addressLine1: '',
    addressLine2: '',
    country: '',
    state: '',
    geoCode: '',
    city: '',
    zip: '',
    phone: '',
    fax: '',
    federal: false,
    sales: false,
    waste: false,
    federalTaxExempt: '',
    salesTaxExempt: '',
    wasteTaxExempt: '',
    loading: false,
  });
  const [parsedErrors, setParsedErrors] = useState<any>([]);

  const handleSave = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { ...billToForm } = state;
      await apiService.customerBillTo.save({ customerId: customersList[0].id, ...billToForm });
      addNotification({
        text: 'Customer\'s Bill To was successfully saved.',
        type: MessageBarType.success,
      });
      setParsedErrors([]);
    } catch (e: any) {
      const { response: { data } } = e;
      const { message, state, statusCode } = data;
      switch (statusCode) {
        case 400:
          setParsedErrors(state);
          return addNotification({
            text: 'Unable to save Bill To.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Customer's Bill To saving error: ${message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchStates = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data: { states } }: any = await apiService.customerBillTo.getStates(state.country);
      setState((prev: any) => ({ ...prev, states }));
    } catch (e: any) {
      addNotification({
        text: `Fetching lists error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const mountingMethod = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data: { data: customerInfo } }: any = await apiService.customerBillTo.get(customersList[0].id);
      const { data: { countries } }: any = await apiService.customerBillTo.getCountries();

      setState((prev: any) => ({ ...prev, ...customerInfo, countries }));
    } catch (e: any) {
      addNotification({
        text: `Fetching customer information error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    customersList.length && mountingMethod();
  }, [customersList]);

  useEffect(() => {
    state.country && fetchStates();
  }, [
    state.country,
  ]);

  const onChangeCheckbox = (event: any) => {
    const fieldToBeUpdate: any = event.target.name;
    setState((prev: any) => ({ ...prev, [fieldToBeUpdate]: !get(prev, fieldToBeUpdate) }));
  };

  const onChangeTextInput = (ev: any, value: any) => {
    setState((prev: any) => ({ ...prev, [ev.target.name]: value }));
  };

  const onChangeCountry = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    item && setState((prev: any) => ({ ...prev, country: item.key }));
  };

  const onChangeState = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    item && setState((prev: any) => ({ ...prev, state: item.key }));
  };

  const parseErrors = (field: string) => {
    const customError = parsedErrors?.filter((error: any) => error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  return (
    <div>
      <div className="ms-Grid">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <Text variant='xLarge' className={styles.highlight}>Address</Text>
            <SeparatorGy />
          </div>
        </div>

        <div className={classNames('ms-Grid-row', styles.row)}>
          <div className="ms-Grid-col ms-sm7">
            <div className={classNames('ms-Grid-row', styles.row)}>
              <div className="ms-Grid-col ms-sm12">
                <TextField
                  name="addressLine1"
                  value={state.addressLine1}
                  placeholder="Address line 1"
                  label="Address line 1:"
                  onChange={onChangeTextInput}
                  errorMessage={parseErrors('addressLine1')}
                />
              </div>
            </div>

            <div className={classNames('ms-Grid-row', styles.row)}>
              <div className="ms-Grid-col ms-sm12">
                <TextField
                  name="addressLine2"
                  value={state.addressLine2}
                  placeholder="Address line 2"
                  label="Address line 2:"
                  onChange={onChangeTextInput}
                  errorMessage={parseErrors('addressLine2')}
                />
              </div>
            </div>

            <div className={classNames('ms-Grid-row', styles.row)}>
              <div className="ms-Grid-col ms-sm4">
                <Dropdown
                  id="countryDropdown"
                  options={state.countries.map((item: any) => ({
                    key: item,
                    text: item,
                  }))}
                  label="Country:"
                  selectedKey={state.country}
                  onChange={onChangeCountry}
                  errorMessage={parseErrors('country')}
                />
              </div>
              <div className="ms-Grid-col ms-sm4">
                <Dropdown
                  id="stateDropdown"
                  options={state.states.map((item: any) => ({
                    key: item,
                    text: item,
                  }))}
                  label="State:"
                  selectedKey={state.state}
                  onChange={onChangeState}
                  errorMessage={parseErrors('state')}
                />
              </div>
              <div className="ms-Grid-col ms-sm4">
                <TextField
                  name="geoCode"
                  value={state.geoCode}
                  placeholder="Geog. Code"
                  label="Geog. Code:"
                  onChange={onChangeTextInput}
                  onRenderPrefix={() => <Icon iconName="LocationCircle" />}
                  errorMessage={parseErrors('geoCode')}
                />
              </div>
            </div>

            <div className={classNames('ms-Grid-row', styles.row)}>
              <div className="ms-Grid-col ms-sm6">
                <TextField
                  name="city"
                  value={state.city}
                  placeholder="City"
                  label="City:"
                  onChange={onChangeTextInput}
                  onRenderPrefix={() => <Icon iconName="CityNext" />}
                  errorMessage={parseErrors('city')}
                />
              </div>
              <div className="ms-Grid-col ms-sm6">
                <TextField
                  name="zip"
                  value={state.zip}
                  placeholder="ZIP"
                  label="ZIP:"
                  onChange={onChangeTextInput}
                  onRenderPrefix={() => <Icon iconName="Location" />}
                  errorMessage={parseErrors('zip')}
                />
              </div>
            </div>

            <div className={classNames('ms-Grid-row', styles.row)}>
              <div className="ms-Grid-col ms-sm6">
                <TextField
                  name="phone"
                  value={state.phone}
                  placeholder="Phone"
                  label="Phone:"
                  onChange={onChangeTextInput}
                  onRenderPrefix={() => <Icon iconName="Phone" />}
                  errorMessage={parseErrors('phone')}
                />
              </div>
              <div className="ms-Grid-col ms-sm6">
                <TextField
                  name="fax"
                  value={state.fax}
                  placeholder="Fax"
                  label="Fax:"
                  onChange={onChangeTextInput}
                  onRenderPrefix={() => <Icon iconName="Fax" />}
                  errorMessage={parseErrors('fax')}
                />
              </div>
            </div>
          </div>
          <div className="ms-Grid-col ms-sm5">
            <Text variant='xLarge' className={styles.highlight}>Tax information</Text>
            <SeparatorGy />
            <div className={classNames('ms-Grid-row', styles.row)}>
              <div className={classNames('ms-Grid-col', 'ms-sm5', styles.centeredColumn)}>
                <Checkbox
                  name="federal"
                  label="Federal"
                  checked={state.federal}
                  onChange={onChangeCheckbox}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm7', styles.centeredColumn)}>
                <TextField
                  name="federalTaxExempt"
                  value={state.federalTaxExempt}
                  placeholder="TAX Exempt #"
                  label="Tax Exempt #"
                  onChange={onChangeTextInput}
                  onRenderPrefix={() => <Icon iconName="Ticket" />}
                  errorMessage={parseErrors('federalTaxExempt')}
                />
              </div>
            </div>
            <div className={classNames('ms-Grid-row', styles.row)}>
              <div className={classNames('ms-Grid-col', 'ms-sm5', styles.centeredColumn)}>
                <Checkbox
                  name="sales"
                  label="Sales"
                  checked={state.sales}
                  onChange={onChangeCheckbox}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm7', styles.centeredColumn)}>
                <TextField
                  name="salesTaxExempt"
                  value={state.salesTaxExempt}
                  placeholder="TAX Exempt #"
                  label="Tax Exempt #"
                  onChange={onChangeTextInput}
                  onRenderPrefix={() => <Icon iconName="Ticket" />}
                  errorMessage={parseErrors('salesTaxExempt')}
                />
              </div>
            </div>
            <div className={classNames('ms-Grid-row', styles.row)}>
              <div className={classNames('ms-Grid-col', 'ms-sm5', styles.centeredColumn)}>
                <Checkbox
                  name="waste"
                  label="Waste"
                  checked={state.waste}
                  onChange={onChangeCheckbox}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm7', styles.centeredColumn)}>
                <TextField
                  name="wasteTaxExempt"
                  value={state.wasteTaxExempt}
                  placeholder="TAX Exempt #"
                  label="Tax Exempt #"
                  onChange={onChangeTextInput}
                  onRenderPrefix={() => <Icon iconName="Ticket" />}
                  errorMessage={parseErrors('wasteTaxExempt')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.buttonsRow)}>
            <PrimaryButton
              id="savingButton"
              iconProps={{ iconName: 'Edit' }}
              text='Save Customer'
              onClick={handleSave}
              disabled={!userPermissions.isWrite}
            />
          </div>
        </div>
      </div>
      {(state.loading || loadingCustomers) && <LoadingScreen />}
    </div>
  );
};

export default BillTo;
