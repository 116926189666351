import { isNil } from 'lodash';

export const customerEMHandler = (field: string, value: string) => {
  if (value && !value.match('^\\d{1,9}$')) {
    return `${field} number must be 9 numbers`;
  }
};

export const orderEMHandler = (field: string, value: string) => {
  if (value && !value.match('^[a-zA-Z0-9_@./#&+-]{10}$')) {
    return `${field} Number must be 10 alphanumeric characters`;
  }
};

export const typeEMHandler = (value: string) => {
  if (value && !value.match('[a-zA-Z0-9_@./#&+-]{5}$')) {
    return 'Type code must be 5 alphanumeric characters';
  }
};

export const shipToEMHandler = (value: string) => {
  if (value && !value.match('^\\d{10}$')) {
    return 'Product number must be 10 numbers';
  }
};

export const productEMHandler = (value: string) => {
  if (value && !value.match('^\\d{14}$')) {
    return 'Product number must be 14 numbers';
  }
};

export const costEMHandler = (value: string) => {
  if (value && Number(value) < 0) {
    return 'Cost should be a positive number';
  }
};

export const quantityEMHandler = (value: string) => {
  const num = Number(value);

  if (value && !Number.isInteger(num)) {
    if (!Number.isInteger(num)) {
      return 'Quantity should be a positive whole number';
    }
    if (num < 0 || String(num) !== value) {
      return 'Quantity should be positive';
    }
  }

};
export const numberEMHandler = (value: string) => {
  const n: number = Math.floor(Number(value));
  if (n < 0 || (value && !Number(value))) {
    return 'Should be a positive number';
  }
};
export const integerEMHandler = (value: string) => {
  const n = Number(value);
  if (n < 0 || (value && !Number.isInteger(n))) {
    return 'Should be a postive whole number';
  }
};

export const oemEMHandler = (value: string) => {
  if (value && !value.match('^\\d{10}$')) {
    return 'OEM number must be 10 numbers';
  }
};

export const invoiceDelivNoteEMHandler = (value: string) => {
  if (value && !value.match('^[a-zA-Z0-9_@./#&+-]{1,10}$')) {
    return 'Input must be less than 10 alphanumeric characters';
  }
};

export const gdyrDelNoEMHandler = (value: string) => {
  if (value && !value.match('^\\d{10}$')) {
    return 'GDYR DELIVERY NOTE # must be 10 numbers';
  }
};

export const gdyrIdEMHandler = (value: string) => {
  if (value && !value.match('^\\d{10}$')) {
    return 'GDYR ORDER # must be 10 numbers';
  }
};