import { HeadCell } from '../../../../../../../../shared/DataGridComponent/DataGridModels';

export const emptyPlaceOriginalOrder = {
  status: '',
  shipDate: '',
  recvDate: '',
  orderDate: '',
  id: '',
  gdyrId: '',
  customerId: '',
  estimatedDeliveryDate: '',
  enteredManually: true,
  sentToR3: true,
  shipToOem: '',
};
      
export const emptyPlaceOriginalOrderItem = {
  beginBrandPrefix: '',
  beginBrandNumber: '',
  beginBrandSuffix: '',
  endBrandPrefix: '',
  endBrandNumber: '',
  endBrandSuffix: '',
  warehouse: '',
  invoiceId: '',
  batch: '',
};

export const orderDetailsColumns = [
  {
    key: 'column0',
    name: 'Status',
    fieldName: 'status',
  },
  {
    key: 'column1',
    name: 'ShipDate',
    fieldName: 'shipDate',
  },
  {
    key: 'column2',
    name: 'RecvDate',
    fieldName: 'recvDate',
  },
  {
    key: 'column3',
    name: 'GDYR Delivery Note #',
    fieldName: 'gdyrDelNo',
  },
  {
    key: 'column4',
    name: 'Invoice',
    fieldName: 'invoiceId',
  },
  {
    key: 'column5',
    name: 'Type Code',
    fieldName: 'typeCode',
  },
  {
    key: 'column6',
    name: 'Warehouse',
    fieldName: 'warehouseNo',
  },
  {
    key: 'column7',
    name: 'Batch',
    fieldName: 'batch',
  },
  {
    key: 'column8',
    name: 'Begin Brand Prefix',
    fieldName: 'beginBrandPrefix',
  },
  {
    key: 'column9',
    name: 'Begin Brand Number',
    fieldName: 'beginBrandNumber',
  },
  {
    key: 'column10',
    name: 'Begin Brand Suffix',
    fieldName: 'beginBrandSuffix',
  },
  {
    key: 'column11',
    name: 'End Brand Prefix',
    fieldName: 'endBrandPrefix',
  },
  {
    key: 'column12',
    name: 'End Brand Number',
    fieldName: 'endBrandNumber',
  },
  {
    key: 'column13',
    name: 'End Brand Suffix',
    fieldName: 'endBrandSuffix',
  },
  {
    key: 'column14',
    name: 'Quantity',
    fieldName: 'quantity',
  },
];