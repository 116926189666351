import { IconButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { FunctionComponent } from 'react';
import UserDetailsModal from '../../../../components/UserAccessManagement/tabComponents/Users/UserDetailsModal/UserDetailsModal';
import { IAvailableInvoice } from '../../../../models/IAvailableInvoice';
import styles from '../Actions.module.scss';


interface UsersManagementActionProps {
  row: IAvailableInvoice;
}

const UsersManagementAction: FunctionComponent<UsersManagementActionProps> = ({ row }) => {
  const [isModalOpen, { toggle: toggleIsModalOpen }] = useBoolean(false);

  return (
    <>
      <IconButton
        id='viewButton'
        iconProps={{ iconName: 'OpenInNewWindow' }}
        onClick={toggleIsModalOpen}
        className={styles.iconBtn}
      />

      <UserDetailsModal
        isOpened={isModalOpen}
        onClose={toggleIsModalOpen}
        userData={row}
      />
    </>
  );
};

export default UsersManagementAction;