import { HeadCell } from '../../../../../../../../shared/DataGridComponent/DataGridModels';

export const dataGridPageSizes = [100, 200, 300];

export const costReportHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Prefix',
    fieldName: 'prefix',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Brand',
    fieldName: 'brand',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Suffix',
    fieldName: 'suffix',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Date',
    fieldName: 'date',
    typeField: 'date',
  },
  {
    key: 'column4',
    name: 'Cost',
    fieldName: 'cost',
    typeField: 'text',
  },
  {
    key: 'column5',
    name: 'Cost Recov',
    fieldName: 'costRecov',
    typeField: 'text',
  },
  {
    key: 'column6',
    name: 'Cost Remain',
    fieldName: 'costRemain',
    typeField: 'text',
  },
  {
    key: 'column7',
    name: 'Notes',
    fieldName: 'notes',
    typeField: 'text',
  },
  {
    key: 'column8',
    name: 'Residual Value',
    fieldName: 'residualValue',
    typeField: 'text',
  },
 
];