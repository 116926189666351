import {
  Pivot,
  PivotItem,
} from '@fluentui/react';
import classNames from 'classnames';
import { get } from 'lodash';
import { FC, FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchCustomerLocation from '../../components/Common/Search/SearchCustomerLocation';
import { recordKeepingTabs } from '../../consts/recordKeeping';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import { MIDAS_MODULES } from '../../models/EnumMidasModules';
import { activeTabSelector, clearRecordKeeping, customerSelector, setActiveSubTab, setActiveTab, setCustomer, setLocation, setRkUsage } from '../../redux/recordKeepingSlice';
import ModuleTitleComponent from '../../shared/ModuleTitleComponent';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { getMenuOptionById } from '../Nav/consts';
import { emptyRecordKeepingState } from './consts';
import { IRecordKeepingProps } from './IRecordKeepingProps';
import { IRecordKeepingState } from './IRecordKeepingState';
import styles from './RecordKeeping.module.scss';

const RecordKeeping: FC<IRecordKeepingProps> = (): ReactElement => {

  const [recordKeepingState, setRecordKeepingState] = useState<IRecordKeepingState>(emptyRecordKeepingState);
  const [currentTab, setCurrentTab] = useState<any>(
    recordKeepingTabs?.recordKeeping?.name,
  );

  const activeTab = useSelector(activeTabSelector);

  const { loadingPermissions, getPermissionsByAccessKey } = useUserPermissions();

  const selectedCustomer = useSelector(customerSelector);
  const dispatch = useDispatch();

  const setLoading = (isLoading: any) => {
    setRecordKeepingState(prev => ({ ...prev, loading: isLoading }));
  };

  const handleLinkClick = (item?: PivotItem) => {
    const newTab = get(item, 'props.itemKey', '');
    dispatch(setActiveTab(newTab));
  };

  const renderTab = (TabComponent: FunctionComponent) => TabComponent && <TabComponent />;

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  const moduleInfo = getMenuOptionById(MIDAS_MODULES.RECORD_KEEPING);

  useEffect(() => {
    setCurrentTab(activeTab);
    dispatch(setActiveSubTab(''));
  }, [activeTab]);

  useEffect(() => {
    return () => {
      dispatch(setActiveTab(recordKeepingTabs?.recordKeeping?.name));
      dispatch(clearRecordKeeping());
    };
  }, []);

  useEffect(() => {
    dispatch(setRkUsage(true));
  }, [selectedCustomer]);

  return (
    <>
      <div className="ms-Grid record-keeping-container mainContainer" dir="ltr">
        <ModuleTitleComponent moduleInfo={moduleInfo} />

        { activeTab!= 'Spare Stock Posting History' && <SearchCustomerLocation callbackLoading={setLoading} />}

        {selectedCustomer &&
          <div className="ms-Grid-row">
            <div
              className={classNames('ms-Grid-col', 'ms-lg12', 'tabulatorWrapper')}
            >
              <Pivot
                aria-label="Separately Rendered Content Pivot Example"
                selectedKey={currentTab}
                onLinkClick={handleLinkClick}
                getTabId={getTabId}
                linkFormat="tabs"
                linkSize="large"
              >
                {Object.values(recordKeepingTabs).map(({ accessKey, name, component }, index) =>
                  getPermissionsByAccessKey(accessKey)?.isAccess && (
                    <PivotItem headerText={name} key={index} itemKey={name}>
                      <div className={styles.pivotBodyContainer}>
                        {renderTab(component)}
                      </div>
                    </PivotItem>
                  ))}
              </Pivot>
            </div>
          </div>}
      </div>
      {(recordKeepingState.loading || loadingPermissions) && <LoadingScreen />}
    </>
  );
};

export default RecordKeeping;
