import { IStackItemStyles, IStackStyles } from '@fluentui/react';
import { DefaultPalette } from '@fluentui/style-utilities';

export const accordionHeader: IStackItemStyles = {
  root: {
    background: DefaultPalette.neutralLighter,
    padding: 5,
    cursor: 'pointer',
  },
};

export const accordion: IStackStyles = {
  root: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: DefaultPalette.neutralTertiary,
  },
};

export const accordionConent: IStackStyles = {
  root: {
    padding: 10,
  },
};