import { DefaultButton, Dropdown, IconButton, Spinner, SpinnerSize, Text, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { debounce, isEmpty, isEqual } from 'lodash';
import { FormEvent, FunctionComponent, MouseEvent, useEffect, useState } from 'react';
import Pagination from '../../components/Pagination/Pagination';
import SeparatorGy from '../../components/SeparatorGy/SeparatorGy';
import DeleteModalComponent from '../DeleteModalComponent';
import SwitchComponent from '../SwitchComponent';
import styles from './DataGrid.module.scss';
import { HeadCell, IDataGridState, ISorting } from './DataGridModels';
import RowComponent from './RowComponent';
import { DataGridComponentProps, defaultDataGridPageSizes, directionSortingList, getComparator, initalDataGridState } from './utils';


const DataGridComponent: FunctionComponent<DataGridComponentProps> = ({
  idTable,
  title,
  headCells,
  defaultSorting = { column: headCells[0].fieldName, order: 'asc' } as ISorting,
  defaultRowsPerPage = defaultDataGridPageSizes,
  preselectedRows,
  rowsTable,
  totalDataFound,
  isLoading = false,
  enableCheckBox = false,
  enableSearchStringUpperCase = false,
  enableSearching = false,
  enableMultiSelectRow = false,
  enableDeleteOption = false,
  enablePagination = false,
  enableRowsPerPage = false,
  enableAutoControls = false,
  enableManageHeadCells = false,
  enableShowFound = true,
  reloadControlsTable = false,
  enableRowClick = false,
  actionsGroupName = null,
  enableResizeColumns = false,
  enableSorting = true,
  enableMultiSorting = false,
  enableRefreshOption = false,
  enableLeftCheckbox = false,
  handleSelectRow = (rowsSelected) => { return; },
  handleChangeDataGridState = (state: any) => { return; },
  handleDelete = (rowsSelected: any) => { return; },
  handleUpdate = (rowsToUpdate: any) => { return; },
  handleRowClick = () => { return; },
}) => {
  /** Initial sorting array, evaluate if defaultSorting is Array or Object, returns an array in both cases */
  const sortingArray = defaultSorting ? defaultSorting instanceof Array ? defaultSorting : [defaultSorting] : [];

  /** Build initial object of Datagrid State */
  const buildInitialDataGridState = {
    ...initalDataGridState,
    sortOrder: defaultSorting ?
      sortingArray : initalDataGridState.sortOrder,
    countOnPage: {
      key: defaultRowsPerPage[0],
      text: defaultRowsPerPage[0].toString(),
    },
    paginationProps: {
      current: 1,
      total: Math.ceil(totalDataFound / +defaultRowsPerPage[0]),
    },
  };

  /** state to manage rows of component */
  const [rows, setRows] = useState(rowsTable);
  /** state to manage rows updated */
  const [rowsToBeUpdated, setRowsToBeUpdated] = useState<Array<any>>([]);
  /** state to manage if a field is focused */
  const [isFieldFocused, setisFieldFocused] = useState<string>(sortingArray[0]?.column);
  /** state to manage sorting list */
  const [sortingList, setSortingList] = useState<Array<ISorting>>(sortingArray);
  /** state to manage if a row is focused */
  const [rowFocused, setRowFocused] = useState<number>(0);
  /** state to manage all selected rows */
  const [selected, setSelected] = useState<string[]>([]);
  /** state to manage loading */
  const [isLoadingTable, setIsLoadingTable] = useState(isLoading);
  /** state to show/hide delete modal */
  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  /** state to show/hide headCell management list */
  const [showManageHeadCell, { toggle: toggleShowingManageHeadCell }] = useBoolean(false);
  /** state to manage if search is focused */
  const [isSearchFoucused, setIsSearchFoucused] = useState(false);
  /** state to manage headCells of the component */
  const [headCellList, setHeadCellList] = useState<Array<HeadCell>>([]);
  /** state to manage the general state of the component */
  const [generalDataGridState, setGeneralDataGridState] = useState<IDataGridState>(buildInitialDataGridState);

  const { searchedText, countOnPage, paginationProps, sortOrder } = generalDataGridState;

  /** Update headCells */
  const handleChangeHeadCells = () => {
    const newHeadCells = headCells.map(headCell => {
      return { ...headCell, isShowing: true };
    });
    setHeadCellList(newHeadCells);
  };

  /** main method to change general state of component */
  const handleChangeGeneralState = (property: string, value: any) => {
    setRowsToBeUpdated([]);
    setGeneralDataGridState({ ...generalDataGridState, [property]: value });
  };

  /** Handler of changes page */
  const onChangePage = (newPage: number) => {
    if (enableAutoControls) {
      setIsLoadingTable(true);
      setTimeout(() => {
        handleChangeGeneralState('paginationProps', { ...paginationProps, current: newPage });
        setIsLoadingTable(false);
      }, 500);
    } else {
      const newDataGridState = { ...generalDataGridState, paginationProps: { ...paginationProps, current: newPage } };
      handleChangeDataGridState({ ...newDataGridState, sortOrder: getSortingObj() });
      setGeneralDataGridState(newDataGridState);
    };
    setRowsToBeUpdated([]);
  };

  /** Handler of changes rows per page */
  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: any | undefined): void => {
    const newDataGridState = {
      ...generalDataGridState,
      paginationProps: {
        current: 1,
        total: Math.ceil(totalDataFound / item?.key),
      },
      countOnPage: item,
    };
    handleChangeDataGridState({ ...newDataGridState, sortOrder: getSortingObj() });
    setGeneralDataGridState(newDataGridState);
  };

  /** Create a sort handler */
  const createSortHandler = ({ target: { id: property } }: any) => handleRequestSort(property);

  /** Evaluate the new order based on the current order */
  const getNewSortingOrder = (directionToChange: string) =>
    directionSortingList.filter(direction => direction != directionToChange)[0];

  /** Get a sorting object based on property name */
  const getSortingObjByProperty = (propertyToFind: string) => sortingList.filter(
    (sortingObj: ISorting) => sortingObj.column == propertyToFind,
  )[0];

  /** Change the order of a sorting object based on property name */
  const changeOrderByProperty = (property: string) => sortingList.map((sortedObject: any) => {
    if (sortedObject.column == property)
      return { ...sortedObject, order: getNewSortingOrder(sortedObject.order) };
    return { ...sortedObject };
  });

  /** Delete a sorting object based on property name */
  const deleteSortingObj = (property: string) =>
    sortingList.filter((sortedObject: ISorting) => sortedObject.column != property);


  /** Handler of sorting */
  const handleRequestSort = (property: string) => {
    let newSorterList = [];

    const sortingObj = getSortingObjByProperty(property);

    if (sortingObj) {
      if (sortingObj.order == 'desc')
        newSorterList = deleteSortingObj(property);
      else
        newSorterList = changeOrderByProperty(property);
    } else {
      if (enableMultiSorting) newSorterList = sortingList;
      newSorterList.push({
        column: property,
        order: 'asc',
      });
    };

    setSortingList([...newSorterList]);

    if (!enableAutoControls) {
      handleChangeGeneralState('sortOrder', [...newSorterList]);
    }
  };

  /** Get the a className based on the order of sorting */
  const getClassSorting = (property: string, disableHeadSorting = false) => {
    if (disableHeadSorting || (!enableSorting && !enableMultiSorting)) return '';
    const sortingObj = getSortingObjByProperty(property);
    return sortingObj ? sortingObj.order == 'asc' ? styles.ascending : styles.descending : '';
  };

  /** Handler of row selection */
  const handleChangeSelectRow = (e: any, row: any) => {
    let currentSelected: any[] = [...selected];
    if (!enableMultiSelectRow && !currentSelected.find(el => isEqual(el, row))) currentSelected = [];
    if (currentSelected.find(el => isEqual(el, row))) currentSelected = currentSelected.filter(current => !isEqual(current, row));
    else currentSelected.push(row);
    setSelected(currentSelected);
  };

  /** Handler of all rows selection */
  const handleOnSelectAllRows = (e: any) => {
    if (allSelected) setSelected([]);
    else setSelected(rows);
  };

  /** Handler of searching */
  const handleOnChangeSearch = ({ target: { value } }: any) => {
    const searchedTextValue = enableSearchStringUpperCase ? value?.toString()?.toUpperCase() : value;
    handleChangeGeneralState('searchedText', searchedTextValue);
  };

  /** Handler of deleting row (fires the callBack for delete ) */
  const handleOnDelete = async () => {
    toggleDeletingConfirmation();
    await handleDelete(selected);
    setSelected([]);
    onChangePage(selected.length === rowsTable.length ?
      generalDataGridState.paginationProps.current > 1 ? generalDataGridState.paginationProps.current - 1 : 1
      : generalDataGridState.paginationProps.current);
  };

  /** Handler internal change state */
  const onHandleChangeDataGridState = () => {
    if (searchedText) handleInternalSearching(searchedText);
    else setRows(rowsTable);
  };

  const onHandleRefreshTable = () => {
    handleChangeDataGridState(getGeneralState());
  };

  /** Handler internal searching */
  const handleInternalSearching = (searchedVal: string) => {
    const currentRows = [...rowsTable];
    if (!currentRows) return;
    const filteredRows = currentRows.filter((row: any) => {
      return JSON.stringify(row)
        .toLowerCase()
        .includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  /** Handler of updating row (fires the callBack for update ) */
  const handleUpdateRow = (rowUpdated: any, idxRow: number) => {
    const currentRows = [...rows];
    let currentRowsToBeUpdated = [...rowsToBeUpdated];

    if (!currentRowsToBeUpdated.length) currentRowsToBeUpdated = [...rows];

    currentRowsToBeUpdated[idxRow] = rowUpdated;
    setRowsToBeUpdated(currentRowsToBeUpdated);

    const rowsFiltered = currentRowsToBeUpdated.filter(row => !currentRows.includes(row));
    handleUpdate(rowsFiltered);
  };

  /** Handler of show/hide columns */
  const handleShowOrHideHeadCell = (fieldName: any, isShowing: boolean) => {
    const currentHeadCellsList = [...headCellList];
    currentHeadCellsList.forEach(headCell => {
      if (headCell.fieldName == fieldName) headCell.isShowing = isShowing;
    });
    setHeadCellList(currentHeadCellsList);
  };

  /** Get all headCells allowed to show */
  const buildHeadCellsTable = () => headCellList.filter(headCell => headCell.isShowing);

  /** Get size of colspan property of the table */
  const getColspanSize = () => {
    let colspanSize = headCellList.length;
    if (enableCheckBox) colspanSize++;
    if (actionsGroupName) colspanSize++;
    return colspanSize;
  };

  /** In charge of build the array of rows */
  const buildRowsTable = () => {
    /** enableAutoControls = false means we don't have to manage sorting and pagination internally */
    if (!enableAutoControls || !sortingList.length) return rows;
    const orderObj: any = sortingList ? sortingList[0] : defaultSorting;
    let rowsBuilded;
    if (enableRowsPerPage) {
      if (!enableSorting) {
        rowsBuilded = rows
          ?.slice(getInitialRow(), getFinalRow());
      } else {
        rowsBuilded = rows
          ?.slice()
          ?.sort(getComparator(orderObj?.order, enableMultiSorting ? sortingList : orderObj?.column))
          ?.slice(getInitialRow(), getFinalRow());
      }
    } else {
      if (!enableSorting) {
        rowsBuilded = rows;
      } else {
        rowsBuilded = rows
          ?.slice()
          ?.sort(getComparator(orderObj?.order, enableMultiSorting ? sortingList : orderObj?.column));
      }
    }
    return rowsBuilded;
  };


  /** Pending of rowsTable changes */
  useEffect(() => {
    setRows(rowsTable);
    setSelected([]);
  }, [rowsTable]);

  /** Pending of headCells changes */
  useEffect(() => {
    handleChangeHeadCells();
  }, [headCells]);

  /** Pending of rows per page, serchedText and totalFound changes */
  useEffect(() => {
    if (!totalDataFound) return;
    /** 
     * Each time we change [totalDataFound, countOnPage, searchedText, reloadControlsTable] 
     * we need to reset pagination to first page 
    */
    const totalPages = Math.ceil(totalDataFound / countOnPage?.key);
    handleChangeGeneralState('paginationProps', {
      current: totalPages > 1
        ? generalDataGridState.paginationProps.current : 1,
      total: totalPages,
    });
  }, [totalDataFound, countOnPage, searchedText]);

  /** Pending of general state changes */
  useEffect(() => {
    /** enableAutoControls = true means we need to manage data internally */
    if (enableAutoControls) onHandleChangeDataGridState();
    /** enableAutoControls = false means we need to fire callBack for changeDataGridState */
    else handleChangeDataGridState(getGeneralState());
  }, [searchedText, sortOrder]);

  useEffect(() => {
    setGeneralDataGridState(buildInitialDataGridState);
  }, [reloadControlsTable]);

  useEffect(() => {
    if (preselectedRows)
      setSelected(preselectedRows);
  }, [preselectedRows]);

  /** Pending of selected rows changes */
  useEffect(() => {
    /** Fires the callBack for selecting row */
    handleSelectRow(selected);
  }, [selected]);

  /** Pending of loading changes */
  useEffect(() => {
    setIsLoadingTable(isLoading);
  }, [isLoading]);


  /** In charge of evaluate if we can render search option */
  const renderSearchOption = () => enableSearching && (
    <TextField
      id="searchString"
      value={searchedText}
      autoFocus={isSearchFoucused}
      onBlur={() => setIsSearchFoucused(false)}
      onFocus={() => setIsSearchFoucused(true)}
      placeholder="Enter search string"
      onChange={handleOnChangeSearch}
    />
  );

  /** In charge of evaluate if we can render rows per page option */
  const renderRowsPerPage = () => enableRowsPerPage && (
    <>
      <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
      <Dropdown
        id='rowsPerPageDropdown'
        className={styles.marginLeftRight}
        options={defaultRowsPerPage.map(pageSize => ({
          key: pageSize,
          text: pageSize.toString(),
        }))}
        defaultSelectedKey={defaultRowsPerPage[0]}
        selectedKey={countOnPage?.key}
        onChange={onChangeCountOnPage}
      />
    </>
  );

  /** In charge of evaluate if we can render selected items label */
  const renderSelectedItems = () => enableCheckBox && (
    <>
      <SeparatorGy vertical />
      <Text variant='large' className={`${styles.marginLeftRight} ${styles.highlight}`}>{`${selected.length} Selected`}</Text>
    </>
  );

  /** In charge of evaluate if we can render delete option */
  const renderDeleteOption = () => enableDeleteOption && (
    <>
      <SeparatorGy vertical />
      <IconButton
        className={styles.marginLeftRight}
        id="deleteIcon"
        disabled={isEmpty(selected)}
        iconProps={{ iconName: 'Delete' }}
        onClick={toggleDeletingConfirmation}
      />
    </>
  );

  /** In charge of evaluate if we can render refresh option */
  const renderRefreshOption = () => enableRefreshOption && (
    <>
      <SeparatorGy vertical />
      <IconButton
        className={styles.marginLeftRight}
        id="RefreshIcon"
        iconProps={{ iconName: 'DisableUpdates' }}
        onClick={onHandleRefreshTable}
      />
    </>
  );

  /** In charge of evaluate if we can render select all rows option */
  const renderSelectAllRows = () => enableCheckBox && (
    <>
      <th>
        {enableMultiSelectRow && (<div className={styles.round}>
          <input
            id={`row-${idTable}`}
            type="checkbox"
            checked={allSelected}
            onChange={handleOnSelectAllRows}
          />
          <label htmlFor={`row-${idTable}`}></label>
        </div>)}
      </th>
    </>
  );

  /** In charge of evaluate if we can render show/hide option */
  const renderShowHideHeadCells = () => enableManageHeadCells && (
    <>
      <SeparatorGy vertical />
      <div className={classNames(styles.customListContainer, styles.marginLeftRight)} id='showHideHeadCellsContainer'>
        <DefaultButton
          id='showHideHeadCellsBtn'
          text="Hide/Show Columns"
          onClick={toggleShowingManageHeadCell}
        />
        <div className={styles.customListOptionsContainer} id='headCellList'>
          {showManageHeadCell && headCellList.map((headCell, idx) => (
            <div className={styles.customListOption} key={`option-${idx}`}>
              <SwitchComponent
                id={headCell.fieldName}
                onChange={handleShowOrHideHeadCell}
                initialCheck={headCell.isShowing}
              />
              {headCell.name}
            </div>
          ))}
        </div>
      </div>
    </>
  );

  /**  boolean to know if a all rows were selected */
  const allSelected = rows?.length > 0 && selected?.length == rows?.length;
  /**  Method to know if a row is selected */
  const isSelected = (row: any) => !!selected.find(el => isEqual(el, row));
  /**  Get the inital row to sort */
  const getInitialRow = () => paginationProps.current == 1 ? paginationProps.current - 1 : (paginationProps.current - 1) * countOnPage.key;
  /**  Get the final row to sort */
  const getFinalRow = () => getInitialRow() + countOnPage.key;
  /**  Evaluate if className resizable must be enabled */
  const getResizableClassName = enableResizeColumns ? styles.resizable : '';
  /** Get sorting object based on enableSorting and enableMultiSorting */
  const getSortingObj = () => {
    if (!enableSorting && !enableMultiSorting) return null;
    if (enableMultiSorting) return sortingList.length ? sortingList : null;
    if (enableSorting) return sortingList.length ? sortingList[0] : null;
  };
  /** Get general state evaluating multi-sorting */
  const getGeneralState = () => {
    setRowsToBeUpdated([]);
    const stateParsed: IDataGridState = { ...generalDataGridState, sortOrder: getSortingObj() };
    return stateParsed;
  };

  return (
    <>
      <div className={styles.tableHeading}>
        <div>
          <div className={styles.tableHeadingFlex}>
            <Text variant='xLarge' className={styles.highlight}>{title}</Text>
            {enableShowFound && (
              <>
                <Text variant='xLarge' className={`${styles.marginLeftRight} ${styles.highlight}`}>{`${totalDataFound} Found`}</Text>
              </>
            )}
          </div>
          <div className={styles.searchInput}>
            {renderSearchOption()}
          </div>
        </div>
        <div className={styles.tableHeadingFlex}>
          {renderRowsPerPage()}
          {renderShowHideHeadCells()}
          {renderSelectedItems()}
          {renderDeleteOption()}
          {renderRefreshOption()}
        </div>
      </div>

      <div className={styles['table-wrapper']}>
        <table id={idTable} >
          <thead className={styles.thead}>
            <tr>
              {enableLeftCheckbox && renderSelectAllRows()}
              {actionsGroupName && (
                <th className={styles.actionsColumn}>
                  <div
                    className={getResizableClassName}
                  >
                    Actions
                  </div>
                </th>)}
              {buildHeadCellsTable()?.map((headCell: HeadCell, idx) => (
                headCell.disableSorting || (!enableSorting && !enableMultiSorting) ?
                  <th key={idx} className={getResizableClassName}>{headCell.name}</th>
                  : <th
                    key={idx}
                    id={headCell.fieldName}
                    onClick={createSortHandler}
                  >
                    <div
                      id={headCell.fieldName}
                      className={classNames(
                        getResizableClassName,
                        styles.divResizable,
                        getClassSorting(headCell.fieldName, headCell.disableSorting),
                      )}
                      onClick={createSortHandler}
                    >{headCell.name}</div></th>
              ))}
              {!enableLeftCheckbox && renderSelectAllRows()}
            </tr>
          </thead>
          <tbody>
            {!rows?.length && !isLoadingTable && (<tr><td colSpan={getColspanSize()}><span className={styles.emptyRowsTable}>No records found</span></td></tr>)}
            {isLoadingTable && (
              <tr key={'loading'}>
                <td colSpan={getColspanSize()}>
                  <span className={styles.emptyRowsTable}>
                    <Spinner id='loadingSpinner' size={SpinnerSize.large} className={styles.spinner} />
                  </span>
                </td>
              </tr>
            )}
            {!isLoadingTable && buildRowsTable()?.map((row: any, idxRow) => {
              const isItemSelected = isSelected(row);
              return (<RowComponent
                setRowFocused={setRowFocused}
                rowFocused={rowFocused}
                isFieldFocused={isFieldFocused}
                setIsFocused={setisFieldFocused}
                isSearchFoucused={isSearchFoucused}
                parentTableId={idTable}
                idxRow={idxRow}
                rowData={row}
                headCells={buildHeadCellsTable()}
                rowsData={rows}
                actionsGroupName={actionsGroupName}
                enableEditableRows={true}
                enableCheckBox={enableCheckBox}
                enableRowClick={enableRowClick}
                isItemSelected={isItemSelected}
                handleChangeSelectRow={handleChangeSelectRow}
                handleUpdate={handleUpdateRow}
                handleRowClick={handleRowClick}
                enableLeftCheckbox={enableLeftCheckbox}
              />);
            })}
          </tbody>
        </table>
      </div>
      {enablePagination && (
        <>
          <SeparatorGy />
          {
            Boolean(totalDataFound) && (<Pagination {...paginationProps} onChangePage={onChangePage} />)
          }
        </>
      )}

      <DeleteModalComponent
        isOpen={isDeletingDialogVisible}
        onCancel={toggleDeletingConfirmation}
        onSubmit={handleOnDelete}
        title={'Confirmation'}
        subText={'Are you sure you want to delete the following items?'}
        headcells={headCellList}
        rowsToDelete={selected}
        onSubmitLabel={'Delete'}
        onCancelLabel={'Cancel'}
      />
    </>
  );
};

export default DataGridComponent;