import { Checkbox, DatePicker, DateRangeType, DefaultButton, Dropdown, IDropdownOption, MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { debounce, get, isNil } from 'lodash';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import apiService from '../../../../api';
import useNotifications from '../../../../hooks/useNotifications';
import DataGridComponent from '../../../../shared/DataGridComponent';
import DialogComponent from '../../../../shared/DialogComponent';
import { IAvailableInvoice } from '../../../../models/IAvailableInvoice';
import { ICustomerDetails } from '../../../../models/ICustomerDetails';
import { INotification } from '../../../../models/INotification';
import { FormBuilderGroup } from '../../../../shared/FormBuilderComponent';
import { INVOICESTATUS_ENUM } from '../../../../models/EnumInvoiceStatuses';
import { ACTION_GROUPS_ENUM } from '../../../../shared/DataGridComponent/ActionsComponent/ActionsGroupEnum';
import { defaultDataGridPageSizes, initalDataGridState } from '../../../../shared/DataGridComponent/utils';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { auth_billing_viewInvoices } from '../../../../consts/programKeys';

import AutoCompleteField from '../../../Common/Search/AutoCompleteField';
import filterIcon from '../../../../assets/icons/filterIcon.svg';
import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import { ICustomer } from '../../../Common/Search/ICustomer';
import { downloadFile, printingTypes } from '../../../PrintingModal/consts';

import PrintingModalReportType from './PrintingModalReportType/PrintingModalReportType';
import ReportPrintingModal from './ReportPrintingModal/ReportPrintingModal';
import { reportTypes } from './PrintingModalReportType/consts';
import {
  availableInvoicesHeadCell,
  FILTERS_FIELDS,
  INVOICE_STATUSES,
  SearchOptions,
  SEARCH_FIELDS,
  HoldAction,
  RevertAction,
  ReleaseAction,
  IActionType,
} from './consts';
import CustomerDetails from './CustomerDetailsComponent';
import { downloadInvoiceReport } from './consts';

import styles from './ViewInvoices.module.scss';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

interface ViewInvoicesComponentProps {
  value?: any;
};

let reportInvertval: any;

const ViewInvoicesComponent: FunctionComponent<ViewInvoicesComponentProps> = () => {

  // hooks
  const { addNotification } = useNotifications();
  const history = useHistory();

  //state
  const [availableInvoicesList, setAvailableInvoicesList] = useState<Array<IAvailableInvoice>>([]);
  const [availableInvoicesSelected, setavailableInvoicesSelected] = useState<Array<IAvailableInvoice>>([]);
  const [countries, setCountries] = useState([]);
  const [regionCodes, setRegionCodes] = useState([]);
  const [customersList, setCustomerList] = useState<ICustomer[]>([]);
  const [invoiceTypes, setInvoiceTypes] = useState([]);
  const [customerDetails, setcustomerDetails] = useState<ICustomerDetails>();
  const [customSearchOption, setCustomSearchOption] = useState<SearchOptions>('regionSearch');
  const [customFilters, setcustomFilters] = useState<any>(null);
  const [customerSelected, setCustomerSelected] = useState<any>();
  const [countrySelected, setCountrySelected] = useState<string>('');
  const [totalFound, setTotalFound] = useState(0);
  const [totalAmount, setTotalAmount] = useState({ totalDebit: 0, totalCredit: 0 });
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });
  const [selectedAction, setSelectedAction] = useState<IActionType>(HoldAction);
  const [dataGridState, setDataGridState] = useState(initalDataGridState);
  const [reportFormat, setReportFormat] = useState('');
  const [customSortOrder, setCustomSortOrder] = useState<any>({ column: availableInvoicesHeadCell[6].fieldName, order: 'desc' });

  // toogles
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [showReport, { toggle: toggleShowReport }] = useBoolean(false);
  const [isDialogComponentOpen, { toggle: toggleDialogComponent }] = useBoolean(false);
  const [isLoadingAvailableTable, { toggle: toggleShowLoadingAvailableTable }] = useBoolean(false);
  const [isLoadingFetchCustomers, { toggle: toggleIsLoadingFetchCustomers }] = useBoolean(false);
  const [isLoadingFetchCustomerDetails, { toggle: toggleIsLoadingFetchCustomerDetails }] = useBoolean(false);
  const [viewCreditOnly, { toggle: toggleViewCreditOnly }] = useBoolean(false);
  const [viewCurrentYear, { toggle: toggleViewCurrentYear }] = useBoolean(false);
  const [reportId, setReportId] = useState('');
  const [isReportBannerVisible, setIsReportBannerVisible] = useState(false);
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(true);
  const [reportBannerLegend, setReportBannerLeggend] = useState('File generation in progress...');

  //permission
  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_billing_viewInvoices);

  //form validators
  const searchFormFieldsGroup = new FormBuilderGroup(SEARCH_FIELDS);
  const filterFormFieldsGroup = new FormBuilderGroup(FILTERS_FIELDS);

  const fetchAvailableInvoices = async (
    pagination: any = { pageSize: defaultDataGridPageSizes[0], pageNumber: 1 },
    filters?: any,
    sortOrder: any = customSortOrder,
  ) => {
    try {
      
      const { data: { data, total, totalCredit, totalDebit } }: any = await apiService.billingAPI.getAvailableInvoices(
        pagination,
        sortOrder,
        filters = enableFilterButton ? filters : null,
        customSearchOption == 'customerSearch' ? customerSelected?.id : null,
        customSearchOption == 'SAPSearch' ? searchFormFieldsGroup.getFieldFormValue('sapInvoiceNoField') : null,
        customSearchOption == 'regionSearch' ? searchFormFieldsGroup.getFieldFormValue('regionField') : null,
        customSearchOption == 'regionSearch' ? countrySelected : null,
      );
      setAvailableInvoicesList(data);
      setTotalAmount({ totalDebit, totalCredit });
      setTotalFound(total?.found);
      setCustomSortOrder(sortOrder);
    } catch (error) {
      setNotification({ text: 'Fetching tires error', type: MessageBarType.error });
    }
  };

  const fetchDeleteInvoices = async (ids: Array<string>) => {
    try {
      await apiService.billingAPI.deleteAvailableInvoices(ids);
      setNotification({ text: `${ids.length} invoices deleted successfully`, type: MessageBarType.success });
    } catch (error) {
      setNotification({ text: 'Deleting invoices error', type: MessageBarType.error });
    }
  };

  const fetchCountries = async () => {
    try {
      const { data: { countries } } = await apiService.customerAPI.getCountries();
      setCountries(countries);
      setCountrySelected(countries[1]);
    }
    catch (e: any) {
      setNotification({ text: 'Error fetching countries', type: MessageBarType.error });
    }
  };

  const fetchRegionCodes = async () => {
    try {
      const { data: { regionCodes } } = await apiService.customerAPI.getRegionCodes(countrySelected);
      setRegionCodes(regionCodes);
    } catch (e: any) {
      setNotification({ text: 'Error fetching region codes', type: MessageBarType.error });
    }
  };

  const fetchCustomers = async (
    filter: any,
    pagination?: any,
    sort: any = { column: 'custPrefix', order: 'asc' }) => {
    toggleIsLoadingFetchCustomers();
    try {
      const { data }: any = await apiService.getCustomerList(filter, pagination, sort);
      setCustomerList(data.data);
    } catch (e: any) {
      setNotification({ text: 'Customers fetching error.', type: MessageBarType.error });
    }
    toggleIsLoadingFetchCustomers();
  };

  const fetchInvoiceTypes = async () => {
    try {
      const { data }: any = await apiService.billingAPI.getInvoiceTypes();
      setInvoiceTypes(data);
    } catch (e: any) {
      setNotification({ text: 'Invoice types fetching error.', type: MessageBarType.error });
    }
  };

  const fetchCustomerDetails = async (invoiceId: string) => {
    toggleIsLoadingFetchCustomerDetails();
    try {
      const { data }: any = await apiService.billingAPI.getViewInvoiceCustomerDetails(invoiceId);
      setcustomerDetails(data);
    } catch (e: any) {
      setNotification({ text: 'Customers details fetching error.', type: MessageBarType.error });
    }
    toggleIsLoadingFetchCustomerDetails();
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    toggleShowLoadingAvailableTable();
    
    setDataGridState(dataGridState);
    const { countOnPage, paginationProps, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    if (validateFetchAvailableInvoices) {
      await fetchAvailableInvoices(pagination, customFilters, sortOrder);
    };
    toggleShowLoadingAvailableTable();
    
  };

  const handleSearch = async () => {
    toggleShowLoadingAvailableTable();
    
    await fetchAvailableInvoices();
    handleCleanFilters();
    toggleShowLoadingAvailableTable();
    
  };

  const handleFilterInvoices = async () => {
    toggleShowLoadingAvailableTable();
    
    const currentYear = viewCurrentYear ? viewCurrentYear : null;
    const duringTheMonth = currentYear ? null : filterFormFieldsGroup.getFieldFormValue('monthOfField');
    const filters = {
      byDate: {
        currentYear,
        duringTheMonth,
      },
      status: filterFormFieldsGroup.getFieldFormValue('statusField') === 'SHOW ALL' ? '' : filterFormFieldsGroup.getFieldFormValue('statusField'),
      invoiceType: filterFormFieldsGroup.getFieldFormValue('invoiceTypeField'),
      creditOnly: viewCreditOnly,
    };
    setcustomFilters(filters);
    await fetchAvailableInvoices(undefined, filters);
    toggleShowLoadingAvailableTable();

  };

  const handleSelectRow = (invoicesSelected: Array<IAvailableInvoice>) => {
    setavailableInvoicesSelected(invoicesSelected);
    setcustomerDetails(undefined);
    if (invoicesSelected.length == 1) {
      fetchCustomerDetails(invoicesSelected[0].invoiceId);
    };
  };

  const handleDeleteInvoices = async (rows: any[] = []) => {
    toggleShowLoadingAvailableTable();
    const invoicesIds = getIdsFromInvoices(availableInvoicesSelected);

    if (validateRowsBeforeDelete(rows)) {
      await fetchDeleteInvoices(invoicesIds);
      await fetchAvailableInvoices(undefined, customFilters);
    };

    toggleShowLoadingAvailableTable();
  };

  const handleChangeCustomSearch = (property: any) => {
    setCustomSearchOption(property || '');
  };

  const handleCleanSearch = () => {
    setCustomerSelected(null);
    searchFormFieldsGroup.cleanFormData();
  };

  const handleCleanFilters = () => {
    filterFormFieldsGroup.cleanFormData();
    if (viewCurrentYear) toggleViewCurrentYear();
    if (viewCreditOnly) toggleViewCreditOnly();
  };

  const validateRowsBeforeDelete = (rowsToDelete: any) => {
    let rowsCanBeDeleted = true;
    if (hasSuccessInvoices(rowsToDelete)) {
      setNotification({
        text: 'Invoices with status "SUCCESS" can not be deleted.',
        type: MessageBarType.error,
      });
      rowsCanBeDeleted = false;
    };
    if (hasInvoicesCreatedByMidas(rowsToDelete)) {
      setNotification({
        text: 'Invoices created by MIDAS can not be deleted.',
        type: MessageBarType.error,
      });
      rowsCanBeDeleted = false;
    };
    return rowsCanBeDeleted;
  };

  const removeEmpty = ({ text }: any) => text !== null;

  const getCustomerPrefixList = useMemo(() => customersList ?
    customersList.map(({ id, prefix }) => ({
      key: id,
      text: prefix,
    })).filter(removeEmpty) : [], [customersList]);


  const getCustomerNameList = useMemo(() => customersList ?
    customersList.map(({ id, customerName }) => ({
      key: id,
      text: customerName,
    })).filter(removeEmpty) : [], [customersList]);

  const customerPrefixInputText = (custPrefix: string) => {
    if (custPrefix.replace(/ /g, '').length) {
      getCustomersDebounce({ custPrefix });
    }
  };

  const customerNameInputText = (custName: string) => {
    if (custName.replace(/ /g, '').length) {
      getCustomersDebounce({ custName }, { column: 'customerName', order: 'asc' });
    }
  };

  const getCustomersDebounce = debounce(async (customerData, sortOrder?) => {
    fetchCustomers(customerData, undefined, sortOrder);
  }, 500);

  const onCustomerChange = async (newCustomerId: any) => {
    setCustomerSelected(getCustomerById(newCustomerId));
  };

  const handleCountryChange = (event: any, item: IDropdownOption<any> | undefined): void => {
    if (item?.text) setCountrySelected(item?.text);
  };

  const onFilterChange = ({ target: { id } }: any, item: IDropdownOption<any> | undefined): void => {
    filterFormFieldsGroup.setFormValue(`${id}Field`, item?.text);
  };

  const onSearchChange = ({ target: { id } }: any, item: IDropdownOption<any> | undefined): void => {
    searchFormFieldsGroup.setFormValue(`${id}Field`, item?.text);
  };

  const onSubmitClicked = async (functionString: string | undefined) => {
    toggleShowLoadingAvailableTable();
    toggleDialogComponent();
    const invoicesIds = getIdsFromInvoices(availableInvoicesSelected);
    const payload = {
      ids: invoicesIds,
    };
    try {
      switch (functionString) {
        case INVOICESTATUS_ENUM.HOLD:
          await apiService.billingAPI.postHold(payload);
          break;
        case INVOICESTATUS_ENUM.REVERT:
          await apiService.billingAPI.postRevert(payload);
          break;
        case INVOICESTATUS_ENUM.RELEASED:
          await apiService.billingAPI.postRelease(payload);
          break;
      }
      setNotification({ text: 'The state of the invoices has been changed', type: MessageBarType.success });
    } catch (e: any) {
      setNotification({ text: 'Error changing the state of the selected invoices', type: MessageBarType.error });
    }
    finally {
      await fetchAvailableInvoices(undefined, customFilters, customSortOrder);
      toggleShowLoadingAvailableTable();
    }
  };

  const getReportStatus = () => {
    if (!reportInvertval) {
      reportInvertval = setInterval(async () => {
        try {
          const response = await apiService.billingAPI.getReportById({ ReportId: reportId });
          if (response.data.status === 'DONE') {
            clearInterval(reportInvertval);
            reportInvertval = null;
            setReportBannerLeggend('File is ready for download.');
            setIsDownloadDisabled(false);
          }
        } catch (error) {
          setNotification({ text: 'Error fetching report status', type: MessageBarType.error });
        }
      }, 5000);
    }
  };

  const handlePrint = async (printingType: any, reportType: any) => {
    toggleIsLoadingFetchCustomers();
    const pagination = {
      pageSize: dataGridState.countOnPage.text,
      pageNumber: dataGridState.paginationProps.current,
    };
    const sortOrder = {
      column: dataGridState.sortOrder?.column,
      order: dataGridState.sortOrder?.order,
    };
    const filters = {
      byDate: {
        currentYear: viewCurrentYear,
        duringTheMonth: filterFormFieldsGroup.getFieldFormValue('monthOfField'),
      },
      status: filterFormFieldsGroup.getFieldFormValue('statusField'),
      invoiceType: filterFormFieldsGroup.getFieldFormValue('invoiceTypeField'),
      creditOnly: filterFormFieldsGroup.getFieldFormValue('creditOnlyField'),
    };

    const input = {
      pagination: pagination,
      sortOrder: sortOrder,
      filters: filters,
      customerId: customSearchOption == 'customerSearch' ?
        customerSelected?.id :
        null,
      sapInvoiceNumber: customSearchOption == 'SAPSearch' ?
        searchFormFieldsGroup.getFieldFormValue('sapInvoiceNoField') :
        null,
      regionCode: customSearchOption == 'regionSearch' ?
        searchFormFieldsGroup.getFieldFormValue('regionField') :
        null,
      country: customSearchOption == 'regionSearch' ? countrySelected : null,
    };
    const headerFields = [
      customSearchOption == 'customerSearch' ? { title: 'Customer Name', value: customerSelected?.customerName } : null,
      customSearchOption == 'regionSearch' ? { title: 'Region Code', value: searchFormFieldsGroup.getFieldFormValue('regionField') } : null,
    ];

    const payload = {
      input: input,
      headerFields: headerFields.filter(el => el !== null),
      printSapInvoiceNumber: reportType === reportTypes.sap,
    };

    try {
      toggleShowPrintExport();
      const response: any = printingType === printingTypes.excel ?
        await apiService.billingAPI.printExcelInvoices(payload) :
        await apiService.billingAPI.printPdfInvoices(payload);

      if (printingType === printingTypes.pdf) {
        setReportId(response.data.mCdScheduleReportFileQueueId);
        setIsReportBannerVisible(true);
        addNotification({
          text: `${response.data.reportName} has been requested, please click here to see the status, or go to My Reports tab.`,
          type: MessageBarType.info,
          handleClick: () => {
            history.push('/myreports');
          },
        });
      } else {
        addNotification({
          text: 'File was successfully received.',
          type: MessageBarType.success,
        });
        downloadFile(response.data, printingType);
      }
    } catch (e: any) {
      addNotification({
        text: 'Printing Error',
        type: MessageBarType.error,
      });
    }
    finally {
      toggleIsLoadingFetchCustomers();
    }
  };

  const handlePrintReport = async (reportName: any, reportFormat: string) => {
    toggleShowReport();
    toggleShowLoadingAvailableTable();
    toggleIsLoadingFetchCustomers();

    try {
      let response;
      if (reportFormat === 'excel') {
        response = await apiService.billingAPI.printInvoiceReportExcel({
          invoiceId: availableInvoicesSelected[0].invoiceId,
          reportName,
        });
      } else if (reportFormat === 'pdf') {
        response = await apiService.billingAPI.printInvoiceReport({
          invoiceId: availableInvoicesSelected[0].invoiceId,
          reportName,
        });
      } else {
        addNotification({
          text: 'Invalid Report Format',
          type: MessageBarType.error,
        });
      }

      setReportId(response?.data?.mCdScheduleReportFileQueueId);
      setReportFormat(reportFormat);
      setIsReportBannerVisible(true);
      addNotification({
        text: `${reportName} has been requested, please click here to see the status, or go to My Reports tab.`,
        type: MessageBarType.info,
        handleClick: () => {
          history.push('/myreports');
        },
      });
    } catch (e: any) {
      let notificationMessage = 'Printing Error';
      if (e?.response?.status === 400) {
        const resp = JSON.parse(
          String.fromCharCode.apply(
            null,
            [...new Uint8Array(e.response?.data)],
          ),
        );
        notificationMessage = `Printing Error : ${resp?.message}`;
      }
      addNotification({
        text: notificationMessage,
        type: MessageBarType.error,
      });
    }
    finally {
      toggleShowLoadingAvailableTable();
      toggleIsLoadingFetchCustomers();
    }
  };

  const downloadReport = async () => {
    toggleIsLoadingFetchCustomers();
    try {
      const { data } = await apiService.billingAPI.downloadReportById({ ReportId: reportId });
      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      
      if (reportFormat === 'excel') downloadFile(data, printingTypes.excel);
      else if (reportFormat === 'pdf') downloadFile(data, printingTypes.pdf);

    } catch (error) {
      addNotification({
        text: 'Printing Error',
        type: MessageBarType.error,
      });
    } finally {
      toggleIsLoadingFetchCustomers();
      setReportId('');
      setIsDownloadDisabled(true);
      setIsReportBannerVisible(false);
      setReportBannerLeggend('File generation in progress...');
    }
  };

  useEffect(() => {
    if (reportId !== '') {
      getReportStatus();
    }
  }, [reportId]);

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  useEffect(() => {
    if (countrySelected) fetchRegionCodes();
  }, [countrySelected]);

  useEffect(() => {
    if (!countries?.length) fetchCountries();
    if (!invoiceTypes?.length) fetchInvoiceTypes();
  }, []);

  const getCustomerById = (idToFind: number) => customersList?.find(({ id }) => id === idToFind) || null;
  const getIdsFromInvoices = (invoicesArray: Array<IAvailableInvoice>) => invoicesArray?.map((invoice: any) => invoice.invoiceId);
  const hasSuccessInvoices = (invoicesArray: Array<IAvailableInvoice>) => invoicesArray?.find((invoice: any) => invoice.status == 'SUCCESS') != undefined;
  const hasInvoicesCreatedByMidas = (invoicesArray: Array<IAvailableInvoice>) => invoicesArray?.find((invoice: any) => invoice.createdBy == 'MIDAS') != undefined;

  const enableSearchButton =
    (customSearchOption == 'regionSearch' && searchFormFieldsGroup.getFieldFormValue('regionField')) ||
    (customSearchOption == 'customerSearch' && customerSelected) ||
    (customSearchOption == 'SAPSearch' && searchFormFieldsGroup.getFieldFormValue('sapInvoiceNoField'));

  const enableFilterButton =
    viewCreditOnly || viewCurrentYear ||
    filterFormFieldsGroup.getFieldFormValue('monthOfField') ||
    filterFormFieldsGroup.getFieldFormValue('currentYearField') ||
    filterFormFieldsGroup.getFieldFormValue('statusField') ||
    filterFormFieldsGroup.getFieldFormValue('invoiceTypeField');

  const validateFetchAvailableInvoices = enableSearchButton || enableFilterButton;
  const disableChangeStatusButton = (statusToFilter: string) =>
    availableInvoicesSelected.length == 0 || availableInvoicesSelected.filter((invoice: any) => invoice.status != statusToFilter).length > 0;

  const disableReleaseBtn = (disableChangeStatusButton(INVOICESTATUS_ENUM.PENDING) &&
    disableChangeStatusButton(INVOICESTATUS_ENUM.HOLD)) ||
    !userPermissions.isWrite ||
    availableInvoicesSelected.length > 1;
  const disableHoldBtn = disableChangeStatusButton(INVOICESTATUS_ENUM.RELEASED) || !userPermissions.isWrite;
  const disableRevertBtn = disableChangeStatusButton(INVOICESTATUS_ENUM.RELEASED) || !userPermissions.isWrite;
  const disableReportBtn = (!(availableInvoicesSelected.length === 1 && !isNil(availableInvoicesSelected[0].availableReports)) || (availableInvoicesSelected[0].fMerged == 'Y' && availableInvoicesSelected[0].status == 'SUCCESS'));
  return (
    <>
      <div className='ms-grid'>
        <div className='ms-Grid-row'>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
            <Text variant='xxLarge' className={styles.highlight}>View Invoices</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
            <Text variant='xLarge' className={styles.highlight}>Search Available Invoices</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <div className={classNames('ms-Grid-col', 'ms-sm3', styles.marginTop20)}>
              <Dropdown
                label='Country'
                options={countries.map(country => ({
                  key: country,
                  text: country,
                }))}
                selectedKey={countrySelected}
                onChange={handleCountryChange}
              />
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm9', styles.customerDetailsContainer)}>
              <SeparatorGy />
            </div>
          </div>

          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
            <div className={classNames('ms-Grid-col', 'ms-sm3', styles.searchOptionContainer)}>
              <div className={classNames('ms-Grid-col', 'ms-sm12', styles.customerSearchOption_title)}>
                <Checkbox
                  onChange={() => handleChangeCustomSearch('regionSearch')}
                  checked={customSearchOption == 'regionSearch'}
                />
                <Text variant='mediumPlus' className={styles.highlight}>Search by Region</Text>
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                <Dropdown
                  {...searchFormFieldsGroup.getFieldForm('regionField')}
                  placeholder='Select a region'
                  options={regionCodes.map(region => ({
                    key: region,
                    text: region,
                  }))}
                  onChange={onSearchChange}
                  selectedKey={searchFormFieldsGroup.getFieldFormValue('regionField')}
                  disabled={customSearchOption != 'regionSearch'}
                />
              </div>
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm4', styles.searchOptionContainer)}>
              <div className={classNames('ms-Grid-col', 'ms-sm12', styles.customerSearchOption_title)}>
                <Checkbox
                  onChange={() => handleChangeCustomSearch('customerSearch')}
                  checked={customSearchOption == 'customerSearch'}
                />
                <Text variant='mediumPlus' className={styles.highlight}>Search by Customer</Text>
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                <AutoCompleteField
                  onChangeAction={onCustomerChange}
                  value={customerSelected?.id}
                  list={getCustomerPrefixList}
                  label="Customer Prefix"
                  data-testid='customer-prefix'
                  icon="FavoriteStar"
                  textValue={customerPrefixInputText}
                  isLoading={isLoadingFetchCustomers}
                  disabled={customSearchOption != 'customerSearch'}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                <AutoCompleteField
                  onChangeAction={onCustomerChange}
                  value={customerSelected?.id}
                  list={getCustomerNameList}
                  label="Customer Name"
                  data-testid='customer-name'
                  icon="Contact"
                  textValue={customerNameInputText}
                  isLoading={isLoadingFetchCustomers}
                  disabled={customSearchOption != 'customerSearch'}
                />
              </div>
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm3', styles.searchOptionContainer)}>
              <div className={classNames('ms-Grid-col', 'ms-sm12', styles.customerSearchOption_title)}>
                <Checkbox
                  onChange={() => handleChangeCustomSearch('SAPSearch')}
                  checked={customSearchOption == 'SAPSearch'}
                />
                <Text variant='mediumPlus' className={styles.highlight}>Search by SAP Invoice #</Text>
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                <TextField
                  {...searchFormFieldsGroup.getFieldForm('sapInvoiceNoField')}
                  disabled={customSearchOption != 'SAPSearch'}
                />
              </div>
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm2', styles.searchBtnContainer)}>
              <PrimaryButton
                id="searchBtn"
                text="Search"
                onClick={handleSearch}
                disabled={!enableSearchButton}
              />
              <DefaultButton onClick={handleCleanSearch} text="Clear Search" />
            </div>
          </div>
        </div>
      </div>

      <SeparatorGy />

      <div className='ms-grid'>
        <div className='ms-Grid-row'>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
            <img className={classNames(styles.filterIcon)} src={filterIcon} width="18px" />
            <Text variant='xLarge' className={styles.highlight}>Filter Search</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop20)}>
            <div className={classNames('ms-Grid-col', 'ms-sm3', styles.searchOptionContainer)}>
              <div className={classNames('ms-Grid-col', 'ms-sm8')}>
                <DatePicker
                  {...filterFormFieldsGroup.getFieldForm('monthOfField')}
                  placeholder={'Select a month...'}
                  calendarProps={{
                    highlightSelectedMonth: true,
                    isDayPickerVisible: false,
                    dateRangeType: DateRangeType.Month,
                  }}
                  value={filterFormFieldsGroup.getFieldFormValue('monthOfField') ?
                    moment(filterFormFieldsGroup.getFieldFormValue('monthOfField')).toDate() : undefined}
                  onSelectDate={date => filterFormFieldsGroup.setFormValue('monthOfField', date)}
                  formatDate={(date: any) => moment(date).format('MM/YY')}
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm4', styles.checkBoxContainer)}>
                <Checkbox
                  {...filterFormFieldsGroup.getFieldForm('currentYearField')}
                  onChange={toggleViewCurrentYear}
                  checked={viewCurrentYear}
                />
              </div>
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm3', styles.searchOptionContainer)}>
              <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                <Dropdown
                  {...filterFormFieldsGroup.getFieldForm('statusField')}
                  options={INVOICE_STATUSES.map(status => ({
                    key: status,
                    text: status,
                  }))}
                  onChange={onFilterChange}
                  selectedKey={filterFormFieldsGroup.getFieldFormValue('statusField')}
                  placeHolder='Select a status...'
                />
              </div>
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm4', styles.searchOptionContainer)}>
              <div className={classNames('ms-Grid-col', 'ms-sm8')}>
                <Dropdown
                  {...filterFormFieldsGroup.getFieldForm('invoiceTypeField')}
                  options={invoiceTypes?.map(invoiceType => ({
                    key: invoiceType,
                    text: invoiceType,
                  }))}
                  onChange={onFilterChange}
                  selectedKey={filterFormFieldsGroup.getFieldFormValue('invoiceTypeField')}
                  placeHolder='Select a Invoice type...'
                />
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm4', styles.checkBoxContainer)}>
                <Checkbox
                  {...filterFormFieldsGroup.getFieldForm('creditOnlyField')}
                  onChange={toggleViewCreditOnly}
                  checked={viewCreditOnly}
                />
              </div>
            </div>
            <div className={classNames('ms-Grid-col', 'ms-sm2', styles.filterBtnContainer)}>
              <PrimaryButton
                id="filterBtn"
                text="Apply Filters"
                onClick={handleFilterInvoices}
                disabled={!enableFilterButton}
              />
              <DefaultButton onClick={handleCleanFilters} text="Clear Filters" />
            </div>
          </div>
        </div>
      </div>

      <div className='ms-grid'>
        <div className='ms-Grid-row'>
          <div className={classNames('ms-Grid-col', 'ms-sm9', styles.marginTop20)}>
            <DataGridComponent
              idTable={'available-invoices-table'}
              title='Available Invoices'
              headCells={availableInvoicesHeadCell}
              defaultSorting={customSortOrder}
              rowsTable={availableInvoicesList}
              totalDataFound={totalFound || 0}
              actionsGroupName={ACTION_GROUPS_ENUM.VIEW_INVOICES}
              isLoading={isLoadingAvailableTable}
              enableCheckBox
              enablePagination
              enableRowsPerPage
              enableManageHeadCells
              enableResizeColumns
              enableDeleteOption={userPermissions.isWrite}
              enableLeftCheckbox={true}
              handleChangeDataGridState={handleChangeDataGridState}
              handleDelete={handleDeleteInvoices}
              handleSelectRow={handleSelectRow}
            />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm3', styles.customerDetailsContainer)}>
            <CustomerDetails
              isLoading={isLoadingFetchCustomerDetails}
              customerDetails={customerDetails}
              totalAmount={totalAmount}
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <div className={styles.buttonContainer}>
              <PrimaryButton text='Hold' id='holdBtn' disabled={disableHoldBtn}
                onClick={() => {
                  setSelectedAction(HoldAction);
                  toggleDialogComponent();
                }} />
              <PrimaryButton text='Revert Status' id='revertBtn' disabled={disableRevertBtn}
                onClick={() => {
                  setSelectedAction(RevertAction);
                  toggleDialogComponent();
                }} />
              <PrimaryButton text='Release' id='releaseBtn' disabled={disableReleaseBtn}
                onClick={() => {
                  setSelectedAction(ReleaseAction);
                  toggleDialogComponent();
                }} />
              <PrimaryButton onClick={toggleShowPrintExport} text="Print/Export" />
              <PrimaryButton
                text="Report"
                onClick={toggleShowReport}
                disabled={disableReportBtn}
              />
            </div>
          </div>
        </div>
      </div>
      <DialogComponent
        isOpen={isDialogComponentOpen}
        onCancel={toggleDialogComponent}
        onSubmit={() => onSubmitClicked(selectedAction.keyword)}
        title={'Confirmation'}
        subText={selectedAction.text}
        onSubmitLabel={'Accept'}
        onCancelLabel={'Cancel'}
      />

      <PrintingModalReportType
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />

      {
        userPermissions.isWrite && <ReportPrintingModal
          reportTypes={get(availableInvoicesSelected, '[0].availableReports', [])}
          isOpened={showReport}
          onClose={toggleShowReport}
          onPrint={handlePrintReport}
          printingTypes={printingTypes}
          invoiceId={getIdsFromInvoices(availableInvoicesSelected)}
        />
      }
      {isLoadingFetchCustomers && <LoadingScreen />}
      {
        isReportBannerVisible && <div style={{
          padding: '10px',
          width: '20rem',
          border: '1px solid',
          borderRadius: '3px',
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          background: 'white',
          right: '16px',
          top: '180px',
          zIndex: 10000000,
        }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text style={{
              color: '#114375',
            }} variant="large">
              {reportBannerLegend}
            </Text>
            <Text
              onClick={() => {
                setIsReportBannerVisible(false);
                clearInterval(reportInvertval);
                reportInvertval = null;
                setReportId('');
              }}
              style={{
                cursor: 'pointer',
                color: '#114375',
              }} variant="large">
              x
            </Text>
          </div>
          <div style={{
            marginTop: '16px',
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'end',
          }}>
            <PrimaryButton
              onClick={downloadReport}
              disabled={isDownloadDisabled}>
              Download
            </PrimaryButton>
          </div>
        </div>
      }
    </>
  );
};

export default ViewInvoicesComponent;