export const printingTypes = {
  excel: 'Excel',
  pdf: 'PDF',
};

export const reportTypes = {
  midas: 'Midas',
  sap: 'SAP',
};

export const downloadFile = (dataToBePrinted: any, printingType: any) => {
  const typeFilePrinted = printingTypes.excel?'data:application/vnd.ms-excel;base64':'application/pdf';
  const url = window.URL.createObjectURL(new Blob([dataToBePrinted], { type: typeFilePrinted }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download', 
    `report.${
      printingType === printingTypes.excel ? 'xlsx' : 
        printingType === printingTypes.pdf ? 'pdf' :
          'docx'
    }`,
  );
  document.body.appendChild(link);
  link.click();
};
