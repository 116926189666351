import React from 'react';
import {
  Separator,
  PrimaryButton,
  Modal,
  DefaultButton,
} from '@fluentui/react';
import styles from './BillingOptionsModal.module.scss';
import { locationsHeadCells } from './const';
import { IBillingOptionsModal } from './IBillingOptionsModal';
import DataGridComponent from '../../../../../../../shared/DataGridComponent';

function BillingOptionsModal({
  isLocationsModalOpen,
  customerLocations,
  setSelectedLocationrow,
  handleCloseLocationsModal,
  selectedLocationRow,
  enableButton,
  setCustomerLocation,
}: IBillingOptionsModal) {
  return (
    <Modal
      titleAriaId={'titleId'}
      isOpen={isLocationsModalOpen}
      onDismiss={() => { null; }}
      isBlocking={false}
      dragOptions={undefined}
      containerClassName={styles.locationsModal}
    >
      <div>
        <div style={{
          marginTop: '10px',
        }} className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div style={{
              display: 'flex',
            }} className="ms-Grid-col ms-sm3">
              <p style={{color:'#114375'}}>Customer Name</p>
              <Separator vertical />
            </div>
            <div style={{
              display: 'flex',
              height: '45px',
              alignItems: 'center',
              color:'#114375',
            }} className="ms-Grid-col ms-sm3">
              Available Locations
            </div>
          </div>
        </div>
        <div style={{
          marginTop: '0.5rem',
        }}>
          <DataGridComponent
            idTable={'billingOptions'}
            title='Locations'
            headCells={locationsHeadCells}
            rowsTable={customerLocations}
            totalDataFound={0}
            enableRowClick
            enableCheckBox
            isLoading={false}
            handleSelectRow={(row: any) => {
              if (row.length) {
                setSelectedLocationrow(row[0]);
              }
            }}
          />
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: '1rem',
        }}>
          <DefaultButton style={{
            marginRight: '0.5rem',
          }} onClick={handleCloseLocationsModal}>
            Cancel
          </DefaultButton>
          <PrimaryButton disabled={!selectedLocationRow || !enableButton} onClick={() => {
            if (selectedLocationRow) {
              setCustomerLocation(selectedLocationRow.id);
              handleCloseLocationsModal();
            }
          }}>
            Select as billing location
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
}

export default BillingOptionsModal;
