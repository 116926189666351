import { HeadCell } from '../DataGridComponent/DataGridModels';

export const vehicleHeadCells: Array<HeadCell> = [
  {
    key: 'column0',
    name: 'Vehicle Number',
    fieldName: 'vehicleNumber',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Make',
    fieldName: 'make',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Model',
    fieldName: 'model',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Wheel Position',
    fieldName: 'wheelPositions',
    typeField: 'text',
  },
  {
    key: 'column4',
    name: 'Year',
    fieldName: 'year',
    typeField: 'text',
  },
  {
    key: 'column5',
    name: 'Status',
    fieldName: 'status',
    typeField: 'text',
  },
  {
    key: 'column6',
    name: 'As Of',
    fieldName: 'asOf',
    typeField: 'text',
  },
  {
    key: 'column7',
    name: 'Comments',
    fieldName: 'comments',
    typeField: 'text',
  },
];