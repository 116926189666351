import { FC, FormEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { get, isNil } from 'lodash';
import {
  Checkbox,
  DefaultButton,
  Dropdown,
  IColumn,
  Icon,
  IconButton,
  IDropdownOption,
  MessageBarType,
  Modal,
  PrimaryButton,
  Text,
} from '@fluentui/react';

import useNotifications from '../../../../hooks/useNotifications';
import apiService from '../../../../api';
import { sortOrder } from '../../../../consts/sortOrder';

import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import Pagination from '../../../Pagination/Pagination';
import { IPaginationProps } from '../../../Pagination/IPaginationProps';

import { IManualRunModalProps } from './IManualRunModalProps';
import { IManualRunModalState } from './IManualRunModalState';
import { tableColumns, pageSizes } from './consts';

import styles from './ManualRunModal.module.scss';

const ManualRunModal: FC<IManualRunModalProps> = ({ isModalOpen, hideModal, taskInfo }) => {

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const { addNotification } = useNotifications();

  const [state, setState] = useState<IManualRunModalState>({
    items: [],
    selectedItems: [],
    loading: false,
    foundCount: 0,
  });
  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(tableColumns);

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const fetch = async () => {
    setState((prev: IManualRunModalState) => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.taskScheduler.getTasksForJob(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        taskInfo.mCdSchedid,
      );
      const foundCount = data.total.found;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, originItems: items, selectedItems: [], foundCount }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Tasks fetching error: ${get(response, 'data.message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleRunTasks = async () => {
    setState((prev: IManualRunModalState) => ({ ...prev, loading: true }));
    try {
      await apiService.taskScheduler.runTasksForJob(taskInfo.mCdSchedid, state.selectedItems);
      addNotification({
        text: 'Selected task(s) was(were) successfully ran.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Tasks running error: ${get(response, 'data.message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      fetch();
    }
  };

  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const handleSelect = (itemId: any, value: any) => {
    const selectedRows = [...state.selectedItems];
    if (value) {
      selectedRows.push(itemId);
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: selectedRows.filter(row => row !== itemId) }));
    }
  };

  useEffect(() => {
    if (!isNil(taskInfo) && isModalOpen) {
      fetch();
    }
  }, [
    taskInfo,
    columnsState,
    paginationProps.current,
    isModalOpen,
  ]);

  return (
    <Modal
      isOpen={isModalOpen}
      containerClassName={styles.modalContainer}
      onDismiss={hideModal}
    >
      <div className="ms-Grid">
        <div className={styles.modalHeader}>
          <Text variant="xxLarge" id="title">Manually run subtasks</Text>
          <IconButton
            id="closeButton"
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel="Close Modal"
            onClick={hideModal}
          />
        </div>
        <SeparatorGy />
        <div className={classNames('ms-Grid-row', styles.mainInfoBlock, styles.row)}>
          <div className={classNames('ms-Grid-col', 'ms-sm4')}>
            <Text variant="xLarge" id="title">{taskInfo?.jobName}</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm8')} />
        </div>
        <div className={classNames('ms-Grid-row', styles.row)}>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <div className="ms-Grid">
              <div className={classNames('ms-Grid-row', styles.mainRow)}>
                <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                  <div className={styles.tableHeading}>
                    <div>
                      <Icon iconName='Completed' className={styles.tableHeaderIcon} />
                      <Text variant="large">Manually run</Text>
                    </div>
                    <div>
                      <Text variant="large">Show # of rows:&nbsp;</Text>
                      <Dropdown
                        options={pageSizes.map(pageSize => ({
                          key: pageSize,
                          text: pageSize.toString(),
                        }))}
                        defaultSelectedKey={pageSizes[0]}
                        selectedKey={countOnPage?.key}
                        onChange={onChangeCountOnPage}
                      />
                      <SeparatorGy vertical />
                      <Text variant="xLarge">{state.foundCount} found</Text>
                    </div>
                  </div>
                  <div className={styles.tableWrapper}>
                    <table>
                      <thead>
                        <tr>
                          {
                            columnsState.map(item => (
                              <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                            ))
                          }
                          <th />
                        </tr>
                      </thead>
                      <tbody className={styles.tableWrapper}>
                        {
                          state.items.map((item: any) => {
                            const {
                              mCdSchedFunctionid,
                              runFunction,
                              status,
                              trigger,
                              nextTimeExecuted,
                              lastTimeExecuted,
                              lastRunResult,
                              enabled,
                              priority,
                            } = item;

                            return (
                              <tr key={mCdSchedFunctionid}
                                className={styles.trBasic}
                              >
                                <td>{priority}</td>
                                <td>{runFunction}</td>
                                <td>{status}</td>
                                <td>{trigger}</td>
                                <td>{nextTimeExecuted && moment(nextTimeExecuted).format('MM/DD/YYYY h:mm:ss a')}</td>
                                <td>{lastTimeExecuted && moment(lastTimeExecuted).format('MM/DD/YYYY h:mm:ss a')}</td>
                                <td>{lastRunResult}</td>
                                <td>{enabled ? 'Yes' : 'No'}</td>
                                <td>
                                  <Checkbox
                                    onChange={(ev: any, value) => handleSelect(mCdSchedFunctionid, value)}
                                    checked={state.selectedItems.includes(mCdSchedFunctionid)}
                                    disabled={!enabled}
                                  />
                                </td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                  {state.foundCount > countOnPage?.key && (
                    <>
                      <SeparatorGy />
                      <Pagination {...paginationProps} />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className={classNames('ms-Grid-col', 'ms-sm12', styles.buttonsWrapper)}>
                <DefaultButton
                  onClick={hideModal}
                  text="Cancel"
                />
                <PrimaryButton
                  onClick={handleRunTasks}
                  disabled={state.selectedItems.length === 0}
                  text="Run now"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {state.loading && <LoadingScreen />}
    </Modal>
  );
};

export default ManualRunModal;
