import { HeadCell } from '../../../../../shared/DataGridComponent/DataGridModels';
import { IAddEquipmentItem } from './IEquipmentState';

const equipmentHeadCell: HeadCell[] = [
  {
    key: 'column0',
    name: 'Tag',
    fieldName: 'tag',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column1',
    name: 'Description',
    fieldName: 'description',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column2',
    name: 'Status',
    fieldName: 'status',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column3',
    name: 'Age',
    fieldName: 'age',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column4',
    name: 'Location',
    fieldName: 'location',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column5',
    name: 'Acq Date',
    fieldName: 'acqDate',
    typeField: 'date',
    isEditable: false,
  },
  {
    key: 'column6',
    name: 'Updated By',
    fieldName: 'updatedBy',
    typeField: 'text',
    isEditable: false,
  },
  {
    key: 'column7',
    name: 'Updated On',
    fieldName: 'updatedOn',
    typeField: 'date',
    isEditable: false,
  },
  {
    key: 'column8',
    name: 'Comments',
    fieldName: 'comments',
    typeField: 'text',
    isEditable: false,
  },
];

type SortColumn = 'tag' | 'description' |
  'status' | 'age' | 'location' | 'acqDate' |
  'updatedBy' | 'updatedOn' | 'comments'

const getSortColumn = (column:SortColumn) => {
  return sortMapper[column];
};

const sortMapper = {
  'tag': 'tag',
  'description': 'description',
  'status': 'status',
  'age': 'age',
  'location': 'location',
  'acqDate': 'acqDate',
  'updatedBy': 'updatedBy',
  'updatedOn': 'updatedOn',
  'comments': 'comments',
};

const emptyEquipment: IAddEquipmentItem = {
  mCbCustomerId: '',
  tagNumber: '',
  description: '',
  status: '',
  comments: '',
  locationId: '',
};

export {
  equipmentHeadCell,
  getSortColumn,
  emptyEquipment,
};