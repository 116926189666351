import { FC, ReactElement, useState, useEffect } from 'react';

import styles from './MileageAuditTab.module.scss';
import classNames from 'classnames';
import { IMileageAuditTabProps } from './IMileageAuditTabProps';
import { IMileageAuditTabState } from './IMileageAuditTabState';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';

import apiService from '../../../../../../api';
import { useBoolean } from '@fluentui/react-hooks';
import { sortOrder } from '../../../../../../consts/sortOrder';
import {
  Dropdown,
  IColumn,
  IconButton,
  IDropdownOption,
  MessageBarType,
  Text,
} from '@fluentui/react';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import moment from 'moment';
import Pagination from '../../../../../Pagination/Pagination';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import { useDispatch, useSelector } from 'react-redux';
import { customerSelector, locationSelector } from '../../../../../../redux/recordKeepingSlice';
import { pushToast } from '../../../../../../redux/notificationsSlice';
import { columns } from './consts';
import { pageSizes } from '../../../../../../consts/recordKeeping';
import MileageAudit from '../../MileageAudit/MileageAudit';
import useNotifications from '../../../../../../hooks/useNotifications';

const MileageAuditTab: FC<IMileageAuditTabProps> = ({ isWrite }): ReactElement => {
  const [state, setState] = useState<IMileageAuditTabState>({
    loading: false,
    items: [],
    selectedItems: [],
    foundCount: 0,
  });


  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });

  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [currentFilters, setCurrentFilters] = useState<any>({
    from: '',
    to: '',
  });
  const [billingYearList, setBillingYearList] = useState<Array<any>>([]);
  const [filters, setFilters] = useState<string>('');
  const [isMileageAuditShow, { setTrue: showMileageAuditEdit, setFalse: hideMileageAuditEdit }] = useBoolean(false);
  const [isEditing, setIsEdit] = useState<any>(null);
  const [isEditable, setIsEditable] = useState<boolean>(false);

  const { addNotification } = useNotifications();
  const dispatch = useDispatch();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);

  const [columnsState, setColumnsState] = useState<Array<any>>(columns);

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const openMileageAuditEdit = async (item: any) => {
    hideMileageAuditEdit();
    setIsEdit(item.id);
    setIsEditable(item.completedStatus !== 'S' ? true : false);
    setCurrentFilters((prev: any) => ({
      ...prev,
      from: moment(item.fromBillPeriod).format('MM/DD/YYYY'),
      to: moment(item.toBillPeriod).format('MM/DD/YYYY'),
    }));
    showMileageAuditEdit();
  };

  const fetchMileageRun = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.mileageRun.getMileageAuditDetails(
        filters,
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
      );
      const foundCount = data.total.all;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      const { response } = e;
      dispatch(
        pushToast({
          text: `Mileage Run fetching error: ${response.data.message}`,
          type: MessageBarType.error,
        }),
      );
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleCancel = () => {
    setIsEdit(null);
    hideMileageAuditEdit();
  };

  const fetchBillingYearList = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.mileageRun.getBillingYearList();
      setBillingYearList(data.data);
      setFilters(new Date().getFullYear().toString());
    } catch (e: any) {
      addNotification({
        text: `Mileage Run fetching billing period error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(
      ({ isSorted }) => isSorted,
    );
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  useEffect(() => {
    fetchBillingYearList();
  }, []);

  useEffect(() => {
    handleCancel();
    if (filters) {
      fetchMileageRun();
    }
  }, [paginationProps.current, countOnPage, columnsState, filters, customerId]);

  return (
    <>
      <div>
        <div>
          <div className={styles.tableHeading}>
            <div>
              <Text variant="xLarge" className={styles.highlight}>Mileage Audit Period</Text>
              <SeparatorGy vertical />
              <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
            </div>
            <div>
              <Text variant="large" className={styles.highlight}>Year</Text>
              <SeparatorGy vertical />
              <Dropdown
                options={billingYearList.map(item => ({
                  key: item.year,
                  text: item.year,
                }))}
                defaultSelectedKey={filters}
                selectedKey={filters}
                onChange={(e, selectedOption: any) => setFilters(selectedOption.key)}
                styles={{ dropdown: { width: 80 } }}
              />
            </div>
            <div>
              <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
              <Dropdown
                options={pageSizes.map(pageSize => ({
                  key: pageSize,
                  text: pageSize.toString(),
                }))}
                defaultSelectedKey={pageSizes[0]}
                selectedKey={countOnPage?.key}
                onChange={onChangeCountOnPage}
              />
            </div>
          </div>
          <div className={styles['table-wrapper']}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th className={styles.viewColumn}></th>
                  {
                    columnsState.map(item => (
                      <th
                        key={item.name}
                        className={classNames(
                          item.isSorted && item.isSortedDescending ?
                            styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined,
                          styles[item.key],
                        )}
                        onClick={() => onColumnClick(item)}>{item.name}
                      </th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {state.items.map(item => (
                  <tr key={item.id} className={isEditing == item.id ? styles.trSelected : styles.trBasic}>
                    <td>
                      <IconButton
                        id="editButton"
                        iconProps={{ iconName: isWrite && item.completedStatus !== 'S' ? 'Edit' : 'View' }}
                        className={classNames(styles.viewButton)}
                        onClick={() => openMileageAuditEdit(item)}
                      />
                    </td>
                    <td>{item.fromBillPeriod && moment(item.fromBillPeriod).format('MM/DD/YYYY')}</td>
                    <td>{item.toBillPeriod && moment(item.toBillPeriod).format('MM/DD/YYYY')}</td>
                    <td>{item.busesCount}</td>
                    <td>{item.zmvCount}</td>
                    <td>{item.status}</td>
                    <td>{item.completedStatus}</td>
                  </tr>
                ),
                )
                }
              </tbody>
            </table>
          </div>
          <SeparatorGy />
          <Pagination {...paginationProps} />
        </div>
        {isMileageAuditShow && <MileageAudit billingPeriodId={isEditing} isEditable={isEditable && isWrite} filters={currentFilters} />}
      </div>
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default MileageAuditTab;