import axios from 'axios';

const headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
};

export const getToken = () => {
  const currentEnvironment = 'login.windows.net';

  const sessions = Object.keys(window.sessionStorage).map(
    (key: any) => JSON.parse(sessionStorage.getItem(key) || ''),
  ).filter(({ environment }: any) => environment === currentEnvironment);

  const { tokenType, secret: accessTokenSecret } = sessions.find(({ credentialType }: any) => credentialType === 'AccessToken');
  const { secret: idTokenSecret } = sessions.find(({ credentialType }: any) => credentialType === 'IdToken');

  return {
    tokenType, idTokenSecret, accessTokenSecret,
  };
};

const authInterceptor = (config: any) => {

  const { tokenType, accessTokenSecret, idTokenSecret } = getToken();

  config.headers.Authorization = `${tokenType} ${idTokenSecret}`;
  config.headers['x-access-token'] = `${tokenType} ${accessTokenSecret}`;

  return config;
};

export const homeTestInstance = axios.create({
  baseURL: process.env.REACT_APP_HOME_API,
  headers,
});
homeTestInstance.interceptors.request.use(authInterceptor);

export const recordTestInstance = axios.create({
  baseURL: process.env.REACT_APP_RECORDS_API,
  headers,
});
recordTestInstance.interceptors.request.use(authInterceptor);

export const tireOrderingTestInstance = axios.create({
  baseURL: process.env.REACT_APP_TIRE_ORDERING_API,
  headers,
});
tireOrderingTestInstance.interceptors.request.use(authInterceptor);

export const customerTestInstance = axios.create({
  baseURL: process.env.REACT_APP_CUSTOMER_API,
  headers,
});
customerTestInstance.interceptors.request.use(authInterceptor);

export const exportTestInstance = axios.create({
  baseURL: process.env.REACT_APP_EXPORTING_API,
  headers,
});
exportTestInstance.interceptors.request.use(authInterceptor);

export const managementTestInstance = axios.create({
  baseURL: process.env.REACT_APP_MANAGEMENT_API,
  headers,
});
managementTestInstance.interceptors.request.use(authInterceptor);

export const quoteTestInstance = axios.create({
  baseURL: process.env.REACT_APP_QUOTE_API,
  headers,
});
quoteTestInstance.interceptors.request.use(authInterceptor);

export const termTestInstance = axios.create({
  baseURL: process.env.REACT_APP_TERMS_API,
  headers,
});
termTestInstance.interceptors.request.use(authInterceptor);

export const billingTestInstance = axios.create({
  baseURL: process.env.REACT_APP_BILLING_API,
  headers,
});
billingTestInstance.interceptors.request.use(authInterceptor);

export const reportTestInstance = axios.create({
  baseURL: process.env.REACT_APP_REPORTING_API,
  headers,
});
reportTestInstance.interceptors.request.use(authInterceptor);
