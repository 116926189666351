
import { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { debounce, get, isNil } from 'lodash';
import { useBoolean } from '@fluentui/react-hooks';
import { DefaultButton, Dropdown, IColumn, IconButton, IDropdownOption, MessageBarType, Text } from '@fluentui/react';
import { useSelector } from 'react-redux';

import apiService from '../../../../../../api';
import AutocompleteInput from '../../../../../../shared/AutocompleteInput';
import useNotifications from '../../../../../../hooks/useNotifications';
import { sortOrder } from '../../../../../../consts/sortOrder';
import { customerSelector } from '../../../../../../redux/recordKeepingSlice';

import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../../Pagination/Pagination';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';

import { tableColumns } from './consts';
import { pageSizes } from '../../../../../../consts/recordKeeping';
import { IViewVehicleHistoryProps } from './IViewVehicleHistoryProps';
import { IViewVehicleHistoryState } from './IViewVehicleHistoryState';

import styles from './ViewVehicleHistory.module.scss';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';

const ViewVehicleHistory: FC<IViewVehicleHistoryProps> = (): ReactElement => {
  const [state, setState] = useState<IViewVehicleHistoryState>({
    vehNoList: [],
    vehicleInfo: {},
    items: [],
    foundCount: 0,
    loading: false,
  });

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });

  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [currentVehNo, setCurrentVehNo] = useState<any>(null);
  const [currentVehId, setCurrentVehId] = useState<any>(null);
  const [columnsState, setColumnsState] = useState<Array<any>>(tableColumns);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);

  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    setColumnsState(newColumns);
  };

  const getSortOrder = () => {
    const column = columnsState.find(({ isSorted }) => isSorted);
    if (isNil(column))
      return;
    const { fieldName, isSortedDescending } = column;
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const delayedFetchVehicles = useCallback(debounce(vehicleNumber => fetchVehicleNumbers(vehicleNumber), 700), []);

  const fetchVehicleNumbers = async (vehicleNumber: string) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.getActiveInactiveVehicleNumbers(
        vehicleNumber,
        customerId,
      );
      setState((prev: any) => ({ ...prev, vehNoList: data.data }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching vehicle numbers error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchVehicleHistory = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.viewVehicleHistory.get(
        currentVehId,
        state.vehNoList.find(el => el.name === currentVehNo)?.name,
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
      );
      const items = data.data.data;
      const foundCount = data.data.total.found;
      const info = data.data;
      setState((prev: any) => ({ ...prev, items, foundCount, vehicleInfo: info }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching vehicles error: ${response?.desc?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const vehicleNumberInputText = (vehicleNumber: string) => {
    setCurrentVehNo(vehicleNumber);
    setState(prev => ({ ...prev, items: [], foundCount: 0, vehicleInfo: {} }));
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        currentVehNo,
        vehNo: currentVehNo,
        pagination: { pageNumber: 1, pageSize: 10 },
        sortOrder,
        customerId,
      };
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.viewVehicleHistory.printExcel(requestData, []) :
        await apiService.viewVehicleHistory.printPdf(requestData, []);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Printing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };


  const hasVehicleNumber: boolean = state.vehNoList.find((vNo: any) => vNo.number === currentVehNo);


  useEffect(() => {
    const vehicleNuberWithoutSpace = currentVehNo?.replace(/ /g, '');
    if (vehicleNuberWithoutSpace?.length > 0) {
      delayedFetchVehicles(currentVehNo);
    }
  }, [currentVehNo]);


  useEffect(() => {
    if (currentVehNo !== null) {
      fetchVehicleHistory();
    }
  }, [
    paginationProps.current,
    columnsState,
    countOnPage,
  ]);

  return (
    <>
      <div>
        <div className={styles.vehicleInfoBlock}>
          <div>
            <Text variant="xLarge" className={styles.highlight}>Search a Vehicle</Text>
            <SeparatorGy />
            <div className={classNames(styles.searchField)}>
              <AutocompleteInput
                label="Vehicle #"
                list={state.vehNoList.map((vehicle) => ({
                  key: vehicle.id,
                  text: vehicle.name,
                }))}
                emptyExpanded
                required
                textValue={vehicleNumberInputText}
                chooseCurrentItem={setCurrentVehId}
              />
              <IconButton
                id="searchVehicle"
                iconProps={{ iconName: 'Search' }} 
                onClick={fetchVehicleHistory}
                disabled={!state.vehNoList.find(el => el.name === currentVehNo)}
              />
            </div>
          </div>
          <div>
            <Text variant="xLarge" className={styles.highlight}>Vehicle Info</Text>
            <SeparatorGy />
            <div>
              <div>
                <Text variant="large" className={styles.highlight}>{get(state, 'vehicleInfo.make', 'N/A')}</Text>
                <Text variant="medium" className={styles.highlight}>Make</Text>
              </div>
              <div>
                <Text variant="large" className={styles.highlight}>{get(state, 'vehicleInfo.model', 'N/A')}</Text>
                <Text variant="medium" className={styles.highlight}>Model</Text>
              </div>
              <div>
                <Text variant="large" className={styles.highlight}>{get(state, 'vehicleInfo.status', 'N/A')}</Text>
                <Text variant="medium" className={styles.highlight}>Status</Text>
              </div>
              <div>
                <Text variant="large" className={styles.highlight}>{state?.vehicleInfo?.asOf ? moment(state.vehicleInfo.asOf).format('MM/DD/YYYY') : 'N/A'}</Text>
                <Text variant="medium" className={styles.highlight}>As Of</Text>
              </div>
              <div>
                <Text variant="large" className={styles.highlight}>{state?.vehicleInfo?.asOfLastMileageRun ? moment(state.vehicleInfo.asOfLastMileageRun).format('MM/DD/YYYY') : 'N/A'}</Text>
                <Text variant="medium" className={styles.highlight}>As Of Last Mileage Run</Text>
              </div>
              <div>
                <Text variant="large" className={styles.highlight}>{get(state, 'vehicleInfo.comments', 'N/A')}</Text>
                <Text variant="medium" className={styles.highlight}>Comments</Text>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.tableHeading}>
          <div>
            <Text variant="xLarge" className={styles.highlight}>Vehicle History</Text>
            <SeparatorGy vertical />
            <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
          </div>
          <div>
            <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
            <Dropdown
              options={pageSizes.map(pageSize => ({
                key: pageSize,
                text: pageSize.toString(),
              }))}
              defaultSelectedKey={pageSizes[0]}
              selectedKey={countOnPage?.key}
              onChange={onChangeCountOnPage}
            />
          </div>
        </div>
        <div className={styles['table-wrapper']}>
          <table>
            <thead>
              <tr>
                {
                  columnsState.map(item => (
                    <th
                      key={item.name}
                      className={classNames(
                        item.isSorted && item.isSortedDescending ?
                          styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined,
                        styles[item.key],
                      )}
                      onClick={() => onColumnClick(item)}>{item.name}
                    </th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {
                state.items.length ?
                  state.items.map((item: any, index) => (
                    <tr key={item.id + index}>
                      <td>{item.pos}</td>
                      <td>{item.fundo}</td>
                      <td>{item.pfx}</td>
                      <td>{item.brandNo}</td>
                      <td>{item.sfx}</td>
                      <td>{item.fcot}</td>
                      <td>{item.from && moment(item.from).format('MM/DD/YYYY')}</td>
                      <td>{item.to && moment(item.to).format('MM/DD/YYYY')}</td>
                      <td>{item.units}</td>
                      <td>{item.locCode}</td>
                    </tr>
                  )) :
                  <tr><td colSpan={columnsState.length}><span className={styles.emptyRowsTable}>No records found</span></td></tr>
              }
            </tbody>
          </table>
        </div>
        <SeparatorGy />
        <Pagination {...paginationProps} />
        <div className={classNames(styles.buttonsWrapper)}>
          <DefaultButton 
            id='printExportBtn' 
            onClick={toggleShowPrintExport}
            text="Print/Export" 
            disabled={!hasVehicleNumber}
          />
        </div>
        <PrintingModal
          isOpened={showPrintExport}
          onClose={toggleShowPrintExport}
          onPrint={handlePrint}
        />
      </div>
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default ViewVehicleHistory;