import { DatePicker, DateRangeType, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IColumn, IDropdownOption, MessageBarType, PrimaryButton, Text, TextField } from '@fluentui/react';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { sortOrder } from '../../../../../consts/sortOrder';
import { customerSelector } from '../../../../../redux/recordKeepingSlice';
import { IMileageAuditProps } from './IMileageAuditProps';
import { IMileageAuditState } from './IMileageAuditState';
import { useBoolean } from '@fluentui/react-hooks';
import apiService from '../../../../../api';
import styles from './MileageAudit.module.scss';
import { postTypeOptions, columns } from './consts';
import { pageSizes, idleReason } from '../../../../../consts/recordKeeping';
import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../Pagination/Pagination';
import classNames from 'classnames';
import LoadingScreen from '../../../../LoadingScreen/LoadingScreen';
import MassUpload from '../../../../MassUpload/MassUpload';
import useNotifications from '../../../../../hooks/useNotifications';
import PrintingModal from '../../../../PrintingModal/PrintingModal';
import ErrorsModal from '../../../../ErrorsModal/ErrorsModal';
import { downloadFile, printingTypes } from '../../../../PrintingModal/consts';
import moment from 'moment';

const MileageAudit: FC<IMileageAuditProps> = ({ billingPeriodId, isEditable, filters }): ReactElement => {

  const [state, setState] = useState<IMileageAuditState>({
    loading: false,
    items: [],
    selectedItems: [],
    foundCount: 0,
  });

  const [changedItems, setChangedItem] = useState<Array<any>>([]);

  const [showUpload, { toggle: toggleShowUpload }] = useBoolean(false);

  const [paginationProps, setPaginationProps] = useState<any>({
    total: 0,
    current: 1,
  });

  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);

  const [postType, setPostType] = useState<string>(postTypeOptions[0].key);
  const [location, setLocation] = useState<any>(null);
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[2], text: pageSizes[0].toString() });
  const [isNotReported, { toggle: toggleIsNotReported }] = useBoolean(false);
  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [showErrorsModal, { toggle: toggleShowErrorsModal }] = useBoolean(false);
  const [isSubmitReady, { setTrue: submitReady, setFalse: submitNotReady }] = useBoolean(true);
  const defaultDate = new Date('0001-01-01');

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(
      ({ isSorted }) => isSorted,
    );
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const hasEmptyComment = () => {
    const emptyCommentList = state.items.filter(item => {
      if (item?.idleReason === 'Other' && (item?.comment === '' || item?.comment?.trim().length === 0 || item?.comment === null)) {
        return item;
      }
    });

    return emptyCommentList?.length > 0; // if true disable submit
  };


  const onChangeFieldValueHandler = (itemIndex: number, field: string, value: any, mileageAuditDetailsId?: string) => {
    if (field === 'comment' && value !== null) {
      submitReady();
    }
    const item = state.items[itemIndex];
    const newItem = {
      ...item,
      [field]: value,
    };

    setState((prev: any) => ({
      ...prev,
      items: prev.items.map((item: any, key: any) => key === itemIndex ? { ...item, [field]: value } : item),
    }));

    const tempChangedItems = changedItems;
    const changedItemIndex = tempChangedItems.findIndex(cItem => cItem.mileageAuditDetailsId === mileageAuditDetailsId);
    if (changedItemIndex > -1) {
      tempChangedItems[changedItemIndex] = {
        ...newItem,
      };
      setChangedItem(tempChangedItems);
    } else {
      tempChangedItems.push(newItem);
      setChangedItem(tempChangedItems);
    }

  };

  const onChangeIdleReason = (itemIndex: number, comment: string, idleReason: any, mileageAuditDetailsId?: string) => {
    if (idleReason === 'Other' && comment === null) {
      submitNotReady();
      addNotification({
        text: 'An item with Idle Reason "Other" requires to have a comment. Please input before you can Submit Mileage Audit',
        type: MessageBarType.error,
      });
    }

    const item = state.items[itemIndex];
    const newItem = {
      ...item,
      idleReason,
    };

    setState((prev: any) => ({
      ...prev,
      items: prev.items.map((item: any, key: any) => key === itemIndex ? { ...item, idleReason } : item),
    }));

    const tempChangedItems = changedItems;
    const changedItemIndex = tempChangedItems.findIndex(cItem => cItem.mileageAuditDetailsId === mileageAuditDetailsId);
    if (changedItemIndex > -1) {
      tempChangedItems[changedItemIndex] = {
        ...newItem,
      };
      setChangedItem(tempChangedItems);
    } else {
      tempChangedItems.push(newItem);
      setChangedItem(tempChangedItems);
    }
  };

  const commentSave = (itemIndex: number, field: string, value: any) => {
    setState((prev: any) => ({
      ...prev,
      items: prev.items.map((item: any, key: any) => key === itemIndex ? { ...item, [field]: value } : item),
    }));
  };

  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const submitMileageAuditDetails = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      await apiService.mileageRun.submitMileageAuditDetails(
        changedItems,
        billingPeriodId,
        customerId,
        location,
      );
      addNotification({
        text: 'Mileage Audit Details submitted.',
        type: MessageBarType.success,
      });
      mountingProcess();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Mileage Audit Details save error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const mileageAuditDetailsData = {
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        billingPeriodId,
        customerId,
        locationId: location,
      };
      const mileageAuditPeriodData = {
        billingPeriodId,
        customerId,
      };
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.mileageAuditAPI.printExcelMileageAuditReport(mileageAuditDetailsData, mileageAuditPeriodData, []) :
        await apiService.mileageAuditAPI.printPdfMileageAuditReport(mileageAuditDetailsData, mileageAuditPeriodData, []);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const mountingProcess = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    const sortOrder = getSortOrder();
    const { data }: any = await apiService.mileageRun.getMileageAuditDetailsByPeriodId(
      { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
      sortOrder,
      billingPeriodId,
      customerId,
      location,
    );
    const foundCount = data.total.all;
    const items = data.data;
    
    if (items.length) {

      const emptyCommentList = items.filter((item: any) => {
        if (item?.idleReason === 'Other' && (item?.comment === '' || item?.comment?.trim().length === 0 || item?.comment === null)) {
          return item;
        }
      });

      if (emptyCommentList.length > 0  && isEditable) {
        addNotification({
          text: 'An item with Idle Reason "Other" requires to have a comment. Please input before you can Submit Mileage Audit',
          type: MessageBarType.error,
        });
      }
    }
    setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    setState((prev: any) => ({ ...prev, items: items, foundCount, selectedItems: [], loading: false }));
  };

  const mountingProcessForData = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    setState((prev) => ({ ...prev, loading: false }));
  };

  const onChangePage = (newPage: number) => {
    setPaginationProps((prev: any) => ({ ...prev, current: newPage }));
  };

  useEffect(() => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
  }, [billingPeriodId]);

  useEffect(() => {
    mountingProcess();
  }, [billingPeriodId, postType, location, paginationProps.current, columnsState, countOnPage, isNotReported]);

  return (
    <>
      <div className={styles.modalContainer}>
        <div className={styles.modalBody}>

          <div>
            <div className={styles.tableHeading}>
              <div>
                <Text variant="xLarge" className={styles.highlight}>Mileage Audit Detail</Text>
                <SeparatorGy vertical />
                <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
              </div>
              <div>
                <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                <Dropdown
                  options={pageSizes.map(pageSize => ({
                    key: pageSize,
                    text: pageSize.toString(),
                  }))}
                  defaultSelectedKey={pageSizes[2]}
                  selectedKey={countOnPage?.key}
                  onChange={onChangeCountOnPage}
                />

              </div>
            </div>
            <div className={styles['table-wrapper']}>
              <table>
                <thead>
                  <tr>
                    {
                      columnsState.map(item => (
                        <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    state.items.map((item, key) => (
                      <tr key={key} className={styles.trBasic}>
                        <td className={styles.vNo}>
                          {item.vNo}
                        </td>
                        <td>
                          <DatePicker
                            className={styles.datePickerIdle}
                            value={moment(item.idleSince).toDate()}
                            calendarProps={{
                              highlightSelectedMonth: true,
                              isDayPickerVisible: false,
                              dateRangeType: DateRangeType.Month,
                            }}
                            formatDate={(date: any) => moment(date).format('MM/YY')}
                            disabled
                          />
                        </td>
                        <td>
                          <DatePicker
                            className={styles.datePickerInfoRecorded}
                            value={(moment(item.infoRecorded).toDate()) > defaultDate ? moment(item.infoRecorded).toDate() : undefined}
                            onSelectDate={(date) => onChangeFieldValueHandler(key, 'infoRecorded', date?.toUTCString(), item.mileageAuditDetailsId)}
                            calendarProps={{
                              highlightSelectedMonth: true,
                            }}
                            disabled={!isEditable}
                          />
                        </td>
                        <td className={styles.vNo}>{item.equipped}</td>
                        <td>
                          <Dropdown
                            id="idleReason"
                            placeholder="Select an option"
                            defaultSelectedKey={item.idleReason}
                            options={idleReason}
                            onChange={(ev, value) => onChangeIdleReason(key, item.comment, value?.text, item.mileageAuditDetailsId)}
                            disabled={!isEditable}
                          />
                        </td>
                        <td>
                          <TextField
                            id="comment"
                            value={item.comment}
                            onChange={(ev, value) => commentSave(key, 'comment', value as string)}
                            onBlur={() => onChangeFieldValueHandler(key, 'comment', item.comment, item?.mileageAuditDetailsId)}
                            multiline
                            rows={2}
                            cols={100}
                            required={item.idleReason === 'Other' ? true : false}
                            disabled={!isEditable}
                          />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <SeparatorGy />
            <Pagination {...paginationProps} onChangePage={onChangePage} />
            <div className={classNames(styles.buttonsWrapper)}>
              <PrimaryButton
                id="submitButton"
                onClick={submitMileageAuditDetails}
                text="Submit"
                disabled={!isEditable || hasEmptyComment()}
              />
            </div>

          </div>
        </div>
        <div className={classNames(styles.buttonsWrapper)}>
          <DefaultButton
            id="printExportButton"
            onClick={toggleShowPrintExport}
            text="Print/Export"
          />
        </div>
      </div>
      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to delete ${state.selectedItems.length} items?`,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <MassUpload
        isModalOpen={showUpload}
        hideModal={toggleShowUpload}
        formType={'Monthly Mileage'}
        fetch={mountingProcessForData}
        period={billingPeriodId}
        location={location}
      />
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      <ErrorsModal
        isModalOpen={showErrorsModal}
        hideModal={toggleShowErrorsModal}
        fetchErrorsService={apiService.postMonthlyMiles.getErrors}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default MileageAudit;

