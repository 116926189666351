import { DefaultButton, IconButton, MessageBarType, Modal, PrimaryButton, Text } from '@fluentui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import DataGridComponent from '../DataGridComponent';
import styles from './VehicleModal.module.scss';
import { useBoolean } from '@fluentui/react-hooks';
import { vehicleHeadCells } from './consts';
import apiService from '../../api';
import useNotifications from '../../hooks/useNotifications';


interface VehiclesModalComponentProps {
  id: string;
  customerId: string;
  locationId: string;
  title: string;
  isOpen: boolean;
  onSubmit: (value?: any) => void;
  onDismiss: () => void;
  successLabel: string;
  cancelLabel: string;
}

const VehiclesModalComponent: FunctionComponent<VehiclesModalComponentProps> = ({
  id,
  customerId,
  locationId,
  title,
  isOpen,
  onSubmit,
  onDismiss,
  successLabel,
  cancelLabel,
}) => {

  const { addNotification } = useNotifications();
  const [vehicleList, setVehicleList] = useState([]);
  const [totalFound, setTotalFound] = useState(0);
  const [vehicleSelected, setVehicleSelected] = useState(null);
  const [notification, setNotification] = useState({ text: '', type: 0 });
  const [isLoading, { toggle: toggleChangeLoading }] = useBoolean(false);

  const fetchData = async (pagination: any, searchedText?: any, sortOrder?: any) => {
    try {
      const { data: { data, total } }: any = await apiService.billingAPI.getTireSaleQuoteVehicles(
        searchedText,
        pagination,
        sortOrder,
        null,
        customerId,
      );
      setVehicleList(data);
      setTotalFound(total?.found);
    } catch (error) {
      setNotification({ text: 'Fetching Plants/Warehouse error', type: MessageBarType.error });
    }
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    toggleChangeLoading();
    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    if (customerId) await fetchData(pagination, searchedText, sortOrder);
    toggleChangeLoading();
  };

  const handleSelectRow = (rowsSelected: any) => {
    if (rowsSelected.length) setVehicleSelected(rowsSelected[0]);
  };

  const handleSubmit = () => onSubmit(vehicleSelected);

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  const disableSelecBtn = vehicleSelected == null;

  return (
    <>
      <Modal
        key={id}
        isOpen={isOpen}
        onDismiss={onDismiss}
        containerClassName='modalContainer'
      >
        <div className={styles.modalBody}>
          <div className='modalHeader'>
            <Text variant='xLarge'>{title}</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel='Close popup modal'
              onClick={onDismiss}
            />
          </div>
          <div className='tableContainer'>
            <DataGridComponent
              idTable={'vehicle-table'}
              title=''
              headCells={vehicleHeadCells}
              rowsTable={vehicleList}
              totalDataFound={totalFound}
              isLoading={isLoading}
              enableCheckBox={true}
              enableSearching={true}
              enablePagination={true}
              enableRowsPerPage={true}
              handleChangeDataGridState={handleChangeDataGridState}
              handleSelectRow={handleSelectRow}
            />
          </div>
          <div className={styles.modalFooter}>
            <DefaultButton
              id='cancelButton'
              text={cancelLabel}
              onClick={onDismiss}
            />
            <PrimaryButton
              id='selectButton'
              text={successLabel}
              onClick={handleSubmit}
              disabled={disableSelecBtn}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default VehiclesModalComponent;